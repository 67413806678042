<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Transferencias
</h3>
<app-main-buscar-filter 
    [num_trab_finiquitados]="num_trab_finiquitados"
    (textSearch)="applyFilter($event)"
    (viewFiniquitadosEmit)="viewDatosFiniquitados($event)">
</app-main-buscar-filter>
<!-- <div class="row container-fluid">
    <div class="mr-2">
        <mat-form-field appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
    </div>
    <div class="d-flex align-items-center">
        <mat-checkbox (change)="viewDatosFiniquitados()" [(ngModel)]="viewFiniquitados" color="warn">
            <span [matBadge]="num_trab_finiquitados <= 99 
                ? num_trab_finiquitados 
                : '+99'" 
                matBadgeOverlap="false">Mostrar trabajadores finiquitados</span>
        </mat-checkbox>
    </div>
</div> -->
<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)" #tabla>

    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.rut | formatoRut }} </td>
    </ng-container>
    <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE COMPLETO </th>
        <td mat-cell *matCellDef="let element"> {{element.nombres | uppercase}} {{element.apellidos | uppercase}} </td>
    </ng-container>

    <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA</th>
        <td mat-cell *matCellDef="let element">{{ element.Empresa_contratante.nombre | uppercase}}</td>
    </ng-container>

    <ng-container matColumnDef="estado_contrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO CONTRATO</th>
        <td mat-cell *matCellDef="let element"> 
            <span [ngClass]="{'bg-danger p-1 rounded text-light': element.estado_contrato === 'Finiquitado'}">{{element.estado_contrato | uppercase}}</span> 
        </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <!-- <button mat-button (click)="mostrar(contenido2,element.rut)" matBadgeColor="accent" class="button_color_primary" style="background-color:rgb(37, 199, 228)"> -->
            <button mat-button (click)="mostrar(contenido2,element)" matBadgeColor="accent" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
              <mat-icon style="color: white">archive</mat-icon>
          </button> &nbsp;
            <!-- <button mat-button (click)="subir(contenido,element.rut)" class="button_color_primary" style="background-color:rgb(37, 199, 228)"> -->
            <button mat-button (click)="subir(contenido,element)" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
              <mat-icon style="color: white">file_upload</mat-icon>
          </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
</mat-paginator>

<!-- modales -->
<!-- modal subir -->
<ng-template style="border: 0px; z-index: 100" #contenido let-modal2>
    <div class="modal-header" style="text-align: center">
        <h4>Subir Transferencias</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <!-- <app-agregar-doctransferencias [rut]="rut_trabajador"></app-agregar-doctransferencias> -->
        <app-agregar-doctransferencias [trabajador]="datosTrabajador"></app-agregar-doctransferencias>
    </div>
</ng-template>

<!-- modal mostrar -->
<ng-template style="border: 0px; z-index: 100" #contenido2 let-modal3>
    <div class="modal-header" style="text-align: center">
        <h4>Mostrar Transferencias</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <!-- <app-modal-mostrar [rut]="rut_trabajador"></app-modal-mostrar> -->
        <app-modal-mostrar [trabajador]="datosTrabajador"></app-modal-mostrar>
    </div>
</ng-template>