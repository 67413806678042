import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { debounceTime } from 'rxjs/operators';
import { RequisitoService } from 'src/app/pages/services/requisitos.service';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { MatSelectChange } from '@angular/material/select';
import {PerfilesService } from 'src/app/pages/services/perfiles/perfiles.service'

@Component({
  selector: 'app-agregar-antecedentes',
  templateUrl: './agregar-antecedentes.component.html',
  styleUrls: ['./agregar-antecedentes.component.css']
})
export class AgregarAntecedentesComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  @Input() list_cargos!:Cargo[];
  debouncer: Subject<boolean> = new Subject();  

  list_requisitos: any;
  id_cargo!: 0;

  list_perfiles: any;
  id_perfiles!: 0;

  nuevo_perfil: any;
  nuevo_descripcion: any;

  render!: boolean |  false;

  requisitos={
    descripcion: "",    
    cargo:this.id_cargo,
    requisito: 0,
    id_cargo:this.id_cargo,
    perfil: 1,
  }

  perfil={
    tipo: "",
  }

  constructor(
    private alert:AlertHelper,
    private modalService:NgbModal,
    private requisitosService: RequisitoService,
    private perfilesService: PerfilesService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
      this.ActualizarPerfiles();
      
  }
  // Mat select con los Cargos
  selectedValue(event: MatSelectChange) {
    this.id_cargo = event.value;
    //console.log("Selecciono el cargo :"+this.id_cargo);
    if(this.id_perfiles>3){         
    this.requisitosService.GetAll_requisitoPorCargo(this.id_cargo).subscribe(
      (res) => {  
        //console.log(res);
        this.list_requisitos = res.data.filter((x:any)=>{ return x.requisitos.perfil==this.id_perfiles;})
       
      }
    );}
  } 

  //Matselect de los perfiles
  selectedPerfil(event: MatSelectChange) {
    this.requisitos.perfil=event.value;
    //console.log("Selecciono el perfil id :"+this.id_perfiles);
    this.requisitosService.GetAll_requisitoPorCargo(this.id_cargo).subscribe(
      (res) => { 
        //console.log(res);
        // Se filtra los resultados donde el requisito perfil sea igual a 1 , es decir REQUISITO                                
        this.list_requisitos = res.data.filter((x:any)=>{ return x.requisitos.perfil==this.id_perfiles;})
        //this.list_requisitos = res.data;
      }
    );  
  }

  actualizarTabla(event:boolean){            
    this.requisitosService.GetAll_requisitoPorCargo(this.id_cargo).subscribe(
      (res) => { 
        // Se filtra los resultados donde el requisito perfil sea igual a 1 , es decir REQUISITO  
        //console.log("id perfil a listar: "+this.id_perfiles);                              
        this.list_requisitos = res.data.filter((x:any)=>{ return x.requisitos.perfil==this.id_perfiles;})
        //this.list_requisitos = res.data;
      }
    ); 
  }

  open(content:any){
    this.limpiar_Requisito();
    this.modalService.open(content);
  }

  validarRequisito(){
    if(this.nuevo_descripcion.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.');
      return false;
    }
    return true;
  }

  limpiar_Requisito(){
    this.nuevo_descripcion="";
    this.nuevo_perfil="";  
  }

  agregar(){    
    if(!this.validarRequisito()){
      return;
    }        
    this.requisitos.cargo = this.id_cargo;
    this.requisitos.descripcion= this.nuevo_descripcion;
      this.requisitosService.Create_requisito(this.requisitos).subscribe((res:any)=>{  
        //console.log(res);     
        if (res.success==true) {
          this.alert.success_small(res.msg)
          this.modalService.dismissAll();
          this.limpiar_Requisito();
          this.debouncer.next( true );
          this.actualizarTabla(true);
        }else{
          this.alert.error_small(res.msg!)
        }
    })
    
  }

  guardarPerfil(){

    if(this.nuevo_perfil.trim().length<3){
      this.alert.errorAlert('Los campos no pueden estar vacios.');
      return;
    }
    this.perfil.tipo=this.nuevo_perfil
    //console.log(this.perfil);
    this.perfilesService.Create_Perfil(this.perfil).subscribe((res:any) =>{
      if(res.success==true){
        this.alert.success_small(res.msg!)
        this.limpiar_Requisito();
        this.debouncer.next( true );
        this.ActualizarPerfiles();
        this.render=false;
      }else{
        this.alert.error_small(res.msg!)
      }

    })
  }

  cancelaradd(){
    this.render= false;
    this.limpiar_Requisito();
  }

  
  crearPerfil(){
    this.render= true;
  }

  ActualizarPerfiles() {            
    this.perfilesService.GetAll_perfiles().subscribe(
      (res) => {  
        this.list_perfiles = res.data.filter((x:any)=>{ return x.id!=1 && x.id!=2 && x.id!=3 && x.id!=4 ;});    
      }
    );  
  }

}
