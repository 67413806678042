import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { debounceTime } from 'rxjs/operators';
import { TrabajadoresService } from '../services/trabajadores/trabajadores.service';
import { ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [],
})

export class HomeComponent implements OnInit {
  debouncer: Subject<boolean> = new Subject()
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()

  usuario: any //<---- esto de aca trae el Json del token 
  trabajadores: any[] = [];
  totalPlazoFijo: number = 0;
  totalPlazoIndefinido: number = 0;
  totalObraFaena: number = 0;
  totalFiniquitado: number = 0;
  index: number = 0;
  intentosFetchPrevired:number = 0;
  countContratoVencer: number = 0;
  countAnexosVencer: number = 0;
  cargarTablaContratos: boolean = false;
  cargarTablaAnexos: boolean = false;
  message: Message[];

  constructor
  (
    public authService: AuthService,
    private trabajadorService: TrabajadoresService,
    private route: ActivatedRoute,
  ) {
    console.log('Home Constructor');
  }

  ngOnInit(): void {
    this.usuario = this.authService.ObtenerinfoToken()

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      });
    this.route.fragment.subscribe(fragment => {
      switch (fragment) {
        case 'viewContrato':
          this.index = 1;
          break;
        case 'viewAnexos':
          this.index = 2;
          break;
        default:
          this.index = 0;
          break;
      }
    });
    // TRAER DATOS DE CONTRATOS DE LOS TRABAJADORES
    this.cargarDatosContratos();
  }
  // MUESTRA EL MENSAJE DE INFORMACION
  viewMessage() {
    this.message = [
      {
        severity:'info', 
        summary:'Nueva funcionalidad importante', 
        detail:'Desde el 15 de febrero, al eliminar un documento se generará una solicitud de eliminación de documentos (excepto las Liquidaciones) a través del sistema. La solicitud será aprobada por Gerencia o Administradores del sistema.'
      }
    ];
  }

  recibirCountContrato(event: number) {
    this.countContratoVencer = event;
  }

  recibirCountAnexo(event: number){
    this.countAnexosVencer = event;
  }

  // RECUPERA Y CUENTA LOS TIPOS DE CONTRATOS QUE EXISTEN
  cargarDatosContratos(){
    this.trabajadorService.TraerTrabajadores().subscribe(({ data }) => {
      this.trabajadores = data;
      this.totalPlazoIndefinido = this.trabajadores.reduce((a, e) => {
        if (e.estado_contrato === 'Contrato Indefinido' && e.estado === 1) {
          return a + 1;
        }
        return a;
      }, 0);
      this.totalPlazoFijo = this.trabajadores.reduce((a, e) => {
        if (e.estado_contrato === 'Contrato a Plazo Fijo' && e.estado === 1) {
          return a + 1;
        }
        return a;
      }, 0);
      this.totalObraFaena = this.trabajadores.reduce((a, e) => {
        if (e.estado_contrato === 'Contrato por Obra o Faena' && e.estado === 1) {
          return a + 1;
        }
        return a;
      }, 0);
      this.totalFiniquitado = this.trabajadores.reduce((a, e) => {
        if (e.estado_contrato === 'Finiquitado' || e.estado === 0) {
          return a + 1;
        }
        return a;
      }, 0)
    });
  }

  handleChange(e) {
    var index = e.index;
    if (index === 1) {
      this.cargarTablaContratos = true;
    }
    if(index === 2){
      this.cargarTablaAnexos = true;
    }
  }
}
