<div class="animate__animated animate__fadeInRight">
    <br/>
    <div class="col">
        <mat-card class="mat_card_trabajadores">
            <h1 class>Modulo de Haberes o Descuentos</h1>
        </mat-card>
    </div>
    <br/>
    <mat-card class="mat-elevation-z5 mat_card_trabajadores_content">
        <mat-tab-group class="card_group_trabajadores">
            <mat-tab label="Haberes o Descuentos">

               
                <app-lista-haberes></app-lista-haberes>
                <hr/>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
    <hr />
</div>