<button mat-raised-button (click)="algo(contenido)" color="primary">
    Agregar razón
</button>
<ng-template #contenido let-modal2>
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Agregar razon</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row justify-content-center">
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Razón</mat-label>
                    <input type="text" name="razon" matInput [(ngModel)]="razon.razon" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Atraso</mat-label>
                    <mat-select [(ngModel)]="razon.esAtraso">
                        <mat-option *ngFor="let opcion of opciones" [value]="opcion.value">{{ opcion.text }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-auto">
                <h4>Color de fondo</h4>
                <p-colorPicker name="colorpicker" [(ngModel)]="razon.colorfondo" [inline]="true">
                </p-colorPicker>
            </div>
            <div class="col-auto">
                <h4>Color de texto</h4>
                <p-colorPicker name="colorpicker" [(ngModel)]="razon.colortexto" [inline]="true">
                </p-colorPicker>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button class="button_color_primary text-light" (click)="guardar()">
                <mat-icon>backup</mat-icon>
                Guardar
            </button>
        </div>
    </div>

</ng-template>