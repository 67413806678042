import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { HelperModule } from 'src/app/helper.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { FileUploadModule } from 'ng2-file-upload';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';

import { MainPermisosComponent } from './main-permisos/main-permisos.component';
import { AgregarPermisosComponent } from './agregar-permisos/agregar-permisos.component';
import { ListaPermisosComponent } from './lista-permisos/lista-permisos.component';
import { ListarPermisosTabajadorComponent } from './listar-permisos-tabajador/listar-permisos-tabajador.component';
import { SubirPermisosComponent } from './subir-permisos/subir-permisos.component';

@NgModule({
  declarations: [
    MainPermisosComponent,
    AgregarPermisosComponent,
    ListaPermisosComponent,
    ListarPermisosTabajadorComponent,
    SubirPermisosComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    HelperModule,
    PrimeNgModule,
    FileUploadModule,
    BuscarFilterModule,
  ],
  exports:[
    MainPermisosComponent,
    AgregarPermisosComponent,
    ListaPermisosComponent,
    CommonModule,
    MaterialModule,
    FormsModule,
    MatSortModule,
    MatMomentDateModule   
  ]
})
export class PermisosModule { }
