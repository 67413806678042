import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HaberesService } from 'src/app/pages/services/haberes/haberes.service';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { ConceptosLREService } from 'src/app/pages/services/conceptosLRE/conceptoslre.service';
import { ConceptoLRE } from 'src/app/shared/models/conceptoslre.interface';

@Component({
  selector: 'app-agregar-haberes',
  templateUrl: './agregar-haberes.component.html',
  styleUrls: ['./agregar-haberes.component.css']
})
export class AgregarHaberesComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  addressForm = this.fb.group({
    nombre: ["", Validators.required, Validators.minLength(3)],
    descripcion: ["", Validators.required, Validators.minLength(3)],
    tipo: [null, Validators.required],
    imponible: [null, Validators.required],
    tributable: [null, Validators.required],
    gratificacion: [null, Validators.required],
    remCaractFijo: [null, Validators.required],
    remCaractVariable: [null, Validators.required],
    bSemanaCorrida: [null, Validators.required],
    proporcional: [null, Validators.required],
    idconceptolre: [null, Validators.required],
  });
  opciones = [0, 1];
  optionDefaul = false;
  conceptos: ConceptoLRE[] = [];
  conceptos_filter: ConceptoLRE[] = [];

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private haberesService: HaberesService,
    private fb: FormBuilder,
    private conceptoService: ConceptosLREService
  ) { }

  open(content: any) {
    this.addressForm.reset();
    this.modalService.open(content, { size: 'lg' });
  }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.conceptoService.GetAll_Conceptos().subscribe(res => {
      if (res.success) {
        this.conceptos = res.data;
        this.filtrarConceptos();
      } else {
        console.error(res.msg);
      }
    });
    this.addressForm.get('imponible').valueChanges.subscribe((value) => {
      this.addressForm.value.imponible = value;
      this.filtrarConceptos()
    });
    this.addressForm.get('tributable').valueChanges.subscribe((value) => {
      this.addressForm.value.tributable = value;
      this.filtrarConceptos()
    });
    this.addressForm.get('tipo').valueChanges.subscribe((value => {
      this.addressForm.value.tipo = value;
      this.filtrarConceptos();
    }))

  }
  filtrarConceptos(){
    const obj = this.addressForm.value;
    const tipo = obj.tipo;
    const imponible = obj.imponible;
    const tributable = obj.tributable;
    console.log({imponible}, {tributable}, {tipo});
    if (tipo === 0) {
      this.conceptos_filter = this.conceptos.filter(x => !x.tipo);
    } else if (imponible === 1 || imponible === 0 
        && tributable === 1 || tributable === 0) 
    {
      if (obj.imponible === 1 && obj.tributable === 1) {
        this.conceptos_filter = this.conceptos.filter(x => x.imponible && x.tributable);
      } else if (obj.imponible === 1 && obj.tributable === 0){
        this.conceptos_filter = this.conceptos.filter(x => x.imponible && !x.tributable);
      } else if (obj.imponible === 0 && obj.tributable === 0){
        this.conceptos_filter = this.conceptos.filter(x => !x.imponible && !x.tributable);
      }
    }
    // console.log({...this.conceptos_filter})
  }

  get f() {
    return this.addressForm.controls;
  }

  onSubmit() {
    const haber = this.addressForm.value;
    console.log({haber});
    // return;
    this.haberesService.Create_Haber(this.addressForm.value).subscribe((res: any) => {
      //console.log(res);      
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.addressForm.reset();
        this.debouncer.next(true);

      } else {
        this.alert.error_small(res.msg!)
      }
    })

  }


}
