import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegionesService } from 'src/app/pages/services/regiones.service';
import { Region } from 'src/app/shared/models/region.interface';
import { AlertHelper } from '../../../../shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-agregar-regiones',
  templateUrl: './agregar-regiones.component.html',
  styleUrls: ['./agregar-regiones.component.css']
})
export class AgregarRegionesComponent implements OnInit {
  @Output() actualizarTabla = new EventEmitter<boolean>(false);

  optionsNumeric = {
    digitGroupSeparator: '', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  region:Region = {
    nombre: '',
    codigo_lre: 0,
  };
  constructor(
    private modalService: NgbModal,
    private regionService: RegionesService,
    private alert:AlertHelper,
  ) { }

  ngOnInit(): void {
  }

  open(content){
    this.modalService.open(content, {size: 'lg'});
  }

  CrearRegion(){
    this.regionService.Crear_Region(this.region).subscribe((res) => {
      if (res.success) {
        this.modalService.dismissAll();
        this.actualizarTabla.emit(true);
        this.alert.createAlert('Creado exitosamente');
      }else{
        this.alert.errorAlert(res.msg);
      }
    });
  }

}
