import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelperModule } from 'src/app/helper.module';
import { FileUploadModule } from 'ng2-file-upload';

// COMPONENTES
import { SolicitudesLiquidacionComponent } from './solicitudes-liquidacion/solicitudes-liquidacion.component';
import { ModalViewLiquidacionComponent } from './solicitudes-liquidacion/modal-view-liquidacion/modal-view-liquidacion.component';
import { ModalViewHistoryComponent } from './solicitudes-liquidacion/modal-view-history/modal-view-history.component';
import { SolicitudesDocumentosComponent } from './solicitudes-documentos/solicitudes-documentos.component';
import { ElegirDesicionCorreoComponent } from './solicitudes-documentos/elegir-desicion-correo/elegir-desicion-correo.component';
import { MainSolicitudDespidoComponent } from './solicitud-despido/main-solicitud-despido/main-solicitud-despido.component';
import { AgregarSolicitudDespidoComponent } from './solicitud-despido/agregar-solicitud-despido/agregar-solicitud-despido.component';
import { ListarSolicitudDespidoComponent } from './solicitud-despido/listar-solicitud-despido/listar-solicitud-despido.component';
import { MainSolicitudVacacionesComponent } from './solicitudes-vacaciones/main-solicitud-vacaciones/main-solicitud-vacaciones.component';
import { SolicitudesHonorarioComponent } from './solicitudes-liquidacion/solicitudes-honorario/solicitudes-honorario.component';
import { ModalViewHonorarioComponent } from './solicitudes-liquidacion/solicitudes-honorario/modal-view-honorario/modal-view-honorario.component';
import { MainSolicitudPermisosComponent} from './solicitud-permisos/main-solicitud-permisos/main-solicitud-permisos.component';
import { SubirDocumentoComponent } from './solicitud-permisos/subir-documento/subir-documento.component'

@NgModule({
  declarations: [
    SolicitudesLiquidacionComponent,
    ModalViewLiquidacionComponent,
    ModalViewHistoryComponent,
    SolicitudesDocumentosComponent,
    ElegirDesicionCorreoComponent,
    MainSolicitudDespidoComponent,
    AgregarSolicitudDespidoComponent,
    ListarSolicitudDespidoComponent,
    MainSolicitudVacacionesComponent,
    SolicitudesHonorarioComponent,
    ModalViewHonorarioComponent,
    MainSolicitudPermisosComponent,
    SubirDocumentoComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PrimeNgModule,
    HelperModule,
    PdfViewerModule,
    FileUploadModule
  ],
  exports:[
    SolicitudesLiquidacionComponent,
    ModalViewLiquidacionComponent,
    ModalViewHistoryComponent,
    MainSolicitudDespidoComponent,
    AgregarSolicitudDespidoComponent,
    ListarSolicitudDespidoComponent,
    MainSolicitudVacacionesComponent,
    SolicitudesHonorarioComponent,
    MainSolicitudPermisosComponent
  ]
})
export class SolicitudesModule { }
