<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
      <span style="color: white">Agregar Tipo de Préstamo </span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
  </div>
  
  <ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
      <mat-card-title>Agregar nuevo tipo de préstamo</mat-card-title>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="text" maxlength="50" minlength="3" matInput name=""
              [(ngModel)]="nombre" />
          </mat-form-field>
        </div> 
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Descripción</mat-label>
            <textarea matInput cols="2" [(ngModel)]="descripcion"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">Cancelar</span>
      </button>
      <button type="button" class="btn btn-outline-success" (click)="guardar()">
        Guardar
      </button>
    </div>
  </ng-template>