import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Role, User, UserResponse } from 'src/app/shared/models/user.interface';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { interval, Subscription } from 'rxjs';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  //variables observables para
  tiempo: Subscription;
  redirectURL: string;
  private loggedIn = new BehaviorSubject<boolean>(false);
  private role = new BehaviorSubject<Role>("NULL");
  public userName = new BehaviorSubject<string>('');
  public NombreUsuario: string = ""

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private alert: AlertHelper) {
      this.checkToken();
      this.ObtenerinfoToken();
      this.tiempo = interval(200000).subscribe((x => {

        // funcion que se ejecuta cada x tiempo
        //coloca aca las futuras comprovaciones con tiempo
        // console.log("se ejecuta la funcion");

        if (!this.checkToken()) {
          this.redirectURL = this.router.url;
          this.router.navigate(['login'], { replaceUrl: true })
        };

      }))
  }

  get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  get ObtenerNombre(): string {
    return this.NombreUsuario;
  }

  get isAdmin(): Observable<string> {
    return this.role.asObservable();
  }

  login(authData: User): Observable<UserResponse> {
    return this.http
      .post<UserResponse>(`${environment.API}/rrhh/usuario/login`, authData)
      .pipe(
        map((res: UserResponse) => {

          const tokeninfo = helper.decodeToken(res.token)
          this.NombreUsuario = tokeninfo.user.nombre
          this.saveToken(res.token)
          this.loggedIn.next(true)
          // console.log(tokeninfo);

          this.role.next(tokeninfo.user.rol)
          return res
        }),
        catchError((err) => this.handlerError(err))
      );
  }

  logout(): void {
    localStorage.clear();
    this.loggedIn.next(false);
    this.router.navigate(['login'], { replaceUrl: true });
  }

  private checkToken(): boolean {

    let userToken = localStorage.getItem('token') || null;

    if (userToken) {

      const isExpired = helper.isTokenExpired(userToken);
      const tokeninfo = helper.decodeToken(userToken);
      if (isExpired) {
        userToken = ''
        this.logout()
        return false;
      } else {
        localStorage.setItem('rol', tokeninfo.user.rol);
        this.loggedIn.next(true);
        this.role.next(tokeninfo.user.rol)
        return true
      }
    }
    else {
      return false
    }
  }

  private saveToken(token: string): void {

    localStorage.setItem('token', token);

  }
  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  public isLoggin(){
    return localStorage.getItem('token') === null || localStorage.getItem('token').length > 0;
  }

  public ObtenerinfoToken(){
    let userToken = localStorage.getItem('token') || null;
    if (userToken){
       const tokeninfo = helper.decodeToken(userToken);
       this.NombreUsuario = tokeninfo.user.nombre;
       return tokeninfo.user;


    }
    else {
      this.logout();
    }
  }

  private handlerError(err: any): Observable<never> {
    // console.log(err)
    let errorMessage = "Ha ocurrido un error recibiendo la data";
    if (err) {
      // errorMessage = `Error: code ${err.error.msg}`;
      errorMessage = `Error: ${err.error.msg}`;
    }
    // this.alert.error_small('Error de conexion con la base de datos ');
    this.alert.error_small(errorMessage);
    return throwError(err.message);
  }

  esEmailValido(email: string): boolean {
    let mailValido = false;
    'use strict';

    // var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email.match(EMAIL_REGEX)) {
      mailValido = true;
    }
    return mailValido;
  }
}
