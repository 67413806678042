import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';  // Necesario para utilizar paginator y filter
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { cleanRut } from 'rutlib/lib';

@Component({
  selector: 'app-main-vacaciones',
  templateUrl: './main-vacaciones.component.html',
  styleUrls: ['./main-vacaciones.component.css'],
  providers: [DatePipe]
})
export class MainVacacionesComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private _liveAnnouncer: LiveAnnouncer,
  ) { }


  estado_contrato = [
    { id: 1, valor: 'Contrato A Plazo Fijo' },
    { id: 2, valor: 'Contratado' },
    { id: 3, valor: 'Contrato Por Proyecto' },
    { id: 4, valor: 'Finiquitado' },
    { id: 5, valor: 'freelance' },
  ]

  displayedColumns = [
    'rut',
    'nombres',
    'empresa',
    'estado_contrato',
    'acciones'
  ];

  dataSource: any;
  lista_trabajadores: any[] = [];
  trabajadorFilter: any[] = [];
  RutTrabajador: any;
  num_trab_finiquitados: number = 0;
  textSearch: string = ''; 
  @ViewChild(MatPaginator) paginator!: MatPaginator;   // variable para asignar paginator a matdatatable
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(textSearch: string) {
    //Funcion para filtar cualquier columna por el texto buscado
    this.textSearch = textSearch;
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
}

  open2(content2: any ,rut:any) {
    this.modalService.open(content2, { size: 'xl' });
    this.RutTrabajador=rut;
  }

  open(content: any ,rut:any) {
    this.modalService.open(content, { size: 'lg' });
    this.RutTrabajador=rut;
  }

  open3(content3: any ,rut:any) {
    this.modalService.open(content3, { size: 'xl' });
    this.RutTrabajador=rut;
  }

  ngOnInit(): void {
    this.TraerDatos();
  }

  // ORDENA LOS DATOS
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  TraerDatos() {

    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        this.lista_trabajadores = res.data;
        this.trabajadorFilter = this.lista_trabajadores.filter(x => x.estado !== 0);
        // CONTAR LOS TRABAJADORES FINIQUITADOS
        this.num_trab_finiquitados = this.lista_trabajadores.reduce((a,e) => e.estado === 0 ? a + 1 : a, 0);
        //Asignacion de dataSource con el tipo MatTableDataSource (necesario para poder agregar los atributos .paginator y .filter)
        this.dataSource = new MatTableDataSource<any>(this.trabajadorFilter);
        this.dataSource = this.configurarTabla(this.dataSource);
      }
    );
  }
  configurarTabla(dataSource: any): any {
    dataSource.paginator = this.paginator;
    // CONFIGURA EL FILTRADO DE DATOS
    dataSource.filterPredicate = (data:any, filter:string) => {
      return this.configurarFilterPredicate(data, filter);
    };
    // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
    dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'empresa': return item.Empresa_contratante.nombre;
        default: return item[property];
      }
    }
    dataSource.sort = this.sort;
    return dataSource;
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLowerCase().split(' ');

    const rutSinPuntos: string = cleanRut(data.rut.toString());
    const nombre: string = this.normalizeText(data.nombres+ ' ' +data.apellidos).toLowerCase();
    const empresa: string = this.normalizeText(data.Empresa_contratante.nombre).toLowerCase();
    const estado_contrato: string = data.estado_contrato.toLowerCase();

    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.rut.toString().includes(x) 
        || nombre.includes(x) || empresa.includes(x) || estado_contrato.includes(x)
    );
  }
  viewDatosFiniquitados(viewFiniquitados: boolean){
    this.trabajadorFilter = viewFiniquitados 
      ? this.lista_trabajadores 
      : this.lista_trabajadores.filter(x => x.estado !== 0);
    
    this.dataSource = new MatTableDataSource<any>(this.trabajadorFilter);
    this.dataSource = this.configurarTabla(this.dataSource);
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  // Función que elimina los diacríticos de las vocales de una cadena
  normalizeText(texto: string): string {
    return texto?.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }
}
