import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LicenciasService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper)  { }

  EnviarArchivo(archivo:any){
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/licencias/documento`,archivo)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  EnviarLicencia(licencia:any) {
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/licencias/crear`,licencia)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/licencias/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async TraerPorRutAsync(rut:number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/licencias/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success:false, msg: 'Hubo un error al obtener la o las licencias.'}as RequestResponse)
    }
  }

  
  TraerArchivo(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarlicenciaid/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerTodo():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/licencias/listartodo/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async TraerTodoAsync(): Promise<RequestResponse> {
    try {
      const response = await this.http.get<RequestResponse>(`${environment.API}/rrhh/licencias/listartodo/`).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los datos de Licencias'} as RequestResponse);
    }
  }


  TraerLicencia(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/licencias/buscarporid/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  eliminarContrato(id:number):Observable<RequestResponse> {
    return this.http
      .delete(`${environment.API}/rrhh/licencias/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError(error => this.handlerError(error)),
      );
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }






}
