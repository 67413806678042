import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AgregarAdelantosComponent } from './agregar-adelantos/agregar-adelantos.component'
import { ListaAdelantosComponent } from './lista-adelantos/lista-adelantos.component'
import { MainAdelantosComponent } from './main-adelantos/main-adelantos.component'
import { MaterialModule } from 'src/app/material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PrimeNgModule } from 'src/app/PrimeNg.module'
import { AutonumericModule } from '@angularfy/autonumeric'



@NgModule({
  declarations: [
    AgregarAdelantosComponent, 
    ListaAdelantosComponent, 
    MainAdelantosComponent,
  ],
  
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNgModule,
    AutonumericModule,
  ],

  exports: [
    AgregarAdelantosComponent, 
    ListaAdelantosComponent, 
    MainAdelantosComponent,
    ]
})
export class AdelantosModule { }
