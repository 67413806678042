import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2'; 
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css']
})
export class UploadDialogComponent {
  description: string = '';
  file: File | null = null;
  uploader: FileUploader;

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { denunciaId: string }
  ) {
    this.uploader = new FileUploader({
      url: `${environment.API}/rrhh/denuncia/documento`,
      itemAlias: 'denuncias',
      additionalParameter: {
        description: this.description,
        denunciaId: this.data.denunciaId
      }
    });

    this.uploader.onSuccessItem = (item, response) => {
      this.resetFields(); 
      this.dialogRef.close();
      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: 'Documento subido correctamente.',
        timer: 3000, 
        timerProgressBar: true, 
        showConfirmButton: false 
      });
    };

    this.uploader.onErrorItem = () => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al subir el documento.',
        timer: 3000, 
        timerProgressBar: true, 
        showConfirmButton: false 
      });
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('description', this.description);
      form.append('denunciaId', this.data.denunciaId);
    };
  }

  onNoClick(): void {
    this.resetFields(); 
    this.dialogRef.close();
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0] || null;
    if (this.file) {
      this.uploader.addToQueue([this.file]); 
    }
  }

  upload(): void {
    this.uploader.uploadAll();
  }

  private resetFields(): void {
    this.description = '';
    this.file = null;
    this.uploader.clearQueue(); 
  }
}
