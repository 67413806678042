import { Injectable } from '@angular/core';
import { getLastDigitOfRut } from 'rutlib';
import { format, getMonth, getYear, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

 formatoRut(rut:number):string{

  var aux: string = rut.toString();
  let aux2=(aux.slice(0, - 1))
  let dv = getLastDigitOfRut(Number(aux2));
  aux= aux2 +dv;
    return (
      aux.slice(0, -7) +
        '.' +
        aux.slice(-7, -4) +
        '.' +
        aux.slice(-4, -1) +
        '-' +
        aux.slice(-1)
    );
 }


 //recibe un int (ya redondeado con math) y devuelve un string con los puntos CLP
 formatoCLP(pesos:number):string{
  var auxi = pesos.toString();

  var auxi2 = '';
  var parts = auxi.split('.');
  var many = parts[0].length; //  //. 6 / 3
  if (many<=3){
    return (parts[0]);
  }
  for (var i = many, j = 3; j <= many; i -= 3, j += 3) {
      auxi = parts[0].slice(-j, i);
      if (j == many) {
            auxi2 = auxi + auxi2;
      } else {
        auxi2 = '.' + auxi+auxi2;
      }
    if((j+3)>many){
    auxi = parts[0].slice(0, -j);
    auxi2 = auxi+ auxi2
    }
  }
    return(auxi2);
 }


 recuperarRut(rut:number):number{
  let rut2 = rut.toString();
  let aux=(rut2.slice(0, - 1))
  let dv = getLastDigitOfRut(Number(aux));
 return Number( aux + dv);

 }

 FechaaTexto(fecha: any) { // Creando una fecha para el 15 de marzo de 1998 (los meses en JavaScript comienzan desde 0)
  fecha = new Date(fecha);
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
  return fecha.toLocaleDateString('es-CL', options);
}

savePeriodoStorage(periodo: Date){
  const year = getYear(periodo);
  const month = getMonth(periodo);
  localStorage.setItem('periodoLiquidacion', JSON.stringify({ year, month }));
}
getPeriodoStorage(){
  const periodo = JSON.parse(localStorage.getItem('periodoLiquidacion'));
  if (periodo) return new Date(periodo.year, periodo.month, 1);
  const date = new Date();
  if (date.getDate() < 15) return new Date(date.getFullYear(), date.getMonth() - 1, 1);
  else return new Date();
}
FechaTextoContrato(fecha: string){
  // FECHA: yyyy-mm-dd
  console.log(typeof fecha);
  const fechaString = fecha.includes('T') ? fecha.split('T')[0] : fecha;
  const fechaDate = parseISO(fechaString);
  const fechaFormateada = format(fechaDate, "d 'de' MMMM 'de' yyyy", { locale: es } );
  console.log(fechaFormateada);
  return fechaFormateada;
}

EsFeriadoChile(fecha: Date){

let feriados = [];
//////////////////// Fuente: https://www.feriados.cl/
feriados.push(new Date(2023, 0, 1));   // Año Nuevo
feriados.push(new Date(2023, 0, 2));  // Feriado Adicional Lunes 2 de enero Ley 20.983
feriados.push(new Date(2023, 3, 7));    //Viernes Santo	Religioso	Ley 2.977
feriados.push(new Date(2023, 3, 8));    //Sábado Santo	Religioso	Ley 2.977
feriados.push(new Date(2023, 4, 1));    // Día Nacional del Trabajo Irrenunciable	Civil	Código del Trabajo, Ley 19.973
feriados.push(new Date(2023, 5, 21));    // San Pedro y San Pablo	Religioso	Ley 2.977, Ley 18.432, Ley 19.668
feriados.push(new Date(2023, 6, 16));    // Día de la Virgen del Carmen	Religioso	Ley 20.148
feriados.push(new Date(2022, 7, 15));    // Asunción de la Virgen	Religioso	Ley 2.977
feriados.push(new Date(2022, 8, 4));    // 	Plebiscito Salida Nueva Constituciónc Irrenunciable	Civil	Ley 18.700, Ley 19.973
feriados.push(new Date(2022, 8, 18));   //Independencia Nacional Irrenunciable	Civil	Ley 2.977, Ley 19.973
feriados.push(new Date(2022, 8, 19));    // Día de las Glorias del Ejército Irrenunciable	Civil	Ley 2.977, Ley 20.629
feriados.push(new Date(2022, 9, 10));    // Encuentro de Dos Mundos	Civil	Ley 3.810, Ley 19.668
feriados.push(new Date(2022, 9, 31));    // 	Día de las Iglesias Evangélicas y Protestantes	Religioso	Ley 20.299
feriados.push(new Date(2022, 10, 1));    // 	Día de Todos los Santos	Religioso	Ley 2.977
feriados.push(new Date(2022, 10, 8));    // Inmaculada Concepción	Religioso	Ley 2.977
feriados.push(new Date(2022, 11, 25));    //  	Navidad Irrenunciable	Religioso	Ley 2.977, Ley 19.973

let day = fecha.getDay();
if (day != 0){


  for (let i = 0; i < feriados.length; i++){
    if (feriados[i].getTime() === fecha.getTime()){
      return true;
    }
  }
  return false;
}
else{
  return true;
}






}








}
