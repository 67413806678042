import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Afp } from 'src/app/shared/models/afps.interface';
import { AfpService } from 'src/app/pages/services/afp.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-agregar-afp',
  templateUrl: './agregar-afp.component.html',
  styleUrls: ['./agregar-afp.component.css']
})
export class AgregarAfpComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  afp:Afp={
    nombre:"",
    tasa: 0,  
    cod_afp: 0,    
  }

  optionsNumericDecimal = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 2, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private afpService: AfpService,
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
  }

  open(content:any){
    this.limpiar_Afp();
    this.modalService.open(content);
  }

  validarAfp(){
    if(this.afp.nombre.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.')
    }
  }

  limpiar_Afp(){
    this.afp.nombre=""; 
  }

  agregar(){

    this.validarAfp()
      this.afpService.Create_afp(this.afp).subscribe((res:any)=>{
        if (res.success==true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.limpiar_Afp();
          this.debouncer.next( true );
        }else{
          this.alert.error_small(res.msg!)
        }
    })
    
  }

}


