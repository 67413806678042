import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import {JornadaLaboral, RequestResponseGetAllJornada, RequestResponseActualizar } from 'src/app/shared/models/jornada-laboral.interface';


@Injectable({
  providedIn: 'root'
})
export class JornadaLaboralService {

  constructor(
    private http:HttpClient,
    private router: Router,
    private alert:AlertHelper)
   { }



   GetAll_jornadas(): Observable<RequestResponseGetAllJornada> {
    return this.http
    .get<RequestResponseGetAllJornada >(`${environment.API}/rrhh/jornadaLaboral/`)
    .pipe(
      map((res:RequestResponseGetAllJornada) =>{          
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  

  CreateJornada(jornada:JornadaLaboral): Observable<RequestResponse> {      
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/jornadaLaboral/crear`,jornada)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  ActualizarJornada(jornada:any): Observable<RequestResponseActualizar>  {
    return this.http
    .put<RequestResponseActualizar >(`${environment.API}/rrhh/jornadaLaboral/actualizar/${jornada.id}`,jornada)
    .pipe(
      map((res:RequestResponseActualizar) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  ObtenerJornada(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/jornadaLaboral/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

  EliminarJornada(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/jornadaLaboral/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

}




