import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { catchError, debounceTime, forkJoin, map, of, Subject } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MatSelectChange } from '@angular/material/select';
import { NgbDateStruct, NgbModal, NgbTimepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl, FormGroup } from '@angular/forms';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { differenceInHours, differenceInMinutes, eachDayOfInterval, isWeekend } from 'date-fns';

import Swal from 'sweetalert2';
import Holidays from 'date-holidays';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Asistencia } from 'src/app/shared/models/gestor-asistencia/gestor-asistencia.interface';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lista-gestorasistencia',
  templateUrl: './lista-gestorasistencia.component.html',
  styleUrls: ['./lista-gestorasistencia.component.css'],
  providers: [DatePipe]
})
export class ListaGestorasistenciaComponent implements OnInit {

  debouncer: Subject<boolean> = new Subject();
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()

  now: Date = new Date();
  modelFecha: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
  timeStart: NgbTimeStruct = { hour: 8, minute: 30, second: 0 };
  timeEnd: NgbTimeStruct = { hour: 8, minute: 30, second: 0 };

  fechaDesdeHasta: FormGroup;
  holidays = new Holidays('CL');

  constructor(
    private asistenciaService: GestorAsistenciaService,
    private auth: AuthService,
    private alert: AlertHelper,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private config: NgbTimepickerConfig,
    private trabajadorService: TrabajadoresService,
  ) {
    this.config.seconds = false;
    this.config.spinners = false;
    this.user = this.auth.ObtenerinfoToken();
  
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator

  /////—————  Variables   ————-\\\\\\

  trabajadores_aux: any[];
  trabajador: any;
  dias_trabajados_semana: number;
  dataSource: any;
  lista_razones: any;
  user: any;

  hoy: Date = new Date();
  maxDateValue: Date = new Date(this.hoy.getFullYear(), this.hoy.getMonth() + 1, 0, 0, 0, 0, 0);

  // FILTERS
  trabajadorSelect: string = '';
  razonSelect: string = '';
  periodoSelect: Date = new Date();
  // DROPDOWN
  trabajadoresFilter: any[] = [];
  razonesFilter: any[] = [];

  asistencias: any[];
  asistencia: any = {
    id: 0,
    start: "",
    end: "",
    timeStart: "",
    timeEnd: "",
    dias_faltados: "",
    razon: "",
    trabajador: 0,
    motivo: "",
    dsueldo: "xx",
    dbono: "xx",
    tsueldo: 0,
    tbono: 0
  }

  start: any;
  end: any;

  fechai: any;
  fechaf: any;
  fechainicioAtrazada: any;
  fecha1incioAtrazadac: any;
  fecha2inicioAtrazada: any;
  fecha2inicioAtrazadac: any;
  DiaAtrazado: any;
  render = 0;

  si: any;
  bono: any;
  sueldo: any;
  no: any;
  /////————— Variable BD and Tabla  ————-\\\\\\


  displayedColumns: string[] = [
    'trabajador',
    'start',
    'end',
    'razon',
    'motivo',
    'dias_faltados',
    // 'dsueldo',
    // 'dbono',
    // 'tsueldo',
    // 'tbono',
    'acciones'
  ];





  /////—————  Select MatSelectChange   ————-\\\\\\

  ObtenerRazon(event: MatSelectChange) {

    this.asistencia.razon = event.value;
  }



  /////—————  Select MatDatepickerInputEvent   ————-\\\\\\


  obtener_fechaAtrasadainicio(event: MatDatepickerInputEvent<Date>) {
    this.fechainicioAtrazada = event.value;
    this.fecha1incioAtrazadac = this.datePipe.transform(this.fechainicioAtrazada, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia

  }

  obtener_fechaAtrasadafin(event: MatDatepickerInputEvent<Date>) {
    this.fecha2inicioAtrazada = event.value;
    this.fecha2inicioAtrazadac = this.datePipe.transform(this.fecha2inicioAtrazada, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia

  }

  DiasAtrazado(event: MatSelectChange) {


    this.DiaAtrazado = event.value;

    if (this.DiaAtrazado == 1) {


      this.render = 1;
    } else {
      this.render = 0;
    }


  }
  Obtenersueldo(event: MatSelectChange) {


    this.sueldo = event.value;



    if (this.sueldo == "SI") {


      this.si = 1;


    }

    else {

      this.si = 0;
    }


  }





  Obtenerbono(event: MatSelectChange) {


    this.bono = event.value;



    if (this.bono == "SI") {


      this.no = 1;


    }

    else {

      this.no = 0;
    }


  }


  /////————— (👍≖‿‿≖)👍  DATOS EN ELMODAL   👍(≖‿‿≖👍)   ————-\\\\\\

  EditarDatos(content: any, id: any) {
    forkJoin([this.asistenciaService.TraerTrabajadores(), this.asistenciaService.TraerPorId(id)])
      .pipe(
        map(([result1, result2]) => ({ trabajadores: result1, asistencia: result2 })),
        catchError(error => of(error)),
      )
      .subscribe(res => {
        const trabajadores = res.trabajadores;
        const asistencia = res.asistencia;

        if (asistencia.success) {
          this.asistencia = asistencia.data;

          this.trabajador = this.asistencia.Trabajador;

          this.trabajadores_aux = trabajadores.data
            .map((x) => ({ value: x.rut, text: x.nombres.toUpperCase() + ' ' + x.apellidos.toUpperCase() }))
            .sort((a, b) => a.text.localeCompare(b.text));
            
          this.render = this.asistencia.Razon.esAtraso ? 1 : 0;

          if (this.asistencia.descuento_sueldo == "SI") {

            this.si = 1;

          } else {
            this.si = 0;

          }
          if (this.asistencia.descuento_bono == "SI") {

            this.no = 1;

          } else {
            this.no = 0;

          }

          this.fechaDesdeHasta = new FormGroup({
            start: new FormControl(moment(this.asistencia.start + 'T00:00:00')),
            end: new FormControl(moment(this.asistencia.end + 'T00:00:00')),
          })
          
          const timeStartArray: string[] = this.asistencia.timeStart ? this.asistencia.timeStart.split(':') : [];
          const timeEndArray: string[] = this.asistencia.timeEnd ? this.asistencia.timeEnd.split(':') : [];

          if (timeStartArray.length > 0) {
            this.timeStart = { hour: parseInt(timeStartArray[0]), minute: parseInt(timeStartArray[1]), second: parseInt(timeStartArray[2]) };
            this.timeEnd = { hour: parseInt(timeEndArray[0]), minute: parseInt(timeEndArray[1]), second: parseInt(timeEndArray[2]) };
          }

          this.asistenciaService.lista_razon().subscribe((res) => {
              this.lista_razones = {};
              this.lista_razones = res.data;
            }
          );

          this.modalService.open(content, { size: 'lg' });

        } else {
          this.alert.error_small(res.msg!)
        }
      }
      );

    // this.ngOnInit();
  }


  /////————— (👍≖‿‿≖)👍  CRUD   👍(≖‿‿≖👍)   ————-\\\\\\




  Actualizar() {
    if (this.trabajador === undefined) {
      this.alert.error_small('Seleccione un trabajador.');
      return;
    }


    if (this.asistencia.descuento_bono === "NO") {


      this.asistencia.tbono = 0;


    }

    if (this.asistencia.descuento_sueldo === "NO") {


      this.asistencia.tsueldo = 0;


    }


    if (this.render == 1) {

      if (this.timeStart <= this.timeEnd) {
        this.calcularDiasFaltados();
        const modelFecha = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day, 0, 0, 0, 0).toISOString();
        this.asistencia.start = modelFecha;
        this.asistencia.end = modelFecha;
        this.asistencia.timeStart = `${this.timeStart.hour}:${this.timeStart.minute}:00`;
        this.asistencia.timeEnd = `${this.timeEnd.hour}:${this.timeEnd.minute}:00`;
        this.asistencia.razon = this.asistencia.razon;

        delete this.asistencia.Razon;
        delete this.asistencia.Trabajador;

        this.asistenciaService.ActualizarAsistencia(this.asistencia).subscribe(res => {
          //si es asi se cnfirma la actualizacion de fechas
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.modalService.dismissAll();

            this.TraerDatos();

          } else {
            console.error(res.msg)
          }
        })
      }

    } else {
      this.calcularDiasFaltados();
      this.asistencia.start = this.datePipe.transform(this.fechaDesdeHasta.value.start, 'yyyy-MM-dd');
      this.asistencia.end = this.datePipe.transform(this.fechaDesdeHasta.value.end, 'yyyy-MM-dd');
      this.asistencia.timeStart = '00:00:00';
      this.asistencia.timeEnd = '00:00:00';
      this.asistencia.razon = this.asistencia.razon;

      this.asistenciaService.ActualizarAsistencia(this.asistencia).subscribe(res => {
        //si es asi se cnfirma la actualizacion de fechas
        if (res.success == true) {

          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();

          this.TraerDatos();
        } else {
          this.alert.error_small(res.msg!)
        }
      })
    }
  }

  ObtenerTrabajador(event: MatSelectChange) {
    const trabajador = event.value;
    this.trabajadorService.TraerTrabajadoresRut(trabajador.value).subscribe(res => {
      if (res.success) {
        this.trabajador = res.data;
        this.dias_trabajados_semana = this.trabajador.dias_semana;
      } else {
        this.alert.error_small('Ocurrio un error al obtener el trabajador.');
        console.error(res.msg)
      }
    });
    this.asistencia.trabajador = trabajador.value;
  }

  Eliminar(id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el gestor de asistencia",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.asistenciaService.Eliminar(id).subscribe(res => {
          if (res.success == true) {
            this.TraerDatos();
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        });
      }
    })

  }


  async TraerDatos() {
    const resAsistencia = await this.asistenciaService.ObtenerTodoAsync();
    if (resAsistencia.success) {
      this.asistencias = resAsistencia.data.sort((a: Asistencia, b: Asistencia) => 
        new Date(b.start+'T00:00:00').getTime() - new Date(a.start+'T00:00:00').getTime());

      const resTrabajador = await this.trabajadorService.TraerTrabajadoresAsync();
      if (resTrabajador.success) {
        const trabajadores: any[] = resTrabajador.data;
        // CALCULO LOS DIAS PARA MOSTRAR EN LA TABLA
        this.calcularDias(trabajadores);
        // INICIALIZO EL FILTER CARGANDO LOS DROPDOWS
        this.FiltrarDatos();
        // console.log({...this.trabajadoresFilter}, {...this.motivosFilter}, {...this.razonesFilter})
      }else{
        console.error(resTrabajador.msg)
        this.FiltrarDatos();
      }
      // this.asistencias.start = this.datePipe.transform(this.asistencias[0].start, 'dd-MMM-yyyy');
      // this.asistencias.end = this.datePipe.transform(this.asistencias[0].end, 'dd-MMM-yyyy');
      //console.table(this.asistencias);
    }else{
      this.alert.error_small(resAsistencia.msg);
    }
  }

  inicializarTabla(asistencias:Asistencia[]){
    console.log({asistencias});
    this.dataSource = new MatTableDataSource(asistencias);
    this.dataSource.filterPredicate = (data:any, filter: string) => {
      const filterLower = filter.toLocaleLowerCase().split(' ');

      const nombre = data.Trabajador.nombres.toLowerCase() 
        + ' ' + data.Trabajador.apellidos.toLowerCase();
      const razon = data.Razon.razon.toLowerCase();
      const motivo = data.motivo.toLowerCase();
      
      return filterLower
        .every(x => 
          nombre.includes(x) || 
          razon.includes(x) || 
          motivo.includes(x));
    }
    this.dataSource.paginator = this.paginator
    this.debouncer.next(true);
  }
  configurarFilter(asistencias: any[]) {
    const trabajadoresFilter = asistencias.map(
      x => ({value: x.Trabajador.nombres.toUpperCase() + ' ' + x.Trabajador.apellidos.toUpperCase()})
    ).sort(
      (a, b) => a.value.localeCompare(b.value)
    );
    const razonesFilter = asistencias.map(x => ({value: x.Razon.razon.toUpperCase()}))
    .sort((a,b) => a.value.localeCompare(b.value));

    this.trabajadoresFilter = this.eliminarDuplicados(trabajadoresFilter);
    this.razonesFilter = this.eliminarDuplicados(razonesFilter);
  }

  FiltrarDatos(){
    // console.log(this.periodoSelect, this.trabajadorSelect, this.razonSelect)
    let asistenciaFilter = this.asistencias;
    if (this.periodoSelect) {
      asistenciaFilter = asistenciaFilter.filter((x:Asistencia) => {
        const fechaStart = new Date(x.start+'T00:00:00');
        return fechaStart.getMonth() === this.periodoSelect.getMonth() 
          && fechaStart.getFullYear() === this.periodoSelect.getFullYear();
      });
    }
    if (this.trabajadorSelect && this.trabajadorSelect !== '') {
      console.log('trabajador select: ', this.trabajadorSelect);
      asistenciaFilter = asistenciaFilter.filter((x:any) => {
        const nombreTrabajador = x.Trabajador.nombres.toUpperCase() 
          + ' ' + x.Trabajador.apellidos.toUpperCase();
        return nombreTrabajador === this.trabajadorSelect;
      });
    }
    if (this.razonSelect && this.razonSelect !== '') {
      console.log('razon select: ', this.razonSelect);
      asistenciaFilter = asistenciaFilter.filter((x:any) => x.Razon.razon === this.razonSelect);
    }
    this.configurarFilter(asistenciaFilter);
    this.inicializarTabla(asistenciaFilter);
  }
  RestablecerFiltro(){
    this.trabajadorSelect = null;
    this.razonSelect = null;
    this.periodoSelect = new Date();
    this.FiltrarDatos();
  }

  eliminarDuplicados(array: any[]) {
      const conjuntoDeValores = new Set();
      return array.filter(objeto => {
        const valor = objeto['value'];
        if (!conjuntoDeValores.has(valor)) {
            conjuntoDeValores.add(valor);
            return true;
        }
        return false;
      }
    );
  }

  calcularDias(trabajadores: any[]) {
    this.asistencias.map((x:any) => {
      const trabajador = trabajadores.find(trabajador => trabajador.rut === x.fk_trabajador);
      // SI EXISTE DATO EN DIAS FALTADOS, NO CONTAR LOS DIAS
      // SI NO EXISTE NINGUN TRABAJADOR, DEVOLVER
      if (x.dias_faltados || !trabajador) return x;

      const interval: Interval = {
        start: new Date(x.start+'T00:00:00'),
        end: new Date(x.end+'T00:00:00')
      }

      // console.log({interval});
  
      const days = eachDayOfInterval(interval);
      x.dias_faltados = days.length;
      // this.dias_trabajados_semana = trabajador.dias_semana??0;
      // if (this.dias_trabajados_semana === 0 || this.dias_trabajados_semana === 5) {
      //   const workingDays = days.filter((day) => !this.holidays.isHoliday(day) && !isWeekend(day));
      //   x.dias_faltados = workingDays.length
      // } else if (this.dias_trabajados_semana === 6) {
      //   const workingDays = days.filter((day) => !this.holidays.isHoliday(day) && !isWeekend(day) || day.getDay() === 6 && !this.holidays.isHoliday(day));
      //   x.dias_faltados = workingDays.length
      // }
      return x;
    });
  }
  //FITRAL
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  calcularDiasFaltados() {
    switch (this.render) {
      case 1:
        this.calcularDias_Atrasos();
        break;
      case 0:
        this.calcularDias_NoAtrasos();
        break;
      default:
        console.error('HUBO UN ERROR CON CODIGO RENDER.');
        break;
    }
  }

  calcularDias_Atrasos() {
    // console.log({...this.modelFecha}, {...this.timeStart}, {...this.timeEnd});
    const modelDateStart = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day, this.timeStart.hour, this.timeStart.minute, this.timeStart.second, 0);
    const modelDateEnd = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day, this.timeEnd.hour, this.timeEnd.minute, this.timeEnd.second, 0);

    const hours = differenceInHours(modelDateEnd, modelDateStart);
    const minutes = differenceInMinutes(modelDateEnd, modelDateStart) % 60;

    const result = (hours + minutes / 60) / 10;
    this.asistencia.dias_faltados = parseFloat(result.toFixed(2));
  }

  calcularDias_NoAtrasos() {
    const interval: Interval = {
      start: this.fechaDesdeHasta.value.start._d,
      end: this.fechaDesdeHasta.value.end._d
    }

    // console.log({interval});

    const days = eachDayOfInterval(interval);
    return days.length;

    // if (this.trabajador.dias_semana === 5 || this.trabajador.dias_semana === null || this.trabajador.dias_semana === undefined) {
    //   const workingDays = days.filter((day) => !this.holidays.isHoliday(day) && !isWeekend(day));
    //   this.asistencia.dias_faltados = workingDays.length
    //   console.log({ workingDays });
    // } else if (this.trabajador.dias_semana === 6) {
    //   const workingDays = days.filter((day) => !this.holidays.isHoliday(day) && !isWeekend(day) || day.getDay() === 6 && !this.holidays.isHoliday(day));
    //   this.asistencia.dias_faltados = workingDays.length
    // }
  }

  prevMonth(){
    const periodo = this.periodoSelect;
    this.periodoSelect = new Date(periodo.getFullYear(), periodo.getMonth() - 1, 1, 0, 0, 0, 0);
    this.FiltrarDatos();
  }
  afterMonth(){
    const periodo = this.periodoSelect;
    this.periodoSelect = new Date(periodo.getFullYear(), periodo.getMonth() + 1, 1, 0, 0, 0, 0);
    this.FiltrarDatos();
  }
  verificarRol(): boolean {
    if (this.user?.rol !== 'ADMIN' && this.user?.rol !== 'ASISTENCIA') {
      alert('No tiene permisos para realizar esta acción');
      return false;
    }
    return true;
  }


  /////—————  AL INICIAR EL MODULO   ————-\\\\\\

  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
    this.TraerDatos();
  }

}



