<div class="row">
    <div class="col col-12">
        <div class="container-formulario">
            <!-- SELECT EMPRESAS -->
            <mat-form-field appearance="outline" class="formulario-empresa">
                <mat-label>Empresa</mat-label>
                <mat-select required >
                    <mat-option *ngFor="let empresa of empresas" value="{{empresa.id}}" (click)="selectEmpresa(empresa)">
                        {{ empresa.run }} - {{empresa.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col col-12">
        <!-- FORMULARIO -->
        <div class="container-formulario" *ngIf="opciones_elegidas.empresa">
            <!-- SELECT PERIODO AÑO -->
            <mat-form-field appearance="outline" class="formulario">
                <mat-label>Año declaratorio</mat-label>
                <input matInput [matDatepicker]="dp" [formControl]="date" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event, dp)"
                panelClass="example-month-picker">
                </mat-datepicker>
            </mat-form-field>
            <!-- SELECT FORMATO -->
            <mat-form-field appearance="outline" class="formulario">
                <mat-label>Formato</mat-label>
                <mat-select required [(value)]="opciones_elegidas.formato">
                    <mat-option *ngFor="let formato of formatos" value="{{formato}}" (click)="selectFormato(formato)">
                        {{formato}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SELECT TIPO DECLARACION -->
            <mat-form-field appearance="outline" class="formulario" *ngIf="viewTipoDeclaracion">
                <mat-label>Tipo de declaración</mat-label>
                <mat-select required [(value)]="opciones_elegidas.tipo_declaracion">
                    <mat-option *ngFor="let tipo of tipos_declaraciones" value="{{tipo}}" (click)="selectTipo(tipo)">
                        {{tipo}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- SELECT TIPO ARCHIVO -->
            <mat-form-field appearance="outline" class="formulario" *ngIf="viewTipoArchivo">
                <mat-label>Tipo de archivo</mat-label>
                <mat-select required [(value)]="opciones_elegidas.tipo_archivo">
                    <mat-option *ngFor="let tipo of tipos_archivos" value="{{tipo}}" (click)="selectArchivo(tipo)">
                        {{tipo}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- INPUT FOLIO ANTERIOR -->
            <mat-form-field appearance="outline" class="formulario" *ngIf="viewFolioAnterior">
                <mat-label>Folio anterior</mat-label>
                <input type="number" name="opciones_elegidas.folio_anterior" matInput [(ngModel)]="opciones_elegidas.folio_anterior" required />
            </mat-form-field>
            <!-- INPUT RUT ANTERIOR -->
            <mat-form-field appearance="outline" class="formulario" *ngIf="viewRutAnterior">
                <mat-label>RUT Anterior</mat-label>
                <input type="text" name="opciones_elegidas.rut_anterior" matInput [(ngModel)]="opciones_elegidas.rut_anterior" (ngModelChange)="formatoRut('rut_anterior')" maxlength="12" required />
            </mat-form-field>
        </div>
        <div class="col col-12">
            <!-- BOTON -->
            <div class="container-formulario" *ngIf="opciones_elegidas.empresa">
                <button 
                (click)="verificarEstadoDatos()" 
                class="btn btn-primary"
                [disabled]="disabledButton">
                    Generar
                </button>
            </div>
        </div>
    </div>
</div>