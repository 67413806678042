<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo de Empresas</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content">
      <app-agregar-empresas
        (onDebounce)="actualizarTabla($event)"
        [list_ciudades]="list_ciudades_main"
      ></app-agregar-empresas>
      <br />
      <app-lista-empresas
        (onDebounce)="actualizarTabla($event)"
        [list_ciudades]="list_ciudades_main"
        [empresas_List]="empresas"
      ></app-lista-empresas>
    </mat-card>
    <hr />
  </div>
  