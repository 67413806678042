<div class="row" *ngIf="loading;else contratos">
    <div class="col-12">
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="text-700 text-center">
                <div class="text-900 font-bold text-5xl mb-3">Cargando anexos de contrato por vencer...</div>
                <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
            </div>
        </div>
    </div>
</div>
<ng-template #contratos>
    <div class="col col-12" *ngIf="contratosPorVencer.length > 0; else without_contract">
        <mat-form-field appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
        <table mat-table [dataSource]="dataSourceContratos" matSort #tabla
            class="mat-elevation-z8" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="rut">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
                <td mat-cell *matCellDef="let element"> {{element.rut | formatoRut }} </td>
            </ng-container>
            <ng-container matColumnDef="nombreTrabajador">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TRABAJADOR </th>
                <td mat-cell *matCellDef="let element"> {{element.nombreTrabajador | uppercase}} </td>
            </ng-container>
            <ng-container matColumnDef="empresa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA </th>
                <td mat-cell *matCellDef="let element"> {{element.empresa | uppercase}} </td>
            </ng-container>
            <ng-container matColumnDef="fechaTermino">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA TERMINO </th>
                <td mat-cell *matCellDef="let element"> {{element.fechaTermino}} </td>
            </ng-container>
            <ng-container matColumnDef="diffDays">
                <th mat-header-cell *matHeaderCellDef> DIAS RESTANTES </th>
                <td mat-cell *matCellDef="let element">
                    <span [ngClass]="{'text-center text-danger fw-bolder': element.diffDays === 0}" *ngIf="element.diffDays >= 0">{{element.diffDays}}</span>
                    <span class="bg-danger text-center text-light p-2 rounded" *ngIf="element.diffDays < 0">Vencido</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    
        <mat-paginator [length]="contratosPorVencer.length" [pageSizeOptions]="[5, 10, 20]" aria-label="Seleccionar pagina de contratos." showFirstLastButtons>
        </mat-paginator>
    </div>
</ng-template>
<ng-template #without_contract>
    <p-card header="Sin contratos" class="w-100">
        <mat-icon>
            <span class="material-symbols-outlined">
              assignment_turned_in
            </span>
        </mat-icon>
        No existe ningún anexo de contrato por vencer.
    </p-card>
</ng-template>