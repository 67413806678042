<div class="animate__animated animate__fadeInRight">
    <div>
        <div class="col">
            <mat-card class="mat_card_trabajadores">
                <h1 class>Modulo de Anexos</h1>
            </mat-card>
        </div>
    </div>
</div>
<div class="col">
    <p-card>
        <div class="animate__animated animate__fadeInRight">
            <app-generar-anexo-contrato></app-generar-anexo-contrato>
        </div>
    </p-card>
</div>