<div class="col">
  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Cargo</mat-label>
    <mat-select (selectionChange)="selectedValue($event)" name="requisitos.id_cargo" [(ngModel)]="id_cargo">
      <mat-option *ngFor="let cargo of list_cargos" value="{{ cargo.id }}">
        {{ cargo.nombre }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>   
<div class="col" *ngIf="id_cargo >0">
  &nbsp;&nbsp;&nbsp;
  <button mat-button class="button_color_primary"  (click)="crearPerfil()">
    <span style="color: white"> </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
  &nbsp;&nbsp;&nbsp;
  
  <button *ngIf="render" mat-button class="button_color_primary" (click)="cancelaradd()">
    <span style="color: white"></span>
    <mat-icon style="color: white">clear</mat-icon>
  </button>
  &nbsp;&nbsp;&nbsp;

  <button *ngIf="nuevo_perfil?.length >3" mat-button class="button_color_primary" (click)="guardarPerfil()">
    <span style="color: white"></span>
    <mat-icon style="color: white">save</mat-icon>
  </button>


  <div class="col" *ngIf="render">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Nuevo Antecedente</mat-label>
      <input
        type="text"
        maxlength="500"
        minlength="3"
        matInput
        name="nuevo_perfil"
        [(ngModel)]="nuevo_perfil"
      /> 
    </mat-form-field>

  </div>

  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Perfiles</mat-label>
    <mat-select (selectionChange)="selectedPerfil($event)"  name="perfiles_id" [(ngModel)]="id_perfiles">
      <mat-option *ngFor="let perfil of list_perfiles" value="{{ perfil.id }}">
        {{ perfil.tipo }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>  

<div *ngIf="id_perfiles >0"> 
    <button mat-button class="button_color_primary" (click)="open(content)" >
      <span style="color: white">Agregar Antecedentes mínimos </span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
</div>
        <app-lista-antecedentes
        (onDebounce)="actualizarTabla($event)"
        [list_requisitos]="list_requisitos"
      ></app-lista-antecedentes>


      <ng-template style="border: 0px; z-index: 100" #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Agregar nuevo Antecedente</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>    
        <div class="modal-body">
          <form>
            <div class="row">
            <div class="col">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Descripcion del Antecedente</mat-label>
                <textarea type="textarea" rows="7" maxlength="1000" matInput name="descripcion_nueva" [(ngModel)]="nuevo_descripcion"></textarea>
              </mat-form-field>
            </div>                              
            </div>                
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">Cancelar</span>
          </button>
          <button type="button" class="btn btn-outline-success" (click)="agregar()">
            Guardar
          </button>
        </div>
      </ng-template>
      