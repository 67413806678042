import { Component, OnInit } from '@angular/core';
import { CargoService } from 'src/app/pages/services/cargo.service';
import { RequisitoService } from 'src/app/pages/services/requisitos.service';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { Requisito } from 'src/app/shared/models/requisitos.interface';

@Component({
  selector: 'app-main-funciones',
  templateUrl: './main-funciones.component.html',
  styleUrls: ['./main-funciones.component.css']
})
export class MainFuncionesComponent implements OnInit {

  requisitos: Requisito[] = [];
  list_cargos_main: Cargo[] = [];
  cargos : any[] = [];

  constructor(    
    private cargoService: CargoService,
    private requisitosService: RequisitoService
  ) { }

  ngOnInit(): void {
    this.cargoService.GetAll_cargos().subscribe(
      (res) => {                           
        this.list_cargos_main = res.data;          
      }
    );                  
    }    
    


}
