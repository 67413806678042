import { LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MaterialModule } from 'src/app/material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { AgregarPrestamosComponent } from './agregar-prestamos/agregar-prestamos.component'
import { ListaPrestamosComponent } from './lista-prestamos/lista-prestamos.component'
import { MainPrestamosComponent } from './main-prestamos/main-prestamos.component'
import { registerLocaleData } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';
import { FileUploadModule } from 'ng2-file-upload'
import { HelperModule } from 'src/app/helper.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { FormCuotasComponent } from './form-cuotas/form-cuotas.component';


registerLocaleData(localeEsCL, 'es-CL');

@NgModule({
  declarations: [
    AgregarPrestamosComponent, 
    ListaPrestamosComponent, 
    MainPrestamosComponent, FormCuotasComponent
  ],
  
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatCardModule,
    MatDatepickerModule,        
    MatNativeDateModule,
    ReactiveFormsModule,
    HelperModule,
    PrimeNgModule,
    AutonumericModule
  ],

  exports: [
    AgregarPrestamosComponent, 
    ListaPrestamosComponent, 
    MainPrestamosComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-CL" },
  ]
})
export class PrestamosModule { }
