import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { contratosPorVencerTotal } from 'src/app/shared/models/home-contratoPorVencer.interface';
import { ContratoService } from '../../services/contratos/contrato.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-contratos-anexos-por-vencer',
  templateUrl: './contratos-anexos-por-vencer.component.html',
  styleUrls: ['./contratos-anexos-por-vencer.component.css']
})
export class ContratosAnexosPorVencerComponent implements OnInit {

  @Input() cargarTabla: boolean;
  @Output() countAnexosVencer = new EventEmitter<number>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  dataSourceContratos: any;
  displayedColumns: string[] = ['rut', 'nombreTrabajador', 'empresa', 'fechaTermino', 'diffDays'];
  loading: boolean = true; // SIRVE PARA CONTROLAR LOS SPINNERS DE CARGA

  contratosPorVencer: contratosPorVencerTotal[] = [];

  constructor(
    private contratoService: ContratoService,
    private _liveAnnouncer: LiveAnnouncer,

  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.contratoService.$anexosPorVencer.subscribe(contratos => {
      this.countAnexosVencer.emit(contratos.length);
      this.contratosPorVencer = contratos.sort(
        (a: contratosPorVencerTotal, b: contratosPorVencerTotal) => a.diffDays - b.diffDays);
      this.cargarDatosTablaContratos();
    });
  }

  cargarDatosTablaContratos() {
    this.dataSourceContratos = new MatTableDataSource(this.contratosPorVencer);

    this.dataSourceContratos.paginator = this.paginator;
    // CONFIGURA EL FILTRADO DE DATOS
    this.dataSourceContratos.filterPredicate = (data: contratosPorVencerTotal, filter: string) => {
      return data.nombreTrabajador.toLowerCase().includes(filter.toLowerCase())
        || data.empresa.toLowerCase().includes(filter.toLowerCase())
        || data.rut.toString().toLowerCase().includes(filter.toLowerCase());
    };
    this.dataSourceContratos.sort = this.sort;
    this.loading = false;
    // console.log(this.dataSourceContratos.sort, this.dataSourceContratos.paginator);
  }

  // ORDENA LOS DATOS
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceContratos.filter = filterValue.trim().toLowerCase();
  }

}
