import { Component, OnInit } from '@angular/core';
import { SolicitudDespidoService } from 'src/app/pages/services/solicitud/solicitud-despido.service';

@Component({
  selector: 'app-main-solicitud-despido',
  templateUrl: './main-solicitud-despido.component.html',
  styleUrls: ['./main-solicitud-despido.component.css']
})
export class MainSolicitudDespidoComponent implements OnInit {
  despidos: any;
  dataSource: any;
  


  constructor(
    private solicitudService: SolicitudDespidoService,

  ) { }

  ngOnInit(): void {
    this.cargarTabla();
  }

  cargarSolicitud(){
    this.cargarTabla();
  }

  cargarTabla() {
    this.solicitudService.traerInfo().subscribe((res: any) => {
      if (res.success) {
        this.despidos = res.data;
        console.log(this.despidos);
  
        // Iterar sobre los despidos y cambiar enviado a true solo si el estado es 1
        this.despidos.map(despido => {
          if (despido.estado === 1) {
            despido.enviado = true;
          }else{
            despido.enviado = false;
          }
          return despido;
        });
      }
    });
  }

  

}
