import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainVacacionesComponent } from './main-vacaciones/main-vacaciones.component';
import { AgregarVacacionesComponent } from './agregar-vacaciones/agregar-vacaciones.component';
import { ListarVacacionesComponent } from './listar-vacaciones/listar-vacaciones.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from 'src/app/material.module';
import { GenerarVacacionesComponent } from './generar-vacaciones/generar-vacaciones.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { HelperModule } from 'src/app/helper.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';


@NgModule({
  declarations: [MainVacacionesComponent, AgregarVacacionesComponent, ListarVacacionesComponent, GenerarVacacionesComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PdfViewerModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    CalendarModule,
    SelectButtonModule,
    HelperModule,
    AutonumericModule,
    BuscarFilterModule,
  ],

  exports: [
    AgregarVacacionesComponent,
    MainVacacionesComponent,
    ListarVacacionesComponent,
  ],
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' } //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
                                                    // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 
  ]
})
export class VacacionesModule { }
