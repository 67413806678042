<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput  (keyup)="applyFilter($event)" placeholder="texto a buscar" #input>
</mat-form-field>

<div class="mat-elevation-z8">
<table mat-table [dataSource]="dataSource" matSort >

    <ng-container matColumnDef="Fecha_inicio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Incio </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.fechaInicio}} </td>
    </ng-container>
    
    <ng-container matColumnDef="Fecha_termino">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Termino</th>
        <td  class="padding-table" mat-cell *matCellDef="let element"> {{element.fechaTermino}} </td>
    </ng-container>

    <ng-container matColumnDef="Motivo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Motivo</th>
        <td mat-cell *matCellDef="let element"> {{ element.motivo}} </td>
    </ng-container>

    <ng-container matColumnDef="Estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.estado === 'SUBIDA'" style="color: grey">{{element.estado}}</span>
            <span *ngIf="element.estado === 'TRAMITADA'" style="color: green">{{element.estado}}</span>
            <span *ngIf="element.estado === 'DENUNCIADA'" style="color: red"> TRAMITADA Y {{element.estado}}</span>

            
        </td>
    </ng-container>

    <ng-container matColumnDef="UpdateAT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> U. modificacion</th>
        <td mat-cell *matCellDef="let element"> {{ element.updatedAt | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    
    <ng-container matColumnDef="Acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td class="padding-table" mat-cell *matCellDef="let element">
            <button (click)="Descargar(element.id)" mat-button class="button_color_primary" style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">search</mat-icon>
            </button>
            <button (click)="Borrar(element.id)" mat-raised-button color="warn">
                <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
                <mat-icon style="color: white" *ngIf="loading">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </button>
            &nbsp;
            
            <!-- Boton para visualizar el archivo-->
            <button 
            *ngIf="element.estado === 'TRAMITADA' || element.estado === 'DENUNCIADA' "
             (click)="DescargarComprobante(element.id)"
             mat-button
             style="background-color: green"
            >
            <mat-icon style="color: white">assignment_turned_in</mat-icon>
            </button>
            &nbsp;
            <button 
            *ngIf=" element.estado === 'DENUNCIADA' "
             (click)="DescargarDenuncia(element.id)"
             mat-button
             style="background-color: red"
            >
            <mat-icon style="color: white">assignment_turned_in</mat-icon>
            </button>
            &nbsp;
            <!-- Boton para subir Comprobante de tramitada, solo se vera si el estado es subida (no se ha realizado el tramite)-->
            <button 
            *ngIf="element.estado === 'SUBIDA'"
            (click)="open(content,element.id)"
             mat-button
             style="background-color:green"
            >
            <mat-icon style="color: white">file_upload</mat-icon>
            </button>
            &nbsp;
            <!-- Boton para subir Comprobante de denunciada, solo se vera si el estado es subida (no se ha realizado el tramite)-->
            <button 
            *ngIf="element.estado === 'SUBIDA' || element.estado === 'TRAMITADA' "
            (click)="open2(content2,element.id)"
             mat-button
             style="background-color:red"
            >
            <mat-icon style="color: white">file_upload</mat-icon>
            </button>
            &nbsp;



        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator
[pageSizeOptions]="[10, 25, 50, 100, 1]"
showFirstLastButtons
aria-label="Seleccionar pagina de Liquidaciones."
>
</mat-paginator>
</div>



<!-- MODAL PARA subir comprobante de tramite de licencia y asi cambiar TRAMITADA al estado-->
<ng-template style="border: 0px; z-index: 100" #content let-modal> 
      
    <div class="modal-header" style="text-align: center">
        <h3> Cargar Comprobante de Tramitacion de Licencia</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    
    <div class="modal-body">

        <!-- <table class="example-full-width" cellspacing="0">
            <tr>

                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">  
                       <mat-label>Estado Tramitacion Licencia</mat-label>                 
                         <mat-select  [(value)]="tramite.estado" placeholder="Tipo de Haber" required>
                           <mat-option *ngFor="let opcion of opciones" [value]="opcion" >
                               {{ opcion }}
                           </mat-option>
                       </mat-select>
                    </mat-form-field>
                 </div>
            </tr>


        </table> -->

        <p>
           <label>Subir Comprobante de Tramite  &nbsp;</label>
            <input mat-input  required type="file" ng2FileSelect [uploader]="uploader"  />
        </p>

        <div>
            Estado de Carga
            <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
        </div>


    </div>
    <!--END Modal Body-->
    <!-- Modal Footer-->
    <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()"> <span aria-hidden="true">Cancelar</span> </button>       
    <button type="button" (click)="guardar()" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button>
        
    </div>
</ng-template>







<!-- MODAL PARA subir comprobante de tramite de licencia y asi cambiar DENUNCIADA al estado-->
<ng-template style="border: 0px; z-index: 100" #content2 let-modal> 
      
    <div class="modal-header" style="text-align: center">
        <h3> Cargar Comprobante de Denuncia de Licencia</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    
    <div class="modal-body">

        <!-- <table class="example-full-width" cellspacing="0">
            <tr>

                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">  
                       <mat-label>Estado Tramitacion Licencia</mat-label>                 
                         <mat-select  [(value)]="tramite.estado" placeholder="Tipo de Haber" required>
                           <mat-option *ngFo [value]="opcion" >
                               {{ opcion }}
                           </mat-option>
                       </mat-select>
                    </mat-form-field>
                 </div>
            </tr>


        </table> -->

        <p>
           <label>Subir Comprobante de Denuncia  &nbsp;</label>
            <input mat-input  required type="file" ng2FileSelect [uploader]="uploader"  />
        </p>

        <div>
            Estado de Carga
            <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
        </div>


    </div>
    <!--END Modal Body-->
    <!-- Modal Footer-->
    <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()"> <span aria-hidden="true">Cancelar</span> </button>       
    <button type="button" (click)="guardar()" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button>
        
    </div>
</ng-template>