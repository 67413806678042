<mat-toolbar class="header">
  <ng-container *ngIf="isLogged; else viewLogout"> <!-- *ngIf="isAdmin == 'TRABAJADOR'; else viewAdmin" ---->
    <div>
      <button mat-icon-button (click)="onToggleSidenav()">
        <mat-icon style="color: white">menu</mat-icon>
      </button>
    </div>
    <button mat-icon-button>
      <mat-icon class="color_icon" style="cursor: pointer; margin-left:10px; color: white"
        routerLink="/">home</mat-icon>
    </button>
    <span class="spacer"></span>
    <span *ngIf="nombreUsuario" style="margin-top: 20px; margin-right: 10px; margin-bottom: 20px; font-size:15px; color: white">{{
      nombreUsuario | uppercase }}
    </span>
    <button mat-icon-button style="margin-right: 10px; color: white;" [matMenuTriggerFor]="menu" #menuTrigger
      [ngClass]="{'animate__animated animate__swing animate__repeat-3': countContratos > 0}">
      <mat-icon [matBadge]="countContratos" matBadgeColor="warn" [matBadgeHidden]="countContratos === 0" pTooltip="Contratos por vencer" tooltipPosition="bottom">
        <span class="material-symbols-outlined">
          quick_reference
        </span>
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <div class="p-2">
        <mat-list>
          <div mat-subheader><b>Anexos de contrato, periodos de prueba y contratos a plazo fijo por vencer</b></div>
          <p-divider></p-divider>
          <mat-list-item *ngIf="countContratos === 0; else listItem">
            <div mat-line class="text-center">
              <span *ngIf="loading">Cargando...</span>
              <span *ngIf="!loading">Sin contratos.</span>
              <br>
              <mat-icon>
                <span *ngIf="loading" class="material-symbols-outlined animate__animated animate__rotateIn animate__infinite">
                  hourglass_bottom
                </span>
                <span *ngIf="!loading" class="material-symbols-outlined">
                  assignment_turned_in
                </span>
              </mat-icon>
            </div>
          </mat-list-item>
          <ng-template #listItem>
            <div>
              <h3 class="ml-3 mb-0">Hoy finaliza periodo de prueba de: </h3>
              <mat-list *ngIf="trabajadores.length > 0">
                <mat-list-item *ngFor="let trabajador of trabajadores">
                  <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                  <div mat-line><b>{{ trabajador.nombres.toUpperCase() + " " + trabajador.apellidos.toUpperCase() }}</b></div>
                </mat-list-item>
              </mat-list>
              <div *ngIf="trabajadores.length === 0" class="ml-3"><b>SIN FINALIZACIONES HOY</b></div>
            </div>
            <hr>
            <div>
              <h3 class="ml-3">Anexos de contrato a punto de vencer: </h3>
              <mat-list-item *ngFor="let aContrato of anexosContratos.slice(0, 5)">
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <div mat-line><b>{{aContrato.nombreTrabajador.toUpperCase()}}</b></div>
                <div mat-line *ngIf="aContrato.diffDays > 0"><b>Termino:</b> {{aContrato.fechaTermino}}<br><b>Dias Rest.: </b>{{aContrato.diffDays}}</div>
                <div mat-line *ngIf="aContrato.diffDays === 0"><b>Termino:</b> {{aContrato.fechaTermino}}<br><b>Dias Rest.: <span style="color: red;">{{aContrato.diffDays}}</span></b></div>
                <div mat-line *ngIf="aContrato.diffDays < 0"><b>Termino:</b> {{aContrato.fechaTermino}}<br><b>Dias Rest.: <span style="color: red;font-weight: bold;">VENCIDO</span></b></div>
              </mat-list-item>
            </div>
        <mat-list-item class="d-flex justify-content-center">
          <button (click)="navegarAnexosContratosPorVencer()" mat-raised-button>Ver mas contratos <span *ngIf="countContratos > 5">({{anexosContratos.length - 5}} mas)</span></button>
        </mat-list-item>
          <hr>
          <div>
            <h3 class="ml-3">Contratos a plazo fijo a punto de vencer: </h3>
            <mat-list-item *ngFor="let contrato of contratosPorVencer.slice(0, 5)">
              <mat-icon mat-list-icon>insert_drive_file</mat-icon>
              <div mat-line><b>{{contrato.nombreTrabajador.toUpperCase()}}</b></div>
              <div mat-line *ngIf="contrato.diffDays > 0"><b>Termino:</b> {{contrato.fechaTermino}}<br><b>Dias Rest.: </b>{{contrato.diffDays}}</div>
              <div mat-line *ngIf="contrato.diffDays === 0"><b>Termino:</b> {{contrato.fechaTermino}}<br><b>Dias Rest.: <span style="color: red;">{{contrato.diffDays}}</span></b></div>
              <div mat-line *ngIf="contrato.diffDays < 0"><b>Termino:</b> {{contrato.fechaTermino}}<br><b>Dias Rest.: <span style="color: red;font-weight: bold;">VENCIDO</span></b></div>
            </mat-list-item>
          </div>
            <p-divider></p-divider>
            <mat-list-item class="d-flex justify-content-center">
              <button (click)="navegarContratosPorVencer()" mat-raised-button>Ver mas contratos <span *ngIf="countContratos > 5">({{contratosPorVencer.length - 5}} mas)</span></button>
            </mat-list-item>
          </ng-template>
        </mat-list>
      </div>
    </mat-menu>
    <button mat-flat-button color="warn" (click)="onLogout()">
      SALIR
      <mat-icon>exit_to_app</mat-icon>
    </button>
    
    <!-- <div> 
      <a *ngIf="!isLogged; else viewLogout" mat-button routerLink="/login">Login</a>
    </div> -->
  </ng-container>
</mat-toolbar>

<ng-template #viewLogout>
  <span class="spacer"></span>
  <div>
    <span style="color: white">BIENVENIDO</span>
    <!-- mat-button routerLink="/login" -->
  </div>
</ng-template>