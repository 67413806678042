import { Component, OnInit } from '@angular/core';
import { PrevisionService } from 'src/app/pages/services/prevision.service';
import { Prevision } from 'src/app/shared/models/prevision.interface';

@Component({
  selector: 'app-main-prevision',
  templateUrl: './main-prevision.component.html',
  styleUrls: ['./main-prevision.component.css']
})
export class MainPrevisionComponent implements OnInit {

  prevision:Prevision[] = [];

  constructor(
    private previsionService: PrevisionService
  ) { }

  ngOnInit(): void {
    this.previsionService.GetAll_previsiones().subscribe(
      (res) => {        
        this.prevision = res.data;
      }
    );
  }
  
  actualizarTabla(event:boolean){
    this.previsionService.GetAll_previsiones().subscribe(res=>{
      this.prevision=res.data
    })
  }

}
