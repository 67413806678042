<mat-card class="mat-elevation-z5 mat_card_general_content">
    <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="texto a buscar" #input>
    </mat-form-field>
    
    <div class="mat-elevation-z8">    
        <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5px">ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
      
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Cargo</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.nombre | uppercase }}
    
            </td>
          </ng-container>
    
          <ng-container matColumnDef="jefe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Jefe</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.jefe | uppercase }}
            </td>
          </ng-container>
    
         <ng-container matColumnDef="lugar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lugar</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.lugar | uppercase }}
            </td>
          </ng-container>            
    
          <ng-container matColumnDef="jornada">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Jornada de Trabajo</th>
            <td class="padding-table" mat-cell *matCellDef="let element" >
              {{ element.jornada}}
            </td>
          </ng-container>
    
          <ng-container matColumnDef="dias">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dias</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.dias | uppercase }}
            </td>
          </ng-container>
    
          <ng-container matColumnDef="sucursal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sucursal</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.sucursales2.nombre| uppercase }}          
            </td>
          </ng-container>      
              
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              <button (click)="open(content,element.id,element.nombre)"
                mat-button class="button_color_primary">
                <mat-icon style="color: white">description</mat-icon>
              </button>       
            </td>        
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
          aria-label="Seleccionar pagina de usuarios."
        >
        </mat-paginator>
      </div>


      <ng-template style="border: 0px; z-index: 100" #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Perfil Ocupacional </h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-agregar-perfiles-ocupacionales [idCargo] ='id_cargo' [cargo_desc]='cargo_desc'>
            
          </app-agregar-perfiles-ocupacionales>
          
        </div>
        <div class="modal-footer">
        </div>
      </ng-template>
