<p-toolbar styleClass="bg-light">
    <div class="p-toolbar-group-left">
        <app-agregar-trabajador (onDebounce)="TraerDatos(true)"></app-agregar-trabajador>
        <p-divider layout="vertical"></p-divider>
        <button class="mr-2" style="color: white;background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button
            pTooltip="Exportar a PDF" tooltipPosition="bottom">
            <i class="pi pi-file-pdf" style="font-size: 1rem"></i>
        </button>
        <button style="color: white;background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button
            pTooltip="Exportar a Excel" tooltipPosition="bottom">
            <i class="pi pi-file-excel" style="font-size: 1rem"></i>
        </button>
        <p-dropdown placeholder="Todas las empresas" [options]="listaEmpresas" [filter]="true" filterBy="text" [(ngModel)]="empresaSelect" optionLabel="text"
        optionValue="value" class="ml-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body"></p-dropdown>
    </div>
    
    <div class="p-toolbar-group-right">
        <mat-checkbox class="mr-4" (change)="viewDatosFiniquitados()" [(ngModel)]="viewFiniquitados"
            color="warn">
            <span [matBadge]="num_trab_finiquitados <= 99 
            ? num_trab_finiquitados 
            : '+99'" matBadgeOverlap="false">Cargar finiquitados</span>
        </mat-checkbox>
        <mat-form-field appearance="outline" style="width: 300px;">
            <mat-label>Buscar</mat-label>
            <input matInput [(ngModel)]="textSearch" (keyup)="applyFilter()" #input>
        </mat-form-field>
    </div>
</p-toolbar>
<div class="overflow-auto table-responsive">
    <table *ngIf="trabajadoresFilter.length > 0" mat-table id="tabla" [dataSource]="dataSource" #tabla class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="rut" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{ element.rut | formatoRut }}
            </td>
        </ng-container>
        <ng-container matColumnDef="nombres" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE COMPLETO </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.nombres + ' ' + element.apellidos | uppercase}} </td>
        </ng-container>
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TELÉFONO </th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element"> {{ element.telefono}}</td>
        </ng-container>
        <ng-container matColumnDef="correo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CORREO </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{ element.correo | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="fecha_nacimiento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA NACIMIENTO</th>
            <td mat-cell *matCellDef="let element"> {{ element.fecha_nacimiento|date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="sexo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SEXO</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.sexo === undefined || element.sexo === null">S/D</span>
            <span *ngIf="element.sexo === 'M'">MASCULINO</span>
            <span *ngIf="element.sexo === 'F'">FEMENINO</span>
        </td>
        </ng-container>
        <ng-container matColumnDef="nacionalidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NACIONALIDAD</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.Nacionalidad.nombre | uppercase}}
            </td>
        </ng-container>
        <ng-container matColumnDef="profesion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PROFESIÓN </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.profesion | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="estado_civil">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO CIVIL</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.Estado_civil.nombre | uppercase}}
            </td>
        </ng-container>
        <ng-container matColumnDef="numero_cargas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> N° CARGAS</th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element"> {{element.numero_cargas}} </td>
        </ng-container>
        <ng-container matColumnDef="jubilado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> JUBILADO </th>
            <td class="mat-column-center" mat-cell *matCellDef="let element"> {{element.jubilado | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DIRECCIÓN </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.direccion | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="region">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> REGION </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.region | uppercase}} </td>
        </ng-container>
        <ng-container matColumnDef="ciudad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CIUDAD </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.Ciudad.nombre | uppercase}} </td>
        </ng-container>
        <ng-container matColumnDef="comuna">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> COMUNA </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.comuna | uppercase }} </td>
        </ng-container>
        <ng-container matColumnDef="patologias">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PATOLOGÍAS </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.patologias | uppercase}} </td>
        </ng-container>
        <ng-container matColumnDef="telefono_contacto_emergencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> N° CONTACTO <br> EMERGENCIA</th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element">
                {{element.telefono_contacto_emergencia}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre_contacto_emergencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE CONTACTO </th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.nombre_contacto_emergencia |
                uppercase}} </td>
        </ng-container>
        <ng-container matColumnDef="estado_contrato">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO CONTRATO</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element">
                <span
                    [ngClass]="{'bg-danger p-1 rounded text-light': element.estado_contrato === 'Finiquitado' || element.estado === 0}">{{element.estado_contrato
                    | uppercase}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="sucursal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SUCURSAL</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element">{{ element.Sucursal.nombre | uppercase}}
            </td>
        </ng-container>
        <ng-container matColumnDef="cargo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CARGO</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{ element.Cargo.nombre | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="empresa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element">{{ element.Empresa_contratante.nombre |
                uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="fecha_ingreso">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA INGRESO</th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_ingreso|date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="sueldo_base">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SUELDO BASE</th>
            <td mat-cell *matCellDef="let element"> {{element.sueldo_base ?
                element.sueldo_base.toLocaleString('es-CL') : element.sueldo_base}}</td>
        </ng-container>
        <ng-container matColumnDef="sueldo_liquido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SUELDO LIQUIDO</th>
            <td mat-cell *matCellDef="let element"> {{element.sueldo_liquido ?
                element.sueldo_liquido.toLocaleString('es-CL') : element.sueldo_liquido}}</td>
        </ng-container>
        <ng-container matColumnDef="afp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AFP</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element">{{ element.Afp.nombre | uppercase}} </td>
        </ng-container>
        <ng-container matColumnDef="prevision">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PREVISIÓN</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.Prevision.nombre | uppercase}}
            </td>
        </ng-container>
        <ng-container matColumnDef="pactado_isapre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PACTADO <br> ISAPRE (UF)</th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element"> {{element.pactado_isapre}}</td>
        </ng-container>
        <ng-container matColumnDef="tramo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TRAMO <br> ASIG. FAMILIAR</th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element" [ngSwitch]="element.tramoAsigFam">
                <div *ngSwitchCase="1">Tramo 1 (A)</div>
                <div *ngSwitchCase="2">Tramo 2 (B)</div>
                <div *ngSwitchCase="3">Tramo 3 (C)</div>
                <div *ngSwitchCase="4">Tramo 4 (D)</div>
                <div *ngSwitchDefault>Sin información</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="ahorro_apv">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AHORRO <br> APV</th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element"> {{element.ahorro_apv}}</td>
        </ng-container>
        <ng-container matColumnDef="banco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> BANCO</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.Banco.nombre | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="tipo_cuenta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO CUENTA</th>
            <td class="mat-column-left" mat-cell *matCellDef="let element"> {{element.tipo_cuenta | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="numero_cuenta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NUMERO CUENTA</th>
            <td class="mat-column-right td_numero" mat-cell *matCellDef="let element"> {{element.numero_cuenta}}</td>
        </ng-container>
        <ng-container matColumnDef="pase_movilidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PASE <br> MOVILIDAD</th>
            <td mat-cell *matCellDef="let element"> {{element.pase_movilidad | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="licencia_conducir">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> LICENCIA <br> CONDUCIR</th>
            <td mat-cell *matCellDef="let element"> {{element.licencia_conducir | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="talla_polera_poleron">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TALLA <br> POLERA</th>
            <td mat-cell *matCellDef="let element"> {{element.talla_polera_poleron | uppercase}}</td>
        </ng-container>
        <ng-container matColumnDef="talla_pantalon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TALLA <br> PANTALON</th>
            <td mat-cell *matCellDef="let element"> {{element.talla_pantalon}}</td>
        </ng-container>
        <ng-container matColumnDef="numero_calzado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> N° CALZADO</th>
            <td mat-cell *matCellDef="let element"> {{element.numero_calzado}}</td>
        </ng-container>
        <ng-container matColumnDef="tipo_jornada">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> TIPO JORNADA</th>
            <td mat-cell *matCellDef="let element"> {{element.tipo_jornada}}</td>
        </ng-container>
        <ng-container matColumnDef="discapacidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DISCAPACIDAD</th>
            <td mat-cell *matCellDef="let element"> {{element.discapacidad}}</td>
        </ng-container>
        <ng-container matColumnDef="afc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> S. CESANTIA</th>
            <td mat-cell *matCellDef="let element"> {{element.afc}}</td>
        </ng-container>
        <!-- <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ESTADO</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.estado == true" style="color: green">ACTIVO</span>
                <span *ngIf="element.estado == false" style="color: red">INACTIVO</span>
            </td>
        </ng-container> -->
        <ng-container matColumnDef="acciones" stickyEnd>
            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
            <td mat-cell *matCellDef="let element" class="mat-column-right">
                <button (click)="EditarDatos(content,element.rutDB)" mat-button class="button_color_primary mr-1">
                    <mat-icon style="color: white">edit</mat-icon>
                </button>
                <button (click)="Desabilitar_Habilitar(element.rutDB, !element.estado)"
                    mat-button [ngClass]="{
                        'p-0 bg-danger': !element.estado,
                        'p-0 bg-success': element.estado}">
                    <mat-icon *ngIf="!element.estado" style="color: white">block</mat-icon>
                    <mat-icon *ngIf="element.estado" style="color: white">done</mat-icon>
                </button>
                <button (click)="openUploadDialog(element.rutDB)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">upload</mat-icon>
                  </button>
                  <button (click)="openDocumentsModal(element.rutDB)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">list</mat-icon>
                  </button>
                <!-- <button (click)="Eliminar( element.rutDB)" mat-button class="button_color_primary" style="background-color:red">
                <mat-icon style="color: white">delete</mat-icon>
            </button> -->
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="getRecord(content, row)"
            class="tr_trabajador"></tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios">
</mat-paginator>

<!----------------------------------------   EL MODAL  -------------------------------------------->

<!-- <ng-template #content2 let-modal2>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Actualizar Trabajador</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <app-modal-editar [rut]="rut_trabajador" ></app-modal-editar>
    </div>

</ng-template> -->
<ng-template #content let-modal>
    <div class="modal-header">
        <mat-card-title>Actualizar Trabajador</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos personales</b>
                    </div>
                </p-divider>
            </div>
            <div class="col col-12 pt-0">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Rut</mat-label>
                    <input type="text" name="rut" matInput disabled [ngModel]="trabajador.rut | formatoRut" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombres</mat-label>
                    <input type="text" name="trabajador.nombres" matInput [(ngModel)]="trabajador.nombres"
                        required maxlength="150" minlength="3" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Apellidos</mat-label>
                    <input type="text" name="trabajador.apellidos" matInput
                        [(ngModel)]="trabajador.apellidos" required maxlength="150" minlength="3" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Teléfono Móvil</mat-label>
                    <input type="text" name="telefono" maxlength="12" matInput
                        [(ngModel)]="trabajador.telefono" required />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Correo</mat-label>
                    <input type="text" name="correo" minlength="10" maxlength="50" matInput
                        [(ngModel)]="trabajador.correo" required />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Fecha De Nacimiento</mat-label>
                    <input matInput [matDatepicker]="fecha_nacimiento" [min]="minDate" [max]="maxDate"
                        name="fecha_nacimiento" [(ngModel)]="trabajador.fecha_nacimiento " required
                        (click)="fecha_nacimiento.open()" (focus)="fecha_nacimiento.open()">
                    <mat-datepicker-toggle matSuffix [for]="fecha_nacimiento"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #fecha_nacimiento></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Nacionalidad</mat-label>
                    <mat-select [(value)]="nacionalidad_seleccionada"
                        (selectionChange)="ObtenerNacionalidad($event)" required>
                        <mat-option class="example-full-width" *ngFor="let nacionalidad of nacionalidades"
                            [value]="nacionalidad.id">
                            {{nacionalidad.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Sexo</mat-label>
                    <mat-select [(ngModel)]="trabajador.sexo" (selectionChange)="ObtenerSexo($event)" required>
                      <mat-option class="example-full-width " *ngFor="let sexo of sexos"
                        [value]="sexo.value">
                        {{sexo.text}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Profesión</mat-label>
                    <input type="text" matInput name="profesion" [(ngModel)]="trabajador.profesion" required
                        maxlength="150" minlength="3" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Estado Civil</mat-label>
                    <mat-select [(value)]="estado_civil_seleccionada"
                        (selectionChange)="ObtenerEstadoCivil($event)" required>
                        <mat-option class="example-full-width" *ngFor="let estado of estados"
                            [value]="estado.id">
                            {{estado.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Ciudad</mat-label>
                    <mat-select [(value)]="ciudad_seleccionada" (selectionChange)="ObtenerCiudad($event)" required>
                        <mat-option class="example-full-width" *ngFor="let ciudad of ciudades"
                            [value]="ciudad.id">
                            {{ciudad.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Seleccion Region</mat-label>
                    <mat-select [(value)]="trabajador.region" (selectionChange)="ObtenerRegion($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let region of region"
                            [value]="region.nombre ">
                            {{region.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- CIUDADES -->
            <div class="col-4" *ngIf=" renderciudad == 1">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna XV</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaXV($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaXV of comunaXV"
                            [value]=" comunaXV.nombre ">
                            {{comunaXV.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 2">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna I</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaI($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaI of comunaI"
                            [value]=" comunaI.nombre ">
                            {{comunaI.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 3">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna II</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaII($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaII of comunaII"
                            [value]=" comunaII.nombre ">
                            {{comunaII.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna III</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaIII($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaIII of comunaIII"
                            [value]=" comunaIII.nombre ">
                            {{comunaIII.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 5">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna IV</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaIV($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaIV of comunaIV"
                            [value]=" comunaIV.nombre ">
                            {{comunaIV.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 6">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna V</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaV($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaV of comunaV"
                            [value]=" comunaV.nombre ">
                            {{comunaV.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 7">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna XIII</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaXIII($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaXIII of comunaXIII"
                            [value]=" comunaXIII.nombre ">
                            {{comunaXIII.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 8">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna VI</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaVI($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaVI of comunaVI"
                            [value]=" comunaVI.nombre ">
                            {{comunaVI.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 9">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna VII</mat-label>
                    <mat-select [(value)]="trabajador.comuna"
                        (selectionChange)="ObtenerComunaVII($event)" required
                        placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaVII of comunaVII"
                            [value]=" comunaVII.nombre ">
                            {{comunaVII.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 10">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna XVI</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaXVI($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaXVI of comunaXVI"
                            [value]="comunaXVI.nombre ">
                            {{comunaXVI.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 11">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna VIII</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaVIII($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="example-full-width" *ngFor="let comunaVIII of comunaVIII"
                            [value]=" comunaVIII.nombre ">
                            {{comunaVIII.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 12">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna IX</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaIX($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="eXIample-full-width" *ngFor="let comunaIX of comunaIX"
                            [value]="comunaIX.nombre ">
                            {{comunaIX.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 13">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna XIV</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaXIV($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="eXIample-full-width" *ngFor="let comunaXIV of comunaXIV"
                            [value]="comunaXIV.nombre ">
                            {{comunaXIV.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 14">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna X</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaX($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="eXIample-full-width" *ngFor="let comunaX of comunaX"
                            [value]="comunaX.nombre ">
                            {{comunaX.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 15">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna XI</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaXI($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="eXIample-full-width" *ngFor="let comunaXI of comunaXI"
                            [value]="comunaXI.nombre ">
                            {{comunaXI.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4" *ngIf=" renderciudad == 16">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Comuna XII</mat-label>
                    <mat-select [(value)]="trabajador.comuna" (selectionChange)="ObtenerComunaXI($event)"
                        required placeholder="Selecciones Region">
                        <mat-option class="eXIample-full-width" *ngFor="let comunaXII of comunaXII"
                            [value]=" comunaXII.nombre ">
                            {{comunaXII.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Dirección</mat-label>
                    <input type="text" matInput name="direccion" [(ngModel)]="trabajador.direccion" required
                        maxlength="250" minlength="3" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Otros datos</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Patologías</mat-label>
                    <textarea type="textarea" rows="3" maxlength="200" name="patologias"
                        [(ngModel)]="trabajador.patologias" matInput maxlength="250" minlength="3"></textarea>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-slide-toggle #slideJubilado [checked]="trabajador.jubilado"
                    (change)="onChangeSlideJubilado($event)">Jubilado</mat-slide-toggle>
            </div>
            <div class="col-4">
                <mat-slide-toggle #slidePase [checked]="trabajador.pase_movilidad"
                    (change)="onChangeSlidePase($event)">Pase de Movilidad</mat-slide-toggle>
            </div>
            <div class="col-4">
                <mat-slide-toggle #slideLicencia [checked]="trabajador.licencia_conducir"
                    (change)="onChangeSlideLicencia($event)">Licencia de Conducir</mat-slide-toggle>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>S. Cesantía</mat-label>
                    <mat-select [(value)]="trabajador.afc" (selectionChange)="ObtenerAfc($event)" required>
                        <mat-option class="example-full-width " *ngFor="let tipo of tipo_afc "
                            [value]="tipo.id ">
                            {{tipo.valor}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Discapacidad</mat-label>
                    <mat-select [(value)]="trabajador.discapacidad"
                        (selectionChange)="ObtenerDiscapacidad($event)" required>
                        <mat-option class="example-full-width " *ngFor="let tipo of tipo_discapacidad "
                            [value]="tipo.id ">
                            {{tipo.valor}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Teléfono Móvil (Contacto Emergencia)</mat-label>
                    <input type="text" name="telefono_contacto_emergencia" maxlength="12"
                        matInput [(ngModel)]="trabajador.telefono_contacto_emergencia" required
                        />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombre del Contacto</mat-label>
                    <input type="text" name="nombre_contacto_emergencia" minlength="3" maxlength="150"
                        matInput [(ngModel)]="trabajador.nombre_contacto_emergencia" required />
                </mat-form-field>
            </div>
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos de contrato</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-3">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Estado del Contrato</mat-label>
                    <mat-select [(value)]="trabajador.estado_contrato"
                        (selectionChange)="ObtenerEstadoContrato($event)" required>
                        <mat-option class="example-full-width " *ngFor="let contrato of estado_contrato "
                            [value]="contrato.valor ">
                            {{contrato.valor}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Tipo de Jornada</mat-label>
                    <mat-select [(value)]="trabajador.tipo_jornada"
                        (selectionChange)="ObtenerTipoJornada($event)" required>
                        <mat-option class="example-full-width " *ngFor="let tipo of tipo_jornada "
                            [value]="tipo.id ">
                            {{tipo.valor}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col col-3">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Horas pactadas semanales</mat-label>
                  <input type="text" matInput [(ngModel)]="trabajador.horasPactadas" [options]="optionsNumeric" required
                    minlength="1" />
                </mat-form-field>
              </div>
            <div class="col-3">
                <!-- DIAS TRABAJADOS -->
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Dias trabajados</mat-label>
                    <mat-select [(value)]="trabajador.dias_semana" (selectionChange)="ObtenerDiaSemana($event)"
                        required>
                        <mat-option class="example-full-width" *ngFor="let dia of dias_semana"
                            [value]="dia.value">
                            {{dia.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Cargo</mat-label>
                    <mat-select [(value)]="cargo_seleccionado" (selectionChange)="ObtenerCargo($event)"
                        required>
                        <mat-option class="example-full-width" *ngFor="let cargo of cargos"
                            [value]="cargo.id">
                            {{cargo.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Sucursal</mat-label>
                    <mat-select [(value)]="sucursal_seleccionada"
                        (selectionChange)="ObtenerSucursal($event)" required>
                        <mat-option class="example-full-width" *ngFor="let sucursal of sucursales"
                            [value]="sucursal.id">
                            {{sucursal.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Empresa Contratante</mat-label>
                    <mat-select [(value)]="empresa_contratante_seleccionada"
                        (selectionChange)="ObtenerEmpresaContratante($event)" required>
                        <mat-option class="example-full-width" *ngFor="let empresa of empresas"
                            [value]="empresa.id">
                            {{empresa.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos de prevención</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>AFP</mat-label>
                    <mat-select [(value)]="afp_seleccionada" (selectionChange)="ObtenerAfp($event)"
                        [required]="requiredAfp">
                        <mat-option class="example-full-width" *ngFor="let afp of afps" [value]="afp.id">
                            {{afp.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Previsión de Salud</mat-label>
                    <mat-select [(value)]="prevision_salud_seleccionada"
                        (selectionChange)="ObtenerPrevision($event)" required>
                        <mat-option class="example-full-width" *ngFor="let prevision of previsiones"
                            [value]="prevision.id">
                            {{prevision.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Monto pactado (UF)</mat-label>
                    <input type="text" name="pactado_isapre" max="10" matInput autonumeric [options]="optionsDecimal"
                        [(ngModel)]="trabajador.pactado_isapre" [disabled]="disableValorIsapre"
                        [required]="requiredUfIsapre" (focus)="soloNumeros($event, false)" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Ahorro APV</mat-label>
                    <input type="text" name="ahorro_apv" matInput autonumeric [options]="optionsNumeric"
                        [(ngModel)]="trabajador.ahorro_apv" [required]="requiredAhorroApv">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>N° Cargas Familiares</mat-label>
                    <input type="text" matInput name="numero_cargas" max="10" autonumeric [options]="optionsNumber"
                        [(ngModel)]="trabajador.numero_cargas" required
                        (focus)="soloNumeros($event, true)" />
                </mat-form-field>
            </div>
            <div class="col col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                  <mat-label>Tramo Asignación Familiar</mat-label>
                  <mat-select (selectionChange)="ObtenerTramo($event)" [(ngModel)]="trabajador.tramoAsigFam" required>
                    <mat-option class="example-full-width " *ngFor="let tramo of tramos"
                      [value]="tramo.value">
                      {{tramo.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos de pago</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Banco</mat-label>
                    <mat-select [(value)]="banco_seleccionado" (selectionChange)="ObtenerBanco($event)"
                        required>
                        <mat-option class="example-full-width " *ngFor="let banco of bancos"
                            [value]="banco.id">
                            {{banco.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Tipo Cuenta</mat-label>
                    <mat-select [(value)]="tipo_cuenta_seleccionada"
                        (selectionChange)="ObtenerTipoCuenta($event)" required>
                        <mat-option class="example-full-width" *ngFor="let tipo_cuenta of tipo_cuentas"
                            [value]="tipo_cuenta.valor ">
                            {{tipo_cuenta.valor}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Número de Cuenta</mat-label>
                    <input type="text" matInput name="numero_cuenta" autonumeric [options]="optionsNumber"
                        [(ngModel)]="trabajador.numero_cuenta" required (focus)="soloNumeros($event, true)" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos de remuneración</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Fecha de Ingreso</mat-label>
                    <input matInput [matDatepicker]="fecha_ingreso" name="fecha_ingreso"
                        [(ngModel)]="trabajador.fecha_ingreso" (click)="fecha_ingreso.open()"
                        (focus)="fecha_ingreso.open()" required>
                    <mat-datepicker-toggle matSuffix [for]="fecha_ingreso"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #fecha_ingreso></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Sueldo Base</mat-label>
                    <input type="text" matInput name="sueldo_base" autonumeric [options]="optionsNumeric"
                        [(ngModel)]="trabajador.sueldo_base" (focus)="soloNumeros($event, true)" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Sueldo Líquido</mat-label>
                    <input type="text" matInput name="sueldo_liquido" autonumeric [options]="optionsNumeric"
                        [(ngModel)]="trabajador.sueldo_liquido" (focus)="soloNumeros($event, true)" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos de uniforme</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla Polera/Poleron</mat-label>
                    <input type="text" matInput name="talla_polera_poleron" maxlength="4"
                        [(ngModel)]="trabajador.talla_polera_poleron" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla Pantalón</mat-label>
                    <input type="text" matInput name="talla_pantalon" autonumeric [options]="optionsNumber"
                        [(ngModel)]="trabajador.talla_pantalon" (focus)="soloNumeros($event, true)" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla Calzado</mat-label>
                    <input type="text" matInput name="numero_calzado" autonumeric [options]="optionsNumber"
                        [(ngModel)]="trabajador.numero_calzado" (focus)="soloNumeros($event, true)" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer ">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" (click)="Actualizar()" class="btn btn-outline-success">Actualizar</button>
    </div>
</ng-template>