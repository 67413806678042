import { Component, OnInit,Input, SimpleChanges } from '@angular/core';

import {Documento} from 'src/app/shared/models/documentos/documentos.interface';
import {DocumentosService} from 'src/app/pages/services/documentos.service';
@Component({
  selector: 'app-lista-liquidaciones',
  templateUrl: './lista-liquidaciones.component.html',
  styleUrls: ['./lista-liquidaciones.component.css']
})
export class ListaLiquidacionesComponent implements OnInit {
  @Input() fk_trabajador:number=0;
  documentos:Documento[] = [];

  constructor(

    private documentoService: DocumentosService
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    

    if (changes.fk_trabajador.currentValue != changes.fk_trabajador.previousValue){

      this.documentos=[]
      this.documentoService.GetAll_liquidaciones(this.fk_trabajador).subscribe(
        (res) => {
          
          this.documentos = res.data;
        }
      );
    }
  
}
  ngOnInit(): void {
    this.documentoService.GetAll_liquidaciones(this.fk_trabajador).subscribe(
      (res) => {
        this.documentos = res.data;
       
      }
    );

  }

}
