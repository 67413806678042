<div class="container-fluid">
    <!-- <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button pButton type="button" icon="pi pi-eye" label="Ver Liquidación" (click)="MostrarLiquidacion()"></button>
        </div>
    </div> -->
    <div class="row">
         <div class="col-8 overflow-auto">
            <pdf-viewer *ngIf="liquidacion.eliminado !== 1" [src]="rutaDocumento" [rotation]="0" [original-size]="false" [show-all]="true"
                [fit-to-page]="false" [zoom-scale]="'page-fit'" [render-text]="true" [external-link-target]="'blank'" 
                [autoresize]="true" [show-borders]="true" style="width: 100%; height: 100vh;">
                <!-- [stick-to-page]="true" [zoom]="1" -->
            </pdf-viewer>
            
             <div class="flex flex-column align-items-center justify-content-center mt-5" *ngIf="liquidacion.eliminado === 1">
                    <!-- <img src="assets/demo/images/access/logo-orange.svg" alt="Sakai logo" class="mb-5 w-6rem flex-shrink-0"> -->
                    <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
                        <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                            <div class="grid flex flex-column align-items-center">
                                <div class="flex justify-content-center align-items-center bg-orange-500 border-circle mb-4" style="width:3.2rem; height:3.2rem;">
                                    <i class="text-50 pi pi-fw pi-lock text-2xl"></i>
                                </div>
                                <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">Acceso Denegado</h1>
                                <span class="text-600 mb-5">La liquidación ha sido eliminada.</span>
                                <img src="../../../../../../assets/img/asset-access.svg" alt="Access denied" class="mb-5" width="80%">
                             </div>
                        </div>
                    </div>
                </div>

         </div>
        <div class="col-4">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-center">Datos de la liquidación</h1>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button *ngIf="liquidacion.eliminado !== 1" pButton pRipple class="p-button-rounded p-button-help" type="button" icon="pi pi-download" 
                        label="Descargar Liquidación" (click)="DescargarLiquidacion()">
                    </button>
                    <button *ngIf="liquidacion.eliminado === 1" pButton pRipple class="p-button-rounded p-button-help" type="button" icon="pi pi-download" 
                       disabled label="Descargar Liquidación" (click)="DescargarLiquidacion()">
                    </button>
                </div>
                <div class="col-12">
                    <label for="periodo">Trabajador</label>
                    <input class="form-control" id="periodo" [value]="nombreTrabajador | uppercase" disabled>
                </div>
                <div class="col-12">
                    <label for="periodo">Empresa</label>
                    <input class="form-control" id="periodo" [value]="nombreEmpresa | uppercase" disabled>
                </div>
                <div class="col-12">
                    <label for="periodo">Sucursal</label>
                    <input class="form-control" id="periodo" [value]="nombreSucursal | uppercase" disabled>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label for="estado">Estado</label>
                    <input class="form-control" id="estado" [value]="estadoCurrent" disabled>
                </div>
                <div class="col-6">
                    <label for="periodo">Periodo</label>
                    <input class="form-control" id="periodo" [value]="modalViewLiquidacion.liquidacion.fecha | date: 'MMMM yyyy'" disabled>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label for="fecha">Haberes $</label>
                    <input type="text" class="form-control" id="haberes" [value]="modalViewLiquidacion.liquidacion.totalHaberes | number:'1.0-0'" readonly>
                </div>
                <div class="col-4">
                    <label for="monto">Descuentos $</label>
                    <input type="text" class="form-control" id="descuentos" [value]="modalViewLiquidacion.liquidacion.totalDescuentos | number:'1.0-0'" readonly>
                </div>
                <div class="col-4">
                    <label for="estado">Líquido $</label>
                    <input type="text" class="form-control" id="liquido" [value]="modalViewLiquidacion.liquidacion.alcanceLiquido | number:'1.0-0'" readonly>
                </div>
            </div>
            <!-- BOTONES DE DECISIONES -->
            <div class="row" *ngIf="viewButtonDecision">
                <div class="col-6 d-flex justify-content-end">
                    <button pButton type="button" label="Aprobar" icon="pi pi-thumbs-up" 
                        class="p-button-success" [disabled]="viewTextAreaComentario && decision" (click)="DecidirAprobacion(true)"></button>
                </div>
                <div class="col-6 d-flex justify-content-start">
                    <button pButton type="button" label="Rechazar" icon="pi pi-thumbs-down" 
                        class="p-button-danger" [disabled]="viewTextAreaComentario && !decision" (click)="DecidirAprobacion(false)"></button>
                </div>
            </div>
            <!-- MODIFICAR DECISION -->
            <div class="row" *ngIf="viewButtonModificacion">
                <div class="col-12 d-flex justify-content-center">
                    <button pButton type="button" label="Cambiar decisión" icon="pi pi-pencil" 
                        class="p-button-secondary" (click)="ModificarDecision()"></button>
                </div>
                <div class="col-12" *ngIf="modificarDecision">
                    <p-message severity="warn" text="Se modificará la decision a {{estadoChange}}" styleClass="mr-2"></p-message> 
                </div>
                <div class="col-12" *ngIf="modificarDecision">
                    <label for="comentarioNew">Comentario / Motivo</label>
                    <textarea id="comentarioNew" cols="2" rows="2" class="form-control" 
                        placeholder="Ingrese un nuevo comentario" [(ngModel)]="NewComentario"></textarea>
                </div>
                <div class="col-12" *ngIf="modificarDecision">
                    <div class="field-checkbox">
                        <p-checkbox [(ngModel)]="pagada_finiquito" [binary]="true" inputId="checkbox"></p-checkbox>
                        <label for="checkbox" class="h5">Pagada en el finiquito</label>
                    </div>
                </div>
                <div class="col-12" *ngIf="modificarDecision">
                    <button pButton type="button" label="Cancelar" class="p-button-secondary mr-2" (click)="Cancelar()"></button>
                    <button pButton type="button" label="Modificar" class="p-button-warning" (click)="CambiarDecision()"></button>
                </div>
            </div>
            <!-- COMENTARIO NUEVO -->
            <div class="row" *ngIf="viewTextAreaComentario">
                <div class="col-12">
                    <label for="comentarioNew">Comentario / Motivo</label>
                    <textarea id="comentarioNew" cols="2" rows="2" class="form-control" 
                        placeholder="Ingrese un comentario" [(ngModel)]="NewComentario"></textarea>
                </div>
                <div class="col-12">
                    <div class="field-checkbox">
                        <p-checkbox [(ngModel)]="pagada_finiquito" [binary]="true" inputId="checkbox"></p-checkbox>
                        <label for="checkbox" class="h5">Pagada en el finiquito</label>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button pButton type="button" label="Cancelar" class="p-button-outlined p-button-secondary mr-2" (click)="Cancelar()"></button>
                    <button pButton type="button" label="Aceptar" class="p-button" (click)="CrearAprobacion(decision)"></button>
                </div>
            </div>
            <div class="row" *ngIf="historyAprobaciones?.length > 0 && historyAprobaciones[0]?.estado !== 'APROBADA' && !viewButtonDecision && !viewTextAreaComentario">
                <div class="col-12">
                    <div class="field-checkbox">
                        <p-checkbox [(ngModel)]="pagada_finiquito" (onChange)="cambiarEstadoPagadaFiniquito()" [binary]="true" inputId="checkbox"></p-checkbox>
                        <label for="checkbox" class="h5">Pagada en el finiquito</label>
                    </div>
                </div>
            </div>
            <!-- HISTORIAL DE COMENTARIOS -->
            <p-divider align="left" type="dashed">
                <b>Historial de decisiones</b>
            </p-divider>
            <app-modal-view-history [historyDecisionesLiquidacion]="historyAprobaciones"></app-modal-view-history>
        </div>
    </div>
    <!-- <div class="row" *ngIf="modalViewLiquidacion.aprobaciones">
        <div class="col-12">
            <mat-list>
                <mat-list-item *ngFor="let aprobacion of modalViewLiquidacion.aprobaciones">
                    <mat-icon mat-list-icon *ngIf="aprobacion.estado === 0"><span class="text-warning">edit_square</span></mat-icon>
                    <mat-icon mat-list-icon *ngIf="aprobacion.estado === 1"><span class="text-success">thumb_up</span></mat-icon>
                    <mat-icon mat-list-icon *ngIf="aprobacion.estado === 2"><span class="text-danger">thumb_down</span></mat-icon>
                    <div mat-line class="text-wrap">{{aprobacion.comentario}}</div>
                    <div mat-line> {{aprobacion.fecha | date: 'dd-MM-yyyy'}} </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div> -->
</div>