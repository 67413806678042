import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MotivosCartaAmonestacionService {

  constructor(
    private http: HttpClient,
  ) { }

  async getMotivosAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/motivosamonestacion/`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener los motivos de cartas de amonestación' } as RequestResponse;
    }
  }
  async agregarMotivoAsync(motivo: any): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/motivosamonestacion/crear`, motivo).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al agregar el motivo de carta de amonestación' } as RequestResponse;
    }
  }
  async actualizarMotivoAsync(motivo: any): Promise<RequestResponse> {
    try {
      return await this.http.put<RequestResponse>(`${environment.API}/rrhh/motivosamonestacion/actualizar/${motivo.id}`, motivo).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al actualizar el motivo de carta de amonestación' } as RequestResponse;
    }
  }
  async eliminarMotivoAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.delete<RequestResponse>(`${environment.API}/rrhh/motivosamonestacion/eliminar/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al eliminar el motivo de carta de amonestación' } as RequestResponse;
    }
  }
}
