import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-main-excel-pago',
  templateUrl: './main-excel-pago.component.html',
  styleUrls: ['./main-excel-pago.component.css']
})
export class MainExcelPagoComponent implements OnInit {
  empresas: any[];

  constructor(
    private empresaService: EmpresaService,
    private alert: AlertHelper,
  ) { 
    this.empresas = [];
  }

  async ngOnInit(): Promise<void> {
    const res = await this.empresaService.GetAll_empresasAsync();
    if (res.success) {
      const empresas = res.data.map(x => {
        if (x.id === 11) {
          return [
            {...x, nombreView: 'SOLANCH MACARENA TEJOS CARRASCO - GENERAL', id: '11-1'},
            {...x, nombreView: 'SOLANCH MACARENA TEJOS CARRASCO - LAMPA', id: '11-2'},
            {...x, nombreView: 'SOLANCH MACARENA TEJOS CARRASCO - QUILICURA', id: '11-3'},
          ];
        }
        return {...x, id: x.id.toString(), nombreView: x.nombre};
      });
      this.empresas = empresas.flat(1);
    }else{
      this.alert.error_small('Hubo un error al obtener las empresas.');
    }
  }

}
