import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { ListaTrabajadores } from 'src/app/shared/models/trabajadores/trabajadores.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  Trabajadores_list:any[]=[]

  constructor(private trabajador_services:TrabajadoresService) { }

  ngOnInit(): void {

    // this.trabajador_services.TraerTrabajadores().subscribe((res:any)=>{
    //   this.Trabajadores_list=res.data;
    // })
  }

  actualizarTabla(event:boolean){
    // this.trabajador_services.TraerTrabajadores().subscribe((res:any)=>{
    // this.Trabajadores_list=res.data;
    // })
  }
}


