import { Component, OnInit } from '@angular/core';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { PreviredService } from 'src/app/pages/services/archivoprevired/previred.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-generarprevired',
  templateUrl: './generarprevired.component.html',
  styleUrls: ['./generarprevired.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class GenerarpreviredComponent implements OnInit {
  now = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  date = new FormControl(moment(this.now));
  constructor(
    private sucursalService:SucursalService,
    private empresaService: EmpresaService,
    private previredService:PreviredService,
  ) { }

  sucursales:Sucursal[];
  currentDate: Date = new Date();
  maxDate:Date = new Date(this.currentDate.getFullYear(),this.currentDate.getMonth()+1,0);
  isGenerated: boolean = false;
  empresas: any[] = [];

  opciones_elegidas = {
    periodo: this.now,
    sucursal: 0,
    empresa: 0,
    cabecera: true,
  }

  async ngOnInit() {
    await this.obtenerSucursalesEmpresas();
  }
  async obtenerSucursalesEmpresas() {
    try {
      const resSucursal = await this.sucursalService.GetAllSucursalesAsync();
      const resEmpresa = await this.empresaService.GetAll_empresasAsync();
      if (resSucursal.success || resEmpresa.success) {
        this.sucursales = resSucursal.data;
        this.empresas = resEmpresa.data;
      }else{
        alert(resSucursal.msg+'\n'+resEmpresa.msg);
      }
    } catch (error) {
      console.error(error);
      alert('Ocurrio un error con la solicitud.');
    }
  }

  chosenDate(event: any) {
    const dateString = event.target.value.split('/');
    const month = parseInt(dateString[0]);
    const year = parseInt(dateString[1]);
    const fecha = new Date(year, month - 1, 1, 0, 0, 0, 0);
    this.date.setValue(moment(fecha))
    this.opciones_elegidas.periodo = fecha;
  }

  // METODO QUE CAPTURA EL AÑO DEL DATEPICKER
  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = normalizedYear;
    ctrlValue.month(normalizedYear.month());
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.opciones_elegidas.periodo = new Date(ctrlValue.year(), ctrlValue.month(), 1);
  }

  selectEmpresa(empresa: any) {
    this.opciones_elegidas.empresa = empresa.value.id;
  }

  selectSucursal(sucursal:any){
    this.opciones_elegidas.sucursal = sucursal.value.id;
    // console.log(this.opciones_elegidas);
  }

  async generarArchivo(){
    this.isGenerated = true;
    await this.previredService.generarArchivoPrevired(this.opciones_elegidas);
    this.isGenerated = false;
  }
}
