import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainHistorialComponent } from './main-historial/main-historial.component';
import { ListaHistorialComponent } from './lista-historial/lista-historial.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { ListaContratoComponent } from './lista-contrato/lista-contrato.component';
import { ListaTransferenciasComponent } from './lista-transferencias/lista-transferencias.component';
import { ListaPrestamoComponent } from './lista-prestamo/lista-prestamo.component';
import { ListaLiquidacionesComponent } from './lista-liquidaciones/lista-liquidaciones.component';
import { ListaCartasComponent } from './lista-cartas/lista-cartas.component';
import { ListaFiniquitosComponent } from './lista-finiquitos/lista-finiquitos.component';

import { ListaImplementossComponent } from './lista-implementos/lista-implementoss.component';
import { ListaImplementossUComponent } from './lista-implementosU/lista-implementossU.component';


@NgModule({
  declarations: [
    MainHistorialComponent,
    ListaHistorialComponent,
    ListaContratoComponent,
    ListaTransferenciasComponent,
    ListaPrestamoComponent,
    ListaLiquidacionesComponent,
    ListaCartasComponent,
    ListaFiniquitosComponent,
    ListaImplementossUComponent,
    ListaImplementossComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
  ],
  exports:[
    MainHistorialComponent,
    ListaHistorialComponent,
    ListaContratoComponent,
    ListaTransferenciasComponent,
    ListaPrestamoComponent,
    ListaLiquidacionesComponent,
    ListaCartasComponent,
    ListaFiniquitosComponent,
    ListaImplementossComponent,
    ListaImplementossUComponent
  ]
})
export class HistorialModule { }
