import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import Chart from 'chart.js/auto';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { LicenciasService } from 'src/app/pages/services/licencias/licencias.service';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-all-licencias',
  templateUrl: './all-licencias.component.html',
  styleUrls: ['./all-licencias.component.css'],
  providers: [DatePipe]
})
export class AllLicenciasComponent implements OnInit {

  chart: any;
  empresas : string[]=[];
  n_licencias:number[] = [];
  list:any;
  displayedColumns: string[] = ['id', 'rut', 'nombre' , 'empresa', 'periodo', 'motivo', ];
  dataSource = new MatTableDataSource();
  year= new Date ;
  mes= new  Date ;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  invalidDates!: Date[];
  maxDate: any;
  minDate: any;
  es: any;
  stateOptions: any[];

  value1: boolean = true;

  constructor(
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private empresaService: EmpresaService,
    private licenciasService: LicenciasService, 
    private primeNGConfig: PrimeNGConfig
    ) { 
      this.stateOptions = [
        { label: 'Año', value: true },
        { label: 'Mes', value: false },
      ];
    }

  ngOnInit(): void {

    // const occurrences = [5, 5, 5, 2, 2, 2, 2, 2, 9, 4].reduce(function (acc, curr) {
    //   console.log(acc);
    //   return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    // }, {});
    
    // console.log(occurrences); // => {2: 5, 4: 1, 5: 3, 9: 1}

    this.primeNGConfig.ripple = true;

    //Obtenemos todos los nombres de las empresas, y lo guardamos en un array
    this.empresaService.GetAll_empresas().subscribe(
      (res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.empresas.push(res.data[i].nombre);
        }
          //console.log(this.empresas);
      }
    );

    //Obtenemos todas las Licencias, y guardamos la cantidad de licencias de acuerdo a cada empresa
    this.licenciasService.TraerTodo().subscribe(
      (res) =>{

        let datos =res.data
        let datosTramitados= res.data ? res.data.filter((x:any)=>{ return x.estado != "SUBIDA"}) :[];
       for (let i=0; i<this.empresas.length; i++){
        this.list = datosTramitados.filter((x:any)=>{ return x.Trabajador.Empresa_contratante.nombre === this.empresas[i];});
        //console.log(" ");
       // console.log(this.list);
        this.n_licencias.push(this.list.length);
       }

       this.dataSource = new MatTableDataSource(datosTramitados);
       this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
        //console.log(res.data);
      }
    );

    this.generarGrafico()

   
   
    
    

  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}
generarGrafico(){

  let htmlRef = this.elementRef.nativeElement.querySelector(`#myChart`);
    htmlRef.widht=10;
    htmlRef.height=40;
    this.chart = new Chart(htmlRef, {
      type: 'bar',
    data: {
        labels: this.empresas,
        datasets: [{
            label: '# Licencias por Empresa',
            data: this.n_licencias,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 3
        }]
    },
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
    })
      
}


  filtrarGrafico(){
    if(this.value1){
      //console.log("Filtrar por Año:");

      //formatear el año con yyyy y no YYYY
      // y   asignado a años
      // Y   is Week-numbering year
      let anio= this.datePipe.transform(this.year, 'yyyy');
      this.filtrarAnio(anio);

    }else{
     // console.log("Filtrar por Mes:");
      let month= this.datePipe.transform(this.mes, 'yyyy-MM');
      this.filtrarMes(month);

    }

  }


  filtrarAnio(anio:any){
    

        this.n_licencias.length=0;
       //Obtenemos todas las Licencias, y guardamos la cantidad de licencias de acuerdo a cada empresa
       this.licenciasService.TraerTodo().subscribe(
        (res) =>{
  
          
          let datosTramitados= res.data ? res.data.filter((x:any)=>{ return x.estado != "SUBIDA"}) :[];
          let datos =datosTramitados.filter((x:any)=>{ return x.fechaInicio.includes(anio)})
          //console.log(datos);
          for (let i=0; i<this.empresas.length; i++){
          this.list = datos.filter((x:any)=>{ return x.Trabajador.Empresa_contratante.nombre === this.empresas[i];});
          //console.log(" ");
         // console.log(this.list);
          this.n_licencias.push(this.list.length);
         }
         
         this.chart.destroy();
         this.generarGrafico();


        }
      );

  }

  filtrarMes(mes:any){
    //console.log(mes);
    this.n_licencias.length=0;
       //Obtenemos todas las Licencias, y guardamos la cantidad de licencias de acuerdo a cada empresa
       this.licenciasService.TraerTodo().subscribe(
        (res) =>{
  
          
          let datosTramitados= res.data ? res.data.filter((x:any)=>{ return x.estado != "SUBIDA"}) :[];
          let datos =datosTramitados.filter((x:any)=>{ return x.fechaInicio.includes(mes)})
          //console.log(datos);
         for (let i=0; i<this.empresas.length; i++){
          this.list = datos.filter((x:any)=>{ return x.Trabajador.Empresa_contratante.nombre === this.empresas[i];});
          //console.log(" ");
         // console.log(this.list);
          this.n_licencias.push(this.list.length);
         }
         
         this.chart.destroy();
         this.generarGrafico();


        }
      );
  }

}
