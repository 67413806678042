<mat-card class="mat-elevation-z5 mat_card_general_content">
    <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="texto a buscar" #input>
    </mat-form-field>
    
    <div>    
        <table id="tabla"  mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5px">ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
      
          <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Usuario</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.userAt_log | uppercase }}
    
            </td>
          </ng-container>
    
          <ng-container matColumnDef="peticion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Peticion</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.body_log | slice:0:140 }}
            </td>
          </ng-container>
    
         <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Accion</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.accion_log | uppercase }}
            </td>
          </ng-container>            
    
          <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Fecha</th>
            <td class="padding-table" mat-cell *matCellDef="let element" >
              {{ element.createdAt | date: 'dd/MM/yyyy HH:mm'}}
            </td>
          </ng-container>     
              
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
          aria-label="Seleccionar pagina de usuarios."
        >
        </mat-paginator>
      </div>