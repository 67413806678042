import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrimeNGConfig } from 'primeng/api';
import { contabilidadService } from 'src/app/pages/services/contabilidad/contabilidad.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';


@Component({
  selector: 'app-agregar-ventas',
  templateUrl: './agregar-ventas.component.html',
  styleUrls: ['./agregar-ventas.component.css']
})
export class AgregarVentasComponent implements OnInit {

  constructor(

    private modalService: NgbModal,
    private primengConfig: PrimeNGConfig,
    private datePipe: DatePipe,
    private alert: AlertHelper,
    private trabajadorService: TrabajadoresService,
    private contabiliada : contabilidadService


  ) { }

  //Variable

  fecha = new Date();

  fecha_Actual = new Date();

  fechaFormatT: any;

  FechaTermino: any;

  fechaMostrar: any;

  trabajadores : any;
  documentos: any;


  iva : any ;



  clientes = [
    { id: 1, nombre: "Emilio" },
    { id: 2, nombre: "Raul" },
    { id: 3, nombre: "Cristian" },
    { id: 4, nombre: "Juan Jose" },
    { id: 5, nombre: "Carlos" },
    { id: 6, nombre: "Jhon" },
    { id: 7, nombre: "Wladimir" },
    { id: 8, nombre: "Felipe" },
    { id: 9, nombre: "Cristian" },
    { id: 10, nombre: "Favio" }
  ]
  documento = [
    { id: 1, nombre: "Boleta" },
    { id: 2, nombre: "Boleta Electronica" },
    { id: 3, nombre: "Boleta Electronica Exeta" },
    { id: 4, nombre: "Boleta Exeta" },
    { id: 5, nombre: "Boleta Voucher" },
    { id: 6, nombre: "Factura" },
    { id: 7, nombre: "Factura de Compra" },
    { id: 8, nombre: "Factura de Compra  Electronica" },

  ]



  trabajador: any = {

    cliente : "",
    documento: "",
    fecha_inicio: "",
    fecha_fin: "",
    folio : "",
    descripcion: "",

  }
  //Columna de la tabla



  Trabajadores(event: any) {

    this.trabajador.cliente  = event.value.nombre;



  }

  Documentos(event: any) {

    this.trabajador.documento = event.value.nombre;



  }

  //Capturar la fecha y asignarle la fecha de termino

  Emision(event: MatDatepickerInputEvent<Date>) {

    this.fecha = event.value;

    this.fechaFormatT = this.datePipe.transform(this.fecha, 'yyyy-MM-dd 00:00:00'); //aqui se transforma usando datepipe al formato año-mes-

    let fec = new Date(this.fechaFormatT);


    this.fechaMostrar = fec.getDate() + ' /' + (fec.getMonth() + 2) + ' /' + fec.getFullYear();


    this.trabajador.fecha_inicio =  this.fechaFormatT;

    this.trabajador.fecha_fin = this.fechaMostrar;


  }

  TraerDatos(){

    this.contabiliada.TraerTodo().subscribe((res: any) => {


    }

     )
  }



  ngOnInit(): void {


    //muestra la fecha actual
    this.fecha_Actual.getMonth();

    this.iva = 37983;


    this.TraerDatos();



  }

}
