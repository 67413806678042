import { DatePipe } from '@angular/common'
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { MatPaginator } from '@angular/material/paginator'
import { MatSelectChange } from '@angular/material/select'
import { MatSort } from '@angular/material/sort'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { PlanillaPagosService } from 'src/app/pages/services/planilla-pagos.service'
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { Planilla } from 'src/app/shared/models/planilla-pagos.interface'

@Component({
  selector: 'app-agregar-planilla-pagos',
  templateUrl: './agregar-planilla-pagos.component.html',
  styleUrls: ['./agregar-planilla-pagos.component.css'],
  providers: [DatePipe]
})

export class AgregarPlanillaPagosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @Input() lista_planillaPagos!: Planilla[]

  debouncer: Subject<boolean> = new Subject()

  planilla: any = {
    
    fecha: "",
    fk_trabajador: "",
    sueldo: 0,
    bonos: 0,
    adelanto: 0,
    prestamos: 0,
    inasistencia: 0,
    asignacionFamiliar: 0,
    sueldoLiquido: 0,
    totalAPagar: 0,
    transferencia: ""
  }


  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private planillaService: PlanillaPagosService,
    private trabajadoresService: TrabajadoresService,
    private datePipe: DatePipe
  ) { }

  trabajadores_aux:any;
  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
      this.trabajadoresService.TraerTrabajadores().subscribe((res) => {
      

        this.trabajadores_aux = res.data;
  
      });
  }


  open(content: any) {

    this.modalService.open(content)
  }



  // limpiar_Planilla() {
   
  //     this.planilla.fecha = "",
  //     this.planilla.fk_trabajador = "",
     
  //     this.planilla.sueldo = 0,
  //     this.planilla.bonos = 0,
  //     this.planilla.adelanto = 0,
  //     this.planilla.prestamos = 0,
  //     this.planilla.inasistencia = 0,
  //     this.planilla.asignacionFamiliar = 0,
  //     this.planilla.sueldoLiquido = 0,
  //     this.planilla.totalAPagar = 0,
  //     this.planilla.transferencia = ""
  // }


ObtenerTrabajador(event: MatSelectChange) {

  this.planilla.fk_trabajador=event.value;

  
 
 }

 fecha: any;

 obtener_fecha(event: MatDatepickerInputEvent<Date>) {

  this.fecha = event.value;

  //Asignacion a la variable con this.adelanto + en la variable en que lo guardaras
  this.planilla.fecha = this.datePipe.transform(this.fecha, 'yyyy-MM-dd')

   
 }
  agregar_Planilla() {

    
    this.planillaService.Create_planilla(this.planilla).subscribe((res: any) => {

     
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll()
       
        this.debouncer.next(true)
      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }

}
