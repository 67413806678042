<form>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Rut</mat-label>
            <input type="text" name="rut" matInput disabled [(ngModel)]="trabajador.rut" />
        </mat-form-field>
    </div>
    <div class="col">
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Nombres</mat-label>
                        <input type="text" name="trabajador.nombres" matInput [(ngModel)]="trabajador.nombres" required
                            maxlength="150" minlength="3" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Apellidos</mat-label>
                        <input type="text" name="trabajador.apellidos" matInput [(ngModel)]="trabajador.apellidos"
                            required maxlength="150" minlength="3" />
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col">
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Teléfono</mat-label>
                        <input type="number" name="telefono" min="0" max="999999999" matInput
                            [(ngModel)]="trabajador.telefono" required />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Correo</mat-label>
                        <input type="text" name="correo" minlength="10" maxlength="50" matInput
                            [(ngModel)]="trabajador.correo" required />
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col ">
        <table style="width:100% ">
            <tr>
                <td width="50%">
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Fecha De Nacimiento</mat-label>
                        <input matInput [matDatepicker]="fecha_nacimiento" [min]="minDate" [max]="maxDate"
                            name="fecha_nacimiento" [(ngModel)]="trabajador.fecha_nacimiento " required
                            (click)="fecha_nacimiento.open()" (focus)="fecha_nacimiento.open()">
                        <mat-datepicker-toggle matSuffix [for]="fecha_nacimiento"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #fecha_nacimiento></mat-datepicker>
                    </mat-form-field>
                </td>
                <td width="50%">
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Nacionalidad</mat-label>
                        <mat-select [(value)]="nacionalidad_seleccionada"
                            (selectionChange)="ObtenerNacionalidad($event)" required>
                            <mat-option class="example-full-width" *ngFor="let nacionalidad of nacionalidades"
                                [value]="nacionalidad.id">
                                {{nacionalidad.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col">
        <table style="width:100% ">
            <tr>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Profesión</mat-label>
                        <input type="text" matInput name="profesion" [(ngModel)]="trabajador.profesion" required
                            maxlength="150" minlength="3" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>N° Cargas Familiares</mat-label>
                        <input type="number" matInput name="numero_cargas" min="0" max="10"
                            [(ngModel)]="trabajador.numero_cargas" required />
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col ">
        <table style="width:100% ">
            <tr>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Estado Civil</mat-label>
                        <mat-select [(value)]="estado_civil_seleccionada" (selectionChange)="ObtenerEstadoCivil($event)"
                            required>
                            <mat-option class="example-full-width" *ngFor="let estado of estados" [value]="estado.id">
                                {{estado.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Ciudad</mat-label>
                        <mat-select [(value)]="ciudad_seleccionada" (selectionChange)="ObtenerCiudad($event)" required>
                            <mat-option class="example-full-width" *ngFor="let ciudad of ciudades" [value]="ciudad.id">
                                {{ciudad.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Dirección</mat-label>
            <input type="text" matInput name="direccion" [(ngModel)]="trabajador.direccion" required maxlength="250"
                minlength="3" />
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Patologías</mat-label>
            <textarea type="textarea" rows="3" maxlength="200" name="patologias" [(ngModel)]="trabajador.patologias"
                matInput maxlength="250" minlength="3"></textarea>
        </mat-form-field>
    </div>
    <div class="col ">
        <table style="width:100% ">
            <tr>
                <td width="33%" align="center">
                    <mat-slide-toggle #slideJubilado [checked]="trabajador.jubilado"
                        (change)="onChangeSlideJubilado($event)">Jubilado</mat-slide-toggle>
                </td>
                <td width="33%" align="center">
                    <mat-slide-toggle #slidePase [checked]="trabajador.pase_movilidad"
                        (change)="onChangeSlidePase($event)">Pase de Movilidad</mat-slide-toggle>
                </td>
                <td width="33%" align="center">
                    <mat-slide-toggle #slideLicencia [checked]="trabajador.licencia_conducir"
                        (change)="onChangeSlideLicencia($event)">Licencia de Conducir</mat-slide-toggle>
                </td>
            </tr>
        </table>
    </div>
    <div class="col">
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Teléfono Contacto Emergencia</mat-label>
                        <input type="number" name="telefono_contacto_emergencia" min="9999999" max="999999999" matInput
                            [(ngModel)]="trabajador.telefono_contacto_emergencia" required />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Nombre del Contacto</mat-label>
                        <input type="text" name="nombre_contacto_emergencia" minlength="3" maxlength="150" matInput
                            [(ngModel)]="trabajador.nombre_contacto_emergencia" required />
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col">
        <table style="width:100%">
            <tr>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Estado del Contrato</mat-label>
                        <mat-select [(value)]="trabajador.estado_contrato"
                            (selectionChange)="ObtenerEstadoContrato($event)" required>
                            <mat-option class="example-full-width " *ngFor="let contrato of estado_contrato "
                                [value]="contrato.valor ">
                                {{contrato.valor}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Sucursal</mat-label>
                        <mat-select [(value)]="sucursal_seleccionada" (selectionChange)="ObtenerSucursal($event)"
                            required>
                            <mat-option class="example-full-width" *ngFor="let sucursal of sucursales"
                                [value]="sucursal.id">
                                {{sucursal.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col ">
        <table style="width:100%">
            <tr>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Cargo</mat-label>
                        <mat-select [(value)]="cargo_seleccionado" (selectionChange)="ObtenerCargo($event)" required>
                            <mat-option class="example-full-width" *ngFor="let cargo of cargos" [value]="cargo.id">
                                {{cargo.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Empresa Contratante</mat-label>
                        <mat-select [(value)]="empresa_contratante_seleccionada"
                            (selectionChange)="ObtenerEmpresaContratante($event)" required>
                            <mat-option class="example-full-width" *ngFor="let empresa of empresas"
                                [value]="empresa.id">
                                {{empresa.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col ">
        <table style="width:100%">
            <tr>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>AFP</mat-label>
                        <mat-select [(value)]="afp_seleccionada" (selectionChange)="ObtenerAfp($event)"
                            [required]="requiredAfp">
                            <mat-option class="example-full-width" *ngFor="let afp of afps" [value]="afp.id">
                                {{afp.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Previsión de Salud</mat-label>
                        <mat-select [(value)]="prevision_salud_seleccionada"
                            (selectionChange)="ObtenerPrevision($event)" required>
                            <mat-option class="example-full-width" *ngFor="let prevision of previsiones"
                                [value]="prevision.id">
                                {{prevision.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Monto pactado (UF)</mat-label>
                        <input type="number" name="pactado_isapre" min="0" max="10" matInput
                            [(ngModel)]="trabajador.pactado_isapre" [disabled]="disableValorIsapre"
                            [required]="requiredUfIsapre" />
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col">
      <table style="width:100%">
        <tr>
          <td>
            <mat-form-field class="example-full-width " appearance="outline">
              <mat-label>Ahorro APV</mat-label>
              <input type="number" name="ahorro_apv" min="0" max="10"matInput
                            [(ngModel)]="trabajador.ahorro_apv"
                            [required]="requiredAhorroApv">
            </mat-form-field>
          </td>
        </tr>
      </table>
    </div>
    <div class="col ">
        <table style="width:100% ">
            <tr>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Banco</mat-label>
                        <mat-select [(value)]="banco_seleccionado" (selectionChange)="ObtenerBanco($event)" required>
                            <mat-option class="example-full-width " *ngFor="let banco of bancos" [value]="banco.id">
                                {{banco.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Tipo Cuenta</mat-label>
                        <mat-select [(value)]="tipo_cuenta_seleccionada" (selectionChange)="ObtenerTipoCuenta($event)"
                            required>
                            <mat-option class="example-full-width" *ngFor="let tipo_cuenta of tipo_cuentas"
                                [value]="tipo_cuenta.valor ">
                                {{tipo_cuenta.valor}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col ">
        <table style="width:100% ">
            <tr>
                <td width="50%">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Número de Cuenta</mat-label>
                        <input type="number" matInput name="numero_cuenta" max="999999999999999"
                            [(ngModel)]="trabajador.numero_cuenta" required />
                    </mat-form-field>
                </td>
                <td width="50%">
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Fecha de Ingreso</mat-label>
                        <input matInput [matDatepicker]="fecha_ingreso" name="fecha_ingreso"
                            [(ngModel)]="trabajador.fecha_ingreso" (click)="fecha_ingreso.open()"
                            (focus)="fecha_ingreso.open()" required>
                        <mat-datepicker-toggle matSuffix [for]="fecha_ingreso"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #fecha_ingreso></mat-datepicker>
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
    <div class="col ">
        <table style="width:100% ">
            <tr>
                <td width="33%">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla Polera/Poleron</mat-label>
                        <input type="text" matInput name="talla_polera_poleron" maxlength="4"
                            [(ngModel)]="trabajador.talla_polera_poleron" />
                    </mat-form-field>
                </td>
                <td width="33%">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla Pantalón</mat-label>
                        <input type="number" matInput name="talla_pantalon" max="99"
                            [(ngModel)]="trabajador.talla_pantalon" />
                    </mat-form-field>
                </td>
                <td width="33%">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla Calzado</mat-label>
                        <input type="number" matInput name="numero_calzado" max="99"
                            [(ngModel)]="trabajador.numero_calzado" />
                    </mat-form-field>
                </td>
            </tr>
        </table>
    </div>
</form>

<div class="modal-footer ">
    <button type="button" class="btn btn-outline-danger" aria-label="Close">
        <span aria-hidden="true">Cancelar</span>
      </button>
    <button type="button" (click)="Actualizar()" class="btn btn-outline-success">Actualizar</button>
</div>
