
<mat-form-field appearance="outline">
  <mat-label>Buscar</mat-label>
  <input matInput (keyup)="applyFilter($event)"  #input>
</mat-form-field>




<div>
    <button style="background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button>Exportar a PDF</button>
    <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button color="green">Exportar a Excel</button>
</div>
<div class="mat-elevation-z8">    
    <table mat-table [dataSource]="dataSource" matSort id="tabla">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
  
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre del cargo</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.nombre | uppercase }}

        </td>
      </ng-container>

      <ng-container matColumnDef="departamento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Departamento</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.departamento | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lugar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lugar de Trabajo</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.lugar | uppercase }}
        </td>
      </ng-container>              

      <ng-container matColumnDef="jornadaLaboral">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Jornada de Trabajo</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.jornadas.descripcion | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dias">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dias</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.dias | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sucursal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sucursal</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.sucursales2.nombre| uppercase }}          
        </td>
      </ng-container>      
          
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          <button 
            (click)="open(content, element.id)"
            mat-button class="button_color_primary">
            <mat-icon style="color: white">edit</mat-icon>
          </button>
          &nbsp;
          <button 
            (click)="Eliminar_Cargo(content, element.id)"
            mat-button class="button_color_primary" style="background-color:red">
            <mat-icon style="color: white">delete</mat-icon>
          </button>          
        </td>        
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Seleccionar pagina de usuarios."
    >
    </mat-paginator>
  </div>
  





  
  <ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Actualizar Cargo</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="row">
        <div class="col">
          <mat-form-field class="example-full-width"appearance="outline">
            <mat-label>Nombre del cargo</mat-label>
            <input
              type="text"
              maxlength="50"
              minlength="3"
              matInput required
              name="cargos.nombre"
              [(ngModel)]="update_cargos.nombre"
            />
          </mat-form-field>
        </div>                        
        <div class="col">
          <mat-form-field class="example-full-width"appearance="outline">
            <mat-label>Departamento</mat-label>
            <input
              type="text"
              maxlength="50"
              minlength="3"
              matInput required
              name="cargos.jefe"
              [(ngModel)]="update_cargos.departamento"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="example-full-width"appearance="outline">
            <mat-label>Lugar</mat-label>
            <input
              type="text"
              maxlength="150"
              minlength="3"
              matInput required
              name="cargos.lugar"
              [(ngModel)]="update_cargos.lugar"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Sucursal</mat-label>
            <mat-select  [(value)]="update_cargos.sucursal" required>
              <mat-option *ngFor="let sucursal of list_sucursales" [value]="sucursal.id">
                {{ sucursal.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
       
      </div>
      <div class="row">
     
        <div class="col">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Jornada</mat-label>
            <mat-select  [(value)]="update_cargos.jornadaLaboral" required>
              <mat-option *ngFor="let jornada of jornadas" [value]="jornada.id">
                {{ jornada.descripcion }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">Cancelar</span>
      </button>
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="Actualizar_Cargo()"
      >
        Actualizar
      </button>
    </div>
  </ng-template>