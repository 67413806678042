<button mat-button (click)="openGenerar(content)" matBadgeColor="accent" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
    <mat-icon style="color: white">add</mat-icon>
</button>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Generar Carta Amonestación</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>TRABAJADOR</mat-label>
                    <input matInput readonly [value]="trabajador.nombres + ' ' +trabajador.apellidos | uppercase">
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>EMPRESA</mat-label>
                    <input matInput readonly [value]="trabajador.Empresa_contratante.nombre | uppercase">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>FECHA EMISIÓN</mat-label>
                    <input matInput readonly [value]="cartaAmonestacion.fecha | date:'dd - MMMM - yyyy'">
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>GRADO</mat-label>
                    <mat-select [(value)]="cartaAmonestacion.grado" required>
                        <mat-option *ngFor="let grado of grados" [value]="grado.value">
                            {{grado.text}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <div class="field">
                    <label for="motivo" class="block">MOTIVO</label>
                    <p-dropdown inputId="motivo" [options]="motivosCarta" [(ngModel)]="selectedMotivo" placeholder="Seleccione un motivo"
                        optionLabel="nombre" styleClass="w-100" [required]="true" (onChange)="verificarMotivo()">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <!-- ITEMS (LISTA FECHAS, LISTA FECHAS HORAS Y ATRASOS) -->
        <div class="row" *ngIf="selectedMotivo">
            <!-- LISTA FECHA -->
            <div class="col-12" *ngIf="viewAllSection || isListaFecha">
                <p-divider align="left">
                    FECHAS SELECCIONADAS
                </p-divider>
                <div class="row pl-2">
                    <div class="col-12">
                        <p-calendar [(ngModel)]="fechasSelected" selectionMode="multiple" [readonlyInput]="true" (onClickOutside)="configurarDescripcion()"
                            placeholder="Seleccione fechas" styleClass="w-100" dateFormat="dd/mm/yy" firstDayOfWeek="1">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <!-- LISTA FECHA Y HORA -->
            <div class="col-12" *ngIf="viewAllSection || isListaFechaHora">
                <p-divider align="left">
                    FECHAS Y HORAS SELECCIONADAS
                </p-divider>
                <div class="row pl-2">
                    <div class="col-12">
                        <p-calendar [(ngModel)]="fechasSelected" [showTime]="true" styleClass="w-100" selectionMode="multiple" 
                            dateFormat="dd/mm/yy" firstDayOfWeek="1" (onClickOutside)="configurarDescripcion()" placeholder="Seleccione fechas y horas">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <!-- LISTA ATRASOS -->
            <div class="col-12" *ngIf="viewAllSection || isListaAtraso">
                <p-divider align="left">
                    ATRASOS
                </p-divider>
                <div class="row pl-2">
                    <div class="col-4">
                        <label for="range" class="block">Desde - Hasta</label>
                        <p-calendar [(ngModel)]="fechasFilterAtrasos" selectionMode="range" firstDayOfWeek="1" styleClass="w-100"
                            [readonlyInput]="true" inputId="range" dateFormat="dd/mm/yy" [numberOfMonths]="2" (onClickOutside)="configurarDescripcion()">
                        </p-calendar>
                    </div>
                    <div class="col-7">
                        <p-listbox [options]="fechasAtrasos" [(ngModel)]="fechasAtrasosSelected" [multiple]="true" 
                            [checkbox]="true" (onChange)="configurarDescripcion()">
                        </p-listbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Motivo</mat-label>
                    <textarea matInput cdkTextareaAutosize class="w-100" id="descripcion" [(ngModel)]="descripcionFormated" 
                        [required]="true" autoResize="autoResize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
        <button type="button" class="btn btn-success" (click)="GenerarCarta()">Generar</button>
    </div>
</ng-template>