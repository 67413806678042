import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { LicenciasService } from 'src/app/pages/services/licencias/licencias.service';

@Component({
  selector: 'app-alerta-licencias',
  templateUrl: './alerta-licencias.component.html',
  styleUrls: ['./alerta-licencias.component.css'],
  providers: [ MessageService ]
})
export class AlertaLicencias implements OnInit {

  list:any;
  n_alertas:number=0;
  constructor(
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private licenciasService: LicenciasService,
    )  { }

    ngOnInit() {
      this.primengConfig.ripple = true;
    }


    allAlertas(){
      this.primengConfig.ripple = true;
      this.licenciasService.TraerTodo().subscribe(
        (res) =>{

         for (let i=0; i<res.data.length; i++){
          this.list = res.data.filter((x:any)=>{ return x.estado === "SUBIDA"})

         }
        // console.log(this.list.length);
        // console.log(this.list);
        this.n_alertas=this.list.length;
        }
      );
      this.messageService.add({severity:'info', summary: 'Info', detail: 'Probando estoy'});
    }


}
