import { MatSelectChange } from '@angular/material/select';
import { Subject } from 'rxjs'
import { PrestamosService } from './../../../../services/prestamos.service'
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { Prestamos } from 'src/app/shared/models/prestamos.interface'
import { debounceTime } from 'rxjs/internal/operators/debounceTime'
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { FoliadosService } from 'src/app/pages/services/foliados/foliados.service';
import { TipoPrestamo } from 'src/app/shared/models/tipoprestamo.interface';
import { TiposPrestamoService } from 'src/app/pages/services/tipoprestamo.service';
import { Cuotas } from 'src/app/shared/models/cuotas.interface';

@Component({
  selector: 'app-agregar-prestamos',
  templateUrl: './agregar-prestamos.component.html',
  styleUrls: ['./agregar-prestamos.component.css']
})

export class AgregarPrestamosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @Input() list_prestamos!: Prestamos[];
  trabajadores_aux: any;
  now: Date = new Date();

  debouncer: Subject<boolean> = new Subject();
  modalReference = null;

  numCuotas: number = 1;
  cuotasPagadas: number = 0;
  montoPrestamo: number = 0;
  fechaPrestamo: string = new Date().toISOString();
  //ME SERVIRA PARA SABER SI EL TRABAJADOR ESTA AGREGANDO O EDITANDO
  //0 = AGREGAR : 1 = EDITAR
  accion:number = 0; 

  tiposprestamo: TipoPrestamo[] = [];

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  prestamos = {
    fk_trabajador: 0,
    estado: false,
    estadoDocumento: false,
    autoriza: '',
    idtipo: 0,
    observacion: "",
    fechaPrestamo: "",
    cuotas: 1,
    montoTotal: 0,
    folio: '0',
    cuotasList: [],
  }

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private trabajadoresService: TrabajadoresService,
    private prestamoService: PrestamosService,
    private FoliadosService: FoliadosService,
    private tiposService: TiposPrestamoService,
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })

    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        this.trabajadores_aux = res.data.filter(x => x.estado !== 0);
        this.trabajadores_aux = this.trabajadores_aux.map(x => ({ 
            value: x.rut, 
            nombre: x.nombres.toUpperCase() 
            + ' ' + x.apellidos.toUpperCase(), 
          })
        )
      }
    );
    this.tiposService.TraerTiposPrestamo().subscribe(res => {
      if (res.success) {
        this.tiposprestamo = res.data.map(x => ({ id: x.id, nombre: x.nombre, descripcion: x.descripcion }));
      } else {
        this.alert.error_small('Hubo un error innesperado al obtener los tipos de prestamo');
        console.error(res.msg);
      }
    });
  }

  open(content: any) {
    this.limpiar_Prestamo();
    this.prestamos.cuotas = 1;
    this.cuotasPagadas = 0;
    this.accion = 0;

    const fechaHoyUTC = this.now.toISOString();
    this.prestamos.fechaPrestamo = new Date(fechaHoyUTC.split('T')[0] + 'T00:00:00').toISOString();
    this.modalReference = this.modalService.open(content, { size: 'lg' });
  }

  obtenerNumCuotas() {
    this.verificarDatos();
    this.accion = 0;
    this.numCuotas = this.prestamos.cuotas;
  }
  obtenerMonto() {
    this.accion = 0;
    this.montoPrestamo = this.prestamos.montoTotal;
  }
  obtenerFechaPrestamo() {
    this.accion = 0;
    console.log('cambio la fecha prestamo')
    this.fechaPrestamo = this.prestamos.fechaPrestamo;
  }
  obtenerCuotasPagadas(event) {
    this.verificarDatos();
    this.accion = 0;
    this.cuotasPagadas = parseInt(event.target.value);
  }

  // CUOTAS RECIBIDAS DESDE EL COMPONENTE FORM-CUOTAS
  recibirCuotas(cuotas: Cuotas[]) {
    this.prestamos.cuotasList = cuotas;
    this.cuotasPagadas = cuotas.reduce((a:number, e:Cuotas) => e.estado ? a + 1 : a, 0);
    // console.log({cuotas});
  }

  verificarDatos(){
    if (this.cuotasPagadas > this.numCuotas) {
      this.cuotasPagadas = this.numCuotas;
    }
    if (this.numCuotas === 0 || !this.numCuotas) {
      this.numCuotas = 1;
    }
  }

  validarPrestamo() {
    let msg = '';
    if (this.prestamos.fk_trabajador == null || this.prestamos.fk_trabajador == undefined || this.prestamos.fk_trabajador === 0) {
      msg = 'Rut no válido';
      this.alert.error_small(msg);
      return;
    }
    else if (this.prestamos.autoriza.trim().length < 3 || this.prestamos.autoriza.trim().length > 150) {
      msg = 'Descripción de quien autoriza no válida';
      this.alert.error_small(msg);
      return;
    }
    else if (this.prestamos.idtipo === 0) {
      this.alert.error_small('Debe seleccionar el tipo de prestamo.');
      return;
    }
    else if (this.prestamos.observacion.trim().length < 1 && this.prestamos.observacion.trim().length > 200) {
      msg = 'Observación no válida';
      this.alert.error_small(msg);
      return;
    }
    else if (this.prestamos.fechaPrestamo == undefined || this.prestamos.fechaPrestamo == '' || this.prestamos.fechaPrestamo == null) {
      msg = 'Fecha Préstamo no válida';
      this.alert.error_small(msg);
      return;
    }
    else if (this.prestamos.cuotas < 1 || this.prestamos.cuotas > 999) {
      msg = 'Número de cuotas no válido';
      this.alert.error_small(msg);
      return;
    }
    else if (this.prestamos.montoTotal < 1 || this.prestamos.montoTotal > 999999999) {
      msg = 'Monto del crédto no válido';
      this.alert.error_small(msg);
      return;
    }
    else {
      this.prestamos.observacion.toUpperCase();
      this.obtenerFolio();
    }
  }

  limpiar_Prestamo() {
    this.prestamos.fk_trabajador = 0
    this.prestamos.autoriza = ""
    this.prestamos.idtipo = 0,
    this.prestamos.observacion = ""
    this.prestamos.fechaPrestamo = ""
    this.prestamos.cuotas = 0
    this.prestamos.montoTotal = 0
    this.prestamos.folio = '0'
    this.prestamos.cuotasList = [];
  }

  agregar() {
    this.prestamoService.Create_prestamos(this.prestamos).subscribe((res: any) => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.cerrarModal()
        this.limpiar_Prestamo()
        this.debouncer.next(true)
      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }

  obtenerFolio() {
    this.FoliadosService.Prestamos().subscribe((res) => {
      if (res.success) {
        this.prestamos.folio = res.data[0].foliado.toString();
        this.prestamos.folio = this.prestamos.folio.padStart(5, '00000');
        this.agregar();
      }
      else {
        this.alert.error_small('Error, no es posible obtener el N° de Folio para generar el documento');
        console.error(res.msg);
      }
    });
  }

  ObtenerRutTrabajador(event: MatSelectChange) {
    this.prestamos.fk_trabajador = event.value?.value ?? '';
  }

  cerrarModal() {
    this.modalReference.close();
    this.modalReference = null;
  }
}
