import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Observable, catchError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { CertificadoSueldoBD } from 'src/app/shared/models/certificadosueldo/certificadosueldo.interface';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Injectable({
  providedIn: 'root'
})
export class CertificadoSueldoService {
  constructor(private http:HttpClient, private router: Router, private alert:AlertHelper) { }

  getLastNumCertificado():Observable<RequestResponse> {
    return this.http 
      .get<RequestResponse>(`${environment.API}/rrhh/certificado/last-num`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError((error) => this.handlerError(error)),
      );
  }

  crearCertificado(certificadoSueldo:CertificadoSueldoBD){
    return this.http
      .post<RequestResponse>(`${environment.API}/rrhh/certificado/crear`,certificadoSueldo)
      .pipe(
        map((res:RequestResponse) => res),
        catchError((error) => this.handlerError(error)),
      );
  }

  private handlerError(err: any):Observable<never>{
    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    throw errorMessage;
}
}
