import { Component, OnInit,Input, SimpleChanges } from '@angular/core';

import {Documento} from 'src/app/shared/models/documentos/documentos.interface';
import {DocumentosService} from 'src/app/pages/services/documentos.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lista-implementoss',
  templateUrl: './lista-implementoss.component.html',
  styleUrls: ['./lista-implementoss.component.css']
})
export class ListaImplementossComponent implements OnInit {
  @Input() fk_trabajador:number=0;

  documentos:Documento[] = [];
 
  constructor(
    private documentoService: DocumentosService
  ) { }

  ngOnInit(): void {
   
    this.documentoService.GetAll_Implementos(this.fk_trabajador).subscribe(
      (res) => {
          this.documentos = res.data;
        //console.log(this.documentos);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    

   

      this.documentos=[]
      this.documentoService.GetAll_Implementos(this.fk_trabajador).subscribe(
        (res) => {

          this.documentos = res.data;
          //console.log(this.documentos);
        }
      );
    
  

}

}
