import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';

import { MainRegionesComponent } from './main-regiones/main-regiones.component';
import { ListaRegionesComponent } from './lista-regiones/lista-regiones.component';
import { SharedModule } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { AgregarRegionesComponent } from './agregar-regiones/agregar-regiones.component';
import { AutonumericModule } from '@angularfy/autonumeric';

@NgModule({
  declarations: [
    MainRegionesComponent,
    ListaRegionesComponent,
    AgregarRegionesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PrimeNgModule,
    SharedModule,
    FormsModule,
    AutonumericModule,
  ],
  exports: [
    MainRegionesComponent,
  ]
})
export class RegionesModule { }
