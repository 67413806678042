<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE </th>
            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="empresas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESAS </th>
            <td mat-cell *matCellDef="let row">
                <mat-list *ngIf="row.Empresas.length > 0">
                    <mat-list-item *ngFor="let empresa of row.Empresas">
                        <p matLine> {{empresa.nombre}} </p>
                    </mat-list-item>
                </mat-list>
                <mat-list *ngIf="row.Empresas.length === 0">
                    <mat-list-item>
                        <p matLine> Sin empresas </p>
                    </mat-list-item>
                </mat-list>
            </td>
        </ng-container>
        <ng-container matColumnDef="firma">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FIRMA </th>
            <td mat-cell *matCellDef="let row">
                <img [src]="URL_BASE_API+row.rutaImg" alt="FIRMA">
            </td>
        </ng-container>
        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef> ACCIÓN </th>
            <td mat-cell *matCellDef="let row" style="width: 10%;">
                <app-editar-firmas [firma]="row" (actualizarTabla)="TraerDatos()"></app-editar-firmas>
                &nbsp;
                <button (click)="EliminarMotivo(row)" mat-button class="button_color_primary"
                    style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- CUANDO NO EXISTEN REGISTROS -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
                <h2 class="text-center">No existen firmas</h2>
            </td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons>
    </mat-paginator>
</div>