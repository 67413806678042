import { Injectable } from '@angular/core';
import { Celda, OpcionElegidaExcelPago } from 'src/app/shared/models/excelpago.interface';
import { TrabajadoresService } from './trabajadores/trabajadores.service';
import { AdelantosService } from './adelantos/adelantos.service';
import { contabilidadService } from './contabilidad/contabilidad.service';
import { format, parseISO } from 'date-fns';
import { getLastDigitOfRut } from 'rutlib'
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { NominaItau, NominaScotiabank } from 'src/app/shared/models/nominas.interface';

@Injectable({
  providedIn: 'root'
})
export class GenerarExcelPagoService {
  // VARIABLES ESTATICAS
  CODIGO_EMPRESA_SOLANCH: number = 11;
  RUTA_EXCEL_SCOTIABANK: string = '/excels/SCOTIABANK.xlsm';
  RUTA_EXCEL_ITAU: string = '/excels/ITAU.xls';

  opciones_elegidas: OpcionElegidaExcelPago;
  trabajadores: any[];

  libro_registros: any[];
  anticipos: any[];

  // NOMINAS
  nominas_SB: NominaScotiabank[];
  nominas_ITAU: NominaItau[];

  constructor(
    private trabajadoresService: TrabajadoresService,
    private contabilidadService: contabilidadService,
    private adelantoService: AdelantosService,
    private http: HttpClient,
  ) {
    this.opciones_elegidas = {
      periodo: new Date,
      tipo: null,
      empresa: null,
    }
    this.nominas_SB = [];
    this.nominas_ITAU = [];
  }

  async generarExcelPago(opciones: OpcionElegidaExcelPago): Promise<void> {
    try {
      this.opciones_elegidas = opciones;
      await this.obtenerTrabajadores();
      // EXCEL PAGO DE REMUNERACIONES
      if (opciones.tipo.id === 1) await this.generarExcelPagoRemuneracion();
      // EXCEL PAGO DE ANTICIPO
      else if (opciones.tipo.id === 2) await this.generarExcelPagoAnticipo();
      else alert('La opcion elegida no existe.');
    } catch (error) {
      alert(error.message);
    }
  }

  async generarExcelPagoRemuneracion() {
    const res = await this.contabilidadService.TraerPorPeriodoAsync(format(this.opciones_elegidas.periodo, 'yyyy-MM-dd'));
    if (!res.success) throw Error('Hubo un error al obtener las remuneraciones.');
    this.libro_registros = res.data.filter(x => this.trabajadores.some(t => t.rut === x.rut) && (x.totalimp - x.totaldescuento) > 0);
    const idempresa = this.opciones_elegidas.empresa.id;
    // SI ES LA EMPRESA MIGUEL VARGAS, GENERAR EXCEL ITAU
    if (idempresa === '2') await this.generarExcelPagoItau(1);
    else await this.generarExcelPagoScotiabank(1);
    // console.log('Libro: ', this.libro_registros);
  }

  async generarExcelPagoAnticipo() {
    const periodo = this.opciones_elegidas.periodo;
    const idempresa = this.opciones_elegidas.empresa;
    this.anticipos = [];
    for (const trabajador of this.trabajadores) {
      const res = await this.adelantoService.ObtenerAdelantosRutAsync(trabajador.rut);
      if (!res.success) throw Error('Hubo un error al obtener los anticipos.');
      const anticipo: any[] = res.data.find(x => {
        const fecha = parseISO(x.fechaInicioContrato);
        return fecha.getMonth() === periodo.getMonth() && periodo.getFullYear() === fecha.getFullYear();
      });
      if (anticipo) this.anticipos.push(anticipo);
    }
    if (idempresa === '2') await this.generarExcelPagoItau(2);
    else await this.generarExcelPagoScotiabank(2);
    console.log({ ...this.anticipos });
  }
  // async generarExcelPagoScotiabank(tipo: number) {
  //   // TIPO 1 = REMUNERACION
  //   // TIPO 2 = ANTICIPO
  //   try {
  //     const bancoSB = 1;
  //     if (tipo === 1) this.calcularNominaPagoRemuneraciones(bancoSB);
  //     else if (tipo === 2) this.calcularNominaPagoAnticipo(bancoSB);
  //     else throw Error('Hubo un error en el tipo de pago.');

  //     if (this.nominas_SB.length > 0) {
  //       const datos = {
  //         empresa: this.opciones_elegidas.empresa,
  //         nomina: this.nominas_SB,
  //       }

  //       const res = await this.generarExcelPagoScotiabankServidor(datos);
  //       console.log(res.data);
  //       if (!res.success) throw Error('Hubo un error al generar el excel.');
  //       const ruta = `${environment.API}/excels/${res.data}`;
  //       this.abrirArchivo(ruta);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     alert(error.message);
  //   }
  // }

  calcularNominaPagoAnticipo(banco: number) {
    // 1- SCOTIABANK
    // 2- ITAU
    this.nominas_SB = [];
    for (const anticipo of this.anticipos) {
      const trabajador = this.trabajadores.find(x => x.rut === anticipo.fk_trabajador);
      if (!trabajador) continue;
      const monto = anticipo.montoAdelanto;
      if (banco === 1) this.generarNominaScotiabank(trabajador, monto);
      else this.generarNominaItau(trabajador, monto);
    }
    console.log(this.nominas_SB);
  }

  generarNominaScotiabank(trabajador: any, monto: number) {
    const { rut, nombres, apellidos, Banco, numero_cuenta } = trabajador;
    const rutSinVerificador: string = rut.toString().slice(0, -1);
    const verificador = getLastDigitOfRut(parseInt(rutSinVerificador));

    if (numero_cuenta === 0 || !numero_cuenta) throw Error('El trabajador ' + nombres + ' ' + apellidos + ' no tiene una cuenta para transferir.')
    const nombre: string = `${nombres.trim().split(' ')[0]} ${apellidos.trim()}`;
    const nomina: NominaScotiabank = {
      rut: `${rutSinVerificador}${verificador}`,
      trabajador: nombre.toUpperCase(),
      banco: Banco?.nombreNomina || '',
      cuenta: numero_cuenta || '',
      monto: monto,
    }
    this.nominas_SB.push(nomina);
  }
  async generarExcelPagoItau(tipo: number) {
    try {
      const bancoItau = 2;
      if (tipo === 1) this.calcularNominaPagoRemuneraciones(bancoItau);
      else if (tipo === 2) this.calcularNominaPagoRemuneraciones(bancoItau);

      const datos = {
        nomina: this.nominas_ITAU,
        empresa: this.opciones_elegidas.empresa,
      }

      const res = await this.generarExcelPagoItauServidor(datos);
      if (!res.success) throw Error('Hubo un error al generar el excel.');
      const ruta = `${environment.API}/excels/${res.data}`;
      this.abrirArchivo(ruta);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  }

  async generarExcelPagoScotiabank(tipo: number) {
    try {
      const Scotiabank = 1;
      if (tipo === 1) this.calcularNominaPagoRemuneraciones(Scotiabank);
      else if (tipo === 2) this.calcularNominaPagoRemuneraciones(Scotiabank);

      const datos = {
        nomina: this.nominas_SB,
        empresa: this.opciones_elegidas.empresa,
      }

      const res = await this.generarExcelPagoItauServidor(datos);
      if (!res.success) throw Error('Hubo un error al generar el excel.');
      const ruta = `${environment.API}/excels/${res.data}`;
      this.abrirArchivo(ruta);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  }

  calcularNominaPagoRemuneraciones(banco: number) {
    // 1- SCOTIABANK
    // 2- ITAU
    this.nominas_SB = [];
    this.nominas_ITAU = [];
    for (const libro of this.libro_registros) {
      const trabajador = this.trabajadores.find(x => x.rut === libro.rut);
      if (!trabajador) continue;

      const monto = libro.totalimp - libro.totaldescuento;
      if (banco === 1) this.generarNominaScotiabank(trabajador, monto);
      else this.generarNominaItau(trabajador, monto);
    }
  }
  generarNominaItau(trabajador: any, monto: number) {
    const { rut, nombres, apellidos, Banco, numero_cuenta, tipo_cuenta } = trabajador;
    const rutSinVerificador: string = rut.toString().slice(0, -1);
    const verificador = getLastDigitOfRut(parseInt(rutSinVerificador));

    const nombreTrabajador = `${nombres.trim().split(' ')[0]} ${apellidos.trim()}`;
    const PAGO_SUELDO_TEXT = 'PAGO SUELDO';
    const EMAIL = 'FINANZAS@GRUPOFIRMA.CL';

    const nominaItau: NominaItau = {
      rut: `${rutSinVerificador}${verificador}`,
      trabajador: nombreTrabajador,
      monto: monto,
      medioPago: 'Abono en cuenta',
      codigoBanco: Banco.codigoNomina,
      tipoCuenta: tipo_cuenta.replace('/rut', ''),
      numeroCuenta: numero_cuenta,
      email: EMAIL,
      referenciaCliente: nombreTrabajador,
      glosaOrigen: PAGO_SUELDO_TEXT,
      glosaDestino: PAGO_SUELDO_TEXT,
      detallePago: PAGO_SUELDO_TEXT,
    }
    this.nominas_ITAU.push(nominaItau);
  }

  // ===> FUNCIONES SECUNDARIAS
  async obtenerExcelBase64(ruta: string): Promise<ArrayBuffer> {
    try {
      const res = await fetch(ruta);
      return await res.arrayBuffer();
    } catch (error) {
      console.error(error);
      throw Error('Hubo un error al obtener el excel.');
    }
  }

  abrirArchivo(ruta: string) {
    window.open(ruta, '_blank');
  }

  async obtenerTrabajadores() {
    console.log(this.opciones_elegidas);
    this.trabajadores = [];
    const idempresa = this.opciones_elegidas.empresa.id;
    if (idempresa !== '11-1' && idempresa !== '11-2' && idempresa !== '11-3') {
      const res = await this.trabajadoresService.TraerTrabajadoresPorEmpresaAsync(parseInt(this.opciones_elegidas.empresa.id));
      if (!res.success) throw Error('Hubo un error al obtener los trabajadores.');
      this.trabajadores = res.data;
    } else {
      const res = await this.trabajadoresService.TraerTrabajadoresPorEmpresaAsync(this.CODIGO_EMPRESA_SOLANCH);
      if (!res.success) throw Error('Hubo un error al obtener los trabajadores')
      if (idempresa === '11-1') this.trabajadores = res.data.filter(x => x.Sucursal.id !== 41 && x.Sucursal.id !== 42);
      else if (idempresa === '11-2') this.trabajadores = res.data.filter(x => x.Sucursal.id === 41);
      else if (idempresa === '11-3') this.trabajadores = res.data.filter(x => x.Sucursal.id === 42);

    }
    console.log(this.opciones_elegidas.empresa.nombre, this.trabajadores);
  }

  descargarBlob(blob: Blob) {
    // Crea un enlace para descargar el archivo
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'archivo_modificado.xlsm';
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  // ===> PETICIONES HTTP SERVIDOR
  async generarExcelPagoScotiabankServidor(datos: any): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/generarExcel/scotiabank`, datos).toPromise();
    } catch (error) {
      console.log({ error });
      return { success: false, msg: 'Hubo un error al generar el excel.' } as RequestResponse;
    }
  }
  async generarExcelPagoItauServidor(datos: any): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/generarExcel/itau`, datos).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al generar el excel.' } as RequestResponse;
    }
  }
}
