import { Component, Input, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { HonorarioService } from 'src/app/pages/services/honorarios/honorario.service';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { data } from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-view-honorario',
  templateUrl: './modal-view-honorario.component.html',
  styleUrls: ['./modal-view-honorario.component.css']
})
export class ModalViewHonorarioComponent implements OnInit {
  rutaDocumento: string;
  estadoCurrent: any;
  documento: any;
  FIRMA_NAME = 'FIRMALIQUIDACIONES.png';
  srcDocumento: Uint8Array | string;
  @Input() modalViewHonorario: any;
  honorario: any;
  nombreTrabajador: string;
  nombreEmpresa: any;
  nombreSucursal: any;
  viewButtonDecision: boolean;
  viewButtonModificacion: boolean;
  documento1: any;
  documento2: any;
  rutaDocumento2: string;
  srcDocumento2: Uint8Array;
  decision: string = '';
  motivo: any;

  constructor(
    private pdfLibService: PdflibService,
    private honorariosService: HonorarioService,
    private alert: AlertHelper,
    private empresaService: EmpresaService,
    private sucursalService: SucursalService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: any) {
    if (changes.modalViewHonorario) {
      this.modalViewHonorario = changes.modalViewHonorario.currentValue;
      
      this.honorario = this.modalViewHonorario.honorario;
      const trabajador = this.honorario.trabajadors;
      console.log("HONORARIOOOO: ",this.honorario);
      this.estadoCurrent = this.honorario.estado === 1
        ? 'APROBADA' : this.honorario.estado === 2
        ? 'RECHAZADA' : this.honorario.estado === 0
        ? 'PENDIENTE' : 'ELIMINADA';

      this.nombreTrabajador = trabajador ? trabajador.nombres + ' ' + trabajador.apellidos : '';
      const data = await this.ObtenerEmpresaSucursalTrabajador(trabajador.empresa_contratante, trabajador.sucursal);
      if (data) {
        this.nombreEmpresa = data.empresa.nombre;
        this.nombreSucursal = data.sucursal.nombre;
        // OBTENER EL ULTIMO DOCUMENTO DEL ARRAY
        const documentos = await this.ObtenerDocumento(this.modalViewHonorario.honorario.id);
        this.documento1 = documentos[0];
        this.documento2 = documentos[1];
        //this.documento = this.documento.cp_documento;
        await this.MostrarHonorario(this.honorario.estado);
      }else{
        this.alert.error_small('Hubo un error al obtener la empresa y sucursal');
      }
    }
  }

  async ObtenerEmpresaSucursalTrabajador(empresa_contratante: any, sucursal: any) {
    const resEmpresa = await this.empresaService.Obtener_empresaAsync(empresa_contratante);
    const resSucursal = await this.sucursalService.Obtener_sucursalAsync(sucursal);
    if (resEmpresa.success && resEmpresa.success) {
      const data = {
        empresa: {...resEmpresa.data},
        sucursal: {...resSucursal.data},
      }
      return data;
    }
    return null;
  }

  cambiarEstado(id:number, data: any){
    // const data = {
    //   estado: estado
    // }
    console.log(data);
      this.honorariosService.updateEstadoHonorario(id, data).subscribe( (resp: any) => {
        console.log(resp);
        if(resp.success){
          this.alert.success_small(resp.msg);
          this.modalService.dismissAll();
        }
      })
   }

   cerrarModal(){
    this.modalService.dismissAll();
   }

  async MostrarHonorario(estado: number){
    this.rutaDocumento = `${environment.API}/honorarios/${this.documento1.ruta}`;
    this.rutaDocumento2 = `${environment.API}/honorarios/${this.documento2.ruta}`;
    switch (this.estadoCurrent) {
      case 'APROBADA':
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaHonorarioBlob(this.rutaDocumento, firma);
        //const blobFirma2 = await this.pdfLibService.agregarFirmaHonorarioBlob(this.rutaDocumento2, firma);
        const readerFirma = new FileReader();
        readerFirma.onload = () => {
          this.srcDocumento = new Uint8Array(readerFirma.result as ArrayBuffer);
          this.srcDocumento2 = new Uint8Array(readerFirma.result as ArrayBuffer);
        }
        readerFirma.readAsArrayBuffer(blobFirma);
        //readerFirma.readAsArrayBuffer(blobFirma2);
        break;
      case 'RECHAZADA':
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(this.rutaDocumento, 'RECHAZADA');
        const renderMarca = new FileReader();
        renderMarca.onload = () => {
          this.srcDocumento = new Uint8Array(renderMarca.result as ArrayBuffer);
        }
        renderMarca.readAsArrayBuffer(blobMarca);
        break;
      default:
        this.srcDocumento = this.rutaDocumento;
        break;
    }
  }


  async ObtenerDocumento(id: number) {
    const res = await this.honorariosService.TraerArchivoAsync(id);
    if (!res.success) {
      console.error(res.msg);
      this.alert.errorAlert('Hubo un error al obtener el archivo.');
      return;
    }
    return res.data.filter(x => !x.fechaFirma);
  }

  async DescargarHonorario(){
    const estado = this.estadoCurrent;
    switch (estado) {
      case 'APROBADA':
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaHonorarioBlob(this.rutaDocumento, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 'RECAHAZADA':
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(this.rutaDocumento, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break; 
      default:
        saveAs(this.rutaDocumento, this.nombreTrabajador.replace(' ','')+'-'+new Date().getTime()+'.pdf');
        break;
    }
  }

  async DescargarHonorarioBoleta(){
    const estado = this.estadoCurrent;
    switch (estado) {
      case 'APROBADA':
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaHonorarioBlob(this.rutaDocumento2, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 'RECAHAZADA':
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(this.rutaDocumento2, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break; 
      default:
        saveAs(this.rutaDocumento2, this.nombreTrabajador.replace(' ','')+'-'+new Date().getTime()+'.pdf');
        break;
    }
  }

  cambiarDecision(){
    
    if(this.honorario.estado == 2){
      const data = {
        estado: 1,
        motivo: null,
      }
       this.cambiarEstado(this.honorario.id, data);

    }
    else{
      this.decision = 'RECHAZADO';
      
    }
  }

  rechazar(){
    Swal.fire({
      title: '¿Desea rechazar la solicitud?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
       const data = {
         estado: 2,
         motivo: this.motivo
        }
        console.log("Datos para rechazar: ", data);
        this.cambiarEstado(this.honorario.id, data);
      }
    })
  }

}
