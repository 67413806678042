import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Permiso } from 'src/app/shared/models/permisos.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subir-permisos',
  templateUrl: './subir-permisos.component.html',
  styleUrls: ['./subir-permisos.component.css']
})
export class SubirPermisosComponent implements OnInit {
  @Input() rut: number;
  fechas: Date[];
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/permiso/documento`,
    itemAlias: 'permiso',
    authToken: `Bearer ${localStorage.getItem('token')}`,
  });
  permiso: Permiso = {
    fk_trabajador: 0,
    fecha: '',
    motivo: '',
  }
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
  ) { }

  ngOnInit() {
    this.permiso.fk_trabajador = this.rut;
    this.configurarUploader();
  }
  configurarUploader() {
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      const result = JSON.parse(response);
      console.log({result});
      if (result?.response == true) {
        this.alert.success_small('Permiso subido correctamente');
        this.modalService.dismissAll();
        this.uploader.clearQueue();
      } else {
        this.uploader.clearQueue();
        this.alert.error_small('Hubo un error al subir el permiso, intente nuevamente');
      }
    }
    this.uploader.onErrorItem = (item, response, status, headers) => {
      this.uploader.clearQueue();
      this.alert.error_small('Hubo un error al subir el permiso, intente nuevamente');
    }
    // Estas funciones son propias del uploader , nose para que son necesarias la verdad
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {

      form.append('fk_trabajador', this.permiso.fk_trabajador);
      form.append('fecha', this.permiso.fecha);
      form.append('motivo', this.permiso.motivo);
    };
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  SubirPermiso(){
    const fechasString = this.fechas.map(fecha => fecha.toISOString().split('T')[0]);
    const fechasSplit = fechasString.join(',');
    console.log({...this.fechas}, {fechasSplit});
    this.permiso.fecha = fechasSplit;
    this.uploader.uploadAll();
  }

}
