<h1 mat-dialog-title>Detalles de la Denuncia</h1>
<div mat-dialog-content>
  <h1>Información del denunciante:</h1>
  <p><strong>Nombre Denunciante:</strong> {{ data.nombre_denunciante }} {{ data.apellido_denunciante }}</p>
  <p><strong>RUT:</strong> {{ data.rut_denunciante }}</p>
  <p><strong>Celular:</strong> {{ data.celular_denunciante }}</p>
  <p><strong>Correo electrónico:</strong> {{ data.correo_denunciante }}</p>
  <p><strong>Sede:</strong> {{ data.sede_denunciante }}</p>
  
  <h1>Información del denunciado:</h1>
  <p><strong>Nombre Denunciado:</strong> {{ data.nombre_denunciado }} {{ data.apellido_denunciado }}</p>
  <p><strong>Cargo:</strong> {{ data.cargo_denunciado }}</p>
  <p><strong>Sede:</strong> {{ data.sede_denunciado }}</p>
  
  <h1>Información denuncia:</h1>
  <p><strong>Fecha de Creación:</strong> {{ data.createdAt }}</p>
  <p><strong>Código:</strong> {{ data.codigo }}</p>
  <p><strong>Estado:</strong> {{ data.estado }}</p>
  <p><strong>Asistencia Psicológica:</strong> {{ data.asissicologica === 1 ? 'Sí' : 'No' }}</p>
    <p><strong>Denuncia:</strong> {{ data.denuncia }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cerrar</button>
</div>
