import { Component, OnInit } from '@angular/core';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-main-razon',
  templateUrl: './main-razon.component.html',
  styleUrls: ['./main-razon.component.css'],
  providers: [NgbAlertConfig]

})

export class MainRazonComponent implements OnInit {

 

  constructor(

   

  ) { }

 
  ngOnInit(): void {
  }



}