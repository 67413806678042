import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FirmasService } from 'src/app/pages/services/firmas.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Firma } from 'src/app/shared/models/firmas.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listar-firmas',
  templateUrl: './listar-firmas.component.html',
  styleUrls: ['./listar-firmas.component.css']
})
export class ListarFirmasComponent implements OnInit {
  @Input() firmas: Firma[];
  @Output() actualizarTabla = new EventEmitter<boolean>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: MatTableDataSource<Firma>;
  displayedColumns: string[] = ['nombre', 'empresas', 'firma', 'acciones'];

  URL_BASE_API: string = environment.API;

  constructor(
    private firmaService: FirmasService,
    private alert: AlertHelper,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.firmas) {
      this.firmas = changes.firmas.currentValue;
      this.configurarTable();
    }
  }
  configurarTable() {
    this.dataSource = new MatTableDataSource<Firma>(this.firmas);
    this.dataSource.paginator = this.paginator;
  }
  async EliminarMotivo(firma: Firma) {
    if (!confirm(`¿Está seguro de eliminar: ${firma.nombre}?`)) return;
    const res = await this.firmaService.updateFirma({ ...firma, eliminado: true });
    if (res.success) {
      this.actualizarTabla.emit(true);
      this.alert.success_small('Firma eliminada correctamente.');
    }else{
      this.alert.error_small(res.msg);
    }
  }
  TraerDatos(){
    this.actualizarTabla.emit(true);
  }

}