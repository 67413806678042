import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DenunciasService } from 'src/app/pages/services/denuncias/denuncia.service';
import { Denuncias } from 'src/app/shared/models/denuncia.interface';
import { DenunciaDetailDialogComponent } from '../denuncia-detail-dialog/denuncia-detail-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { MostrarDocumentoDenunciasComponent } from '../mostrar-documento-denuncias/mostrar-documento-denuncias.component';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-list-denuncias',
  templateUrl: './list-denuncias.component.html',
  styleUrls: ['./list-denuncias.component.css']
})
export class ListDenunciasComponent implements OnInit {
  displayedColumns: string[] = ['nombre_denunciante', 'nombre_denunciado', 'estado', 'createdAt', 'codigo', 'actions'];
  dataSource = new MatTableDataSource<Denuncias>();
  statuses = [
    'Denuncia ingresada',
    'En etapa de investigación',
    'Cierre de la investigación',
    'Análisis del proceso investigativo',
    'Remisión de antecedentes a la Dirección del trabajo',
    'En espera de la resolución de la Dirección del trabajo',
    'Cierre del proceso'
  ];
  denuncia_update:any={
    codigo:"",
    estado:"",
  }
  textoBuscar: string = '';


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private denunciasService: DenunciasService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getDenuncias();
  }

  getDenuncias() {
    this.denunciasService.TraerDenuncias().subscribe(
      (res) => {
        this.dataSource.data = res.data.map((item: any) => ({
          ...item,
          createdAt: this.formatDate(item.createdAt)
        }));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error) => {
        console.error("Error al obtener denuncias: ", error);
      }
    );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  applyFilter() {
    const filterValue = this.textoBuscar.trim().toLowerCase();
    this.dataSource.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  

  onStatusChange(newStatus: string, element: Denuncias) {
    this.denuncia_update.estado = newStatus; 
  }

  openDialog(codigo: string): void {
    this.denunciasService.obtenerDenunciaPorCodigo(codigo).subscribe(
      (res) => {
        if (res.success) {
          this.dialog.open(DenunciaDetailDialogComponent, {
            width: '600px',
            data: res.data 
          });
        } else {
          console.error('Error al obtener los detalles de la denuncia');
        }
      },
      (error) => {
        console.error('Error en la solicitud al backend: ', error);
      }
    );
  }

  updateStatus(element: Denuncias, newStatus: string): void {
    const updatedElement = { ...element, estado: newStatus };
      this.denunciasService.updateDenunciaStatus(updatedElement).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'Estado actualizado correctamente.',
          timer: 1500, 
          timerProgressBar: true, 
          showConfirmButton: false 
        });
        const index = this.dataSource.data.findIndex(d => d.codigo === element.codigo);
        if (index > -1) {
          this.dataSource.data[index] = updatedElement;
          this.dataSource._updateChangeSubscription();
        }
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al actualizar el estado.',
          timer: 2000, 
          timerProgressBar: true, 
          showConfirmButton: false 
        });
        console.error('Error al actualizar el estado de la denuncia: ', error);
      }
    );
  }

  openUploadDialog(codigo: string): void {
    this.dialog.open(UploadDialogComponent, {
      width: '400px',
      data: { denunciaId: codigo } 
    });
  }
  openDocumentsModal(codigo: string): void {
    this.dialog.open(MostrarDocumentoDenunciasComponent, {
      data: { codigo: codigo },
      width: '500px'
    });
  }

}
