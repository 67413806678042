<button (click)="OpenEditar(content)" mat-button class="button_color_primary">
    <mat-icon style="color: white">edit</mat-icon>
  </button>
  
  <ng-template #content let-modal>
    <div class="modal-header">
      <mat-card-title>Editar firma</mat-card-title>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Ingrese nombre de la firma" [(ngModel)]="firmaEdit.nombre" required>
          </mat-form-field>
        </div>
        <div class="col-12" *ngIf="!fileSrc">
          <input id="fileFirma" type="file" class="form-control" ng2FileSelect [uploader]="uploader"
            accept=".jpg,.jpeg,.png" />
          <mat-hint>El archivo debe ser de tipo imagen transparente para tener un buen resultado</mat-hint>
        </div>
        <!-- IMAGEN URL -->
        <div class="col-12" *ngIf="!fileSrc && firmaEdit?.rutImg !== ''">
          <img class="img-fluid rounded mx-auto d-block" [src]="URL_BASE_API+firmaEdit.rutaImg" alt="preview firma url">
        </div>
        <!-- IMAGEN SRC -->
        <div class="col-12" *ngIf="fileSrc">
          <img class="img-fluid rounded mx-auto d-block" [src]="fileSrc" alt="preview firma reader">
          <button (click)="quitarImagen()" type="button" class="btn btn-secondary btn-lg btn-block">No cambiar firma</button>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
      <button type="button" class="btn btn-warning" (click)="EditarFirma()">Editar firma</button>
    </div>
  </ng-template>
