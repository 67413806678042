import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';

import { MainResumenHaberesComponent } from './main-resumen-haberes/main-resumen-haberes.component';
import { GenerarResumenHaberesComponent } from './generar-resumen-haberes/generar-resumen-haberes.component';



@NgModule({
  declarations: [
    MainResumenHaberesComponent,
    GenerarResumenHaberesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    PrimeNgModule,
  ]
})
export class ResumenHaberesModule { }
