<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo de Prevision</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content border border-primary">
      <app-agregar-afp
        (onDebounce)="actualizarTabla($event)"
      ></app-agregar-afp>
      <br />
      <app-lista-afp
        (onDebounce)="actualizarTabla($event)"
        [afps_List]="afp"
      ></app-lista-afp>
    </mat-card>
    <hr />
  </div>
  