import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RegionesService } from 'src/app/pages/services/regiones.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Region } from 'src/app/shared/models/region.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-regiones',
  templateUrl: './lista-regiones.component.html',
  styleUrls: ['./lista-regiones.component.css']
})
export class ListaRegionesComponent implements OnInit {
  @Output() actualizarTabla = new EventEmitter<boolean>(false);
  @Input('regiones') regiones: Region[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  optionsNumeric = {
    digitGroupSeparator: '', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  displayedColumns: string[] = ['nombre', 'codigo_lre', 'accion'];
  dataSource: any;
  texto_buscador: string = '';
  region:Region = {
    id: 0,
    codigo_lre: 0,
    nombre: '',
  }

  constructor(
    private regionService: RegionesService,
    private alert:AlertHelper,
    private modalService:NgbModal,
  ) { }

  ngOnInit(): void {
  }
  // CUANDO EL ATRIBUTO REGIONES ESTE CARGADO, SE CARGA LA TABLA
  ngOnChanges(changes: any) {
    const regiones: Region[] = changes.regiones.currentValue;
    this.cargarDatosTabla(regiones)
  }
  cargarDatosTabla(regiones: Region[]) {
    this.dataSource = new MatTableDataSource<Region>(regiones);
    this.dataSource.paginator = this.paginator;
  }

  Eliminar_Region(id: number) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara la región",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.regionService.Eliminar_Ciudad(id).subscribe(res => {
          if (res.success == true) {
            this.actualizarTabla.emit(true);
            this.alert.success_small(res.msg!);
          } else {
            this.alert.error_small(res.msg!);
          }
        })
      }
    });
  }

  open(content: any, id: number){
    this.region.id = id;
    this.regionService.BuscarTrabajador(id).subscribe((res) => {
      if (res.success) {
        this.region = res.data;
        this.modalService.open(content, { size: 'lg' });
      }else{
        this.alert.errorAlert('Ocurrio un error al obtener los datos del trabajador.');
      }
    });
  }

  ActualizarRegion(){
    this.regionService.ActualizarTrabajador(this.region).subscribe(res => {
      if (res.success) {
        this.actualizarTabla.emit(true);
        this.modalService.dismissAll();
        this.alert.success_small(res.msg!);
      }else{
        this.alert.error_small(res.msg!);
      }
    })
  }

  // BUSCADOR DE LA TABLA REGIONES (AUN NO IMPLEMENTADO)
  buscar() {
    let regionesAux:Region[] = [];
    if (this.texto_buscador.length === 0) {
      return;
    }
    if (isNaN(parseFloat(this.texto_buscador))) {
      regionesAux = this.regiones.filter(x => {
        const nombreNormalizado = x.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
        const textoBuscar = this.texto_buscador.toUpperCase();
        return nombreNormalizado.toUpperCase().includes(textoBuscar)
      });
    }else{
      regionesAux = this.regiones.filter(x => x.codigo_lre.toString().includes(this.texto_buscador))
    }
    this.cargarDatosTabla(regionesAux);
    // console.log(this.regiones);
  }
}