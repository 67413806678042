import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Region, RequestResponseGetAllRegion } from 'src/app/shared/models/region.interface';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionesService {
  constructor(
    private http: HttpClient, 
    private alert: AlertHelper,
  ) { }

  TraerRegiones(): Observable<RequestResponseGetAllRegion> {
    return this.http.get<RequestResponseGetAllRegion>(`${environment.API}/rrhh/region/`)
      .pipe(
        map((res:RequestResponseGetAllRegion) => res),
        catchError((err)=> this.handlerError(err))
      );
  }
  async TraerRegionesAsync(): Promise<RequestResponseGetAllRegion>{
    try {
      return await this.http.get<RequestResponseGetAllRegion>(`${environment.API}/rrhh/region/`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener las regiones.'} as RequestResponseGetAllRegion
    }
  }

  BuscarTrabajador(id:number):Observable<RequestResponse> {
    return this.http.get<RequestResponse>(`${environment.API}/rrhh/region/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError(error => this.handlerError(error)),
      );
  }

  Crear_Region(region:Region): Observable<RequestResponse> {      
    return this.http
      .post<RequestResponse >(`${environment.API}/rrhh/region/crear`, region)
      .pipe(
        map((res:RequestResponse) => res),
        catchError((err)=> this.handlerError(err))
      );
  }

  Eliminar_Ciudad(id: number): Observable<RequestResponse>{
    return this.http.delete<RequestResponse>(`${environment.API}/rrhh/region/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError((error) => this.handlerError(error)),
      );
  }

  ActualizarTrabajador(region:Region): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/region/actualizar/${region.id}`,region)
    .pipe(
      map((res:RequestResponse) => res),
      catchError((err)=> this.handlerError(err))
    );
  }

  private handlerError(err: any):Observable<never>{
    const errorMessage = err ? `Error: ${err.error.msg}` : '';
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
