<div>
    <button
      style="background-color: rgb(207, 51, 3);"
      (click)="GenerarPdf()"
      mat-raised-button
    >
      Exportar a PDF
    </button>
  </div>

  <mat-stepper [linear]="false" #stepper>
    <!-- primera pestaña  -->
    <mat-step label="Datos Trabajador">
      <ng-template matStepLabel>Datos Trabajador</ng-template>
      <div class="col-md-12">
        <h4 style="color:#2a3f54"><b>I ) INFORMACIÓN DE TRABAJADOR</b></h4>
        <ngx-spinner size="medium" color="#ff0000" type="ball-scale-multiple">
        </ngx-spinner>

        <div class="form-row">
          <div class="form-group col-md-3">
            <p-dropdown
              [options]="lista_trabajadores"
              (onChange)="ObtenerTrabajador($event)"
              [(ngModel)]="TrabajadorSeleccionado"
              placeholder="TRABAJADOR"
              optionLabel="nombreCompleto"
              [filter]="true"
              filterBy="nombreCompleto"
              [showClear]="true"
              [style]="{ width: '100%' }"
            >
            </p-dropdown>
          </div>
          <div class="form-group col-md-3">
            <p-dropdown
              [options]="lista_ciudades"
              [(ngModel)]="CiudadSeleccionada"
              placeholder="CIUDAD EN QUE SE FIRMA CONTRATO"
              optionLabel="nombre"
              [filter]="true"
              filterBy="nombre"
              [showClear]="true"
              [style]="{ width: '100%' }"
            >
            </p-dropdown>
          </div>
          <div class="form-group col-md-3">
            <p-inputNumber
              inputId="sueldo"
              mode="currency"
              (keydown.enter)="pulsar($event)"
              [(ngModel)]="sueldo"
              placeholder="SUELDO BASE"
              currency="CLP"
              locale="en-US"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
            >
            </p-inputNumber>
          </div>
          <div
            class="form-group col-md-3"
            *ngIf="render == 1 && TrabajadorSeleccionado.cargo == 3"
          >
            <input
              type="text"
              placeholder="Comuna de trabajo"
              [(ngModel)]="ComunaConductor"
              pInputText
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
            />
          </div>
          <div *ngIf="render == 0">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div *ngIf="render == 1" class="col-md-12">
            <br />
            <form>
              <!-- primera fila  -->
              <div class="form-row">
                <div class="form-group col-md-3">
                  <h4 style="color:#2a3f54"><b>Rut</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="rut"
                    pInputText
                    [disabled]="true"
                    [ngModel]="TrabajadorSeleccionado.rut | formatoRut"
                  />
                </div>
                <div class="form-group col-md-3">
                  <h4 style="color:#2a3f54"><b>Nombres</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="nombres"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.nombres"
                  />
                </div>
                <div class="form-group col-md-3">
                  <h4 style="color:#2a3f54"><b>Apellidos</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="apellidos"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.apellidos"
                  />
                </div>
                <div class="form-group col-md-3">
                  <h4 style="color:#2a3f54"><b>Cargo</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="cargo"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.Cargo.nombre"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <h4 style="color:#2a3f54"><b>Jornada de trabajo</b></h4>
                  <textarea
                    [disabled]="true"
                    [(ngModel)]="
                      TrabajadorSeleccionado.Cargo.jornadas.descripcion
                    "
                    class="form-control"
                    name="algo"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <!-- segunda fila -->
              <div class="form-row">
                <div class="form-group col-md-4">
                  <h4 style="color:#2a3f54"><b>Direccion</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="Direccion"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.direccion"
                  />
                </div>
                <div class="form-group col-md-4">
                  <h4 style="color:#2a3f54"><b>Afp</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="Afp"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.Afp.nombre"
                  />
                </div>
                <div class="form-group col-md-4">
                  <h4 style="color:#2a3f54"><b>Ciudad</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="ciudad"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.Ciudad.nombre"
                  />
                </div>
              </div>
              <!-- tercera fila -->
              <div class="form-row">
                <div class="form-group col-md-4">
                  <h4 style="color:#2a3f54"><b>Previción</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="previcion"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.Prevision.nombre"
                  />
                </div>
                <div class="form-group col-md-4">
                  <h4 style="color:#2a3f54"><b>Nacionalidad</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="nacionalidad"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.Nacionalidad.nombre"
                  />
                </div>
                <div class="form-group col-md-4">
                  <h4 style="color:#2a3f54"><b>fecha de nacimiento</b></h4>
                  <input
                    style="width: inherit;"
                    type="text"
                    name="nacimieno"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.fecha_nacimiento"
                  />
                </div>
              </div>
              <!-- cuarta fila -->
              <div class="form-row">
                <div class="form-group col-md-6" style="width: 100%;">
                  <h4 style="color:#2a3f54"><b>Estado civil</b></h4>
                  <input
                    style="width: 100%;"
                    type="text"
                    name="civil"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.Estado_civil.nombre"
                  />
                </div>
                <div class="form-group col-md-6" style="width: 100%;">
                  <h4 style="color:#2a3f54"><b>Correo Electronico</b></h4>
                  <input
                    style="width: 100%;"
                    type="text"
                    name="correo"
                    pInputText
                    [disabled]="true"
                    [(ngModel)]="TrabajadorSeleccionado.correo"
                  />
                </div>
              </div>
              <!-- fin cuarta fila -->
            </form>
          </div>
        </div>
      </div>
      <div></div>
      <div>
        <button mat-button matStepperPrevious>
          <mat-icon>navigate_before</mat-icon>Anterior
        </button>
        <button style="float: right;" mat-button matStepperNext>
          <mat-icon>navigate_next</mat-icon>Siguiente
        </button>
      </div>
    </mat-step>
    <!-- fin primera pestaña -->

    <!-- segundo paso: datos de la empresa -->
    <mat-step label="Datos Empresa">
      <ng-template matStepLabel>Datos Empresa</ng-template>

      <div *ngIf="render == 1" class="col-md-12">
        <h4 style="color:#2a3f54"><b>II ) EMPRESA</b></h4>
        <!-- primera fila -->
        <div class="form-row">
          <div class="form-group col-md-4">
            <h4 style="color:#2a3f54"><b>Empresa</b></h4>
            <input
              style="width: inherit;"
              type="text"
              name="empresa"
              pInputText
              [disabled]="true"
              [(ngModel)]="TrabajadorSeleccionado.Empresa_contratante.nombre"
            />
          </div>
          <div class="form-group col-md-4">
            <h4 style="color:#2a3f54"><b>Cargo</b></h4>
            <input
              style="width: inherit;"
              type="text"
              name="cargo"
              pInputText
              [disabled]="true"
              [(ngModel)]="TrabajadorSeleccionado.Cargo.nombre"
            />
          </div>
          <div class="form-group col-md-4">
            <h4 style="color:#2a3f54"><b>Departamento</b></h4>
            <input
              style="width: inherit;"
              type="text"
              name="departamento"
              pInputText
              [disabled]="true"
              [(ngModel)]="TrabajadorSeleccionado.Cargo.departamento"
            />
          </div>
        </div>
        <!-- segunda fila -->
        <div class="form-row">
          <div class="form-group col-md-6">
            <h4 style="color:#2a3f54"><b>Representante legal</b></h4>
            <input
              style="width: inherit;"
              type="text"
              name="representante"
              pInputText
              [disabled]="true"
              [(ngModel)]="
                TrabajadorSeleccionado.Empresa_contratante.representante
              "
            />
          </div>
        </div>
      </div>
      <div>
        <button mat-button matStepperPrevious>
          <mat-icon>navigate_before</mat-icon>Anterior
        </button>
        <button style="float: right;" mat-button matStepperNext>
          <mat-icon>navigate_next</mat-icon>Siguiente
        </button>
      </div>
    </mat-step>

    <!-- fin datos de empresa -->
    <!-- tercera pestaña: duracion del contrato -->
    <mat-step>
      <ng-template matStepLabel>Duracion de Contrato</ng-template>
      <div class="form-group">
        <div class="row">

          <!---- Plazo Fijo---->
        <div class="form-group col-md-1" >
          <h4 style="color:#2a3f54"><b>Plazo Fijo</b></h4>
          <span #plazofijo><p-inputSwitch [(ngModel)]="PazoFijo" (click)="checkPlazoFijo()"></p-inputSwitch></span>
        </div>
        <div class="form-group col-md-3">
          <h4 style="color:#2a3f54"><b>Obras y Faenas</b></h4>
          <p-inputSwitch [(ngModel)]="ObrasFaenas" (click)="checkObrasFaenas()"></p-inputSwitch>
        </div>
      </div>
      </div>

        <div *ngIf="PazoFijo">
          <br />
          <mat-form-field appearance="outline" style="width: 49%;">
            <mat-label> Fecha de Inicio</mat-label>
            <input
              (click)="fecha_inicio.open()"
              (focus)="fecha_inicio.open()"
              [(ngModel)]="Fechas.fecha_inicio"
              required
              matInput
              [matDatepicker]="fecha_inicio"
              name="fecha_inicioo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fecha_inicio"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #fecha_inicio></mat-datepicker>
            <!-- fecha de emisión -->
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            style="width: 49%;border-left-width: 10px;margin-left: 20px;"
          >
            <mat-label> Fecha Fin</mat-label>
            <input
              (click)="fecha_fin.open()"
              (focus)="fecha_fin.open()"
              [(ngModel)]="Fechas.fecha_fin"
              required
              matInput
              [matDatepicker]="fecha_fin"
              name="fecha_fin"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fecha_fin"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #fecha_fin></mat-datepicker>
            <!-- fecha de emisión -->
          </mat-form-field>
        </div>


      <div *ngIf="ObrasFaenas">
        <br />
        <mat-form-field appearance="outline" style="width: 49%;">
          <mat-label> Fecha de Inicio</mat-label>
          <input
            (click)="fecha_inicio.open()"
            (focus)="fecha_inicio.open()"
            [(ngModel)]="Fechas.fecha_inicio"
            required
            matInput
            [matDatepicker]="fecha_inicio"
            name="fecha_iniciooo"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fecha_inicio"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #fecha_inicio></mat-datepicker>
          <!-- fecha de emisión -->
        </mat-form-field>

      </div>

        <div>
          <button mat-button matStepperPrevious>
            <mat-icon>navigate_before</mat-icon>Anterior
          </button>
          <button style="float: right;" mat-button matStepperNext>
            <mat-icon>navigate_next</mat-icon>Siguiente
          </button>
        </div>

    </mat-step>
    <!-- fin tercera pestaña -->
    <!-- cuarta pestaña : haberes y sucursales -->
    <mat-step>
      <ng-template matStepLabel>Haberes y Sucursales</ng-template>
      <div class="form-group">
        <div class="col-md-12">
          <h4 style="color:#2a3f54"><b>Haberes</b></h4>
          <app-listar-haberes-trabajador
            [rut]="rut"
          ></app-listar-haberes-trabajador>
        </div>
        <div class="col-md-12">
          <h4 style="color:#2a3f54"><b>Sucursales</b> <b> </b></h4>
          <button
            mat-raised-button
            (click)="MostrarModalSucursales()"
            style="background-color: rgb(109, 181, 214);"
          >
            <span style="color: white">Ir a Sucursales </span>
            <mat-icon style="color: white">add</mat-icon>
          </button>
          <p-listbox
            [options]="sucursales"
            [(ngModel)]="SucursalesSeleccionadas"
            [metaKeySelection]="false"
            [checkbox]="true"
            [filter]="true"
            [multiple]="true"
            optionLabel="nombre"
            [listStyle]="{ 'max-height': '100%' }"
            [style]="{ width: '100%' }"
          >
            <ng-template let-sucursal pTemplate="item">
              <div class="country-item">
                <div>
                  {{ sucursal.nombre }} dirección: {{ sucursal.direccion }}
                </div>
              </div>
            </ng-template>
          </p-listbox>
        </div>
      </div>
    </mat-step>
    <!-- fin cuarta pestaña -->
  </mat-stepper>

  <!-- Modal -->

  <ng-template style="border: 0px; z-index: 100" #content3 let-modal3>
    <div class="modal-header" style="text-align: center">
      <mat-card-title>Generar PDF contrato</mat-card-title>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal3.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-modal-mostrar-pdf [src]="src"></app-modal-mostrar-pdf>
    </div>
  </ng-template>

  <!-- Fin Modal -->

  <!-- modal con primeng -->
  <p-dialog
    [(visible)]="MostrarSucursales"
    (onHide)="TraerDatos()"
    [modal]="true"
    [style]="{ width: '100%' }"
    [maximizable]="true"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <app-main-sucursales></app-main-sucursales>
  </p-dialog>

  <!-- <p-dialog [(visible)]="VerFotos" (onHide)="StopSound()" [modal]="true" [style]="{width: '100%'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

  </p-dialog> -->

  <!-- fin modal on primeng -->
