<div class="mt-3 container-fluid animate__animated animate__slideInUp">
    <mat-card>
        <mat-card-title>Generar Certificado de Sueldo Anual</mat-card-title>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content class="container">
            <app-generar-certificado></app-generar-certificado>
        </mat-card-content>
    </mat-card>
</div>