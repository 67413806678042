<div>
  <button mat-button class="button_color" (click)="open(content)">
    <span style="color: white">Agregar Trabajador</span>
  </button>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar Trabajador</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos personales</b>
          </div>
        </p-divider>
      </div>
      <!-- (blur)="verificaTrabajador()" -->
      <div class="col col-12">
        <!-- <p-inputMask [(ngModel)]="trabajador.rut" [unmask]="true" required [mask]="maskRut" required (blur)="verificaTrabajador()" (ngModelChange)="verificarLargoRut()"
          styleClass="example-full-width"></p-inputMask> -->
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Rut</mat-label>
          <input type="text" name="rut" matInput [(ngModel)]="trabajador.rut" required (blur)="verificaTrabajador()" (keyup)="formatearRut($event)"
            minlength="7">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombres</mat-label>
          <input type="text" name="nombres" matInput [(ngModel)]="trabajador.nombres" required maxlength="150"
            minlength="3" />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Apellidos</mat-label>
          <input type="text" name="apellidos" matInput [(ngModel)]="trabajador.apellidos" required maxlength="150"
            minlength="3" />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Teléfono Móvil</mat-label>
          <!-- <input type="number" name="telefono" min="0" max="999999999" matInput [(ngModel)]="trabajador.telefono" required /> -->
          <input type="text" name="telefono" maxlength="12" matInput [(ngModel)]="trabajador.telefono"
            required />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Correo</mat-label>
          <input type="text" name="correo" minlength="10" maxlength="50" matInput [(ngModel)]="trabajador.correo" />
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Fecha De Nacimiento</mat-label>
          <input matInput [matDatepicker]="fecha_nacimiento" [min]="minDate" [max]="maxDate" required
            (click)="fecha_nacimiento.open()" (focus)="fecha_nacimiento.open()" name="fecha_nacimiento"
            [(ngModel)]="trabajador.fecha_nacimiento" maxlength="10">
          <mat-datepicker-toggle matSuffix [for]="fecha_nacimiento"></mat-datepicker-toggle>
          <mat-datepicker touchUi #fecha_nacimiento></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Nacionalidad</mat-label>
          <mat-select [(ngModel)]="trabajador.nacionalidad" required>
            <mat-option class="example-full-width " *ngFor="let nacionalidad of nacionalidades"
              [value]="nacionalidad.id ">
              {{nacionalidad.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Sexo</mat-label>
          <mat-select (selectionChange)="ObtenerSexo($event)" required>
            <mat-option class="example-full-width " *ngFor="let sexo of sexos" [value]="sexo.value ">
              {{sexo.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Profesión</mat-label>
          <input type="text" matInput name="profesion" [(ngModel)]="trabajador.profesion" required maxlength="150"
            minlength="3" />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Estado Civil</mat-label>
          <mat-select (selectionChange)="ObtenerEstadoCivil($event)" required>
            <mat-option class="example-full-width " *ngFor="let civil of estado_civil " [value]="civil.id ">
              {{civil.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-12">
        <div class="row">
          <div class="col col-12">
            <table style="width:100% ">
              <tr>
                <td>
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Seleccion Region</mat-label>
                                  <mat-select (selectionChange)="ObtenerRegion($event)" required placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let region of region"
                                     >
                                      {{region.nombre}}
                                  </mat-option>
                                  </mat-select>
                              </mat-form-field>  -->

                  <p-dropdown [options]="region" (onChange)="ObtenerRegion($event)" [(ngModel)]="trabajadoresregion"
                    [ngModelOptions]="{standalone: true}" placeholder="Regiones" optionLabel="nombre" [filter]="true"
                    filterBy="nombre" [showClear]="true" [style]="{ width: '100%' }">
                  </p-dropdown>


                </td>
                <td *ngIf=" renderciudad == 1">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna XV</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaXV($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaXV of comunaXV"
                                          [value]=" comunaXV.nombre ">
                                          {{comunaXV.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaXV" (onChange)="ObtenerComunaXV($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna XV"
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 2">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna I</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaI($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaI of comunaI"
                                          [value]=" comunaI.nombre ">
                                          {{comunaI.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field>-->
                  <p-dropdown [options]="comunaI" (onChange)="ObtenerComunaI($event)" [(ngModel)]="trabajadorescomunas"
                    [ngModelOptions]="{standalone: true}" placeholder="Comuna I" optionLabel="nombre" [filter]="true"
                    filterBy="nombre" [showClear]="true" [style]="{ width: '100%' }">
                  </p-dropdown>

                </td>
                <td *ngIf=" renderciudad == 3">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna II</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaII($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaII of comunaII"
                                          [value]=" comunaII.nombre ">
                                          {{comunaII.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaII" (onChange)="ObtenerComunaII($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna II "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 4">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna III</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaIII($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaIII of comunaIII"
                                          [value]=" comunaIII.nombre ">
                                          {{comunaIII.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaIII" (onChange)="ObtenerComunaIII($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna III "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 5">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna IV</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaIV($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaIV of comunaIV"
                                          [value]=" comunaIV.nombre ">
                                          {{comunaIV.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaIV" (onChange)="ObtenerComunaIV($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna IV "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 6">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna V</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaV($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaV of comunaV"
                                          [value]=" comunaV.nombre ">
                                          {{comunaV.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaV" (onChange)="ObtenerComunaV($event)" [(ngModel)]="trabajadorescomunas"
                    [ngModelOptions]="{standalone: true}" placeholder="Comuna V " optionLabel="nombre" [filter]="true"
                    filterBy="nombre" [showClear]="true" [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 7">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna XIII</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaXIII($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaXIII of comunaXIII"
                                          [value]=" comunaXIII.nombre ">
                                          {{comunaXIII.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaXIII" (onChange)="ObtenerComunaXIII($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna XIII "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 8">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna VI</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaVI($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaVI of comunaVI"
                                          [value]=" comunaVI.nombre ">
                                          {{comunaVI.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaVI" (onChange)="ObtenerComunaVI($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna VI "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 9">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna VII</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaVII($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaVII of comunaVII"
                                          [value]=" comunaVII.nombre ">
                                          {{comunaVII.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaVII" (onChange)="ObtenerComunaVII($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna VII "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 10">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna XVI</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaXVI($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaXVI of comunaXVI"
                                          [value]="comunaXVI.nombre ">
                                          {{comunaXVI.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaXVI" (onChange)="ObtenerComunaXVI($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna XVI "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 11">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna VIII</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaVIII($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="example-full-width" *ngFor="let comunaVIII of comunaVIII"
                                          [value]=" comunaVIII.nombre ">
                                          {{comunaVIII.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaVIII" (onChange)="ObtenerComunaVIII($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna VIII "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 12">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna IX</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaIX($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="eXIample-full-width" *ngFor="let comunaIX of comunaIX"
                                          [value]="comunaIX.nombre ">
                                          {{comunaIX.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaIX" (onChange)="ObtenerComunaIX($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="ComunaIX "
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 13">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna XIV</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaXIV($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="eXIample-full-width" *ngFor="let comunaXIV of comunaXIV"
                                          [value]="comunaXIV.nombre ">
                                          {{comunaXIV.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaXIV" (onChange)="ObtenerComunaXIV($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna XIV"
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 14">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna X</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaX($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="eXIample-full-width" *ngFor="let comunaX of comunaX"
                                          [value]="comunaX.nombre ">
                                          {{comunaX.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaX" (onChange)="ObtenerComunaX($event)" [(ngModel)]="trabajadorescomunas"
                    [ngModelOptions]="{standalone: true}" placeholder="Comuna X" optionLabel="nombre" [filter]="true"
                    filterBy="nombre" [showClear]="true" [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 15">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna XI</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaXI($event)" required
                                      placeholder="Selecciones Comuna">
                                      <mat-option class="eXIample-full-width" *ngFor="let comunaXI of comunaXI"
                                          [value]="comunaXI.nombre ">
                                          {{comunaXI.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaXI" (onChange)="ObtenerComunaXI($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna XI"
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
                <td *ngIf=" renderciudad == 16">
                  <!-- <mat-form-field class="example-full-width " appearance="outline">
                                  <mat-label>Comuna XII</mat-label>
                                  <mat-select (selectionChange)="ObtenerComunaXI($event)" required
                                      placeholder="Selecciones Region">
                                      <mat-option class="eXIample-full-width" *ngFor="let comunaXII of comunaXII"
                                          [value]=" comunaXII.nombre ">
                                          {{comunaXII.nombre}}
                                      </mat-option>
                                  </mat-select>
                              </mat-form-field> -->
                  <p-dropdown [options]="comunaXII" (onChange)="ObtenerComunaXII($event)"
                    [(ngModel)]="trabajadorescomunas" [ngModelOptions]="{standalone: true}" placeholder="Comuna XII"
                    optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                </td>
              </tr>
              <td *ngIf=" renderciudad == 1">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 15" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 14" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <!--  <td *ngIf=" renderciudad == 3">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Ciudad</mat-label>
                    <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                      <ng-container *ngFor="let ciudad of ciudades">
                        <mat-option *ngIf="ciudad.id == 8 || ciudad.id == 19" class="example-full-width" [value]="ciudad.id">
                          {{ciudad.nombre }}
                        </mat-option>
  
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
              </td>
            -->
              <td *ngIf=" rendercomuna == 19">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 19" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" rendercomuna == 8">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 8" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 4">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 20" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 5">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 4" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <!-- <td *ngIf=" renderciudad == 6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Ciudad</mat-label>
              <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                <ng-container *ngFor="let ciudad of ciudades">
                  <mat-option *ngIf="ciudad.id == 3 || ciudad.id == 11" class="example-full-width" [value]="ciudad.id">
                    {{ciudad.nombre }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
        </td>
  -->
              <td *ngIf=" rendercomuna == 11">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 11" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" rendercomuna == 3">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 3" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 7">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 1" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 8">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 5" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <!-- <td *ngIf=" renderciudad == 9">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Ciudad</mat-label>
        <mat-select (selectionChange)="ObtenerCiudad($event)" required>
          <ng-container *ngFor="let ciudad of ciudades">
            <mat-option *ngIf="ciudad.id == 6 || ciudad.id == 16" class="example-full-width" [value]="ciudad.id">
              {{ciudad.nombre }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
  </td>
  -->

              <td *ngIf=" rendercomuna == 6">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 6" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" rendercomuna == 16">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 16" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 10">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 9" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <!-- <td *ngIf=" renderciudad == 11">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Ciudad</mat-label>
      <mat-select (selectionChange)="ObtenerCiudad($event)" required>
        <ng-container *ngFor="let ciudad of ciudades">
          <mat-option *ngIf="ciudad.id == 2 || ciudad.id == 17" class="example-full-width" [value]="ciudad.id">
            {{ciudad.nombre }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </td>
  -->
              <td *ngIf=" rendercomuna == 2">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 2" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
              <td *ngIf=" rendercomuna == 17">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 17" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 12">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 7" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 13">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 18" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <!-- <td *ngIf=" renderciudad == 14">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Ciudad</mat-label>
      <mat-select (selectionChange)="ObtenerCiudad($event)" required>
        <ng-container *ngFor="let ciudad of ciudades">
          <mat-option *ngIf="ciudad.id == 10 || ciudad.id == 13" class="example-full-width" [value]="ciudad.id">
            {{ciudad.nombre }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </td>
  -->
              <td *ngIf=" rendercomuna == 10">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 10" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" rendercomuna == 13">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 13" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>

                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 15">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 1" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>

              <td *ngIf=" renderciudad == 16">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Ciudad</mat-label>
                  <mat-select (selectionChange)="ObtenerCiudad($event)" required>
                    <ng-container *ngFor="let ciudad of ciudades">
                      <mat-option *ngIf="ciudad.id == 12" class="example-full-width" [value]="ciudad.id">
                        {{ciudad.nombre }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </td>
            </table>
          </div>
        </div>
      </div>
      <div class="col col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Dirección</mat-label>
          <input type="text" matInput name="direccion" [(ngModel)]="trabajador.direccion" required maxlength="250"
            minlength="3" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Otros datos</b>
          </div>
        </p-divider>
      </div>
      <div class="col col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Patologías</mat-label>
          <textarea type="textarea" rows="3" maxlength="200" name="patologias" [(ngModel)]="trabajador.patologias"
            matInput maxlength="250" minlength="3"></textarea>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-slide-toggle #slideJubilado [checked]="trabajador.jubilado"
          (change)="onChangeSlideJubilado($event)">Jubilado</mat-slide-toggle>
      </div>
      <div class="col col-4">
        <mat-slide-toggle #slidePase [checked]="trabajador.pase_movilidad" (change)="onChangeSlidePase($event)">Pase de
          Movilidad</mat-slide-toggle>
      </div>
      <div class="col col-4">
        <mat-slide-toggle #slideLicencia [checked]="trabajador.licencia_conducir"
          (change)="onChangeSlideLicencia($event)">Licencia de Conducir</mat-slide-toggle>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>S. de Cesantía</mat-label>
          <mat-select [(ngModel)]="trabajador.afc" required>
            <mat-option class="example-full-width" *ngFor="let afc of tipo_afc " [value]="afc.id">
              {{afc.valor}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Discapacidad</mat-label>
          <mat-select (selectionChange)="ObtenerDiscapacida($event)" required>
            <mat-option class="example-full-width " *ngFor="let discapacidad of tipo_discapacidad "
              [value]="discapacidad.id">
              {{discapacidad.valor}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Teléfono Móvil (Contacto Emergencia)</mat-label>
          <input type="text" name="telefono_contacto_emergencia" maxlength="12" matInput
            [(ngModel)]="trabajador.telefono_contacto_emergencia" required />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre del Contacto</mat-label>
          <input type="text" name="nombre_contacto_emergencia" minlength="3" maxlength="150" matInput
            [(ngModel)]="trabajador.nombre_contacto_emergencia" required />
        </mat-form-field>
      </div>
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos de contrato</b>
          </div>
        </p-divider>
      </div>
      <div class="col col-3">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Estado del Contrato</mat-label>
          <mat-select [(ngModel)]="trabajador.estado_contrato" required>
            <mat-option class="example-full-width " *ngFor="let contrato of estado_contrato " [value]="contrato.valor">
              {{contrato.valor}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Tipo de Jornada</mat-label>
          <mat-select [(ngModel)]="trabajador.tipo_jornada" required>
            <mat-option class="example-full-width " *ngFor="let tipo of tipo_jornada" [value]="tipo.id">
              {{tipo.valor}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Horas pactadas semanales</mat-label>
          <input type="text" matInput [(ngModel)]="trabajador.horasPactadas"  (focus)="soloNumeros($event, false)" [options]="optionsNumeric" required
            minlength="1" />
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Dias trabajados por semana</mat-label>
          <mat-select [(ngModel)]="trabajador.dias_semana" required>
            <mat-option class="example-full-width " *ngFor="let dias of dias_semana " [value]="dias.value">
              {{dias.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Cargo</mat-label>
          <mat-select (selectionChange)="ObtenerCargo($event)" required>
            <mat-option class="example-full-width " *ngFor="let cargo of cargos " [value]="cargo.id">
              {{cargo.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Sucursal</mat-label>
          <mat-select (selectionChange)="ObtenerSucursal($event)" required>
            <mat-option class="example-full-width " *ngFor="let sucursal of sucursales " [value]="sucursal.id ">
              {{sucursal.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Empresa Contratante</mat-label>
          <mat-select (selectionChange)="ObtenerEmpresaContratante($event)" required>
            <mat-option class="example-full-width " *ngFor="let empresa of empresas " [value]="empresa.id ">
              {{empresa.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos de prevención</b>
          </div>
        </p-divider>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>AFP</mat-label>
          <mat-select [(ngModel)]="trabajador.afp" [required]="requiredAfp">
            <mat-option class="example-full-width " *ngFor="let afp of afps" [value]="afp.id">
              {{afp.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Previsión de Salud</mat-label>
          <mat-select (selectionChange)="ObtenerPrevision($event)" [(ngModel)]="trabajador.prevision" required>
            <mat-option class="example-full-width " *ngFor="let prevision of previsiones " [value]="prevision.id ">
              {{prevision.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Monto pactado (UF)</mat-label>
          <input type="text" name="pactado_isapre" matInput autonumeric [options]="optionsDecimal"
            [(ngModel)]="trabajador.pactado_isapre" [disabled]="disableValorIsapre" [required]="requiredUfIsapre"
            (focus)="soloNumeros($event, false)" />
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Ahorro APV</mat-label>
          <input type="text" name="ahorro_apv" [defaultValue]="0" matInput autonumeric [options]="optionsNumeric"
            [(ngModel)]="trabajador.ahorro_apv">
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>N° Cargas Familiares</mat-label>
          <input type="text" matInput name="numero_cargas" max="15" autonumeric [options]="optionsNumber"
            [(ngModel)]="trabajador.numero_cargas" required (focus)="soloNumeros($event, true)" />
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Tramo Asignación Familiar</mat-label>
          <mat-select (selectionChange)="ObtenerTramo($event)" [(ngModel)]="trabajador.tramoAsigFam"
            [ngModelOptions]="{standalone: true}" required>
            <mat-option class="example-full-width " *ngFor="let tramo of tramos" [value]="tramo.value ">
              {{tramo.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-6"></div>
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos de pago</b>
          </div>
        </p-divider>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Banco</mat-label>
          <mat-select (selectionChange)="ObtenerBanco($event)" required>
            <mat-option *ngFor="let banco of bancos" [value]="banco.id">
              {{banco.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Tipo Cuenta</mat-label>
          <mat-select (selectionChange)="ObtenerTipoCuenta($event)" required>
            <mat-option *ngFor="let tipo_cuenta of tipo_cuentas " [value]="tipo_cuenta.valor">
              {{tipo_cuenta.valor}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Número de Cuenta</mat-label>
          <input type="text" matInput name="numero_cuenta" autonumeric [options]="optionsNumber"
            [(ngModel)]="trabajador.numero_cuenta" required (focus)="soloNumeros($event, true)" />
        </mat-form-field>
      </div>
      <div class="col col-4 d-flex justify-content-center align-self-center">
        <mat-checkbox class="example-margin" name="checkAdelanto" [(ngModel)]="checkAdelanto">
          <b>Solicitar adelanto</b>
        </mat-checkbox>
      </div>
      <div class="col-4">
        <label for="fechaadelanto" class="block">Fecha Adelanto</label>
        <p-calendar inputId="fechaadelanto" [(ngModel)]="fechaAdelanto" [disabled]="!checkAdelanto" styleClass="w-100" dateFormat="dd MM yy"></p-calendar>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Monto adelanto</mat-label>
          <input type="text" matInput name="montoAdelanto" autonumeric [options]="optionsNumeric"
            [(ngModel)]="montoAdelanto" required (focus)="soloNumeros($event, true)" [disabled]="!checkAdelanto" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos de remuneración</b>
          </div>
        </p-divider>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Fecha de Ingreso</mat-label>
          <input matInput [matDatepicker]="fecha_ingreso" name="fecha_ingreso" [(ngModel)]="trabajador.fecha_ingreso"
            required (click)="fecha_ingreso.open()" (focus)="fecha_ingreso.open()">
          <mat-datepicker-toggle matSuffix [for]="fecha_ingreso"></mat-datepicker-toggle>
          <mat-datepicker touchUi #fecha_ingreso></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Sueldo Base</mat-label>
          <input type="text" matInput name="sueldo_bruto" autonumeric [options]="optionsNumeric"
            [(ngModel)]="trabajador.sueldo_base" (focus)="soloNumeros($event, true)" />
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Sueldo Líquido</mat-label>
          <input type="text" matInput name="sueldo_liquido" autonumeric [options]="optionsNumeric"
            [(ngModel)]="trabajador.sueldo_liquido" (focus)="soloNumeros($event, true)" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos de uniforme</b>
          </div>
        </p-divider>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Talla Polera/Poleron</mat-label>
          <input type="text" matInput name="talla_polera_poleron" maxlength="4"
            [(ngModel)]="trabajador.talla_polera_poleron" />
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Talla Pantalón</mat-label>
          <input type="text" matInput name="talla_pantalon" [(ngModel)]="trabajador.talla_pantalon" />
        </mat-form-field>
      </div>
      <div class="col col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Talla Calzado</mat-label>
          <input type="text" matInput name="numero_calzado" autonumeric [options]="optionsNumber"
            [(ngModel)]="trabajador.numero_calzado" (focus)="soloNumeros($event, true)" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer ">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button " class="btn btn-outline-success " (click)="guardar()">Guardar</button>
  </div>
</ng-template>