import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DocTransferencias } from 'src/app/shared/models/doc-transferencias.interfase';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { cleanRut } from 'rutlib/lib';
@Component({
  selector: 'app-lista-doctransferencias',
  templateUrl: './lista-doctransferencias.component.html',
  styleUrls: ['./lista-doctransferencias.component.css']
})
export class ListaDoctransferenciasComponent implements OnInit {

  @Input() transferencias_list: DocTransferencias[] = []
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  debouncer: Subject<boolean> = new Subject()

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private _liveAnnouncer: LiveAnnouncer,
  ) { }

  ///// variables //////

  lista_trabajadores:any[] = [];
  trabajadoresFilter: any[] = [];

  displayedColumns = [
    'rut',
    'nombres',
    'empresa',
    'estado_contrato',
    'acciones'
  ];
  dataSource:any;
  rut_trabajador:any;
  datosTrabajador:any;
  num_trab_finiquitados:number = 0;
  textSearch: string = '';

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
      this.TraerDatos();
  }

  async TraerDatos() {
    const res = await this.trabajadoresService.TraerTrabajadoresAsync();
    if (res.success) {
      this.lista_trabajadores = res.data;
      this.trabajadoresFilter = this.lista_trabajadores.filter(x => x.estado_contrato !== 'Finiquitado' && x.estado !== 0);

      // CONTAR LOS TRABAJADORES FINIQUITADOS
      this.num_trab_finiquitados = this.lista_trabajadores.reduce((a,e) => e.estado === 0 ? a + 1 : a, 0);
      this.dataSource = new MatTableDataSource<any>(this.trabajadoresFilter);
      this.dataSource.paginator = this.paginator;
      // CONFIGURA EL FILTRADO DE DATOS
      this.dataSource.filterPredicate = (data:any, filter:string) => {
        return this.configurarFilterPredicate(data, filter)
      };
      // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'empresa': return item.Empresa_contratante.nombre;
          default: return item[property];
        }
      }
      this.dataSource.sort = this.sort;
    }else{
      console.error(res.msg);
    }
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLowerCase().split(' ');

    const rutSinPuntos: string = cleanRut(data.rut.toString());
    const nombre: string = this.normalizeText(data.nombres +' '+ data.apellidos).toLowerCase();
    const empresa: string = this.normalizeText(data.Empresa_contratante.nombre).toLowerCase();
    const estado_contrato: string = data.estado_contrato.toLowerCase();
    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.rut.toString().includes(x) || nombre.includes(x)
      || empresa.includes(x) || estado_contrato.includes(x)
    )
  }

  // ORDENA LOS DATOS
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(textSearch: string) {
    this.textSearch = textSearch;
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  subir(content: any ,datos: any) {
    this.datosTrabajador = datos;
    this.modalService.open(content,{size:'lg'});

  }

  // mostrar(content: any ,rut: any) {
  mostrar(content: any ,datos: any) {
    this.datosTrabajador = datos;
    this.modalService.open(content,{size:'lg'});

  }
  viewDatosFiniquitados(viewFiniquitados: boolean){
    this.trabajadoresFilter = viewFiniquitados 
      ? this.lista_trabajadores 
      : this.lista_trabajadores.filter(x => x.estado !== 0);
    this.dataSource = new MatTableDataSource<any>(this.trabajadoresFilter);
    this.dataSource.paginator = this.paginator;
    // CONFIGURA EL FILTRADO DE DATOS
    this.dataSource.filterPredicate = (data:any, filter:string) => {
      return this.configurarFilterPredicate(data, filter);
    };
    // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'empresa': return item.Empresa_contratante.nombre;
        default: return item[property];
      }
    }
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  // Función que elimina los diacríticos de las vocales de una cadena
  normalizeText(texto: string): string {
    return texto?.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }
}
