
<div class="mat-elevation-z8">
  <div class="buscar">
    <mat-form-field appearance="outline" style="width: 20%; margin: 10px;">
      <mat-label>Buscar</mat-label>
      <input matInput [(ngModel)]="textoBuscar" (keyup)="applyFilter()" #input>
    </mat-form-field>
  </div>
  <table *ngIf="dataSource.data.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="nombre_denunciante">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>NOMBRE DENUNCIANTE</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombre_denunciante + ' ' + element.apellido_denunciante | uppercase }}
      </td>
    </ng-container>
    <ng-container matColumnDef="nombre_denunciado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>NOMBRE DENUNCIADO</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombre_denunciado + ' ' + element.apellido_denunciado | uppercase }}
      </td>
    </ng-container>
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ESTADO</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field class="example-full-width " appearance="outline">
          <mat-label>Estado</mat-label>
          <mat-select [(value)]="element.estado" (selectionChange)="onStatusChange($event.value, element)">
            <mat-option *ngFor="let status of statuses" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA INGRESO</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt }}
      </td>
    </ng-container>
    <ng-container matColumnDef="codigo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CODIGO</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.codigo !== null">{{ element.codigo }}</span>
        <span *ngIf="element.codigo === null">S/D</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
      <td mat-cell *matCellDef="let element">
        <button (click)="updateStatus(element, element.estado)" mat-button class="button_color_primary">
          <mat-icon style="color: white">update</mat-icon>
        </button>
        <button (click)="openDialog(element.codigo)" mat-button class="button_color_primary">
          <mat-icon style="color: white">description</mat-icon>
        </button>
        <button (click)="openUploadDialog(element.codigo)" mat-button class="button_color_primary">
          <mat-icon style="color: white">upload</mat-icon>
        </button>
        <button (click)="openDocumentsModal(element.codigo)" mat-button class="button_color_primary">
          <mat-icon style="color: white">list</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar página de usuarios.">
  </mat-paginator>
</div>
