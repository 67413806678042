import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMarcacionComponent } from './main-marcacion/main-marcacion.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from 'src/app/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { KnobModule } from "primeng/knob";
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';



@NgModule({
  declarations: [
    MainMarcacionComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    KnobModule,
    ButtonModule,
    RippleModule,
  ],
  exports:[
    MainMarcacionComponent,

  ]
})
export class MarcacionModule { }
