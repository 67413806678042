import { Component, OnInit } from '@angular/core';
import { RegionesService } from 'src/app/pages/services/regiones.service';
import { Region, RequestResponseGetAllRegion } from 'src/app/shared/models/region.interface';

@Component({
  selector: 'app-main-regiones',
  templateUrl: './main-regiones.component.html',
  styleUrls: ['./main-regiones.component.css']
})
export class MainRegionesComponent implements OnInit {
  regiones: Region[] = [];

  constructor(
    private regionService:RegionesService,
  ) { }

  ngOnInit(): void {
    this.TraerDatos();
  }

  TraerDatos(){
    this.regionService.TraerRegiones().subscribe((res:RequestResponseGetAllRegion) => {
      this.regiones = res.data;
    });
  }

  // SE ACTIVARA CADA VEZ QUE SE AGREGA O ELIMINA UNA REGIÓN
  actualizarTabla(){ 
    this.TraerDatos(); 
  }
}
