import { Component, OnInit,Input, SimpleChanges } from '@angular/core';

import {Documento} from 'src/app/shared/models/documentos/documentos.interface';
import {DocumentosService} from 'src/app/pages/services/documentos.service';
@Component({
  selector: 'app-lista-finiquitos',
  templateUrl: './lista-finiquitos.component.html',
  styleUrls: ['./lista-finiquitos.component.css']
})
export class ListaFiniquitosComponent implements OnInit {
  @Input() fk_trabajador!: any;

  documentos:Documento[] = [];
  constructor(
    private documentoService: DocumentosService
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    

    if (changes.fk_trabajador.currentValue != changes.fk_trabajador.previousValue){

      this.documentos=[]
      this.documentoService.GetAll_finiquito(this.fk_trabajador).subscribe(
        (res) => {
          
          this.documentos = res.data;
        }
      );
    }
  
}
  ngOnInit(): void {
    this.documentoService.GetAll_finiquito(this.fk_trabajador).subscribe(
      (res) => {
       
        this.documentos = res.data;
       
      
      }
    );
  }

}
