import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { CargoService } from 'src/app/pages/services/cargo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lista-perfiles-ocupacionales',
  templateUrl: './lista-perfiles-ocupacionales.component.html',
  styleUrls: ['./lista-perfiles-ocupacionales.component.css']
})
export class ListaPerfilesOcupacionalesComponent implements OnInit {

  displayedColumns: string[] = ['id', 'nombre', 'jefe' , 'sucursal', 'jornada', 'lugar', 'actions'];
  dataSource = new MatTableDataSource();
  listSucursal: any;
  cargos: any;
  id_cargo: any;
  cargo_desc:any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private cargoService: CargoService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.cargoService.GetAll_cargoS().subscribe(
      (res) => {                
        this.cargos = res.data; 
       // console.table(res.data);  
       this.dataSource = new MatTableDataSource(this.cargos);
       this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;           
      });
  }

  ngOnChanges(changes: SimpleChanges) {   
    if(changes.cargos!=undefined){
      if (!changes.cargos.firstChange) {
        this.dataSource = new MatTableDataSource(this.cargos);       
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }


  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}


open(content:any,id:any,cargo:any){
  this.id_cargo=id;
  this.cargo_desc=cargo;
  //console.log(this.id_cargo);
  //this.modalService.open(content);
  this.modalService.open(content, { size: 'xl'});
}


}
