<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo de Funciones por cargo</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content">
      <app-agregar-funciones
        [list_cargos]="list_cargos_main"
      ></app-agregar-funciones>
      <br />
    </mat-card>
    <hr />
  </div>