<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo de Planilla</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content">
      <app-agregar-planilla-pagos (onDebounce)="actualizarTabla($event)" 
      [lista_planillaPagos]="planilla">
    </app-agregar-planilla-pagos>
      <br />
      <app-lista-planilla-pagos (onDebounce)="actualizarTabla($event)" 
      [lista_planilla]="planilla">
    </app-lista-planilla-pagos>
    </mat-card>
    <hr />
  </div>