import { PrimeNgModule } from './../../../PrimeNg.module';
import { MaterialModule } from './../../../material.module';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainGenerarAnexoComponent } from './main-generar-anexo/main-generar-anexo.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FormsModule } from '@angular/forms';
import { HelperModule } from 'src/app/helper.module';
import { GenerarAnexoContratoComponent } from './generar-anexo-contrato/generar-anexo-contrato.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PdfViewerModule } from 'ng2-pdf-viewer';




@NgModule({
  declarations: [
    MainGenerarAnexoComponent,
    GenerarAnexoContratoComponent,
  
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    HelperModule,
    PdfViewerModule

  ],
  exports:[
    MainGenerarAnexoComponent,
    GenerarAnexoContratoComponent,
  

  ],
  providers:[
     { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
     { provide: LOCALE_ID, useValue: "es-CL" },
     {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  schemas: [
    // CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GenerarAnexoModule { }
