import { Injectable } from '@angular/core';
import { HelperService } from '../../helper/helper.service';
import { style } from '@angular/animations';
import { formatRut } from 'rutlib';

@Injectable({
  providedIn: 'root'
})
export class ImplementoService {
  public src:any=undefined;
  pdfMake: any;
  dateObj = new Date();
  month = this.dateObj.getUTCMonth() + 1; //months from 1-12
  day = this.dateObj.getUTCDate();
  year = this.dateObj.getUTCFullYear();
  constructor(private helpers : HelperService) { }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }
  async GenerarPdf(trabajador: any) {
  
    console.log({...trabajador});
    //necesitas traer los datos de la ciudad del contrato aun 
    await this.loadPdfMaker();

    const rutFormat = formatRut(trabajador.fk_trabajador.toString());

    var dd = {

      pageSize: {
        width: 216 * 2.83465,
        height: 356 * 2.83465
      },
      content: [
         
      
        {
          text: '\n\n\nANEXO DE ENTREGA DE IMPLEMENTOS Y HERRAMIENTAS DE TRABAJO\n\n\n',
          style: 'header'
        },  
        
        //Comienzo primer texto 
        
         {
          text:  'En '+trabajador.ciudad+' , '+ this.day+' de '+this.month+'  del   '+this.year+'  , por intermedio de la presente, yo ' +trabajador.nombre.toUpperCase() + '  ' +trabajador.apellido.toUpperCase() + ',' +' cédula nacional de identidad número ' + rutFormat  +', trabajador  de  la  empresa '+trabajador.empresa+' , Rol  Único Tributario ' + trabajador.rol +', declaro que, con esta fecha, recibí los siguientes equipos, implementos y/o herramientas de trabajo que se detallan: \n\n',
          style: 'otro'
        },  
        // {
        //   text:   '  ',
        //   style: 'otro'
        // },  
        // {
        //   text:     '',
        //   style: 'otro'
        // }, 
        // {
        //   text:   '',
        //   style: 'otro'
        // }, 
       
              
        //Termino primera linea
        
        //Comienzo de tabla
        {
          
            
      
        table: {

            body:[
                ['FECHA DE ENTREGA', 'EQUIPO, IMPLEMENTO, HERRAMIENTA DE TRABAJO', 'VALOR DE ESPECIE ($)'],
                ['  ', '  ', '  '],
                ['  ', '  ', '  '],
                ['  ', '  ', '  '],
                ['  ', '  ', '  '],
                ['  ', '  ', '  '],
                ['  ', '  ', '  '],
                [{text: 'OBSERVACIONES', style: 'tableHeader', colSpan: 3, alignment: 'center'}],
                    [{text: ' ', style: 'tableHeader', colSpan: 3, margin: [40, 0, 40, 0]}],
                ],
                },
                margin: [40, 0, 40, 0],
                       
        },
                
                //Fin Tabla
                
                
                //Comienzo de segundo parrafo 
                  
          
            {text: '\nLa dotación que por este acto se entrega para estar a cargo del trabajador, es de la empresa en todo momento, por lo que, en caso del término del contrato de trabajo o por la entrega de una nueva dotación, el trabajador se compromete a realizar la devolución de forma inmediata.' , style: 'otro'},  
          
            {text: 'En caso de que la dotación sufra daño en forma total o parcial, el trabajador deberá informar a la brevedad al empleador, para su reparación y/o reemplazo.', style: 'otro'},  
          
              {text: 'El trabajador reconoce que los bienes que, por este acto se le entregan, son de propiedad y dominio absoluto de su empleador.' , style: 'otro'}, 
        
              {text: 'El trabajador, por medio de este acto, se compromete a mantener un correcto y adecuado cuidado de la especies que, por este acto, se le entregan.\n' , style: 'otro'}, 
      
           {text: '\nPOR ESTE ACTO, EL TRABAJADOR AUTORIZA EXPRESAMENTE AL EMPLEADOR PARA REALIZAR LAS DEDUCCIONES O DESCUENTOS EN DINERO, YA SEA DE LA REMUNERACIÓN Y/O FINIQUITO, POR EL DAÑO TOTAL O PARCIAL, POR PÉRDIDA O EXTRAVÍO DE LAS ESPECIES ENTREGADAS, CAUSADAS POR SU RESPONSABILIDAD, O LA NO ENTREGA DEL EQUIPO EN TIEMPO INMEDIATO DE TERMINAR LA RELACIÓN LABORAL.\n\n\n\n\n', style: 'subheaderr'},
        
        
        
            {text: 'NOMBRE: '+ trabajador.nombre.toUpperCase()+' '+trabajador.apellido.toUpperCase()+'\n\n', margin: [40, 0, 40, 0]},  
          {text: 'CNI: '+this.helpers.formatoRut(trabajador.fk_trabajador)+'\n\n',  margin: [40, 0, 40, 0]},  
          {text: 'FIRMA:',  margin: [40, 0, 40, 0]},  
    
      ],
      styles: {
        header: {
            margin: [40 , 0, 40, 0],
          fontSize: 13,
          bold: true,
        
        },
        
        subheader: {
          fontSize: 11,
          bold: true,
           margin: [40, 0, 40, 0],
           lineHeight: 1.5,
           alignment: 'justify',
        
        },
          subheaderr: {
          fontSize: 11,
          bold: true,
           margin: [40, 0, 40, 0],
           lineHeight: 1.5,
           alignment: 'justify'
        
        },
          otro: {
            alignment: 'justify',
           margin: [40 , 0, 40, 0],
           lineHeight: 1.5
        
        },
         folio: {
             bold: true,
           margin: [450, 0, 0, 0]
        
        },
          
         
      }	
      
    
      
    };
    
    this.pdfMake.createPdf(dd).open();

  }


}
