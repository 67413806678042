import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MatSelectChange } from '@angular/material/select';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { differenceInHours, differenceInMinutes, eachDayOfInterval, isWeekend } from 'date-fns'
import { FormControl, FormGroup } from '@angular/forms';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import Holidays from 'date-holidays';
import moment from 'moment';

@Component({
  selector: 'app-agregar-gestorasistencia',
  templateUrl: './agregar-gestorasistencia.component.html',
  styleUrls: ['./agregar-gestorasistencia.component.css'],
  providers: [DatePipe]

})
export class AgregarGestorasistenciaComponent implements OnInit {
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  now: Date = new Date();
  modelFecha: NgbDateStruct = { year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate() };
  timeStart: NgbTimeStruct = { hour: 8, minute: 30, second: 0 };
  timeEnd: NgbTimeStruct = { hour: 8, minute: 30, second: 0 };

  fechaDesdeHasta: FormGroup;
  holidays = new Holidays('CL');

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private datePipe: DatePipe,
    private alert: AlertHelper,
    private asistenciaService: GestorAsistenciaService,
    private trabajadorService: TrabajadoresService,
    private config: NgbTimepickerConfig,
  ) {
    this.config.seconds = false;
    this.config.spinners = false;

    const hoy = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0, 0);

    this.fechaDesdeHasta = new FormGroup({
      start: new FormControl(moment(hoy)),
      end: new FormControl(moment(hoy)),
    })
    this.user = this.auth.ObtenerinfoToken();
  }

  /////—————  Variables   ————-\\\\\\

  EventoRazon: any;
  razones: any[];

  // FECHAS
  fecha: any;
  fecha2: any;
  end: any;
  endFormateada: any;
  fechaa: any;
  fechaA: any;
  fechainicioAtrazada: any;
  fecha1incioAtrazadac: any;
  fecha2inicioAtrazada: any;
  fecha2inicioAtrazadac: any;

  dias: any;
  DiaAtrazado: any;
  user: any;

  render = 0;
  data: any;

  si: any;
  bono: any;
  sueldo: any;
  no: any;

  /////————— Variable BD AND TABLA   ————-\\\\\\

  trabajador: any;
  trabajadores_aux: any;
  dias_trabajados_semana: number;
  asistencia: any = {
    id: 0,
    start: "",
    end: "",
    timeStart: "",
    timeEnd: "",
    dias_faltados: "",
    razon: "",
    trabajador: 0,
    motivo: "",
    dsueldo: "xx",
    dbono: "xx",
    tsueldo: 0,
    tbono: 0
  }

  /////—————  Select   MatSelectChange————-\\\\\\

  ObtenerRazon(event: MatSelectChange) {

    this.asistencia.razon = event.value;

  }


  /////—————  Select   MatDatepickerInputEvent————-\\\\\\

  obtener_fecha(event: MatDatepickerInputEvent<Date>) {
    this.fecha = event.value;
    console.log({ ...this.fecha })
    this.fecha2 = this.datePipe.transform(this.fecha, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia
  }

  obtener_fecha_fin(event: MatDatepickerInputEvent<Date>) {

    this.end = event.value;
    console.log({ ...this.end })
    this.endFormateada = this.datePipe.transform(this.end, 'yyyy-MM-dd');

  }

  verificarRazon(event: MatSelectChange) {
    const razon = this.razones.find(x => x.id === event);
    console.log({ razon });
    this.render = razon.esAtraso ?? false ? 1 : 0;
  }



  obtener_fechaAtrasadainicio(event: MatDatepickerInputEvent<Date>) {
    this.fechainicioAtrazada = event.value;
    console.log({ ...this.fechainicioAtrazada });
    this.fecha1incioAtrazadac = this.datePipe.transform(this.fechainicioAtrazada, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia

  }

  verificarRol(): boolean {
    if (this.user?.rol !== 'ADMIN' && this.user?.rol !== 'ASISTENCIA') {
      alert('No tiene permisos para realizar esta acción');
      return false;
    }
    return true;
  }

  obtener_fechaAtrasadafin(event: MatDatepickerInputEvent<Date>) {
    this.fecha2inicioAtrazada = event.value;
    console.log({ ...this.fecha2inicioAtrazada });
    this.fecha2inicioAtrazadac = this.datePipe.transform(this.fecha2inicioAtrazada, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia

  }


  // DiasAtrazado(event: MatSelectChange) {


  //   this.DiaAtrazado = event.value;

  //   if (this.DiaAtrazado == 1) {


  //     this.render = 1;
  //   } else {
  //     this.render = 0;
  //   }


  // }




  Obtenersueldo(event: MatSelectChange) {


    this.sueldo = event.value;



    if (this.sueldo == "SI") {


      this.si = 1;


    }

    else {

      this.si = 0;
    }


  }


  Obtenerbono(event: MatSelectChange) {


    this.bono = event.value;



    if (this.bono == "SI") {


      this.no = 1;


    }

    else {

      this.no = 0;
    }


  }






  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\

  open(content: any) {
    this.modalService.open(content, { size: 'lg', backdrop: 'static' });

  }

  calcularDiasFaltados() {
    switch (this.render) {
      case 1:
        this.calcularDias_Atrasos();
        break;
      case 0:
        this.calcularDias_NoAtrasos();
        break;
      default:
        console.error('HUBO UN ERROR CON CODIGO RENDER.');
        break;
    }
  }

  calcularDias_Atrasos() {
    // console.log({...this.modelFecha}, {...this.timeStart}, {...this.timeEnd});
    const modelDateStart = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day, this.timeStart.hour, this.timeStart.minute, this.timeStart.second, 0);
    const modelDateEnd = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day, this.timeEnd.hour, this.timeEnd.minute, this.timeEnd.second, 0);

    const hours = differenceInHours(modelDateEnd, modelDateStart);
    const minutes = differenceInMinutes(modelDateEnd, modelDateStart) % 60;

    const result = (hours + minutes / 60) / 10;
    this.asistencia.dias_faltados = parseFloat(result.toFixed(2));
  }

  calcularDias_NoAtrasos() {
    const interval: Interval = {
      start: this.fechaDesdeHasta.value.start._d,
      end: this.fechaDesdeHasta.value.end._d
    }

    // console.log({interval});

    const days = eachDayOfInterval(interval);

    if (this.trabajador.dias_semana === 5 || this.trabajador.dias_semana === null || this.trabajador.dias_semana === undefined) {
      const workingDays = days.filter((day) => !this.holidays.isHoliday(day) && !isWeekend(day));
      this.asistencia.dias_faltados = workingDays.length
      console.log({workingDays});
    }else if (this.trabajador.dias_semana === 6) {
      const workingDays = days.filter((day) => !this.holidays.isHoliday(day) && (!isWeekend(day) || (day.getDay() === 6)));
      this.asistencia.dias_faltados = workingDays.length
    }
  }

  enviar() {
    if (this.trabajador === undefined) {
      this.alert.error_small('Seleccione un trabajador.');
      return;
    }

    this.asistencia.id = 0;

    // if (this.sueldo == "SI" ) {


    //   this.asistencia.dsueldo = this.sueldo


    //   }else{


    //     this.asistencia.dsueldo = this.sueldo


    //   }



    // if (this.bono == "SI" ) {


    // this.asistencia.dbono =  this.bono



    // }else{


    //   this.asistencia.dbono =  this.bono

    // }


    this.asistencia.tsueldo = this.asistencia.tsueldoo
    this.asistencia.tbono = this.asistencia.tbonoo

    if (this.render == 1) {

      if (this.timeStart <= this.timeEnd) {
        this.calcularDiasFaltados();
        const modelFecha = new Date(this.modelFecha.year, this.modelFecha.month - 1, this.modelFecha.day, 0, 0, 0, 0).toISOString();
        this.asistencia.start = modelFecha;
        this.asistencia.end = modelFecha;
        this.asistencia.timeStart = `${this.timeStart.hour}:${this.timeStart.minute}:00`;
        this.asistencia.timeEnd = `${this.timeEnd.hour}:${this.timeEnd.minute}:00`;
        this.asistencia.razon = this.asistencia.razon;

        // console.log({ ...this.asistencia });

        this.asistenciaService.crear(this.asistencia).subscribe((res: any) => {
          if (res.success == true) {

            this.alert.success_small(res.msg!)

            this.modalService.dismissAll();
            this.debouncer.next(true);

            this.limpiarFormulario();
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      } else {
        this.alert.error_small('Error: La hora de inicio no puede ser mayor que la hora final')
      }
    } else {
      this.calcularDiasFaltados();
      this.asistencia.start = this.datePipe.transform(this.fechaDesdeHasta.value.start, 'yyyy-MM-dd');
      this.asistencia.end = this.datePipe.transform(this.fechaDesdeHasta.value.end, 'yyyy-MM-dd');
      this.asistencia.timeStart = '00:00:00';
      this.asistencia.timeEnd = '00:00:00';
      this.asistencia.razon = this.asistencia.razon;

      console.log({ ...this.asistencia });

      this.asistenciaService.crear(this.asistencia).subscribe((res: any) => {
        if (res.success == true) {

          this.alert.success_small(res.msg!)

          this.modalService.dismissAll();
          this.debouncer.next(true);

          this.limpiarFormulario();

        } else {
          this.alert.error_small(res.msg!)
        }
      })

    }

  }
  limpiarFormulario() {
    this.asistencia = {
      id: 0,
      start: "",
      end: "",
      dias_faltados: "",
      razon: "",
      trabajador: 0,
      motivo: "",
      dsueldo: "xx",
      dbono: "xx",
      tsueldo: 0,
      tbono: 0
    }
  }

  ObtenerTrabajador(event: MatSelectChange) {
    const trabajador = event.value;
    this.trabajadorService.TraerTrabajadoresRut(trabajador.value).subscribe(res => {
      if (res.success) {
        this.trabajador = res.data;
        this.dias_trabajados_semana = this.trabajador.dias_semana;
      }else{
        this.alert.error_small('Ocurrio un error al obtener el trabajador.');
        console.error(res.msg)
      }
    });
    this.asistencia.trabajador = trabajador.value;
  }





  /////—————AL INICIAR EL MODULO   ————-\\\\\\




  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.asistenciaService.TraerTrabajadores().subscribe(
      (res) => {
        console.log({ res });
        this.trabajadores_aux = res.data.map((data) => ({ value: data.rut, text: data.nombres.toUpperCase() + ' ' + data.apellidos.toUpperCase() }));
        this.trabajadores_aux = this.trabajadores_aux.sort((a, b) => a.text.localeCompare(b.text));
      });

    this.asistenciaService.lista_razon().subscribe((res) => {
      this.razones = res.data;
    });



  }

}


