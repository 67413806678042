import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ListaCartasTrabajadorComponent } from 'src/app/pages/admin/documentos/cartas-amonestacion/lista-cartas-trabajador/lista-cartas-trabajador.component'
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MotivosCartaAmonestacionService } from 'src/app/pages/services/motivos-carta-amonestacion.service';

interface Carta {
  FechaEmision: string;
  GradoFalta: string;
  Motivo: string;
  idmotivo: number;
}

@Component({
  selector: 'app-modal-agregar',
  templateUrl: './modal-agregar.component.html',
  styleUrls: ['./modal-agregar.component.css']
})


export class ModalAgregarComponent implements OnInit {
  @Input() rut: any;
  responses: string[] = [];
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  
  motivos: any[] = [];
  motivosSelect: any;

  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/cartasamonestacion/crear`,
    itemAlias: 'cartas',
    additionalParameter: {
      // contrato:JSON.parse(this.contrato)
    }
  });
  carta: Carta = {
    FechaEmision: '',
    GradoFalta: '',
    Motivo: '',
    idmotivo: 0,
  }
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private CartasTrabajadorComponent: ListaCartasTrabajadorComponent,
    private motivosCartaAmonestacionService: MotivosCartaAmonestacionService,
    private auth: AuthService,
  ) {

    this.uploader.onCompleteAll = () => {
      //console.log('se termino de enviar ');
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user= this.auth.ObtenerinfoToken();
      form.append('rut', this.rut);
      form.append('FechaEmision', this.carta.FechaEmision);
      form.append('GradoFalta', this.carta.GradoFalta);
      form.append('Motivo', this.carta.Motivo);
      form.append('idmotivo', this.motivosSelect.id);
      form.append('userAt', user.nombre);
    };

  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  async ngOnInit(): Promise<void> {

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        })
        this.modalService.dismissAll();
        this.CartasTrabajadorComponent.TraerDatos(false);
      } else {

        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',
        })

      }
    }
    this.motivos = (await this.obtenerMotivos());

  }
  async obtenerMotivos(): Promise<any[]> {
    const res = await this.motivosCartaAmonestacionService.getMotivosAsync();
    if (res.success) return res.data;
    else return [];
  }

  Guardar() {

    if (this.validar()==true) {
      this.uploader.uploadAll();
    }
    else {
      // console.log("fallo el validar y entro aca por alguna razón ")
    }

  }

  validar(): boolean {

    if (
      this.carta.FechaEmision != '' &&
      this.carta.GradoFalta != '' &&
      this.carta.Motivo != ''
    ) {
      // console.log("esta todo validado")
      // console.log(true);
      return true;
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Faltan datos , favor revisar',

      })
      // console.log(false);
      return false;
    }
  }

  OcultarModal() {
    this.modalService.dismissAll();
  }


  OntenerGradoFalta(event: MatSelectChange) {

    this.carta.GradoFalta = event.value;

  }

}
