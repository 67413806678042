import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OpcionesElegidasResumen } from 'src/app/shared/models/opciones-elegidas-resumen.interface';
import { TrabajadorHaberService } from './TrabajadorHaber/trabajador-haber.service';
import { TrabajadoresService } from './trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { contabilidadService } from './contabilidad/contabilidad.service';
import { TipoHaberes } from 'src/app/shared/models/Enums/tipos-haberes.enum';
import { MovimientosPersonalService } from './movimientos-personal.service';
import { differenceInYears, eachDayOfInterval, endOfMonth, format, isWithinInterval, parseISO, startOfMonth } from 'date-fns';
import { MovimientosPersonal } from 'src/app/shared/models/movimientospersonal.interface';
import { FuncionesLiquidacionesService } from './liquidaciones/funciones-liquidaciones.service';
import { NombreHaberes } from 'src/app/shared/models/Enums/nombre-haberes.enum';
import * as XLSX from 'xlsx';
import { PrestamosService } from './prestamos.service';
import { AdelantosService } from './adelantos/adelantos.service';
import { Prestamos } from 'src/app/shared/models/prestamos.interface';
import { Adelantos } from 'src/app/shared/models/adelantos.interface';

@Injectable({
  providedIn: 'root'
})
export class GenerarResumenHaberesService {
  private $eventComplete = new Subject<boolean>();

  // MOVIMIENTOS
  movimientos: MovimientosPersonal[];
  movimiento: MovimientosPersonal;
  // PREVIRED
  previred: any = {
    periodo: null,
    topes: {
      AfpSalud: 0,
      IPS: 0,
      SeguroCesantia: 0,
    },
    familiar: {
      tramoA: {
        valor: 0,
        max: 0,
      },
      tramoB: {
        valor: 0,
        min: 0,
        max: 0
      },
      tramoC: {
        valor: 0,
        min: 0,
        max: 0,
      },
      tramoD: {
        valor: 0,
        min: 0,
      }
    },
    ufMesActual: 0,
    ufMesAnterior: 0,
    rentaMinima: 0,
    sCesantiaIndefinido: 0,
    seguroInvalidez: 0
  }  
  // EMPRESA
  empresa: any;
  // TRABAJADORES DE LA EMPRESA
  trabajadores: any[];
  // HABERES DE LOS TRABAJADORES
  haberes: any[];
  // PRESTAMOS
  prestamos: Prestamos[];
  // ADELANTOS
  adelantos: Adelantos[];
  // LIBRO DE REMUNERACION
  libroRemuneraciones: any[];
  // CONTENDRA EL TOTAL DE LOS HABERES
  resumenHaberes: any[];

  constructor(
    private haberesTrabajadorServices: TrabajadorHaberService,
    private trabajadorService: TrabajadoresService,
    private contabilidadService: contabilidadService,
    private movimientosPersonalesService: MovimientosPersonalService,
    private funcionesLiquidacionesService: FuncionesLiquidacionesService,
    private prestamoService: PrestamosService,
    private adelantoService: AdelantosService,
    private alert: AlertHelper,
  ) {
    this.trabajadores = [];
    this.libroRemuneraciones = [];
    this.movimientos = [];
    this.prestamos = [];
    this.adelantos = [];
    this.resumenHaberes = [];
  }
  // MANDA UN DATO AL COMPONENTE PARA AVISAR QUE DEJE DE MOSTRAR EL SPINNER EN EL BOTON
  onCompleteEvent(): Observable<boolean>{
    return this.$eventComplete.asObservable();
  }

  async generarResumenHaberes(opciones_elegidas: OpcionesElegidasResumen){
    // SETEO EL PERIODO
    this.funcionesLiquidacionesService.setPeriodo(opciones_elegidas.periodo); // SETEO EL PERIODO EN EL SERVICIO DE FUNCIONES LIQUIDACIONES
    this.previred = await this.funcionesLiquidacionesService.obtenerPrevired(); // OBTENGO LOS DATOS DE PREVIRED
    // OBTENER TRABAJADORES
    await this.obtenerTrabajadores(opciones_elegidas);
    if (this.trabajadores.length === 0) {
      this.$eventComplete.next(false);
      return;
    };
    // OBTENER EMPRESA
    this.empresa = this.trabajadores[0].Empresa_contratante;
    // TRAER LIBRO DE REMUNERACIONES
    await this.obtenerLibroRemuneraciones(opciones_elegidas);
    if (this.libroRemuneraciones.length === 0) {
      this.$eventComplete.next(false);
      return;
    };
    // OBTENER HABERES
    await this.obtenerHaberesPeriodo(opciones_elegidas);
    if (this.haberes.length === 0) {
      this.$eventComplete.next(false);
      return;
    };
    // OBTENER ADELANTOS Y PRESTAMOS
    await this.obtenerPrestamosAdelantos(opciones_elegidas);
    await this.calcularMontosResumenesHaberes(opciones_elegidas.periodo);
    // GENEREAMOS EL EXCEL
    this.generarExcel();
    // ENVIO FALSE PARA DETENER EL LOADING EN EL COMPONENTE
    this.$eventComplete.next(false);
  }
  async obtenerPrestamosAdelantos(opciones_elegidas: OpcionesElegidasResumen) {
    const periodo = format(opciones_elegidas.periodo, 'yyyy-MM-dd');
    const resPrestamo = await this.prestamoService.TraerPorPeriodo(periodo);
    const resAdelanto = await this.adelantoService.TraerPorPeriodo(periodo);
    // OBTENEMOS LOS PRESTMOS
    if (resPrestamo.data) this.prestamos = resPrestamo.data;
    else console.error(resPrestamo.msg);
    // OBTENEMOS LOS ADELANTOS
    if (resAdelanto.data) this.adelantos = resAdelanto.data.map(x => ({...x, rut: x.fk_trabajador}));
    else console.log(resAdelanto.msg);

    // console.log({...this.prestamos}, {...this.adelantos});
  }
  async obtenerLibroRemuneraciones(opciones_elegidas: OpcionesElegidasResumen) {
    const periodo = opciones_elegidas.periodo;
    const periodoString = this.crearFechaString(periodo);
    if (periodoString === '') {
      this.alert.error_small('No se pudo crear el periodo.');
      return;
    }
    const res = await this.contabilidadService.TraerPorPeriodoAsync(periodoString);
    if (res.success) {
      this.libroRemuneraciones = res.data;
    }else{
      console.error(res.msg);
      this.alert.error_small('Hubo un error al obtener los registros del libro de remuneración o no existen registros.');
    }
  }
  async calcularMontosResumenesHaberes(periodo: Date) {
    if (this.haberes.length === 0) {
      this.alert.error_small('No se encontraron haberes para el periodo seleccionado.');
      return;
    }
    // console.log( {...this.trabajadores}, {...this.libroRemuneraciones});

    await this.calcularResumenesLibro();
    this.calcularResumenesHaberes();
    this.calcularPrestamosAdelantos(periodo);
  }
  calcularPrestamosAdelantos(periodo: Date) {
    const prestamosFilter = this.prestamos.filter(p => this.libroRemuneraciones.some(l => l.rut === p.fk_trabajador) && p.estadoDocumento);
    const adelantosFilter = this.adelantos.filter(a => this.libroRemuneraciones.some(l => l.rut === a.rut));
    let resumenPrestamo = { nombre: 'DESCUENTO EMPRESARIAL', tipo: TipoHaberes.Descuento, monto: 0 }
    let resumenAdelanto = { nombre: 'ADELANTO', tipo: TipoHaberes.Descuento, monto: 0 };
    
    // CALCULAR PRESTAMO
    if (prestamosFilter.length > 0) {
      prestamosFilter.forEach(p => resumenPrestamo.monto += p.Cuotas.find(c => {
        const fecha = new Date(c.fechacuota);
        return fecha.getMonth() === periodo.getMonth() && periodo.getFullYear() === fecha.getFullYear();
      }).montocuota);
      this.resumenHaberes.push(resumenPrestamo);
    }
    // CALCULAR ADELANTO
    if (adelantosFilter.length > 0) {
      adelantosFilter.forEach(a => resumenAdelanto.monto += a.montoAdelanto);
      this.resumenHaberes.push(resumenAdelanto);
    }
  }
  // SE CALCULA EL RESUMEN EN BASE A LOS DATOS DEL LIBRO DE REMUNERACIONES
  async calcularResumenesLibro() {
    try {
      let a: any;
      let aArray: any[] = [];
      this.libroRemuneraciones = this.libroRemuneraciones.filter(l => this.trabajadores.some(t => t.rut === l.rut));
      this.resumenHaberes = await this.libroRemuneraciones.reduce(async (aPromise: Promise<any[]>, e:any) => {
        aArray = await aPromise;
        // SUELDO BASE
        a = aArray?.find(x => x.nombre === NombreHaberes.SueldoBase);
        if (a && a?.nombre === NombreHaberes.SueldoBase){
          // a.monto += e.sbase
          aArray = aArray.map(x => {
            if (x.nombre === NombreHaberes.SueldoBase) x.monto += e.sbase;
            return x;
          });
        }else {
          a = { nombre: NombreHaberes.SueldoBase, tipo: TipoHaberes.Haber, monto: e.sbase };
          aArray.push(a);
        }
        
        // GRATIFICACION LEGAL
        a = aArray?.find(x => x.nombre === NombreHaberes.GratificacionLegal);
        if (a && a?.nombre === NombreHaberes.GratificacionLegal){
          // a.monto += e.glegal;
          aArray = aArray.map(x => {
            if (x.nombre === NombreHaberes.GratificacionLegal) x.monto += e.glegal;
            return x;
          });
        } else {
          a = { nombre: 'GRATIFICACION LEGAL', tipo: TipoHaberes.Haber, monto: e.glegal };
          aArray.push(a);
        }
        
        // ASIGNACION FAMILIAR
        if (e.asig_familiar > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.AsignacionFamiliar);
          if (a && a?.nombre === NombreHaberes.AsignacionFamiliar){
            // a.monto += e.asig_familiar;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.AsignacionFamiliar) x.monto += e.asig_familiar;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.AsignacionFamiliar, tipo: TipoHaberes.Haber, monto: e.asig_familiar };
            aArray.push(a);
          }
        }
        // BENEFICIO SEMANA CORRIDA
        if (e.bsemanacorrida > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.BeneficioSemanaCorrida);
          if (a && a?.nombre === NombreHaberes.BeneficioSemanaCorrida) {
            // a.monto += e.bsemanacorrida;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.BeneficioSemanaCorrida) x.monto += e.bsemanacorrida;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.BeneficioSemanaCorrida, tipo: TipoHaberes.Haber, monto: e.bsemanacorrida };
            aArray.push(a);
          }
        }
        // PREVISION
        if (e.prevision > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.Prevision);
          if (a && a?.nombre === NombreHaberes.Prevision) {
            // a.monto += e.prevision;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.Prevision) x.monto += e.prevision;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.Prevision, tipo: TipoHaberes.Descuento, monto: e.prevision };
            aArray.push(a);
          }
        }
        // SALUD
        if (e.salud > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.Salud);
          if (a && a?.nombre === NombreHaberes.Salud) {
            // a.monto += e.salud;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.Salud) x.monto += e.salud;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.Salud, tipo: TipoHaberes.Descuento, monto: e.salud };
            aArray.push(a);
          }
        }
        // HORAS EXTRAS
        if (e.horasextras > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.HorasExtras);
          if (a && a?.nombre === NombreHaberes.HorasExtras) {
            // a.monto += e.horasextras;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.HorasExtras) x.monto += e.horasextras;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.HorasExtras, tipo: TipoHaberes.Haber, monto: e.horasextras };
            aArray.push(a);
          }
        }
        // IMPUESTO UNICO
        if (e.impuestounico > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.ImpuestoUnico);
          if (a && a?.nombre === NombreHaberes.ImpuestoUnico) {
            // a.monto += e.impuestounico;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.ImpuestoUnico) x.monto += e.impuestounico;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.ImpuestoUnico, tipo: TipoHaberes.Descuento, monto: e.impuestounico };
            aArray.push(a);
          }
        }
        // SEGURO CESANTIA
        if (e.scesantia > 0) {
          a = aArray?.find(x => x.nombre === NombreHaberes.SeguroCesantia);
          if (a && a?.nombre === NombreHaberes.SeguroCesantia) {
            // a.monto += e.scesantia;
            aArray = aArray.map(x => {
              if (x.nombre === NombreHaberes.SeguroCesantia) x.monto += e.scesantia;
              return x;
            });
          } else {
            a = { nombre: NombreHaberes.SeguroCesantia, tipo: TipoHaberes.Descuento, monto: e.scesantia };
            aArray.push(a);
          }
        }
        const totalLiquido = this.calcularMontoLiquido(e);
        // LIQUIDO DESCUENTO
        this.calcularTotalLiquido(aArray, NombreHaberes.Liquido, TipoHaberes.Descuento, totalLiquido);
        // a = aArray?.find(x => x.nombre === NombreHaberes.Liquido);
        // const totalLiquidoDescuento = this.calcularMontoLiquido(e);
        // if (totalLiquidoDescuento >= 0) {
        //   if (a?.nombre === NombreHaberes.Liquido && a?.tipo === TipoHaberes.Descuento) {
        //     aArray = aArray.map(x => {
        //       if (x.nombre === NombreHaberes.Liquido && x.tipo === TipoHaberes.Descuento) x.monto += totalLiquidoDescuento;
        //       return x;
        //     });
        //   } else {
        //     a = { nombre: NombreHaberes.Liquido, tipo: TipoHaberes.Descuento, monto: totalLiquidoDescuento };
        //     aArray.push(a);
        //   }
        // }
        // LIQUIDO HABER
        this.calcularTotalLiquido(aArray, NombreHaberes.Liquido, TipoHaberes.Haber, totalLiquido);
        // a = aArray?.find(x => x.nombre === NombreHaberes.Liquido);
        // const totalLiquidoHaber = this.calcularTotalLiquido(e);
        // if (totalLiquidoHaber < 0) {
        //   if (a?.nombre === NombreHaberes.Liquido && a?.tipo === TipoHaberes.Haber) {
        //     aArray = aArray.map(x => {
        //       if (x.nombre === NombreHaberes.Liquido && x.tipo === TipoHaberes.Haber) x.monto += totalLiquidoHaber;
        //       return x;
        //     });
        //   } else {
        //     a = { nombre: NombreHaberes.Liquido, tipo: TipoHaberes.Haber, monto: totalLiquidoHaber };
        //     aArray.push(a);
        //   }
        // }

        // DATOS
        const { esPlazoFijo, Jubilado, tieneLicencia } = await this.obtenerDatosTrabajador({rut: e.rut, periodo: e.f_liquidacion});
        const totalImp = tieneLicencia ? await this.obtenerTotalImp(e.rut) : e.totalimp;
        const diasLicencia = tieneLicencia && this.movimiento ? this.calcularDiasLicencias(this.movimiento, e.f_liquidacion) : 0;
        const trabajador = this.trabajadores.find(x => x.rut === e.rut);
        const mayor65Year = differenceInYears(new Date(), parseISO(trabajador.fecha_nacimiento)) > 65;
        const totalimp = totalImp > 0 ? totalImp : e.totalimp;
        const codMutual = this.obtenerCodMutual(trabajador.Empresa_contratante.nombreMutual);
        const tasaBasica = parseFloat(trabajador.Empresa_contratante.cotizBasica) / 100;
        const tasaLeySanna = parseFloat(trabajador.Empresa_contratante.cotizLeySanna) / 100;
        const tasaMutual = parseFloat(tasaBasica.toFixed(4)) + parseFloat(tasaLeySanna.toFixed(4));
        // SEGURO DE CESANTIA - EMPLEADOR
        const segCesantiaEmpleador = Jubilado ? 0 : esPlazoFijo && tieneLicencia
          ? Math.round((((totalimp / 30) * diasLicencia) * 0.03) + (totalimp * 0.03)) : tieneLicencia && !esPlazoFijo 
          ? Math.round((((totalimp / 30) * diasLicencia) * 0.024) + (totalimp * 0.024)) : esPlazoFijo
          ? Math.round(totalimp * 0.03) : Math.round(totalimp * 0.024);
          
        a = aArray?.find(x => x.nombre === NombreHaberes.SeguroCesantiaEmpleador);
        if (a && a?.nombre === NombreHaberes.SeguroCesantiaEmpleador) {
          // a.monto += segCesantiaEmpleador;
          aArray = aArray.map(x => {
            if (x.nombre === NombreHaberes.SeguroCesantiaEmpleador) x.monto += segCesantiaEmpleador;
            return x;
          });
        } else {
          a = { nombre: NombreHaberes.SeguroCesantiaEmpleador, tipo: TipoHaberes.Ambos, monto: segCesantiaEmpleador};
          aArray.push(a);
        }
        // ACCIDENTE DE TRABAJO
        const acc_trabajo = codMutual === 0 ? 0 : tieneLicencia 
          ? Math.round((((totalimp / 30) * diasLicencia) * tasaLeySanna) + (totalimp * tasaMutual)) 
          : Math.round(totalimp * tasaMutual);
        
        a = aArray?.find(x => x.nombre === NombreHaberes.MutualSeguridad);
        if (a && a?.nombre === NombreHaberes.MutualSeguridad) {
          // a.monto += acc_trabajo;
          aArray = aArray.map(x => {
            if (x.nombre === NombreHaberes.MutualSeguridad) x.monto += acc_trabajo;
            return x;
          });
        } else {
          a = { nombre: NombreHaberes.MutualSeguridad, tipo: TipoHaberes.Ambos, monto: acc_trabajo};
          aArray.push(a);
        }

        // SEGURO DE INAVLIDEZ Y SOBREVIVENCIA - EMPLEADOR
        const sis = Jubilado || mayor65Year ? 0 : tieneLicencia 
          ? Math.round(((totalimp / 30) * diasLicencia) * (this.previred.seguroInvalidez / 100) + (totalimp * (this.previred.seguroInvalidez / 100))) 
          : Math.round(totalimp * (this.previred.seguroInvalidez / 100));
        a = aArray?.find(x => x.nombre === NombreHaberes.SIS);
        if (a && a?.nombre === NombreHaberes.SIS) {
          // a.monto += sis;
          aArray = aArray.map(x => {
            if (x.nombre === NombreHaberes.SIS) x.monto += sis;
            return x;
          });
        } else {
          a = { nombre: NombreHaberes.SIS, tipo: TipoHaberes.Ambos, monto: sis};
          aArray.push(a);
        }
        return aArray;
      }, Promise.resolve([]));
    } catch (error) {
      console.log({error});
      this.alert.error_small(error.message);
    }
  }
  calcularMontoLiquido(e: any): number {
    const { totalhaberes, totaldescuento } = e;
    return totalhaberes - totaldescuento;
  }
  calcularTotalLiquido(listaResumen: any[], nombreHaber: string, tipoHaber: string, total: number){
    const a = listaResumen?.find(x => x.nombre === nombreHaber);

    if ((total >= 0 && tipoHaber === TipoHaberes.Descuento) || (total < 0 && tipoHaber === TipoHaberes.Haber)) {
      if (a?.nombre === nombreHaber && a?.tipo === tipoHaber) {
        listaResumen = listaResumen.map(x => {
          if (x.nombre === nombreHaber && x.tipo === tipoHaber) x.monto += total;
          return x;
        });
      } else {
        listaResumen.push({ nombre: nombreHaber, tipo: tipoHaber, monto: total });
      }
    }
  }
  async obtenerTotalImp(rut: number): Promise<number> {
    const res = await this.contabilidadService.TraerPorRutAll(rut);
    if (res.success) {
      const registros = res.data.sort((a, b) => {
        const fechaA = parseISO(a.f_liquidacion);
        const fechaB = parseISO(b.f_liquidacion);
        return fechaB.getTime() - fechaA.getTime();
      });
      const registro = registros.find(x => x.d_trabajados > 0);
      return registro ? registro.totalimp : 0;
    }else{
      return 0;
    }
  }
  // SE CALCULA EL RESUMEN EN BASE A LOS HABERES
  calcularResumenesHaberes() {
    // let aArray: any[] = []; 
    const rutSinRegistroLibro: any[] = [];
    this.haberes = this.haberes.filter(h => this.libroRemuneraciones.some(l => l.rut === h.trabajador));
    const resumenHaberesAux = this.haberes.reduce((a: any[],e: any) => {
      const registro = this.libroRemuneraciones.find(x => x.rut === e.trabajador)
      if (registro) {
        const diasTrabajados = registro.d_trabajados;
        const nombreHaber = e.Haberes.nombre.toUpperCase();
        const tipoHaber = e.Haberes.tipo === 1 ? TipoHaberes.Haber : TipoHaberes.Descuento;
        const montoHaber = diasTrabajados < 30 && e.Haberes.proporcional === 1 ? e.monto / 30 * diasTrabajados : e.monto;
        if (a.length > 0 && a?.some(x => x.nombre === nombreHaber)) {
          a = a.map(x => {
            if (x.nombre === nombreHaber) x.monto += Math.round(montoHaber);
            return x;
          })
        } else {
          a.push({ nombre: nombreHaber, tipo: tipoHaber, monto: Math.round(montoHaber) });
        }
        return a;
      }else{
        rutSinRegistroLibro.push(e.trabajador);
        return a;
      }
    }, []);
    this.resumenHaberes = [
      ...this.resumenHaberes,
      ...resumenHaberesAux,
    ];

    let resumenHaberesFinal: any[] = [];
    
    this.resumenHaberes.forEach(x => {
      const { nombre, tipo, monto } = x;
      if (tipo === TipoHaberes.Ambos) {
        const descuento = { nombre: nombre, tipo: TipoHaberes.Descuento, monto: monto };
        const haber = { nombre: nombre, tipo: TipoHaberes.Haber, monto: monto };
        resumenHaberesFinal = [
          ...resumenHaberesFinal,
          descuento,
          haber,
        ]
      }else{
        resumenHaberesFinal = [
          ...resumenHaberesFinal,
          x,
        ]
      }

    })
    this.resumenHaberes = resumenHaberesFinal;
  }
  generarExcel() {
    // LIMPIAMOS Y ORDENAMOS LOS DATOS
    this.resumenHaberes = this.resumenHaberes
      .filter(x => x.monto > 0)
      .sort((a,b) => a.nombre.localeCompare(b.nombre));
    try {
      const header = ["DESCRIPCION", "TIPO", "VALOR"];
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, [header], {origin: 'A1'});
      XLSX.utils.sheet_add_json(ws, this.resumenHaberes, {origin: 'A2', skipHeader: true});
      XLSX.utils.book_append_sheet(wb,ws,"Resumen Haberes");
      XLSX.writeFile(wb, "Resumen Haberes - "+this.empresa.nombre.toUpperCase()+".xlsx");
    } catch (error) {
      console.error(error);
      this.alert.error_small('Hubo un error al generar el excel.');
    }
  }
  async obtenerDatosTrabajador(params: { rut: number; periodo: string; }): Promise<{ esPlazoFijo: boolean; Jubilado: boolean; tieneLicencia: boolean; }> {
    const trabajador = this.trabajadores.find(x => x.rut === params.rut);
    if (!trabajador) throw new Error('Al obtener los datos del trabajador cuando se calcula los montos, no se encuentra ningun registro');
    // ES PLAZO FIJO ?
    const esPlazoFijo: boolean = trabajador.estado_contrato === 'Contrato a Plazo Fijo';
    // ESTA JUBILADO ?
    const Jubilado: boolean = trabajador.jubilado === 1;
    // LICENCIA ?
    const tieneLicencia: boolean = await this.verificarLicencias(params.rut, params.periodo);

    return {
      esPlazoFijo,
      Jubilado,
      tieneLicencia
    }
  }
  // VERIFICA SI EXISTE LICENCIA DEL TRABAJADOR
  async verificarLicencias(rut: number, periodoString: string): Promise<boolean> {
    const periodo = parseISO(periodoString);
    let movimientos: MovimientosPersonal[] = [];
    const res = await this.movimientosPersonalesService.obtenerMovimientosPersonalesPorRut(rut);
    if (res.success) {
      movimientos = res.data.filter(x => {
        const firstDatePeriodo = startOfMonth(periodo);
        const lastDatePeriodo = endOfMonth(periodo);
        const fechaInicioMovimiento = x.fecha_inicio ? parseISO(x.fecha_inicio) : firstDatePeriodo;
        const fechaFinalMovimiento = x.fecha_final ? parseISO(x.fecha_final) : lastDatePeriodo;
        return isWithinInterval(fechaInicioMovimiento, { start: firstDatePeriodo, end: lastDatePeriodo }) 
          || isWithinInterval(fechaFinalMovimiento, { start: firstDatePeriodo, end: lastDatePeriodo }); 
      });
      this.movimiento = movimientos.find(x => x.TiposEvento.codigo === 3);
      return movimientos.some(x => x.TiposEvento.codigo === 3);
    }else{
      console.error(res.msg);
      this.movimientos = movimientos;
      return movimientos.some(x => x.TiposEvento.codigo === 3);
    }
  }
  async obtenerHaberesPeriodo(opciones_elegidas: OpcionesElegidasResumen){
    this.haberes = [];
    const periodo = opciones_elegidas.periodo;
    const periodoString = this.crearFechaString(periodo);
    if (periodoString === '') {
      this.alert.error_small('No se pudo crear el periodo.');
      return;
    }

    const res = await this.haberesTrabajadorServices.TraerHaberPorPeriodoAsync(periodoString);
    if (res.success) {
      const haberes = res.data;
      // this.trabajadores.forEach(trabajador => {
      //   const haber = haberes.find(h => h.trabajador === trabajador.rut);
      //   if (haber) this.haberes.push(haber);
      // })
      this.haberes = haberes.filter(haber => this.trabajadores.some(x => x.rut === haber.trabajador));
    }else{
      console.error(res.msg);
      this.alert.error_small('Hubo un error al obtener los haberes o no existen registros.')
    }
  }
  async obtenerTrabajadores(opciones_elegidas: OpcionesElegidasResumen) {
    try {
      const res = await this.trabajadorService.TraerTrabajadoresPorEmpresaAsync(opciones_elegidas.empresaSelected);
      if (res.success) {
        this.trabajadores = res.data;
      }else{
        console.error(res.msg);
        this.alert.error_small('Hubo un error al obtener los trabajadores.');
      }
    } catch (error) {
      console.error(error);      
      this.alert.error_small('Hubo un error en la conexion o al obtener los trabajadores.');
    }
  }
  calcularDiasLicencias(movimiento: MovimientosPersonal, periodoString: string): number {
    const periodo = parseISO(periodoString); 
    const fechaInicio = parseISO(movimiento.fecha_inicio);
    const fechaFinal = parseISO(movimiento.fecha_final);
    const diasLicencia = eachDayOfInterval({ start: fechaInicio, end: fechaFinal })
      .filter(x => x.getMonth() === periodo.getMonth() && x.getFullYear() === periodo.getFullYear());
    return diasLicencia.length;
  }
  crearFechaString(periodo: Date) {
    if (!periodo) {
      return '';
    }
    const month = periodo.getMonth() + 1;
    if (month < 10) {
      return `${periodo.getFullYear()}-0${month}-1`;
    }else{
      return `${periodo.getFullYear()}-${month}-1`;
    }
  }
  obtenerCodMutual(nombreMutual: string): number {
    switch (nombreMutual) {
      case 'Asociación Chilena de Seguridad':
        return 1;
      case 'Mutual de Seguridad CCHC':
        return 2;
      case 'Instituto de Seguridad del Trabajo':
        return 3;
      default:
        return 0;
    }
  }
}
