<div class="modal-body">


    <h4 class="modal-title" id="modal-basic-title">Agregar Impuesto </h4>

    <div class="row">
         <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Trabajador</mat-label>
                <mat-select name="rut" (selectionChange)="ObtenerTrabajador($event)">
                    <mat-option *ngFor="let element of trabajadores_aux" [value]="element.rut" >
                        {{element.nombres}} {{element.apellidos}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> 
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Desde </mat-label>
                <input type="number" matInput name="Desde" [(ngModel)]="impuesto.desde">

            </mat-form-field>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Hasta </mat-label>
                    <input type="number" matInput name="Hasta" [(ngModel)]="impuesto.hasta">

                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cantidad </mat-label>
                    <input type="number" matInput name="Cantidad" [(ngModel)]="impuesto.cantidad">

                </mat-form-field>
            </div>
        </div>

    </div>
    <br>

    <button mat-raised-button class=" button_color_primary text-light boton_guardar " (click)="guardar()">
        Guardar</button>



</div>