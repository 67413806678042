import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Cuotas } from 'src/app/shared/models/cuotas.interface';

@Component({
  selector: 'app-form-cuotas',
  templateUrl: './form-cuotas.component.html',
  styleUrls: ['./form-cuotas.component.css']
})
export class FormCuotasComponent implements OnInit {
  @Output() $cuotas = new EventEmitter<Cuotas[]>();
  @Output() $cuotasEliminadas = new EventEmitter<Cuotas[]>();
  @Input() numCuotas: number;
  @Input() montoPrestamo: number;
  @Input() fechaPrestamo: Date;
  @Input() cuotasPagadas: number;
  @Input() accion: number;
  // VARIABLES DE ACTUALIZAR CUOTAS
  @Input() cuotasListUpdate!: Cuotas[];

  cuotasEliminadas:Cuotas[];

  cuotas: Cuotas[] = [];
  estadoCuota: any[] = [
    { value: true, text: 'Pagado ✅' },
    { value: false, text: 'Pendiente ❌' }
  ];

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  constructor(
    private alert: AlertHelper,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const {
      cuotas,
      fechaPrestamo,
      montoPrestamo,
      numCuotas,
      cuotasPagadas,
      accion,
    } = changes;

    if (cuotas === undefined && fechaPrestamo === undefined && montoPrestamo === undefined
        && numCuotas === undefined && cuotasPagadas === undefined && accion === undefined 
      ) return;

    // console.log(
    //   {cuotas}, {fechaPrestamo}, {montoPrestamo}, 
    //   {numCuotas}, {cuotasPagadas}, {accion}, {cuotasListUpdate}
    // );

    if (accion) {
      this.accion = accion.currentValue;
    }
    if (cuotas) {
      this.cuotas = cuotas.currentValue;
    }
    if (fechaPrestamo) {
      this.fechaPrestamo = fechaPrestamo.currentValue;
    }
    if (montoPrestamo) {
      this.montoPrestamo = parseInt(montoPrestamo.currentValue);
    }
    if (numCuotas) {
      this.numCuotas = numCuotas.currentValue??1;
    }
    if (cuotasPagadas) {
      this.cuotasPagadas = cuotasPagadas.currentValue??0;
    }

    // console.log(this.cuotasPagadas)

    switch (this.accion) {
      case 0:
        this.AgregarCuotas();
        break;
      case 1:
        this.ActualizarCuotas();
        break;
      default: 
        return;
    }
  }

  AgregarCuotas() {
    let fechaAux = new Date(this.fechaPrestamo);
    let countCuota = 0; // CONTADOR PARA EL NUMERO DE CUOTAS
    // SI EL NUMERO DE CUOTAS ES MAYOR QUE EL LARGO DEL ARRAY DE CUOTAS, AGREGAR NUEVOS ELEMENTOS
    if (this.numCuotas > this.cuotas.length) {
      if (this.cuotas.length === 0) {
        this.cuotas = new Array(this.numCuotas).fill(0).map(() => {
          countCuota++;
          const cuota = {
            numcuota: countCuota,
            montocuota: 0,
            estado: false,
            fechacuota: '',
          };
          return cuota;
        });
      } else {
        countCuota = this.cuotas.length;
        const restNumCuotas = this.numCuotas - this.cuotas.length;
        const newCuotas: Cuotas[] = new Array(restNumCuotas).fill(0).map(() => {
          countCuota++;
          return {
            numcuota: countCuota,
            montocuota: 0,
            estado: false,
            fechacuota: '',
          }
        });
        this.cuotas.push(...newCuotas);
      }
    } else if (this.numCuotas < this.cuotas.length) { // SI EL NUMERO DE CUOTAS ES MENOR QUE EL LARGO DEL ARRAY DE CUOTAS, ELIMINAR ELEMENTOS.
      const resNumCuotas = this.cuotas.length - this.numCuotas;
      this.cuotas.splice(-resNumCuotas, resNumCuotas);
    }
    // AGREGAR EL ESTADO DE LAS CUOTAS
    // AGREGAR LAS FECHAS DE LAS CUOTAS
    // CALCULAR EL MONTO DE CADA CUOTA
    countCuota = 0;
    this.cuotas = this.cuotas.map((x: Cuotas) => {
      countCuota++;

      const fechaCuota = new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 5, 0, 0, 0, 0);
      fechaAux = new Date(fechaAux.getFullYear(), fechaAux.getMonth() + 1, 1, 0, 0, 0, 0);
      x.fechacuota = fechaCuota.toISOString();
      x.estado = this.cuotasPagadas >= countCuota && this.cuotasPagadas > 0;
      x.montocuota = this.montoPrestamo !== 0
        ? Math.round(this.montoPrestamo / this.numCuotas)
        : 0;

      return x;
    });
    // EMITIMOS LA CUOTA AL COMPONENTE PADRE
    this.$cuotas.emit(this.cuotas);
  }

  ActualizarCuotas() {
    let fechaAux = new Date(this.fechaPrestamo);
    console.log({fechaAux});
    this.cuotas = this.cuotasListUpdate;
    
    // VERIFICAR SI CAMBIO EL NUMERO DE CUOTAS
    // PARA ELIMINAR O AGREGAR NUEVAS CUOTAS
    if (this.numCuotas > this.cuotas.length) {
      if (this.cuotas.length === 0) {
        this.cuotas = new Array(this.numCuotas).fill(0).map((x:any,i:number) => {
          const countCuota = i + 1;
          const cuota = {
            id: null,
            numcuota: countCuota,
            montocuota: 0,
            estado: false,
            fechacuota: '',
          };
          return cuota;
        });
      } else {
        let countCuota = this.cuotas.length;
        const restNumCuotas = this.numCuotas - this.cuotas.length;
        const newCuotas: Cuotas[] = new Array(restNumCuotas).fill(0).map((x:any) => {
          countCuota++;
          return {
            id: null,
            numcuota: countCuota,
            montocuota: 0,
            estado: false,
            fechacuota: '',
          }
        });
        this.cuotas.push(...newCuotas);
      }
    }else{
      const cuotasAux = this.cuotas.slice();
      const resNumCuotas = this.cuotas.length - this.numCuotas;
      // OBTENGO LAS CUOTAS ELIMINADAS,
      // SOLO LAS YA CREADAS EN LA BASE DE DATOS
      this.cuotasEliminadas = cuotasAux.splice(this.numCuotas).filter(x => x.id !== null);
      this.$cuotasEliminadas.emit(this.cuotasEliminadas);
      // ELIMINO LAS CUOTAS DEL ARRAY PRINCIPAL
      this.cuotas.splice(-resNumCuotas, resNumCuotas);
    }
    this.cuotas = this.cuotas.map((x: Cuotas, i:number) => {
      const countCuota = i + 1;
      const fechaCuota = new Date(fechaAux.getFullYear(), fechaAux.getMonth(), 5, 0, 0, 0, 0);
      fechaAux = new Date(fechaAux.getFullYear(), fechaAux.getMonth() + 1, 1, 0, 0, 0, 0);
      x.fechacuota = fechaCuota.toISOString(); // x.fechacuota === '' ? fechaCuota.toISOString() : x.fechacuota
      x.estado = this.cuotasPagadas >= countCuota && this.cuotasPagadas > 0;

      return x;
    });
    this.$cuotas.emit(this.cuotas);
  }

  obtenerEstado(cuotasChange: number, event: MatSelectChange){
    if (!confirm('Cambiar estado de la cuota.\n¿Seguro que quiere cambiar el estado de la cuota?')) return;

    const estadoCuota: boolean = event.value;
    this.cuotas = this.cuotas.map((cuota:Cuotas, i:number) => {
      const numeroCuota = i + 1;
      cuota.estado = estadoCuota ? 
        cuotasChange >= numeroCuota && numeroCuota > 0 
        : cuotasChange > numeroCuota && numeroCuota > 0;

      // console.log(numeroCuota, {...cuota}, cuota.estado, {estadoCuota});
      return cuota;
    });
    this.$cuotas.emit(this.cuotas);
  }

  recalcularMontoCuotas(){
    this.cuotas = this.cuotas.map(x => {
      x.montocuota = this.montoPrestamo / this.numCuotas;
      return x;
    });
    this.$cuotas.emit(this.cuotas);
  }
}
