import { Component, OnInit } from '@angular/core';
import { MotivosCartaAmonestacionService } from 'src/app/pages/services/motivos-carta-amonestacion.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-main-motivos',
  templateUrl: './main-motivos.component.html',
  styleUrls: ['./main-motivos.component.css']
})
export class MainMotivosComponent implements OnInit {
  motivos: any[] = [];
  constructor(
    private motivosCartaAmonestacionService: MotivosCartaAmonestacionService,
    private alert: AlertHelper,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.TraerMotivos();
  }

  async TraerMotivos(){
    const res = await this.motivosCartaAmonestacionService.getMotivosAsync();
    if (res.success) {
      this.motivos = res.data;
    }else{
      console.error(res.msg);
      this.alert.error_small(res.msg);
    }
  }
}
