import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-finiquitos',
  templateUrl: './main-finiquitos.component.html',
  styleUrls: ['./main-finiquitos.component.css']
})
export class MainFiniquitosComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }

}
