
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { debounceTime, Subject } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { ImplementosUService } from 'src/app/pages/services/implementosU/implementosU.service';



import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-uniforme',
  templateUrl: './ver-uniforme.component.html',
  styleUrls: ['./ver-uniforme.component.css']
})
export class VerUniformeComponent implements OnInit {


  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @Input() rut !: any;
  debouncer: Subject<boolean> = new Subject();
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  constructor(

    private modalService: NgbModal,
    private alert: AlertHelper,
    private implementosUService: ImplementosUService,
    private auth: AuthService

  ) { 


    this.uploader.onCompleteAll = () => {

  
      // console.log("estoy aqui" +this.proceso);
    };
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.proceso = 1 ;

      //console.log("estoy en el segundo" +this.proceso);
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user= this.auth.ObtenerinfoToken();
      form.append('estado', this.uniforme_update.estadoU);
      form.append('id', this.uniforme_update.id);
      form.append('fk_trabajador', this.updateU.fk_trabajador);
      form.append('userAt', user.nombre);


    };


  }


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  /////—————  Variables   ————-\\\\\\



  fechaT: Date;
  fechaFormatT: string;
  
  dataSource: any;
  RutTrabajador: any;
  ImplementosUList: any;
  UniformeList: any;
  mostrar: any = 0;
  checked: boolean = false;
  pendiente: any = 0;
  TrabajadorList: any;
  uniforme_update : any = [];
  modalReference = null; 
  info_documento :any;
  modalReferencee = null; 

  proceso: any = 0;

  /////————— Variable BD AND TABLA   ————-\\\\\\


  displayedColumns: string[] = [
    'fecha',
    'tallapolera',
    'cantidadpolera',
    'tallapoleron',
    'cantidadpoleron',
    'tallapantalon',
    'tallazapato',
    'cantidadzapato',
    'adicional',
    'estado',
    'acciones',
  ]

  updateU: any = {

    id: 0,
    estadoU: "",
    fk_trabajador: "",


 
}



  /////—————  Select   MatSelectChange————-\\\\\\



  /////—————  Select   MatDatepickerInputEvent————-\\\\\\




  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\


  onChangeSlide($event: any) {

    if ($event.checked) {
      this.mostrar = 1
      this.checked = true
    }
    else {
      this.mostrar = 0
      this.checked = false

    }
  }

  //FUNCION PARA FILTRAR LA COLUMNA
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  

  
  //MODAL PARA SUBIR UN ARCHIVO
  updated(upp: any, rut: any) {

  
    this.uniforme_update = rut;
  

  this.modalReferencee = this.modalService.open(upp, { size: 'lg' })
  

  }

  //MODAL PARA EDITA EL UNIFORME
 open2(contenidoo: any, rut: any) {

  this.uniforme_update = rut;

  this.modalReference = this.modalService.open(contenidoo, { size: 'lg' })

   }
  


buscar(){


  this.implementosUService.TraerPorRutU(this.rut).subscribe(
    (res) => {

   
      this.dataSource = [];
     
      // this.buscar();
      
      this.ImplementosUList = res.data;
      this.dataSource = new MatTableDataSource(this.ImplementosUList);
   
      this.dataSource.paginator = this.paginator

});
}

    cerrarModal(){
      this.modalService.dismissAll(); 
    }
   
      
    
//FUNCION PARA ELIMINAR UN UNIFORME

    eliminarU(contenido: any, id: any) {
      Swal.fire({
        title: 'Eliminar?',
        text: "Se eliminara de las Uniforme",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#ff0000',
        cancelButtonColor: '#6c736e',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
    
          this.implementosUService.eliminar(id).subscribe(res => {
            if (res.success == true) {
             
              this.alert.success_small(res.msg!)
              this.debouncer.next(true);
              this.ngOnInit();
            } else {
              this.alert.error_small(res.msg!)
            }
          });
        }
      })
    
    }

    //FUNCION PARA DESCARGAR EL DOCUMENTO SUBIDO
    Descargar(id:any){
      this.implementosUService.TraerArchivo(id).subscribe(
        (res)=>{
        
          if (res.success==true){
  
            
          
          let ultimo =res.data.length-1
          this.info_documento =res.data[ultimo]; 
    

          window.open(`${environment.API}/implementosU/` + this.info_documento.ruta);
        
          }
          else{
            this.alert.errorAlert("No se encuentran archivos");
          }
        }
        
      );
    }
 

    public uploader: FileUploader = new FileUploader({
      url: `${environment.API}/rrhh/implementosU/documento`,
      itemAlias: 'implementosU',
  
    });
  

    limpiar(){

      this.uniforme_update.estadoU = "";
      this.uniforme_update.id = "";

      this.uniforme_update.fk_trabajador= "";


    }

    //FUNCION PARA ACTUALIZAR EL ESTADO 
    guardarU() {

      this.uniforme_update.estadoU = "LISTO";
    
      this.implementosUService.actualizarEstadoU(this.uniforme_update).subscribe(res => {
  
       
        
             this.alert.success_small(res.msg)
            // this.modalService.dismissAll();
       
             this.ngOnInit();
             this.debouncer.next(true);
        
       
         
       })
   
        this.uploader.uploadAll();

      
      
        
    }

    //ACTUALIZAR  IMPLEMENTOS
    actualizar(){

      this.implementosUService.actualizar(this.uniforme_update).subscribe(res => {
        
      
        
            if (res.success == true) {
              this.alert.success_small(res.msg!)
              this.modalReference.close();
              this.debouncer.next(true);
              this.ngOnInit();
      
      
            } else {
              this.alert.error_small(res.msg!)
            }
  
      })
  
    }
  
  
  /////—————AL INICIAR EL MODULO   ————-\\\\\\


  ngOnInit(): void {

    this.updateU.fk_trabajador = this.rut;

    this.debouncer
    .pipe(debounceTime(500))
    .subscribe(valor => {
      this.onDebounce.emit(valor);
    });

    
    this.buscar();

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
     ) => {
      let result = JSON.parse(response);
      
 
      if (result.response == true) {
     
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        });
        this.modalReferencee.close();

        // this.modalService.dismissAll(); 
      

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',

        })

      }
     }
 
  }

}


 

