<div zclass="animate__animated animate__fadeInRight">
    <mat-card class="mat_card_razon">
        <h1 class="text-center">RAZONES</h1>

        <div class="row">


            <div class="col-md-2 text-center">
                <app-agregar-razon (onDebounce)="TraerDatos($event)"> </app-agregar-razon>
            </div>
            <div class="col-md-2 col-offset-8 text-right">
                <mat-form-field appearance="outline">
                    <mat-label>Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input>
                </mat-form-field>
            </div>
            <div class="col-12">

             
                <div class="container-fluid">

                    <table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
                        </ng-container>
                        <ng-container matColumnDef="razon">
                            <th mat-header-cell *matHeaderCellDef> Razon </th>
                            <td mat-cell *matCellDef="let element"> {{ element.razon}} </td>
                        </ng-container>
                        <ng-container matColumnDef="colorfondo">
                            <th mat-header-cell *matHeaderCellDef> Color Texto </th>
                            <td mat-cell *matCellDef="let element"> {{ element.colortexto }} </td>
                        </ng-container>
                        <ng-container matColumnDef="colortexto">
                            <th mat-header-cell *matHeaderCellDef> Color Fondo </th>
                            <td mat-cell *matCellDef="let element"> {{ element.colorfondo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="btncolortexto">
                            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button class="apartado1" 
                                    [style.background-color]="element.colorfondo"
                                    [style.color]="element.colortexto">
                                    {{element.razon}}
                                </button>
                                <!-- -->
                            </td>
                        </ng-container>
                        
                      
                        
                        <ng-container matColumnDef="acciones">
                            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button class="button_color_primary" (click)="open(up, element.id)">
                                    <mat-icon style="color: white">edit</mat-icon>
                                </button> &nbsp;
                                <button (click)="eliminar(element.id)" mat-button class="button_color_primary"
                                    style="background-color:red">
                                    <mat-icon style="color: white">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de horas extras.">
                    </mat-paginator>
                </div>

                
            </div>
        </div>

    </mat-card>

    <!-- modal -->
    <ng-template #up let-modal3>
        <div class="modal-header" style="text-align: center">
            <mat-card-title>Actuaizar razón</mat-card-title>
            <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Razon</mat-label>
                    <input type="text" name="razon" matInput [(ngModel)]="razones_update.razon" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Atraso</mat-label>
                    <mat-select [(ngModel)]="razones_update.esAtraso">
                        <mat-option *ngFor="let opcion of opciones" [value]="opcion.value">{{ opcion.text }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <table style="width:100%">
                    <tr>
                        <td class="center">
                            <h4>Color de fondo</h4>
                            <p-colorPicker name="colorpicker" [(ngModel)]="razones_update.colorfondo" ></p-colorPicker>
                        </td>
                        <td class="center"> 
                            <h4>Color de texto</h4>
                            <p-colorPicker name="colorpicker" [(ngModel)]="razones_update.colortexto" ></p-colorPicker>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button color="primary" (click)="ActualizarRazon()">
                <mat-icon>backup</mat-icon>
                Actualizar
            </button>
        </div>
    </ng-template>
</div>