import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';
import { TiposPrestamoService } from 'src/app/pages/services/tipoprestamo.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-agregar-tipoprestamo',
  templateUrl: './agregar-tipoprestamo.component.html',
  styleUrls: ['./agregar-tipoprestamo.component.css']
})
export class AgregarTipoprestamoComponent implements OnInit {

  constructor( private modalService: NgbModal,
    private alert: AlertHelper,
    private tprestamoService: TiposPrestamoService) { }


  nombre: string;
  descripcion: string;

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();


  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
    
  }

  open(content:any){
    this.modalService.open(content);
  }

  guardar(){
    const data = {
        nombre: this.nombre,
        descripcion: this.descripcion
    }
    this.tprestamoService.Crear_TipoPrestamo(data).subscribe(res =>{
      if(res.success){
        this.alert.success_small("Se creó con éxito")
        this.modalService.dismissAll();
        this.limpiarCampos();
        this.debouncer.next( true );
      }
      else{
        this.alert.error_small(res.msg!);
      }
    })
  }

  limpiarCampos(){
    this.nombre = '';
    this.descripcion = '';
  }


}
