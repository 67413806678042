import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPreviredComponent } from './main-previred/main-previred.component';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { GenerarpreviredComponent } from './generarprevired/generarprevired.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MainPreviredComponent,
    GenerarpreviredComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    MainPreviredComponent,
    GenerarpreviredComponent,
  ]
})
export class PreviredModule { }
