import { MaterialModule } from './../../../material.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgregarPrevisionComponent } from './agregar-prevision/agregar-prevision.component';
import { ListaPrevisionComponent } from './lista-prevision/lista-prevision.component';
import { MainPrevisionComponent } from './main-prevision/main-prevision.component';


@NgModule({
  declarations: [
    AgregarPrevisionComponent,
    ListaPrevisionComponent,
    MainPrevisionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  exports:[
    AgregarPrevisionComponent,
    ListaPrevisionComponent,
    MainPrevisionComponent
  ]
})
export class PrevisionModule { }
