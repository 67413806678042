import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';


@Injectable({
  providedIn: 'root'
})
export class contabilidadService {

  constructor(
    private http:HttpClient,
    private alert:AlertHelper
  ) { }

  TraerTodo(): Observable<RequestResponse> {

    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/ventas/`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async TraerTodoAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/ventas/`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Ocurrio un error al obtener los registros.'} as RequestResponse)      
    }
  }

  async TraerPorRut(rut: number): Promise<RequestResponse>{
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/ventas/traerPorRut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error en la solicitud.'} as RequestResponse)
    }
  }

  async TraerPorRutAll(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/ventas/traerPorRutAll/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error en la solicitud.'} as RequestResponse)
    }
  }

  async TraerPorLiquidacionAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/ventas/traerPorLiquidacion/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error en la solicitud.'} as RequestResponse)
    }
  }

  async TraerPorPeriodoAsync(periodo: string): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/ventas/traerPorPeriodo/${periodo}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener los registros' } as RequestResponse;
    }
  }

  // TraerPorId(id:number): Observable<RequestResponse> {
  //   return this.http
  //   .get<RequestResponse >(`${environment.API}/rrhh/contabilidad/${id}`)
  //   .pipe(
  //     map((res:RequestResponse) =>{ 
  //       return res;
  //     }),
  //     catchError((err)=> this.handlerError(err))
  //   );
  // }

  crear(remu:any): Observable<RequestResponse> {        
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/ventas/crear`,remu)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async crearAsync(remu:any): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/ventas/crear`,remu).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al crear el registro.'} as RequestResponse)
    }
  }

  eliminar(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/ventas/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  actualizar(ventas:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse>(`${environment.API}/rrhh/ventas/actualizar/ ${ventas.id}`,ventas)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  


  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
