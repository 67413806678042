import { data } from 'jquery';
import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import {Asistencia,RequestResponseGetAllAsistencias} from 'src/app/shared/models/gestor-asistencia/gestor-asistencia.interface';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';


@Injectable({
  providedIn: 'root'
})
export class GestorAsistenciaService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

  ObtenerTodo(): Observable<RequestResponseGetAllAsistencias> {
    return this.http
    .get<RequestResponseGetAllAsistencias >(`${environment.API}/rrhh/calendario`)
    .pipe(
      map((res:RequestResponseGetAllAsistencias) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async ObtenerTodoAsync(): Promise<RequestResponseGetAllAsistencias>{
    try {
      return await this.http.get<RequestResponseGetAllAsistencias>(`${environment.API}/rrhh/calendario`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener la asistencia'} as RequestResponseGetAllAsistencias
    }
  }


  crear(asistencia:Asistencia): Observable<RequestResponse> {


    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/calendario/crear`,asistencia)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  Eliminar(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/calendario/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  TraerPorId(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/calendario/buscar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  getDiasFaltados(rut:any, mes:any, year:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/calendario/diasfaltados/${rut}/${mes}/${year}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  ObtenerAsistenciaPeriodo(rut:number, fecha: Date){
    const mes = fecha.getMonth() + 1;
    const year = fecha.getFullYear();
    return this.http
    .get<RequestResponseGetAllAsistencias>(`${environment.API}/rrhh/calendario/asistenciaperiodo/${rut}/${mes}/${year}`)
    .pipe(
      map((res:RequestResponseGetAllAsistencias) => res),
      catchError((err)=> this.handlerError(err))
    );
  }
  async ObtenerAsistenciaPeriodoAsync(rut:number, fecha: Date): Promise<RequestResponse>{
    try {
      const mes = fecha.getMonth() + 1;
      const year = fecha.getFullYear();
      return await this.http.get<RequestResponseGetAllAsistencias>(`${environment.API}/rrhh/calendario/asistenciaperiodo/${rut}/${mes}/${year}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los datos de Asistencia'} as RequestResponse);
    }
  }
  async buscarPorRut(rut: number): Promise<RequestResponseGetAllAsistencias>{
    try {
      return await this.http.get<RequestResponseGetAllAsistencias>(`${environment.API}/rrhh/calendario/buscarporrut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener la asistencia'} as RequestResponseGetAllAsistencias
    }
  }

  ActualizarAsistencia(asistencia:any): Observable<RequestResponse>  {

    return this.http
    .put<RequestResponse>(`${environment.API}/rrhh/calendario/actualizar/ ${asistencia.cp_calendario}`,asistencia)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  TraerTrabajadores(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/trabajador`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  TraerRazonId(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse>(`${environment.API}/rrhh/razon`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  lista_razon(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/razon/listar`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
    }





  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
