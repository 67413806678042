import { Injectable } from '@angular/core';
import { CiudadService } from '../ciudades.service';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { Previsional } from 'src/app/shared/models/previsonal';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { CiudadRegion } from 'src/app/shared/models/ciudades.interface';
import { Adelantos } from 'src/app/shared/models/adelantos.interface';
import { Prestamos } from 'src/app/shared/models/prestamos.interface';
import { Cuotas } from 'src/app/shared/models/cuotas.interface';
import { Asistencia } from 'src/app/shared/models/gestor-asistencia/gestor-asistencia.interface';

import { LiquidacionService } from './liquidacion.service';
import { AdelantosService } from '../adelantos/adelantos.service';
import { PrestamosService } from '../prestamos.service';
import { TrabajadoresService } from '../trabajadores/trabajadores.service';
import { GestorAsistenciaService } from '../gestor-asistencia/gestor-asistencia.service';
import { TrabajadorHaberService } from '../TrabajadorHaber/trabajador-haber.service';
import { LicenciasService } from '../licencias/licencias.service';
import { VacacionesService } from '../vacaciones/vacaciones.service';
import { MovimientosPersonalService } from '../movimientos-personal.service';

import Holidays from 'date-holidays';
import { eachDayOfInterval, endOfMonth, isSameMonth, isSameYear, isWithinInterval, parseISO, startOfMonth } from 'date-fns';
import moment from 'moment';
import { MovimientosPersonal } from 'src/app/shared/models/movimientospersonal.interface';
import { HorasExtrasService } from '../horasExtras/horasExtras.service';
import { HelperService } from '../helper/helper.service';


@Injectable({
  providedIn: 'root'
})
export class FuncionesLiquidacionesService {
  holidays = new Holidays('CL');
  periodo: Date = new Date();
  rut: number;

  sucursalTrabajador: Sucursal;
  ciudades: CiudadRegion[] = [];
  movimientos: MovimientosPersonal[] = [];

  adelantosList: Adelantos[] = [];
  montoAdelanto: number = 0;
  numAdelanto: number = 0;

  prestamos: Prestamos[] = [];
  cuota: Cuotas;

  asistencias: any[] = [];

  modeFiniquito: boolean = false;
  milisecons:number = 1000;

  movimiento: MovimientosPersonal[] = [];

  libroremuneracion = {
    idliquidacion: null,
    rut: 0,
    nombre: '',
    f_start: new Date(),
    f_liquidacion: new Date(),
    sucursal: '', //Es la empresa
    sucur: '', //La sucursal, no lo edite porque...
    adelanto: 0,
    horasextras: 0,
    horasExtras: 0,
    glegal: 0,
    otroimp: 0,
    totalimp: 0,
    otronoimp: 0,
    totalnoimp: 0,
    prevision: 0,
    isapre: 0,
    salud: 0,
    scesantia: 0,
    otrodesclegales: 0,
    totaldesclegales: 0,
    totaldescuentos: 0,
    liquido: 0,
    sbase: 0,
    impuestounico: 0,
    desuentovarios: 0,
    asig_familiar: 0,
    d_trabajados: 0,
    fecha_inicio_contratos: '',
    cod_region_prest_servicios: 0,
    cod_comuna_prest_servicios: 0,
    cod_tipo_jornada: 101,
    cod_discapacidad: 0,
    jubilado: false,
    isapre_fonasa: 102, //Por defecto Fonasa
    cod_afp: 100, //por defecto ninguna
    afc: 1, //por defecto si
    seguroInvalidez: 0,
    comision: 0,
    bsemanacorrida: 0,
    colacion: 0,
    movilizacion: 0,
    viaticos: 0,
    ret_clase_media: 0,
    sueldo: 0,
    sobresueldo: 0,
    participacion: 0,
    recargo_domingo: 0,
    rem_pagada_vacaciones: 0,
    rem_pagada_clausura: 0,
    aguinaldo: 0,
    bonos_rem_fijas: 0,
    no_pactado_contrato: 0,
    benef_remun: 0,
    pago_horas_sindical: 0,
    subs_incap_laboral: 0,
    beca_estudio: 0,
    gratificacion_zona: 0,
    ingresos_no_constitutivos: 0,
    perdida_caja: 0,
    desg_herramientas: 0,
    gasto_trabajo: 0,
    gasto_residencia: 0,
    sala_cuna: 0,
    asign_teletrabajo: 0,
    aloj_trabajo: 0,
    asig_traslacion: 0,
  };
  
  uf = {
    mesActual: 0.0,
    mesAnterior: 0.0,
  }

  trabajador = {
    ubicacion: '',
    nombre: '',
    apellido: '',
    fk_trabajador: 0,
    f_start: '',
    f_end: '',
    dias_semana: 0,
    fecha_ingreso: '',
    habiles: 0,
    inhabiles: 0,
    horasExtras: 0,
    pendiente: 0.0,
    periodo: new Date(),
    empresa: '',
    empresaRut: 0,
    palabras: '',
    folio: '00000',
    afp: '',
    afc: 1,
    afptasa: 0,
    prevision: '',
    previsiontasa: 0,
    carga: 0,
    sbase: 0,
    haberes: [],
    sCesantia: 0,
    estadoContrato: '',
    adelanto: 0,
    prestamo: 0,
    numCuota: 0,
    ahorro_apv: 0,
    prestamos: [],
    jubilado: false,
    tramoAsigFam: 0,
    horasPactadas: 0,
    impuestounicoC: 0,
  }

  previred = {
    periodo: null,
    topes: {
      AfpSalud: 0,
      IPS: 0,
      SeguroCesantia: 0,
    },
    familiar: {
      tramoA: {
        valor: 0,
        max: 0,
      },
      tramoB: {
        valor: 0,
        min: 0,
        max: 0
      },
      tramoC: {
        valor: 0,
        min: 0,
        max: 0,
      },
      tramoD: {
        valor: 0,
        min: 0,
      }
    },
    ufMesActual: 0,
    ufMesAnterior: 0,
    rentaMinima: 0,
    sCesantiaIndefinido: 0,
    seguroInvalidez: 0
  }

  licencias: any[] = [];
  numLicencias: number = 0;
  vacaciones: any[] = [];
  numVacaciones: number = 0;
  adelantos: Adelantos[];

  constructor(
    private ciudadService: CiudadService,
    private modLiquidaciones: LiquidacionService,
    private adelantos_services: AdelantosService,
    private prestamos_services: PrestamosService,
    private trabajadoresService: TrabajadoresService,
    private gestorAsistenciaService: GestorAsistenciaService,
    private trabajadorHaberService: TrabajadorHaberService,
    private licenciaService: LicenciasService,
    private vacacionService: VacacionesService,
    private movimientoPersonalService: MovimientosPersonalService,
    private horasExtrasService: HorasExtrasService,
    private helperService: HelperService,
  ) {
    // console.log('INICIALIZACION DE FUNCIONES LIQUIDACIONES');
    this.inicializarDatos();
  }

  setRut(rut: number){
    this.rut = rut;
  }
  setPeriodo(periodo: Date){
    this.periodo = periodo;
    this.trabajador.periodo = periodo;
  }
  setMode(state: boolean) {
    this.modeFiniquito = state;
  }

  async inicializarDatos() {
    const periodo = this.obtenerPeriodoStorage();
    this.setPeriodo(periodo);

    await this.obtenerUFs();
    await this.obtenerPrevired();
    await this.obtenerRegionesCiudades();
  }

  async traerCiudadSucursal(idSucursalTrabajador: number){
    const res = await this.ciudadService.ObtenerCiudadRegionAsync(idSucursalTrabajador);
    if (res.success) {
      return res.data;
    }else{
      console.error(res.msg)
    }
  }

  async obtenerPrevired(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        const previred = this.cargarDatosPrevired();
        resolve(previred);
      }, this.milisecons)
    })
  }

  async cargarDatosPrevired(){
    const month = this.periodo.getMonth() + 1;
    const monthString = month < 10 ? '0' + month : month;
    try {
      const resultPrevired = await fetch('https://api.gael.cloud/general/public/previred/' + monthString + this.periodo.getFullYear());
      const previredData = await resultPrevired.json();
      if (!previredData.message) {
        const previred: Previsional = previredData;
        previred.UFValPeriodoAnt = previred.UFValPeriodoAnt.replace(',', '.');
        previred.UFValPeriodo = previred.UFValPeriodo.replace(',', '.');
        previred.AFCCpiTrabajador = previred.AFCCpiTrabajador.replace(',', '.');
        previred.AFPHabitatTasaSIS = previred.AFPHabitatTasaSIS.replace(',', '.');

        // console.log({ previred });

        this.previred = {
          periodo: monthString + '' + this.periodo.getFullYear(),
          topes: {
            AfpSalud: parseInt(previred.RTIAfpPesos),
            IPS: parseInt(previred.RTIIpsPesos),
            SeguroCesantia: parseInt(previred.RTISegCesPesos),
          },
          familiar: {
            tramoA: {
              valor: parseInt(previred.AFamTramoAMonto),
              max: parseInt(previred.AFamTramoAHasta),
            },
            tramoB: {
              valor: parseInt(previred.AFamTramoBMonto),
              min: parseInt(previred.AFamTramoBDesde),
              max: parseInt(previred.AFamTramoBHasta),
            },
            tramoC: {
              valor: parseInt(previred.AFamTramoCMonto),
              min: parseInt(previred.AFamTramoCDesde),
              max: parseInt(previred.AFamTramoCHasta),
            },
            tramoD: {
              valor: parseInt(previred.AFamTramoDMonto),
              min: parseInt(previred.AFamTramoDDesde),
            }
          },
          rentaMinima: parseInt(previred.RMITrabDepeInd),
          sCesantiaIndefinido: parseFloat(previred.AFCCpiTrabajador),
          seguroInvalidez: parseFloat(previred.AFPHabitatTasaSIS),
          ufMesActual: this.uf.mesActual,
          ufMesAnterior: this.uf.mesAnterior,
        }
        return this.previred;
      } else {
        const res = await this.modLiquidaciones.TraerPreviredLiquidacion();
        let previred: any;
        if (res.success) {
          previred = res.data;
          this.previred = {
            periodo: monthString + '' + this.periodo.getFullYear(),
            topes: {
              AfpSalud: previred.topes.AfpSalud,
              IPS: previred.topes.IPS,
              SeguroCesantia: previred.topes.SeguroCesantia,
            },
            familiar: {
              tramoA: {
                valor: previred.familiar.tramoA.valor,
                max: previred.familiar.tramoA.max,
              },
              tramoB: {
                valor: previred.familiar.tramoB.valor,
                min: previred.familiar.tramoB.min,
                max: previred.familiar.tramoB.max,
              },
              tramoC: {
                valor: previred.familiar.tramoC.valor,
                min: previred.familiar.tramoC.min,
                max: previred.familiar.tramoC.max,
              },
              tramoD: {
                valor: previred.familiar.tramoD.valor,
                min: previred.familiar.tramoD.min,
              }
            },
            rentaMinima: parseInt(previred.rentaMinima),
            sCesantiaIndefinido: parseFloat(previred.sCesantiaIndefinido),
            seguroInvalidez: parseFloat(previred.seguroInvalidez),
            ufMesActual: this.uf.mesActual,
            ufMesAnterior: this.uf.mesAnterior,
          }
          // this.previred.periodo = monthString + '' + periodo.getFullYear();
          return this.previred;
        }else{
          return this.previred;
        }
      }
    } catch (error) {
      let previred;
      const res:RequestResponse = await this.modLiquidaciones.TraerPreviredLiquidacion()
      if (res.success) {
        previred = res.data;
        this.previred = {
          periodo: monthString + '' + this.periodo.getFullYear(),
          topes: {
            AfpSalud: previred.topes.AfpSalud,
            IPS: previred.topes.IPS,
            SeguroCesantia: previred.topes.SeguroCesantia,
          },
          familiar: {
            tramoA: {
              valor: previred.familiar.tramoA.valor,
              max: previred.familiar.tramoA.max,
            },
            tramoB: {
              valor: previred.familiar.tramoB.valor,
              min: previred.familiar.tramoB.min,
              max: previred.familiar.tramoB.max,
            },
            tramoC: {
              valor: previred.familiar.tramoC.valor,
              min: previred.familiar.tramoC.min,
              max: previred.familiar.tramoC.max,
            },
            tramoD: {
              valor: previred.familiar.tramoD.valor,
              min: previred.familiar.tramoD.min,
            }
          },
          rentaMinima: parseInt(previred.rentaMinima),
          sCesantiaIndefinido: parseFloat(previred.sCesantiaIndefinido),
          seguroInvalidez: parseFloat(previred.seguroInvalidez),
          ufMesActual: this.uf.mesActual,
          ufMesAnterior: this.uf.mesAnterior,
        }
        return this.previred;
        // console.log('PREVIRED: ', this.trabajador.previred);
      }
    }
    return this.previred;
  }

  async cargarDatosImpuestoUnico(month: string, year: number): Promise<any> {
    const periodo = month+year;
    return new Promise(async (resolve, reject) => {
      try {
        setTimeout(async () => {
          const result = await fetch('https://api.gael.cloud/general/public/impunico/' + periodo);
          resolve(result);
        }, this.milisecons);
      } catch (error) {
        console.error(error);
        reject(error);        
      }
    })
  }

  async obtenerUFs() {
    try {
      const DateActual = new Date(this.periodo.getFullYear(), this.periodo.getMonth()+1, 0, 0, 0, 0, 0);
      const DateAnterior = new Date(this.periodo.getFullYear(), this.periodo.getMonth(), 0, 0, 0, 0, 0);

      const monthActual = DateActual.getMonth() + 1;
      const monthAnterior = DateAnterior.getMonth() + 1;
      const lastDayActual = DateActual.getDate();
      const lastDayAnterior = DateAnterior.getDate();

      let monthString = monthActual < 10 ? '0' + monthActual : monthActual;
      let dayString = lastDayActual < 10 ? '0' + lastDayActual : lastDayActual;
      const result1 = await fetch('https://mindicador.cl/api/uf/' +dayString+'-'+ monthString+'-'+ DateActual.getFullYear());
      const ufsActual = await result1.json();

      monthString = monthAnterior < 10 ? '0' + monthAnterior : monthAnterior;
      dayString = lastDayAnterior < 10 ? '0' + lastDayAnterior : lastDayAnterior;
      const result2 = await fetch('https://mindicador.cl/api/uf/' +dayString+'-'+ monthString+'-'+ DateAnterior.getFullYear());
      const ufsAnterior = await result2.json();

      this.uf.mesActual = ufsActual.serie[0] ? ufsActual.serie[0].valor : 0;
      this.uf.mesAnterior = ufsAnterior.serie[0] ? ufsAnterior.serie[0].valor : 0;
      return this.uf;
    } catch (error) {
      console.error(error);
      return this.uf;
    }
  }

  async obtenerRegionesCiudades(): Promise<CiudadRegion[]> {
    const res = await this.ciudadService.GetAllCiudadesRegionesAsync();
    if (res.success) {
      this.ciudades = res.data;
      return this.ciudades;
    }else{
      console.error(res.msg);
      return this.ciudades;
    }
  }
  async ObtenerAdelantos() {
    const res = await this.adelantos_services.ObtenerAdelantosRutAsync(this.rut);
    if (res.success) {
      this.adelantosList = res.data;
      return this.actualizarAdelanto();
    }else{
      console.error(res.msg);
      return this.montoAdelanto;
    }
  }
  actualizarAdelanto(): number {
    this.adelantos = this.adelantosList.sort(
      (a:Adelantos, b:Adelantos) => 
        new Date(b.fechaInicioContrato+'T00:00:00').getTime() - new Date(a.fechaInicioContrato).getTime())
    .filter(x => {
      const fechaAdelanto = new Date(x.fechaInicioContrato+'T00:00:00');
      return fechaAdelanto.getMonth() === this.periodo.getMonth() && fechaAdelanto.getFullYear() === this.periodo.getFullYear();
    });
    this.montoAdelanto = 0;
    if (this.adelantos.length > 0) {
      this.numAdelanto = this.adelantos.length;
      this.adelantos.forEach(adelanto => {
        this.montoAdelanto += adelanto.montoAdelanto;
      });
      // console.log("Actualizando Adelantos: "+this.trabajador.adelanto);
    }
    return this.montoAdelanto; 
  }
  async CargarPrestamo() {
    const res = await this.prestamos_services.TraerPrestamoPorRutAsync(this.rut);
    if (res.success) {
      this.prestamos = res.data.filter((x: any) => { return x.estadoDocumento || x.estadoDocumento === 1 });
      // console.log({...this.prestamos});
      return this.actualizarPrestamo();
    }else{
      console.error(res.msg);
      return {
        tienePrestamo: 0,
        prestamos: [],
        prestamo: 0,
        numCuota: 0,
        idCuota: [],
      }
    }
  }
  async obtenerHorasExtras(): Promise<number> {
    try {
      const res = await this.horasExtrasService.TraerPorRutAsync(this.rut);
      if (res.success) {
        this.trabajador.horasExtras = res.data
          .filter(x => {
            const fechaHE = parseISO(x.fecha);
            return fechaHE.getMonth() === this.periodo.getMonth() 
              && fechaHE.getFullYear() === this.periodo.getFullYear()
          })
          .reduce(
            (a: number, e: any) => a + e?.horasExtras??0, 0
          );
        return this.trabajador.horasExtras;
      }else{
        console.error(res.msg);
        return this.trabajador.horasExtras;
      }
    } catch (error) {
      console.error(error);
      return this.trabajador.horasExtras;
    }
  }
  actualizarPrestamo(){
    const ids: number[] = [];
    this.prestamos.forEach(prestamo => {
      if (prestamo.estadoDocumento) {
        const fecha = new Date(this.periodo);
        this.cuota = prestamo.Cuotas.find((x:Cuotas) => {
          const fechaString = x.fechacuota.split('-');
          const fechaCuota = new Date(parseInt(fechaString[0]), parseInt(fechaString[1]) - 1, parseInt(fechaString[2]), 0, 0, 0, 0);
          // console.log({fechaCuota}, {fecha});
          return fecha.getMonth() === fechaCuota.getMonth() && fecha.getFullYear() === fechaCuota.getFullYear();
        });
        if (this.cuota) ids.push(this.cuota.id);
      }
    });
    return {
      tienePrestamo: 1,
      prestamos: this.prestamos,
      prestamo: 0,
      numCuota: 0,
      idCuota: ids,
    }
  }
  // CARGA EL TRABAJADOR CON EL OBJETO PASADO POR PARAMETRO
  CargarTrabajadorLocal(trabajador: any){
    this.sucursalTrabajador = trabajador?.Sucursal;
    this.trabajador.ubicacion = trabajador?.Sucursal?.nombre;
    this.trabajador.nombre = trabajador?.nombres;
    this.trabajador.apellido = trabajador?.apellidos;
    this.trabajador.fk_trabajador = trabajador?.rut;
    this.trabajador.empresa = trabajador?.Empresa_contratante?.nombre;
    this.trabajador.empresaRut = trabajador?.Empresa_contratante?.run;
    this.trabajador.afp = trabajador?.Prevision?.nombre;
    this.trabajador.afptasa = trabajador?.afp === 'Fonasa' ? 7 : trabajador.pactado_isapre;
    this.trabajador.jubilado = trabajador?.jubilado;
    this.trabajador.prevision = trabajador?.Afp?.nombre??'';
    this.trabajador.previsiontasa = trabajador?.Afp?.tasa??0;
    this.trabajador.carga = trabajador?.numero_cargas;
    this.trabajador.estadoContrato = trabajador?.estado_contrato;
    this.trabajador.ahorro_apv = trabajador?.ahorro_apv;
    this.trabajador.fecha_ingreso = trabajador?.fecha_ingreso;
    this.trabajador.dias_semana = trabajador?.dias_semana;
    this.trabajador.sbase = trabajador?.sueldo_base??0;
    this.trabajador.tramoAsigFam = trabajador?.tramoAsigFam;
    this.trabajador.horasPactadas = trabajador?.horasPactadas;
    this.trabajador.horasExtras = trabajador?.horasExtras;
  }
  async CargarTrabajador(){
    await this.cargarMovimientosPersonales();
    const res = await this.trabajadoresService.TraerTrabajadoresRutAsync(this.rut);
    if (res.success) {
      const trabajador = res.data[0];
      this.sucursalTrabajador = trabajador.Sucursal;
      this.trabajador.ubicacion = trabajador.Sucursal.nombre;
      this.trabajador.nombre = trabajador.nombres;
      this.trabajador.apellido = trabajador.apellidos;
      this.trabajador.fk_trabajador = trabajador.rut;
      this.trabajador.empresa = trabajador.Empresa_contratante.nombre;
      this.trabajador.empresaRut = trabajador.Empresa_contratante.run;
      this.trabajador.afp = trabajador.Prevision.nombre;
      this.trabajador.afptasa = this.trabajador.afp === 'Fonasa' ? 7 : trabajador.pactado_isapre;
      this.trabajador.jubilado = trabajador.jubilado;
      this.trabajador.prevision = trabajador.Afp.nombre??'';
      this.trabajador.previsiontasa = trabajador.Afp.tasa??0;
      this.trabajador.carga = trabajador.numero_cargas;
      this.trabajador.estadoContrato = trabajador.estado_contrato;
      this.trabajador.ahorro_apv = trabajador.ahorro_apv;
      this.trabajador.fecha_ingreso = trabajador.fecha_ingreso;
      this.trabajador.dias_semana = trabajador.dias_semana;
      this.trabajador.sbase = trabajador.sueldo_base??0;
      this.trabajador.tramoAsigFam = trabajador.tramoAsigFam;
      this.trabajador.horasPactadas = trabajador.horasPactadas;

      // const numPendiente: number = await this.obtenerLicenciasVacaciones(new Date());
      const numDias = await this.contarDias();
      const numInhasistencias = await this.cambioMes();
      const pendiente = Math.floor(numInhasistencias * 10) / 10  + numDias;
      this.trabajador.pendiente = pendiente > 30 ? 30 : pendiente;

      this.CargarLibroRemuneraciones(trabajador);

      return this.trabajador;
    }else{
      console.error(res.msg);
      return this.trabajador;
    }
  }
  CargarLibroRemuneraciones(trabajador: any) {
    this.libroremuneracion.isapre = this.trabajador.afp !== 'FONASA' ? trabajador.pactado_isapre * this.previred.ufMesActual : 0;
    this.libroremuneracion.sucur = trabajador.Sucursal.nombre;
    this.libroremuneracion.asig_familiar = this.trabajador.carga;
    this.libroremuneracion.f_start = new Date(this.trabajador.fecha_ingreso + 'T00:00:00');
    this.libroremuneracion.cod_tipo_jornada = trabajador.tipo_jornada;
    this.libroremuneracion.cod_discapacidad = trabajador.discapacidad;
    this.libroremuneracion.jubilado = this.trabajador.jubilado;
    this.libroremuneracion.isapre_fonasa = trabajador.Prevision.cod_prevision;
    this.libroremuneracion.cod_afp = trabajador.Afp.cod_afp;
    this.libroremuneracion.afc = trabajador.afc;
  }

  async contarDias(): Promise<number>{
    // console.log({...this.trabajador})
    const fechaIngreso = new Date(this.trabajador.fecha_ingreso+'T00:00:00');
    // periodoLiquidacion es el primer dia del mes actual
    const periodoLiquidacion = new Date(this.trabajador.periodo);
    const firstDateMes = new Date(periodoLiquidacion.getFullYear(), periodoLiquidacion.getMonth(), 1, 0, 0, 0, 0)
    // diasFaltantesPorIngreso son los días que el trabajador faltó
    let diasFaltantesPorIngreso = this.modeFiniquito && this.movimientos.length > 0 ? 0 : this.verificarMovimientoPersonal();
    
    console.log({fechaIngreso}, {periodoLiquidacion}, {firstDateMes}, fechaIngreso.getDate() !== 1 && fechaIngreso.getMonth() === firstDateMes.getMonth() && fechaIngreso.getFullYear() === firstDateMes.getFullYear());

    if (fechaIngreso.getDate() !== 1 
      && fechaIngreso.getMonth() === firstDateMes.getMonth() && fechaIngreso.getFullYear() === firstDateMes.getFullYear()) {
       diasFaltantesPorIngreso = Math.floor(moment(fechaIngreso).diff(moment(firstDateMes), 'days'));
    }
    return diasFaltantesPorIngreso;
  }
  async cargarMovimientosPersonales() {
    const res = await this.movimientoPersonalService.obtenerMovimientosPersonalesPorRut(this.rut);
    if (res.success) {
      this.movimientos = res.data.filter(x => {
        const periodo = this.trabajador.periodo;
        const fechaDesde = parseISO(x.fecha_inicio??'1900-01-01');
        const fechaHasta = parseISO(x.fecha_final??'2100-12-31');
        return (isSameMonth(periodo, fechaDesde) && isSameYear(periodo, fechaDesde)) || (isSameMonth(periodo, fechaHasta) && isSameYear(periodo, fechaHasta));
      });
    }else{
      this.movimientos = [];
      console.error(res.msg);
    }
  }

  formatearFecha(fechaString: string): string {
    const fecha = new Date(fechaString);
    const año = fecha.getFullYear();
    const mes = this.agregarCeroSiNecesario(fecha.getMonth() + 1);
    const dia = this.agregarCeroSiNecesario(fecha.getDate());
  
    return `${año}-${mes}-${dia}`;
  }
  
  agregarCeroSiNecesario(numero: number): string {
    return numero < 10 ? `0${numero}` : `${numero}`;
  }

  verificarMovimientoPersonal(): number {
    //  DEVUELVE TODOS LO MOVIMIENTOS QUE SON RETIRO
    const movimientoPersonal: MovimientosPersonal = this.movimientos.find((x: MovimientosPersonal) => {
      const fechaFinal = parseISO(x.fecha_final);
      const periodo = startOfMonth(this.trabajador.periodo);
      return (x.idtipoevento === 9 || x.idtipoevento === 10 || x.idtipoevento === 5) && 
        (fechaFinal.getMonth() === periodo.getMonth() && fechaFinal.getFullYear() === periodo.getFullYear());
    });
    if (movimientoPersonal) {
      if (movimientoPersonal.idtipoevento === 9) {
        const fechaTermino = parseISO(movimientoPersonal.fecha_final);
        const lastFecha = endOfMonth(fechaTermino);
        return lastFecha.getDate() - fechaTermino.getDate();
      }else if (movimientoPersonal.idtipoevento === 10 || movimientoPersonal.idtipoevento === 5){
        const fechaInicio = parseISO(movimientoPersonal.fecha_inicio);
        const fechaTermino = parseISO(movimientoPersonal.fecha_final);
        // este calculo probablemente esté mal (hay que sumarle 1 al resultado), pero al cambiarlo se cambiarán las liquidaciones ya generadas con respecto a los subsidios
        // si un trabajador falta del 1 al 30, en la liquidación saldrá que trabajó 1 día y faltó 29
        return fechaTermino.getDate() - fechaInicio.getDate()
      }
      return 0;
    }
    return 0;
  }
  async obtenerLicenciasVacaciones(): Promise<any> {
    // console.log({periodo});
    try {
      const resLicencia = await this.licenciaService.TraerPorRutAsync(this.rut);
      const resVacacion = await this.vacacionService.TraerPorRutAsync(this.rut);
      let numLicencias = 0;
      let numVacaciones = 0;

      if (resLicencia.success) {
        const f_periodo = moment(this.periodo);
        this.licencias = resLicencia.data?.filter(x => {
          const f_start = moment(parseISO(x.fechaInicio));
          const f_end = moment(parseISO(x.fechaTermino));
          return f_periodo.isBetween(f_start, f_end);
        });
        numLicencias = await this.contarDiasLicencias();
      }else{
        console.error(resLicencia.msg);
      }
      if (resVacacion.success) {
        const f_periodo = moment(this.periodo);
        this.vacaciones = resVacacion.data?.filter(x => {
          // console.log({x});
          const f_start = moment(parseISO(x.f_start));
          const f_end = moment(parseISO(x.f_end));
          return f_periodo.isBetween(f_start, f_end);
        });
        // console.log({...this.licencias});
        numVacaciones = await this.contarDiasVacaciones();
      }else{
        console.error(resVacacion.msg);
      }
      return {numLicencias: numLicencias, numVacaciones: numVacaciones};
    } catch (error) {
      console.error(error);
      return [
        {numLicencias: 0},
        {numVacaciones: 0}
      ];
    }
  }
  async contarDiasVacaciones(){
    const periodoCurrent = moment(this.periodo);

    let f_start = moment();
    let f_end = moment();
    let firsDayMonth = moment();
    let lastDayMonth = moment();

    if (this.vacaciones.length > 0) {
      this.vacaciones.forEach(e => {
        f_start = moment(parseISO(e.f_start));
        f_end = moment(parseISO(e.f_end));
        firsDayMonth = periodoCurrent.clone().startOf('month');
        lastDayMonth = periodoCurrent.clone().endOf('month');
        if (firsDayMonth.isAfter(f_start)) {
          while (firsDayMonth.isSameOrBefore(f_end) && firsDayMonth.isSameOrBefore(lastDayMonth)) {
            // console.log(firsDayMonth.clone().toDate());
            if (firsDayMonth.weekday() !== 6 || firsDayMonth.weekday() !== 0 || this.holidays.isHoliday(firsDayMonth.toDate())) {
              this.numVacaciones++;
            }
            firsDayMonth.add(1, 'days');
          }
        } else {
          while (f_start.isSameOrBefore(f_end) && firsDayMonth.isSameOrBefore(lastDayMonth)) {
            // console.log(f_start.clone().toDate());
            if (f_start.weekday() !== 6 || f_start.weekday() !== 0 || this.holidays.isHoliday(firsDayMonth.toDate())) {
              this.numVacaciones++;
            }
            f_start.add(1, 'days');
          }
        }
      })
    }
    return this.numVacaciones;
  }
  async contarDiasLicencias() {
    const periodoCurrent = moment(this.periodo);
    let f_start = moment();
    let f_end = moment();
    let firsDayMonth = moment();
    let lastDayMonth = moment();
    // console.log({...this.licencias});
    if (this.licencias.length > 0) {
      this.licencias.forEach(e => {
        f_start = moment(parseISO(e.fechaInicio));
        f_end = moment(parseISO(e.fechaTermino));
        firsDayMonth = periodoCurrent.clone().startOf('month');
        lastDayMonth = periodoCurrent.clone().endOf('month');
        if (firsDayMonth.isAfter(f_start)) {
          while (firsDayMonth.isSameOrBefore(f_end) && firsDayMonth.isSameOrBefore(lastDayMonth)) {
            // console.log(firsDayMonth.clone().toDate());
            this.numLicencias++;
            firsDayMonth.add(1, 'days');
          }
        } else {
          while (f_start.isSameOrBefore(f_end) && firsDayMonth.isSameOrBefore(lastDayMonth)) {
            // console.log(f_start.clone().toDate());
            this.numLicencias++;
            f_start.add(1, 'days');
          }
        }
      });
    }
    return this.numLicencias;
  }
  async cambioMes() {
    this.asistencias = await this.ObtenerAsistenciaPeriodo(this.trabajador.periodo);
    this.asistencias = this.asistencias.filter(x => x.Razon.razon !== 'LICENCIA');
    return this.asistencias.length > 0 ? this.calcularDiasPendientes() : 0;
  }

  calcularDiasPendientes(): number{
    const esAtraso: Asistencia[] = this.asistencias.filter(x => x.Razon.esAtraso);
    const noEsAtraso: Asistencia[] = this.asistencias.filter(x => !x.Razon.esAtraso);
    
    const diasAtrasos = esAtraso.length > 0 ? this.calcularAtrasos(esAtraso) : 0;

    const diasInasistencia = noEsAtraso.length > 0 ? this.calcularInasistencias(noEsAtraso) : 0;

    // console.log({diasAtrasos}, {diasInasistencia});
    const total = diasAtrasos + diasInasistencia; 

    return total;
  }

  async ObtenerAsistenciaPeriodo(periodo: Date){
    const res = await this.gestorAsistenciaService.ObtenerAsistenciaPeriodoAsync(this.rut, periodo);
    let asistencias: Asistencia[] = [];
    if (res.success) {
      asistencias = res.data;
      return asistencias;
    }else{
      console.error(res.msg);
      return asistencias;
    }
  }

  calcularAtrasos(esAtraso: Asistencia[]) {
    return esAtraso.reduce((a, e) => {
      // const fechaStartString = e.start.split('-');
      // const fechaEndString = e.end.split('-');
      // const timeStart = e.timeStart.split(':');
      // const timeEnd = e.timeEnd.split(':');
      // const modelDateStart = new Date(parseInt(fechaStartString[0]), parseInt(fechaStartString[1]) - 1, parseInt(fechaStartString[2]), parseInt(timeStart[0]), parseInt(timeStart[1]), 0, 0);
      // const modelDateEnd = new Date(parseInt(fechaEndString[0]), parseInt(fechaEndString[1]) - 1, parseInt(fechaEndString[2]), parseInt(timeEnd[0]), parseInt(timeEnd[1]), 0, 0);

      // const hours = differenceInHours(modelDateEnd, modelDateStart);
      // const minutes = differenceInMinutes(modelDateEnd, modelDateStart) % 60;

      // const result = ((hours + minutes) / 60) / 10;
      return a + parseFloat(e.dias_faltados.toString());
    }, 0);
  }
  calcularInasistencias(noEsAtraso: Asistencia[]) {
    const periodo = this.trabajador.periodo;
    // console.log({noEsAtraso});
    return noEsAtraso.reduce((a, e) => {
      // let subTotalDias = 0;
      const intervalo:Interval = this.crearIntervalo(e);

      const days = eachDayOfInterval(intervalo);
      const workingDays = days.filter(day => day.getMonth() === periodo.getMonth() 
        && day.getFullYear() === periodo.getFullYear());
      return a + workingDays.length;
    },0);
  }

  crearIntervalo(e: Asistencia): Interval {
    const fechaDateStart = parseISO(e.start);
    const fechaDateEnd = parseISO(e.end);
    return {
      start: fechaDateStart,
      end: fechaDateEnd,
    }
  }

  async obtenerHaberes() {
    const res = await this.trabajadorHaberService.traerHaberConceptoPorRutAsync(this.rut);
    if (res.success) {
      this.trabajador.haberes = res.data.filter(x => {
        const fechaDesde = parseISO(x.fechaDesde);
        const fechaHasta = parseISO(x.fechaHasta);
        return isWithinInterval(this.trabajador.periodo, {start: startOfMonth(fechaDesde), end: endOfMonth(fechaHasta)});
      });
      return this.trabajador.haberes;
    }else{
      console.error(res.msg);
      return this.trabajador.haberes;
    }
  }
  obtenerPeriodoStorage(): Date {
    return this.helperService.getPeriodoStorage();
  }
  guardarPeridoStorage(): void {
    this.helperService.savePeriodoStorage(this.trabajador.periodo);
  }
}
