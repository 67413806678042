import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { ImpuestoSegundaCatService } from 'src/app/pages/services/impuestoSegundaCat/impuesto-segunda-cat.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-impuesto2cat',
  templateUrl: './listar-impuesto2cat.component.html',
  styleUrls: ['./listar-impuesto2cat.component.css']
})
export class ListarImpuesto2catComponent implements OnInit {

  displayedColumns: string[] = ['id', 'desde', 'hasta', 'cantidad', 'trabajador', 'actions'];
  dataSource = new MatTableDataSource();
  modalreference: any;

  update_tramo = {
    id: 0,
    desde: 0,
    hasta: 0,
    cantidad: 0,
    fk_trabajador: 0,
  }
  trabajadores:any;
  listaHoras: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @Input() rut!:any;


  debouncer: Subject<boolean> = new Subject()

  constructor(
    private _impuesto: ImpuestoSegundaCatService,
    private alert: AlertHelper,
    private trabajador: TrabajadoresService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {

  
    this.debouncer
    .pipe(debounceTime(500))
    .subscribe(valor => {
      this.onDebounce.emit(valor)
    })

    this.TraerDatos()

     this.TraerTrabajador()
   
  }



  TraerTrabajador(){

    this.trabajador.TraerTrabajadores().subscribe(res => {

      this.trabajadores = res.data


    })



  }




  ObtenerTrabajador(event: MatSelectChange) {


    this.trabajador = event.value;

    

  }



  TraerDatos(){


    this._impuesto.Todo().subscribe(res => {
      
      this.dataSource = new MatTableDataSource(res.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.debouncer.next(true);
   
    })
  }



  open(content: any, id: number) {


    this._impuesto.listarporid(id).subscribe(res => {



      this.update_tramo.id = res.data.id;
      this.update_tramo.cantidad = res.data.cantidad;
      this.update_tramo.hasta = res.data.hasta;
      this.update_tramo.desde = res.data.desde;
      this.update_tramo.fk_trabajador = res.data.fk_trabajador;
    })


    this.modalreference = this.modalService.open(content);


  }


  Eliminar(id: any) {


   Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el impuesto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

     
        this._impuesto.eliminar(id).subscribe(res => {
   
          if (res.success == true) {
           
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
            this.ngOnInit();
            this.modalreference.close();
    
          } else {
            this.alert.error_small(res.msg!)
          }
        });

      }
    })

 


  }

  Actualizar() {




    this._impuesto.actualizar(this.update_tramo).subscribe(res => {

    
      if (res.success == true) {

        this.modalService.dismissAll();
        this.debouncer.next(true);
        this.ngOnInit();
        this.modalreference.close();


      } else {
        this.alert.error_small(res.msg!)
      }

    })
  }

  openn(ventas: any) {


    this.modalreference = this.modalService.open(ventas, { size: 'xl' });


  }




  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
