<div class="container-fluid animate__animated animate__fadeInRight">
  <br />

  <div class="col-12 pl-0 pr-0">
    <mat-card class="mat_card_general">
      <h1>Módulo Permisos</h1>
    </mat-card>
  </div>

  <br />
  <p-toolbar styleClass="bg-light">
    <div class="p-toolbar-group-left">
      <div class="mr-2">
        <label for="periodo" class="mr-1">Periodo</label>
        <p-calendar 
          placeholder="Todos" 
          inputId="periodo" 
          [(ngModel)]="periodo" 
          view="month" 
          dateFormat="mm/yy"
          (onSelect)="onMonthChandler($event)" 
          [minDate]="MIN_DATE" 
          appendTo="body">
        </p-calendar>
      </div>
    </div>
    <div>
      <button mat-button class="button_color_primary" (click)="open(content)">
        <mat-icon style="color: white">add</mat-icon>
        <span style="color: white">Agregar Permiso</span>
      </button>
    </div>
  </p-toolbar>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="trabajador">
        <th mat-header-cell *matHeaderCellDef> TRABAJADOR </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.Trabajador.nombres | uppercase }}
          {{element.Trabajador.apellidos | uppercase }} 
        </td>
      </ng-container>

      <ng-container matColumnDef="fechaDesde">
        <th mat-header-cell *matHeaderCellDef> DESDE </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.fechaDesde | date: 'dd/MM/yyyy' }} 
        </td>
      </ng-container>

      <ng-container matColumnDef="fechaHasta">
        <th mat-header-cell *matHeaderCellDef> HASTA </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.fechaHasta | date: 'dd/MM/yyyy' }} 
        </td>
      </ng-container>

      <ng-container matColumnDef="horaDesde">
        <th mat-header-cell *matHeaderCellDef> HORA INICIO </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.horaDesde}} 
        </td>
      </ng-container>

      <ng-container matColumnDef="horaHasta">
        <th mat-header-cell *matHeaderCellDef> HORA HASTA </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.horaHasta}} 
        </td>
      </ng-container>

      <ng-container matColumnDef="razon">
        <th mat-header-cell *matHeaderCellDef> RAZON </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.Razon.razon }} 
        </td>
      </ng-container>

      <ng-container matColumnDef="motivo">
        <th mat-header-cell *matHeaderCellDef> MOTIVO </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.motivo }} 
        </td>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
          <button 
            *ngIf="element.tieneDocumento" 
            (click)="verPDF(element)" 
            mat-button 
            class="button_color_primary mr-2" 
            style="background-color: rgb(37, 199, 228);">
            <mat-icon style="color: white">visibility</mat-icon>
          </button>
          
          <button 
            *ngIf="element.tieneDocumento" 
            (click)="EliminarDocumento(element.id)" 
            mat-raised-button 
            color="warn" 
            [disabled]="loading">
            <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
            <mat-icon style="color: white" *ngIf="loading">
              <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
          </button>
              <button 
            *ngIf="!element.tieneDocumento" 
            (click)="openUploadDialog(element.id, element.Trabajador.rut)" 
            mat-button 
            class="button_color_primary mr-2" 
            style="background-color: rgb(37, 199, 228);">
            <mat-icon style="color: white">upload</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center p-1 mt-2" colspan="8">
          <h1>No existen datos</h1>
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar nuevo permiso</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12 form-group">
        <p-dropdown 
          [options]="todosLosTrabajadores" 
          [(ngModel)]="trabajadorSelect" 
          optionLabel="text"
          optionValue="value" 
          placeholder="Seleccione trabajador" 
          [showClear]="true" 
          [filter]="true" 
          filterBy="text"
          [ngModelOptions]="{standalone: true}" 
          [style]="{'width': '100%'}">
        </p-dropdown>
      </div>
      <div class="col col-6 form-group">
        <p-calendar 
          name="fechaHoraInicio" 
          showTime="true" 
          placeholder="Fecha y hora inicio" 
          [(ngModel)]="fechaInicio" 
          [minDate]="fechaMinima" 
          [hourFormat]="24" 
          dateFormat="dd/mm/yy" 
          [style]="{'width': '100%'}">
        </p-calendar>
      </div>
      <div class="col col-6">
        <p-calendar 
          name="fechaHoraFin" 
          showTime="true" 
          placeholder="Fecha y hora fin" 
          [(ngModel)]="fechaFin" 
          [hourFormat]="24" 
          [minDate]="fechaInicio" 
          dateFormat="dd/mm/yy" 
          [style]="{'width': '100%'}">
        </p-calendar>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <p-dropdown 
          [options]="todasLasRazones" 
          [(ngModel)]="razonSelect" 
          optionLabel="text"
          optionValue="value" 
          placeholder="Seleccione razon" 
          [showClear]="false" 
          [filter]="true" 
          filterBy="text"
          [ngModelOptions]="{standalone: true}" 
          [style]="{'width': '100%'}">
        </p-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Motivo</mat-label>
          <textarea matInput name="motivo" [(ngModel)]="motivo"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="guardarSolicitud()">Guardar</button>
  </div>
</ng-template>
