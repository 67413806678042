import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainAfpComponent } from './main-afp/main-afp.component';
import { AgregarAfpComponent } from './agregar-afp/agregar-afp.component';
import { ListaAfpComponent } from './lista-afp/lista-afp.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { AutonumericModule } from '@angularfy/autonumeric';


@NgModule({
  declarations: [
    MainAfpComponent,
    AgregarAfpComponent,
    ListaAfpComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    AutonumericModule.forRoot(),
  ],
  exports:[
    MainAfpComponent,
    AgregarAfpComponent,
    ListaAfpComponent
  ]
})
export class AfpModule { }
