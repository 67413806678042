import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { Observable, catchError, throwError, map, BehaviorSubject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Injectable({
  providedIn: 'root'
})
export class MovimientosPersonalService {
  private $eventAdd = new BehaviorSubject<boolean>(false);
  eventAdd = this.$eventAdd.asObservable();

  constructor(
    private http: HttpClient,
    private alert: AlertHelper,
  ) { }

  setEventAdd(value: boolean) {
    this.$eventAdd.next(value);
  }

  async obtenerMovimientosPersonalesPorRut(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/movimientospersonal/obtenerConRut/${rut}`).toPromise()
    } catch (error) {
      console.error(error);
      return ({ success: false, msg: 'Hubo un error en la solicitud.' } as RequestResponse)
    }
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

  TraerMovimientosPersonal(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/movimientospersonal/`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerTipoEvento(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/tiposevento/`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  CrearMovimiento(data:any): Observable<RequestResponse> {     
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/movimientospersonal/crear`,data)
    .pipe(
      map((res:RequestResponse) =>{
       
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  EditarMovimiento(data:any){
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/movimientospersonal/editar/${data.id}`, data)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  EliminarMovimiento(id:number){
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/movimientospersonal/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

}
