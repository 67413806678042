import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { PermisoService } from 'src/app/pages/services/permisos.service';
import { Permiso } from 'src/app/shared/models/permisos.interface';

@Component({
  selector: 'app-main-permisos',
  templateUrl: './main-permisos.component.html',
  styleUrls: ['./main-permisos.component.css']
})
export class MainPermisosComponent implements OnInit {
  
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()

  debouncer: Subject<boolean> = new Subject()

  permisos:Permiso[] =[];

  constructor(private permisos_services:PermisoService) {
    
   }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
  }

  actualizarTabla(event:boolean){
    this.permisos_services.GetAll_permisos().subscribe(res=>{
      this.permisos=res.data
      console.log(this.permisos)
    })
  }
}
