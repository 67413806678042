import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { docTrabajadorService } from '../../../services/trabajadores/docTrabajador.service'; 
import Swal from 'sweetalert2'; 
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mostrar-documento-trabajador',
  templateUrl: './mostrar-documento-trabajador.component.html',
  styleUrls: ['./mostrar-documento-trabajador.component.css']
})
export class MostrarDocumentoTrabajadorComponent implements OnInit {
  
  info_documento:any;
  displayedColumns: string[] = ['descripcion', 'acciones'];
  dataSource: any[] = [];
  loading = false;
 

  constructor(
    private docuTrabajadorService: docTrabajadorService,
    public dialogRef: MatDialogRef<MostrarDocumentoTrabajadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { rutDB: number },
  ) {}

  ngOnInit(): void {
    this.loadDocuments();
  }

  async loadDocuments() {
    this.loading = true;
    try {
      const res = await this.docuTrabajadorService.TraerPorRut(this.data.rutDB);
      if (res.success) {
        const filteredData = res.data.filter(item => item.estado !== 0);
        this.dataSource = filteredData; 
      } else {
        Swal.fire('Error', 'No se pudieron cargar los documentos.', 'error');
      }
    } catch (error) {
      console.error('Error al cargar documentos', error);
      Swal.fire('Error', 'Hubo un problema al cargar los documentos.', 'error');
    } finally {
      this.loading = false;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  DescargarDocumento(id: any){
    this.docuTrabajadorService.TraerArchivo(id).subscribe(
      (res)=>{
        if (res.success){
        window.open(`${environment.API}/uploads/trabajadores/`+res.data.ruta);
        } else {
          console.log('aaaa')
        }
      }
    );
    
  }

  EliminarDocumento(id: any){
    this.dialogRef.close();
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.docuTrabajadorService.ActualizarDocumento(id).subscribe(
          () => {
            this.loadDocuments(); 
            Swal.fire(
              'Eliminado!',
              'El documento ha sido eliminado.',
              'success'
            );
            this.loading = false;
          },
          error => {
            Swal.fire(
              'Error!',
              'Hubo un problema al eliminar el documento.',
              'error'
            );
            this.loading = false;
          }
        );
      }
    });
  }
}
