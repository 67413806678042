import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { Empresa } from 'src/app/shared/models/empresas.interface';

@Component({
  selector: 'app-main-resumen-haberes',
  templateUrl: './main-resumen-haberes.component.html',
  styleUrls: ['./main-resumen-haberes.component.css']
})
export class MainResumenHaberesComponent implements OnInit {
  empresas: Empresa[] = []
  constructor(
    private empresaService: EmpresaService,
  ) { }

  async ngOnInit(): Promise<void> {
    const res = await this.empresaService.GetAll_empresasAsync();
    if (res.success) {
      this.empresas = res.data;
    }else{
      console.log(res.msg);
    }
  }
}