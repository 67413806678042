import { Injectable } from '@angular/core';
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PdflibService {

  constructor() { }

  // AGREGAR MARCA DE AGUA A UN PDF CON PDF-LIB
  async agregarMarcaAgua(pdfDocURL: string, marcaAgua: string) {
    const existingPdfBytes = await fetch(pdfDocURL).then(res => res.arrayBuffer());
    
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();
    // DIBUJAR UN TEXTO EN EL PDF QUE TENGA TRANSPARENCIA
    firstPage.drawText(marcaAgua, {
      x: width / 2 - 150,
      y: height / 2 + 150,
      size: 58,
      font: font,
      color: rgb(0.95, 0.1, 0.1),
      rotate: degrees(-45),
      opacity: 0.2,
    });
    return await pdfDoc.save();
    // this.visualizarBlob(blob);
  }
  async agregarFirma(pdfDocURL: string, firmaURL: string) {
    const [existingPdfBytes, firmaBytes] = await Promise.all([
      fetch(pdfDocURL).then(res => res.arrayBuffer()),
      fetch(firmaURL).then(res => res.arrayBuffer()),
    ]);
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const firmaImage = await pdfDoc.embedPng(firmaBytes);

    const page = pdfDoc.getPages()[0];

    page.drawImage(firmaImage, {
      x: 90,
      y: 80,
      width: 140,
      height: 80,
    });
    
    // GENERAR UN FILE DESDE UN BLOB
    return await pdfDoc.save();
    // this.visualizarBlob(blob);
  }

  // Función para verificar si la URL apunta a un archivo PNG
  async verificarURLPNG(firmaURL: string): Promise<boolean> {
    try {
        const response = await fetch(firmaURL);
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.startsWith('image/png')) {
                return true;
            }
        }
    } catch (error) {
        console.error('Error al verificar la URL:', error);
    }
    return false;
  }

  async obtenerFirmaBytesDesdeURL(firmaURL: string): Promise<ArrayBuffer> {
    const response = await fetch(firmaURL);
    if (!response.ok) {
        throw new Error(`Error al obtener la imagen desde la URL ${firmaURL}`);
    }
    return await response.arrayBuffer();
}

  async agregarFirmaDesdeArchivo(pdfFile: any, firmaURL: string, formato: string) {
    // Leer el contenido del archivo PDF como ArrayBuffer
    const pdfArrayBuffer: any = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(pdfFile);
    });

    // Cargar el PDF desde el ArrayBuffer
    const pdfDoc = await PDFDocument.load(pdfArrayBuffer);

    // Verificar si la URL apunta a un archivo PNG válido
    if (await this.verificarURLPNG(firmaURL)) {
        try {
            // Obtener los bytes de la imagen desde la URL
            const firmaBytes = await this.obtenerFirmaBytesDesdeURL(firmaURL);
            const firmaImage = await pdfDoc.embedPng(firmaBytes);
            const page = pdfDoc.getPages()[0];

            switch(formato) {
              case 'permiso':
                page.drawImage(firmaImage, {
                  x: 220,
                  y: 350,
                  width: 140,
                  height: 80,
                })
                break;
              case 'vacacion':
                page.drawImage(firmaImage, {
                  x: 320,
                  y: 265,
                  width: 140,
                  height: 80,
                })
                break;
              default:
                break; 
            }

            return await pdfDoc.save();
            
        } catch (error) {
            console.error('Error al obtener los bytes de la imagen:', error);
        }
    }

    return await pdfDoc.save();
    
  }

  async agregarFirmaHonorario(pdfDocURL: string, firmaURL: string) {
    const [existingPdfBytes, firmaBytes] = await Promise.all([
      fetch(pdfDocURL).then(res => res.arrayBuffer()),
      fetch(firmaURL).then(res => res.arrayBuffer()),
    ]);
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const firmaImage = await pdfDoc.embedPng(firmaBytes);

    const page = pdfDoc.getPages()[0];

    page.drawImage(firmaImage, {
      x: 250,
      y: 200,
      width: 140,
      height: 80,
    });
    // GENERAR UN FILE DESDE UN BLOB
    return await pdfDoc.save();
    // this.visualizarBlob(blob);
  }

  async agregarMarcaAguaBlob(pdfDocURL: string, marcaAgua: string) {
    const pdfBytes = await this.agregarMarcaAgua(pdfDocURL, marcaAgua);
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }
  async agregarMarcaAguaFile(pdfDocURL: string, marcaAgua: string) {
    const pdfBytes = await this.agregarMarcaAgua(pdfDocURL, marcaAgua);
    return new File([pdfBytes], 'PDF-MODIFICADO-RECHAZADO.pdf', { type: 'application/pdf' });
  }
  async agregarFirmaBlob(pdfDocURL: string, firmaURL: string) {
    const pdfBytes = await this.agregarFirma(pdfDocURL, firmaURL);
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  async agregarFirmaBlobDesdeArchivo(pdfFile: any, firmaURL: string, formato: string) {
    const pdfBytes = await this.agregarFirmaDesdeArchivo(pdfFile, firmaURL, formato);
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  async agregarFirmaHonorarioBlob(pdfDocURL: string, firmaURL: string) {
    const pdfBytes = await this.agregarFirmaHonorario(pdfDocURL, firmaURL);
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }
  async agregarFirmaFile(pdfDocURL: string, firmaURL: string) {
    const pdfBytes = await this.agregarFirma(pdfDocURL, firmaURL);
    return new File([pdfBytes], 'PDF-MODIFICADO-FIRMA.pdf', { type: 'application/pdf' });
  }

  visualizarBlob(blob: Blob){
    const url = window.URL.createObjectURL(blob);
    open(url);
  }

  async mergePDFs(pdfPaths: string[]): Promise<Uint8Array> {
    const mergedPdf = await PDFDocument.create();

    for (const pdfPath of pdfPaths) {
        const pdfBytes = await this.fetchPDFBytes(pdfPath);

        if (pdfBytes) {
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            for (const page of pages) {
                mergedPdf.addPage(page);
            }
        }
    }

    const mergedPdfBytes = await mergedPdf.save();
    return mergedPdfBytes;
}

async fetchPDFBytes(path: string): Promise<Uint8Array | null> {
    try {
        const response = await fetch(path);
        if (!response.ok) {
            throw new Error(`Failed to fetch ${path}: ${response.status} ${response.statusText}`);
        }
        return new Uint8Array(await response.arrayBuffer());
    } catch (error) {
        console.error('Error fetching PDF:', error);
        return null;
    }
}

}
