<table class="example-full-width">
    <tr>
        <td colspan="2">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label> Fecha de Emisión </mat-label>
                <input (click)="picker.open()" (focus)="picker.open()" required matInput [matDatepicker]="picker" [(ngModel)]="carta.FechaEmision" name="picker_fecha_emision">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
                <!-- fecha de emisión -->
            </mat-form-field>
        </td>
    </tr>

    <tr>
        <td style="width: 50%;">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Grado de la Falta</mat-label>
                <mat-select required="required" name="sueldo" (selectionChange)="OntenerGradoFalta($event)">
                    <mat-option value="1">Menor</mat-option>
                    <mat-option value="2">Medio</mat-option>
                    <mat-option value="3">Grave</mat-option>
                </mat-select>
            </mat-form-field>
        </td>
        <td style="width: 50%;">
            <label for="motivo" class="block">Tipo Motivo</label>
            <p-dropdown inputId="motivo" [options]="motivos" [(ngModel)]="motivosSelect" optionLabel="nombre" styleClass="w-100"></p-dropdown>
        </td>
    </tr>
    <tr>
        <td colspan="2">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Descripcion</mat-label>
                <textarea type="textarea" rows="7" maxlength="1000" name="sueldo" matInput [(ngModel)]="carta.Motivo" required></textarea>
            </mat-form-field>
        </td>
    </tr>
</table>
<p>
    <!-- <input type="file" accept="image/*,.pdf,.doc,.docx" (change)='fileChangeEvent($event)' /> -->
    <input mat-input type="file" ng2FileSelect [uploader]="uploader" />
</p>

<div>
    Estado de Carga
    <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
    </div>
</div>
<!-- <p-fileUpload mode="basic" name="files" showUploadButton="false" (uploadHandler)="myUploader($event)" [customUpload]="true" chooseLabel="Subir Contrato"></p-fileUpload> -->


<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="OcultarModal()">
  <span aria-hidden="true">Cancelar</span>
</button>
    <button type="button" (click)="Guardar()" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button>
</div>