import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
  providers: [MessageService]
})
export class BannerComponent implements OnInit {
  titleError: string = 'Sin conexion a internet 🙁';
  subtitleError: string = '¡Vaya! Parece que no hay conexión a internet. Tal vez se haya caído la red o haya algún problema técnico. Te recomiendo que intentes reiniciar la conexión o que lo intentes más tarde.';
  titleSucces: string = 'Con conexion a internet 😁';
  subtitleSuccess: string = 'Se recomienda actualizar la página para evitar problemas con algunas funcionalidades.';


  constructor(
    private messageService: MessageService,
    private router: Router,
  ) 
  { }

  ngOnInit(): void{
    // SE DEBE LLAMAR MILESIMAS DE SEGUNDOS TARDES PARA QUE PUEDA LANZAR CONRRECTAMENTE EL METODO
    setTimeout(() => {
      if (!navigator.onLine) {
        this.messageService.add({ severity: 'error', summary: this.titleError, detail: this.subtitleError });
      }
    }, 100);
    window.addEventListener('offline', () => {
      this.messageService.add({ severity: 'error', summary: this.titleError, detail: this.subtitleError });
    })
    window.addEventListener('online', () => {
      this.messageService.clear();
      this.messageService.add({ severity: 'success', summary: this.titleSucces, detail: this.subtitleSuccess });
      setTimeout(() => {
        this.messageService.clear();
      }, 8000);
    })
  }


  // CONFIGURACION ROUTER PARA HACER RELOAD
  // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  // this.router.onSameUrlNavigation = 'reload';
  // this.router.navigateByUrl(this.router.url, { skipLocationChange: true });
}
