import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MainUsuariosComponent } from './main-usuarios/main-usuarios.component';
import { MaterialModule } from 'src/app/material.module';
import { AgregarUsuariosComponent } from './agregar-usuarios/agregar-usuarios.component';
import { ListaUsuariosComponent } from './lista-usuarios/lista-usuarios.component';
import { MainPermisosComponent } from '../documentos/permisos/main-permisos/main-permisos.component';
import { PermisosModule } from '../documentos/permisos/permisos.module';

@NgModule({
  declarations: [
    MainUsuariosComponent,
    AgregarUsuariosComponent,
    ListaUsuariosComponent,

  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PermisosModule

  ],
  providers: [
  ],
  exports:[
    MainUsuariosComponent,
    AgregarUsuariosComponent,
    ListaUsuariosComponent
  ]
})
export class UsuariosModule { }
