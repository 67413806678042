<button mat-raised-button (click)="open(content)" color="primary">
    <mat-icon>add</mat-icon>
    Agregar firma
</button>
<ng-template #content let-modal>
    <div class="modal-header">
        <mat-card-title>Agregar firma</mat-card-title>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Ingrese nombre de la firma" [(ngModel)]="firmaAdd.nombre" required>
                </mat-form-field>
            </div>
            <div class="col-12">
                <input id="fileFirma" type="file" class="form-control" ng2FileSelect [uploader]="uploader" accept=".jpg,.jpeg,.png"/>
                <mat-hint>El archivo debe ser de tipo imagen transparente para tener un buen resultado</mat-hint>
            </div>
            <div class="col-12" *ngIf="fileSrc">
                <img class="img-fluid rounded mx-auto d-block" [src]="fileSrc" alt="preview Firma">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="mr-2" mat-raised-button (click)="modal.dismiss()">Cancelar</button>
        <button mat-raised-button color="primary" [disabled]="uploader.getNotUploadedItems().length === 0" (click)="AgregarFirma()">Crear firma</button>
    </div>
</ng-template>