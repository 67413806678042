import { MaterialModule } from '../../../../material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainCompetenciasComponent } from './main-competencias/main-competencias.component';
import { ListaCompetenciasComponent } from './lista-competencias/lista-competencias.component';
import { AgregarCompetenciasComponent } from './agregar-competencias/agregar-competencias.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    MainCompetenciasComponent,
    ListaCompetenciasComponent,
    AgregarCompetenciasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule
  ],
  exports:[
    MainCompetenciasComponent,
    ListaCompetenciasComponent,
    AgregarCompetenciasComponent
  ]
})
export class CompetenciasModule { }
