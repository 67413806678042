import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';
import { TiposPrestamoService } from 'src/app/pages/services/tipoprestamo.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-tipoprestamo',
  templateUrl: './lista-tipoprestamo.component.html',
  styleUrls: ['./lista-tipoprestamo.component.css']
})
export class ListaTipoprestamoComponent implements OnInit {

  constructor(
     private alert: AlertHelper,
    private modalService: NgbModal,
    private tprestamoService: TiposPrestamoService,
    private cdRef: ChangeDetectorRef,
    ) { }

  update_tprestamo: any = {
    id: 0,
    nombre: "",
    descripcion: "",
  }

  @Input() tipoprestamos_list: [] = [];

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['centro', 'nombre', 'direccion', 'actions'];
  dataSource = new MatTableDataSource<any>(this.tipoprestamos_list);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit(): void {
   
    this.cargarDatos();
  }

  ngOnChanges(changes: any) {

    if (changes['tipoprestamos_list']) {
      this.dataSource.data = this.tipoprestamos_list;
    }
  }

  cargarDatos(){
    this.tprestamoService.TraerTiposPrestamo().subscribe(
      (res: any) => {
        console.log(res);
          if (res.success){
          this.dataSource.data = res.data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else{
          //this.alert.error_small(res.msg);
          this.dataSource.data = [];
          
        }
      }
    )
  }

  open(content: any, id: number) {
    this.update_tprestamo.id = id
    this.tprestamoService.Obtener_TipoPrestamo(id).subscribe(res => {
      if (res.success) {
        const tprestamo = res.data;
        this.update_tprestamo.nombre = tprestamo.nombre;
        this.update_tprestamo.descripcion = tprestamo.direccion;
        this.modalService.open(content);
      } else {
        this.alert.error_small('Ocurrio un error al obtener los datos');
        console.error(res.msg);
      }

    });
  }

  eliminarTipoPrestamo(content:any, id: number){
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminará el tipo de préstamo",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.tprestamoService.Eliminar_TipoPrestamo(id).subscribe(res => {
          if (res.success) {
            
            this.cargarDatos();
            this.alert.success_small(res.msg!);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }

  actualizarTipoPrestamo(){
    this.tprestamoService.ActualizarTipoPrestamo(this.update_tprestamo).subscribe(res =>{
      
      if(res.success){
        this.cargarDatos();
        this.alert.success_small("Se actualizó con éxito")
        this.modalService.dismissAll();
      }
      else{
        this.alert.error_small(res.msg!);
      }
    })
  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
