<div class="row">
    <div class="field col-4">
        <label for="fechaFirma" class="block font-weight-bolder">Fecha de Firma</label>
        <p-calendar inputId="fechaFirma" [(ngModel)]="liquidacionFirma.fechaFirma" *ngIf="liquidacionFirma" dateFormat="dd-mm-yy" styleClass="w-100"></p-calendar>
    </div>
    <div class="col-8">
        <label class="block font-weight-bolder" for="fileLiquidacion">Subir Liquidación Firmada</label>
        <input type="file" class="form-control" id="fileLiquidacion" ng2FileSelect [uploader]="uploader" accept=".pdf, .doc, .docx"/>
    </div>
</div>
<div class="d-flex justify-content-end">
    <button type="button" class="btn btn-primary" (click)="subirLiquidacionFirmada()" [disabled]="uploader.getNotUploadedItems().length === 0">Subir</button>
</div>