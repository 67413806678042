import { Injectable } from '@angular/core';
import { HelperService } from '../../helper/helper.service';
import conversor from 'conversor-numero-a-letras-es-ar';
import { IndicadoresImpuestoUnico } from 'src/app/shared/models/impuesto-unico.interface';
import { Previsional } from 'src/app/shared/models/previsonal';
import { FuncionesLiquidacionesService } from '../../liquidaciones/funciones-liquidaciones.service';
import { differenceInDays, endOfMonth, parseISO, startOfMonth } from 'date-fns';
import { MovimientosPersonal } from 'src/app/shared/models/movimientospersonal.interface';

@Injectable({
  providedIn: 'root'
})
export class generarLiquidacionService {
  src: any;
  url: any;
  pdfMake: any;
  dateObj = new Date();
  month = this.dateObj.getUTCMonth();
  day = this.dateObj.getUTCDate();
  year = this.dateObj.getUTCFullYear();
  lastDay: any;
  Meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  ClaseConversor = conversor.conversorNumerosALetras;
  miConversor = new this.ClaseConversor(); // CONVIERTE LOS NUMEROS A LETRAS
  total_imponible = 0;
  // total_imponible_sin_proporcional = 0;
  total_dsctos = 0;
  total_dsctosOtros = 0;
  gratificacion = 0;
  gratificacionSinProporcional = 0;
  prevision = 0;
  sCesantia = 0;
  hrsExtra = 0;
  salud = 0;
  pactadoIsapre = 0;
  adicionalIsapre = 0;
  tributable = 0;
  haberesNoImponibles = 0;
  carga = 0;
  totalNoImponible = 0;
  sbase = 0; // SUELDO BASE CON PROPORCIONAL DE DIAS
  sbaseSinProporcional = 0; // SUELDO BASE SIN PROPORCIONAL DE DIAS
  impuestoSegundaCat = 0;
  condicionIsapre = false;
  totalIsapre = 0;
  ahorro_apv: 0;
  totalAdelantosPrestamo: number = 0;
  r: any;
  previsional:Previsional;
  totalNoImponibleSinCarga = 0;
  indicadores: any;

  fontSizeStandard = 9;
  fontSizeLine = 14;
  lineDivider = '__________________________________________________________________________________';

  remuneracion = {
    idliquidacion: null,
    rut: 0,
    nombre: '',
    f_start: new Date(),
    sucursal: '',
    sucur: '',
    adelanto: 0,
    horasextras: 0,
    glegal: 0,
    otroimp: 0,
    totalimp: 0,
    otronoimp: 0,
    totalnoimp: 0,
    totalhaberes: 0,
    prevision: 0,
    salud: 0,
    scesantia: 0,
    otrodesclegales: 0,
    totaldesclegales: 0,
    liquido: 0,
    sbase: 0,
    folio: 0,
    impuestounico: 0,
    descvarios: 0,
    totaldescuento: 0,
    asig_familiar: 0,
    d_trabajados: 0,
    fecha_inicio_contratos: '',
    cod_region_prest_servicios: '',
    cod_comuna_prest_servicios: '',
    cod_tipo_jornada: 101,
    cod_discapacidad: 0,
    jubilado: 0,
    isapre_fonasa: 102,
    cod_afp: 100,
    apvi: 0,
    afc: 1,
    seguroInvalidez: 0,
    otrosdescuentosCSV: 0,
    totalaportesempleador: 0,
    ret_clase_media: 0,
    adicional_isapre: 0,
  }

  indicadoresImpuestoUnico: IndicadoresImpuestoUnico = {
    periodo: '',
    TR1Desde: 0,
    TR1Hasta: 0,
    TR1Factor: 0,
    TR1CReb: 0,
    TR2Desde: 0,
    TR2Hasta: 0,
    TR2Factor: 0,
    TR2CReb: 0,
    TR3Desde: 0,
    TR3Hasta: 0,
    TR3Factor: 0,
    TR3CReb: 0,
    TR4Desde: 0,
    TR4Hasta: 0,
    TR4Factor: 0,
    TR4CReb: 0,
    TR5Desde: 0,
    TR5Hasta: 0,
    TR5Factor: 0,
    TR5CReb: 0,
    TR6Desde: 0,
    TR6Hasta: 0,
    TR6Factor: 0,
    TR6CReb: 0,
    TR7Desde: 0,
    TR7Hasta: 0,
    TR7Factor: 0,
    TR7CReb: 0,
    TR8Desde: 0,
    TR8Hasta: 0,
    TR8Factor: 0,
    TR8CReb: 0,
  }

  constructor(
    private helper: HelperService,
    private funcionesLiquidaciones: FuncionesLiquidacionesService,
  ) { }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }
  Imponibles(trabajador: any) {
    this.sbase = trabajador.sbase;
    this.total_imponible = 0;
    this.gratificacion = 0;
    this.gratificacionSinProporcional = 0;
    this.hrsExtra = 0;
    console.log('1: ', this.total_imponible.toString());
    //dias faltados para libro de remuneraciones
    this.remuneracion.d_trabajados = this.calcularDiasTrabajados(trabajador)
    this.remuneracion.fecha_inicio_contratos = trabajador.fecha_ingreso;
    const haberes = trabajador.haberes.filter(x => x.Haberes.imponible === 1 && x.Haberes.tipo === 1 && x.Haberes.tributable === 1 && x.Haberes.gratificacion === 1 || x.bSemanaCorrida);
    this.sbase = Math.round(((this.sbase / 30) * this.remuneracion.d_trabajados));
    this.total_imponible += this.sbase;
    if (haberes.length > 0) {
      for (let i = 0; i < haberes.length; i++) {
        if (trabajador.pendiente > 0 && trabajador.pendiente < 31) {
          console.log('SEMANA CORRIDA: ', haberes[i].bSemanaCorrida);
          if (haberes[i].Haberes.proporcional === 1) {
            this.total_imponible += Math.round((haberes[i].monto / 30) * this.remuneracion.d_trabajados);
          } else if (haberes[i].Haberes.bSemanaCorrida === 1) {
            this.total_imponible += haberes[i].monto;
            this.total_imponible += haberes[i].montoBSC;
            console.log('2: ', this.total_imponible.toString());
          } else {
            this.total_imponible += haberes[i].monto; 
            console.log('2: ', this.total_imponible.toString());
          }
        } else {
          this.total_imponible += haberes[i].monto;
          if (haberes[i].bSemanaCorrida) {
            this.total_imponible += haberes[i].montoBSC;
          }
          console.log('3: ', this.total_imponible.toString());
        }
      }
      console.log('4: ', this.total_imponible.toString());
    }
    if (trabajador.horasExtras > 0) {
      // this.hrsExtra = Math.round((0.0077777 * trabajador.previred.rentaMinima) * trabajador.horasExtras);
      // const valorHora = (((trabajador.sbase / 30) * 28) / 180) * 1.5;
      const valorHora = (((trabajador.sbase / 30) * 7) / trabajador.horasPactadas) * 1.5;
      this.hrsExtra = Math.round(valorHora * trabajador.horasExtras);
      this.total_imponible += this.hrsExtra;
    }
    console.log('5: ', this.total_imponible.toString());
    //revisar esta funcion en caso de que este mañana
    this.remuneracion.horasextras = this.hrsExtra;
    let gratiLegal = this.total_imponible * 0.25;

    const topeGratificacion = ((trabajador.previred.rentaMinima * 4.75) / 12);
    if (gratiLegal <= topeGratificacion) {
      this.gratificacion = Math.round(gratiLegal);
      this.total_imponible += this.gratificacion;
    } else {
      this.gratificacion = Math.round(topeGratificacion);
      this.total_imponible += this.gratificacion;
    }
    // console.log({ gratiLegal }, this.gratificacion, { topeGratificacion });
    this.remuneracion.totalimp = this.total_imponible;
    console.log('6: ', this.total_imponible.toString());
  }
  calcularDiasTrabajados(trabajador: any): number {
    const movimientos: MovimientosPersonal[] = this.funcionesLiquidaciones.movimientos;
    const lastDate = endOfMonth(trabajador.periodo).getDate();
    // this.trabajador.pendiente = 0.0; d_trabajados > 30 ? 30 : d_trabajados
    
    return lastDate === 31 && movimientos.some(x => x.TiposEvento.codigo === 5 && parseISO(x.fecha_inicio).getDate() > 1) ? 31 - trabajador.pendiente : 30 - trabajador.pendiente;
  }

  NoImponibles(trabajador: any) {
    this.totalAdelantosPrestamo = 0;
    this.haberesNoImponibles = 0;
    this.totalNoImponible = 0;
    this.total_dsctosOtros = 0;
    this.carga = 0;

    const haberes = trabajador.haberes.filter((x) => x.Haberes.imponible === 0 && x.Haberes.tipo === 1 && x.Haberes.tributable === 0 && x.Haberes.gratificacion == 0);

    if (haberes.length >= 1) {
      this.haberesNoImponibles = haberes.reduce((total, haber) => {
        return total + (haber.Haberes.proporcional && (trabajador.periodo > 0) 
          ? (haber.monto / 30) * (this.remuneracion.d_trabajados)
          : haber.monto);
      }, 0);
    }
    if (trabajador.carga > 0 && trabajador.previred.familiar) {
      const tramos = {
        0: trabajador.previred.familiar.tramoA.valor,
        1: trabajador.previred.familiar.tramoA.valor,
        2: trabajador.previred.familiar.tramoB.valor,
        3: trabajador.previred.familiar.tramoC.valor,
        4: 0
      };
      this.carga = this.calcularCargaFamiliar(tramos, trabajador);
    }
    //remuneracionn
    this.totalNoImponible = this.haberesNoImponibles + this.carga;

    this.remuneracion.otroimp = this.haberesNoImponibles;

    this.remuneracion.asig_familiar = this.carga;

    // Calcular prestamo
    if (trabajador.prestamos.length > 0) {
      for (let index = 0; index < trabajador.prestamos.length; index++) {
        const prestamo = trabajador.prestamos[index];
        const cuota = prestamo.Cuotas.find(x => {
          const fecha = parseISO(x.fechacuota);
          return fecha.getMonth() === trabajador.periodo.getMonth() && fecha.getFullYear() === trabajador.periodo.getFullYear();
        });
        this.total_dsctosOtros = cuota ? this.total_dsctosOtros + cuota.montocuota : this.total_dsctosOtros;
        this.totalAdelantosPrestamo = cuota ? this.totalAdelantosPrestamo + cuota.montocuota : this.totalAdelantosPrestamo;
      }
    }
    this.totalAdelantosPrestamo = trabajador.adelanto > 0 ? trabajador.adelanto + this.totalAdelantosPrestamo : this.totalAdelantosPrestamo;
    this.remuneracion.adelanto = this.totalAdelantosPrestamo;
    
    this.total_dsctosOtros = trabajador.adelanto > 0 ? this.total_dsctosOtros + trabajador.adelanto : this.total_dsctosOtros;

    const descuentos = trabajador.haberes.filter((x) => x.Haberes.imponible === 0 && x.Haberes.tipo === 0 && x.Haberes.tributable === 0 && x.Haberes.gratificacion === 0);
    this.total_dsctosOtros += descuentos.reduce((a, descuento) => a + descuento.monto, 0);
    this.remuneracion.otrosdescuentosCSV = Math.round(this.total_dsctosOtros);
  }
  calcularCargaFamiliar(tramos: any, trabajador: any): number {
    const movimientos = this.funcionesLiquidaciones.movimientos;
    const licencias = movimientos.filter(x => {
      const f_inicio = parseISO(x.fecha_inicio);
      const f_final = parseISO(x.fecha_final);
      const periodo = trabajador.periodo;
      return x.TiposEvento.codigo === 3 && 
        (
          (f_inicio.getMonth() === periodo.getMonth()) && 
          (f_inicio.getFullYear() === periodo.getFullYear()) && 
          (f_final.getMonth() === periodo.getMonth()) && 
          (f_final.getFullYear() === periodo.getFullYear())
        );
    });
    const totalCarga = Math.round(tramos[trabajador.tramoAsigFam] * trabajador.carga); 
    return this.remuneracion.d_trabajados < 25 && licencias.length === 0 ? (totalCarga / 30) * this.remuneracion.d_trabajados : totalCarga;
  }

  SeguroCesantia(trabajador: any) {
    // PROPORCIONAL A LOS DIAS TRABAJADOS
    if (trabajador.jubilado || trabajador.estadoContrato === 'Contrato a Plazo Fijo') {
      this.sCesantia = 0;
    } else if (trabajador.estadoContrato === 'Contrato Indefinido' && !trabajador.jubilado) {
      if (trabajador.pendiente > 0 && trabajador.pendiente < 31) {
        this.sCesantia = Math.round((trabajador.previred.sCesantiaIndefinido / 100) * this.total_imponible);
      } else {
        this.sCesantia = Math.round((trabajador.previred.sCesantiaIndefinido / 100) * this.total_imponible);
      }
    }
    else if (trabajador.estadoContrato === 'Contrato por Obra o Faena' && !trabajador.jubilado) {
      //console.log("EL CONTRATO ES Obra Faena,, debe pagar cesantia");
      if (trabajador.pendiente > 0 && trabajador.pendiente < 31) {
        // this.sCesantia = Math.round(((((trabajador.previred.sCesantiaIndefinido / 100) * this.total_imponible) / 30) * (30 - trabajador.pendiente)));
        this.sCesantia = Math.round((trabajador.previred.sCesantiaIndefinido / 100) * this.total_imponible);
      } else {
        this.sCesantia = Math.round((trabajador.previred.sCesantiaIndefinido / 100) * this.total_imponible);
      }
    }
    //remuneracion
    this.remuneracion.scesantia = this.sCesantia;
  }

  TotalDesctoLegal(trabajador: any) {
    // console.log({trabajador}, 'Prevision: '+this.prevision, 'Salud: '+this.isapre, 'Isapre OTRO: '+this.isapreOtro, 'AHORRO APV: '+this.ahorro_apv);
    if (trabajador.afp === "Fonasa") {
      this.total_dsctos = Math.round(this.sCesantia + this.prevision + this.salud + this.ahorro_apv);
      //remuneracion
      this.remuneracion.totaldesclegales = this.total_dsctos;
    } else {
      const adicionalIsapre = Math.round(this.pactadoIsapre - this.salud);
      this.total_dsctos = Math.round(this.sCesantia + this.prevision + this.salud + (adicionalIsapre > 0 ? adicionalIsapre : 0) + this.ahorro_apv);
      //remuneracion
      this.remuneracion.totaldesclegales = this.total_dsctos;
    }
    this.calcularImpuestoUnico(trabajador);

    this.total_dsctos = Math.round(this.total_dsctos + this.impuestoSegundaCat);
  }
  calcularImpuestoUnico(trabajador: any) {
    this.impuestoSegundaCat = trabajador.impuestounicoC;
    const totalDescuentos = this.calcularTotalDescuentoLegal(trabajador);
    const tributable = Math.round(this.total_imponible - totalDescuentos);
    for (let i = 0; i < 8; i++) {
      const numeroTramo = i + 1;
      const tramoDesde = parseFloat(this.cambiarComaPunto(this.indicadoresImpuestoUnico['TR' + numeroTramo + 'Desde']));
      const tramoHasta = parseFloat(this.cambiarComaPunto(this.indicadoresImpuestoUnico['TR' + numeroTramo + 'Hasta']));
      const tramoFactor = parseFloat(this.cambiarComaPunto(this.indicadoresImpuestoUnico['TR' + numeroTramo + 'Factor']));
      const tramoReb = parseFloat(this.cambiarComaPunto(this.indicadoresImpuestoUnico['TR' + numeroTramo + 'CReb']));
      if (tributable >= tramoDesde && tributable <= tramoHasta) {
        const totalFactor = tributable * tramoFactor;
        const totalRebaja = totalFactor - tramoReb;
        this.impuestoSegundaCat = totalRebaja;
        break;
      }
    }
  }
  calcularTotalDescuentoLegal(trabajador: any): number {
    return this.remuneracion.totaldesclegales;
    // if (this.salud > this.pactadoIsapre && trabajador.afp === 'Fonasa' ) return this.total_dsctos;
    // else if (this.salud > this.pactadoIsapre && trabajador.afp !== 'Fonasa') return this.sCesantia + this.prevision + this.salud;
    // else {
    //   const salud = this.salud + this.pactadoIsapre;
    //   if (salud > this.pactadoIsapre) return this.sCesantia + this.prevision + this.pactadoIsapre;
    //   return this.sCesantia + this.prevision + salud;
    // }
  }
  Tributable(trabajador: any) {
   
    const totalDescuentos = this.calcularTotalDescuentoLegal(trabajador);
    this.tributable = Math.round(this.total_imponible - totalDescuentos);
    this.tributable = this.tributable - this.ahorro_apv;
  }
  cambiarComaPunto(valor: string): string {
    return valor ? valor.replace(',', '.') : '0';
  }

  crear() {
    const adicional = Math.round(this.pactadoIsapre - this.salud);
    this.remuneracion.adicional_isapre = adicional > 0 ? adicional : 0;
    this.r = this.remuneracion;
    return this.r
  }

  async convetToPDF(trabajador: any) {
    await this.loadPdfMaker();
    this.calcularLiquidacion(trabajador);
    this.lastDay = new Date(trabajador.periodo.getFullYear(), trabajador.periodo.getMonth() + 1, 0);
    var documentDefinition = {
      content: [
        {
          text: 'LIQUIDACION DE SUELDO',
          style: 'header',
          alignment: 'center'
        },
        {
          text: 'REMUNERACIONES MES DE : ' + this.Meses[trabajador.periodo.getUTCMonth()].toUpperCase() + ' DEL ' + trabajador.periodo.getUTCFullYear(),
          fontSize: 10, bold: true, margin: [0, 10, 0, 10]
        },
        {
          columns: [
            {
              fontSize: 10, bold: true, text: 'RAZON SOCIAL:', width: '33.33%',
            },
            {
              fontSize: 10, bold: true, text: 'RUT EMPRESA:', width: '33.33%', alignment: 'center', 
            },
            {
              fontSize: 10, bold: true, text: 'FOLIO:', width: '33.33%', alignment: 'right',
            },
          ],
          lineHeight: 1.5
        },
        {
          columns: [
            {
              fontSize: 10, bold: false, text: trabajador.empresa.toUpperCase(), width: '33.33%', 
            },
            {
              fontSize: 10, bold: false, text: trabajador.empresaRut, width: '33.33%', alignment: 'center',
            },
            {
              fontSize: 10, bold: false, text: trabajador.folio, width: '33.33%', alignment: 'right',
            }
          ],
          lineHeight: 1.5
        },
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -10, 0, 0],
          text: this.lineDivider,
        },
        {
          columns: [
            {
              fontSize: 10, bold: true, text: 'R.U.T:', width: '33.33%', lineHeight: 1.5,
            },
            {
              fontSize: 10, bold: true, text: 'TRABAJADOR:', width: '33.33%', alignment: 'left'
            },
            {
              fontSize: 10, bold: true, text: '', width: '*', alignment: 'right'
            },
          ],

          margin: [0, 10, 0, 0],
          lineHeight: 1.5
        },
        {
          columns: [
            {
              fontSize: 10, bold: false, text: this.helper.formatoRut(trabajador.fk_trabajador), width: '33.33%',
            },
            {
              fontSize: 10, bold: false, text: trabajador.apellido.toUpperCase() + ' ' + trabajador.nombre.toUpperCase(), width: 'auto', alignment: 'left'
            },
            {
              fontSize: 10, bold: false, text: '', width: '*', alignment: 'right'
            },
          ],
          lineHeight: 1.5
        },
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -10, 0, 0],
          text: this.lineDivider,
        },
        {
          columns: [
            {
              fontSize: 10, bold: true, text: 'A.F.P.', width: '33.33%'
            },
            {
              fontSize: 10, bold: true, text: 'SALUD', width: '33.33%', alignment: 'center',
            },
            {
              fontSize: 10, bold: true, text: '', width: '33.33%', alignment: 'right',
              // SEGURO CESANTIA
            }
          ],
          margin: [0, 10, 0, 0],
          lineHeight: 1.5
        },
        {
          columns: this.datoPrevision(trabajador),lineHeight: 1.5
        },
        {
          columns: this.Isapre(trabajador),lineHeight: 1.5
        },
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -10, 0, 0],
          text: this.lineDivider,
        },
        {
          columns: [
            {
              fontSize: 10, bold: true, text: 'DIAS', width: '20%'
            },
            {
              fontSize: 10, bold: true, text: 'HORAS EXTRAS', width: '20%', alignment: 'center',
            },
            {
              fontSize: 10, bold: true, text: 'CARGAS', width: '20%', alignment: 'center',
            },
            {
              fontSize: 10, bold: true, text: 'IMPONIBLE', width: '20%', alignment: 'center',
            },
            {
              fontSize: 10, bold: true, text: 'TRIBUTABLE', width: '20%', alignment: 'right',
            },
          ],
          margin: [0, 10, 0, 0],
          lineHeight: 1.5
        },
        {
          columns: [
            {
              fontSize: 10, bold: false, text: this.remuneracion.d_trabajados, width: '20%',
            },
            {
              fontSize: 10, bold: false, text: trabajador.horasExtras ?? 0, width: '20%', alignment: 'center',
            },
            {
              fontSize: 10, bold: false, text: trabajador.carga ?? 0, width: '20%', alignment: 'center',
            },
            {
              fontSize: 10, bold: false, text: this.helper.formatoCLP(Math.round(this.total_imponible)), width: '20%', alignment: 'center',
            },
            {
              fontSize: 10, bold: false, text: this.helper.formatoCLP(Math.round(this.tributable)), width: '20%', alignment: 'right',
            },
          ],
          lineHeight: 1.5
        },
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -10, 0, 0],
          text: this.lineDivider,
        },
        {
          fontSize: 10, bold: true, alignment: 'center',
          text: 'HABERES' + '\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t' + 'DESCUENTOS',
        },
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -10, 0, 0],
          text: this.lineDivider,
        },
        // Comienzo del desgloce de haberes y descuentos
        {
          columns: [
            {
              fontSize: this.fontSizeStandard, text: 'SUELDO BASE'
            },
            {
              fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(this.sbase)), alignment: 'right'
            },
            {
              fontSize: this.fontSizeStandard, text: 'PREVISION'
            },
            {
              fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(this.prevision)), alignment: 'right'
            }
          ]

        },
        {
          columns: [
            {
              fontSize: this.fontSizeStandard, text: 'GRATIFICACION LEGAL'
            },
            {
              fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(this.gratificacion)), alignment: 'right'
            },
            {
              fontSize: this.fontSizeStandard, text: 'SALUD'
            },
            {
              fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(this.salud)), alignment: 'right'
            }
          ]
        },
        this.GenerarHaberesDescuentos(trabajador),
        this.GenerarHaberesDescuentosNoImponibles(trabajador),
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -10, 0, 0],
          text: '\n\n\n\n\n\n\n\n\n\n' + this.lineDivider,
        },
        {
          columns: [
            {
              fontSize: this.fontSizeStandard, bold: true,
              text: 'TOTAL HABERES:'
            },
            {
              fontSize: this.fontSizeStandard,
              text: this.helper.formatoCLP(Math.round(this.total_imponible + this.totalNoImponible)), alignment: 'right', height: 'auto'
            },
            {
              fontSize: this.fontSizeStandard, bold: true,
              text: 'TOTAL DESCUENTOS'
            },
            {
              fontSize: this.fontSizeStandard,
              text: this.helper.formatoCLP(Math.round(this.total_dsctos + this.total_dsctosOtros)), alignment: 'right', height: 'auto'
            }
          ]
        },
        {
          columns: [
            {
              fontSize: this.fontSizeStandard, bold: true, text: '\nFECHA:',
            },
            {
              fontSize: this.fontSizeStandard, text: '\n' + this.lastDay.getUTCDate() + '/' + this.Meses[trabajador.periodo.getUTCMonth()].toUpperCase() + '/' + trabajador.periodo.getUTCFullYear(),
              alignment: 'right',
            },
            {
              fontSize: this.fontSizeStandard, bold: true, text: '\nALCANCE LIQUIDO',
            },
            {
              fontSize: this.fontSizeStandard,
              text: '\n' + this.helper.formatoCLP(Math.round((this.total_imponible + this.totalNoImponible) - (this.total_dsctos + this.total_dsctosOtros))),
              alignment: 'right'
            }
          ]
        },
        {
          text: [
            {
              text: '\nSON:\t\t', fontSize: 9, bold: true,
            },
            this.dinerilloATexto(),
          ]
        },
        {
          fontSize: this.fontSizeLine, bold: true, margin: [0, -104, 0, 0],
          text: '\n\n\n\n\n\n' + this.lineDivider,
        },
        {
          columns: [
            {
              fontSize: 9, bold: false,
              text: '\nRecibí conforme el alcance líquido de la presente liquidación, no teniendo cargo o cobro alguno que hacer por otro concepto.',
            },
            {
              fontSize: 9,
              text: '',
            },
          ]
        },
        {
          margin: [20, 0, 0, 0],
          absolutePosition: { x: 40, y: 760 },
          canvas: [
            {
              type: 'line',
              x1: 20,
              y1: 5,
              x2: 220, // Ajusta el valor para la primera línea de firma
              y2: 5,
              lineWidth: 2,
              // lineColor: 'blue', // Color de la línea
            },
            {
              type: 'line',
              x1: 290,
              y1: 5,
              x2: 480, // Ajusta el valor para la segunda línea de firma
              y2: 5,
              lineWidth: 2,
              // lineColor: 'red', // Color de la línea
            },
          ],
        },
        {
          columns: [
            {
              fontSize: 9, alignment: 'center',
              text: 'FIRMA DEL EMPLEADOR',
            },
            {
              fontSize: 9, alignment: 'center',
              text: 'FIRMA DEL TRABAJADOR',
            },
          ],
          absolutePosition: { x: 30, y: 780 }
        },
      ],
      styles: {
        header: {
          fontSize: 13,
          bold: true,
          alignment: 'justify'
        },
        tableExample: {
          margin: [0, 0, 0, 0]
        },
      },
      defaultStyle: {
        columnGap: 10,
      }
    };

    //Remuneracion
    const pdfDocGenerator = this.pdfMake.createPdf(documentDefinition);
    console.log({ pdfDocGenerator });
    try {
      const pdf64: string = await new Promise((resolve) => pdfDocGenerator.getDataUrl(resolve));
      const pdfBlob = this.dataURItoBlob(pdf64);

      //pasando los datos a la variable remuneracion
      this.remuneracion.nombre = trabajador.nombre + ' ' + trabajador.apellido;

      let a = new Date();
      this.remuneracion.f_start = a;
      this.remuneracion.sucursal = trabajador.empresa;
      this.remuneracion.sucur = trabajador.ubicacion;
      this.remuneracion.sbase = this.sbase;
      this.remuneracion.glegal = Math.round(this.gratificacion);
      this.remuneracion.salud = this.salud;
      this.remuneracion.prevision = this.prevision;
      this.remuneracion.totalhaberes = this.total_imponible + this.totalNoImponible;
      this.remuneracion.otronoimp = this.totalNoImponible;
      this.remuneracion.totalnoimp = this.totalNoImponible;

      this.remuneracion.folio = trabajador.folio;
      this.remuneracion.totalimp = this.total_imponible;
      this.remuneracion.liquido = (this.total_imponible + this.totalNoImponible) - (this.total_dsctos + this.total_dsctosOtros);

      this.remuneracion.impuestounico = trabajador.impuestounicoC;
      this.remuneracion.descvarios = (this.total_dsctosOtros);

      // this.ahorro_apv = trabajador.ahorro_apv;
      this.remuneracion.otrodesclegales = trabajador.ahorro_apv + this.adicionalIsapre;
      return {
        urlFile: pdf64,
        blobFile: pdfBlob
      };
    } catch (error) {
      console.log('ERROR!!!!: ', { error });
      return {
        urlFile: '',
        blobFile: '',
      };
    }
  }
  // METODO QUE CALCULA LA LIQUIDACION
  calcularLiquidacion(trabajador: any) {
    this.Imponibles(trabajador);
    this.NoImponibles(trabajador);
    this.calcularPrevisionalSalud(trabajador);
    this.SeguroCesantia(trabajador);
    this.TotalDesctoLegal(trabajador);
    this.Tributable(trabajador);
    this.remuneracion.totaldescuento = Math.round(this.total_dsctos + this.total_dsctosOtros);
  }
  calcularPrevisionalSalud(trabajador: any) {
    // console.log({...trabajador});
    this.prevision = Math.round((this.total_imponible * trabajador.previsiontasa / 100));
    if (trabajador.afp === "Fonasa" && trabajador.afp !== '') {
      if (trabajador.pendiente > 0 && trabajador.pendiente < 31) {
        // this.isapre = Math.round(((((trabajador.afptasa / 100) * this.total_imponible) / 30) * (30 - trabajador.pendiente)));
        this.salud = Math.round((trabajador.afptasa / 100) * this.total_imponible);
      } else {
        this.salud = Math.round((trabajador.afptasa / 100) * this.total_imponible);
      }
    } else {
      const diasTrabajadosIsapre = this.calcularDiasTrabajadosIsapre(trabajador);
      this.salud = Math.round((7 / 100) * this.total_imponible);
      this.pactadoIsapre = Math.round(((trabajador.previred.ufMesActual * trabajador.afptasa) / 30) * diasTrabajadosIsapre);
      // if (trabajador.pendiente > 0 && trabajador.pendiente < 31) {
      //   this.salud = Math.round(((((7 / 100) * this.total_imponible) / 30) * (this.remuneracion.d_trabajados)));
      //   this.pactadoIsapre = Math.round((((trabajador.previred.ufMesActual * trabajador.afptasa) / 30) * (this.remuneracion.d_trabajados)));
      // }
      // else {
      //   this.salud = Math.round((7 / 100) * this.total_imponible);
      //   this.pactadoIsapre = Math.round(trabajador.previred.ufMesActual * trabajador.afptasa);
      // }
    }
    this.condicionIsapre = false;
    this.ahorro_apv = trabajador.ahorro_apv;
    this.remuneracion.apvi = this.ahorro_apv > 0 ? 1 : 0;
    if (trabajador.afp !== 'Fonasa' && trabajador.afp !== '') {
      this.condicionIsapre = true;
      this.totalIsapre = trabajador.afptasa * trabajador.previred.ufMesActual;
      this.adicionalIsapre = this.pactadoIsapre > this.salud ? this.pactadoIsapre - this.salud : 0;
      // console.log(this.sobraIsapre, this.totalIsapre, this.totalIsapre);
    }
  }
  // SE CALCULA LOS DIAS TRABAJADOS PARA EL PROPORCIONAL DEL MONTO PACTADO ISAPRE
  calcularDiasTrabajadosIsapre(trabajador: any) {
    const movimientos = this.funcionesLiquidaciones.movimientos;
    const licencias = movimientos.filter(x => {
      const f_inicio = parseISO(x.fecha_inicio);
      const f_final = parseISO(x.fecha_final);
      const periodo = trabajador.periodo;
      return x.TiposEvento.codigo === 3 &&
        (
          (f_inicio.getMonth() === periodo.getMonth()) &&
          (f_inicio.getFullYear() === periodo.getFullYear()) &&
          (f_final.getMonth() === periodo.getMonth()) &&
          (f_final.getFullYear() === periodo.getFullYear())
        );
    });
    // SABER SI LA FECHA ES EL PRIMER DIA DEL MES
    const fechaIngreso = parseISO(trabajador.fecha_ingreso);
    const periodo: Date = trabajador.periodo; 
    const dateIngreso = fechaIngreso.getDate();
    const equalsPeriodo = periodo.getMonth() === fechaIngreso.getMonth() 
      && periodo.getFullYear() === fechaIngreso.getFullYear()
    const primerDiaMes = fechaIngreso.getDate() === 1;
    const lastDate = endOfMonth(trabajador.periodo).getDate();

    if ((equalsPeriodo && !primerDiaMes) || licencias.length > 0) {
      // CONTRAR LOS DIAS TRABAJADOS
      const diasLicencias = licencias.reduce((a: number, e: MovimientosPersonal) => {
        const f_inicio = parseISO(e.fecha_inicio);
        const f_final = parseISO(e.fecha_final);
        const diff = differenceInDays(f_final, f_inicio);
        return a + diff;
      }, 0);
      return lastDate - (diasLicencias + (dateIngreso - 1));
    }
    return lastDate > 30 ? 30 : lastDate;
  }

  dataURItoBlob(pdf64: string) {
    const byteString = atob(pdf64.split(',')[1]);
    const mimeString = 'application/pdf';
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  obtenerTotalTributable() { return this.tributable; }

  Isapre(trabajador: any) {
    let columns: any[] = [];
    console.log('AFP trabajador: ', trabajador.afp);
    if (trabajador.afp === "Fonasa") {
      columns.push(
        {
          fontSize: 10, bold: false, text: trabajador.previsiontasa + '% ', width: '33.33%',
        },
        {
          fontSize: 10, bold: false, text: (trabajador.afptasa) + '%', width: '33.33%', alignment: 'center',
        },
        { width: '33.33%', alignment: 'right', text: '' }
      )
    } else {
      columns.push(
        {
          fontSize: 10, bold: false,
          text: trabajador.previsiontasa + '% ', width: '33.33%',
        },
        {
          fontSize: 10, bold: false, width: '33.33%', alignment: 'center',
          text: (trabajador.afptasa) + ' UF - ' + (this.totalIsapre.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' })),
        },
        { width: '33.33%', alignment: 'right', text: '' },
      );
      return columns;
    }
    return columns;
  }

  datoPrevision(trabajador: any) {
    let columns: any[] = [];
    columns.push(
      {
        fontSize: 10, bold: false, text: trabajador.prevision, width: '33.33%'
      },
      {
        fontSize: 10, bold: false, text: trabajador.afp, width: '33.33%', alignment: 'center',
      },
    )
    // SEGURO CESANTIA (SE COMENTA PORQUE NO NECESITAN QUE APAREZCA EN LA LIQUIDACION)
    // if (trabajador.estadoContrato === "Contrato Indefinido") {
    //   columns.push(
    //     {
    //       fontSize: 10, bold: false, text: trabajador.previred.sCesantiaIndefinido+'%', width: '33.33%', alignment: 'right',
    //     }
    //   );
    // }
    // else {
    //   columns.push(
    //     {
    //       fontSize: 10, bold: false, text: '0%', width: '33.33%', alignment: 'right',
    //     }
    //   )
    // }
    return columns;
  }
  GenerarHaberesDescuentos(trabajador: any) {
    var Haberes = [];
    var Descuentos = [];
    // var montosDescuentos = [];
    var tributables = [];
    // var montoTributables = [];
    var haberes = trabajador.haberes;
    haberes = haberes.filter((x) => x.Haberes.imponible === 1 && x.Haberes.tipo === 1 && x.Haberes.tributable === 1 && x.Haberes.gratificacion === 1 || x.Haberes.bSemanaCorrida === 1);

    if (this.hrsExtra > 0) {
      tributables.push([
          { fontSize: this.fontSizeStandard, text: 'HORAS EXTRAS 50%' }, 
          { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(this.hrsExtra), alignment: 'right', noWrap: true }
        ]
      );
      // montoTributables.push({ fontSize: 9, text: this.helper.formatoCLP((Math.round((0.0077777 * this.sbase) * trabajador.horasExtras))) });
      // montoTributables.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(this.hrsExtra), alignment: 'right' })
    }
    if (haberes.length > 0) {
      for (let i = 0; i < haberes.length; i++) {
        if (haberes[i].bSemanaCorrida === 1) {
          tributables.push(
            [
              { fontSize: this.fontSizeStandard, bold: false, text: 'COMISIÓN' },
              { fontSize: this.fontSizeStandard, alignment: 'right', bold: false, text: haberes[i].monto.toLocaleString('es-CL', { style: 'decimal', maximumFractionDigits: 0 }), noWrap: true }
            ]
          );
          // montoTributables.push({ fontSize: this.fontSizeStandard, alignment: 'right', bold: false, text: haberes[i].monto.toLocaleString('es-CL', { style: 'decimal', maximumFractionDigits: 0 }) });

          tributables.push(
            [
              { fontSize: this.fontSizeStandard, bold: false, text: 'B.SEMANA CORRIDA' }, 
              { fontSize: this.fontSizeStandard, alignment: 'right', bold: false, text: haberes[i].montoBSC.toLocaleString('es-CL', { style: 'decimal', maximumFractionDigits: 0 }), noWrap: true }
            ]
          );
          // montoTributables.push({ fontSize: this.fontSizeStandard, alignment: 'right', bold: false, text: haberes[i].montoBSC.toLocaleString('es-CL', { style: 'decimal', maximumFractionDigits: 0 }) });
        } else if (trabajador.pendiente > 0 && trabajador.pendiente < 31) {
          tributables.push(
            [
              { fontSize: this.fontSizeStandard, text: haberes[i].Haberes.nombre.toUpperCase() }, 
              { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round((haberes[i].monto / 30) *(this.remuneracion.d_trabajados))), alignment: 'right', noWrap: true }
            ]
          );
          // montoTributables.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round((haberes[i].monto / 30) *(this.remuneracion.d_trabajados))), alignment: 'right' });
        } else {
          tributables.push(
            [
              { fontSize: this.fontSizeStandard, text: haberes[i].Haberes.nombre },
              { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(haberes[i].monto)), alignment: 'right', noWrap: true }
            ]
          );
          // montoTributables.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(haberes[i].monto)), alignment: 'right' });
        }
      }
    }

    tributables.push(
      [
        { fontSize: this.fontSizeStandard, bold: true, text: 'TOTAL IMPONIBLE' },
        { fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.total_imponible)), alignment: 'right', noWrap: true }
      ]
    );
    // montoTributables.push({ fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.total_imponible)), alignment: 'right' });

    const adicionalIsapre = Math.round(this.pactadoIsapre - this.salud);

    if (trabajador.afp != "Fonasa" && adicionalIsapre > 0) {
      Descuentos.push(
        [
          { fontSize: this.fontSizeStandard, text: 'ADICIONAL ISAPRE' },
          { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.abs(Math.round(this.pactadoIsapre - this.salud))), alignment: 'right', noWrap: true }
        ]
      );
      // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.abs(Math.round(this.isapreOtro - this.isapre))), alignment: 'right' });
    }
    if (this.sCesantia > 0) {
      Descuentos.push(
        [
          { fontSize: this.fontSizeStandard, text: 'SEGURO CESANTIA' },
          { fontSize: this.fontSizeStandard, text: (this.helper.formatoCLP(Math.round(this.sCesantia))), alignment: 'right', noWrap: true }
        ]
      );
      // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: (this.helper.formatoCLP(Math.round(this.sCesantia))), alignment: 'right' });
    }

    if (this.ahorro_apv > 0) {
      Descuentos.push(
        [
          { fontSize: this.fontSizeStandard, text: 'AHORRO APV' },
          { fontSize: this.fontSizeStandard, text: (this.helper.formatoCLP(Math.round(this.ahorro_apv))), alignment: 'right', noWrap: true }
        ]
      );
      // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: (this.helper.formatoCLP(Math.round(this.ahorro_apv))), alignment: 'right' });
    }
    if (this.impuestoSegundaCat > 0) {
      Descuentos.push(
        [
          { fontSize: this.fontSizeStandard, text: 'IMPUESTO UNICO' },
          { fontSize: this.fontSizeStandard, text: (this.helper.formatoCLP(Math.round(this.impuestoSegundaCat))), alignment: 'right', noWrap: true }
        ]
      );
      // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: (this.helper.formatoCLP(Math.round(this.impuestoSegundaCat))), alignment: 'right' });
    }
    Descuentos.push(
      [
        { fontSize: this.fontSizeStandard, bold: true, text: 'TOTAL DESC. LEGALES' },
        { fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.total_dsctos)), noWrap: true, alignment: 'right' }
      ]
    );
    // montosDescuentos.push({ fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.total_dsctos)), alignment: 'right' });
    console.log('GenerarHaberesDescuentos: ', {tributables}, {Descuentos});
    Haberes.push(
      {
        columns: [
          {
            table: {
              widths: ['*', 'auto'],
              body: tributables,
            },
            layout: 'noBorders'
          },
          {
            table: {
              widths: ['*', 'auto'],
              body: Descuentos,
            },
            layout: 'noBorders'
          }
        ]
      }
    )
    return Haberes;
  }

  GenerarHaberesDescuentosNoImponibles(trabajador: any) {
    var Mostrar = [];
    var Descuentos = [];
    // var montosDescuentos = [];
    var tributables = [];
    // var montoTributables = [];

    tributables.push([{ fontSize: 9, text: '', }, { fontSize: 9, text: '', alignment: 'right' }]);
    // montoTributables.push({ fontSize: 9, text: ' ', alignment: 'right' });

    const haberes = trabajador.haberes.filter((x) => x.Haberes.imponible === 0 && x.Haberes.tipo === 1 && x.Haberes.tributable === 0 && x.Haberes.gratificacion == 0);

    if (haberes.length >= 1) {
      for (let i = 0; i < haberes.length; i++) {
        if (haberes[i].Haberes.proporcional && (trabajador.periodo > 0)) {
          tributables.push(
            [
              { fontSize: this.fontSizeStandard, text: haberes[i].Haberes.nombre.toUpperCase() },
              { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round((haberes[i].monto / 30) * (this.remuneracion.d_trabajados))), alignment: 'right', noWrap: true }
            ]
          );
          // montoTributables.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round((haberes[i].monto / 30) * (this.remuneracion.d_trabajados))), alignment: 'right' });
        }
        else {
          tributables.push(
            [
              { fontSize: this.fontSizeStandard, text: haberes[i].Haberes.nombre.toUpperCase() },
              { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(haberes[i].monto)), alignment: 'right', noWrap: true }
            ]
          );
          // montoTributables.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(haberes[i].monto)), alignment: 'right' });
        }
      }
    }
    if (trabajador.carga > 0 && trabajador.previred.familiar) {
      tributables.push(
        [
          { fontSize: this.fontSizeStandard, text: 'CARGA FAMILIAR' },
          { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(this.carga)), alignment: 'right', noWrap: true }
        ]
      );
      // montoTributables.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(this.carga)), alignment: 'right' });
    }
    
    tributables.push(
      [
        { fontSize: this.fontSizeStandard, bold: true, text: 'TOTAL NO IMPONIBLE' },
        { fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.totalNoImponible)), alignment: 'right', noWrap: true }
      ]
    );
    // montoTributables.push({ fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.totalNoImponible)), alignment: 'right' });
    Descuentos.push([{ fontSize: this.fontSizeStandard, text: '' }, { fontSize: this.fontSizeStandard, text: '', alignment: 'right', noWrap: true }]);
    // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: ' ', alignment: 'right' });
    
    if (trabajador.prestamos.length > 0) {
      for (let index = 0; index < trabajador.prestamos.length; index++) {
        const prestamo = trabajador.prestamos[index];
        const cuota = prestamo.Cuotas.find(x => {
          const fecha = parseISO(x.fechacuota);
          return fecha.getMonth() === trabajador.periodo.getMonth() && fecha.getFullYear() === trabajador.periodo.getFullYear();
        });
        if (cuota) {
          Descuentos.push(
            [
              { fontSize: this.fontSizeStandard, text: 'DESC. EMPRESARIAL (' + (cuota.numcuota) + '/' + prestamo.cuotas + ')' },
              { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(cuota.montocuota)), alignment: 'right', noWrap: true }
            ]
          );
          // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(cuota.montocuota)), alignment: 'right' });
        }
      }
    }

    if (trabajador.adelanto > 0) {
      Descuentos.push(
        [
          { fontSize: this.fontSizeStandard, text: 'ADELANTO' },
          { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(trabajador.adelanto)), alignment: 'right', noWrap: true }
        ]
      );
      // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(trabajador.adelanto)), alignment: 'right' });
      // if ((this.total_imponible + this.totalNoImponible) - this.total_dsctos > trabajador.adelanto) {
      // }
    }

    const descuentos = trabajador.haberes.filter((x) => x.Haberes.imponible === 0 && x.Haberes.tipo === 0 && x.Haberes.tributable === 0 && x.Haberes.gratificacion === 0);
    for (let i = 0; i < descuentos.length; i++) {
      Descuentos.push(
        [
          { fontSize: this.fontSizeStandard, text: descuentos[i].Haberes.nombre.toUpperCase() },
          { fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(descuentos[i].monto)), alignment: 'right', noWrap: true }
        ]
      );
      // montosDescuentos.push({ fontSize: this.fontSizeStandard, text: this.helper.formatoCLP(Math.round(descuentos[i].monto)), alignment: 'right' });
    }
    Descuentos.push(
      [
        { fontSize: this.fontSizeStandard, bold: true, text: 'TOTAL OTROS DESC.' },
        { fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.total_dsctosOtros)), alignment: 'right', noWrap: true }
      ]
    );
    // montosDescuentos.push(
    //   { fontSize: this.fontSizeStandard, bold: true, text: this.helper.formatoCLP(Math.round(this.total_dsctosOtros)), alignment: 'right' },
    // );
    console.log('GenerarHaberesDescuentosNoImponibles: ', {tributables}, {Descuentos});
    Mostrar.push(
      {
        columns: [
          {
            table: {
              headerRows: 0,
              widths: [ 'auto', '*' ],
              body: tributables,
            },
            layout: 'noBorders'
          },
          {
            table: {
              headerRows: 0,
              widths: [ 'auto', '*' ],
              body: Descuentos,
            },
            layout: 'noBorders'
          }
        ]
      }
    );
    return Mostrar;
  }
  async obtenerIndicadoresImpuestoUnico(periodo: Date): Promise<void> {
    let month = periodo.getMonth() + 1;
    const year = periodo.getFullYear();
    try {
      const monthString: string = month < 10 ? '0' + month : month.toString();
      const result = await this.funcionesLiquidaciones.cargarDatosImpuestoUnico(monthString, year);
      this.indicadores = await result.json();
      if (this.indicadores.message) {
        const periodoNew = new Date(periodo.getFullYear(), periodo.getMonth() - 1, 1, 0, 0, 0, 0);
        const monthNew = periodoNew.getMonth();
        const year = periodoNew.getFullYear();
        const monthString = monthNew < 10 ? '0' + monthNew : monthNew.toString();
        const result = await this.funcionesLiquidaciones.cargarDatosImpuestoUnico(monthString, year);
        this.indicadores = await result.json();
      }
    } catch (error) {
      console.error(error);
      const periodoNew = new Date(periodo.getFullYear(), periodo.getMonth() - 1, 1, 0, 0, 0, 0);
      const monthNew = periodoNew.getMonth();
      const year = periodoNew.getFullYear();
      const monthString = monthNew < 10 ? '0' + monthNew : monthNew.toString();
      const result = await this.funcionesLiquidaciones.cargarDatosImpuestoUnico(monthString, year);
      this.indicadores = await result.json();
    }
    
    this.indicadoresImpuestoUnico = {
      periodo: this.indicadores.PeriodoMY,
      TR1Desde: this.indicadores.TR1Desde,
      TR1Hasta: this.indicadores.TR1Hasta,
      TR1Factor: this.indicadores.TR1Factor,
      TR1CReb: this.indicadores.TR1CReb,
      TR2Desde: this.indicadores.TR2Desde,
      TR2Hasta: this.indicadores.TR2Hasta,
      TR2Factor: this.indicadores.TR2Factor,
      TR2CReb: this.indicadores.TR2CReb,
      TR3Desde: this.indicadores.TR3Desde,
      TR3Hasta: this.indicadores.TR3Hasta,
      TR3Factor: this.indicadores.TR3Factor,
      TR3CReb: this.indicadores.TR3CReb,
      TR4Desde: this.indicadores.TR4Desde,
      TR4Hasta: this.indicadores.TR4Hasta,
      TR4Factor: this.indicadores.TR4Factor,
      TR4CReb: this.indicadores.TR4CReb,
      TR5Desde: this.indicadores.TR5Desde,
      TR5Hasta: this.indicadores.TR5Hasta,
      TR5Factor: this.indicadores.TR5Factor,
      TR5CReb: this.indicadores.TR5CReb,
      TR6Desde: this.indicadores.TR6Desde,
      TR6Hasta: this.indicadores.TR6Hasta,
      TR6Factor: this.indicadores.TR6Factor,
      TR6CReb: this.indicadores.TR6CReb,
      TR7Desde: this.indicadores.TR7Desde,
      TR7Hasta: this.indicadores.TR7Hasta,
      TR7Factor: this.indicadores.TR7Factor,
      TR7CReb: this.indicadores.TR7CReb,
      TR8Desde: this.indicadores.TR8Desde,
      TR8Hasta: this.indicadores.TR8Hasta,
      TR8Factor: this.indicadores.TR8Factor,
      TR8CReb: this.indicadores.TR8CReb,
    };
  }
  dinerilloATexto() {
    let varia = Math.round((this.total_imponible + this.totalNoImponible) - (this.total_dsctos + this.total_dsctosOtros))
    if (varia < 0) {
      return {
        fontSize: this.fontSizeStandard,
        text: ' MENOS ' + this.miConversor.convertToText(Math.abs(varia)).toUpperCase() + ' PESOS',
        alignment: 'right'
      }
    } else {
      return {
        fontSize: this.fontSizeStandard,
        text: this.miConversor.convertToText(varia).toUpperCase() + ' PESOS',
        alignment: 'right'
      }
    }
  }
}
