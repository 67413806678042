import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MotivosCartaAmonestacionService } from 'src/app/pages/services/motivos-carta-amonestacion.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-editar-motivo',
  templateUrl: './editar-motivo.component.html',
  styleUrls: ['./editar-motivo.component.css']
})
export class EditarMotivoComponent implements OnInit {
  @Input() motivo: any;
  @Output() actualizarTabla = new EventEmitter<boolean>(); 
  
  motivoEdit: any;
  descripcionAux: string = '';
  posicionAux: number = 0;

  variables: any[] = [
    {nombre: '%TRABAJADOR%', descripcion: 'Nombre del trabajador'},
    {nombre: '%LISTA_FECHA%', descripcion: 'Lista de fechas seleccionadas'},
    {nombre: '%LISTA_FECHA_HORA%', descripcion: 'Lista de fechas y horas seleccionadas'},
    {nombre: '%LISTA_FECHA_ATRASOS%', descripcion: 'Lista de fechas de atrasos del trabajador'},
    {nombre: '%MESES_SELECCIONADOS%', descripcion: 'Meses seleccionados en el periodo'},
  ]

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private motivosCartaAmonestacionService: MotivosCartaAmonestacionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.motivoEdit = {...this.motivo};
    this.descripcionAux = this.motivoEdit.descripcion;
  }

  OpenEditar(context: any) {
    this.modalService.open(context, { size: 'lg' });
  }
  async EditarMotivo(){
    if (this.motivoEdit.nombre.trim() === '') {
      this.alert.error_small('Nombre del motivo esta vacio.');
      return;
    }
    const res = await this.motivosCartaAmonestacionService.actualizarMotivoAsync(this.motivoEdit);
    if (res.success) {
      this.alert.success_small('Motivo actualizado correctamente.');
      this.modalService.dismissAll();
      this.actualizarTabla.emit(true);
    }else{
      this.alert.error_small(res.msg);
    }
  }

  changeDescripcion(){
    // OBTENER EL ELEMENTO DEL DOM
    const textArea = document.getElementById('textarea_descripcion') as HTMLTextAreaElement;
    // OBTENER LA POSICIÓN ACTUAL DEL PUNTERO DE ESCRITURA
    this.posicionAux = textArea.selectionStart;

    this.descripcionAux = this.motivoEdit.descripcion;

  }

  onOptionSelected(event: any) {
    const textAntes = this.descripcionAux.substring(0, this.posicionAux);
    const textDespues = this.descripcionAux.substring(this.posicionAux);
    // ESTABLECER EL NUEVO VALOR EN EL INPUT
    this.motivoEdit.descripcion = textAntes + event.option.value + textDespues;
  }

}
