import { Component, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';  // Necesario para utilizar paginator y filter
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { MatSort } from '@angular/material/sort';
import { contabilidadService } from '../../../../services/contabilidad/contabilidad.service';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { SolicitudLiquidacionService } from 'src/app/pages/services/solicitudes/solicitud-liquidacion.service';
import { Aprobacion } from 'src/app/shared/models/aprobacion.interface';
import { parseISO } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface LiquidacionTabla {
  id?: number;
  rut: number;
  fecha: Date;
  haberes: number;
  descuentos: number;
  alcanceLiquido: number;
  eliminado: boolean;
  firmada?: boolean;
  estado?: boolean;
}

@Component({
  selector: 'app-lista-liquidaciones',
  templateUrl: './lista-liquidaciones.component.html',
  styleUrls: ['./lista-liquidaciones.component.css'],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ListaLiquidacionesComponent implements OnInit {

  constructor(
    private trabajadoresService: TrabajadoresService,
    private liquidacionService: LiquidacionService,
    private documentoService: DocumentosService,
    private libroService: contabilidadService,
    private solicitudService: SolicitudLiquidacionService,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private pdfLibService: PdflibService,
    private formBuilder: FormBuilder,
  ) {
    this.MIN_DATE = parseISO('2023-12-01');
  }
  NAME_FIRMA = 'FIRMALIQUIDACIONES.png';
  MIN_DATE: Date;
  now: Date;
  displayedColumns = [
    'fecha',
    'haberes',
    'descuentos',
    'alcanceLiquido',
    'estado',
    'Acciones'
  ];

  date = new FormControl();
  dateDesde = new FormControl();
  dateHasta = new FormControl();
  RutTrabajador: any;
  dataSource: MatTableDataSource<LiquidacionTabla>;
  liquidaciones: LiquidacionTabla[];
  idliquidacion: number;
  libro: any;
  aprobaciones: Aprobacion[];
  aprobacionLiquidacion: Aprobacion[];
  textoBuscar: string = '';
  trabajadorNombre: string = '';
  info_documento: any;
  @Input() rut: any;
  @Output() nombreTrabajador = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator: MatPaginator;   // variable para asignar paginator a matdatatable
  @ViewChild(MatSort) sort: MatSort;
  dashboardForm: FormGroup;

  async ngOnInit() {
    await this.traerDatos();
    this.dashboardForm = this.formBuilder.group({
      dataFilter: [''] // Puedes inicializar con algún valor por defecto si es necesario
    });

    // forkJoin([this.liquidacionService.TraerPorRut(this.rut), this.trabajadoresService.TraerTrabajadoresRut(this.rut)])
    //   .pipe(
    //     map(([liquidaciones, trabajador]) => ({ liquidaciones, trabajador })),
    //     catchError(error => of(error))
    //   ).subscribe(data => {
    //     // console.log({data});

    //   });
  }
  async traerDatos() {
    await this.TraerAprobaciones();
    const resLiquidacion = await this.traerLiquidacionesTrabajador();
    const resTrabajador = await this.traerTrabajador();
    if (!resLiquidacion.success || !resTrabajador.success) {
      this.alert.error_small('Hubo un error al obtener las liquidaciones y/o trabajadores.');
      console.error(resLiquidacion.msg??'', resTrabajador.msg??'');
      return;
    }

    const liquidaciones = resLiquidacion.data.filter(x => !x.eliminado);
    const liquidacionesModificado = await this.traerDocumentosLiquidacion(liquidaciones);
    const liquidacionesFilter = liquidacionesModificado.filter(element => {
      const fechaLiquidacion = moment(element.fecha, 'YYYY-MM-DD')
      if (this.dateDesde.value != undefined && this.dateHasta.value != undefined) {
        return fechaLiquidacion >= this.dateDesde.value && fechaLiquidacion <= this.dateHasta.value
      } else if (this.dateDesde.value != undefined && this.dateHasta.value == undefined) {
        return fechaLiquidacion >= this.dateDesde.value
      } else if (this.dateDesde.value == undefined && this.dateHasta.value != undefined ) {
        return fechaLiquidacion <= this.dateHasta.value
      } else {
        return true
      }
    })
    this.liquidaciones = liquidacionesFilter.map((x: any) => {
      const estado: any = this.aprobaciones.find(y => y.idliquidacion === x.id)?.estado;
      const fecha = parseISO(x.fecha);
      return {
        ...x,
        rut: x.fk_trabajador,
        haberes: x.totalHaberes,
        descuentos: x.totalDescuentos,
        fecha: fecha,
        estado: estado === 0 ? 0
          : estado === 1 ? 1
          : estado === 2 && fecha.getTime() >= this.MIN_DATE.getTime() ? 2
          : fecha.getTime() < this.MIN_DATE.getTime() ? 1
          : null
      }
    })
    .sort((a, b) => b.fecha.getTime() - a.fecha.getTime());
    this.configurarTabla();

    const trabajador = resTrabajador.data[0];
    this.trabajadorNombre = trabajador.nombres + ' ' + trabajador.apellidos;
    this.nombreTrabajador.emit(this.trabajadorNombre);
  }
  configurarTabla(){
    this.dataSource = new MatTableDataSource(this.liquidaciones);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async traerDocumentosLiquidacion(liquidaciones: any) {
    const liquidacionesModificado = [];
    for (const liquidacion of liquidaciones) {
      const res = await this.liquidacionService.TraerArchivoAsync(liquidacion.id);
      if (res.success) {
        const documentos = res.data;
        liquidacion.firmada = documentos.some((x:any) => x.fechaFirma || new Date(x.fechaCronologica).getTime() < this.MIN_DATE.getTime());
      }else{
        liquidacion.firmada = false;
        console.error(res.msg);
      }
      liquidacionesModificado.push(liquidacion);
    }
    return liquidacionesModificado;
  }
  async traerLiquidacionesTrabajador() {
    return await this.liquidacionService.TraerPorRutAsync(this.rut);
  }
  async traerTrabajador(){
    return await this.trabajadoresService.TraerTrabajadoresRutAsync(this.rut);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.liquidaciones !== undefined) {
      if (!changes.liquidaciones.firstChange) {
        this.dataSource = new MatTableDataSource(this.liquidaciones);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  applyFilter() {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = this.textoBuscar;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  quitarFiltro() {
    this.textoBuscar = '';
    this.dataSource.filter = this.textoBuscar;
  }

  async TraerAprobaciones() {
    const res = await this.solicitudService.ObtenerTodasAprobacionesLiquidacion();
    if (!res.success) {
      this.alert.errorAlert('Hubo un error al obtener las aprobaciones de liquidación');
      return;
    }
    this.aprobaciones = res.data.sort((a: Aprobacion, b: Aprobacion) => b.id - a.id);

  }

  async descargarLiquidaciones() {
    let arrayRutas: string[] = []
    for (const liquidacion of this.liquidaciones) {
      const res = await this.liquidacionService.TraerArchivoAsync(liquidacion.id);
      if (res.success) {
        const documentos = res.data[0];
        const ruta = `${environment.API}/liquidaciones/` + documentos.ruta;
        arrayRutas = [...arrayRutas, ruta]
      }
    }
    const mergedPdfBytes = await this.pdfLibService.mergePDFs(arrayRutas)
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank')
  }

  async Descargar(liquidacion: any, option: number) {
    // OPTION 1 = VISUALIZAR LIQUIDACÍON
    // OPTION 2 = VISUALIZAR LIQUIDACION FIRMADA
    const id = liquidacion.id;
    const estado = liquidacion.estado;
    this.liquidacionService.TraerArchivo(id).subscribe(
      async res => {
        const documentos = res.data;
        if (option === 1) {
          await this.documentoNoFirmado(estado, documentos);
        }else if (option === 2) {
          await this.documentoFirmado(estado, documentos);
        }
      }
    );
  }

  async documentoNoFirmado(estado: number, documentos: any[]){
    // ME TRAIGO LOS DOCUMENTOS QUE NO ESTAN FIRMADOS
    const documentosFilter = documentos.filter((x) => !x.fechaFirma);
    // OBTENER EL ULTIMO DOCUMENTO
    this.info_documento = documentosFilter[documentosFilter.length - 1]
    const ruta = `${environment.API}/liquidaciones/` + this.info_documento.ruta;
    switch (estado) {
      case 1:
        const firma = `${environment.API}/firmas/` + this.NAME_FIRMA;
        const blobFirma = await this.pdfLibService.agregarFirmaBlob(ruta, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 2:
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(ruta, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break;
      default:
        window.open(ruta, '_blank');
        break;
    }
  }

  async documentoFirmado(estado: number, documentos: any[]){
    // ME TRAIGO EL DOCUMENTO FIRMADO
    const documentosFirmado = documentos.filter(x => x.fechaFirma);
    if (documentosFirmado.length > 0) {
      const ruta = `${environment.API}/liquidaciones/` + documentosFirmado[documentosFirmado.length - 1].ruta;
      switch (estado) {
        case 2:
          const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(ruta, 'RECHAZADA');
          this.pdfLibService.visualizarBlob(blobMarca);
          break;
        default:
          window.open(ruta, '_blank');
          break;
      }
    }else{
      this.alert.error_small('No existe documento firmado. Cargando documento no firmado...');
      await this.documentoNoFirmado(estado, documentos);
    }
  }

  async verHistorial(id: number, viewHistorialDecisiones: any) {
    this.aprobacionLiquidacion = this.aprobaciones.filter(x => x.idliquidacion === id);
    this.modalService.open(viewHistorialDecisiones, { size: 'md', modalDialogClass: 'shadow', scrollable: true });
  }

  async openLiquidacionFirmada(element: any, content: any) {
    const ref = this.modalService.open(content, {size: 'lg', modalDialogClass: 'shadow'});
    this.idliquidacion = element.id;
    this.RutTrabajador = element.rut;
    ref.closed.subscribe((res) => {
      console.log({res});
    })
  }

  // SE ABRE UN MODAL PARA EDITAR LA LIQUIDACION
  async EditarLiquidacion(id: number, contenidos: any) {
    // console.log(id);
    const res = await this.libroService.TraerPorLiquidacionAsync(id);
    if (!res.success) {
      this.alert.error_small(res.msg);
      return;
    }
    this.modalService.open(contenidos, { fullscreen: true, scrollable: true });
    this.libro = res.data;
    this.RutTrabajador = this.libro.rut;
  }
  // BORRA LA LIQUIDACION, CON SUS DATOS Y ARCHIVO GUARDADO EN EL SERVIDOR
  async Borrar(id: number) {
    if (!confirm('¿Seguro que desea eliminar la liquidacion?')) return;
    const result = await this.documentoService.eliminarDocumentoAsync(id, 'LIQUIDACION');
    if (!result.success){
      this.alert.error_small('Hubo un error al eliminar el documento.');
      console.error(result.msg);
    }
    this.alert.success_small('Se eliminó la liquidación');
    await this.traerDatos();
    // const resLibro = await this.libroService.TraerTodoAsync();
    // if (resLibro.success) {
    //   const registroLibro = resLibro.data.find(x => x.idliquidacion === id);
      // const result = await this.documentoService.eliminarDocumentoAsync(id, 'LIQUIDACION');
      // if (!result.success){
      //   this.alert.error_small('Hubo un error al eliminar el documento.');
      //   console.error(result.msg);
      // }
      // this.alert.success_small('Se eliminó la liquidación');
    // }
    // this.libroService.TraerTodo().subscribe(async res => {

    // }, (error) => {
    //   console.error(error);
      // this.alert.error_small('Hubo un error al eliminar los registros. Liquidacion y libro de remuneración');
      // this.documentoService.eliminarDocumento(id, 'LIQUIDACION').subscribe(() => {
      //   this.liquidacionService.eliminarLiquidacion(id).subscribe(() => {
      //     this.alert.success_small('Se eliminó la liquidación');
      //     this.liquidacionService.TraerPorRut(this.rut).subscribe(
      //       ({ data }) => {
      //         this.liquidaciones = data.map(x => ({
      //           id: x.id,
      //           rut: x.fk_trabajador,
      //           fecha: new Date(x.fecha + 'T00:00:00'),
      //           haberes: x.totalHaberes,
      //           descuentos: x.totalDescuentos,
      //           alcanceLiquido: x.alcanceLiquido,
      //         }));
      //         this.dataSource = new MatTableDataSource(this.liquidaciones);
      //         this.dataSource.paginator = this.paginator;
      //         this.dataSource.sort = this.sort;
      //       }
      //     );
      //   }, (error) => {
      //     this.alert.error_small('Hubo un error al eliminar el finiquito.');
      //     console.error(error);
      //   });
      // }, (error) => {
      //   this.alert.error_small('Hubo un error al eliminar el documento.');
      //   console.error(error);
      // });
    // });
  }
  // Consigue el nombre del trabajador una vez que se carga en el modal
  getNombreTrabajador(nombre: string) {
    this.trabajadorNombre = nombre;
  }


  chosenYearHandlerDesde(normalizedYear: moment.Moment) {
    const ctrlValue = this.dateDesde.value || moment();
    ctrlValue.year(normalizedYear.year());
    this.dateDesde.setValue(ctrlValue);
  }

  chosenMonthHandlerDesde(normalizedMonth: moment.Moment, datepicker: any) {
    const ctrlValue = this.dateDesde.value || moment();
    ctrlValue.year(normalizedMonth.year());
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(1)
    this.dateDesde.setValue(ctrlValue);
    datepicker.close();
    this.traerDatos()
  }

  limpiarDateDesde() {
    this.dateDesde.reset()
    this.traerDatos()
  }

  chosenYearHandlerHasta(normalizedYear: moment.Moment) {
    const ctrlValue = this.dateHasta.value || moment();
    ctrlValue.year(normalizedYear.year());
    this.dateHasta.setValue(ctrlValue);
  }

  chosenMonthHandlerHasta(normalizedMonth: moment.Moment, datepicker: any) {
    const ctrlValue = this.dateHasta.value || moment();
    ctrlValue.year(normalizedMonth.year());
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(31)
    this.dateHasta.setValue(ctrlValue);
    datepicker.close();
    this.traerDatos()
  }

  limpiarDateHasta() {
    this.dateHasta.reset()
    this.traerDatos()
  }
}

