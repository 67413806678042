<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<div class="animate__animated animate__slideInUp">
    <br />
    <mat-card class="mat-elevation-z5 mat_card_trabajadores_content">
        <mat-tab-group class="card_group_trabajadores">
            <mat-tab label="Licencias por Trabajadores">
                <br />
                <mat-card>
                    <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
                        Licencias Medicas
                    </h3>
                    <app-main-buscar-filter
                        [num_trab_finiquitados]="num_trab_finiquitados"
                        (textSearch)="applyFilter($event)"
                        (viewFiniquitadosEmit)="viewDatosFiniquitados($event)">
                    </app-main-buscar-filter>

                    <table mat-table id="tabla" [dataSource]="dataSource" matSort class="mat-elevation-z8" (matSortChange)="announceSortChange($event)">

                        <ng-container matColumnDef="rut">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
                            <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.rut | formatoRut}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="nombres">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE COMPLETO </th>
                            <td class="padding-table" mat-cell *matCellDef="let element"> {{element.nombres |
                                uppercase}} {{element.apellidos | uppercase}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="empresa">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA</th>
                            <td class="padding-table" mat-cell *matCellDef="let element">{{
                                element.Empresa_contratante.nombre | uppercase}}</td>
                        </ng-container>
                            
                        <ng-container matColumnDef="estado_contrato">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CONTRATACIÓN</th>
                            <td class="padding-table" mat-cell *matCellDef="let element"> 
                                <span [ngClass]="{'bg-danger p-1 rounded text-light': element.estado_contrato === 'Finiquitado'}">{{element.estado_contrato | uppercase}}</span> 
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="acciones">
                            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
                            <td class="padding-table" mat-cell *matCellDef="let element">
                                <button mat-button (click)="open2(contenido,element.rut)" class="button_color_primary"
                                    style="background-color:rgb(37, 199, 228)">
                                    <mat-icon style="color: white">archive</mat-icon>
                                </button> &nbsp;
                                <button mat-button (click)="open(content,element.rut)" class="button_color_primary"
                                    style="background-color:rgb(37, 199, 228)">
                                    <mat-icon style="color: white">file_upload</mat-icon>
                                </button>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1]" showFirstLastButtons
                        aria-label="Seleccionar pagina de Trabajadores.">
                    </mat-paginator>
                </mat-card>


            </mat-tab>
            <mat-tab label="Todas las Licencias">
                <hr />
                <app-all-licencias>
                </app-all-licencias>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>



<!-- MODAL PARA LISTAR LIQUIDACIONES POR RUT  llama a la app-listar-liquidaciones-->
<ng-template style="border: 0px; z-index: 100" #contenido let-modal2>
    <div class="modal-header" style="text-align: center">
        <h4>Visualizar Licencias</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-listar-licencias [rut]='RutTrabajador'></app-listar-licencias>
    </div>
</ng-template>

<!-- MODAL PARA CREAR LIQUIDACION llama a la app-agregar-liquidaciones-->
<ng-template style="border: 0px; z-index: 100" #content let-modal>

    <div class="modal-header" style="text-align: center">
        <h4> Cargar Licencia</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-agregar-licencias [rut]='RutTrabajador'></app-agregar-licencias>

</ng-template>