import { PrimeNgModule } from './../../../PrimeNg.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { FileUploadModule } from 'ng2-file-upload';
import { HelperModule } from 'src/app/helper.module';
import { BuscarFilterModule } from '../buscar-filter/buscar-filter.module';

import { MainImplementosComponent } from './main-implementos/main-implementos/main-implementos.component';
import { ListaImplementosComponent } from './lista-implementos/lista-implementos/lista-implementos.component';
import { VerImplementosComponent } from './ver-implementos/ver-implementos/ver-implementos.component';
import { AgregarImplementosComponent } from './agregar-implementos/agregar-implementos.component';
import { VerUniformeComponent } from './ver-uniforme/ver-uniforme/ver-uniforme.component';
import { AgregarUniformeComponent } from './agregar-uniforme/agregar-uniforme.component';

@NgModule({
  declarations: [
    MainImplementosComponent,
    ListaImplementosComponent,
    AgregarImplementosComponent,
    AgregarUniformeComponent,
    VerImplementosComponent,
    VerUniformeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatSortModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FileUploadModule,
    HelperModule,
    PrimeNgModule,
   BuscarFilterModule,

  ],
  exports:[
    MainImplementosComponent,
    ListaImplementosComponent,
    AgregarUniformeComponent,
    AgregarImplementosComponent,
    VerImplementosComponent,
    VerUniformeComponent
 
  ]
})
export class ImplementosModule { }
