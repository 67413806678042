import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { GetAllUsuarios } from 'src/app/shared/models/user.interface';
import { UsuariosService } from '../../../services/usuarios.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['./lista-usuarios.component.css']
})
export class ListaUsuariosComponent implements OnInit {

  public mostrar: number = 0;
  checked: boolean = false;

  @ViewChild('slidePass') slidePass: ElementRef;
  @Input() Usuarios_list!: GetAllUsuarios[];
  @Input() Roles_list!: any;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();

  debouncer: Subject<boolean> = new Subject();

  usuario_update: any = {
    id: 0,
    nombre_usuario: "",
    email_usuario: "",
    clave_usuario: "",
    clave_usuario_confirm: "",
    estado_usuario: "",
    id_rol: "",
  }

  displayedColumns: string[] = ['id', 'nombre_usuario', 'email_usuario', 'rol', 'estado_usuario', 'actions'];
  dataSource = new MatTableDataSource<GetAllUsuarios>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private user_services: UsuariosService,
    private modalService: NgbModal,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.Usuarios_list && !changes.Usuarios_list.firstChange) {
      // Filtra los usuarios cuyo rol no es "COMITE"
      const filteredUsers = this.Usuarios_list.filter(usuario => usuario.id_rol !== 9);
      this.dataSource = new MatTableDataSource<GetAllUsuarios>(filteredUsers);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      for (let i = 0; i < this.Roles_list.length; i++) {
        this.Roles_list[i].id = this.Roles_list[i].id.toString();
      }
    }
  }

  open(content: any, id: number) {
    this.checked = false;
    this.mostrar = 0;
    this.user_services.Obtener_usuario(id).subscribe(res => {
      this.usuario_update = res.data;
      this.usuario_update.id_rol = this.usuario_update.rolUsuario.id.toString();
      this.usuario_update.estado_usuario = this.usuario_update.estado_usuario.toString();
      this.modalService.open(content);
    });
  }

  actualizar_usuario() {
    let valido: boolean = true;

    if (this.usuario_update.nombre_usuario.trim().length == 0 ||
      this.usuario_update.email_usuario.trim().length == 0 ||
      (this.checked == true && (this.usuario_update.clave_usuario.trim().length == 0 || this.usuario_update.clave_usuario.trim().length == 0)) ||
      this.usuario_update.id_rol.trim().length == 0 ||
      this.usuario_update.estado_usuario.trim().length == 0 ||
      this.auth.esEmailValido(this.usuario_update.email_usuario) === false
    ) {
      this.alert.errorAlert('Faltan datos o datos incorrectos, favor revisar');
      valido = false;
    }

    if (this.checked) {
      if (this.usuario_update.clave_usuario != this.usuario_update.clave_usuario_confirm) {
        this.alert.errorAlert('Las passwords ingresadas deben ser iguales, favor revisar');
        valido = false;
      }
    } else {
      delete this.usuario_update.clave_usuario;
    }

    if (valido) {
      delete this.usuario_update.clave_usuario_confirm;
      this.user_services.Actualizar_usuario(this.usuario_update).subscribe(res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!);
          this.modalService.dismissAll();
          this.checked = false;
          this.limpiar_usuario();
          this.debouncer.next(true);
        } else {
          this.alert.error_small(res.msg!);
        }
      });
    }
  }

  Desabilitar_Habilitar_Usuario(id: number, estado_usuario: boolean) {
    let Title = "";

    if (estado_usuario == true) {
      Title = "Habilitar";
    } else {
      Title = "Inhabilitar";
    }

    const user = {
      id: id,
      estado_usuario: estado_usuario
    }

    Swal.fire({
      title: `¿${Title} Usuario?`,
      text: "Se cambiará el estado del usuario!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#777777',
      cancelButtonColor: '#d33',
      confirmButtonText: `Sí, ${Title}!`,
      cancelButtonText: 'Cancelar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.user_services.Desabilitar_usuario(user).subscribe(result => {
          this.debouncer.next(true);
          this.alert.success_small(result.msg);
        });
      }
    });
  }

  onChangeSlide($event: any) {
    if ($event.checked) {
      this.mostrar = 1;
      this.checked = true;
    } else {
      this.mostrar = 0;
      this.checked = false;
      this.usuario_update.clave_usuario_confirm = "";
      this.usuario_update.clave_usuario = "";
    }
  }

  limpiar_usuario() {
    this.usuario_update.nombre_usuario = "";
    this.usuario_update.email_usuario = "";
    this.usuario_update.clave_usuario = "";
    this.usuario_update.estado_usuario = "";
    this.usuario_update.id_rol = "";
    this.usuario_update.clave_usuario_confirm = "";
  }
}
