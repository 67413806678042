

  <div>
    <button mat-button class="button_color_primary" (click)="openn(ventas)">
      <span style="color: white">Agregar Impuesto </span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
  </div>
  <br>

<ng-template style="border: 0px; z-index: 100" #ventas let-modal2>
  <div class="modal-header" style="text-align: center">

    <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-agregar-impuesto2cat> </app-agregar-impuesto2cat>
</ng-template>

<div class="mat-elevation-z8">

  
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="desde">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DESDE</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.desde }}
      </td>
    </ng-container>

    <ng-container matColumnDef="hasta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>HASTA</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.hasta }}
      </td>
    </ng-container>


    <ng-container matColumnDef="cantidad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.cantidad | number }}
      </td>
    </ng-container>
    <ng-container matColumnDef="trabajador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Trabajador</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.fk_trabajador }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        <button (click)="open(content, element.id)" mat-button class="button_color_primary">
          <mat-icon style="color: white">edit</mat-icon>
        </button>
        &nbsp;
        <button (click)="Eliminar(element.id)" mat-button class="button_color_primary"
          style="background-color:red">
          <mat-icon style="color: white">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="Seleccionar pagina">
  </mat-paginator>
</div>




<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Actualizar Tramo</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form>
      <!-- Fila 1 -->
      <!-- <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Trabajador</mat-label>
            <mat-select name="rut" (selectionChange)="ObtenerTrabajador($event)">
                <mat-option *ngFor="let element of trabajadores"  [value]="element.rut" >
                    {{element.nombres}} {{element.apellidos}}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Número de cuenta</mat-label>
          <input type="number" maxlength="12" minlength="1" matInput name="update_tramo.desde" [(ngModel)]="update_tramo.desde"
            [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Número de cuenta</mat-label>
          <input type="number" maxlength="12" minlength="1" matInput name="update_tramo.hasta"
            [(ngModel)]="update_tramo.hasta" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Número de cuenta</mat-label>
          <input type="number" maxlength="12" minlength="1" matInput name="update_tramo.cantidad"
            [(ngModel)]="update_tramo.cantidad" [ngModelOptions]="{standalone: true}" />
        </mat-form-field>
      </div>


    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="Actualizar()">
      Actualizar
    </button>
  </div>
</ng-template>