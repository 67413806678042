import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import {  RequestResponseGetAllPlanillas, RequestResponseActualizar } from 'src/app/shared/models/planilla-pagos.interface';


@Injectable({
  providedIn: 'root'
})
export class PlanillaPagosService {

  constructor(
      private http:HttpClient,
      private router: Router,
      private alert:AlertHelper) 
      { }



  GetAll_planillas(): Observable<RequestResponseGetAllPlanillas> {
    return this.http
    .get<RequestResponseGetAllPlanillas >(`${environment.API}/rrhh/planilla/`)
    .pipe(
      map((res:RequestResponseGetAllPlanillas) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Create_planilla(planilla:any): Observable<RequestResponse> {               
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/planilla/crear`,planilla)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Obtener_planilla(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/planilla/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Eliminar_planilla(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/planilla/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Actualizar_planilla(planilla:any): Observable<RequestResponseActualizar>  {
    return this.http
    .put<RequestResponseActualizar >(`${environment.API}/rrhh/planilla/actualizar/${planilla.id}`,planilla)
    .pipe(
      map((res:RequestResponseActualizar) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

}




