import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Injectable({
  providedIn: 'root'
})
export class ConceptosLREService {

  constructor(
      private http:HttpClient,
      private alert:AlertHelper,
    ) { }
  
    GetAll_Conceptos():Observable<RequestResponse> {
      return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/conceptoslre/`)
      .pipe(
        map((res:RequestResponse) =>{
          return res;
        }),
        catchError((err)=> this.handlerError(err))
      );
    }
    
    private handlerError(err: any):Observable<never>{

      let errorMessage = "";
      if (err) {
        errorMessage = `Error: ${err.error.msg}`;
      }
      this.alert.error_small(err.error.msg);
      return throwError(errorMessage);
    }
}
