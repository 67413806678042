<div class="animate__animated animate__fadeInRight">
    <br />

    <div class="col">
        <mat-card class="mat_card_general">
            <h1 class>Modulo de Ciudades</h1>
        </mat-card>
    </div>

    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content">
        <app-agregar-ciudades (onDebounce)="actualizarTabla($event)"></app-agregar-ciudades>
        <br />
        <app-lista-ciudades (onDebounce)="actualizarTabla($event)" [ciudades_List]="ciudades"></app-lista-ciudades>
    </mat-card>
    <hr />
</div>