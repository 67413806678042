<div class="container">
    <div class="rightbox">
        <div class="rb-container">
            <ul class="rb">
                <!--  -->
                <li class="rb-item" ng-repeat="itembx" *ngFor="let item of documentos">
                    <div class="timestamp">{{item.fechaCronologica | date :'dd/MM/yyyy'}}</div>
                    <h2 class="item-title" style="margin-bottom: 0px;">
                        <a>{{item.tipo}}</a>
                    </h2>
                    <div class="byline"><a href="{{item.ruta}}" style="color:#1ABB9C;">Descargar</a></div>
                    <!--  -->
                </li>
            </ul>

        </div>
    </div>
</div>