import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { debounceTime } from 'rxjs/operators';
import { RolesService } from 'src/app/pages/services/roles.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Roles } from 'src/app/shared/models/roles.interface';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lista-roles',
  templateUrl: './lista-roles.component.html',
  styleUrls: ['./lista-roles.component.css']
})
export class ListaRolesComponent implements OnInit {

  @Input() Roles_list: Roles[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  Update_roles: Roles = {
    id: 0,
    nombre: "",
    descripcion: "",
    estado: false
  };

  rolesForm = this.fb.group({
    descripcion: [, Validators.required],
    nombre: [, Validators.required],
    estado: [, Validators.required],

  })
  estados = [0, 1];

  debouncer: Subject<boolean> = new Subject();

  displayedColumns: string[] = ['id', 'nombre', 'descripcion', 'estado', 'actions'];
  dataSource = new MatTableDataSource<Roles>(this.Roles_list);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private roles_services: RolesService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }

  get f() {
    return this.rolesForm.controls;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.Roles_list && !changes.Roles_list.firstChange) {
      // Filtra los roles antes de asignar los datos a dataSource
      const filteredRoles = this.Roles_list.filter(role => role.nombre !== 'COMITE');
      this.dataSource = new MatTableDataSource<Roles>(filteredRoles);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  limpiar_roles() {
    this.Update_roles.nombre = "";
    this.Update_roles.descripcion = "";
    this.Update_roles.estado = false;
  }

  onSubmit() {
    if (this.rolesForm.status === 'VALID') {
      this.Update_roles.descripcion = this.rolesForm.controls['descripcion'].value;
      this.Update_roles.nombre = this.rolesForm.controls['nombre'].value;
      this.Update_roles.estado = this.rolesForm.controls['estado'].value;

      this.roles_services.Actualizar_rol(this.Update_roles).subscribe(res => {
        if (res.success) {
          this.alert.success_small(res.msg!);
          this.modalService.dismissAll();
          this.rolesForm.reset();
          this.limpiar_roles();
          this.debouncer.next(true);
        } else {
          this.alert.error_small(res.msg!);
        }
      });
    } else {
      this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top',
      });
    }
  }

  eliminar(id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el rol",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.roles_services.Eliminar_rol(id).subscribe(res => {
          if (res.success) {
            this.alert.success_small(res.msg!);
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!);
          }
        });
      }
    });
  }

  open(content: any, id: number) {
    this.Update_roles.id = id;
    this.limpiar_roles();
    this.roles_services.Obtener_rol(id).subscribe(res => {
      this.Update_roles = res.data;
      this.f.descripcion.setValue(res.data.descripcion);
      this.f.nombre.setValue(res.data.nombre);
      this.f.estado.setValue(res.data.estado);
    });
    this.modalService.open(content);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  Desabilitar_Habilitar_Rol(id: number, estado: boolean) {
    let Title = estado ? "Habilitar" : "Inhabilitar";
    const rol = {
      id: id,
      estado: estado
    };

    this.roles_services.Desabilitar_Habilitar_rol(rol).subscribe(res => {
      this.debouncer.next(true);
      this.alert.success_small(res.msg);
    });
  }
}
