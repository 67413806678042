import { Component, OnInit } from '@angular/core';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
@Component({
  selector: 'app-main-ciudades',
  templateUrl: './main-ciudades.component.html',
  styleUrls: ['./main-ciudades.component.css']
})
export class MainCiudadesComponent implements OnInit {

  ciudades:Ciudad[] = [];

  constructor(
    private ciudadService: CiudadService
  ) { }

  ngOnInit(): void {
    this.ciudadService.GetAll_ciudades().subscribe(
      (res) => {
        this.ciudades = res.data;
        
      }
    );
  }
  
  actualizarTabla(event:boolean){
    this.ciudadService.GetAll_ciudades().subscribe(res=>{
      this.ciudades=res.data
    })
  }

}
