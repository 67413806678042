
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { CargoService } from 'src/app/pages/services/cargo.service';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { JornadaLaboralService } from 'src/app/pages/services/jornada-laboral.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { JornadaLaboral } from 'src/app/shared/models/jornada-laboral.interface';

@Component({
  selector: 'app-lista-cargos',
  templateUrl: './lista-cargos.component.html',
  styleUrls: ['./lista-cargos.component.css']
})
export class ListaCargosComponent implements OnInit {

  @Input() cargos_List!: Cargo[];
  @Input() list_sucursales!: Sucursal[];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();
  cargosLista: any;


  fileName = 'Cargos.xlsx';
  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }

  public convetToPDF() {
    let datos = [];
  this.cargoService.GetAll_cargoS().subscribe((res) => {                
    this.cargosLista = res.data;
    //console.log(this.cargosLista);
    datos.push([
      'ID',
      'CARGO',
      'SUCURSALES',
      'DEPARTAMENTO',
      'LUGAR DE TRABAJO',
      'JORNADA DE TRABAJO',
      'DIAS']);
    
    this.cargosLista.map(function (item) {
      // console.log(item.jornadas.descripcion);
      datos.push([
        item.id,
        item.nombre,
        item.sucursales2.nombre,
        item.departamento,
        item.lugar,
        item.jornadas.descripcion,
        item.dias
      ]);
    });
    
    var documentDefinition = {
      content: [
        { text: 'Cargos', style: 'header', fontSize: 30 },
        {
          table: {
            //widths: [50,400],
            body: datos
          }
        }
      ],
    }
    pdfMake.createPdf(documentDefinition).open();
  });
  }


  update_cargos: Cargo = {
    id: 0,
    nombre: "",
    departamento: "",
    lugar: "",
    jornadaLaboral: 0,
    dias: "",
    sucursal: 0
  };

  displayedColumns: string[] = ['id', 'nombre', 'departamento', 'sucursal', 'lugar', 'jornadaLaboral', 'dias', 'actions'];
  dataSource = new MatTableDataSource<Cargo>(this.cargos_List);
  listSucursal: Sucursal[] = [];
  jornadas: JornadaLaboral[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private cargoService: CargoService,
    private sucursalService: SucursalService,
    private JornadaLaboralService: JornadaLaboralService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.getSucursales();
    this.getJornadas();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cargos_List != undefined) {
      if (!changes.cargos_List.firstChange) {
        this.dataSource = new MatTableDataSource<Cargo>(this.cargos_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  getSucursales() {
    this.sucursalService.GetAll_sucursales().subscribe(
      (res) => {
        this.listSucursal = res.data;

      }
    );
  }

  getJornadas() {
    this.JornadaLaboralService.GetAll_jornadas().subscribe(
      (res) => {
        this.jornadas = res.data;
      }
    );
  }

  open(content: any, id: number) {
    this.limpiar();
    this.update_cargos.id = id
    this.cargoService.Obtener_cargo(id).subscribe(res => {
      this.update_cargos.nombre = res.data.nombre;
      this.update_cargos.departamento = res.data.departamento;
      this.update_cargos.lugar = res.data.lugar;
      this.update_cargos.jornadaLaboral = res.data.jornadaLaboral;
      this.update_cargos.dias = res.data.dias;
      this.update_cargos.sucursal = res.data.sucursal;
    })
  
    this.modalService.open(content, { size: 'lg' });
  }

  limpiar() {
    this.update_cargos.nombre = "";
    this.update_cargos.departamento = "";
    this.update_cargos.lugar = "";
    this.update_cargos.jornadaLaboral = 0;
    this.update_cargos.dias = "";
    this.update_cargos.sucursal = 0;
  }

  Actualizar_Cargo() {
    if(this.validarCargo()){
      this.cargoService.Actualizar_cargo(this.update_cargos).subscribe(res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.limpiar();
          this.debouncer.next(true);
        } else {
          this.alert.error_small(res.msg!)
        }
      })
    }
    else{
      this.alert.errorAlert('Existen campos vacíos o con un formato no válido, favor revisar');
    }

  }

  Eliminar_Cargo(content: any, id: number) {

    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el Cargo seleccionado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.cargoService.Eliminar_cargo(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
    this.debouncer.next(true);
  }

  validarCargo(){
    if(this.update_cargos.nombre.trim().length < 3 || this.update_cargos.nombre.trim().length > 50 ||
    this.update_cargos.departamento.trim().length < 3 || this.update_cargos.departamento.trim().length > 50 ||
    this.update_cargos.lugar.trim().length < 3 || this.update_cargos.lugar.trim().length > 150 ||
    this.update_cargos.dias.trim().length < 0 || this.update_cargos.dias.trim().length > 50 ||
    this.update_cargos.jornadaLaboral == 0 || this.update_cargos.sucursal == 0
     ){
      return false;
    }
    else {
      return true;
    }
  }

  //FILTRAR
  applyFilter(event: Event) {
  //Funcion para filtar cualquier columna por el texto buscado
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
