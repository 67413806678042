import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import { Denuncias, RequestResponseGetAllDenuncias, RequestResponseActualizar } from 'src/app/shared/models/denuncia.interface';


@Injectable({
  providedIn: 'root'
})
export class DenunciasService {
  

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }



  TraerDenuncias(): Observable<RequestResponseGetAllDenuncias> {
    return this.http
    .get<RequestResponseGetAllDenuncias >(`${environment.API}/rrhh/denuncia/`)
    .pipe(
      map((res:RequestResponseGetAllDenuncias) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  obtenerDenunciaPorCodigo(codigo: string): Observable<RequestResponseGetAllDenuncias> {
    return this.http.get<RequestResponseGetAllDenuncias>(`${environment.API}/rrhh/denuncia/buscar/${codigo}`);
  }

  updateDenunciaStatus(denuncia:any): Observable<RequestResponseActualizar> {
    return this.http.put<RequestResponseActualizar>(`${environment.API}/rrhh/denuncia/actualizar/${denuncia.codigo}`, denuncia );
  }
  
  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

}




