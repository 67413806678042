<!-- <div class="row">
  <div class="col-2">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Buscar</mat-label>
      <input matInput [(ngModel)]="textoBuscar" (keyup)="applyFilter($event)"  #input>
    </mat-form-field>
  </div>
</div> -->
<app-main-buscar-filter [num_trab_finiquitados]="num_trab_finiquitados" (textSearch)="applyFilter($event)"
  >
</app-main-buscar-filter>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>RUT</th>
      <td mat-cell *matCellDef="let element">{{ element.TrabajadorPermiso.rut | formatoRut }}</td>
    </ng-container>

    <ng-container matColumnDef="trabajador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TRABAJADOR</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.TrabajadorPermiso.nombres + ' ' + element.TrabajadorPermiso.apellidos | uppercase }}
      </td>
    </ng-container>

   <ng-container matColumnDef="empresa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EMPRESA</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.TrabajadorPermiso.Empresa_contratante.nombre }}
      </td>
    </ng-container>
    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
          {{element.fecha}}
      </td>
    </ng-container> 
    <ng-container matColumnDef="motivo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>MOTIVO</th>
      <td class="padding-table" mat-cell *matCellDef="let element">         {{element.motivo | uppercase}}
      </td>
    </ng-container> 

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        <button mat-button (click)="open(listPermisos, element.TrabajadorPermiso.rut)" class="button_color_primary"
          style="background-color:rgb(37, 199, 228)">
          <mat-icon style="color: white">archive</mat-icon>
        </button> &nbsp;
        <button mat-button (click)="openSubir(content, element.rut)" class="button_color_primary"
          style="background-color:rgb(37, 199, 228)">
          <mat-icon style="color: white">file_upload</mat-icon>
        </button>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
  </mat-paginator>
</div>

<!-- Modal para visualizar los modales por trabajador -->


<ng-template style="border: 0px; z-index: 100" #listPermisos let-modal>
  <div class="modal-header">
    <mat-card-title>Visualizar permisos</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-listar-permisos-tabajador [rut]="rut"></app-listar-permisos-tabajador>
  </div>
</ng-template>
<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header" style="text-align: center">
    <mat-card-title>Subir Contrato</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-subir-permisos [rut]="rut"></app-subir-permisos>
  </div>
</ng-template>