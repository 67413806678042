<div class="col" appearance="outline">
  <mat-form-field class="example-full-width" >
    <mat-label>Cargo</mat-label>
    <mat-select (selectionChange)="selectedValue($event)" name="requisitos.id_cargo" [(ngModel)]="id_cargo">
      <mat-option *ngFor="let cargo of list_cargos" value="{{ cargo.id }}">
        {{ cargo.nombre }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>                       
<div *ngIf="id_cargo >0"> 
    <button mat-button class="button_color_primary" (click)="open(content)" >
      <span style="color: white">Agregar conocimientos minimos</span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
</div>
        <app-lista-conocimientos
        (onDebounce)="actualizarTabla($event)"
        [list_requisitos]="list_requisitos"
      ></app-lista-conocimientos>


      <ng-template style="border: 0px; z-index: 100" #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Agregar nuevo Conocimiento</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>    
        <div class="modal-body">
          <form>
            <div class="row">
            <div class="col">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Descripcion del Conocimiento minimo</mat-label>
                <textarea type="textarea" rows="7"  matInput maxlength="1000" name="descripcion_nueva" [(ngModel)]="nuevo_descripcion"></textarea>
              </mat-form-field>
            </div>                              
            </div>                
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-danger"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">Cancelar</span>
          </button>
          <button type="button" class="btn btn-outline-success" (click)="agregar()">
            Guardar
          </button>
        </div>
      </ng-template>
      