import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrabajadorHaberService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

  TraerTodosHaberesTrabajador():Observable<RequestResponse>{
    return this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajadorHaber/`)
    .pipe(
      map((res:RequestResponse) => res),
      catchError((error) => this.handlerError(error))
    );
  }
  async TraerTodosHaberesTrabajadorAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajadorHaber/`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener los haberes'} as RequestResponse;
    }
  }
  TraerHaberPorRut(rut:number):Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/trabajadorHaber/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerHaberConceptoPorRut(rut:number):Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/trabajadorHaber/haberconcepto/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async traerHaberConceptoPorRutAsync(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajadorHaber/haberconcepto/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los conceptos.'} as RequestResponse)      
    }
  }
  async TraerHaberConceptoPorRutAsync(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajadorHaber/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los conceptos.'} as RequestResponse)      
    }
  }
  async TraerHaberPorPeriodoAsync(periodo: string): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajadorHaber/haberesperiodo/${periodo}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los haberes.'} as RequestResponse)      
    }
  }
 
  AsignarHaber(haberNuevo): Observable<RequestResponse> {      
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/trabajadorHaber/crear`,haberNuevo)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  EliminarAsignacion(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/trabajadorHaber/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  ActualizarHaberTrabajador(haberAct:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/trabajadorHaber/actualizar/${haberAct.id}`,haberAct)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  ObtenerHaberTrabajador(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/trabajadorHaber/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async ObtenerHaberTrabajadorAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajadorHaber/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener el haber.'} as RequestResponse)      
    }
  }


  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    console.error(err.error.msg);
    return throwError(errorMessage);
  }
}
