import { DatePipe } from '@angular/common';
import { Component, OnInit,Input } from '@angular/core';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { FiniquitoService } from 'src/app/pages/services/finiquitos/finiquito.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-modal-mostrar',
  templateUrl: './modal-mostrar.component.html',
  styleUrls: ['./modal-mostrar.component.css'],
  providers: [DatePipe]
})
export class ModalMostrarComponent implements OnInit {
  @Input() rut:any
  displayedColumns = [
    'id',
    'rut',
    'fecha',
    'total',
    'acciones'
  ];
  dataSource:any;
  finiquitos:any;
  info_documento:any;
  loading: boolean = false;
  constructor(
    private datePipe: DatePipe,
    private finiquitoService:FiniquitoService,
    private alert: AlertHelper,
    private documentoService: DocumentosService,
    // private solicitudDocumentoService: SolicitudesDocumentosService,
  ) { }

  ngOnInit(): void {
    this.TraerFiniquitos();
    //console.log(this.rut);
  }

  Descargar(id :any){
    webPreferences: {
      webSecurity: false
    }
    this.finiquitoService.TraerArchivo(id).subscribe(
      (res)=>{

        //console.log(res);
        if (res.success){


          this.info_documento =res.data[0];
          // console.log(this.info_documento);
          window.open(`${environment.API}/finiquitos/`+res.data[0].ruta);
        }
        else{
          this.alert.errorAlert("No se Encuentran archivos");
        }


      }

    );
  }

  TraerFiniquitos(){


    this.finiquitoService.TraerPorRut(this.rut).subscribe(
      (res) => {
        //console.table(res);
        this.finiquitos = res.data;
        this.dataSource = this.finiquitos;
        for (let i = 0; i <this.finiquitos.length; i++) {

          this.finiquitos[i].fecha=this.datePipe.transform(this.finiquitos[i].fecha,'yyyy-MM-dd');

        }

      }
    );
  }

  Borrar(id:number, estado:boolean){
    if (!confirm('¿Seguro que desea eliminar el finiquito?')) return;
      if (estado) {
        this.documentoService.eliminarDocumento(id, 'FINIQUITO').subscribe((res:RequestResponse) => {
          this.finiquitoService.eliminarContrato(id).subscribe((res:RequestResponse) => {
            this.alert.success_small('Se elimino el contrato');
            this.TraerFiniquitos();
          }, (error) => {
            this.alert.error_small('Hubo un error al eliminar el finiquito.');
            console.error(error);
          });
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar el finiquito.');
          console.error(error);
        });
      }else{
        this.finiquitoService.eliminarContrato(id).subscribe((res:RequestResponse) => {
          this.alert.success_small('Se elimino el finiquito');
          this.TraerFiniquitos();
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar el finiquito.');
          console.error(error);
        });
      }
    }

}
