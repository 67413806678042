import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Firma } from 'src/app/shared/models/firmas.interface';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmasService {

  constructor(private http: HttpClient) { }
  
  async getFirmas(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/firmas/`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener las firmas' } as RequestResponse
    }
  }
  async getFirma(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/firmas/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener la firma' } as RequestResponse
    }
  }
  async createFirma(firma: Firma): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/firmas/crear`, firma).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al crear la firma' } as RequestResponse
    }
  }
  async updateFirma(firma: Firma): Promise<RequestResponse> {
    try {
      return await this.http.put<RequestResponse>(`${environment.API}/rrhh/firmas/actualizar/${firma.id}`, firma).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al actualizar la firma' } as RequestResponse
    }
  }
}