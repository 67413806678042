<div class="mat-elevation-z8">
  <div class="filter-container">
    <mat-form-field appearance="outline">
      <mat-label>Filtrar por Empresa</mat-label>
      <mat-select placeholder="Seleccione empresa" (selectionChange)="ObtenerEmpresa($event)">
        <mat-option *ngFor="let e of empresas" [value]="e.id">
          {{ e.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
      <mat-form-field appearance="outline">
        <mat-label>Filtrar por Periodo</mat-label>
        <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Fecha Inicio">
          <input matEndDate formControlName="end" placeholder="Fecha Fin">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (dateChange)="onDateRangeChange($event)"></mat-date-range-picker>    
      </mat-form-field>
    
      <button mat-raised-button color="primary" (click)="applyFilters()" class="apply-filters-button">Aplicar Filtros</button>
      <button mat-raised-button color="primary" (click)="clearFilters()" class="clear-filters-button">Limpiar Filtros</button>
  </div>
  
  <!-- Tabla de Adelantos -->
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Fecha Adelanto -->
    <ng-container matColumnDef="fechaInicioContrato">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA ADELANTO</th>
      <td mat-cell *matCellDef="let element">{{ element.fechaInicioContrato | date:'dd/MM/yyyy' }}</td>
    </ng-container>

    <!-- Nombre Trabajador -->
    <ng-container matColumnDef="fk_trabajador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>NOMBRE TRABAJADOR</th>
      <td mat-cell *matCellDef="let element">{{ element.Trabajador.nombres + ' ' + element.Trabajador.apellidos | uppercase }}</td>
    </ng-container>

    <!-- Empresa -->
    <ng-container matColumnDef="empresa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EMPRESA</th>
      <td mat-cell *matCellDef="let element">{{ element.Empresa.nombre | uppercase }}</td>
    </ng-container>

    <!-- Banco -->
    <ng-container matColumnDef="banco">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>BANCO</th>
      <td mat-cell *matCellDef="let element">
        <img [src]="element.Banco.urlLogo" width="40" class="rounded" [pTooltip]="element.Banco.nombre | uppercase" tooltipPosition="bottom">
      </td>
    </ng-container>

    <!-- Número Cuenta -->
    <ng-container matColumnDef="numeroCuenta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>NÚMERO CUENTA</th>
      <td mat-cell *matCellDef="let element">{{ element.numeroCuenta }}</td>
    </ng-container>

    <!-- Monto Adelanto -->
    <ng-container matColumnDef="montoAdelanto">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>MONTO ADELANTO</th>
      <td mat-cell *matCellDef="let element">{{ element.montoAdelanto | currency: 'CLP' }}</td>
    </ng-container>

    <!-- Acciones -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
      <td mat-cell *matCellDef="let element">
        <button (click)="open(content, element.id)" mat-button class="button_color_primary">
          <mat-icon style="color: white">edit</mat-icon>
        </button>
        &nbsp;
        <button (click)="eliminar_adelanto(content, element.id)" mat-button class="button_color_primary" style="background-color: red">
          <mat-icon style="color: white">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de trabajadores."></mat-paginator>
</div>

<!-- Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Actualizar Adelanto</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addressForm">
      <!-- Nombre Trabajador -->
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Trabajador</mat-label>
        <mat-select (selectionChange)="ObtenerTrabajador($event)">
          <mat-option *ngFor="let t of trabajadores" [value]="t.rut">{{ t.nombres }} {{ t.apellidos }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Fecha Inicio Contrato -->
      <mat-form-field appearance="outline">
        <mat-label>Fecha inicio contrato</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="update_adelantos.fechaInicioContrato" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <!-- Empresa -->
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Empresa</mat-label>
        <mat-select (selectionChange)="ObtenerEmpresa($event)">
          <mat-option *ngFor="let e of empresas" [value]="e.id">{{ e.nombre }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Banco -->
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Banco</mat-label>
        <mat-select (selectionChange)="ObtenerBanco($event)">
          <mat-option *ngFor="let b of bancos" [value]="b.id">{{ b.nombre }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Tipo Cuenta -->
      <mat-form-field appearance="outline">
        <mat-label>Tipo cuenta</mat-label>
        <input matInput [(ngModel)]="update_adelantos.tipoCuenta" />
      </mat-form-field>

      <!-- Número Cuenta -->
      <mat-form-field appearance="outline">
        <mat-label>Número de cuenta</mat-label>
        <input matInput type="number" [(ngModel)]="update_adelantos.numeroCuenta" />
      </mat-form-field>

      <!-- Monto Adelanto -->
      <mat-form-field appearance="outline">
        <mat-label>Monto adelanto</mat-label>
        <input matInput type="number" [(ngModel)]="update_adelantos.montoAdelanto" />
      </mat-form-field>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss('Cross click')">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="actualizar_adelanto()">Actualizar Adelanto</button>
  </div>
</ng-template>