<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo Solicitud de Despido</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content">
      <app-agregar-solicitud-despido
        (onClose)="cargarSolicitud()"
      ></app-agregar-solicitud-despido>
      <app-listar-solicitud-despido
        [despidos]="despidos"
      ></app-listar-solicitud-despido>
    </mat-card>
    <hr />
  </div>
  