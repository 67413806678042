import { Component, OnInit, ViewChild } from '@angular/core';
import { SolicitudLiquidacionService } from 'src/app/pages/services/solicitudes/solicitud-liquidacion.service';
import { Aprobacion } from 'src/app/shared/models/aprobacion.interface';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { MatTableDataSource } from '@angular/material/table';
import { ListaLiquidacion, ListaLiquidacionAprobaciones } from 'src/app/shared/models/liquidacion/listaliquidacion.interface';
import { MatPaginator } from '@angular/material/paginator';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { format, getMonth, getYear, parseISO } from 'date-fns';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/pages/auth/auth.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { MenuItem } from 'primeng/api';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
import { SelectionModel } from '@angular/cdk/collections';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';

@Component({
  selector: 'app-solicitudes-liquidacion',
  templateUrl: './solicitudes-liquidacion.component.html',
  styleUrls: ['./solicitudes-liquidacion.component.css']
})
export class SolicitudesLiquidacionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // VARIABLE PARA LIMITAR LAS LIQUIDACION MINIMAS EN FECHAS CREADAS
  // SOLO SE MOSTRARÁN LAS LIQUIDACIONES IGUALES O DESPUES DE LA FECHA MINIMA
  FIRMA_NAME = 'FIRMALIQUIDACIONES.png' 
  MIN_DATE = parseISO('2023-12-01');
  totalliquido: number = 0;

  loading: boolean = false;
  viewHistorial: any;
  aprobaciones: Aprobacion[] = [];
  aprobacionLiquidacion: Aprobacion[] = [];
  liquidaciones: ListaLiquidacionAprobaciones[] = [];
  liquidacionesFilter: ListaLiquidacionAprobaciones[] = [];
  modalViewLiquidacion = {
    liquidacion: null,
    aprobaciones: null,
    documento: '',
  }
  user: any;
  
  idLiquidacion: number;
  iddocumento: number;
  liquidacionEliminada: number = 0;
  nombreTrabajador: string = '';
  estadoCurrent: number;

  trabajadores: any[] = [];
  empresas: any[] = [];
  sucursales: any[] = [];
  estadoLiquidacion: any[] = [
    {value: '-1', text: 'PENDIENTE'},
    {value: '0', text: 'RECTIFICADA'},
    {value: '1', text: 'APROBADA'},
    {value: '2', text: 'RECHAZADA'},
    {value: '3', text: 'ELIMINADA'},
  ];
  estadoLiquidacionView: any[] = [];
  periodo: Date = new Date();

  // FILTERS
  trabajadorSelect: string = '';
  empresaSelect: string = '';
  sucursalSelect: string[] = [];
  estadoLiquidacionSelect: string[] = [];
  // DROPDOWS
  trabajadoresFilter: any[] = [];

  dataSource: MatTableDataSource<ListaLiquidacionAprobaciones>;
  displayedColumns: string[] = ['select', 'trabajador', 'periodo', 'estado',  'haberes', 'descuentos', 'liquido', 'acciones'];
  selection: SelectionModel<ListaLiquidacionAprobaciones>;
  // CONTEXT MENU
  desiciones: MenuItem[] = [];
  moreOptions: MenuItem[] = [];
  constructor(
    private solicitudLiquidacionService: SolicitudLiquidacionService,
    private liquidacionService: LiquidacionService,
    private trabajadorService: TrabajadoresService,
    private modalService: NgbModal,
    private auth: AuthService,
    private pdfLibService: PdflibService,
    private alert: AlertHelper,
  ) {
    this.user = this.auth.ObtenerinfoToken();
  }

  async ngOnInit() {
    this.obtenerPeriodoLocalStorage();
    this.loading = true;
    this.desiciones = [
      {
        label: 'Aprobar',
        icon: 'pi pi-thumbs-up',
        command: () => this.CrearAprobacion(true),
      },
      {
        label: 'Rechazar',
        icon: 'pi pi-thumbs-down',
        command: () => this.CrearAprobacion(false),
      }
    ];
    this.moreOptions = [
      {
        label: 'Cambiar decision',
        icon: 'pi pi-pencil',
        command: () => {
            this.cambiarDecision();
        }
      },
      {
        label: 'Ver historial de decisiones',
        icon: 'pi pi-history',
        command: () => {
            this.VerHistorialDecisiones();
        }
      }
    ];
    await this.TraerDatosFilter();
    await this.TraerAprobaciones();
    await this.TraerLiquidaciones();
  }

  // SELECCIONAR TODAS LAS FILAS
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    const registros = this.dataSource.data.filter(x => !x.estado && x.eliminado === 0);
    this.selection.select(...registros);
  }
  liquidacionesPendientes(){
    return this.liquidacionesFilter.some(x => !x.estado && x.eliminado === 0);
  }
  async recargarTabla(){
    await this.TraerDatosFilter();
    await this.TraerAprobaciones();
    await this.TraerLiquidaciones();
  }

  async initSolicitudesLiquidaciones(){
    this.obtenerPeriodoLocalStorage();
    await this.TraerDatosFilter();
    await this.TraerAprobaciones();
    await this.TraerLiquidaciones();
  }

  async TraerDatosFilter() {
    const res = await this.trabajadorService.TraerTrabajadoresAsync();
    this.trabajadores = res.data.sort((a: any, b: any) => {
      const nombresA = `${a.nombres.toUpperCase()} ${a.apellidos.toUpperCase()}`;
      const nombresB = `${b.nombres.toUpperCase()} ${b.apellidos.toUpperCase()}`;
      return nombresA.localeCompare(nombresB);
    });
    this.empresas = this.cargarEmpresas();
    this.sucursales = this.cargarSucursales();
    this.trabajadoresFilter = this.trabajadores.map(x => ({
      value: x.rut.toString(),
      text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
    })).sort((a, b) => a.text.localeCompare(b.text));
  }
  // OBTENER LAS EMPRESAS DESDE LOS TRABAJADORES Y QUITAR LAS REPETIDAS
  cargarEmpresas(): any[] {
    const empresas = this.trabajadores.map(x => ({
      value: x.Empresa_contratante.id.toString(),
      text: x.Empresa_contratante.nombre.toUpperCase()
    }));
    const empresasFilter = empresas.filter((x, index, self) => {
      return self.findIndex(t => t.value === x.value) === index;
    });
    return empresasFilter.sort((a, b) => a.text.localeCompare(b.text));
  }
  cargarSucursales(): any[] {
    const sucursales = this.trabajadores.map(x => ({
      value: x.Sucursal.id.toString(),
      text: x.Sucursal.nombre.toUpperCase()
    }));
    const sucursalesFilter = sucursales.filter((x, index, self) => {
      return self.findIndex(t => t.value === x.value) === index;
    });
    return sucursalesFilter.sort((a, b) => a.text.localeCompare(b.text));
  }
  FiltrarDatos(){    
    this.liquidacionesFilter = this.liquidaciones.filter(x => {
      const fecha = parseISO(x.fecha);
      return this.periodo.getMonth() === fecha.getMonth() 
        && this.periodo.getFullYear() === fecha.getFullYear()
    }).map(x => x.eliminado === 1 ? {...x, estado: 3} : x);
    this.trabajadoresFilter = this.trabajadores.map(x => ({
      value: x.rut.toString(), 
      text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
    }));
    if (this.empresaSelect && this.sucursalSelect?.length > 0) {
      this.trabajadoresFilter = this.trabajadores
        .filter(x => x.Empresa_contratante.id === parseInt(this.empresaSelect) && this.sucursalSelect.includes(x.Sucursal.id.toString()))
        .sort((a: any, b: any) => {
          const nombresA = `${a.nombres.toUpperCase()} ${a.apellidos.toUpperCase()}`;
          const nombresB = `${b.nombres.toUpperCase()} ${b.apellidos.toUpperCase()}`;
          return nombresA.localeCompare(nombresB);
        })
        .map(x => ({
          value: x.rut.toString(), 
          text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
        })
      );
      this.liquidacionesFilter = this.liquidacionesFilter.filter(x => this.trabajadoresFilter.some(y => parseInt(y.value) === x.fk_trabajador));
    }else if (this.empresaSelect) {
      this.trabajadoresFilter = this.trabajadores
        .filter(x => x.Empresa_contratante.id === parseInt(this.empresaSelect))
        .sort((a: any, b: any) => {
          const nombresA = `${a.nombres.toUpperCase()} ${a.apellidos.toUpperCase()}`;
          const nombresB = `${b.nombres.toUpperCase()} ${b.apellidos.toUpperCase()}`;
          return nombresA.localeCompare(nombresB);
        })
        .map(x => ({
          value: x.rut.toString(), 
          text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
        })
      );
      this.liquidacionesFilter = this.liquidacionesFilter.filter(x => this.trabajadoresFilter.some(y => parseInt(y.value) === x.fk_trabajador));
    }else if (this.sucursalSelect?.length > 0){
      this.trabajadoresFilter = this.trabajadores
        .filter(x => this.sucursalSelect.includes(x.Sucursal.id.toString()))
        .sort((a: any, b: any) => {
          const nombresA = `${a.nombres.toUpperCase()} ${a.apellidos.toUpperCase()}`;
          const nombresB = `${b.nombres.toUpperCase()} ${b.apellidos.toUpperCase()}`;
          return nombresA.localeCompare(nombresB);
        })
        .map(x => ({
          value: x.rut.toString(), 
          text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
        })
      );
      this.liquidacionesFilter = this.liquidacionesFilter.filter(x => this.trabajadoresFilter.some(y => parseInt(y.value) === x.fk_trabajador));
    }
    if (this.trabajadorSelect) {
      this.liquidacionesFilter = this.liquidacionesFilter.filter(x => x.fk_trabajador === parseInt(this.trabajadorSelect));
    }
    if (this.periodo) {
      this.liquidacionesFilter = this.liquidacionesFilter.filter(x => parseISO(x.fecha).getMonth() === this.periodo.getMonth() && parseISO(x.fecha).getFullYear() === this.periodo.getFullYear());
    }
    if (this.estadoLiquidacionSelect?.length > 0) {
      this.liquidacionesFilter = this.liquidacionesFilter.filter(x => {
        // BUSCAR EN EL ARRAY STRING DE ESTADOS SI HAY UN -1 Y CAMBIARLO POR NULL
        const estados = this.estadoLiquidacionSelect.map(y => {
          return y === '-1' ? null : parseInt(y);
        });
        return estados.includes(x.estado);
      });
    }
    // CONFIGURACION DE LA TABLA (MAT TABLE ANGULAR)
    this.dataSource = new MatTableDataSource<ListaLiquidacionAprobaciones>(this.liquidacionesFilter);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<ListaLiquidacionAprobaciones>(true, []);

    // CALCULAR TOTAL LIQUIDO
    this.totalliquido = this.liquidacionesFilter.filter(x => (x.eliminado as number) === 0).reduce((a, b) => a + b.alcanceLiquido, 0);
  }
  configurarDropdownEstado() {
     this.estadoLiquidacionView = this.estadoLiquidacion;
  }

  onMonthChandler(event: any) {
    this.periodo = event;
    this.guardarPeriodoLocalStorage();
    this.FiltrarDatos();
    this.configurarDropdownEstado();
  }

  async elegirDesicion(row: ListaLiquidacionAprobaciones, menu: any){
    this.idLiquidacion = row.id;
    await this.ObtenerIdDocumento();
  }

  async masOpciones(row: ListaLiquidacionAprobaciones, viewHistorialDecisiones: any, menu: any){
    this.idLiquidacion = row.id;
    this.liquidacionEliminada = row.eliminado;
    this.estadoCurrent = row.estado;
    this.viewHistorial= viewHistorialDecisiones;
    // OBTENEMOS EL ID DEL ULTIMO DOCUMENTO DE LA LIQUIDACION
    await this.ObtenerIdDocumento();
  }
  async ObtenerIdDocumento() {
    const documentos = await this.ObtenerDocumento(this.idLiquidacion);
    const documento = documentos[documentos.length - 1];
    this.iddocumento = documento.cp_documento;
  }

  async cambiarDecision() {
    if (this.estadoCurrent === 2) {
      // CREAR REGISTRO DE APROBACION
      const comentario = 'LIQUIDACIÓN APROBADA'
      await this.AddRegisterAprobacion(true, comentario);
      
      this.idLiquidacion = null;
      this.iddocumento = null;
      this.estadoCurrent = null;

      await this.TraerAprobaciones();
      await this.TraerLiquidaciones();
    }else{
      if (!this.verificarRol()) return;
      if (!this.verificarEstado()) return;
  
      // OBTENER LIQUIDACION
      const res = await this.liquidacionService.TraerPorIdAsync(this.idLiquidacion);
      if (!res.success) console.error(res.msg);
      const liquidacion = res.data;
  
      const formValues = await this.alertDecision(liquidacion);
      if (formValues.isConfirmed) {
        const comentario: string = (formValues.result[0] as string);
        await this.AddRegisterAprobacion(false, comentario);
        const pagadaFiniquito: boolean = (formValues.result[1] as boolean); 
        if (pagadaFiniquito) await this.actualizarPagadoFiniquitoLiquidacion(liquidacion);
        
        this.idLiquidacion = null;
        this.iddocumento = null;
        this.estadoCurrent = null;

        await this.TraerAprobaciones();
        await this.TraerLiquidaciones();
      }
    }
    
    // const aprobacion: Aprobacion = {
    //   comentario: comentario,
    //   estado: 2,
    //   idliquidacion: this.idLiquidacion,
    //   iddocumento: this.iddocumento,
    //   idaprobador: this.user.id,
    //   fecha: format(new Date(), 'yyyy-MM-dd'),
    // }
    
    // const textView = 'RECHAZAR';
    // const colorButton = '#d9342b';
    // Swal.fire({
    //   title: "Se va a cambiar la decisión a " + textView,
    //   text: "Ingrese un comentario para " + textView + " la liquidación",
    //   input: "textarea",
    //   // inputLabel: "Comentario",
    //   inputPlaceholder: "Ingrese un nuevo comentario",
    //   inputAttributes: {
    //     "aria-label": "Type your message here"
    //   },
    //   showCancelButton: true,
    //   cancelButtonText: "CANCELAR",
    //   confirmButtonText: textView,
    //   confirmButtonColor: colorButton,
    // }).then((result) => {
      
    // });
  }
  verificarEstado(): boolean {
    if (this.liquidacionEliminada === 1) {
      alert('La liquidación no se puede cambiar de decisón, ya que se encuentra eliminada');
      return false;
    }
    if (this.estadoCurrent === 0) {
      alert('La liquidación no se puede cambiar de decisón, ya que se enuentra rectificada');
      return false;
    }
    return true;
  }
  verificarRol(): boolean {
    if (this.user?.rol !== 'ADMIN' && this.user?.rol !== 'GERENCIA') {
      alert('No tiene permisos para realizar esta acción');
      return false;
    }
    return true;
  }
  VerHistorialDecisiones() {
    this.aprobacionLiquidacion = this.aprobaciones.filter(x => x.idliquidacion === this.idLiquidacion);
    const ref = this.modalService.open(this.viewHistorial, {size: 'md'});
    ref.dismissed.subscribe(async () => {
      await this.TraerAprobaciones();
      await this.TraerLiquidaciones();
    })
  }
  async CrearAprobacion(estado: boolean) {
    if (!this.verificarRol()) return;
    if (estado) {
      const comentario = 'LIQUIDACIÓN APROBADA';
      await this.AddRegisterAprobacion(estado, comentario);

      await this.TraerAprobaciones();
      await this.TraerLiquidaciones();
    }else{
      // OBTENER LIQUIDACION
      const res = await this.liquidacionService.TraerPorIdAsync(this.idLiquidacion);
      if (!res.success) console.error(res.msg);

      const liquidacion = res.data;
      const formValues = await this.alertDecision(liquidacion);
      // SI SE CONFIRMA, DISPARAR EVENTO PARA CREAR LA APROBACIÓN
      if (formValues.isConfirmed) {
        console.log({formValues});
        // POSICION 0: VALUE TEXTAREA
        const comentario: string = (formValues.result[0] as string);
        await this.AddRegisterAprobacion(estado, comentario);
        // VERIFICAR SI LA LIQUIDACION ES PAGADA EN EL FINIQUITO, PARA ACTUALIZAR
        const pagadaFiniquito: boolean = (formValues.result[1] as boolean); 
        if (pagadaFiniquito) await this.actualizarPagadoFiniquitoLiquidacion(liquidacion);

        await this.TraerAprobaciones();
        await this.TraerLiquidaciones();
      }
    }
  }
  // SE ACTUALIZA EL ESTADO DEL PAGADO FINIQUITO EN LA LIQUIDACION
  async actualizarPagadoFiniquitoLiquidacion(liquidacion: any): Promise<void> {
    liquidacion.pagada_finiquito = true;
    const resUpdate = await this.liquidacionService.ActualizarLiquidacion(liquidacion);
    if (resUpdate.success) {
      console.log('SE ACTUALIZO LA LIQUIDACION: FINIQUITO PAGADO TRUE ');        
    }else{
      console.error(resUpdate.msg);
    }
  }
  async alertDecision(liquidacion: any): Promise<any>{
    const textView = 'RECHAZAR';
    const colorButton = '#d9342b';
    const checkedString = liquidacion.pagada_finiquito === 1 ? "checked" : "";
    const {value: result, isConfirmed} = await Swal.fire({
      title: "Se va a " + textView + " la liquidación",
      text: "Ingrese un comentario para " + textView + " la liquidación",
      html: `
        <textarea id="swal-textarea" placeholder="Ingresar un comentario" class="form-control mb-2"></textarea>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" id="swal-checkbox" ${checkedString}>
          <label class="form-check-label" for="swal-checkbox">Pagada en el finiquito</label>
        </div>
      `,
      showCancelButton: true,
      cancelButtonText: "CANCELAR",
      confirmButtonText: textView,
      confirmButtonColor: colorButton,
      preConfirm: () => {
        return [
          (document.getElementById("swal-textarea") as HTMLTextAreaElement).value,
          (document.getElementById("swal-checkbox") as HTMLInputElement).checked,
        ];
      }
    });
    return {result, isConfirmed};
  }
  async AddRegisterAprobacion(estado: boolean, comentario: string) {
    const aprobacion: Aprobacion = {
      comentario: comentario,
      estado: estado ? 1 : 2,
      idliquidacion: this.idLiquidacion,
      iddocumento: this.iddocumento,
      idaprobador: this.user.id,
      fecha: format(new Date(), 'yyyy-MM-dd'),
    }
    const res = await this.solicitudLiquidacionService.CrearLiquidacion(aprobacion);
    if (!res.success) {
      this.alert.errorAlert(res.msg);
      return;
    }
    this.alert.success_small(res.msg);
  }
  async TraerAprobaciones() {
    const res = await this.solicitudLiquidacionService.ObtenerTodasAprobacionesLiquidacion();
    if (!res.success) {
      this.alert.errorAlert(res.msg);
      return;
    }
    this.aprobaciones = res.data;
  }
  async TraerLiquidaciones() {
    const res = await this.liquidacionService.ObtenerAllLiquidacionesAsync();
    if (!res.success) {
      this.alert.errorAlert(res.msg);
      return;
    }
    this.aprobaciones = this.aprobaciones?.sort((a:Aprobacion, b: Aprobacion) => b.id  - a.id);
    this.liquidaciones = res.data
      .filter(x => {
        const fecha = parseISO(x.fecha);
        return fecha.getTime() >= this.MIN_DATE.getTime();
      })
      .sort((a: ListaLiquidacion, b: ListaLiquidacion) => {
        const fechaTimA = parseISO(a.fecha).getTime();
        const fechaTimB = parseISO(b.fecha).getTime();
        return fechaTimB - fechaTimA;
      }).map(x => ({
        ...x,
        estado: this.aprobaciones.find(y => y.idliquidacion === x.id)?.estado??null,
      }))
      .filter(x =>  (x.eliminado && (this.user.rol === 'ADMIN' || this.user.rol === 'GERENCIA')) || !x.eliminado);
    // console.log({...this.liquidaciones});
    this.loading = false;
    this.FiltrarDatos();
    this.configurarDropdownEstado();
  }

  async ObtenerDocumento(id: number) {
    const res = await this.liquidacionService.TraerArchivoAsync(id);
    if (!res.success) {
      console.error(res.msg);
      this.alert.errorAlert('Hubo un error al obtener el archivo.');
      return;
    }
    return res.data;
  }
  
  async verDetalle(id: number, viewLiquidacionModal: any) {
    if (!this.verificarRol()) return;

    const documentos = await this.ObtenerDocumento(id);
    const documento = documentos[documentos.length - 1];
    const aprobaciones = this.aprobaciones.length > 0 ? this.aprobaciones
      ?.filter(x => x.idliquidacion === id) : null;

    const liquidacion = this.liquidaciones.find(x => x.id === id);
    const trabajador = liquidacion.fk_trabajadores;
    this.nombreTrabajador = String(trabajador.nombres + ' ' + trabajador.apellidos).toUpperCase();
    this.modalViewLiquidacion = {
      liquidacion: liquidacion,
      documento: `${environment.API}/liquidaciones/${documento.ruta}`,
      aprobaciones: aprobaciones?.length > 0 ? aprobaciones : null
    }
    const ref = this.modalService.open(viewLiquidacionModal, { fullscreen: true, scrollable: true });
    ref.dismissed.subscribe(async () => {
      await this.TraerAprobaciones();
      await this.TraerLiquidaciones();
    });
  }
  async verLiquidacion(liquidacion: any){
    const id = liquidacion.id;
    const estado = liquidacion.estado;
    const documentos = await this.ObtenerDocumento(id);
    // OBTENER ULTIMO ELEMENTO DEL ARRAY DE DOCUMENTOS
    const documento = documentos[documentos.length - 1];
    const ruta = `${environment.API}/liquidaciones/${documento.ruta}`;
    switch (estado) {
      case 1:
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaBlob(ruta, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 2:
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(ruta, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break;
      default:
        window.open(`${environment.API}/liquidaciones/` + ruta);
        break;
    }
    // window.open(`${environment.API}/liquidaciones/${documento.ruta}`, '_blank');
  }
  guardarPeriodoLocalStorage() {
    const year = getYear(this.periodo);
    const month = getMonth(this.periodo);
    localStorage.setItem('periodoLiquidacion', JSON.stringify({ year, month }));
  }
  obtenerPeriodoLocalStorage() {
    const periodo = JSON.parse(localStorage.getItem('periodoLiquidacion'));
    if (periodo) {
      const { year, month } = periodo;
      this.periodo = new Date(year, month, 1);
    }
  }
 async AllDesicion(desicion: boolean){
    if (!this.verificarRol()) return;
    const seleccionados: any[] = this.selection.selected ;
    if (desicion) {
      const comentario = 'LIQUIDACIÓN APROBADA';
      this.CrearAprobacionBulk(1, comentario, seleccionados);      
    }else{
      Swal.fire({
        title: "Se va a RECHAZAR las liquidaciones",
        text: "Ingrese un comentario para RECHAZAR las liquidaciones",
        input: "textarea",
        inputPlaceholder: "Ingrese un comentario",
        showCancelButton: true,
        cancelButtonText: "CANCELAR",
        confirmButtonText: 'RECHAZAR',
        confirmButtonColor: '#d9342b',
      }).then((result) => {
        if (result.isConfirmed) {
          const comentario = result.value;
          this.CrearAprobacionBulk(2, comentario, seleccionados);
        }
      });
    }
  }
  // METODO PARA CREAR APROBACIONES EN BULK
  async CrearAprobacionBulk(estado: number, comentario: string, seleccionados: any[]) {
      const aprobaciones = [];
      for (const seleccionado of seleccionados) {
        // OBTENER EL ID DEL ULTIMO DOCUMENTO DE LA LIQUIDACION
        const documentos = await this.ObtenerDocumento(seleccionado.id);
        const documento = documentos[documentos.length - 1];
        // CREAR OBJETO APROBACION
        const aprobacion: Aprobacion = {
          comentario: comentario,
          estado: estado,
          idliquidacion: seleccionado.id,
          iddocumento: documento.cp_documento,
          idaprobador: this.user.id,
          fecha: format(new Date(), 'yyyy-MM-dd'),
        }
        // AGREGAR A ARRAY DE APROBACIONES
        aprobaciones.push(aprobacion);
      }
      // CREAR LAS APROBACIONES
      const res = await this.solicitudLiquidacionService.bulkCreateAprobaciones(aprobaciones);
      if (!res.success) {
        this.alert.errorAlert(res.msg);
        return;
      }
      await this.TraerAprobaciones();
      await this.TraerLiquidaciones();
      this.alert.success_small(res.msg);
  }

  // METODO DE PRUEBA PARA VISUALIZAR EL PDF
  AgregarFirma(){
    // const urlPDF = 'http://localhost:3000/liquidaciones/39dc2bf5-40cc-4cc3-a371-95482bd077a2.pdf';
    // const urlFirma = 'https://i.ibb.co/YBTGppm/Diego.jpg';
    // this.pdfLibService.agregarFirma(urlPDF, urlFirma);
    // this.pdfLibService.agregarMarcaAgua(urlPDF, 'RECHAZADA');
  }
}
