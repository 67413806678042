<div>
    <button mat-button class="button_color" (click)="open(content)">
    <span style="color: white">Agregar Usuario </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Agregar Usuario</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombre Completo</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput required name="usuario.nombre_usuario" [(ngModel)]="usuario.nombre_usuario" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input type="text" maxlength="50" minlength="10" matInput required name="usuario.email_usuario" [(ngModel)]="usuario.email_usuario" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input type="password" maxlength="50" minlength="6" matInput required name="usuario.clave_usuario" [(ngModel)]="usuario.clave_usuario" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Confirmar Password</mat-label>
                    <input type="password" maxlength="50" minlength="6" matInput required name="usuario.clave_usuario_confirm" [(ngModel)]="usuario.clave_usuario_confirm" />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Rol</mat-label>
                  <mat-select name="usuario.id_rol" required [(ngModel)]="usuario.id_rol">
                    <mat-option *ngFor="let rol of filteredRoles" [value]="rol.id">
                      {{ rol.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Estado Usuario</mat-label>
                    <mat-select name="usuario.estado_usuario" required [(value)]="usuario.estado_usuario">
                        <mat-option value="1">Activo</mat-option>
                        <mat-option value="0">Inactivo</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
        <button type="button" class="btn btn-outline-success" (click)="agregar()">
      Guardar
    </button>
    </div>
</ng-template>