<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput  (keyup)="applyFilter($event)" #input>
</mat-form-field>

<div class="mat-elevation-z8">
<table mat-table [dataSource]="dataSource" matSort >

    <ng-container matColumnDef="Folio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Folio </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.folio}} </td>
    </ng-container>
    
    <ng-container matColumnDef="Inicio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Inicio</th>
        <td  class="padding-table" mat-cell *matCellDef="let element">⏩ &nbsp; {{element.f_start}} </td>
    </ng-container>

    <ng-container matColumnDef="Termino">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Termino</th>
        <td mat-cell *matCellDef="let element"> ⏹&nbsp; {{ element.f_end}} </td>
    </ng-container>

    <ng-container matColumnDef="Habiles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>D. Habiles </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> ✅&nbsp; {{ element.habiles}}</td>
    </ng-container>


    <ng-container matColumnDef="Inhabiles">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>D. Inhabiles </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> 🚫&nbsp; {{ element.inhabiles}}</td>
    </ng-container>



    <ng-container matColumnDef="Pendiente">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>D. Pendientes </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> ♻︎&nbsp;{{ element.pendiente}}</td>
    </ng-container>
    <ng-container matColumnDef="Periodo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Periodo </th>
        <td class="padding-table" mat-cell *matCellDef="let element">📅&nbsp; {{ element.periodo }}</td>
    </ng-container>
    
    <ng-container matColumnDef="Acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td class="padding-table" mat-cell *matCellDef="let element">
            <button *ngIf="element.estado" (click)="Descargar(element.id)" mat-button class="button_color_primary" 
                style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">search</mat-icon>
            </button>&nbsp;
            <button (click)="Borrar(element.id)" mat-raised-button color="warn">
                <mat-icon style="color: white">delete</mat-icon>
            </button>
            <!-- <button (click)="Borrar(element)" mat-raised-button color="warn" [disabled]="element.pendienteSolicitud || loading"
                [pTooltip]="element.pendienteSolicitud ? 'Esperando aprobación' : ''" tooltipPosition="bottom">
                <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
                <mat-icon style="color: white" *ngIf="loading">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </button> -->
            <button 
            *ngIf="!element.estado"
            (click)="open(content,element.id)"
             mat-button
             style="background-color:red"
            >
            <mat-icon style="color: white">file_upload</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator
[pageSizeOptions]="[10, 25, 50, 100, 1]"
showFirstLastButtons
aria-label="Seleccionar pagina de Vacaciones."
>
</mat-paginator>
</div>



<!-- MODAL PARA subir Comprobante de vacacion -->
<ng-template style="border: 0px; z-index: 100" #content let-modal> 
      
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Cargar Comprobantes de Vacaciones</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <app-agregar-vacaciones [id]='id'></app-agregar-vacaciones>

</ng-template>