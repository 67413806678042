import { NgModule } from "@angular/core"
import { CommonModule } from '@angular/common'
import { MaterialModule } from 'src/app/material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MainPlanillaPagosComponent } from "./main-planilla-pagos/main-planilla-pagos.component"
import { AgregarPlanillaPagosComponent } from "./agregar-planilla-pagos/agregar-planilla-pagos.component"
import { ListaPlanillaPagosComponent } from "./lista-planilla-pagos/lista-planilla-pagos.component"
import { MatNativeDateModule } from "@angular/material/core"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatCardModule } from "@angular/material/card"

@NgModule({
    declarations:[
        MainPlanillaPagosComponent,
        AgregarPlanillaPagosComponent,
        ListaPlanillaPagosComponent
    ],

    imports:[
        CommonModule,
        MaterialModule,
        FormsModule,
        MatCardModule,
        MatDatepickerModule,        
        MatNativeDateModule,
        ReactiveFormsModule
    ],
    exports: [
    MainPlanillaPagosComponent,
    AgregarPlanillaPagosComponent,
    ListaPlanillaPagosComponent
    ]
})
export class PlanillaModule{ }
