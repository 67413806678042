<div class="row pl-2">
    <div class="col-2">
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Buscar</mat-label>
            <input matInput [(ngModel)]="textoBuscar" (keyup)="applyFilter()" #input>
        </mat-form-field>
    </div>
    <div class="col-2 d-flex align-items-center">
        <div class="col-12">
            <div class="p-inputgroup">
                <button type="button" (click)="CambiarMes('LEFT')" pButton pRipple 
                    icon="pi pi-angle-left" styleClass="p-button"></button>    
                <p-calendar [(ngModel)]="periodo" view="month" dateFormat="mm/yy" styleClass="w-100"
                    (onClose)="FiltrarDatos()" [readonlyInput]="true" appendTo="body"></p-calendar>
                <button type="button" (click)="CambiarMes('RIGHT')" pButton pRipple 
                    icon="pi pi-angle-right" styleClass="p-button"></button>      
            </div>
        </div>
    </div>
    
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="posicion">
        <th mat-header-cell *matHeaderCellDef> Rut </th>
        <td mat-cell *matCellDef="let element"> {{element.fk_trabajador | formatoRut }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.TrabajadorMovimiento.nombres | uppercase}}
            {{element.TrabajadorMovimiento.apellidos | uppercase}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="tipomovimiento">
        <th mat-header-cell *matHeaderCellDef> Tipo Movimiento </th>
        <td mat-cell *matCellDef="let element"> {{element.TiposMovimiento.nombre}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="tipoevento">
        <th mat-header-cell *matHeaderCellDef> Tipo Evento </th>
        <td mat-cell *matCellDef="let element"> {{element.TiposEvento.nombre}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="fechainicio">
        <th mat-header-cell *matHeaderCellDef> Fecha Inicio</th>
        <td mat-cell *matCellDef="let element"> {{element.fecha_inicio | date: 'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="fechafinal">
        <th mat-header-cell *matHeaderCellDef> Fecha Final</th>
        <td mat-cell *matCellDef="let element"> {{element.fecha_final | date: 'dd/MM/yyyy'}} </td>
      </ng-container> -->

    <ng-container matColumnDef="periodo">
        <th mat-header-cell *matHeaderCellDef> Periodo</th>
        <td mat-cell *matCellDef="let element"> {{element.periodo | date: 'MM/yyyy'}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="rutpagadora">
        <th mat-header-cell *matHeaderCellDef> Rut Pagadora</th>
        <td mat-cell *matCellDef="let element"> {{element.rutpagadora}} </td>
      </ng-container> -->

    <ng-container matColumnDef="acciones" stickyEnd>
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let element" class="mat-column-right">
            <button (click)="open(content, element.fk_trabajador, element.id)" mat-button
                class="button_color_primary mr-1">
                <mat-icon style="color: white">edit</mat-icon>
            </button>
            <button (click)="Eliminar(element.id)" mat-button class="button_color_primary" style="background-color:red">
                <mat-icon style="color: white">delete</mat-icon>
            </button>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
</mat-paginator>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Editar Movimiento de Personal</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="form-group">

            <div class="row align-items-center">
                <div class="col-12 col-sm-3 text-center mb-2">Trabajador</div>
                <div class="col-12 col-sm-6">
                    <p-dropdown [options]="trabajadores" [(ngModel)]="selectedValueTrabajador" optionLabel="text"
                        optionValue="value" placeholder="Seleccione trabajador"
                        (ngModelChange)="onTrabajadorChange($event)" [showClear]="true" [filter]="true"
                        filterBy="text"></p-dropdown>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-12 col-sm-3 text-center mb-2">Periodo </div>
                <div class="col-12 col-sm-3">

                    <div class="card flex justify-content-center">
                        <p-calendar [(ngModel)]="date" view="month" dateFormat="mm/yy"
                            [readonlyInput]="true"></p-calendar>
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-12 col-sm-3 text-center mb-2">Tipo de movimiento </div>
                <div class="col-12 col-sm-5">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Tipo</mat-label>
                        <mat-select placeholder="Seleccione un tipo" [(ngModel)]="selectedValueTipo"
                            (ngModelChange)="onTipoChange($event)">
                            <mat-option [value]="tipo.id" *ngFor="let tipo of tiposMovimiento">
                                {{ tipo.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row align-items-center" *ngIf="selectedValueTipo==2">
                <div class="col-12 col-sm-3 text-center mb-2">AFP </div>
                <div class="col-12 col-sm-4">
                    <strong>
                        <p> {{ afp }} </p>
                    </strong>
                </div>
            </div>

            <div class="row align-items-center" *ngIf="selectedValueTipo==3">
                <div class="col-12 col-sm-3 text-center mb-2">ISAPRE </div>
                <div class="col-12 col-sm-4">
                    <strong>
                        <p> {{ prevision }} </p>
                    </strong>
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-12 col-sm-3 text-center mb-2">Evento </div>
                <div class="col-12 col-sm-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Evento</mat-label>
                        <mat-select placeholder="Seleccione un nombre" [(ngModel)]="selectedValue"
                            (ngModelChange)="onAfpChange($event)">
                            <mat-option [value]="evento.codigo" *ngFor="let evento of eventosFilter">
                                {{ evento.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row align-items-center" *ngIf="selectedValue!=2">
                <div class="col-12 col-sm-3 text-center mb-2">Fecha Inicio </div>
                <div class="col-12 col-sm-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Fecha Inicio</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Selecciona una fecha"
                            [(ngModel)]="selectedFechaInicio">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="row align-items-center"
                *ngIf="selectedValue == 2 || selectedValue == 3 || selectedValue == 4 || selectedValue == 7 || selectedValue == 11">
                <div class="col-12 col-sm-3 text-center mb-2">Fecha Termino</div>
                <div class="col-12 col-sm-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Fecha Fin</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Selecciona una fecha"
                            [(ngModel)]="selectedFechaTermino">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="row align-items-center" *ngIf="selectedValue==3 ">
                <div class="col-12 col-sm-3 text-center mb-2">Rut Entidad Pagadora</div>
                <div class="col-12 col-sm-5">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Rut</mat-label>
                        <input matInput placeholder="Placeholder" (keyup)="onKeyEntidadPagadora($event)"
                            [(ngModel)]="selectedRut">
                    </mat-form-field>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="Editar()">Editar</button>
    </div>
</ng-template>