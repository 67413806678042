import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';

import { MainTipoprestamoComponent } from './main-tipoprestamo/main-tipoprestamo.component';
import { AgregarTipoprestamoComponent } from './agregar-tipoprestamo/agregar-tipoprestamo.component';
import { ListaTipoprestamoComponent } from './lista-tipoprestamo/lista-tipoprestamo.component';


@NgModule({
  declarations: [
    MainTipoprestamoComponent,
    AgregarTipoprestamoComponent,
    ListaTipoprestamoComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
  ]
})
export class TipoprestamoModule { }
