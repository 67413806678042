<div class="animate__animated animate__slideInUp">
  <mat-card>
    <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
      Comprobantes de Vacaciones
    </h3>
    <app-main-buscar-filter [num_trab_finiquitados]="num_trab_finiquitados" (textSearch)="applyFilter($event)"
      (viewFiniquitadosEmit)="viewDatosFiniquitados($event)">
    </app-main-buscar-filter>
    <!-- <div class="row container-fluid">
    <div class="mr-2">
      <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input matInput  (keyup)="applyFilter($event)" #input>
      </mat-form-field>
    </div>
    <div class="d-flex align-items-center">
      <mat-checkbox (change)="viewDatosFiniquitados()" [(ngModel)]="viewFiniquitados" color="warn">
        <span [matBadge]="num_trab_finiquitados <= 99 
                ? num_trab_finiquitados 
                : '+99'" 
                matBadgeOverlap="false">Mostrar trabajadores finiquitados</span>
      </mat-checkbox>
    </div>
  </div> -->

    <table mat-table id="tabla" [dataSource]="dataSource" #tabla matSort class="mat-elevation-z8"
      (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.rut | formatoRut}} </td>
      </ng-container>

      <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE COMPLETO </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> {{element.nombres | uppercase}}
          {{element.apellidos | uppercase}} </td>
      </ng-container>

      <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA</th>
        <td class="padding-table" mat-cell *matCellDef="let element">{{ element.Empresa_contratante.nombre | uppercase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="estado_contrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CONTRATACIÓN</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          <span
            [ngClass]="{'bg-danger p-1 rounded text-light': element.estado_contrato === 'Finiquitado'}">{{element.estado_contrato
            | uppercase}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          <button mat-button (click)="open2(contenido,element.rut)" class="button_color_primary"
            style="background-color:rgb(37, 199, 228)">
            <mat-icon style="color: white">archive</mat-icon>
          </button> &nbsp;
          <!-- <button mat-button (click)="open(content,element.rut)" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">file_upload</mat-icon>
            </button> &nbsp; -->
          <button mat-button (click)="open3(content3,element.rut)" class="button_color_primary"
            style="background-color:rgb(37, 199, 228)">
            <mat-icon style="color: white">add</mat-icon>
          </button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1]" showFirstLastButtons
      aria-label="Seleccionar pagina de Trabajadores.">
    </mat-paginator>
  </mat-card>
</div>

<!-- MODAL PARA LISTAR Comprobantes de vacaciones POR RUT  l-->
<ng-template style="border: 0px; z-index: 100" #contenido let-modal2>
  <div class="modal-header" style="text-align: center">
    <mat-card-title>Visualizar Comprobantes de Vacaciones</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-listar-vacaciones [rut]='RutTrabajador'></app-listar-vacaciones>
  </div>
</ng-template>

<!-- MODAL PARA subir Comprobante de vacacion -->
<ng-template style="border: 0px; z-index: 100" #content let-modal>

  <div class="modal-header" style="text-align: center">
    <mat-card-title> Cargar Comprobantes de Vacaciones</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-agregar-vacaciones [rut]='RutTrabajador'></app-agregar-vacaciones>

</ng-template>


<!-- MODAL PARA LA GENERACION DEL COMPROBANTE DE VACACIONES llama a la -->
<ng-template style="border: 0px; z-index: 100" #content3 let-modal3>

  <div class="modal-header" style="text-align: center">
    <mat-card-title>Generar Comprobante de Vacaciones</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-generar-vacaciones [rut]='RutTrabajador'></app-generar-vacaciones>

</ng-template>