import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { debounceTime, Subject } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { ImplementosService } from 'src/app/pages/services/implementos/implementos.service';

import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-implementos',
  templateUrl: './ver-implementos.component.html',
  styleUrls: ['./ver-implementos.component.css'],
  providers: [DatePipe]
})
export class VerImplementosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @Input() rut!: any;


  debouncer: Subject<boolean> = new Subject();
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  responses: string[] = [];

  constructor(

    private modalService: NgbModal,
    private alert: AlertHelper,
    private implementosService: ImplementosService,
    private auth: AuthService


  ) {


    this.uploader.onCompleteAll = () => {
     // console.log('se termino de enviar ');
    };
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.proceso = 1;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {

      let user= this.auth.ObtenerinfoToken();
      form.append('estado', this.implementos_update.estadoI);
      form.append('id', this.implementos_update.id);
      form.append('fk_trabajador', this.updateI.fk_trabajador);
      form.append('userAt', user.nombre);

    };

  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    //console.log('fileOverBase');
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
    //console.log('fileOverAnother');
  }



  /////—————  Variables   ————-\\\\\\


  // [rut]='RutTrabajador'


  dataSource: any;
  RutTrabajador: any;
  ImplementosList: any;
  UniformeList: any;
  mostrar: any = 0;
  checked: boolean = false;
  implementos_update: any = [];
  proceso: any = 0;
  modalReferencee = null;
  modalReference = null;
  listo = "LISTO";
  info_documento: any;
  /////————— Variable BD AND TABLA   ————-\\\\\\


  displayedColumns: string[] = [
    'fecha',
    'equipo',
    'modelo',
    'equipoo',
    'modeloo',
    'equipo2',
    'modelo2',
    'equipo3',
    'modelo3',
    'equipo4',
    'modelo4',
    'equipo5',
    'modelo5',
    'observacion',
    'estado',
    'acciones',
  ]

  updateI: any = {
    id: 0,
    estado: "",
    fk_trabajador: "",



  };




  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\



  onChangeSlide($event: any) {

    if ($event.checked) {
      this.mostrar = 1
      this.checked = true
    }
    else {
      this.mostrar = 0
      this.checked = false

    }
  }



  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  buscar() {


    this.implementosService.TraerPorRut(this.rut).subscribe(
      (res) => {


        this.dataSource = [];
        // this.buscar();

        this.ImplementosList = res.data.filter((x:any)=>{ return x.estado != 0});

        this.dataSource = new MatTableDataSource(this.ImplementosList);

        this.dataSource.paginator = this.paginator

      });
  }



  eliminar(contenido: any, id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara de las Implementos",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.implementosService.eliminar(id).subscribe(res => {
          if (res.success == true) {

            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
            this.ngOnInit();
          } else {
            this.alert.error_small(res.msg!)
          }
        });
      }
    })

  }


//MODAL PARA SUBIR EL ARCHIVO
  update(up: any, rut: any) {
    
    this.implementos_update = rut;


    this.modalReferencee = this.modalService.open(up, { size: 'lg' })


  }

  cerrarModal() {
    this.modalService.dismissAll();
  }


//MODAL PARA EDITAR EL IMPLEMENTO
  updated(upp: any, rut: any) {


    this.implementos_update = rut;

    this.modalReference = this.modalService.open(upp, { size: 'lg' })

  }


  actualizar() {
    

    this.implementosService.actualizar(this.implementos_update).subscribe(res => {

    
     // console.log(res);


      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalReference.close();
        this.debouncer.next(true);
        this.ngOnInit();


      } else {
        this.alert.error_small(res.msg!)
      }

    })

  }

  Descargar(id: any) {
    webPreferences: {
      webSecurity: false
    }
    this.implementosService.TraerImplementos(id).subscribe(
      (res) => {
        if (res.success == true) {

          let ultimo =res.data.length-1
          this.info_documento =res.data[ultimo]; 

          // console.log(this.info_documento);
          window.open(`${environment.API}/implementos/` + this.info_documento.ruta);

        }
        else {
          this.alert.errorAlert("No se encuentran archivos");
        }
      }

    );
  }


  public uploader: FileUploader = new FileUploader({

    url: `${environment.API}/rrhh/implementos/documento`,
    itemAlias: 'implementos',

  });

  guardar() {


    this.implementos_update.estadoI = "LISTO";


    this.implementosService.actualizarEstado(this.implementos_update).subscribe(res => {


      this.alert.success_small(res.msg)
      //  this.modalService.dismissAll();

      this.ngOnInit();
      this.debouncer.next(true);



    });


    this.uploader.uploadAll();


   
  }


  mandaD() {

    this.implementosService.TraerPorRut(this.rut).subscribe(
      (res) => {


        this.updateI.id = res.data[0].id;
        this.updateI.estado = res.data[0].estadoI;


      })
  }



  //CODIGO PARA TENER UNA TALBA EN DONDE SE PUEDE ELIMINAR Y AGREGAR COLUMANAS 


  // columnsToDisplay: string[] = this.displayedColumns.slice();



  // addColumn() {
  //   const randomColumn = Math.floor(Math.random() * this.displayedColumns.length);
  //   this.columnsToDisplay.push(this.displayedColumns[randomColumn]);
  // }
  // removeColumn() {
  //   if (this.columnsToDisplay.length) {
  //     this.columnsToDisplay.pop();
  //   }
  // }

  // shuffle() {
  //   let currentIndex = this.columnsToDisplay.length;
  //   while (0 !== currentIndex) {
  //     let randomIndex = Math.floor(Math.random() * currentIndex);
  //     currentIndex -= 1;

  //     // Swap
  //     let temp = this.columnsToDisplay[currentIndex];
  //     this.columnsToDisplay[currentIndex] = this.columnsToDisplay[randomIndex];
  //     this.columnsToDisplay[randomIndex] = temp;
  //   }
  // }


  /////—————AL INICIAR EL MODULO   ————-\\\\\\

  ngOnInit(): void {


    this.updateI.fk_trabajador = this.rut;

    // console.log(this.updateI);
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });

    this.buscar();


    this.uploader.onCompleteItem = (


      item: any,
      response: any,
      status: any,
      headers: any
    ) => {

      let result = JSON.parse(response);



      if (result.response == true) {
        //console.log("el archivo se guardo con el nombre ", result.nombre);

        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        })
        this.modalReferencee.close();

      } else {

        // this.responses.push(response);

        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',

        })

      }
    }

  }

}
