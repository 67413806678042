import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';

@Injectable({
  providedIn: 'root'
})
export class HaberesService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

  GetAll_Haberes():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/haberes/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async GetAll_HaberesAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/haberes/`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener los haberes'} as RequestResponse;
    }
  }

  Obtener_Haberes(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/haberes/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async Obtener_HaberesAsync(id:number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/haberes/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener los haberes'} as RequestResponse;
    }
  }


  Create_Haber(entity:any){                   
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/haberes/crear`,entity)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Actualizar_Haber(haber:any,id:any) {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/haberes/actualizar/${id}`,haber)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  Eliminar_Haberes(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/haberes/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }









  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
