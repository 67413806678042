import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Firma } from 'src/app/shared/models/firmas.interface';

import { FileUploader } from 'ng2-file-upload';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FirmasService } from 'src/app/pages/services/firmas.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-editar-firmas',
  templateUrl: './editar-firmas.component.html',
  styleUrls: ['./editar-firmas.component.css']
})
export class EditarFirmasComponent implements OnInit {
  @Output() actualizarTabla: EventEmitter<boolean> = new EventEmitter();
  @Input() firma: Firma;
  firmaEdit: Firma;
  fileSrc: any;
  cambiarImagen: boolean;

  // UPLOADER
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  URL_BASE_API: string = environment.API;

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private firmaService: FirmasService,
  ) {
    this.cambiarImagen = false;
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.firma) {
      this.firmaEdit = {...this.firma};
      this.configurarUploader();
    }
  }
  configurarUploader() {
    this.hasAnotherDropZoneOver = false;
    this.hasBaseDropZoneOver = false;
    
    this.uploader = new FileUploader({
      url: `${environment.API}/rrhh/firmas/actualizarFirmaImag/${this.firma.id}`,
      itemAlias: 'firma',
      method: 'PUT',
    });
    
    this.uploader.onAfterAddingFile = (file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileSrc = e.target.result;
      };
      reader.readAsDataURL(file._file);
      this.cambiarImagen = true;
      file.withCredentials = false;
    };
    // SE CREA EL FORMULARIO PARA MANDARLO AL BACKEND
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('nombre', this.firmaEdit.nombre);
      form.append('rutaImg', '');
    };
    // SI LA SUBIDA SALE CORRECTA
    this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
      this.alert.success_small('Firma editada correctamente.');
      this.actualizarTabla.emit(true);
      this.modalService.dismissAll();
    }
    // SI LA SUBIDA SALE MAL
    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.alert.error_small('Error al editada firma.');
    }
    // SE COMPLETA LA SUBIDA (CON ERRORES O SIN ERRORES)
    this.uploader.onCompleteAll = () => {
      this.limpiarformulario();
    }
  }
  // METODOS PARA EL DROP FILE
  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  OpenEditar(context: any) {
    this.modalService.open(context, { size: 'lg' });
  }

  async EditarFirma(){
    if (this.firmaEdit.nombre.trim() === '') {
      this.alert.error_small('Nombre de la firma esta vacio.');
      return;
    }
    if (this.cambiarImagen) this.uploader.uploadAll();
    else{
      const res = await this.firmaService.updateFirma(this.firmaEdit);
      if (res.success) {
        this.alert.success_small('Firma actualizada correctamente.');
        this.actualizarTabla.emit(true);
        this.modalService.dismissAll();
      }else{
        this.alert.error_small(res.msg);
      }
    }
  }
  // SE DESHACE EL CAMBIO DE FIRMA
  quitarImagen(){
    this.fileSrc = undefined;
    this.cambiarImagen = false;
  }

  limpiarformulario() {
    this.uploader.clearQueue();
    this.fileSrc = undefined;
    this.cambiarImagen = false;
  }

}