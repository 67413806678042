<div class="animate__animated animate__fadeInRight">

    <div class="col">
        <mat-card class="mat_card_trabajadores">
            <h1 class>Modulo de Banco</h1>
        </mat-card>
    </div>

    <mat-card class="mat-elevation-z5 mat_card_trabajadores_content">
        <mat-tab-group class="card_group_trabajadores">
            <mat-tab label="Trabajadores">
                <div style="
                margin-top: 10px;">
                </div>
                <div style="
                margin-top: 10px;">
                </div>
                <app-lista-banco></app-lista-banco>
            </mat-tab>
        </mat-tab-group>
    </mat-card>


</div>