<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.requisito }}</td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripcion de la Competencias y Caracteristicas</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        {{ element.requisitos.descripcion | uppercase }}
      </td>
    </ng-container> 
        
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        <button  (click)="open(content, element.requisito)"
          mat-button class="button_color_primary">
          <mat-icon style="color: white">edit</mat-icon>
        </button>
        &nbsp;
        <button (click)="Eliminar_Requisito(content, element.requisito)"
          mat-button class="button_color_primary" style="background-color:red">
          <mat-icon style="color: white">delete</mat-icon>
        </button>          
      </td>        
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Seleccionar pagina de usuarios."
  >
  </mat-paginator>
</div>


<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Actualizar competencia y caracteristicas</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Descripcion dela competencia y caracteristica</mat-label>
          <textarea type="textarea" rows="7" matInput maxlength="1000" name="requisitos.descripcion" [(ngModel)]="update_requisitos.descripcion"></textarea>
        </mat-form-field>
      </div> 
    </div>      
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="Actualizar_Requisito()"
    >
      Actualizar
    </button>
  </div>
</ng-template>