<div>
  <button mat-button class="button_color_primary" (click)="open(content)">
    <span style="color: white">Agregar Adelanto </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar nuevo adelanto</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <!-- Fila 1 -->
      <div class="row">
        <!-- Fecha -->
        <!-- Proceso de prueba -->
        <div class="col-12">
          <p-dropdown [options]="trabajadores_aux" placeholder="Seleccione un trabajador" optionLabel="nombre"
            emptyFilterMessage="Sin resultados de trabajadores" (onChange)="ObtenerTrabajador($event)"
            [showClear]="true" [filter]="true" filterBy="nombre" [style]="{ width: '100%' }"></p-dropdown>
          <!-- 
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Trabajador</mat-label> 
              <mat-select name="adelanto.rut" (selectionChange)="ObtenerTrabajador($event)">
                <mat-option *ngFor="let element of trabajadores_aux" [value]="element.rut">
                  {{element.nombres}} {{element.apellidos}}
                </mat-option>
              </mat-select>
            </mat-form-field> 
          -->
          <!--
          <div class="form-group col-md-4">
            <h4 style="color:#2a3f54"><b>Rut</b></h4>
            <input style="width: inherit;" type="text" name="rut" pInputText [disabled]="true" [(ngModel)]="TrabajadorSeleccionado.rut" />
        </div>
        <div class="form-group col-md-4">
            <h4 style="color:#2a3f54"><b>Nombres</b></h4>
            <input style="width: inherit;" type="text" name="nombres" pInputText [disabled]="true" [(ngModel)]="TrabajadorSeleccionado.nombres" />
        </div> -->
        </div>

      </div>

      <div *ngIf="render==0">

        <mat-progress-bar mode="buffer"></mat-progress-bar>

      </div>

      <div *ngIf="render==1">

        <div class="row">

          <div class="col">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Fecha adelanto</mat-label>
              <input matInput [matDatepicker]="picker" (dateChange)="obtener_fecha($event)" name="adela.fechaInicioContrato"
                [(ngModel)]="adela.fechaInicioContrato" [ngModelOptions]="{standalone: true}">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>

          </div>
          <!-- <div class="col">
              <mat-form-field class="example-full-width " appearance="outline">
                <mat-label>Empresas</mat-label>
                 <mat-select [(ngModel)]="adelantos.empresa" name="adelanto.empresa"
                  (selectionChange)="ObtenerEmpresa($event)">
                  <mat-option class="example-full-width " *ngFor="let element of empresas_aux" [value]="element.id">
                    {{element.nombre}}
                  </mat-option>
                </mat-select> 
              </mat-form-field>
            </div> -->
          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>BANCO</mat-label>

              <input type="text" minlength="3" maxlength="10" matInput name="adela.banco" [(ngModel)]="bancoo" readonly
                [value]="adela.banco" [ngModelOptions]="{standalone: true}" />

            </mat-form-field>
          </div>


        </div>


        <div class="row">
          <!-- <div class="col">
                <mat-form-field class="example-full-width " appearance="outline">
                  <mat-label>Banco</mat-label>
                  <mat-select [(ngModel)]="adelantos.banco" name="adelanto.banco"
                    (selectionChange)="ObtenerBanco($event)">
                    <mat-option  class="example-full-width " *ngFor="let element of bancos_aux" [value]="element.id">
                      {{element.nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
    
              </div> -->
          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>EMPRESA</mat-label>

              <input type="text" minlength="3" maxlength="150" matInput name="adela.empresa" [(ngModel)]="empresaa" readonly
                [value]="adela.empresa" [ngModelOptions]="{standalone: true}" />

            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Tipo cuenta</mat-label>
              <input type="text" minlength="3" maxlength="150" matInput name="adela.tipoCuenta" [(ngModel)]="adela.tipoCuenta"
                readonly [value]="adela.tipoCuenta" [ngModelOptions]="{standalone: true}" />
              <!-- 
                <mat-select [(ngModel)]="adelantos.banco" name="adelanto.banco"
                  (selectionChange)="ObtenerBanco($event)">
                  <mat-option class="example-full-width "  [value]="element.id">
                    {{element.nombre}}
                  </mat-option>
                </mat-select>
              -->

            </mat-form-field>
          </div>
        </div>

        <!-- Fila 5 -->
        <div class="row">
          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>N° cuenta</mat-label>
              <input type="number" minlength="3" maxlength="150" matInput name="adela.numeroCuenta"
                [(ngModel)]="adela.numeroCuenta" readonly [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Monto Adelanto</mat-label>
              <input type="text" matInput name="adelantos.montoAdelanto" autonumeric [options]="optionsNumeric"
                [(ngModel)]="adela.montoAdelanto" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Fin de la condicion -->

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="agregar()">
      Guardar
    </button>
  </div>
</ng-template>