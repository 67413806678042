<div class="modal-body">
        
    <!-- <div *ngIf=" constt==1">
        <h1>
    
            Ya ingreso implementos a este usuario
         
        </h1>
    
    
    
    </div>
 -->

    <div *ngIf=" constt==0 || constt==1">

    <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Fecha Acta de Implementos </mat-label>
            <input matInput [matDatepicker]="picker" (dateChange)="obtener_fechaImplementos($event)" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUI #picker></mat-datepicker>
        </mat-form-field>
    </div>


    <div class="row">
        <div class="col">

            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Equipo </mat-label>
                <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.equipo" />
            </mat-form-field>

        </div>



        <div class="col">

            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Modelo o Nº Serie</mat-label>
                <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.modelo" />
            </mat-form-field>

        </div>
    </div>

    <div class="col ">
        <mat-slide-toggle #slidePass (change)="unoMas($event)">
        </mat-slide-toggle> Agregar otro equipo
    </div>

    <div *ngIf="mas==1">

        <div class="row">

            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.equipo2" />
                </mat-form-field>


            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.modelo2" />
                </mat-form-field>

            </div>

        </div>



    </div>


    <div class="col ">
        <mat-slide-toggle #slidePass (change)="unoMass($event)">
        </mat-slide-toggle> Agregar otro equipo
    </div>
    <div *ngIf="mass==1">

        <div class="row">

            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.equipo3" />
                </mat-form-field>


            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.modelo3" />
                </mat-form-field>

            </div>

        </div>
    </div>

    <div class="col ">
        <mat-slide-toggle #slidePass (change)="unoMasss($event)">
        </mat-slide-toggle> Agregar otro equipo
    </div>
    <div *ngIf="masss==1">

        <div class="row">

            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.equipo4" />
                </mat-form-field>


            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.modelo4" />
                </mat-form-field>

            </div>

        </div>

    </div>

    <div class="col ">
        <mat-slide-toggle #slidePass (change)="unoMassss($event)">
        </mat-slide-toggle> Agregar otro equipo
    </div>
    <div *ngIf="massss==1">


        <div class="row">

            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.equipo5" />
                </mat-form-field>


            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.modelo5" />
                </mat-form-field>

            </div>

        </div>


    </div>
    <div *ngIf="masssss==1">
        <div class="row">

            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.equipoo" />
                </mat-form-field>


            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.modeloo" />
                </mat-form-field>

            </div>

        </div>
    </div>

    <div class="col">

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Observacion</mat-label>
            <input type="text" minlength="0" maxlength="500" matInput [(ngModel)]="implementos.observacion" />
        </mat-form-field>

    </div>




    <button type="button" class="btn btn-outline-success" (click)="generarImplento()">Guardar
    </button>



</div>

</div>