import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { TipoPrestamo, RequestResponseGetAllTipoPrestamo, RequestResponseGetTipoPrestamo } from 'src/app/shared/models/tipoprestamo.interface';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TiposPrestamoService {
  constructor(
    private http: HttpClient, 
    private alert: AlertHelper,
  ) { }

  TraerTiposPrestamo(): Observable<RequestResponseGetAllTipoPrestamo> {
    return this.http.get<RequestResponseGetAllTipoPrestamo>(`${environment.API}/rrhh/tipoprestamo/`)
      .pipe(
        map((res:RequestResponseGetAllTipoPrestamo) => res),
        //catchError((err)=> this.handlerError(err))
      );
  }

  Crear_TipoPrestamo(tipo:TipoPrestamo): Observable<RequestResponse> {      
    return this.http
      .post<RequestResponseGetTipoPrestamo>(`${environment.API}/rrhh/tipoprestamo/crear`, tipo)
      .pipe(
        map((res:RequestResponseGetTipoPrestamo) => res),
        catchError((err)=> this.handlerError(err))
      );
  }

  Obtener_TipoPrestamo(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/tipoprestamo/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Eliminar_TipoPrestamo(id: number): Observable<RequestResponse>{
    return this.http.delete<RequestResponse>(`${environment.API}/rrhh/tipoprestamo/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError((error) => this.handlerError(error)),
      );
  }

  ActualizarTipoPrestamo(tprestamo:TipoPrestamo): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse>(`${environment.API}/rrhh/tipoprestamo/actualizar/${tprestamo.id}`,tprestamo)
    .pipe(
      map((res:RequestResponse) => res),
      catchError((err)=> this.handlerError(err))
    );
  }

  private handlerError(err: any):Observable<never>{
    const errorMessage = err ? `Error: ${err.error.msg}` : '';
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
