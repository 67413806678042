import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { debounceTime } from 'rxjs/operators';
import { Requisito } from 'src/app/shared/models/requisitos.interface';
import { RequisitoService } from 'src/app/pages/services/requisitos.service';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { MatSelectChange } from '@angular/material/select';


@Component({
  selector: 'app-agregar-conocimientos',
  templateUrl: './agregar-conocimientos.component.html',
  styleUrls: ['./agregar-conocimientos.component.css']
})
export class AgregarConocimientosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  @Input() list_cargos!:Cargo[];
  debouncer: Subject<boolean> = new Subject();  

  list_requisitos: any;
  id_cargo!: 0;
  nuevo_descripcion: any;

  requisitos={
    descripcion: "",    
    cargo:this.id_cargo,
    requisito: 0,
    id_cargo:this.id_cargo,
    perfil: 4
  }

  constructor(
    private alert:AlertHelper,
    private modalService:NgbModal,
    private requisitosService: RequisitoService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
  }

  selectedValue(event: MatSelectChange) {
    this.id_cargo = event.value;              
    this.requisitosService.GetAll_requisitoPorCargo(this.id_cargo).subscribe(
      (res) => {  
        
        // Se filtra los resultados donde el requisito perfil sea igual a 1 , es decir REQUISITO
        // es similar a las funciones lamba en python
        this.list_requisitos = res.data.filter((x:any)=>{ return x.requisitos.perfil===4;})
        //console.log(this.list_requisitos);
        //console.log("Traje esto al actualizar el select con el CARGO id:"+this.id_cargo);        
      }
    );  
  } 

  actualizarTabla(event:boolean){            
    this.requisitosService.GetAll_requisitoPorCargo(this.id_cargo).subscribe(
      (res) => { 
        // Se filtra los resultados donde el requisito perfil sea igual a 1 , es decir REQUISITO                                
        this.list_requisitos = res.data.filter((x:any)=>{ return x.requisitos.perfil===4;})
      }
    ); 
  }

  open(content:any){
    this.limpiar_Requisito();
    this.modalService.open(content);
  }

  validarRequisito(){
    if(this.nuevo_descripcion.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.');
      return false;
    }
    return true;
  }

  limpiar_Requisito(){
    this.nuevo_descripcion=""; 
  }


  agregar(){    
    if(!this.validarRequisito()){
      return;
    }        
    this.requisitos.cargo = this.id_cargo;
    this.requisitos.descripcion= this.nuevo_descripcion;
      this.requisitosService.Create_requisito(this.requisitos).subscribe((res:any)=>{  
        //console.log(res);      
        if (res.success==true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.limpiar_Requisito();
          this.debouncer.next( true );
          this.actualizarTabla(true);
        }else{
          this.alert.error_small(res.msg!)
        }
    })
    
  }


}
