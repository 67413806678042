<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
      <span style="color: white">Agregar Cargo </span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Agregar un nuevo cargo</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="row">
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Nombre del cargo</mat-label>
                        <input type="text" maxlength="50" minlength="3" matInput required name="cargos.nombre" [(ngModel)]="cargos.nombre" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Departamento</mat-label>
                        <input type="text" maxlength="50" minlength="3" matInput required name="cargos.jefe" [(ngModel)]="cargos.departamento" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Lugar</mat-label>
                        <input type="text" maxlength="150" minlength="3" matInput required name="cargos.lugar" [(ngModel)]="cargos.lugar" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sucursal</mat-label>
                        <mat-select name="cargos.sucursal" [(ngModel)]="cargos.sucursal" required>
                            <mat-option *ngFor="let sucursal of list_sucursales" [value]="sucursal.id">
                                {{ sucursal.nombre }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
              
            </div>
            <div class="row">
                <div class="col">
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Jornada</mat-label>
                        <input type="text" maxlength="50" minlength="3" matInput name="cargos.jornada" [(ngModel)]="cargos.jornada" />
                    </mat-form-field> -->
                    <mat-form-field class="example-full-width " appearance="outline">
                        <mat-label>Jornada</mat-label>
                        <mat-select required name="cargos.jornadaLaboral" [(ngModel)]="cargos.jornadaLaboral" >
                            <mat-option class="example-full-width " *ngFor="let jornada of jornadas" [value]="jornada.id">
                                {{jornada.descripcion}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
             
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">Cancelar</span>
      </button>
        <button type="button" class="btn btn-outline-success" (click)="agregar()">
        Guardar
      </button>
    </div>
</ng-template>