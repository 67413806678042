<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Cartas Amonestación
</h3>

<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.fk_trabajador | formatoRut }} </td>
    </ng-container>

    <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> FECHA </th>
        <td mat-cell *matCellDef="let element">{{ element.FechaEmision | date:'dd/MM/yyyy'}}</td>
    </ng-container>


    <ng-container matColumnDef="grado">
        <th mat-header-cell *matHeaderCellDef> GRADO </th>
        <td mat-cell *matCellDef="let element"> {{ element.Grado | uppercase }} </td>
    </ng-container>

    <ng-container matColumnDef="motivo" style="width: 30%;">
        <th mat-header-cell *matHeaderCellDef> MOTIVO </th>
        <td mat-cell *matCellDef="let element"> {{ element.Motivo  | uppercase }} </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button (click)="Descargar(element.id)" mat-button class="button_color_primary mr-2" style="background-color:rgb(37, 199, 228)">
              <mat-icon style="color: white">search</mat-icon>
            </button>
            <button (click)="Borrar(element.id)" mat-raised-button color="warn" [disabled]="element.pendienteSolicitud || loading" 
                [pTooltip]="element.pendienteSolicitud ? 'Esperando aprobación' : ''" tooltipPosition="bottom">
                <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
                <mat-icon style="color: white" *ngIf="loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>