import { Component,EventEmitter,Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NacionalidadesService } from 'src/app/pages/services/nacionalidades.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Nacionalidades } from 'src/app/shared/models/nacionalidades.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-nacionalidades',
  templateUrl: './lista-nacionalidades.component.html',
  styleUrls: ['./lista-nacionalidades.component.css']
})
export class ListaNacionalidadesComponent implements OnInit {

  @Input() nacionalidades_List:Nacionalidades[]=[];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  update_nacionalidades: Nacionalidades = {
    id:0,
    nombre:"",       
  };

  displayedColumns: string[] = ['id', 'nombre', 'actions'];
  dataSource = new MatTableDataSource<Nacionalidades>(this.nacionalidades_List);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert:AlertHelper,
    private modalService: NgbModal,
    private nacionalidadesService: NacionalidadesService
  ) { }

  ngOnInit(): void {    
      this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );        
      });
  }

    ngOnChanges(changes: SimpleChanges) {      
    if(changes.nacionalidades_List!=undefined){      
      if (!changes.nacionalidades_List.firstChange) {        
        this.dataSource = new MatTableDataSource<Nacionalidades>(this.nacionalidades_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  open(content:any,id:number) {    
    this.update_nacionalidades.id=id
    this.nacionalidadesService.Obtener_nacionalidad(id).subscribe(res =>{      
      this.update_nacionalidades.nombre=res.data.nombre;      
      })

    this.limpiar_nacionalidades();
    this.modalService.open(content);

  }

  limpiar_nacionalidades(){
    this.update_nacionalidades.nombre="";      
  }

  Actualizar_Nacionalidades(){  
    if(this.update_nacionalidades.nombre.trim().length==0){
      this.alert.error_small('Los campos no pueden estar vacios.')
      return
    }

    this.nacionalidadesService.Actualizar_nacionalidad(this.update_nacionalidades).subscribe(res =>{
      if (res.success==true) {
            this.alert.success_small(res.msg!)
            this.modalService.dismissAll();
            this.limpiar_nacionalidades();
            this.debouncer.next( true );
          }else{
            this.alert.error_small(res.msg!)
          }
    })

    
  }

  Eliminar_Nacionalidad(content:any,id:number){
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el nacionalidad",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

    this.nacionalidadesService.Eliminar_nacionalidad(id).subscribe(res =>{
      if (res.success==true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next( true );
          }else{
            this.alert.error_small(res.msg!)
          }
    })   
  }
}) 
  }

    //FITRAL
    applyFilter(event: Event) {
      //Funcion para filtar cualquier columna por el texto buscado
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  
  
}
