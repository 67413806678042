//import { Prevision } from 'src/app/shared/models/prevision.interface';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { MostrarDocumentoTrabajadorComponent } from '../mostrar-documento-trabajador/mostrar-documento-trabajador.component';

import { ListaTrabajadores } from 'src/app/shared/models/trabajadores/trabajadores.interface';
import * as XLSX from 'xlsx';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSelectChange } from '@angular/material/select';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { BancoService } from 'src/app/pages/services/banco/banco.service';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { getLastDigitOfRut } from 'rutlib';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { MatDialog } from '@angular/material/dialog';

const pdfMake = require('pdfmake/build/pdfmake.js');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lista-trabajadores',
  templateUrl: './lista-trabajadores.component.html',
  styleUrls: ['./lista-trabajadores.component.css']
})


export class ListaTrabajadoresComponent implements OnInit {

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private empresaService: EmpresaService,
    private trabajadoresService: TrabajadoresService,
    private bancoService: BancoService,
    private auth: AuthService,
    private dialog: MatDialog
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 18, 11, 31);
  }

  listaEmpresas: any[] = [];
  empresaSelect: string = '';

  renderciudad = 1;
  trabajador: any;
  ciudades: any;
  sucursales: any;
  cargos: any;
  empresas: any;
  afps: any;
  previsiones: any;
  estados: any;
  nacionalidades: any;
  nacimiento: any;
  ciudad_seleccionada = 0;
  sucursal_seleccionada = 0;
  cargo_seleccionado = 0;
  empresa_contratante_seleccionada = 0;
  afp_seleccionada = 0;
  prevision_salud_seleccionada = 0;
  estado_civil_seleccionada = 0;
  nacionalidad_seleccionada = 0;
  fecha_nacimiento_seleccionada = 0;
  banco_seleccionado: any;
  tipo_cuenta_seleccionada: any;
  tipo_jornada_seleccionada: any;
  tipo_discapacidad_seleccionada: any;
  tipo_afc_seleccionada: any;
  sexo_seleccionada: string;
  bancos: any;
  pase_movilidad: boolean;
  licencia_conducir: boolean;
  num_trab_finiquitados: number = 0;
  rut_trabajador: any;
  modalReference: any = null;
  comunaA: any;
  regionA: any;
  viewFiniquitados: boolean = false;
  textSearch: string = '';

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  optionsNumber = {
    digitGroupSeparator: '', // usar punto como separador de miles
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  }
  optionsDecimal = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 3, // si mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  }

  sexos: any[] = [
    { value: "M", text: "Masculino" },
    { value: "F", text: "Femenino" },
  ]

  dias_semana: any[] = [
    { value: 5, texto: "5 dias a la semana" },
    { value: 6, texto: "6 dias a la semana" },
    { value: 0, texto: "Menos de 5 dias a la semana" }
  ]
  
  tramos: any[] = [
    {value: 0, text: 'Sin información'},
    {value: 1, text: 'Tramo 1'}, //A
    {value: 2, text: 'Tramo 2'}, //B
    {value: 3, text: 'Tramo 3'}, //C
    {value: 4, text: 'Tramo 4'}, //D
  ]

  //Gestion Larga de Regiones
  region = [
    { id: 1, nombre: "Arica y Parinacota" },
    { id: 2, nombre: "Tarapacá" },
    { id: 3, nombre: "Antofagasta" },
    { id: 4, nombre: "Atacama" },
    { id: 5, nombre: "Coquimbo" },
    { id: 6, nombre: "Valparaíso" },
    { id: 7, nombre: "Metropolitana de Santiago" },
    { id: 8, nombre: "Libertador Gral. Bernardo OHiggins" },
    { id: 9, nombre: "Maule" },
    { id: 10, nombre: "Ñuble" },
    { id: 11, nombre: "Biobío" },
    { id: 12, nombre: "Araucanía" },
    { id: 13, nombre: "Los Ríos" },
    { id: 14, nombre: "Lagos" },
    { id: 15, nombre: "Aisén del Gral. Carlos Ibáñez del Campo" },
    { id: 16, nombre: "Magallanes y de la Antártica Chilena" }
  ]
  //Gestion Larga de Comunas
  comunaXV = [
    { id: 1, nombre: 'Arica', region: 'XV' },
    { id: 2, nombre: 'Camarones', region: 'XV' },
    { id: 3, nombre: 'General Lagos', region: 'XV' },
    { id: 4, nombre: 'Putre', region: 'XV' }
  ]

  comunaI = [
    { id: 1, nombre: 'Alto Hospicio', region: 'I' },
    { id: 2, nombre: 'Camiña', region: 'I' },
    { id: 3, nombre: 'Colchane', region: 'I' },
    { id: 4, nombre: 'Huara', region: 'I' },
    { id: 5, nombre: 'Iquique', region: 'I' },
    { id: 6, nombre: 'Pica', region: 'I' },
    { id: 7, nombre: 'Pozo Almonte', region: 'I' }
  ]

  comunaII = [
    { id: 1, nombre: "Antofagasta", region: 'II' },
    { id: 2, nombre: "Calama", region: 'II' },
    { id: 3, nombre: "María Elena", region: 'II' },
    { id: 4, nombre: "Mejillones", region: 'II' },
    { id: 5, nombre: "Ollagüe", region: 'II' },
    { id: 6, nombre: "San Pedro de Atacama", region: 'II' },
    { id: 7, nombre: "Sierra Gorda", region: 'II' },
    { id: 8, nombre: "Taltal", region: 'II' },
    { id: 9, nombre: "Tocopilla", region: 'II' },
  ]
  comunaIII = [
    { id: 1, nombre: "Alto del Carmen", region: 'III' },
    { id: 2, nombre: "Caldera", region: 'III' },
    { id: 3, nombre: "Chañaral", region: 'III' },
    { id: 4, nombre: "Copiapó", region: 'III' },
    { id: 5, nombre: "Diego de Almagro", region: 'III' },
    { id: 6, nombre: "Freirina", region: 'III' },
    { id: 7, nombre: "Huasco", region: 'III' },
    { id: 8, nombre: "Tierra Amarilla", region: 'III' },
    { id: 9, nombre: "Vallenar", region: 'III' },
  ]
  comunaIV = [
    { id: 1, nombre: "Andacollo", region: 'IV' },
    { id: 2, nombre: "Canela", region: 'IV' },
    { id: 3, nombre: "Combarbalá", region: 'IV' },
    { id: 4, nombre: "Coquimbo", region: 'IV' },
    { id: 5, nombre: "Illapel", region: 'IV' },
    { id: 6, nombre: "La Higuera", region: 'IV' },
    { id: 7, nombre: "La Serena", region: 'IV' },
    { id: 8, nombre: "Los Vilos", region: 'IV' },
    { id: 9, nombre: "Monte Patria", region: 'IV' },
    { id: 10, nombre: "Ovalle", region: 'IV' },
    { id: 11, nombre: "Paiguano", region: 'IV' },
    { id: 12, nombre: "Punitaqui", region: 'IV' },
    { id: 13, nombre: "Río Hurtado", region: 'IV' },
    { id: 14, nombre: "Salamanca", region: 'IV' },
    { id: 15, nombre: "Vicuña", region: 'IV' },
  ]
  comunaV = [
    { id: 1, nombre: "Algarrobo", region: 'V' },
    { id: 2, nombre: "Cabildo", region: 'V' },
    { id: 3, nombre: "Calera", region: 'V' },
    { id: 4, nombre: "Calle Larga", region: 'V' },
    { id: 5, nombre: "Cartagena", region: 'V' },
    { id: 6, nombre: "Casablanca", region: 'V' },
    { id: 7, nombre: "Catemu", region: 'V' },
    { id: 8, nombre: "Concón", region: 'V' },
    { id: 9, nombre: "El Quisco", region: 'V' },
    { id: 10, nombre: "El Tabo", region: 'V' },
    { id: 11, nombre: "Hijuelas", region: 'V' },
    { id: 12, nombre: "Isla de Pascua", region: 'V' },
    { id: 13, nombre: "Juan Fernández", region: 'V' },
    { id: 14, nombre: "La Cruz", region: 'V' },
    { id: 15, nombre: "La Ligua", region: 'V' },
    { id: 16, nombre: "Limache", region: 'V' },
    { id: 17, nombre: "Llaillay", region: 'V' },
    { id: 18, nombre: "Los Andes", region: 'V' },
    { id: 19, nombre: "Nogales", region: 'V' },
    { id: 20, nombre: "Olmué", region: 'V' },
    { id: 21, nombre: "Panquehue", region: 'V' },
    { id: 22, nombre: "Papudo", region: 'V' },
    { id: 23, nombre: "Petorca", region: 'V' },
    { id: 24, nombre: "Puchuncaví", region: 'V' },
    { id: 25, nombre: "Putaendo", region: 'V' },
    { id: 26, nombre: "Quillota", region: 'V' },
    { id: 27, nombre: "Quilpué", region: 'V' },
    { id: 28, nombre: "Quintero", region: 'V' },
    { id: 29, nombre: "Rinconada", region: 'V' },
    { id: 30, nombre: "San Antonio", region: 'V' },
    { id: 31, nombre: "San Esteban", region: 'V' },
    { id: 32, nombre: "San Felipe", region: 'V' },
    { id: 33, nombre: "Santa María", region: 'V' },
    { id: 34, nombre: "Santo Domingo", region: 'V' },
    { id: 35, nombre: "Valparaíso", region: 'V' },
    { id: 36, nombre: "Villa Alemana", region: 'V' },
    { id: 37, nombre: "Viña del Mar", region: 'V' },
    { id: 38, nombre: "Zapallar", region: 'V' },
  ]
  comunaXIII = [
    { id: 1, nombre: "Alhué", region: 'XIII' },
    { id: 2, nombre: "Buin", region: 'XIII' },
    { id: 3, nombre: "Calera de Tango", region: 'XIII' },
    { id: 4, nombre: "Cerrillos", region: 'XIII' },
    { id: 5, nombre: "Cerro Navia", region: 'XIII' },
    { id: 6, nombre: "Colina", region: 'XIII' },
    { id: 7, nombre: "Conchalí", region: 'XIII' },
    { id: 8, nombre: "Curacaví", region: 'XIII' },
    { id: 9, nombre: "El Bosque", region: 'XIII' },
    { id: 10, nombre: "El Monte", region: 'XIII' },
    { id: 11, nombre: "Estación Central", region: 'XIII' },
    { id: 12, nombre: "Huechuraba", region: 'XIII' },
    { id: 13, nombre: "Independencia", region: 'XIII' },
    { id: 14, nombre: "Isla de Maipo", region: 'XIII' },
    { id: 15, nombre: "La Cisterna", region: 'XIII' },
    { id: 16, nombre: "La Florida", region: 'XIII' },
    { id: 17, nombre: "La Granja", region: 'XIII' },
    { id: 18, nombre: "La Pintana", region: 'XIII' },
    { id: 19, nombre: "La Reina", region: 'XIII' },
    { id: 20, nombre: "Lampa", region: 'XIII' },
    { id: 21, nombre: "Las Condes", region: 'XIII' },
    { id: 22, nombre: "Lo Barnechea", region: 'XIII' },
    { id: 23, nombre: "Lo Espejo", region: 'XIII' },
    { id: 24, nombre: "Lo Prado", region: 'XIII' },
    { id: 25, nombre: "Macul", region: 'XIII' },
    { id: 26, nombre: "Maipú", region: 'XIII' },
    { id: 27, nombre: "María Pinto", region: 'XIII' },
    { id: 27, nombre: "Melipilla", region: 'XIII' },
    { id: 28, nombre: "Ñuñoa", region: 'XIII' },
    { id: 29, nombre: "Padre Hurtado", region: 'XIII' },
    { id: 30, nombre: "Paine", region: 'XIII' },
    { id: 31, nombre: "Pedro Aguirre Cerda", region: 'XIII' },
    { id: 32, nombre: "Peñaflor", region: 'XIII' },
    { id: 33, nombre: "Peñalolén", region: 'XIII' },
    { id: 34, nombre: "Pirque", region: 'XIII' },
    { id: 35, nombre: "Providencia", region: 'XIII' },
    { id: 36, nombre: "Pudahuel", region: 'XIII' },
    { id: 37, nombre: "Puente Alto", region: 'XIII' },
    { id: 38, nombre: "Quilicura", region: 'XIII' },
    { id: 39, nombre: "Quinta Normal", region: 'XIII' },
    { id: 40, nombre: "Recoleta", region: 'XIII' },
    { id: 41, nombre: "Renca", region: 'XIII' },
    { id: 42, nombre: "San Bernardo", region: 'XIII' },
    { id: 43, nombre: "San Joaquín", region: 'XIII' },
    { id: 44, nombre: "San José de Maipo", region: 'XIII' },
    { id: 45, nombre: "San Miguel", region: 'XIII' },
    { id: 46, nombre: "San Pedro", region: 'XIII' },
    { id: 47, nombre: "San Ramón", region: 'XIII' },
    { id: 48, nombre: "Santiago", region: 'XIII' },
    { id: 49, nombre: "Talagante", region: 'XIII' },
    { id: 50, nombre: "Tiltil", region: 'XIII' },
    { id: 51, nombre: "Vitacura", region: 'XIII' },
  ]
  comunaVI = [
    { id: 1, nombre: "Chimbarongo", region: 'VI' },
    { id: 2, nombre: "Chépica", region: 'VI' },
    { id: 3, nombre: "Codegua", region: 'VI' },
    { id: 4, nombre: "Coinco", region: 'VI' },
    { id: 5, nombre: "Coltauco", region: 'VI' },
    { id: 6, nombre: "Doñihue", region: 'VI' },
    { id: 7, nombre: "Graneros", region: 'VI' },
    { id: 8, nombre: "La Estrella", region: 'VI' },
    { id: 9, nombre: "Las Cabras", region: 'VI' },
    { id: 10, nombre: "Litueche", region: 'VI' },
    { id: 11, nombre: "Lolol", region: 'VI' },
    { id: 12, nombre: "Machalí", region: 'VI' },
    { id: 13, nombre: "Malloa", region: 'VI' },
    { id: 14, nombre: "Marchihue", region: 'VI' },
    { id: 16, nombre: "Nancagua", region: 'VI' },
    { id: 17, nombre: "Navidad", region: 'VI' },
    { id: 18, nombre: "Olivar", region: 'VI' },
    { id: 19, nombre: "Palmilla", region: 'VI' },
    { id: 20, nombre: "Paredones", region: 'VI' },
    { id: 21, nombre: "Peralillo", region: 'VI' },
    { id: 22, nombre: "Peumo", region: 'VI' },
    { id: 23, nombre: "Pichidegua", region: 'VI' },
    { id: 24, nombre: "Pichilemu", region: 'VI' },
    { id: 25, nombre: "Placilla", region: 'VI' },
    { id: 26, nombre: "Pumanque", region: 'VI' },
    { id: 27, nombre: "Quinta de Tilcoco", region: 'VI' },
    { id: 28, nombre: "Rancagua", region: 'VI' },
    { id: 29, nombre: "Rengo", region: 'VI' },
    { id: 30, nombre: "Requínoa", region: 'VI' },
    { id: 31, nombre: "San Fernando", region: 'VI' },
    { id: 32, nombre: "San Francisco de Mostazal", region: 'VI' },
    { id: 33, nombre: "San Vicente de Tagua Tagua", region: 'VI' },
    { id: 34, nombre: "Santa Cruz", region: 'VI' },
  ]
  comunaVII = [
    { id: 1, nombre: "Cauquenes", region: 'VII' },
    { id: 2, nombre: "Chanco", region: 'VII' },
    { id: 3, nombre: "Colbún", region: 'VII' },
    { id: 4, nombre: "Constitución", region: 'VII' },
    { id: 5, nombre: "Curepto", region: 'VII' },
    { id: 6, nombre: "Curicó", region: 'VII' },
    { id: 7, nombre: "Empedrado", region: 'VII' },
    { id: 8, nombre: "Hualañé", region: 'VII' },
    { id: 9, nombre: "Licantén", region: 'VII' },
    { id: 10, nombre: "Linares", region: 'VII' },
    { id: 11, nombre: "Longaví", region: 'VII' },
    { id: 12, nombre: "Maule", region: 'VII' },
    { id: 13, nombre: "Molina", region: 'VII' },
    { id: 14, nombre: "Parral", region: 'VII' },
    { id: 15, nombre: "Pelarco", region: 'VII' },
    { id: 16, nombre: "Pelluhue", region: 'VII' },
    { id: 17, nombre: "Pencahue", region: 'VII' },
    { id: 18, nombre: "Rauco", region: 'VII' },
    { id: 19, nombre: "Retiro", region: 'VII' },
    { id: 20, nombre: "Romeral", region: 'VII' },
    { id: 21, nombre: "Río Claro", region: 'VII' },
    { id: 22, nombre: "Sagrada Familia", region: 'VII' },
    { id: 23, nombre: "San Clemente", region: 'VII' },
    { id: 24, nombre: "San Javier de Loncomilla", region: 'VII' },
    { id: 25, nombre: "San Rafael", region: 'VII' },
    { id: 26, nombre: "Talca", region: 'VII' },
    { id: 27, nombre: "Teno", region: 'VII' },
    { id: 28, nombre: "Vichuquén", region: 'VII' },
    { id: 29, nombre: "Villa Alegre", region: 'VII' },
    { id: 30, nombre: "Yerbas Buenas", region: 'VII' },
  ]
  comunaXVI = [
    { id: 1, nombre: "Bulnes", region: 'XVI' },
    { id: 2, nombre: "Chillán Viejo", region: 'XVI' },
    { id: 3, nombre: "Chillán", region: 'XVI' },
    { id: 4, nombre: "Cobquecura", region: 'XVI' },
    { id: 5, nombre: "Coelemu", region: 'XVI' },
    { id: 6, nombre: "Coihueco", region: 'XVI' },
    { id: 7, nombre: "El Carmen", region: 'XVI' },
    { id: 8, nombre: "Ninhue", region: 'XVI' },
    { id: 9, nombre: "Ñiquén", region: 'XVI' },
    { id: 10, nombre: "Pemuco", region: 'XVI' },
    { id: 11, nombre: "Pinto", region: 'XVI' },
    { id: 12, nombre: "Portezuelo", region: 'XVI' },
    { id: 13, nombre: "Quillón", region: 'XVI' },
    { id: 14, nombre: "Quirihue", region: 'XVI' },
    { id: 15, nombre: "Ránquil", region: 'XVI' },
    { id: 16, nombre: "San Carlos", region: 'XVI' },
    { id: 17, nombre: "San Fabián", region: 'XVI' },
    { id: 18, nombre: "San Ignacio", region: 'XVI' },
    { id: 19, nombre: "San Nicolás", region: 'XVI' },
    { id: 20, nombre: "Treguaco", region: 'XVI' },
    { id: 21, nombre: "Yungay", region: 'XVI' },
  ]
  comunaVIII = [
    { id: 1, nombre: "Alto Biobío", region: 'VIII' },
    { id: 2, nombre: "Antuco", region: 'VIII' },
    { id: 3, nombre: "Arauco", region: 'VIII' },
    { id: 4, nombre: "Cabrero", region: 'VIII' },
    { id: 5, nombre: "Cañete", region: 'VIII' },
    { id: 6, nombre: "Chiguayante", region: 'VIII' },
    { id: 7, nombre: "Concepción", region: 'VIII' },
    { id: 8, nombre: "Contulmo", region: 'VIII' },
    { id: 9, nombre: "Coronel", region: 'VIII' },
    { id: 10, nombre: "Curanilahue", region: 'VIII' },
    { id: 11, nombre: "Florida", region: 'VIII' },
    { id: 12, nombre: "Hualpén", region: 'VIII' },
    { id: 13, nombre: "Hualqui", region: 'VIII' },
    { id: 14, nombre: "Laja", region: 'VIII' },
    { id: 15, nombre: "Lebu", region: 'VIII' },
    { id: 16, nombre: "Los Álamos", region: 'VIII' },
    { id: 17, nombre: "Los Ángeles", region: 'VIII' },
    { id: 18, nombre: "Lota", region: 'VIII' },
    { id: 19, nombre: "Mulchén", region: 'VIII' },
    { id: 20, nombre: "Nacimiento", region: 'VIII' },
    { id: 21, nombre: "Negrete", region: 'VIII' },
    { id: 22, nombre: "Penco", region: 'VIII' },
    { id: 23, nombre: "Quilaco", region: 'VIII' },
    { id: 24, nombre: "Quilleco", region: 'VIII' },
    { id: 25, nombre: "San Pedro de la Paz", region: 'VIII' },
    { id: 26, nombre: "San Rosendo", region: 'VIII' },
    { id: 27, nombre: "Santa Bárbara", region: 'VIII' },
    { id: 28, nombre: "Santa Juana", region: 'VIII' },
    { id: 29, nombre: "Talcahuano", region: 'VIII' },
    { id: 30, nombre: "Tirúa", region: 'VIII' },
    { id: 31, nombre: "Tomé", region: 'VIII' },
    { id: 32, nombre: "Tucapel", region: 'VIII' },
    { id: 33, nombre: "Yumbel", region: 'VIII' },
  ]
  comunaIX = [
    { id: 1, nombre: "Angol", region: 'IX' },
    { id: 2, nombre: "Carahue", region: 'IX' },
    { id: 3, nombre: "Cholchol", region: 'IX' },
    { id: 4, nombre: "Collipulli", region: 'IX' },
    { id: 5, nombre: "Cunco", region: 'IX' },
    { id: 6, nombre: "Curacautín", region: 'IX' },
    { id: 7, nombre: "Curarrehue", region: 'IX' },
    { id: 8, nombre: "Ercilla", region: 'IX' },
    { id: 9, nombre: "Freire", region: 'IX' },
    { id: 10, nombre: "Galvarino", region: 'IX' },
    { id: 11, nombre: "Gorbea", region: 'IX' },
    { id: 12, nombre: "Lautaro", region: 'IX' },
    { id: 13, nombre: "Loncoche", region: 'IX' },
    { id: 14, nombre: "Lonquimay", region: 'IX' },
    { id: 15, nombre: "Los Sauces", region: 'IX' },
    { id: 16, nombre: "Lumaco", region: 'IX' },
    { id: 17, nombre: "Melipeuco", region: 'IX' },
    { id: 18, nombre: "Nueva Imperial", region: 'IX' },
    { id: 19, nombre: "Padre las Casas", region: 'IX' },
    { id: 20, nombre: "Perquenco", region: 'IX' },
    { id: 21, nombre: "Pitrufquén", region: 'IX' },
    { id: 22, nombre: "Pucón", region: 'IX' },
    { id: 23, nombre: "Purén", region: 'IX' },
    { id: 24, nombre: "Renaico", region: 'IX' },
    { id: 25, nombre: "Saavedra", region: 'IX' },
    { id: 26, nombre: "Temuco", region: 'IX' },
    { id: 27, nombre: "Teodoro Schmidt", region: 'IX' },
    { id: 28, nombre: "Toltén", region: 'IX' },
    { id: 29, nombre: "Traiguén", region: 'IX' },
    { id: 30, nombre: "Victoria", region: 'IX' },
    { id: 31, nombre: "Vilcún", region: 'IX' },
    { id: 32, nombre: "Villarrica", region: 'IX' },
  ]
  comunaXIV = [
    { id: 1, nombre: "Corral", region: 'XIV' },
    { id: 2, nombre: "Futrono", region: 'XIV' },
    { id: 3, nombre: "La Unión", region: 'XIV' },
    { id: 4, nombre: "Lago Ranco", region: 'XIV' },
    { id: 5, nombre: "Lanco", region: 'XIV' },
    { id: 6, nombre: "Los Lagos", region: 'XIV' },
    { id: 7, nombre: "Mariquina", region: 'XIV' },
    { id: 8, nombre: "Máfil", region: 'XIV' },
    { id: 9, nombre: "Paillaco", region: 'XIV' },
    { id: 10, nombre: "Panguipulli", region: 'XIV' },
    { id: 11, nombre: "Río Bueno", region: 'XIV' },
    { id: 12, nombre: "Valdivia", region: 'XIV' },
  ]
  comunaX = [
    { id: 1, nombre: "Ancud", region: 'X' },
    { id: 2, nombre: "Calbuco", region: 'X' },
    { id: 3, nombre: "Castro", region: 'X' },
    { id: 4, nombre: "Chaitén", region: 'X' },
    { id: 5, nombre: "Chonchi", region: 'X' },
    { id: 6, nombre: "Cochamó", region: 'X' },
    { id: 7, nombre: "Curaco de Vélez", region: 'X' },
    { id: 8, nombre: "Dalcahue", region: 'X' },
    { id: 9, nombre: "Fresia", region: 'X' },
    { id: 10, nombre: "Frutillar", region: 'X' },
    { id: 11, nombre: "Futaleufú", region: 'X' },
    { id: 12, nombre: "Hualaihué", region: 'X' },
    { id: 13, nombre: "Llanquihue", region: 'X' },
    { id: 14, nombre: "Los Muermos", region: 'X' },
    { id: 15, nombre: "Maullín", region: 'X' },
    { id: 16, nombre: "Osorno", region: 'X' },
    { id: 17, nombre: "Palena", region: 'X' },
    { id: 18, nombre: "Puerto Montt", region: 'X' },
    { id: 19, nombre: "Puerto Octay", region: 'X' },
    { id: 20, nombre: "Puerto Varas", region: 'X' },
    { id: 21, nombre: "Puqueldón", region: 'X' },
    { id: 22, nombre: "Purranque", region: 'X' },
    { id: 23, nombre: "Puyehue", region: 'X' },
    { id: 24, nombre: "Queilén", region: 'X' },
    { id: 25, nombre: "Quellón", region: 'X' },
    { id: 26, nombre: "Quemchi", region: 'X' },
    { id: 27, nombre: "Quinchao", region: 'X' },
    { id: 28, nombre: "Río Negro", region: 'X' },
    { id: 29, nombre: "San Juan de la Costa", region: 'X' },
    { id: 30, nombre: "San Pablo", region: 'X' },
  ]
  comunaXI = [
    { id: 1, nombre: 'Aisén', region: 'XI' },
    { id: 2, nombre: 'Chile Chico', region: 'XI' },
    { id: 3, nombre: 'Cisnes', region: 'XI' },
    { id: 4, nombre: 'Cochrane', region: 'XI' },
    { id: 5, nombre: 'Coyhaique', region: 'XI' },
    { id: 6, nombre: 'Guaitecas', region: 'XI' },
    { id: 7, nombre: 'Lago Verde', region: 'XI' },
    { id: 8, nombre: 'OHiggins', region: 'XI' },
    { id: 9, nombre: 'Río Ibáñez', region: 'XI' },
    { id: 10, nombre: 'Tortel', region: 'XI' }
  ]
  comunaXII = [
    { id: 1, nombre: 'Antártica', region: 'XII' },
    { id: 2, nombre: 'Cabo de Hornos (Ex Navarino)', region: 'XII' },
    { id: 3, nombre: 'Laguna Blanca', region: 'XII' },
    { id: 4, nombre: 'Natales', region: 'XII' },
    { id: 5, nombre: 'Porvenir', region: 'XII' },
    { id: 6, nombre: 'Primavera', region: 'XII' },
    { id: 7, nombre: 'Punta Arenas', region: 'XII' },
    { id: 8, nombre: 'Río Verde', region: 'XII' },
    { id: 9, nombre: 'San Gregorio', region: 'XII' },
    { id: 10, nombre: 'Timaukel', region: 'XII' },
    { id: 11, nombre: 'Torres del Paine', region: 'XII' }
  ]




  estado_contrato = [
    { id: 1, valor: 'Contrato a Plazo Fijo' },
    { id: 2, valor: 'Contrato Indefinido' },
    { id: 3, valor: 'Contrato por Obra o Faena' }
  ]

  tipo_cuentas = [
    { id: 1, valor: 'Cuenta Corriente' },
    { id: 2, valor: 'Cuenta Vista/Rut' },
    { id: 3, valor: 'Cuenta de Ahorro' }
  ]

  tipo_jornada = [
    { id: 101, valor: 'Ordinaria-Art. 22' },
    { id: 201, valor: 'Parcial-Art. 40 Bis' },
    { id: 412, valor: 'Especial-Art 22 Inciso Final' },
    { id: 701, valor: 'Exenta-Art. 22' }
  ]

  tipo_discapacidad = [
    { id: 0, valor: 'No' },
    { id: 1, valor: 'Discapacidad Certificada por la compin' },
    { id: 2, valor: 'Asignatorio Pensión por Invalidez Total' },
    { id: 3, valor: 'Pensionado por Invalidez Parcial' }
  ]

  tipo_afc = [
    { id: 1, valor: 'Si' },
    { id: 0, valor: 'No' },
  ]

  disableValorIsapre: boolean = true;
  requiredAfp: boolean = true;
  requiredUfIsapre: boolean = true;

  minDate: Date;
  maxDate: Date;



  @Input() trabajadoresList: any = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  dataSource = new MatTableDataSource();
  lista_trabajadores: any[] = [];
  trabajadoresFilter: any[] = [];
  ELEMENT_DATA: any = [];
  //dataSource :any= this.ELEMENT_DATA;

  debouncer: Subject<boolean> = new Subject();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'rut',
    'nombres',
    'fecha_nacimiento',
    'sexo',
    'estado_civil',
    'numero_cargas',
    'nacionalidad',
    'telefono',
    'correo',
    'profesion',
    'jubilado',
    'direccion',
    'patologias',
    'telefono_contacto_emergencia',
    'nombre_contacto_emergencia',
    'region',
    'ciudad',
    'comuna',
    'sucursal',
    'estado_contrato',
    'cargo',
    'empresa',
    'afp',
    'prevision',
    'pactado_isapre',
    'tramo',
    'ahorro_apv',
    'banco',
    'tipo_cuenta',
    'numero_cuenta',
    'fecha_ingreso',
    'sueldo_base',
    'sueldo_liquido',
    'talla_polera_poleron',
    'talla_pantalon',
    'numero_calzado',
    'pase_movilidad',
    'licencia_conducir',
    'tipo_jornada',
    'discapacidad',
    'afc',
    'acciones'
  ];

  fileName = 'Trabajadores.xlsx';
  exportexcel(): void {
    //let element = document.getElementById('tabla');
    
    function configExcel(data: any) {
  
      const rut = data.rut.toString().slice(0, -1);
      const dv = getLastDigitOfRut(rut);
      const rutCompleto = rut + dv;
      const nombre: string = data.nombres?.toUpperCase() + ' ' +data.apellidos?.toUpperCase();
      const fecha_nacimiento = data.fecha_nacimiento;
      const numero_cargas: number = data.numero_cargas;
      const telefono = data.telefono;
      const correo: string = data.correo?.toUpperCase();
      const profesion: string = data.profesion?.toUpperCase();
      const jubilado: string = data.jubilado?.toUpperCase();
      const direccion: string = data.direccion?.toUpperCase();
      const patologias: string = data.patologias?.toUpperCase();
      const telefono_contacto_emergencia = data.telefono_contacto_emergencia;
      const nombre_contacto_emergencia: string = data.nombre_contacto_emergencia?.toUpperCase();
      const comuna: string = data.comuna?.toUpperCase();
      const pactado_isapre = data.pactado_isapre;
      const afp: string = data.Afp.nombre?.toUpperCase();
      const tramoAsigFam: number = data.tramoAsigFam;
      const ahorro_apv = data.ahorro_apv;
      const fecha_ingreso = data.fecha_ingreso;
      const banco: string = data.Banco.nombre?.toUpperCase();
      const cargo: string = data.Cargo.nombre?.toUpperCase();
      const ciudad: string = data.Ciudad.nombre?.toUpperCase();
      const region: string = data.Ciudad.CiudadRegion.nombre?.toUpperCase();
      const empresa: string = data.Empresa_contratante.nombre?.toUpperCase();
      const estado_civil: string = data.Estado_civil.nombre?.toUpperCase();
      const nacionalidad: string = data.Nacionalidad.nombre?.toUpperCase();
      const salud: string = data.Prevision.nombre?.toUpperCase();
      const sucursal: string = data.Sucursal.nombre?.toUpperCase();
      const contrato: string = data.estado_contrato?.toUpperCase();
      const numero_cuenta: string = data.numero_cuenta?.toString().toUpperCase();
      const sexo: string = !data.sexo ? 'S/D' : data.sexo === 'M' ? 'MASCULINO' : 'FEMENINO';
      const tipo_cuenta: string = data.tipo_cuenta?.toUpperCase();
      const sueldo_base: number = data.sueldo_base;
      const sueldo_liquido: number = data.sueldo_liquido;
      const talla_polera_poleron = data.talla_polera_poleron;
      const talla_pantalon = data.talla_pantalon;
      const numero_calzado = data.numero_calzado;
      const pase_movilidad: string = data.pase_movilidad?.toUpperCase();
      const licencia_conducir: string = data.licencia_conducir?.toUpperCase();
      const tipo_jornada = data.tipo_jornada;
      const discapacidad: string = data.discapacidad;
      const seguroCesantia = data.afc;

      return { 
        RUT: rutCompleto,
        'NOMBRE COMPLETO': nombre,
        'FECHA NACIMIENTO': fecha_nacimiento,
        SEXO: sexo,
        'ESTADO CIVIL': estado_civil,
        'N°CARGAS': numero_cargas,
        NACIONALIDAD: nacionalidad,
        'TELÉFONO': telefono,
        CORREO: correo,
        PROFESIÓN: profesion,
        JUBILADO: jubilado,
        DIRECCIÓN: direccion,
        PATOLOGÍAS: patologias,
        'N°CONTACTO DE EMERGENCIA': telefono_contacto_emergencia,
        'NOMBRE CONTACTO': nombre_contacto_emergencia,
        REGION: region, 
        CIUDAD: ciudad,
        COMUNA: comuna, 
        SUCURSAL: sucursal,
        'ESTADO CONTRATO': contrato, 
        CARGO: cargo, 
        EMPRESA: empresa, 
        AFP: afp, 
        PREVISION: salud, 
        'PACTADO ISAPRE (UF)': pactado_isapre,
        'TRAMO ASIG. FAMILIAR': tramoAsigFam,
        AHORROAPV: ahorro_apv, 
        BANCO: banco, 
        'TIPO CUENTA': tipo_cuenta,
        'NUMERO CUENTA': numero_cuenta,
        'FECHA INGRESO': fecha_ingreso,
        'SUELDO BASE': sueldo_base,
        'SUELDO LIQUIDO': sueldo_liquido,
        'TALLA POLERA': talla_polera_poleron,
        'TALLA PANTALON': talla_pantalon,
        'N° CALZADO': numero_calzado,
        PASEMOVILIDAD: pase_movilidad,
        'LICENCIA CONDUCIR': licencia_conducir,
        'TIPO JORNADA': tipo_jornada,
        DISCAPACIDAD: discapacidad,  
        'S. CESANTÍA': seguroCesantia 
      }
    }

    const format = this.trabajadoresFilter.map(e => configExcel(e))
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(format);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  public convetToPDF() {

    var datos = [];
    datos.push([
      // 'ID',
      'RUT',
      'NOMBRES',
      'APELLIDOS',
      'DIRECCIÓN',
      'EMPRESA',
      'SUCURSAL',
      'CARGO']);

    // this.ELEMENT_DATA = this.dataSource;
    // this.ELEMENT_DATA.map(function (item: any) {
    this.trabajadoresFilter.map(function (item: any) {

      datos.push([
        // item.id,
        item.rut,
        item.nombres,
        item.apellidos,
        item.direccion,
        item.Empresa_contratante.nombre,
        item.Sucursal.nombre,
        item.Cargo.nombre]);
    });


    this.ELEMENT_DATA.map(function (item: any) {

      datos.push([
        // item.id,
        item.rut,
        item.nombres,
        item.apellidos,
        item.direccion,
        item.empresa,
        item.sucursal,
        item.cargo]);
    });


    var documentDefinition = {
      pageOrientation: 'landscape',
      content: [

        { text: 'TRABAJADORES', style: 'header', fontSize: 30 },
        {
          table: {

            body: datos

          }
        }
      ]
    };

    pdfMake.createPdf(documentDefinition).open();
  }
  MostrarModal(content: any, rut: any) {
    this.modalService.open(content, { size: 'xl', windowClass: 'animate__animated animate__bounceIn animate__faster', centered: true });
    this.rut_trabajador = rut;
  }

  viewDatosFiniquitados() {
    this.trabajadoresFilter = this.viewFiniquitados ? this.lista_trabajadores : this.lista_trabajadores.filter(x => x.estado !== 0);
    this.trabajadoresFilter.map(function (dato: any) {
      dato.licencia_conducir = dato.licencia_conducir ? "Si" : "No";
      dato.pase_movilidad = dato.pase_movilidad ? "Si" : "No";
      dato.jubilado = dato.jubilado ? "Si" : "No";

      const rut = dato.rut.toString().slice(0, -1);
      const dv = getLastDigitOfRut(rut);
      dato.rut = rut + dv;
      dato.rutDB = dato.rut;
      return dato;
    });

    this.dataSource = new MatTableDataSource<ListaTrabajadores>(this.trabajadoresFilter);
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return this.configFilterPredicate(data, filter);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  ObtenerRegion(event: MatSelectChange) {
    this.trabajador.region = event.value;
    const findRegion = this.region.find(x => x.nombre === this.trabajador.region);
    this.renderciudad = findRegion.id || 0;
  }
  //Obtener Comunas
  ObtenerComunaXV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaIII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }
  ObtenerComunaIV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }
  ObtenerComunaV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }
  ObtenerComunaXIII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }
  ObtenerComunaVI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaVII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaXVI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaVIII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaIX(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaXIV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaX(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaXI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerComunaXII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value

  }

  ObtenerDiaSemana(event: any) {
    this.trabajador.dias_semana = event.value;
  }


  async TraerDatos(event: boolean) {
    this.empresaService.GetAll_empresas().subscribe((res: any) => {
      const empresas = res.data.map(element => ({
        value: element.id.toString(),
        text: element.nombre.toUpperCase()
      }));
      this.listaEmpresas = empresas
    });
    const res = await this.trabajadoresService.TraerTrabajadoresAsync();
    if (res.success) {
      if (this.empresaSelect) {
        this.lista_trabajadores = res.data.filter(element => element.empresa_contratante == this.empresaSelect);
      } else {
        this.lista_trabajadores = res.data
      }
      this.trabajadoresFilter = this.lista_trabajadores.filter(x => x.estado !== 0);
      // CONTAR LOS TRABAJADORES FINIQUITADOS
      this.num_trab_finiquitados = this.lista_trabajadores.reduce((a, e) => {
        return e.estado === 0 ? a + 1 : a;
      }, 0);
      this.trabajadoresFilter.map(function (dato: any) {
        dato.licencia_conducir = dato.licencia_conducir ? "Si" : "No";
        dato.pase_movilidad = dato.pase_movilidad ? "Si" : "No";
        dato.jubilado = dato.jubilado ? "Si" : "No";

        const rut = dato.rut.toString().slice(0, -1);
        const dv = getLastDigitOfRut(rut);
        dato.rutDB = dato.rut;
        dato.rut = rut + dv;
        return dato;
      });

      this.dataSource = new MatTableDataSource<ListaTrabajadores>(this.trabajadoresFilter);
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return this.configFilterPredicate(data, filter);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  FiltrarDatos() {
    this.TraerDatos(true)
  }
  configFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLowerCase().split(' ');

    const nombre: string = this.normalizeText(data.nombres + ' ' +data.apellidos)?.toLowerCase();
    const afp: string = this.normalizeText(data.Afp.nombre)?.toLowerCase();
    const banco: string = this.normalizeText(data.Banco.nombre)?.toLowerCase();
    const cargo: string = this.normalizeText(data.Cargo.nombre)?.toLowerCase();
    const ciudad: string = this.normalizeText(data.Ciudad.nombre)?.toLowerCase();
    const region: string = this.normalizeText(data.Ciudad.CiudadRegion.nombre)?.toLowerCase();
    const empresa: string = this.normalizeText(data.Empresa_contratante.nombre)?.toLowerCase();
    const estado_civil: string = data.Estado_civil.nombre?.toLowerCase();
    const nacionalidad: string = data.Nacionalidad.nombre?.toLowerCase();
    const salud: string = data.Prevision.nombre?.toLowerCase();
    const sucursal: string = this.normalizeText(data.Sucursal.nombre)?.toLowerCase();
    const contrato: string = data.estado_contrato?.toLowerCase();
    const numero_cuenta: string = data.numero_cuenta?.toString().toLowerCase();
    const sexo: string = !data.sexo ? 'S/D' : data.sexo === 'M' ? 'masculino' : 'femenino';
    const tipo_cuenta: string = data.tipo_cuenta?.toLowerCase();

    return filterLower.every(
      x => nombre?.includes(x) || afp?.includes(x) || banco?.includes(x) || cargo?.includes(x)
      || ciudad?.includes(x) || region?.includes(x) || empresa?.includes(x) || estado_civil?.includes(x)
      || nacionalidad?.includes(x) || salud?.includes(x) || sucursal?.includes(x) || contrato?.includes(x)
      || numero_cuenta?.includes(x) || sexo?.includes(x) || tipo_cuenta?.includes(x)
    )
  }

  // Función que elimina los diacríticos de las vocales de una cadena
  normalizeText(texto: string): string {
    return texto?.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }

  EditarDatos(content: any, rut: any) {
    this.trabajadoresService.TraerTrabajadoresRut(rut).subscribe(res => {
      //parseo para que no se indefina
      this.trabajador = res.data[0];
      console.log(this.trabajador);
      this.sexo_seleccionada = this.trabajador.sexo;
      // console.log(this.trabajador);

      const region = this.trabajador.region;
      const findRegion = this.region.find(x => x.nombre === region);
      this.renderciudad = findRegion.id || 0;

      let rut = this.trabajador.rut.toString();
      rut = rut.slice(0, - 1);
      let dv = getLastDigitOfRut(rut);
      this.trabajador.rut = rut + dv;

      this.modalReference = this.modalService.open(content, { size: 'xl' });

      this.requiredAfp = (this.trabajador.jubilado) ? false : true;
      this.requiredUfIsapre = (this.trabajador.Prevision.id == 1) ? false : true;
      this.disableValorIsapre = (this.trabajador.Prevision.id == 1) ? true : false;


      this.trabajadoresService.TraerCiudades().subscribe((res: any) => {

        this.ciudades = res.data;
        this.ciudad_seleccionada = this.trabajador.Ciudad.id;
      });

      this.trabajadoresService.TraerSucursales().subscribe((res: any) => {

        this.sucursales = res.data;
        this.sucursal_seleccionada = this.trabajador.Sucursal.id;
      });
      this.trabajadoresService.TraerCargos().subscribe((res: any) => {

        this.cargos = res.data;
        this.cargo_seleccionado = this.trabajador.Cargo.id;
      });
      this.trabajadoresService.TraerEmpresas().subscribe((res: any) => {

        this.empresas = res.data;
        this.empresa_contratante_seleccionada = this.trabajador.Empresa_contratante.id;
      });
      this.trabajadoresService.TraerAfp().subscribe((res: any) => {

        this.afps = res.data;
        if (this.trabajador.Afp != null) {
          this.afp_seleccionada = this.trabajador.Afp.id;
        }
      });
      this.trabajadoresService.TraerPrevisiones().subscribe((res: any) => {

        this.previsiones = res.data;



        this.prevision_salud_seleccionada = this.trabajador.Prevision.id;
      });
      this.trabajadoresService.TraerEstadosCiviles().subscribe((res: any) => {

        this.estados = res.data;
        this.estado_civil_seleccionada = this.trabajador.Estado_civil.id;
      });
      this.trabajadoresService.TraerNacionalidades().subscribe((res: any) => {

        this.nacionalidades = res.data;
        this.nacionalidad_seleccionada = this.trabajador.Nacionalidad.id;
      });

      this.bancoService.TraerTodos().subscribe((res: any) => {
        this.bancos = res.data;
        this.banco_seleccionado = this.trabajador.Banco.id;
      });

      this.tipo_cuenta_seleccionada = this.trabajador.tipo_cuenta;
      this.tipo_jornada_seleccionada = this.trabajador.tipo_jornada;
      this.tipo_discapacidad_seleccionada = this.trabajador.tipo_discapacidad;
      this.tipo_afc_seleccionada = this.trabajador.tipo_afc;


    });


  }

  async Actualizar() {

    delete this.trabajador.Ciudad;
    delete this.trabajador.Afp;
    delete this.trabajador.Cargo;
    delete this.trabajador.Empresa_contratante;
    delete this.trabajador.Estado_civil;
    delete this.trabajador.Nacionalidad;
    delete this.trabajador.Prevision;
    delete this.trabajador.Sucursal;
    delete this.trabajador.Banco;

    let valid: any;
    valid = this.validarFormulario(this.trabajador);

    if (valid.length == 0) {
      let rut = this.trabajador.rut.toString();
      rut = rut.slice(0, - 1);
      let dv = getLastDigitOfRut(rut);
      dv = (dv == 'K') ? '0' : dv;
      this.trabajador.rut = rut + dv;

      this.trabajadoresService.ActualizarTrabajador(this.trabajador).subscribe(async res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          await this.TraerDatos(true);
          this.debouncer.next(true);
        } else {
          this.alert.error_small(res.msg!)
        }
      })
    }
    else {
      this.alert.error_small(valid);
    }
  }

  ObtenerCiudad(event: MatSelectChange) {

    this.trabajador.ciudad = event.value;

  }

  ObtenerSucursal(event: MatSelectChange) {

    this.trabajador.sucursal = event.value;

  }

  ObtenerDiscapacidad(event: MatSelectChange) {

    this.trabajador.discapacidad = event.value;

  }

  ObtenerAfc(event: MatSelectChange) {

    this.trabajador.afc = event.value;

  }

  ObtenerCargo(event: MatSelectChange) {

    this.trabajador.cargo = event.value;

  }

  ObtenerEmpresaContratante(event: MatSelectChange) {

    this.trabajador.empresa_contratante = event.value;

  }

  ObtenerAfp(event: MatSelectChange) {

    this.trabajador.afp = event.value;

  }

  ObtenerTramo(event: MatSelectChange) {
    this.trabajador.tramoAsigFam = event.value;
  }

  ObtenerPrevision(event: MatSelectChange) {

    this.trabajador.prevision = event.value;
    this.trabajador.pactado_isapre = null;

    if (event.value == 1) {
      this.disableValorIsapre = true;
      this.requiredUfIsapre = false;
    }
    else {
      this.disableValorIsapre = false;
      this.requiredUfIsapre = true;
    }

  }

  ObtenerEstadoContrato(event: MatSelectChange) {

    this.trabajador.estado_contrato = event.value;

  }

  ObtenerEstadoCivil(event: MatSelectChange) {

    this.trabajador.estado_civil = event.value;

  }

  ObtenerNacionalidad(event: MatSelectChange) {

    this.trabajador.nacionalidad = event.value;

  }

  onChangeSlidePase($event: any) {
    if ($event.checked) {
      this.trabajador.pase_movilidad = true
    }
    else {
      this.trabajador.pase_movilidad = false
    }
  }

  onChangeSlideLicencia($event: any) {
    if ($event.checked) {
      this.trabajador.licencia_conducir = true
    }
    else {
      this.trabajador.licencia_conducir = false
    }
  }

  onChangeSlideJubilado($event: any) {
    if ($event.checked) {
      this.trabajador.jubilado = true;
      this.requiredAfp = false;
    }
    else {
      this.trabajador.jubilado = false;
      this.requiredAfp = true;
    }
  }

  ObtenerBanco(event: MatSelectChange) {
    this.trabajador.banco = event.value;
  }

  ObtenerTipoCuenta(event: MatSelectChange) {
    this.trabajador.tipo_cuenta = event.value;
  }

  ObtenerTipoJornada(event: MatSelectChange) {
    this.trabajador.tipo_jornada = event.value;
  }
  ObtenerSexo(event: MatSelectChange) {
    this.trabajador.sexo = event.value;
  }

  applyFilter() {
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  async ngOnInit() {
    await this.TraerDatos(true);
  }

  validarFormulario(datos: any): any {
    if (datos.rut == null || datos.rut == "") {
      return 'Rut no válido';
    }
    else if (datos.nombres == null || datos.nombres == "" || datos.nombres.length < 3) {
      return 'Nombre no valido (min. 3 caracteres)';
    }
    else if (datos.apellidos == null || datos.apellidos == "" || datos.apellidos.length < 3) {
      return 'Apellido no válido (min. 3 caracteres)';
    }
    else if (datos.telefono == null || datos.telefono == "" || datos.telefono.length < 12 || datos.telefono.length > 12) {
      return 'Teléfono no válido';
    }
    else if (datos.correo == null || datos.correo == "" || (datos.correo != null && datos.correo != '' && !this.auth.esEmailValido(datos.correo))) {
      return 'Correo no válido';
    }
    else if (datos.fecha_nacimiento == null || datos.fecha_nacimiento == "") {
      return 'Fecha de nacimiento no válida';
    }
    else if (datos.nacionalidad == null || datos.nacionalidad == "") {
      return 'Nacionalidad no válida';
    }
    else if (datos.profesion == null || datos.profesion == "") {
      return 'Profesión no válida';
    }
    else if (datos.numero_cargas == null || !Number.isInteger(parseInt(datos.numero_cargas)) || parseInt(datos.numero_cargas) > 15) {
      return 'Número de cargas no válido';
    }
    else if (datos.estado_civil == null || datos.estado_civil == "") {
      return 'Estado civil no válido';
    }
    else if (datos.ciudad == null || datos.cuidad == "") {
      return 'Ciudad no válida';
    }
    else if (datos.direccion == null || datos.direccion == "" || datos.direccion.length < 3) {
      return 'Dirección no válida (min. 3 caracteres)';
    }
    else if (datos.direccion == null && ((datos.patologias.length > 0 && datos.patologias.length < 3) || datos.patologias.length > 250)) {
      return 'Patologías no válida (min. 3 caracteres)';
    }
    else if (datos.telefono_contacto_emergencia == null || datos.telefono_contacto_emergencia == "" || datos.telefono_contacto_emergencia.length < 12 || datos.telefono_contacto_emergencia.length > 12) {
      return 'Teléfono de emergencia no válido';
    }
    else if (datos.nombre_contacto_emergencia == null || datos.nombre_contacto_emergencia == "" || datos.nombre_contacto_emergencia.length < 3) {
      return 'Nombre del contacto de emergencia no válido (min. 3 caracteres)';
    }
    else if (datos.estado_contrato == null || datos.estado_contrato == "") {
      return 'Estado del contrato no válido';
    }
    else if (datos.sucursal == null || datos.sucursal == "") {
      return 'Sucursal no válida';
    }
    else if (datos.cargo == null || datos.cargo == "") {
      return 'Cargo no válido';
    }
    else if (datos.empresa_contratante == null || datos.empresa_contratante == "") {
      return 'Empresa contratante no válida';
    }
    else if (datos.jubilado != true && (datos.afp == null || datos.afp == "")) {
      return 'Afp no válida';
    }
    else if (datos.prevision == null || datos.prevision == "") {
      return 'Previsión no válida';
    }
    else if (datos.prevision != 1 && (datos.pactado_isapre == null || datos.pactado_isapre > 10)) {
      return 'Monto pactado (Isapre) no válido';
    }
    else if (datos.banco == null || datos.banco == "") {
      return 'Banco no válido';
    }
    else if (datos.tipo_cuenta == null || datos.tipo_cuenta == "") {
      return 'Tipo de cuenta no válido';
    }
    else if (datos.numero_cuenta == null || !Number.isInteger(parseInt(datos.numero_cuenta)) || parseInt(datos.numero_cuenta) > 999999999999999) {
      return 'Número de cuenta no válido';
    }
    else if (datos.fecha_ingreso == null || datos.fecha_ingreso == "") {
      return 'Fecha de ingreso no válida';
    }
    else if (datos.sueldo_bruto != null && (!Number.isInteger(parseInt(datos.sueldo_bruto)) || parseInt(datos.sueldo_bruto) > 999999999)) {
      return 'Sueldo bruto no válido';
    }
    else if (datos.sueldo_liquido != null && (!Number.isInteger(parseInt(datos.sueldo_liquido)) || parseInt(datos.sueldo_liquido) > 999999999)) {
      return 'Sueldo líquido no válido';
    }
    else if (datos.talla_pantalon != null && (!Number.isInteger(parseInt(datos.talla_pantalon)) || parseInt(datos.talla_pantalon) > 99)) {
      return 'Talla pantalon no válida';
    }
    else if (datos.numero_calzado != null && (!Number.isInteger(parseInt(datos.numero_calzado)) || parseInt(datos.numero_calzado) > 99)) {
      return 'Talla calzado no válida';
    }
    else {
      if (datos.region == "" && datos.comuna == "") {
        return ' Region o comuna estan vacios';
      } else {
        return '';
      }

    }
  }

  getRecord(content: any, row: any) {
    this.EditarDatos(content, row.rutDB);
  }

  async Desabilitar_Habilitar(rut: number, estado: boolean) {
    if (estado) {
      await this.habilitarTrabajador(estado, rut);
    } else {
      await this.desabilitarTrabajador(estado, rut);
    }
  }
  async desabilitarTrabajador(estado: boolean, rut: number) {
    const Title = "Inabilitar"

      const trabajador = {
        rut: rut,
        estado: estado,
        estado_contrato: 'Finiquitado',
      }

      Swal.fire({
        title: `¿${Title} Trabajador?`,
        text: "Se cambiara estado del Trabajador!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7d0000',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${Title}!`,
        cancelButtonText: 'Cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {

          this.trabajadoresService.ActualizarTrabajador(trabajador).subscribe(async result => {
            await this.TraerDatos(true);
            this.debouncer.next(true);
            this.alert.success_small(result.msg)
          })

        }
      })
  }
  async habilitarTrabajador(estado: boolean, rut: number) {
    const Title = "Habilitar"

    const list_contratos = {
      fijo: 'Contrato a Plazo Fijo',
      indefinido: 'Contrato Indefinido',
      faena: 'Contrato por Obra o Faena'
    }

    const estado_contrato = await this.alert.selectOptions(list_contratos, 'Contrato', 'Seleccione el nuevo contrato del trabajador');
    // console.log({estado_contrato});

    if (estado_contrato.dismiss !== Swal.DismissReason.cancel) {
      const trabajador = {
        rut: rut,
        estado: estado,
        estado_contrato: list_contratos[estado_contrato.value],
      }
  
      console.log({trabajador});
  
      // return;
  
      Swal.fire({
        title: `¿${Title} Trabajador?`,
        text: "Se cambiara estado del Trabajador!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7d0000',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${Title}!`,
        cancelButtonText: 'Cancelar!'
      }).then((result) => {
        if (result.isConfirmed) {
  
          this.trabajadoresService.ActualizarTrabajador(trabajador).subscribe(async result => {
            await this.TraerDatos(true);
            this.debouncer.next(true);
            this.alert.success_small(result.msg)
          })
  
        }
      })
    }
  }

  validaTelefono(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trabajador.telefono = this.formatoTelefono(filterValue);
  }

  validaTelefonoEmegencia(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trabajador.telefono_contacto_emergencia = this.formatoTelefono(filterValue);
  }

  formatoTelefono(filterValue: any) {
    let codigo: any,
      numero: any;
    if (filterValue.length < 3) {
      return '+56';
    } else {
      if (filterValue.substring(0, 3) != '+56') {
        return '+56';
      } else {
        codigo = filterValue.substring(0, 3);
        numero = filterValue.substring(3);
        numero = numero.replace(/[^\d]/g, '');
        return codigo + numero;
      }
    }
  }

  cerrarModal() {
    this.modalReference.close();
  }

  soloNumeros(event: Event, param: boolean) {
    var invalidChars = ["-", "+", "e"];
    if (param) { invalidChars.push(".") }

    const filterValue = (event.target as HTMLInputElement);
    filterValue.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }

  openUploadDialog(rutDB: number): void {
    console.log("RUT MODAL: ", rutDB)
    this.dialog.open(UploadDialogComponent, {
      width: '400px',
      data: { rutDB: rutDB } 
    });
  }
  openDocumentsModal(rutDB: number): void {
    this.dialog.open(MostrarDocumentoTrabajadorComponent, {
      data: { rutDB: rutDB },
      width: '500px'
    });
  }

}
