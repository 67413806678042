<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>RUT</th>
      <td mat-cell *matCellDef="let element" style="width: 11%;">
        {{ element.fk_trabajador | formatoRut }}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="nombreTrabajador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TRABAJADOR</th>
      <td mat-cell *matCellDef="let element" style="width: 25%;">
        <!-- {{ element.Trabajador.nombres | uppercase }} {{ element.Trabajador.apellidos | uppercase }} -->
        {{ element.Trabajador.nombres | uppercase }} {{ element.Trabajador.apellidos | uppercase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="autoriza">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AUTORIZA</th>
      <td mat-cell *matCellDef="let element" style="width: 10%;">
        {{ element.autoriza| uppercase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="observacion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>OBSERVACIÓN</th>
      <td mat-cell *matCellDef="let element" style="width: 20%;">
        {{ element.observacion | uppercase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fechaPrestamo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA PRÉSTAMO</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fechaPrestamo | date:'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cuotas">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CUOTAS</th>
      <td mat-cell *matCellDef="let element">
        {{ element.cuotas }}
      </td>
    </ng-container>

    <ng-container matColumnDef="montoTotal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>MONTO TOTAL</th>
      <td mat-cell *matCellDef="let element">
        {{ element.montoTotal | currency: 'CLP' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ESTADO</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="text-success" *ngIf="element.estado" 
          pTooltip="Pagado" tooltipPosition="bottom">
          check_circle
        </mat-icon>
        <mat-icon class="text-danger" *ngIf="!element.estado" 
          pTooltip="Pendiente" tooltipPosition="bottom">
          pending
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
      <td mat-cell *matCellDef="let element" nowrap>
        <button (click)="open(content, element.id)" mat-button
          class="button_color_primary" pTooltip="Editar préstamo" [tooltipPosition]="POSITION" [showDelay]="SHOW_MS">
          <mat-icon style="color: white">edit</mat-icon>
        </button>
        <!-- &nbsp; -->
        <button mat-button (click)="eliminar_prestamo(content, element.id, element)"
          class="button_color_primary" pTooltip="Eliminar préstamo" [tooltipPosition]="POSITION" [showDelay]="SHOW_MS" 
          style="background-color:red">
          <mat-icon style="color: white">delete</mat-icon>
        </button>
        <!-- &nbsp; -->
        <button mat-button [matMenuTriggerFor]="menu" pTooltip="Más opciones" [tooltipPosition]="POSITION" [showDelay]="SHOW_MS" 
          class="button_color_primary" style="background-color:rgb(37, 199, 228)">
          <mat-icon style="color: white">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="ImprimirDocumento(element)">
            <mat-icon>print</mat-icon>
            <span>Imprimir plantilla de préstamo</span>
          </button>
          <button mat-menu-item *ngIf="!element.estadoDocumento" (click)="SubirDocumento(content2, element.fk_trabajador, element.id)">
            <mat-icon>file_upload</mat-icon>
            <span>Subir documento préstamo</span>
          </button>
          <button mat-menu-item *ngIf="element.estadoDocumento" (click)="DescargarDocumento(element.id)">
            <mat-icon>archive</mat-icon>
            <span>Visualizar préstamo subido</span>
          </button>
        </mat-menu>
        <!-- 
        <button mat-button (click)="ImprimirDocumento(element)" matBadgeColor="accent" 
          class="button_color_primary" matTooltip="Imprimir plantilla de préstamo" style="background-color:rgb(37, 199, 228)">
          <mat-icon style="color: white">print</mat-icon>
        </button>
        <button *ngIf="element.estadoDocumento == 0" mat-button (click)="SubirDocumento(content2, element.fk_trabajador, element.id)" 
          class="button_color_primary" matTooltip="Subir documento préstamo" style="background-color:rgb(37, 199, 228)">
          <mat-icon style="color: white">file_upload</mat-icon>
        </button>
        <button *ngIf="element.estadoDocumento == 1" mat-button (click)="DescargarDocumento(element.id)" matBadgeColor="accent" 
          class="button_color_primary" matTooltip="Visualizar préstamo subido" style="background-color:rgb(37, 199, 228)">
          <mat-icon style="color: white">archive</mat-icon>
        </button> 
        -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de prestamos.">
  </mat-paginator>
</div>


<!-- Actualizar datos de préstamo -->
<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Actualizar Préstamo</mat-card-title>
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> -->
    <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
      <mat-step label="Prestamo" state="payments">
        <div class="row">
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Nombre Trabajador</mat-label>
              <input type="text" maxlength="100" minlength="3" disabled matInput
                [(ngModel)]="update_prestamos.Trabajador.nombres+' '+update_prestamos.Trabajador.apellidos" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Rut Trabajador</mat-label>
              <input type="text" disabled matInput name="prestamos.fk_trabajador"
                [ngModel]="update_prestamos.fk_trabajador | formatoRut" />
            </mat-form-field>
          </div>
        </div>
    
        <div class="row">
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Autoriza</mat-label>
              <input type="text" maxlength="50" minlength="3" matInput required
                [(ngModel)]="update_prestamos.autoriza" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Tipo prestamo</mat-label>
              <mat-select [(value)]="update_prestamos.idtipo" required>
                <mat-option [value]="tipo.id" *ngFor="let tipo of tiposprestamo">{{ tipo.nombre }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
    
        <div class="row">
          <div class="col-12">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Observación</mat-label>
              <textarea type="textarea" rows="3" maxlength="200" matInput
                [(ngModel)]="update_prestamos.observacion">
              </textarea>
            </mat-form-field>
          </div>
        </div>
    
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Fecha de solicitud</mat-label>
              <input matInput (click)="picker.open()" [matDatepicker]="picker" required (focus)="picker.open()"
                [(ngModel)]="update_prestamos.fechaPrestamo" (dateChange)="obtenerFechaPrestamo()" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Cuotas</mat-label>
              <input type="number" max="999" minlength="1" required matInput 
                [(ngModel)]="update_prestamos.cuotas" (blur)="obtenerNumCuotas()"/>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Pagadas</mat-label>
              <input type="number" max="999" minlength="0" matInput
                [value]="cuotasPagadas" (blur)="obtenerCuotasPagadas($event)"/>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Monto Total</mat-label>
              <input type="text" matInput required [(ngModel)]="update_prestamos.montoTotal" 
                autonumeric [options]="optionsNumeric" (blur)="obtenerMonto()"/>
            </mat-form-field>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button mat-raised-button matStepperNext color="primary">Ir a las cuotas</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Cuotas del préstamo" state="request_quote">
        <app-form-cuotas 
          [numCuotas]="numCuotas" 
          [montoPrestamo]="montoPrestamo" 
          [fechaPrestamo]="fechaPrestamo" 
          [cuotasPagadas]="cuotasPagadas"
          [accion]="accion"
          [cuotasListUpdate]="update_prestamos.Cuotas"
          ($cuotas)="recibirCuotas($event)"
          ($cuotasEliminadas)="recibirCuotasEliminadas($event)"></app-form-cuotas>
        <div class="row">
          <div class="col-12">
            <p-divider></p-divider>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button mat-raised-button matStepperPrevious color="primary">Ir al prestamo</button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" required (click)="cerrarModal()">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="validarPrestamo()">
      Actualizar
    </button>
  </div>
</ng-template>

<!---------------------------------------------------------------------------------------------------------------------------------------------->


<ng-template style="border: 0px; z-index: 100" #content2 let-modal>
  <div class="modal-header" style="text-align: center">
    <mat-card-title>Subir Documento Firmado</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>
      <label>Prestamo Firmado</label>
      &nbsp;
      <input mat-input type="file" ng2FileSelect [uploader]="uploader" required (click)="removeFileFromQueue()" />
    </p>
    <div>
      Estado de Carga
      <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" (click)="guardarDocumentoFirmado()" [disabled]="!uploader.getNotUploadedItems().length"
      class="btn btn-outline-success">Guardar</button>
  </div>
</ng-template>