import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { SolicitudesDocumento } from 'src/app/shared/models/solicituddocumento.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudesDocumentosService {

  constructor(
    private http: HttpClient,
  ) { }
  
  async TraerSolicitudes(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener las solicitudes.' } as RequestResponse;
    }
  }

  async TraerSolicitud(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener las solicitudes.' } as RequestResponse;
    }
  }

  async TraerPorCodigo(codigo: string): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/getForCode/${codigo}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener la solicitud.' } as RequestResponse;
    }
  }

  async TraerPorDocumento(id_documento: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/documento/${id_documento}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener la solicitud.' } as RequestResponse;
    }
  }

  async TraerSolicitudInclude(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/get/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener la solicitud.' } as RequestResponse;
    }
  }

  async CrearAprobacion(aprobacion: SolicitudesDocumento): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/crear`, aprobacion).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al crear la aprobación.' } as RequestResponse;
    }
  }

  async ActualizarAprobacion(aprobacion: SolicitudesDocumento): Promise<RequestResponse> {
    try {
      return await this.http.put<RequestResponse>(`${environment.API}/rrhh/solicitudesdocumentos/actualizar`, aprobacion).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al actualizar la aprobación.' } as RequestResponse
    }
  }
}
