import { SucursalService } from './../../../services/sucursales.service';
import { Component, EventEmitter, OnInit, Output, ViewChild ,HostListener} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgxSpinnerService } from "ngx-spinner";
import { ContratoEstandarPlazoFijoService } from 'src/app/pages/services/PDF-GENERADOR/contratos/contrato-estandar-plazo-fijo.service';
const pdfMake = require('pdfmake/build/pdfmake.js');
import { MatStepper } from '@angular/material/stepper';
import { ContratoConductor } from 'src/app/pages/services/PDF-GENERADOR/contratos/contrato-estandar-conductor';
import Swal from 'sweetalert2';
export enum KEY_CODE {
	RIGHT_ARROW = 39,
	LEFT_ARROW = 37
}
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
	selector: 'app-formato-editable',
	templateUrl: './formato-editable.component.html',
	styleUrls: ['./formato-editable.component.css']
})
export class FormatoEditableComponent implements OnInit {
	@ViewChild('content3', {static: false}) modal: any;
	@ViewChild('modal2', {static: false}) modal2: any;
	@ViewChild('stepper') private myStepper: MatStepper;
	@Output() EventoRut = new EventEmitter<string>();
	@HostListener('window:keyup', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		this.TH = this.TH + event.key
		if (event.code == 'Enter') {
			this.myStepper.next();
		}
		if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
			this.myStepper.next();
		}
		if (event.keyCode === KEY_CODE.LEFT_ARROW) {
			this.myStepper.previous();
		}
		this.key = event.key;
	}
	constructor(
		private modalService: NgbModal,
		private alert: AlertHelper,
		private trabajadoresService: TrabajadoresService,
		private ciudadService: CiudadService,
		private pdfgenerador:ContratoEstandarPlazoFijoService,
		private pdfconductor:ContratoConductor,
		private spinner: NgxSpinnerService,
		private sucursalService:SucursalService
	) { }
	rut:any;
	TH:any='';
	key:any;
	ComunaConductor: any;
	sucursales:any;
	SucursalesSeleccionadas:any;
	lista_trabajadores: any;
	TrabajadorSeleccionado: any;
	render: number = 0;
	lista_ciudades: any;
	CiudadSeleccionada: any = undefined;
	PazoFijo:boolean=false;
  ObrasFaenas:boolean=false;
	DatosJson:any={};
	Fechas:any={
		fecha_inicio:'',
		fecha_fin:''
	}
	src:any
	sueldo!: number;

	MostrarSucursales :boolean;
	MostrarTrabajadores:boolean;
	MostrarFuncionesOcupacionales:boolean;

  checkPlazoFijo(): void {
    this.ObrasFaenas = false;
      }

      checkObrasFaenas(): void {
        this.PazoFijo = false;
          }
	ngOnInit(): void {

		this.TraerDatos();
	}
	MostrarFunciones() {
        this.MostrarFuncionesOcupacionales = true;
    }

    MostrarModalSucursales() {
        this.MostrarSucursales = true;
    }

	MostrarModuloTrabajadores() {
        this.MostrarTrabajadores = true;
    }


	ObtenerTrabajador(event: any) {

		// this.transferencia.banco=this.BancoSeleccionado.id;
		//console.log(this.TrabajadorSeleccionado);
		//console.log(event.value.rut);
		this.rut = event.value.rut;
		this.SucursalesSeleccionadas=[];
		//this.EventoRut.emit(event.value.rut);
		this.render = 1;
	}
	ObtenerCiudad(event: any) {

		// this.transferencia.banco=this.BancoSeleccionado.id;
		//console.log(this.CiudadSeleccionada);
		this.render = 1;
	}

	Abrirhaberes(){
		this.modalService.open(this.modal2, { size: 'xl' })
	}
	 pulsar(e) {
			this.myStepper.next();
			//console.log("pulso enter")


	}

	TraerDatos() {
		//console.log("trayendo datos")
		this.SucursalesSeleccionadas=[];
		this.trabajadoresService.TraerTrabajadores().subscribe(
			(res) => {
				this.lista_trabajadores = res.data.map(x => {
					x.nombreCompleto = x.nombres.toUpperCase() + ' ' + x.apellidos.toUpperCase();
					return x;
				});
			}
		);
		this.ciudadService.GetAll_ciudades().subscribe(
			(res) => {
				this.lista_ciudades = res.data;
				// console.log(this.lista_ciudades);
			}
		);
		this.sucursalService.GetAll_sucursales().subscribe(
			(res) => {
				this.sucursales = res.data;
				// console.log(this.lista_ciudades);
			}
		);
	}
	async GenerarPdf(){

		if (this.validar()==true){
			//console.log(this.SucursalesSeleccionadas)
			this.DatosJson.Ciudad=this.CiudadSeleccionada;
			this.DatosJson.Trabajador=this.TrabajadorSeleccionado;
			this.DatosJson.PlazoFijo=this.PazoFijo
			this.DatosJson.Fechas=this.Fechas
			this.DatosJson.Sueldo=this.sueldo
			this.DatosJson.SucursalesSeleccionadas=this.SucursalesSeleccionadas
			// console.log(this.DatosJson)
			//console.log(this.pdfgenerador.GenerarPdf(this.DatosJson));
			this.spinner.show();

			switch (this.TrabajadorSeleccionado.cargo){
				case 3:
					this.DatosJson.comuna=this.ComunaConductor;
					//console.log(this.DatosJson);
					await this.pdfconductor.GenerarPdf(this.DatosJson);
					setTimeout(() => {
						if (this.pdfconductor.src!=undefined){
						   this.src=this.pdfconductor.src
						   //console.log(this.src);
						   this.spinner.hide();
						   this.modalService.open(this.modal, { size: 'xl' })
						}
						else{
							this.alert.errorAlert('error al generar pdf')
						}
					}, 1500);
				   // console.log(this.pdfgenerador.src)
					break;

					default:
						await this.pdfgenerador.GenerarPdf(this.DatosJson);
					setTimeout(() => {
						if (this.pdfgenerador.src!=undefined){
						   this.src=this.pdfgenerador.src
						   //console.log(this.src);
						   this.spinner.hide();
						   this.modalService.open(this.modal, { size: 'xl' })
						}
						else{
							this.alert.errorAlert('error al generar pdf')
						}
					}, 1500);
						break;
			}
		}
		else{
			this.alert.errorAlert('faltan campos que llenar')
		}


	}

	validar() :boolean{
		if (this.sueldo>0 && this.CiudadSeleccionada!=undefined ){
			return true
		}
		else{
			return false
		}
	}

}
