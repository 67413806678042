import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TrabajadoresService } from '../../services/trabajadores/trabajadores.service';
import { format, parseISO } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventClickArg, FullCalendarComponent } from '@fullcalendar/angular';

import esLocale from '@fullcalendar/core/locales/es';

@Component({
  selector: 'app-fechas-cumpleanios',
  templateUrl: './fechas-cumpleanios.component.html',
  styleUrls: ['./fechas-cumpleanios.component.css']
})
export class FechasCumpleaniosComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild('fullcalendar') fullcalendar!: FullCalendarComponent;
  @ViewChild('content') content!: any;

  dataSource: any;
  displayedColumns: string[] =
  [
    'rut',
    'nombreTrabajador',
    'sucursal',
    'fechaNac',
    //'diffDays'
  ];
  loading: boolean = true; // SIRVE PARA CONTROLAR LOS SPINNERS DE CARGA

  cumpleaños: any[] = [];
  trabajadores: any[] = [];
  events: any[] = [];
  options: any;
  header: any;
  sucursales: any[] = [];
  selectedValueSucursal: any;
  sucursal: any;
  periodo: Date = new Date();
  trabajadorSelect: any;
  sucursalSelect: string[] = [];
  trabajadoresFilter: any[] = [];
  trabajadorSeleccionado: any;

  constructor(
    private trabajadorService: TrabajadoresService,
    private modalService: NgbModal,
    ) {}

  ngOnInit(): void {

    this.cargarCumpleaños();
    this.cargarCumpleaniosCalendar();
    this.cargarSucursales();
    this.TraerDatosFilter();


  }

  cargarCumpleaniosCalendar() {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res: any) => {
        this.trabajadores = res.data;
        console.log(this.trabajadores);
        this.trabajadores = res.data.filter(trabajador => trabajador.estado == 1);

        const eventos = this.trabajadores.map(trabajador => {
          const fechaFormateada = parseISO(trabajador.fecha_nacimiento);
          const yearActual = new Date().getFullYear();
          const start = format(new Date(yearActual, fechaFormateada.getMonth(), fechaFormateada.getDate(), 0, 0, 0, 0), 'yyyy-MM-dd');
          return {
            title: `${trabajador.nombres.toUpperCase()} ${trabajador.apellidos.toUpperCase()}`,
            start: start
          };
        });

        // Renderizar eventos con un ligero retraso
        setTimeout(() => {
          this.options = {
            locale: esLocale,
            initialView: 'dayGridMonth',
            headerToolbar: {
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            events: eventos,
            eventClick: this.abrirModal.bind(this),
            eventBackgroundColor: 'rgb(248, 168, 173)',
            eventBorderColor: 'rgb(248, 168, 173)'
          };

          this.fullcalendar.getApi().updateSize();
        }, 1000);
      }
    );
  }


  abrirModal(arg: EventClickArg) {
    // Encuentra el trabajador seleccionado por el título del evento
    this.trabajadorSeleccionado = this.trabajadores.find(trabajador => {
      const nombreCompleto = `${trabajador.nombres} ${trabajador.apellidos}`;
      return nombreCompleto === arg.event.title;
    });

    // Abre el modal
    this.modalService.open(this.content);
  }

  cargarCumpleaños() {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res: any) => {
        this.cumpleaños = res.data.filter(trabajador => trabajador.estado === 1);

        // Luego, ordena los cumpleaños filtrados por fecha de nacimiento
        this.cumpleaños.sort((a, b) => {
          const fechaNacimientoA = new Date(a.fecha_nacimiento);
          const fechaNacimientoB = new Date(b.fecha_nacimiento);
          const diaMesA = fechaNacimientoA.getDate();
          const diaMesB = fechaNacimientoB.getDate();
          const mesA = fechaNacimientoA.getMonth();
          const mesB = fechaNacimientoB.getMonth();
          // Comparar primero por mes
          if (mesA === mesB) {
            // Si están en el mismo mes, comparar por día del mes
            return diaMesA - diaMesB;
          } else {
            // Si están en meses diferentes, comparar por mes
            return mesA - mesB;
          }


        });
        this.FiltrarDatos();
        //this.cargarCumpleaniosCalendar();
      }
    );
  }


  async TraerDatosFilter() {
    try{
    const res = await this.trabajadorService.TraerTrabajadoresAsync();
    this.cumpleaños = res.data.sort((a: any, b: any) => {
      const nombresA = `${a.nombres.toUpperCase()} ${a.apellidos.toUpperCase()}`;
      const nombresB = `${b.nombres.toUpperCase()} ${b.apellidos.toUpperCase()}`;
      return nombresA.localeCompare(nombresB);
    });
    this.sucursales = this.cargarSucursales();
    this.trabajadoresFilter = this.cumpleaños.map(x => ({
      value: x.rut.toString(),
      text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
    })).sort((a, b) => a.text.localeCompare(b.text));
  }catch(err){
    console.log(err);
  }
}

  cargarSucursales(): any[] {
    const sucursales = this.cumpleaños.map(x => ({
      value: x.Sucursal.id.toString(),
      text: x.Sucursal.nombre.toUpperCase()
    }));
    const sucursalesFilter = sucursales.filter((x, index, self) => {
      return self.findIndex(t => t.value === x.value) === index;
    });
    return sucursalesFilter.sort((a, b) => a.text.localeCompare(b.text));
  }


  onMonthChandler(event: any) {
    this.periodo = event;
    this.FiltrarDatos();
    //this.configurarDropdownEstado();
  }

  FiltrarDatos() {
    let solicitudesFiltradas = this.cumpleaños;

    if (this.trabajadorSelect) {
      solicitudesFiltradas = solicitudesFiltradas.filter(x => x.rut === parseInt(this.trabajadorSelect));
    }

    if (this.sucursalSelect && this.sucursalSelect.length > 0) {
      solicitudesFiltradas = solicitudesFiltradas.filter(x => {
        return this.sucursalSelect.includes(x.sucursal.toString());
      });
    }

    if (this.periodo) {
      solicitudesFiltradas = solicitudesFiltradas.filter(x => {
        const fechaNacimiento = new Date(x.fecha_nacimiento);
        return fechaNacimiento.getMonth() === this.periodo.getMonth();
      });
    }

    this.cumpleaños.sort((a, b) => {
      const fechaNacimientoA = new Date(a.fecha_nacimiento);
      const fechaNacimientoB = new Date(b.fecha_nacimiento);
      const diaMesA = fechaNacimientoA.getDate();
      const diaMesB = fechaNacimientoB.getDate();
      const mesA = fechaNacimientoA.getMonth();
      const mesB = fechaNacimientoB.getMonth();
      // Comparar primero por mes
      if (mesA === mesB) {
        // Si están en el mismo mes, comparar por día del mes
        return diaMesA - diaMesB;
      } else {
        // Si están en meses diferentes, comparar por mes
        return mesA - mesB;
      }


    });

    this.dataSource = new MatTableDataSource<any>(solicitudesFiltradas);
    this.dataSource.paginator = this.paginator;
  }


}
