
  <app-agregar-horas-extras (onDebounce)="TraerDatos($event)"></app-agregar-horas-extras>
       
  <br/>
<div class="mat-elevation-z8">
    <div class="row justify-content-end pr-3">
        <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Buscar</mat-label>
                <input matInput [(ngModel)]="textSearch" (keyup)="applyFilter()">
                <button mat-icon-button matSuffix (click)="quitarFiltro()">
                    <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef style="width: 8%;" mat-sort-header>RUT</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.rut | formatoRut }}
            </td>
        </ng-container>

        <ng-container matColumnDef="trabajador">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;" mat-sort-header>TRABAJADOR</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.trabajador }}
            </td>
        </ng-container>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">FECHA</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.fecha | date: 'dd/MM/yyyy'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="horasExtras">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">HORAS EXTRAS</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.horasExtras | number: '1.2-2' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.descripcion }}
            </td>
        </ng-container>

        <!-- Acciones -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">ACCIONES</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button mat-button class="button_color_primary mr-2" (click)="open(content, element.id)">
                    <mat-icon style="color: white">edit</mat-icon>
                </button>
                <button mat-button class="button_color_primary" (click)="eliminar(content, element.id)" style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons aria-label="Seleccionar pagina de horas extras.">
    </mat-paginator>
</div>

<!-- Actualizar datos de horas extras -->
<ng-template style="border: 0px; z-index: 100;" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Actualizar Horas Extras</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form>
            <!-- Fila 1 -->
            <div class="row">
                <!-- Trabajador -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Trabajador</mat-label>
                        <input type="text" readonly="readonly" minlength="3" maxlength="100" matInput name="horasExtras.trabajador" [value]="listaHoras.trabajador" [(ngModel)]="listaHoras.trabajador" />
                    </mat-form-field>
                </div>

                <!-- Fecha -->
             <div class="col">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha </mat-label>
                    <input matInput [matDatepicker]="picker"
                     name="horasExtras.fecha" 
                     [value]="listaHoras.fecha" 
                     [(ngModel)]="listaHoras.fecha">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                    <!-- fecha de entrada y fecha de salida  -->
                </mat-form-field>

                
                 
                </div> 
            </div>

            <!-- Fila 2 -->
            <div class="row">
                <!-- Horas Extras -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Horas Extras</mat-label>
                        <input type="text" matInput name="horasExtras.horasExtras" [(ngModel)]="listaHoras.horasExtras" autonumeric [options]="optionsDecimal" />
                    </mat-form-field>
                </div>

                <!-- Valor Hora -->
                <!-- <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Valor Hora</mat-label>
                        <input type="number" minlength="0" maxlength="6" matInput name="horasExtras.valorHora"  [value]="listaHoras.valorHora"[(ngModel)]="listaHoras.valorHora" />
                    </mat-form-field>
                </div> -->
            </div>

            <!-- Fila 3 -->
            <div class="row">
               
             
                <!-- Descripción -->
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <input type="text" minlength="3" maxlength="100" matInput name="horasExtras.descripcion" [value]="listaHoras.descripcion" [(ngModel)]="listaHoras.descripcion" />
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
 <button type="button" class="btn btn-outline-success" (click)="actualizar_horasExtras()">
            Actualizar
        </button> 
    </div>
</ng-template>