import { Component, OnInit } from '@angular/core'
import { PrestamosService } from 'src/app/pages/services/prestamos.service'
import { Prestamos } from 'src/app/shared/models/prestamos.interface'

@Component({
  selector: 'app-main-prestamos',
  templateUrl: './main-prestamos.component.html',
  styleUrls: ['./main-prestamos.component.css']
})
export class MainPrestamosComponent implements OnInit {
  prestamos: Prestamos[] = []
  textoBuscar: string = '';

  constructor(
    private prestamos_services: PrestamosService,
    ) { }

  ngOnInit(): void {
    this.TraerPrestamos();
  }
  TraerPrestamos() {
    this.prestamos_services.GetAll_prestamos().subscribe( res => {
      this.prestamos=res.data.sort((a: Prestamos, b: Prestamos) => {
        const timeA = new Date(a.fechaPrestamo).getTime();
        const timeB = new Date(b.fechaPrestamo).getTime();
        return timeB - timeA;
      })
    })
  }

  obtenerTextoBuscar(event: any) {
    this.textoBuscar = event.target.value;
  }

  actualizarTabla(event:boolean){
    this.TraerPrestamos();
  }
}
