import { JornadaLaboral } from '../../../../shared/models/jornada-laboral.interface';
import { JornadaLaboralService } from 'src/app/pages/services/jornada-laboral.service';
import { Component, OnDestroy, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Swal from 'sweetalert2';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lista-jornada-laboral',
  templateUrl: './lista-jornada-laboral.component.html',
  styleUrls: ['./lista-jornada-laboral.component.css']
})

export class ListaJornadaLaboralComponent implements OnInit {

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private JornadaLaboralService: JornadaLaboralService,
  ) { }

  displayedColumns: string[] = ['id', 'descripcion', 'actions'];
  @Input() jornadas_List: JornadaLaboral[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();
  dataSource;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  updateJornada: JornadaLaboral = {
    id: 0,
    descripcion: ""
  };
  

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.jornadas_List != undefined) {
      if (!changes.jornadas_List.firstChange) {
        this.dataSource = new MatTableDataSource<JornadaLaboral>(this.jornadas_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }
  
  open(content: any, id: any) {
    this.updateJornada.descripcion = "";
    this.updateJornada.id = id;
    this.JornadaLaboralService.ObtenerJornada(id).subscribe(res => {
      this.updateJornada.descripcion = res.data.descripcion;
    })

    this.modalService.open(content);
  }

  ActualizarJornada() {
    if(this.updateJornada.descripcion.trim().length==0){
      this.alert.errorAlert('La descripción no puede estar vacía');
    }
    else {
      this.JornadaLaboralService.ActualizarJornada(this.updateJornada).subscribe(res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!);
          this.modalService.dismissAll();
          this.updateJornada.descripcion = "";
          this.debouncer.next(true);
        } else {
          //this.alert.error_small(res.msg!);
          this.alert.error_small('Error, no es posible actualizar la jornada laboral');
        }
      })
    }
  }

  EliminarJornada(content: any, id: any) {

    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara la jornada seleccionada",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {


    this.JornadaLaboralService.EliminarJornada(id).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.debouncer.next(true);
      } else {
        // this.alert.error_small(res.msg!)
        this.alert.error_small('Error, no es posible eliminar la jornada laboral');
      }
    })
  }
})

  }

  fileName = 'jornada_laboral.xlsx';
  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }


  public convetToPDF() {

    var datos=[];
    datos.push(['ID','DESCRIPCIÓN JORNADA']);

    this.jornadas_List.map(function (item) {

      datos.push([item.id,item.descripcion]);
    });
   
    var documentDefinition = {
      content: [
        {text: 'Jornadas', style: 'header',fontSize:30},
        {
          table: {
            widths: [50,400],
            body:datos
          }
        }
      ]
    };
    pdfMake.createPdf(documentDefinition).open();
  }


  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

  ngOnDestroy(): void {
   
  }


}
