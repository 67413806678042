
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRazonComponent } from './main-razon/main-razon/main-razon.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgregarRazonComponent } from './agregar-razon/agregar-razon.component';
import { ListaRazonComponent } from './lista-razon/lista-razon.component';

import { ColorPickerModule } from 'primeng/colorpicker';
import { FileUploadModule } from 'ng2-file-upload';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';



///prueba de pickear

@NgModule({

  declarations: [
    
    MainRazonComponent,
    AgregarRazonComponent,
    ListaRazonComponent
   
  ],
  
  imports: [
    
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    ColorPickerModule,
    NgbModalModule,
    ReactiveFormsModule
    
 

  

  
  ],
  exports:[
    MainRazonComponent,
    AgregarRazonComponent,
    ListaRazonComponent,

  ],

  
})
export class RazonModule { }
