
    <p-toolbar styleClass="bg-light animate__animated animate__fadeIn" *ngIf="selection?.selected.length > 0">
        <div class="p-toolbar-group-left overflow-x-auto">
          <button pButton pRipple icon="pi pi-thumbs-up" class="p-button-rounded p-button-success p-button-sm mr-2"
            (click)="AllDesicion(true)" label="Aprobar Seleccionadas">
          </button>
          <button pButton pRipple icon="pi pi-thumbs-down" class="p-button-rounded p-button-danger p-button-sm"
            (click)="AllDesicion(false)" label="Rechazar Seleccionadas">
          </button>
        </div>
      </p-toolbar>
    <p-toolbar styleClass="bg-light">
      <div class="p-toolbar-group-left overflow-x-auto">
        <p-dropdown placeholder="Todas las empresas" [options]="empresas" [filter]="true" filterBy="text" [(ngModel)]="empresaSelect" optionLabel="text"
        optionValue="value" class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body"></p-dropdown>
      <p-multiSelect [style]="{'max-width': '800px'}" placeholder="Todas las sucursales" [options]="sucursales" [filter]="true" filterBy="text" [(ngModel)]="sucursalSelect" optionLabel="text"
        optionValue="value" class="mr-2" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body" [selectedItemsLabel]="'{0} sucursales seleccionadas'"></p-multiSelect>
       <p-dropdown placeholder="Todos los trabajadores" [options]="allTrabajadores" [(ngModel)]="trabajadorSelect" [filter]="true" filterBy="text"
          optionLabel="text" optionValue="value" class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()"
            appendTo="body"></p-dropdown>
        <div class="mr-2">
          <label for="periodo" class="mr-1">Periodo</label>
          <p-calendar [readonlyInput]="true" inputId="periodo" [(ngModel)]="periodo" view="month" dateFormat="mm/yy" (onSelect)="onMonthChandler($event)"
          [minDate]="MIN_DATE" appendTo="body">
          </p-calendar>
        </div>
        <div class="mr-2">
          <label for="estado" class="mr-1">Estado</label>
          <p-multiSelect 
          [options]="estadoSolicitud" 
          [(ngModel)]="estadoSolicitudSelect" 
          (onChange)="FiltrarDatos()"
          placeholder="Todos los estados" 
          optionLabel="text" 
          optionValue="value" 
          appendTo="body">
        </p-multiSelect>
     
        </div>

    
     
      </div>
 

    </p-toolbar>
      <div class="mat-elevation-z8" >
        <table mat-table [dataSource]="dataSource">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox   
              (change)="$event ? masterToggle() : null"
              [checked]="selection?.hasValue() && isAllSelected()"
              [indeterminate]="selection?.hasValue() && !isAllSelected()"
                            color="warn">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection?.toggle(element) : null"
                            [checked]="selection?.isSelected(element)"
                            color="warn">
              </mat-checkbox>
            </td>
            <td mat-footer-cell *matFooterCellDef class="text-center">
              <span>{{selection?.selected.length??0}}</span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="trabajador">
            <th mat-header-cell *matHeaderCellDef> TRABAJADOR </th>
            <td mat-cell *matCellDef="let element"> {{ element.trabajadors.nombres | uppercase }} {{ element.trabajadors.apellidos | uppercase }} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="fecha"> 
            <th mat-header-cell *matHeaderCellDef> FECHA </th>
            <td mat-cell *matCellDef="let element"> {{ element.fecha | date: 'dd/MM/yyyy' }} </td>
            <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right"> TOTAL LIQUIDO:</td>
          </ng-container>

          <ng-container matColumnDef="alcanceLiq"> 
            <th mat-header-cell *matHeaderCellDef> ALCANCE LÍQUIDO </th>
            <td mat-cell *matCellDef="let element"> $ {{ element.alcanceLiquido | number:'1.0-0' }} </td>
            <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right"> ${{12132312 | number: '1.0-0' }} </td>
          </ng-container>

          <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> ESTADO </th>
             <td mat-cell *matCellDef="let element">
            <p-message *ngIf="element.estado === 0" severity="info" text="PENDIENTE"></p-message>
            <p-message *ngIf="element.estado === 1" severity="success" text="APROBADA"></p-message>
            <p-message *ngIf="element.estado === 2" severity="error" text="RECHAZADA"></p-message>
            <p-message *ngIf="element.estado === 3" severity="error" text="ELIMINADA"></p-message>
            <p-message *ngIf="(element.estado === null || element.estado === undefined)" severity="info"
              text="PENDIENTE"></p-message>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <!-- <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
            <td mat-cell *matCellDef="let element"> </td>
          </ng-container> -->

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef style="width: 20%;"> ACCIONES </th>
            <td mat-cell *matCellDef="let element" class="pl-2">
                <p-button icon="pi pi-book" styleClass="mr-2 p-button-secondary" *ngIf="(user.rol === 'ADMIN' || user.rol === 'GERENCIA')"
                (click)="verDetalle(element.id, viewHonorarioModal)" pTooltip="Ver detalle"
                tooltipPosition="bottom"></p-button>
              <!-- ELEGIR OPCION -->
              <button *ngIf="(element.estado === null || element.estado !== 3) && (user.rol === 'ADMIN' || user.rol === 'GERENCIA')" type="button" pButton icon="pi pi-check-circle"
                class="mr-2" pTooltip="Elegir decisión" tooltipPosition="bottom"
                (click)="getIdHonorario(element.id);menu1.toggle($event)"></button>
              <p-menu #menu1 [popup]="true" [model]="desiciones"></p-menu>

              <!-- MAS OPCIONES -->
              <!-- <button *ngIf="element.estado !== null && element.estado !== 0" type="button" pButton icon="pi pi-bars"
                pTooltip="Más opciones" tooltipPosition="bottom"
                (click)="masOpciones(element, viewHistorialDecisiones);menu2.toggle($event)"></button> -->
              <p-menu #menu2 [popup]="true" [model]="moreOptions"></p-menu>
            </td>
            <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right">TOTAL REGISTROS FILTRADOS: {{dataSource?.data.length}}</td>

        </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center p-1 mt-2" colspan="8">
              <h1>No existen datos</h1>
            </td>
          </tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[10, 15, 20]"
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>

      <ng-template #viewHonorarioModal let-modal>
        <div class="modal-header">
          <mat-card-title class="modal-title">Detalles de Honorario</mat-card-title>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-modal-view-honorario [modalViewHonorario]="modalViewHonorario"></app-modal-view-honorario>
        </div>
      </ng-template>


