import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'src/app/material.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { HelperModule } from 'src/app/helper.module';
import { MatSortModule } from '@angular/material/sort';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ListaComprasComponent } from './lista-compras/lista-compras.component';
import { MainComprasComponent } from './main-compras/main-compras.component';

@NgModule({
  declarations: [
   MainComprasComponent,
    ListaComprasComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    FileUploadModule,
    PrimeNgModule,
    HelperModule,
    MatSortModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
  providers: [
  ],
  exports: [
  
    MainComprasComponent,
    ListaComprasComponent

  ]
})
export class ComprasModule { }
