import { TreeModule } from 'primeng/tree';
import { Component, Input, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { TreeTableModule } from 'primeng/treetable';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { VacacionesService } from 'src/app/pages/services/vacaciones/vacaciones.service';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-agregar-vacaciones',
  templateUrl: './agregar-vacaciones.component.html',
  styleUrls: ['./agregar-vacaciones.component.css'],
  providers: [DatePipe]
})
export class AgregarVacacionesComponent implements OnInit {

  @Input() id:any;
  @Input() vacacion:any;
  responses: string[] = [];
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  trabajador={
    nombre:'',
    apellido:'',
    rut:0,
    f_start:'',
    f_end:'',
    habiles:0,
    inhabiles:0,
    pendiente:0,
    periodo:'',
    empresa:'',
    folio:0,
  };

  vacacionesUpdate={
    id:0,
    estado:1,
    fk_trabajador:0,
  }

  calendario : any ={

    motivo:"Vacaciones",
    id: 0,
    start: "",
    end: "",
    razon: 2,
    trabajador: 0,
    dsueldo: "NO",
    dbono: "NO"
  }




  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/vacaciones/subirVacacion`,
    itemAlias: 'vacacion',

  });

  constructor(
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public sanitizer:DomSanitizer,
    private trabajadoresService: TrabajadoresService,
    private  vacaciones: VacacionesService,
    private asistenciaService: GestorAsistenciaService,
    private alert: AlertHelper,
    private auth: AuthService,
  ) { 

    this.uploader.onCompleteAll = () => {

      //console.log('se termino de enviar ');
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user= this.auth.ObtenerinfoToken();
      form.append('estado', this.vacacionesUpdate.estado);
      form.append('id', this.vacacionesUpdate.id);
      form.append('fk_trabajador', this.vacacionesUpdate.fk_trabajador);
      form.append('userAt', user.nombre);
      
    };




  }

  ngOnInit(): void {
    //this.trabajador.rut=this.id;
    this.vacacionesUpdate.id=this.id;
    //console.log(this.id);
    this.vacaciones.TraerVacacion(this.id).subscribe(
      (res) => {
    //console.log(res.data);
    this.trabajador.folio=res.data.folio;
    this.vacacionesUpdate.fk_trabajador=res.data.fk_trabajador;
    this.calendario.trabajador=res.data.fk_trabajador;
    this.calendario.start=res.data.f_start;
    this.calendario.end=res.data.f_end;
   
    //console.log(this.trabajador.ubicacion);
    // this.trabajador.nombre= res.data[0].nombres;
    // this.trabajador.apellido=res.data[0].apellidos;
      }
      
    );    

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      if (result.response == true) {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        });

        this.modalService.dismissAll(); 
        this.asistenciaService.crear(this.calendario).subscribe((res: any) => {
          if (res.success == true) {
            //console.log("INASISTENCIA CREADA CORRECTAMENTE");
  
          } else {
            this.alert.error_small(res.msg!)
          }
        })

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',

        })

      }
    }
  }




  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  


  cerrarModal(){
    this.modalService.dismissAll(); 
  }



  guardar() {

    //console.log("Iniciando proceso de guardado");
    //console.log(this.trabajador);
    this.uploader.uploadAll();

  }

}
