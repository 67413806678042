import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HaberesService } from 'src/app/pages/services/haberes/haberes.service';
import * as XLSX from 'xlsx';
import { Validators, FormBuilder } from '@angular/forms';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConceptoLRE } from 'src/app/shared/models/conceptoslre.interface';
import { ConceptosLREService } from 'src/app/pages/services/conceptosLRE/conceptoslre.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lista-haberes',
  templateUrl: './lista-haberes.component.html',
  styleUrls: ['./lista-haberes.component.css']
})
export class ListaHaberesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();
  fileName = 'haberes.xlsx';
  lista_haberes: any;
  dataSource = new MatTableDataSource;
  id: any; 111

  /**  Configuración de formulario, requiere importar Validators  */
  addressForm = this.fb.group({
    nombre: ["", Validators.required],
    descripcion: ["", Validators.required],
    tipo: [, Validators.required],
    imponible: [, Validators.required],
    tributable: [, Validators.required],
    gratificacion: [, Validators.required],
    remCaractFijo: [, Validators.required],
    remCaractVariable: [, Validators.required],
    bSemanaCorrida: [, Validators.required],
    proporcional: [, Validators.required],
    idconceptolre: [, Validators.required],
  });

  conceptos: ConceptoLRE[] = [];
  conceptos_filter: ConceptoLRE[] = [];

  haber = {
    id: 0,
    nombre: "",
    descripcion: "",
    tipo: 0,
    imponible: 0,
    tributable: 0,
    gratificacion: 0,
    remCaractFijo: 0,
    remCaractVariable: 0,
    bSemanaCorrida: 0,
    proporcional: 0,
    idconceptolre: 0,
  }
  opciones = [0, 1];


  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }

  public convetToPDF() {

    var datos = [];
    datos.push([
      'CÓDIGO',
      'DESCRIPCIÓN',
      'TIPO',
      'IMP',
      'TRIB',
      'GRAT.',
      'FIJO',
      'VAR.',
      'SEM.',
      'PROP']);


    this.lista_haberes.map(function (item: any) {

      datos.push([
        item.id,
        item.descripcion,
        item.tipo,
        item.imponible,
        item.tributable,
        item.gratificacion,
        item.remCaractFijo,
        item.remCaractVariable,
        item.bSemanaCorrida,
        item.proporcional]);
    });


    var documentDefinition = {

      content: [

        { text: 'HABERES', style: 'header', fontSize: 30 },
        {
          table: {

            body: datos

          }
        }
      ]
    };

    pdfMake.createPdf(documentDefinition).open();


  }


  displayedColumns: string[] = [
    'codigo',
    'nombre',
    'descripcion',
    'tipo',
    'imp',
    'trib',
    'grat',
    'fijo',
    'var',
    'sem',
    'prop',
    'acciones'
  ];



  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private haberesService: HaberesService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private conceptoService: ConceptosLREService,
  ) { }

  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.obtenerDatos();

  }

  get f() {
    return this.addressForm.controls;
  }


  obtenerDatos() {
    this.haberesService.GetAll_Haberes().subscribe(
      (res) => {
        this.lista_haberes = res.data.filter((x: any) => x.eliminado === 0);
        //console.log(this.lista_haberes);
        this.dataSource = new MatTableDataSource(this.lista_haberes);
        this.dataSource.paginator = this.paginator;
      }
    );
  }

  open(content: any, id: any) {
    this.id = id;
    //console.log(this.id_cargo);
    //this.modalService.open(content);
    this.modalService.open(content, { size: 'lg' });
    //console.log("Id del haber a editar:"+this.id);
    forkJoin([this.conceptoService.GetAll_Conceptos(), this.haberesService.Obtener_Haberes(this.id)])
      .pipe(
        map(([result1, result2]) => ({conceptos: result1, haber: result2})),
      ).subscribe((result) => {
        if (result.conceptos.success || result.haber.success) {
          this.conceptos = result.conceptos.data;
          this.haber = result.haber.data;

          this.f.nombre.setValue(this.haber.nombre);
          this.f.descripcion.setValue(this.haber.descripcion);
          this.f.tipo.setValue(this.haber.tipo);
          this.f.imponible.setValue(this.haber.imponible);
          this.f.tributable.setValue(this.haber.tributable);
          this.filtrarConceptos();
          this.f.gratificacion.setValue(this.haber.gratificacion);
          this.f.remCaractFijo.setValue(this.haber.remCaractFijo);
          this.f.bSemanaCorrida.setValue(this.haber.bSemanaCorrida);
          this.f.remCaractVariable.setValue(this.haber.remCaractVariable);
          this.f.proporcional.setValue(this.haber.proporcional);
          this.f.idconceptolre.setValue(this.haber.idconceptolre);
        }else{
          this.alert.error_small('Hubo un error al obtener los datos');
          console.error(result.conceptos.msg, result.haber.msg);
        }
      });
  }

  filtrarConceptos(){
    const obj = this.addressForm.value;
    const tipo = obj.tipo;
    const imponible = obj.imponible;
    const tributable = obj.tributable;
    console.log({imponible}, {tributable}, {tipo});
    if (tipo === 0) {
      this.conceptos_filter = this.conceptos.filter(x => !x.tipo);
    } else if (imponible === 1 || imponible === 0 
        && tributable === 1 || tributable === 0) 
    {
      if (obj.imponible === 1 && obj.tributable === 1) {
        this.conceptos_filter = this.conceptos.filter(x => x.imponible && x.tributable);
      } else if (obj.imponible === 1 && obj.tributable === 0){
        this.conceptos_filter = this.conceptos.filter(x => x.imponible && !x.tributable);
      } else if (obj.imponible === 0 && obj.tributable === 0){
        this.conceptos_filter = this.conceptos.filter(x => !x.imponible && !x.tributable);
      }
    }
    // console.log({...this.conceptos_filter})
  }

  onSubmit() {
    //console.log("Guardando");
    //console.log(this.addressForm.value);

    if (this.addressForm.status === 'VALID') {
      this.haberesService.Actualizar_Haber(this.addressForm.value, this.id).subscribe(res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.addressForm.reset();
          this.ngOnInit();
        } else {
          this.alert.error_small(res.msg!)
        }
      })
    } else {
      this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top',
      });

    }

  }
  // SE ELIMINA DE FORMA SUAVE (DELETE SOFT)
  async eliminar(id: any) {
    if (!confirm('Eliminar haber\n¿Seguro que deseas eliminar el haber?')) return;
    const res = await this.haberesService.Obtener_HaberesAsync(id);
    if (!res.success) {
      this.alert.error_small('No existe el haber a eliminar.');
      return;
    }
    const haber = res.data;
    const haberUpdate = {...haber, eliminado: true}
    this.haberesService.Actualizar_Haber(haberUpdate, haberUpdate.id).subscribe(res => {
      if (res.success) {
        this.alert.success_small('Se elimino el haber exitosamente');
        this.obtenerDatos();
      }else{
        this.alert.error_small(res.msg)
      }
    });
    // this.haberesService.Eliminar_Haberes(id).subscribe(res => {
    //   if (res.success == true) {
    //     this.alert.success_small(res.msg!)
    //     this.ngOnInit();
    //   } else {
    //     this.alert.error_small(res.msg!)
    //   }
    // });

  }
  //FITRAL
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  actualizar(event: boolean) {
    this.obtenerDatos();
  }

}
