<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<div class="animate__animated animate__fadeInRight border ">
    <div class="col">
        <mat-card class="mat_card_trabajadores">
            <h1 class>Gestor De Asistencia</h1>
        </mat-card>
    </div>
    <div class="col">
        <mat-card class="mat-elevation-z5 mat_card_trabajadores_content border border-primary">
            <mat-tab-group class="card_group_trabajadores">
                <mat-tab label="Listados">
                    <ng-template matTabContent>
                        <app-lista-gestorasistencia></app-lista-gestorasistencia>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Calendario">
                    <ng-template matTabContent class="row">
                        <div class="col-10">
                            <ng-template class="animate__zoomIn" style="border: 0px; z-index: 100" #mostrar let-modal3>
                                <div class="modal-header">

                                    <div id="div1">
                                        <table class="table table-hover" id="tabla">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th>
                                                        Nombre
                                                    </th>
                                                    <th>
                                                        Fecha Inicio
                                                    </th>
                                                    <th>
                                                        Fecha fin
                                                    </th>
                                                    <th>
                                                        Color
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tr *ngFor='let asistencias of asistencias'>

                                                <td>
                                                    {{asistencias.Trabajador.nombres}}
                                                </td>
                                                <td>
                                                    {{asistencias.start | date: 'dd/MM/yy' }}
                                                </td>
                                                <td>
                                                    {{asistencias.end | date: 'dd/MM/yy'}}
                                                </td>
                                                <td>
                                                    <div [style.background-color]="asistencias.Razon.colorfondo"
                                                        [style.color]="asistencias.Razon.colortexto">

                                                        {{asistencias.Razon.razon}}

                                                    </div>


                                                </td>

                                            </tr>

                                        </table>

                                    </div>


                                </div>
                                <button style="color: white;background-color: rgb(207, 51, 3);" (click)="convetToPDF()"
                                    mat-raised-button>Exportar a PDF</button>

                                <button style=" background-color:rgb(22, 117, 27)" (click)="exportexcel()"
                                    mat-raised-button color="green">Exportar a Excel</button>


                            </ng-template>



                            <section>

                                <div class="example-button-row">
                                    <div class="example-flex-container">

                                        <button mat-icon-button color="warn" (click)="openn(mostrar)"
                                            aria-label="Example icon button with a heart icon">
                                            <mat-icon>visibility<h5>Cantidad({{conteo}})</h5>
                                            </mat-icon>
                                        </button>



                                    </div>
                                </div>
                            </section>


                        </div>



                        <div class="col-12">

                            <full-calendar [options]="calendarOptions"></full-calendar>
                        </div>



                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
        <hr />
    </div>
</div>