<!-- <div *ngIf="isAdmin == 'RRHH' && !menuCoosen">

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Trabajadores </mat-panel-title>
        <mat-panel-description>
          <mat-icon>people_outline </mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <div *ngFor="let Option of typesOfOptionsRRHH">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
</div> -->


<div *ngIf="isAdmin === 'EMPLEADO' && !menuCoosen">
  <mat-selection-list #option_sidebar_edmin [multiple]="false">
    <div *ngFor="let Option of typesOfOptionsEmployed">
      <mat-list-option class="relieve_mat_list" (click)="RedirectFunction(Option)">
        <span>{{ Option.nombreItem }}
          <mat-icon style="float: right; color: gray ">{{ Option.icon }}
          </mat-icon>
        </span>
      </mat-list-option>
    </div>
  </mat-selection-list>
</div>

<div *ngIf="isAdmin === 'ASISTENCIA' && !menuCoosen">
  <mat-selection-list #option_sidebar_edmin [multiple]="false">
    <div *ngFor="let Option of typesOfOptionsASISTENCIA">
      <mat-list-option *ngIf="Option.roles.includes(isAdmin)" class="relieve_mat_list" 
        (click)="RedirectFunction(Option)">
        <span>{{ Option.nombreItem }}
          <mat-icon style="float: right; color: gray ">{{ Option.icon }}</mat-icon>
        </span>
      </mat-list-option>
    </div>
  </mat-selection-list>
</div>
<!-- Ocultar todas las demás secciones para el rol de 'ASISTENCIA' -->
<div *ngIf="isAdmin !== 'ASISTENCIA' && !menuCoosen">

<div *ngIf="isAdmin === 'COMITE' && !menuCoosen">
  <mat-selection-list #option_sidebar_edmin [multiple]="false">
    <div *ngFor="let Option of typesOfOptionsDenuncias">
      <mat-list-option class="relieve_mat_list" (click)="RedirectFunction(Option)">
        <span>{{ Option.nombreItem }}
          <mat-icon style="float: right; color: gray ">{{ Option.icon }}
          </mat-icon>
        </span>
      </mat-list-option>
    </div>
  </mat-selection-list>
</div>

<div *ngIf="isAdmin !== 'EMPLEADO' && isAdmin !== 'COMITE' && !menuCoosen; else Contabilidad">
  <mat-selection-list #option_sidebar_edmin [multiple]="false">
    <ng-container *ngFor="let Option of typesOfOptionsAdmin">
      <mat-list-option *ngIf="Option.roles.includes(isAdmin)" class="relieve_mat_list" 
        (click)="RedirectFunction(Option)">
        <span>{{ Option.nombreItem }}
          <mat-icon style="float: right; color: gray ">{{ Option.icon }}
          </mat-icon>
        </span>
      </mat-list-option>
    </ng-container>
  </mat-selection-list>
</div>

<ng-template #Contabilidad>
  <mat-selection-list #option_sidebar_edmin [multiple]="false">
    <ng-container *ngFor="let Option of typesOfOptionsAdmin">
      <mat-list-option *ngIf="Option.roles.includes(isAdmin) && Option.menuContabilidad" class="relieve_mat_list" 
        (click)="RedirectFunction(Option)">
        <span>{{ Option.nombreItem }}
          <mat-icon style="float: right; color: gray ">{{ Option.icon }}
          </mat-icon>
        </span>
      </mat-list-option>
    </ng-container>
  </mat-selection-list>
</ng-template>

<p-divider></p-divider>

<div *ngIf="isAdmin !== 'EMPLEADO' && isAdmin !== 'COMITE'">
  <!-- Solicitudes -->
  <mat-accordion class="example-headers-align" multi *ngIf="!menuCoosen">
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Solicitudes </mat-panel-title>
        <mat-panel-description>
          <mat-icon>schedule</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <p-divider align="left"><b>Liquidaciones</b></p-divider>
        <ng-container *ngFor="let Option of typesOfOptionsSolicitudesLiquidaciones">
          <mat-list-option *ngIf="Option.roles.includes(isAdmin)" (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
        <p-divider align="left"><b>Documentos</b></p-divider>
        <ng-container *ngFor="let Option of typesOfOptionsSolicitudesDocumentos">
          <mat-list-option *ngIf="Option.roles.includes(isAdmin)" (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
        <p-divider align="left"><b>Vacaciones</b></p-divider>
        <ng-container *ngFor="let Option of typesOfOptionsSolicitudesVacaciones">
          <mat-list-option *ngIf="Option.roles.includes(isAdmin)" (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
        <p-divider align="left"><b>Despido</b></p-divider>
        <ng-container *ngFor="let Option of typesOfOptionsSolicitudesDespido">
          <mat-list-option *ngIf="Option.roles.includes(isAdmin)" (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
        <p-divider align="left"><b>Permisos</b></p-divider>
        <ng-container *ngFor="let Option of typesOfOptionsSolicitudesPermisos">
          <mat-list-option *ngIf="Option.roles.includes(isAdmin)" (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- Agregando menú desplegable de Documentos -->
  <mat-accordion *ngIf="isAdmin !== 'EMPLEADO' && isAdmin !== 'COMITE' && !menuCoosen" class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Documentos </mat-panel-title>
        <mat-panel-description>
          <mat-icon>description</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <ng-container *ngFor="let Option of typesOfOptionsDocumentos">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion *ngIf="isAdmin === 'ADMIN' || isAdmin === 'RRHH' || isAdmin === 'RRHH+'" class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Generar Documentos </mat-panel-title>
        <mat-panel-description>
          <mat-icon>document_scanner_sharp </mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <div *ngFor="let Option of typesOfOptionsContabilidad">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  
  <mat-accordion *ngIf="(isAdmin === 'ADMIN' || isAdmin === 'RRHH' || isAdmin === 'RRHH+' || isAdmin !== 'COMITE') && !menuCoosen" class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Generar Contrato y Anexo </mat-panel-title>
        <mat-panel-description>
          <mat-icon>gavel </mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <div *ngFor="let Option of typesOfOptionsContrato">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  
  <mat-accordion *ngIf="isAdmin !== 'EMPLEADO' && isAdmin !== 'COMITE' && !menuCoosen" class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Gestion de pagos </mat-panel-title>
        <mat-panel-description>
          <mat-icon>payments</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <div *ngFor="let Option of typesOfOptionsGestorPagos">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  
  <mat-accordion *ngIf="isAdmin === 'ADMIN' || isAdmin !== 'RRHH' && isAdmin !== 'COMITE' && !menuCoosen" class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Mantenedores </mat-panel-title>
        <mat-panel-description>
          <mat-icon>build</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <ng-container *ngFor="let Option of typesOfOptionsMantenedores">
          <mat-list-option *ngIf="Option.roles.includes(isAdmin)" (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
  
  <mat-accordion *ngIf="(isAdmin === 'ADMIN' || isAdmin === 'RRHH' || isAdmin === 'RRHH+') && !menuCoosen" class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Perfiles Ocupacionales </mat-panel-title>
        <mat-panel-description>
          <mat-icon>settings</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
  
      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <div *ngFor="let Option of typesOfOptionsPerfiles">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>


<!--<div *ngIf="isAdmin == 'ADMIN' || isAdmin == 'RRHH'&& menuCoosen">
  SE QUITO YA QUE LOS DATOS DEL IMPUESTO SE SACA MEDIANTE UNA API O SCRAPING
   <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel class="relieve_mat_list">
      <mat-expansion-panel-header>
        <mat-panel-title> Parametros </mat-panel-title>
        <mat-panel-description>
          <mat-icon>assessment </mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-selection-list #option_sidebar_edmin [multiple]="false">
        <div *ngFor="let Option of typesOfOptionsParametros">
          <mat-list-option (click)="RedirectFunction(Option)">
            <span>
              {{ Option.nombreItem }}
              <mat-icon style="float: right; color: gray">
                {{ Option.icon }}
              </mat-icon>
            </span>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion> 
</div> -->