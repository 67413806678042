import { Injectable } from '@angular/core';

import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class UniformeService {


	public src:any=undefined;
	pdfMake: any;
	dateObj = new Date();
	month = this.dateObj.getUTCMonth() + 1; //months from 1-12
	day = this.dateObj.getUTCDate();
	year = this.dateObj.getUTCFullYear();
  
  constructor( private helpers : HelperService) { }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
	  
    }
  }
  async GenerarPdfU(trabajador: any) {
  

    //necesitas traer los datos de la ciudad del contrato aun 
    await this.loadPdfMaker();

    
var dd = {
	content: [
	   
	
		{
			text: '\nAnexo de entregarde implementos y herramientas de trabajo\n\n\n',
			style: 'header'
		}, 
		
		
		//Comienzo primer texto 
		
			{
			text: 'Por intermedio de la presente yo,   '  + trabajador.nombre.toUpperCase()+'     '+trabajador.apellido.toUpperCase()+    ' Rut   '      +this.helpers.formatoRut(trabajador.fk_trabajador)+ ' dejo contancia de haber \n\n',
			style: 'primera'
		}, 	{
			text: ' para recibido  uniforme de  trabajo  proporcionado   por   empresa   Solanch   Macarena Tejos Carrasco\n\n',
			style: 'folioo'
		}, 
			{ 
			text: ' ser  utilizado  solo  en  los  turnos  asigado,  horarios  e  instalaciones  en  lo que se me asigna a desarrollar\n\n',
			style: 'folioo'
		}, 
			{
			text: ' en el trabajo\n\n',
			style: 'folioo'
		}, {
			text: 'CONSIDERANDO LOS SIGUIENTES ELEMENTOS: \n\n',
			style: 'foliooo'
		}, 
		
		
		
		
		
		//Termino primera linea
		
		//Comienzo de tabla
		{
		    
		style: 'tableExample',	margin: [30, 0, 0, 0],
		table: {
		  	widths: [250, 220],
		    body:[
		  
		        ['Elementos/Ropa', 'Tipo Talla'],
		        ['  ', '  '],
		        ['  ', '  '],
		        ['  ', '  '],
		        ['  ', '  '],
		        ['  ', '  '],
		      
		        ]
		    
            },
            
      
		
	
		    
		    
		        },
		        
		        //Fin Tabla
		        
		        
		        //Comienzo de segundo parrafo 
		          
			
				{text: '\n\n\nEl trabajador maifiesta que:\n\n' , style: 'subheader'},  
			
		    	{text: 'Autorizo expresamente a la empresa mediante este documento a descntar del salario y liquidacion \n', style: 'subheaderrr'},  
		    	{text: 'de sueldos los  valores de la dotaciòn cuando en cualquier de los casos anteriores no la devuelve al\n', style: 'subheaderrr'},  
		    	{text: 'empleador.\n\n\n\n\n\n\n', style: 'subheaderrr'},  
		    	
		    
		    
		    
		    
		
	    	{text: 'NOMBRE: '+ trabajador.nombre.toUpperCase()+' '+trabajador.apellido.toUpperCase()+'\n\n', style: 'folioo'},  
			{text: 'CNI: '+this.helpers.formatoRut(trabajador.fk_trabajador)+'\n\n', style: 'folioo'},  
			{text: 'FECHA: '+ this.day+'/'+this.month+'/'+this.year+' \n\n', style: 'folioo'},  
			{text: 'CARGO: '+trabajador.cargos+'\n\n', style: 'folioo'},
			{text: 'FIRMA \n\n', style: 'folioo'},  

	],
	styles: {
		header: {
		    margin: [50 , 0, 0, 0],
			fontSize: 14,
			bold: true,
		
		},
		
		subheader: {
			fontSize: 11,
			bold: true,
			 margin: [30, 0, 0, 0]
		
		},
		subheaderrr: {
			fontSize: 11,
		
			margin: [30, 0, 0, 0]
		
		},
			subheaderr: {
			fontSize: 10,
			bold: true,
			 margin: [0, 0, 0, 0]
		
		},
			otro: {
			 margin: [20 , 0, 0, 0]
		
		},
		 folio: {
		     bold: true,
			 margin: [450, 0, 0, 0]
		
		},
		 folioo: {
		     	fontSize: 10,
			 	margin: [30, 0, 0, 0]
		
		},
		 foliooo: {
		     	fontSize: 10,
			 	margin: [30, 0, 0, 0]
		
		},
		 primera: {
		     	fontSize: 10,
			 	margin: [60, 0,0, 0]
		
		},
		
			
		 
	}	
	

	
}

    this.pdfMake.createPdf(dd).open();

  }

}
