import { Component, OnInit } from '@angular/core';
import { Observable, timer } from "rxjs";
import { MatCardModule } from '@angular/material/card';
const counter = timer(0, 2000);

@Component({
  selector: 'app-main-marcacion',
  templateUrl: './main-marcacion.component.html',
  styleUrls: ['./main-marcacion.component.css']
})
export class MainMarcacionComponent implements OnInit {

  public lat;
  public lng;
  public clock;
  public clockHandle;
  public ahora;
  public entrada = null;
  dateObj = new Date();
  month = this.dateObj.getUTCMonth() + 1; //months from 1-12
  day = this.dateObj.getUTCDate();
  year = this.dateObj.getUTCFullYear();
  salida = null;

  constructor(
  ) { }

  ngOnInit(): void {
    this.ahora = new Date();
    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {

          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          // console.log(
          //   "Latitude: " + this.lat
          //   +
          //   "Longitude: " + this.lng
          // );
          //       this.clockHandle = setInterval(()=>{
          //         let second: number | string = new Date().getSeconds();
          //         let minute: number | string = new Date().getMinutes();
          //         let hour: number | string = new Date().getHours();
          //          if (second < 10) {
          //              second = "0" + second;
          //            }
          //        if (minute < 0) {
          //         minute = "0" + minute;
          //        }
          // this.clock = hour + ":" + minute + ":" + second;

          //       },1000);

        }
      },
        (error) => console.log(error));
    } else {
      alert("Active el GPS y vuelva a intentarlo.");
    }
  }


  marcarEntrada() {
    this.entrada = this.clock;
  }

  marcarSalida() {

    this.salida = this.clock;
  }

}
