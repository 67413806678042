<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Finiquitos
</h3>

<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.fk_trabajador | formatoRut }} </td>
    </ng-container>
    <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> FECHA </th>
        <td mat-cell *matCellDef="let element">{{ element.fecha }}</td>
    </ng-container>
    <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef> TOTAL </th>
        <td mat-cell *matCellDef="let element"> {{ element.total | currency: 'CLP' }} </td>
    </ng-container>
    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button (click)="Descargar(element.id)" mat-button class="button_color_primary"
                style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">search</mat-icon>
            </button>
            <button (click)="Borrar(element.id)" mat-raised-button color="warn" [disabled]="element.pendienteSolicitud || loading" 
            [pTooltip]="element.pendienteSolicitud ? 'Esperando aprobación' : ''" tooltipPosition="bottom">
                <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
                <mat-icon style="color: white" *ngIf="loading">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>