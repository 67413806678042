import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PrevisionService } from 'src/app/pages/services/prevision.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Prevision } from 'src/app/shared/models/prevision.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-prevision',
  templateUrl: './lista-prevision.component.html',
  styleUrls: ['./lista-prevision.component.css']
})
export class ListaPrevisionComponent implements OnInit {

  @Input() previsiones_List: Prevision[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  update_previsiones: any = {
    id: 0,
    nombre: "",
    tasa: 0,
    cod_prevision: 0,
  };

  displayedColumns: string[] = ['id', 'nombre', 'codigo', 'actions'];
  dataSource = new MatTableDataSource<Prevision>(this.previsiones_List);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private previsionService: PrevisionService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.previsiones_List != undefined) {
      if (!changes.previsiones_List.firstChange) {
        console.log(this.previsiones_List);
        this.dataSource = new MatTableDataSource<Prevision>(this.previsiones_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  open(content: any, id: number) {
    this.update_previsiones.id = id
    this.previsionService.Obtener_prevision(id).subscribe(res => {
      this.update_previsiones.nombre = res.data.nombre;
      this.update_previsiones.tasa = res.data.tasa;
    
    })

    this.limpiar_previsiones();
    this.modalService.open(content);

  }

  limpiar_previsiones() {
    this.update_previsiones.nombre = "";
    this.update_previsiones.tasa = 0;
    this.update_previsiones.isapre = "";
  }

  Actualizar_Previsiones() {
    if (this.update_previsiones.nombre.trim().length == 0) {
      this.alert.error_small('Los campos no pueden estar vacios.')
      return
    }

    this.previsionService.Actualizar_prevision(this.update_previsiones).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small("Prevision actualizada");
        this.modalService.dismissAll();
        this.limpiar_previsiones();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })


  }

  Eliminar_Prevision(content: any, id: number) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el Prevision salud",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.previsionService.Eliminar_prevision(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!);
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }

    //FITRAL
    applyFilter(event: Event) {
      //Funcion para filtar cualquier columna por el texto buscado
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
