import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { AdelantosService } from 'src/app/pages/services/adelantos/adelantos.service'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { MatSelectChange } from '@angular/material/select'
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service'
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-agregar-adelantos',
  templateUrl: './agregar-adelantos.component.html',
  styleUrls: ['./agregar-adelantos.component.css'],
  providers: [DatePipe]
})
export class AgregarAdelantosComponent implements OnInit {
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()

  debouncer: Subject<boolean> = new Subject()
  adelanto: any;
  bancos_aux: any;
  trabajadores_aux: any;
  empresas_aux: any;
  modalReference = null;
  fechaHoy: Date;

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private adelantosServices: AdelantosService,
    private trabajadoresService: TrabajadoresService,
    private datePipe: DatePipe
  ) { }

  adelantos: any = {
    fechaInicioContrato: "",
    fk_trabajador: "",
    empresa: 0,
    banco: "",
    tipoCuenta: "",
    numeroCuenta: 0,
    montoAdelanto: 0
  }

  adela: any = {

    fechaInicioContrato: "",
    fk_trabajador: "",
    empresa: 0,
    banco: "",
    tipoCuenta: "",
    numeroCuenta: 0,
    montoAdelanto: 0

  }
  ////----Inicio prueba-----////

  render: number = 0;

  banco: number = 0;

  ////----Fin prueba-----////

  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })

    this.trabajadoresService.TraerTrabajadores().subscribe((res) => {
      //filter((x:any)=>{ return x.estado != 0}) Esta funcion trae a los trabajadores que su estado es 0
      this.trabajadores_aux = res.data.filter((x: any) => { return x.estado != 0 });
      this.trabajadores_aux = this.trabajadores_aux.map(x => ({value:x.rut, nombre: x.nombres + ' ' + x.apellidos}));
    });
  }

  //----BUSCAR BANCO BUSCAR TRABAJADOR------////

  trabajador: any;
  bancos: any;
  fecha: any;
  fechatransform: any;
  lista_trabajadores: any;
  rutTrabajadores: any;
  bancoo: any;
  empresaa: any;

  ObtenerTrabajador(event: MatSelectChange) {
    this.adelantos.fk_trabajador = event.value.value;
    this.rutTrabajadores = event.value.value;

    // console.log(this.adelantos, this.rutTrabajadores, this.render);
    this.fechaHoy = new Date();
    this.trabajadoresService.TraerTrabajadoresRut(this.rutTrabajadores).subscribe((res) => {
      this.adela.empresa = res.data[0].Empresa_contratante.id;

      this.adela.fechaInicioContrato = new Date(this.fechaHoy.getFullYear(), this.fechaHoy.getMonth(), this.fechaHoy.getDate(), 0, 0, 0, 0).toISOString().split('T')[0];

      this.empresaa = res.data[0].Empresa_contratante.nombre;

      this.adela.banco = res.data[0].Banco.id;

      this.bancoo = res.data[0].Banco.nombre;

      this.adela.tipoCuenta = res.data[0].tipo_cuenta;

      this.adela.numeroCuenta = res.data[0].numero_cuenta;

      this.render = 1;
    })

  }

  //----- Rescatar fecha----//

  obtener_fecha(event: MatDatepickerInputEvent<Date>) {
    this.fecha = event.value;
    //Asignacion a la variable con this.adelanto + en la variable en que lo guardaras
    this.adelantos.fechaInicioContrato = this.datePipe.transform(this.fecha, 'yyyy-MM-dd')
  }

  //----FIN DE LA FUNCION------////

  traerdatos: any;

  open(content: any) {

    this.modalReference = this.modalService.open(content)

    this.adela.fechaInicioContrato = undefined;
    this.adela.fk_trabajador = undefined;
    this.bancoo = undefined;
    this.empresaa = undefined;
    this.adela.tipoCuenta = undefined;
    this.adela.numeroCuenta = undefined;
    this.adela.montoAdelanto = undefined;
  }

  fechaA: any;

  agregar() {

    this.fechaA = new Date();
    this.fechaA = this.datePipe.transform(this.fechaA, 'yyyy-MM-dd');
    this.adelantos.fechaInicioContrato = this.adela.fechaInicioContrato;
    this.adelantos.empresa = this.adela.empresa
    this.adelantos.banco = this.adela.banco
    this.adelantos.tipoCuenta = this.adela.tipoCuenta
    this.adelantos.numeroCuenta = this.adela.numeroCuenta
    this.adelantos.montoAdelanto = this.adela.montoAdelanto

    this.adelantosServices.Create_adelantos(this.adelantos).subscribe((res: any) => {
      if (res.success == true) {

        this.alert.success_small(res.msg!)
        this.debouncer.next(true);
        this.modalReference.close()

      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }
}