<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo de Cargos</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content">
      <app-agregar-cargos
        (onDebounce)="actualizarTabla($event)"
        [list_sucursales]="list_sucursales_main"
      ></app-agregar-cargos>
      <br />
      <app-lista-cargos
        (onDebounce)="actualizarTabla($event)"
        [list_sucursales]="list_sucursales_main"
        [cargos_List]="cargos"
      ></app-lista-cargos>
    </mat-card>
    <hr />
  </div>
  