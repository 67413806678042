<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Modulo de Perfiles Ocupacionales</h1>
      </mat-card>
    </div>
  
    <br />
    <app-lista-perfiles-ocupacionales> </app-lista-perfiles-ocupacionales>
    
      <br />

      
    <hr />
  </div>
