import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe} from '@angular/common';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';
import { CartasAmonestacionService } from 'src/app/pages/services/cartas-amonestacion/cartas-amonestacion.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { cleanRut } from 'rutlib/lib';

export interface DialogData {
  url: any;
}

@Component({
  selector: 'app-lista-cartas-trabajador',
  templateUrl: './lista-cartas-trabajador.component.html',
  styleUrls: ['./lista-cartas-trabajador.component.css'],
  providers: [DatePipe, MessageService],
})
export class ListaCartasTrabajadorComponent implements OnInit{

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private cartaService: CartasAmonestacionService,
    private messageService: MessageService,
    private _liveAnnouncer: LiveAnnouncer,
  ) { }
  
  ngOnInit(): void {
    this.TraerDatos(true);
  }

 /// declaracion de variables 
  lista_trabajadores:any[] = [];
  trabajadorFilter: any[] = []
  lista_cartas: any;
  // viewFiniquitados: boolean = false;
  textSearch: string = '';
  num_trab_finiquitados:number = 0;

  dataSource: any;
  displayedColumns = [
    'rut',
    'nombres',
    'empresa',
    'estado_contrato',
    'acciones'
  ];

  rut_trabajador:any
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  subir(content: any ,rut: any) {
    this.modalService.open(content, { size: 'lg',windowClass:'animate__animated animate__bounceIn animate__faster'});
    
    this.rut_trabajador = rut;
  }
  Mostrar(content: any ,rut: any) {
    this.modalService.open(content, { size: 'xl',windowClass:'animate__animated animate__bounceIn animate__faster',centered:true });
    this.rut_trabajador = rut;
  }

  cerrarmodal(){
    this.modalService.dismissAll();
  }
  
  TraerDatos(param:boolean) {
    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        this.lista_trabajadores = res.data;
        this.trabajadorFilter = this.lista_trabajadores.filter(x => x.estado_contrato !== 'Finiquitado' && x.estado !== 0);
        this.num_trab_finiquitados = this.lista_trabajadores.reduce((a,e) => e.estado === 0 ? a + 1 : a, 0);
        this.cartasPorRut(this.trabajadorFilter, param);
      }
    );
  }

  cartasPorRut(trabajadores:any, param:boolean){
    const messageService = this.messageService;
    const fecha = new Date(),
        fechaInicio = new Date(fecha.getFullYear()+'-01-01 00:00:00'),
        fechaFinal = new Date(fecha.getFullYear()+'-12-31 00:00:00');
    this.cartaService.traerTodas().subscribe(
      (res) => {
        // if (res.data.length > 0) {
        //   const cartas = res.data;
        //   trabajadores.forEach((dato: any) => {
        //     const result = cartas.filter((x: any) => x.fk_trabajador === dato.rut && (new Date(x.FechaEmision)) >= fechaInicio && (new Date(x.FechaEmision)) <= fechaFinal);
        //     dato.cantidad_cartas = result.length;
        
        //     if (param && dato.cantidad_cartas >= 8) {
        //       setTimeout(() => {
        //         messageService.add({ key: 'tc', severity: 'info', summary: ' Alerta Cartas Amonestación', detail: `${dato.nombres} ${dato.apellidos}` });
        //       }, 1000);
        //     }
        //   });
        // }
        if(res.data.length > 0){
          const cartas = res.data;
          trabajadores.map(function (dato: any) {
            const result = cartas.filter((x:any)=>{ return x.fk_trabajador == dato.rut && (new Date(x.FechaEmision)) >= fechaInicio && (new Date(x.FechaEmision)) <= fechaFinal})
            dato.cantidad_cartas = result.length;
            if(param){
              if(dato.cantidad_cartas >= 8){
                setTimeout(() => {
                  messageService.add({ key: 'tc', severity: 'info', summary: ' Alerta Cartas Amonestación', detail: dato.nombres +' '+ dato.apellidos });
                }, 1000);
              }
            }
          });
        }
        this.dataSource = new MatTableDataSource<any>(trabajadores);
        this.configurarTabla(this.dataSource);
      }
    );
    
  }

  // ORDENA LOS DATOS
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  // APLICA EL FILTRO A LA TABLA SEGUN LO INGRESADO EN EL INPUT DE BUSQUEDA
  applyFilter(textSearch: string) {
    this.textSearch = textSearch;
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }
  viewDatosFiniquitados(viewFiniquito: boolean){
    this.trabajadorFilter = viewFiniquito ? 
      this.lista_trabajadores : this.lista_trabajadores.filter(x => x.estado !== 0);
    this.dataSource = new MatTableDataSource<any>(this.trabajadorFilter);
    this.configurarTabla(this.dataSource);
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  configurarTabla(dataSource) {
    dataSource.paginator = this.paginator;
    // CONFIGURA EL FILTRADO DE DATOS
    dataSource.filterPredicate = (data:any, filter:string) => {
      return this.configurarFilterPredicate(data, filter);
    };
    // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
    dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'empresa': return item.Empresa_contratante.nombre;
        default: return item[property];
      }
    }
    dataSource.sort = this.sort;
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLowerCase().split(' ');

    const rutSinPuntos: string = cleanRut(data.rut.toString());
    const nombre: string = this.normalizeText(data.nombres+ ' ' +data.apellidos).toLowerCase();
    const empresa: string = this.normalizeText(data.Empresa_contratante.nombre).toLowerCase();
    const estado_contrato: string = data.estado_contrato.toLowerCase();

    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.rut.toString().includes(x) 
        || nombre.includes(x) || empresa.includes(x) || estado_contrato.includes(x)
    );
  }

  // Función que elimina los diacríticos de las vocales de una cadena
  normalizeText(texto: string): string {
    return texto?.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }
}