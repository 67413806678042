import { Component, OnInit } from '@angular/core';
import { FirmasService } from 'src/app/pages/services/firmas.service';
import { FirmaDataSource } from 'src/app/shared/models/firmas.interface';

@Component({
  selector: 'app-firmas-main',
  templateUrl: './firmas-main.component.html',
  styleUrls: ['./firmas-main.component.css']
})
export class FirmasMainComponent implements OnInit {
  firmas: FirmaDataSource[] = [];
  constructor(
    private firmaService: FirmasService,
  ) { }

  async ngOnInit(): Promise<void> {
    const res = await this.firmaService.getFirmas();
    if (res.success) {
      this.firmas = res.data;
    }else{
      alert(res.msg);
    }
  }

  async TraerFirmas(){
    const res = await this.firmaService.getFirmas();
    if (res.success) {
      this.firmas = res.data;
    }else{
      alert(res.msg);
    }
  }

}