<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput [(ngModel)]="textoBuscar" (keyup)="applyFilter()" placeholder="texto a buscar" #input>
    <button mat-icon-button matSuffix (click)="quitarFiltro()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<mat-form-field class="ml-2">
    <input matInput [matDatepicker]="dpDesde" placeholder="Desde" [formControl]="dateDesde" readonly>
    <mat-datepicker-toggle matSuffix [for]="dpDesde"></mat-datepicker-toggle>
    <button mat-icon-button matSuffix (click)="limpiarDateDesde()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker #dpDesde
                    startView="multi-year"
                    (yearSelected)="chosenYearHandlerDesde($event)"
                    (monthSelected)="chosenMonthHandlerDesde($event, dpDesde)"
                    panelClass="example-month-picker">
    </mat-datepicker>
</mat-form-field>
<mat-form-field class="ml-2">
    <input matInput [matDatepicker]="dpHasta" placeholder="Hasta" [formControl]="dateHasta" readonly>
    <mat-datepicker-toggle matSuffix [for]="dpHasta"></mat-datepicker-toggle>
    <button mat-icon-button matSuffix (click)="limpiarDateHasta()">
        <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker #dpHasta
                    startView="multi-year"
                    (yearSelected)="chosenYearHandlerHasta($event)"
                    (monthSelected)="chosenMonthHandlerHasta($event, dpHasta)"
                    panelClass="example-month-picker">
    </mat-datepicker>
</mat-form-field>
<!-- <div>
    <button
    style="background-color: rgb(207, 51, 3);"
    (click)="GenerarPdf()"
    mat-raised-button
    >
    Exportar a PDF
    </button>
  </div> -->
  <div class="inline ml-2">
    <button
    (click)="descargarLiquidaciones()"
    style="background-color: rgb(207, 51, 3);"
    mat-raised-button
    >
    Exportar a PDF
    </button>
  </div>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> FECHA </th>
            <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.fecha | date:'MMMM - yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="haberes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> HABERES ($) </th>
            <td class="padding-table" mat-cell *matCellDef="let element"> {{element.haberes | number }} </td>
        </ng-container>

        <ng-container matColumnDef="descuentos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DESCUENTOS ($) </th>
            <td mat-cell *matCellDef="let element"> {{ element.descuentos | number }} </td>
        </ng-container>

        <ng-container matColumnDef="alcanceLiquido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ALCANCE LÍQUIDO ($) </th>
            <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.alcanceLiquido | number }}</td>
        </ng-container>

        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef> ESTADO </th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <p-message *ngIf="element.estado === null" severity="info" text="PENDIENTE"></p-message>
                <p-message *ngIf="element.estado === 0" severity="warn" text="RECTIFICADA"></p-message>
                <p-message *ngIf="element.estado === 1" severity="success" text="APROBADA"></p-message>
                <p-message *ngIf="element.estado === 2" severity="error" text="RECHAZADA"></p-message>
                <span *ngIf="">
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button mat-raised-button class="button_color_primary mr-2" style="background-color:rgb(37, 199, 228)"
                    [matMenuTriggerFor]="menuView">
                    <mat-icon style="color: white">
                        <span class="material-symbols-outlined">
                            visibility
                        </span>
                    </mat-icon>
                </button>
                <mat-menu #menuView="matMenu">
                    <button *ngIf="element.eliminado !== 1" mat-menu-item (click)="Descargar(element, 1)">
                        <mat-icon>receipt</mat-icon>
                        Visualizar liquidación
                    </button>
                    <button mat-menu-item (click)="Descargar(element, 2)"
                        *ngIf="element.firmada && element.estado === 1">
                        <mat-icon>receipt</mat-icon>
                        Visualizar liquidación firmada
                    </button>
                    <button mat-menu-item (click)="verHistorial(element.id, viewHistorialDecisiones)">
                        <mat-icon>history</mat-icon>
                        Visualizar historial de aprobaciones
                    </button>
                </mat-menu>
                <!-- BOTON SUBIR LIQUIDACION FIRMADA -->
                <button mat-raised-button [ngClass]="{'bg-warning': !element.firmada, 'bg-primary': element.firmada}"
                    *ngIf="element.estado === 1"
                    [pTooltip]="!element.firmada ? 'Subir liquidación firmada' : 'Cambiar liquidación firmada'"
                    tooltipPosition="bottom" (click)="openLiquidacionFirmada(element, modalLiquidacion)">
                    <mat-icon style="color: white" *ngIf="!element.firmada">file_upload</mat-icon>
                    <mat-icon style="color: white" *ngIf="element.firmada">restore_page</mat-icon>
                </button>
                <button *ngIf="element.estado === 2 || element.estado === null" mat-raised-button color="primary" [matMenuTriggerFor]="menuAction"
                    >
                    <mat-icon style="color: white">more_vert</mat-icon>
                </button>
                <mat-menu #menuAction="matMenu" class="p-0">
                    <button mat-menu-item (click)="EditarLiquidacion(element.id, contenidos)"
                        style="background-color: #f2d066;" class="border border-dark">
                        <mat-icon>edit_square</mat-icon>
                        <span class="font-weight-bold">Editar liquidación</span>
                    </button>
                    <button mat-menu-item (click)="Borrar(element.id)" style="background-color: #ff8780;"
                        class="border border-dark">
                        <mat-icon>delete</mat-icon>
                        <span class="font-weight-bold">Borrar liquidación</span>
                    </button>
                </mat-menu>
                <button *ngIf="element.estado === 0" mat-raised-button color="warn" (click)="Borrar(element.id)">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1]" showFirstLastButtons
        aria-label="Seleccionar pagina de Liquidaciones.">
    </mat-paginator>
</div>
<ng-template #viewHistorialDecisiones let-modal>
    <div class="modal-header">
        <mat-card-title class="modal-title">Historial de decisiones</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-modal-view-history [historyDecisionesLiquidacion]="aprobacionLiquidacion"></app-modal-view-history>
    </div>
</ng-template>
<!-- MODAL PARA MODIFICAR LIQUIDACIONES POR RUT -->
<ng-template #contenidos let-modal3>
    <div class="modal-header" (hidden.bs.modal)="onHidden($event)">
        <mat-card-title>Modificar Liquidación de {{trabajadorNombre | uppercase}}</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body container-fluid">
        <app-test-liquidaciones [rut]='RutTrabajador' [libroInput]="libro"
            (nombreTrabajador)="getNombreTrabajador($event)"></app-test-liquidaciones>
    </div>
</ng-template>
<!-- MODAL PARA SUBIR LIQUIDACION FIRMADA -->
<ng-template #modalLiquidacion let-modalLiquidacion>
    <div class="modal-header" (hidden.bs.modal)="onHidden($event)">
        <mat-card-title>Subir Liquidación Firmada de {{trabajadorNombre | uppercase}}</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modalLiquidacion.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
    
      <div class="modal-body container-fluid">
        <app-subir-liquidacion-firmada [idliquidacion]="idliquidacion" [rut]="RutTrabajador"></app-subir-liquidacion-firmada>
      </div>
  </ng-template>
