import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import { Permiso, RequestResponseGetAllPermiso } from 'src/app/shared/models/permisos.interface';


@Injectable({
  providedIn: 'root'
})
export class PermisoService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

  GetAll_permisos(): Observable<RequestResponseGetAllPermiso> {
    return this.http
    .get<RequestResponseGetAllPermiso >(`${environment.API}/rrhh/permiso/`)
    .pipe(
      map((res:RequestResponseGetAllPermiso) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  // GetAll_roles(): Observable<RequestResponse> {
  //   return this.http
  //   .get<RequestResponse >(`${environment.API}/rrhh/rol/`)
  //   .pipe(
  //     map((res:RequestResponse) =>{
  //       return res
  //     }),
  //     catchError((err)=> this.handlerError(err))
  //   );
  // }

  actualizar_permisos(permisos:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse>(`${environment.API}/rrhh/permiso/actualizar/ ${permisos.id}`,permisos)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Create_permiso(permiso:any): Observable<RequestResponse> {
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/permiso/crear`,permiso)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  
  Subir_Documento(permiso:any): Observable<RequestResponse> {
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/permiso/documento`,permiso)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorId(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/permiso/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerArchivo(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarPermisosId/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  
  TraerPorIdSolicitudPermiso(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/permiso/bySolicitud/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerPermisosRutAsync(rut:number): Promise<RequestResponse> {
    try {
      return  this.http.get<RequestResponse>(`${environment.API}/rrhh/permiso/byRut/${rut}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener los permisos' } as RequestResponse;
    }
  }
  async TraerArchivoIdAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/documento/buscarPermisosId/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener el archivo' } as RequestResponse;
    }
  }

  TraerArchivoId(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarPermisosId/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Eliminar(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/permiso/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

}