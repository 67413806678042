<div class="animate__animated animate__fadeInRight">
    <div class="row container-fluid">
        <div class="col-12">
            <mat-card class="mb-2">
                <h1 class>Modulo de Regiones</h1>
            </mat-card>
        
            <mat-card>
                <div class="row">
                    <div class="col-12">
                        <app-agregar-regiones (actualizarTabla)="actualizarTabla()"></app-agregar-regiones>
                    </div>
                    <div class="col-12">
                        <app-lista-regiones (actualizarTabla)="actualizarTabla()" [regiones]="regiones"></app-lista-regiones>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>      
</div>
