import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';  // Necesario para utilizar paginator y filter
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { cleanRut } from 'rutlib/lib';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { FuncionesLiquidacionesService } from 'src/app/pages/services/liquidaciones/funciones-liquidaciones.service';

@Component({
  selector: 'app-main-liquidaciones',
  templateUrl: './main-liquidaciones.component.html',
  styleUrls: ['./main-liquidaciones.component.css'],
  providers: [DatePipe]
})
export class MainLiquidacionesComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private funcionesLiquidacionesService: FuncionesLiquidacionesService,
    private _liveAnnouncer: LiveAnnouncer,
  ) {
    this.lista_trabajadores = [];
    this.trabajadorFilter = [];
    this.num_trab_finiquitados = 0;
    this.nombreTrabajador = '';
    this.viewFiniquitados = false;
    this.textoBuscar = '';
  }

  estado_contrato = [
    { id: 1, valor: 'Contrato A Plazo Fijo' },
    { id: 2, valor: 'Contratado' },
    { id: 3, valor: 'Contrato Por Proyecto' },
    { id: 4, valor: 'Finiquitado' },
    { id: 5, valor: 'freelance' },
  ]

  displayedColumns = [
    'rut',
    'nombres',
    'empresa',
    'estado_contrato',
    'acciones'
  ];

  dataSource: any;
  lista_trabajadores: any[];
  trabajadorFilter: any[];
  RutTrabajador: number;
  num_trab_finiquitados: number;
  nombreTrabajador: string;
  viewFiniquitados: boolean;
  textoBuscar:string;
  @ViewChild(MatPaginator) paginator!: MatPaginator;   // variable para asignar paginator a matdatatable
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(textSearch: string) {
    //Funcion para filtar cualquier columna por el texto buscado
    this.textoBuscar = textSearch;
    this.dataSource.filter = this.textoBuscar.trim().toLocaleLowerCase();
    this.dataSource.paginator.firstPage();
  }

  open2(content2: any, rut: any) {
    this.nombreTrabajador = '';
    this.modalService.open(content2, { size: 'xl' });
    this.RutTrabajador = rut;
  }

  open(content: any, rut: any) {
    this.nombreTrabajador = '';
    this.modalService.open(content, { size: 'lg' });
    this.RutTrabajador = rut;
  }

  open3(contenidos: any, rut: any) {
    this.nombreTrabajador = '';
    this.RutTrabajador = rut;
    this.modalService.open(contenidos, { fullscreen: true, scrollable: true });
  }

  open4(contenidos2: any) {
    this.nombreTrabajador = '';
    this.modalService.open(contenidos2, { size: 'xl' });
  }

  quitarFiltro(){
    this.textoBuscar = '';
    this.dataSource.filter = this.textoBuscar;
  }


  ngOnInit(): void {
    this.TraerDatos();
  }

  TraerDatos() {
    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        this.lista_trabajadores = res.data.map(x => (
          {rut: x.rut, nombres: x.nombres+ ' ' +x.apellidos, estado: x.estado, estado_contrato: x.estado_contrato, 
          nombreEmpresa: x.Empresa_contratante.nombre}
        ));
        // CONTAR LOS TRABAJADORES FINIQUITADOS
        this.num_trab_finiquitados = this.lista_trabajadores.reduce((a, e) => e.estado === 0 ? a + 1 : a, 0);
        
        this.trabajadorFilter =  this.lista_trabajadores.filter(x => x.estado_contrato !== 'Finiquitado' && x.estado !== 0);

        //Asignacion de dataSource con el tipo MatTableDataSource (necesario para poder agregar los atributos .paginator y .filter)
        this.dataSource = new MatTableDataSource(this.trabajadorFilter);
        this.dataSource = this.configurarTabla(this.dataSource);
      }
    );
  }
  configurarTabla(dataSource: any) {
    dataSource.filterPredicate = (data:any, filter: string) => {
      return this.configurarFilterPredicate(data, filter)
    }

    dataSource.paginator = this.paginator;
    // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
    dataSource.sortingDataAccessor = (item, property) => {
      // console.log({property}, {item});
      switch (property) {
        case 'empresa': return item.nombreEmpresa;
        default: return item[property];
      }
    }
    dataSource.sort = this.sort;
    return dataSource;
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLocaleLowerCase().split(' ');
    
    const rutSinPuntos: string = cleanRut(data.rut.toString());
    const nombre = this.normalizeText(data.nombres.toLocaleLowerCase());
    const nombreEmpresa = this.normalizeText(data.nombreEmpresa.toLocaleLowerCase());
    const estado_contrato = this.normalizeText(data.estado_contrato.toLocaleLowerCase());

    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.rut.toString().includes(x) 
        || nombre.includes(x) || nombreEmpresa.includes(x) || estado_contrato.includes(x)
    );
  }
  // PARA EFECTOS DE BUSQUEDA O SABER SI UN TEXTO, QUE TIENE TILDES, MAYUS, MINUS SE INCLUYE EN OTRO, 
  // SE DEBE NORMALIZAR LA CADENA DE TEXTO
  normalizeText(texto: string): string {
    return texto.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }
  // ORDENA LOS DATOS
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  viewDatosFiniquitados(viewFiniquito: boolean) {
    this.trabajadorFilter = viewFiniquito 
      ? this.lista_trabajadores 
      : this.lista_trabajadores.filter(x => x.estado !== 0);

    this.dataSource = new MatTableDataSource<any>(this.trabajadorFilter);
    this.dataSource = this.configurarTabla(this.dataSource);
    this.dataSource.filter = this.textoBuscar.trim().toLowerCase();
  }
  // Consigue el nombre del trabajador una vez que se carga en el modal
  getNombreTrabajador(nombre: string) {
    this.nombreTrabajador = nombre;
  }

}