<div class="mt-3 container-fluid animate__animated animate__slideInUp">
    <mat-card>
        <mat-card-title>Generar Excel de Pago</mat-card-title>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content>
            <app-generar-excel-pago [empresasList]="empresas"></app-generar-excel-pago>
        </mat-card-content>
    </mat-card>
</div>