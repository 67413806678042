import { Injectable } from '@angular/core';
import { formatRut } from 'rutlib';
import conversor from 'conversor-numero-a-letras-es-ar';

@Injectable({
  providedIn: 'root'
})
export class GenerarfiniquitoService {

  constructor() { }
  pdfMake:any; 
  prefijo: string;
  ClaseConversor = conversor.conversorNumerosALetras;
  miConversor = new this.ClaseConversor();
  totalAPagar: number;
  fontSizeStandard = 9;
  prefijoRepresentante: string;
  tablaDetalle: any = [];
  meses: any;

  nombresMeses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  fechaActual = new Date();

  // Obtener el mes actual (los meses en JavaScript van de 0 a 11)
  mesActual = this.fechaActual.getMonth(); 
  
  // Obtener el año actual
  anioActual = this.fechaActual.getFullYear();
  nombreMesActual = this.nombresMeses[this.mesActual]
  

  async loadPDFMake(){
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  async convertToPDF(datos: any){
    await this.loadPDFMake();

    var fonts = {
      Courier: {
        normal: 'Courier',
        bold: 'Courier-Bold',
        italics: 'Courier-Oblique',
        bolditalics: 'Courier-BoldOblique'
      },
      Helvetica: {
        normal: 'Helvetica',
        bold: 'Helvetica-Bold',
        italics: 'Helvetica-Oblique',
        bolditalics: 'Helvetica-BoldOblique'
      },
      Times: {
        normal: 'Times-Roman',
        bold: 'Times-Bold',
        italics: 'Times-Italic',
        bolditalics: 'Times-BoldItalic'
      },
      Symbol: {
        normal: 'Symbol'
      },
      ZapfDingbats: {
        normal: 'ZapfDingbats'
      }
    };

    let contenido = this.pdfContenido(datos);
    const documento = {
      content: [
        ...contenido
      ],
      styles: {
        header: {
          fontSize: 11,
          bold: true,
        },
        normal: {
          fontFamily: fonts.Courier.normal,
          fontSize: 10,
          bold: false,
        },
        headerTable: {
          fontSize: 10,
          bold: true,
        },
        textTable: {
          fontSize: 10,
          bold: false
        },
        justified: {
          alignment: 'justify'
        },
      }
    }
    this.pdfMake.createPdf(documento).open();
  }

  pdfContenido(datos: any){
    console.log(datos);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const options1: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const options3: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };


    let rut_trabajador = formatRut(datos.rutTrabajador.toString());
   
    let fechaingreso = datos.fechaIngreso+'T00:00:00';
    let fecha_ingreso = new Date(fechaingreso);

    const feriadoProporcionalRem = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP'
    }).format(datos.feriadoProporcionalRemuneracion);

    const remuneracionUltMes = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(datos.remuneracionUltimoMes);

    const suma_total = new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
    }).format(datos.sumaTotal);

    if(datos.trabajadorSexo === "F"){
      this.prefijo = "doña";
    }else{
      this.prefijo = "don";
    }

    if(datos.idEmpresaContratante == 11){
      this.prefijoRepresentante = "doña";
    }else{
      this.prefijoRepresentante = "don";
    }

    this.totalAPagar = datos.sumaTotal;

    let valorEnLetras = this.dinerilloATexto();

      let feriadoProporcional = {
        diasHabiles:  Math.round(datos.feriadosProporcionalDiasHabiles * 100) / 100,
        diasCorridos: Math.round(datos.feriadosProporcionalDiasCorridos * 100) / 100,
        monto: '$ ' + feriadoProporcionalRem,
      };

      const feriadoLegal = {
        diasHabiles: Math.round(datos.feriadoLegalDiasHabiles * 100) / 100,
        diasCorridos: Math.round(datos.feriadoLegalDiasInhabiles * 100) / 100,
        monto: '$ '+ datos.feriadoLegalRemuneracion,
      }
    
      let remuneracionNoviembre = remuneracionUltMes;
      let totalPagar = suma_total;

      let parrafos0 = [
        { 
          text: `En ${datos.ciudadEmpresa}, a ${datos.fechaHoy.toLocaleDateString('es-CL', options)}, comparece como trabajador ${this.prefijo} `, margin: [0, 0, 0, 20],
        },
        { 
          text: datos.nombreTrabajador, bold: true
        },
        {
          text: ` cédula nacional de identidad N° ${rut_trabajador}, ${datos.nacionalidadTrabajador}, domiciliado en ${datos.direccionTrabajador}, comuna de ${datos.comunaTrabajador}, ${datos.regionTrabajadorNombre}, y como empleador `
        },
        {
          text: datos.empresaContratante, bold: true
        },
        {
          text: `, Rol Único Tributario, N° ${datos.rutEmpresa}, representada legalmente por ${this.prefijoRepresentante} ${datos.representanteEmpresa}, cédula nacional de identidad N° ${datos.rutRepresentante}, ambos con domicilio en Calle 10 Norte, N°85, comuna y ciudad de Talca, Región del Maule, correo electrónico recepcionrrhh@grupofirma.cl, entre quienes se acordó el siguiente finiquito: `
        }
      ]

      let parrafos1 = [
        {
          text: "PRIMERO: ", bold: true
        },
        {
          text: `${this.prefijo} `,
        },
        {
          text: datos.nombreTrabajador, bold: true
        },
        {
          text: ` ingresó a prestar servicios a la empresa el `
        },
        {
          text: fecha_ingreso.toLocaleDateString('es-CL', options1), bold: true
        },
        {
          text: `, teniendo al término de sus servicios la función de `
        },
        {
          text: `"`, bold: true
        },
        {
          text: datos.funcion, bold: true
        },
        {
          text: `".`, bold: true
        },
      ]

      let parrafos2 = [
        {
          text: "SEGUNDO: ", bold: true
        },
        {
          text: `El término de la relación laboral se hizo efectiva a partir del día `,
        },
        {
          text: datos.fechaTerminoLaboral.toLocaleDateString('es-CL', options), bold: true
        },
        {
          text: `, por en virtud de la causal establecida en el `
        },
        {
          text: datos.articuloCodigo, bold: true
        },
        {
          text: ` del Código del Trabajo, esto es “`
        },
        {
          text: datos.articuloNombre, bold: true, italics: true
        },
        {
          text: `".`,
        },
      ]

      let parrafos3 = [
        {
          text: "TERCERO: ", bold: true
        },
        {
          text: `El trabajador declara haber recibido oportunamente de su empleador a su entera satisfacción, todas sus remuneraciones y demás prestaciones `,
        },
        {
          text: datos.termino
        },
        {
          text: `, recibiendo además, en este acto la suma de `
        },
        {
          text: suma_total, bold: true
        },
        {
          text: `.- (`
        },
        {
          text: valorEnLetras
        },
        {
          text: `), de acuerdo al siguiente detalle:`,
        },
      ]

      let parrafos4 = [
        {
          text: "CUARTO: ", bold: true
        },
        {
          text: `Declara además `,
        },
        {
          text: this.prefijo
        },
        {
          text: " " + datos.nombreTrabajador, bold: true
        },
        {
          text: `, que durante el tiempo que prestó servicios a la empresa, recibió oportunamente de esta el total de las remuneraciones, beneficios y demás prestaciones estipuladas que hayan derivado o deriven de disposiciones legales u otras normas obligatorias o de cualquier naturaleza u origen. En consecuencia, expresa, que el empleador nada le adeuda por causa o motivo alguno, legal, contractual o de otro orden, sea relacionado con la prestación de los servicios o la terminación de estos, en especial, con la causal de término de la relación laboral.`
        },
      ]

      let parrafos4p2 = [
        {
          text: "A mayor abundamiento, ", textIndent: 20
        },
        {
          text: this.prefijo
        },
        {
          text: " " + datos.nombreTrabajador, bold: true
        },
        {
          text: " manifiesta expresamente no tener cargo ni reclamo alguno que formular en contra de su ex empleador, "
        },
        {
          text: datos.empresaContratante, bold: true
        },
        {
          text: `, razón por la cual, libre y voluntariamente y con pleno cabal conocimiento de sus derechos, otorga a ésta y a su representante, el más amplio, completo, total y definitivo finiquito.`
        }
      ]

      let parrafos5 = [
        {
          text: "QUINTO: ", bold: true
        },
        {
          text: `El empleador individualizado, declara bajo juramento, que no ha sido notificado por tribunal alguno, para efectuar retención por concepto de pensión de alimentos al trabajador, de conformidad a la ley `
        },
        {
          text: `Nº 21.389 del registro de deudores de pensiones de alimentos.`, bold: true
        },
      ]

      let parrafos5p2 = [
        {
          text: "Las cotizaciones previsionales se encuentran al día y las que correspondan al último mes serán enteradas en tiempo y forma en las respectivas entidades a las cuales la trabajadora se encuentra afiliada."
        },
      ]

      let parrafos5p3 = [
        {
          text: "Se firma el presente documento en dos ejemplares iguales y del mismo tenor, declarando las partes haber recibido una copia."
        },
      ]

      this.meses = datos.meses;

      this.tablaDetalle = [];

      if (datos.feriadoLegalDiasHabiles > 0) {
        this.tablaDetalle.push(
          [
              { text: `-  Feriado legal(${feriadoLegal.diasHabiles} días hábiles - ${feriadoLegal.diasCorridos} días corridos)`, alignment: 'left', lineHeight: 1.5 },
              { text: '', alignment: 'right' },
              { text: feriadoLegal.monto, alignment: 'right', lineHeight: 1.5 },
          ]
       );
      }

      if (this.meses >= 1) {
        this.tablaDetalle.push(
            [
                { text: `-  Feriado proporcional(${feriadoProporcional.diasHabiles} días hábiles - ${feriadoProporcional.diasCorridos} días corridos)`, alignment: 'left', lineHeight: 1.5 },
                { text: '', alignment: 'right' },
                { text: feriadoProporcionalRem, alignment: 'right', lineHeight: 1.5 },
            ]
        );
    }
    
    this.tablaDetalle.push(
        [
            { text: `-  Remuneración mes de ${datos.fechaTerminoLaboral.toLocaleDateString('es-CL', options3)}`, alignment: 'left', lineHeight: 1.5 },
            { text: '', alignment: 'right' },
            { text: remuneracionNoviembre, alignment: 'right', lineHeight: 1.5 },
        ],
        [
            { text: 'TOTAL A PAGAR', alignment: 'right', bold: true, lineHeight: 1.5 },
            { text: '', alignment: 'right' },
            { text: suma_total, alignment: 'right', bold: true, lineHeight: 1.5 },
        ]
    );

    
    
      return [
        {
        },
        {
          margin: [-10, 5, -10, 0],
          stack: [
            {
              text: 'FINIQUITO DE TRABAJO',
              style: 'header',
              alignment: 'center',
              bold: true,
              width: '*',
            },
            {
              width: '*',
              text: parrafos0,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 40, 40, 10],
            },
            {
              width: '*',
              text: parrafos1,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              width: '*',
              text: parrafos2,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              width: '*',
              text: parrafos3,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              table: {
                body: this.tablaDetalle,
              },
              layout: 'noBorders',
              margin: [75, 25, 40, 40],
              alignment: 'center',
            },
            {
              width: '*',
              text: parrafos4,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              width: '*',
              text: parrafos4p2,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              width: '*',
              text: parrafos5,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              width: '*',
              text: parrafos5p2,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
            {
              width: '*',
              text: parrafos5p3,
              style: 'justified',
              lineHeight: 1.5,
              margin: [40, 10, 40, 10],
            },
          ],
        },
        {
          width: '*',
          margin: [40, 150, 40, 25],
          table: {
            widths: ['*', '*'],
            body: [
             
              [
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                },
                {
                  canvas: [
                    {
                      type: 'line',
                      x1: 0,
                      y1: 0,
                      x2: 200,
                      y2: 0,
                      lineWidth: 1,
                    },
                  ],
                },
              ],
              [
                { text: `${datos.nombreTrabajador}`, alignment: 'center', bold: true },
                { text: `${datos.empresaContratante}`, alignment: 'center', bold: true },
              ],
              [
                { text: `C.I N°${rut_trabajador}`, alignment: 'center', margin: [0, 5, 0, 0], bold: true },
                { text: `R.U.T N°${datos.rutEmpresa}`, alignment: 'center', margin: [0, 5, 0, 0], bold: true },
              ],
              [
                { text: 'TRABAJADOR', alignment: 'center', margin: [0, 5, 0, 0], bold: true },
                { text: 'EMPLEADOR', alignment: 'center', margin: [0, 5, 0, 0], bold: true },
              ],
            ],
          },
          layout: 'noBorders',
        },
      ];     
  }

  dinerilloATexto() {
    let varia = Math.round(this.totalAPagar)
    if (varia < 0) {
      return {
        fontSize: this.fontSizeStandard,
        text: this.miConversor.convertToText(Math.abs(varia)) + ' PESOS',
      }
    } else {
      return {
        fontSize: this.fontSizeStandard,
        text: this.miConversor.convertToText(varia).toUpperCase() + ' PESOS',
      }
    }
  }
}
