import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { EstadoCivil } from 'src/app/shared/models/estado-civil.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EstadoCivilService } from 'src/app/pages/services/estado-civil.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-estado-civil',
  templateUrl: './lista-estado-civil.component.html',
  styleUrls: ['./lista-estado-civil.component.css']
})
export class ListaEstadoCivilComponent implements OnInit {

  @Input() estadoCivil_List: EstadoCivil[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  update_estadoCivil: EstadoCivil = {
    id: 0,
    nombre: ""
  };

  //dataCiudad: Ciudad[] = [];

  displayedColumns: string[] = ['id', 'nombre', 'actions'];
  dataSource = new MatTableDataSource<EstadoCivil>(this.estadoCivil_List);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private estadoCivilService: EstadoCivilService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes.estadoCivil_List != undefined) {
      if (!changes.estadoCivil_List.firstChange) {
        this.dataSource = new MatTableDataSource<EstadoCivil>(this.estadoCivil_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  open(content: any, id: number) {
    this.update_estadoCivil.id = id
    this.estadoCivilService.Obtener_estado(id).subscribe(res => {
      this.update_estadoCivil.nombre = res.data.nombre;
    })

    this.limpiar_estado_civil();
    this.modalService.open(content);

  }

  limpiar_estado_civil() {
    this.update_estadoCivil.nombre = ""
  }

  Actualizar_EstadoCivil() {
    if (this.update_estadoCivil.nombre.trim().length == 0) {
      this.alert.error_small('Los campos no pueden estar vacios.')
      return
    }

    this.estadoCivilService.Actualizar_estadoCivil(this.update_estadoCivil).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.limpiar_estado_civil();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })


  }

  Eliminar_EstadoCivil(estadoCivilcontent: any, id: number) {

    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el Prevision salud",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.estadoCivilService.Eliminar_EstadoCivil(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }


    //FITRAL
    applyFilter(event: Event) {
      //Funcion para filtar cualquier columna por el texto buscado
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
