<div class="animate__animated animate__fadeInRight">
    <div class="row container-fluid">
        <div class="col-12">
            <mat-card class="mat_card_roles">
                <h1 class>Mantenedor de Motivos de Carta de Amonestación</h1>
            </mat-card>
        </div>
        <div class="col-12">
            <mat-card class="mat-elevation-z5 mat_card_roles_content">
                <app-agregar-motivo (actualizarTabla)="TraerMotivos()"></app-agregar-motivo>
            </mat-card>
        </div>
        <div class="col-12">
            <mat-card class="mat-elevation-z5 mat_card_roles_content">
                <app-listar-motivos [motivos]="motivos" (actualizarTabla)="TraerMotivos()"></app-listar-motivos>
            </mat-card>
        </div>
    </div>
</div>