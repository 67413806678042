import { Injectable } from '@angular/core';
import { TrabajadoresService } from '../../trabajadores/trabajadores.service';
import { OpcionElegidaCertificado } from 'src/app/shared/models/opcioneselegidas.interface';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { EmpresaService } from '../../empresas.service';
import { contabilidadService } from '../../contabilidad/contabilidad.service';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import { CiudadService } from '../../ciudades.service';
import { FormatoRutPipe } from 'src/app/formato-rut.pipe';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FactorActualizacion, UF } from 'src/app/shared/models/valoresUF.interface';
import { CertificadoSueldo, CertificadoSueldoBD, TablaMontos, Totales } from 'src/app/shared/models/certificadosueldo/certificadosueldo.interface';
import { CertificadoSueldoService } from '../../certificadosueldo/certificadosueldo.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GenerarCertificadoSueldoService {
  constructor(
    private trabajadorservice:TrabajadoresService,
    private empresaService:EmpresaService,
    private ciudadService: CiudadService,
    private ventaService:contabilidadService,
    private certificadoService:CertificadoSueldoService,
    private formatoRut:FormatoRutPipe,
    private alert:AlertHelper,
  ) { }

  certificadoSueldo:CertificadoSueldo;
  factores:FactorActualizacion[];
  totalesMontos:Totales;
  empresa:Empresa;
  trabajador:any;
  ciudad:Ciudad;
  libroRemuneraciones:any[] = []; // LISTA DE LIQUIDACIONES
  anio:number = 0;
  pdfMake:any; 

  generarDocumento(opciones_elegidas: OpcionElegidaCertificado) {
    this.anio = opciones_elegidas.periodo_declatorio;
    // MODAL VISUAL CARGA DOCUMENTO
    Swal.fire({
      title: 'Generando el documento',
      allowOutsideClick: false,
      didOpen: () => {
          Swal.showLoading()
      }
    });

    this.empresaService.Obtener_empresa(opciones_elegidas.id_empresa)
      .subscribe(({ data: empresa }) => {
          this.empresa = empresa;
          this.ciudadService.Obtener_ciudad(this.empresa.ciudad).subscribe(({data}) => {
              this.ciudad = data;
          });
      });
    this.cargarDatosTrabajador(opciones_elegidas.rut_trabajador)
      .then((data) => {
        this.trabajador = data;
        this.ventaService.TraerTodo().subscribe(({data:libroContable}) => {
          this.libroRemuneraciones = libroContable.filter(lc => lc.rut === this.trabajador[0].rut);
          if (this.libroRemuneraciones.length <= 0 || !this.filtrarLiquidaciones()) {
            // console.log(this.libroRemuneraciones.length <= 0 || !this.filtrarLiquidaciones());
            this.alert.error_small('No existen registros en el libro.');
            return;
          }
          this.cargarHeader()
            .then(() => {
              this.obtenerFactorActualizacion(opciones_elegidas.periodo_declatorio).then((data:FactorActualizacion[]) => {
                this.cargarDatosTabla(data);
                this.factores = data;
                this.convertToPDF();
                this.certificadoService.getLastNumCertificado().subscribe(({data}) => {
                  console.log(data);
                  const certificado:CertificadoSueldoBD = {
                    anio: opciones_elegidas.periodo_declatorio,
                    idempresa: opciones_elegidas.id_empresa,
                    idtrabajador: parseInt(opciones_elegidas.rut_trabajador),
                    numcertificado: data ? data+1 : 1,
                  }
                  this.certificadoService.crearCertificado(certificado).subscribe(({data}) => {
                    Swal.close();
                    this.alert.success_small('Se creo el certificado correctamente.');
                    console.log({data});
                  });
                })
              });
            })
          .catch(error => {
            Swal.close();
            this.alert.error_small('Ocurrio un error innesperado.')
            console.error(error);
          })
        });
      });
  }

  // CREA EL REGISTRO EN LA BASE DE DATOS
  crearRegistroCertificadoSueldo(opciones_elegidas: OpcionElegidaCertificado){
    try {
      this.certificadoService.getLastNumCertificado()
      .subscribe(({data:num}) => {
        const certificadoSueldo : CertificadoSueldoBD = {
          idempresa: opciones_elegidas.id_empresa,
          idtrabajador: parseInt(opciones_elegidas.rut_trabajador),
          anio: opciones_elegidas.periodo_declatorio,
          numcertificado: num === null ? 1 : num + 1,
        } 
        this.certificadoService.crearCertificado(certificadoSueldo)
          .subscribe((res:any) => {
            this.alert.success_small('Certificado creado con exito.');
          });
      }); 
    } catch (error) {
      this.alert.error_small('Ocurrio un error innesperado.');
    }
  }

  // CARGA LA LIBRERIA PDFMAKE
  async loadPDFMake(){
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }
  // GENERAMOS EL PDF
  async convertToPDF(){
    await this.loadPDFMake();

    let contenido = this.pdfContenido();
    const documento = {
      pageSize: {
        width: 1250,
        height: 660
      },
      content: [
        ...contenido
      ],
      styles: {
        header: {
          fontSize: 11,
          bold: true,
        },
        normal: {
          fontSize: 10,
          bold: false,
        },
        headerTable: {
          fontSize: 10,
          bold: true,
        },
        textTable: {
          fontSize: 10,
          bold: false
        },
        justified: {
          alignment: 'justify'
        },
      }
    }
    this.pdfMake.createPdf(documento).open();
  }

  // CARGA EL DOCUMENTO DDJJ CON LOS DATOS  
  cargarDatosTabla(valoresUF:FactorActualizacion[]) {
    // INICIALIZAMOS EL OBJETO
    this.certificadoSueldo = {
      ...this.certificadoSueldo,
      tablaMontos: [],
    }

    this.totalesMontos = {
      totalCotizaciones: 0,
      totalImpuestoUnicoActualizado: 0,
      totalImpuestoUnicoSinActualizar: 0,
      totalMayorRetencionActualizado: 0,
      totalMayorRetencionSinActualizar: 0,
      totalRebajaZonaExtremaActualizado: 0,
      totalRebajaZonaExtremaSinActualizar: 0,
      totalRentaImponibleActualizado: 0,
      totalRentaImponibleSinActualizar: 0,
      totalRentaTotalExentaActualizado: 0,
      totalRentaTotalExentaSinActualizar: 0,
      totalRentaTotalNoGravadaActualizado: 0,
      totalRentaTotalNoGravadaSinActualizar: 0,
      totalSueldoBruto: 0,
    };

    this.libroRemuneraciones.forEach((elemento) => {
      const cotizaciones = elemento.prevision + elemento.salud;
      const factorActualizacion = valoresUF.find(x => x.mes === new Date(elemento.f_liquidacion+'T00:00:00').getMonth()).factor;

      const montosTabla:TablaMontos = {
        mes: new Date(elemento.f_liquidacion+'T00:00:00').toLocaleDateString('es', {month: 'long'}),
        mesIndex: new Date(elemento.f_liquidacion+'T00:00:00').getMonth(),
        sueldoBruto: elemento.totalimp,
        cotizaciones: cotizaciones,
        factorActualizacion: factorActualizacion,
        rentaImponible: {
          rentaImponibleSinActualizar: elemento.totalimp - cotizaciones,
          rentaImponibleActualizada: this.calcularActualizacionPrecios(elemento.totalimp - cotizaciones, factorActualizacion),
        },
        impuestoUnico: {
          impuestoUnicoSinActualizar: elemento.impuestounico,
          impuestoUnicoActualizado: this.calcularActualizacionPrecios(elemento.impuestounico, factorActualizacion),
        },
        mayorRetencion: {
          mayorRetencionSinActualizar: 0,
          mayorRetencionActualizado: 0,
        },
        rentaTotalExenta: {
          rentaTotalExentaSinActualizar: 0,
          rentaTotalExentaActualizado: 0,
        },
        rentaTotalNoGravada: {
          rentaTotalNoGravadaSinActualizar: elemento.totalnoimp,
          rentaTotalNoGravadaActualizado: this.calcularActualizacionPrecios(elemento.totalnoimp, factorActualizacion),
        },
        rebajaZonasExtremas: {
          rebajaZonaExtremaActualizado: 0,
          rebajaZonaExtremaSinActualizar: 0,
        },
      }
      this.totalesMontos.totalSueldoBruto += montosTabla.sueldoBruto;
      this.totalesMontos.totalCotizaciones += montosTabla.cotizaciones;
      this.totalesMontos.totalRentaImponibleActualizado += montosTabla.rentaImponible.rentaImponibleActualizada;
      this.totalesMontos.totalRentaImponibleSinActualizar += montosTabla.rentaImponible.rentaImponibleSinActualizar;
      this.totalesMontos.totalImpuestoUnicoActualizado += montosTabla.impuestoUnico.impuestoUnicoActualizado;
      this.totalesMontos.totalImpuestoUnicoSinActualizar += montosTabla.impuestoUnico.impuestoUnicoSinActualizar;
      this.totalesMontos.totalMayorRetencionActualizado += montosTabla.mayorRetencion.mayorRetencionActualizado;
      this.totalesMontos.totalMayorRetencionSinActualizar += montosTabla.mayorRetencion.mayorRetencionSinActualizar;
      this.totalesMontos.totalRentaTotalExentaActualizado += montosTabla.rentaTotalExenta.rentaTotalExentaActualizado;
      this.totalesMontos.totalRentaTotalExentaSinActualizar += montosTabla.rentaTotalExenta.rentaTotalExentaSinActualizar;
      this.totalesMontos.totalRentaTotalNoGravadaActualizado += montosTabla.rentaTotalNoGravada.rentaTotalNoGravadaActualizado;
      this.totalesMontos.totalRentaTotalNoGravadaSinActualizar += montosTabla.rentaTotalNoGravada.rentaTotalNoGravadaSinActualizar;
      this.totalesMontos.totalRebajaZonaExtremaActualizado += montosTabla.rebajaZonasExtremas.rebajaZonaExtremaActualizado;
      this.totalesMontos.totalRebajaZonaExtremaSinActualizar += montosTabla.rebajaZonasExtremas.rebajaZonaExtremaSinActualizar;

      this.certificadoSueldo.tablaMontos.push(montosTabla);
    });
    this.certificadoSueldo.totales = this.totalesMontos;
  }

  // REALIZA EL CALCULO DE LAS ACTUALIZACION DE LOS MONTOS
  calcularActualizacionPrecios(valor:number, factor:number){
    return valor * factor;
  }
  // GENERA EL CONTENIDO DEL PDF
  pdfContenido(){
    const trabajador = this.certificadoSueldo.trabajador[0];

    let textoInfo = `El Empleador, Habilitado o Pagador ${this.certificadoSueldo.empresa.nombre.toUpperCase()}, certifica que el(la) Sr(a). ${trabajador.nombres.toUpperCase()} ${trabajador.apellidos.toUpperCase()}, R.U.T. N° ${this.formatoRut.transform(trabajador.rut)}, en su calidad de empleado dependiente, jubilado, pensionado o montepiado, según corresponda, durante el año ${this.certificadoSueldo.fecha.getFullYear()}, se le han pagado las rentas que se indican y sobre las cuales se le practicaron las retenciones de impuestos que se señalan:`
    textoInfo = textoInfo.replace(/\n/g, '');

    const pdfMontos = this.pdfTablaMontos();
    return [
      {
        margin: [-10, -20, -10, -20],
        columns: [
          {
            width: 'auto',
            margin: [0, 0, 0, 25],
            alignment: 'left',
            table: {
              widths: 
              ['auto', 'auto'],
              body: [
                [
                  {text: 'Empleador, Habilitado o Pagador', style: 'normal', alignment: 'left'},
                  {text: ': '+this.certificadoSueldo.empresa.nombre, style: 'normal', alignment: 'left'},
                ],
                [
                  {text: 'RUT N°', style: 'normal', alignment: 'left'},
                  {text: ': '+this.certificadoSueldo.empresa.run, style: 'normal', alignment: 'left'},
                ],
                [
                  {text: 'Dirección', style: 'normal', alignment: 'left'},
                  {text: ': '+this.certificadoSueldo.empresa.ubicacion + ', ' + this.certificadoSueldo.empresa.ciudad, style: 'normal', alignment: 'left'},
                ],
                [
                  {text: 'Giro o Actividad', style: 'normal', alignment: 'left'},
                  {text: ': '+(this.certificadoSueldo.empresa.giro??''), style: 'normal', alignment: 'left'},
                ]
              ]
            },
            layout: 'noBorders',
          },
          {
            width: '*',
            margin: [0, 0, 0, 15],
            alignment: 'right',
            text: '',
          },
          {
            width: 'auto',
            margin: [0, 0, 0, 15],
            table: {
              widths: 
              ['auto', 'auto', 'auto'],
              body: [
                [
                  {text: 'CERTIFICADO N°', style: 'normal', alignment: 'left'},
                  {text: ':', style: 'normal', alignment: 'left'},
                  {text: this.certificadoSueldo.num_certificado, style: 'normal', alignment: 'right'},
                ],
                [
                  {text: 'FECHA', style: 'normal', alignment: 'left'},
                  {text: ':', style: 'normal', alignment: 'left'},
                  {text: this.certificadoSueldo.fecha.toLocaleDateString('default'), style: 'normal', alignment: 'right'},
                ]
              ]
            },
            layout: 'noBorders'
          }
        ],
      },
      {
        margin: [-10, 5, -10, 0],
        stack: [
          {
            text: 'CERTIFICADO SOBRE SUELDOS, PENSIONES O JUBILACIONES Y OTRAS RENTAS SIMILARES',
            style: 'header',
            alignment: 'center',
            width: '*',
          },
          {
            width: '*',
            text: textoInfo,
            style: 'justified',
            margin: [0, 10, 0, 0],
          }
        ],
      },
      {
        margin: [-10, 10, -10, 0],
        width: '*',
        table: {
          widths: [
            'auto','auto','auto','auto',
            'auto','auto','auto','auto',
            'auto','auto','auto','auto',
            'auto','auto','auto','auto',
          ],
          body: [
            [
              {text: 'PERIODOS', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'SUELDO\nBRUTO', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'COTIZACION\nPREVISIONAL O\nDE SALUD DE\nCARGO DEL\nTRABAJADOR', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'RENTA\nIMPONIBLE\nAFECTA AL\nIMPTO. UNICO\nDE 2a CAT.', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'IMPTO. UNICO\nRETENIDO', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'MAYOR RETENCION\nDE IMPTO\nSOLICITADA\n(ART. 88 L.I.R.)', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'RENTA\nTOTAL\nEXENTA', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'RENTA TOTAL\nNO GRAVADA', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'REBAJA POR\nZONAS EXTREMAS\n(FRANQUICIA D.L\n889)', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'FACTOR DE\nACTUALIZACION', style: 'headerTable', alignment: 'center', rowSpan: 2},
              {text: 'MONTOS ACTUALIZADOS', style: 'headerTable', alignment: 'center', colSpan: 6},
              {},{},{},{},{}
            ],
            [
              {},{},{},{},{},{},{},{},{},{},
              {text: 'RENTA AFECTA\nAL IMPTO. UNICO\nDE 2a CAT.', style: 'headerTable', alignment: 'center'},
              {text: 'IMPUESTO\nUNICO\nRETENIDO', style: 'headerTable', alignment: 'center'},
              {text: 'MAYOR\nRETENCION DE\nIMPTO.\nSOLICITADA\n(ART. 88 L.I.R.)', style: 'headerTable', alignment: 'center'},
              {text: 'RENTA TOTAL\nEXENTA', style: 'headerTable', alignment: 'center'},
              {text: 'RENTA TOTAL\nNO GRAVADA', style: 'headerTable', alignment: 'center'},
              {text: 'REBAJA POR\nZONAS EXTREMAS\n(FRANQUICIA D.L.\n889)', style: 'headerTable', alignment: 'center'},
            ],...pdfMontos
          ]
        }
      },
      {
        width: '*',
        margin: [980, 110, -5, 0],
        canvas: [
          {
            type: 'line',
            x1: 10,
            y1: 0,
            x2: 200,
            y2: 0,
            lineWidth: 1,
          },
        ],
      },
      {
        margin: [1000, 5, -5, 0],
        text: `${this.certificadoSueldo.empresa.representante}\n${this.certificadoSueldo.empresa.cedula}\nREPRESENTANTE LEGAL`, alignment: 'center', style: 'normal'
      }
    ]
  }
  // CARGA LA TABLA CON SUS DATOS CORRESPONDIENTES PARA EL PDF
  pdfTablaMontos(){
    let filas = [];
    const anio = this.certificadoSueldo.fecha.getFullYear();
    for (let mes = 0; mes < 13; mes++) {
      let fila = [];
      if (mes === 12) {
        const totalesRegistros = this.certificadoSueldo.totales;
        fila = [
          { text: 'TOTALES', style: 'textTable', alignment: 'left' },
          { text: this.formatearNumeros(totalesRegistros.totalSueldoBruto), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalCotizaciones), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRentaImponibleSinActualizar), style: 'textTable', alignment: 'center' },
          { text: this.formatearNumeros(totalesRegistros.totalImpuestoUnicoSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalMayorRetencionSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRentaTotalExentaSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRentaTotalNoGravadaSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRebajaZonaExtremaSinActualizar), style: 'textTable', alignment: 'right' },
          {},
          { text: this.formatearNumeros(totalesRegistros.totalRentaImponibleActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalImpuestoUnicoActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalMayorRetencionActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRentaTotalExentaActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRentaTotalNoGravadaActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(totalesRegistros.totalRebajaZonaExtremaActualizado), style: 'textTable', alignment: 'right' },
        ]
        filas.push(fila);
        continue;
      }
      const registro = this.certificadoSueldo.tablaMontos.find(x => x.mesIndex === mes);
      if (registro !== undefined) {
        fila = [
          { text: registro.mes.toUpperCase(), style: 'textTable', alignment: 'left' },
          { text: this.formatearNumeros(registro.sueldoBruto), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.cotizaciones), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rentaImponible.rentaImponibleSinActualizar), style: 'textTable', alignment: 'center' },
          { text: this.formatearNumeros(registro.impuestoUnico.impuestoUnicoSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.mayorRetencion.mayorRetencionSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rentaTotalExenta.rentaTotalExentaSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rentaTotalNoGravada.rentaTotalNoGravadaSinActualizar), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rebajaZonasExtremas.rebajaZonaExtremaSinActualizar), style: 'textTable', alignment: 'right' },
          { text: registro.factorActualizacion, style: 'textTable', alignment: 'center' },
          { text: this.formatearNumeros(registro.rentaImponible.rentaImponibleActualizada), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.impuestoUnico.impuestoUnicoActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.mayorRetencion.mayorRetencionActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rentaTotalExenta.rentaTotalExentaActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rentaTotalNoGravada.rentaTotalNoGravadaActualizado), style: 'textTable', alignment: 'right' },
          { text: this.formatearNumeros(registro.rebajaZonasExtremas.rebajaZonaExtremaActualizado), style: 'textTable', alignment: 'right' },
        ]
        filas.push(fila);
      }else{
        fila = [
          { text: this.traerMes(mes).toUpperCase(), style: 'textTable', alignment: 'left' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'center' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: this.factores.find(x => x.mes === mes).factor, style: 'textTable', alignment: 'center' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
          { text: 0, style: 'textTable', alignment: 'right' },
        ];
        filas.push(fila);
      }
    }
    return filas;
  }
  // TRAE EL MES DE ACUERDO AL INDEX
  traerMes(mes:number){
    const fecha = new Date(2023, mes);
    return fecha.toLocaleDateString('es', { month: 'long' });
  }
  // CARGA EL HEADER DEL DOCUMENTO CON LOS DATOS QUE SE OCUPARAN
  async cargarHeader(){
    return new Promise((resolve, reject) => {
      try {
        this.certificadoService.getLastNumCertificado().subscribe(({ data: num }) => {
          this.certificadoSueldo = {
            empresa: this.empresa,
            fecha: new Date(),
            num_certificado: num === null ? 1 : num,
            trabajador: this.trabajador,
          }
          resolve(1);
        });
      } catch (error) { 
        reject(error)
      }
    });
  }

  cargarDatosTrabajador(rut:string){
    return new Promise((resolve, reject) => {
      try {
        this.trabajadorservice.TraerTrabajadoresRut(rut)
        .subscribe(({data:trabajador}) => {
          resolve(trabajador);
        });
      } catch (error) {
        reject(error);
      }
    });  
  }

  // obtenerFactorActualizacion(anio:number){
  //   return new Promise((resolve, reject) => {
  //     const ufs : UF[] = [];
  //     const factores : FactorActualizacion[] = [];
  //     fetch('https://mindicador.cl/api/uf/'+anio)
  //       .then(res => res.json())
  //       .then(({serie}) => {
  //         // OBTENGO LAS ULTIMAS UF'S DE CADA MES DEL PERIODO 
  //         for (let mes = 0; mes < 12; mes++) {
  //           const fecha = new Date(anio,mes,this.getLastDayOfMonth(anio, mes));
  //           const valores : UF = serie.find((x : UF) => new Date(x.fecha).getTime() === fecha.getTime());
  //           ufs.push(valores);
  //         }
  //         // CALCULAR EL FACTOR DE ACTUALIZACION
  //         for (let mes = 0; mes < 12; mes++) {
  //           const totalFactor = ufs[11].valor / ufs[mes].valor;
  //           const factor : FactorActualizacion = {
  //               mes: mes,
  //               factor: Number(totalFactor.toFixed(3)),
  //           }
  //           factores.push(factor);
  //         }
  //         this.factores = factores;
  //         resolve(factores);
  //       })
  //     .catch((error: Error) => {
  //       console.error('ERROR: ', error.message);
  //       reject(error);
  //     });
  //   });
  // }
  // OBTENER LA UF DE ACUERDO AL PERIODO SELECCIONADO
  obtenerFactorActualizacion(anio:number){
    return new Promise((resolve, reject) => {
        const ufs : UF[] = [];
        const factores : FactorActualizacion[] = [];
        fetch('https://mindicador.cl/api/uf/'+anio)
            .then(res => res.json())
            .then(({serie}) => {
                // OBTENGO LAS ULTIMAS UF'S DE CADA MES DEL PERIODO
                for (let mes = 0; mes < 12; mes++) {
                    const fecha = new Date(anio, mes + 1, 0).toISOString();
                    const valoresSplit : UF[] = serie.map((x:UF) => ({valor: x.valor, fecha: x.fecha.split('T')[0]}));
                    // console.log({valoresSplit});
                    const valores : UF = valoresSplit.find((x : UF) => x.fecha === fecha.split('T')[0]);
                    ufs.push(valores);
                }
                // CALCULAR EL FACTOR DE ACTUALIZACION
                for (let mes = 0; mes < 12; mes++) {
                    const totalFactor = ufs[11].valor / ufs[mes].valor;
                    const factor : FactorActualizacion = {
                        mes: mes,
                        factor: Number(totalFactor.toFixed(2)),
                    }
                    factores.push(factor);
                }
                console.log({factores});
                resolve(factores);
            })
        .catch((error: Error) => {
            console.error('ERROR: ', error.message);
            reject(error);
        });
    });
}
  // OBTIENE EL ULTIMO DIA DEL MES
  getLastDayOfMonth(year: number, month: number) {
    let date = new Date(year, month + 1, 0);
    return date.getDate();
  }

  // FILTRA LAS LIQUIDACIONES DEL LIBRO DE CONTABILIDAD DE ACUERDO AL PERIODO SELECCIONADO
  filtrarLiquidaciones() : boolean{
    const periodoInicial = new Date(this.anio, 0, 1);
    const periodoFinal = new Date(this.anio, 11, 31);
    return this.libroRemuneraciones.some((libro) => {
      const fecha = new Date(libro.f_liquidacion);
      return fecha >= periodoInicial && fecha <= periodoFinal;
    });
  }
  // FORMATEA LOS NUMEROS CON SEPARADORES DE MILES
  formatearNumeros(numero:number):string{
    const opciones = {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        minimumIntegerDigits: 1,
    };
    return numero.toLocaleString('es', opciones);
  }
}