import { Component, OnInit } from '@angular/core';
import { AfpService } from 'src/app/pages/services/afp.service';
import { Afp } from 'src/app/shared/models/afps.interface';

@Component({
  selector: 'app-main-afp',
  templateUrl: './main-afp.component.html',
  styleUrls: ['./main-afp.component.css']
})
export class MainAfpComponent implements OnInit {

  afp:Afp[] = [];

  constructor(
    private afpsService: AfpService
  ) { }

  ngOnInit(): void {
    this.afpsService.GetAll_afps().subscribe(
      (res) => {        
        this.afp = res.data;
      }
    );
  }
  
  actualizarTabla(event:boolean){
    this.afpsService.GetAll_afps().subscribe(res=>{
      this.afp=res.data
    })
  }
}
