<!-- SPINNER -->
<p-blockUI [blocked]="blockedPanel">
    <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</p-blockUI>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help1">
            <div class="inline-flex align-items-center">
                <b>(1) Datos de liquidación</b>
            </div>
        </p-divider>
        <small id="help1" class="block">
            Aquí debes ingresar los datos básicos de la liquidación, el sueldo base, 
            nombre del trabajador y dias faltados. El sueldo base se utilizará
            para calcular las indemnizaciones del trabajador.
            <ul>
                <li>
                    Si el <b>Sueldo base</b> y/o los <b>dias trabajados</b> no son correctos, puede ser por que no existen 
                    liquidaciones validas del trabajador.
                </li>
                <li>
                    Si los <b>dias pendientes</b> no son correctos, puede ser que la asistencia no este completa.
                </li>
            </ul>
        </small>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Nombre del trabajador</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.NombreTrabajador" readonly>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Sueldo base + Bonos para el proporcional</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.SueldoBase" (change)="changeSueldoBase()" autonumeric [options]="optionsNumeric"
            [placeholder]="finiquitoForm.SueldoBase === null ? 'Ingrese sueldo base' : ''">
        </mat-form-field>
        <mat-error *ngIf="advertencias.sinSueldoBase">El trabajador no tiene asignado un sueldo base.</mat-error>
    </div>
    <div class="col-3">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Dias trabajados</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.diasTrabajados" (change)="changeDiasTrabajados()" autonumeric [options]="optionsNumericPendiente"
            [placeholder]="trabajador.pendiente === null ? 'Ingrese dias pendientes' : ''">
        </mat-form-field>
    </div>
    <!-- <div class="col-6">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Dias pendientes</mat-label>
            <input matInput [(ngModel)]="trabajador.pendiente" (change)="changeDiasPendientes()" autonumeric [options]="optionsNumericPendiente"
            [placeholder]="trabajador.pendiente === null ? 'Ingrese dias pendientes' : ''">
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Remuneración liquida pendiente</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.totalSueldoLiquido" readonly autonumeric [options]="optionsNumeric">
        </mat-form-field>
    </div> -->
</div>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help2">
            <div class="inline-flex align-items-center">
                <b>(2) Información de finiquito</b>
            </div>
        </p-divider>
        <small id="help2" class="block">
            Aquí debes ingresar informacion acerca del finiquito, como tipo de termino y articulo afecto.
        </small>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Término de relación laboral</mat-label>
            <mat-select [(ngModel)]="selectedTermino" placeholder="Seleccione termino de relación laboral" required>
              <mat-option *ngFor="let t of finiquitoForm.Termino" [value]="t.value">
                {{ t.label }}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Articulo</mat-label>
            <mat-select [(ngModel)]="selectArticulo" placeholder="Seleccione articulo afecto" required>
              <mat-option *ngFor="let articulo of articulos" [value]="articulo.id">
                   {{ articulo.cod_art }} : {{ articulo.nombre | uppercase }}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help3">
            <div class="inline-flex align-items-center">
                <b>(3) Periodo de servicio</b>
            </div>
        </p-divider>
        <small id="help3" class="block">
            Aquí debes ingresar la fecha de inicio, de aviso y la del último periodo de servicio del trabajador.
            Este periodo puede coincidir con el de liquidación o ser menor, dependiendo de si el trabajador tiene derecho
            a vacaciones proporcionales o no.
        </small>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Fecha inicio contrato</mat-label>
            <input (click)="picker1.open()" [(ngModel)]="finiquitoForm.FechaInicio" matInput [min]="minDate" [max]="maxDate"
                [matDatepicker]="picker1" name="FechaInicio" (dateChange)="changeDate()">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker1></mat-datepicker>
        </mat-form-field>
        <mat-error *ngIf="advertencias.sinFechaInicioContrato">El trabajador no tiene asignado la fecha de inicio de contrato</mat-error>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Fecha aviso</mat-label>
            <input (click)="picker3.open()" [(ngModel)]="finiquitoForm.FechaAviso" matInput [min]="minDate" [max]="maxDate"
                [matDatepicker]="picker3" name="FechaAviso" (dateChange)="changeDate()" >
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker3></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Fecha termino</mat-label>
            <input (click)="picker2.open()" [(ngModel)]="finiquitoForm.Fecha" matInput [min]="minDate" [max]="maxDate" required
                [matDatepicker]="picker2" name="finiquitoForm.Fecha" (dateChange)="changeDate()">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-8">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Periodo de servicio</mat-label>
            <input matInput [value]="periodo" readonly>
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Años de servicio</mat-label>
            <input matInput [value]="periodoServicio.yearServicio" readonly>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help4">
            <div class="inline-flex align-items-center">
                <b>(4) Vacaciones Proporcionales</b>
            </div>
        </p-divider>
        <small id="help4" class="block">
            Aquí se mostrarán los días de vacaciones que le corresponden al trabajador por el periodo de servicio trabajado.
            Estos valores serviran para generar el total de vacaciones proporcionales.
        </small>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Dias obtenidos</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.diasTotalesProp" (blur)="changeDiasVacacionesProp()" autonumeric [options]="optionsNumericDecimal">
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Dias inhabiles</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.diasInhabilesProp" readonly autonumeric [options]="optionsNumeric">
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Total vacaciones proporcionales</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.totalVacacionesProp" readonly autonumeric [options]="optionsNumericDecimal">
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help4">
            <div class="inline-flex align-items-center">
                <b>(5) Feriado Legal</b>
            </div>
        </p-divider>
        <small id="help4" class="block">
            Aquí se mostrarán los datos necesarios para calcular el feriado legal del trabajador segun corresponda. 
            Estos valores serviran para generar el total del feriado legal.
        </small>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Dias obtenidos</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.diasTotalesFeriado" (blur)="calcularFiniquito()" autonumeric [options]="optionsNumericDecimal">
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Dias inhabiles</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.diasInhabilesFeriado" readonly autonumeric [options]="optionsNumeric">
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Total feriado legal</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.totalVacacionesFeriado" readonly autonumeric [options]="optionsNumericDecimal">
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help5">
            <div class="inline-flex align-items-center">
                <b>(6) Indemnizaciones</b>
            </div>
        </p-divider>
        <small id="help5" class="block">
            Aquí se mostrarán los totales de indemnizacion. La indemnización puede ser por años de servicio, 
            por aviso previo y por vacaciones proporcional.
        </small>
    </div>
    <div class="col-3">
        <mat-checkbox class="example-margin" [(ngModel)]="checkidemAviso" color="warn" (change)="onCheckboxChange('idemAviso')">Aplicar indemnización</mat-checkbox>
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Por aviso previo</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.idemAviso" readonly autonumeric [options]="optionsNumeric"
                [placeholder]="finiquitoForm.idemAviso === null ? 'No Aplica' : ''">
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-checkbox class="example-margin" [(ngModel)]="checkidemServicio" color="warn" (change)="onCheckboxChange('idemServicio')">Aplicar indemnización</mat-checkbox>
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Por años de servicios</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.idemServicio" readonly autonumeric [options]="optionsNumeric"
                [placeholder]="finiquitoForm.idemServicio === null ? 'No Aplica' : ''">
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-checkbox class="example-margin" [(ngModel)]="checkidemVacaciones" color="warn" (change)="onCheckboxChange('idemVacaciones')">Aplicar indemnización</mat-checkbox>
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Vacaciones proporcionales</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.idemVacaciones" readonly autonumeric [options]="optionsNumeric"
                [placeholder]="finiquitoForm.idemVacaciones === null ? 'No Aplica' : ''">
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-checkbox class="example-margin" [(ngModel)]="checkidemFeriado" color="warn" (change)="onCheckboxChange('idemFeriado')">Aplicar indemnización</mat-checkbox>
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Feriado Legal</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.idemFeriado" readonly autonumeric [options]="optionsNumeric"
                [placeholder]="finiquitoForm.idemFeriado === null ? 'No Aplica' : ''">
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <p-divider align="left" aria-describedby="help6">
            <div class="inline-flex align-items-center">
                <b>(7) Totales</b>
            </div>
        </p-divider>
        <small id="help6" class="block">
            Aquí se mostrarán los totales de indemnizacion y el total del finiquito.
            <ul>
                <li>
                    <b>Remuneración liquida pendiente: </b>Remuneracion liquida que se pagará en el finiquito.
                </li>
            </ul>
        </small>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Remuneración liquida pendiente</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.totalSueldoLiquido" (blur)="calcularIndemnizaciones()" autonumeric [options]="optionsNumericTotal">
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
            <mat-label>Total indemnizaciones</mat-label>
            <input matInput [(ngModel)]="finiquitoForm.totalIndemnizaciones" readonly (change)="calcularFiniquito()" autonumeric [options]="optionsNumericTotal">
        </mat-form-field>
    </div>
    <div class="col-4">
        <mat-form-field class="example-full-width" appearance="outline">
            <span matPrefix>$ &nbsp;</span>
            <mat-label>Total finiquito</mat-label>
            <input matInput type="text" [(ngModel)]="finiquito.Total" readonly required name="sueldo" autonumeric [options]="optionsNumericTotal" />
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <label class="form-label" for="customFile">Subir Finiquito</label>
        <input type="file" class="form-control" id="customFile" accept="image/*,.pdf,.doc,.docx"
             ng2FileSelect [uploader]="uploader" />
    </div>
    <div class="col-12">
        Estado de Carga
        <div class="progress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary mr-auto" (click)="GenerarFiniquito()">
        <span aria-hidden="true">Generar Finiquito</span>
    </button>
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="OcultarModal()">
        <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" (click)="Guardar()" class="btn btn-outline-success">Guardar</button>
</div>