
<h3 style="text-align:center;font-size:1.77rem;font-weight:500">FINIQUITOS</h3>
<div class="row col-12 pl-3">
    
  </div>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <p-dropdown [options]="empresaFilter" [(ngModel)]="empresaSelect" [filter]="true" filterBy="value" (onChange)="FiltrarDatos()"
        [showClear]="true" placeholder="Todas las empresa" optionLabel="text" optionValue="value"styleClass="mr-2">
      </p-dropdown>
      <p-dropdown [options]="trabajadoresFilter" [(ngModel)]="trabajadorSelect" [filter]="true" filterBy="text" (onChange)="FiltrarDatos()"
        [showClear]="true" placeholder="Todos los trabajadores" optionLabel="text" optionValue="value" styleClass="mr-2">
      </p-dropdown>
      <p-dropdown [options]="estadoContratoFilter" [(ngModel)]="estadoContratoSelect" [filter]="true" filterBy="value" (onChange)="FiltrarDatos()"
        [showClear]="true" placeholder="Todos los estado de contrato" optionLabel="text" optionValue="value" styleClass="mr-2">
      </p-dropdown>
      <mat-checkbox class="mr-2" (change)="viewDatosFiniquitados()" 
        [(ngModel)]="viewFiniquitados" color="warn">
        Cargar finiquitados
      </mat-checkbox>
      <button pButton pRipple type="button" icon="pi pi-filter-slash" class="p-button-rounded p-button-danger" pTooltip="Quitar filtro" tooltipPosition="bottom" (click)="RestablecerFiltro()"></button>
    </div>
  </p-toolbar>
<!-- <div class="row container-fluid">
    <div class="mr-2">
        <mat-form-field appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
    </div>
    <div class="d-flex align-items-center">
        <mat-checkbox (change)="viewDatosFiniquitados()" [(ngModel)]="viewFiniquitados" color="warn">
            <span [matBadge]="num_trab_finiquitados <= 99 
                ? num_trab_finiquitados 
                : '+99'" 
                matBadgeOverlap="false">Mostrar trabajadores finiquitados</span>
        </mat-checkbox>
    </div>
</div> -->
<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.rut | formatoRut}} </td>
    </ng-container>
    <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef> NOMBRE COMPLETO </th>
        <td mat-cell *matCellDef="let element"> {{element.nombres | uppercase}} {{element.apellidos | uppercase}} </td>
    </ng-container>

    <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef> EMPRESA</th>
        <td mat-cell *matCellDef="let element">{{ element.Empresa_contratante.nombre | uppercase}}</td>
    </ng-container>


    <ng-container matColumnDef="estado_contrato">
        <th mat-header-cell *matHeaderCellDef> ESTADO CONTRATO</th>
        <td mat-cell *matCellDef="let element"> 
          <span [ngClass]="{'bg-danger text-light rounded p-2': element.estado_contrato.toLowerCase() === 'finiquitado'}">
            {{ element.estado_contrato | uppercase}}
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button (click)="Mostrar(mostrar,element.rut)" matBadgeColor="accent" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
              <mat-icon style="color: white">archive</mat-icon>
          </button>&nbsp;
          <button mat-button (click)="subir(contenido,element.rut)" class="button_color_primary"
                style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">file_upload</mat-icon>
            </button>&nbsp;
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons>
</mat-paginator>

<!-- Aca empieza el modal -->
<ng-template #contenido let-modal2>
    <div class="modal-header">
        <mat-card-title>Subir Finiquitos</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-agregar-finiquitos (finiquitoCreado)="updateCargaDatos()" [rut]="rut_trabajador"></app-agregar-finiquitos>
    </div>
</ng-template>


<ng-template style="border: 0px; z-index: 100" #mostrar let-modal3>
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Mostrar Finiquitos</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="CerrarModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-modal-mostrar [rut]="rut_trabajador"></app-modal-mostrar>
    </div>



</ng-template>