
<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
        <span style="color: white">Agregar horas extras</span>
        <mat-icon style="color: white">add</mat-icon>
    </button>
</div>

<ng-template style="border: 0px; z-index: 100;" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Agregar una hora extra</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form>
            <!-- Fila 1 -->
            <div class="row">
                <!-- Trabajador -->
                <div class="col col-12">
                    <p-dropdown [options]="trabajadores_aux" [(ngModel)]="trabajador" placeholder="Seleccione un trabajador" optionLabel="nombre" 
                        [showClear]="true" [filter]="true" filterBy="nombre" [style]="{ width: '100%' }" [ngModelOptions]="{standalone: true}"></p-dropdown>
                    <!-- <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Trabajador</mat-label>
                        
                        <mat-select name="rut" (selectionChange)="ObtenerTrabajador($event)">
                            <mat-option *ngFor="let element of trabajadores_aux" [value]="element.rut">
                                {{element.nombres}} {{element.apellidos}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                </div>
                <!-- Fecha -->
                <div class="col col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="picker" (dateChange)="obtener_fecha($event)" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUI #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <!-- Example 1: <input [(ngModel)]="person.firstName" name="first">
            Example 2: <input [(ngModel)]="person.firstName" [ngModelOptions]="{standalone: true}"> -->

            <!-- Fila 2 -->
            <div class="row">
                <!-- Horas Extras -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Horas Extras</mat-label>
                        <input type="text" matInput name="horasExtras" [(ngModel)]="horasExtras.horasExtras" autonumeric [options]="optionsDecimal">

                    </mat-form-field>
                </div>
                <!-- Valor Hora -->
                <!-- <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Valor hora</mat-label>
                        <input type="number" minlength="0" maxlength="6" matInput name="valorHora" [(ngModel)]="horasExtras.valorHora"  />
                    </mat-form-field>
                </div> -->
            </div>
            <!-- Fila 3 -->
            <div class="row">

                <!-- Descripción -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <input type="text" matInput matInput name="descripcion" [(ngModel)]="horasExtras.descripcion">

                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="enviar()">Guardar</button>
    </div>

</ng-template>