<div class="mt-3 container-fluid animate__animated animate__slideInUp">
    <mat-card>
        <mat-card-title>Generar archivo PREVIRED</mat-card-title>
    </mat-card>
    <br>
    <mat-card>
        <mat-card-content>
            <app-generarprevired></app-generarprevired>
        </mat-card-content>
    </mat-card>
</div>