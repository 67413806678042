<div class="modal-body">
  <div class="row container-fluid">
    <div class="col-12">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label style="text-align:center">DESDE - HASTA </mat-label>
        <mat-date-range-input [rangePicker]="picker2" name="rango_pick">
            <input (click)="picker2.open()" matStartDate placeholder="FECHA INICIO" #dateRangeStart>
            <input (click)="picker2.open()" matEndDate placeholder="FECHA FIN" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker touchUi #picker2></mat-date-range-picker>

        <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
    </mat-form-field>
    </div>
    <div class="col-3">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Días Habiles</mat-label>
        <input type="text" required [(ngModel)]= "trabajador.habiles" autonumeric [options]="optionsNumeric"
          matInput #input name ="Días Habiles">
      </mat-form-field> 
    </div>
    <div class="col-3">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Días Inhabiles</mat-label>
        <input type="text" required [(ngModel)]= "trabajador.inhabiles" autonumeric [options]="optionsNumeric"
          matInput #input name ="Días Inhabiles">
      </mat-form-field> 
    </div>
    <div class="col-3">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Saldo Pendiente</mat-label>
        <input type="text" required [(ngModel)]= "trabajador.pendiente" autonumeric [options]="optionsNumeric"
          matInput #input name ="Saldo Pendiente">
      </mat-form-field> 
    </div>
    <div class="col-3">
      <div class="field example-full-width mt-3">
        <span class="p-float-label">
           <p-calendar [(ngModel)]="trabajador.periodo" [showIcon]="true" view="year" dateFormat="yy" inputId="anio" showButtonBar="true"></p-calendar>
          <label for="calendar">Periodo</label>
        </span>
     </div>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>En palabras</mat-label>
        <textarea type="textarea" rows="3" required [(ngModel)]= "trabajador.palabras"  matInput #input name ="Días Habiles"></textarea>
     </mat-form-field>
    </div>
    <div class="col-12">
      <button style="background-color: green" (click)="printpdf()"  mat-raised-button>Generar PDF <mat-icon>picture_as_pdf</mat-icon></button>
      <button *ngIf="renderPdf" style="background-color: rgb(207, 51, 3);" (click)="imprimir()"  mat-raised-button>Descargar PDF <mat-icon>picture_as_pdf</mat-icon></button>
    </div>
  </div>
 
  
  <mat-progress-bar  *ngIf="!render" mode="indeterminate"></mat-progress-bar>
  
  
  <div *ngIf="renderPdf" class="pdf-container">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="true"
        style="width: 100%; height: 800px;"
      ></pdf-viewer>
    </div>


