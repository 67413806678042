import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { AgregarPerfilComponent } from './agregar-perfil/agregar-perfil.component';
import { ListaPerfilComponent } from './lista-perfil/lista-perfil.component';
import { MainPerfilComponent } from './main-perfil/main-perfil.component';


@NgModule({
  declarations: [        
    AgregarPerfilComponent,
    ListaPerfilComponent,
    MainPerfilComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,    
  ],
  exports:[
    AgregarPerfilComponent,
    ListaPerfilComponent,
    MainPerfilComponent
  ]
})
export class RequisitosModule { }
