import { Component, OnInit } from '@angular/core';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FormControl } from '@angular/forms';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { OpcionElegidaDDJJ } from 'src/app/shared/models/opcioneselegidas.interface'; 
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { GenerarDDJJService } from 'src/app/pages/services/PDF-GENERADOR/declaracionjurada/generarddjj.service';
import { DeclaracionDDJJService } from '../../../services/declaracion/declaracion.service';
import { DeclaracionJuradaDB } from 'src/app/shared/models/declaracionjurada/declaracionjurada.interface';
import { RectificacionDDJJDB } from '../../../../shared/models/declaracionjurada/rectificacionddjj.interface';
import { formatRut } from 'rutlib/lib';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
const FORMATO_TEXTO = "TEXTO";
const TIPO_DECLARACION_RECTIFICACION = "RECTIFICACIÓN";
const TIPO_ARCHIVO_BORRADOR = "BORRADOR";
const TIPO_ARCHIVO_DECLARACION = 'DECLARACIÓN';

@Component({
  selector: 'app-generarddjj',
  templateUrl: './generarddjj.component.html',
  styleUrls: ['./generarddjj.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class GenerarddjjComponent implements OnInit {
  date = new FormControl(moment());
  empresas: Empresa[] = [];
  // CONSTRUCTOR QUE TRAE LAS EMPRESAS DE LA BASE DE DATOS
  constructor
  (
    private empresaService: EmpresaService, 
    private alert:AlertHelper,
    private generarService:GenerarDDJJService, // ESTE SIRVE PARA GENERAR EL PDF O TEXTO
    private declaracionService:DeclaracionDDJJService, // ESTE SIRVE PARA SUBIR LOS DATOS A LA BASE DE DATOS
  ) {
    this.empresaService.GetAll_empresaC().subscribe(
      (res) => {                     
        this.empresas = res.data;
        this.opciones_elegidas.empresa = `${this.empresas[0].run} ${this.empresas[0].nombre}`;
      }
    );
  }

  ngOnInit(): void {
    // INICIALIZAR UNA VARIABLE LOCAL
    localStorage.setItem("recordarAlert", "1");
  }
  
  currentYear: number = new Date().getFullYear();
  maxDate:Date = new Date(this.currentYear,11,31);
  // OBJETO QUE SE UTILIZA PARA GUARDAR LA INFOMACION DE LAS OPCIONES ELEGIDAS EN EL FORMULARIO
  opciones_elegidas: OpcionElegidaDDJJ = {
    folio: 0,
    periodo_declatorio: new Date().getFullYear(),
    formato: 'PDF',
    tipo_declaracion: 'ORIGINAL',
    tipo_archivo: 'BORRADOR',
    id_empresa: 0,
    empresa: '',
    folio_anterior: null,
    rut_anterior: null,
    rut_declarante: '',
  }

  // LISTAS PARA CARGAR EN LOS SELECTS DEL FORMULARIO
  formatos: string[] = ['PDF', 'TEXTO']; // 'TEXTO'
  tipos_declaraciones: string[] = ['ORIGINAL', 'RECTIFICACIÓN'];
  tipos_archivos:string[] = ['BORRADOR', 'DECLARACIÓN'];

  // DECLARACION ATRIBUTOS DE VISIBILIDAD DE COMPONENTES
  viewTipoDeclaracion: boolean = true;
  viewTipoArchivo:boolean = false;
  viewFolioAnterior: boolean = false;
  viewRutAnterior: boolean = false;
  disabledButton:boolean = true;

  // METODO QUE CAPTURA EL AÑO DEL DATEPICKER
  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.opciones_elegidas.periodo_declatorio = this.date.value.year();
  }

  // SELECT EMPRESA
  selectEmpresa(empresa:Empresa){
    this.opciones_elegidas.empresa = `${empresa.id}`;
    this.verificarOpciones();
  }
  // SELECT FORMATO
  selectFormato(formato:string){
    this.opciones_elegidas.formato = formato;
    this.verificarOpciones();
  }
  // SELECT TIPO
  selectTipo(tipo:string){
    this.opciones_elegidas.tipo_declaracion = tipo;
    this.verificarOpciones();
  }
  // SELECT ARCHIVO
  selectArchivo(tipo:string){
    this.opciones_elegidas.tipo_archivo = tipo;
    if (localStorage.getItem("recordarAlert") === "0") {
    this.verificarOpciones();
      return;
    }
    if (tipo === "DECLARACIÓN") {
      const texto = `
      Al elegir esta opcion, el sistema incrementara más 1 el folio del documento.\n
      Si desea obtener el documento sin incrementar el folio, elegir tipo Borrador.
      `;
      alert(texto);
    }
  }
  // VERIFICA LAS OPCIONES ELEGIDAS Y MUESTRA LOS ELEMENTOS EN EL FORMULARIO.
  verificarOpciones(){
    this.disabledButton = false;
    
    const view = this.opciones_elegidas.tipo_declaracion === TIPO_DECLARACION_RECTIFICACION;
    this.viewTipoArchivo = this.opciones_elegidas.formato === FORMATO_TEXTO;
    this.viewRutAnterior = view;
    this.viewFolioAnterior = view;
  }
  verificarEstadoDatos(){ // VERIFICA LOS DATOS SELECCIONADOS POR EL USUARIO
    // VERIFICAR SI LA DECLARACION SE HACE EL MISMO AÑO EN CURSO
    const anioHoy = new Date().getFullYear();
    if (anioHoy === this.opciones_elegidas.periodo_declatorio) {
      this.alert.errorAlert
      (
        "No se puede elegir el mismo periodo en curso: "+this.opciones_elegidas.periodo_declatorio
      );
      return;
    }
    console.log(this.opciones_elegidas.tipo_declaracion, this.opciones_elegidas.folio_anterior, isNaN(this.opciones_elegidas.folio_anterior), this.opciones_elegidas.rut_anterior);
    if 
    (// VERIFICO SI LOS DATOS INGRESADOS SON NUMERICOS (FOLIO Y RUT) Y DE ACUERDO A LA LOGICA
      (this.opciones_elegidas.tipo_declaracion === TIPO_DECLARACION_RECTIFICACION) &&
      (isNaN(this.opciones_elegidas.folio_anterior) && this.opciones_elegidas.tipo_declaracion === TIPO_DECLARACION_RECTIFICACION) || 
      (this.opciones_elegidas.rut_anterior === null && this.opciones_elegidas.tipo_declaracion === TIPO_DECLARACION_RECTIFICACION) ||
      (this.opciones_elegidas.folio_anterior === null && this.opciones_elegidas.tipo_declaracion === TIPO_DECLARACION_RECTIFICACION)
    ) { return this.alert.error_mail("Rut y/o folio son incorrectos o estan vacios.\nIntente no ingresarlos con simbolos o letras") }
    // VERIFICAR SI EXISTE UNA DECLARACION CON EL FOLIO INGRESADO
    this.obtenerDeclaracionConFolio().then(declaracion => {
      if ( declaracion === undefined && this.opciones_elegidas.rut_anterior !== null && this.opciones_elegidas.folio_anterior !== null) 
      {// SI NO EXISTE LA DECLARACION Y EL USUARIO NO CONFIRMA LA GENERACION DEL DOCUMENTO, RETORNAR VACIO.
        if 
        (
          !confirm("El folio ingresado no existe en la base de datos, puede deberse a un error o que no existe constancia en la base de datos\n¿Desea continuar?")
        ) 
        { return; }
      };
      // VERIFICAR SI EXISTE UNA DECLARACION CON EL PERIODO SELECCIONADO
      this.obtenerDeclaracionConAnio().then((declaraciones:DeclaracionJuradaDB[]) => {
        // SI SOLO SE QUIERE PREVISUALIZAR O VER UN BORRADOR, SOLO SE GENERA EL DOCUMENTO
        if (this.opciones_elegidas.formato === 'PDF' 
            || (this.opciones_elegidas.formato === FORMATO_TEXTO 
            && this.opciones_elegidas.tipo_archivo === TIPO_ARCHIVO_BORRADOR)) {
          this.generarDocumento();
          return;
        }
        // SI SE QUIERE EXPORTAR A TEXTO PLANO PARA DECLARAR, SE REALIZARÁ TODA LA LOGICA PARA SUBIR A LA BASE DATOS
        if (this.opciones_elegidas.tipo_archivo === TIPO_ARCHIVO_DECLARACION 
            && this.opciones_elegidas.tipo_declaracion !== TIPO_DECLARACION_RECTIFICACION) {
          if (declaraciones.length > 0) 
          { // SI EXISTE Y EL TIPO DE ARCHIVO NO ES DECLARACION AL SII, GENERAR EL DOCUMENTO
            this.generarDocumento();
          }else{// SI NO EXISTE LA DECLARACION, GENERAR Y CREAR LA DECLARACION
            this.generarDocumento();
            this.crearDeclaracionBD();
          }  
        }
        if (this.opciones_elegidas.tipo_archivo === TIPO_ARCHIVO_DECLARACION 
            && this.opciones_elegidas.tipo_declaracion === TIPO_DECLARACION_RECTIFICACION) {
          // SI SE HACE UNA RECTIFICACION Y NO EXISTE UNA DECLARACION ORIGINAL, MOSTRAR UN MENSAJE DE ERROR
          if (declaraciones.length === 0) {
            this.alert.error_small('No puedes hacer una rectificacion si no existe la Declaracion Jurada original del periodo seleccionado.');
            return;
          }
          this.generarDocumento();
          this.crearRectificacionBD(declaraciones.at(-1).id);
        }
      }).catch(error => this.alert.error_small(error.message));
    }).catch(error => this.alert.error_small(error.message));
  }
  crearDeclaracionBD(){
    return new Promise((resolve, reject) => {
      try {
        this.declaracionService.getLastFolio().subscribe(({data:folio}) => {
          // CREAR LA DECLARACION
          const declaracion:DeclaracionJuradaDB = {
            idempresa: parseInt(this.opciones_elegidas.empresa),
            anio: this.opciones_elegidas.periodo_declatorio,
            folio: folio + 1,
          }
          this.declaracionService.crearDeclaracion(declaracion)
            .subscribe((res:any) => {
              this.alert.success_small('Documento generado con exito.');
              resolve(res.data);
            });
        });  
      } catch (error) {
        reject(error);
      }
    }); 
  }
  crearRectificacionBD(idDeclaracionAnterior:number){
    // SE CREA LA NUEVA DECLARACION
    this.crearDeclaracionBD()
      .then((declaracion:DeclaracionJuradaDB) => {
        const rectificacion:RectificacionDDJJDB = {
          iddeclaracion_nueva: declaracion.id,
          iddeclaracion_anterior: idDeclaracionAnterior,
        }
        this.declaracionService.crearRectificacion(rectificacion).subscribe(({msg}) => {
          this.alert.success_small(msg);
        });
      })
    .catch(() => {
      this.alert.error_small('Ocurrio un error innesperado.');
    });
  }
  generarDocumento(){
    try {
      this.generarService.generarDocumento(this.opciones_elegidas);
    } catch (error) {
      this.alert.errorAlert("Ocurrio un error innesperado");
    }
  }
  // OBTIENE LA DECLARACION CON EL FOLIO INGRESADO EN "FOLIO ANTERIOR"
  obtenerDeclaracionConFolio(){
    return new Promise((resolve) => {
      this.declaracionService.getDeclaracionConFolio(this.opciones_elegidas.folio_anterior).subscribe(({data}) => {
        resolve(data);
      });
    });
  }
  // OBTIENE LA RECTIFICACION CON EL PERIODO INGRESADO
  obtenerRectificacionConAnio(){
    return new Promise((resolve, reject) => {
      try {
        this.declaracionService.getRectificacionConAnio(this.opciones_elegidas.periodo_declatorio).subscribe(({data}) => {
          resolve(data);
        });  
      } catch (error) {
        reject(error);
      }
    });
  }
  // OBTIENE LA DECLARACION CON EL PERIODO INGRESADO
  obtenerDeclaracionConAnio(){
    return new Promise((resolve) => {
      this.declaracionService.getDeclaracionConAnio(this.opciones_elegidas.periodo_declatorio).subscribe(({data}) => {
        resolve(data);
      });
    });
  }
  formatoRut($event){
    this.opciones_elegidas[$event] = formatRut(this.opciones_elegidas[$event]);
  }
}