<div class="animate__animated animate__fadeInRight container-fluid">
    <!-- Fila 1 de los números de los contratos -->
    <div class="row">
        <!-- MESANJES INFORMATIVOS -->
        <div class="col-12 pb-0 pt-0">
            <p-messages [(value)]="message" [enableService]="false"></p-messages>
        </div>
        <div class="col col-3">
            <mat-card>
                <mat-card-title class="p-2" style="text-align: center;background-color: #525151; border-radius: 5px;">
                    <span style="color: white">Contratos a plazo</span>
                </mat-card-title>

                <mat-card-content>
                    <h1 style="text-align: center;">{{totalPlazoFijo??0}}</h1>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col col-3">
            <mat-card>
                <mat-card-title class="p-2" style="text-align: center;background-color: #525151;border-radius: 5px;">
                    <span style="color: white">Contratos Indefinidos</span>
                </mat-card-title>

                <mat-card-content>
                    <h1 style="text-align: center;">{{totalPlazoIndefinido??0}}</h1>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col col-3">
            <mat-card>
                <mat-card-title class="p-2" style="text-align: center;background-color: #525151;border-radius: 5px;">
                    <span style="color: white">Contratos por Obra o Faena</span>
                </mat-card-title>

                <mat-card-content>
                    <h1 style="text-align: center;">{{totalObraFaena??0}}</h1>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col col-3">
            <mat-card>
                <mat-card-title class="p-2" style="text-align: center;background-color: #a50808;border-radius: 5px;">
                    <span style="color: white">Finiquitados</span>
                </mat-card-title>

                <mat-card-content>
                    <h1 style="text-align: center;">{{totalFiniquitado??0}}</h1>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-12">
            <p-tabView [(activeIndex)]="index" (onChange)="handleChange($event)">
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <mat-icon class="material-icons">attach_money</mat-icon>Indicadores previsionales
                    </ng-template>
                    <app-indicadores-previsionales class="fadeInOut"></app-indicadores-previsionales>
            </p-tabPanel>

                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <mat-icon class="material-icons">insert_drive_file</mat-icon>Contratos a plazo fijo por vencer ({{ countContratoVencer }})
                    </ng-template>
                    <div id="contratosPorVencer">
                        <app-contratos-por-vencer class="fadeInOut" [cargarTabla]="cargarTablaContratos" 
                            (countContratoVencer)="recibirCountContrato($event)"></app-contratos-por-vencer>
                    </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <mat-icon class="material-icons">insert_drive_file</mat-icon> Anexos de contrato por vencer ( {{ countAnexosVencer }} )
                </ng-template>
                <div id="contratosAnexoPorVencer">
                <app-contratos-anexos-por-vencer [cargarTabla]="cargarTablaAnexos" 
                (countAnexosVencer)="recibirCountAnexo($event)"></app-contratos-anexos-por-vencer>
            </div>
        </p-tabPanel>
            
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <mat-icon class="material-icons">cake</mat-icon> Fechas de cumpleaños
                        </ng-template>
                        <app-fechas-cumpleanios class="fadeInOut"></app-fechas-cumpleanios>
         
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>