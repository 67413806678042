import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainCargosComponent } from './main-cargos/main-cargos.component';
import { AgregarCargosComponent } from './agregar-cargos/agregar-cargos.component';
import { ListaCargosComponent } from './lista-cargos/lista-cargos.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    MainCargosComponent,
    AgregarCargosComponent,
    ListaCargosComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
  ],
  exports:[
    MainCargosComponent,
    AgregarCargosComponent,
    ListaCargosComponent
  ]
})
export class CargosModule { }
