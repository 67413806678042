import { Component, OnInit } from '@angular/core';
import { HorasExtras } from 'src/app/shared/models/horasExtras.interface';
import { HorasExtrasService } from '../../../services/horasExtras/horasExtras.service';

@Component({
  selector: 'app-main-horas-extras',
  templateUrl: './main-horas-extras.component.html',
  styleUrls: ['./main-horas-extras.component.css']
})
export class MainHorasExtrasComponent implements OnInit {

  horasExtras: HorasExtras[] = []

  constructor(
    private horasExtraService: HorasExtrasService
  ) { }

  ngOnInit(): void {
    
    
  }

 
  

}
