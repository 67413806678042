<h1 mat-dialog-title class="dialog-title">Subir Documento Trabajador</h1>
<div mat-dialog-content class="dialog-content">
  <form class="dialog-form">
    <mat-form-field appearance="outline" class="form-field">
      <label>DESCRIPCIÓN:</label>
      <input matInput [(ngModel)]="description" name="description" required />
      <mat-error *ngIf="!description">La descripción es obligatoria.</mat-error>
    </mat-form-field>
    <div class="file-input-container">
      <input type="file" (change)="onFileSelected($event)" class="file-input" />
    </div>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onNoClick()" class="action-button">Cancelar</button>
  <button mat-button (click)="upload()" class="confirm-button" [disabled]="!description">Subir</button>
</div>
