<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE </th>
            <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DESCRIPCIÓN </th>
            <td mat-cell *matCellDef="let row"> {{row.descripcion}} </td>
        </ng-container>
        <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef> ACCIÓN </th>
            <td mat-cell *matCellDef="let row" style="width: 10%;">
                <app-editar-motivo [motivo]="row" (actualizarTabla)="TraerDatos()"></app-editar-motivo>
                &nbsp;
                <button (click)="EliminarMotivo(row.id)"
                    mat-button class="button_color_primary" style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]"
                 showFirstLastButtons>
  </mat-paginator>
</div>