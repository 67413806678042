import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-banco',
  templateUrl: './main-banco.component.html',
  styleUrls: ['./main-banco.component.css']
})
export class MainBancoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



 

}
