import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { HaberesService } from 'src/app/pages/services/haberes/haberes.service';
import { TrabajadorHaberService } from 'src/app/pages/services/TrabajadorHaber/trabajador-haber.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Holidays from 'date-holidays';
import { TrabajadoresService } from '../../../services/trabajadores/trabajadores.service';
import { eachDayOfInterval, endOfMonth, format, isWithinInterval, max, min, parseISO, startOfMonth } from 'date-fns';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { FeriadosService } from 'src/app/pages/services/helper/feriados.service';

@Component({
  selector: 'app-listar-haberes-trabajador',
  templateUrl: './listar-haberes-trabajador.component.html',
  styleUrls: ['./listar-haberes-trabajador.component.css']
})
export class ListarHaberesTrabajadorComponent implements OnInit {
  @Input() rut: number;
  @Input() tributable: number;
  @Input() periodoLiquidacion: Date;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  debouncer: Subject<boolean> = new Subject()
  dataSource = new MatTableDataSource();
  habereslist!: any[];
  haberesTrabajador: any[] = [];
  haberSeleccionado: any;
  empresaSeleccionado: any;
  trabajadorSeleccionad: any;
  nombreTrabajador: string = '';
  nombreHaber: string = '';
  messageErrorForm: string = '';

  changeStateHaber: boolean = false;

  haberFijo: boolean = false;
  haberComision: boolean;

  fechaDesde: Date = null;
  fechaHasta: Date = null;

  modalReference: NgbModalRef = null;
  modalReference2: NgbModalRef = null;
  modalReference3: NgbModalRef = null;
  periodo = new Date();
  bSemanaCorrida = false;
  dias = 0;
  feriados = 0;
  hoy: Date = new Date();
  rangoPeriodo: Date[] = [];

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  displayedColumns: string[] = [
    'Trabajador',
    'nombre',
    'monto',
    'periodo',
    'acciones'
  ];
  haber: {
    haber: 0,
    trabajador: 0,
    monto: 0,
    montoBSC: 0,
    bSemanaCorrida: 0,
    fechaDesde: '',
    fechaHasta: '', 
  }
  haberNuevo = {
    haber: 0,
    trabajador: 0,
    monto: 0,
    diasHabiles: 0,
    diasInhabiles: 0,
    montoBSC: 0,
    bSemanaCorrida: 0,
    fechaDesde: '',
    fechaHasta: '',
  }
  haberAct = {
    id: 0,
    monto: 0,
    montoBSC: 0,
    bSemanaCorrida: 0,
    diasHabiles: 0,
    diasInhabiles: 0,
    fechaDesde: '',
    fechaHasta: '',
  }
  trabajador: any;
  // FILTERS
  trabajadorSelect:number = null;
  empresaSelect: number = null;
  haberSelect: number = null;
  // DROPDOWNS
  trabajadoresList: any[] = [];
  trabajadorFilter: any[] = [];
  empresasList: any[] = [];
  empresasFilter: any[] = [];
  haberesList: any[] = [];
  haberesFilter: any[] = [];

  constructor(
    private trabajadorHaberService: TrabajadorHaberService,
    private haberService: HaberesService,
    private trabajadorService: TrabajadoresService,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private holidays: FeriadosService,
  ) { }

  async ngOnInit() {
    // console.log('RUT HABERES TRABAJADOR: ', this.rut);
    this.debouncer.pipe(debounceTime(500)).subscribe(valor => { this.onDebounce.emit(valor) })

    // await this.TraerDatos();
    this.TraerHaberes();
  }
  async ngOnChanges(changes: SimpleChanges) {
    console.log({...changes});
    if (changes.rut) {
      if (changes.rut.currentValue !== changes.rut.previousValue) {
        await this.TraerDatos();
      }
    }
    if (changes.periodoLiquidacion) {
      this.periodoLiquidacion = changes.periodoLiquidacion.currentValue;
      this.cargarHaberesLiquidacion();
    }
  }

  async TraerDatos() {
    let success = true;
    let resHaberes: RequestResponse;
    let resTrabajador: RequestResponse;
    if (this.haberesTrabajador.length === 0 || this.changeStateHaber) {
      resHaberes = await this.trabajadorHaberService.TraerTodosHaberesTrabajadorAsync();
      if (resHaberes.success) {
        this.haberesTrabajador = resHaberes.data;
      }else{
        success = false;
      }
    }
    if (this.trabajadoresList.length === 0 || this.changeStateHaber) {
      resTrabajador = await this.trabajadorService.TraerTrabajadoresAsync();
      if (resTrabajador.success) {
        this.trabajadoresList = resTrabajador.data;
      }else{
        success = false;
      }
    }
    if (success) {
      this.trabajador = this.trabajadoresList.find(x => x.rut === this.rut);
      this.empresasList = this.trabajadoresList.map(
        x => ({value: x.Empresa_contratante.id, text: x.Empresa_contratante.nombre.toUpperCase()})
      );

      this.empresaSelect = this.trabajador.Empresa_contratante.id;
      this.trabajadorSelect = this.rut;
      this.FiltrarDatos();
      this.debouncer.next(true);
      this.changeStateHaber = false;
    }else{
      this.alert.error_small('Ocurrio un error al obtnener los haberes y/o los trabajadores');
      console.error(resHaberes.msg, resTrabajador.msg);
    }
  }
  async recargarTabla() {
    const resHaberes = await this.trabajadorHaberService.TraerTodosHaberesTrabajadorAsync();
    if (!resHaberes.success) this.alert.error_small('Hubo un error al obtener los haberes');
    this.haberesTrabajador = resHaberes.data;
    this.trabajador = this.trabajadoresList.find(x => x.rut === this.rut);
    this.empresasList = this.trabajadoresList.map(
      x => ({value: x.Empresa_contratante.id, text: x.Empresa_contratante.nombre.toUpperCase()})
    );
    const haberesTrabajadoresFilter = this.haberesTrabajador.filter(x => {
      const fechaDesde = parseISO(x.fechaDesde);
      const fechaHasta = parseISO(x.fechaHasta);
      return x.trabajador === this.trabajador.rut && 
        (isWithinInterval(this.rangoPeriodo[0], { start: fechaDesde, end: fechaHasta }) 
        || isWithinInterval(this.rangoPeriodo[1], { start: fechaDesde, end: fechaHasta }))
    }).sort((a, b) => {
      const timeA = parseISO(a.fechaHasta).getTime();
      const timeB = parseISO(b.fechaHasta).getTime();
      return timeA < timeB ? 1 : timeA > timeB ? -1 : 0;
    });
    this.inicializarTabla(haberesTrabajadoresFilter);
  }

  EmpresaSelect(){
    this.trabajadorSelect = null;
    this.haberSelect = null;
    this.FiltrarDatos();
  }
  RangoPeriodoChange(){
    let listaHaberesFilter = this.haberesTrabajador;
    
    listaHaberesFilter = listaHaberesFilter.filter(x => {
      const fechaDesde = startOfMonth(parseISO(x.fechaDesde));
      const fechaHasta =  endOfMonth(parseISO(x.fechaHasta));

      // 0: DESDE 1: HASTA
      return isWithinInterval(this.rangoPeriodo[0]??fechaDesde, { start: fechaDesde, end: fechaHasta }) 
        || isWithinInterval(this.rangoPeriodo[1]??fechaHasta, { start: fechaDesde, end: fechaHasta });
    })

    if (this.empresaSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.TrabajadorHaberes.empresa_contratante === this.empresaSelect
      )
    }
    if (this.trabajadorSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.trabajador === this.trabajadorSelect
      );
    }
    if (this.haberSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.haber === this.haberSelect
      )
    }
    this.configurarFilter(listaHaberesFilter);
    this.inicializarTabla(listaHaberesFilter);
  }
  cargarHaberesLiquidacion(){
    let listaHaberesFilter = this.haberesTrabajador;
    
    listaHaberesFilter = listaHaberesFilter.filter(x => {
      const fechaDesde = startOfMonth(parseISO(x.fechaDesde));
      const fechaHasta =  endOfMonth(parseISO(x.fechaHasta));

      // 0: DESDE 1: HASTA
      return isWithinInterval(this.periodoLiquidacion, { start: fechaDesde, end: fechaHasta });
    })

    this.rangoPeriodo = [
      this.periodoLiquidacion,
      this.periodoLiquidacion,
    ]

    if (this.empresaSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.TrabajadorHaberes.empresa_contratante === this.empresaSelect
      )
    }
    if (this.trabajadorSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.trabajador === this.trabajadorSelect
      );
    }
    if (this.haberSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.haber === this.haberSelect
      )
    }
    this.configurarFilter(listaHaberesFilter);
    this.inicializarTabla(listaHaberesFilter);
  }
  FiltrarDatos(){
    this.rut = this.trabajadorSelect;
    // this.rangoPeriodo = [null, null]

    let listaHaberesFilter = this.haberesTrabajador
      .sort((a, b) => {
        const timeA = parseISO(a.fechaHasta).getTime();
        const timeB = parseISO(b.fechaHasta).getTime();
        return timeA < timeB ? 1 : timeA > timeB ? -1 : 0;
      });

    if (this.empresaSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.TrabajadorHaberes.empresa_contratante === this.empresaSelect
      )
    }
    if (this.trabajadorSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.trabajador === this.trabajadorSelect
      );
    }
    if (this.haberSelect) {
      listaHaberesFilter = listaHaberesFilter.filter(
        x => x.haber === this.haberSelect
      )
    }

    this.configurarRangoPeriodo(listaHaberesFilter);
    this.configurarFilter(listaHaberesFilter);
    this.inicializarTabla(listaHaberesFilter);

  }
  configurarRangoPeriodo(listaHaberesFilter: any[]) {
    // Mapear las fechas a objetos Date
    const fechas = listaHaberesFilter.map(item => ([parseISO(item.fechaDesde),parseISO(item.fechaHasta)]));
    
    // Obtener la fecha mínima y máxima
    const fechaMinima = min(fechas.map(fecha => fecha[0]));
    const fechaMaxima = max(fechas.map(fecha => fecha[1]));
    
    this.rangoPeriodo = listaHaberesFilter.length > 0
      ? [fechaMinima, fechaMaxima]
      : [startOfMonth(this.hoy), endOfMonth(this.hoy)];
    
    // listaHaberesFilter = listaHaberesFilter.filter(x => {
    //   const fechaDesde = parseISO(x.fechaDesde);
    //   const fechaHasta =  parseISO(x.fechaHasta);

    //   // 0: DESDE 1: HASTA
    //   return isWithinInterval(this.rangoPeriodo[0], { start: fechaDesde, end: fechaHasta }) 
    //     || isWithinInterval(this.rangoPeriodo[1], { start: fechaDesde, end: fechaHasta });
    // });
  }
  configurarFilter(haberesTrabajadorList: any[]) {
    this.empresasList = this.empresasList
      .sort(
        (a,b) => a.text.localeCompare(b.text)
      );
    const trabajadorFilter = this.trabajadoresList
    .filter(
      x => x.empresa_contratante === this.empresaSelect
    )
    .map(
      x => {
        const nombreTrabajador = x.nombres + ' ' + x.apellidos;
        return {
          value: x.rut,
          text: nombreTrabajador.toUpperCase()
        }
      }
    ).sort(
      (a, b) => a.text.localeCompare(b.text)
    );
    const haberesFilter = haberesTrabajadorList.map(
      x => {
        const haber = x.Haberes;
        return {
          value: haber.id,
          text: haber.nombre.toUpperCase(),
        }
      }
    ).sort(
      (a,b) => a.text.localeCompare(b.text),
    );
    this.trabajadorFilter = this.eliminarDuplicados(trabajadorFilter);
    this.empresasList = this.eliminarDuplicados(this.empresasList);
    this.haberesFilter = this.eliminarDuplicados(haberesFilter);
  }

  eliminarDuplicados(array: any[]) {
    const conjuntoDeValores = new Set();
    return array.filter(objeto => {
      const valor = objeto['value'];
      if (!conjuntoDeValores.has(valor)) {
          conjuntoDeValores.add(valor);
          return true;
      }
      return false;
    });
  }

  inicializarTabla(haberes: any[]) {
    this.dataSource = new MatTableDataSource(haberes);
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filterLower = filter.toLocaleLowerCase().split(' ');
      
      const trabajador = data.TrabajadorHaberes;
      const empresa = trabajador.Empresa_contratante;
      const haber = data.Haberes;

      const nombre = trabajador.nombres.toLoweCase() 
        + ' ' + trabajador.apellidos.toLowerCase();
      const nombreEmpresa = empresa.nombre.toLowerCase();
      const nombreHaber = haber.nombre.toLowerCase();

      return filterLower.every(
        x => nombre.includes(x) || nombreEmpresa.includes(x) 
        || nombreHaber.includes(x)
      );
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  TraerHaberes() {
    this.haberService.GetAll_Haberes().subscribe((res) => {
      const descuentos = res.data.filter(x => x.tipo === 0);
      const haberes = res.data.filter(x => x.tipo === 1);

      this.habereslist = [
        {
          label: 'Haberes',
          items: haberes,
        },
        {
          label: 'Descuentos',
          items: descuentos
        }
      ];
    });
  }
  
  // ==> METODOS MODAL: ASIGNAR HABER / DESCUENTO A TRABAJADOR
  async open(content: any) {
    const trabajador = this.trabajadoresList.find(x => x.rut === this.rut);
    this.nombreTrabajador = trabajador?.nombres.toUpperCase() + ' ' + trabajador.apellidos.toUpperCase();
    
    this.modalReference = this.modalService.open(content, { size: 'md', modalDialogClass: 'shadow-4' });
    this.modalReference.dismissed.subscribe(() => {
      this.reset();
    });
  }

  CrearHaber() {
    this.haberNuevo.trabajador = this.rut;
    this.haberNuevo.diasHabiles = this.dias;
    this.haberNuevo.diasInhabiles = this.feriados;
    if (this.Validar()) {
      if(this.verificarFechas()){
        this.trabajadorHaberService.AsignarHaber(this.haberNuevo).subscribe(async (res: any) => {
          if (res.success) {
            this.alert.success_small(res.msg!)
            this.modalReference.close();
            this.changeStateHaber = true;
            await this.recargarTabla();
            // await this.TraerDatos();
            // this.RangoPeriodoChange();
            this.reset();
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    }
  }

  seleccionarPeriodo(event: Date, tipo: number) {
    const formatoDate = 'yyyy-MM-dd';
    const month = event.getMonth() + 1 < 10 
      ? `0${event.getMonth() + 1}` 
      : event.getMonth() + 1;
    const year = event.getFullYear();
    if (this.fechaDesde && this.fechaHasta) {
      this.haberNuevo.fechaDesde = format(this.fechaDesde, formatoDate);
      this.haberNuevo.fechaHasta = format(this.fechaHasta, formatoDate);
      this.haberAct.fechaDesde = format(this.fechaDesde, formatoDate); 
      this.haberAct.fechaHasta = format(this.fechaHasta, formatoDate);
    }else if (this.haberNuevo.fechaDesde === '' || this.haberNuevo.fechaHasta === '') {
      if (tipo === 0) {
        this.haberNuevo.fechaDesde = `${year}-${month}-01`;
        this.haberAct.fechaDesde = `${year}-${month}-01`;
      }else{
        this.haberNuevo.fechaHasta = `${year}-${month}-01`;
        this.haberAct.fechaHasta = `${year}-${month}-01`;
      }
    }
    // if (!this.haberFijo) {
    //   this.haberNuevo.fechaHasta = this.haberNuevo.fechaDesde;
    //   this.haberAct.fechaHasta = this.haberAct.fechaDesde;
    // }

    if (this.haberNuevo.fechaHasta !== '' && this.haberAct.fechaHasta !== '') {
      if (this.verificarFechas()) this.calcularBeneficioSemanaCorrida();
    }else{
      this.calcularBeneficioSemanaCorrida();
    }
  }
  verificarFechas(): boolean {
    const desde = parseISO(this.haberNuevo.fechaDesde);
    const hasta = parseISO(this.haberNuevo.fechaHasta);

    if (desde.getTime() > hasta.getTime()) {
      this.messageErrorForm = 'La fecha desde no puede ser mayor a la fecha hasta';
      return false;
    }else if (hasta.getTime() < desde.getTime()) {
      this.messageErrorForm = 'La fecha hasta no puede ser menor a la fecha desde';
      return false;
    }
    this.messageErrorForm = '';
    return true;
  } 

  // METODOS MODAL: SEMANA CORRIDA HABER
  async open2(content: any, id: any) {
    this.haberAct.id = id;
    const resHaberTrabajador = await this.trabajadorHaberService.ObtenerHaberTrabajadorAsync(id);
    if (resHaberTrabajador.success) {
      const haberTrabajador = resHaberTrabajador.data;
      this.fechaDesde = parseISO(haberTrabajador.fechaDesde);
      this.fechaHasta = parseISO(haberTrabajador.fechaHasta);

      const trabajador = this.trabajadoresList.find(x => x.rut === haberTrabajador.trabajador);
      this.nombreTrabajador = trabajador?.nombres.toUpperCase() + ' ' + trabajador?.apellidos.toUpperCase();
      
      const resHaber = await this.haberService.Obtener_HaberesAsync(haberTrabajador.haber);
      if (resHaber.success) {
        const haberConcepto = resHaber.data;
        this.haberFijo = haberConcepto.remCaractFijo === 1;
        this.haberComision = haberConcepto.bSemanaCorrida === 1;
        this.nombreHaber = haberConcepto.nombre.toUpperCase();
 
        this.haberAct.monto = haberTrabajador.monto;
        this.haberAct.montoBSC = haberTrabajador.montoBSC; 
        this.haberAct.bSemanaCorrida = haberTrabajador.bSemanaCorrida;
        this.haberAct.diasHabiles = haberTrabajador.diasHabiles;
        this.haberAct.diasInhabiles = haberTrabajador.diasInhabiles;
        this.haberAct.fechaDesde = haberTrabajador.fechaDesde;
        this.haberAct.fechaHasta = haberTrabajador.fechaHasta;
        this.modalReference2 = this.modalService.open(content, { size: 'md', modalDialogClass: 'shadow-4' });
      }else{
        this.alert.error_small(resHaber.msg);
      }
      // if (haber.bSemanaCorrida) {
      //   this.bSemanaCorrida = true;
      //   this.modalReference3 = this.modalService.open(content2, { size: 'lg' });
      //   this.haberAct.monto = res.data.monto;
      //   this.haberAct.montoBSC = res.data.montoBSC;
      //   this.diasHolidays();
      // }
      // else {
      //   this.bSemanaCorrida = false;
      //   this.modalReference2 = this.modalService.open(content, { size: 'lg' });
      //   this.haberAct.monto = res.data.monto;
      // }
    }else{
      this.alert.error_small(resHaberTrabajador.msg);
    }
  }
  
  /**
   * Calcula el beneficio de la semana corrida.
   */
  async calcularBeneficioSemanaCorrida() {
    if (!this.haberComision) return;

    this.periodo = this.haberNuevo.fechaDesde ? parseISO(this.haberNuevo.fechaDesde) : new Date();
    this.dias = 0;
    this.feriados = 0;

    await this.holidays.cargarFeriados(this.periodo.getFullYear().toString());
    
    const firstDay = startOfMonth(this.periodo); // new Date(this.mes.getFullYear(), this.mes.getMonth(), 1)
    const lastDay = endOfMonth(this.periodo); // new Date(this.mes.getFullYear(), this.mes.getMonth() + 1, 0)
    const dias_semana = this.trabajador.dias_semana; // Math.floor(Math.random() * (6 - 5 + 1) + 5)
    const intervalo = eachDayOfInterval({ start: firstDay, end: lastDay });
    
    if (dias_semana === 5) this.dias = intervalo.filter(x => !this.holidays.esFeriado(x) && (x.getDay() !== 0 && x.getDay() !== 6)).length;
    else if(dias_semana === 6) this.dias = intervalo.filter(x => !this.holidays.esFeriado(x) && (x.getDay() !== 0)).length;
    // CALCULO DE LOS DIAS FERIADOS E INHABILES
    this.feriados = intervalo.filter(x => this.holidays.esFeriado(x) || (x.getDay() === 0)).length;

    // CALCULO EL MONTO DEL BENEFICIO SEMANA CORRIDA
    this.haberNuevo.montoBSC = Math.round((this.haberNuevo.monto / this.dias) * this.feriados);
    this.haberAct.montoBSC = Math.round((this.haberAct.monto / this.dias) * this.feriados);

    // while (countDay <= lastDay) {
    //   if (this.hd.isHoliday(countDay) || countDay.getDay() === 0) {
    //     // SI ES DIA FERIADO O SI ES DIA SABADO Y EL TRABAJADOR TRABAJA SOLO 5 DIAS O ES DIA DOMINGO, CONTAR DIA FERIADO
    //     this.feriados += 1;
    //   } else if(countDay.getDay() === 6 && dias_semana === 6) {
    //     // SI NO, CONTAR DIA TRABAJADO
    //     this.dias += 1;
    //   } else if(countDay.getDay() !== 6 && dias_semana === 5) {
    //     // SI NO, CONTAR DIA TRABAJADO
    //     this.dias += 1;
    //   }
    //   countDay.setDate(countDay.getDate() + 1);
    // }
    // this.haberAct.montoBSC = Math.round((this.haberAct.monto / this.dias) * this.feriados);
  }

  calcularSoloBSC(){
    // CALCULO EL MONTO DEL BENEFICIO SEMANA CORRIDA
    if (this.dias && this.feriados) {
      this.haberNuevo.montoBSC = Math.round((this.haberNuevo.monto / this.dias) * this.feriados);
      this.haberAct.montoBSC = Math.round((this.haberAct.monto / this.dias) * this.feriados);
    }
  }

  onchangebsc() {
    this.haberAct.montoBSC = Math.round((this.haberAct.monto / this.dias) * this.feriados);
  }

  // ==> ELIMINAR HABER ASIGNADO
  eliminar(id: any) {
    if (!confirm('Eliminar haber\n¿Esta seguro que desea eliminar el haber?')) return;
    
    this.trabajadorHaberService.EliminarAsignacion(id).subscribe(async (res: any) => {
      if (res.success) {
        this.alert.success_small(res.msg!);
        this.changeStateHaber = true;
        await this.recargarTabla();
        // await this.TraerDatos();
        // this.RangoPeriodoChange();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }

  // ==> ACTUALIZAR HABER ASIGNADO
  Actualizar() {
    // if (!this.haberFijo) {
    //   this.haberAct.fechaHasta = this.haberAct.fechaDesde;
    // }
    if (this.verificarFechas()) {
      this.trabajadorHaberService.ActualizarHaberTrabajador(this.haberAct).subscribe(async res => {
        if (res.success) {
          this.alert.success_small(res.msg!)
          this.modalReference2.close();
          this.changeStateHaber = true;
          await this.recargarTabla();
          // await this.TraerDatos();
          // this.RangoPeriodoChange();
          this.debouncer.next(true);
        } else {
          this.alert.error_small(res.msg!)
        }
      })
    }
  }

  // ==> VALIDAR INFORMACION HABER
  Validar() {
    if (this.haberNuevo.fechaDesde !== '' && this.haberNuevo.haber != 0 && this.haberNuevo.monto != 0) {
      return true;
    }else{
      this.alert.errorAlert('Faltan datos o son incorrectos, favor revisar');
      return false;
    }
  }

  reset() {
    this.haberNuevo.haber = 0;
    this.haberNuevo.monto = 0;
    this.haberNuevo.fechaDesde = '';
    this.haberNuevo.fechaHasta = '';
    this.haberNuevo.bSemanaCorrida = 0;
    this.haberNuevo.montoBSC = 0;
    this.haberNuevo.diasHabiles = 0;
    this.haberNuevo.diasInhabiles = 0;
    this.haberNuevo.trabajador = 0; 
    this.haberSeleccionado = null;
    this.messageErrorForm = '';

    this.fechaDesde = null;
    this.fechaHasta = null;
  }

  onchangeHaber(event: any) {
    const haber = event.value;
    const nombreHaber:string = haber.nombre;
    this.haberNuevo.bSemanaCorrida = this.haberSeleccionado.bSemanaCorrida;
    this.haberNuevo.haber = this.haberSeleccionado.id;

    this.haberFijo = haber.remCaractFijo === 1;
    this.haberComision = haber.bSemanaCorrida === 1;

    // REVISA SI ES EL PRESTAMO SOLIDARIO 3%
    const regex = /(?=.*RET)(?=.*PREST)(?=.*SOLIDARIO)(?=.*3%)/i;
    if (regex.test(nombreHaber)) {
      // RETENCION PRESTAMO SOLIDARIO 3% CLASE MEDIA
      this.haberNuevo.monto = isNaN(Math.round(this.tributable * 0.03)) ? 0 : Math.round(this.tributable * 0.03);
    }
  }
}