

<mat-form-field appearance="outline">
  <mat-label>Buscar</mat-label>
  <input matInput (keyup)="applyFilter($event)"  #input>
</mat-form-field>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
  
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de la Afp</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.nombre | uppercase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tasa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tasa de la Afp</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.tasa | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Codigo LRE</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          {{ element.cod_afp }}
        </td>
      </ng-container>
          
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          <button 
            (click)="open(content, element.id)"
            mat-button class="button_color_primary">
            <mat-icon style="color: white">edit</mat-icon>
          </button>
          &nbsp;
          <button 
            (click)="Eliminar_Afp(content, element.id)"
            mat-button class="button_color_primary" style="background-color:red">
            <mat-icon style="color: white">delete</mat-icon>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Seleccionar pagina de usuarios."
    >
    </mat-paginator>
  </div>
  
  <ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Actualizar Afp</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Nombre de la afp</mat-label>
            <input
              type="text"
              maxlength="50"
              minlength="3"
              matInput
              name="afp.nombre"
              [(ngModel)]="update_afps.nombre"
            />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Codigo LRE</mat-label>
            <input
              type="text"
              maxlength="50"
              minlength="3"
              matInput
              name="afp.cod_afp"
              [(ngModel)]="update_afps.cod_afp"
            />
            
          </mat-form-field>
        </div>             
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Tasa de la afp</mat-label>
              <input
                type="text"
                maxlength="50"
                minlength="1"
                matInput
                name="afp.tasa"
                autonumeric [options]="optionsNumericDecimal"
                [(ngModel)]="update_afps.tasa"
              />
            </mat-form-field>
          </div>                
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">Cancelar</span>
      </button>
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="Actualizar_Afps()"
      >
        Actualizar
      </button>      
    </div>
  </ng-template>