<div>
  <button mat-button class="button_color_primary" (click)="open(content)">
    <span style="color: white">Agregar Empresa </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar nueva empresa</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Identificación</b>
          </div>
        </p-divider>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre </mat-label>
          <input type="text" maxlength="50" minlength="3" matInput name="empresas.nombre"
            [(ngModel)]="empresas.nombre" />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Rut </mat-label>
          <input type="text" matInput name="empresas.run" (keyup)="formatearRut($event, 'empresa')" [(ngModel)]="empresas.run" />
        </mat-form-field>
      </div>
      <div class="col col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Giro</mat-label>
          <input type="text" matInput name="empresas.giro" [(ngModel)]="empresas.giro" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Dirección y contacto</b>
          </div>
        </p-divider>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Ciudad </mat-label>
          <mat-select name="empresas.id_ciudad" [(ngModel)]="empresas.id_ciudad">
            <mat-option *ngFor="let ciudad of list_ciudades" value="{{ ciudad.id }}">
              {{ ciudad.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Ubicacion </mat-label>
          <input type="text" maxlength="50" minlength="3" matInput name="empresas.ubicacion"
            [(ngModel)]="empresas.ubicacion" />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Correo </mat-label>
          <input type="text" maxlength="50" minlength="3" matInput name="empresas.correo"
            [(ngModel)]="empresas.correo" />
        </mat-form-field>
      </div>
      <div class="col col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Numero Telefono </mat-label>
          <input type="number" maxlength="11" minlength="9" matInput name="empresas.num_telefono"
            [(ngModel)]="empresas.num_telefono" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Datos representante</b>
          </div>
        </p-divider>
      </div>
      <div class="col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Representante </mat-label>
          <input type="text" maxlength="50" minlength="3" matInput name="empresas.representante"
            [(ngModel)]="empresas.representante" />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Cedula </mat-label>
          <input type="text" matInput name="empresas.cedula" (keyup)="formatearRut($event, 'representante')"
            [(ngModel)]="empresas.cedula" />
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Firma</mat-label>
          <mat-select (selectionChange)="mostrarFirma()" [(value)]="firmaSelected">
            <mat-option *ngFor="let firma of firmas" [value]="firma.id">{{firma.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="firmaSrc">
        <img class="img-fluid rounded mx-auto d-block" [src]="firmaSrc" alt="Firma Preview">
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p-divider align="left">
          <div class="inline-flex align-items-center">
            <b>Mutual de seguridad</b>
          </div>
        </p-divider>
      </div>
      <div class="col-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre </mat-label>
          <mat-select name="empresas.nombreMutual" [(ngModel)]="empresas.nombreMutual">
            <mat-option *ngFor="let mutual of mutuales" value="{{ mutual.value }}">
              {{ mutual.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Cotización básica </mat-label>
          <input type="number" maxlength="11" minlength="9" matInput name="empresas.cotizBasica"
            [(ngModel)]="empresas.cotizBasica" />
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Cotización ley Sanna </mat-label>
          <input type="number" maxlength="11" minlength="9" matInput name="empresas.cotizLeySanna"
            [(ngModel)]="empresas.cotizLeySanna" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="agregar()">
      Guardar
    </button>
  </div>
</ng-template>