import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { debounceTime } from 'rxjs/operators';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import { Firma } from 'src/app/shared/models/firmas.interface';
import { FirmasService } from 'src/app/pages/services/firmas.service';
import { environment } from 'src/environments/environment';
import { cleanRut, formatRut } from 'rutlib';

@Component({
  selector: 'app-agregar-empresas',
  templateUrl: './agregar-empresas.component.html',
  styleUrls: ['./agregar-empresas.component.css']
})
export class AgregarEmpresasComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  @Input() list_ciudades!:Ciudad[];
  debouncer: Subject<boolean> = new Subject();  

  empresas:Empresa={
    nombre:"",
    run:"",
    ubicacion: "",
    ciudad: 0,
    representante: "",
    correo:"",
    cedula: "",
    id_ciudad:0,
    nombreMutual: '',
    cotizBasica: 0.0,
    cotizLeySanna: 0.0,
    num_telefono:'',
    giro: '',
  }
  firmas: Firma[] = [];
  firmaSelected: number;
  firmaSrc: string = '';

  mutuales:any[] = [
    {value: 'Sin Mutual', text: 'Sin mutual' },
    {value: 'Asociación Chilena de Seguridad', text: 'Asociación Chilena de Seguridad (ACHS)'},
    {value: 'Instituto de Seguridad del Trabajo', text: 'Instituto de Seguridad del Trabajo (IST)'},
    {value: 'Mutual de Seguridad CChC', text: 'Mutual de Seguridad CChC'},
  ]

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private empresaService: EmpresaService,
    private firmaService: FirmasService,
  ) {
    this.firmaSelected = null;
  }

  async ngOnInit(): Promise<void> {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
    const res = await this.firmaService.getFirmas();
    if (res.success) {
      this.firmas = res.data;
    }else{
      this.firmas = [];
    }
  }

  mostrarFirma(){
    const firma = this.firmas.find(f => f.id === this.firmaSelected);
    if (firma) {
      this.firmaSrc = `${environment.API}${firma.rutaImg}`;
    }
  }

  open(content:any){
   
    this.modalService.open(content, { size: 'lg' });
  }

  validarEmpresa(){
    if(this.empresas.nombre.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.')
    }
  }

  formatearRut(event: any, tipo: string){
    const value = (event.target.value as string);
    if (value.length > 12) {
      if (tipo === 'empresa') this.empresas.run = value.slice(0, 12);
      if (tipo === 'representante') this.empresas.cedula = value.slice(0, 12);
      return;
    };
    if (value.length <= 2) return; 
    if (tipo === 'empresa') this.empresas.run = formatRut(value);
    if (tipo === 'representante') this.empresas.cedula = formatRut(value);
  }


  agregar() {
    this.empresas.ciudad = this.empresas.id_ciudad;
    this.empresas.idfirma = this.firmaSelected;
    this.empresaService.Create_empresa(this.empresas).subscribe((res: any) => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    });
  }
}
