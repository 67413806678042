import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { SolicitudDespidoService } from 'src/app/pages/services/solicitud/solicitud-despido.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { UsuariosService } from 'src/app/pages/services/usuarios.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-agregar-solicitud-despido',
  templateUrl: './agregar-solicitud-despido.component.html',
  styleUrls: ['./agregar-solicitud-despido.component.css']
})
export class AgregarSolicitudDespidoComponent implements OnInit {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: NgbModal,
    private auth: AuthService,
    private alert: AlertHelper,
    private user_services: UsuariosService,
    private trabajadorService: TrabajadoresService,
    private solicitudService: SolicitudDespidoService,
    ){ }

  ngOnInit(): void {
    this.getAllTrabajadorInfo();
  }

  solicitud: any = {

    rut: "",
    trabajador: "",
    razon: "",
    empresa: "",
    cargo: "",
    sucursal: "",

  }
  razon: string;

  selectedValueTrabajador: number;
  selectedValue: number;
  trabajadores: any[] = [];
  despidos: any[] = [];


  open(content: any) {

    this.limpiarCampos();
    const ref = this.modalService.open(content);
    ref.dismissed.subscribe(() => {
      this.onClose.emit(true);
    })
  }

  getAllTrabajadorInfo(): void {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res: any) => {
        if (res.success) {
          this.trabajadores = res.data.map(x => ({value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }))
            .sort((a, b) => a.text.localeCompare(b.text));

        }
      }
    );
  }

  onTrabajadorChange(event:any): void{
    this.selectedValueTrabajador = event;

    this.trabajadorService.TraerTrabajadoresRut(this.selectedValueTrabajador).subscribe(
      (res: any) => {
        if (res.success) {
          console.log(res);
            this.solicitud.rut = res.data[0].rut;
            this.solicitud.trabajador = `${res.data[0].nombres} ${res.data[0].apellidos}`;
            this.solicitud.razon = this.razon;
            this.solicitud.empresa = res.data[0].Empresa_contratante.nombre;
            this.solicitud.cargo = res.data[0].Cargo.nombre;
            this.solicitud.sucursal = res.data[0].Sucursal.nombre;

        }
      }
    )
  }

  limpiarCampos(){
    this.selectedValueTrabajador = null;
    this.solicitud.razon = "";
  }

 


  Enviar(){

    const data = {
      fk_trabajador: this.solicitud.rut,
      nombre: this.solicitud.trabajador,
      razon: this.solicitud.razon,
      empresa: this.solicitud.empresa,
      cargo: this.solicitud.cargo,
      sucursal: this.solicitud.sucursal,
      estado: 0,
    }

    this.solicitudService.crearSolicitudDespido(data).subscribe(
      (res: any) => {
        if(res.success){
          this.modalService.dismissAll();
          this.limpiarCampos();
          this.alert.success_small('Solicitud realizada con éxito');
        }
      })
  }

}
