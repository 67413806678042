import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainJornadaLaboralComponent } from './main-jornada-laboral/main-jornada-laboral.component';
import { ListaJornadaLaboralComponent } from './lista-jornada-laboral/lista-jornada-laboral.component';
import { AgregarJornadaLaboralComponent } from './agregar-jornada-laboral/agregar-jornada-laboral.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';



@NgModule({
  declarations: [
    MainJornadaLaboralComponent,
    ListaJornadaLaboralComponent,
    AgregarJornadaLaboralComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatDatepickerModule,
    MatSortModule,
    MatSortModule
  ],
  exports:[
    MainJornadaLaboralComponent,
    AgregarJornadaLaboralComponent,
    ListaJornadaLaboralComponent,
  ]
})
export class JornadaLaboralModule { }
