import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'ng2-file-upload';
import { HelperModule } from 'src/app/helper.module';
import { RippleModule } from 'primeng/ripple';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';
import { MainCartasComponent } from './main-cartas/main-cartas.component';
import { ListaCartasTrabajadorComponent } from './lista-cartas-trabajador/lista-cartas-trabajador.component';
import { ModalAgregarComponent } from './modal-agregar/modal-agregar.component';
import { ModalMostrarComponent } from './modal-mostrar/modal-mostrar.component';
import { GenerarCartaComponent } from './generar-carta/generar-carta.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';


@NgModule({
  declarations: [
    MainCartasComponent,
    ListaCartasTrabajadorComponent,
    ModalAgregarComponent,
    ModalMostrarComponent,
    GenerarCartaComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatMomentDateModule,
    FileUploadModule,
    RippleModule,
    ToastModule,
    HelperModule,
    BuscarFilterModule,
    PrimeNgModule,
  ],
  exports:[
    MainCartasComponent,
    ListaCartasTrabajadorComponent,
    ModalAgregarComponent,
    ModalMostrarComponent
  ],

  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' } //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
                                                    // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 
  ]
})
export class CartasAmonestacionModule { }
