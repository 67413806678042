import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
import { SolicitudesDocumento } from 'src/app/shared/models/solicituddocumento.interface';

@Component({
  selector: 'app-elegir-desicion-correo',
  templateUrl: './elegir-desicion-correo.component.html',
  styleUrls: ['./elegir-desicion-correo.component.css']
})
export class ElegirDesicionCorreoComponent implements OnInit {
  option: number;
  code: string;
  solicitud: SolicitudesDocumento;

  infoMensaje: string;
  solicitudActualizada: boolean;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private solicitudService: SolicitudesDocumentosService,
    private auth: AuthService,
  ) {
    const user = this.auth.ObtenerinfoToken();
    const rol = user.rol;
    if (rol === 'ADMIN' || rol === 'GERENCIA') {
      
    }
    console.log({user});

    this.option = 0;
    this.code = '';
    this.infoMensaje = '';
    this.solicitudActualizada = false;
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.obtenerParametros()
    await this.obtenerSolicitud();
    if (this.solicitud.estado === 0) {
      this.verificarSolicitud();
      await this.actualizarSolicitud();
    }else{
      this.infoMensaje = 'La solicitud se encuentra resolvida.';
    }
    this.loading = false;

    console.log('option: ', this.option);
    console.log('code: ', this.code);
  }
  obtenerParametros() {
    this.option = parseInt(this.route.snapshot.paramMap.get('option'));
    this.code = this.route.snapshot.paramMap.get('code').trim();
  }
  async obtenerSolicitud() {
    const res = await this.solicitudService.TraerPorCodigo(this.code);
    if (!res.success) {
      this.infoMensaje = res.msg;
      return;
    }
    this.solicitud = res.data;
  }
  verificarSolicitud() {
    if (this.solicitud) {
      if (this.option === 1 || this.option === 0) this.solicitud.estado = this.option === 1 ? 1 : 2;
      else this.infoMensaje = 'No se pudo crear la aprobación debido a un error en los datos.\nPruebe mas tarde.';
    }else{
      this.infoMensaje = 'Al parecer no existe la solicitud, o hubo un error al obtener los datos.';
    }
  }
  async actualizarSolicitud() {
    if (this.solicitud) {
      const res = await this.solicitudService.ActualizarAprobacion(this.solicitud);
      if (!res.success) {
        this.infoMensaje = 'Hubo un error al ingresar la opción.\nPruebe mas tarde.';
        return;
      }
      this.solicitudActualizada = true;
    }else{
      this.infoMensaje = 'Al parecer no existe la solicitud, o hubo un error al obtener los datos.';
    }
  }
  navigate(){
    this.router.navigate(['main-solicitudes-documentos'], {replaceUrl: true});
  }

}
