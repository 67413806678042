import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {Documento} from 'src/app/shared/models/documentos/documentos.interface';
import {DocumentosService} from 'src/app/pages/services/documentos.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-lista-contrato',
  templateUrl: './lista-contrato.component.html',
  styleUrls: ['./lista-contrato.component.css']
})
export class ListaContratoComponent implements OnInit {



@Input() fk_trabajador!: any;

documentos:any

  constructor(

    private documentoService: DocumentosService
  ) { }

  ngOnInit(): void {
   
    let url=`${environment.API}/contratos/`
    this.documentoService.GetAll_contrato(this.fk_trabajador).subscribe(
      (res) => {
          this.documentos = res.data;
          for (let i = 0; i < this.documentos.length; i++) { 
            this.documentos[i].ruta = url+this.documentos[i].ruta
            //console.log(this.documentos[i].ruta);
          }
         
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    

    if (changes.fk_trabajador.currentValue != changes.fk_trabajador.previousValue){

      this.documentos=[]
      this.documentoService.GetAll_contrato(this.fk_trabajador).subscribe(
        (res) => {
        }
      );
    }
  

}
}
