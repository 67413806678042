
import { Razon } from 'src/app/shared/models/razon.interface';
import { NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RazonService } from 'src/app/pages/services/razon/razon.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-lista-razon',
  templateUrl: './lista-razon.component.html',
  styleUrls: ['./lista-razon.component.css'],
  providers: [NgbAlertConfig]

})

export class ListaRazonComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @Input() razon_List: Razon[] = [];
  debouncer: Subject<boolean> = new Subject();


  @ViewChild(MatSort) sort!: MatSort;

  //variables vacias

  lista_razones: any; // para guardar la lista


  razones_update: any;  // Crud del modal

  dataSource: any; //guardar datos de la tabla 


  btn_colores: any

  //
  update_prueba: Razon = {
    id: 0,
    razon: "",
    esAtraso: false,
    colorfondo: "",
    colortexto: "",
  };

  opciones: any[] = [
    { value: 0, text: 'NO' },
    { value: 1, text: 'SI' },
  ];
  
  //uso obligatorio para poder crear una tabla 
  displayedColumns = [
    'id',
    'razon',
    'colorfondo',
    'colortexto',
    'btncolortexto',
    'acciones',
  ];

  constructor(

    private razonService: RazonService,
    private modalService: NgbModal,
    private alert: AlertHelper

  ) {




  }

  public colorf: string = '#2889e9';
  public colort: string = '#2889e9';



  open(content: any, id: number) {
    this.update_prueba.id = id
    this.razonService.TraerRazonId(id).subscribe(res => {
      this.razones_update = res.data[0];

    })

    this.limpiar_razon();
    this.modalService.open(content);
  }


  limpiar_razon() {
    this.update_prueba.razon = "";
    this.update_prueba.colorfondo = "";
    this.update_prueba.colortexto = "";
  }


  eliminar(id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el razon",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {


        this.razonService.Eliminar_razon(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
            this.ngOnInit();

          }


        })


      }
    })

  }

  ActualizarRazon() {

    this.razonService.Actualizar_razon(this.razones_update).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg)
        this.modalService.dismissAll();
        this.limpiar_razon();
        this.ngOnInit();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })

  }
  TraerDatos(event: boolean) {

    this.razonService.lista_razon().subscribe(
      (res) => {

        this.lista_razones = {};
        this.lista_razones = res.data;
        this.dataSource = this.lista_razones;

        this.dataSource = new MatTableDataSource(this.lista_razones);

        this.dataSource.paginator = this.paginator

        this.btn_colores = this.lista_razones;


      }
    );

  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }




  ngOnInit(): void {
    this.TraerDatos(true);

    
  }

}



