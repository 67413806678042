import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { addMonths } from 'date-fns';
import { GenerarResumenHaberesService } from 'src/app/pages/services/generar-resumen-haberes.service';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { OpcionesElegidasResumen } from 'src/app/shared/models/opciones-elegidas-resumen.interface';

@Component({
  selector: 'app-generar-resumen-haberes',
  templateUrl: './generar-resumen-haberes.component.html',
  styleUrls: ['./generar-resumen-haberes.component.css']
})
export class GenerarResumenHaberesComponent implements OnInit {
  @Input() empresas: Empresa[] = [];

  opciones_elegidas: OpcionesElegidasResumen;

  isLoading: boolean;

  constructor(
    private generarResumenService: GenerarResumenHaberesService,
  ) {
    this.isLoading = false;
    this.opciones_elegidas = {
      empresaSelected: null,
      periodo: new Date(),
    }
    generarResumenService.onCompleteEvent().subscribe((res: boolean) => this.isLoading = res);
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.empresas.currentValue !== changes.empresas.previousValue) {
      this.empresas = ((changes.empresas.currentValue) as Empresa[])
        .sort((a:Empresa, b:Empresa) => 
          a.nombre.localeCompare(b.nombre)
        );
    }
  }

  async generarResumen() {
    this.isLoading = true;
    await this.generarResumenService.generarResumenHaberes(this.opciones_elegidas);
  }

  changeMonth(modo: string) {
    if (modo === 'PREV') this.opciones_elegidas.periodo = addMonths(this.opciones_elegidas.periodo, -1);
    if (modo === 'LATER') this.opciones_elegidas.periodo = addMonths(this.opciones_elegidas.periodo, 1);
  }

}
