import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subir-liquidacion-firmada',
  templateUrl: './subir-liquidacion-firmada.component.html',
  styleUrls: ['./subir-liquidacion-firmada.component.css']
})
export class SubirLiquidacionFirmadaComponent implements OnInit {
  @Input() idliquidacion: number;
  @Input() rut: number;

  uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/liquidacion/documentoFirmado`,
    itemAlias: 'liquidacion',
  });
  liquidacionFirma: {
    id: number,
    rut: number,
    fechaFirma: Date,
  };

  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
  ) {
    this.uploader.onSuccessItem = (item, response) => {
      this.alert.success_small('Liquidacion firmada subida correctamente.');
      this.modalService.dismissAll();
    }   
    this.uploader.onErrorItem = () => {
      this.alert.error_small('Hubo un error al subir la liquidacion firmada.');
    }
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    }
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('id', this.liquidacionFirma.id);
      form.append('fechaFirma', this.liquidacionFirma.fechaFirma);
      form.append('rut', this.liquidacionFirma.rut);
    }
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
  }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChanges){
    console.log({change});
    if (change.idliquidacion || change.rut) {
      this.liquidacionFirma = {
        id: this.idliquidacion,
        rut: this.rut,
        fechaFirma: new Date(),
      }
    }
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }
  fileOverAnother(e: any) {
    this.hasAnotherDropZoneOver = e;
  }

  subirLiquidacionFirmada(){
    this.uploader.uploadAll();
  }

}
