import { Component, Input, OnInit, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { endOfMonth, format, getMonth, getYear, parseISO, startOfMonth } from 'date-fns';
import { GenerarCartaAmonestacionService } from 'src/app/pages/services/PDF-GENERADOR/carta-amonestacion/carta-amonestacion.service';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service';
import { MotivosCartaAmonestacionService } from 'src/app/pages/services/motivos-carta-amonestacion.service';
import { Asistencia } from 'src/app/shared/models/gestor-asistencia/gestor-asistencia.interface';

@Component({
  selector: 'app-generar-carta',
  templateUrl: './generar-carta.component.html',
  styleUrls: ['./generar-carta.component.css']
})
export class GenerarCartaComponent implements OnInit {
  @Input() trabajador: any;
  @Output() generateLetter: boolean;

  TEXT_MATCH = /%([^%]+)%/g;

  motivosCarta: any[] = [];
  selectedMotivo: any;
  
  descripcionFormated: string = '';

  // BOOLEAN PARA MOSTRAR ITEMS
  isListaFecha: boolean = false;
  isListaFechaHora: boolean = false;
  isListaAtraso: boolean = false;

  fechasFilterAtrasos: Date[];

  // ITEM ATRASOS
  atrasos: Asistencia[] = [];
  fechasAtrasos: string[] = [];
  fechasAtrasosSelected: string[] = [];

  // ITEM FECHAS SELECCIONADAS
  fechasSelected: Date[] = [];

  // ARRAY Y OBJETOS PARA EL FORMULARIO
  grados: any[] = [
    {value: '1', text: 'MENOR'},
    {value: '2', text: 'MEDIO'},
    {value: '3', text: 'GRAVE'},
  ]
  cartaAmonestacion: any = {
    rut: 0,
    fecha: '',
    grado: 0,
    motivo: '',
    idmotivo: 0,
  }
  objGenerarCarta: any = {
    carta: null,
    trabajador: null,
    descripcion: '',
  }

  constructor(
    private modalService: NgbModal,
    private generarCartaService: GenerarCartaAmonestacionService,
    private motivoCartaService: MotivosCartaAmonestacionService,
    private asistenciaService: GestorAsistenciaService,
  ) { 
    this.fechasFilterAtrasos = [
      startOfMonth(new Date()),
      endOfMonth(new Date()),
    ];
  }

  async ngOnInit(): Promise<void> {
    const res = await this.motivoCartaService.getMotivosAsync();
    if (res.success) this.motivosCarta = res.data; 
    else alert(res.msg);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.trabajador.currentValue) {
      this.cartaAmonestacion.rut = this.trabajador.rut;
      this.cartaAmonestacion.fecha = format(new Date(), 'yyyy-MM-dd');
      this.cartaAmonestacion.grado = 1;
    }
  }

  openGenerar(content: any){
    this.modalService.open(content, { size: 'lg'});
  }
  GenerarCarta(){
    this.objGenerarCarta.carta = this.cartaAmonestacion;
    this.objGenerarCarta.trabajador = this.trabajador;
    this.objGenerarCarta.descripcion = this.descripcionFormated;
    this.generarCartaService.generarCartaAmonestacion(this.objGenerarCarta);
  }
  async verificarMotivo(){
    this.isListaFecha = false;
    this.isListaFechaHora = false;
    this.isListaAtraso = false;
    this.cartaAmonestacion.idmotivo = this.selectedMotivo.id;

    await this.configurarDescripcion();
  }
  async configurarDescripcion() {
    // SI ES EL MOTIVO PERSONALIZADO DEJAR VACIO EL TEXTAREA
    if (this.selectedMotivo.id === 1) {
      this.descripcionFormated = '';
      return;
    }
    const descripciones = this.selectedMotivo.descripcion.match(this.TEXT_MATCH);
    if (!descripciones) return;

    this.descripcionFormated = this.selectedMotivo.descripcion;

    if (descripciones.includes('%TRABAJADOR%')) {
      this.descripcionFormated = this.selectedMotivo.descripcion.replace('%TRABAJADOR%', this.trabajador.nombres.toUpperCase() + ' '+ this.trabajador.apellidos.toUpperCase());
    }
    // MOSTRAR SECCION LISTA DE FECHAS
    if (descripciones.includes('%LISTA_FECHA%')) {
      this.configurarFecha();
      this.isListaFecha = true;
    }
    // MOSTRAR SECCION LISTA DE FECHAS Y HORAS
    if (descripciones.includes('%LISTA_FECHA_HORA%')) {
      this.configurarFechaHora();
      this.isListaFechaHora = true;
    }
    // MOSTRAR SECCION LISTA DE ATRASOS
    if (descripciones.includes('%LISTA_FECHA_ATRASOS%')) {
      await this.configurarAtrasos();
      this.isListaAtraso = true;
    }
  }
  async obtenerAtrasos() {
    this.fechasFilterAtrasos = [
      startOfMonth(this.fechasFilterAtrasos[0]),
      endOfMonth(this.fechasFilterAtrasos[1]),
    ]

    const res = await this.asistenciaService.buscarPorRut(this.trabajador.rut);
    if (res.success) {
      this.atrasos = res.data.filter(x => x.Razon.id === 6);
    }else{
      console.log('ERROR', res.msg);
    }
  }
  addFechasAtraso(){
    // IMPRIMIR LOS MESES
    this.imprimirMeses();
    this.descripcionFormated = this.fechasAtrasosSelected.length > 0 
      ? this.descripcionFormated.replace('%LISTA_FECHA_ATRASOS%', this.fechasAtrasosSelected.map(x => '- '+x).join('\n')) 
      : this.descripcionFormated.replace('%LISTA_FECHA_ATRASOS%', 'Sin fechas seleccionadas');
  }
  async configurarAtrasos(){
    await this.obtenerAtrasos();
    if (this.atrasos.length > 0) {
      this.fechasAtrasos = this.atrasos.filter(x => {
        const fechaInicio = parseISO(x.start);
        const fechaFin = parseISO(x.end);

        return (getMonth(fechaInicio) === getMonth(this.fechasFilterAtrasos[0]) && getYear(fechaInicio) === getYear(this.fechasFilterAtrasos[0])) 
          || (getMonth(fechaFin) === getMonth(this.fechasFilterAtrasos[1]) && getYear(fechaFin) === getYear(this.fechasFilterAtrasos[1]))
      }).map((x:Asistencia) => parseISO(x.start)
        .toLocaleDateString('es', { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' })
        .replace(/,/g, '') + ' ' + x.timeEnd );
      this.imprimirMeses();
      this.descripcionFormated = this.fechasAtrasosSelected.length > 0
        ? this.descripcionFormated.replace('%LISTA_FECHA_ATRASOS%', this.fechasAtrasosSelected.map(x => '- '+x).join('\n'))
        : this.descripcionFormated.replace('%LISTA_FECHA_ATRASOS%', 'Sin fechas seleccionadas');
    }
  }
  configurarFecha(){
    this.imprimirMeses();
    this.descripcionFormated = this.fechasSelected.length > 0 
      ? this.descripcionFormated.replace('%LISTA_FECHA%', this.fechasSelected.map(x => '- '+x.toLocaleDateString('es', { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' })).join('\n')) 
      : this.descripcionFormated.replace('%LISTA_FECHA%', 'Sin fechas seleccionadas');
  }
  configurarFechaHora() {
    this.imprimirMeses();
    this.descripcionFormated = this.fechasSelected.length > 0 
      ? this.descripcionFormated.replace('%LISTA_FECHA_HORA%', this.fechasSelected.map(x => '- '+x.toLocaleDateString('es', { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', weekday: 'long' })).join('\n')) 
      : this.descripcionFormated.replace('%LISTA_FECHA_HORA%', 'Sin fechas seleccionadas');
  }

  imprimirMeses(){
    // IMPRIMIR LOS MESES
    if (this.selectedMotivo.descripcion.includes('%MESES_SELECCIONADOS%')) {
      const meses = new Set();
      this.fechasFilterAtrasos.forEach(x => meses.add(x.toLocaleDateString('es', { month: 'long' })));
      const mesesArray = Array.from(meses);
      this.descripcionFormated = mesesArray.length > 0 
        ? this.selectedMotivo.descripcion.replace('%MESES_SELECCIONADOS%', mesesArray.join(', '))
        : this.selectedMotivo.descripcion.replace('%MESES_SELECCIONADOS%', '');
    }
  }
}

