import { Component, Input, OnInit } from '@angular/core';
import saveAs from 'file-saver';

@Component({
  selector: 'app-modal-mostrar-pdf',
  templateUrl: './modal-mostrar-pdf.component.html',
  styleUrls: ['./modal-mostrar-pdf.component.css']
})
export class ModalMostrarPdfComponent implements OnInit {
  @Input() src!:any;
  constructor() { }

  ngOnInit(): void {
    //console.log("estoy en el modal")
    //console.log(this.src)
  }
  printpdf() {
    saveAs(this.src, 'contrato.pdf');
    }
}

