import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSelectChange } from '@angular/material/select'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { PlanillaPagosService } from 'src/app/pages/services/planilla-pagos.service'
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import Swal from 'sweetalert2'
import { Planilla } from '../../../../../shared/models/planilla-pagos.interface'

@Component({
  selector: 'app-lista-planilla-pagos',
  templateUrl: './lista-planilla-pagos.component.html',
  styleUrls: ['./lista-planilla-pagos.component.css'],
  providers: [DatePipe]

})
export class ListaPlanillaPagosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @Input() lista_planilla!: Planilla[]
  debouncer: Subject<boolean> = new Subject()

  update_planilla: Planilla = {
    fecha: "",
    fk_trabajador: "",
    sueldo: 0,
    bonos: 0,
    adelanto: 0,
    prestamos: 0,
    inasistencia: 0,
    asignacionFamiliar: 0,
    sueldoLiquido: 0,
    totalAPagar: 0,
    transferencia: ""
  }

  displayedColumns: string[] = ['id','fecha','fk_trabajador', 'sueldo', 'bonos', 'adelanto', 'prestamos',
    'inasistencia', 'asignacionFamiliar', 'sueldoLiquido', 'totalAPagar', 'transferencia', 'actions']

  dataSource = new MatTableDataSource<Planilla>(this.lista_planilla)

  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  constructor(
  
    private alert: AlertHelper,
    private planillaService: PlanillaPagosService,
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
     private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.lista_planilla != undefined) {
      if (!changes.lista_planilla.firstChange) {
        this.dataSource = new MatTableDataSource<Planilla>(this.lista_planilla)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
      }
    }
  }
  ObtenerTrabajador(event: MatSelectChange) {

    this.update_planilla.fk_trabajador=event.value;
  
   }


  actualizar_planilla() {

  
 this.update_planilla.id

this.planillaService.Actualizar_planilla(this.update_planilla).subscribe(res => {
        
  if (res.success == true) {
    this.alert.success_small(res.msg!)
    this.modalService.dismissAll();
   
    this.debouncer.next(true);
  } else {
    this.alert.error_small(res.msg!)
  }
})

  }
trabajador: any;

  open(content: any, id: number) {


    this.update_planilla.id = id
    this.planillaService.Obtener_planilla(id).subscribe(res => {
       


   
      this.update_planilla.fecha = res.data.fecha
      this.update_planilla.fk_trabajador = res.data.fk_trabajador
      this.update_planilla.sueldo = res.data.sueldo
      this.update_planilla.bonos = res.data.bonos
      this.update_planilla.adelanto = res.data.adelanto
      this.update_planilla.prestamos = res.data.prestamos
      this.update_planilla.inasistencia = res.data.inasistencia
      this.update_planilla.asignacionFamiliar = res.data.asignacionFamiliar
      this.update_planilla.sueldoLiquido = res.data.sueldoLiquido
      this.update_planilla.totalAPagar = res.data.totalAPagar
      this.update_planilla.transferencia = res.data.transferencia
    })

    this.trabajadoresService.TraerTrabajadores().subscribe((res:any)=>{ 

      this.trabajador= res.data;
      
    
    })
    
    this.modalService.open(content)
  }

  eliminar_planilla(content: any, id: number) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara la plantilla",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
    this.planillaService.Eliminar_planilla(id).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.debouncer.next(true)
      }
      else {
        this.alert.error_small(res.msg!)
      }
    })
  }
})
  }

}
