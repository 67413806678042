import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MenuInterface } from './interfaces_sidebar/interfaces_sidebar.interface';
import { MatSidenav } from '@angular/material/sidenav';
import { Roles } from '../../models/Enums/roles.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() menuCoosen : boolean;
  @Input() sidenav: MatSidenav;
  panelOpenState = false;
  isAdmin: string = "";

  //asignar rutas de menu de administrador

  typesOfOptionsAdmin: MenuInterface[] = [
    { nombreItem: 'Registros/Logs', icon: 'restore', route: "listar-logs", roles: [Roles.ADMIN], menuContabilidad: false },
    { nombreItem: 'Trabajadores', icon: 'directions_walk', route: "main-trabajadores", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS, Roles.GERENCIA], menuContabilidad: true },
    { nombreItem: 'Movimiento de Personal', icon: 'social_distance', route: "main-movimientos-personal", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS, Roles.GERENCIA], menuContabilidad: false },
    { nombreItem: 'Control haberes y descuentos', icon: 'format_list_bulleted_add', route: "main-asignar-haberes", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS, Roles.GERENCIA], menuContabilidad: false },
    { nombreItem: 'Horas extras', icon: 'watch_rounded', route: 'main-horas-extras', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS, Roles.GERENCIA], menuContabilidad: false },
    { nombreItem: 'Gestor Asistencia', icon: 'calendar_today', route: "main-gestorasistencia", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS, Roles.GERENCIA,], menuContabilidad: false },
    { nombreItem: 'Historial de Trabajadores  ', icon: 'update', route: "main-historial", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS, Roles.GERENCIA], menuContabilidad: false },
    { nombreItem: 'Control de Usuarios', icon: 'manage_accounts', route: "main-usuarios", roles: [Roles.ADMIN], menuContabilidad: false },
  ];

  typesOfOptionsASISTENCIA: MenuInterface[] = [
    { nombreItem: 'Gestor Asistencia', icon: 'calendar_today', route: "main-gestorasistencia", roles: [Roles.ASISTENCIA,], menuContabilidad: false },
  ]

  // SOLICITUDES
  typesOfOptionsSolicitudesLiquidaciones: MenuInterface[] = [
    { nombreItem: 'Visualizar solicitudes de Liquidaciones', icon: 'request_page', route: "main-solicitudes-liquidacion", roles: [Roles.ADMIN, Roles.RRHH_PLUS, Roles.RRHH] },
    { nombreItem: 'Solicitudes de Liquidaciones', icon: 'request_page', route: "main-solicitudes-liquidacion", roles: [Roles.ADMIN, Roles.GERENCIA] },
  ]
  typesOfOptionsSolicitudesDocumentos: MenuInterface[] = [
    { nombreItem: 'Solicitudes Eliminación de Documentos', icon: 'folder_delete', route: 'main-solicitudes-documentos', roles: [Roles.ADMIN, Roles.GERENCIA, Roles.RRHH_PLUS, Roles.RRHH] },
  ]

  typesOfOptionsSolicitudesVacaciones: MenuInterface[] = [
    { nombreItem: 'Solicitudes de vacaciones', icon: 'beach_access', route: 'main-solicitud-vacaciones', roles: [Roles.ADMIN, Roles.GERENCIA, Roles.RRHH_PLUS, Roles.RRHH] },
  ]
  typesOfOptionsSolicitudesDespido: MenuInterface[] = [
    { nombreItem: 'Solicitud de Despido', icon: 'lock', route: 'main-solicitud-despido', roles: [Roles.ADMIN, Roles.GERENCIA, Roles.RRHH_PLUS, Roles.RRHH] },
  ]
  // typesOfOptionsSolicitudesPermisos: MenuInterface[] = [
  //   { nombreItem: 'Solicitud de Permisos', icon: 'assignment_late', route: 'main-solicitud-permisos', roles: [Roles.ADMIN, Roles.GERENCIA, Roles.RRHH_PLUS, Roles.RRHH] },
  // ]


  typesOfOptionsContrato: MenuInterface[] = [
    { nombreItem: 'Generar Contrato', icon: 'description', route: "main-generar-contrato" },
    { nombreItem: 'Generar Anexo', icon: 'description', route: "main-generar-anexo" },
  ];

  //asignar rutas a empleados

  typesOfOptionsEmployed: MenuInterface[] = [
    { nombreItem: 'Mi perfil', icon: 'menu', route: "" },
    { nombreItem: 'Marcacion', icon: 'gps_fixed', route: "main-marcacion"},
    { nombreItem: 'Mis liquidaciones', icon: 'menu', route: "" },
    { nombreItem: 'Informacion Adicional', icon: 'menu', route: "" },
  ];

  typesOfOptionsContabilidad: MenuInterface[] = [
    { nombreItem: 'Libro Remuneración', icon: 'payments', route: "main-ventas" },
    { nombreItem: 'Generar Archivo PREVIRED', icon: 'article', route: "main-previred" },
    { nombreItem: 'Generar Excel de Pago', icon: 'local_atm', route: 'main-excel-pago' },
    { nombreItem: 'Generar Resumen Haberes', icon: 'summarize', route: 'main-resumen-haberes' },
    { nombreItem: 'Generar DDJJ', icon: 'library_books', route: "main-recaudaciones" },
    { nombreItem: 'Certificado Sueldo Anual', icon: 'receipt_long', route: "main-certificado" },
  ];


  typesOfOptionsParametros: MenuInterface[] = [
    { nombreItem: 'Segundo Impuesto', icon: 'layers', route: "main-impuesto-segunda-cat"},
  ];

  //asignar rutas de menu de trabajadores
  //hay que generar un submodulo dentro de la carpeta page para generar vistas de
  //trabajador

  typesOfOptionsMantenedores: MenuInterface[] = [
    { nombreItem: 'Roles', icon: 'group_add', route: "main-roles", roles: [Roles.ADMIN] },
    { nombreItem: 'Cargos', icon: 'school', route: 'main-cargos', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Regiones', icon: 'pin_drop', route: 'main-regiones', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Ciudades', icon: 'domain', route: "main-ciudades", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Empresas', icon: 'playlist_add', route: 'main-empresas', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Firmas', icon: 'draw', route: 'main-firmas', roles: [Roles.ADMIN, Roles.GERENCIA, Roles.RRHH_PLUS] },
    { nombreItem: 'Sucursales', icon: 'add_business', route: "main-sucursales", roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Bancos', icon: 'account_balance', route: 'main-banco', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Haberes', icon: 'monetization_on', route: 'main-haberes', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Previsión AFP', icon: 'bloodtype_rounded', route: 'main-afp', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Previsión de salud', icon: 'add_box', route: 'main-prevision', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Estado civil', icon: 'attribution', route: 'main-estado-civil', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Nacionalidades', icon: 'assistant_photo', route: 'main-nacionalidades', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Razon', icon: 'face', route: 'main-razon', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Jornada laboral', icon: 'work_history', route: 'main-jornada-laboral', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Tipos de prestamo', icon: 'work_history', route: 'main-tipoprestamo', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
    { nombreItem: 'Motivos Carta Amones.', icon: 'history_edu', route: 'main-motivos', roles: [Roles.ADMIN, Roles.RRHH, Roles.RRHH_PLUS] },
  ];

  typesOfOptionsPerfiles: MenuInterface[] = [
    { nombreItem: 'Requisitos Mínimos', icon: 'compare_arrows', route: "main-perfil" },
    { nombreItem: 'Funciones', icon: 'compare_arrows', route: "main-funciones" },
    { nombreItem: 'Competencias y Características', icon: 'compare_arrows', route: "main-competencias" },
    { nombreItem: 'Conocimientos Básicos', icon: 'compare_arrows', route: "main-conocimientos" },
    { nombreItem: 'Otros Antecedentes', icon: 'compare_arrows', route: "main-otros" },
  ];

  typesOfOptionsSolicitudes: MenuInterface[] = [
    { nombreItem: 'Solicitud de despido', icon: 'block', route: "main-solicitud-despido" },
  ];

  typesOfOptionsDenuncias: MenuInterface[] = [
    { nombreItem: 'Denuncias', icon: 'block', route: "main-denuncias", roles: [Roles.COMITE] },
  ];

  typesOfOptionsGestorPagos: MenuInterface[] = [
    { nombreItem: 'Adelantos', icon: 'attach_money', route: 'main-adelantos' },
    { nombreItem: 'Préstamos', icon: 'add_chart_sharp', route: 'main-prestamos' },
    { nombreItem: 'Planilla de pagos', icon: 'account_box', route: 'main-planilla-pagos' },
  ];

  // paid_rounded for container Gestion de pagos account_balance_sharp

  // Rutas de Menú de horas extras
  typesOfOptionsHorasExtras: MenuInterface[] = [
    { nombreItem: 'Horas extras', icon: 'watch_rounded', route: 'main-horas-extras' }
  ]

  // Ruta de Documentos
  typesOfOptionsDocumentos: MenuInterface[] = [
    { nombreItem: 'Contratos', icon: 'newspaper', route: 'main-contratos' },
    { nombreItem: 'Transferencias', icon: 'attach_money', route: 'main-transferencias' },
    { nombreItem: 'Cartas de amonestación', icon: 'sd_card_alert_outlined', route: 'main-cartas' },
    { nombreItem: 'Perfiles ocupacionales', icon: 'document_scanner', route: 'main-perfiles-ocupacionales' },
    { nombreItem: 'Liquidaciones', icon: 'money', route: 'main-liquidaciones' },
    { nombreItem: 'Finiquitos', icon: 'assignment_turned_in_outlined ', route: 'main-finiquitos' },
    { nombreItem: 'Licencias', icon: 'local_pharmacy ', route: 'main-licencias' },
    { nombreItem: 'Vacaciones', icon: 'card_travel', route: 'main-vacaciones' },
    { nombreItem: 'Implementos', icon: 'accessibility_new', route: 'main-implementos' },
    { nombreItem: 'Permisos', icon: 'assignment_late', route: 'main-solicitud-permisos' },
  ]

  //RUTAS RECURSOS HUMANOS
  typesOfOptionsRRHH: MenuInterface[] = [
    { nombreItem: 'Trabajadores', icon: 'person', route: "main-trabajadores" },
    { nombreItem: 'Historial de  Trabajadores  ', icon: 'update', route: "main-historial" },
    { nombreItem: 'Gestor Asistencia', icon: 'calendar_today', route: "main-gestorasistencia" },
    { nombreItem: 'Generar Contrato', icon: 'description', route: "main-generar-contrato" },
    { nombreItem: 'Generar Anexo', icon: 'description', route: "main-generar-anexo" },
  ]

  private subscripcion: Subscription = new Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    //console.log(this.router);
    this.subscripcion.add(
      this.authService.isAdmin.subscribe(res => this.isAdmin = res));
  }

  RedirectFunction(ToRoute: MenuInterface): void {
    this.router.navigate([`admin/${ToRoute.route}`]);
    this.sidenav.close();
  }

  ngOnChanges(changes: SimpleChanges) {

  }
}
