import { Component, OnInit,Input, SimpleChanges, OnChanges } from '@angular/core';

import {Documento} from 'src/app/shared/models/documentos/documentos.interface';
import {DocumentosService} from 'src/app/pages/services/documentos.service';
@Component({
  selector: 'app-lista-historial',
  templateUrl: './lista-historial.component.html',
  styleUrls: ['./lista-historial.component.css']
})
export class ListaHistorialComponent implements OnChanges {


@Input() fk_trabajador:number=0;
@Input() mes2:number=0;
@Input() ano2:number=0;
ano: number = new Date().getFullYear();
documentos:Documento[] = [];

  constructor(
  
    private documentoService: DocumentosService
  ) { }

  ngOnChanges(changes: SimpleChanges) {

  
    if (changes.fk_trabajador.currentValue != changes.fk_trabajador.previousValue){
      
      this.documentos=[]
      this.documentoService.GetAll_documentos(this.fk_trabajador,this.mes2,this.ano2).subscribe(
        (res) => {
          
          this.documentos = res.data;
        }
      );
    } 
  
}

 
}
