<div class="mb-0">
    <div class="row">
        <div class="col-3">
            <button mat-raised-button color="warn" (click)="recalcularMontoCuotas()">
                <mat-icon>
                    currency_exchange
                </mat-icon>
                Recalcular monto cuota
            </button>
        </div>
    </div>
</div>
<div class="mb-0" *ngFor="let cuota of cuotas">
    <div class="row">
        <div class="col-12">
            <p-divider align="center" type="solid">Cuota N° {{cuota.numcuota}}</p-divider>
            <input type="hidden" [(ngModel)]="cuota.numcuota">
        </div>
        <div class="col-3">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Monto</mat-label>
                <input matInput [(ngModel)]="cuota.montocuota" autonumeric [options]="optionsNumeric" required>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Fecha del préstamo</mat-label>
                <input matInput (click)="picker.open()" (focus)="picker.open()" [matDatepicker]="picker"
                    [(ngModel)]="cuota.fechacuota" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUI #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Estado</mat-label>
                <mat-select [(value)]="cuota.estado" (selectionChange)="obtenerEstado(cuota.numcuota, $event)" required>
                  <mat-option [value]="estado.value" *ngFor="let estado of estadoCuota">{{ estado.text }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>