import { Component, OnInit } from '@angular/core';
import { CargoService } from 'src/app/pages/services/cargo.service';
import { RequisitoService } from 'src/app/pages/services/requisitos.service';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { Requisito } from 'src/app/shared/models/requisitos.interface';

@Component({
  selector: 'app-main-perfil',
  templateUrl: './main-perfil.component.html',
  styleUrls: ['./main-perfil.component.css']
})
export class MainPerfilComponent implements OnInit {

  requisitos: Requisito[] = [];
  list_cargos_main: Cargo[] = [];
  cargos : any[] = [];

  constructor(    
    private cargoService: CargoService,
    private requisitosService: RequisitoService
  ) { }

  ngOnInit(): void {
              
      this.cargoService.GetAll_cargos().subscribe(
        (res) => {                           
          this.list_cargos_main = res.data;
          //console.log(this.list_cargos_main);
        }
      );                  
    }    

}
