import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { Subject } from 'rxjs'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { debounceTime } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { HorasExtrasService } from 'src/app/pages/services/horasExtras/horasExtras.service'
import { DatePipe } from '@angular/common'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'

@Component({
  selector: 'app-agregar-horas-extras',
  templateUrl: './agregar-horas-extras.component.html',
  styleUrls: ['./agregar-horas-extras.component.css'],
  providers: [DatePipe]
})
export class AgregarHorasExtrasComponent implements OnInit {
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  debouncer: Subject<boolean> = new Subject()
  modalReference = null;
  optionsDecimal = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 2, // si mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  }
  horasExtras: any = {
    trabajador: 0,
    fecha: '',
    horasExtras: 0,
    valorHora: 0,
    valorTotal: 0,
    descripcion: ""
  }
  trabajadores_aux: any;
  fecha: any;
  fecha2: any;
  data: any;
  trabajador: any = {
    value: '',
    nombre: '',
  };

  varibles: any;

  valor: any = 0.0077777;
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private horasExtrasService: HorasExtrasService,
    private datePipe: DatePipe,
  ) { }
  totalvalorhoras = 0;
  open(content: any) {
    this.modalReference = this.modalService.open(content)
    this.limpiezaCampos();
  }
  obtener_fecha(event: MatDatepickerInputEvent<Date>) {
    this.fecha = event.value;
    this.fecha2 = this.datePipe.transform(this.fecha, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia
  }

  // ObtenerTrabajador(event: MatSelectChange) {
  //   this.trabajador = event.value;
  // }
  enviar() {
    this.horasExtras.trabajador = this.trabajador.value;

    this.horasExtras.fecha = this.fecha2;

    this.horasExtras.valorTotal = (this.valor*400000)* this.horasExtras.horasExtras

    console.log(this.horasExtras);

    this.horasExtrasService.crear(this.horasExtras).subscribe((res) => {

      if (res.success == true) {

        this.alert.success_small(res.msg!)

        //this.modalService.dismissAll();
        this.modalReference.close();
        this.debouncer.next(true);
        // this.ngOnInit();

      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }
  limpiezaCampos() {
      this.horasExtras.trabajador = "";
      this.horasExtras.fecha = "";
      this.horasExtras.horasExtras = "";
      this.horasExtras.descripcion = "";
  }

  Traerdatos() {
    this.horasExtrasService.TraerTrabajadores().subscribe((res) => {
      const trabajadoresList:any[] = res.data;
      this.trabajadores_aux = trabajadoresList.map(x => (
        {value: x.rut, nombre: x.nombres + ' ' + x.apellidos}
        )
      );
    });
  }

  ngOnInit(): void {
    this.Traerdatos()
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
  }
}
