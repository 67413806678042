<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>

<!-- <div>
    <button style="background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button>Exportar a PDF</button>
    <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button color="green">Exportar a Excel</button>
</div> -->

<div class="mat-elevation-z8">
    <table mat-table id="tabla" #tabla [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripción Jornada Laboral</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.descripcion | uppercase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button (click)="open(content,element.id)" mat-button class="button_color_primary">
            <mat-icon style="color: white">edit</mat-icon>
          </button> &nbsp;
                <button (click)="EliminarJornada(content, element.id)" mat-button class="button_color_primary" style="background-color:red">
            <mat-icon style="color: white">delete</mat-icon>
          </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
    </mat-paginator>
</div>


<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Actualizar Jornada Laboral</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Descripción de la Jornada</mat-label>
                    <textarea type="textarea" rows="7" maxlength="1000" name="jornada" required matInput [(ngModel)]="updateJornada.descripcion"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">Cancelar</span>
      </button>
        <button type="button" class="btn btn-outline-success" (click)="ActualizarJornada()">
        Actualizar
      </button>
    </div>
</ng-template>