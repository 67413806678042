import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { debounceTime } from 'rxjs/operators';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-agregar-sucursales',
  templateUrl: './agregar-sucursales.component.html',
  styleUrls: ['./agregar-sucursales.component.css']
})
export class AgregarSucursalesComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  sucursales:Sucursal={
    nombre:"",  
    direccion:"",  
    idciudad: 0,
    centroCosto: 1,
  }

  ciudades_dropdown: any[] = [];

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private sucursalesService: SucursalService,
    private ciudadService: CiudadService,
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
    this.ciudadService.GetAll_ciudadesRegiones().subscribe(res => {
      if (res.success) {
        const ciudades = res.data.sort((a,b) => a.nombre.localeCompare(b.nombre));
        ciudades.forEach(ciudad => {
          this.ciudades_dropdown.push({
            value: ciudad.id,
            text: ciudad.nombre,
          });
        });
      }else{
        this.alert.error_mail('Hubo un error al recibir las ciudades.');
        console.error(res.msg);
      }
    })
  }

  open(content:any){
    this.limpiar_Sucursal();
    this.modalService.open(content);
  }

  validarSucursal(){
    if(this.sucursales.nombre.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.')
    }
  }

  limpiar_Sucursal(){
    this.sucursales.nombre=""; 
    this.sucursales.direccion = "";
    this.sucursales.idciudad = 0;
    this.sucursales.centroCosto = 1;
  }

  agregar(){
    this.validarSucursal()
      this.sucursalesService.Create_sucursal(this.sucursales).subscribe((res:any)=>{
        if (res.success==true) {
          this.alert.success_small("Se creo con exito")
          this.modalService.dismissAll();
          this.limpiar_Sucursal();
          this.debouncer.next( true );
        }else{
          this.alert.error_small(res.msg!)
        }
    })
    
  }

  ObtenerCiudad(event: MatSelectChange){
    const ciudad = event.value;
    this.sucursales.idciudad = ciudad.value;
  }

}
