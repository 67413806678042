<div class="modal-body">

    <!-- <div *ngIf=" constt==1">
        <h1>

           Ya ingreso uniforme a este usuario

        </h1>



    </div>  -->

    <div *ngIf=" constt==0 ||   constt==1">



        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Fecha entrega Uniforme </mat-label>
            <input matInput [matDatepicker]="picker" (dateChange)="obtener_fechaUniforme($event)" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUI #picker></mat-datepicker>
        </mat-form-field>

        <div class="row">


            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla de Polera</mat-label>
                    <mat-select matNativeControl required (selectionChange)="ObtenerPolera($event)">


                        <mat-option value=""> Null</mat-option>
                        <mat-option value="S"> S</mat-option>
                        <mat-option value="M"> M</mat-option>
                        <mat-option value="L"> L</mat-option>
                        <mat-option value="XL"> XL</mat-option>
                        <mat-option value="XXL"> XXL</mat-option>
                    </mat-select>
                </mat-form-field>



            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cantidad</mat-label>
                    <input type="number" name="cantidadPolera" matInput [(ngModel)]="implementosU.cantidadPolera" />
                </mat-form-field>

            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla de Poleron</mat-label>
                    <mat-select matNativeControl required (selectionChange)="ObtenerPoleron($event)">


                        <mat-option value=""> Null</mat-option>
                        <mat-option value="S"> S</mat-option>
                        <mat-option value="M"> M</mat-option>
                        <mat-option value="L"> L</mat-option>
                        <mat-option value="XL"> XL</mat-option>
                        <mat-option value="XXL">XXL </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cantidad</mat-label>
                    <input type="text" name="cantidadPoleron" matInput [(ngModel)]="implementosU.cantidadPoleron" />
                </mat-form-field>

            </div>
        </div>

        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla de Pantalon</mat-label>
                    <mat-select matNativeControl required (selectionChange)="ObtenerPantalon($event)">

                        <mat-option value=""> Null</mat-option>
                        <mat-option value="S"> S</mat-option>
                        <mat-option value="M"> M</mat-option>
                        <mat-option value="L"> L</mat-option>
                        <mat-option value="XL"> XL</mat-option>
                        <mat-option value="XXL">XXL </mat-option>

                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cantidad</mat-label>
                    <input type="text" name="cantidadPantalon" matInput [(ngModel)]="implementosU.cantidadPantalon" />
                </mat-form-field>

            </div>

            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Talla de Zapatos</mat-label>
                    <mat-select matNativeControl required (selectionChange)="ObtenerZapatos($event)">


                        <mat-option value=""> Null</mat-option>
                        <mat-option value="34"> 34</mat-option>
                        <mat-option value="34.5">34.5</mat-option>
                        <mat-option value="35">35</mat-option>
                        <mat-option value="35.5">35.5</mat-option>
                        <mat-option value="36">36</mat-option>
                        <mat-option value="36.5">36.5 </mat-option>
                        <mat-option value="37">37 </mat-option>
                        <mat-option value="37.5">37.5 </mat-option>
                        <mat-option value="38">38 </mat-option>
                        <mat-option value="38.5">38.5 </mat-option>
                        <mat-option value="39">39 </mat-option>
                        <mat-option value="39.5">39.5 </mat-option>
                        <mat-option value="40">40 </mat-option>
                        <mat-option value="40.5">40.5 </mat-option>
                        <mat-option value="41">41 </mat-option>
                        <mat-option value="41.5">41.5 </mat-option>
                        <mat-option value="42">42 </mat-option>
                        <mat-option value="42.5">42.5 </mat-option>
                        <mat-option value="43">43 </mat-option>
                        <mat-option value="43.5">43.5 </mat-option>
                        <mat-option value="44">44 </mat-option>
                        <mat-option value="44.5">44.5 </mat-option>
                        <mat-option value="45">45 </mat-option>
                        <mat-option value="45.5">45.5 </mat-option>
                        <mat-option value="46">46 </mat-option>

                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cantidad</mat-label>
                    <input type="text" name="cantidadZapato" matInput [(ngModel)]="implementosU.cantidadZapato" />
                </mat-form-field>

            </div>
        </div>
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Observacion</mat-label>
                <input  type="text" name="adicional" matInput [(ngModel)]="implementosU.adicional"/>
            </mat-form-field>
            
    </div>
        <div>

            <button type="button" class="btn btn-outline-success" (click)="generarUniforme()">Guardar
            </button>
        </div>
        <!-- </div> -->
    </div>