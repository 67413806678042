import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { forEach } from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class ImplementosService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper)  { }

//   EnviarArchivo(archivo:any){
//     return this.http
//     .post<RequestResponse >(`${environment.API}/rrhh/licencias/documento`,archivo)
//     .pipe(
//       map((res:RequestResponse) =>{
//         return res;
//       }),
//       catchError((err)=> this.handlerError(err))
//     );
//   }

arrayImplementos: any[] = [];
arrayModelos: any[] = [];


TraerImplementos(id:any): Observable<RequestResponse> {
  return this.http
  .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarimplementosid/${id}`)
  .pipe(
    map((res:RequestResponse) =>{ 
      return res;
    }),
    catchError((err)=> this.handlerError(err))
  );
}

TraerArchivo(id:any): Observable<RequestResponse> {

  return this.http
  .get<RequestResponse >(`${environment.API}/rrhh/implementos/buscarimplementosid/${id}`)
  .pipe(
    map((res:RequestResponse) =>{ 
      return res;
    }),
    catchError((err)=> this.handlerError(err))
  );
}

  CrearImplementos(Implementos:any) {
    console.log("Implementos: ",Implementos);
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/implementos/crear`,Implementos)
    .pipe(
      map((res:RequestResponse) =>{
        this.http.get(`${environment.API}/rrhh/trabajador/buscar/${Implementos.fk_trabajador}`).subscribe((response: any) => {
          // Asegúrate de verificar si la propiedad 'data' existe y tiene un valor antes de intentar acceder a sus propiedades
          const trabajador = response && response.data && response.data.length > 0 ? response.data[0] : null;

          this.arrayImplementos = [];
          this.arrayModelos = [];
          const equiposE = Object.keys(Implementos).filter(key => key.startsWith('equipo'));
          const modelosE = Object.keys(Implementos).filter(key => key.startsWith('modelo'));

          // Iterar solo sobre las propiedades de "equipo"
          for (const key of equiposE) {
            if (Implementos[key] !== "") {
              this.arrayImplementos.push(Implementos[key]);
            }
          }

          // Iterar solo sobre las propiedades de "modelo"
          for(const key of modelosE){
            if(Implementos[key] !== ""){
              this.arrayModelos.push(Implementos[key])
            }
          }
  
        
          if (trabajador) {
            console.log(trabajador);
            const dataCorreo = {
              destinatario: 'd.vargas@grupofirma.cl',
              asunto: 'Entrega de implementos de trabajo',
              cuerpo: `Los siguientes implementos fueron entregados al trabajador: ${trabajador.nombres.toUpperCase()} ${trabajador.apellidos.toUpperCase()} de la empresa ${trabajador.Empresa_contratante.nombre}`,
              equipos: this.arrayImplementos,
              fechaI: Implementos.fechaI,
              modelos: this.arrayModelos,
              observacion: Implementos.observacion,
            }
            console.log({dataCorreo});

            this.http.post(`${environment.API}/rrhh/implementos/enviarCorreo/`, dataCorreo).subscribe({
              next: (response) => {
                console.log('Correo enviado con éxito:', response)      
              },
              error: (error) => {
                console.error('Error al enviar el correo:', error);
              }
            });
          } else {
            console.error('No se encontró el trabajador o la respuesta no tiene el formato esperado.');
          }

        
        });
        
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  
  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/implementos/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  
//   TraerArchivo(id:any): Observable<RequestResponse> {
//     return this.http
//     .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarlicenciaid/${id}`)
//     .pipe(
//       map((res:RequestResponse) =>{ 
//         return res;
//       }),
//       catchError((err)=> this.handlerError(err))
//     );
//   }
eliminar(id:any): Observable<RequestResponse> {
  return this.http
  .delete<RequestResponse >(`${environment.API}/rrhh/implementos/eliminar/${id}`)
  .pipe(
    map((res:RequestResponse) =>{
      return res;
    }),
    catchError((err)=> this.handlerError(err))
  );
}

  TraerTodo():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/implementos/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  // actualizar(implementos:any): Observable<RequestResponse>  {
  //   return this.http
  //   .put<RequestResponse >(`${environment.API}/rrhh/implementos/actualizar/ ${implementos.id}`,implementos)
  //   .pipe(
  //     map((res:RequestResponse) =>{
  //       return res;
  //     }),
  //     catchError((err)=> this.handlerError(err))
  //   );
  // }


  actualizarEstado(implementos:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/implementos/actualizarEstado/ ${implementos.id}`,implementos)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  actualizar(implementos:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/implementos/actualizar/ ${implementos.id}`,implementos)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


//   TraerLicencia(id:any): Observable<RequestResponse> {
//     return this.http
//     .get<RequestResponse >(`${environment.API}/rrhh/licencias/buscarporid/${id}`)
//     .pipe(
//       map((res:RequestResponse) =>{ 
//         return res;
//       }),
//       catchError((err)=> this.handlerError(err))
//     );
//   }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }






}
