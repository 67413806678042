import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { saveAs } from 'file-saver';
import { ComprobanteVacacionesService } from 'src/app/pages/services/PDF-GENERADOR/vacaciones/comprobante-vacaciones.service';
import { DatePipe } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { FoliadosService } from 'src/app/pages/services/foliados/foliados.service';
import { VacacionesService } from 'src/app/pages/services/vacaciones/vacaciones.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Holidays from 'date-holidays';
import Swal from 'sweetalert2';
import { parseISO } from 'date-fns';
const pdfMake = require('pdfmake/build/pdfmake.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const holidays = new Holidays('CL');

@Component({
  selector: 'app-generar-vacaciones',
  templateUrl: './generar-vacaciones.component.html',
  styleUrls: ['./generar-vacaciones.component.css'],
  providers: [DatePipe]
})
export class GenerarVacacionesComponent implements OnInit {
  @Input() rut!: any;

  src: any;
  url: any;
  dateObj = new Date();
  month = this.dateObj.getUTCMonth() + 1; //months from 1-12
  day = this.dateObj.getUTCDate();
  year = this.dateObj.getUTCFullYear();
  render = true;
  renderPdf = false;

  trabajador = {
    ubicacion: '',
    nombre: '',
    apellido: '',
    fk_trabajador: 0,
    f_start: '',
    f_end: '',
    habiles: 0,
    inhabiles: 0,
    pendiente: 0,
    periodo: '',
    empresa: '',
    palabras: '',
    folio: '0000',
  };

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private vacacionesService: ComprobanteVacacionesService,
    private datePipe: DatePipe,
    private primeNGConfig: PrimeNGConfig,
    private folio: FoliadosService,
    private vacacionesApi: VacacionesService,
    private alert: AlertHelper,
  ) { }

  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
    this.folio.Vacaciones().subscribe((res) => {
      this.trabajador.folio = res.data[0].foliado.toString();
      this.trabajador.folio = this.trabajador.folio.padStart(5, '00000');
    });

    this.trabajadoresService.TraerTrabajadoresRut(this.rut).subscribe(
      (res) => {

        //console.log(res.data);
        this.trabajador.ubicacion = res.data[0].Sucursal.nombre;
        //console.log(this.trabajador.ubicacion);
        this.trabajador.nombre = res.data[0].nombres;
        this.trabajador.apellido = res.data[0].apellidos;
        this.trabajador.fk_trabajador = res.data[0].rut;
        this.trabajador.empresa = res.data[0].Empresa_contratante.nombre;
      }
    );
  }

  async printpdf() {
    this.render = false;
    this.trabajador.periodo = this.datePipe.transform(this.trabajador.periodo, 'yyyy');

    //this.src= await this.vacacionesService.convetToPDF(this.trabajador);
    await this.vacacionesService.convetToPDF(this.trabajador);

    setTimeout(() => {
      this.render = true;
      this.renderPdf = true;
      this.src = this.vacacionesService.src;
      //console.log(this.vacacionesService.src);
    }, 1000);
    // this.src= base64;
    // saveAs(this.src, 'comprobante_vacaciones.pdf');
  }

  imprimir() {
    if (this.validar()) {
      saveAs(this.src, 'comprobante_vacaciones.pdf');
      //console.log("AQUI DEBIERA GUARDAR LOS DATOS, Sin la imagen");
      this.vacacionesApi.EnviarVacacion(this.trabajador).subscribe((res: any) => {
        //console.log(res);      
        if (res.success == true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();

        } else {
          this.alert.error_small(res.msg!)
        }
      })
      this.modalService.dismissAll();
    }
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    let fecha_i = dateRangeStart.value;
    let fecha_f = dateRangeEnd.value;
    this.trabajador.f_start = fecha_i.replace(new RegExp("/", "g"), "-");
    this.trabajador.f_end = fecha_f.replace(new RegExp("/", "g"), "-");
    //se invirte la fecha del formato "dd-mm-yyyy" al formato "yyyy-mm-dd" 
    let inicio_fecha = this.trabajador.f_start.split("-").reverse().join("-");
    let final_fecha = this.trabajador.f_end.split("-").reverse().join("-");
    //se crea una variable de tipo fecha ya con el string de fecha correcto

    // Divide la fecha para formar un arreglo [año, mes, día]
    let inicio_arr = inicio_fecha.split("-");
    let final_arr = final_fecha.split("-");

    // Ajusta el día para tener dos dígitos
    inicio_arr[2] = inicio_arr[2].padStart(2, '0');
    final_arr[2] = final_arr[2].padStart(2, '0');

    inicio_arr[1] = inicio_arr[1].padStart(2, '0');
    final_arr[1] = final_arr[1].padStart(2, '0');

    // Reconstruye la fecha con los días ajustados
    inicio_fecha = inicio_arr.join("-");
    final_fecha = final_arr.join("-");

    let inicio = parseISO(inicio_fecha);
    let final = parseISO(final_fecha);

    console.log({inicio}, {inicio_fecha}, {final}, {final_fecha});

    //uso datepipe para transformar por la dudas 
    this.trabajador.f_start = this.datePipe.transform(inicio, 'yyyy-MM-dd');
    this.trabajador.f_end = this.datePipe.transform(final, 'yyyy-MM-dd');

    var Difference_In_Time = final.getTime() - inicio.getTime();
    //console.log(Difference_In_Time);

    // To calculate the no. of days between two datesxx
    var Difference_In_Days = (Difference_In_Time + 86400000) / (1000 * 3600 * 24);
    this.trabajador.habiles = this.getBusinessDatesCount(inicio, final);
    //console.log(Difference_In_Days);
    this.trabajador.inhabiles = Difference_In_Days - this.trabajador.habiles;
    console.log({Difference_In_Days}, this.trabajador.habiles);

    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    this.trabajador.palabras = "Desde el " + inicio.toLocaleDateString('es-CL', options) + " hasta el " + final.toLocaleDateString('es-CL', options);
  }

  getBusinessDatesCount(startDate, endDate) {
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      const esFeriado = holidays.isHoliday(curDate);
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !esFeriado) count++;
      curDate.setDate(curDate.getDate() + 1);
    }

    return count;
  }

  validar(): boolean {
    if (
      this.trabajador.periodo != undefined &&
      this.trabajador.palabras.length > 0 &&
      this.trabajador.f_end.length > 0 &&
      this.trabajador.f_start.length > 0
    ) {
      return true;
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Faltan datos , favor revisar',
      })
      return false;
    }
  }
}
