import { Injectable } from '@angular/core';
import moment from 'moment';
const conversor = require('conversor-numero-a-letras-es-ar');
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IncumplimientosConductor, ObligacionesConductor, ProhibicionesConductor } from './DatosCondutor';
import { HelperService } from '../../helper/helper.service';
export interface respuesta {
  success: string;
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class ContratoConductor {
  public src: any;
  pdfMake: any;
  FechaHoy: any;
  FechaNacimiento: any
  requisitos: any;
  haberes: any;
  FechaFinContrato: any;
  Arrayhaberes: any = [];
  SucursalesTrabajador: any = [];
  ClaseConversor = conversor.conversorNumerosALetras;
  miConversor = new this.ClaseConversor();
  fecha_ingreso:any;
  sucursales:any;
  constructor(
    private helper : HelperService,
    private http: HttpClient
  ) { }


  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }
  RequisitosCargo(id: any): Observable<respuesta> {
    return this.http.get<respuesta>(`${environment.API}/rrhh/requisito/funciones/${id}`);
  }
  HaberesTrabajador(rut: any): Observable<respuesta> {
    return this.http.get<respuesta>(`${environment.API}/rrhh/trabajadorHaber/rut/${rut}`);
  }

  mostrarFecha(datos: any) {
    var fecha_fin = moment(datos.Fechas.fecha_fin).format('DD-MM-YYYY');

    if (datos.PlazoFijo == true) {

      if (datos.tipoContrato = 'PLAZO FIJO') {
        return {


          text: [
            `El presente contrato tendrá duración hasta el `,
            {text: `${this.FechaFinContrato} `, bold: true},
            `en caso contrario, terminará por alguna de las causales de la legislación vigente.\n\n `

          ]
        }
      } else if(datos.tipoContrato = 'OBRASFAENAS'){

        return {
          text: [
            `El presente contrato tendrá inicio el `,
            {text: `${this.fecha_ingreso} `, bold: true},
            `hasta cuando \n\n `

          ]
        }

    }else{

     return {
      alignment: 'justify',
      text: `El presente contrato tendrá duración de forma indefinida .\n\n `,
      style: 'parrafo'
    }
  }
  }
    else {
      return {
        alignment: 'justify',
        text: `El presente contrato tendrá duración de forma indefinida .\n\n `,
        style: 'parrafo'
      }
    }



  }

  async fechaHoy() {
    var fecha = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    this.FechaHoy = fecha.toLocaleDateString('es-CL', options);
    // console.log("termino de setear la fecha")
  }

  async fechaatexto(fecha: any) {
    fecha = new Date(fecha);
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    return fecha.toLocaleDateString('es-CL', options);
  }


  async TraerHaberes(datos: any) {
    // Reiniciar Arrayhaberes para evitar duplicaciones
    this.Arrayhaberes = [];

    return new Promise((resolve, reject) => {
      this.HaberesTrabajador(datos.Trabajador.rut).subscribe(
        (res) => {
          this.haberes = res.data ? res.data.filter((x: any) => x.Haberes.tipo == 1) : [];

          if (this.haberes.length > 0) {
            for (let i = 0; i < this.haberes.length; i++) {
              this.Arrayhaberes.push(
                this.haberes[i].Haberes.nombre + ` de $ ${this.helper.formatoCLP(this.haberes[i].monto)} .-(${this.miConversor.convertToText(this.haberes[i].monto)} pesos), ${this.haberes[i].Haberes.descripcion}\n\n`
              );
            }
          }

          console.log("estos son los haberes: ", this.Arrayhaberes);
          resolve(this.Arrayhaberes);
        },
        (error) => {
          console.error("Error al obtener los haberes: ", error);
          reject(error);
        }
      );
    });
  }

  ProhibicionesConductor() {
    var prohibiciones = []
    for (let i = 0; i < ProhibicionesConductor.length; i++) {
      prohibiciones.push({
        text: `${String.fromCharCode(97 + i)}) ${ProhibicionesConductor[i].text}`,
        style: 'parrafo',
        lineHeight: 1.5,
        margin: [40, 0, 40, 0],
        alignment: 'justify'
      })
    }
    return prohibiciones
  }

  IncumplimientosConductor() {
    var incumplimietos = []
    for (let i = 0; i < IncumplimientosConductor.length; i++) {
      incumplimietos.push({
        text: `${String.fromCharCode(97 + i)}. ${IncumplimientosConductor[i].text}`,
        style: 'parrafo',
        lineHeight: 1.5,
        margin: [40, 0, 40, 0],
        alignment: 'justify'
      })
    }
    return incumplimietos
  }
  ObligacionesConductor() {
    var obligaciones = []
    for (let i = 0; i < ObligacionesConductor.length; i++) {
      obligaciones.push({
        text: `${String.fromCharCode(97 + i)}) ${ObligacionesConductor[i].text}`,
        style: 'parrafo',
        lineHeight: 1.5,
        margin: [40, 0, 40, 0],
        alignment: 'justify'
      })
    }
    return obligaciones
  }
  
  async GenerarPdf(datos: any) {

    this.SucursalesTrabajador = [];
    for (let i = 0; i < datos.SucursalesSeleccionadas.length; i++) {
      this.SucursalesTrabajador.push({
        alignment: 'justify',
        margin: [40, 0, 40, 0],
        ul: [datos.SucursalesSeleccionadas[i].nombre + ' en ' + datos.SucursalesSeleccionadas[i].direccion + '\n\n'],
        style: 'parrafo'
      })

    }
    console.log("SUCURSALES: ", this.SucursalesTrabajador);
    console.log("largo: ", this.SucursalesTrabajador.length);

    this.sucursales = "";
    if (this.SucursalesTrabajador.length > 0) {
      this.sucursales = `El Trabajador prestará servicios en la oficina ubicada en ${datos.Trabajador.Sucursal.direccion} y las demás sucursales que se nombran a continuación: \n\n`;
    }else{
      this.sucursales = `El Trabajador prestará servicios en la oficina ubicada en ${datos.Trabajador.Sucursal.direccion}.\n\n`;
    }
    
    console.log(this.sucursales);



    // this.Arrayhaberes = [];
    // await this.TraerHaberes(datos).then(() => {


    //   // console.log(this.EsConductor());
    // })
    // console.log("los haberes "+this.Arrayhaberes);
    // console.log(datos);
    await this.loadPdfMaker();
    var SueldoLetras = this.miConversor.convertToText(datos.Sueldo);
    datos.Sueldo = datos.Sueldo.toLocaleString('es-CL');
    await this.fechaHoy();
    this.FechaNacimiento = await this.fechaatexto(datos.Trabajador.fecha_nacimiento);
    this.FechaFinContrato = await this.fechaatexto(datos.Fechas.fecha_fin);
    this.fecha_ingreso=await this.fechaatexto(datos.Trabajador.fecha_ingreso);
    this.Arrayhaberes = [];
    const haberes = await this.TraerHaberes(datos);
    console.log("haberse: ", haberes);
    console.log("data: ", datos);

    this.RequisitosCargo(datos.Trabajador.Cargo.id).subscribe(
      (res) => {
        this.requisitos = res.data;
        const ArrayRequisitos = () => {
          const requisitos = [];

          for (let i = 0; i < this.requisitos.length; i++) {
            // console.log(this.requisitos[i].requisitos.descripcion)
            requisitos.push(

             this.requisitos[i].requisitos.descripcion

            )
          }
          return requisitos;
        };

        // console.log(this.SucursalesTrabajador);

        var documentDefinition = {

          content: [
            {
              text: 'Contrato de Trabajo\n\n',
              style: 'header',
              alignment: 'center',

            },
            {
              //con esta wea se justifica
              alignment: 'justify',
              //------------------------
              text: [

                {
                  text: 'I.  Partes\n\n',
                  style: 'romanos'

                },
                {
                  text:  `En  ${datos.Ciudad.nombre.charAt(0) + datos.Ciudad.nombre.slice(1).toLowerCase()}, a ${this.FechaHoy} entre `,
                  style: 'parrafo'
                },

                {
                  text: `${datos.Trabajador.Empresa_contratante.nombre} ,`,
                  style: 'parrafo_negro'

                },
                {
                  text: 'Rol Único Tributario N°',
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.Empresa_contratante.run} ,`,
                  style: 'parrafo'

                },
                {
                  text: ' representada legalmente por',
                  style: 'parrafo'
                },
                {
                  text: ` ${datos.Trabajador.Empresa_contratante.representante} `,
                  style: 'parrafo_negro'
                },
                {
                  text: `cédula nacional de identidad N° ${datos.Trabajador.Empresa_contratante.cedula}`,
                  style: 'parrafo'
                },

                {
                  text: ` ambos domiciliados en ${datos.Trabajador.Empresa_contratante.ubicacion}, comuna y ciudad de ${datos.Trabajador.Empresa_contratante.CiudadEmpresa.nombre}, Región del Maule, `,
                  style: 'parrafo'
                },
                {
                  text: `correo electrónico ${datos.Trabajador.Empresa_contratante.correo},`,
                  style: 'parrafo'
                },
                {
                  text: ' en adelante',
                  style: 'parrafo'
                },
                {
                  text: ' "El empleador"',
                  style: 'parrafo_negro'
                },
                {
                  text: ' y don ',
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.nombres} ${datos.Trabajador.apellidos} `,
                  style: 'parrafo_negro'
                },
                {
                  text: 'cédula nacional de identidad N° ',
                  style: 'parrafo'
                },

                {
                  text: `${this.helper.formatoRut(datos.Trabajador.rut)} `,
                  style: 'parrafo_negro'
                },
                {
                  text: 'domiciliado en ',
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.direccion}, ${datos.Trabajador.Ciudad.CiudadRegion.nombre}`,
                  style: 'parrafo'
                },
                {
                  text: `, de nacionalidad ${datos.Trabajador.Nacionalidad.nombre} nacido/a el ${this.FechaNacimiento} , afiliado a `,
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.Afp.nombre} `,
                  style: 'parrafo_negro'
                },
                {
                  text: 'y sistema de salud ',
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.Prevision.nombre} ,`,
                  style: 'parrafo_negro'
                },
                {
                  text: `correo electrónico ${datos.Trabajador.correo}, número de contacto ${datos.Trabajador.telefono}`,
                  style: 'parrafo'
                },
                {
                  text: ', en adelante ',
                  style: 'parrafo'
                },
                {
                  text: '"El trabajador"',
                  style: 'parrafo_negro'
                },
                {
                  text: ', se ha convenido el siguiente contrato de trabajo: \n\n',
                  style: 'parrafo'
                },
                {
                  text: 'II. Naturaleza de los servicios\n\n',
                  style: 'romanos'
                },
                {
                  text: 'El trabajador se compromete y obliga a ejecutar el trabajo de ',
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.Cargo.nombre}`,
                  style: 'parrafo_negro'
                },
                {
                  text: `, u otro trabajo de función similar.\n`,
                  style: 'parrafo'
                },
                {
                  text: `Deberá, en su caso, realizar traslados de toda índole, principalmente de pasajeros, para el Programa de Cuidados Paliativos Universales de la Corporación Municipal de Educación, Salud, Cultura y Recreación de La Florida, precisamente para servicios de traslado de pacientes, insumos médicos y profesionales de atención domiciliaria, desarrollando toda actividad que tenga directa relación con el cargo indicado y su naturaleza, en la comuna de La Florida y sus alrededores, pudiendo ser trasladado inclusive a otro lugar, con el fin de ejecutar y cumplir en las labores que fue contratado, todo lo anterior con ocasión al proyecto asignado, sin que ello importe menoscabo para el trabajador.\n`,
                  style: 'parrafo'
                },
                {
                  text: `El trabajador se compromete y obliga expresamente a cumplir las instrucciones que le sean impartidas por su jefe inmediato o por el personal a cargo del Programa de Cuidados Paliativos Universales de la Corporación Municipal de Educación, Salud, Cultura y Recreación de La Florida.\n\n`,
                  style: 'parrafo'
                },
                {
                  text: 'Serán funciones propias del cargo, especialmente, las siguientes:\n',
                  style: 'parrafo',
                },
                {
                  text: `\t\t1.- Conducir el vehículo como ${datos.Trabajador.Cargo.nombre}, con la debida precaución y responsabilidad, respetando siempre las normas del Tránsito, no superando los límites de velocidad legales permitidos según el tipo de camino.\n`,
                  style: 'parrafo'
                },
                {
                  text: `\t\t2.- Realizar las rutas designadas y /o programadas por el Servicio.\n`,
                  style: 'parrafo'
                },
                {
                  text: `\t\t3.- Apoyar a la comunidad en las labores que le sean encomendadas y que tengan directa relación con su función principal de conductor, debiendo mantener siempre un trato cordial y acorde con las funciones que realiza.\n`,
                  style: 'parrafo'
                },
                {
                  text: `\t\t4.- Informar y/o comunicar directamente a la jefatura de la empresa cualquier inconveniente o incidente que presente dentro de su jornada, ya sea personalmente o con el vehículo.\n`,
                  style: 'parrafo'
                },
                {
                  text: `\t\t5.- Mantener el vehículo a cargo en buenas condiciones, aseada y limpia.\n`,
                  style: 'parrafo'
                },
                {
                  text: `\t\t6.- Seguir las instrucciones impartidas por su jefatura directa en el Servicio y de su contratante.\n`,
                  style: 'parrafo'
                }
               
              ],
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]

            },
            //aca una lista de labores
            //ArrayRequisitos(),
            //termino de la lista


            {
              text: [
                {
                  text: '\n III.  Lugar de la prestación de servicios:\n\n',
                  style: 'romanos'

                },
              ],
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `${this.sucursales}`,
              style: 'parrafo',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            this.SucursalesTrabajador,
            {
              alignment: 'justify',
              text: `Sin perjuicio de la facultad del empleador de alterar, por causa justificada, la naturaleza de los servicios o el sitio o recinto en que ellos han de presentarse, con la sola limitación de que se trate de labores similares y que el nuevo sitio o recinto que dentro de la misma localidad o ciudad.\n\n`,
              style: 'parrafo',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: 'IV. Jornada de trabajo:\n\n',
              style: 'romanos',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `La jornada de trabajo será de 45 horas semanales las que serán distribuidas de lunes a viernes, de la siguiente manera: \n\n `,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `1.- Jornada de mañana: desde las 08:00 horas y hasta las 13:00 horas.\n `,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `2.- Jornada de tarde: desde las 14:00 horas y hasta 18:00 horas.\n\n`,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `\nEl horario podría ser modificado según el requerimiento y necesidades del servicio, previa coordinación.\n `,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `El trabajador deberá consignar fielmente el registro de asistencia, de forma diaria y de manera coetánea al inicio y termino de cada turno.\n\n`,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: 'V. Remuneraciones:\n\n',
              style: 'romanos',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: [
                `El empleador se compromete a remunerar los servicios trabajador con un sueldo mensual base de $ ${datos.Sueldo} `,
                {text: `.-( ${SueldoLetras} pesos)-.  \n`, bold: true},

              ],
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `El empleador pagará una gratificación mensual equivalente al 25% del total de las remuneraciones mensuales, con tope legal de 4.75 ingresos mínimos mensuales.\n\n`,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `Además, el empleador se compromete a pagar los siguientes conceptos :\n\n`,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              ol:
              this.Arrayhaberes,
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [70, 0, 40, 0]
            },
          
            {
              text: [
                {
                  alignment: 'justify',
                  text: `La remuneración será liquidada y pagada el día 05 de cada mes calendario, del caso de corresponder a un día inhábil, se pagará al siguiente día hábil. Asimismo, se podrá otorgar un`,
                  style: 'parrafo'

                },
                {
                  alignment: 'justify',
                  text: ` anticipo de sueldo el día 20 de cada mes calendario, `,
                  style: 'parrafo_negro'
                },
                {
                  alignment: 'justify',
                  text: `a solicitud del trabajador y en proporción a los días trabajados hasta el día quince del respectivo mes. La solicitud del anticipo de sueldo deberá ser solicitado por escrito con 2 días hábiles de anticipación y pudiendo ser modificada a solicitud del trabajador, por escrito, cada 6 meses. En el caso de que el día 20 sea un día inhábil, el anticipo de sueldo se otorgará el día hábil siguiente. \n\n`,
                  style: 'parrafo'
                },
                {
                  alignment: 'justify',
                  text: `Se aplicarán las deducciones de impuestos que las graven, las cotizaciones de seguridad social y otras, de conformidad a lo establecido en el artículo 58 del Código del Trabajo. \n\n`,
                  style: 'parrafo'
                },
                {
                  text: 'VI. Duración de la relación jurídica laboral: \n\n',
                  style: 'romanos'
                },
                //esto es el caso de que tenga fecha fin de contrato, añadir diferencia si es indefinido
                this.mostrarFecha(datos),
                {
                  text: [
    
                    {
                      text: 'VII. Cláusula de vigencia:\n\n',
                      style: 'romanos'
                    },
                    {
                      text: [
                        `Se deja constancia que el trabajador comenzó el dia`,
                        {text: ` ${this.fecha_ingreso}`, bold: true},
                        ` a prestar servicios. \n\n`,
    
                      ]
                    },
                   
    
                  ]
    
                },

                {
                  text: 'VIII. Obligaciones como Conductor: \n\n',
                  style: 'romanos'
                },
              ],
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            this.ObligacionesConductor(),
            {
              text: "\nIX. Incumplimientos graves al contrato: \n\n",
              style: 'romanos',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            {
              text: `Serán especialmente considerados como incumplimientos graves a este contrato, las siguientes conductas:\n\n`,
              style: 'parrafo',
              lineHeigth: 1.5,
              margin: [40, 0, 40, 0],
            },
            this.IncumplimientosConductor(),
            {
              text: "\nX. Prohibiciones Del Trabajador:\n\n",
              style: 'romanos',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            {
              text: `El trabajador estará afecto a las siguientes prohibiciones:\n\n`,
              style: 'parrafo',
              lineHeigth: 1.5,
              margin: [40, 0, 40, 0],
            },
            this.ProhibicionesConductor(),
            {
              text: "\nXI. Consecuencias por los incumplimientos: \n\n",
              style: 'romanos',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            {
              text: `Ante el incumplimiento de cualquiera de las obligaciones establecidas tanto en las cláusulas VIII, IX o X, todas de este mismo instrumento, dará derecho al empleador a poner término inmediato al presente contrato. Así mismo, hará especialmente responsable al trabajador, por su incumplimiento, en caso de producir daños a los vehículos que conduce y eventualmente responsable del pago del deducible del Seguro Automotriz contratado, cuando corresponda.\n\n`,
              style: 'parrafo',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            {
              text: `Tanto la negativa del trabajador a realizar sus funciones dentro del horario laboral, la inasistencia no justificada de un trabajador uno o más días, o el atraso en el ingreso a su horario laboral y que le acarree multas a la Empresa, serán una causa grave de incumplimiento del contrato por parte del trabajador y se podrá poner término de inmediato a éste, sin derecho a réplica.\n`,
              style: 'parrafo',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            {
              text: '\n\nA tener en cuenta:\n\n',
              style: 'parrafo_negro',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],

            },
            {
              alignment: 'justify',
              text: `Para todos los efectos derivados del presente contrato las partes fijan domicilio en la ciudad de  ${datos.Trabajador.Ciudad.nombre} y se someten a la Jurisdicción de sus Tribunales.\n\n`,
              style: 'parrafo',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            {
              alignment: 'justify',
              text: `El presente contrato se firma en 2 ejemplares, declarando el trabajador haber recibido en este acto un ejemplar de dicho instrumento, que es el fiel reflejo de la relación laboral convenida entre las partes.`,
              style: 'parrafo',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            
            // {
           

            //   text: '________________________________                       ________________________________',
            // },
            {
              margin: [0, 90, 0, 0],
              columns: [
                {
                  fontSize: 12, alignment: 'center',
                  text: '________________________________',
                  lineHeight: 1.5
                },
                {
                  fontSize: 12, alignment: 'center',
                  text: '________________________________',
                  lineHeight: 1.5
                },
              ]
            },
            {
              columns: [
                {
                  fontSize: 12,
                  text: `${datos.Trabajador.nombres + ' ' + datos.Trabajador.apellidos}\n`,
                  alignment: 'center',
                  style: 'parrafo_negro',
                  lineHeight: 1.5
                },
                {
                  fontSize: 12,
                  text: `${datos.Trabajador.Empresa_contratante.nombre}\n`,
                  alignment: 'center',
                  style: 'parrafo_negro',
                  lineHeight: 1.5
                },
              ]
            },
            {
              columns: [
                {
                  fontSize: 12,
                  text: `C.I. N°${this.helper.formatoRut(datos.Trabajador.rut)}\n`,
                  alignment: 'center',
                  style: 'parrafo_negro',
                  lineHeight: 1.5
                },
                {
                  fontSize: 12,
                  text: `R.U.T N°${datos.Trabajador.Empresa_contratante.run}\n`,
                  alignment: 'center',
                  style: 'parrafo_negro',
                  lineHeight: 1.5
                },
              ]
            },
            {
              columns: [
                {
                  fontSize: 12,
                  text: `TRABAJADOR \n`,
                  alignment: 'center',
                  style: 'parrafo_negro',
                  lineHeight: 1.5
                },
                {
                  fontSize: 12,
                  text: `EMPLEADOR \n `,
                  alignment: 'center',
                  style: 'parrafo_negro',
                  lineHeight: 1.5
                },
              ]
            }


          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true
            },

            romanos: {
              fontSize: 13,
              bold: true,
              decoration: 'underline',
            },
            tabla: {
              bold: true,
              fontSize: 12

            },
            parrafo: {
              fontSize: 12
            },
            parrafo_negro: {
              fontSize: 12,
              bold: true
            }


          }
        };
        const pdfDocGenerator = this.pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getDataUrl((dataUrl: any) => {
          //this.url=dataUrl;
          this.src = dataUrl;
        }
        );
      }
    );
  }


}
