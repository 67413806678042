import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
import { SolicitudLiquidacionService } from 'src/app/pages/services/solicitudes/solicitud-liquidacion.service';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Aprobacion } from 'src/app/shared/models/aprobacion.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-view-liquidacion',
  templateUrl: './modal-view-liquidacion.component.html',
  styleUrls: ['./modal-view-liquidacion.component.css']
})
export class ModalViewLiquidacionComponent implements OnInit {
  @Input() modalViewLiquidacion: any = {
    liquidacion: null,
    aprobaciones: null,
  };
  FIRMA_NAME = 'FIRMALIQUIDACIONES.png';

  iddocumento: number;
  documento: any;
  rutaDocumento: string;
  srcDocumento: Uint8Array | string;
  aprobador: any; // USUARIO QUIEN APRUEBA LA LIQUIDACIÓN
  aprobacion: Aprobacion;
  nombreTrabajador: string = '';
  nombreEmpresa: string = '';
  nombreSucursal: string = '';
  nombreDocumento: string = '';
  liquidacion:any;

  NewComentario: string = '';
  estadoChange: string = '';
  estadoCurrent: string = '';
  pagada_finiquito: boolean = false;

  viewButtonDecision: boolean = false;
  viewButtonModificacion: boolean = false;

  viewTextAreaComentario: boolean;
  decision: number | string;
  modificarDecision: boolean;
  historyAprobaciones: any[] = [];

  constructor(
    private modalService: NgbModal,
    private liquidacionService: LiquidacionService,
    private solicitudLiquidacionService: SolicitudLiquidacionService,
    private empresaService: EmpresaService,
    private sucursalService: SucursalService,
    private auth: AuthService,
    private pdfLibService: PdflibService,
    private alert: AlertHelper,
  ) {
    this.viewTextAreaComentario = false;
    this.decision = null;
    this.modificarDecision = false;
    this.aprobador = this.auth.ObtenerinfoToken();
  }

  ngOnInit() { }

  async ngOnChanges(changes: any) {
    if (changes.modalViewLiquidacion) {
      this.modalViewLiquidacion = changes.modalViewLiquidacion.currentValue;
      this.aprobacion = this.modalViewLiquidacion.aprobaciones && this.modalViewLiquidacion.aprobaciones?.length > 0 
        ? this.modalViewLiquidacion.aprobaciones[0] : null;
      this.liquidacion = this.modalViewLiquidacion.liquidacion;
      this.pagada_finiquito = this.liquidacion.pagada_finiquito === 1;
      const trabajador = this.liquidacion.fk_trabajadores;
      const estado = this.aprobacion?.estado;
      this.estadoCurrent = estado === 1
        ? 'APROBADA' : estado === 0 
        ? 'RECTIFICADA' : estado === 2 
        ? 'RECHAZADA' : this.liquidacion.eliminado === 1 
        ? 'ELIMINADA' : 'PENDIENTE';

      this.nombreTrabajador = trabajador ? trabajador.nombres + ' ' + trabajador.apellidos : '';
      const data = await this.ObtenerEmpresaSucursalTrabajador(trabajador.empresa_contratante, trabajador.sucursal);
      if (data) {
        this.historyAprobaciones = this.modalViewLiquidacion.aprobaciones;
        this.nombreEmpresa = data.empresa.nombre;
        this.nombreSucursal = data.sucursal.nombre;
        // OBTENER EL ULTIMO DOCUMENTO DEL ARRAY
        const documentos = await this.ObtenerDocumento(this.modalViewLiquidacion.liquidacion.id);
        this.documento = documentos[documentos.length - 1];
        this.iddocumento = this.documento.cp_documento;
        this.viewButtonDecision =  (!this.modalViewLiquidacion.aprobaciones || this.modalViewLiquidacion.aprobaciones?.length === 0 || !this.aprobacion || this.aprobacion?.estado === 0) && this.modalViewLiquidacion.liquidacion.eliminado === 0;
        this.viewButtonModificacion = this.modalViewLiquidacion.aprobaciones?.length > 0 && this.aprobacion?.estado !== 0 && this.modalViewLiquidacion.liquidacion.eliminado === 0;
        await this.MostrarLiquidacion(estado);
      }else{
        this.alert.error_small('Hubo un error al obtener la empresa y sucursal');
      }
    }
  }
  async ObtenerEmpresaSucursalTrabajador(empresa_contratante: any, sucursal: any) {
    const resEmpresa = await this.empresaService.Obtener_empresaAsync(empresa_contratante);
    const resSucursal = await this.sucursalService.Obtener_sucursalAsync(sucursal);
    if (resEmpresa.success && resEmpresa.success) {
      const data = {
        empresa: {...resEmpresa.data},
        sucursal: {...resSucursal.data},
      }
      return data;
    }
    return null;
  }

  // MOTRAR ARCHIVO EN NUEVA PESTAÑA
  async MostrarLiquidacion(estado: number){
    this.rutaDocumento = `${environment.API}/liquidaciones/${this.documento.ruta}`;
    switch (this.estadoCurrent) {
      case 'APROBADA':
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaBlob(this.rutaDocumento, firma);
        const readerFirma = new FileReader();
        readerFirma.onload = () => {
          this.srcDocumento = new Uint8Array(readerFirma.result as ArrayBuffer);
        }
        readerFirma.readAsArrayBuffer(blobFirma);
        break;
      case 'RECHAZADA':
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(this.rutaDocumento, 'RECHAZADA');
        const renderMarca = new FileReader();
        renderMarca.onload = () => {
          this.srcDocumento = new Uint8Array(renderMarca.result as ArrayBuffer);
        }
        renderMarca.readAsArrayBuffer(blobMarca);
        break;
      default:
        this.srcDocumento = this.rutaDocumento;
        break;
    }
  }

  async DecidirAprobacion(decision: boolean){
    this.decision = decision ? 1 : 2;
    if (decision) {
      this.CrearAprobacion(1);
    }else{
      this.viewTextAreaComentario = true;
    }
  }

  Cancelar(){
    this.viewTextAreaComentario = false;
    this.modificarDecision = false;
    this.decision = null;
  }

  async CrearAprobacion(decision: number){
    // const accion = decision === 1 ? 'aprobar' : 'rechazar';
    // if (this.NewComentario.trim() === '') if (!confirm('Comentario vacio.\n¿Seguro que quiere seguir?')) return;
    this.NewComentario = decision === 1 ? 'LIQUIDACIÓN APROBADA' : this.NewComentario;
    const resLiquidacion = await this.liquidacionService.TraerPorIdAsync(this.modalViewLiquidacion.liquidacion.id);
    if (!resLiquidacion.success){
      this.alert.error_small(resLiquidacion.msg);
      return;
    }
    const liquidacion = resLiquidacion.data;
    const aprobacion: Aprobacion = {
      comentario: this.NewComentario,
      estado: decision,
      idliquidacion: this.modalViewLiquidacion.liquidacion.id,
      iddocumento: this.iddocumento,
      idaprobador: this.aprobador.id,
      fecha: format(new Date(), 'yyyy-MM-dd'),
    }
    const res = await this.solicitudLiquidacionService.CrearLiquidacion(aprobacion);
    if (res.success) {
      if (this.pagada_finiquito) await this.actualizarPagadoFiniquitoLiquidacion(liquidacion);
      this.alert.success_small(res.msg);
      this.modalService.dismissAll();
      this.NewComentario = '';
    }else{
      console.error(res.msg);
      this.alert.error_small('Hubo un error al crear la aprobación');
    }
    // SE RESETEA EL PAGADA_FINIQUITO A FALSE
    this.pagada_finiquito = false;
  }
  // SE ACTUALIZA EL ESTADO DEL PAGADO FINIQUITO EN LA LIQUIDACION
  async actualizarPagadoFiniquitoLiquidacion(liquidacion: any): Promise<void> {
    liquidacion.pagada_finiquito = true;
    const resUpdate = await this.liquidacionService.ActualizarLiquidacion(liquidacion);
    if (resUpdate.success) {
      console.log('SE ACTUALIZO LA LIQUIDACION: FINIQUITO PAGADO TRUE ');        
    }else{
      console.error(resUpdate.msg);
    }
  }
  ModificarDecision(){
    const estado = this.modalViewLiquidacion.aprobaciones?.find((x: Aprobacion) => x.idliquidacion === this.modalViewLiquidacion.liquidacion.id)?.estado??null;
      this.decision = estado;
    if (estado === 'RECHAZADA') {
      this.CambiarDecision();
    }else{
      this.estadoChange = estado === 'APROBADA' ? 'RECHAZADA' : 'APROBADA';
      this.modificarDecision = true;
    }
  }
  async CambiarDecision(){
    // if (!confirm(`¿Esta seguro de cambiar el estado a ${this.estadoChange} de la liquidación?`)) return;
    this.NewComentario = this.decision === 'RECHAZADA' ? 'LIQUIDACIÓN APROBADA' : this.NewComentario;
    // if (this.NewComentario.trim() === '') if (!confirm('Comentario vacio.\n¿Seguro que quiere seguir?')) return;

    const aprobacion: Aprobacion = {
      comentario: this.NewComentario,
      estado: this.decision === 'APROBADA' ? 2 : 1,
      idliquidacion: this.modalViewLiquidacion.liquidacion.id,
      iddocumento: this.iddocumento,
      idaprobador: this.aprobador.id,
      fecha: format(new Date(), 'yyyy-MM-dd'),
    }
    const res = await this.solicitudLiquidacionService.CrearLiquidacion(aprobacion);
    if (res.success) {
      this.alert.success_small(res.msg);
      this.modalService.dismissAll();
      this.NewComentario = '';
    }else{
      console.error(res.msg);
      this.alert.error_small('Hubo un error al modificar la aprobación');
    }
  }
  async cambiarEstadoPagadaFiniquito(){
    const idliquidacion = this.modalViewLiquidacion.liquidacion.id;
    const resLiquidacion = await this.liquidacionService.TraerPorIdAsync(idliquidacion);
    if (resLiquidacion.success) {
      const liquidacion = resLiquidacion.data;
      const resUpdateLiquidacion = await this.liquidacionService.ActualizarLiquidacion({...liquidacion, pagada_finiquito: this.pagada_finiquito})
      if (resUpdateLiquidacion.success) {
        this.alert.success_small(resUpdateLiquidacion.msg);
      }else{
        this.alert.error_small(resUpdateLiquidacion.msg);
      }
    }else{
      this.alert.error_small(resLiquidacion.msg);
    }
  }
  async ObtenerDocumento(id: number) {
    const res = await this.liquidacionService.TraerArchivoAsync(id);
    if (!res.success) {
      console.error(res.msg);
      this.alert.errorAlert('Hubo un error al obtener el archivo.');
      return;
    }
    return res.data.filter(x => !x.fechaFirma);
  }
  async DescargarLiquidacion(){
    const estado = this.estadoCurrent;
    switch (estado) {
      case 'APROBADA':
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaBlob(this.rutaDocumento, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 'RECAHAZADA':
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(this.rutaDocumento, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break; 
      default:
        saveAs(this.rutaDocumento, this.nombreTrabajador.replace(' ','')+'-'+new Date().getTime()+'.pdf');
        break;
    }
  }
}
