<h2>FILTROS</h2>
<p-toolbar styleClass="mb-2">
  <div class="p-toolbar-group-left" *ngIf="!periodoLiquidacion">
    <p-button label="Asignar" icon="pi pi-plus" pTooltip="Asignar Haber / Descuento" [disabled]="!rut" tooltipPosition="bottom" (click)="open(content)"></p-button>
    <p-divider layout="vertical"></p-divider>
    <div *ngIf="empresasList.length > 0">
      <span class="mr-2">Empresa</span>
      <p-dropdown [options]="empresasList" [(ngModel)]="empresaSelect" [filter]="true" filterBy="text"
        (onChange)="EmpresaSelect()" placeholder="Todas las Empresas" optionLabel="text"
        optionValue="value">
      </p-dropdown>
    </div>
    <p-calendar #rangeCalendario *ngIf="rangoPeriodo.length > 0" [(ngModel)]="rangoPeriodo" selectionMode="range" view="month" dateFormat="mm/yy" 
      (onClickOutside)="RangoPeriodoChange()" [readonlyInput]="true" pTooltip="Perido: Desde - Hasta" tooltipPosition="bottom">
    </p-calendar>
    <p-dropdown *ngIf="trabajadorFilter.length > 0" [options]="trabajadorFilter" [(ngModel)]="trabajadorSelect"
      [filter]="true" filterBy="text" (onChange)="FiltrarDatos()" [showClear]="true" pTooltip="Trabajadores" tooltipPosition="bottom"
      placeholder="Todos los Trabajadores" optionLabel="text" optionValue="value" styleClass="w-100">
    </p-dropdown>
    <p-dropdown [options]="haberesFilter" [(ngModel)]="haberSelect" [filter]="true" filterBy="text"
      (onChange)="FiltrarDatos()" [showClear]="true" placeholder="Todos los Haberes" optionLabel="text"
      optionValue="value" styleClass="mr-2" pTooltip="Haberes" tooltipPosition="bottom">
    </p-dropdown>
  </div>
  <div class="p-toolbar-group-left" *ngIf="periodoLiquidacion">
    <p-button label="Asignar" icon="pi pi-plus" pTooltip="Asignar Haber / Descuento" [disabled]="!rut" tooltipPosition="bottom" (click)="open(content)"></p-button>
    <p-divider layout="vertical"></p-divider>
    <span class="mr-2">Periodo</span>
    <p-calendar #rangeCalendario *ngIf="rangoPeriodo.length > 0" [(ngModel)]="rangoPeriodo" selectionMode="range" view="month" dateFormat="mm/yy" 
      (onClickOutside)="RangoPeriodoChange()" [readonlyInput]="true" pTooltip="Perido: Desde - Hasta" tooltipPosition="bottom">
    </p-calendar>
  </div>
</p-toolbar>

<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="Trabajador" style="width: 20%;">
    <th mat-header-cell *matHeaderCellDef> TRABAJADOR </th>
    <td mat-cell *matCellDef="let element">
      {{element.TrabajadorHaberes.nombres + ' ' + element.TrabajadorHaberes.apellidos| uppercase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef style="width: 20%;"> NOMBRE </th>
    <td mat-cell *matCellDef="let element"> {{element.Haberes.nombre | uppercase }} </td>
  </ng-container>
  <ng-container matColumnDef="monto">
    <th mat-header-cell *matHeaderCellDef> MONTO </th>
    <td mat-cell *matCellDef="let element"> {{element.monto | currency:'CLP' }} </td>
  </ng-container>
  <ng-container matColumnDef="periodo">
    <th mat-header-cell *matHeaderCellDef> PERIODO </th>
    <td mat-cell *matCellDef="let element">
      {{element.fechaDesde | date:'MM/yyyy' }} - {{element.fechaHasta | date: 'MM/yyyy'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
    <td mat-cell *matCellDef="let element">
      <button mat-button class="button_color_primary" (click)="open2(contento, element.id)">
        <mat-icon style="color: white">edit</mat-icon>
      </button> &nbsp;
      <button mat-button class="button_color_primary" style="background-color:red" (click)='eliminar(element.id)'>
        <mat-icon style="color: white">delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="Seleccionar pagina de haberes.">
</mat-paginator>

<!--  Aqui empieza el modal para crear un nuevo haber asociado al trabajador -->

<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Asignar a {{nombreTrabajador}}</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body container-fluid">

    <div class="row">
      <div class="col-12 mb-3" *ngIf="messageErrorForm !== ''">
        <p-message severity="error" text="{{messageErrorForm}}"></p-message>
      </div>
      <div class="col col-12 mb-1">
        <div><span>Haber / Descuento</span></div>
        <p-dropdown [options]="habereslist" (onChange)="onchangeHaber($event)" [(ngModel)]="haberSeleccionado"
          [group]="true" placeholder="Seleccione un Haber / Descuento" optionLabel="nombre" [filter]="true" filterBy="nombre"
          [showClear]="true" [style]="{ width: '100%' }">
          <ng-template let-haber pTemplate="group">
            <div>{{haber.label}}</div>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- PERIODOS DESDE HABER -->
      <div class="col-6" *ngIf="haberSeleccionado">
        <div><span>Periodo Desde</span></div>
        <p-calendar (onSelect)="seleccionarPeriodo($event, 0)" placeholder="Seleccione periodo desde" selectionMode="single"
          view="month" dateFormat="mm/yy" styleClass="w-100"></p-calendar>
      </div>
      <div class="col-6" *ngIf="haberSeleccionado">
        <div><span>Periodo Hasta</span></div>
        <p-calendar (onSelect)="seleccionarPeriodo($event, 1)" placeholder="Seleccione periodo hasta" selectionMode="single"
          view="month" dateFormat="mm/yy" styleClass="w-100"></p-calendar>
      </div>

      <div class="col-12 mt-3" *ngIf="haberSeleccionado">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" (change)="calcularBeneficioSemanaCorrida()" required [(ngModel)]="haberNuevo.monto" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Monto</label>
        </span>
      </div>
    </div>
    <div class="row mt-3" *ngIf="haberComision && (haberNuevo.fechaDesde !== '' || haberNuevo.fechaHasta !== '')">
      <div class="col-4">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" (change)="calcularSoloBSC()" required [(ngModel)]="dias" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Dias trabajados</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" (change)="calcularSoloBSC()" required [(ngModel)]="feriados" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Dias inhabiles</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" readonly required [(ngModel)]="haberNuevo.montoBSC" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Monto BSC</label>
        </span>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="CrearHaber()">
      Guardar
    </button>
  </div>
</ng-template>

<!--  Aqui empieza el modal para Editar haber asociado al trabajador -->

<ng-template style="border: 0px; z-index: 100" #contento let-modal>
  <div class="modal-header">
    <mat-card-title>Editar Haber/Descuento de {{nombreTrabajador}}</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body container-fluid">
    <div class="row mt-2">
      <div class="col-12" *ngIf="messageErrorForm !== ''">
        <p-message severity="error" text="{{messageErrorForm}}"></p-message>
      </div>
      <div class="col-12" *ngIf="nombreHaber !== ''">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" readonly [(ngModel)]="nombreHaber"> 
          <label for="float-input">Haber/Descuento</label>
        </span>
      </div>
      <div class="col-12 mt-3">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" required [(ngModel)]="haberAct.monto" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Monto</label>
        </span>
      </div>
      <!-- PERIODOS DESDE HABER -->
      <div class="col-6">
        <div><span>Periodo Desde</span></div>
        <p-calendar [(ngModel)]="fechaDesde" (onSelect)="seleccionarPeriodo($event, 0)" placeholder="Seleccione periodo desde" selectionMode="single"
          view="month" dateFormat="mm/yy" styleClass="w-100"></p-calendar>
      </div>
      <div class="col-6">
        <div><span>Periodo Hasta</span></div>
        <p-calendar [(ngModel)]="fechaHasta" (onSelect)="seleccionarPeriodo($event, 1)" placeholder="Seleccione periodo hasta" selectionMode="single"
          view="month" dateFormat="mm/yy" styleClass="w-100"></p-calendar>
      </div>
    </div>
    <div class="row mt-3" *ngIf="haberComision && (haberAct.fechaDesde !== '' || haberAct.fechaHasta !== '')">
      <div class="col-4">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" (change)="calcularSoloBSC()" required [(ngModel)]="haberAct.diasHabiles" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Dias trabajados</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" (change)="calcularSoloBSC()" required [(ngModel)]="haberAct.diasInhabiles" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Dias inhabiles</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="example-full-width" readonly required [(ngModel)]="haberAct.montoBSC" 
            [options]="optionsNumeric" autonumeric> 
          <label for="float-input">Monto BSC</label>
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="Actualizar()">
      Actualizar
    </button>
  </div>
</ng-template>



<!-- Modal especial para aquellos Haberes del tipo Beneficio Semana corrida (NO SE OCUPA) -->

<ng-template style="border: 0px; z-index: 100" #benSemanaCorrida let-modal>
  <div class="modal-header">
    <mat-card-title>Editar Beneficio Semana Corrida</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col col-12">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Monto</mat-label>
          <input type="number" required [(ngModel)]="haberAct.monto" (ngModelChange)="diasHolidays()" matInput #input
            name="Monto">
        </mat-form-field>
      </div>
      <div class="col col-3" style="padding-top: 22px;">
        <span class="p-float-label p-input-icon-left">
          <p-calendar [(ngModel)]="mes" (ngModelChange)="diasHolidays()" [defaultDate]="mes" view="month"
            dateFormat="mm/yy" inputId="mes" [showIcon]="true" [inputStyle]="{'height':'52px'}"></p-calendar>
          <label style="margin-left: -30px;" class="h6" for="mes">Mes</label>
        </span>
      </div>
      <div class="col col-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <span matPrefix> &nbsp;</span>
          <mat-label>N° Dias Trabajados</mat-label>
          <input type="number" [(ngModel)]="dias" (ngModelChange)="onchangebsc()" matInput #input
            name="N° Dias Trabajados">
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <span matPrefix> &nbsp;</span>
          <mat-label>N° de Feriados</mat-label>
          <input type="number" [(ngModel)]="feriados" (ngModelChange)="onchangebsc()" matInput #input
            name="N° de Feriados">
        </mat-form-field>
      </div>
      <div class="col col-3">
        <mat-form-field appearance="outline" class="example-full-width">
          <span matPrefix>$ &nbsp;</span>
          <mat-label>Monto B. SEMANA CORRIDA</mat-label>
          <input type="number" [(ngModel)]="haberAct.montoBSC" matInput #input name="Monto">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="Actualizar()">
      Actualizar
    </button>
  </div>
</ng-template>