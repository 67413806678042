import { Component,EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { CiudadService } from '../../../services/ciudades.service';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import { RegionesService } from 'src/app/pages/services/regiones.service';
import { Region } from 'src/app/shared/models/region.interface';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-agregar-ciudades',
  templateUrl: './agregar-ciudades.component.html',
  styleUrls: ['./agregar-ciudades.component.css']
})
export class AgregarCiudadesComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();

  debouncer: Subject<boolean> = new Subject();

  regiones:Region[] = [];

  ciudades: Ciudad = {
    nombre:"",
    codigo_lre: 0,
    idregion: 0,
  }

  optionsNumeric = {
    digitGroupSeparator: '', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private ciudadService: CiudadService,
    private regionService: RegionesService,
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
    this.regionService.TraerRegiones().subscribe(res => {
      if (res.success) {
        this.regiones = res.data;
      }else{
        this.modalService.dismissAll();
        this.alert.error_small('Ocurrio un error al obtener las regiones');
        console.error(res.msg);
      }
    })
  }

  open(content:any){
    this.limpiar_Ciudad();
    this.modalService.open(content, {size: 'lg'});
  }

  validarCiudad(){
    if(this.ciudades.nombre.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.')
    }
  }

  limpiar_Ciudad(){
    this.ciudades = {
      nombre:"",
      codigo_lre: 0,
      idregion: 0,
    }
  }

  agregar(){
    this.validarCiudad()
      this.ciudadService.Create_ciudad(this.ciudades).subscribe((res:any)=>{
        if (res.success==true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.limpiar_Ciudad();
          this.debouncer.next( true );
        }else{
          this.alert.error_small(res.msg!)
        }
    })
  }

  ObtenerRegion(event: MatSelectChange){
    this.ciudades.idregion = event.value;
    console.log(this.ciudades);
  }

}
