import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-view-history',
  templateUrl: './modal-view-history.component.html',
  styleUrls: ['./modal-view-history.component.css']
})
export class ModalViewHistoryComponent implements OnInit {
  @Input() historyDecisionesLiquidacion: any[];
  FIRMA_NAME = 'FIRMALIQUIDACIONES.png';
  documentos: any[];

  constructor(
    private liquidacionService: LiquidacionService,
    private pdfLibService: PdflibService,
    private alert: AlertHelper,
  ) {
    this.documentos = [];
  }

  ngOnInit(): void {
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.historyDecisionesLiquidacion) {
      this.historyDecisionesLiquidacion = (changes.historyDecisionesLiquidacion.currentValue as any[]);
      if (this.historyDecisionesLiquidacion?.length > 0 && this.historyDecisionesLiquidacion) {
        const historialModificado = [];
        for (const historial of this.historyDecisionesLiquidacion) {
          this.documentos = await this.ObtenerDocumento(historial.idliquidacion);
          const documento = this.documentos.find(y => y.cp_documento === historial.iddocumento);
          const estado = historial.estado;
          historial.documento = `${environment.API}/liquidaciones/${documento.ruta}`;
          historial.estado = estado === 0 ? 'RECTIFICADA' : estado === 1 ? 'APROBADA' : 'RECHAZADA';
          historial.icon = estado === 0 ? 'edit_square' : estado === 1 ? 'thumb_up' : 'thumb_down';
          historial.icon_color = estado === 0 ? 'text-warning' : estado === 1 ? 'text-success' : 'text-danger';
          historialModificado.push(historial);
        }
        this.historyDecisionesLiquidacion = historialModificado;
        this.documentos = this.documentos.map(x => ({...x, documento: `${environment.API}/liquidaciones/${x.ruta}`}));
      }      
    }
  }
  async ObtenerDocumento(id: number) {
    const res = await this.liquidacionService.TraerArchivoAsync(id);
    if (!res.success) {
      console.error(res.msg);
      this.alert.errorAlert('Hubo un error al obtener el archivo.');
      return;
    }
    return res.data;
  }
  async verLiquidacion(aprobacion: any){
    const ruta = aprobacion.documento;
    switch (aprobacion.estado) {
      case 'APROBADA':
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaBlob(ruta, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 'RECHAZADA':
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(ruta, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break;
      default:
        window.open(ruta, '_blank');
        break;
    }
  }
}
