import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'src/app/pages/auth/auth.service';
import moment from 'moment';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { ContratoService } from 'src/app/pages/services/contratos/contrato.service';
import { ArticuloService } from 'src/app/pages/services/articulo.service';
import { GenerarfiniquitoService } from 'src/app/pages/services/PDF-GENERADOR/finiquito/generarfiniquito.service';
import { MovimientosPersonalService } from 'src/app/pages/services/movimientos-personal.service';
import { differenceInMonths, parseISO } from 'date-fns';
import { FuncionesLiquidacionesService } from 'src/app/pages/services/liquidaciones/funciones-liquidaciones.service';
import { intervalToDuration } from 'date-fns/fp';
import { Finiquito, FiniquitoForm, PeriodoServicio } from 'src/app/shared/models/finiquito.interface';
import Holidays from 'date-holidays';
import { generarLiquidacionService } from 'src/app/pages/services/PDF-GENERADOR/liquidaciones/liquidacion.service';
import { contabilidadService } from 'src/app/pages/services/contabilidad/contabilidad.service';
import { FiniquitoService } from 'src/app/pages/services/finiquitos/finiquito.service';
import { Valores } from 'src/app/shared/models/Enums/valores-vacaciones.enum';
import { TrabajadorHaberService } from 'src/app/pages/services/TrabajadorHaber/trabajador-haber.service';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { SolicitudLiquidacionService } from 'src/app/pages/services/solicitudes/solicitud-liquidacion.service';

@Component({
  selector: 'app-agregar-finiquitos',
  templateUrl: './agregar-finiquitos.component.html',
  styleUrls: ['./agregar-finiquitos.component.css']
})
export class AgregarFiniquitosComponent implements OnInit {
  @Input() rut: any;
  @Output() finiquitoCreado= new EventEmitter<boolean>();
  responses: string[] = [];
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  selectedTermino: string;
  selectArticulo: number;
  articulos: any = [];
  articuloEscogido: any;
  sueldoMinimo: number = 0;
  vacacionesTrabajador: any[] = [];
  montoTotalBonos: number = 0;
  pendiente: number = 0; // SIRVE PARA CONTROLAR LOS DIAS PENDIENTES EN EL FINIQUITO
  integrarBonos: boolean = true;
  modificarDias: boolean = false;

  // LOADING MODAL
  blockedPanel: boolean = false;

  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/finiquito/crear`,
    itemAlias: 'finiquito',
    additionalParameter: {
      // contrato:JSON.parse(this.contrato)
    }
  });
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private auth: AuthService,
    private liquidacionService: LiquidacionService,
    private solicitudService: SolicitudLiquidacionService,
    private trabajadorService: TrabajadoresService,
    private contratoService:ContratoService,
    private articuloService: ArticuloService,
    private generarFiniquitoService: GenerarfiniquitoService,
    private movimientoPersonalService: MovimientosPersonalService,
    private finiquitoService: FiniquitoService,
    private funcionesLiquidaciones: FuncionesLiquidacionesService,
    private generarLiquidacionService: generarLiquidacionService,
    private contabilidadService: contabilidadService,
    // private vacacionService: VacacionesService,
  ) {
    this.uploader.onCompleteAll = () => {
      //console.log('se termino de enviar ');
    };
    //estas funciones son propias del uploader , nose para que son necesarias la verdad

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user = this.auth.ObtenerinfoToken();
      form.append('Rut', this.rut);
      form.append('fecha', this.finiquito.Fecha);
      form.append('total', this.finiquito.Total.replace(/\./g, ""));
      form.append('id_causalTermino', this.finiquito.id_causal);
      form.append('userAt', user.nombre);
    };
  }
  uf = {
    mesActual: 0.0,
    mesAnterior: 0.0,
  }
  // ESTE OBJETO ES PARA ENVIAR DATOS AL BACKEND
  finiquito: Finiquito = {
    Fecha: '',
    Total: '',
    id_causal: null
  }
  // ESTE OBJETO SIRVE PARA HACER LOS CALCULOS AUTOMATICOS DEL FINIQUITO
  finiquitoForm: FiniquitoForm = {
    SueldoBase: null,
    NombreTrabajador: '',
    FechaInicio: null,
    FechaAviso: null,
    Fecha: null,
    diasTotalesProp: 0,
    diasInhabilesProp: 0,
    totalVacacionesProp: 0,
    diasTotalesFeriado: 0,
    diasInhabilesFeriado: 0,
    totalVacacionesFeriado: 0,
    idemAviso: null,
    idemServicio: null,
    idemVacaciones: null,
    idemFeriado: null,
    totalSueldoLiquido: 0,
    Total: '0',
    diasTrabajados: 0,
    Termino: [
      {
        label: 'Despido', value: 'hasta la fecha de termino de su contrato'
      },
      {
        label: 'Renuncia', value: 'hasta la fecha de su renuncia'
      }
    ],
    Articulo: '',
    totalIndemnizaciones: 0,
  }

  // SERVIRAN PARA MOSTRAR ADVERTENCIAS DEBAJO DE CADA INPUT
  // DE MODO INFORMATIVO PARA EL USUARIO
  advertencias = {
    sinSueldoBase: false,
    sinFechaInicioContrato: false,
  }

  liquidaciones: any[] = [];
  liquidacion: any;

  trabajador: any = {
    ubicacion: '',
    nombre: '',
    apellido: '',
    fk_trabajador: 0,
    f_start: '',
    f_end: '',
    dias_semana: 0,
    fecha_ingreso: '',
    habiles: 0,
    inhabiles: 0,
    pendiente: null,
    periodo: new Date(),
    empresa: '',
    empresaRut: 0,
    palabras: '',
    folio: '00000',
    afp: '',
    afc: 1,
    afptasa: 0,
    prevision: '',
    previsiontasa: 0,
    horasExtras: 0,
    carga: 0,
    sbase: null,
    haberes: [],
    sCesantia: 0,
    estadoContrato: '',
    adelanto: 0,
    prestamo: 0,
    numCuota: 0,
    ahorro_apv: 0,
    prestamos: [],
    jubilado: false,
    tramoAsigFam: 0,
    impuestounicoC: 0,
  };

  previred = {
    periodo: null,
    topes: {
      AfpSalud: 0,
      IPS: 0,
      SeguroCesantia: 0,
    },
    familiar: {
      tramoA: {
        valor: 0,
        max: 0,
      },
      tramoB: {
        valor: 0,
        min: 0,
        max: 0
      },
      tramoC: {
        valor: 0,
        min: 0,
        max: 0,
      },
      tramoD: {
        valor: 0,
        min: 0,
      }
    },
    ufMesActual: 0,
    ufMesAnterior: 0,
    rentaMinima: 0,
    sCesantiaIndefinido: 0,
    seguroInvalidez: 0
  };
  // ESTO SIRVE PARA MOSTRAR EL PERIODO RESTANTE EN PANTALLA
  periodoServicio: PeriodoServicio = {
    days: 0,
    month: 0,
    year: 0,
    yearServicio: 0,
  }
  // OBJETO HOLIDAYS (FERIADOS)
  holidays = new Holidays('CL');
  optionsNumeric = {
    outputFormat: 'number',
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  optionsNumericTotal = {
    outputFormat: 'number',
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: '0',
  }
  optionsNumericDecimal = {
    outputFormat: 'number',
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 2, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  optionsNumericPendiente = {
    outputFormat: 'number',
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 1, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    maximumValue: '30', // maximo valor 30
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  periodo: string = 'Seleccione ambas fechas para hacer el calculo';
  totalImponible: number = 0;
  yearNow = new Date().getFullYear();
  maxDate = new Date(this.yearNow, 11, 31);
  minDate = new Date(1900, 1, 0);
  checkidemAviso: boolean = false;
  checkidemServicio: boolean = false;
  checkidemVacaciones: boolean = true;
  checkidemFeriado: boolean = false;

  async ngOnInit() {
    this.getArticulos();

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      //console.log("la respuesta ", response);
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
        //console.log("el archivo se guardo con el nombre ", result.nombre);
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        })
        this.modalService.dismissAll();
        this.contratoService.setVerificarContratosPlazoFijo(true);
      } else {
        //console.log("falso", response)
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',
        })
      }
    }
    await this.InitFiniquito();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  async InitFiniquito() {
    this.blockedPanel = true;
    this.setValueService();
    await this.cargarTrabajador();

    this.cargarAdvertencias()

    await this.obtenerHaberes();

    this.finiquitoForm.NombreTrabajador = this.trabajador.nombre.toUpperCase()
      + ' ' + this.trabajador.apellido.toUpperCase();
    // this.finiquitoForm.SueldoBase = this.trabajador.sbase + this.montoTotalBonos;
    this.finiquitoForm.FechaInicio = moment(this.trabajador.fecha_ingreso);
    this.finiquitoForm.Fecha = moment(this.trabajador.periodo)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);

      // await this.TraerVacaciones();
    await this.obtenerLiquidaciones();
    if (this.liquidaciones.length > 0) {
      this.liquidacion = this.liquidaciones.find(x => x.aprobacion?.estado === 2);
      if(this.liquidacion?.pagada_finiquito === 1) this.finiquitoForm.totalSueldoLiquido = this.liquidacion.alcanceLiquido;
    }
    await this.calcularFiniquito();
    this.blockedPanel = false;
  }
  async obtenerLiquidaciones() {
    const resLiq = await this.liquidacionService.TraerPorRutAsync(this.rut);
    if (resLiq.success) {
      const liquidaciones = resLiq.data.sort((a, b) => {
        const timeA = parseISO(a.fecha).getTime();
        const timeB = parseISO(b.fecha).getTime();
        return timeB - timeA;
      });
      for (const liquidacion of liquidaciones) {
        const resSol = await this.solicitudService.ObtenerAprobacionLiquidacion(liquidacion.id);
        if (resSol.success) {
          const aprobacion = resSol.data.sort((a, b) => {
            const timeA = parseISO(a.fecha).getTime();
            const timeB = parseISO(b.fecha).getTime();
            return timeB - timeA;
          })[0];
          liquidacion.aprobacion = aprobacion;
        }
        this.liquidaciones.push(liquidacion);
      }
    }
    // console.log({...this.liquidaciones});
  }
  cargarAdvertencias() {
    this.advertencias.sinSueldoBase = this.trabajador.sbase === null || this.trabajador.sbase === undefined || this.trabajador.sbase === 0;
    this.advertencias.sinFechaInicioContrato = this.trabajador.fecha_ingreso === null || this.trabajador.fecha_ingreso === undefined
      || this.trabajador.fecha_ingreso === '';
  }
  async cargarLiquidacion() {
    await this.cargarDatosPrevisionales();
    await this.cargarPrestamosAdelantos();
    this.trabajador.previred = this.previred;
    this.trabajador.pendiente = this.trabajador.pendiente > 30 ? 30 : this.trabajador.pendiente;
    const src = await this.generarLiquidacionService.convetToPDF(this.trabajador);

    const totalHaberes: number = this.generarLiquidacionService.total_imponible + this.generarLiquidacionService.totalNoImponible;
    const totalDescuentos: number = this.generarLiquidacionService.total_dsctos + this.generarLiquidacionService.total_dsctosOtros;
    this.finiquitoForm.totalSueldoLiquido = Math.round(totalHaberes - totalDescuentos);
    console.log('FINIQUITO FORM: ', this.finiquitoForm)
  }
  async cargarPrestamosAdelantos() {
    this.trabajador.adelanto = await this.ObtenerAdelantos();

    const objetoPrestamo = await this.CargarPrestamo();
    this.trabajador.prestamo = objetoPrestamo.prestamo; // MONTO CUOTA
    this.trabajador.numCuota = objetoPrestamo.numCuota;
  }
  async cargarDatosPrevisionales() {
    const month = this.trabajador.periodo.getMonth() + 1;
    const monthString = month < 10 ? '0'+month : month;
    const year = this.trabajador.periodo.getFullYear();

    this.uf = await this.funcionesLiquidaciones.obtenerUFs();
    const previred = await this.funcionesLiquidaciones.obtenerPrevired();
    this.previred = {
      topes: {
        AfpSalud: previred.AfpSalud,
        IPS: previred.IPS,
        SeguroCesantia: previred.SeguroCesantia,
      },
      familiar: {
        tramoA: {
          valor: previred.familiar.tramoA.valor,
          max: previred.familiar.tramoA.max,
        },
        tramoB: {
          valor: previred.familiar.tramoB.valor,
          min: previred.familiar.tramoB.min,
          max: previred.familiar.tramoB.max
        },
        tramoC: {
          valor: previred.familiar.tramoC.valor,
          min: previred.familiar.tramoC.min,
          max: previred.familiar.tramoC.max,
        },
        tramoD: {
          valor: previred.familiar.tramoD.valor,
          min: previred.familiar.tramoD.min,
        }
      },
      periodo: monthString+''+year,
      rentaMinima: previred.rentaMinima,
      sCesantiaIndefinido: previred.sCesantiaIndefinido,
      seguroInvalidez: previred.seguroInvalidez,
      ufMesActual: this.uf.mesActual,
      ufMesAnterior: this.uf.mesAnterior,
    }
  }
  async cargarTrabajador() {
    const trabajador = await this.funcionesLiquidaciones.CargarTrabajador();
    this.trabajador = {
      ubicacion: trabajador.ubicacion,
      nombre: trabajador.nombre,
      apellido: trabajador.apellido,
      fk_trabajador: trabajador.fk_trabajador,
      f_start: trabajador.f_start,
      f_end: trabajador.f_end,
      dias_semana: trabajador.dias_semana??5,
      fecha_ingreso: trabajador.fecha_ingreso,
      habiles: trabajador.habiles,
      inhabiles: trabajador.inhabiles,
      pendiente: trabajador.pendiente,
      periodo: trabajador.periodo,
      empresa: trabajador.empresa,
      empresaRut: trabajador.empresaRut,
      palabras: trabajador.palabras,
      folio: trabajador.folio,
      afp: trabajador.afp,
      afc: trabajador.afc,
      afptasa: trabajador.afptasa,
      prevision: trabajador.prevision,
      previsiontasa: trabajador.previsiontasa,
      horasExtras: trabajador.horasExtras,
      carga: trabajador.carga,
      sbase: trabajador.sbase,
      haberes: trabajador.haberes,
      sCesantia: trabajador.sCesantia,
      estadoContrato: trabajador.estadoContrato,
      adelanto: trabajador.adelanto,
      prestamo: trabajador.prestamo,
      numCuota: trabajador.numCuota,
      ahorro_apv: trabajador.ahorro_apv,
      prestamos: trabajador.prestamos,
      jubilado: trabajador.jubilado,
      tramoAsigFam: trabajador.tramoAsigFam,
      impuestounicoC: trabajador.impuestounicoC,
    }
  }

  async obtenerHaberes() {
    this.trabajador.haberes = await this.funcionesLiquidaciones.obtenerHaberes();
  }
  calcularSueldoBase() {
    // console.log(this.trabajador.haberes);
    // const sueldoDiario = this.finiquitoForm.SueldoBase / this.finiquitoForm.diasTrabajados;
    // this.finiquitoForm.SueldoBase = sueldoDiario * this.finiquitoForm.diasTrabajados;
    if (this.integrarBonos) {
      for (let i = 0; i < this.trabajador.haberes.length; i++) {
        const haberTrabajador = this.trabajador.haberes[i];
        const haber = haberTrabajador.Haberes;
        const conceptoLRE = haber.ConceptoLRE;
        const totalBono = conceptoLRE.codigo === 2101 ? (haberTrabajador.monto / 30) * this.finiquitoForm.diasTrabajados : 0;
        this.finiquitoForm.SueldoBase += totalBono;
      }
    }
  }

  async CargarPrestamo() {
    this.trabajador.prestamo = 0;
    this.trabajador.prestamos = [];
    return await this.funcionesLiquidaciones.CargarPrestamo();
  }
  async ObtenerAdelantos() {
    return await this.funcionesLiquidaciones.ObtenerAdelantos();
  }

  OcultarModal() {
    this.modalService.dismissAll();
  }
  async Guardar() {
    this.blockedPanel = true;
    if (this.finiquito.Total === '' || this.finiquitoForm.Fecha === '') {
      this.alert.error_small('Campos de total de finiquito y/o fecha de termino estan vacios');
      return;
    }
    console.log(this.finiquito);
    this.trabajadorService.TraerTrabajadoresRut(this.rut)
      .subscribe(({data}) =>{
        let fecha_hoy = new Date();
        const trabajador = data[0];
        this.eliminarEntidades(trabajador);
        if (trabajador.estado_contrato !== 'Finiquitado') {
          trabajador.estado_contrato = 'Finiquitado';
          trabajador.estado = 0;
          this.trabajadorService.ActualizarTrabajador(trabajador).subscribe(res => {
            if (res.success == true) {
              this.alert.success_small(res.msg!);
              this.finiquito.Fecha = this.finiquitoForm.Fecha;
              console.log("este es el id: ", this.selectArticulo);
              this.finiquito.id_causal = this.selectArticulo;
              this.uploader.uploadAll();
              this.uploader.onSuccessItem = () => {
                this.finiquitoCreado.emit(true);
              }
              this.modalService.dismissAll();
              const data = {
                fk_trabajador: this.rut,
                idtipomovimiento: 2,
                idtipoevento: 9,
                periodo: fecha_hoy,
                fecha_final: this.finiquitoForm.Fecha._d
              }
              console.log(data);
              this.movimientoPersonalService.CrearMovimiento(data).subscribe(res => {
                if (res.success) {
                  this.alert.success_small(res.msg!);
                }
              })
            } else {
              this.alert.error_small(res.msg!)
            }
          })
        }else{
          this.uploader.uploadAll();
          this.modalService.dismissAll();
        }
        this.blockedPanel = false;
      }
    );
  }

  getArticulos() {
    this.articuloService.GetAll_articulos()
      .subscribe(({data}) => {
        this.articulos = data;
      })
      this.contratoService.TraerPorRut(this.rut).subscribe(({data}) => {
        console.log(data);
      })


  }

  GenerarFiniquito(){
    if (!this.selectArticulo && !this.selectedTermino) {
      this.alert.error_small('El articulo o el tipo de termino no deben ser vacios');
      return;
    }
    let fechaHoy = new Date();
    this.trabajadorService.TraerTrabajadoresRut(this.rut)
      .subscribe(({data}) =>{
        try{
        const trabajador = data[0];
        console.log(trabajador);
        let trabajadorSexo = trabajador.sexo;
        let nombreTrabajador = trabajador.nombres.toUpperCase() + ' ' + trabajador.apellidos.toUpperCase();
        let rutTrabajador = trabajador.rut;
        let direccionTrabajador = trabajador.direccion.toUpperCase();
        let regionTrabajadorNombre = trabajador.Ciudad.CiudadRegion.nombre;
        let nacionalidadTrabajador = trabajador.Nacionalidad.nombre.toUpperCase();
        let comunaTrabajador = trabajador.comuna?.toUpperCase();
        let regionTrabajador = trabajador.region?.toUpperCase();
        let idEmpresaContratante = trabajador.Empresa_contratante.id;
        let empresaContratante = trabajador.Empresa_contratante.nombre.toUpperCase();
        let rutEmpresa = trabajador.Empresa_contratante.run;
        let direccionEmpresa = trabajador.Empresa_contratante.ubicacion.toUpperCase();
        let ciudadEmpresa = trabajador.Empresa_contratante.CiudadEmpresa.nombre.toUpperCase();
        let representanteEmpresa = trabajador.Empresa_contratante.representante.toUpperCase();
        let rutRepresentante = trabajador.Empresa_contratante.cedula;
        let fechaIngreso = trabajador.fecha_ingreso;
        let funcion = trabajador.Cargo.nombre.toUpperCase();
        let fechaTerminoLaboral = this.finiquitoForm.Fecha._d;
        this.articuloEscogido = this.articulos.find(x => x.id === this.selectArticulo);
        let articuloCodigo = this.articuloEscogido.cod_art;
        let articuloNombre =this.articuloEscogido.nombre.toUpperCase();


         let sumaTotal = this.finiquito.Total;
         let termino = this.selectedTermino;

         let fecha_ingreso = new Date(fechaIngreso);
         console.log(`Fecha de ingreso: ${fecha_ingreso}`);
         console.log(`Fecha de termino laboral: ${fechaTerminoLaboral}`);

         let meses = differenceInMonths(fechaTerminoLaboral, fecha_ingreso);

         console.log(`Meses transcurridos: ${meses}`);

         let feriadosProporcionalDiasHabiles = this.finiquitoForm.diasTotalesProp;
         let feriadosProporcionalDiasCorridos = this.finiquitoForm.totalVacacionesProp;
         let feriadoProporcionalRemuneracion = this.finiquitoForm.idemVacaciones;
         let remuneracionUltimoMes = this.finiquitoForm.totalSueldoLiquido;
         const feriadoLegalDiasHabiles = this.finiquitoForm.diasTotalesFeriado;
         const feriadoLegalDiasInhabiles = this.finiquitoForm.diasInhabilesFeriado;
         const feriadoLegalRemuneracion = this.finiquitoForm.idemFeriado;

         const datos = {
          trabajadorSexo,
           fechaHoy,
           nombreTrabajador,
           rutTrabajador,
           direccionTrabajador,
           regionTrabajadorNombre,
           nacionalidadTrabajador,
           comunaTrabajador,
           regionTrabajador,
           idEmpresaContratante,
           meses,
           empresaContratante,
           rutEmpresa,
           direccionEmpresa,
           ciudadEmpresa,
           representanteEmpresa,
           rutRepresentante,
           fechaIngreso,
           funcion,
           fechaTerminoLaboral,
           articuloCodigo,
           articuloNombre,
           sumaTotal,
           termino,
           feriadosProporcionalDiasHabiles,
           feriadosProporcionalDiasCorridos,
           feriadoProporcionalRemuneracion,
           remuneracionUltimoMes,
           feriadoLegalDiasHabiles,
           feriadoLegalDiasInhabiles,
           feriadoLegalRemuneracion,
         }

         this.generarFiniquitoService.convertToPDF(datos);
         }catch(error){
            this.alert.errorAlert('Error al generar finiquito, contacte con informática');
            console.error(error);
         }

      });
  }

  async onCheckboxChange(idem: string) {
    if (!this[`check${idem}`]) {
      this.finiquitoForm[idem];
    }
    await this.calcularFiniquito();
  }
  async calcularFiniquito() {
    this.blockedPanel = true;
    if (this.finiquitoForm.FechaInicio && this.finiquitoForm.Fecha) {
      const fecha = this.finiquitoForm.Fecha;
      const fechaInicio = this.finiquitoForm.FechaInicio;
      const fechaAviso = this.finiquitoForm.FechaAviso;

      await this.obtenerLiquidacionValida();

      // SE CALCULAN LOS AÑOS DE SERVICIOS: SI LLEVA MAS DE 1 AÑO Y TIENE MAS O IGUAL A 6 MESES SE AGREGA 1 AÑO DE SERVICIO.
      const intervalos = this.calcularAñosServicio(fecha, fechaInicio, fechaAviso)
      // SE CALCULAN LOS DIAS DE VACACIONESzz
      this.calcularDiasVacaciones(intervalos.year, intervalos.month, intervalos.days);

      const totalIdemServicio = this.finiquitoForm.SueldoBase * intervalos.yearServicio;
      this.finiquitoForm.idemServicio = totalIdemServicio === 0 && this.checkidemServicio ? null : !this.checkidemServicio ? null : totalIdemServicio;

      this.periodoServicio = intervalos;
      this.periodo = `${this.periodoServicio.year} año(s) ${this.periodoServicio.month} mes(es) ${this.periodoServicio.days} dia(s)`;
      // ==> CALCULO DEL TOTAL FINITQUITO, DE ACUERDO A LOS SELECCIONADO
      this.calcularIndemnizaciones();
      this.finiquito.Fecha = this.finiquitoForm.Fecha;
      // console.log(this.periodoServicio);
    } else {
      this.periodo = 'Seleccione ambas fechas para hacer el calculo';
    }
    this.blockedPanel = false;
  }
  calcularIndemnizaciones(){
    // CALCULO DEL SUELDO LIQUIDO
    let total = 0;
    if (this.checkidemAviso) total += parseInt(this.finiquitoForm.idemAviso?.toString()) ?? 0;
    if (this.checkidemServicio) total += parseInt(this.finiquitoForm.idemServicio?.toString()) ?? 0;
    if (this.checkidemVacaciones) total += parseInt(this.finiquitoForm.idemVacaciones?.toString()) ?? 0;
    if (this.checkidemFeriado) total += parseInt(this.finiquitoForm.idemFeriado?.toString()) ?? 0;
    this.finiquitoForm.totalIndemnizaciones = total;

    total += parseInt(this.finiquitoForm.totalSueldoLiquido.toString());
    this.finiquito.Total = total.toString();
  }
  async changeSueldoBase(){
    this.blockedPanel = true;
    this.integrarBonos = false;
    const fecha = this.finiquitoForm.Fecha;
    const fechaInicio = this.finiquitoForm.FechaInicio;
    const fechaAviso = this.finiquitoForm.FechaAviso;
    // SE CALCULAN LOS AÑOS DE SERVICIOS: SI LLEVA MAS DE 1 AÑO Y TIENE MAS O IGUAL A 6 MESES SE AGREGA 1 AÑO DE SERVICIO.
    const intervalos = this.calcularAñosServicio(fecha, fechaInicio, fechaAviso)
    // SE CALCULAN LOS DIAS DE VACACIONESzz
    this.calcularDiasVacaciones(intervalos.year, intervalos.month, intervalos.days);

    const totalIdemServicio = this.finiquitoForm.SueldoBase * intervalos.yearServicio;
    this.finiquitoForm.idemServicio = totalIdemServicio === 0 && this.checkidemServicio ? null : !this.checkidemServicio ? null : totalIdemServicio;
    this.calcularIndemnizaciones();
    this.blockedPanel = false;
  }

  async changeDate(){
    this.blockedPanel = true;
    this.modificarDias = false;
    if (this.finiquitoForm.FechaInicio && this.finiquitoForm.Fecha) {
      const fecha = this.finiquitoForm.Fecha;
      const fechaInicio = this.finiquitoForm.FechaInicio;
      const fechaAviso = this.finiquitoForm.FechaAviso;

      this.setValueService();
      // this.calcularDiasPendientes();
      await this.obtenerLiquidacionValida();
      // await this.cargarLiquidacion();
      // SE CALCULAN LOS AÑOS DE SERVICIOS: SI LLEVA MAS DE 1 AÑO Y TIENE MAS O IGUAL A 6 MESES SE AGREGA 1 AÑO DE SERVICIO.
      const intervalos = this.calcularAñosServicio(fecha, fechaInicio, fechaAviso)
      // SE CALCULAN LOS DIAS DE VACACIONES
      this.calcularDiasVacaciones(intervalos.year, intervalos.month, intervalos.days);

      const totalIdemServicio = this.finiquitoForm.SueldoBase * intervalos.yearServicio;
      this.finiquitoForm.idemServicio = totalIdemServicio === 0 && this.checkidemServicio ? null : !this.checkidemServicio ? null : totalIdemServicio;

      this.periodoServicio = intervalos;
      this.periodo = `${this.periodoServicio.year} año(s) ${this.periodoServicio.month} mes(es) ${this.periodoServicio.days} dia(s)`;
      // ==> CALCULO DEL TOTAL FINITQUITO, DE ACUERDO A LOS SELECCIONADO
      this.calcularIndemnizaciones();
      this.finiquito.Fecha = this.finiquitoForm.Fecha;
    }else{
      this.periodo = 'Seleccione ambas fechas para hacer el calculo';
    }
    this.modificarDias = true;
    this.blockedPanel = false;
  }

  async changeDiasTrabajados(){
    this.blockedPanel = true;
    this.integrarBonos = false;
    this.trabajador.periodo = this.finiquitoForm.Fecha._d;
    this.calcularSueldoBase();

    // await this.cargarLiquidacion();
    if (this.finiquitoForm.FechaInicio && this.finiquitoForm.Fecha) {
      const fecha = this.finiquitoForm.Fecha;
      const fechaInicio = this.finiquitoForm.FechaInicio;
      const fechaAviso = this.finiquitoForm.FechaAviso;

      // SE CALCULAN LOS AÑOS DE SERVICIOS: SI LLEVA MAS DE 1 AÑO Y TIENE MAS O IGUAL A 6 MESES SE AGREGA 1 AÑO DE SERVICIO.
      const intervalos = this.calcularAñosServicio(fecha, fechaInicio, fechaAviso)
      // SE CALCULAN LOS DIAS DE VACACIONES
      this.calcularDiasVacaciones(intervalos.year, intervalos.month, intervalos.days);

      const totalIdemServicio = this.finiquitoForm.SueldoBase * intervalos.yearServicio;
      this.finiquitoForm.idemServicio = totalIdemServicio === 0 && this.checkidemServicio ? null : !this.checkidemServicio ? null : totalIdemServicio;

      this.periodoServicio = intervalos;
      this.periodo = `${this.periodoServicio.year} año(s) ${this.periodoServicio.month} mes(es) ${this.periodoServicio.days} dia(s)`;
      // ==> CALCULO DEL TOTAL FINITQUITO, DE ACUERDO A LOS SELECCIONADO
      // CALCULO DEL SUELDO LIQUIDO
      let total = 0;
      if (this.checkidemAviso) total += this.finiquitoForm.idemAviso ?? 0;
      if (this.checkidemServicio) total += this.finiquitoForm.idemServicio ?? 0;
      if (this.checkidemVacaciones) total += this.finiquitoForm.idemVacaciones ?? 0;
      this.finiquitoForm.totalIndemnizaciones = total;

      total += this.finiquitoForm.totalSueldoLiquido;
      this.finiquito.Total = total.toString();
      this.finiquito.Fecha = this.finiquitoForm.Fecha;
    }else{
      this.periodo = 'Seleccione ambas fechas para hacer el calculo';
    }
    this.blockedPanel = false;
  }

  // (METODO NO OCUPADO)
  async changeDiasPendientes(){
    this.blockedPanel = true;
    this.trabajador.periodo = this.finiquitoForm.Fecha._d;
    // this.calcularSueldoBase();
    // await this.cargarLiquidacion();
    if (this.finiquitoForm.FechaInicio && this.finiquitoForm.Fecha) {
      const fecha = this.finiquitoForm.Fecha;
      const fechaInicio = this.finiquitoForm.FechaInicio;
      const fechaAviso = this.finiquitoForm.FechaAviso;

      // SE CALCULAN LOS AÑOS DE SERVICIOS: SI LLEVA MAS DE 1 AÑO Y TIENE MAS O IGUAL A 6 MESES SE AGREGA 1 AÑO DE SERVICIO.
      const intervalos = this.calcularAñosServicio(fecha, fechaInicio, fechaAviso)
      // SE CALCULAN LOS DIAS DE VACACIONES
      this.calcularDiasVacaciones(intervalos.year, intervalos.month, intervalos.days);

      const totalIdemServicio = this.finiquitoForm.SueldoBase * intervalos.yearServicio;
      this.finiquitoForm.idemServicio = totalIdemServicio === 0 && this.checkidemServicio ? null : !this.checkidemServicio ? null : totalIdemServicio;

      this.periodoServicio = intervalos;
      this.periodo = `${this.periodoServicio.year} año(s) ${this.periodoServicio.month} mes(es) ${this.periodoServicio.days} dia(s)`;
      // ==> CALCULO DEL TOTAL FINITQUITO, DE ACUERDO A LOS SELECCIONADO
      // CALCULO DEL SUELDO LIQUIDO
      let total = 0;
      if (this.checkidemAviso) total += this.finiquitoForm.idemAviso ?? 0;
      if (this.checkidemServicio) total += this.finiquitoForm.idemServicio ?? 0;
      if (this.checkidemVacaciones) total += this.finiquitoForm.idemVacaciones ?? 0;
      this.finiquitoForm.totalIndemnizaciones = total;

      total += this.finiquitoForm.totalSueldoLiquido;
      this.finiquito.Total = total.toString();
      this.finiquito.Fecha = this.finiquitoForm.Fecha;
    }else{
      this.periodo = 'Seleccione ambas fechas para hacer el calculo';
    }
    this.blockedPanel = false;
  }

  async changeDiasVacacionesProp() {
    this.modificarDias = true;
    await this.calcularFiniquito();
  }

  async obtenerLiquidacionValida() {
    const res = await this.contabilidadService.TraerPorRutAll(this.rut);
    if (res.success) {
      const registros = res.data.sort((a, b) => {
        const timeA = new Date(a.f_liquidacion).getTime();
        const timeB = new Date(b.f_liquidacion).getTime();
        return timeB - timeA;
      });
      const registro = registros.find(x => x.sueldo > 0);
      this.finiquitoForm.SueldoBase = registro ? registro.sueldo : this.trabajador.sbase;
      this.finiquitoForm.diasTrabajados = registro ? registro.d_trabajados : 0;
      this.integrarBonos = false;
      this.calcularSueldoBase();
    }else{
      console.error(res.msg);
    }
  }
  calcularAñosServicio(fecha, fechaInicio, fechaAviso) {
    // CREO EL INTERVALO PARA SABER EL TRANSCURSO DEL TIEMPO ENTRE DOS FECHAS
    const intervalo: Duration = intervalToDuration({
      start: fechaInicio._d,
      end: fecha._d,
    });
    let diasCount = intervalo.days;
    let monthsCount = intervalo.months;
    let yearsCount = intervalo.years;

    let anioCountServicio = monthsCount >= 6 && (yearsCount >= 1) ? yearsCount + 1 : yearsCount;
    anioCountServicio = anioCountServicio > 11 ? 11 : anioCountServicio;
    if (fechaAviso && fecha.diff(fechaAviso, 'day') <= 30) { // AQUI COLOCAR ADEMAS LA CAUSAL DE DESPIDO
      // SI SE LE AVISO POR DEBAJO DE LOS 30 DIAS, SE APLICA LA IDEMNIZACION POR AVISO
      this.finiquitoForm.idemAviso = this.checkidemAviso ? this.finiquitoForm.SueldoBase : null;
    } else {
      this.finiquitoForm.idemAviso = null;
    }

    return {
      days: diasCount,
      month: monthsCount,
      year: yearsCount,
      yearServicio: anioCountServicio,
    }
  }
  calcularDiasVacaciones(anio: number, month: number, dias: number) {
    // this.finiquitoForm = {
    //   ...this.finiquitoForm,
    //   diasInhabilesProp: 0,
    //   // diasHabiles: 0,
    //   diasTotalesProp: 0,
    // }
    this.calcularVacacionesProporcionales({anio, month, dias});
    this.calcularFeriadoLegal();

    const totalVacacionesProp = this.finiquitoForm.totalVacacionesProp;
    const totalVacacionesFeriado = this.finiquitoForm.totalVacacionesFeriado;
    this.calcularIndemVacaciones({totalVacacionesProp, totalVacacionesFeriado});
  }
  calcularIndemVacaciones(dias: any) {
    const diasTrabajados = parseInt(this.finiquitoForm.diasTrabajados.toString());
    const sueldoBase = parseInt(this.finiquitoForm.SueldoBase.toString());
    // CALCULAR INDEMNIAZACION
    const sueldoDiario = diasTrabajados !== 0 && sueldoBase !== 0 ? sueldoBase / diasTrabajados : 0;
    const totalVacacionesPropRound = Math.round(dias.totalVacacionesProp * 100) / 100;
    const totalVacacionesFeriadoRound = Math.round(dias.totalVacacionesFeriado * 100) / 100;

    const idemVacacionesProp = Math.round(sueldoDiario * totalVacacionesPropRound);
    this.finiquitoForm.idemVacaciones = this.checkidemVacaciones && idemVacacionesProp > 0 ? idemVacacionesProp : null;

    const idemVacacionesFeriado = Math.round(sueldoDiario * totalVacacionesFeriadoRound);
    this.finiquitoForm.idemFeriado = this.checkidemFeriado && idemVacacionesFeriado > 0 ? idemVacacionesFeriado : null;
  }
  calcularFeriadoLegal() {
    const diasTotalesFeriado = parseInt(this.finiquitoForm.diasTotalesFeriado.toString());
    if (diasTotalesFeriado > 0) this.checkidemFeriado = true;
    this.finiquitoForm.diasInhabilesFeriado = this.calcularDiasInhabiles(diasTotalesFeriado);
    this.finiquitoForm.totalVacacionesFeriado = diasTotalesFeriado + this.finiquitoForm.diasInhabilesFeriado;
  }
  calcularVacacionesProporcionales(intervalo: any) {
    const { anio, month, dias } = intervalo;
    if (this.modificarDias) {
      const diasTotalesProp = parseInt(this.finiquitoForm.diasTotalesProp.toString());
      this.finiquitoForm.diasInhabilesProp = this.calcularDiasInhabiles(diasTotalesProp);
      this.finiquitoForm.totalVacacionesProp = diasTotalesProp + this.finiquitoForm.diasInhabilesProp;
    }else{
      const diasTotalesVacaciones = (Valores.VALOR_UNITARIO_DIAS_VACACIONES * dias) + (Valores.VALOR_UNITARIO_MESES_VACACIONES * month) + (Valores.VALOR_UNITARIO_MESES_VACACIONES * (12 * anio)  );

      this.finiquitoForm.diasTotalesProp = diasTotalesVacaciones;

      this.calcular3Años(anio);

      this.finiquitoForm.diasInhabilesProp = this.calcularDiasInhabiles(this.finiquitoForm.diasTotalesProp);
      this.finiquitoForm.totalVacacionesProp = this.finiquitoForm.diasTotalesProp + this.finiquitoForm.diasInhabilesProp;
    }
  }
  calcularDiasInhabiles(diasAdd: number): number {
    // CALCULO DE DIAS INHABILES
    const fechaTermino = this.finiquitoForm.Fecha;
    const desde = fechaTermino.clone();
    // const hasta = this.obtenerFechaHasta(desde.clone().add(Math.floor(this.finiquitoForm.diasTotales), 'days'));
    const hasta = this.obtenerFechaHasta(desde.clone().add(Math.floor(diasAdd), 'days'));
    let diasInhabiles = 0;
    desde.add(1, 'day');

    while (!desde.isAfter(hasta)) {
      diasInhabiles += this.actualizarDiasInhabiles(desde);
      desde.add(1, 'day');
    }
    return diasInhabiles;
  }
  calcular3Años(anio: number) {
    // VERIFICAR SI HAY MAS DE 3 AÑOS DE SERVICIOS, PARA REALIZAR EL CALCULO
    if (anio >= 3) {
      this.finiquitoForm.diasTotalesProp += 15;
      // CICLO DEL TOTAL DE AÑOS
      for (let i = 3; i < anio; i++) {
        // CADA 3 AÑOS, SE AGREGA 1 DIA A LAS VACACIONES
        if (i % 3 === 0) this.finiquitoForm.diasTotalesProp += 15;
        // EN EL ULTIMO AÑO, SI ESTE ES MAYOR A 3 AÑOS, AGREGAR 1 DIA MAS
        if ((i + 1 === anio) && (i % 3 > 0)) this.finiquitoForm.diasTotalesProp++;
      }
    }
  }

  obtenerFechaHasta(fecha: moment.Moment) {
    while (fecha.isoWeekday() === 6 || fecha.isoWeekday() === 7) fecha.add(1, 'day');
    return fecha;
  }

  private actualizarDiasInhabiles(fecha: moment.Moment): number {
    const esFinDeSemana = fecha.isoWeekday() === 6 || fecha.isoWeekday() === 7;
    const esFeriado = this.holidays.isHoliday(fecha.toDate());
    let diasInhabiles = 0;

    if (esFinDeSemana || esFeriado) {
      diasInhabiles++;
      // if (fecha.isoWeekday() === 6 && fecha.isSame(hasta.clone().subtract(1, 'day'))) {
      //   this.finiquitoForm.diasInhabiles++;
      // }
    }
    return diasInhabiles;
  }
  // ELIMINA LAS ENTIDADES DEL TRABAJADOR ANTES DE ENVIAR PARA ACTUALIZAR SUS DATOS
  eliminarEntidades(trabajador:any){
    delete trabajador.Ciudad;
    delete trabajador.Afp;
    delete trabajador.Cargo;
    delete trabajador.Empresa_contratante;
    delete trabajador.Estado_civil;
    delete trabajador.Nacionalidad;
    delete trabajador.Prevision;
    delete trabajador.Sucursal;
    delete trabajador.Banco;
  }

  // SETEA LOS VALORES NECESARIOS DEL SERVICIO
  setValueService() {
    this.funcionesLiquidaciones.setMode(true);
    this.funcionesLiquidaciones.setRut(this.rut);
    this.funcionesLiquidaciones.setPeriodo(this.trabajador.periodo);
  }
}
