import { Component, OnInit,Input, SimpleChanges } from '@angular/core';

import {Documento} from 'src/app/shared/models/documentos/documentos.interface';
import {DocumentosService} from 'src/app/pages/services/documentos.service';

@Component({
  selector: 'app-lista-transferencias',
  templateUrl: './lista-transferencias.component.html',
  styleUrls: ['./lista-transferencias.component.css']
})
export class ListaTransferenciasComponent implements OnInit {
  @Input() fk_trabajador:number=0;

  documentos:Documento[] = [];
 
  constructor(
    private documentoService: DocumentosService
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    

    if (changes.fk_trabajador.currentValue != changes.fk_trabajador.previousValue){

      this.documentos=[]
      this.documentoService.GetAll_transferencias(this.fk_trabajador).subscribe(
        (res) => {
          
          this.documentos = res.data;
        }
      );
    }
  
}

  ngOnInit(): void {

    this.documentoService.GetAll_transferencias(this.fk_trabajador).subscribe(
      (res) => {
        this.documentos = res.data;
       
      }
    );
  }

}
