<!-- <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center" *ngIf="loading">
        <div class="text-blue-600 font-bold mb-3"><i class="pi pi-spin pi-spinner"></i>&nbsp;CARGANDO LA OPERACIÓN</div>
        <div class="text-700 text-2xl mb-5">Espere un momento para realizar el proceso de aprobar la solicitud.</div>
    </div>
    <div class="text-700 text-center" *ngIf="option === 1 && solicitudActualizada">
        <div class="text-green-600 font-bold mb-3"><i class="pi pi-thumbs-up"></i>&nbsp;SOLICITUD APROBADA</div>
        <div class="text-900 font-bold text-5xl mb-3">Documento Eliminado con Éxito</div>
        <div class="text-700 text-2xl mb-5">La solicitud para eliminar el documento ha sido aprobada y el documento ha sido eliminado satisfactoriamente del sistema.</div>
        <button pButton pRipple label="Regresar" icon="pi pi-check" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></button>
    </div>
    <div class="text-700 text-center" *ngIf="option === 0 && solicitudActualizada">
        <div class="text-red-600 font-bold mb-3"><i class="pi pi-thumbs-down"></i>&nbsp;SOLICITUD RECHAZADA</div>
        <div class="text-900 font-bold text-5xl mb-3">No se eliminará el documento.</div>
        <div class="text-700 text-2xl mb-5">La solicitud para eliminar el documento ha sido rechazada y el documento seguirá en el sistema.</div>
        <button pButton pRipple label="Regresar" icon="pi pi-check" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></button>
    </div>
    <div class="text-700 text-center" *ngIf="!solicitudActualizada">
        <div class="text-600 font-bold mb-3"><i class="pi pi-exclamation-circle"></i>&nbsp;ERROR EN LA SOLICITUD</div>
        <div class="text-700 text-2xl mb-5">{{infoMensaje}}</div>
        <button pButton pRipple label="Regresar" icon="pi pi-check" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></button>
    </div>
</div> -->
<div class="surface-section px-4 py-8 md:px-6 lg:px-8">

    <ng-container *ngIf="loading">
      <div class="text-700 text-center">
        <div class="text-blue-600 font-bold mb-3"><i class="pi pi-spin pi-spinner"></i>&nbsp;CARGANDO LA OPERACIÓN</div>
        <div class="text-700 text-2xl mb-5">Espere un momento para realizar el proceso de aprobar la solicitud.</div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="solicitudActualizada && !loading">
      <div class="text-700 text-center" *ngIf="option === 1">
        <div class="text-green-600 font-bold mb-3"><i class="pi pi-thumbs-up"></i>&nbsp;SOLICITUD APROBADA</div>
        <div class="text-900 font-bold text-5xl mb-3">Documento Eliminado con Éxito</div>
        <div class="text-700 text-2xl mb-5">La solicitud para eliminar el documento ha sido aprobada y el documento ha sido eliminado satisfactoriamente del sistema.</div>
      </div>
  
      <div class="text-700 text-center" *ngIf="option === 0">
        <div class="text-red-600 font-bold mb-3"><i class="pi pi-thumbs-down"></i>&nbsp;SOLICITUD RECHAZADA</div>
        <div class="text-900 font-bold text-5xl mb-3">No se eliminará el documento.</div>
        <div class="text-700 text-2xl mb-5">La solicitud para eliminar el documento ha sido rechazada y el documento seguirá en el sistema.</div>
      </div>
    </ng-container>

    <ng-container *ngIf="!solicitudActualizada && !loading">
        <div class="text-700 text-center">
            <div class="text-600 font-bold mb-3"><i class="pi pi-exclamation-circle"></i>&nbsp;ERROR EN LA SOLICITUD</div>
            <div class="text-700 text-2xl mb-5">{{infoMensaje}}</div>
        </div>
    </ng-container>
    <div class="text-center">
        <button pButton pRipple label="Regresar" icon="pi pi-check" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap" (click)="navigate()"></button>
    </div>
  </div>
  