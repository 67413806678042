import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Console } from 'console';

import { debounceTime, Subject } from 'rxjs';
import { HorasExtrasService } from 'src/app/pages/services/horasExtras/horasExtras.service';


import { ImpuestoSegundaCatService } from 'src/app/pages/services/impuestoSegundaCat/impuesto-segunda-cat.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';


@Component({
  selector: 'app-agregar-impuesto2cat',
  templateUrl: './agregar-impuesto2cat.component.html',
  styleUrls: ['./agregar-impuesto2cat.component.css']
})
export class AgregarImpuesto2catComponent implements OnInit {


  dataSource: any;
  trabajadores_aux: any;
  trabajador: any;
  modalReference = null

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  debouncer: Subject<boolean> = new Subject()

  constructor(
    private _impuesto: ImpuestoSegundaCatService,
    private alert: AlertHelper,
    private modalService: NgbModal,
    private horasExtrasService: HorasExtrasService,
  ) { }


  impuesto: any = {
    id: 0,
    desde: '',
    hasta: '',
    cantidad: '',
    fk_trabajador: '',


  }



  guardar() {

    this._impuesto.listarporrut(this.impuesto.fk_trabajador).subscribe((res) => {

      let rojo = 1
      let a = res.data


      if (a != null) {


        this.alert.error_small("El usuario ya tiene impuesto")

      } else {

        this._impuesto.crear(this.impuesto).subscribe((res) => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
            this.ngOnInit();

          }


        })






      }





    })



  }


  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })

    this.Traerdatos()
  }





  open(ventas: any) {

    this.modalReference = this.modalService.open(ventas);
  }




  Traerdatos() {

    this.horasExtrasService.TraerTrabajadores().subscribe((res) => {



      this.trabajadores_aux = res.data;

      // this._impuesto.Todo().subscribe((res) => {

      // const requisitos = [];
      // requisitos.push(

      //   res.data[0].fk_trabajador
      //  )


      // console.log( res.data[0].fk_trabajador)
      // console.log( res.data)
      // console.log(this.trabajadores_aux)
      //  this.trabajadores_aux = res.data.filter((x: any) => { return x.rut !=  });

      this.trabajadores_aux

      // });

      // this._impuesto.listarporrut(this.trabajador).subscribe((res) => {



      // let datos = this.trabajadores_aux.filter((x: any ) => { return x.rut ==  });


      // })





    });



  }


  ObtenerTrabajador(event: MatSelectChange) {


    this.trabajador = event.value;



    this.impuesto.fk_trabajador = this.trabajador;



    this._impuesto.listarporrut(this.trabajador).subscribe((res) => {

      let a = res.data


      if (a == null) {


        // this.alert.error_small("El usuario no tiene impuesto")



      }





    })

    //  this.trabajadores_aux = res.data.filter((x: any) => { return x.rut ==   this.impuesto.fk_trabajador  });






  }


}
