import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-lista-sucursales',
  templateUrl: './lista-sucursales.component.html',
  styleUrls: ['./lista-sucursales.component.css']
})
export class ListaSucursalesComponent implements OnInit {

  @Input() sucursales_List: Sucursal[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  update_sucursales: Sucursal = {
    id: 0,
    nombre: "",
    direccion: "",
    idciudad: 0,
    centroCosto: 1,
  };
  ciudades_dropdown: any[] = [];
  ciudadSeleccionada: number;

  fileName = 'sucursales.xlsx';
  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }

  public convetToPDF() {

    var datos = [];
    datos.push([
      'CENTRO COSTO',
      'NOMBRE',
      'DIRECCION',
    ]);


    this.sucursales_List.map(function (item) {

      datos.push([
        item.centroCosto,
        item.nombre,
        item.direccion,
      ]);
    });


    var documentDefinition = {

      content: [

        { text: 'SUCURSALES', style: 'header', fontSize: 30 },
        {
          table: {

            body: datos

          }
        }
      ]
    };

    pdfMake.createPdf(documentDefinition).open();
  }
  displayedColumns: string[] = ['centro', 'nombre', 'direccion', 'actions'];
  dataSource = new MatTableDataSource<Sucursal>(this.sucursales_List);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private sucursalesService: SucursalService,
    private ciudadService: CiudadService,
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.ciudadService.GetAll_ciudades().subscribe(res => {
      if (res.success) {
        this.ciudades_dropdown = res.data.sort((a,b) => a.nombre.localeCompare(b.nombre));
      } else {
        this.alert.error_mail('Hubo un error al recibir las ciudades.');
        console.error(res.msg);
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sucursales_List != undefined) {
      if (!changes.sucursales_List.firstChange) {
        this.dataSource = new MatTableDataSource<Sucursal>(this.sucursales_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  open(content: any, id: number) {
    this.update_sucursales.id = id
    this.sucursalesService.Obtener_sucursal(id).subscribe(res => {
      if (res.success) {
        const sucursal = res.data;
        this.update_sucursales.nombre = sucursal.nombre;
        this.update_sucursales.direccion = sucursal.direccion;
        this.update_sucursales.idciudad = sucursal.idciudad ?? 0;
        this.update_sucursales.centroCosto = sucursal.centroCosto;
        this.ciudadSeleccionada = sucursal.idciudad ?? 0;
        this.modalService.open(content);
      } else {
        this.alert.error_small('Ocurrio un error al obtener los datos');
        console.error(res.msg);
      }

    });
  }

  limpiar_sucursales() {
    this.update_sucursales.nombre = ""
  }

  Actualizar_Sucursales() {
    if (this.update_sucursales.nombre.trim().length == 0) {
      this.alert.error_small('Los campos no pueden estar vacios.')
      return
    }
    this.update_sucursales.idciudad = this.ciudadSeleccionada;

    this.sucursalesService.Actualizar_sucursal(this.update_sucursales).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
        //console.log(res);
      }
    })


  }

  Eliminar_Sucursal(content: any, id: number) {


    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el sucursal",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {


        this.sucursalesService.Eliminar_sucursal(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }

  ObtenerCiudad(event: MatSelectChange) {
    const ciudad = event.value;
    console.log({ciudad});
    this.update_sucursales.idciudad = ciudad;
  }

  //FITRAL
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
