<div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 class>Mantenedor Impuesto Segunda Categoria</h1>
      </mat-card>
    </div>
  
    <br />
    <mat-card class="mat-elevation-z5 mat_card_general_content border border-primary">
      <app-listar-impuesto2cat></app-listar-impuesto2cat>
      <br />
      <!-- <app-lista-afp
        (onDebounce)="actualizarTabla($event)"
        [afps_List]="afp"
      ></app-lista-afp> -->
    </mat-card>
    <hr />
  </div>
  