export const IncumplimientosConductor = [
    {
        text: `Ocupar el vehículo después del horario de trabajo sin autorización del supervisor directo. \n`,
        style: 'parrafo'
    },
    {
        text: `Conducir a exceso de velocidad o incumpliendo cualquier ley de tránsito vigente en nuestro país. \n`,
        style: 'parrafo'
    },
    {
        text: `Actuar de forma grosera y/o agresiva, en contra del personal de salud u personal de la empresa. \n`,
        style: 'parrafo'
    },
    {
        text: `Ocasionarle algún daño o perjuicio al vehículo por negligencia o descuido.\n`,
        style: 'parrafo'
    },
    {
        text: `Manipular el sistema GPS del vehículo \n`,
        style: 'parrafo'
    },
    {
        text: `No manejar atento a las condiciones del tránsito. \n`,
        style: 'parrafo'
    },
    {
        text: `No informar los desperfectos o averías del vehículo. \n`,
        style: 'parrafo'
    },
    {
        text: `No mantener aseado el vehículo que se le entrega. \n`,
        style: 'parrafo'
    },
    {
        text: `Si el trabajador se presenta en condiciones físicas no acorde para prestar servicio o fuese sorprendida en estado de intemperancia, bajo efectos psicotrópicos, durmiendo o que el chofer no se presente a cumplir con sus funciones.\n`,
        style: 'parrafo'
    },
    {
        text: `El incumplimiento del conductor en el horario dispuesto para su servicio (entrada tarde y salida antes de horario).\n`,
        style: 'parrafo'
    },
    {
        text: `En caso de que el conductor sea sorprendido alterando el recorrido asignado o simplemente haciendo abandono de su servicio.\n`,
        style: 'parrafo'
    },
    {
        text: `Incumplimiento en la vestimenta que haya sido entregada a los conductores.\n`,
        style: 'parrafo'
    },
    {
        text: `No mantener salud acorde con las funciones propias del cargo físicas y mentales.\n`,
        style: 'parrafo'
    },
   
];

export const ProhibicionesConductor = [
    {
        text: `No registrar la asistencia en el libro llevado para el efecto. \n`,
        style: 'parrafo'
    },
    {
        text: `Al presentarse a su lugar de trabajo, negarse a realizar las labores establecidas y aceptadas en este contrato o retirarse de su lugar de trabajo antes del término de la jornada pactada en el mismo. \n`,
        style: 'parrafo'
    },
    {
        text: `Realizar en el transcurso de su jornada de trabajo, cualquier actividad que no tenga relación con las labores para las cuales fue contratado, como, por ejemplo, vender dulces u otros productos, desviarse del cuadrante designado, ir a su domicilio en el vehículo a su cargo.\n`,
        style: 'parrafo'
    },
    {
        text: `Sacar o sustraer del vehículo designado a su cargo o de las instalaciones en donde deba permanecer, estar o transitar, cualquier tipo de elemento que pertenezcan a ella, ya sea personalmente o por medio de terceras personas. El incumplimiento de esta prohibición, que se califica de grave, dará lugar, además de su desvinculación, a perseguir las acciones legales pertinentes. \n`,
        style: 'parrafo'
    },
    {
        text: `Ejecutar, durante las horas de trabajo y en el desempeño de sus funciones, actividades ajenas a la labor y al establecimiento o dedicarse a atender asuntos particulares. \n`,
        style: 'parrafo'
    },
    {
        text: `Ejecutar negociaciones que se encuentren comprendidas dentro del giro de la empresa.\n`,
        style: 'parrafo'
    },
    {
        text: `Transportar durante sus recorridos a personas extrañas y ajenas al trabajo asignado. Solo se permitirá con previa autorización de personal del Programa de Cuidados Paliativos Universales de la Corporación Municipal de Educación, Salud, Cultura y Recreación de La Florida. \n`,
        style: 'parrafo'
    },
    {
        text: `Destinar el vehículo a otro objeto, que no sean las correspondientes al trabajo asignado.\n`,
        style: 'parrafo'
    },
    {
        text: ` Fumar en el interior del vehículo a su cargo, ya sea transitando o detenido.\n`,
        style: 'parrafo'
    },
    {
        text: `Consumir alcohol o drogas durante su jornada laboral. \n`,
        style: 'parrafo'
    },
    {
        text: `Presentarse a su lugar de trabajo, en estado de intemperancia, estado de ebriedad o bajo la influencia del alcohol. \n`,
        style: 'parrafo'
    },
    {
        text: `Presentarse a su lugar de trabajo bajo la influencia de las drogas. \n`,
        style: 'parrafo'
    },
    {
        text: `Aducir enfermedades no acreditadas con el objeto de no trabajar, presentar certificados médicos adulterados o falsos. \n`,
        style: 'parrafo'
    },
    {
        text: `Confiar el vehículo a su cargo a terceros no autorizados por el empleador o alguno de sus representantes, sea en la conducción o en el cuidado; \n`,
        style: 'parrafo'
    },
    {
        text: `Conducir el vehículo dentro de las horas de servicio, fuera de ruta normal de trabajo, a menos que cuente con la autorización de LA EMPRESA. \n`,
        style: 'parrafo'
    },
    {
        text: `No abastecerse de combustible en los lugares expresamente establecidos por el empleador.\n`,
        style: 'parrafo'
    },
]

export const ObligacionesConductor = [

    {
        text: `Presentarse puntualmente a su trabajo en el turno y hora que le correspondiese, en buen estado físico y mental. \n`,
        style: 'parrafo'
    },
    {
        text: `Velar por el buen estado de los vehículos de la empresa, avisando de manera inmediata al supervisor de zona cualquier desperfecto que observe o notase, dentro del más breve plazo para que se proceda a efectuar las coordinaciones para llevar a efecto reparaciones que sean del caso o el remplazo del vehículo. \n`,
        style: 'parrafo'
    },
    {
        text: ` Tener un trato cordial y de respeto con sus compañeros de trabajo, personal de salud, administrativo y personal de la empresa. \n`,
        style: 'parrafo'
    },
    {
        text: `Utilizar–cuidareluniformeyaccesoriosasignadosporlaempresaasucargo.\n`,
        style: 'parrafo'
    },
    {
        text: `Solicitar autorización para realizar carga de combustible a los encargados de dicha carga dentro de los horarios informados por el departamento correspondiente, preocupándose, cuando se encuentre en el turno de día, que el nivel de combustible sea suficiente para que el turno de noche realice su ronda sin inconvenientes. \n`,
        style: 'parrafo'
    },
    {
        text: `El trabajador se compromete a guardar absoluta reserva de lo que llegue a su conocimiento en relación con los asuntos del Empleador y de sus clientes; a no dar información alguna a personas ajenas a la empresa respecto de las relaciones con el empleador o respecto de los demás trabajadores de la institución o de la , en su caso.\n`,
        style: 'parrafo'
    },
    {
        text: `Mantener su documentación y licencias necesarias para conducir el vehículo que se le asigne, debidamente al día, portándolas en todo momento en que conduzca.\n`,
        style: 'parrafo'
    },
    {
        text: `Conservar ordenadamente la documentación del vehículo asignado, la que deberá permanecer siempre en él, comunicando y solicitando oportunamente al empleador, la renovación de los documentos que se encuentran próximos a vencer con el objeto de evitar infracciones.\n`,
        style: 'parrafo'
    },
    {
        text: `Efectuar una revisión previa a todo viaje del vehículo a su cargo, considerando en ella, a lo menos, los siguientes puntos: presión de neumáticos, tensión de correas, niveles de aceite, agua, combustible, luces, aseo interior y exterior, elementos de apoyo (botiquín, rueda de repuesto, triángulos, llaves, gatas, etc.).\n`,
        style: 'parrafo'
    },
    {
        text: `Velar por el cuidado y mantención de las herramientas de dotación del vehículo y de todos los elementos de seguridad y mecánicos de esta.\n`,
        style: 'parrafo'
    },
    {
        text: `Respetar y cumplir a cabalidad con todas las exigencias que se impone en la ley de tránsito para el desempeño de sus funciones.\n`,
        style: 'parrafo'
    },
    {
        text: `Conducir en todo momento de manera segura, prudente y conforme a las normas de seguridad legalmente establecidas, siendo exclusivamente responsable de cualquier infracción a la normativa de tránsito vigente en la que incurra. \n`,
        style: 'parrafo'
    },
    {
        text: `Conducir en todo momento a la defensiva para evitar todo daño o perjuicio al vehículo que conduce, así como a la carga o pasajeros que transporte.\n`,
        style: 'parrafo'
    },
    {
        text: `Utilizar durante la conducción del vehículo el cinturón de seguridad existente en él y exigir que los pasajeros que viajen en el vehículo lo utilicen también.\n`,
        style: 'parrafo'
    },
    {
        text: `Mantener un respeto irrestricto a los límites de velocidad máximos permitidos, estándole absolutamente prohibido superarlos.\n`,
        style: 'parrafo'
    },
    {
        text: `Cualquiera otra función que tenga estricta relación conlaslabores encomendadas.\n`,
        style: 'parrafo'
    },
]