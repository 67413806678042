import { Component, OnInit } from '@angular/core';
import { HaberesService } from 'src/app/pages/services/haberes/haberes.service';

@Component({
  selector: 'app-main-haberes',
  templateUrl: './main-haberes.component.html',
  styleUrls: ['./main-haberes.component.css']
})
export class MainHaberesComponent implements OnInit {

  constructor(
    private haberesService: HaberesService,
  ) { }

  ngOnInit(): void {
  }

}
