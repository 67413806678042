<div class="row container-fluid">
    <div class="col-12">
        <button mat-raised-button (click)="open(content)" color="primary">
            <mat-icon style="color: white">
                <span class="material-symbols-outlined">add</span>
            </mat-icon>
            Agregar región
        </button>
        
        <ng-template #content let-modal>
            <div class="modal-header">
                <mat-card-title>Agregar nueva región</mat-card-title>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row container">
                    <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" [(ngModel)]="region.nombre">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Codigo LRE</mat-label>
                            <input matInput type="text" [(ngModel)]="region.codigo_lre" pTooltip="Codigo del Libro de Remuneraciones Electronico" tooltipPosition="bottom" autonumeric [options]="optionsNumeric">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancelar</button>
                <button type="button" class="btn btn-success" (click)="CrearRegion()">Aceptar</button>
            </div>
        </ng-template>
    </div>
</div>