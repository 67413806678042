import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
//import { Router } from '@angular/router'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { RequestResponse } from 'src/app/shared/models/request-response.interface'
import { Observable, throwError } from 'rxjs'
import { RequestResponseActualizar, RequestResponseGetAllPrestamos, Prestamos } from 'src/app/shared/models/prestamos.interface'

@Injectable({
  providedIn: 'root'
})

export class PrestamosService {

  constructor(
    private http: HttpClient,
    //private router: Router,
    private alert: AlertHelper) { }

  GetAll_prestamos(): Observable<RequestResponseGetAllPrestamos> {
    return this.http
      .get<RequestResponseGetAllPrestamos>(`${environment.API}/rrhh/prestamo/`)
      .pipe(
        map((res: RequestResponseGetAllPrestamos) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  } 

  private handlerError(err: any): Observable<never> {

    let errorMessage = ""
    if (err) {
      errorMessage = `Error: code ${err.error.msg}`
    }
    this.alert.error_small(err.error.msg)
    return throwError(errorMessage)
  }

  Create_prestamos(prestamos: any): Observable<RequestResponse> {    
    return this.http
      .post<RequestResponse>(`${environment.API}/rrhh/prestamo/crear`, prestamos)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  actualizar_prestamo(prestamo: any): Observable<RequestResponseActualizar> {
    return this.http
      .put<RequestResponseActualizar>(`${environment.API}/rrhh/prestamo/actualizar/${prestamo.id}`, prestamo)
      .pipe(
        map((res: RequestResponseActualizar) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  Obtener_prestamo(id: number): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/prestamo/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  // Eliminar_prestamo(id: number): Observable<RequestResponse> {
  //   return this.http
  //     .delete<RequestResponse>(`${environment.API}/rrhh/prestamo/eliminar/${id}`)
  //     .pipe(
  //       map((res: RequestResponse) => {
  //         return res
  //       }),
  //       catchError((err) => this.handlerError(err))
  //     )
  // }

  Eliminar_prestamo(id: number, prestamo: Prestamos): Observable<RequestResponse> {
    return this.http
      .put<RequestResponse>(`${environment.API}/rrhh/prestamo/eliminar/${id}`, prestamo)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  TraerArchivo(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarPrestamoId/${id}`)
    .pipe(
      map((res:RequestResponse) => res),
      catchError((err)=> this.handlerError(err))
    );
  }

  traer_todos_por_rut(rut: any): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/prestamo/rut/${rut}`)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }
  async TraerPrestamoPorRutAsync(rut: number): Promise<RequestResponse> { 
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/prestamo/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los prestamos del trabajador.'} as RequestResponse)
    }
  }
  async TraerPorPeriodo(periodo: string): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/prestamo/periodo/${periodo}`).toPromise();
    } catch (error) {
      console.log(error);
      return { success: false, msg:'Hubo un error al obtener los prestamos.' } as RequestResponse; 
    }
  }
  actualizarPrestamoCuotaLiquidacion(id:number, prestamo: Prestamos): Observable<RequestResponse>{
    return this.http.put<RequestResponse>(`${environment.API}/rrhh/prestamo/actualizarEstado/${id}`, prestamo)
      .pipe(
        map((res: RequestResponse) => res),
        catchError((error) => this.handlerError(error))
      );
  }
}