
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RazonService } from 'src/app/pages/services/razon/razon.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-agregar-razon',
  templateUrl: './agregar-razon.component.html',
  styleUrls: ['./agregar-razon.component.css']
})

export class AgregarRazonComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  razon: any = {
    id: 0,
    razon: '',
    esAtraso: false,
    colorfondo: '',
    colortexto: ''
  };
  opciones: any[] = [
    { value: 0, text: 'NO' },
    { value: 1, text: 'SI' },
  ];

  constructor(
    private razonService: RazonService,
    private alert: AlertHelper,
    private modalService: NgbModal

  ) { }


  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }


  algo(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  guardar() {

    if (this.razon.razon.length < 4) {
      return
    }
    this.razonService.Crear(this.razon).subscribe((res: any) => {

      if (res.success == true) {

        this.alert.success_small(res.msg!)

        this.modalService.dismissAll();
        this.razon.razon="";
        this.ngOnInit();
        this.debouncer.next(true);

      } else {

        this.alert.error_small(res.msg!)

      }
    })

  }

}

