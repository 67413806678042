import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LiquidacionService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

  EnviarArchivo(archivo:any){
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/liquidacion/documento`,archivo)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async ObtenerAllLiquidacionesAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/liquidacion/`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener las liquidaciones.'} as RequestResponse;
    }
  }

  EnviarLiquidacion(liquidacion:any) {
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/liquidacion/crear`,liquidacion)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/liquidacion/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerPorRutAsync(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/liquidacion/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener las liquidaciones.'} as RequestResponse);
    }
  }

  async TraerPorIdAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/liquidacion/id/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener la liquidacion.'} as RequestResponse);
    }
  }

  TraerPrevired():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/liquidacion/listarValores/all`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async TraerPreviredLiquidacion(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/liquidacion/listarValores/all`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los datos de Previred'} as RequestResponse);
    }
  }

  
  TraerArchivo(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarcliquidacionesid/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerArchivoAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/documento/buscarcliquidacionesid/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener el archivo'} as RequestResponse);
    }
  }

  async ActualizarLiquidacion(liquidacion: any): Promise<RequestResponse> {
    try {
      return await this.http.put<RequestResponse>(`${environment.API}/rrhh/liquidacion/actualizar/${liquidacion.id}`, liquidacion).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al actualizar la liquidación.'} as RequestResponse);
    }
  }

  eliminarLiquidacion(id:number): Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/liquidacion/eliminar/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res;
        }),
        catchError((error) => this.handlerError(error))
      );
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
