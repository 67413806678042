import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrimeNGConfig } from 'primeng/api';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';


@Component({
  selector: 'app-lista-compras',
  templateUrl: './lista-compras.component.html',
  styleUrls: ['./lista-compras.component.css'],
  providers: [DatePipe]
})

export class ListaComprasComponent implements OnInit {

  date = new FormControl(new Date());
  serializedDate = new FormControl(new Date().toISOString());

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(MatPaginator) paginator!: MatPaginator


  constructor(

    private modalService: NgbModal,
    private primengConfig: PrimeNGConfig,
    private datePipe: DatePipe,
    private alert: AlertHelper,
    private trabajadorService: TrabajadoresService,


  ) { }

  //Variable

  fecha = new Date();

  fecha_Actual = new Date();

  fechaFormatT: any;

  FechaTermino: any;

  fechaMostrar: any;

  trabajadores : any;
  documentos: any;

  clientes = [
    { id: 1, nombre: "Emilio" },
    { id: 2, nombre: "Raul" },
    { id: 3, nombre: "Cristian" },
    { id: 4, nombre: "Juan Jose" },
    { id: 5, nombre: "Carlos" },
    { id: 6, nombre: "Jhon" },
    { id: 7, nombre: "Wladimir" },
    { id: 8, nombre: "Felipe" },
    { id: 9, nombre: "Cristian" },
    { id: 10, nombre: "Favio" }
  ]
  documento = [
    { id: 1, nombre: "Emilio" },
    { id: 2, nombre: "Raul" },
    { id: 3, nombre: "Cristian" },
    { id: 4, nombre: "Juan Jose" },
    { id: 5, nombre: "Carlos" },
    { id: 6, nombre: "Jhon" },
    { id: 7, nombre: "Wladimir" },
    { id: 8, nombre: "Felipe" },
    { id: 9, nombre: "Cristian" },
    { id: 10, nombre: "Favio" }
  ]



  trabajador: any = {

    ventas : "",
    documento: ""


  }
  //Columna de la tabla
  displayedColumns: string[] = [
    'id',
    'compra'

  ]
  

  Trabajadores(event: any) {

    this.trabajador.ventas  = event.value.nombre;

 
  
  }

  Documentos(event: any) {

    this.trabajador.documentos  = event.value.nombre;


  
  }

  //Capturar la fecha y asignarle la fecha de termino

  Emision(event: MatDatepickerInputEvent<Date>) {

    this.fecha = event.value;

    this.fechaFormatT = this.datePipe.transform(this.fecha, 'yyyy-MM-dd 00:00:00'); //aqui se transforma usando datepipe al formato año-mes-

    let fec = new Date(this.fechaFormatT);


    this.fechaMostrar = fec.getDate() + ' /' + (fec.getMonth() + 2) + ' /' + fec.getFullYear();

  }




  ngOnInit(): void {


    //muestra la fecha actual 
    this.fecha_Actual.getMonth();

   

  }

}
