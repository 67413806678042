import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FileUploadModule } from 'ng2-file-upload';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MainPerfilesOcupacionalesComponent } from './main-perfiles-ocupacionales/main-perfiles-ocupacionales.component';
import { ListaPerfilesOcupacionalesComponent } from './lista-perfiles-ocupacionales/lista-perfiles-ocupacionales.component';
import { AgregarPerfilesOcupacionalesComponent } from './agregar-perfiles-ocupacionales/agregar-perfiles-ocupacionales.component';



@NgModule({
  declarations: [
    MainPerfilesOcupacionalesComponent,
    ListaPerfilesOcupacionalesComponent,
    AgregarPerfilesOcupacionalesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports:[
    MainPerfilesOcupacionalesComponent,
    ListaPerfilesOcupacionalesComponent,
    AgregarPerfilesOcupacionalesComponent,

  ]
})
export class PerfilesOcupacionalesModule { }
