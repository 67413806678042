import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { TransferenciaService } from 'src/app/pages/services/transferencias/transferencia.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
@Component({
  selector: 'app-modal-mostrar',
  templateUrl: './modal-mostrar.component.html',
  styleUrls: ['./modal-mostrar.component.css'],
  providers: [DatePipe]
})
export class ModalMostrarComponent implements OnInit {
  @Input() rut: any
  @Input() trabajador: any
  @ViewChild(MatPaginator) paginator!: MatPaginator;   // variable para asignar paginator a matdatatable
  @ViewChild(MatSort) sort!: MatSort;
  info_documento:any;
  displayedColumns = [
    'id',
    'rut',
    'fecha',
    'motivo',
    'banco',
    'monto',
    'acciones'
  ];
  dataSource = new MatTableDataSource();
  transferencias: any;
  constructor(
    private transferenciaService: TransferenciaService,
    private alert: AlertHelper,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private documentoService:DocumentosService,
  ) { }

  ngOnInit(): void {
    this.Traertransferencias();
  }

  Traertransferencias() {
    this.transferencias = [];
    //this.transferenciaService.TraerPorRut(this.rut).subscribe(
    this.transferenciaService.TraerPorRut(this.trabajador.rut).subscribe(
      (res) => {
        this.transferencias = res.data;
        if (this.transferencias.length > 0) {
          this.dataSource = this.transferencias;
        }
        else {
          this.modalService.dismissAll();
          Swal.fire({title: "", text: "No hay transferencias para mostrar", icon: "info"})
        }
      }
    );

  }

  Descargar(id:any){
    this.transferenciaService.TraerArchivo(id).subscribe(
      (res)=>{
        if (res.success==true){

        this.info_documento =res.data[0];
        //console.log(this.info_documento);
        window.open(`${environment.API}/transferencias/`+res.data[0].ruta);
        }
        else{
          this.alert.errorAlert("No se encuentran archivos");
        }
      }
      
    );
  }

  Borrar(id:number){
    if (!confirm('¿Seguro que desea eliminar la transferencia?')) return;
    this.documentoService.eliminarDocumento(id, 'TRANSFERENCIA').subscribe((res:RequestResponse) => {
      this.transferenciaService.eliminarTransferencia(id).subscribe((res:RequestResponse) => {
        this.alert.success_small('Se elimino el contrato');
        this.Traertransferencias();
      }, (error) => {
        this.alert.error_small('Hubo un error al eliminar la transferencia.');
        console.error(error);
      });
    }, (error) => {
      this.alert.error_small('Hubo un error al eliminar el documento.');
      console.error(error);
    });
  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
