<table class="example-full-width" cellspacing="0">
    <tr>
        <td colspan="2">
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Trabajador</mat-label>
                    <input type="text" required matInput disabled
                        [ngModel]="nombre | uppercase" name="nombre" />
                </mat-form-field>
            </div>
        </td>
    </tr>
    <tr>
        <td>
            <div class="col">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label> Fecha de Transferencia </mat-label>
                    <input (click)="picker.open()" required matInput [matDatepicker]="picker" (focus)="picker.open()"
                        [(ngModel)]="transferencia.fecha" name="picker_fecha_emision">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </td>
        <td>
            <div class="col">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Motivo</mat-label>
                    <mat-select (selectionChange)="ObtenerMotivo($event)" required>
                        <!-- <mat-option [value]="1">
                        Adelanto
                    </mat-option>
                    <mat-option [value]="2">
                        Pago Mensual
                    </mat-option>
                    <mat-option [value]="3">
                        Préstamo Empresarial
                    </mat-option> -->
                        <mat-option class="example-full-width " *ngFor="let motivo of motivos" [value]="motivo.id">
                            {{motivo.descripcion | uppercase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </td>
    </tr>

    <tr>
        <td>
            <!-- 
            <div class="ng-autocomplete" appearance="outline">

                <ng-autocomplete [data]="bancos" [searchKeyword]="keyword" placeholder="Buscar Banco" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.nombre"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>

            </div> -->
            <!-- <div style="width: 100%;">
                <mat-form-field appearance="outline" style="width: 50%;">
                    <mat-label>Motivo</mat-label>
                    <mat-select (selectionChange)="ObtenerMotivo($event)">
                        <mat-option [value]="1">
                            Adelanto
                        </mat-option>
                        <mat-option [value]="2">
                            Pago Mensual
                        </mat-option>
                        <mat-option [value]="3">
                            Préstamo Empresarial
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <p-dropdown [options]="bancos" (onChange)="ObtenerBanco($event)" [(ngModel)]="BancoSeleccionado" placeholder="Selecciona Banco" optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true" [style]="{'width':'50%'}">
                </p-dropdown>
            </div> -->
        </td>
    </tr>
    <tr>
        <td>
            <div class="col">
                <!-- <p-dropdown [options]="bancos" (onChange)="ObtenerBanco($event)" [(ngModel)]="BancoSeleccionado" placeholder="Selecciona Banco" 
                optionLabel="nombre" [filter]="true" filterBy="nombre" [showClear]="true" [style]="{'width':'50%'}">
                </p-dropdown> -->

                <!-- <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Banco</mat-label>
                    <mat-select (selectionChange)="ObtenerBanco($event)" required>
                        <mat-option class="example-full-width " *ngFor="let banco of bancos" [value]="banco.id">
                            {{banco.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Banco</mat-label>
                    <input type="text" required matInput disabled
                        [ngModel]="bancos" name="bancos" />
                </mat-form-field>
            </div>
        </td>
        <td>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Tipo Cuenta</mat-label>
                    <input type="text" required matInput disabled
                        [ngModel]="transferencia.tipo_cuenta | uppercase" name="tipo_cuenta" />
                </mat-form-field>
            </div>
        </td>
    </tr>
    <tr>
        <td>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Numero Cuenta</mat-label>
                    <input type="number" required matInput disabled
                        [ngModel]="transferencia.numero_cuenta" name="numero_cuenta" />
                </mat-form-field>
            </div>
        </td>
        <td>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Monto</mat-label>
                    <!-- <input (focus)="onFocusEvent($event)" type="number" min="1" required matInput -->
                    <input type="text" required matInput autonumeric [options]="optionsNumeric"
                        [(ngModel)]="transferencia.monto" name="monto" />
                </mat-form-field>
            </div>
        </td>
    </tr>
</table>

<div class="col">
    <p>
        <label></label>
        &nbsp;
        <!-- <input type="file" accept="image/*,.pdf,.doc,.docx" (change)='fileChangeEvent($event)' /> -->
        <input mat-input type="file" ng2FileSelect [uploader]="uploader" required (click)="removeFileFromQueue()" />
    </p>
    <div>
        <div class="progress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
    </div>
</div>


<!-- <p>
    <label>Subir transferencia </label> -->
<!-- <input type="file" accept="image/*,.pdf,.doc,.docx" (change)='fileChangeEvent($event)' /> -->
<!-- <input mat-input type="file" ng2FileSelect [uploader]="uploader" />
</p> -->

<!-- <div>
    Estado de Carga
    <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
    </div>
</div> -->
<!-- <p-fileUpload mode="basic" name="files" showUploadButton="false" (uploadHandler)="myUploader($event)" [customUpload]="true" chooseLabel="Subir Contrato"></p-fileUpload> -->


<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="OcultarModal()">
        <span aria-hidden="true">Cancelar</span>
    </button>
    <!-- <button type="button" (click)="Guardar()" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button> -->
    <button type="button" (click)="Guardar()" [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button>
</div>