import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgregarLicenciasComponent } from './agregar-licencias/agregar-licencias.component';
import { MainLicenciasComponent } from './main-licencias/main-licencias.component';
import { MaterialModule } from 'src/app/material.module';
import { FileUploadModule } from 'ng2-file-upload';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ListarLicenciasComponent } from './listar-licencias/listar-licencias.component';
import { AllLicenciasComponent } from './all-licencias/all-licencias.component';


import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { HelperModule } from 'src/app/helper.module';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';


@NgModule({
  declarations: [AgregarLicenciasComponent, MainLicenciasComponent, ListarLicenciasComponent, AllLicenciasComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    CalendarModule,
    SelectButtonModule,
    MatMomentDateModule,
    HelperModule,
    BuscarFilterModule,
  ],
  exports: [
    AgregarLicenciasComponent,
    ListarLicenciasComponent,
    MainLicenciasComponent,
    AllLicenciasComponent


  ],
 
  
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB',  } //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
                                                    // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 

  ]
})
export class LicenciasModule { }
