import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';

import { FormsModule } from '@angular/forms';
import { MainCiudadesComponent } from './main-ciudades/main-ciudades.component';
import { AgregarCiudadesComponent } from './agregar-ciudades/agregar-ciudades.component';
import { ListaCiudadesComponent } from './lista-ciudades/lista-ciudades.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { AutonumericModule } from '@angularfy/autonumeric';
@NgModule({
  declarations: [    
    MainCiudadesComponent,
    AgregarCiudadesComponent,
    ListaCiudadesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    MatDatepickerModule,
    MatSortModule,
    MatSortModule,
    AutonumericModule,
  ],
  exports:[
    MainCiudadesComponent,
    AgregarCiudadesComponent,
    ListaCiudadesComponent,
  ]
})
export class CiudadesModule { }
