
import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from "@fullcalendar/angular";
import esLocale from '@fullcalendar/core/locales/es';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service'
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import pdfMake from 'pdfmake/build/pdfmake';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-main-gestorasistencia',
  templateUrl: './main-gestorasistencia.component.html',
  styleUrls: ['./main-gestorasistencia.component.css'],
  providers: [DatePipe, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainGestorasistenciaComponent implements OnInit {
  [x: string]: any;

  constructor(

    private modalService: NgbModal,
    private asistenciaService: GestorAsistenciaService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,

  ) { }

  /////—————  Variables   ————-\\\\\\

  options: any;
  asistencias: any;
  conteo: any;
  data: any;
  cantidad: any;
  razon_update: any;
  list: any;
  atrazados: any;

  modalReference = null;
  /////————— Variable BD AND TABLA   ————-\\\\\\

  /////—————  Select   Calendar -\\\\\\

  eventsCalendar: any = [];
  calendarVisible = true;
  calendarOptions!: CalendarOptions;

  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\

  openn(content: any) {
    this.modalService.open(content, { size: 'lg' });


  }


  getCalendrio() {



    this.asistenciaService.ObtenerTodo().subscribe(
      (res) => {
        if (res.success) {
          //guardamos lo que traemes del res.data en un varible 
          this.asistencias = res.data;
          //funcion para el conteo 
          this.conteo = this.asistencias.length

          //recorremos la variable con un ford
          for (let i = 0; this.asistencias.length > i; i++) {
            //le damos formatos a las fechas
            this.asistencias[i].start = this.datePipe.transform(this.asistencias[i].start, 'yyyy-MM-dd');
            this.asistencias[i].end = this.datePipe.transform(this.asistencias[i].end, 'yyyy-MM-dd');
            //añadimos los eventos del fullCalendar
            this.eventsCalendar.push({
              //trabajador: '---'+ this.asistencias[i].fk_trabajador,
              start: this.asistencias[i].start,
              //Se le agrega un dia en horas, por defecto del fullCalendar 
              end: this.asistencias[i].end + "T23:59:00Z",
              title: this.asistencias[i].motivo,
              description: this.asistencias[i].Razon.razon,
              backgroundColor: this.asistencias[i].Razon.colorfondo,
              textColor: this.asistencias[i].Razon.colortexto
            });
          }
          // console.log( this.eventsCalendar); 
        }
      });

    //inicializamos el fullCaledar
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      events: this.eventsCalendar,
      locale: esLocale,
    };



  }


  temp: any[] = [];
  tempp: any[] = [];

  mes: any;
  fecha: any;

  dateObj = new Date();
  month = this.dateObj.getUTCMonth() + 1; //months from 1-12
  day = this.dateObj.getUTCDate();
  year = this.dateObj.getUTCFullYear();

  fechaP: any;


  alertasNoTramitadas() {


    this.fecha = new Date();



    this.fecha = this.datePipe.transform(this.fecha, 'M');






    this.asistenciaService.ObtenerTodo().subscribe(
      (res) => {
        if (res.success) {
          this.atrazados = res.data

          // console.log({...this.atrazados});

          for (let i = 0; this.atrazados.length > i; i++) {

            this.temp.push(this.atrazados[i].fk_trabajador)


            this.asistencias[i].start = this.datePipe.transform(this.asistencias[i].start, 'yyyy-MM-dd');


            this.fechaP = this.datePipe.transform(this.asistencias[i].start, 'M');

          }


          const resultado = [];
          this.temp.forEach(el => (resultado[el] = resultado[el] + 1 || 1))

          let valores = Object.keys(resultado);
          const datos = [];
          for (let i = 0; i < valores.length; i++) {
            let valor = valores[i];
            // console.log({valor})
            if (resultado[valor] >= 3 && this.fecha == this.fechaP) {
              const dato = this.atrazados.filter((x: any, z: any) => { return x.fk_trabajador == valor });
              datos.push(dato);
            }
          }
          // console.log({valores}, {datos}, {resultado});
          if (datos.length > 0) {
            this.messageService.add({ key: 'tc', severity: 'info', summary: 'Atrasos acomulados', detail: 'Existen ' + datos.length + ' atraso/s acomulado/s' });
          }
        }

      }



    );



  }

  fileName = 'Asistencias.xlsx';
  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }


  public convetToPDF() {

    var datos = [];
    datos.push([


      'NOMBRES',
      'FECHAINICIO',
      'FECHAFIN',
      'COLOR'

    ]);

    // console.log(this.asistencias);
    // this.ELEMENT_DATA = this.dataSource;
    // this.ELEMENT_DATA.map(function (item: any) {
    this.asistencias.map(function (item: any) {

      datos.push([
        // item.id,

        item.fk_trabajador,
        item.start,
        item.end,
        item.fk_razon

      ]);
    });


    var documentDefinition = {

      content: [

        { text: 'ASISTENCIAS', style: 'header', fontSize: 30 },
        {
          table: {

            body: datos

          }
        }
      ]
    };

    pdfMake.createPdf(documentDefinition).open();
  }


  /////—————AL INICIAR EL MODULO   ————-\\\\\\


  ngOnInit(): void {


    this.getCalendrio();

    this.primengConfig.ripple = true;
    //this.alerta.licenciasAlert();
    //.log("alerta?");
    setTimeout(() => {
      this.alertasNoTramitadas();
    }, 1000);



  }




}

