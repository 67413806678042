import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getLastDigitOfRut } from 'rutlib/lib';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { BancoService } from 'src/app/pages/services/banco/banco.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-modal-editar',
  templateUrl: './modal-editar.component.html',
  styleUrls: ['./modal-editar.component.css']
})
export class ModalEditarComponent implements OnInit {
  constructor(

    private alert: AlertHelper,
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private bancoService: BancoService,
    private auth: AuthService
  ) { }

  @Input() rut: any;
  trabajador: any;
  ciudades: any;
  sucursales: any;
  cargos: any;
  empresas: any;
  afps: any;
  previsiones: any;
  estados: any;
  nacionalidades: any;
  nacimiento: any;
  ciudad_seleccionada = 0;
  sucursal_seleccionada = 0;
  cargo_seleccionado = 0;
  empresa_contratante_seleccionada = 0;
  afp_seleccionada = 0;
  prevision_salud_seleccionada = 0;
  estado_civil_seleccionada = 0;
  nacionalidad_seleccionada = 0;
  fecha_nacimiento_seleccionada = 0;
  banco_seleccionado:any;
  tipo_cuenta_seleccionada:any;
  bancos: any;
  pase_movilidad: boolean;
  licencia_conducir: boolean;
  minDate: Date;
  maxDate: Date;
  disableValorIsapre: boolean = true;
  requiredAfp:boolean = true;
  requiredUfIsapre:boolean = true;
  requiredAhorroApv: boolean = true;
  estado_contrato = [
    { id: 1, valor: 'Contrato a Plazo Fijo' },
    { id: 2, valor: 'Contrato Indefinido' },
    { id: 3, valor: 'Contrato por Obra o Faena' }
  ]

  tipo_cuentas = [
    { id: 1, valor: 'Cuenta Corriente' },
    { id: 2, valor: 'Cuenta Vista/Rut' },
    { id: 3, valor: 'Cuenta de Ahorro' }
  ]

  validarFormulario(datos:any):boolean{
    let telefono  = parseInt(datos.telefono),
        telefono_contacto_emergencia = parseInt(datos.telefono_contacto_emergencia),
        numero_cuenta = parseInt(datos.numero_cuenta),
        cargas = parseInt(datos.numero_cargas);

    if(
      (datos.rut == undefined || datos.rut == "") ||
      (datos.nombres == undefined || datos.nombres == "") ||
      (datos.apellidos == undefined || datos.apellidos == "") ||
      (datos.telefono == undefined || !Number.isInteger(telefono) || telefono > 999999999) ||
      (datos.correo == undefined || datos.correo == "" || (datos.correo != undefined && datos.correo != '' && !this.auth.esEmailValido(datos.correo))) ||
      (datos.fecha_nacimiento == undefined || datos.fecha_nacimiento == "") ||
      (datos.nacionalidad == undefined || datos.nacionalidad == "") ||
      (datos.profesion == undefined || datos.profesion == "") ||
      (datos.estado_civil == undefined || datos.estado_civil == "") ||
      (datos.ciudad == undefined || datos.cuidad == "") ||
      (datos.direccion == undefined || datos.direccion == "") ||
      (datos.telefono_contacto_emergencia == undefined || !Number.isInteger(telefono_contacto_emergencia) || telefono_contacto_emergencia > 999999999) ||
      (datos.nombre_contacto_emergencia == undefined || datos.nombre_contacto_emergencia == "") ||
      (datos.estado_contrato == undefined || datos.estado_contrato == "") ||
      (datos.empresa_contratante == undefined || datos.empresa_contratante == "") ||
      (datos.jubilado != true && (datos.afp == undefined || datos.afp == "")) ||
      (datos.prevision == undefined || datos.prevision == "") ||
      (datos.prevision != 1 && (datos.pactado_isapre == undefined || datos.pactado_isapre > 10)) ||
      (datos.ahorro_apv == undefined) ||
      (datos.banco == undefined || datos.banco == "") ||
      (datos.tipo_cuenta == undefined || datos.tipo_cuenta == "") ||
      (datos.numero_cuenta == undefined || !Number.isInteger(numero_cuenta) || numero_cuenta > 999999999999999) ||
      (datos.fecha_ingreso == undefined || datos.fecha_ingreso == "") ||
      (datos.numero_cargas == undefined || !Number.isInteger(cargas) || cargas > 15)
    ){
      return false;
    }
    else{
      return true;
    }
  }
  ngOnInit(): void {
    //console.log("el rut que llego",this.rut)
    this.EditarDatos(this.rut)
  }



  Actualizar() {

    delete this.trabajador.Ciudad;
    delete this.trabajador.Afp;
    delete this.trabajador.Cargo;
    delete this.trabajador.Empresa_contratante;
    delete this.trabajador.Estado_civil;
    delete this.trabajador.Nacionalidad;
    delete this.trabajador.Prevision;
    delete this.trabajador.Sucursal;
    delete this.trabajador.Banco;

    if(this.validarFormulario(this.trabajador)){

      let rut = this.trabajador.rut.toString();
          rut = rut.slice(0, - 1);
      let dv = getLastDigitOfRut(rut);
      dv = (dv == 'K') ? '0' : dv ;
      this.trabajador.rut = rut + dv;
      //console.log(this.trabajador.rut)
      this.trabajadoresService.ActualizarTrabajador(this.trabajador).subscribe(res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
        } else {
          this.alert.error_small(res.msg!)
        }
      })
    }
    else{
      this.alert.errorAlert('Faltan datos o datos incorrectos, favor revisar');
    }
  }

  EditarDatos(rut: any) {

    this.trabajadoresService.TraerTrabajadoresRut(rut).subscribe(res => {
      //parseo para que no se indefina
      this.trabajador = res.data[0];
      let rut = this.trabajador.rut.toString();
          rut = rut.slice(0, - 1);
      let dv = getLastDigitOfRut(rut);
      this.trabajador.rut = rut + dv;

      this.requiredAfp = (this.trabajador.jubilado) ? false : true;
      this.requiredUfIsapre = (this.trabajador.Prevision.id == 1) ? false : true;
      this.disableValorIsapre = (this.trabajador.Prevision.id == 1) ? true : false;
      this.requiredAhorroApv = this.trabajador.ahorroApv;

      this.trabajadoresService.TraerCiudades().subscribe((res: any) => {

        this.ciudades = res.data;
        this.ciudad_seleccionada = this.trabajador.Ciudad.id;
      });

      this.trabajadoresService.TraerSucursales().subscribe((res: any) => {

        this.sucursales = res.data;
        this.sucursal_seleccionada = this.trabajador.Sucursal.id;
      });
      this.trabajadoresService.TraerCargos().subscribe((res: any) => {

        this.cargos = res.data;
        this.cargo_seleccionado = this.trabajador.Cargo.id;
      });
      this.trabajadoresService.TraerEmpresas().subscribe((res: any) => {

        this.empresas = res.data;
        this.empresa_contratante_seleccionada = this.trabajador.Empresa_contratante.id;
      });
      this.trabajadoresService.TraerAfp().subscribe((res: any) => {
        this.afps = res.data;
        if(this.trabajador.Afp != null){
          this.afp_seleccionada = this.trabajador.Afp.id;
        }
      });
      this.trabajadoresService.TraerPrevisiones().subscribe((res: any) => {

        this.previsiones = res.data;
        this.prevision_salud_seleccionada = this.trabajador.Prevision.id;
      });
      this.trabajadoresService.TraerEstadosCiviles().subscribe((res: any) => {

        this.estados = res.data;
        this.estado_civil_seleccionada = this.trabajador.Estado_civil.id;
      });
      this.trabajadoresService.TraerNacionalidades().subscribe((res: any) => {

        this.nacionalidades = res.data;
        this.nacionalidad_seleccionada = this.trabajador.Nacionalidad.id;
      });

      this.bancoService.TraerTodos().subscribe((res: any) => {
        this.bancos = res.data;
        this.banco_seleccionado = this.trabajador.Banco.id;
      });

      this.tipo_cuenta_seleccionada = this.trabajador.tipo_cuenta

    });
  }


  ObtenerEstadoContrato(event: MatSelectChange) {

    this.trabajador.estado_contrato = event.value;

  }

  ObtenerEstadoCivil(event: MatSelectChange) {

    this.trabajador.estado_civil = event.value;

  }

  ObtenerNacionalidad(event: MatSelectChange) {

    this.trabajador.nacionalidad = event.value;

  }

  onChangeSlidePase($event: any) {
    if ($event.checked) {
      this.trabajador.pase_movilidad = true
    }
    else {
      this.trabajador.pase_movilidad = false
    }
  }

  onChangeSlideLicencia($event: any) {
    if ($event.checked) {
      this.trabajador.licencia_conducir = true
    }
    else {
      this.trabajador.licencia_conducir = false
    }
  }

  onChangeSlideJubilado($event: any){
    if ($event.checked) {
      this.trabajador.jubilado = true;
      this.requiredAfp = false;
    }
    else {
      this.trabajador.jubilado = false;
      this.requiredAfp = true;
    }
  }
  ObtenerBanco(event: MatSelectChange) {
    this.trabajador.banco = event.value;
  }

  ObtenerTipoCuenta(event: MatSelectChange) {
    this.trabajador.tipo_cuenta = event.value;
  }
  ObtenerSucursal(event: MatSelectChange) {

    this.trabajador.sucursal = event.value;

  }
  ObtenerCiudad(event: MatSelectChange) {

    this.trabajador.ciudad = event.value;

  }
  ObtenerCargo(event: MatSelectChange) {

    this.trabajador.cargo = event.value;

  }
  ObtenerEmpresaContratante(event: MatSelectChange) {

    this.trabajador.empresa_contratante = event.value;

  }
  ObtenerAfp(event: MatSelectChange) {

    this.trabajador.afp = event.value;

  }
  ObtenerPrevision(event: MatSelectChange) {

    this.trabajador.prevision = event.value;

    this.trabajador.pactado_isapre = undefined;
    if(event.value == 1){
      this.disableValorIsapre = true;
      this.requiredUfIsapre = false;
    }
    else{
      this.disableValorIsapre = false;
      this.requiredUfIsapre = true;
    }

  }

}
