import { Injectable } from '@angular/core';
import { parseISO } from 'date-fns';
import { Feriado } from 'src/app/shared/models/feriado.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeriadosService {
  private feriados: Feriado[] = [];
  private URL_API = 'https://public-holiday.p.rapidapi.com';
  private options = {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': environment.API_KEY,
      'X-RapidAPI-Host': 'public-holiday.p.rapidapi.com',
      'Content-Type': 'application/json',
    },
  }

  constructor() { }

  async cargarFeriados(year: string): Promise<void>{
    if (
      this.feriados.length === 0 ||
      this.feriados[0].date.substring(0, 4) !== year
    ) {
      try {
        const FETCH_URL = `${this.URL_API}/${year}/CL`
        const response = await fetch(`${FETCH_URL}`, this.options);
        const data = await response.json();
        this.feriados.push(...data);
        // TRAER SOLO LOS FERIADOS COMUNES O NACIONALES, NO REGIONALES
        this.feriados = this.feriados.filter(x => !x.counties || x.counties?.length === 0)
      } catch (error) {
        console.error(error);
      }
    }
  }
  getFeriados(): Feriado[] {
    return this.feriados;
  }
  esFeriado(fecha: Date): boolean {
    return this.feriados.some(feriado => parseISO(feriado.date).getTime() === fecha.getTime());
  }
}
