<app-banner></app-banner>
<mat-sidenav-container>
    <mat-sidenav #sidenav mode="push"
      style="transform: none;visibility: visible;width: 350px;">
        <mat-toolbar class="toolbar_header">
            <span style="cursor: pointer; color: white" routerLink="/">Menú </span>
        </mat-toolbar>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Modulo</mat-label>
            <mat-select  [(value)]="menuChoosen" >   <!--  (selectionChange)="printpdf($event)" -->
              <mat-option *ngFor="let menu of menus " [value]="menu.id">
                {{ menu.valor }}
              </mat-option>
            </mat-select>
          </mat-form-field> 
         <app-sidebar [menuCoosen]="menuChoosen" [sidenav]="sidenav"></app-sidebar> 
    </mat-sidenav>
    <mat-sidenav-content style="position: relative">
        <app-header (toggleSidenav)="sidenav.toggle()"></app-header>
        <div class="content-container">
          <router-outlet></router-outlet>
        </div>
        <app-footer style="position: absolute;bottom: 0;left: 0;right: 0;"></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>