import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VacacionesService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

  TraerTodo():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/vacaciones/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerVacacion(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/vacaciones/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  EnviarVacacion(vacaciones:any) {
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/vacaciones/crear`,vacaciones)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/vacaciones/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerPorRutAsync(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/vacaciones/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({ success: false, msg: 'Hubo un error al obtener las vacaciones' }as RequestResponse)
    }
  }


  EnviarArchivo(archivo:any){
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/vacaciones/documento`,archivo)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerArchivo(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarvacacionid/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  eliminarContrato(id:number):Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/vacaciones/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError(error => this.handlerError(error)),
      )
  }

  eliminarVacacion(id:number):Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/vacaciones/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError(error => this.handlerError(error)),
      )
  }
  async eliminarVacacionAsync(id: number): Promise<RequestResponse> {
    try {
      return await this.http.delete<RequestResponse>(`${environment.API}/rrhh/vacaciones/eliminar/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener las vacaciones.' } as RequestResponse;
    }
  }

  TraerPorIdSolicitudVacacion(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/vacaciones/bySolicitud/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    // this.alert.error_small(err.error.msg);
    console.error(err);
    return throwError(errorMessage);
  }


}
