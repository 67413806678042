import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { Documento, RequestResponseGetAllDocumento, RequestResponseActualizar } from 'src/app/shared/models/documentos/documentos.interface';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import Swal from 'sweetalert2';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  constructor(private http: HttpClient, private auth: AuthService,) { }


  GetAll_documentos(id: number, mes: number, ano: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscartodo/${id}/${mes}/${ano}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }

  GetAll_adelanto(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscaradelanto/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }

  GetAll_contrato(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarcontrato/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }
  async getContrato(id: number): Promise<RequestResponseGetAllDocumento> {
    try {
      return await this.http.get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarcontratosrut/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener los documentos.' } as RequestResponseGetAllDocumento;
    }
  }

  GetAll_liquidaciones(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarliquidaciones/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }
  GetAll_prestamo(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarprestamo/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }
  GetAll_finiquito(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarfiniquito/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }
  GetAll_transferencias(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscartransferencia/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }
  GetAll_Cartas(id: number): Observable<RequestResponseGetAllDocumento> {
    return this.http
      .get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarcartas/${id}`)
      .pipe(
        map((res: RequestResponseGetAllDocumento) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }
  async getCarta(id: number): Promise<RequestResponseGetAllDocumento> {
    try {
      return await this.http.get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarcartasporid/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener los documentos de las cartas.' } as RequestResponseGetAllDocumento;
    }
  }
  async getFiniquito(id: number): Promise<RequestResponseGetAllDocumento> {
    try {
      return await this.http.get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarfiniquitosid/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener los documentos del finiquito.' } as RequestResponseGetAllDocumento;
    }
  }
  async getLicencia(id: number): Promise<RequestResponseGetAllDocumento> {
    try {
      return await this.http.get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarlicenciaid/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener los documentos de licencia.' } as RequestResponseGetAllDocumento;
    }
  }
  async getPermiso(id: number): Promise<RequestResponseGetAllDocumento>{
    try {
      return await this.http.get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarPermisosId/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un al obtener los archivos del permisos.' } as RequestResponseGetAllDocumento;
    }
  }

  TraerPermiso(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarPermisosId/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  
  async getVacacion(id: number): Promise<RequestResponseGetAllDocumento> {
    try {
      return await this.http.get<RequestResponseGetAllDocumento>(`${environment.API}/rrhh/documento/buscarvacacionid/${id}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener los archivos de la vacación.' } as RequestResponseGetAllDocumento;
    }
  }



  GetAll_Implementos(id: number): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/documento/buscarImplementos/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }


  GetAll_ImplementosU(id: number): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/documento/buscarImplementosU/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res;
        }),
        catchError((err) => this.handlerError(err))
      );
  }

  eliminarDocumento(id:number, tipo: string): Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/documento/eliminar/${id}/${tipo}`)
      .pipe(
        map((res: RequestResponse) => {
          return res;
        }),
        catchError((error) => this.handlerError(error))
      );
  }
  async eliminarDocumentoAsync(id:number, tipo: string): Promise<RequestResponse> {
    try {
      const user = this.auth.ObtenerinfoToken();
      return await this.http.delete<RequestResponse>(`${environment.API}/rrhh/documento/eliminar/${id}/${user.id}/${tipo}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al eliminar los registros.' } as RequestResponse;
    }
  }

  private handlerError(err: any): Observable<never> {
    // console.log("EJECUCION DE CODIGO EN CASO DE ERROR O NO ENCONTRAR DATOS");
    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    // this.alert.error_small(err.error.msg);

    Swal.fire({
      icon: 'error',
      title: 'NO SE ENCONRARON REGISTROS ',

    })
    return throwError(errorMessage);
  }
}
