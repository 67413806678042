import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FoliadosService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }


  Vacaciones():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/foliados/vacaciones/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  Herramientas():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/foliados/herramientas/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Contratos():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/foliados/contratos/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Vestuarios():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/foliados/vestuarios/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Prestamos():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/foliados/prestamos/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  Liquidaciones():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/foliados/liquidaciones/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async LiquidacionAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/foliados/liquidaciones/`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener el folio de liquidaciones.'} as RequestResponse)
    }
  }

  getLastFolio():Observable<RequestResponse>{
    return this.http 
      .get<RequestResponse>(`${environment.API}/rrhh/foliados/liquidaciones/getLastFolio`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError(error => this.handlerError(error))
      );
  }
  async getLastFolioAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/foliados/liquidaciones/getLastFolio`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener el ultimo folio.'} as RequestResponse)
    }
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }




}
