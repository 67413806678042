import { TrabajadoresService } from './../../../services/trabajadores/trabajadores.service';
import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { HelperService } from '../../../services/helper/helper.service'
//import {TrabajadoresService} from 'src/app/pages/services/trabajadores/trabajadores.service';

interface Trabajadores {
  id: string;
  valor: string;
}

interface Anios {
  id: number,
  valor: number;

}
@Component({
  selector: 'app-main-historial',
  templateUrl: './main-historial.component.html',
  styleUrls: ['./main-historial.component.css']
})
export class MainHistorialComponent implements OnInit {
  constructor(
    public helper: HelperService,
    public trabajadoresService: TrabajadoresService
  ) { }
  actual: number = new Date().getFullYear();
  anio: Anios[] = [];
  render = 0;
  public dato: any = 0;
  public mes: any = 0;
  public ano: any = 0;
  trabajadorid: number = 0;
  mesid: number = 0;
  anoid: number = 0;
  trabajadores: any

  selectedValue(event: MatSelectChange) {
    this.render = 0;
    this.dato = event;

    this.trabajadorid = this.dato.value;

  }
  selectedMes(event: MatSelectChange) {
    this.render = 0;
    this.mes = event;
    this.mesid = this.mes.value;

  }

  selectedano(event: MatSelectChange) {
    this.render = 0;
    this.ano = event;
    this.anoid = this.ano.source.triggerValue;

  }

  enviar() {
    this.render = 1

    if (this.mesid == 0) {
      this.mesid = 13;
    }

  }
  TraerTrabajadores() {
    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        this.trabajadores = res.data;
        //console.log(this.trabajadores);
      }
    );
  }

  // trabajadores:Trabajadores[]=[
  // {id:'1', valor:'Felipe Diaz'},
  // {id:'2', valor:'Jhon ortiz'},
  // {id:'60', valor:'claudio'}

  // ];


  meses = [
    { id: 0, valor: 'CUALQUIERA' },
    { id: 1, valor: 'enero' },
    { id: 2, valor: 'febrero' },
    { id: 3, valor: 'marzo' },
    { id: 4, valor: 'abril' },
    { id: 5, valor: 'mayo' },
    { id: 6, valor: 'junio' },
    { id: 7, valor: 'julio' },
    { id: 8, valor: 'agosto' },
    { id: 9, valor: 'septiembre' },
    { id: 10, valor: 'octubre' },
    { id: 11, valor: 'noviembre' },
    { id: 12, valor: 'diciembre' },
  ];



  ngOnInit(): void {

    for (let i = this.actual; i >= 2020; i--) {
      this.anio.push({ id: this.anio.length, valor: i });
    }
    this.TraerTrabajadores();
  }

 

}
