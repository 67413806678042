<div class="right_col animate__animated animate__fadeInRight" role="main">
    <div class="col-md-12 col-sm-12  ">

        <div class="x_panel">

            <mat-card>
                <h3 class="text-center">HISTORIAL</h3>
            </mat-card>
            <div class="x_title">

                <div class="clearfix"></div>
            </div>
            <mat-divider></mat-divider>


            <mat-card class="mat-elevation-z5 ">

                <div class="x_content">
                    <div class="row">
                        <div class="col-md-3" style="margin-bottom:30px;">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>TRABAJADOR</mat-label>
                                <mat-select (selectionChange)="selectedValue($event)">
                                    <mat-option class="example-full-width" *ngFor="let trabajador of trabajadores" [value]="trabajador.rut">
                                        {{trabajador.nombres}} {{trabajador.apellidos}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" style="margin-bottom:30px;">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>MES</mat-label>
                                <mat-select (selectionChange)="selectedMes($event)">
                                    <mat-option class="example-full-width" *ngFor="let mes of meses" [value]="mes.id">
                                        {{mes.valor}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3" style="margin-bottom:30px;">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>AÑO</mat-label>
                                <mat-select (selectionChange)="selectedano($event)">
                                    <mat-option class="example-full-width" *ngFor="let an of anio" [value]="an.id">
                                        {{an.valor}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="example-button-row">
                            <button mat-fab aria-label="filter_alt" (click)="enviar()">
                              <mat-icon>filter_list</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <mat-tab-group dynamicHeight>
                    <mat-tab label="Cronológico">

                        <div *ngIf="render==1">

                            <app-lista-historial [fk_trabajador]=trabajadorid [mes2]=mesid [ano2]=anoid></app-lista-historial>

                        </div>

                    </mat-tab>
                    <mat-tab label="Contratos">

                        <div *ngIf="render==1">
                            <app-lista-contrato [fk_trabajador]=trabajadorid></app-lista-contrato>
                        </div>
                    </mat-tab>
                    <mat-tab label="Transferencias">
                        <div *ngIf="render==1">
                            <app-lista-transferencias [fk_trabajador]=trabajadorid></app-lista-transferencias>
                        </div>
                    </mat-tab>
                    <mat-tab label="Liquidaciones">
                        <div *ngIf="render==1">
                            <app-lista-liquidaciones [fk_trabajador]=trabajadorid></app-lista-liquidaciones>
                        </div>
                    </mat-tab>
                    <mat-tab label="Préstamos">
                        <div *ngIf="render==1">
                            <app-lista-prestamo [fk_trabajador]=trabajadorid></app-lista-prestamo>
                        </div>
                    </mat-tab>
                    <mat-tab label="Cartas de amonestación">
                        <div *ngIf="render==1">
                            <app-lista-cartas [fk_trabajador]=trabajadorid></app-lista-cartas>
                        </div>
                    </mat-tab>
                    <mat-tab label="Finiquitos">
                        <div *ngIf="render==1">
                            <app-lista-finiquitos [fk_trabajador]=trabajadorid></app-lista-finiquitos>
                        </div>
                    </mat-tab>
                    <mat-tab label="Implementos">
                        <div *ngIf="render==1">
                         <app-lista-implementoss [fk_trabajador]=trabajadorid></app-lista-implementoss>
                        </div>
                    </mat-tab>
                     <mat-tab label="Uniformes">
                        <div *ngIf="render==1">
                            <app-lista-implementossU [fk_trabajador]=trabajadorid></app-lista-implementossU>
                        </div>
                    </mat-tab> 
                </mat-tab-group>
            </mat-card>


        </div>
    </div>