import { JornadaLaboral } from '../../../../shared/models/jornada-laboral.interface';
import { JornadaLaboralService } from 'src/app/pages/services/jornada-laboral.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-agregar-jornada-laboral',
  templateUrl: './agregar-jornada-laboral.component.html',
  styleUrls: ['./agregar-jornada-laboral.component.css']
})
export class AgregarJornadaLaboralComponent implements OnInit {
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();
  @Input() jornadas_List: JornadaLaboral[] = [];
  dataSource = new MatTableDataSource;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  jornada: JornadaLaboral = {
    descripcion:""
  }
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private JornadaLaboralService: JornadaLaboralService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
  }

  open(content:any){
    this.jornada.descripcion="";
    this.modalService.open(content);
  }

  agregar(){
    if(this.jornada.descripcion.trim().length==0){
      this.alert.errorAlert('La descripción no puede estar vacía')
    }
    else {  
      this.JornadaLaboralService.CreateJornada(this.jornada).subscribe((res:any)=>{
        if (res.success==true) {
          this.alert.success_small(res.msg!)
          this.jornada.descripcion="";
          this.modalService.dismissAll();
          this.actualizarTabla();
          this.debouncer.next( true );
        }else{
          //this.alert.error_small(res.msg!)
          this.alert.error_small('Error, no es posible crear la jornada laboral');
        }
      })
    }
  }

  actualizarTabla(){
    this.JornadaLaboralService.GetAll_jornadas().subscribe(
      (res) => {
        this.jornadas_List = res.data;
        this.dataSource = new MatTableDataSource<JornadaLaboral>(this.jornadas_List);
      }
    );
  }

}
