import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainMotivosComponent } from './main-motivos/main-motivos.component';
import { MaterialModule } from 'src/app/material.module';
import { AgregarMotivoComponent } from './agregar-motivo/agregar-motivo.component';
import { ListarMotivosComponent } from './listar-motivos/listar-motivos.component';
import { EditarMotivoComponent } from './editar-motivo/editar-motivo.component';

@NgModule({
  declarations: [
    MainMotivosComponent,
    AgregarMotivoComponent,
    ListarMotivosComponent,
    EditarMotivoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ]
})
export class MotivosCartaAmonestacionModule { }
