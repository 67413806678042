import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CartasAmonestacionService } from './../../../../services/cartas-amonestacion/cartas-amonestacion.service';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
@Component({
  selector: 'app-modal-mostrar',
  templateUrl: './modal-mostrar.component.html',
  styleUrls: ['./modal-mostrar.component.css'],
  
})
export class ModalMostrarComponent implements OnInit {
  @Input() rut:any
  constructor(
    private cartaservice: CartasAmonestacionService,
    private documentoService: DocumentosService,
    private solicitudDocumentoService: SolicitudesDocumentosService,
    private alert: AlertHelper,
  ) { }

  loading: boolean = false;
  displayedColumns = [
    'rut',
    'fecha',
    'grado',
    'motivo',
    'acciones'
  ];
  dataSource:any;
  cartas:any;
  info_documento:any;
  ngOnInit(): void {
    this.TraerCartas();
    
  }
  Descargar(id :any){
    this.cartaservice.TraerArchivo(id).subscribe(
      (res)=>{
        this.info_documento =res.data[0];
        window.open(`${environment.API}/cartas/`+res.data[0].ruta);
      }
      
    );
  }

  async TraerCartas(){
    const resCarta = await this.cartaservice.TraerPorRutAsync(this.rut);
    this.cartas = resCarta.data;
    const cartaSoure = [];
    for (const carta of this.cartas) {
      console.log({carta});
      if (carta.Grado == 1) carta.Grado = "Menor";
      if (carta.Grado == 2) carta.Grado = "Medio";
      if (carta.Grado == 3) carta.Grado = "Grave";
      const resDoc = await this.documentoService.getCarta(carta.id);
      if (resDoc.success) {
        const documento = resDoc.data;
        const resSol = await this.solicitudDocumentoService.TraerPorDocumento(documento[0].cp_documento);
        if (resSol) {
          const solicitud = resSol.data;
          carta.pendienteSolicitud = solicitud;
        }else{
          carta.pendienteSolicitud = false;
        }
      }else{
        carta.pendienteSolicitud = false;
      }
      cartaSoure.push(carta);
    }
    this.dataSource = cartaSoure;
  }
  async Borrar(id:number) {
    this.loading = true;
    if (confirm('¿Seguro que desea eliminar la amonestación?')){
      const res = await this.documentoService.eliminarDocumentoAsync(id, 'AMONESTACION');
      if (res.success) {
        this.alert.success_small('Se creo la solicitud para eliminar el documento.');
        await this.TraerCartas();
      }else{
        this.alert.error_small(res.msg);
      }
    };
    this.loading = false;
    // this.documentoService.eliminarDocumento(id, 'AMONESTACION').subscribe(() => {
    //   this.cartaService.eliminarCarta(id).subscribe(() => {
    //     this.alert.success_small('Se elimino la amonestación');
    //     this.TraerCartas();
    //   }, (error) => {
    //     this.alert.error_small('Hubo un error al eliminar la amonestación.');
    //     console.error(error);
    //   });
    // }, (error) => {
    //   this.alert.error_small('Hubo un error al eliminar el documento.');
    //   console.error(error);
    // });
  }
}
