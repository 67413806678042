import { Component, OnInit } from '@angular/core';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';

@Component({
  selector: 'app-main-generar-contrato',
  templateUrl: './main-generar-contrato.component.html',
  styleUrls: ['./main-generar-contrato.component.css']
})
export class MainGenerarContratoComponent implements OnInit {
  rut:any=undefined;
  constructor() { }

  ngOnInit(): void {
  }

  RecibirEvento($event) {
    this.rut=$event
    // console.log(this.rut)
  }

}
