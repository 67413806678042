import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MaterialModule } from 'src/app/material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatCardModule } from '@angular/material/card'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatSortModule } from '@angular/material/sort'
import { AgregarHorasExtrasComponent } from './agregar-horas-extras/agregar-horas-extras.component'
import { MainHorasExtrasComponent } from './main-horas-extras/main-horas-extras.component'
import { ListaHorasExtrasComponent } from './lista-horas-extras/lista-horas-extras.component'
import { HelperModule } from "src/app/helper.module"
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { AutonumericModule } from "@angularfy/autonumeric"

@NgModule({
    declarations: [
        AgregarHorasExtrasComponent,
        MainHorasExtrasComponent,
        ListaHorasExtrasComponent
    ],

    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatSortModule,
        HelperModule,
        PrimeNgModule,
        AutonumericModule.forRoot(),
    ],

    exports: [
        AgregarHorasExtrasComponent,
        MainHorasExtrasComponent,
        ListaHorasExtrasComponent
    ]
})
export class horasExtrasModule { }