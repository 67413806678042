import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import { Requisito, RequestResponseGetAllRequisito, RequestResponseActualizar } from 'src/app/shared/models/requisitos.interface';


@Injectable({
  providedIn: 'root'
})
export class RequisitoService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }



  GetAll_requisitos(): Observable<RequestResponseGetAllRequisito> {
    return this.http
    .get<RequestResponseGetAllRequisito >(`${environment.API}/rrhh/requisito/`)
    .pipe(
      map((res:RequestResponseGetAllRequisito) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  GetAll_requisitoPorCargo(id:number): Observable<RequestResponse> {
    
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/requisito/cargo/${id}`)
    .pipe(
      map((res:RequestResponse) =>{

        return res;
      }),
      catchError((err)=>(
       
        this.handlerError(err))) 
    );
  }
  
  GetAll_funcionesPorCargo(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/requisito/funciones/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=>(
        this.handlerError(err))) 
    );
  }

  GetAll_requisitoCargo(): Observable<RequestResponseGetAllRequisito> {
    return this.http
    .get<RequestResponseGetAllRequisito >(`${environment.API}/rrhh/requisito/`)
    .pipe(
      map((res:RequestResponseGetAllRequisito) =>{          
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  Create_requisito(requisito:Requisito): Observable<RequestResponse> {                   
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/requisito/crear`,requisito)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Obtener_requisito(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/requisito/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Eliminar_requisito(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/requisito/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Actualizar_requisito(requisito:any): Observable<RequestResponseActualizar>  {
    return this.http
    .put<RequestResponseActualizar >(`${environment.API}/rrhh/requisito/actualizar/${requisito.id}`,requisito)
    .pipe(
      map((res:RequestResponseActualizar) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

}




