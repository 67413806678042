<h1 mat-dialog-title class="dialog-title">Subir Documento</h1>
<div mat-dialog-content class="dialog-content">
  <form class="dialog-form">
    <mat-form-field appearance="fill" class="form-field">
      <label>DESCRIPCIÓN: &nbsp;</label>
      <input mat-input [(ngModel)]="description" name="description" />
    </mat-form-field>
    <div class="file-input-container">
      <input type="file" (change)="onFileSelected($event)" class="file-input" />
    </div>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onNoClick()" class="action-button">Cancelar</button>
  <button mat-button (click)="upload()" class="action-button">Subir</button>
</div>
