import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { HonorarioService } from 'src/app/pages/services/honorarios/honorario.service';

@Component({
  selector: 'app-agregar-liquidaciones',
  templateUrl: './agregar-liquidaciones.component.html',
  styleUrls: ['./agregar-liquidaciones.component.css'],
  providers: [DatePipe]
})
export class AgregarLiquidacionesComponent implements OnInit {

  tipoDoc = [
    {value: 0, text: 'Liquidación'},
    {value: 1, text: 'Honorario'},
  ]

  selectedTipoDoc: any = 0;
  fecha: Date;
  alcanceLiq: any;


  @Input() rut:any;
  @Output() nombreTrabajador = new EventEmitter<string>();
  responses: string[] = [];
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  liq_duplicado: boolean = false;
  labelFileInput: string = 'Ningun archivo seleccionado';

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  liquidacion : any = {
    fecha : undefined,
    haberes: 0,
    descuentos: 0,
    alcance_liquido: 0,
  };
  liquidaciones:any[] = [];
  
  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/liquidacion/documento`,
    itemAlias: 'liquidacion',

  });

  public uploader2: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/honorario/documento`,
    itemAlias: 'honorario',
    authToken: 'Bearer ' + this.auth.getToken(),

  });

  ngOnInit(): void {
    this.trabajadorService.TraerTrabajadoresRut(this.rut)
      .subscribe(({data}) => {
        const trabajador = data[0];
        // console.log(data.trabajador);
        const nombre = trabajador.nombres + ' ' + trabajador.apellidos;
        this.nombreTrabajador.emit(nombre);
      })
    this.liquidacionService.TraerPorRut(this.rut).subscribe(res => {
      if (res.success) {
        this.liquidaciones = res.data;
      }else{
        this.alert.error_small('Hubo un error al obtener las liquidaciones.');
        console.error(res.msg);
      }
    })
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
       this.alert.success_small('Exito.\n'+result.msg);
      } else {
        this.alert.errorAlert('Hubo un error al subir la liquidación');
      }
    }

    this.uploader2.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
       this.alert.success_small('Exito.\n'+result.msg);
      } else {
        this.alert.errorAlert('Hubo un error al subir el honorario');
      }
    }
  }

  constructor(
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public sanitizer:DomSanitizer,
    private auth: AuthService,
    private alert: AlertHelper,
    private trabajadorService:TrabajadoresService,
    private liquidacionService: LiquidacionService,
    private honorarioService: HonorarioService
  ) 
  {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader2.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        let user= this.auth.ObtenerinfoToken();
        form.append('fecha', this.liquidacion.fecha);
        form.append('totalHaberes', this.liquidacion.haberes);
        form.append('totalDescuentos', this.liquidacion.descuentos);
        form.append('alcanceLiquido', this.liquidacion.alcance_liquido);
        form.append('fk_trabajador', this.rut);
        form.append('userAt', user.nombre);
    };
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  verificarPeriodo(){
    this.liquidacion.fecha = this.datePipe.transform(this.liquidacion.fecha, 'yyyy-MM-dd', 'es-CL');
    if (this.liquidaciones.some(x => x.fecha === this.liquidacion.fecha)) {
        alert('Existe otra liquidación con el mismo periodo.');
        this.liq_duplicado = true;
    }else{
      this.liq_duplicado = false;
    }
  }

  guardar() {
    if (this.validar()) {
      Swal.fire(
        { title: 'Espere', text: 'Subiendo liquidación...', icon: 'info', allowOutsideClick: false }
      )
      Swal.showLoading();

      this.uploader.uploadAll();

      this.uploader.onSuccessItem = () => {
        Swal.close();
        this.modalService.dismissAll();
      }

      this.uploader.onErrorItem = (item, response) => {
        Swal.close();
        this.alert.errorAlert('Hubo un error al subir el documento');
        console.error(response);
      }
    }
  }

  guardarHonorario() {

        Swal.fire(
          { title: 'Espere', text: 'Subiendo honorario...', icon: 'info', allowOutsideClick: false }
        )
        Swal.showLoading();
    
        const formData = new FormData();
        
        formData.append('fecha', this.fecha.toString());
        formData.append('alcanceLiquido', this.alcanceLiq);
        formData.append('fk_trabajador', this.rut);
        formData.append('estado', '0');
        formData.append('honorario1', this.uploader2.queue[0]._file);
        formData.append('honorario2', this.uploader2.queue[1]._file);
        
        this.honorarioService.guardarHonorario(formData).subscribe(
          (res: any) => {
            if (res.response === true) {
              this.alert.success_small('Honorario guardado correctamente');
              this.modalService.dismissAll();
            } else {
              this.alert.errorAlert('Hubo un error al subir el documento');
              Swal.close();
            }
          }
        ); 
  }

  cerrarModal(){
    this.modalService.dismissAll(); 
  }

  validar(): boolean {
    if (
      this.liquidacion.haberes &&
      this.liquidacion.alcance_liquido &&
      this.liquidacion.descuentos &&
      this.liquidacion.fecha
    ) {
      // this.liquidacion.fecha = this.datePipe.transform(this.liquidacion.fecha, 'yyyy-MM-dd', 'es-CL');
      // if (this.liquidaciones.some(x => x.fecha === this.liquidacion.fecha)) {
      //   return confirm('Existe otra liqudacion con el mismo periodo.\n¿Desea guardarla de todas maneras?');
      // }
      return true;
    }
    Swal.fire({
      icon: 'error',
      title: 'Faltan datos',
      text: 'Faltan datos , favor revisar',

    })
    return false;
  }

  calcularAlcanceLiquido() {
    if (this.liquidacion.haberes > 0 && this.liquidacion.descuentos > 0) {
      const alcanceLiquido = this.liquidacion.haberes - this.liquidacion.descuentos;
      this.liquidacion.alcance_liquido = alcanceLiquido >= 0 ? alcanceLiquido : 0;
    }
  }
}
