
    





 <!--END Modal Header-->
    <!-- Modal Body-->
    <div class="modal-body">

        <table class="example-full-width" cellspacing="0">
            <tr>
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label style="text-align:center">DESDE - HASTA </mat-label>
                    <mat-date-range-input [rangePicker]="picker2" name="rango_pick">
                        <input (click)="picker2.open()" matStartDate placeholder="FECHA INICIO" #dateRangeStart>
                        <input (click)="picker2.open()" matEndDate placeholder="FECHA FIN" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-date-range-picker touchUi #picker2></mat-date-range-picker>
            
                    <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
                </mat-form-field>
            </tr>


        </table>

        <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Motivo</mat-label>
                        <input type="text"  required [(ngModel)]= "licencia.motivo"  matInput #input name ="motivo">
                      </mat-form-field>
                </td>
 
 
            </tr>

        </table>

        <p>
            <label>Subir Licencia  &nbsp;</label>
            <input mat-input  required type="file" ng2FileSelect [uploader]="uploader"  />
        </p>

        <div>
            Estado de Carga
            <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
        </div>


    </div>
    <!--END Modal Body-->
    <!-- Modal Footer-->
    <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()"> <span aria-hidden="true">Cancelar</span> </button>       
    <button type="button" (click)="guardar()"  [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button>
        
    </div>