<h3>{{ titulo}}</h3>

<mat-divider></mat-divider>

<div>
  <button style="background-color: rgb(207, 51, 3);" (click)="convetToPDF()"  mat-raised-button>Exportar a PDF <mat-icon>picture_as_pdf</mat-icon></button>
  <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button color="green">Exportar a Excel  <mat-icon>toc</mat-icon></button>
</div>

<div class="mat-elevation-z8">    
    <table mat-table [dataSource]="dataSource" matSort  id="perfilCargo">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
        <td mat-cell *matCellDef="let element">{{ element.requisitos.id }}</td>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
        <td mat-cell *matCellDef="let element">{{ element.requisitos.descripcion }}</td>
      </ng-container>

      <ng-container matColumnDef="blank">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"></td>{{""}}
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>