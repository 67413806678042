  <div class="animate__animated animate__fadeInRight">
    <br />
  
    <div class="col">
      <mat-card class="mat_card_general">
        <h1 style="text-align:center;font-size:1.77rem;font-weight:500">Marcacion de asistencia {{day}}/{{month}}/{{year}}</h1>
        <br  />
        <div class="row">
            <div class="col" *ngIf="!entrada">
                 <div class="p-grid p-formgrid p-text-center">
                     <div class="p-field p-col-12 p-md-4">
                      <p-knob [(ngModel)]="clock" [size]="200" [disabled]="true"></p-knob>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<button mat-flat-button color="primary"   (click)="marcarEntrada()"
                      >Marcar Entrada</button>
                     </div>
                </div>
            </div>
            <div class="col" *ngIf="entrada">
                <div class="p-grid p-formgrid p-text-center">
                    <div class="p-field p-col-12 p-md-4">
                     <p-knob [(ngModel)]="entrada" [size]="200" [disabled]="true"></p-knob>
                     &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<button mat-flat-button color="primary" [disabled]="true" 
                     >Marcar Entrada</button>
                    </div>
               </div>
           </div>

            <div class="col">
          <div class="p-grid p-formgrid p-text-center">
            <div class="p-field p-col-12 p-md-4">
              <p-knob [(ngModel)]="clock" [size]="200" [disabled]="true"></p-knob>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<button mat-flat-button color="red" [disabled]="true">Tiempo Trabajado</button>
            </div>
          </div>
        </div>

        <div class="col" *ngIf="!salida">
            <div class="p-grid p-formgrid p-text-center">
              <div class="p-field p-col-12 p-md-4">
                <p-knob [(ngModel)]="clock" [size]="200" [disabled]="true"></p-knob>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-flat-button color="primary" (click)="marcarSalida()"  >Marcar Salida</button>
              </div>
              
            </div>
          </div>

          <div class="col" *ngIf="salida">
            <div class="p-grid p-formgrid p-text-center">
              <div class="p-field p-col-12 p-md-4">
                <p-knob [(ngModel)]="salida" [size]="200" [disabled]="true"></p-knob>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button mat-flat-button color="primary" [disabled]="true" >Marcar Salida</button>
              </div>
            </div>
          </div>

        </div>

      </mat-card>
    </div>
  
    <br /> 
    <br />  
    <hr />
  </div>