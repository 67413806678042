import { Injectable } from '@angular/core';
import moment from 'moment';
const conversor = require('conversor-numero-a-letras-es-ar');
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HelperService } from '../../helper/helper.service';
import { Firma } from 'src/app/shared/models/firmas.interface';
import { FirmasService } from '../../firmas.service';
import { error } from 'console';
export interface respuesta {
  success: string;
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class ContratoEstandarPlazoFijoService {
  public src: any;
  pdfMake: any;
  FechaHoy: any;
  FechaNacimiento: any
  requisitos: any;
  haberes: any;
  FechaFinContrato: any;
  Arrayhaberes: any = [];
  SucursalesTrabajador: any = [];
  ClaseConversor = conversor.conversorNumerosALetras;
  miConversor = new this.ClaseConversor();
  fecha_ingreso:any;

  firma: Firma;
  base64Firma: string;
  base64ImageWhite: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAADIAQMAAAAwS4omAAAAA1BMVEX///+nxBvIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAG0lEQVRIie3BMQEAAADCoPVPbQwfoAAAAIC3AQ+gAAEq5xQCAAAAAElFTkSuQmCC';
  BASE_API = environment.API;

  constructor(
    private helper : HelperService,
    private http: HttpClient,
    private firmaService: FirmasService,
  ) { }


  async loadPdfMaker() {
    if (!this.pdfMake) {

      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.pdfMake.vfs;
    }
  }
  RequisitosCargo(id: any): Observable<respuesta> {
    return this.http.get<respuesta>(`${environment.API}/rrhh/requisito/funciones/${id}`);
  }
  HaberesTrabajador(rut: any): Observable<respuesta> {
    return this.http.get<respuesta>(`${environment.API}/rrhh/trabajadorHaber/rut/${rut}`);
  }

  async TraerFirma(id: number) {
    if (id == null) {
      alert('No existe una firma anexada a la empresa');
      return;
    }
    const res = await this.firmaService.getFirma(id);
    if (res.success) {
      this.firma = res.data;
    }else{
      console.error('Error al traer la firma: ', res.msg);
    }
  }
  // TRANSFORMAR UNA IMAGEN DESDE UNA URL A BASE64
  getBase64ImageFromURL(url: string): Promise<string> {
    const urlFirma = `${this.BASE_API}${url}`;
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = urlFirma;
    });
  }

  ///

  mostrarFecha(datos: any) {
    var fecha_fin = moment(datos.Fechas.fecha_fin).format('DD-MM-YYYY');

    if (datos.PlazoFijo == true) {
      if (datos.tipoContrato == 'PLAZO FIJO') {
        return {


          text: [
            {text: `El presente contrato tendrá duración hasta el `, style: 'parrafo'},
            {text: `${this.FechaFinContrato} `, bold: true, style: 'parrafo'},
            {text: `en caso contrario, terminará por alguna de las causales de la legislación vigente.\n\n `, style: 'parrafo'},

          ]
        }
      } else if(datos.tipoContrato == 'OBRASFAENAS'){
        return {
          text: [
            {text: `El presente contrato tendrá inicio el `, style: 'parrafo'},
            {text: `${this.fecha_ingreso} `, bold: true, style: 'parrafo'},
            {text: `y terminará por alguna de las causales de la legislación vigente. \n\n `, style: 'parrafo'},
          ]
        }

    } return {
      alignment: 'justify',
      text: `El presente contrato tendrá duración de forma indefinida .\n\n `,
      style: 'parrafo'
    }

  }
    else {
      return {
        alignment: 'justify',
        text: `El presente contrato tendrá duración de forma indefinida .\n\n `,
        style: 'parrafo'
      }
    }
  }

  async fechaHoy() {
    var fecha = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    this.FechaHoy = fecha.toLocaleDateString('es-CL', options);
    // console.log("termino de setear la fecha")
  }

  async fechaatexto(fecha: any) {
    fecha = new Date(fecha);
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
    return fecha.toLocaleDateString('es-CL', options);
  }

  async TraerHaberes(datos: any) {
    // Reiniciar Arrayhaberes para evitar duplicaciones
    this.Arrayhaberes = [];

    return new Promise((resolve, reject) => {
      this.HaberesTrabajador(datos.Trabajador.rut).subscribe(
        (res) => {
          this.haberes = res.data ? res.data.filter((x: any) => x.Haberes.tipo == 1) : [];

          if (this.haberes.length > 0) {
            for (let i = 0; i < this.haberes.length; i++) {
              this.Arrayhaberes.push(
                this.haberes[i].Haberes.nombre + ` de $ ${this.helper.formatoCLP(this.haberes[i].monto)} .-(${this.miConversor.convertToText(this.haberes[i].monto)} pesos), ${this.haberes[i].Haberes.descripcion}\n\n`
              );
            }
          }

          console.log("estos son los haberes: ", this.Arrayhaberes);
          resolve(this.Arrayhaberes);
        },
        (error) => {
          console.error("Error al obtener los haberes: ", error);
          reject(error);
        }
      );
    });
  }



  async GenerarPdf(datos: any) {
    await this.TraerFirma(datos.Trabajador.Empresa_contratante.idfirma);
    if (this.firma) this.base64Firma = await this.getBase64ImageFromURL(this.firma?.rutaImg);

    this.SucursalesTrabajador = [];
    console.log("estos son los datos: ", datos);
    console.log(datos.Fechas.fecha_inicio._d);
    for (let i = 0; i < datos.SucursalesSeleccionadas.length; i++) {
      this.SucursalesTrabajador.push({
        alignment: 'justify',
        ul: [datos.SucursalesSeleccionadas[i].nombre + ' en ' + datos.SucursalesSeleccionadas[i].direccion + '\n\n'],
        style: 'tabla'
      })

    }

   



    await this.loadPdfMaker();
    var SueldoLetras = this.miConversor.convertToText(datos.Sueldo);
    datos.Sueldo = datos.Sueldo.toLocaleString('es-CL');
    await this.fechaHoy();
    this.FechaNacimiento = await this.fechaatexto(datos.Trabajador.fecha_nacimiento);
    this.FechaFinContrato = await this.fechaatexto(datos.Fechas.fecha_fin);
    this.fecha_ingreso=await this.fechaatexto(datos.Trabajador.fecha_ingreso);
    this.Arrayhaberes = [];
    const haberes = await this.TraerHaberes(datos);
    console.log("haberse: ", haberes);
    console.log("los haberes "+this.Arrayhaberes);
    // console.log(datos);
    this.RequisitosCargo(datos.Trabajador.Cargo.id).subscribe(
      (res) => {
        console.log(res);
        this.requisitos = res.data;
        const ArrayRequisitos = () => {
          const requisitos = [];

          for (let i = 0; i < this.requisitos.length; i++) {
             console.log(this.requisitos[i].requisitos.descripcion)
             requisitos.push(
              //alignment: 'justify',
              this.requisitos[i].requisitos.descripcion
              //style: 'tabla'
            )
          }
          return requisitos;
        };
        console.log(this.requisitos);
        var prueba = ArrayRequisitos();
        console.log("estos son los requisitos: ", prueba);

        var documentDefinition = {

          content: [
            {
              alignment: 'center',
              text: 'Contrato de Trabajo\n\n',
              style: 'header',
            },
            {
              //con esta wea se justifica
              alignment: 'justify',

              //------------------------
              text: [

                {
                  text: 'I.  Partes\n\n',
                  style: 'romanos'

                },
                {
                  text: `En  ${datos.Ciudad.nombre.charAt(0) + datos.Ciudad.nombre.slice(1).toLowerCase()}, ${this.FechaHoy}, entre `,
                  style: 'parrafo',
                },

                {
                  text: `${datos.Trabajador.Empresa_contratante.nombre}, `,
                  style: 'negrita',

                },
                {
                  text: 'Rol Único Tributario N°',
                  style: 'parrafo',
                },
                {
                  text: `${datos.Trabajador.Empresa_contratante.run},`,
                  style: 'parrafo',

                },
                {
                  text: ' representada legalmente por',
                  style: 'parrafo',
                },
                {
                  text: ` ${datos.Trabajador.Empresa_contratante.representante}, `,
                  style: 'parrafo_negro',
                },
                {
                  text: `cédula nacional de identidad N°${datos.Trabajador.Empresa_contratante.cedula}, `,
                  style: 'parrafo',
                },

                {
                  text: ` ambos con domicilio en ${datos.Trabajador.Empresa_contratante.ubicacion}, comuna y ciudad de ${datos.Trabajador.Empresa_contratante.CiudadEmpresa.nombre},`,
                  style: 'parrafo',
                },
                {
                  text: `correo electrónico ${datos.Trabajador.Empresa_contratante.correo},`,
                  style: 'parrafo',
                },
                {
                  text: ' en adelante',
                  style: 'parrafo',
                },
                {
                  text: ' "EL EMPLEADOR" ',
                  style: 'parrafo_negro',
                },
                {
                  text: 'y el/la colaborador/a ',
                  style: 'parrafo',
                },
                {
                  text: `${datos.Trabajador.nombres} ${datos.Trabajador.apellidos}, `,
                  style: 'parrafo_negro',
                },
                {
                  text: 'cédula de identidad N° ',
                  style: 'parrafo',
                },
                {
                  text: `${this.helper.formatoRut(datos.Trabajador.rut)}, `,
                  style: 'parrafo',
                },
                {
                  text: ` de nacionalidad ${datos.Trabajador.Nacionalidad.nombre}, nacido/a el ${this.FechaNacimiento},`,
                  style: 'parrafo',
                },
                {
                  text: ` estado civil ${datos.Trabajador.Estado_civil.nombre}, `,
                  style: 'parrafo',
                },
                {
                  text: 'domiciliado en ',
                  style: 'parrafo',
                },
                {
                  text: `${datos.Trabajador.direccion},`,
                  style: 'parrafo',
                },
                {
                  text: ` comuna y ciudad de ${datos.Trabajador.comuna},`,
                  style: 'parrafo',
                },
                {
                  text: ` ${datos.Trabajador.Ciudad.CiudadRegion.nombre}, `,
                  style: 'parrafo',
                },
                {
                  text: `teléfono ${datos.Trabajador.telefono}, `,
                  style: 'parrafo',
                },
                {
                  text: `correo electrónico ${datos.Trabajador.correo}, `,
                  style: 'parrafo',
                },
                {
                  text: ` afiliado/a AFP ${datos.Trabajador.Afp.nombre} `,
                  style: 'parrafo',
                },
                {
                  text: 'y sistema de salud ',
                  style: 'parrafo'
                },
                {
                  text: `${datos.Trabajador.Prevision.nombre},`,
                  style: 'parrafo',
                },

                {
                  text: ' en adelante ',
                  style: 'parrafo'
                },
                {
                  text: '"EL TRABAJADOR"',
                  style: 'parrafo_negro'
                },
                {
                  text: ' , se ha convenido el siguiente contrato de trabajo: \n\n',
                  style: 'parrafo',
                },
                {
                  text: 'II. Naturaleza de los servicios\n\n',
                  style: 'romanos',
                },
                {
                  text: 'El trabajador se compromete y obliga a ejecutar el trabajo de ',
                  style: 'parrafo',
                },
                {
                  text: `${datos.Trabajador.Cargo.nombre.toUpperCase()}`,
                  style: 'parrafo_negro',
                  alignment: 'justify',
                },
                {
                  text: ', lo cual implica entre otras labores, realizar lo siguiente:\n\n',
                  style: 'parrafo',
                  alignment: 'justify',
                },
              ],
              margin: [40, 0, 40, 0],
            },

            //aca una lista de labores
            	{
                  ol:
                    ArrayRequisitos(),
                    margin: [40, 0, 40, 0],
                    lineHeight: 1.5,
                },

            //termino de la lista

            {
              text: [
                {
                  text: '\n III.  Lugar de la prestación de servicios:\n\n',
                  style: 'romanos'

                },
              ],
              margin: [40, 0, 40, 0],
            },
            {
              alignment: 'justify',
              text: `El Trabajador prestará servicios en la oficina ubicada en ${datos.Trabajador.Sucursal.direccion} y las demás sucursales que se nombran a continuación: \n\n`,
              style: 'parrafo',
            },
            this.SucursalesTrabajador,
            {
              alignment: 'justify',
              text: `Sin perjuicio de la facultad del empleador de alterar, por causa justificada, la naturaleza de los servicios o el sitio o recinto en que ellos han de prestarse \n\n`,
              style: 'parrafo',
            },
            {
              text: 'IV. Jornada de trabajo:\n\n',
              style: 'romanos',
              alignment: 'justify',
            },
            {
              text: `La jornada de trabajo será de 45 horas semanales las que serán distribuidas de lunes a viernes, de la siguiente manera: \n\n `,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `1.- Jornada de mañana: desde las 08:00 horas y hasta las 13:00 horas.\n `,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `2.- Jornada de tarde: desde las 14:00 horas y hasta 18:00 horas.\n\n`,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `\nEl horario podría ser modificado según el requerimiento y necesidades del servicio, previa coordinación.\n `,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: `El trabajador deberá consignar fielmente el registro de asistencia, de forma diaria y de manera coetánea al inicio y termino de cada turno.\n\n`,
              style: 'parrafo',
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0]
            },
            {
              text: 'V. Remuneraciones:\n\n',
              style: 'romanos',
              alignment: 'justify',
            },
            {
              alignment: 'justify',
              style: 'parrafo',
              text: [
                `El empleador se compromete a remunerar los servicios trabajador con un sueldo mensual base de $ ${datos.Sueldo} `,
                {text: `.-( ${SueldoLetras} pesos)-.  \n\n`, bold: true},

              ]
            },
            // {
            //   text: `El empleador se compromete a remunerar los servicios trabajador con un sueldo mensual base de $ ${datos.Sueldo} `,
            //   style: 'parrafo'
            // },

            // {
            //   text:
            //   style: 'parrafo_negro'
            // },



            {
              text: `El empleador pagará una gratificación mensual equivalente al 25% del total de las remuneraciones mensuales, con tope legal de 4.75 ingresos mínimos mensuales.\n\n`,
              style: 'parrafo',
              alignment: 'justify',
            },
            {
              text: `Además, el empleador se compromete a pagar los siguientes conceptos :\n\n`,
              style: 'parrafo',
              alignment: 'justify',
            },
            //comienzo de los bonos
            {
              ol:
              this.Arrayhaberes,
              alignment: 'justify',
              lineHeight: 1.5,
              margin: [70, 0, 40, 0],
            },

            //fin lista
            {
              text: [
                {
                  text: `\nEn caso de inasistencia por parte del trabajador serán descontados proporcionalmente a los días de inasistencia los conceptos de asignación por movilización y colación. \n`,
                  style: 'parrafo',
                  lineHeight: 1.5,
                  alignment: 'justify',
                },
                {
                  alignment: 'justify',
                  text: `\nSe podrá otorgar un`,
                  style: 'parrafo'

                },
                {
                  alignment: 'justify',
                  text: ` anticipo de sueldo el día 20 de cada mes calendario, `,
                  style: 'parrafo_negro'
                },
                {
                  alignment: 'justify',
                  text: `a solicitud del trabajador y en proporción a los días trabajados hasta el día quince del respectivo mes. La solicitud del anticipo de sueldo deberá ser solicitado por escrito con 02 días hábiles de anticipación y pudiendo ser modificada a solicitud del trabajador, por escrito, cada 6 meses. \n\n`,
                  style: 'parrafo'
                },
                {
                  alignment: 'justify',
                  text: `En el caso de que el día 20 sea un día inhábil, el anticipo de sueldo se otorgará el día hábil siguiente. \n\n`,
                  style: 'parrafo'
                },
                {
                  alignment: 'justify',
                  text: `La remuneración será liquidada y pagada por periodos mensuales vencidos, siendo liquidada y pagada el día 05 de cada mes calendario, en el caso de que el día 05 sea un día inhábil, la remuneración se otorgará el día hábil siguiente. Se aplicarán las deducciones de impuestos que las graven, las cotizaciones de seguridad social y otras, de conformidad a lo establecido en el artículo 58 del Código del Trabajo. \n\n`,
                  style: 'parrafo'
                },
                {
                  text: 'VI. Duración de la relación jurídica laboral: \n\n',
                  style: 'romanos'
                },
                //esto es el caso de que tenga fecha fin de contrato, añadir diferencia si es indefinido
                this.mostrarFecha(datos),

                {
                  text: 'VII. Cláusula de vigencia:\n\n',
                  style: 'romanos'
                },

                {
                  alignment: 'justify',
                  style: 'parrafo',
                  text:`Se deja constancia que el trabajador comenzó el día  `,

                },
                {
                  alignment: 'justify',
                  bold: true,
                  style: 'parrafo',
                  text: `${this.helper.FechaaTexto(datos.Fechas.fecha_inicio._d)}`
                },
                {
                  alignment: 'justify',
                  style: 'parrafo',
                  text: ` a prestar servicios. \n\n`,
                },
                {
                  text: 'A tener en cuenta:\n\n',
                  style: 'romanos'
                },
                {
                  alignment: 'justify',
                  text: `Para todos los efectos derivados del presente contrato las partes fijan domicilio en la ciudad de  ${datos.Trabajador.Ciudad.nombre.charAt(0) + datos.Trabajador.Ciudad.nombre.slice(1).toLowerCase()} y se someten a la Jurisdicción de sus Tribunales.\n\n`,
                  style: 'parrafo'
                },
                {
                  alignment: 'justify',
                  text: `El presente contrato se firma en 2 ejemplares, declarando el trabajador haber recibido en este acto un ejemplar de dicho instrumento, que es el fiel reflejo de la relación laboral convenida entre las partes.`,
                  style: 'parrafo'
                },

              ],
              margin: [40, 0, 40, 0],
            },
            {
              columns:[
                {
                  alignment: 'center',
                  image: this.base64ImageWhite,
                  height: 80,
                },
                {
                  alignment: 'center',
                  image: this.base64Firma??this.base64ImageWhite,
                  height: 80,
                }
              ]
            },
            {
              columns: [
                {
                  canvas: [{ type: 'line', x1: 0, y1: 0, x2: 200, y2: 0 }],
                  width: '*',
                  alignment: 'center',
                },
                {
                  canvas: [{ type: 'line', x1: 0, y1: 0, x2: 200, y2: 0 }],
                  width: '*',
                  alignment: 'center',
                },
              ],
            },
            // {
            //   fontSize: 15, bold: true, margin: [20, 130, 0, 0],

            //   text: '_________________________________            _________________________________',
            // },
            {
              columns: [
                {
                  text: `${datos.Trabajador.nombres} ${datos.Trabajador.apellidos}`,
                  style: 'negrita',
                  fontSize: 14, bold: true, margin: [10, 0, 0, 0],
                  alignment: 'center',
                },
                {
                  text: `${datos.Trabajador.Empresa_contratante.nombre}`,
                  style: 'negrita',
                  fontSize: 14, bold: true, margin: [30, 0, 0, 0],
                  alignment: 'center',
                },
              ]
            },
            {
              columns: [
                {
                  text: `C.I: ${this.helper.formatoRut(datos.Trabajador.rut)} `,
                  style: 'negrita',
                  fontSize: 14, bold: true, margin: [75, 0, 0, 0],
                },
                {
                  text: `R.U.T: ${datos.Trabajador.Empresa_contratante.run}`,
                  style: 'negrita',
                  fontSize: 14, bold: true, margin: [75, 0, 0, 0],
                },
              ]
            },
            {
              columns: [
                {
                  text: `TRABAJADOR.`,
                  style: 'parrafo_negro',
                  fontSize: 14, bold: true, margin: [80, 0, 0, 0],
                },
                {
                  text: `EMPLEADOR.`,
                  style: 'parrafo_negro',
                  fontSize: 14, bold: true, margin: [95, 0, 0, 0],
                },
              ]
            },
          ],
          styles: {
            header: {
              fontSize: 17,
              bold: true,
            },
            romanos: {
              fontSize: 13,
              bold: true,
              decoration: 'underline',
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            tabla: {
              bold: true,
              fontSize: 12,
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            parrafo: {
              fontSize: 12,
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            parrafo_negro: {
              fontSize: 12,
              bold: true,
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
            negrita: {
              fontSize: 12,
              bold: true,
              lineHeight: 1.5,
              margin: [40, 0, 40, 0],
            },
          }
        };
        const pdfDocGenerator = this.pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getDataUrl((dataUrl: any) => {
          //this.url=dataUrl;

          this.src = dataUrl;
        }
        );
      }
    );
  }


}
