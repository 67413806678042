import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { RequestResponse } from 'src/app/shared/models/request-response.interface'
import { Observable, throwError } from 'rxjs'
import { RequestResponseActualizar, RequestResponseGetAllAdelantos, Adelantos } from 'src/app/shared/models/adelantos.interface'


@Injectable({
  providedIn: 'root'
})
export class AdelantosService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: AlertHelper) { }
    

  GetAll_adelantos(): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/adelanto/`)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  async TraerPorPeriodo(periodo: string): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/adelanto/periodo/${periodo}`).toPromise();
    } catch (error) {
      return { success: false, msg: 'Hubo un error al obtener los adelantos.' } as RequestResponse;
    }
  }

  private handlerError(err: any): Observable<never> {

    let errorMessage = ""
    if (err) {
      errorMessage = `Error: code ${err.error.msg}`
    }
    this.alert.error_small(err.error.msg)
    return throwError(errorMessage)
  }

  Create_adelantos(adelanto: any): Observable<RequestResponse> {
    console.log({adelanto});
    return this.http
      .post<RequestResponse>(`${environment.API}/rrhh/adelanto/crear`, adelanto)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  actualizar_adelanto(adelanto: any): Observable<RequestResponseActualizar> {
    return this.http
      .put<RequestResponseActualizar>(`${environment.API}/rrhh/adelanto/actualizar/${adelanto.id}`, adelanto)
      .pipe(
        map((res: RequestResponseActualizar) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  Obtener_adelanto(id: number): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/adelanto/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  Obtener_adelantosRUT(rut: number): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/adelanto/rut/${rut}`)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }

  async ObtenerAdelantosRutAsync(rut: number):  Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/adelanto/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los adelantos.'}as RequestResponse)
    }
  }

  Eliminar_adelanto(id: number): Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/adelanto/eliminar/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res
        }),
        catchError((err) => this.handlerError(err))
      )
  }
}
