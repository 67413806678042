<div class="container-fluid">
    <div class="col-12 pl-0 pr-0">
        <mat-card class="mat_card_trabajadores">
            <h1>Solicitudes de eliminación de documentos</h1>
        </mat-card>
    </div>
    <div class="mat-elevation-z8">
        <!-- TOOLBAR -->
        <p-toolbar styleClass="bg-light">
            <div class="p-toolbar-group-left">
                <p-dropdown placeholder="Todas los usuarios" [options]="usuarios!" [filter]="true" filterBy="text" [(ngModel)]="usuarioSelect" optionLabel="nombre_usuario"
                    class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body">
                </p-dropdown>
                <p-dropdown placeholder="Todas los tipos" [options]="tipos!" [filter]="true" filterBy="text" [(ngModel)]="tipoSelect"
                    class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body">
                </p-dropdown>
                <p-dropdown placeholder="Todas los estados" [options]="estados!" [filter]="true" filterBy="text" [(ngModel)]="estadoSelect" optionLabel="text"
                    optionValue="value" class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body">
                </p-dropdown>
                <label for="periodo" class="mr-1 block">Periodo</label>
                <p-calendar [readonlyInput]="true" inputId="periodo" [(ngModel)]="periodo" view="month" dateFormat="mm/yy" (onSelect)="onMonthChandler($event)" appendTo="body">
                </p-calendar>
            </div>
        </p-toolbar>
        <!-- TABLA -->
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef>FECHA</th>
                <td mat-cell *matCellDef="let row">{{row.fecha | date: 'dd-MM-yyyy'}}</td>
            </ng-container>
            <ng-container matColumnDef="usuario">
                <th mat-header-cell *matHeaderCellDef>USUARIO</th>
                <td mat-cell *matCellDef="let row">{{row.usuario | uppercase}}</td>
            </ng-container>
            <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef>TIPO</th>
                <td mat-cell *matCellDef="let row">{{row.tipo | uppercase}}</td>
            </ng-container>
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef>ESTADO</th>
                <td mat-cell *matCellDef="let row">
                    <p-message *ngIf="row.estado === 'PENDIENTE'" severity="info" [text]="row.estado"></p-message>
                    <p-message *ngIf="row.estado === 'APROBADO'" severity="success" [text]="row.estado"></p-message>
                    <p-message *ngIf="row.estado === 'RECHAZADO'" severity="error" [text]="row.estado"></p-message>
                </td>
            </ng-container>
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef>ACCIONES</th>
                <td mat-cell *matCellDef="let row">
                    <p-button icon="pi pi-eye" styleClass="mr-2 p-button-secondary" (click)="verDocumento(row)" pTooltip="Ver documento" *ngIf="row.estado !== 'APROBADO'"
                        tooltipPosition="bottom">
                    </p-button>
                    <!-- ELEGIR OPCION -->
                    <button *ngIf="row.estado === 'PENDIENTE' && (user.rol === 'ADMIN' || user.rol === 'GERENCIA')" type="button" pButton icon="pi pi-check-circle"
                        class="mr-2" pTooltip="Elegir decisión" tooltipPosition="bottom"
                        (click)="elegirDesicion(row);menu1.toggle($event)"></button>
                    <p-menu #menu1 [popup]="true" [model]="desiciones"></p-menu>
                    <!-- CAMBIAR OPCION -->
                    <button *ngIf="row.estado !== 'PENDIENTE'" type="button" pButton icon="pi pi-pencil"
                    class="mr-2" pTooltip="Cambiar decisión" tooltipPosition="bottom" (click)="cambiarDesicion(row)"></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center p-1" colspan="8">
                  <h1>No existen datos</h1>
                </td>
            </tr>
        </table>
        <div *ngIf="loading" class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="text-700 text-center">
              <div class="text-900 font-bold text-5xl mb-3">Cargando solicitudes...</div>
              <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
            </div>
          </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 30, 50]" 
            showFirstLastButtons 
            aria-label="Select page of users">
        </mat-paginator>
    </div>
</div>