import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RequestResponse, Banco } from 'src/app/shared/models/banco/banco.interface';


@Injectable({
  providedIn: 'root'
})
export class BancoService {

  constructor(
    private http:HttpClient,
    private router: Router,
    private alert:AlertHelper
  ) { }

  TraerTodos(): Observable<RequestResponse> {

    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/banco`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorId(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/banco/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  crear(bancos:any){
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/banco/crear`, bancos)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  

  eliminar(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/banco/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  actualizar(bancos:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse>(`${environment.API}/rrhh/banco/actualizar/ ${bancos.id}`,bancos)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  


  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
