<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
        <span style="color: white">Agregar Haberes o Descuentos</span>
        <mat-icon style="color: white">add</mat-icon>
    </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Haberes y Descuentos </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()">
            <div class="col">
                <table class="example-full-width" cellspacing="0">
                    <tr>

                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput maxlength="150" minlength="3" placeholder="Nombre"
                                    formControlName="nombre" />
                                <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Descripción</mat-label>
                                <textarea matInput type="textarea" rows="7" maxlength="25000" placeholder="Descripcion"
                                    formControlName="descripcion"></textarea>
                                <mat-error *ngIf="addressForm.controls['descripcion'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col">
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Tipo</mat-label>
                                <mat-select placeholder="Tipo de Haber" formControlName="tipo">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'HABER':'DESCUENTO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['tipo'].hasError('required')">
                                    tipo Haber es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Proporcinal</mat-label>
                                <mat-select placeholder="Proporcional" formControlName="proporcional">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['proporcional'].hasError('required')">
                                    Proporcional es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col">
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Imponible</mat-label>
                                <mat-select placeholder="Imponible?" formControlName="imponible">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['imponible'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Tributable</mat-label>
                                <mat-select placeholder="Tributable?" formControlName="tributable">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['tributable'].hasError('required')">Este campo es
                                    <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Gratificacíon</mat-label>
                                <mat-select placeholder="Gratificacion?" formControlName="gratificacion">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['gratificacion'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>REMUN. DE CARÁCTER FIJA (1)</mat-label>
                                <mat-select placeholder="REMUN. DE CARÁCTER FIJA (1)" formControlName="remCaractFijo">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['remCaractFijo'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>REMUN. DE CARÁCTER VARIABLE (2)</mat-label>
                                <mat-select placeholder="REMUN. DE CARÁCTER VARIABLE (2)"
                                    formControlName="remCaractVariable">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['remCaractVariable'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>BENEFICIO SEMANA CORRIDA (3)</mat-label>
                                <mat-select placeholder="BENEFICIO SEMANA CORRIDA (3)" formControlName="bSemanaCorrida">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['bSemanaCorrida'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <p-dropdown inputId="dropdown" [options]="conceptos_filter" formControlName="idconceptolre"
                                optionLabel="nombre" optionValue="id" [filter]="true" filterBy="nombre"
                                [showClear]="true" styleClass="w-100 mt-2" placeholder="CONCEPTO LRE (4)">
                            </p-dropdown>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-12">
                <br>
                <label>OBS: (1) Remuneración que forma parte del sueldo base, utilizada para el cálculo de H. Extras y
                    Ley 20.281</label>
                <br>
                <label>OBS: (2) Se usa para el ajuste del Sueldo Base Ley 20.281</label>
                <br>
                <label>OBS: (3) Nuevo concepto Ley 20.281.</label>
                <br>
                <label>OBS: (4) Conceptos del Libro de Remuneraciones Electronico.</label>
                <br>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">Cancelar</span>
                </button>
                <button type="button" class="btn btn-outline-success" type="submit">Guardar
                </button>
            </div>


        </form>
    </div>
</ng-template>