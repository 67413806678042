<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>



<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de la Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.nombre }}
            </td>
        </ng-container>

        <ng-container matColumnDef="run">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Run de la Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.run }}
            </td>
        </ng-container>

        <ng-container matColumnDef="ubicacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicacion de la Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.ubicacion }}
            </td>
        </ng-container>


        <ng-container matColumnDef="ciudad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ciudad</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.CiudadEmpresa.nombre }}
            </td>
        </ng-container>
        <ng-container matColumnDef="correo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo de la Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.correo }}
            </td>
        </ng-container>


        <ng-container matColumnDef="representante">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Representante de la Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.representante }}
            </td>
        </ng-container>

        <ng-container matColumnDef="cedula">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cedula de la Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.cedula }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button (click)="open(content, element.id)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">edit</mat-icon>
                </button> &nbsp;
                <button (click)="Eliminar_Empresa(content, element.id)" mat-button class="button_color_primary"
                    style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
    </mat-paginator>
</div>



<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Actualizar Empresa</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Identificación</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="empresas.nombre"
                        [(ngModel)]="update_empresas.nombre" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Rut</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="empresas.run"
                        [(ngModel)]="update_empresas.run" />
                </mat-form-field>
            </div>
            <div class="col col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Giro</mat-label>
                    <input type="text" matInput name="empresas.giro" [(ngModel)]="update_empresas.giro" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Dirección y contacto</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Ciudad</mat-label>
                    <mat-select name="update_empresas.ciudad" [(ngModel)]="update_empresas.ciudad">
                        <mat-option *ngFor="let ciudad of list_ciudades" [value]="ciudad.id">
                            {{ ciudad.nombre }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Ubicacion</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="empresas.ubicacion"
                        [(ngModel)]="update_empresas.ubicacion" />
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Correo</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="empresas.correo"
                        [(ngModel)]="update_empresas.correo" />
                    <!-- NAME ES EL NOMBRE EN COMO SE MOSTRARA EL ELEMENTO EN EL IMPUT Y EN EL NGMODEL ES EL NOMBRE EN COMO SE VA A CAPTURAR -->
                </mat-form-field>
            </div>
            <div class="col col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Numero Telefono</mat-label>
                    <input type="number" maxlength="11" minlength="9" matInput name="empresas.num_telefono"
                        [(ngModel)]="update_empresas.num_telefono" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Datos representante</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Representante</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="empresas.representante"
                        [(ngModel)]="update_empresas.representante" />
                </mat-form-field>
            </div>
            <div class="col col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cedula</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="empresas.cedula"
                        [(ngModel)]="update_empresas.cedula" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Firma</mat-label>
                    <mat-select (selectionChange)="mostrarFirma()" [(value)]="firmaSelected">
                        <mat-option *ngFor="let firma of firmas" [value]="firma.id">{{firma.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="firmaSrc">
                <img class="img-fluid rounded mx-auto d-block" [src]="firmaSrc" alt="Firma Preview">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p-divider align="left">
                    <div class="inline-flex align-items-center">
                        <b>Mutual de seguridad</b>
                    </div>
                </p-divider>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Mutual</mat-label>
                    <mat-select name="update_empresas.nombreMutual" [(ngModel)]="update_empresas.nombreMutual">
                        <mat-option *ngFor="let mutual of mutuales" value="{{ mutual.value }}">
                            {{ mutual.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col col-3">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cotización básica</mat-label>
                    <input type="number" maxlength="11" minlength="9" matInput name="update_empresas.cotizBasica"
                        [(ngModel)]="update_empresas.cotizBasica" />
                </mat-form-field>
            </div>
            <div class="col col-3">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Cotización ley Sanna </mat-label>
                    <input type="number" maxlength="11" minlength="9" matInput name="update_empresas.cotizLeySanna"
                        [(ngModel)]="update_empresas.cotizLeySanna" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="Actualizar_Empresa()">
            Actualizar
        </button>
    </div>
</ng-template>