import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { ListaSolicitudDocumento, SolicitudesDocumento } from 'src/app/shared/models/solicituddocumento.interface';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MenuItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { UsuariosService } from 'src/app/pages/services/usuarios.service';
import { format, getMonth, getYear } from 'date-fns';

@Component({
  selector: 'app-solicitudes-documentos',
  templateUrl: './solicitudes-documentos.component.html',
  styleUrls: ['./solicitudes-documentos.component.css']
})
export class SolicitudesDocumentosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  solicitudesDocumentos: SolicitudesDocumento[];
  periodo: Date;
  user: any;
  desiciones: MenuItem[];

  idsolicitud: number;
  iddocumento: number;
  idusuario: number;

  loading: boolean;

  // FILTRO
  usuarios: any[];
  usuarioSelect: any;
  tipos: string[];
  tipoSelect: string;
  estados: {value: number, text: string}[];
  estadoSelect: string;
  
  dataSource: MatTableDataSource<ListaSolicitudDocumento>;
  // 'select', 
  displayedColumns: string[];

  constructor(
    private solicitudesDocumentosService: SolicitudesDocumentosService,
    private usuarioService: UsuariosService,
    private alert: AlertHelper,
    private auth: AuthService,
  ) { 
    this.obtenerPeriodoLocalStorage();
    this.loading = false;
    this.displayedColumns = ['fecha', 'usuario', 'tipo', 'estado', 'acciones'];
    this.user = this.auth.ObtenerinfoToken();
    this.tipos = ['CONTRATO', 'AMONESTACION', 'FINIQUITO', 'TRANSFERENCIA', 'LICENCIA', 'VACACION', 'PERMISO'];
    this.estados = [
      {value: 0, text: 'PENDIENTE'},
      {value: 1, text: 'APROBADO'},
      {value: 2, text: 'RECHAZADO'},
    ]
    this.solicitudesDocumentos = [];
    this.desiciones = [
      {
        label: 'Aprobar',
        icon: 'pi pi-thumbs-up',
        command: () => this.ActualizarAprobacion(true),
      },
      {
        label: 'Rechazar',
        icon: 'pi pi-thumbs-down',
        command: () => this.ActualizarAprobacion(false),
      }
    ];
  }
  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.obtenerUsuarios();
    await this.TraerSolicitudes();
    await this.FiltrarDatos();
    this.loading = false;
  }
  async TraerSolicitudes() {
    const res = await this.solicitudesDocumentosService.TraerSolicitudes();
    if (!res.success) {
      this.alert.error_small(res.msg);
      return;
    }
    this.solicitudesDocumentos = res.data;
  }
  async FiltrarDatos(){
    let solicitudes: SolicitudesDocumento[] = [];
    if (this.periodo !== null && this.periodo !== undefined) {
      solicitudes = this.solicitudesDocumentos.filter((x: SolicitudesDocumento) => {
        const fecha = new Date(x.createdAt.split('T')[0]);
        const monthPeriodo = this.periodo.getMonth();
        const yearPeriodo = this.periodo.getFullYear();
        return getMonth(fecha) === monthPeriodo && getYear(fecha) === yearPeriodo;
      });
    }
    if (this.usuarioSelect !== null && this.usuarioSelect !== undefined) {
      solicitudes = solicitudes.filter(
        (x: SolicitudesDocumento) => (this.usuarioSelect.nombre_usuario.toUpperCase() === x.SolicitudUsuario.nombre_usuario)
      );
    }
    if (this.tipoSelect !== null && this.tipoSelect !== undefined) {
      solicitudes = solicitudes.filter(
        (x: SolicitudesDocumento) => (this.tipoSelect === x.Documento.tipo.toUpperCase())
      );
    }
    if (this.estadoSelect !== null && this.estadoSelect !== undefined) {
      solicitudes = solicitudes.filter(
        (x: SolicitudesDocumento) => (parseInt(this.estadoSelect) === x.estado)
      )
    }

    this.configurarTabla(solicitudes);
  }
  async obtenerUsuarios() {
    const res = await this.usuarioService.obtenerTodosUsuariosAsync();
    if (res.success) {
      this.usuarios = res.data;
    }else{
      console.error(res.msg);
    }
  }

  configurarTabla(solicitudes: SolicitudesDocumento[]) {
    const listaSolicitudesDocumento: ListaSolicitudDocumento[] = solicitudes.map(x => ({
      id: x.id,
      id_documento: x.id_documento,
      fecha: x.createdAt,
      usuario: x.SolicitudUsuario.nombre_usuario,
      tipo: x.Documento.tipo,
      rutadoc: this.obtenerRuta(x),
      estado: this.obtenerEstado(x.estado)
    }));
    this.dataSource = new MatTableDataSource<ListaSolicitudDocumento>(listaSolicitudesDocumento);
    this.dataSource.paginator = this.paginator;
  }
  onMonthChandler(event){
    this.periodo = event;
    this.guardarPeriodoLocalStorage();
    this.FiltrarDatos();
  }

  verDocumento(solicitud: ListaSolicitudDocumento){
    window.open(solicitud.rutadoc, '_blank');
  }
  // SE ACTUALIZA APROBACION DE ACUERDO A LO SELECCIONADO
  async ActualizarAprobacion(desicion: boolean): Promise<void> {
    const resGet = await this.solicitudesDocumentosService.TraerSolicitud(this.idsolicitud);
    if (!resGet.success) {
      this.alert.error_small(resGet.msg);
      return;
    }
    const aprobacion: SolicitudesDocumento = resGet.data;
    aprobacion.estado = desicion ? 1 : 2;
    const resPut = await this.solicitudesDocumentosService.ActualizarAprobacion(aprobacion);
    if (!resPut.success) {
      this.alert.error_small(resPut.msg);
      return;
    }
    this.alert.success_small(resPut.msg);
    await this.TraerSolicitudes();
  }
  async cambiarDesicion(aprobacion: ListaSolicitudDocumento){
    const titulo = '¿Desea cambiar la desición de esta solicitud?';
    const confirmed = await this.alertConfirmation(titulo);
    const valueEstado = aprobacion.estado === 'RECHAZADO';
    this.idsolicitud = aprobacion.id;
    if (confirmed) await this.ActualizarAprobacion(valueEstado);
  }
  elegirDesicion(solicitud: ListaSolicitudDocumento){
    this.idsolicitud = solicitud.id;
    this.iddocumento = solicitud.id_documento;
  }
  obtenerEstado(estado: number): string {
    switch (estado) {
      case 0:
        return 'PENDIENTE'
      case 1:
        return 'APROBADO'
      case 2:
        return 'RECHAZADO'        
      default:
        return 'SIN INFORMACIÓN'
    }
  }
  obtenerRuta(solicitud: SolicitudesDocumento): string {
    // CONTRATO - AMONESTACION - FINIQUITO - LIQUIDACION - TRANSFERENCIA - LICENCIA - VACACION - PERMISO
    const tipos = {
      CONTRATO: 'contratos',
      AMONESTACION: 'cartas',
      FINIQUITO: 'finiquitos',
      LIQUIDACION: 'liquidaciones',
      TRANSFERENCIA: 'transferencias',
      LICENCIA: 'licencias',
      VACACION: 'vacaciones',
      PERMISO: 'permisos',
    }
    const documento = solicitud.Documento;
    const tipo = documento.tipo;
    return tipos[tipo] ? `${environment.API}/${tipos[tipo]}/${documento.ruta}` : '';
  }
  async alertConfirmation(titulo: string): Promise<boolean> {
    const {isConfirmed} = await Swal.fire({
      title: titulo,
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    return isConfirmed;
  }
  guardarPeriodoLocalStorage() {
    const year = getYear(this.periodo);
    const month = getMonth(this.periodo);
    localStorage.setItem('periodoSolicitudDocumento', JSON.stringify({ year, month }));
  }
  obtenerPeriodoLocalStorage() {
    const periodo = JSON.parse(localStorage.getItem('periodoSolicitudDocumento'));
    if (periodo) {
      const { year, month } = periodo;
      this.periodo = new Date(year, month, 1);
    }else{
      this.periodo = new Date();
      this.guardarPeriodoLocalStorage();
    }
  }
}
