import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { AgregarFuncionesComponent } from './agregar-funciones/agregar-funciones.component';
import { ListaFuncionesComponent } from './lista-funciones/lista-funciones.component';
import { MainFuncionesComponent } from './main-funciones/main-funciones.component';



@NgModule({
  declarations: [
    AgregarFuncionesComponent,
    ListaFuncionesComponent,
    MainFuncionesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  exports:[
    AgregarFuncionesComponent,
    ListaFuncionesComponent,
    MainFuncionesComponent
  ]
})
export class FuncionesModule { }
