import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-generar-anexo',
  templateUrl: './main-generar-anexo.component.html',
  styleUrls: ['./main-generar-anexo.component.css']
})
export class MainGenerarAnexoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
