import { MatSortModule } from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaHaberesComponent } from './lista-haberes/lista-haberes.component';
import { AgregarHaberesComponent } from './agregar-haberes/agregar-haberes.component';
import { MainHaberesComponent } from './main-haberes/main-haberes.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PrimeNgModule } from 'src/app/PrimeNg.module';


@NgModule({
  declarations: [
    ListaHaberesComponent,
    AgregarHaberesComponent,
    MainHaberesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatSortModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    PrimeNgModule,
  ],
  exports:[
    ListaHaberesComponent,
    AgregarHaberesComponent,
    MainHaberesComponent
  ]
})
export class HaberesModule { }
