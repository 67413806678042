<div class="container-fluid">
  <div class="col-12 pl-0 pr-0">
    <mat-card class="mat_card_trabajadores">
      <h1 class>Solicitudes de liquidaciones y honorarios</h1>
    </mat-card>
  </div>
  <p-tabView>
    <p-tabPanel header="Liquidaciones"> 
  <p-toolbar styleClass="bg-light">
    <div class="p-toolbar-group-left overflow-x-auto">
      <p-dropdown placeholder="Todas las empresas" [options]="empresas" [filter]="true" filterBy="text" [(ngModel)]="empresaSelect" optionLabel="text"
        optionValue="value" class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body"></p-dropdown>
      <p-multiSelect [style]="{'max-width': '800px'}" placeholder="Todas las sucursales" [options]="sucursales" [filter]="true" filterBy="text" [(ngModel)]="sucursalSelect" optionLabel="text"
        optionValue="value" class="mr-2" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body" [selectedItemsLabel]="'{0} sucursales seleccionadas'"></p-multiSelect>
      <p-dropdown placeholder="Todos los trabajadores" [options]="trabajadoresFilter" [(ngModel)]="trabajadorSelect" [filter]="true" filterBy="text"
        optionLabel="text" optionValue="value" class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()"
          appendTo="body"></p-dropdown>
      <div class="mr-2">
        <label for="periodo" class="mr-1">Periodo</label>
        <p-calendar [readonlyInput]="true" inputId="periodo" [(ngModel)]="periodo" view="month" dateFormat="mm/yy" (onSelect)="onMonthChandler($event)"
        [minDate]="MIN_DATE" appendTo="body">
        </p-calendar>
      </div>
      <div class="mr-2">
        <label for="estado" class="mr-1">Estado</label>
        <p-multiSelect [options]="estadoLiquidacionView" [(ngModel)]="estadoLiquidacionSelect" (onChange)="FiltrarDatos()"
          placeholder="Todos los estados" optionLabel="text" optionValue="value" appendTo="body">
        </p-multiSelect>
      </div>
      <!-- BOTON RECARGA (NO FUNCIONA) -->
      <!-- <div class="mr-2">
        <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded p-button" 
          pTooltip="Refrescar tabla" tooltipPosition="bottom" (click)="recargarTabla()"></button>
      </div> -->
    </div>
  </p-toolbar>
  <p-toolbar styleClass="bg-light animate__animated animate__fadeIn" *ngIf="selection?.selected.length > 0">
    <div class="p-toolbar-group-left overflow-x-auto">
      <button pButton pRipple icon="pi pi-thumbs-up" class="p-button-rounded p-button-success p-button-sm mr-2"
        (click)="AllDesicion(true)" label="Aprobar Seleccionadas">
      </button>
      <button pButton pRipple icon="pi pi-thumbs-down" class="p-button-rounded p-button-danger p-button-sm mr-2"
        (click)="AllDesicion(false)" label="Rechazar Seleccionadas">
      </button>
      <!-- <button pButton pRipple icon="pi pi-thumbs-down" class="p-button-rounded p-button-danger p-button-sm"
        (click)="AllDesicion(false, true)" label="Rechazar y Pagar en finiquito">
      </button> -->
    </div>
  </p-toolbar>
  <!-- TABLE -->

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection?.hasValue() && isAllSelected()"
                        [indeterminate]="selection?.hasValue() && !isAllSelected()"
                        *ngIf="liquidacionesPendientes()"
                        color="warn">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection?.toggle(row) : null"
                        [checked]="selection?.isSelected(row)"
                        *ngIf="!row.estado && row.eliminado === 0"
                        color="warn">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-center">
          <span *ngIf="liquidacionesPendientes()">{{selection?.selected.length??0}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="trabajador">
        <th mat-header-cell *matHeaderCellDef> TRABAJADOR </th>
        <td mat-cell *matCellDef="let row"> {{row.fk_trabajadores.nombres + ' ' +row.fk_trabajadores.apellidos |
          uppercase}} </td>
        <td mat-footer-cell *matFooterCellDef>
          <span *ngIf="liquidacionesPendientes()">Liquidacion(es) seleccionada(s)</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="periodo">
        <th mat-header-cell *matHeaderCellDef> PERIODO </th>
        <td mat-cell *matCellDef="let row"> {{row.fecha | date: 'MMMM yyyy'}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> ESTADO </th>
        <td mat-cell *matCellDef="let row">
          <p-message *ngIf="row.estado === 0" severity="warn" text="RECTIFICADA"></p-message>
          <p-message *ngIf="row.estado === 1" severity="success" text="APROBADA"></p-message>
          <p-message *ngIf="row.estado === 2" severity="error" text="RECHAZADA"></p-message>
          <p-message *ngIf="row.estado === 3" severity="error" text="ELIMINADA"></p-message>
          <p-message *ngIf="(row.estado === null || row.estado === undefined) && row.eliminado === 0" severity="info"
            text="PENDIENTE"></p-message>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="haberes">
        <th mat-header-cell *matHeaderCellDef> HABERES $ </th>
        <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalHaberes | number:'1.0-0'}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="descuentos">
        <th mat-header-cell *matHeaderCellDef> DESCUENTOS $ </th>
        <td mat-cell *matCellDef="let row" class="text-right"> {{row.totalDescuentos | number:'1.0-0'}} </td>
        <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right"> TOTAL LIQUIDO:</td>
      </ng-container>

      <ng-container matColumnDef="liquido">
        <th mat-header-cell *matHeaderCellDef> LIQUIDO $ </th>
        <td mat-cell *matCellDef="let row" class="text-right"> {{row.alcanceLiquido | number:'1.0-0'}} </td>
        <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right"> ${{totalliquido | number: '1.0-0' }} </td>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef style="width: 20%;"> ACCIONES </th>
        <td mat-cell *matCellDef="let row" class="pl-2">
          <!-- VER LIQUIDACIÓN 
          <p-button icon="pi pi-eye" styleClass="mr-2 p-button-secondary" (click)="verLiquidacion(row)"
            pTooltip="Ver liquidación" tooltipPosition="bottom"></p-button> -->
          <!-- VER DETALLE -->
          <p-button icon="pi pi-book" styleClass="mr-2 p-button-secondary" *ngIf="(user.rol === 'ADMIN' || user.rol === 'GERENCIA')"
            (click)="verDetalle(row.id, viewLiquidacionModal)" pTooltip="Ver detalle"
            tooltipPosition="bottom"></p-button>
          <!-- ELEGIR OPCION -->
          <button *ngIf="(row.estado === null || row.estado === 0) && (user.rol === 'ADMIN' || user.rol === 'GERENCIA') && !row.eliminado" type="button" pButton icon="pi pi-check-circle"
            class="mr-2" pTooltip="Elegir decisión" tooltipPosition="bottom"
            (click)="elegirDesicion(row);menu1.toggle($event)"></button>
          <p-menu #menu1 [popup]="true" [model]="desiciones"></p-menu>
          <!-- MAS OPCIONES -->
          <button *ngIf="row.estado !== null && row.estado !== 0" type="button" pButton icon="pi pi-bars"
            pTooltip="Más opciones" tooltipPosition="bottom"
            (click)="masOpciones(row, viewHistorialDecisiones);menu2.toggle($event)"></button>
          <p-menu #menu2 [popup]="true" [model]="moreOptions"></p-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right">TOTAL REGISTROS FILTRADOS: {{dataSource?.data.length}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center p-1" colspan="8">
          <h1>No existen datos</h1>
        </td>
      </tr>
    </table>
    <div *ngIf="loading" class="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">Cargando liquidaciones...</div>
        <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
      </div>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 20, 30, 50]" 
      showFirstLastButtons 
      aria-label="Select page of users">
    </mat-paginator>
  </div>
    </p-tabPanel>
<p-tabPanel header="Honorarios">
  <app-solicitudes-honorario></app-solicitudes-honorario>
</p-tabPanel>

  </p-tabView>
</div>
<ng-template #viewLiquidacionModal let-modal>
  <div class="modal-header">
    <mat-card-title class="modal-title">Detalles de la Liquidación</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-modal-view-liquidacion [modalViewLiquidacion]="modalViewLiquidacion"></app-modal-view-liquidacion>
  </div>
</ng-template>
<ng-template #viewHistorialDecisiones let-modal>
  <div class="modal-header">
    <mat-card-title class="modal-title">Historial de decisiones</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-modal-view-history [historyDecisionesLiquidacion]="aprobacionLiquidacion"></app-modal-view-history>
  </div>

</ng-template>
<!-- <h1>TOTAL LIQUIDO: {{totalliquido | number: '1.0-0'}}</h1> -->
<!-- <button (click)="AgregarFirma()">PRUEBA PDF</button> -->