<div class="row p-2" *ngIf="previsional; else loading">
    <div class="col">
        <div class="row">
            <div class="col col-3">
                <h2 class="text-center mb-0">Renta Minima Imponible</h2>
                &nbsp;
                <mat-card-title class="text-center border border-secondary rounded p-3">{{previsional.RMITrabDepeInd | currency: 'CLP'}}</mat-card-title>
            </div>
            <div class="col col-9">
                <h2 class="text-center mb-2">Valor UF</h2>
                <div class="row pr-2 pl-2">
                    <div class="col col-6">
                        <mat-card-title class="text-right border border-secondary rounded p-3">{{previsional.UFDescPeridoAnt}}: {{previsional.UFValPeriodoAnt | number}}</mat-card-title>
                    </div>
                    <div class="col col-6">
                        <mat-card-title class="text-right border border-secondary rounded p-3">{{previsional.UFDescPeriodo}} {{previsional.UFValPeriodo | number}}</mat-card-title>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- AFP TASAS % -->
            <div class="col col-6">
                <h2 class="text-center mb-2">Tasas AFP´s</h2>
                <div id="carouselPrevisional" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <!-- CAPITAL -->
                        <div class="carousel-item active" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>Capital: %{{previsional.AFPCapitalTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- CUPRUM -->
                        <div class="carousel-item" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>Cuprum: %{{previsional.AFPCuprumTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- HABITAT -->
                        <div class="carousel-item" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>Habitat: %{{previsional.AFPHabitatTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- PLANVITAL -->
                        <div class="carousel-item" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>PlanVital: %{{previsional.AFPPlanVitalTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- PROVIDA -->
                        <div class="carousel-item" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>ProVida: %{{previsional.AFPProVidaTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- MODELO -->
                        <div class="carousel-item" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>Modelo: %{{previsional.AFPModeloTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- UNO -->
                        <div class="carousel-item" data-interval="10000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>Uno: %{{previsional.AFPUnoTasaDep}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev bg-secondary rounded-left" href="#carouselPrevisional" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next bg-secondary rounded-right" href="#carouselPrevisional"
                        role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <!-- UTM -->
            <div class="col col-3">
                <h2 class="text-center mb-2">UTM {{previsional.UTMDesc}}</h2>
                <mat-card class="border border-secondary">
                    <mat-card-title class="text-center">{{previsional.UTMVal | currency: 'CLP'}}</mat-card-title>
                </mat-card>
            </div>
            <!-- UTA -->
            <div class="col col-3">
                <h2 class="text-center mb-2">UTA</h2>
                <mat-card class="border border-secondary">
                    <mat-card-title class="text-center">{{previsional.UTAVal | currency: 'CLP'}}</mat-card-title>
                </mat-card>
            </div>
        </div>
        <div class="row">
            <div class="col col-12">
                <!-- ASIGNACION FAMILIAR -->
                <h2 class="text-center mb-3">Asignación Familiar (Tramos)</h2>
                <div id="carouselAsigFam" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <!-- TRAMO A -->
                        <div class="carousel-item active" data-interval="20000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>A: {{previsional.AFamTramoAMonto | currency:'CLP'}} | Requisitos: entre $0 y {{previsional.AFamTramoAHasta | currency: 'CLP'}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- TRAMO B -->
                        <div class="carousel-item" data-interval="20000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>B: {{previsional.AFamTramoBMonto | currency:'CLP'}} | Requisitos: entre {{previsional.AFamTramoBDesde | currency: 'CLP'}} y {{previsional.AFamTramoBHasta | currency: 'CLP'}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- TRAMO C -->
                        <div class="carousel-item" data-interval="20000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>C: {{previsional.AFamTramoCMonto | currency:'CLP'}} | Requisitos: entre {{previsional.AFamTramoCDesde | currency: 'CLP'}} y {{previsional.AFamTramoCHasta | currency: 'CLP'}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                        <!-- TRAMO D -->
                        <div class="carousel-item" data-interval="20000">
                            <div class="d-block w-100">
                                <mat-card class="border border-secondary pb-1">
                                    <mat-card-header class="d-flex justify-content-center">
                                        <mat-card-title>D: {{previsional.AFamTramoDMonto | currency:'CLP'}} | Requisitos: mayor o igual {{previsional.AFamTramoDDesde | currency: 'CLP'}}</mat-card-title>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev bg-secondary rounded-left" href="#carouselAsigFam" role="button"
                        data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next bg-secondary rounded-right" href="#carouselAsigFam"
                        role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <div class="col col-12 text-center mb-0">
                <a mat-flat-button style="background-color: #5B2B81; color: white; text-decoration: none;" href="https://www.previred.com/indicadores-previsionales/" target="_blank">
                    <img src="https://i.ibb.co/zbGj4Qw/1519868074706.jpg" width="50" height="50" alt="logo previred" class="p-1">
                    Ir a Indicadores PREVIRED
                </a>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="row">
        <div class="col-12">
            <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div class="text-700 text-center">
                    <div class="text-900 font-bold text-5xl mb-3">Cargando datos previsionales...</div>
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
                </div>
            </div>
        </div>
    </div>
</ng-template>