<div class="animate__animated animate__fadeInRight">
    <br />


    <br />
   <mat-card class="mat-elevation-z5 mat_card_general_content border border-primary ">
     
    <app-lista-compras> </app-lista-compras> 
           

    </mat-card> 
    <hr />
</div>