<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Contratos
</h3>
<app-main-buscar-filter 
    [num_trab_finiquitados]="num_trab_finiquitados" 
    (textSearch)="applyFilter($event)" 
    (viewFiniquitadosEmit)="viewDatosFiniquitados($event)">
</app-main-buscar-filter>
<table mat-table id="tabla" [dataSource]="dataSource" matSort class="mat-elevation-z8" #tabla
    (matSortChange)="announceSortChange($event)">

    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.rut | formatoRut }} </td>
    </ng-container>
    <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE COMPLETO </th>
        <td mat-cell *matCellDef="let element"> {{element.nombres | uppercase }} {{element.apellidos | uppercase}} </td>
    </ng-container>

    <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA</th>
        <td mat-cell *matCellDef="let element">{{ element.Empresa_contratante.nombre | uppercase}}</td>
    </ng-container>

    <ng-container matColumnDef="estado_contrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ESTADO CONTRATO</th>
        <td mat-cell *matCellDef="let element"> 
            <span [ngClass]="{'bg-danger p-1 rounded text-light': element.estado_contrato === 'Finiquitado'}">{{element.estado_contrato | uppercase}}</span> 
        </td>
    </ng-container>
    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button (click)="open2(contenido,element.rut)" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">archive</mat-icon>
            </button> &nbsp;
            <button mat-button (click)="open(content,element.rut)" class="button_color_primary"
                style="background-color:rgb(37, 199, 228)">
                <mat-icon style="color: white">file_upload</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons
    aria-label="Seleccionar pagina de contratos.">
</mat-paginator>


<!--

███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗         ███████╗██████╗ ██╗████████╗
████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║         ██╔════╝██╔══██╗██║╚══██╔══╝
██╔████╔██║██║   ██║██║  ██║███████║██║         █████╗  ██║  ██║██║   ██║
██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║         ██╔══╝  ██║  ██║██║   ██║
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗    ███████╗██████╔╝██║   ██║
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝    ╚══════╝╚═════╝ ╚═╝   ╚═╝


-->

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Subir Contrato</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col col-12">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>EMPRESA</mat-label>
                    <mat-select required [(value)]="selectEmpresa" (selectionChange)="ObtenerEstadoContrato($event)"> <!-- (selectionChange)="ObtenerEstadoConrato($event)" ---->
                        <mat-option *ngFor="let empresa of empresas" [value]="empresa.id">{{empresa.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col col-12">
                <mat-checkbox [(ngModel)]="cambiarEmpresaTrabajador" color="warn">
                    Cambiar empresa del trabajador
                </mat-checkbox>
            </div>
            <div class="col col-12">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>ESTADO</mat-label>
                    <mat-select required [(value)]="selectEstadoContrato" (selectionChange)="ObtenerEstadoContrato($event)">
                        <mat-option value="1">A Plazo Fijo</mat-option>
                        <mat-option value="2">Indefinido</mat-option>
                        <mat-option value="3">Por Obra o Faena</mat-option>
                        <mat-option value="4">Finiquitado</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col col-12">
                <mat-checkbox [(ngModel)]="cambiarEstadoContrato" color="warn">
                    Cambiar estado contrato del trabajador
                </mat-checkbox>
            </div>
            <div class="col col-12">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>TIPO</mat-label>
                    <mat-select required name="tipo_contrato" [(ngModel)]="contrato.tipo_contrato">
                        <!-- (selectionChange)="ObtenerEstadoContrato($event)" -->
                        <mat-option value="1">Contrato</mat-option>
                        <mat-option value="2">Anexo</mat-option>
                        <mat-option value="3">Anexo de Remuneración</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col col-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha Firma</mat-label>
                    <input (click)="picker.open()" matInput [matDatepicker]="picker"
                        [(ngModel)]="contrato.fechaFirma" (ngModelChange)="cambiarFechaInicio()" name="picker_firma">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col col-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha inicio</mat-label>
                    <input (click)="picker2.open()" required matInput [matDatepicker]="picker2"
                        [(ngModel)]="contrato.fechaInicio" name="picker_inicio">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker2></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col col-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha termino</mat-label>
                    <input (click)="picker3.open()" matInput [matDatepicker]="picker3"
                        [(ngModel)]="contrato.fechaTermino" name="picker_termino">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker3></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col col-12">
                <label>Subir Contrato </label>
                <!-- <input type="file" accept="image/*,.pdf,.doc,.docx" (change)='fileChangeEvent($event)' /> -->
                <input mat-input type="file" ng2FileSelect [uploader]="uploader" />
            </div>
            <div class="col col-12">
                Estado de Carga
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                </div>
            </div>
        </div>
        <!-- <div>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>JORNADA DE TRABAJO</mat-label>
                <input type="text" required matInput [(ngModel)]="contrato.jornada" name="jornada" />
            </mat-form-field>
        </div> -->
        <!-- <table class="example-full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>TIPO DE SUELDO</mat-label>
                        <mat-select required="required" name="sueldo" (selectionChange)="ObtenerTipoSueldo($event)">
                            <mat-option value="1">sueldo líquido</mat-option>
                            <mat-option value="2">sueldo bruto</mat-option>
                            <mat-option value="3">sueldo base</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <span matPrefix>$ &nbsp;</span>
                        <mat-label>Sueldo</mat-label>
                        <input type="number" required matInput [(ngModel)]="contrato.sueldo" name="sueldo" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Fecha Firma</mat-label>
                        <input (click)="picker.open()" required matInput [matDatepicker]="picker" [(ngModel)]="contrato.fechaFirma" name="picker_firma">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                         fecha de firma
                    </mat-form-field>
                </td>
            </tr>
        </table> -->
        <!-- <div class="example-full-width" appearance="outline">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label style="text-align:center">DESDE - HASTA </mat-label>
                <mat-date-range-input [rangePicker]="picker2" name="rango_pick">
                    <input (click)="picker2.open()" matStartDate placeholder="FECHA INICIO" #dateRangeStart>
                    <input (click)="picker2.open()" matEndDate placeholder="FECHA FIN" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-date-range-picker touchUi #picker2></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
        </div> -->

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" (click)="guardar()" class="btn btn-outline-success">Guardar</button>
    </div>
</ng-template>


<ng-template style="border: 0px; z-index: 100" #contenido let-modal2>
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Visualizar Contrato</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-mostrar-contratos [rut]='RutTrabajador'> </app-mostrar-contratos>
    </div>


</ng-template>