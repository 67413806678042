<!-- <div class="animate__animated animate__slideInUp">
    <mat-card>
      <app-lista-liquidaciones> </app-lista-liquidaciones>
    </mat-card>
</div> -->
<div class="animate__animated animate__slideInUp">
  <mat-card>
    <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
      Liquidaciones
    </h3>

    <app-main-buscar-filter
      [num_trab_finiquitados]="num_trab_finiquitados"
      (textSearch)="applyFilter($event)"
      (viewFiniquitadosEmit)="viewDatosFiniquitados($event)">
    </app-main-buscar-filter>
    <!-- <div class="row container-fluid">
      <div class="mr-2">
        <mat-form-field appearance="outline">
          <mat-label>Buscar</mat-label>
          <input matInput type="texto" [(ngModel)]="textoBuscar" (keyup)="applyFilter($event)" #input>
          <button mat-icon-button matSuffix (click)="quitarFiltro()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="d-flex align-items-center">
        <mat-checkbox (change)="viewDatosFiniquitados()" [(ngModel)]="viewFiniquitados" color="warn">
          <span [matBadge]="num_trab_finiquitados <= 99
                ? num_trab_finiquitados
                : '+99'" matBadgeOverlap="false">Mostrar trabajadores finiquitados</span>
        </mat-checkbox>
      </div>
    </div> -->

    <table mat-table id="tabla" [dataSource]="dataSource" matSort class="mat-elevation-z8"
      (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> {{ element.rut | formatoRut }} </td>
      </ng-container>

      <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE COMPLETO </th>
        <td class="padding-table" mat-cell *matCellDef="let element"> {{element.nombres | uppercase}} </td>
      </ng-container>

      <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> EMPRESA</th>
        <td class="padding-table" mat-cell *matCellDef="let element">{{ element.nombreEmpresa | uppercase}}
        </td>
      </ng-container>

      <ng-container matColumnDef="estado_contrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CONTRATACIÓN</th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          <span
            [ngClass]="{'bg-danger p-1 rounded text-light': element.estado_contrato === 'Finiquitado'}">{{element.estado_contrato
            | uppercase}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td class="padding-table" mat-cell *matCellDef="let element">
          <button mat-button (click)="open2(contenido,element.rut)" class="button_color_primary"
            style="background-color:rgb(37, 199, 228)">
            <mat-icon style="color: white">archive</mat-icon>
          </button> &nbsp;
          <button mat-button (click)="open(content,element.rut)" class="button_color_primary"
            style="background-color:rgb(37, 199, 228)">
            <mat-icon style="color: white">file_upload</mat-icon>
          </button>
          &nbsp;
          <button *ngIf ="element.estado !== 0"  mat-button (click)="open3(contenidos,element.rut)" class="button_color_primary"
            style="background-color:rgb(37, 199, 228)">
            <mat-icon style="color: white">add</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
      aria-label="Seleccionar pagina de Trabajadores.">
    </mat-paginator>
  </mat-card>
</div>

<!-- MODAL PARA LISTAR LIQUIDACIONES POR RUT  llama a la app-listar-liquidaciones-->
<ng-template style="border: 0px; z-index: 100" #contenido let-modal2>
  <div class="modal-header" style="text-align: center">
    <mat-card-title *ngIf="nombreTrabajador.length > 0">Visualizar Liquidaciones de {{nombreTrabajador | uppercase}}</mat-card-title>
    <mat-card-title *ngIf="nombreTrabajador.length === 0">Cargando...</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-lista-liquidaciones [rut]='RutTrabajador'
      (nombreTrabajador)="getNombreTrabajador($event)"></app-lista-liquidaciones>
  </div>
</ng-template>

<!-- MODAL PARA CREAR LIQUIDACION llama a la app-agregar-liquidaciones-->
<ng-template style="border: 0px; z-index: 100" #content let-modal>

  <div class="modal-header" style="text-align: center">
    <mat-card-title>Cargar Liquidacion de {{nombreTrabajador | uppercase}}</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-agregar-liquidaciones [rut]='RutTrabajador'
    (nombreTrabajador)="getNombreTrabajador($event)"></app-agregar-liquidaciones>

</ng-template>


<!-- MODAL PARA GENERAR LIQUIDACIONES POR RUT  llama a la app-generar-liquidaciones-->
<ng-template #contenidos let-modal3>
  <div class="modal-header" (hidden.bs.modal)="onHidden($event)">
    <mat-card-title>Generar Liquidación de {{nombreTrabajador}}</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body container-fluid">
    <app-test-liquidaciones [rut]='RutTrabajador'
      (nombreTrabajador)="getNombreTrabajador($event)"></app-test-liquidaciones>
  </div>
</ng-template>


<!-- MODAL PARA GENERAR LIQUIDACION DE PRUEBA  llama a la app-test-liquidaciones-->
<ng-template style="border: 0px; z-index: 100" #contenidos2 let-modal4>
  <div class="modal-header" style="text-align: center">
    <h4>Generar Liquidación</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal4.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-test-liquidaciones [rut]='RutTrabajador'></app-test-liquidaciones>
  </div>
</ng-template>

<ng-template #cargando>
  <div class="row">
      <div class="col-12">
          <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
              <div class="text-700 text-center">
                  <div class="text-900 font-bold text-5xl mb-3">Cargando liquidaciones...</div>
                  <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
              </div>
          </div>
      </div>
  </div>
</ng-template>
