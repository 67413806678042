import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild ,SimpleChanges} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
// import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
import { VacacionesService } from 'src/app/pages/services/vacaciones/vacaciones.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listar-vacaciones',
  templateUrl: './listar-vacaciones.component.html',
  styleUrls: ['./listar-vacaciones.component.css']
})
export class ListarVacacionesComponent implements OnInit {

  @Input() rut:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;   // variable para asignar paginator a matdatatable
  @ViewChild(MatSort) sort!: MatSort;
  dataSource= new MatTableDataSource();
  info_documento: any;

  loading: boolean = false;


  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

  constructor(
    private vacacionesApi: VacacionesService,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private documentoService: DocumentosService,
    // private solicitudDocumentoService: SolicitudesDocumentosService,
  ) { }

  displayedColumns = [
    'Folio',
    'Inicio',
    'Termino',
    'Habiles',
    'Inhabiles',
    'Pendiente',
    'Periodo',
    'Acciones'
  ];
  vacaciones:any;
  id:any;

  ngOnInit(): void {

    this.TraerVacaciones();
  }
  TraerVacaciones(){
      this.vacacionesApi.TraerPorRut(this.rut).subscribe(
        (res) => {
         // console.log(res.data[0].lenght);  // undefined
         //console.log(res.data );
  
          this.dataSource =  new MatTableDataSource(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //console.table(this.liquidaciones);
        }
      );
    }
    open(content: any ,vacacion:any) {
      this.modalService.open(content, { size: 'lg' });
      this.id=vacacion;
    }
  
  
    Descargar(id :any){
      //console.log("Buscando archivo "+ id);
      webPreferences: {
        webSecurity: false
      }
      this.vacacionesApi.TraerArchivo(id).subscribe(
        (res)=>{
          //console.log(res.data);
          this.info_documento =res.data[0];
          window.open(`${environment.API}/vacaciones/`+this.info_documento.ruta);
        }
  
      );
    }
  
    Borrar(id:number, estado:boolean){
      if (!confirm('¿Seguro que desea eliminar la vacación?')) return;
      if (estado) {
        this.documentoService.eliminarDocumento(id, 'VACACION').subscribe((res:RequestResponse) => {
          this.vacacionesApi.eliminarContrato(id).subscribe((res:RequestResponse) => {
            this.alert.success_small('Se elimino la vacación');
            this.TraerVacaciones();
          }, (error) => {
            this.alert.error_small('Hubo un error al eliminar la vacación.');
            console.error(error);
          });
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar el documento.');
          console.error(error);
        });
      }else{
        this.vacacionesApi.eliminarContrato(id).subscribe((res:RequestResponse) => {
          this.alert.success_small('Se elimino la vacación');
          this.TraerVacaciones();
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar la vacación.');
          console.error(error);
        });
      }
    }
  }
  // -----------------------<- CON SOLICITUD PARA BORRAR ->-----------------------
  // async ngOnInit() {
  //   await this.TraerVacaciones();
  // }

  // async TraerVacaciones(){
  //   const res = await this.vacacionesApi.TraerPorRutAsync(this.rut);
  //   if (res.success) {
  //     this.vacaciones = res.data.sort((a, b) => {
  //       const timeA = new Date(a.f_start).getTime();
  //       const timeB = new Date(b.f_start).getTime();
  //       return timeB - timeA;
  //     });
  //     const vacacionSource = [];
  //     for (const vacacion of this.vacaciones) {
  //       const resDoc = await this.documentoService.getVacacion(vacacion.id);
  //       if (resDoc.success) {
  //         const documento = resDoc.data[0];
  //         const resSol = await this.solicitudDocumentoService.TraerPorDocumento(documento.cp_documento);
  //         if (resSol) {
  //           const solicitud = resSol.data;
  //           vacacion.pendienteSolicitud = solicitud;
  //         }else{
  //           vacacion.pendienteSolicitud = false;
  //         }
  //       }else{
  //         vacacion.pendienteSolicitud = false;
  //       }
  //       vacacionSource.push(vacacion);
  //     }
  //     this.dataSource = new MatTableDataSource(this.vacaciones);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //   }else{
  //     this.alert.error_small(res.msg);
  //   }
  // }
  // open(content: any ,vacacion:any) {
  //   this.modalService.open(content, { size: 'lg' });
  //   this.id=vacacion;
  // }

  // Descargar(id :any){
  //   this.vacacionesApi.TraerArchivo(id).subscribe(
  //     (res)=>{
  //       //console.log(res.data);
  //       this.info_documento =res.data[0];
  //       window.open(`${environment.API}/vacaciones/`+this.info_documento.ruta);
  //     }
      
  //   );
  // }
  // <- CON SOLICITUD PARA BORRAR <-
  // async Borrar(vacacion:any){
  //   this.loading = true;
  //   console.log(vacacion);
  //   if (confirm('¿Seguro que desea eliminar la vacación?')){
  //     if (vacacion.estado === 0) await this.eliminarRegistroVacacion(vacacion);
  //     else await this.eliminarVacacionDocumento(vacacion);
  //   };
  //   this.loading = false;
  // }
  // async eliminarRegistroVacacion(vacacion: any) {
  //   const res = await this.vacacionesApi.eliminarVacacionAsync(vacacion.id);
  //   if (res.success) {
  //     this.alert.success_small('Vacación eliminada.');
  //     await this.TraerVacaciones();
  //   }else{
  //     this.alert.error_small(res.msg);
  //   }
    
  // }
  // async eliminarVacacionDocumento(vacacion: any) {
  //   const res = await this.documentoService.eliminarDocumentoAsync(vacacion.id, 'VACACION');
  //   if (res.success) {
  //     this.alert.success_small('Se creo la solicitud para eliminar la vacacion.');
  //     await this.TraerVacaciones();
  //   }else{
  //     this.alert.error_small(res.msg);
  //   }
  // }

