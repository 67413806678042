import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { AdelantosService } from 'src/app/pages/services/adelantos/adelantos.service';
import { BancoService } from 'src/app/pages/services/banco/banco.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Adelantos } from 'src/app/shared/models/adelantos.interface';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-lista-adelantos',
  templateUrl: './lista-adelantos.component.html',
  styleUrls: ['./lista-adelantos.component.css'],
  providers: [DatePipe]
})
export class ListaAdelantosComponent implements OnInit {
  @Input() textoBuscar: string;
  @Input() adelantos_list: Adelantos[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();

  addressForm = this.fb.group({
    fechaInicioContrato: [null, Validators.required]
  });

  update_adelantos: any = {
    id: 0,
    fechaInicioContrato: "",
    fk_trabajador: "",
    empresa: "",
    banco: "",
    tipoCuenta: "",
    numeroCuenta: 0,
    montoAdelanto: 0
  };

  empresa_seleccionado: any;
  debouncer: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['fechaInicioContrato', 'fk_trabajador', 'empresa', 'banco', 'numeroCuenta', 'montoAdelanto', 'actions'];
  dataSource = new MatTableDataSource<Adelantos>(this.adelantos_list);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  bancos: any;
  empresas: any;
  trabajadores: any;
  modalReference: any = null;

  dateRangeForm = this.fb.group({
    start: [null],
    end: [null],
    empresa: [null]
  });

  constructor(
    private fb: FormBuilder,
    private alert: AlertHelper,
    private adelantoService: AdelantosService,
    private modalService: NgbModal,
    private bancoService: BancoService,
    private trabajadoresService: TrabajadoresService,
    private empresaService: EmpresaService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });

    this.loadEmpresas();
    this.loadTrabajadores();
    this.loadBancos();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.adelantos_list) {
      this.dataSource = new MatTableDataSource<Adelantos>(this.adelantos_list);
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const filterLower = filter.toLocaleLowerCase().split(' ');

        const rutTrabajador = data.fk_trabajador.toString();
        const nombreTrabajador = data.Trabajador.nombres.toLocaleLowerCase() + ' ' + data.Trabajador.apellidos.toLocaleLowerCase();
        const nombreEmpresa = data.Empresa.nombre.toLocaleLowerCase();
        const nombreBanco = data.Banco.nombre.toLocaleLowerCase();
        const tipoCuenta = data.tipoCuenta.toLocaleLowerCase();
        const numCuenta = data.numeroCuenta.toString();

        return filterLower.every(
          x => nombreTrabajador.includes(x) || nombreEmpresa.includes(x)
          || nombreBanco.includes(x) || tipoCuenta.includes(x)
          || numCuenta.includes(x) || rutTrabajador.includes(x),
        );
      };

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

    if (changes.textoBuscar) {
      const filterValue = this.textoBuscar;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  loadEmpresas() {
    this.empresaService.GetAll_empresas().subscribe(res => {
      this.empresas = res.data;
    });
  }

  loadTrabajadores() {
    this.trabajadoresService.TraerTrabajadores().subscribe(res => {
      this.trabajadores = res.data;
    });
  }

  loadBancos() {
    this.bancoService.TraerTodos().subscribe(res => {
      this.bancos = res.data;
    });
  }

  applyFilters() {
    const empresaId = this.dateRangeForm.get('empresa')?.value;
    const { start, end } = this.dateRangeForm.value;
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const isInEmpresa = !empresaId || data.Empresa.id === empresaId;
      const isInDateRange = (!start || new Date(data.fechaInicioContrato) >= new Date(start)) &&
                            (!end || new Date(data.fechaInicioContrato) <= new Date(end));
      return isInEmpresa && isInDateRange;
    };

    this.dataSource.filter = JSON.stringify({ empresaId, start, end });
  }

  clearFilters() {
    this.dateRangeForm.reset();
    this.dataSource.filter = '';
  }

  onDateRangeChange(event: MatDatepickerInputEvent<Date>) {
    this.applyFilters();
  }

  ObtenerTrabajador(event: MatSelectChange) {
    this.update_adelantos.fk_trabajador = event.value;
  }

  ObtenerEmpresa(event: MatSelectChange) {
    this.update_adelantos.empresa = event.value;
    this.dateRangeForm.get('empresa')?.setValue(event.value);
  }

  ObtenerBanco(event: MatSelectChange) {
    this.update_adelantos.banco = event.value;
  }

  limpiar_adelantos() {
    this.update_adelantos = {
      id: 0,
      fechaInicioContrato: "",
      fk_trabajador: "",
      empresa: "",
      banco: "",
      tipoCuenta: "",
      numeroCuenta: 0,
      montoAdelanto: 0
    };
  }

  actualizar_adelanto() {
    this.update_adelantos.fechaInicioContrato = this.datePipe.transform(this.update_adelantos.fechaInicioContrato, 'yyyy-MM-dd');
    this.adelantoService.actualizar_adelanto(this.update_adelantos).subscribe(res => {
      if (res.success === true) {
        this.alert.success_small(res.msg!);
        this.modalReference.close();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!);
      }
    });
  }

  open(content: any, id: number) {
    this.update_adelantos.id = id;
    this.adelantoService.Obtener_adelanto(id).subscribe(res => {
      this.update_adelantos = {
        ...this.update_adelantos,
        ...res.data
      };
    });

    this.loadEmpresas();
    this.loadTrabajadores();
    this.loadBancos();
    this.modalReference = this.modalService.open(content);
  }

  eliminar_adelanto(content: any, id: number) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el adelanto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.adelantoService.Eliminar_adelanto(id).subscribe(res => {
          if (res.success === true) {
            this.alert.success_small(res.msg!);
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!);
          }
        });
      }
    });
  }
}
