import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { getMonth, getYear, parseISO } from 'date-fns';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { SolicitudesvacacionesService } from 'src/app/pages/services/solicitudes/solicitudesvacaciones.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { SolicitudesVacaciones } from 'src/app/shared/models/solicitud-vacaciones.interface';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { differenceInDays } from 'date-fns';
import { VacacionesService } from 'src/app/pages/services/vacaciones/vacaciones.service';
import Holidays from 'date-holidays';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service';
import { ComprobanteVacacionesService } from 'src/app/pages/services/PDF-GENERADOR/vacaciones/comprobante-vacaciones.service';
import { environment } from 'src/environments/environment';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
import { FileUploader } from 'ng2-file-upload';

const holidays = new Holidays('CL');

@Component({
  selector: 'app-main-solicitud-vacaciones',
  templateUrl: './main-solicitud-vacaciones.component.html',
  styleUrls: ['./main-solicitud-vacaciones.component.css'],
  providers: [DatePipe]
})
export class MainSolicitudVacacionesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  uploader: FileUploader;
  idSolicitud: number;
  idVacacionCreada: number;
  solicitudActual: {
    createdAt: string,
    diasTomados: number,
    estado: number,
    fechaDesde: string,
    fechaHasta: string,
    fk_trabajador: number,
    id: number,
    updatedAt: string 
  };
  loading: boolean = false;

  trabajadores: any[] = [];
  empresas: any[] = [];
  sucursales: any[] = [];
  src: any;
  estadoSolicitud: any[] = [
    {value: 0, text: 'PENDIENTE'},
    {value: 1, text: 'APROBADA'},
    {value: 2, text: 'RECHAZADA'},
  ];

  fecha:Date;
  fecha2: any;
  diasTomados: number;
  
  fechaInicio:Date;
  fechaFin:Date;

  solicitudesFilter: SolicitudesVacaciones[] = [];

  periodo: Date = new Date();

  // FILTERS
  trabajadorSelect: string = '';
  empresaSelect: string = '';
  sucursalSelect: string[] = [];
  MIN_DATE = parseISO('2023-12-01');
  estadoLiquidacionView: string[] = [];
  estadoSolicitudSelect: any[] = [];
  solicitudes: any[] = [];

  // DROPDOWNS
  
  trabajadoresFilter: any[] = [];
  displayedColumns: string[] = ['select', 'trabajador', 'fechaDesde', 'fechaHasta', 'dias', 'estado', 'acciones'];

  // CONTEXT MENU
  desiciones: MenuItem[] = [];
  moreOptions: MenuItem[] = [];
  user:any;
  modalReference = null;


  dataSource: MatTableDataSource<SolicitudesVacaciones>;
  selection: SelectionModel<SolicitudesVacaciones>;
  
  allTrabajadores: any[] = [];

  selectedTrabajador: any;

  constructor( 
    private solicitudesVacacionesService: SolicitudesvacacionesService,
    private auth: AuthService,
    private alert: AlertHelper,
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private datePipe: DatePipe,
    private vacacionesService: VacacionesService,
    private gestorAsistencia: GestorAsistenciaService,
    private comprobanteVacacionesService: ComprobanteVacacionesService,
    private pdfLibService: PdflibService,
    )
    {
    this.user = this.auth.ObtenerinfoToken();
    const token = this.auth.getToken();
    this.uploader = new FileUploader({
      url: `${environment.API}/rrhh/vacaciones/documento`,
      itemAlias: 'vacacion',
      headers: [{name: 'Authorization', value: `Bearer ${token}`}]
    });
   }

  open(content: any) {
    this.modalReference = this.modalService.open(content)
  }

  obtener_fecha1(event: MatDatepickerInputEvent<Date>) {
    this.fecha = event.value;
    this.fecha2 = this.datePipe.transform(this.fecha, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-dia
    console.log("fecha inicio: ",this.fecha2);
  }

  onFechaInicioChange() {
    console.log(this.fechaInicio);
    this.calcularDiferenciaDias();
  }
  
  onFechaFinChange() {
    console.log(this.fechaFin);
    this.calcularDiferenciaDias();
  }

  private calcularDiferenciaDias() {
    if (this.fechaInicio && this.fechaFin) {
      const f_inicio = new Date(this.fechaInicio);
      const f_fin = new Date(this.fechaFin);
      this.diasTomados = (differenceInDays(f_inicio, f_fin) * -1) + 1;
      console.log(this.diasTomados);
    } else {
      this.diasTomados = null;
    }
  }


  ngOnInit(): void {
    this.getSolicitudes();
    this.getAllTrabajadores();
    this.initUploader();
    //this.obtenerPeriodoLocalStorage();
    this.desiciones = [
      {
        label: 'Aprobar',
        icon: 'pi pi-thumbs-up',
        command: () => this.cambiarEstado(this.idSolicitud, 1),
      },
      {
        label: 'Rechazar',
        icon: 'pi pi-thumbs-down',
        command: () => this.cambiarEstado(this.idSolicitud,2),
      }
    ];
    this.src = {
      urlFile: '',
      blobFile: '',
    }
  }

  initUploader(){
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('id', this.idVacacionCreada);
      form.append('fk_trabajador', this.solicitudActual.fk_trabajador);
    }
     // SIRVE PARA EVITAR CONFLICTOS CON LAS CORS
     this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
  }
  
  FiltrarDatos() {    
    // Verificar si no se ha seleccionado ningún estado ni ningún trabajador
    if ((!this.estadoSolicitudSelect || this.estadoSolicitudSelect.length === 0) && (!this.trabajadorSelect || this.trabajadorSelect.length === 0) && (!this.periodo)) {
      // No se ha seleccionado ningún estado ni ningún trabajador, mostrar todas las solicitudes sin filtrar
      this.dataSource = new MatTableDataSource<SolicitudesVacaciones>(this.solicitudes);
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel<SolicitudesVacaciones>(true, []);
      return;
    }
  
    // Aplicar filtro por estado de solicitud si se ha seleccionado algún estado
    if (this.estadoSolicitudSelect && this.estadoSolicitudSelect.length > 0) {
      this.solicitudesFilter = this.solicitudes.filter(solicitud => {
        return this.estadoSolicitudSelect.includes(solicitud.estado);
      });
    } else {
      // No se ha seleccionado ningún estado, mantener todas las solicitudes
      this.solicitudesFilter = this.solicitudes;
    }
  
    // Aplicar filtro por trabajador si se ha seleccionado algún trabajador
    if (this.trabajadorSelect && this.trabajadorSelect.length > 0) {
      this.solicitudesFilter = this.solicitudesFilter.filter(solicitud => {
        return this.trabajadorSelect.includes(solicitud.fk_trabajador.toString());
      });
    }

    if (this.periodo) {
          this.solicitudesFilter = this.solicitudesFilter.filter(x => parseISO(x.fechaDesde).getMonth() === this.periodo.getMonth() && parseISO(x.fechaDesde).getFullYear() === this.periodo.getFullYear());
        }
  
    // Configurar la tabla (MAT TABLE ANGULAR) con las solicitudes filtradas
    this.dataSource = new MatTableDataSource<SolicitudesVacaciones>(this.solicitudesFilter);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<SolicitudesVacaciones>(true, []);
  }

 getSolicitudes(){
    this.solicitudesVacacionesService.getAllSolicitudes().subscribe( (resp: any) => {
      this.solicitudes = resp.data;
      console.log("solicitudes",resp.data)
     try{
        this.trabajadores = resp.data.map(x => x.Trabajador);
        this.trabajadoresFilter = this.trabajadores.map(x => ({
          value: x.rut.toString(),
          text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
        })).sort((a, b) => a.text.localeCompare(b.text));
  
        this.dataSource = new MatTableDataSource(this.solicitudes);
        this.selection = new SelectionModel<SolicitudesVacaciones>(true, []);
        this.dataSource.paginator = this.paginator;
      }catch{
        this.dataSource = new MatTableDataSource();
      }
    
    })
    
 }

 cambiarEstado(id:number, estado: number){
  const data = {
    estado: estado
  }
    this.solicitudesVacacionesService.updateEstadoSolicitud(id, data).subscribe( (resp: any) => {
      console.log(resp);
      if(resp.success){
        this.alert.success_small(resp.message);
        this.getSolicitudes();
        if (data.estado == 1) {
        this.solicitudesVacacionesService.TraerSolicitud(id).subscribe((res: any) => {
            const respuesta = res.data[0]
            this.solicitudActual = respuesta
            console.log('respuesta', respuesta)
            const {habiles, inhabiles} = this.dateRangeChange(respuesta.fechaDesde, respuesta.fechaHasta)
          
            const vacacion = {
            f_start: respuesta.fechaDesde,
            f_end: respuesta.fechaHasta,
            fk_trabajador: respuesta.fk_trabajador,
            habiles,
            inhabiles,
            pendiente: null,
            periodo: new Date().getFullYear(),
            folio: null,
            estado: respuesta.estado,
            idSolicitudVacaciones: respuesta.id,
            eliminado: 0,
            userAt: this.user.nombre
          }
          this.vacacionesService.EnviarVacacion(vacacion).subscribe((res: any) => {
            console.log('vacacion creada',res.vacacionCreada.id)
            this.idVacacionCreada = res.vacacionCreada.id
          })
          const calendario = {
            id: 0,
            start: respuesta.fechaDesde,
            end: respuesta.fechaHasta,
            timeStart: null,
            timeEnd: null,
            fk_razon: 2,
            razon: '',
            fk_trabajador: respuesta.fk_trabajador,
            trabajador: respuesta.fk_trabajador,
            motivo: 'Vacaciones',
            dsueldo: 'NO',
            dbono: 'NO',
            dias_faltados: null,
            idSolicitudPermiso: null
          }
          this.gestorAsistencia.crear(calendario).subscribe((resp: any) => {
            console.log('calendario', resp)
          })
          this.trabajadoresService.TraerTrabajadoresRut(respuesta.fk_trabajador).subscribe((res: any) => {
            const trabajadorResponse = res.data[0]
            console.log('trabajador', trabajadorResponse)

            const trabajador = {
              folio: '0000',
              ubicacion: trabajadorResponse.Empresa_contratante.ubicacion,
              nombre: trabajadorResponse.nombres,
              apellido: trabajadorResponse.apellidos,
              fk_trabajador: respuesta.fk_trabajador,
              f_start: respuesta.fechaDesde,
              f_end: respuesta.fechaHasta,
              palabras: '',
              habiles: habiles,
              inhabiles: inhabiles,
              pendiente: '-',
              periodo: new Date().getFullYear(),
              empresa: trabajadorResponse.Empresa_contratante.nombre,
            }
            
            let fechaInicio = new Date(respuesta.fechaDesde);
            let fechaHasta = new Date(respuesta.fechaHasta)

            fechaInicio.setDate(fechaInicio.getDate() + 1);
            fechaHasta.setDate(fechaHasta.getDate() + 1);
            const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
            trabajador.palabras = "Desde el " + new Date(fechaInicio).toLocaleDateString('es-CL', options) + " hasta el " + new Date(fechaHasta).toLocaleDateString('es-CL', options);

          // aqui se debe de crear el pdf
          this.comprobanteVacacionesService.convetToPDF(trabajador)
          .then((resultado) => {
            console.log('resultado',resultado);
            this.src = resultado;
            console.log('src', this.src)
            const fileName = String('Vacaciones-' + trabajadorResponse.nombres + '-' + trabajadorResponse.apellidos + new Date().toISOString() + '.pdf').replace(' ', '-');
            const pdfFile = new File([this.src.blobFile], fileName, { type: 'application/pdf' });
            // TODO: cuando exista una forma de guardar las firmas de los trabajadores, aquí se debe obtener su ruta
            const rutaFirma = `${environment.API}/firmas/fake-firma.png`
            // El formato es para ubicar la firma en las coordenadas correctas
            const formato = 'vacacion'
            this.pdfLibService.agregarFirmaBlobDesdeArchivo(pdfFile, rutaFirma, formato).then(res => {
              const pdfFirmado = new File([res], fileName, { type: 'application/pdf' });
              // initUploader para refrescar el valor del formulario antes de guardar en la tabla documentos
              this.initUploader()
              this.uploader.addToQueue([pdfFirmado]);
              this.uploader.uploadAll();
              this.uploader.onSuccessItem = async (item: any, response: any, status: any, headers: any) => {
                const res = JSON.parse(response);
                console.log(res)
              }
              this.uploader.onErrorItem = async (item: any, response: any, status: any, headers: any) => {
                console.log('item', item)
                console.log('response', response)
                console.log('status', status)
                console.log('headers', headers)
                //const res = JSON.parse(response);
              }
            })
          })
          })
        })
        }
      }
    })
 }

 dateRangeChange(dateRangeStart, dateRangeEnd) {
  const fechaInicio = new Date(dateRangeStart)
  const fechaFin = new Date(dateRangeEnd)
  let Difference_In_Time = fechaFin.getTime() - fechaInicio.getTime();

  //To calculate the no. of days between two datesxx
  var Difference_In_Days = (Difference_In_Time + 86400000) / (1000 * 3600 * 24);
  const habiles = this.getBusinessDatesCount(fechaInicio, fechaFin);
  const inhabiles = Difference_In_Days - habiles;
  return {habiles, inhabiles}
}

getBusinessDatesCount(startDate, endDate) {
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      const esFeriado = holidays.isHoliday(curDate);
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !esFeriado) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }   

 getAllTrabajadores(){
  this.trabajadoresService.TraerTrabajadores().subscribe(
    (res) => {
      if (res.success) {
        this.allTrabajadores = res.data.map(x => ({value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }))
          .sort((a, b) => a.text.localeCompare(b.text));

      }
    }
  )
}

 isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}
masterToggle() {
  if (this.isAllSelected()) {
    this.selection.clear();
    return;
  }
  const registros = this.dataSource.data.filter(x => !x.estado);
  this.selection.select(...registros);
}

 onMonthChandler(event: any) {
  this.periodo = event;
  this.FiltrarDatos();
}

async AllDesicion(desicion: boolean){
  const seleccionados: any[] = this.selection.selected;
  if (desicion) {
    const comentario = 'SOLICITUD APROBADA';   
    this.CrearAprobacionBulk(1,seleccionados);
  }else{
    Swal.fire({
      title: "Se va a RECHAZAR las solicitudes de vacaciones",
      text: "Ingrese un comentario para RECHAZAR las solicitudes",
      input: "textarea",
      inputPlaceholder: "Ingrese un comentario",
      showCancelButton: true,
      cancelButtonText: "CANCELAR",
      confirmButtonText: 'RECHAZAR',
      confirmButtonColor: '#d9342b',
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
         const comentario = result.value;
        this.CrearAprobacionBulk(2,seleccionados);
      }
    });
  }
}
  // CrearAprobacionBulk(estado: number, seleccionados: any[]) {
  //  console.log(estado, seleccionados);
  // }


  async CrearAprobacionBulk(estado: number, seleccionados: any[]) {
    const aprobaciones = [];
    for (const seleccionado of seleccionados) {
      // CREAR OBJETO APROBACION
      console.log(seleccionado);
      const aprobacion = {
        estado: estado,
        id: seleccionado.id,
      }
      // AGREGAR A ARRAY DE APROBACIONES
      aprobaciones.push(aprobacion);
    }
    // CREAR LAS APROBACIONES
    const res = await this.solicitudesVacacionesService.bulkCreateAprobaciones(aprobaciones);
    if (!res.success) {
      this.alert.errorAlert(res.msg);
      return;
    }else{
      this.getSolicitudes();
      this.selection.clear();
      this.alert.success_small(res.msg);
    }
    
}

getIdSolicitud(id: any){
  this.idSolicitud = id;
}


guardarSolicitud(){
  console.log(this.selectedTrabajador);
  const data = {
    rut: this.selectedTrabajador,
    f_inicio: this.fechaInicio,
    f_fin: this.fechaFin,
    dias: this.diasTomados
  }
  console.log(data);
  this.solicitudesVacacionesService.generarSolicitud(data).subscribe( (resp: any) => {
    if(resp.success){
      this.alert.success_small(resp.message);
      this.getSolicitudes();
      this.modalReference.close();
      this.limpiarFormulario();
    }
  })
}

limpiarFormulario(){
  this.selectedTrabajador = undefined;
  this.diasTomados = undefined;
  this.fechaInicio = undefined;
  this.fechaFin = undefined;
}

async verPDF(element: any) {
  try {
    console.log('element', element.id)
    let permisoId = ''
    this.vacacionesService.TraerPorIdSolicitudVacacion(element.id).subscribe(async(res: any) => {
      // Obtener el ID del archivo
      permisoId = res.data[0].id
      console.log('permiso id', permisoId)
      // Llamar al servicio para obtener la información del archivo
      const resp = await this.vacacionesService.TraerArchivo(permisoId).toPromise();
      console.log('resres', resp)
      const documentos = resp.data;
      console.log('doc', documentos)
      // Obtener la ruta completa del PDF
      const rutaPDF = `${environment.API}/vacaciones/${documentos[0].nombreDoc}`;
      // Descargar el archivo PDF como un blob
      const pdfBlob = await fetch(rutaPDF).then(res => res.blob());
      // visualizar PDF
      this.pdfLibService.visualizarBlob(pdfBlob);
    })
   
  } catch (error) {
    console.error('Error al cargar el PDF:', error);
  }
}

}
