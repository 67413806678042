<mat-tab-group class="card_group_trabajadores">
  <mat-tab label="Liquidaciones">
      <br />


<table class="example-full-width" cellspacing="0">
    <tr>
        <td>
             <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Dias Faltados</mat-label>
                <input type="number"  required [(ngModel)]= "trabajador.pendiente"  matInput #input name ="Dias Inasistidos">
              </mat-form-field>
        </td>
    </tr>

  </table>
  <h4>Generar liquidacion del 31/{{month}}/{{year}}</h4>

</mat-tab>
<mat-tab label="Haberes por Trabajador">
    <!-- <hr /> -->
    <app-listar-haberes-trabajador [rut]= "rut"></app-listar-haberes-trabajador>
</mat-tab>
</mat-tab-group>







&nbsp;  <button style="background-color: green" (click)="printpdf()" mat-raised-button>Generar PDF <mat-icon>picture_as_pdf</mat-icon></button>
  &nbsp; &nbsp; <button *ngIf="renderPdf"  style="background-color: rgb(207, 51, 3);" (click)="imprimir()" mat-raised-button>Descargar PDF <mat-icon>picture_as_pdf</mat-icon></button>


  <mat-progress-bar *ngIf="!render" mode="indeterminate"></mat-progress-bar>


  <div *ngIf="renderPdf"class="pdf-container">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="true"
        style="width: 100%; height: 800px;"
      ></pdf-viewer>
    </div>
