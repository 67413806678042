<div class="animate__animated animate__slideInUp">


    <mat-card>
        <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
            Implementos y Uniformes
        </h3>

        <app-main-buscar-filter
            [num_trab_finiquitados]="num_trab_finiquitados"
            (textSearch)="applyFilter($event)"
            (viewFiniquitadosEmit)="viewDatosFiniquitados($event)">
        </app-main-buscar-filter>
        <!-- <mat-form-field appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput placeholder="texto a buscar" #input (keyup)="applyFilter($event)">
        </mat-form-field> -->

        <table mat-table id="tabla" matSort [dataSource]="dataSource">

            <ng-container matColumnDef="rut">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> RUT </th>
                <td mat-cell *matCellDef="let element"> {{ element.rut | formatoRut }} </td>
            </ng-container>


            <ng-container matColumnDef="nombres">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> NOMBRE </th>
                <td mat-cell *matCellDef="let element"> {{ element.nombres | uppercase }} {{ element.apellidos | uppercase}} </td>
            </ng-container>

            <ng-container matColumnDef="agregarI">
                <th mat-header-cell *matHeaderCellDef> AGREGAR IMPLEMENTOS </th>

                <td class="padding-table" mat-cell *matCellDef="let element">
                    <!-- <span *ngIf=" ff === 1" >  -->
                    <button mat-button (click)="open2(contenido,element.rut)" class="button_color_primary"
                        style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">add</mat-icon>
                    </button> &nbsp;


                    <!-- </span> -->
                    <!-- <span *ngIf=" ff === 0" >   -->

                    <!-- Hay registro de implementos
        
                    
            </span>  -->

                </td>
            </ng-container>
            <ng-container matColumnDef="agregarU">
                <th mat-header-cell *matHeaderCellDef> AGREGAR UNIFORME </th>



                <td class="padding-table" mat-cell *matCellDef="let element">
                    <!-- <span *ngIf=" sacaragregar === 1" >  -->
                    <button mat-button (click)="open3(contenid,element.rut)" class="button_color_primary"
                        style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">add</mat-icon>
                    </button> &nbsp;

                    <!-- </span> -->

                    <!-- <span *ngIf=" sacaragregar === 0" >  

                        Hay registro de implementos
                
                    </span> -->
                </td>
            </ng-container>




            <ng-container matColumnDef="subir">
                <th mat-header-cell *matHeaderCellDef> LISTA - SUBIR </th>

                <td class="padding-table" mat-cell *matCellDef="let element">


                    <button mat-button (click)="open(content3 ,element.rut)" class="button_color_primary"
                        style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">listbulleted</mat-icon>
                    </button>



                </td>
            </ng-container>

            <ng-container matColumnDef="implementos">
                <th mat-header-cell *matHeaderCellDef> PDF IMPLEMENTOS </th>

                <td class="padding-table" mat-cell *matCellDef="let element">



                    <button mat-button (click)="printpdf(element.rut)" class="button_color_primary"
                        style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">download</mat-icon>
                    </button>



                </td>
            </ng-container>

            <ng-container matColumnDef="uniforme">
                <th mat-header-cell *matHeaderCellDef> PDF UNIFORME </th>

                <td class="padding-table" mat-cell *matCellDef="let element">


                    <button mat-button (click)="printpdfU(element.rut)" class="button_color_primary"
                        style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">download</mat-icon>
                    </button>




                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1]" showFirstLastButtons
            aria-label="Seleccionar pagina de Trabajadores.">
        </mat-paginator>
    </mat-card>
</div>


<!-- MODAL PARA LISTAR Comprobantes de vacaciones POR RUT  l-->
<ng-template style="border: 0px; z-index: 100" #contenido let-modal2>
    <div class="modal-header" style="text-align: center">
        <h4>Agregar Implementos </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-agregar-implementos [rut]='RutTrabajador'> </app-agregar-implementos>

</ng-template>


<ng-template style="border: 0px; z-index: 100" #contenid let-modal2>
    <div class="modal-header" style="text-align: center">
        <h4>Agregar Uniforme </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>



    <app-agregar-uniforme [rut]='RutTrabajador'> </app-agregar-uniforme>


</ng-template>



<!-- MODAL PARA LA GENERACION DEL COMPROBANTE DE VACACIONES llama a la -->



<ng-template style="border: 0px; z-index: 100;  " #content3 let-modal>

    <div class="modal-header" style="text-align: center ; ">

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header> -->
    <div class="container">

        <div class="row ">
            <div class="col Inline ">
                <mat-panel-title>
                    <h1>IMPLEMENTOS</h1>
                </mat-panel-title>
                <p-button  (click)="implemento()">
                    <mat-icon>computer
                    </mat-icon>
                </p-button>

                <!-- </mat-expansion-panel-header> -->


                <p-dialog [(visible)]="implementoo" [modal]="true" [style]="{width: '100vw'}" [maximizable]="true"
                    [baseZIndex]="10000" [draggable]="false" [resizable]="false">

                    <app-ver-implementos [rut]='RutTrabajador'></app-ver-implementos>

                </p-dialog>


            </div>
            <div class="col">
                <mat-panel-title>
                    <h1>UNIFORME</h1>
                </mat-panel-title>
                <p-button (click)="uniforme()">
                    <mat-icon>accessibility

                    </mat-icon>


                    <!-- </mat-expansion-panel-header> -->
                </p-button>


                <p-dialog [(visible)]="uniformee" [modal]="true" [style]="{width: '100vw'}" [maximizable]="true"
                    [baseZIndex]="10000" [draggable]="false" [resizable]="false">

                    <app-ver-uniforme [rut]='RutTrabajador'></app-ver-uniforme>

                </p-dialog>

            </div>
        </div>

        <!-- </mat-expansion-panel>
        <mat-expansion-panel > -->
        <!-- <mat-expansion-panel-header> -->

    </div>


    <!-- 
        </mat-expansion-panel>
    </mat-accordion> -->






</ng-template>