import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { ImplementosService } from 'src/app/pages/services/implementos/implementos.service';
import { ImplementosUService } from 'src/app/pages/services/implementosU/implementosU.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-agregar-implementos.',
  templateUrl: './agregar-implementos.component.html',
  styleUrls: ['./agregar-implementos.component.css'],
  providers: [DatePipe]
})
export class AgregarImplementosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @Input() rut !: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  debouncer: Subject<boolean> = new Subject();

  constructor(

    private modalService: NgbModal,
    private trabajadorService: TrabajadoresService,
    private datePipe: DatePipe,
    private alert: AlertHelper,
    private implementoService: ImplementosService,
    private implementoUService: ImplementosUService,

  ) { }





  /////—————  Variables   ————-\\\\\\



  dataSource: any;
  RutTrabajador: any;
  TrabajadorList: any;
  mostrar: any = 0;
  checked: boolean = false;

  poleron: any;
  polera: any;
  pantalon: any;
  zapatos: any;
  fechaT: Date;
  fechaFormatT: string;
  fecha: Date;
  fechaFormat: string;
  mas: any = 0;
  mass: any = 0;
  masss: any = 0;
  massss: any = 0;
  masssss: any = 0;
  constt: any =0;
  listo: any = 0;
  listoU: any = 0;

  mandado: any;

  /////————— Variable BD AND TABLA   ————-\\\\\\


  displayedColumns: string[] = [
    'rut',
    'nombres',
    'acciones'
  ]

  implementos: any = {
    id: 0,
    fechaI: "",
    equipo: "",
    modelo: "",
    equipo2: "",
    modelo2: "",
    equipo3: "",
    modelo3: "",
    equipo4: "",
    modelo4: "",
    equipo5: "",
    modelo5: "",
    equipoo: "",
    modeloo: "",
    observacion: "",
    estadoI: "",
    fk_trabajador: 0

  }


  /////—————  Select   MatSelectChange————-\\\\\\


  onChangeSlide($event: any) {

    if ($event.checked) {
      this.mostrar = 1
      this.checked = true
    }
    else {
      this.mostrar = 0
      this.checked = false

    }
  }



  unoMas($event: any) {

    if ($event.checked) {
      this.mas = 1
      this.checked = true
    }
    else {
      this.mas = 0
      this.checked = false

    }
  }



  unoMass($event: any) {

    if ($event.checked) {
      this.mass = 1
      this.checked = true
    }
    else {
      this.mass = 0
      this.checked = false

    }
  }

  unoMasss($event: any) {

    if ($event.checked) {
      this.masss = 1
      this.checked = true
    }
    else {
      this.masss = 0
      this.checked = false

    }
  }


  unoMassss($event: any) {

    if ($event.checked) {
      this.massss = 1
      this.checked = true
    }
    else {
      this.massss = 0
      this.checked = false

    }
  }
  unoMasssss($event: any) {

    if ($event.checked) {
      this.masssss = 1
      this.checked = true
    }
    else {
      this.masssss = 0
      this.checked = false

    }
  }






  /////—————  Select   MatDatepickerInputEvent————-\\\\\\



  obtener_fechaImplementos(event: MatDatepickerInputEvent<Date>) {

    this.fecha = event.value;
    this.fechaFormat = this.datePipe.transform(this.fecha, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-

  }



  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\



  open(content: any) {


    this.modalService.open(content);

  }
  TraerDatos(event: boolean) {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res) => {
        this.dataSource = [];
        this.TrabajadorList = res.data;


        // this.list = datos.filter((x:any)=>{ return x.Trabajador.Empresa_contratante.nombre === this.empresas[i];});

        // let datosTramitados= res.data ? res.data.filter((x:any)=>{ return x.estado != "SUBIDA"}) :[];


        this.dataSource = new MatTableDataSource(this.TrabajadorList);
        this.dataSource.paginator = this.paginator
      });
  }



  generarImplento() {



    this.implementos.estadoI = "PENDIENTE";

    this.implementos.fk_trabajador = this.rut;

    this.implementos.fechaI = this.fechaFormat;

  

    this.implementoService.CrearImplementos(this.implementos).subscribe((res: any) => {

      if (res.success == true) {

      

        this.alert.success_small(res.msg!)
        this.ngOnInit();
        this.modalService.dismissAll();
        this.debouncer.next(true);

      } else {
        this.alert.error_small(res.msg)
      }

    })

  }



  /////—————AL INICIAR EL MODULO   ————-\\\\\\



  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });

    this.TraerDatos(true);

    this.constt = 0;

    this.implementoService.TraerPorRut(this.rut).subscribe((res: any) => {



      if (res.data == false) {

        //console.log("Este usuario no tiene implementos")
      

        this.constt = 0;

        
      }else{
        //console.log("Este usuario si tiene implementos")
        
        
        this.constt = 1;

      }

    })



  }

}
