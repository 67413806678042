import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MainEstadoCivilComponent } from './main-estado-civil/main-estado-civil.component';
import { AgregarEstadoCivilComponent } from './agregar-estado-civil/agregar-estado-civil.component';
import { ListaEstadoCivilComponent } from './lista-estado-civil/lista-estado-civil.component';



@NgModule({
  declarations: [
    MainEstadoCivilComponent,
    AgregarEstadoCivilComponent,
    ListaEstadoCivilComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
  ],
  exports:[
    MainEstadoCivilComponent,
    AgregarEstadoCivilComponent,
    ListaEstadoCivilComponent,
  ]
})
export class EstadoCivilModule { }
