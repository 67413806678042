<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Documentos de Denuncia
</h3>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
    </ng-container>

    <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef> DESCRIPCIÓN </th>
        <td mat-cell *matCellDef="let element"> {{ element.description }} </td>
    </ng-container>

    <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> FECHA </th>
        <td mat-cell *matCellDef="let element"> {{ element.createdAt | date:'shortDate' }} </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button (click)="DescargarDocumento(element.id)" mat-button class="button_color_primary mr-2" style="background-color:rgb(37, 199, 228);">
              <mat-icon style="color: white">download</mat-icon>
            </button>
            <button (click)="EliminarDocumento(element.id)" mat-raised-button color="warn" [disabled]="loading">
                <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
                <mat-icon style="color: white" *ngIf="loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
