import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { parseISO } from 'date-fns';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { HonorarioService } from 'src/app/pages/services/honorarios/honorario.service';
import { SolicitudesHonorarios } from 'src/app/shared/models/solicitud-honorarios-interface';
import { environment } from 'src/environments/environment';
import { PdflibService } from 'src/app/pages/services/pdf-lib/pdflib.service';
@Component({
  selector: 'app-solicitudes-honorario',
  templateUrl: './solicitudes-honorario.component.html',
  styleUrls: ['./solicitudes-honorario.component.css']
})
export class SolicitudesHonorarioComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  idSolicitud: number;
  loading: boolean = false;

  trabajadores: any[] = [];
  empresas: any[] = [];
  sucursales: any[] = [];
  estadoSolicitud: any[] = [
    {value: 0, text: 'PENDIENTE'},
    {value: 1, text: 'APROBADA'},
    {value: 2, text: 'RECHAZADA'},
  ];

  fecha:Date;
  fecha2: any;
  diasTomados: number;

  fechaInicio:Date;
  fechaFin:Date;

  solicitudesFilter: SolicitudesHonorarios[] = [];

  periodo: Date = new Date();

  id_honorario: number;

  // FILTERS
  trabajadorSelect: string = '';
  empresaSelect: string = '';
  sucursalSelect: string[] = [];
  MIN_DATE = parseISO('2023-12-01');
  estadoLiquidacionView: string[] = [];
  estadoSolicitudSelect: any[] = [];
  solicitudes: any[] = [];
  // DROPDOWS
  trabajadoresFilter: any[] = [];

  displayedColumns: string[] = ['select', 'trabajador', 'fecha', 'alcanceLiq' ,'estado', 'acciones'];

  // CONTEXT MENU
  desiciones: MenuItem[] = [];
  moreOptions: MenuItem[] = [];
  user:any;
  modalReference = null;


  dataSource: MatTableDataSource<SolicitudesHonorarios>;
  selection: SelectionModel<SolicitudesHonorarios>;
  
  allTrabajadores: any[] = [];

  selectedTrabajador: any;
  aprobaciones: any;
  liquidaciones: any;
  nombreTrabajador: string;
  honorarios: any;
  modalViewHonorario: { honorario: any; documento1: string; documento2: string };
  FIRMA_NAME: any;
  motivo: any;

  constructor( 
    private auth: AuthService,
    private alert: AlertHelper,
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private honorariosService: HonorarioService,
    private pdfLibService: PdflibService,
    private trabajadorService: TrabajadoresService,
    )
    {
    this.user = this.auth.ObtenerinfoToken();
   }

  open(content: any) {
    this.modalReference = this.modalService.open(content)
  }

  ngOnInit(): void {
    //this.getSolicitudes();
    this.getHonorarios();
    this.getAllTrabajadores();
    this.cargarEmpresas();
    this.cargarSucursales();
    //this.obtenerPeriodoLocalStorage();
    this.desiciones = [
      {
        label: 'Aprobar',
        icon: 'pi pi-thumbs-up',
        command: () => this.cambiarEstado(this.id_honorario, {estado:1, motivo: null}),
      },
      {
        label: 'Rechazar',
        icon: 'pi pi-thumbs-down',
        command: () => this.cambiarEstado(this.id_honorario, {estado: 2, motivo: this.motivo}),
      }
    ];
    this.moreOptions = [
      {
        label: 'Cambiar decision',
        icon: 'pi pi-pencil',
        command: () => {
            //this.cambiarDecision();
        }
      },
      {
        label: 'Ver historial de decisiones',
        icon: 'pi pi-history',
        command: () => {
            //this.VerHistorialDecisiones();
        }
      }
    ];

    this.TraerDatosFilter();
  }
  
  FiltrarDatos() {
    
    let solicitudesFiltradas = this.solicitudes;
  
    if (this.estadoSolicitudSelect && this.estadoSolicitudSelect.length > 0) {
      solicitudesFiltradas = solicitudesFiltradas.filter(solicitud => {
        return this.estadoSolicitudSelect.includes(solicitud.estado);
      });
    }
  
    if (this.trabajadorSelect) {
      solicitudesFiltradas = solicitudesFiltradas.filter(x => x.fk_trabajador === parseInt(this.trabajadorSelect));
    }
  
    if (this.empresaSelect) {
      solicitudesFiltradas = solicitudesFiltradas.filter(x => x.trabajadors.empresa_contratante === parseInt(this.empresaSelect));
    }
  
    if (this.sucursalSelect && this.sucursalSelect.length > 0) {
      solicitudesFiltradas = solicitudesFiltradas.filter(solicitud => {
        return this.sucursalSelect.includes(solicitud.trabajadors.sucursal.toString());
      });
    }
  
    if (this.periodo) {
      solicitudesFiltradas = solicitudesFiltradas.filter(x => parseISO(x.fecha).getMonth() === this.periodo.getMonth() && parseISO(x.fecha).getFullYear() === this.periodo.getFullYear());
    }
  
    this.dataSource = new MatTableDataSource<SolicitudesHonorarios>(solicitudesFiltradas);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<SolicitudesHonorarios>(true, []);
  }
  

  async ObtenerDocumento(id: number) {
    const res = await this.honorariosService.TraerArchivoAsync(id);
    if (!res.success) {
      console.error(res.msg);
      this.alert.errorAlert('Hubo un error al obtener el archivo.');
      return;
    }
    return res.data;
  }

  async verDetalle(id: number, viewHonorarioModal: any) {
    if (!this.verificarRol()) return;

    console.log(id);

    const documentos = await this.ObtenerDocumento(id);  
  console.log(documentos);
     const documento = documentos[documentos.length - 1];

     const honorario = this.solicitudes.find(x => x.id === id);
     console.log(honorario);
    const trabajador = honorario.fk_trabajador;
    this.nombreTrabajador = String(honorario.trabajadors.nombres + ' ' + honorario.trabajadors.apellidos).toUpperCase();
    console.log(this.nombreTrabajador);
    this.modalViewHonorario = {
      honorario: honorario,
      documento1: `${environment.API}/honorarios/${documentos[0].ruta}`,
      documento2: `${environment.API}/honorarios/${documentos[1].ruta}`
    }
    const ref = this.modalService.open(viewHonorarioModal, { fullscreen: true, scrollable: true });
    ref.dismissed.subscribe(async () => {
        await this.getHonorarios();
    });
  }

  async verHonorario(honorario: any){
    const id = honorario.id;
    const estado = honorario.estado;
    const documentos = await this.ObtenerDocumento(id);
    // OBTENER ULTIMO ELEMENTO DEL ARRAY DE DOCUMENTOS
    const documento1 = documentos[0];
    const documento2 = documentos[1];
    const ruta = `${environment.API}/honorarios/${documento1.ruta}`;
    const ruta2 = `${environment.API}/honorarios/${documento2.ruta}`;
    switch (estado) {
      case 1:
        const firma = `${environment.API}/firmas/${this.FIRMA_NAME}`;
        const blobFirma = await this.pdfLibService.agregarFirmaBlob(ruta, firma);
        this.pdfLibService.visualizarBlob(blobFirma);
        break;
      case 2:
        const blobMarca = await this.pdfLibService.agregarMarcaAguaBlob(ruta, 'RECHAZADA');
        this.pdfLibService.visualizarBlob(blobMarca);
        break;
      default:
        window.open(`${environment.API}/honorarios/` + ruta);
        window.open(`${environment.API}/honorarios/` + ruta2);
        break;
    }
    // window.open(`${environment.API}/liquidaciones/${documento.ruta}`, '_blank');
  }

  verificarRol(): boolean {
    if (this.user?.rol !== 'ADMIN' && this.user?.rol !== 'GERENCIA') {
      alert('No tiene permisos para realizar esta acción');
      return false;
    }
    return true;
  }

  getHonorarios() {
    this.honorariosService.getAllHonorarios().subscribe((resp: any) => {
      this.solicitudes = resp.data;
  
      this.solicitudes.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
      });
  
      try {
        this.dataSource = new MatTableDataSource(this.solicitudes);
        this.selection = new SelectionModel<SolicitudesHonorarios>(true, []);
        this.dataSource.paginator = this.paginator;
      } catch {
        this.dataSource = new MatTableDataSource();
      }
    });
  }
  

 cambiarEstado(id:number, data: any){
 
  console.log(data);
  if(data.estado == 1){
    this.honorariosService.updateEstadoHonorario(id, data).subscribe( (resp: any) => {
      console.log(resp);
      if(resp.success){
        this.alert.success_small(resp.msg);
      
        this.getHonorarios();
      }
    })
  }else{
    Swal.fire({
      title: 'Motivo de rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        const motivo = result.value;
        
        if (motivo) {
         
          const data = {
            estado: 2,
            motivo: motivo
          };
          console.log("Datos para rechazar: ", data);
          
          this.honorariosService.updateEstadoHonorario(id, data).subscribe((resp: any) => {
            console.log(resp);
            if (resp.success) {
              this.alert.success_small(resp.msg);
              this.getHonorarios();
            }
          });
        } else {
          console.error("No se ingresó ningún motivo.");
        }
      }
    });
    
}
  }
 

 getAllTrabajadores(){
  this.trabajadoresService.TraerTrabajadores().subscribe(
    (res) => {
      if (res.success) {
        this.allTrabajadores = res.data.map(x => ({value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }))
          .sort((a, b) => a.text.localeCompare(b.text));

      }
    }
  )
}

async TraerDatosFilter() {
  const res = await this.trabajadorService.TraerTrabajadoresAsync();
  this.trabajadores = res.data.sort((a: any, b: any) => {
    const nombresA = `${a.nombres.toUpperCase()} ${a.apellidos.toUpperCase()}`;
    const nombresB = `${b.nombres.toUpperCase()} ${b.apellidos.toUpperCase()}`;
    return nombresA.localeCompare(nombresB);
  });
  this.empresas = this.cargarEmpresas();
  this.sucursales = this.cargarSucursales();
  this.trabajadoresFilter = this.trabajadores.map(x => ({
    value: x.rut.toString(),
    text: `${x.nombres.toUpperCase()} ${x.apellidos.toUpperCase()}`
  })).sort((a, b) => a.text.localeCompare(b.text));
}

cargarEmpresas(): any[] {
  const empresas = this.trabajadores.map(x => ({
    value: x.Empresa_contratante.id.toString(),
    text: x.Empresa_contratante.nombre.toUpperCase()
  }));
  const empresasFilter = empresas.filter((x, index, self) => {
    return self.findIndex(t => t.value === x.value) === index;
  });
  return empresasFilter.sort((a, b) => a.text.localeCompare(b.text));
}
cargarSucursales(): any[] {
  const sucursales = this.trabajadores.map(x => ({
    value: x.Sucursal.id.toString(),
    text: x.Sucursal.nombre.toUpperCase()
  }));
  const sucursalesFilter = sucursales.filter((x, index, self) => {
    return self.findIndex(t => t.value === x.value) === index;
  });
  return sucursalesFilter.sort((a, b) => a.text.localeCompare(b.text));
}

 isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}
masterToggle() {
  if (this.isAllSelected()) {
    this.selection.clear();
    return;
  }
  const registros = this.dataSource.data.filter(x => !x.estado);
  this.selection.select(...registros);
}

 onMonthChandler(event: any) {
  this.periodo = event;
  this.FiltrarDatos();
}

async AllDesicion(desicion: boolean){
  const seleccionados: any[] = this.selection.selected;
  console.log(seleccionados);
  if (desicion) {
    const comentario = 'SOLICITUD APROBADA';   
    this.CrearAprobacionBulk(1,seleccionados, comentario);
  }else{
    Swal.fire({
      title: "Se va a RECHAZAR las solicitudes de vacaciones",
      text: "Ingrese un comentario para RECHAZAR las solicitudes",
      input: "textarea",
      inputPlaceholder: "Ingrese un comentario",
      showCancelButton: true,
      cancelButtonText: "CANCELAR",
      confirmButtonText: 'RECHAZAR',
      confirmButtonColor: '#d9342b',
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
         const comentario = result.value;
        this.CrearAprobacionBulk(2,seleccionados, comentario);
      }
    });
  }
}

  getIdHonorario(id:number){
    console.log(id);
    this.id_honorario = id;
  }

  async CrearAprobacionBulk(estado: number, seleccionados: any[], comentario: string) {
    const aprobaciones = [];
    for (const seleccionado of seleccionados) {
      // CREAR OBJETO APROBACION
      console.log(seleccionado);
      const aprobacion = {
        estado: estado,
        id: seleccionado.id,
        motivo: comentario,
      }
      // AGREGAR A ARRAY DE APROBACIONES
      aprobaciones.push(aprobacion);
    }
    // CREAR LAS APROBACIONES
    const res = await this.honorariosService.bulkCreateAprobaciones(aprobaciones);
    if (!res.success) {
      this.alert.errorAlert(res.msg);
      return;
    }else{
      this.getHonorarios();
      this.selection.clear();
      this.alert.success_small(res.msg);
    }
    
}

}
