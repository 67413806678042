import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core'
import { Subject, debounceTime } from 'rxjs'
import { HorasExtras } from 'src/app/shared/models/horasExtras.interface'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { AlertHelper } from '../../../../shared/components/helpers/alert.helpers'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { HorasExtrasService } from 'src/app/pages/services/horasExtras/horasExtras.service'
import Swal from 'sweetalert2'
import { parseISO } from 'date-fns'

@Component({
  selector: 'app-lista-horas-extras',
  templateUrl: './lista-horas-extras.component.html',
  styleUrls: ['./lista-horas-extras.component.css']
})
export class ListaHorasExtrasComponent implements OnInit {


  //Add lista trabajadores
  @Output() horasExtrasValue: EventEmitter<number> = new EventEmitter()
  @Input() rut!:any;
  @Input() periodo!:Date;

  debouncer: Subject<boolean> = new Subject()
  optionsDecimal = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 2, // si mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  }

  textSearch: string;
  
  update_horasExtras: HorasExtras = {
    rut: 0,
    trabajador: '',
    fecha: "",
    horasExtras: 0,
    descripcion: ""
  }
  listaHoras: any;
  varibles: any;

  modalReference = null; 

  displayedColumns: string[] = [
    'trabajador',
    'fecha',
    'horasExtras',
    'descripcion',
    'actions'
  ]
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  dataSource: any
  horasExtras: any;
  trabajadores_aux: any;
  datePipe: any
  totalvalorhoras: any;
  fecha:any ;
  onDebounce = new EventEmitter<any>();

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private horasExtrasService: HorasExtrasService
  ) { }

  async ngOnInit() {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
    // SI NO SE ENVIA RUT, TRAER TODAS LAS HORAS EXTRAS
    if (!this.rut) await this.TraerDatos(true);
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.rut || changes.periodo) {
      await this.TraerDatos(true);
    }
  }

  open(content: any, id: number) {
    this.horasExtrasService.TraerPorId(id).subscribe(res => {

      this.listaHoras = {};
      this.listaHoras = res.data;


    })
   this.modalReference= this.modalService.open(content)
  }


  applyFilter() {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = this.textSearch;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Eliminar

  eliminar(content: any, id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara de las horas extras",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.horasExtrasService.Eliminar(id).subscribe(res => {
          if (res.success == true) {
           
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
            this.ngOnInit();

          } else {
            this.alert.error_small(res.msg!)
          }
        });
      }
    })
  }

  async TraerDatos(event: boolean) {
    const res = this.rut 
      ? await this.horasExtrasService.TraerPorRutAsync(this.rut)
      : await this.horasExtrasService.ObtenerTodoAsync();

    if (res.success) {
      this.listaHoras = res.data
        .filter((x:any)=> {
          if (this.periodo) {
            const fecha = parseISO(x.fecha);
            return x.estado !== 0 && this.periodo.getFullYear() === fecha.getFullYear() && this.periodo.getMonth() === fecha.getMonth();
          }
          return x.estado !== 0;
        })
        .map(x => (
          {
            id: x.id,
            rut: x.trabajador, 
            trabajador: x.Trabajador.nombres.toUpperCase() + ' ' + x.Trabajador.apellidos.toUpperCase(),
            fecha: x.fecha,
            horasExtras: x.horasExtras,
            descripcion: x.descripcion,
          }
        )).sort((a, b) => {
          const timeA = parseISO(a.fecha).getTime();
          const timeB = parseISO(b.fecha).getTime();
          return timeB - timeA;
        });
      const totalHoras: number = this.listaHoras.reduce((a:number,e:HorasExtras) => {
        return a + parseFloat(e.horasExtras.toString());
      }, 0)
      this.horasExtrasValue.emit(totalHoras);

      this.configurarTabla();
      this.debouncer.next(true);
    }else{
      this.alert.error_small(res.msg);
    }    
  }
  configurarTabla() {
    this.dataSource = new MatTableDataSource(this.listaHoras);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort=this.sort;

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return this.configurarFilterPredicate(data, filter);
    };
  }
  
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLocaleLowerCase().split(' ');
    const nombre = this.normalizeText(data.trabajador.toLocaleLowerCase());
    return filterLower.every(x => nombre.includes(x));
  }

  actualizar_horasExtras() {
    this.horasExtrasService.Update_horaExtra(this.listaHoras).subscribe(res => {
      this.fecha= this.listaHoras.fecha;
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalReference.close();
        this.debouncer.next(true);
        this.ngOnInit();
      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }

  quitarFiltro(){
    this.textSearch = '';
    this.dataSource.filter = this.textSearch;
  }

  // PARA EFECTOS DE BUSQUEDA O SABER SI UN TEXTO, QUE TIENE TILDES, MAYUS, MINUS SE INCLUYE EN OTRO, 
  // SE DEBE NORMALIZAR LA CADENA DE TEXTO
  normalizeText(texto: string): string {
    return texto.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }
}