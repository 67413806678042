<div class="row container-fluid">
  <div class="col-2">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput type="text" [(ngModel)]="texto_buscador" (ngModelChange)="buscar()">
        </mat-form-field>
    </div>
  <div class="col-12">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">¿
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> NOMBRE </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre | uppercase}} </td>
      </ng-container>
      <ng-container matColumnDef="codigo_lre">
        <th mat-header-cell *matHeaderCellDef> CODGIO LRE </th>
        <td mat-cell *matCellDef="let element"> {{element.codigo_lre}} </td>
      </ng-container>
      <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
          <button (click)="open(content,element.id)" mat-button style="background-color:rgb(124, 124, 124)">
            <mat-icon style="color: white">edit</mat-icon>
          </button> &nbsp;
          <button (click)="Eliminar_Region(element.id)" mat-button style="background-color:red">
            <mat-icon style="color: white">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
      <mat-card-title>Editar Región</mat-card-title>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="row container">
          <div class="col-6">
              <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Nombre</mat-label>
                  <input matInput type="text" [(ngModel)]="region.nombre">
              </mat-form-field>
          </div>
          <div class="col-6">
              <mat-form-field class="w-100" appearance="outline">
                  <mat-label>Codigo LRE</mat-label>
                  <input matInput type="text" [(ngModel)]="region.codigo_lre" pTooltip="Codigo del Libro de Remuneraciones Electronico" tooltipPosition="bottom" autonumeric [options]="optionsNumeric">
              </mat-form-field>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancelar</button>
      <button type="button" class="btn btn-success" (click)="ActualizarRegion()">Guardar</button>
  </div>
</ng-template>
