import { MatSortModule } from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { AgregarBancoComponent } from './agregar-banco/agregar-banco.component';
import { ListaBancoComponent } from './lista-banco/lista-banco.component';
import { MainBancoComponent } from './main-banco/main-banco.component';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';



@NgModule({
  declarations: [
    AgregarBancoComponent,
    ListaBancoComponent,
    MainBancoComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatDatepickerModule,
    MatSortModule,
    MatMomentDateModule   
  ],
  exports:[
    AgregarBancoComponent,
    ListaBancoComponent,
    MainBancoComponent
  ]
})
export class BancoModule { }
