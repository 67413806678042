<div class="animate__animated animate__fadeInRight">
    <div class="row container-fluid">
        <div class="col-12">
            <mat-card class="mat_card_roles">
                <h1 class>Mantenedor de Firmas</h1>
            </mat-card>
        </div>
        <div class="col-12">
            <mat-card class="mat-elevation-z5 mat_card_roles_content">
                <!--  -->
                <app-agregar-firmas (actualizarTabla)="TraerFirmas()"></app-agregar-firmas>
            </mat-card>
        </div>
        <div class="col-12">
            <mat-card class="mat-elevation-z5 mat_card_roles_content">
                <!--  -->
                <app-listar-firmas [firmas]="firmas" (actualizarTabla)="TraerFirmas()"></app-listar-firmas>
            </mat-card>
        </div>
    </div>
</div>