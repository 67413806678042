import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { LogsService } from 'src/app/pages/services/logs/logs.service';

@Component({
  selector: 'app-listar-logs',
  templateUrl: './listar-logs.component.html',
  styleUrls: ['./listar-logs.component.css']
})
export class ListarLogsComponent implements OnInit {

  displayedColumns: string[] = ['id','usuario','peticion','accion','fecha'];
  dataSource = new MatTableDataSource();
  listLogs:any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private logService: LogsService,
  ) { }

  ngOnInit(): void {
    this.logService.getLogs().subscribe(logs => {
      //console.log(logs);
      this.listLogs=logs.data;
      this.dataSource = new MatTableDataSource(this.listLogs);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;  
    })
  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

}
