import { Component, OnInit } from '@angular/core';
import { EstadoCivilService } from 'src/app/pages/services/estado-civil.service';
import { EstadoCivil } from 'src/app/shared/models/estado-civil.interface';

@Component({
  selector: 'app-main-estado-civil',
  templateUrl: './main-estado-civil.component.html',
  styleUrls: ['./main-estado-civil.component.css']
})
export class MainEstadoCivilComponent implements OnInit {

  estadoCivil:EstadoCivil[] = [];

  constructor(
    private estadocivilService: EstadoCivilService
  ) { }

  ngOnInit(): void {
    this.estadocivilService.GetAll_estadoCivils().subscribe(
      (res) => {
        this.estadoCivil = res.data;
      }
    );
  }
  
  actualizarTabla(event:boolean){
    this.estadocivilService.GetAll_estadoCivils().subscribe(res=>{
      this.estadoCivil=res.data
    })
  }

}
