import { Component,EventEmitter,Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Requisito } from 'src/app/shared/models/requisitos.interface';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { RequisitoService } from 'src/app/pages/services/requisitos.service';
import { CargoService } from 'src/app/pages/services/cargo.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-agregar-perfiles-ocupacionales',
  templateUrl: './agregar-perfiles-ocupacionales.component.html',
  styleUrls: ['./agregar-perfiles-ocupacionales.component.css']
})
export class AgregarPerfilesOcupacionalesComponent implements OnInit {

  @Input() idCargo:any;
  @Input() cargo_desc:any;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id','blank','nombre'];
  list_requisitos: any;
  titulo:any;
  fileName:any;
  cargo!:any;
  ELEMENT_DATA : any=[];
  constructor(
    private requisitosService: RequisitoService,
    private cargoService: CargoService,
  ) { }



  ngOnInit(): void {

    //console.log("ID del cargo: "+this.idCargo);
     this.requisitosCargo();
     this.titulo=this.cargo_desc;
     this.fileName = this.cargo_desc+'.xlsx';
     this.cargoService.Obtener_cargo(this.idCargo).subscribe(
      (res) => {                
        this.cargo = res.data; 
        //console.log(this.cargo);
               
      });
  }


  exportexcel(): void {

    var ws: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet([[""]]); //Creo la hoja de excel vacia, para poder trabajar sobre el
    ws=XLSX.utils.sheet_add_dom(ws, document.getElementById('perfilCargo'), {origin: "A3"}); //copio la tabla, y se pegara desde la celda A3
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    ws = XLSX.utils.sheet_add_aoa(ws,[[this.cargo_desc]]);
    ws=XLSX.utils.sheet_add_aoa(ws, [[this.cargo.jornada]], { origin: "C1" });
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, this.fileName);
    

  }

  public convetToPDF() {

    var datos=[];
    datos.push([
      'ID',
      'DESCRIPCION']);

 
  this.list_requisitos.map(function (item:any) {

    datos.push([
      item.id,
     item.requisitos.descripcion , 
    ]);
  });
 

  var documentDefinition = {

    content: [

      {text: this.cargo_desc, style: 'header',fontSize:30},
      {
        style: 'tableExample',
        table: {
          widths: ['*', 'auto'],
          border: [true, true, true, true],
          body: datos

          
          
        }
        
      }
    ]
  };
  
  pdfMake.createPdf(documentDefinition).open();
  }

  requisitosCargo(){
    this.requisitosService.GetAll_requisitoPorCargo(this.idCargo).subscribe(
      (res) => {  
        this.list_requisitos = res.data;
        this.dataSource = new MatTableDataSource(this.list_requisitos);
        this.dataSource.sort = this.sort;
        //console.log(this.list_requisitos);
       
      }
    ); 

  }

}
