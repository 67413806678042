<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Contratos
</h3>

<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef> TIPO </th>
        <td mat-cell *matCellDef="let element"> {{ element.tipo_contrato | uppercase }} </td>
    </ng-container>

    <ng-container matColumnDef="firma">
        <th mat-header-cell *matHeaderCellDef> FIRMA</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.fechaFirma">{{ element.fechaFirma }}</span>
            <h2 *ngIf="!element.fechaFirma"><span class="badge badge-danger">Pendiente Firma</span></h2>
        </td>
    </ng-container>


    <ng-container matColumnDef="desde">
        <th mat-header-cell *matHeaderCellDef> DESDE </th>
        <td mat-cell *matCellDef="let element"> {{ element.fechaInicio }} </td>
    </ng-container>

    <ng-container matColumnDef="hasta">
        <th mat-header-cell *matHeaderCellDef> HASTA </th>
        <td mat-cell *matCellDef="let element"> {{ element.fechaTermino }} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="jornada">
        <th mat-header-cell *matHeaderCellDef> JORNADA </th>
        <td mat-cell *matCellDef="let element"> {{ element.jornada }} </td>
    </ng-container>

    <ng-container matColumnDef="sueldo">
        <th mat-header-cell *matHeaderCellDef> SUELDO </th>
        <td mat-cell *matCellDef="let element"> {{ element.sueldo}} </td>
    </ng-container> -->

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button (click)="Descargar(element.id)" mat-button class="button_color_primary mr-2" style="background-color:rgb(37, 199, 228);">
              <mat-icon style="color: white">search</mat-icon>
            </button>
            <button (click)="Borrar(element.id)" mat-raised-button color="warn">
                <mat-icon style="color: white">delete</mat-icon>
              </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
