import { Component, OnInit } from '@angular/core'
import { data } from 'jquery';
import { PlanillaPagosService } from 'src/app/pages/services/planilla-pagos.service';
import { Planilla } from '../../../../../shared/models/planilla-pagos.interface';



@Component({
  selector: 'app-main-planilla-pagos',
  templateUrl: './main-planilla-pagos.component.html',
  styleUrls: ['./main-planilla-pagos.component.css']
})
export class MainPlanillaPagosComponent implements OnInit {

  //list_trabajadores: Trabajadores[] = []
  planilla: Planilla[] = []

  constructor( 
    //private trabajadoresService: TrabajadoresService
    private planillaService: PlanillaPagosService
   ) { }

  ngOnInit(): void {
    // this.trabajadoresService.GetAll_trabajadores().subscribe(
    //   (res) => {                     
    //     this.list_trabajadores = res.data  
    //   });
    this.planillaService.GetAll_planillas().subscribe(
      (res) => { 
        this.planilla = res.data
       
      }
    )
  }

  actualizarTabla(event:boolean){
    // this.trabajadoresService.GetAll_trabajadores().subscribe(res=>{          
    //   this.list_trabajadores= res.data
    // })
    this.planillaService.GetAll_planillas().subscribe(
      (res) => {
        this.planilla = res.data
      }
    )
  }

}
