import { Component,EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { EstadoCivilService } from '../../../services/estado-civil.service';
import { EstadoCivil } from 'src/app/shared/models/estado-civil.interface';

@Component({
  selector: 'app-agregar-estado-civil',
  templateUrl: './agregar-estado-civil.component.html',
  styleUrls: ['./agregar-estado-civil.component.css']
})
export class AgregarEstadoCivilComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();

  debouncer: Subject<boolean> = new Subject();

  estadoCivil: EstadoCivil = {
    nombre:"",
  }

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private estadoCivilService: EstadoCivilService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
  }

  open(content:any){
    this.limpiar_EstadoCivil();
    this.modalService.open(content);
  }

  validarEstadoCivil(){
    if(this.estadoCivil.nombre.trim().length==0){
      this.alert.errorAlert('Los campos no pueden estar vacios.')
    }
  }

  limpiar_EstadoCivil(){
    this.estadoCivil.nombre=""; 
  }

  agregar(){
    this.validarEstadoCivil()
      this.estadoCivilService.Create_estadoCivil(this.estadoCivil).subscribe((res:any)=>{
        if (res.success==true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.limpiar_EstadoCivil();
          this.debouncer.next( true );
        }else{
          this.alert.error_small(res.msg!)
        }
    })
    
  }

}
