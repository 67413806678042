<div>
    <button mat-button class="button_color" (click)="open(content)">
    <span style="color: white">Agregar Solicitud </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
</div>
<br>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Agregar Solicitud de Despido</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
            <form>
                <div class="form-group">
                  <p-dropdown [options]="trabajadores" [(ngModel)]="selectedValueTrabajador" name="trabajador" optionLabel="text" optionValue="value"
                    placeholder="Seleccione trabajador" (ngModelChange)="onTrabajadorChange($event)" [showClear]="true" [filter]="true" filterBy="text"></p-dropdown>
                </div>
              
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Razón</mat-label>
                    <textarea matInput minlength="10" rows="5" required name="razon" [(ngModel)]="solicitud.razon"></textarea>
                  </mat-form-field>
                </div>
              </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
        <button type="button" class="btn btn-outline-success" (click)="Enviar()">
      Enviar
    </button>
    </div>
</ng-template>