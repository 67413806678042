<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
      <span style="color: white">Agregar Afp </span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
  </div>
  
  <ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Agregar nueva afp</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre de la afp</mat-label>
          <input
            type="text"
            maxlength="50"
            minlength="3"
            matInput
            name="afp.nombre"
            [(ngModel)]="afp.nombre"
          />
          
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Codigo LRE</mat-label>
          <input
            type="text"
            maxlength="50"
            minlength="3"
            matInput
            name="afp.cod_afp"
            [(ngModel)]="afp.cod_afp"
          />
          
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Tasa de la afp</mat-label>
          <input
            type="text"
            maxlength="50"
            minlength="3"
            matInput
            name="afp.nombre"
            autonumeric [options]="optionsNumericDecimal"
            [(ngModel)]="afp.tasa"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">Cancelar</span>
      </button>
      <button type="button" class="btn btn-outline-success" (click)="agregar()">
        Guardar
      </button>
    </div>
  </ng-template>
  