import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cleanRut } from 'rutlib';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PermisoService } from 'src/app/pages/services/permisos.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Permiso } from 'src/app/shared/models/permisos.interface';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-lista-permisos',
  templateUrl: './lista-permisos.component.html',
  styleUrls: ['./lista-permisos.component.css']
})
export class ListaPermisosComponent implements OnInit {
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  num_trab_finiquitados: number;
  displayedColumns: string[] = ['rut', 'trabajador', 'empresa', 'fecha', 'motivo','actions'];
  rut: number = 0;
  textoBuscar: string = '';
  trabajadorFilter: any[] = [];
  lista_trabajadores: any[] = [];
  lista_permisos_aprobados: any[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private modalService: NgbModal,
    private permisosServices: PermisoService,
    private alert: AlertHelper,
    private trabajadorService: TrabajadoresService,
  ) { }

  rol_select: any
  permiso_select: any
  dataSource: any;

  async ngOnInit() {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    await this.LlenarTabla();
  }

  permisos = {
    id: 0,
    nombre: '',
    descripcion: 'string',
    rol: 0
  };

  // FUNCIONES PARA ABRIR MODALES

  //LISTADO DE PERMISOS
  open(up: any, rut: number) {
    this.rut = rut;
    console.log(rut)
    this.permisosServices.TraerPermisosRutAsync(this.rut)
      .then((permisos) => {
        console.log('Permisos obtenidos:', permisos);
        // Aquí puedes realizar cualquier otra operación con los permisos obtenidos
      })
      .catch((error) => {
        console.error('Error al obtener permisos:', error);
        // Aquí puedes manejar el error de alguna manera
      });
    
    this.modalService.open(up, { size: 'xl' });
  }

  // PARA SUBIR PERMISOS (NO SE SI ES NECESARIO?)

  openSubir(content: any, rut: number) {
    this.rut = rut;
    this.modalService.open(content, { size: 'lg', backdrop: 'static' });
  }

  actualizar_permisos() {
    this.permisosServices.actualizar_permisos(this.permisos).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.debouncer.next(true);
        this.LlenarTabla();
      } else {
        this.alert.error_small(res.msg!)
      }
    });

    this.refreshTabla();

  }

 


  async LlenarTabla() {
    this.permisosServices.GetAll_permisos().subscribe(res => {
        console.log(res.data);

        // Crear un conjunto para almacenar los IDs únicos de los permisos
        const idsSet = new Set<number>();

        // Filtrar la lista para eliminar permisos duplicados
        const listaFiltrada = res.data.filter((permiso: any) => {
            // Si el ID del permiso no está en el conjunto, añadirlo al conjunto y devolver true
            // Si el ID del permiso ya está en el conjunto, devolver false
            if (!idsSet.has(permiso.fk_trabajador)) {
                idsSet.add(permiso.fk_trabajador);
                return true;
            }
            return false;
        });

        // Asignar la lista filtrada al origen de datos de la tabla
        this.lista_permisos_aprobados = listaFiltrada;
        this.dataSource = new MatTableDataSource(this.lista_permisos_aprobados);
        this.configurarTabla(this.dataSource);
    });
}


  async refreshTabla() {
    await this.LlenarTabla();
  }


  configurarTabla(dataSource: any) {
    dataSource.filterPredicate = (data:any, filter: string) => {
      return this.configurarFilterPredicate(data, filter)
    }

    dataSource.paginator = this.paginator;
    // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
    dataSource.sortingDataAccessor = (item, property) => {
      // console.log({property}, {item});
      switch (property) {
        case 'empresa': return item.nombreEmpresa;
        default: return item[property];
      }
    }
    dataSource.sort = this.sort;
    return dataSource;
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLocaleLowerCase().split(' ');
    console.log(data)
    const nombreTrabajador = data.TrabajadorPermiso.nombres.toLocaleLowerCase() + ' ' + data.TrabajadorPermiso.apellidos.toLowerCase();
    
    const rutSinPuntos: string = cleanRut(data.TrabajadorPermiso.rut.toString());
    const nombre = this.normalizeText(nombreTrabajador);
    const nombreEmpresa = this.normalizeText(data.TrabajadorPermiso.Empresa_contratante.nombre.toLocaleLowerCase());
    const estado_contrato = this.normalizeText(data.TrabajadorPermiso.estado_contrato.toLocaleLowerCase());

    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.TrabajadorPermiso.rut.toString().includes(x) 
        || nombre.includes(x) || nombreEmpresa.includes(x) || estado_contrato.includes(x)
    );
  }
  // PARA EFECTOS DE BUSQUEDA O SABER SI UN TEXTO, QUE TIENE TILDES, MAYUS, MINUS SE INCLUYE EN OTRO, 
  // SE DEBE NORMALIZAR LA CADENA DE TEXTO
  normalizeText(texto: string): string {
    return texto.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }

  //FITRAL
  applyFilter(textSearch: string) {
    //Funcion para filtar cualquier columna por el texto buscado
    this.textoBuscar = textSearch;
    console.log(this.textoBuscar.trim())
    this.dataSource.filter = this.textoBuscar.trim().toLocaleLowerCase();
    this.dataSource.paginator.firstPage();
  }
  viewDatosFiniquitados(viewFiniquito: boolean) {
    this.trabajadorFilter = viewFiniquito 
      ? this.lista_trabajadores 
      : this.lista_trabajadores.filter(x => x.estado !== 0);

    this.dataSource = new MatTableDataSource<any>(this.trabajadorFilter);
    this.dataSource = this.configurarTabla(this.dataSource);
    this.dataSource.filter = this.textoBuscar.trim().toLowerCase();
  }

}
