import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-main-buscar-filter',
  templateUrl: './main-buscar-filter.component.html',
  styleUrls: ['./main-buscar-filter.component.css']
})
export class MainBuscarFilterComponent implements OnInit {
  @Output() textSearch = new EventEmitter<string>();
  @Output() viewFiniquitadosEmit = new EventEmitter<boolean>(false);
  @Input() num_trab_finiquitados: number;
  viewFiniquitados: boolean = false;


  constructor() { }

  ngOnInit(): void {
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.textSearch.emit(filterValue);
  }

  viewDatosFiniquitados(){
    this.viewFiniquitadosEmit.emit(this.viewFiniquitados);
  }

}
