<div class="row">
    <div class="col-12">
        <h3>Fechas</h3>
        <p-calendar [(ngModel)]="fechas" styleClass="w-100" selectionMode="multiple" dateFormat="dd-mm-yy"
            [readonlyInput]="true" inputId="multiple" [firstDayOfWeek]="1">
        </p-calendar>
    </div>
    <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Motivo</mat-label>
            <textarea matInput placeholder="Ingrese motivo" [(ngModel)]="permiso.motivo"></textarea>
        </mat-form-field>
    </div>
    <div class="col-12">
        <label>Subir Contrato </label>
        <input mat-input type="file" ng2FileSelect [uploader]="uploader" />
    </div>
    <div class="col-12">
        <button mat-raised-button color="primary" (click)="SubirPermiso()" [disabled]="!uploader.getNotUploadedItems().length">
            <mat-icon>file_upload</mat-icon>
            <span>Subir</span>
        </button>
    </div>
</div>