<button (click)="OpenEditar(content)"
    mat-button class="button_color_primary">
    <mat-icon style="color: white">edit</mat-icon>
</button>

<ng-template #content let-modal>
    <div class="modal-header">
      <mat-card-title>Editar motivo</mat-card-title>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 mb-3">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Ingrese nombre del motivo" [(ngModel)]="motivoEdit.nombre" required>
              </mat-form-field>
        </div>
        <div class="col-12 mb-3">
          <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Descripcion</mat-label>
              <textarea id="textarea_descripcion" matInput placeholder="Ingrese la descripcion del motivo, la cual aparecerá en el documento" 
                (keyup)="changeDescripcion()" (click)="changeDescripcion()" cols="2" rows="10" [(ngModel)]="motivoEdit.descripcion" [matAutocomplete]="auto"></textarea>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                  <mat-option disabled>VARIABLES</mat-option>
                  <mat-option *ngFor="let variable of variables" [value]="variable.nombre">
                    {{variable.nombre}}: {{variable.descripcion}}
                  </mat-option>
              </mat-autocomplete>
          </mat-form-field>
          <mat-hint>
            <b>VARIABLES</b>: Al escribir en el campo de texto, se mostrará una lista de variables las cuales se 
            pueden ocupar para mostrar informacion del trabajador para crear la carta de amonestacion de forma dinámica
          </mat-hint>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
      <button type="button" class="btn btn-warning" (click)="EditarMotivo()">Editar motivo</button>
    </div>
</ng-template>