import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MaterialModule } from "src/app/material.module";
import { AgregarDoctransferenciasComponent } from "./agregar-doctransferencias/agregar-doctransferencias.component";
import { ListaDoctransferenciasComponent } from "./lista-doctransferencias/lista-doctransferencias.component";
import { MainDoctransferenciasComponent } from "./main-doctransferencias/main-doctransferencias.component";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from "ng2-file-upload";
import { ModalMostrarComponent } from './modal-mostrar/modal-mostrar.component';
import { HelperModule } from 'src/app/helper.module';
import { AutonumericModule } from "@angularfy/autonumeric";
import { BuscarFilterModule } from "../../buscar-filter/buscar-filter.module";

@NgModule({
    declarations: [
        AgregarDoctransferenciasComponent,
        MainDoctransferenciasComponent,
        ListaDoctransferenciasComponent,
        ModalMostrarComponent,
    ],

    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        AutocompleteLibModule,
        DropdownModule,
        FileUploadModule,
        HelperModule,
        AutonumericModule,
        BuscarFilterModule,
    ],

    exports: [
        AgregarDoctransferenciasComponent,
        MainDoctransferenciasComponent,
        ListaDoctransferenciasComponent,
    ]

})

export class DocTransferencias { }