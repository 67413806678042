import { Component, OnInit } from '@angular/core';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';

@Component({
  selector: 'app-main-sucursales',
  templateUrl: './main-sucursales.component.html',
  styleUrls: ['./main-sucursales.component.css']
})
export class MainSucursalesComponent implements OnInit {

  sucursales:Sucursal[] = [];

  constructor(
    private sucursalesService: SucursalService
  ) { }

  ngOnInit(): void {
    this.sucursalesService.GetAll_sucursales().subscribe(
      (res) => {
        this.sucursales = res.data.sort((a:Sucursal, b: Sucursal) => a.nombre.localeCompare(b.nombre));
      }
    );
  }
  
  actualizarTabla(event:boolean){
    this.sucursalesService.GetAll_sucursales().subscribe(res=>{
      this.sucursales=res.data
    })
  }

}
