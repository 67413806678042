<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
      <span style="color: white">Agregar Banco</span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Agregar Banco</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Nombre Banco</mat-label>
                <input type="text" maxlength="150" minlength="10" matInput name="bancos.nombre" [(ngModel)]="bancos.nombre" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Url Logo Banco</mat-label>
                <input type="text" matInput name="bancos.nombre" [(ngModel)]="bancos.urlLogo" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">Cancelar</span>
                </button>
            <button type="button" class="btn btn-outline-success" (click)="enviar()">Guardar
                </button>
        </div>
    </div>
</ng-template>