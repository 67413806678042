<button mat-button class="button_color_primary" *ngIf="(user.rol !== 'ASISTENCIA')" (click)="open(content)">
    <span style="color: white">Registrar inasistencia</span>
    <mat-icon style="color: white">add</mat-icon>
</button>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Registrar inasistencia</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <label for="dropdown" class="fw-bold">Trabajador</label>
                <p-dropdown id="dropdown" [options]="trabajadores_aux" placeholder="Seleccione trabajador"
                    optionLabel="text" emptyFilterMessage="Sin resultados de trabajadores"
                    (onChange)="ObtenerTrabajador($event)" styleClass="example-full-width" [showClear]="true"
                    [filter]="true" filterBy="text"></p-dropdown>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Razón</mat-label>
                    <mat-select [(ngModel)]="asistencia.razon" (ngModelChange)="verificarRazon($event)" name="id">
                        <mat-option *ngFor="let element of razones" [value]="element.id">
                            {{element.razon}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="render==0">
            <div class="col-12">
                <!-- [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end" -->
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Fecha inasistencia (Rango)</mat-label>
                    <mat-date-range-input [formGroup]="fechaDesdeHasta" [rangePicker]="fechaPicker">
                        <input matStartDate placeholder="Ingrese fecha inasistencia desde" formControlName="start" (click)="fechaPicker.open()">
                        <input matEndDate placeholder="Ingrese fecha inasistencia hasta" formControlName="end" (click)="fechaPicker.open()">
                    </mat-date-range-input>
                    <!-- <mat-hint>{{fechaDesdeHasta.value.start | date }} - {{ fechaDesdeHasta.value.end | date }}</mat-hint> -->
                    <mat-datepicker-toggle matSuffix [for]="fechaPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #fechaPicker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <!-- <div class="col-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha Inicio</mat-label>
                    <input matInput [matDatepicker]="picker" (dateChange)="obtener_fecha($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha Final</mat-label>
                    <input matInput [matDatepicker]="picker2" (dateChange)="obtener_fecha_fin($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker2></mat-datepicker>
                </mat-form-field>
            </div> -->
        </div>
        <div class="row" *ngIf="render==1">
            <div class="col-6">
                <div class="input-group">
                    <span class="input-group-text border-0 w-100 mb-2">Fecha de atraso</span>
                    <input class="form-control example-full-width pt-2 pb-2" placeholder="Ingrese fecha de atraso"
                        name="dp" placement="bottom" [(ngModel)]="modelFecha" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group">
                    <span class="input-group-text border-0 w-100 mb-2">HH:MM Inicio</span>
                    <ngb-timepicker [(ngModel)]="timeStart"></ngb-timepicker>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group">
                    <span class="input-group-text border-0 w-100 mb-2">HH:MM Llegada</span>
                    <ngb-timepicker [(ngModel)]="timeEnd"></ngb-timepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>MOTIVO</mat-label>
                    <textarea type="textarea" rows="6" maxlength="100" matInput name="motivo"
                        [(ngModel)]="asistencia.motivo"></textarea>
                </mat-form-field>
            </div>
        </div>
        <table style="width:100%">
            <!-- <tr>
                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Descuento Sueldo</mat-label>
                            <mat-select (selectionChange)="Obtenersueldo($event)" name="asistencia.dsueldo" required>
                                <mat-option value="SI">
                                    SI
                                </mat-option>
                                <mat-option value="NO">
                                    NO
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>


                    <td>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Descuento Bono</mat-label>
                            <mat-select (selectionChange)="Obtenerbono($event)" name="asistencia.dbono" required>
                                <mat-option value="SI">
                                    SI
                                </mat-option>
                                <mat-option value="NO">
                                    NO
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>

                </tr> -->


            <!--      <tr>

                    <td *ngIf="si== 1">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Porcentaje</mat-label>
                            <input matInput type="number"  [(ngModel)]="asistencia.tsueldoo" name="tsueldo"
                           >
                        </mat-form-field>
                    </td>
                    <td *ngIf="no==1">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Porcentaje</mat-label>
                            <input  matInput type="number" [(ngModel)]="asistencia.tbonoo" name="tbono">


                        </mat-form-field>
                    </td>

                </tr>
              -->
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="enviar()">Guardar</button>
    </div>
</ng-template>