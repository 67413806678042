<mat-stepper [linear]="false" #stepper>
  <mat-step label="Datos Trabajador">
    <ng-template matStepLabel>Datos Trabajador</ng-template>
    <div class="col-md-12">
      <h4 style="color:#2a3f54"><b>I INFORMACIÓN DE TRABAJADOR</b></h4>
      <div class="form-row">
        <div class="form-group col-md-6">
          <p-dropdown [options]="lista_trabajadores" (onChange)="ObtenerTrabajador($event)"
            [(ngModel)]="TrabajadorSeleccionado" placeholder="TRABAJADOR" optionLabel="nombreCompleto" [filter]="true"
            filterBy="nombreCompleto" [showClear]="true" [style]="{ width: '100%' }">
          </p-dropdown>
        </div>
        <div class="form-group col-md-6">
          <p-dropdown [options]="lista_ciudades" [(ngModel)]="CiudadSeleccionada"
            placeholder="CIUDAD EN QUE SE FIRMA ANEXO" optionLabel="nombre" [filter]="true" filterBy="nombre"
            [showClear]="true" [style]="{ width: '100%' }">
          </p-dropdown>
        </div>
        <div *ngIf="render == 0">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <div *ngIf="render == 1" class="col-md-12">
          <br />
          <form>
            <!-- primera fila  -->
            <div class="form-row">
              <div class="form-group col-md-3">
                <h4 style="color:#2a3f54"><b>Rut</b></h4>
                <input style="width: inherit;" type="text" name="rut" pInputText [disabled]="true"
                  [ngModel]="TrabajadorSeleccionado.rut | formatoRut" />
              </div>
              <div class="form-group col-md-3">
                <h4 style="color:#2a3f54"><b>Nombres</b></h4>
                <input style="width: inherit;" type="text" name="nombres" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.nombres" />
              </div>
              <div class="form-group col-md-3">
                <h4 style="color:#2a3f54"><b>Apellidos</b></h4>
                <input style="width: inherit;" type="text" name="apellidos" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.apellidos" />
              </div>
              <div class="form-group col-md-3">
                <h4 style="color:#2a3f54"><b>Cargo</b></h4>
                <input style="width: inherit;" type="text" name="cargo" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.Cargo.nombre" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <h4 style="color:#2a3f54"><b>Jornada de trabajo</b></h4>
                <textarea [disabled]="true" [(ngModel)]="
                    TrabajadorSeleccionado.Cargo.jornadas.descripcion
                  " class="form-control" name="algo" rows="5"></textarea>
              </div>
            </div>
            <!-- segunda fila -->
            <div class="form-row">
              <div class="form-group col-md-4">
                <h4 style="color:#2a3f54"><b>Direccion</b></h4>
                <input style="width: inherit;" type="text" name="Direccion" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.direccion" />
              </div>
              <div class="form-group col-md-4">
                <h4 style="color:#2a3f54"><b>Afp</b></h4>
                <input style="width: inherit;" type="text" name="Afp" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.Afp.nombre" />
              </div>
              <div class="form-group col-md-4">
                <h4 style="color:#2a3f54"><b>Ciudad</b></h4>
                <input style="width: inherit;" type="text" name="ciudad" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.Ciudad.nombre" />
              </div>
            </div>
            <!-- tercera fila -->
            <div class="form-row">
              <div class="form-group col-md-4">
                <h4 style="color:#2a3f54"><b>Previción</b></h4>
                <input style="width: inherit;" type="text" name="previcion" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.Prevision.nombre" />
              </div>
              <div class="form-group col-md-4">
                <h4 style="color:#2a3f54"><b>Nacionalidad</b></h4>
                <input style="width: inherit;" type="text" name="nacionalidad" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.Nacionalidad.nombre" />
              </div>
              <div class="form-group col-md-4">
                <h4 style="color:#2a3f54"><b>fecha de nacimiento</b></h4>
                <input style="width: inherit;" type="text" name="nacimieno" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.fecha_nacimiento" />
              </div>
            </div>
            <!-- cuarta fila -->
            <div class="form-row">
              <div class="form-group col-md-6" style="width: 100%;">
                <h4 style="color:#2a3f54"><b>Estado civil</b></h4>
                <input style="width: 100%;" type="text" name="civil" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.Estado_civil.nombre" />
              </div>
              <div class="form-group col-md-6" style="width: 100%;">
                <h4 style="color:#2a3f54"><b>Correo Electronico</b></h4>
                <input style="width: 100%;" type="text" name="correo" pInputText [disabled]="true"
                  [(ngModel)]="TrabajadorSeleccionado.correo" />
              </div>
            </div>
            <!-- fin cuarta fila -->
          </form>
        </div>
      </div>
    </div>
    <div>
      <button mat-button matStepperPrevious>
        <mat-icon>navigate_before</mat-icon>Anterior
      </button>
      <button style="float: right;" mat-button matStepperNext>
        <mat-icon>navigate_next</mat-icon>Siguiente
      </button>
    </div>
  </mat-step>



  <mat-step label="Modificación de cláusula">
    <ng-template matStepLabel>Modificación de cláusula</ng-template>
    <h4 style="color:#2a3f54"><b>II MODIFICACIÓN DE CLÁUSULA</b></h4>
    <div class="col-md-12">
      <div class="form-row">
        <div class="form-group col-md-5">
          <h4 style="color:#2a3f54"><b>NÚMERO ORIGINAL DE LA CLÁUSULA</b></h4>
          <input style="width: 100%;" [(ngModel)]="numero_clausula"
            (ngModelChange)="numero_clausula = $event.toUpperCase()" type="text" pInputText (change)="ObtenerClausula()" />
        </div>
        <div class="form-group col-md-1">
          <br />
          <br />
          <p-button (click)="MostrarEjemplo()" icon="pi pi-eye"></p-button>
        </div>
        <div class="form-group col-md-6">
          <h4 style="color:#2a3f54"><b>CLÁUSULA A MODIFICAR</b></h4>
          <p-dropdown [(ngModel)]="clausula_seleccionada" [showClear]="true" [options]="clausulas" [filter]="true"
            [scrollHeight]="400" [style]="{ width: '100%' }" (onChange)="ObtenerClausula($event)" filterBy="nombre"
            placeholder="Seleccione cláusula" optionLabel="nombre"></p-dropdown>
        </div>
        <div class="form-group col-md-12">
          <button type="button" (click)="GenerarPDF()" class="btn btn-primary">
            GENERAR PDF
          </button>
        </div>
        <div *ngIf="cargando==true" class="form-group col-md-12">
          <p-progressBar mode="indeterminate"></p-progressBar>
        </div>
        <br>
        <!---------------codigo mio -->


        <div *ngIf="mostrarfecha == true">
          <div class="form-group row">
            <div class="col-3">
              <h4 style="color:#2a3f54"><b>3 Meses</b></h4>
              <p-inputSwitch [(ngModel)]="TresMeses" (click)="obtener_fecha_clausula(click3Meses())" class="myInput"></p-inputSwitch>
            </div>
            <div class="col-3">
              <h4 style="color:#2a3f54"><b>6 Meses</b></h4>
              <p-inputSwitch [(ngModel)]="SeisMeses" (click)=" obtener_fecha_clausula(click6Meses())"
                class="myInput"></p-inputSwitch>
            </div>
            <div class="col-3">
              <h4 style="color:#2a3f54"><b>Indefinido</b></h4>
              <p-inputSwitch [(ngModel)]="indefinido" (click)="clickIndefinido()" class="myInput"></p-inputSwitch>
            </div>
            <div class="col-3">
              <h4 style="color:#2a3f54"><b>Otro</b></h4>
              <p-inputSwitch [(ngModel)]="Otro" (click)="clickOtro()"
                class="myInput"></p-inputSwitch>
            </div>

            <div class="form-group col-md-12" *ngIf=" Otro == true">
              <h4 style="color:#2a3f54"><b>FECHA TERMINO </b></h4>
              <p-calendar dateFormat="dd/mm/yy" showIcon="true" placeholder="Ingrese fecha" [style]="{ width: '100%' }"
                (onSelect)="obtener_fecha_clausula($event)"></p-calendar>
            </div>

          </div>
        </div>

        <!-- Hasta aca -->
        <br />
        <br />
        <div class="form-group col-md-12">
          <!-- <p-editor
          (onTextChange)="mostrar($event)"
          [(ngModel)]="texto"
          [style]="{ height: '320px' }"
        >
        </p-editor> -->
          <textarea [(ngModel)]="texto" [rows]="20" [cols]="20" style="width:100%;" pInputTextarea>
          </textarea>
          <!-- rrhh corre Doom -->
          <!-- <iframe
          width="100%"
          height="400"
          frameborder="0"
          src="https://dos.zone/player/?bundleUrl=https%3A%2F%2Fcdn.dos.zone%2Fcustom%2Fdos%2Fdoom.jsdos?anonymous=1"
          allowfullscreen
        >
        </iframe> -->

          <div>
            <button mat-button matStepperPrevious>
              <mat-icon>navigate_before</mat-icon>Anterior
            </button>
            <button style="float: right;" mat-button matStepperNext>
              <mat-icon>navigate_next</mat-icon>Siguiente
            </button>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </mat-step>
</mat-stepper>

<p-dialog [(visible)]="ejemplo" [modal]="true" [style]="{ width: '75%' }" [draggable]="false" [resizable]="false">
  <div id="itemsContrato">
    <div class="col-md-12">
      <br />
      <h4 style="color:#2a3f54"><b>ITEMS COMUNES DE UN CONTRATO</b></h4>
    </div>
    <ul style="list-style:none">
      <li>
        <h6 style="color:#49505c;">I. Partes</h6>
      </li>
      <li>
        <h6 style="color:#49505c;">II. Naturaleza de los servicios</h6>
      </li>
      <li>
        <h6 style="color:#49505c;">III. Lugar de la prestación de servicios</h6>
      </li>
      <li>
        <h6 style="color:#49505c;">IV. Jornada de trabajo</h6>
      </li>
      <li>
        <h6 style="color:#49505c;">V. Remuneraciones</h6>
      </li>
      <li>
        <h6 style="color:#49505c;">
          VI. Duración de la relación jurídica laboral
        </h6>
      </li>
      <li>
        <h6 style="color:#49505c;">VII. Cláusula de la vigencia</h6>
      </li>
      <li>
        <h6 style="color:#49505c;">VIII. A tener en cuenta</h6>
      </li>
    </ul>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="ejemplo = false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="MostrarModalPDF" [modal]="true" [style]="{ width: '75%' }" [draggable]="false"
  [resizable]="false">
  <button style="background-color: rgb(207, 51, 3);" (click)="printpdf()" mat-raised-button>
    Exportar a PDF <mat-icon>picture_as_pdf</mat-icon>
  </button>
  <div>
    <pdf-viewer [(src)]="src" [render-text]="true" [zoom]="0.7" [show-borders]="true" [original-size]="false"
      style="width: 100%; height: 550px"></pdf-viewer>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="MostrarModalPDF = false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>