import { Component, OnInit } from '@angular/core';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FormControl } from '@angular/forms';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { ListaTrabajadores } from 'src/app/shared/models/trabajadores/trabajadores.interface';
import { OpcionElegidaCertificado } from 'src/app/shared/models/opcioneselegidas.interface';
import { GenerarCertificadoSueldoService } from '../../../services/PDF-GENERADOR/certificado/generarcertificadosueldo.service';
import { CertificadoSueldoService } from 'src/app/pages/services/certificadosueldo/certificadosueldo.service';
import { CertificadoSueldoBD } from 'src/app/shared/models/certificadosueldo/certificadosueldo.interface';
import { MatSelectChange } from '@angular/material/select';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-generar-certificado',
  templateUrl: './generar-certificado.component.html',
  styleUrls: ['./generar-certificado.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class GenerarCertificadoComponent implements OnInit {
  empresas: Empresa[] = [];
  trabajadores:ListaTrabajadores[] = [];
  trabajadores_aux:any[] = [];
  trabajador:ListaTrabajadores;

  constructor(
    private empresaService: EmpresaService,
    private trabajadorService: TrabajadoresService, 
    private alert:AlertHelper,
    private generarCertificadoService:GenerarCertificadoSueldoService,
    private certificadoService:CertificadoSueldoService
  ) { 
    this.empresaService.GetAll_empresaC().subscribe(
      (res) => {                     
        this.empresas = res.data;

        const id = this.empresas[0]?.id || 0;
        this.opciones_elegidas.id_empresa = id;
        this.empresa_default_select = new FormControl(id);
        this.cargarTrabajadores();
      }
    );
  }

  date = new FormControl(moment());
  currentYear: number = new Date().getFullYear();
  maxDate:Date = new Date(this.currentYear,11,31);
  // VALORES POR DEFECTO PARA LOS SELECT
  empresa_default_select:FormControl;
  trabajador_default_select:FormControl;

  // OBJETO QUE SE UTILIZA PARA GUARDAR LA INFOMACION DE LAS OPCIONES ELEGIDAS EN EL FORMULARIO
  opciones_elegidas: OpcionElegidaCertificado = {
    id_empresa: 0,
    num_certificado: 0,
    periodo_declatorio: new Date().getFullYear(),
    rut_trabajador: ''
  }

  ngOnInit(): void { }

  verificarEstadoDatos(){
    console.log(this.opciones_elegidas);
    // VERIFICAR SI LA DECLARACION SE HACE EL MISMO AÑO EN CURSO
    if (this.opciones_elegidas.periodo_declatorio === this.currentYear) {
      this.alert.error_small
      (
        "No se puede elegir el mismo periodo en curso: "+this.opciones_elegidas.periodo_declatorio
      );
      return;
    }
    this.generarCertificadoService.generarDocumento(this.opciones_elegidas);
  }
  // SELECT EMPRESA
  selectEmpresa(empresa:Empresa){
    this.opciones_elegidas.id_empresa = empresa.id;
    this.cargarTrabajadores()
  }
  // SELECT TRABAJADOR
  selectTrabajador(trabajador:ListaTrabajadores){
    this.opciones_elegidas.rut_trabajador = trabajador.rut;
  }
  // CARGA LOS TRABAJADORES EN EL SELECT DEPENDIENDO DE LA EMPRESA SELECCIONADA
  cargarTrabajadores(){
    this.trabajadorService.TraerTrabajadores()
      .subscribe(({data}) => {
        this.trabajadores = data.filter(x => x.empresa_contratante === this.opciones_elegidas.id_empresa);
        this.trabajadores_aux = this.trabajadores.map(x => ({value: x.rut, text: x.nombres + ' ' +x.apellidos}));

        if (this.trabajadores.length === 0) {
          this.alert.error_small('No existen trabajadores.');
          return;
        }
        const rut = this.trabajadores[0].rut;
        this.opciones_elegidas.rut_trabajador = rut;
        this.trabajador_default_select = new FormControl(rut);
      });
  }
  ObtenerTrabajador(event: MatSelectChange){
    this.opciones_elegidas.rut_trabajador = event.value.value;
  }
  
  // METODO QUE CAPTURA EL AÑO DEL DATEPICKER
  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.opciones_elegidas.periodo_declatorio = this.date.value.year();
  }
}
