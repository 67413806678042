import { Component, OnInit } from '@angular/core';
import 'animate.css';
@Component({
  selector: 'app-main-contratos',
  templateUrl: './main-contratos.component.html',
  styleUrls: ['./main-contratos.component.css']
})
export class MainContratosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
