<div class="container-fluid">
    <!-- <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <button pButton type="button" icon="pi pi-eye" label="Ver Liquidación" (click)="MostrarLiquidacion()"></button>
        </div>
    </div> -->
    <div class="row">
         <div class="col-8 overflow-auto">
            <p-tabView>
                <p-tabPanel header="PDF">
                    <pdf-viewer  [src]="rutaDocumento" [rotation]="0" [original-size]="false" [show-all]="true"
                        [fit-to-page]="false" [zoom-scale]="'page-fit'" [render-text]="true" [external-link-target]="'blank'" 
                        [autoresize]="true" [show-borders]="true" style="width: 100%; height: 100vh;">
                        <!-- [stick-to-page]="true" [zoom]="1" -->
                    </pdf-viewer>

                </p-tabPanel>
                <p-tabPanel header="Boleta">
                    <pdf-viewer  [src]="rutaDocumento2" [rotation]="0" [original-size]="false" [show-all]="true"
                        [fit-to-page]="false" [zoom-scale]="'page-fit'" [render-text]="true" [external-link-target]="'blank'" 
                        [autoresize]="true" [show-borders]="true" style="width: 100%; height: 100vh;">
                        <!-- [stick-to-page]="true" [zoom]="1" -->
                    </pdf-viewer>
                </p-tabPanel>
            </p-tabView>
        

         </div>
        <div class="col-4">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-center">Datos de honorario</h1>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button pButton pRipple class="p-button-rounded p-button-help" type="button" icon="pi pi-download" 
                        label="Descargar PDF" (click)="DescargarHonorario()">
                    </button>
                    <button pButton pRipple class="p-button-rounded p-button-help ml-2" type="button" icon="pi pi-download" 
                    label="Descargar Boleta" (click)="DescargarHonorarioBoleta()">
                </button>

                </div>
                <div class="col-12">
                    <label for="periodo">Trabajador</label>
                    <input class="form-control" id="periodo" [value]="nombreTrabajador | uppercase" disabled>
                </div>
                <div class="col-12">
                    <label for="periodo">Empresa</label>
                    <input class="form-control" id="periodo" [value]="nombreEmpresa | uppercase" disabled>
                </div>
                <div class="col-12">
                    <label for="periodo">Sucursal</label>
                    <input class="form-control" id="periodo" [value]="nombreSucursal | uppercase" disabled>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label for="estado">Estado</label>
                    <input class="form-control" id="estado" [value]="estadoCurrent" disabled>
                </div>
                <div class="col-6">
                    <label for="periodo">Fecha</label>
                    <input class="form-control" id="periodo" [value]="modalViewHonorario.honorario.fecha | date: 'dd/MM/yyyy'" disabled>
                </div>
            </div>
            <div class="row">
      
                <div class="col-12">
                    <label for="estado">Líquido $</label>
                    <input type="text" class="form-control" id="liquido" [value]="modalViewHonorario.honorario.alcanceLiquido | number:'1.0-0'" disabled>
                </div>
            </div>

            <div class="row">
                <div class="col-6 d-flex justify-content-end" *ngIf="modalViewHonorario.honorario.estado === 0">
                    <button pButton type="button" label="Aprobar"  icon="pi pi-thumbs-up" 
                    class="p-button-success" (click)="cambiarEstado(modalViewHonorario.honorario.id, {estado:1, motivo: null})"></button>
                </div>
                <div class="col-6 d-flex justify-content-start" *ngIf="modalViewHonorario.honorario.estado === 0">
                    <button pButton type="button" label="Rechazar" icon="pi pi-thumbs-down" 
                        class="p-button-danger"></button>
                </div>
                <div class="col-12 d-flex justify-content-center" *ngIf="modalViewHonorario.honorario.estado !== 0">
                    <button pButton type="button" label="Cambiar decisión" icon="pi pi-pencil" 
                        class="p-button-secondary" (click)="cambiarDecision()"></button>
                </div>
            </div>

            <div class="row" *ngIf="decision!=''">
             
                <div class="col-12" >
                    <p-message severity="warn" text="Se modificará la decisión a {{ decision }}" styleClass="mr-2"></p-message> 
                </div>
                <div class="col-12" >
                    <label for="comentarioNew">Comentario / Motivo</label>
                    <textarea id="comentarioNew" cols="2" rows="2" class="form-control" 
                        placeholder="Ingrese un nuevo comentario" [(ngModel)]="motivo"></textarea>
                </div>
             
                <div class="col-12">
                    <button pButton type="button" label="Cancelar" (click)="cerrarModal()" class="p-button-secondary mr-2"></button>
                    <button pButton type="button" label="Modificar" class="p-button-warning" (click)="rechazar()"></button>
                </div>
            </div>

            <div class="row" *ngIf="modalViewHonorario.honorario.estado===2">
                <div class="col-12">
                      <div class="mensaje-rechazo">
                        <h2>Rechazada</h2>
                        <h3><strong>Motivo: </strong>{{ modalViewHonorario.honorario.motivo }}</h3>
                      </div>
                </div>
            </div>
            
        </div>
    </div>

</div>