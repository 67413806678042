<div class="animate__animated animate__fadeInRight container-fluid">
    <div class="col pr-0 pl-0 pt-2 pb-2">
        <mat-card class="mat_card_trabajadores">
            <h1 class>Modulo de Movimientos de Personal</h1>
        </mat-card>
    </div>
    <mat-card class="mat-elevation-z5 mat_card_trabajadores_content">
        <mat-tab-group class="card_group_trabajadores">
            <mat-tab label="Movimientos de personal">
                <app-lista-movimientos-personal></app-lista-movimientos-personal>
            </mat-tab>
            <mat-tab label="Registro Movimientos de Personal">
                <div class="overflow-hidden">
                    <app-agregar-movimientos-personal></app-agregar-movimientos-personal>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
    <hr />
</div>