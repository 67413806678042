import { Injectable } from '@angular/core';
import { HelperService } from '../../helper/helper.service';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { FirmasService } from '../../firmas.service';
import { Firma } from 'src/app/shared/models/firmas.interface';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class GenerarAnexoService {
  src: any = ''
  firma: Firma;
  base64ImageWhite: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAADIAQMAAAAwS4omAAAAA1BMVEX///+nxBvIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAG0lEQVRIie3BMQEAAADCoPVPbQwfoAAAAIC3AQ+gAAEq5xQCAAAAAElFTkSuQmCC';
  base64Firma: string;
  BASE_API: string = environment.API;

  constructor(
    private helper: HelperService,
    private firmaService: FirmasService,
  ) { }


  async TraerFirma(id: number) {
    if (id == null) {
      alert('No existe una firma anexada a la empresa');
      return;
    }
    const res = await this.firmaService.getFirma(id);
    if (res.success) {
      this.firma = res.data;
    }else{
      console.error('Error al traer la firma: ', res.msg);
    }
  }
  // TRANSFORMAR UNA IMAGEN DESDE UNA URL A BASE64
  getBase64ImageFromURL(url: string): Promise<string> {
    const urlFirma = `${this.BASE_API}${url}`;
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = urlFirma;
    });
  }


  MostrarClausula(dato) {
    return {
      text:   `${dato.Clausula}`,
      style: 'parrafo',
      alignment: 'justify',
    }
  }
  async generarPDF(datos) {
    await this.TraerFirma(datos.Trabajador.Empresa_contratante.idfirma);
    if (this.firma) this.base64Firma = await this.getBase64ImageFromURL(this.firma?.rutaImg);
    
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const fontSize = 12;
    const nombreTrabajador = datos.Trabajador.nombres.toUpperCase() + ' ' + datos.Trabajador.apellidos.toUpperCase();
    const sexoTrabajador = datos.Trabajador.sexo;
    const esHombre = sexoTrabajador === 'M';
    const esMujer = sexoTrabajador === 'F';
    // SE APLICARÁ DON SI ES HOMBRE Y DOÑA SI ES FEMENINO
    // SI NO ESTA CONFIGURADO SU SEXO SE APLICARÁ AMBOS
    const terminoDONDONA = esHombre ? 'Don' : esMujer ? 'Doña' : 'Don/ña';
    const terminoNACIDO = esHombre ? 'nacido' : esMujer ? 'nacida' : 'nacido/a';

     const clausulas = datos.Clausula.split('|');
     console.log({clausulas});

     const texto = clausulas[0];

    const numeroRomano = texto.match(/^[IVXLCDM]+/)[0];
    const enCuantoA = texto.match(/en cuanto a la/)[0];

    // Buscar la palabra "Remuneraciones"
    const palabra = new RegExp(`\\b${datos.clausulaSeleccionada}\\b`, 'i');
    const matchResult = texto.match(palabra);
    const clauNombre = matchResult ? matchResult[0] : null;

    // Obtener el resto del texto después de "Remuneraciones"
    const splitResult = texto.split(clauNombre);
    const restoTexto = splitResult.length > 1 ? splitResult[1].trim() : '';

    var documentDefinition = {

      pageSize: {
        width: 216 * 2.83465,
        height: 356 * 2.83465
      },
      content: [
        {
          text: "\n\n\nANEXO DE CONTRATO\n\n",
          style: "header",
          alignment: "center",
        },
        {
          lineHeight: 1.5,
          margin: [40, 0, 40, 0],
          alignment: "justify",
          text: [
            {
              text: "I.  Partes\n\n",
              style: "romanos",
            },
            {
              text: `En ${datos.Ciudad.nombre.charAt(0) + datos.Ciudad.nombre.slice(1).toLowerCase()}, a  ${this.helper.FechaaTexto(Date.now())} entre `,
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.Empresa_contratante.nombre}, `,
              style: "parrafo_negro",
            },
            {
              text: "Rol Único Tributario N°",
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.Empresa_contratante.run}, `,
              style: "parrafo",
            },
            {
              text: " representada legalmente por ",
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.Empresa_contratante.representante} `,
              style: "parrafo_negro",
            },
            {
              text: `cédula nacional de identidad N° ${datos.Trabajador.Empresa_contratante.cedula} `,
              style: "parrafo",
            },
            {
              text: ` con domicilio en  ${datos.Trabajador.Empresa_contratante.ubicacion}, comuna y ciudad de ${datos.Trabajador.Empresa_contratante.CiudadEmpresa.nombre}, Región del Maule, `,
              style: "parrafo",
            },
            {
              text: `correo electrónico ${datos.Trabajador.Empresa_contratante.correo}, `,
              style: "parrafo",
            },
            {
              text: ", en adelante ",
              style: "parrafo",
            },
            {
              text: "\"el empleador\"",
              style: "parrafo_negro",
            },
            {
              text: ` y ${terminoDONDONA} `,
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.nombres.toUpperCase()} ${datos.Trabajador.apellidos.toUpperCase()}`,
              style: "parrafo_negro",
            },
            {
              text: " cédula de identidad N° ",
              style: "parrafo",
            },
            {
              text: `${this.helper.formatoRut(datos.Trabajador.rut)} `,
              style: "parrafo_negro",
            },
            {
              text: "domiciliado en ",
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.direccion}, ${datos.Trabajador.Ciudad.nombre}, ${datos.Trabajador.Ciudad.CiudadRegion.nombre}`,
              style: "parrafo",
            },
            {
              text: `, de nacionalidad ${datos.Trabajador.Nacionalidad.nombre.toLowerCase()}, ${datos.Trabajador.Estado_civil.nombre.toLowerCase()}, ${terminoNACIDO} el ${this.helper.FechaTextoContrato(datos.Trabajador.fecha_nacimiento)}, afiliado a `,
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.Afp.nombre}, `,
              style: "parrafo_negro",
            },
            {
              text: "sistema de salud ",
              style: "parrafo",
            },
            {
              text: `${datos.Trabajador.Prevision.nombre}, `,
              style: "parrafo_negro",
            },
            {
              text: `correo electrónico ${datos.Trabajador.correo} y `,
              style: "parrafo",
            },
            {
              text: `número de telefono ${datos.Trabajador.telefono} `,
              style: "parrafo",
            },
            {
              text: "en adelante ",
              style: "parrafo",
            },
            {
              text: "\"el trabajador\"",
              style: "parrafo_negro",
            },
            {
              text: ", se ha convenido el siguiente anexo de contrato de trabajo: \n\n",
              style: "parrafo",
            },
          ],
        },
        {
          text: "II.  Modificación de Clausula\n\n",
          style: "parrafo_negro",
        },
      	{
          lineHeight: 1.5,
          margin: [40, 0, 40, 0],
          text: [
            
            {
              text: "Se modifica la clausula " ,
              alignment: 'justify',
            },
            {
              text:  `${numeroRomano || 'Sin clausula definida'}`,
              alignment: 'justify',
              style: 'parrafo_negro'
            },
            {
              text: ` ${enCuantoA} `,
              alignment: 'justify',
            },
            {
              text: `${clauNombre} `,
              alignment: 'justify',
              style: 'parrafo_negro'
            },
            {
              text: `${restoTexto}`,
              alignment: 'justify',
            },
           

          ]
        },
        {
          text: `\nEl presente anexo reemplaza todos los demás anexos que sobre la materia las partes hubiesen celebrado con anterioridad y el contrato de trabajo celebrado entre las partes se mantiene vigente en todo lo no modificado por el presente anexo.`,
          style: "parrafo",
          alignment: 'justify',
          lineHeight: 1.5,
        },

        {
          text: `\n Para constancia de lo anterior, firman las partes en dos ejemplares del mismo tenor, quedando uno para “el trabajador” y el otro para “el empleador”.`,
          style: "parrafo",
          alignment: 'justify',
          lineHeight: 1.5,
        },
        // {
        //   fontSize: 15,
        //   bold: true,
        //   margin: [
        //     20,
        //     150,
        //     0,
        //     0,
        //   ],
        //   text: "________________________________            __________________________________",
        // },
        {
          columns:[
            {
              alignment: 'center',
              image: this.base64ImageWhite,
              height: 80,
            },
            {
              alignment: 'center',
              image: this.base64Firma??this.base64ImageWhite,
              height: 80,
            }
          ]
        },
        {
          columns: [
            {
              canvas: [{ type: 'line', x1: 0, y1: 0, x2: 200, y2: 0 }],
              width: '*',
              alignment: 'center',
            },
            {
              canvas: [{ type: 'line', x1: 0, y1: 0, x2: 200, y2: 0 }],
              width: '*',
              alignment: 'center',
            },
          ],
        },
        {
          columns: [
            {
              fontSize: 9,
              alignment: "center",
              style: "parrafo_negroo",
              text: `${nombreTrabajador}\n`,
            },
            {
              fontSize: 9,
              alignment: "center",
              style: "parrafo_negroo",
              text:  `${datos.Trabajador.Empresa_contratante.nombre}\n`,
            },
          ],
        },
        {
          columns: [
            {
              fontSize: 9,
              alignment: "center",
              style: "parrafo_negroo",
              text: `C.I.N° ${this.helper.formatoRut(datos.Trabajador.rut)}\n`,

            },
            {
              fontSize: 9,
              alignment: "center",
              style: "parrafo_negroo",
              text: `RUT : ${datos.Trabajador.Empresa_contratante.run}\n`,
            },
          ]
        },

        {
          columns: [
            {
              fontSize: 9,
              alignment: 'center',
              style: "parrafo_negroo",
              text: `EL TRABAJADOR\n`,

            },
            {
              fontSize: 9,
              alignment: 'center',
              style: "parrafo_negroo",
              text: `EL EMPLEADOR\n`,
            },
          ]
        }
      ],
      styles: {
        header: {
          fontSize: fontSize, // 20
          bold: true,
        },
        romanos: {
          fontSize: fontSize, // 16
          bold: true,
          decoration: "underline",
        },
        tabla: {
          bold: true,
          fontSize: fontSize, // 13
        },
        parrafo: {
          margin: [40, 0, 40, 0],
          fontSize: fontSize, // 13
        },
        parrafo_negro: {
          margin: [40, 0, 40, 0],
          fontSize: fontSize, // 13
          bold: true,
        },
        parrafo_negroo: {
          margin: [40, 0, 40, 0],
          fontSize: fontSize, // 14
          bold: true,
        },
      },
    }

    pdfMake.fonts = {
      TimesNewRoman: {
        normal: 'https://fonts.googleapis.com/css2?family=Lora&family=Source+Code+Pro&display=swap', // Reemplaza con la ruta a tu archivo de fuente Times New Roman
        bold: 'https://fonts.googleapis.com/css2?family=Lora:wght@700&family=Source+Code+Pro&display=swap', // Ruta al archivo de fuente en negrita
        italics: 'https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Source+Code+Pro&display=swap', // Ruta al archivo de fuente en cursiva
        bolditalics: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,700&family=Source+Code+Pro&display=swap' // Ruta al archivo de fuente en negrita y cursiva
      }
    }

    let pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    const pdf64 = await new Promise((resolve) => {
      pdfDocGenerator.getDataUrl(resolve);
    });
    this.src=pdf64;
    // console.log(this.src);
    return this.src;
  }

}
