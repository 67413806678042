import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { PermisoService } from 'src/app/pages/services/permisos.service';
import { Permiso } from 'src/app/shared/models/permisos.interface';
import { PermisosPDFService } from 'src/app/pages/services/PDF-GENERADOR/permisos/permisos.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { formatRut } from 'rutlib';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
import { SolicitudPermisoService } from 'src/app/pages/services/solicitud/solicitud-permisos.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-listar-permisos-tabajador',
  templateUrl: './listar-permisos-tabajador.component.html',
  styleUrls: ['./listar-permisos-tabajador.component.css']
})
export class ListarPermisosTabajadorComponent implements OnInit {
  @Input() rut: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  pdfMake: any;
  dataSource: any;
  displayedColumns = ['fechaDesde','fechaHasta', 'motivo', 'acciones'];
  permisosTrabajador: any[] = [];
  loading: boolean = false;

  //Variables para rellenar documento PDF

  nombreTrabajador: any;
  rutTrabajador: any;
  empresaTrabajador: any;
  rolEmpresa: any;
  fechaDesde: any;
  fechaHasta: any;
  motivo:any;
  fechaCreacionSolicitud: any;

  constructor(
    private documentoService: DocumentosService,
    private permisosService: PermisoService,
    private solicitudDocumentoService: SolicitudesDocumentosService,
    private solicitudPermisoService: SolicitudPermisoService,
    private permisosPDFService: PermisosPDFService,
    private modalService : NgbModal,
   
    private alert: AlertHelper
  ) { }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  async ngOnInit() {
    await this.loadPdfMaker();
    await this.TraerPermisos();
  }

   obtenerNombreMes(numeroMes) {
    const meses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    return meses[numeroMes - 1];
  }

  async Borrar(id: number) {
    this.loading = true;
    if (confirm('¿Seguro que desea eliminar el permiso?')) {
      const res = await this.documentoService.eliminarDocumentoAsync(id, 'PERMISO');
      if (res.success) {
        this.alert.success_small('Se creo la solicitud para eliminar el permiso.');
        await this.TraerPermisos();
      } else {
        this.alert.error_small(res.msg);
      }
    }
    this.loading = false;
  }

  async TraerPermisos() {
    const resPer = await this.permisosService.TraerPermisosRutAsync(this.rut);
    if (resPer.success) {
      this.permisosTrabajador = resPer.data;
      const permisosTrabajadorSource = [];
      console.log("pasa por aqui 1");
      for (const permiso of this.permisosTrabajador) {
        permiso.fechaDesde.split(',');
        console.log(this.permisosTrabajador);
        console.log(permiso);
        const resDoc = await this.documentoService.getPermiso(permiso.id);
        console.log(resDoc);
        if (resDoc.success) {
          const documento = resDoc.data[0];
          console.log(documento);
          const resSol = await this.solicitudDocumentoService.TraerPorDocumento(documento.cp_documento);
          if (resSol) {
            permiso.pendienteSolicitud = permiso;
          } else {
            permiso.pendienteSolicitud = false;
          }
        } else {
          permiso.pendienteSolicitud = false;
        }
        permisosTrabajadorSource.push(permiso);
      }
      this.dataSource = new MatTableDataSource(permisosTrabajadorSource);
      this.dataSource.paginator = this.paginator;
    } else {
      this.alert.error_small('Hubo un error al obtener los permisos');
    }
  }

   Descargar(permiso: any) {
    console.log("log para checar:", permiso)
    this.permisosPDFService.generarPDF(permiso)
   
   
  }


  openModal(contenidos: any, rut: any) {
    
    this.modalService.open(contenidos, { fullscreen: false, scrollable: true });
  }

}