//import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { BancoService } from 'src/app/pages/services/banco/banco.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { cleanRut, validateRut, getLastDigitOfRut, formatRut } from 'rutlib';
import Swal from 'sweetalert2';
//import { BooleanInput } from '@angular/cdk/coercion';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { AdelantosService } from 'src/app/pages/services/adelantos/adelantos.service';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { DatePipe } from '@angular/common';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';

// import { RegionComunas } from 'src/app/shared/models/region.interface';

@Component({
  selector: 'app-agregar-trabajador',
  templateUrl: './agregar-trabajador.component.html',
  styleUrls: ['./agregar-trabajador.component.css'],
  providers: [DatePipe]
})
export class AgregarTrabajadorComponent implements OnInit {
  modalReference: any = null;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  optionsNumber = {
    digitGroupSeparator: '', // usar punto como separador de miles
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  }
  optionsDecimal = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 4, // si mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  }

  minDate: Date;
  maxDate: Date;

  render = 0;
  renderciudad = 0;
  rendercomuna = 0;

  trabajadoresregion :any;
  trabajadorescomunas: any;
  // trabajadoresprovincia: any;
  // trabajadorescomuna: any;
  ciudades: any;
  sucursales: any
  cargos: any;
  empresas: any;
  afps: any;
  previsiones: any;
  estado_civil: any;
  nacionalidades: any;
  bancos: any;
  pase_movilidad: boolean = false;
  licencia_conducir: boolean = false;
  disableValorIsapre: boolean = true;
  requiredAfp: boolean = true;
  existe_trabajador: any;
  requiredUfIsapre: boolean = true;
  dv: any;
  rut: any;
  // ADELANTO
  checkAdelanto:boolean = false;
  montoAdelanto: number = null;
  fechaAdelanto: Date = new Date();

  trabajador: any = {
    afc: 1, //seguro de cesantia
    afp: 8,
    ahorro_apv: "",
    apellidos: "",
    banco: "",
    cargo: "",
    ciudad: "",
    comuna:"",
    correo: "",
    dias_semana: 5,
    direccion: "",
    discapacidad:0,
    empresa_contratante: "",
    estado_civil: "",
    estado_contrato: "Contrato a Plazo Fijo",
    fecha_ingreso: "",
    fecha_nacimiento: "",
    horasPactadas: 45,
    jubilado: false,
    licencia_conducir: false,
    nacionalidad: 3,
    nombre_contacto_emergencia: "",
    nombres: "",
    numero_calzado: 0,
    numero_cargas: 0,
    numero_cuenta: 0,
    pactado_isapre: "",
    pase_movilidad: false,
    patologias: "",
    prevision: 1,
    profesion: "",
    region: "",
    rut: "",
    sexo: undefined,
    sucursal: "",
    sueldo_base: 0,
    sueldo_liquido: 0,
    talla_pantalon: "",
    talla_polera_poleron: "",
    telefono_contacto_emergencia: "",
    telefono: "",
    tipo_cuenta: 0,
    tipo_jornada:101,
    tramoAsigFam: 0,
  }
  dias_semana:any[] = [
    {value: 5, texto: "5 dias a la semana"},
    {value: 6, texto: "6 dias a la semana"},
    {value: 0, texto: "Menos de 5 dias a la semana"}
  ]
  sexos:any[] = [
    {value: "M", text: "Masculino"},
    {value: "F", text: "Femenino"},
  ]
  tramos: any[] = [
    {value: 0, text: 'Sin información'},
    {value: 1, text: 'Tramo 1'}, //A
    {value: 2, text: 'Tramo 2'}, //B
    {value: 3, text: 'Tramo 3'}, //C
    {value: 4, text: 'Tramo 4'}, //D
  ]
  //Gestion Larga de Regiones
  region = [
    { id: 1, nombre: "Arica y Parinacota" },
    { id: 2, nombre: "Tarapacá" },
    { id: 3, nombre: "Antofagasta" },
    { id: 4, nombre: "Atacama" },
    { id: 5, nombre: "Coquimbo" },
    { id: 6, nombre: "Valparaíso" },
    { id: 7, nombre: "Metropolitana de Santiago" },
    { id: 8, nombre: "Libertador Gral. Bernardo OHiggins" },
    { id: 9, nombre: "Maule" },
    { id: 10, nombre: "Ñuble" },
    { id: 11, nombre: "Biobío" },
    { id: 12, nombre: "Araucanía" },
    { id: 13, nombre: "Los Ríos" },
    { id: 14, nombre: "Lagos" },
    { id: 15, nombre: "Aisén del Gral. Carlos Ibáñez del Campo" },
    { id: 16, nombre: "Magallanes y de la Antártica Chilena" }
  ]

//Gestion Larga de Comunas
  comunaXV = [
    { id: 1, nombre: 'Arica', region: 'XV' },
    { id: 2, nombre: 'Camarones', region: 'XV' },
    { id: 3, nombre: 'General Lagos', region: 'XV' },
    { id: 4, nombre: 'Putre', region: 'XV' }
  ]


  comunaI = [
    { id: 1, nombre: 'Alto Hospicio', region: 'I' },
    { id: 2, nombre: 'Camiña', region: 'I' },
    { id: 3, nombre: 'Colchane', region: 'I' },
    { id: 4, nombre: 'Huara', region: 'I' },
    { id: 5, nombre: 'Iquique', region: 'I' },
    { id: 6, nombre: 'Pica', region: 'I' },
    { id: 7, nombre: 'Pozo Almonte', region: 'I' }
  ]



  comunaII = [
    { id: 1, nombre: "Antofagasta", region: 'II' },
    { id: 2, nombre: "Calama", region: 'II' },
    { id: 3, nombre: "María Elena", region: 'II' },
    { id: 4, nombre: "Mejillones", region: 'II' },
    { id: 5, nombre: "Ollagüe", region: 'II' },
    { id: 6, nombre: "San Pedro de Atacama", region: 'II' },
    { id: 7, nombre: "Sierra Gorda", region: 'II' },
    { id: 8, nombre: "Taltal", region: 'II' },
    { id: 9, nombre: "Tocopilla", region: 'II' },
  ]
  comunaIII = [
    { id: 1, nombre: "Alto del Carmen", region: 'III' },
    { id: 2, nombre: "Caldera", region: 'III' },
    { id: 3, nombre: "Chañaral", region: 'III' },
    { id: 4, nombre: "Copiapó", region: 'III' },
    { id: 5, nombre: "Diego de Almagro", region: 'III' },
    { id: 6, nombre: "Freirina", region: 'III' },
    { id: 7, nombre: "Huasco", region: 'III' },
    { id: 8, nombre: "Tierra Amarilla", region: 'III' },
    { id: 9, nombre: "Vallenar", region: 'III' },
  ]
  comunaIV = [
    { id: 1, nombre: "Andacollo", region: 'IV' },
    { id: 2, nombre: "Canela", region: 'IV' },
    { id: 3, nombre: "Combarbalá", region: 'IV' },
    { id: 4, nombre: "Coquimbo", region: 'IV' },
    { id: 5, nombre: "Illapel", region: 'IV' },
    { id: 6, nombre: "La Higuera", region: 'IV' },
    { id: 7, nombre: "La Serena", region: 'IV' },
    { id: 8, nombre: "Los Vilos", region: 'IV' },
    { id: 9, nombre: "Monte Patria", region: 'IV' },
    { id: 10, nombre: "Ovalle", region: 'IV' },
    { id: 11, nombre: "Paiguano", region: 'IV' },
    { id: 12, nombre: "Punitaqui", region: 'IV' },
    { id: 13, nombre: "Río Hurtado", region: 'IV' },
    { id: 14, nombre: "Salamanca", region: 'IV' },
    { id: 15, nombre: "Vicuña", region: 'IV' },
  ]
  comunaV = [
    { id: 1, nombre: "Algarrobo", region: 'V' },
    { id: 2, nombre: "Cabildo", region: 'V' },
    { id: 3, nombre: "Calera", region: 'V' },
    { id: 4, nombre: "Calle Larga", region: 'V' },
    { id: 5, nombre: "Cartagena", region: 'V' },
    { id: 6, nombre: "Casablanca", region: 'V' },
    { id: 7, nombre: "Catemu", region: 'V' },
    { id: 8, nombre: "Concón", region: 'V' },
    { id: 9, nombre: "El Quisco", region: 'V' },
    { id: 10, nombre: "El Tabo", region: 'V' },
    { id: 11, nombre: "Hijuelas", region: 'V' },
    { id: 12, nombre: "Isla de Pascua", region: 'V' },
    { id: 13, nombre: "Juan Fernández", region: 'V' },
    { id: 14, nombre: "La Cruz", region: 'V' },
    { id: 15, nombre: "La Ligua", region: 'V' },
    { id: 16, nombre: "Limache", region: 'V' },
    { id: 17, nombre: "Llaillay", region: 'V' },
    { id: 18, nombre: "Los Andes", region: 'V' },
    { id: 19, nombre: "Nogales", region: 'V' },
    { id: 20, nombre: "Olmué", region: 'V' },
    { id: 21, nombre: "Panquehue", region: 'V' },
    { id: 22, nombre: "Papudo", region: 'V' },
    { id: 23, nombre: "Petorca", region: 'V' },
    { id: 24, nombre: "Puchuncaví", region: 'V' },
    { id: 25, nombre: "Putaendo", region: 'V' },
    { id: 26, nombre: "Quillota", region: 'V' },
    { id: 27, nombre: "Quilpué", region: 'V' },
    { id: 28, nombre: "Quintero", region: 'V' },
    { id: 29, nombre: "Rinconada", region: 'V' },
    { id: 30, nombre: "San Antonio", region: 'V' },
    { id: 31, nombre: "San Esteban", region: 'V' },
    { id: 32, nombre: "San Felipe", region: 'V' },
    { id: 33, nombre: "Santa María", region: 'V' },
    { id: 34, nombre: "Santo Domingo", region: 'V' },
    { id: 35, nombre: "Valparaíso", region: 'V' },
    { id: 36, nombre: "Villa Alemana", region: 'V' },
    { id: 37, nombre: "Viña del Mar", region: 'V' },
    { id: 38, nombre: "Zapallar", region: 'V' },
  ]
  comunaXIII = [
    { id: 1, nombre: "Alhué", region: 'XIII' },
    { id: 2, nombre: "Buin", region: 'XIII' },
    { id: 3, nombre: "Calera de Tango", region: 'XIII' },
    { id: 4, nombre: "Cerrillos", region: 'XIII' },
    { id: 5, nombre: "Cerro Navia", region: 'XIII' },
    { id: 6, nombre: "Colina", region: 'XIII' },
    { id: 7, nombre: "Conchalí", region: 'XIII' },
    { id: 8, nombre: "Curacaví", region: 'XIII' },
    { id: 9, nombre: "El Bosque", region: 'XIII' },
    { id: 10, nombre: "El Monte", region: 'XIII' },
    { id: 11, nombre: "Estación Central", region: 'XIII' },
    { id: 12, nombre: "Huechuraba", region: 'XIII' },
    { id: 13, nombre: "Independencia", region: 'XIII' },
    { id: 14, nombre: "Isla de Maipo", region: 'XIII' },
    { id: 15, nombre: "La Cisterna", region: 'XIII' },
    { id: 16, nombre: "La Florida", region: 'XIII' },
    { id: 17, nombre: "La Granja", region: 'XIII' },
    { id: 18, nombre: "La Pintana", region: 'XIII' },
    { id: 19, nombre: "La Reina", region: 'XIII' },
    { id: 20, nombre: "Lampa", region: 'XIII' },
    { id: 21, nombre: "Las Condes", region: 'XIII' },
    { id: 22, nombre: "Lo Barnechea", region: 'XIII' },
    { id: 23, nombre: "Lo Espejo", region: 'XIII' },
    { id: 24, nombre: "Lo Prado", region: 'XIII' },
    { id: 25, nombre: "Macul", region: 'XIII' },
    { id: 26, nombre: "Maipú", region: 'XIII' },
    { id: 27, nombre: "María Pinto", region: 'XIII' },
    { id: 27, nombre: "Melipilla", region: 'XIII' },
    { id: 28, nombre: "Ñuñoa", region: 'XIII' },
    { id: 29, nombre: "Padre Hurtado", region: 'XIII' },
    { id: 30, nombre: "Paine", region: 'XIII' },
    { id: 31, nombre: "Pedro Aguirre Cerda", region: 'XIII' },
    { id: 32, nombre: "Peñaflor", region: 'XIII' },
    { id: 33, nombre: "Peñalolén", region: 'XIII' },
    { id: 34, nombre: "Pirque", region: 'XIII' },
    { id: 35, nombre: "Providencia", region: 'XIII' },
    { id: 36, nombre: "Pudahuel", region: 'XIII' },
    { id: 37, nombre: "Puente Alto", region: 'XIII' },
    { id: 38, nombre: "Quilicura", region: 'XIII' },
    { id: 39, nombre: "Quinta Normal", region: 'XIII' },
    { id: 40, nombre: "Recoleta", region: 'XIII' },
    { id: 41, nombre: "Renca", region: 'XIII' },
    { id: 42, nombre: "San Bernardo", region: 'XIII' },
    { id: 43, nombre: "San Joaquín", region: 'XIII' },
    { id: 44, nombre: "San José de Maipo", region: 'XIII' },
    { id: 45, nombre: "San Miguel", region: 'XIII' },
    { id: 46, nombre: "San Pedro", region: 'XIII' },
    { id: 47, nombre: "San Ramón", region: 'XIII' },
    { id: 48, nombre: "Santiago", region: 'XIII' },
    { id: 49, nombre: "Talagante", region: 'XIII' },
    { id: 50, nombre: "Tiltil", region: 'XIII' },
    { id: 51, nombre: "Vitacura", region: 'XIII' },
  ]
  comunaVI = [
    { id: 1, nombre: "Chimbarongo", region: 'VI' },
    { id: 2, nombre: "Chépica", region: 'VI' },
    { id: 3, nombre: "Codegua", region: 'VI' },
    { id: 4, nombre: "Coinco", region: 'VI' },
    { id: 5, nombre: "Coltauco", region: 'VI' },
    { id: 6, nombre: "Doñihue", region: 'VI' },
    { id: 7, nombre: "Graneros", region: 'VI' },
    { id: 8, nombre: "La Estrella", region: 'VI' },
    { id: 9, nombre: "Las Cabras", region: 'VI' },
    { id: 10, nombre: "Litueche", region: 'VI' },
    { id: 11, nombre: "Lolol", region: 'VI' },
    { id: 12, nombre: "Machalí", region: 'VI' },
    { id: 13, nombre: "Malloa", region: 'VI' },
    { id: 14, nombre: "Marchihue", region: 'VI' },
    { id: 16, nombre: "Nancagua", region: 'VI' },
    { id: 17, nombre: "Navidad", region: 'VI' },
    { id: 18, nombre: "Olivar", region: 'VI' },
    { id: 19, nombre: "Palmilla", region: 'VI' },
    { id: 20, nombre: "Paredones", region: 'VI' },
    { id: 21, nombre: "Peralillo", region: 'VI' },
    { id: 22, nombre: "Peumo", region: 'VI' },
    { id: 23, nombre: "Pichidegua", region: 'VI' },
    { id: 24, nombre: "Pichilemu", region: 'VI' },
    { id: 25, nombre: "Placilla", region: 'VI' },
    { id: 26, nombre: "Pumanque", region: 'VI' },
    { id: 27, nombre: "Quinta de Tilcoco", region: 'VI' },
    { id: 28, nombre: "Rancagua", region: 'VI' },
    { id: 29, nombre: "Rengo", region: 'VI' },
    { id: 30, nombre: "Requínoa", region: 'VI' },
    { id: 31, nombre: "San Fernando", region: 'VI' },
    { id: 32, nombre: "San Francisco de Mostazal", region: 'VI' },
    { id: 33, nombre: "San Vicente de Tagua Tagua", region: 'VI' },
    { id: 34, nombre: "Santa Cruz", region: 'VI' },
  ]
  comunaVII = [
    { id: 1, nombre: "Cauquenes", region: 'VII' },
    { id: 2, nombre: "Chanco", region: 'VII' },
    { id: 3, nombre: "Colbún", region: 'VII' },
    { id: 4, nombre: "Constitución", region: 'VII' },
    { id: 5, nombre: "Curepto", region: 'VII' },
    { id: 6, nombre: "Curicó", region: 'VII' },
    { id: 7, nombre: "Empedrado", region: 'VII' },
    { id: 8, nombre: "Hualañé", region: 'VII' },
    { id: 9, nombre: "Licantén", region: 'VII' },
    { id: 10, nombre: "Linares", region: 'VII' },
    { id: 11, nombre: "Longaví", region: 'VII' },
    { id: 12, nombre: "Maule", region: 'VII' },
    { id: 13, nombre: "Molina", region: 'VII' },
    { id: 14, nombre: "Parral", region: 'VII' },
    { id: 15, nombre: "Pelarco", region: 'VII' },
    { id: 16, nombre: "Pelluhue", region: 'VII' },
    { id: 17, nombre: "Pencahue", region: 'VII' },
    { id: 18, nombre: "Rauco", region: 'VII' },
    { id: 19, nombre: "Retiro", region: 'VII' },
    { id: 20, nombre: "Romeral", region: 'VII' },
    { id: 21, nombre: "Río Claro", region: 'VII' },
    { id: 22, nombre: "Sagrada Familia", region: 'VII' },
    { id: 23, nombre: "San Clemente", region: 'VII' },
    { id: 24, nombre: "San Javier de Loncomilla", region: 'VII' },
    { id: 25, nombre: "San Rafael", region: 'VII' },
    { id: 26, nombre: "Talca", region: 'VII' },
    { id: 27, nombre: "Teno", region: 'VII' },
    { id: 28, nombre: "Vichuquén", region: 'VII' },
    { id: 29, nombre: "Villa Alegre", region: 'VII' },
    { id: 30, nombre: "Yerbas Buenas", region: 'VII' },
  ]
  comunaXVI = [
    { id: 1, nombre: "Bulnes", region: 'XVI' },
    { id: 2, nombre: "Chillán Viejo", region: 'XVI' },
    { id: 3, nombre: "Chillán", region: 'XVI' },
    { id: 4, nombre: "Cobquecura", region: 'XVI' },
    { id: 5, nombre: "Coelemu", region: 'XVI' },
    { id: 6, nombre: "Coihueco", region: 'XVI' },
    { id: 7, nombre: "El Carmen", region: 'XVI' },
    { id: 8, nombre: "Ninhue", region: 'XVI' },
    { id: 9, nombre: "Ñiquén", region: 'XVI' },
    { id: 10, nombre: "Pemuco", region: 'XVI' },
    { id: 11, nombre: "Pinto", region: 'XVI' },
    { id: 12, nombre: "Portezuelo", region: 'XVI' },
    { id: 13, nombre: "Quillón", region: 'XVI' },
    { id: 14, nombre: "Quirihue", region: 'XVI' },
    { id: 15, nombre: "Ránquil", region: 'XVI' },
    { id: 16, nombre: "San Carlos", region: 'XVI' },
    { id: 17, nombre: "San Fabián", region: 'XVI' },
    { id: 18, nombre: "San Ignacio", region: 'XVI' },
    { id: 19, nombre: "San Nicolás", region: 'XVI' },
    { id: 20, nombre: "Treguaco", region: 'XVI' },
    { id: 21, nombre: "Yungay", region: 'XVI' },
  ]
  comunaVIII = [
    { id: 1, nombre: "Alto Biobío", region: 'VIII' },
    { id: 2, nombre: "Antuco", region: 'VIII' },
    { id: 3, nombre: "Arauco", region: 'VIII' },
    { id: 4, nombre: "Cabrero", region: 'VIII' },
    { id: 5, nombre: "Cañete", region: 'VIII' },
    { id: 6, nombre: "Chiguayante", region: 'VIII' },
    { id: 7, nombre: "Concepción", region: 'VIII' },
    { id: 8, nombre: "Contulmo", region: 'VIII' },
    { id: 9, nombre: "Coronel", region: 'VIII' },
    { id: 10, nombre: "Curanilahue", region: 'VIII' },
    { id: 11, nombre: "Florida", region: 'VIII' },
    { id: 12, nombre: "Hualpén", region: 'VIII' },
    { id: 13, nombre: "Hualqui", region: 'VIII' },
    { id: 14, nombre: "Laja", region: 'VIII' },
    { id: 15, nombre: "Lebu", region: 'VIII' },
    { id: 16, nombre: "Los Álamos", region: 'VIII' },
    { id: 17, nombre: "Los Ángeles", region: 'VIII' },
    { id: 18, nombre: "Lota", region: 'VIII' },
    { id: 19, nombre: "Mulchén", region: 'VIII' },
    { id: 20, nombre: "Nacimiento", region: 'VIII' },
    { id: 21, nombre: "Negrete", region: 'VIII' },
    { id: 22, nombre: "Penco", region: 'VIII' },
    { id: 23, nombre: "Quilaco", region: 'VIII' },
    { id: 24, nombre: "Quilleco", region: 'VIII' },
    { id: 25, nombre: "San Pedro de la Paz", region: 'VIII' },
    { id: 26, nombre: "San Rosendo", region: 'VIII' },
    { id: 27, nombre: "Santa Bárbara", region: 'VIII' },
    { id: 28, nombre: "Santa Juana", region: 'VIII' },
    { id: 29, nombre: "Talcahuano", region: 'VIII' },
    { id: 30, nombre: "Tirúa", region: 'VIII' },
    { id: 31, nombre: "Tomé", region: 'VIII' },
    { id: 32, nombre: "Tucapel", region: 'VIII' },
    { id: 33, nombre: "Yumbel", region: 'VIII' },
  ]
  comunaIX = [
    { id: 1, nombre: "Angol", region: 'IX' },
    { id: 2, nombre: "Carahue", region: 'IX' },
    { id: 3, nombre: "Cholchol", region: 'IX' },
    { id: 4, nombre: "Collipulli", region: 'IX' },
    { id: 5, nombre: "Cunco", region: 'IX' },
    { id: 6, nombre: "Curacautín", region: 'IX' },
    { id: 7, nombre: "Curarrehue", region: 'IX' },
    { id: 8, nombre: "Ercilla", region: 'IX' },
    { id: 9, nombre: "Freire", region: 'IX' },
    { id: 10, nombre: "Galvarino", region: 'IX' },
    { id: 11, nombre: "Gorbea", region: 'IX' },
    { id: 12, nombre: "Lautaro", region: 'IX' },
    { id: 13, nombre: "Loncoche", region: 'IX' },
    { id: 14, nombre: "Lonquimay", region: 'IX' },
    { id: 15, nombre: "Los Sauces", region: 'IX' },
    { id: 16, nombre: "Lumaco", region: 'IX' },
    { id: 17, nombre: "Melipeuco", region: 'IX' },
    { id: 18, nombre: "Nueva Imperial", region: 'IX' },
    { id: 19, nombre: "Padre las Casas", region: 'IX' },
    { id: 20, nombre: "Perquenco", region: 'IX' },
    { id: 21, nombre: "Pitrufquén", region: 'IX' },
    { id: 22, nombre: "Pucón", region: 'IX' },
    { id: 23, nombre: "Purén", region: 'IX' },
    { id: 24, nombre: "Renaico", region: 'IX' },
    { id: 25, nombre: "Saavedra", region: 'IX' },
    { id: 26, nombre: "Temuco", region: 'IX' },
    { id: 27, nombre: "Teodoro Schmidt", region: 'IX' },
    { id: 28, nombre: "Toltén", region: 'IX' },
    { id: 29, nombre: "Traiguén", region: 'IX' },
    { id: 30, nombre: "Victoria", region: 'IX' },
    { id: 31, nombre: "Vilcún", region: 'IX' },
    { id: 32, nombre: "Villarrica", region: 'IX' },
  ]
  comunaXIV = [
    { id: 1, nombre: "Corral", region: 'XIV' },
    { id: 2, nombre: "Futrono", region: 'XIV' },
    { id: 3, nombre: "La Unión", region: 'XIV' },
    { id: 4, nombre: "Lago Ranco", region: 'XIV' },
    { id: 5, nombre: "Lanco", region: 'XIV' },
    { id: 6, nombre: "Los Lagos", region: 'XIV' },
    { id: 7, nombre: "Mariquina", region: 'XIV' },
    { id: 8, nombre: "Máfil", region: 'XIV' },
    { id: 9, nombre: "Paillaco", region: 'XIV' },
    { id: 10, nombre: "Panguipulli", region: 'XIV' },
    { id: 11, nombre: "Río Bueno", region: 'XIV' },
    { id: 12, nombre: "Valdivia", region: 'XIV' },
  ]
  comunaX = [
    { id: 1, nombre: "Ancud", region: 'X' },
    { id: 2, nombre: "Calbuco", region: 'X' },
    { id: 3, nombre: "Castro", region: 'X' },
    { id: 4, nombre: "Chaitén", region: 'X' },
    { id: 5, nombre: "Chonchi", region: 'X' },
    { id: 6, nombre: "Cochamó", region: 'X' },
    { id: 7, nombre: "Curaco de Vélez", region: 'X' },
    { id: 8, nombre: "Dalcahue", region: 'X' },
    { id: 9, nombre: "Fresia", region: 'X' },
    { id: 10, nombre: "Frutillar", region: 'X' },
    { id: 11, nombre: "Futaleufú", region: 'X' },
    { id: 12, nombre: "Hualaihué", region: 'X' },
    { id: 13, nombre: "Llanquihue", region: 'X' },
    { id: 14, nombre: "Los Muermos", region: 'X' },
    { id: 15, nombre: "Maullín", region: 'X' },
    { id: 16, nombre: "Osorno", region: 'X' },
    { id: 17, nombre: "Palena", region: 'X' },
    { id: 18, nombre: "Puerto Montt", region: 'X' },
    { id: 19, nombre: "Puerto Octay", region: 'X' },
    { id: 20, nombre: "Puerto Varas", region: 'X' },
    { id: 21, nombre: "Puqueldón", region: 'X' },
    { id: 22, nombre: "Purranque", region: 'X' },
    { id: 23, nombre: "Puyehue", region: 'X' },
    { id: 24, nombre: "Queilén", region: 'X' },
    { id: 25, nombre: "Quellón", region: 'X' },
    { id: 26, nombre: "Quemchi", region: 'X' },
    { id: 27, nombre: "Quinchao", region: 'X' },
    { id: 28, nombre: "Río Negro", region: 'X' },
    { id: 29, nombre: "San Juan de la Costa", region: 'X' },
    { id: 30, nombre: "San Pablo", region: 'X' },
  ]
  comunaXI = [
    { id: 1, nombre: 'Aisén', region: 'XI' },
    { id: 2, nombre: 'Chile Chico', region: 'XI' },
    { id: 3, nombre: 'Cisnes', region: 'XI' },
    { id: 4, nombre: 'Cochrane', region: 'XI' },
    { id: 5, nombre: 'Coyhaique', region: 'XI' },
    { id: 6, nombre: 'Guaitecas', region: 'XI' },
    { id: 7, nombre: 'Lago Verde', region: 'XI' },
    { id: 8, nombre: 'OHiggins', region: 'XI' },
    { id: 9, nombre: 'Río Ibáñez', region: 'XI' },
    { id: 10, nombre: 'Tortel', region: 'XI' }
  ]
  comunaXII = [
    { id: 1, nombre: 'Antártica', region: 'XII' },
    { id: 2, nombre: 'Cabo de Hornos (Ex Navarino)', region: 'XII' },
    { id: 3, nombre: 'Laguna Blanca', region: 'XII' },
    { id: 4, nombre: 'Natales', region: 'XII' },
    { id: 5, nombre: 'Porvenir', region: 'XII' },
    { id: 6, nombre: 'Primavera', region: 'XII' },
    { id: 7, nombre: 'Punta Arenas', region: 'XII' },
    { id: 8, nombre: 'Río Verde', region: 'XII' },
    { id: 9, nombre: 'San Gregorio', region: 'XII' },
    { id: 10, nombre: 'Timaukel', region: 'XII' },
    { id: 11, nombre: 'Torres del Paine', region: 'XII' }
  ]

  estado_contrato = [
    { id: 1, valor: 'Contrato a Plazo Fijo' },
    { id: 2, valor: 'Contrato Indefinido' },
    { id: 3, valor: 'Contrato por Obra o Faena' }
  ]

  tipo_jornada = [
    { id: 101, valor: 'Ordinaria-Art. 22' },
    { id: 201, valor: 'Parcial-Art. 40 Bis' },
    { id: 412, valor: 'Especial-Art 22 Inciso Final' },
    { id:701,  valor: 'Exenta-Art. 22'}
  ]

  tipo_discapacidad = [
    { id: 0, valor: 'No' },
    { id: 1, valor: 'Discapacidad Certificada por la compin' },
    { id: 2, valor: 'Asignatorio Pensión por Invalidez Total' },
    { id:3,  valor: 'Pensionado por Invalidez Parcial'}
  ]

  tipo_afc = [
    { id: 1, valor: 'Si' },
    { id: 0, valor: 'No' },
  ]

  tipo_cuentas = [
    { id: 1, valor: 'Cuenta Corriente' },
    { id: 2, valor: 'Cuenta Vista/Rut' },
    { id: 3, valor: 'Cuenta de Ahorro' }
  ]

  constructor(
    private auth: AuthService,
    private trabajadoresService: TrabajadoresService,
    private adelantoServie: AdelantosService,
    private bancoService: BancoService,
    private liquidaconService: LiquidacionService,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private datePipe: DatePipe,
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 18, 11, 31);
  }

  async guardar() {
    let valid: any;
    valid = this.validarFormulario(this.trabajador);
    if (valid.length == 0) {
      this.trabajador.rut = this.rut + this.dv;
      //console.log(this.trabajador);
      this.trabajadoresService.CrearTrabajador(this.trabajador).subscribe(async (res: RequestResponse) => {
        console.log('Trabajador creado: ',{res});
        if (res.success == true) {
          // SI SE SOLICITO EL ADELANTO, AGREGARLO A LA BASE DE DATOS
          if (this.checkAdelanto) {
            await this.agregarAdelanto();
          }else{
            this.alert.success_small(res.msg);
            this.modalReference.close();
            this.limpiarFormulario();
            this.debouncer.next(true);
          }
        } else {
          this.alert.error_small(res.msg!);
        }
      })
    }
    else {
      this.alert.error_small(valid);
    }
  }

  async agregarAdelanto(){
    const res = await this.trabajadoresService.TraerTrabajadoresRutAsync(this.trabajador.rut);
    if (res.success) {
      const data = res.data;
      const adelanto:any = {
        banco: data[0].Banco.id,
        empresa: data[0].Empresa_contratante.id,
        fechaInicioContrato: this.datePipe.transform(this.fechaAdelanto, 'yyyy-MM-dd'),
        montoAdelanto: this.montoAdelanto,
        numeroCuenta: data[0].numero_cuenta,
        fk_trabajador: parseInt(this.trabajador.rut),
        tipoCuenta: data[0].tipo_cuenta,
        userAt: this.auth.ObtenerNombre,
      }
      this.adelantoServie.Create_adelantos(adelanto).subscribe((res:RequestResponse) => {
        if (res.success) {
          this.alert.success_small('Se creo correctamente el trabajador.');
          this.modalReference.close();
          this.limpiarFormulario();
          this.debouncer.next(true);
        }
      });
    }else{
      this.alert.error_small(res.msg);
    }
  }

  verificaTrabajador() {
    let rutDB: any;
    if (this.trabajador.rut != undefined && this.trabajador.rut != '') {
      if (this.trabajador.rut.length >= 7 && this.trabajador.rut.length < 9) {
        this.dv = getLastDigitOfRut(this.trabajador.rut);
        this.trabajador.rut = this.trabajador.rut + this.dv;
      }
      else {
        this.dv = this.trabajador.rut.slice(-1);
        this.dv = this.dv.toUpperCase();
      }
      const rutClean = cleanRut(this.trabajador.rut);
      if (validateRut(rutClean)) {
        this.rut = rutClean;
        this.rut = this.rut.slice(0, -1);
        this.dv = (this.dv == 'K') ? 0 : this.dv;
        rutDB = this.rut + this.dv;
        this.trabajadoresService.TraerTrabajadoresRut(rutDB).subscribe(res => {
          if (res.success) {
            this.trabajador.rut = "";
            this.rut = "";
            this.dv = "";
            Swal.fire({
              icon: 'error',
              title: 'El trabajador con el rut ingresado ya existe!'
            })
          }
        })
      }else {
        this.trabajador.rut = "";
        this.rut = "";
        this.dv = "";
        Swal.fire({
          icon: 'error',
          title: 'Rut inválido'
        })
      }
    }
  }

  formatearRut(event){
    this.trabajador.rut = formatRut(event.target.value)
  }

  open(mostrar: any) {
    // this.limpiarFormulario();
    this.trabajador.telefono = '+56';
    this.trabajador.telefono_contacto_emergencia = '+56';
    this.modalReference = this.modalService.open(mostrar, { size: 'xl' });
    this.trabajadoresService.TraerCiudades().subscribe((res: any) => {
      this.ciudades = res.data;
    });

  }

  async ngOnInit() {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });

    const resPrevired = await this.liquidaconService.TraerPreviredLiquidacion();
    if (resPrevired.success) {
      const previred = resPrevired.data;
      this.trabajador.sueldo_base = previred.rentaMinima;
    }else{
      console.log(resPrevired.msg);
    }

    this.trabajadoresService.TraerSucursales().subscribe((res: any) => {

      this.sucursales = res.data;
    });

    this.trabajadoresService.TraerCargos().subscribe((res: any) => {

      this.cargos = res.data;

    });

    this.trabajadoresService.TraerEmpresas().subscribe((res: any) => {

      this.empresas = res.data;

    });
    this.trabajadoresService.TraerAfp().subscribe((res: any) => {

      this.afps = res.data;

    });

    this.trabajadoresService.TraerPrevisiones().subscribe((res: any) => {

      this.previsiones = res.data;

    });

    this.trabajadoresService.TraerEstadosCiviles().subscribe((res: any) => {

      this.estado_civil = res.data;

    });

    this.trabajadoresService.TraerNacionalidades().subscribe((res: any) => {

      this.nacionalidades = res.data;

    });

    this.bancoService.TraerTodos().subscribe((res: any) => {
      this.bancos = res.data;
    });

  }


  //************** Funciones para Obtener datos de los select ******************/
  //Obtener region
  ObtenerRegion(event: any) {
    this.trabajador.region = event.value?.nombre || '';
    if (this.trabajador.region === '') {
      this.renderciudad = 0;
      return;
    }

    const findRegion = this.region.find(x => x.nombre === this.trabajador.region);
    this.renderciudad = findRegion.id || 0;
  }
  //Obtener Comunas
  ObtenerComunaXV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

    this.rendercomuna = 0

  }

  ObtenerComunaI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }

  ObtenerComunaII(event: any) {
    // console.log(event);
    this.trabajador.comuna = event.value.nombre

    if(event.value.nombre == 'Calama'){
      this.rendercomuna = 19;



    }else{
      this.rendercomuna = 8;
    }

  }

  ObtenerComunaIII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }
  ObtenerComunaIV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre


  }
  ObtenerComunaV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre
    if(event.value.nombre == 'San Antonio' || event.value.nombre == 'Cartagena' || event.value.nombre == 'El Tabo' || event.value.nombre == 'El Quisco' || event.value.nombre == 'Algarrobo' || event.value.nombre == 'Santo Domingo'){
      this.rendercomuna = 11;

    }else{
      this.rendercomuna = 3;
    }
  }
  ObtenerComunaXIII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }
  ObtenerComunaVI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }

  ObtenerComunaVII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

    if(event.value.nombre == 'Curicó' || event.value.nombre == 'Rauco' || event.value.nombre == 'Romeral' || event.value.nombre == 'Sagrada Familia' || event.value.nombre == 'Teno' || event.value.nombre == 'Vichuquén' || event.value.nombre == 'Molina' || event.value.nombre == 'Licantén' || event.value.nombre == 'Hualañé'){
      this.rendercomuna = 16;

    }else{
      this.rendercomuna = 6;
    }

  }

  ObtenerComunaXVI(event: any) {
    //console.log(event);
    this.trabajador.comuna= event.value.nombre

  }

  ObtenerComunaVIII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

    if(event.value.nombre == 'Concepción' || event.value.nombre == 'Chiguayante' || event.value.nombre == 'Coronel' || event.value.nombre == 'Florida' || event.value.nombre == 'Hualpén' || event.value.nombre == 'Hualqui' || event.value.nombre == 'Lota' || event.value.nombre == 'Penco' || event.value.nombre == 'San Pedro de la Paz' || event.value.nombre == 'Santa Juana' || event.value.nombre == 'Talcahuano' || event.value.nombre == 'Tomé'){
      this.rendercomuna = 2;

    }else{
      this.rendercomuna = 17;
    }
  }

  ObtenerComunaIX(event: any) {
    // console.log(event);
    this.trabajador.comuna = event.value.nombre


  }

  ObtenerComunaXIV(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }

  ObtenerComunaX(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

    if(event.value.nombre == 'Osorno' || event.value.nombre == 'San Pablo' || event.value.nombre == 'Puyehue' || event.value.nombre == 'Puerto Octay' || event.value.nombre == 'Purranque' || event.value.nombre == 'Río Negro' || event.value.nombre == 'San Juan de la Costa'){
      this.rendercomuna = 13;

    }else{
      this.rendercomuna = 10;
    }
  }

  ObtenerComunaXI(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }

  ObtenerComunaXII(event: any) {
    //console.log(event);
    this.trabajador.comuna = event.value.nombre

  }



  ObtenerCiudad(event: MatSelectChange) {

    this.trabajador.ciudad = event.value;

  }
  ObtenerSucursal(event: MatSelectChange) {

    this.trabajador.sucursal = event.value;

  }
  ObtenerCargo(event: MatSelectChange) {

    this.trabajador.cargo = event.value;

  }

  ObtenerEmpresaContratante(event: MatSelectChange) {

    this.trabajador.empresa_contratante = event.value;

  }

  ObtenerTramo(event: MatSelectChange) {
    this.trabajador.tramoAsigFam = event.value;
  }

  ObtenerPrevision(event: MatSelectChange) {
    this.trabajador.pactado_isapre = undefined;

    if (event.value == 1) {
      this.disableValorIsapre = true;
      this.requiredUfIsapre = false;
    }
    else {
      this.disableValorIsapre = false;
      this.requiredUfIsapre = true;
    }
  }

  ObtenerTipoJornada(event: MatSelectChange) {
    this.trabajador.tipo_jornada = event.value;
  }

  ObtenerDiscapacidad(event: MatSelectChange) {
    this.trabajador.discapacidad = event.value;
  }

  ObtenerBanco(event: MatSelectChange) {
    this.trabajador.banco = event.value;
  }

  ObtenerTipoCuenta(event: MatSelectChange) {
    this.trabajador.tipo_cuenta = event.value;
  }

  ObtenerEstadoCivil(event: MatSelectChange) {
    this.trabajador.estado_civil = event.value;
  }

  ObtenerSexo(event:MatSelectChange) {
    this.trabajador.sexo = event.value;
  }

  onChangeSlidePase(event: any) {
    this.trabajador.pase_movilidad = event.checked;
  }

  onChangeSlideLicencia(event: any) {
    this.trabajador.licencia_conducir = event.checked;
  }

  onChangeSlideJubilado(event: any) {
    this.trabajador.jubilado = event.checked;
    this.requiredAfp = !event.checked;
  }

  limpiarFormulario() {
    this.trabajador.rut = undefined;
    this.trabajador.nombres = undefined;
    this.trabajador.apellidos = undefined;
    this.trabajador.direccion = undefined;
    this.trabajador.ciudad = undefined;
    this.trabajador.sucursal = undefined;
    this.trabajador.cargo = undefined;
    this.trabajador.empresa_contratante = undefined;
    this.trabajador.afp = undefined;
    this.trabajador.prevision = undefined;
    this.trabajador.estado_contrato = undefined;
    this.trabajador.estado_civil = undefined;
    this.trabajador.nacionalidad = undefined;
    this.trabajador.fecha_nacimiento = undefined;
    this.trabajador.banco = undefined;
    this.trabajador.numero_cuenta = undefined;
    this.trabajador.tipo_cuenta = undefined;
    this.trabajador.fecha_ingreso = undefined;
    this.trabajador.correo = undefined;
    this.trabajador.telefono = undefined;
    this.trabajador.pase_movilidad = false;
    this.trabajador.licencia_conducir = false;
    this.trabajador.jubilado = false;
    this.trabajador.patologias = undefined;
    this.trabajador.numero_calzado = undefined;
    this.trabajador.talla_pantalon = undefined;
    this.trabajador.talla_polera_poleron = undefined;
    this.trabajador.profesion = undefined;
    this.trabajador.nombre_contacto_emergencia = undefined;
    this.trabajador.telefono_contacto_emergencia = undefined;
    this.trabajador.numero_cargas = undefined;
    this.trabajador.pactado_isapre = undefined;
    this.trabajador.ahorro_apv = undefined;
    this.trabajador.sueldo_bruto = undefined;
    this.trabajador.sueldo_liquido = undefined;
    this.disableValorIsapre = true;
    this.requiredUfIsapre = false;
    this.trabajador.region = undefined;
    this.trabajador.comuna = undefined;
    this.trabajadoresregion = undefined;
    this.trabajadorescomunas= undefined;
    this.trabajador.tipo_jornada = 101;
    this.trabajador.horasPactadas = undefined;
    this.renderciudad = 0;
    this.rendercomuna = 0;
    this.rut = "";
    this.dv = "";
  }

  validarFormulario(datos: any): any {
    if (datos.rut == undefined || datos.rut == "") {
      return 'Rut no válido';
    }
    else if (datos.nombres == undefined || datos.nombres == "" || datos.nombres.length < 3) {
      return 'Nombre no valido (min. 3 caracteres)';
    }
    else if (datos.apellidos == undefined || datos.apellidos == "" || datos.apellidos.length < 3) {
      return 'Apellido no válido (min. 3 caracteres)';
    }
    else if (datos.telefono == undefined || datos.telefono == "" || datos.telefono.length < 12 || datos.telefono.length > 12) {
      return 'Teléfono no válido';
    }
    // else if (datos.correo == undefined || datos.correo == "" || (datos.correo != undefined && datos.correo != '' && !this.auth.esEmailValido(datos.correo))) {
    //   return 'Correo no válido';
    // }
    else if (datos.fecha_nacimiento == undefined || datos.fecha_nacimiento == "") {
      return 'Fecha de nacimiento no válida';
    }
    else if (datos.nacionalidad == undefined || datos.nacionalidad == "") {
      return 'Nacionalidad no válida';
    }
    else if (datos.profesion == undefined || datos.profesion == "") {
      return 'Profesión no válida';
    }
    else if (datos.numero_cargas == undefined || !Number.isInteger(parseInt(datos.numero_cargas)) || parseInt(datos.numero_cargas) > 15) {
      return 'Número de cargas no válido';
    }
    else if (datos.estado_civil == undefined || datos.estado_civil == "") {
      return 'Estado civil no válido';
    }
    else if (datos.ciudad == undefined || datos.ciudad == "") {
      return 'Ciudad no válida';
    }
    else if (datos.direccion == undefined || datos.direccion == "" || datos.direccion.length < 3) {
      return 'Dirección no válida (min. 3 caracteres)';
    }
    else if (datos.direccion == undefined && ((datos.patologias.length > 0 && datos.patologias.length < 3) || datos.patologias.length > 250)) {
      return 'Patologías no válida (min. 3 caracteres)';
    }
    else if (datos.telefono_contacto_emergencia == undefined || datos.telefono_contacto_emergencia == "" || datos.telefono_contacto_emergencia.length < 12 || datos.telefono_contacto_emergencia.length > 12) {
      return 'Teléfono de emergencia no válido';
    }
    else if (datos.nombre_contacto_emergencia == undefined || datos.nombre_contacto_emergencia == "" || datos.nombre_contacto_emergencia.length < 3) {
      return 'Nombre del contacto de emergencia no válido (min. 3 caracteres)';
    }
    else if (datos.estado_contrato == undefined || datos.estado_contrato == "") {
      return 'Estado del contrato no válido';
    }
    else if (datos.sucursal == undefined || datos.sucursal == "") {
      return 'Sucursal no válida';
    }
    else if (datos.cargo == undefined || datos.cargo == "") {
      return 'Cargo no válido';
    }
    else if (datos.empresa_contratante == undefined || datos.empresa_contratante == "") {
      return 'Empresa contratante no válida';
    }
    else if (datos.jubilado != true && (datos.afp == undefined || datos.afp == "")) {
      return 'Afp no válida';
    }
    else if (datos.prevision == undefined || datos.prevision == "") {
      return 'Previsión no válida';
    }
    else if (datos.prevision != 1 && (datos.pactado_isapre == undefined || datos.pactado_isapre > 10)) {
      return 'Monto pactado (Isapre) no válido';
    }
    else if (datos.banco == undefined || datos.banco == "") {
      return 'Banco no válido';
    }
    else if (datos.tipo_cuenta == undefined || datos.tipo_cuenta == "") {
      return 'Tipo de cuenta no válido';
    }
    else if (datos.numero_cuenta == undefined || !Number.isInteger(parseInt(datos.numero_cuenta)) || parseInt(datos.numero_cuenta) > 999999999999999) {
      return 'Número de cuenta no válido';
    }
    else if (datos.fecha_ingreso == undefined || datos.fecha_ingreso == "") {
      return 'Fecha de ingreso no válida';
    }
    else if (datos.sueldo_bruto != undefined && (!Number.isInteger(parseInt(datos.sueldo_bruto)) || parseInt(datos.sueldo_bruto) > 999999999)) {
      return 'Sueldo bruto no válido';
    }
    else if (datos.sueldo_liquido != undefined && (!Number.isInteger(parseInt(datos.sueldo_liquido)) || parseInt(datos.sueldo_liquido) > 999999999)) {
      return 'Sueldo líquido no válido';
    }
    else if (datos.talla_pantalon != undefined && (!Number.isInteger(parseInt(datos.talla_pantalon)) || parseInt(datos.talla_pantalon) > 99)) {
      return 'Talla pantalon no válida';
    }
    else if (datos.numero_calzado != undefined && (!Number.isInteger(parseInt(datos.numero_calzado)) || parseInt(datos.numero_calzado) > 99)) {
      return 'Talla calzado no válida';
    }
    else {
      return '';
    }
  }


  validaTelefono(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trabajador.telefono = this.formatoTelefono(filterValue);
  }

  validaTelefonoEmegencia(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.trabajador.telefono_contacto_emergencia = this.formatoTelefono(filterValue);
  }

  formatoTelefono(filterValue: any) {
    let codigo: any,
      numero: any;
    if (filterValue.length < 3) {
      return '+56';
    } else {
      if (filterValue.substring(0, 3) != '+56') {
        return '+56';
      } else {
        codigo = filterValue.substring(0, 3);
        numero = filterValue.substring(3);
        numero = numero.replace(/[^\d]/g, '');
        return codigo + numero;
      }
    }
  }

  cerrarModal() {
    this.modalReference.close();
  }

  soloNumeros(event: Event, param: boolean) {
    var invalidChars = ["-", "+", "e"];
    if (param) { invalidChars.push(".") }

    const filterValue = (event.target as HTMLInputElement);
    filterValue.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }

  //*************** fin funciones  ********************/
}
