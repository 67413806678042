import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MotivosCartaAmonestacionService } from 'src/app/pages/services/motivos-carta-amonestacion.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-agregar-motivo',
  templateUrl: './agregar-motivo.component.html',
  styleUrls: ['./agregar-motivo.component.css']
})
export class AgregarMotivoComponent implements OnInit {
  @Output() actualizarTabla: EventEmitter<boolean> = new EventEmitter();
  motivoAdd = {
    nombre: '',
    descripcion: '',
  };
  descripcionAux: string = '';
  posicionAux: number = 0;
  variables: any[] = [
    {nombre: '%TRABAJADOR%', descripcion: 'Nombre del trabajador'},
    {nombre: '%LISTA_FECHA%', descripcion: 'Lista de fechas seleccionadas'},
    {nombre: '%LISTA_FECHA_HORA%', descripcion: 'Lista de fechas y horas seleccionadas'},
    {nombre: '%LISTA_FECHA_ATRASOS%', descripcion: 'Lista de fechas de atrasos del trabajador'},
    {nombre: '%MESES_SELECCIONADOS%', descripcion: 'Meses seleccionados en el periodo'},
  ]

  constructor(
    private modalService: NgbModal,
    private motivoService: MotivosCartaAmonestacionService, 
    private alert: AlertHelper,
  ) { }
  
  ngOnInit() {
  }

  open(content: any){
    const modalRef = this.modalService.open(content, { size: 'xl' });
    modalRef.result.then(() => { // CLOSE
      this.limpiarFormulario();
    }, () => { // DIMISED
      this.limpiarFormulario();
    });
  }
  limpiarFormulario() {
    this.motivoAdd.nombre = '';
    this.motivoAdd.descripcion = '';
  }
  async AgregarMotivo(){
    if (this.motivoAdd.nombre.trim() === '') {
      this.alert.error_small('Nombre del motivo esta vacio.');
      return;
    }
    const res = await this.motivoService.agregarMotivoAsync(this.motivoAdd);
    if (res.success) {
      this.actualizarTabla.emit(true);
      this.alert.success_small('Motivo agregado correctamente.');
      this.modalService.dismissAll();
    }else{
      this.alert.error_small(res.msg);
    }
  }

  changeDescripcion(){
    // OBTENER EL ELEMENTO DEL DOM
    const textArea = document.getElementById('textarea_descripcion') as HTMLTextAreaElement;
    // OBTENER LA POSICIÓN ACTUAL DEL PUNTERO DE ESCRITURA
    this.posicionAux = textArea.selectionStart;

    this.descripcionAux = this.motivoAdd.descripcion;

  }

  onOptionSelected(event: any) {
    const textAntes = this.descripcionAux.substring(0, this.posicionAux);
    const textDespues = this.descripcionAux.substring(this.posicionAux);
    // ESTABLECER EL NUEVO VALOR EN EL INPUT
    this.motivoAdd.descripcion = textAntes + event.option.value + textDespues;
  }
}
