import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import { Sucursal, RequestResponseGetAllSucursal, RequestResponseActualizar } from 'src/app/shared/models/sucursales.interface';


@Injectable({
  providedIn: 'root'
})
export class SucursalService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }



  GetAll_sucursales(): Observable<RequestResponseGetAllSucursal> {
    return this.http
    .get<RequestResponseGetAllSucursal >(`${environment.API}/rrhh/sucursal/`)
    .pipe(
      map((res:RequestResponseGetAllSucursal) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async GetAllSucursalesAsync(): Promise<RequestResponseGetAllSucursal> {
    try {
      return await this.http.get<RequestResponseGetAllSucursal>(`${environment.API}/rrhh/sucursal/`).toPromise();
    } catch (error) {
      console.error(error);
      return ( {success: false, msg: 'Hubo un error al obtener las sucursales'} as RequestResponseGetAllSucursal); 
    }
  }


  Create_sucursal(sucursal:Sucursal): Observable<RequestResponse> {      
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/sucursal/crear/`,sucursal)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Actualizar_sucursal(sucursal:any): Observable<RequestResponseActualizar>  {
    return this.http
    .put<RequestResponseActualizar >(`${environment.API}/rrhh/sucursal/editar/${sucursal.id}`,sucursal)
    .pipe(
      map((res:RequestResponseActualizar) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  Obtener_sucursal(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/sucursal/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  async Obtener_sucursalAsync(id:number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/sucursal/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return ( {success: false, msg: 'Hubo un error al obtener la sucursal'} as RequestResponse); 
    }
  }

  Eliminar_sucursal(id:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/sucursal/eliminar/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }


  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

}




