import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLiquidacionesComponent } from './main-liquidaciones/main-liquidaciones.component';
import { ListaLiquidacionesComponent } from './lista-liquidaciones/lista-liquidaciones.component';
import { MaterialModule } from 'src/app/material.module';
import { FileUploadModule } from 'ng2-file-upload';

import { MAT_DATE_LOCALE } from '@angular/material/core'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgregarLiquidacionesComponent } from './agregar-liquidaciones/agregar-liquidaciones.component';
import { GenerarLiquidacionComponent } from './generar-liquidacion/generar-liquidacion.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ToastModule } from 'primeng/toast';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TrabajadoresModule } from '../../trabajadores/trabajadores.module';
import { TestLiquidacionesComponent } from './test-liquidaciones/test-liquidaciones.component';
import { SubirLiquidacionFirmadaComponent } from './subir-liquidacion-firmada/subir-liquidacion-firmada.component'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy
import { horasExtrasModule } from '../../horas-extras/horasExtras.module';
import { AdelantosModule } from '../../gestion-pago/adelantos/adelantos.module';
import { PrestamosModule } from '../../gestion-pago/prestamos/prestamos.module';
import { HelperModule } from 'src/app/helper.module';
import { ImpuestoSegundaCatModule } from 'src/app/pages/contabilidad/parametros/impuesto-segunda-cat/impuesto-segunda-cat.module';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AutonumericModule } from '@angularfy/autonumeric';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';
import { SolicitudesModule } from '../../solicitudes/solicitudes.module';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
// import { SubirLiquidacionFirmadaComponent } from './subir-liquidacion-firmada/subir-liquidacion-firmada.component'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy

@NgModule({
  declarations: [
    MainLiquidacionesComponent,
    ListaLiquidacionesComponent,
    AgregarLiquidacionesComponent,
    GenerarLiquidacionComponent,
    TestLiquidacionesComponent,
    SubirLiquidacionFirmadaComponent,
  ],
  imports: [
    HelperModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    PdfViewerModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    CalendarModule,
    SelectButtonModule,
    TrabajadoresModule,
    horasExtrasModule,
    AdelantosModule,
    PrestamosModule,
    ImpuestoSegundaCatModule,
    PrimeNgModule,
    BuscarFilterModule,
    SolicitudesModule,
    MdbModalModule,
    AutonumericModule.forRoot(),
  ],
  exports: [
    MainLiquidacionesComponent,
    ListaLiquidacionesComponent,
    AgregarLiquidacionesComponent,
    GenerarLiquidacionComponent
  ],
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
                                  // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 
  ],
  bootstrap: [TestLiquidacionesComponent],
})
export class LiquidacionesModule { }