<div class="container-fluid">
  <div class="col-12 pl-0 pr-0">
    <mat-card class="mat_card_trabajadores">
      <h1 class>Solicitudes de vacaciones</h1>
    </mat-card>
  </div>

  <p-toolbar styleClass="bg-light animate__animated animate__fadeIn" *ngIf="selection?.selected.length > 0">
    <div class="p-toolbar-group-left overflow-x-auto">
      <button pButton pRipple icon="pi pi-thumbs-up" class="p-button-rounded p-button-success p-button-sm mr-2"
        (click)="AllDesicion(true)" label="Aprobar Seleccionadas">
      </button>
      <button pButton pRipple icon="pi pi-thumbs-down" class="p-button-rounded p-button-danger p-button-sm"
        (click)="AllDesicion(false)" label="Rechazar Seleccionadas">
      </button>
    </div>
  </p-toolbar>
  <p-toolbar styleClass="bg-light">
    <div class="p-toolbar-group-left overflow-x-auto">
      <div style="display: flex; justify-content: space-between!important;">
        <p-dropdown placeholder="Todos los trabajadores" [options]="trabajadoresFilter" [(ngModel)]="trabajadorSelect"
          [filter]="true" filterBy="text" optionLabel="text" optionValue="value" class="mr-2" [showClear]="true"
          (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body"></p-dropdown>
        <div class="mr-2">
          <label for="periodo" class="mr-1">Periodo</label>
          <p-calendar [readonlyInput]="true" inputId="periodo" [(ngModel)]="periodo" view="month" dateFormat="mm/yy"
            (onSelect)="onMonthChandler($event)" [minDate]="MIN_DATE" appendTo="body">
          </p-calendar>
        </div>
        <div class="mr-2">
          <label for="estado" class="mr-1">Estado</label>
          <p-multiSelect [options]="estadoSolicitud" [(ngModel)]="estadoSolicitudSelect" (onChange)="FiltrarDatos()"
            placeholder="Todos los estados" optionLabel="text" optionValue="value" appendTo="body">
          </p-multiSelect>
        </div>
      </div>



    </div>

    <div>
      <button mat-button class="button_color_primary" (click)="open(content)">
        <mat-icon style="color: white">add</mat-icon>
        <span style="color: white">Agregar Solicitud </span>

      </button>
    </div>

  </p-toolbar>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection?.hasValue() && isAllSelected()"
            [indeterminate]="selection?.hasValue() && !isAllSelected()" color="warn">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection?.toggle(element) : null"
            [checked]="selection?.isSelected(element)" color="warn">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-center">
          <span>{{selection?.selected.length??0}}</span>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="trabajador">
        <th mat-header-cell *matHeaderCellDef> TRABAJADOR </th>
        <td mat-cell *matCellDef="let element"> {{ element.Trabajador.nombres + element.Trabajador.apellidos }} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="fechaDesde">
        <th mat-header-cell *matHeaderCellDef> DESDE </th>
        <td mat-cell *matCellDef="let element"> {{ element.fechaDesde | date: 'dd/MM/yyyy' }} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="fechaHasta">
        <th mat-header-cell *matHeaderCellDef> HASTA </th>
        <td mat-cell *matCellDef="let element"> {{ element.fechaHasta | date: 'dd/MM/yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="dias">
        <th mat-header-cell *matHeaderCellDef> DIAS </th>
        <td mat-cell *matCellDef="let element"> {{ element.diasTomados }} </td>
      </ng-container>

      <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> ESTADO </th>
        <td mat-cell *matCellDef="let element">
          <p-message *ngIf="element.estado === 0" severity="info" text="PENDIENTE"></p-message>
          <p-message *ngIf="element.estado === 1" severity="success" text="APROBADA"></p-message>
          <p-message *ngIf="element.estado === 2" severity="error" text="RECHAZADA"></p-message>
          <p-message *ngIf="element.estado === 3" severity="error" text="ELIMINADA"></p-message>
          <p-message *ngIf="(element.estado === null || element.estado === undefined)" severity="info"
            text="PENDIENTE"></p-message>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
            <td mat-cell *matCellDef="let element"> </td>
          </ng-container> -->

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef style="width: 20%;"> ACCIONES </th>
        <td mat-cell *matCellDef="let element" class="pl-2">
          <!-- ELEGIR OPCION -->
          <button
            *ngIf="(element.estado === null || element.estado !== 3) && (user.rol === 'ADMIN' || user.rol === 'GERENCIA')"
            type="button" pButton icon="pi pi-check-circle" class="mr-2" pTooltip="Elegir decisión"
            tooltipPosition="bottom" (click)="getIdSolicitud(element.id);menu1.toggle($event)"></button>
          <p-menu #menu1 [popup]="true" [model]="desiciones"></p-menu>

          <!-- MAS OPCIONES -->
          <!-- <button *ngIf="element.estado !== null && element.estado !== 0" type="button" pButton icon="pi pi-bars"
                pTooltip="Más opciones" tooltipPosition="bottom"
                (click)="masOpciones(element, viewHistorialDecisiones);menu2.toggle($event)"></button> -->
          <p-menu #menu2 [popup]="true" [model]="moreOptions"></p-menu>
          <!-- VER PDF -->
          <p-button icon="pi pi-book" styleClass="ml-2 p-button-secondary" *ngIf="((user.rol === 'ADMIN' || user.rol === 'GERENCIA') && element.estado === 1)"
            (click)="verPDF(element)" pTooltip="Ver PDF"
            tooltipPosition="bottom"></p-button>
        </td>
        <td mat-footer-cell *matFooterCellDef class="font-weight-bold text-right">TOTAL REGISTROS FILTRADOS:
          {{dataSource?.data.length}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center p-1 mt-2" colspan="8">
          <h1>No existen datos</h1>
        </td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>


<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar nueva solicitud</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <!-- Fila 1 -->
    <div class="row">
      <!-- Trabajador -->
      <div class="col col-12">
        <p-dropdown [options]="allTrabajadores" [(ngModel)]="selectedTrabajador" optionLabel="text" optionValue="value"
          placeholder="Seleccione trabajador" [showClear]="true" [filter]="true" filterBy="text"
          [ngModelOptions]="{standalone: true}"></p-dropdown>
        <!-- <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>Trabajador</mat-label>
                      
                      <mat-select name="rut" (selectionChange)="ObtenerTrabajador($event)">
                          <mat-option *ngFor="let element of trabajadores_aux" [value]="element.rut">
                              {{element.nombres}} {{element.apellidos}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field> -->
      </div>
      <!-- Fecha -->
      <div class="col col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Fecha Inicio</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="fechaInicio" (ngModelChange)="onFechaInicioChange()" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker touchUI #picker [ngModelOptions]="{standalone: true}"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <!-- Example 1: <input [(ngModel)]="person.firstName" name="first">
          Example 2: <input [(ngModel)]="person.firstName" [ngModelOptions]="{standalone: true}"> -->

    <!-- Fila 2 -->
    <div class="row">
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Fecha Fin</mat-label>
          <input matInput [matDatepicker]="picker2" [(ngModel)]="fechaFin" (ngModelChange)="onFechaFinChange()" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker touchUI #picker2 [ngModelOptions]="{standalone: true}"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <!-- Fila 3 -->
    <div class="row">
      <div class="col">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Días tomados</mat-label>
          <input type="text" matInput matInput name="diasTomados" disabled [(ngModel)]="diasTomados">
        </mat-form-field>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="guardarSolicitud()">Guardar</button>
  </div>
</ng-template>