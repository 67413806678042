import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { SolicitudPermisoService } from 'src/app/pages/services/solicitud/solicitud-permisos.service';
import { MatTableDataSource } from '@angular/material/table';
import { parseISO } from 'date-fns'
import { SolicitudesPermisos } from 'src/app/shared/models/solicitud-permisos-interface'
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from 'primeng/api';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { RazonService } from 'src/app/pages/services/razon/razon.service'
import { SubirDocumentoComponent } from 'src/app/pages/admin/solicitudes/solicitud-permisos/subir-documento/subir-documento.component';
import { FileUploader } from 'ng2-file-upload';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2'; 

@Component({
  selector: 'app-main-solicitud-permisos',
  templateUrl: './main-solicitud-permisos.component.html',
  styleUrls: ['./main-solicitud-permisos.component.css']
})
export class MainSolicitudPermisosComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  uploader: FileUploader;
  messageService: MessageService;
  idSolicitud: number;
  permisos: any;
  periodo: Date | null = null;
  decisiones: MenuItem[] = [];
  todosLosTrabajadores: any[] = [];
  todasLasRazones: any[] = [];
  trabajadores: any[] = [];
  user: any;
  src: any;
  razon: string;
  motivo: string;
  fechaInicio: Date;
  fechaFin: Date;
  fechaMinima: Date = new Date();
  permiso:{
    fk_trabajador: 0,
    fk_solicitud_permiso: 0,
    fechaDesde: "",
    fechaHasta: "",
    motivo:""
  }
  // Filters
  loading = false;
  trabajadoresFilter: any[] = [];
  MIN_DATE = parseISO('2023-12-01');
  trabajadorSelect: string = '';
  razonSelect: string = '';
  estadoSolicitudSelect: any[] = [];
  dataSource: MatTableDataSource<SolicitudesPermisos>;
  selection: SelectionModel<SolicitudesPermisos>;
  solicitudesFilter: SolicitudesPermisos[] = [];

  // DROPDOWNS
  displayedColumns: string[] = ['trabajador', 'fechaDesde', 'fechaHasta', 'razon', 'motivo', 'horaDesde', 'horaHasta', 'acciones'];

  //CONTEXT MENU
  modalReference = null

  constructor(
    private solicitudPermisoService: SolicitudPermisoService,
    private razonService: RazonService,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private trabajadoresService: TrabajadoresService,
    private documentoService: DocumentosService,
    private dialog: MatDialog
  ) {}
  
  open(content: any) {
    this.modalReference = this.modalService.open(content)
  }

  ngOnInit(): void {
    console.log(this.user);
    this.cargarTabla();
    this.getAllTrabajadores();
    this.getAllRazones();
    this.src = {
      urlFile: '',
      blobFile: '',
    }
   
   

    // this.decisiones = [
    //   {
    //     label: 'Aprobar',
    //     icon: 'pi pi-thumbs-up',
    //     command: () => this.cambiarEstado(this.idSolicitud, 1),
    //   }
    // ];
  }

  
  

  cargarSolicitud() {
    this.cargarTabla();
  }
  onMonthChandler(event: any) {
    console.log("monthChandler")
    this.periodo = event;
    this.FiltrarDatos();
  }
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    const registros = this.dataSource.data.filter(x => !x.estado);
    this.selection.select(...registros);
  }

  getIdSolicitud(id: any) {
    this.idSolicitud = id;
  }
  getAllTrabajadores() {
    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        if (res.success) {
          this.todosLosTrabajadores = res.data.map(x => ({ value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }))
            .sort((a, b) => a.text.localeCompare(b.text));

        }
      }
    )
  }

  getAllRazones() {
    this.razonService.lista_razon().subscribe(
      (res) => {
        if (res.success) {
          console.log(res.data)
          const razonesConPermiso = res.data.filter(x => (x.razon.includes("PERMISO")))
          this.todasLasRazones = razonesConPermiso.map(x => ({ value: x.id, text: x.razon }))
          console.log(this.todasLasRazones)
        }
      }
    )
  }

  cargarTabla() {
    this.solicitudPermisoService.traerInfo().subscribe((res: any) => {
      if (res.success) {
        this.permisos = res.data;
        // Crear una lista de promesas para verificar los documentos
        const permisosConDocumentosPromises = this.permisos.map(permiso => 
          this.documentoService.TraerPermiso(permiso.id).toPromise()
            .then((documentosResponse) => {
              return {
                ...permiso,
                tieneDocumento: documentosResponse.success && documentosResponse.data.length > 0
              };
            })
            .catch((error) => {
              console.error('Error al verificar documentos:', error);
              return {
                ...permiso,
                tieneDocumento: false
              };
            })
        );
  
        // Esperar a que todas las promesas se resuelvan
        Promise.all(permisosConDocumentosPromises).then(permisosActualizados => {
          this.dataSource = new MatTableDataSource(permisosActualizados);
          this.selection = new SelectionModel<SolicitudesPermisos>(true, []);
          this.dataSource.paginator = this.paginator;
        });
      }
    });
  }


  FiltrarDatos() {
    // Revisar si se ha seleccionado algún trabajador o estado
    const hasFilters = (this.estadoSolicitudSelect && this.estadoSolicitudSelect.length > 0) ||
      (this.trabajadorSelect && this.trabajadorSelect.length > 0);

    // Mantener el período seleccionado si hay otros filtros aplicados
    if (hasFilters && !this.periodo) {
      this.periodo = null; // O cualquier valor por defecto que desees
    }

    // Filtrar por trabajador
    let filteredData = this.permisos;

    if (this.trabajadorSelect && this.trabajadorSelect.length > 0) {
      filteredData = filteredData.filter(solicitud => {
        return this.trabajadorSelect.includes(solicitud.fk_trabajador.toString());
      });
    }
    // Filtrar por periodo solo si se ha seleccionado un período válido
    if (this.periodo instanceof Date) {
      filteredData = filteredData.filter(x => parseISO(x.fechaDesde).getMonth() === this.periodo.getMonth() && parseISO(x.fechaDesde).getFullYear() === this.periodo.getFullYear());
    }

    // Configurar la tabla con los datos filtrados
    this.dataSource = new MatTableDataSource<SolicitudesPermisos>(filteredData);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<SolicitudesPermisos>(true, []);
  }

  disabledDates(date: Date) {
    return this.fechaInicio && date < this.fechaInicio;
  }

  guardarSolicitud() {
    const diferenciaHoras = -3; 
    //AJUSTO LA HORA PARA UTC SANTIAGO DE CHILE    
    const ajustarHora = (fecha) => {
        const utcDate = new Date(fecha.toISOString());
        const horaLocal = utcDate.getHours() + diferenciaHoras;
        utcDate.setHours(horaLocal);
        return utcDate;
    };
    const fechaInicioAjustada = ajustarHora(this.fechaInicio);
    const fechaFinAjustada = ajustarHora(this.fechaFin);
    const fechaInicioSplit = fechaInicioAjustada.toISOString().split('T')[0];
    const horaInicioSplit = fechaInicioAjustada.toISOString().split('T')[1].slice(0, 8);
    const fechaFinSplit = fechaFinAjustada.toISOString().split('T')[0];
    const horaFinSplit = fechaFinAjustada.toISOString().split('T')[1].slice(0, 8);

    console.log("HORA INICIO: ", horaInicioSplit);
    console.log("HORA FIN: ", horaFinSplit);

    const data = {
        fk_trabajador: this.trabajadorSelect,
        fechaDesde: fechaInicioSplit,
        fechaHasta: fechaFinSplit,
        fk_razon: this.razonSelect,
        motivo: this.motivo,
        horaDesde: horaInicioSplit,
        horaHasta: horaFinSplit
    };
    console.log(data);
    this.solicitudPermisoService.generarSolicitud(data).subscribe((resp: any) => {
      if (resp.success) {
        this.alert.success_small(resp.message);
        this.cargarTabla();
        this.modalReference.close();
        this.limpiarFormulario();
      }
    })
}



  limpiarFormulario() {
    this.trabajadorSelect = undefined;
    this.fechaInicio = undefined;
    this.fechaFin = undefined;
  }

  async verPDF(element: any) {
    try {
      console.log('element', element.id);
      this.documentoService.TraerPermiso(element.id).subscribe(
        (res)=>{
          if (res.success){
            window.open(`${environment.API}/uploads/permisos/`+res.data[0].ruta);
          } else {
            console.log('aaaa')
          }
        }
      )
    } catch (error) {
      console.error('Error al cargar el PDF:', error);
    }
  }

  openUploadDialog(id: number, rut: string): void {
    const dialogRef = this.dialog.open(SubirDocumentoComponent, {
      width: '400px',
      data: { idSolicitud: id, rutTrabajador: rut }
    });
  
    dialogRef.componentInstance.documentUploaded.subscribe(() => {
      this.cargarTabla();
    });
  }

  EliminarDocumento(id: number): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.solicitudPermisoService.Eliminar(id).subscribe(
          () => {
            this.cargarTabla();
            window.location.reload();
            Swal.fire(
              'Eliminado!',
              'El documento ha sido eliminado.',
              'success'
            );
            this.loading = false;
          },
          error => {
            Swal.fire(
              'Error!',
              'Hubo un problema al eliminar el documento.',
              'error'
            );
            this.loading = false;
          }
        );
      }
    });
  }
}