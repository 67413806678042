import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent  {
  title = 'clientRRHH';

  menu(){ }
  menus =[
    { id: false, valor: 'RRHH'},
    { id: true, valor: 'Contabilidad' } ,
  ]

  menuChoosen: boolean= false;

  primeTranslation = {
    startsWith: "Comienza con",
    contains: "Contiene",
    notContains: "No contiene",
    endsWith: "Termina con",
    equals: "Igual a",
    notEquals: "No es igual a",
    noFilter: "Sin filtro",
    lt: "Menor que",
    lte: "Menor o igual que",
    gt: "Mayor que",
    gte: "Mayor o igual que",
    is: "Es",
    isNot: "No es",
    before: "Antes de",
    after: "Después de",
    dateIs: "La fecha es",
    dateIsNot: "La fecha no es",
    dateBefore: "La fecha es anterior a",
    dateAfter: "La fecha es posterior a",
    clear: "Limpiar",
    apply: "Aplicar",
    matchAll: "Coincidir con todo",
    matchAny: "Coincidir con alguno",
    addRule: "Agregar regla",
    removeRule: "Eliminar regla",
    accept: "Sí",
    reject: "No",
    choose: "Elegir",
    upload: "Subir",
    cancel: "Cancelar",
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sá"],
    monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    dateFormat: "mm/dd/aa",
    firstDayOfWeek: 0,
    today: "Hoy",
    weekHeader: "Sem",
    weak: 'Débil',
    medium: 'Medio',
    strong: 'Fuerte',
    passwordPrompt: 'Ingrese una contraseña',
    emptyMessage: 'No se encontraron resultados',
    emptyFilterMessage: 'No se encontraron resultados'
  }

  constructor(
    private config: PrimeNGConfig
  )
  {
    this.config.setTranslation(this.primeTranslation);
  }
}