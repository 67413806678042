export const clausulas = [
    {
        id:1,
        nombre:"Partes"
    },
    {
        id:2,
        nombre:"Naturaleza de los servicios"
    },
    {
        id:3,
        nombre:"Lugar de prestación de servicios"
    },
    {
        id:4,
        nombre:"Jornada de trabajo"
    },
    {
        id:5,
        nombre:"Remuneraciones"
    },
    {
        id:6,
        nombre:"Duración de la relación jurídica laboral"
    },
    {
        id:7,
        nombre:"Cláusula de vigencia"
    },
    {
        id:8,
        nombre:"A tener en cuenta"
    },
    {
        id:9,
        nombre:"Cláusula de confidencialidad"
    },
    {
        id:10,
        nombre:"Propiedad intelectual"
    },
    {
        id: 12,
        nombre: "Regulaciones asignaciones de vehiculos"
    }
]

export const valores = [
    {
        id : 1,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la PARTES "
    },
    {
        id : 2,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la NATURALEZA DE LOS SERVICIOS"
    },
    {
        id : 3,
        valor:`%[ aca ira el numero de clausula ]% en cuanto a la LUGAR DE PRESTACIÓN DE SERVICIOS`
    },
    {
        id : 4,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la JORNADA DE TRABAJO"
    },
    {
        id : 5,
        valor:`%[ aca ira el numero de clausula ]% en cuanto a la REMUNERACIONES`
    },
    {
        id : 6,
        valor:`%[ aca ira el numero de clausula ]% en cuanto a la DURACIÓN DE LA RELACIÓN JURÍDICA LABORAL prorrogando el contrato, firmado entre ambas partes con fecha %[ aqui la fecha de hoy  ]%, el cual tendrá duración hasta el %[ aca la fecha de final ]%. En caso contrario, terminará por alguna de las causales de la legislación vigente.`
    },
    {
        id : 7,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la CLÁUSULA DE VIGENCIA"
    },
    {
        id : 8,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la A TENER EN CUENTA"
    },
    {
        id : 9,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la CLÁUSULA DE CONFIDENCIALIDAD"
    },
    {
        id : 10,
        valor:"%[ aca ira el numero de clausula ]% en cuanto a la PROPIEDAD INTELECTUAL"
    },
    {
        id : 11,
        valor:`%[ aca ira el numero de clausula ]% en cuanto a la DURACIÓN DE LA RELACIÓN JURÍDICA LABORAL prorrogando el contrato, firmado entre ambas partes con fecha %[ aqui la fecha de hoy  ]%, el cual desde ahora en adelante, tendrá carácter INDEFINIDO. En caso contrario, terminará por alguna de las causales de la legislación vigente.`
    },
    {
        id : 12,
        valor:`%[ aca ira el numero de clausula ]% en cuanto a la REGULACIÓN ASIGNACIÓN DE VEHÍCULO`
    },
]
