import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core'; //se necesita para que el datepicker pueda mostrarse en el formato dd-mm-yyyy
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelperModule } from 'src/app/helper.module';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';
import { MainContratosComponent } from './main-contratos/main-contratos.component';
import { MostrarContratosComponent } from './mostrar-contratos/mostrar-contratos.component';
import { ListaContratoComponent } from './lista-contrato/lista-contrato.component';
import { VerContratosEmpleadosComponent } from './ver-contratos-empleados/ver-contratos-empleados.component';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [
    MainContratosComponent,
    ListaContratoComponent,
    VerContratosEmpleadosComponent,
    MostrarContratosComponent,
  ],
  imports: [
    CommonModule,
    HelperModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    BuscarFilterModule,
    FileUploadModule,
  ],
  exports:[
    MainContratosComponent,
    ListaContratoComponent,
    VerContratosEmpleadosComponent,
    MostrarContratosComponent
  ],
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' } //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
                                                    // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 

  ]
})
export class ContratosModule { }
