import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { catchError,map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, forkJoin, of, throwError } from 'rxjs';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import moment from 'moment';
import { contratosPorVencerTotal } from 'src/app/shared/models/home-contratoPorVencer.interface';
import { asRoughSeconds } from '@fullcalendar/core';
import { CheckLoginGuard } from 'src/app/shared/guards/check-login.guard';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {
  private actualizarContratosVencido = new BehaviorSubject<boolean>(false);
  $actualizarContratoVencido = this.actualizarContratosVencido.asObservable();

  private contratosPorVencer = new BehaviorSubject<any[]>([]);x
  private anexosPorVencer = new BehaviorSubject<any[]>([]);
  $contratosPorVencer = this.contratosPorVencer.asObservable();
  $anexosPorVencer = this.anexosPorVencer.asObservable();
  private correEnviado: boolean = false;
  arrayContratos: any[] = [];

  constructor(
    private http:HttpClient,
    private alert:AlertHelper,
    private trabajadorService:TrabajadoresService,
    ) { }

  EnviarArchivo(archivo:any){
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/contrato/documento`,archivo)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  EnviarContrato(conrato:any){

    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/contrato/crear`,conrato)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerListaContrato(rut:any){
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/contrato`,9373373)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/contrato/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  // async TraerPorRutAsync(rut: number): Promise<RequestResponse> {
  //   try {
  //     return await this.http.get<RequestResponse>(`${environment.API}/rrhh/contrato/${rut}`).toPromise();
  //   } catch (error) {
  //     return { success: false, msg: 'Hubo un error al obtener los contratos.' } as RequestResponse;
  //   }
  // }

  TraerArchivo(id:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarcontratosrut/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  eliminarContrato(id:number): Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/contrato/eliminar/${id}`)
      .pipe(
        map((res: RequestResponse) => {
          return res;
        }),
        catchError((error) => this.handlerError(error))
      );
  }
  // SERVICIO COMPARTIDO PARA SABER CUANDO CAMBIA LOS DATOS DE LOS CONTRATOS
  // SIRVE PARA ACTUALIZAR LAS NOTIFICACIONES DE LOS CONTRATOS A PLAZO FIJO POR VENCER
  setVerificarContratosPlazoFijo(verificar:boolean){
    this.actualizarContratosVencido.next(verificar);
  }

  verificarContratosPlazoFijo() {
    const now = new Date();
    const fechaHoy = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return new Promise((resolve, reject) => {
      this.trabajadorService.TraerTrabajadores().pipe(
        switchMap(({data}) => {
          const trabajadores: any[] = data.filter(x => x.estado === 1 && x.estado_contrato === 'Contrato a Plazo Fijo');
          if (trabajadores.length === 0) { return of([]); }

          const obs = trabajadores.map(x => {
            return this.TraerPorRut(x.rut).pipe(
              map(({data}) => {
                if (data.length > 0) {
                  const contratos: any[] = data.map(this.transformarFechaContrato);
                  const contratosNoRemuneracion = [...contratos].filter(element => element.tipo_contrato != 3)
                  const contratosOrdenados = [...contratosNoRemuneracion].sort(this.ordenarContratos);
                  const contratosFiltrados = contratosOrdenados.filter(element => element.fechaTermino != null)
                  if (contratosFiltrados.length > 0) {
                    const lastContrato = contratosFiltrados[0]
                    const fechaTermino = lastContrato.fechaTermino;
                    const diffDays = fechaTermino.diff(fechaHoy, 'days')

                    if (diffDays <= 5 && diffDays > -30) {
                      return {
                        id: lastContrato.id,
                        rut: lastContrato.fk_trabajador,
                        nombreTrabajador: x.nombres + ' ' + x.apellidos,
                        empresa: x.Empresa_contratante.nombre,
                        fechaTermino: lastContrato.fechaTermino.toDate().toLocaleDateString('es-CL'),
                        diffDays: diffDays,
                        aviso: lastContrato.aviso,
                        tipo_contrato: lastContrato.tipo_contrato,
                      };
                    }
                  }
                }
                return null;
              }),
              catchError(error => this.handlerError(error))
            );
          });
          return forkJoin(obs);
        }),
        map(res => res.filter(x => x !== null)),
        catchError(error => this.handlerError(error)),
      ).subscribe((contratosPorVencer: contratosPorVencerTotal[]) => {
        // EMITIR PARA CONTABILIZAR EL NUMERO DE CONTRATOS POR VENCER
        this.contratosPorVencer.next(contratosPorVencer.filter(x => x.tipo_contrato === 1));
        this.anexosPorVencer.next(contratosPorVencer.filter(x => x.tipo_contrato === 2 || x.tipo_contrato === 3));

        const contratosAcumulados: contratosPorVencerTotal[] = [];
        contratosPorVencer.forEach(contrato => {
          if (contrato.diffDays === 5) {
            contratosAcumulados.push(contrato);
          }
        });

        console.log("Contratos acumulados:", contratosAcumulados);
        const enviarCorreo = contratosAcumulados.some(contrato => contrato.aviso === 0 || contrato.aviso === null);

        if (enviarCorreo) {
            this.enviarCorreo(contratosAcumulados);
            this.correEnviado = true;

            console.log(enviarCorreo);
        }
        resolve(contratosPorVencer);
      });
    });
  }

  enviarCorreo(contratosPorVencer: contratosPorVencerTotal[]) {
    const correoData = {
      contratos: contratosPorVencer,
      destinatario: 'd.vargas@grupofirma.cl',
      asunto: 'Contrato a Plazo Fijo a punto de vencer',
      cuerpo: `Los siguientes contratos a plazo fijo están a punto de vencer:`,
    };
  
    // // Envía la solicitud HTTP para enviar el correo
    // this.http.post(`${environment.API}/rrhh/contrato/enviarCorreo/`, correoData)
    //   .subscribe({
    //     next: (response) => {
    //       console.log('Correo enviado con éxito:', response);
    //       this.http.put(`${environment.API}/rrhh/contrato/actualizarAviso/`, { contratos: contratosPorVencer })
    //         .subscribe({
    //           next: (updateResponse) => {
    //             console.log('Estado de aviso actualizado con éxito:', updateResponse);
    //           },
    //           error: (updateError) => {
    //             console.error('Error al actualizar el estado de aviso:', updateError);
    //           }
    //         });
    //     },
    //     error: (error) => {
    //       console.log("Error al enviar el correo:", contratosPorVencer);
    //       console.error('Error al enviar el correo:', error);
    //     }
    //   });
  }

  verificarContratos() {
    const now = new Date();
    const fechaHoy = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return new Promise((resolve, reject) => {
      this.trabajadorService.TraerTrabajadores().pipe(
        switchMap(({data}) => {
          const trabajadores: any[] = data.filter(x => x.estado === 1 && x.estado_contrato === 'Contrato a Plazo Fijo' || x.estado_contrato === 'Contrato Indefinido');
          if (trabajadores.length === 0) { return of([]); }

          const obs = trabajadores.map(x => {
            return this.TraerPorRut(x.rut).pipe(
              map(({data}) => {
                if (data.length > 0) {
                  const contratos: any[] = data.map(this.transformarFechaContrato);
                  const lastContrato = [...contratos].sort(this.ordenarContratos)[0];
                  const fechaTermino = lastContrato.fechaTermino;
                  const diffDays = fechaTermino ? fechaTermino.diff(fechaHoy, 'days') : Number.MAX_SAFE_INTEGER; 
                  if (diffDays <= 5) {
                    return {
                      id: lastContrato.id,
                      rut: lastContrato.fk_trabajador,
                      nombreTrabajador: x.nombres + ' ' + x.apellidos,
                      empresa: x.Empresa_contratante.nombre,
                      fechaTermino: fechaTermino.toDate().toLocaleDateString('es-CL'),
                      diffDays: diffDays,
                      aviso: lastContrato.aviso,
                      tipo_contrato: lastContrato.tipo_contrato,
                    };
                  }
                }
                return null;
              }),
              catchError(error => this.handlerError(error))
            );
          });
          return forkJoin(obs);
        }),
        map(res => res.filter(x => x !== null)),
        catchError(error => this.handlerError(error)),
      ).subscribe((contratosPorVencer: contratosPorVencerTotal[]) => {
        this.contratosPorVencer.next(contratosPorVencer);

        const contratosAcumulados: contratosPorVencerTotal[] = [];
        contratosPorVencer.forEach(contrato => {
          if (contrato.diffDays === 5) {
            contratosAcumulados.push(contrato);
          }
        });

        console.log("Contratos acumulados:", contratosAcumulados);
        const enviarCorreo = contratosAcumulados.some(contrato => contrato.aviso === 0 || contrato.aviso === null);

        if (enviarCorreo) {
            //this.enviarCorreo(contratosAcumulados);
            this.correEnviado = true;

            console.log(enviarCorreo);
        }
        resolve(contratosPorVencer);
      });
    });
  }


  verificarAnexosContratos() {
    const now = new Date();
    const fechaHoy = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  
    return new Promise((resolve, reject) => {
      this.trabajadorService.TraerTrabajadores().pipe(
        switchMap(({data}) => {
          const trabajadores: any[] = data.filter(x => x.estado === 1 && x.estado_contrato === 'Contrato a Plazo Fijo' || x.estado_contrato === 'Contrato Indefinido');
          if (trabajadores.length === 0) { return of([]); }

          const obs = trabajadores.map(x => {
            return this.TraerPorRut(x.rut).pipe(
              map(({data}) => {
                if (data.length > 0) {
                  const contratos: any[] = data.map(this.transformarFechaContrato);
                  const lastContrato = [...contratos].sort(this.ordenarContratos)[0];
                  const fechaTermino = lastContrato.fechaTermino;
                  const diffDays = fechaTermino ? fechaTermino.diff(fechaHoy, 'days') : Number.MAX_SAFE_INTEGER; 
                  if (diffDays <= 5 && lastContrato.tipo_contrato === 2) {
                    return {
                      id: lastContrato.id,
                      rut: lastContrato.fk_trabajador,
                      nombreTrabajador: x.nombres + ' ' + x.apellidos,
                      empresa: x.Empresa_contratante.nombre,
                      fechaTermino: fechaTermino.toDate().toLocaleDateString('es-CL'),
                      diffDays: diffDays,
                      aviso: lastContrato.aviso,
                      tipo_contrato: lastContrato.tipo_contrato,
                    };
                  }
                }
                return null;
              }),
              catchError(error => this.handlerError(error))
            );
          });
          return forkJoin(obs);
        }),
        map(res => res.filter(x => x !== null)),
        catchError(error => this.handlerError(error)),
      ).subscribe((anexosPorVencer: contratosPorVencerTotal[]) => {
        this.anexosPorVencer.next(anexosPorVencer);

        const contratosAcumulados: contratosPorVencerTotal[] = [];
        anexosPorVencer.forEach(contrato => {
          if (contrato.diffDays === 5) {
            contratosAcumulados.push(contrato);
          }
        });

        console.log("Contratos acumulados:", contratosAcumulados);
        const enviarCorreo = contratosAcumulados.some(contrato => contrato.aviso === 0 || contrato.aviso === null);

        if (enviarCorreo) {
            //this.enviarCorreo(contratosAcumulados);
            this.correEnviado = true;

            console.log(enviarCorreo);
        }
        resolve(anexosPorVencer);
      });
    });
  }

  private transformarFechaContrato(contrato): any[] {
    // console.log({...contrato});
    if (contrato.fechaFirma) {
      contrato.fechaFirma = moment(contrato.fechaFirma);
    }
    if (contrato.fechaInicio) {
      contrato.fechaInicio = moment(contrato.fechaInicio);
    }
    if (contrato.fechaTermino) {
      contrato.fechaTermino = moment(contrato.fechaTermino);
    }
    return contrato;
  }

  private ordenarContratos(a, b) {
    if (b.fechaTermino && a.fechaTermino) {
      return b.fechaTermino.toDate() - a.fechaTermino.toDate();
    }
    return -1;
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error?.msg??''}`;
      console.error(err);
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}