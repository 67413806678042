<div class="animate__animated animate__fadeInRight">
    <div class="row container-fluid">
        <div class="col-12">
            <mat-card class="mat_card_trabajadores">
                <h1 class>Control haberes y descuentos</h1>
            </mat-card>
        </div>
        <div class="col-12">
            <mat-card class="mat-elevation-z5 mat_card_trabajadores_content">
                <div class="overflow-hidden">
                    <app-haberes-trabajador></app-haberes-trabajador>
                </div>
            </mat-card>
        </div>
    </div>
</div>
