import { Component, Input, OnInit, ViewChild ,SimpleChanges} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { MatSelectChange } from '@angular/material/select';
import { DatePipe, formatDate } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';  // Necesario para utilizar paginator y filter
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { MatSort } from '@angular/material/sort';
import { LicenciasService } from 'src/app/pages/services/licencias/licencias.service';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';

@Component({
  selector: 'app-listar-licencias',
  templateUrl: './listar-licencias.component.html',
  styleUrls: ['./listar-licencias.component.css'],
  providers: [DatePipe]
})
export class ListarLicenciasComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private liquidacionService: LiquidacionService,
    private alert: AlertHelper,
    private datePipe: DatePipe,
    private licenciasService: LicenciasService,
    private asistenciaService: GestorAsistenciaService,
    private auth: AuthService,
    private documentoService: DocumentosService,
  ) {
    this.uploader.onCompleteAll = () => {

      //console.log('se termino de enviar ');
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user= this.auth.ObtenerinfoToken();
      form.append('estado', this.tramite.estado);
      form.append('id', this.tramite.id);
      form.append('userAt', user.nombre);

    };
  }


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }



    public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/licencias/comprobante`,
    itemAlias: 'licencia',

  });

  displayedColumns = [
    'Fecha_inicio',
    'Fecha_termino',
    'Motivo',
    'Estado',
    'UpdateAT',
    'Acciones'
  ];

  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  opciones=['TRAMITADA', 'DENUNCIADA'];

  tramite={
    estado: '',
    id:0,
  }
  RutTrabajador: any;
  dataSource= new MatTableDataSource();
  liquidaciones: any;
  info_documento: any;

  calendario : any ={


    motivo:"",
    id: 0,
    start: "",
    end: "",
    razon: 1,
    trabajador: 0,
    dsueldo: "",
    dbono: ""


  }



  @Input() rut:any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;   // variable para asignar paginator a matdatatable
  @ViewChild(MatSort) sort!: MatSort;

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}

  ngOnInit(): void {
   // console.log("Buscando licencias de RUT: "+this.rut);
    this.licenciasService.TraerPorRut(this.rut).subscribe(
      (res) => {
       // console.log(res.data[0].lenght);  // undefined
        this.liquidaciones = res.data;
        this.dataSource =  new MatTableDataSource(this.liquidaciones);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //console.table(this.liquidaciones);
      }
    );

    this.traerLicencias();
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //console.log(result);
      //response viene directo del backend
      if (result.success == true) {
        //console.log("el archivo se guardo con el nombre ", result.nombre);
        //console.log("El Comprobante Se subio Correctamente")
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        });
        //console.log(this.tramite.estado);
        if (this.tramite.estado=== "TRAMITADA"){
          //console.log("Iniciando proceso de inasistencia");
        this.calendario.trabajador=this.rut;
        this.calendario.motivo="LICENCIA";
        this.calendario.razon=1;
        this.calendario.dsueldo="SI";
        this.calendario.dbono="SI";
        //console.log(this.calendario);

        this.asistenciaService.crear(this.calendario).subscribe((res: any) => {
          if (res.success == true) {
            //console.log("INASISTENCIA CREADA CORRECTAMENTE");

          } else {
            this.alert.error_small(res.msg!)
          }
        })

        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',

        })

      }
    }


  }

  traerLicencias(){
    // console.log("Buscando licencias de RUT: "+this.rut);
    this.licenciasService.TraerPorRut(this.rut).subscribe(
      (res) => {
      // console.log(res.data[0].lenght);  // undefined
        this.liquidaciones = res.data;
        this.dataSource =  new MatTableDataSource(this.liquidaciones);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //console.table(this.liquidaciones);
      }
    );
  }

  Descargar(id :any){
    //console.log("Buscando archivo "+ id);
    webPreferences: {
      webSecurity: false
    }
    this.licenciasService.TraerArchivo(id).subscribe(
      (res)=>{
        //console.log(res.data);
        this.info_documento =res.data[0];
        window.open(`${environment.API}/licencias/`+this.info_documento.ruta);
      }

    );
  }

  Borrar(id:number, estado:boolean){
    if (!confirm('¿Seguro que desea eliminar la licencia?')) return;
    if (estado) {
      this.documentoService.eliminarDocumento(id, 'LICENCIA').subscribe((res:RequestResponse) => {
        this.licenciasService.eliminarContrato(id).subscribe((res:RequestResponse) => {
          this.alert.success_small('Se elimino la licencia');
          this.traerLicencias();
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar la licencia.');
          console.error(error);
        });
      }, (error) => {
        this.alert.error_small('Hubo un error al eliminar el documento.');
        console.error(error);
      });
    }else{
      this.licenciasService.eliminarContrato(id).subscribe((res:RequestResponse) => {
        this.alert.success_small('Se elimino la licencia');
        this.traerLicencias();
      }, (error) => {
        this.alert.error_small('Hubo un error al eliminar la licencia.');
        console.error(error);
      });
    }
  }



  DescargarComprobante(id :any){
    //console.log("Buscando archivo "+ id);
    webPreferences: {
      webSecurity: false
    }
    this.licenciasService.TraerLicencia(id).subscribe(
      (res)=>{
        //console.log(res.data);
        this.info_documento =res.data;
         window.open(`${environment.API}/licencias/`+this.info_documento.nombreDoc);
      }

    );
  }

  DescargarDenuncia(id :any){
    //console.log("Buscando archivo "+ id);
    webPreferences: {
      webSecurity: false
    }
    this.licenciasService.TraerLicencia(id).subscribe(
      (res)=>{
        //console.log(res.data);
        this.info_documento =res.data;
         window.open(`${environment.API}/licencias/`+this.info_documento.nombreDocDenuncia);
      }

    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.liquidaciones!=undefined){
      if (!changes.liquidaciones.firstChange) {
        this.dataSource = new MatTableDataSource(this.liquidaciones);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  open(content: any ,id:any) {
    // Modal para ingresar Tramitacion de licencia
    this.modalService.open(content, { size: 'lg' });
    //console.log("Subir comprobante a licencia id: "+id);
    this.limpiar();
    this.tramite.id=id;
    this.tramite.estado="TRAMITADA";
    //console.log(id,this.tramite.estado);
    this.licenciasService.TraerLicencia(id).subscribe(
      (res)=>{
        this.calendario.start=res.data.fechaInicio;
        this.calendario.end = res.data.fechaTermino
        //console.log(this.calendario);

      }

    );
  }


  open2(content: any ,id:any) {
    //Modal para ingresar Denuncia de licencia
    this.modalService.open(content, { size: 'lg' });
    //console.log("Subir comprobante a licencia id: "+id);
    this.limpiar();
    this.tramite.id=id;
    this.tramite.estado="DENUNCIADA";

    this.licenciasService.TraerLicencia(id).subscribe(
      (res)=>{
        this.calendario.start=res.data.fechaInicio;
        this.calendario.end = res.data.fechaTermino
        //console.log(this.calendario);

      }

    );
  }


  validar(): boolean {

    if (
      this.tramite.estado.length > 0
    ) {
    return true;
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Faltan datos , favor revisar',

      })
      return false;
    }
  }


  guardar() {


    if (this.validar()) {

      this.uploader.uploadAll();
      //console.log(this.tramite);
      this.cerrarModal();


    }
    else {
      //console.log("Error al guardar");

    }
  }

  cerrarModal(){
    this.modalService.dismissAll();
  }

  limpiar(){
    this.tramite.estado='';
  }



}
