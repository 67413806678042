import { Component, OnInit } from '@angular/core';
import { AdelantosService } from 'src/app/pages/services/adelantos/adelantos.service';
import { Adelantos } from 'src/app/shared/models/adelantos.interface'

@Component({
  selector: 'app-main-adelantos',
  templateUrl: './main-adelantos.component.html',
  styleUrls: ['./main-adelantos.component.css']
})
export class MainAdelantosComponent implements OnInit {
  textoBuscar: string = '';
  adelantos: Adelantos[] = []

  constructor(
    private adelantos_services: AdelantosService
  ) { }

  ngOnInit(): void {
    this.TraerAdelantos();
  }
  TraerAdelantos() {
    this.adelantos_services.GetAll_adelantos().subscribe((res) => {
      this.adelantos = res.data.sort((a: Adelantos, b: Adelantos) => {
        const timeA = new Date(a.fechaInicioContrato).getTime();
        const timeB = new Date(b.fechaInicioContrato).getTime();
        return timeB - timeA;
      })
      // console.log({res}, {...this.adelantos});
    })
  }

  actualizarTabla(event: boolean) {
    this.TraerAdelantos();
  }

  obtenerTextoBuscar(event: any) {
    this.textoBuscar = event.target.value;
  }
}
