import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepicker } from '@angular/material/datepicker';
import { RegionesService } from 'src/app/pages/services/regiones.service';

import { default as _rollupMoment, Moment } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';

import { debounceTime, Subject } from 'rxjs';
import { contabilidadService } from 'src/app/pages/services/contabilidad/contabilidad.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';

import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import * as XLSX from 'xlsx';
import _moment from 'moment';

//necesario para que pesque  los estilos
const pdfMake = require('pdfmake/build/pdfmake.js');
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { saveAs } from 'file-saver';
import { IndicadoresImpuestoUnico } from 'src/app/shared/models/impuesto-unico.interface';
import { differenceInDays, endOfMonth, format, getMonth, getYear, parseISO } from 'date-fns';
import { MovimientosPersonalService } from 'src/app/pages/services/movimientos-personal.service';
import { MovimientosPersonal } from '../../../../shared/models/movimientospersonal.interface';
import { Console } from 'console';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-lista-ventas',
  templateUrl: './lista-ventas.component.html',
  styleUrls: ['./lista-ventas.component.css'],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class ListaVentasComponent implements OnInit {

  // date = new FormControl(new Date());
  datePeriodo = new FormControl(moment());
  // serializedDate = new FormControl(new Date().toISOString());
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort;

  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();

  maxDatePeriodo: Date = new Date(this.currentYear, this.currentMonth + 1, 0);
  dateNow = new Date();
  periodo: any = {
    min: new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(), 1),
    max: new Date(this.dateNow.getFullYear(), this.dateNow.getMonth() + 1, 0),
  };
  indicadoresImpuestoUnico: IndicadoresImpuestoUnico = {
    periodo: '',
    TR1Desde: 0,
    TR1Hasta: 0,
    TR1Factor: 0,
    TR1CReb: 0,
    TR2Desde: 0,
    TR2Hasta: 0,
    TR2Factor: 0,
    TR2CReb: 0,
    TR3Desde: 0,
    TR3Hasta: 0,
    TR3Factor: 0,
    TR3CReb: 0,
    TR4Desde: 0,
    TR4Hasta: 0,
    TR4Factor: 0,
    TR4CReb: 0,
    TR5Desde: 0,
    TR5Hasta: 0,
    TR5Factor: 0,
    TR5CReb: 0,
    TR6Desde: 0,
    TR6Hasta: 0,
    TR6Factor: 0,
    TR6CReb: 0,
    TR7Desde: 0,
    TR7Hasta: 0,
    TR7Factor: 0,
    TR7CReb: 0,
    TR8Desde: 0,
    TR8Hasta: 0,
    TR8Factor: 0,
    TR8CReb: 0,
  }
  debouncer: Subject<boolean> = new Subject();

  constructor(
    private alert: AlertHelper,
    private movimimientoService: MovimientosPersonalService,
    private trabajadorService: TrabajadoresService,
    private movimienotPersonalService: MovimientosPersonalService,
    private regionService: RegionesService,
    private venta: contabilidadService,
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 18, 11, 31);
  }

  //Variable
  fecha = new Date();
  fecha_Actual = new Date();
  fechaFormatT: any;
  FechaTermino: any;
  fechaMostrar: any;
  trabajadores: any;
  nohaydatos = 0;
  mostrarFechaIngreso = false;
  documentos: any;
  listatra: any;
  listasuc: any;
  listaFiltrada: any[];
  listaFiltradaFinal: any[];
  dataSource: any;
  fileName: any;
  renderPDF = 0;
  renderCSV = 0;
  renderExcel = 0;
  mes: any = 0;
  minDate: Date;
  maxDate: Date;
  sucur: any[] = [];

  ano: any = 0;
  actual: number = new Date().getFullYear();

  remunreacionmostrar: any;

  trabajadorid: number = 0;
  anio: any[] = [];
  dato: any = 0;
  mesid: number = 0;
  anoid: number = 0;
  empresa: any;

  // ordenarpor: any;
  FormatoDescararga: any;
  empresas: any;
  sucursales: any[] = [];
  selectedSucursal: any;
  traba: any;
  tipo: any;

  // nombreEmpresa: any;
  // empresa_contratante_seleccionada = 0;
  // sumaAdelanto: any;
  // numero: any;
  selectedValue: any;
  tasaLeySanna:number = 0.0;
  tasaCotizBasica:number = 0.0;

  //obtener datos de empresa
  nempresa: any;
  rempresa: any;
  uempresas: any;
  cempresa: any;
  rutempresa: any;

  sucursalSelect: any;
  nsucursal: any; 

  displayedColumns: string[] = [
    'acciones',
    'rut',
    'nombre',
    'sucursal',
    'folio',
    'd_trabajados',
    'sbase',
    'horasextras',
    'glegal',
    'otroimp',
    'totalimp',
    'afamiliar',
    'otronoimp',
    'totalnoimp',
    'totalhaberes',
    'prevision',
    'salud',
    'impuestounico',
    'scesantia',
    'otrodesclegales',
    'totaldesclegales',
    'descvarios',
    'totaldescuento',
    'liquido',

    //DATOS PARA CSV
    'fecha_ingreso',
    'cod_region_prest_servicios',
    'cod_comuna_prest_servicios',
    'cod_tipo_jornada',
    'cod_discapacidad',
    'jubilado',
    'isapre_fonasa',
    'cod_afp',
    'apvi',
    'afc',
    'seguroInvalidez',
    'otrosdescuentosCSV',
    'totalaportesempleador',
    'numeroCargas',
    'tramoAsigFamiliar',
    'numDiasLicencias',
    'numDiasVacaciones',
    'comision',
    'bsemanacorrido',
    'colacion',
    'movilizacion',
    'viaticos',
    'retClaseMedia',
  ];
  remuneracion = {
    rut: 0,
    nombre: '',
    f_start: '',
    d_trabajados: 0,
    sucursal: '',
    sucur: '',
    adelanto: 0,
    horasextras: 0,
    glegal: 0,
    // otroimp: 0,
    totalimp: 0,
    otronoimp: 0,
    totalnoimp: 0,
    totalhaberes: 0,
    prevision: 0,
    salud: 0,
    scesantia: 0,
    // otrodesclegales: 0,
    totaldesclegales: 0,
    totaldescuento: 0,
    liquido: 0,
    sbase: 0,
    descvarios: 0,
    impuestounico: 0,
    asig_familiar: 0,

    num_cargas: 0,
    tramo_asig_fam: '',
    num_dias_licencias: 0,
    num_dias_vacaciones: 0,
    comision: 0,
    bsemanacorrida: 0,
    colacion: 0,
    movilizacion: 0,
    viaticos: 0,
    ret_clase_media: 0,
  }

  meses = [
    { id: 1, valor: 'enero' },
    { id: 2, valor: 'febrero' },
    { id: 3, valor: 'marzo' },
    { id: 4, valor: 'abril' },
    { id: 5, valor: 'mayo' },
    { id: 6, valor: 'junio' },
    { id: 7, valor: 'julio' },
    { id: 8, valor: 'agosto' },
    { id: 9, valor: 'septiembre' },
    { id: 10, valor: 'octubre' },
    { id: 11, valor: 'noviembre' },
    { id: 12, valor: 'diciembre' },
  ];

  forma = [
    // { id: 0, valor: 'PDF ' },
    { id: 0, valor: 'PDF ' },
    { id: 1, valor: 'Archivo LRE' },
    { id: 2, valor: 'EXCEL' }
  ];

  async eliminar(id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminará el registro del libro de remuneraciones",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.venta.eliminar(id).subscribe(res => {
          if (res.success == true) {
            this.ObtenerEmpresaContratante(this.selectedValue);
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
            // this.ngOnInit();
          } else {
            this.alert.error_small(res.msg!)
          }
        });
      }
    })
  }

  async ObtenerEmpresaContratante(event: MatSelectChange): Promise<void> {
    this.empresa = event.value;
    // const buscarId = this.empresa;
    // this.nempresa = this.empresas.find(e => e.id === buscarId);
    // this.tasaLeySanna = this.nempresa.cotizLeySanna;
    // this.tasaCotizBasica = this.nempresa.cotizBasica;
    // const res = await this.venta.TraerTodoAsync();
    // if (res.success) {
    //   this.dataSource = [];
    //   this.listatra = res.data.filter((x: any) => x.sucursal === this.nempresa.nombre);
    //   this.filtrarTabla();
    // }else{
    //   this.alert.error_small(res.msg);
    this.nempresa = this.empresas.find(e => e.id === this.empresa);
    if (this.nempresa) {
        this.tasaLeySanna = this.nempresa.cotizLeySanna;
        this.tasaCotizBasica = this.nempresa.cotizBasica;
        const res = await this.venta.TraerTodoAsync();
        if (res.success) {
            this.dataSource = [];
            this.listatra = res.data;
            this.filtrarTabla(); 
        } else {
            this.alert.error_small(res.msg);
        
    }
  }
}

  async ObtenerSucursal(event: MatSelectChange): Promise<void> {
      this.sucursalSelect = event.value;
      const res = await this.venta.TraerTodoAsync();
      if (res.success) {
          this.dataSource = [];
          this.listatra = res.data;
          this.filtrarTabla(); 
      } else {
          this.alert.error_small(res.msg);
      }
  }
  

  FormatoDescar(event: MatSelectChange) {
    this.FormatoDescararga = event.value;

    if (this.FormatoDescararga == 0) {
      this.renderPDF = 1;
      this.renderCSV = 0;
      this.renderExcel = 0;
    }
    else if (this.FormatoDescararga == 1) {
      this.renderCSV = 1;
      this.renderPDF = 0;
      this.renderExcel = 0;
    } else if (this.FormatoDescararga == 2) {
      this.renderExcel = 1;
      this.renderCSV = 0;
      this.renderPDF = 0;
      this.fileName = 'Libro de remuneracion.xlsx';
    }
  }
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  obtenerDatosTabla() {
    this.sucur = [];
    const datas = [];
    // transformar datos a json para sucursales y asi dividir el excel entre sucursales
    const filas = this.dataSource.data;
    const sucurSet = new Set(filas.map(registro => registro.sucur));
    this.sucur = Array.from(sucurSet);
    const observables = filas.map(row => {
      return this.trabajadorService.TraerTrabajadoresRut(row.rut);
    });
    // Recorrer todas las filas de la tabla y agregar sus datos al objeto JSON
    filas.forEach(row => {
      const dato = {
        rut: row.rut,
        nombre: row.nombre,
        sucursal: row.sucur,
        dt: row.d_trabajados,
        s_base: row.sbase,
        horas_extras: row.horasextras,
        g_legal: row.glegal,
        o_imponible: row.otroimp,
        t_imponible: row.totalimp,
        familiar: row.asig_familiar,
        t_no_imponible: row.totalnoimp,
        o_no_imponible: row.otronoimp,
        t_haberes: row.totalhaberes,
        prevision: row.prevision,
        salud: row.salud,
        imp_unico: row.impuestounico,
        s_cesantia: row.scesantia,
        o_descuentos_legales: row.otrodesclegales,
        t_descuentos_legales: row.totaldesclegales,
        d_varios: row.descvarios,
        totaldescuento: row.totaldescuento,
        liquido: row.liquido,
      };
      datas.push(dato);
    });
    this.exportexcel(datas);

    return datas;
    
  }

  exportexcel(datas): void {

    //Ancho de las columnas
    const wscols = [
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 20 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 22 },
      { width: 22 },
      { width: 22 },
      { width: 22 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
    ];
    // const datos = this.obtenerDatosTabla();
    // Agregar el array de sucursales al objeto JSON

    // Convertir el objeto JSON en una cadena con formato

    const datosJSON = JSON.stringify(datas, null, 0);
    const datosObjeto = JSON.parse(datosJSON);
    // console.log({datosJSON}, {datosObjeto}, this.sucur);
    console.log("DATOS JSON: ",{datosJSON}, {datosObjeto}, this.sucur);
    let coincidencias = [];
    for (let i = 0; i < this.sucur.length; i++) {
      coincidencias[i] = []; // Crea un array vacío dentro de coincidencias para cada sucursal en sucur
      for (let j = 0; j < datosObjeto.length; j++) {
        if (this.sucur[i] == datosObjeto[j]['sucursal']) {
          coincidencias[i].push(datosObjeto[j]); // Agrega el objeto coincidente al array correspondiente dentro de coincidencias
        }
      }
    }
    const wb = XLSX.utils.book_new(); // Crea un nuevo libro de trabajo
    const ws = XLSX.utils.json_to_sheet([]); // Crea una hoja de trabajo vacía
    const header = [
      'Rut',
      'Nombre',
      'Sucursal',
      'Dias Trabajados',
      'Sueldo Base',
      'Horas Extras',
      'Gratificación Legal',
      'Otros Imponible',
      'Total Imponible',
      'Aporte Familiar',
      'Otros No Imponible',
      'Total No Imponible',
      'Total Haberes',
      'Previsión',
      'Salud',
      'Impuesto Unico',
      'Seguro de Cesantia',
      'Otros Descuentos Legales',
      'Total Descuentos Legales',
      'Descuentos Varios',
      'Total Descuentos',
      'Liquido'
    ];
    console.log(this.empresas, this.empresa);
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A4' });
    XLSX.utils.sheet_add_aoa(ws, [[this.empresas.find(x => x.id === this.empresa).nombre]], { origin: "A1" });
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
    ws['A1'].s = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };

    //  Combina las celdas desde A6 hasta V6
    XLSX.utils.sheet_add_aoa(ws, [["Libro de Remuneraciones"]], { origin: "A3" });
    // ws['!merges'] = [{ s: { r: 5, c: 0 }, e: { r: 5, c: 20 } }];
    //ws['!merges'] = [{ s: { r: 5, c: 0 }, e: { r: 5, c: 20 } }];
    ws['A3'].s = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };

    //TOTAL DE TOTALES
    let totales = [];

    // Recorre los datos en coincidencias y agrega cada conjunto de datos a la hoja de trabajo

    for (let i = 0; i < coincidencias.length; i++) {
      XLSX.utils.sheet_add_json(ws, coincidencias[i], { skipHeader: true, origin: -1 });
      // const lastRow = XLSX.utils.decode_range(ws['!ref']).e.r;
      const totalsRow = this.totalesTabla(coincidencias[i]);
      // console.log({totalsRow});

      XLSX.utils.sheet_add_aoa(ws, [['', this.sucur[i], '', ...totalsRow]], { origin: -1 });
      totales.push(totalsRow);
      console.log({totales});
      console.log("totales:",{totales});
    }

    //eliminar sucursal del excel
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let r = range.s.r; r <= range.e.r; r++) {
      for (let c = 2; c <= range.e.c; c++) {
        const nextCell = ws[XLSX.utils.encode_cell({ r: r, c: c + 1 })];
        if (nextCell !== undefined) {
          ws[XLSX.utils.encode_cell({ r: r, c: c })].v = nextCell.v;
          ws[XLSX.utils.encode_cell({ r: r, c: c })].t = nextCell.t;
          ws[XLSX.utils.encode_cell({ r: r, c: c })].w = nextCell.w;
        } else {
          delete ws[XLSX.utils.encode_cell({ r: r, c: c })];
        }
      }
    }
    ws['!ref'] = XLSX.utils.encode_range({ s: range.s, e: { r: range.e.r, c: range.e.c - 1 } });

    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    ws['!cols'] = wscols;

    let sumArray = totales[0].slice();

    console.log("SUMARRAY: ", sumArray)
    for (let i = 0; i < totales.length; i++) {
      for (let j = 0; j < totales[i].length; j++) {
        // console.log(totales[i][j]);
        console.log("TOTALES FOR: ",totales[i][j]);
        // sumArray[j] += totales[i][j];
      }
    }
    const lastCell = XLSX.utils.encode_cell({ r: ws['!merges'][0].e.r, c: sumArray.length });

// Obtener el rango de la fila 'Total General'
 const rango = XLSX.utils.decode_range(ws['!ref']);
const totalGeneralRow = rango.e.r;

// Verificar si la celda existe antes de aplicar formato
if (ws[lastCell]) {
  // Aplicar formato de texto en negrita a toda la fila 'Total General'
  for (let c = rango.s.c; c <= rango.e.c; c++) {
    const cell = XLSX.utils.encode_cell({ r: totalGeneralRow, c: c });
    if (ws[cell]) {
      ws[cell].s = { font: { bold: true } };
    }
  }
}
    console.log({sumArray});
    sumArray[19] = sumArray[19] === 0 ? '' : sumArray[19];
    XLSX.utils.sheet_add_aoa(ws, [['', 'Total General', sumArray]], { origin: -1 });
    // XLSX.utils.sheet_add_aoa(ws, [['', 'Total General', ...sumArray]], { origin: -1 });

    XLSX.utils.book_append_sheet(wb, ws, 'Libro Remuneraciones');

    // Descargar el archivo de Excel
    XLSX.writeFile(wb, this.fileName);
    
  }

  totalesTabla(arreglo) {
    const suma = arreglo.reduce((acc, curr) => ({
      dt: acc.dt + Number(curr.dt),
      s_base: acc.s_base + Number(curr.s_base),
      horas_extras: acc.horas_extras + Number(curr.horas_extras),
      g_legal: acc.g_legal + Number(curr.g_legal),
      o_imponible: acc.o_imponible + Number(curr.o_imponible),
      t_imponible: acc.t_imponible + Number(curr.t_imponible),
      familiar: acc.familiar + Number(curr.familiar),
      t_no_imponible: acc.t_no_imponible + Number(curr.t_no_imponible),
      o_no_imponible: acc.o_no_imponible + Number(curr.o_no_imponible),
      t_haberes: acc.t_haberes + Number(curr.t_haberes),
      prevision: acc.prevision + Number(curr.prevision),
      salud: acc.salud + Number(curr.salud),
      imp_unico: acc.imp_unico + Number(curr.imp_unico),
      s_cesantia: acc.s_cesantia + Number(curr.s_cesantia),
      o_descuentos_legales: acc.o_descuentos_legales + Number(curr.o_descuentos_legales),
      t_descuentos_legales: acc.t_descuentos_legales + Number(curr.t_descuentos_legales),
      d_varios: acc.d_varios + Number(curr.d_varios),
      totaldescuento: acc.totaldescuento + Number(curr.totaldescuento),
      liquido: acc.liquido + Number(curr.liquido),
    }), {
      dt: 0,
      s_base: 0,
      horas_extras: 0,
      g_legal: 0,
      o_imponible: 0,
      t_imponible: 0,
      familiar: 0,
      t_no_imponible: 0,
      o_no_imponible: 0,
      t_haberes: 0,
      prevision: 0,
      salud: 0,
      imp_unico: 0,
      s_cesantia: 0,
      o_descuentos_legales: 0,
      t_descuentos_legales: 0,
      d_varios: 0,
      totaldescuento: 0,
      liquido: 0,
    });
    const totalsRow = Object.values(suma);

    return totalsRow;
  }
  formatoFecha(fecha: string) {
    const fecha_inicio_contrato = parseISO(fecha);

    // Obtener los valores de día, mes y año
    const dia = fecha_inicio_contrato.getDate().toString().padStart(2, '0');
    const mes = (fecha_inicio_contrato.getMonth()).toString().padStart(2, '0');
    const anio = fecha_inicio_contrato.getFullYear();

    // Formatear la fecha como una cadena de texto con el formato dd/mm/yyyy
    const fecha_inicio_contrato_formateada = `${dia}/${mes}/${anio}`;
    return fecha_inicio_contrato_formateada;
  }
  obtenerCodMutual(nombreMutual: string): number {
    console.log({ nombreMutual });
    switch (nombreMutual) {
      case 'Asociación Chilena de Seguridad':
        return 1;
      case 'Mutual de Seguridad CCHC':
        return 2;
      case 'Instituto de Seguridad del Trabajo':
        return 3;
      default:
        return 0;
    }
  }
  async exportCsv(): Promise<void> {
    const element = document.getElementById('tabla');
    const table: HTMLTableElement = element as HTMLTableElement;
    const rows = table.rows;
    // transformar datos a json para sucursales y asi dividir el excel entre sucursales
    const filas = Array.from(rows).slice(1);
    // Crear un objeto JSON para almacenar los datos de la tabla
    const datos = [];
    await this.obtenerIndicadoresImpuestoUnico(this.datePeriodo.value._d);
    // Recorrer todas las filas de la tabla y agregar sus datos al objeto JSON
    await Promise.all(
      filas.map(async row => {
        // Se verifica si el ultimo digito es K para cambiarlo a 0
        let rut = row.cells[1].textContent.replace( /[.-]/g, '').trim();
        rut = rut.endsWith('K') ? rut.slice(0, -1) + '0' : rut;
        const periodo:Date = this.datePeriodo.value._d;
        const registroLibro = this.listaFiltrada.find(x => x.rut === parseInt(rut));
        const trabajador = await this.obtenerTrabajador(parseInt(rut));
        const regionPrestServicios = await this.obtenerCodigoRegion(trabajador.Empresa_contratante.CiudadEmpresa.idregion);
        const comunaPrestServicios = trabajador.Empresa_contratante.CiudadEmpresa.codigo_lre;
        const tramoAsigFam = this.obtenerTramoAsigFam(trabajador.tramoAsigFam);
        const fechaContrato = parseISO(trabajador.fecha_ingreso);

        const fechaInicio = parseISO(trabajador.fecha_ingreso);
        const diasLicencias = await this.obtenerDiasLicencias(parseInt(rut));

        const totalImponible = parseInt(row.cells[10].textContent);
        const totalTasaAporteAccidSanna = parseFloat(((this.tasaCotizBasica + this.tasaLeySanna) / 100).toFixed(5));
        console.log('TASA APORTE SANNA: ', {totalTasaAporteAccidSanna});
        // const numDecimales = 10000;
        const totalAporteAccidSanna = Math.round(totalImponible * totalTasaAporteAccidSanna);
        const totalAportesEmpleador = registroLibro.totalaportesempleador + totalAporteAccidSanna + parseInt(row.cells[34].textContent.trim());
        const dato = {
          rut: row.cells[1].textContent.replace(/\./g, '').trim(), // OBLIGATORIO
          fecha_inicio_contrato: format(fechaContrato, 'dd-MM-yyyy'), // OBLIGATORIO
          fecha_termino: '',
          causal_termino: '',
          region_prest_servicios: regionPrestServicios, // OBLIGATORIO
          comuna_prest_servicios: comunaPrestServicios, // OBLIGATORIO
          t_imp_renta: 1, // OBLIGATORIO
          tec_ext_cot_prev: 0, // OBLIGATORIO
          t_jornada: trabajador.tipo_jornada??101, // OBLIGATORIO
          disc_invalidez: trabajador.discapacidad??0, // OBLIGATORIO
          pensionado_vejez: trabajador.jubilado??false ? 1 : 0, // OBLIGATORIO
          afp: trabajador.Afp.cod_afp, // OBLIGATORIO
          ips: 0, // OBLIGATORIO
          fonasa_isapre: trabajador.Prevision.cod_prevision, // OBLIGATORIO
          afc: trabajador.afc, // OBLIGATORIO
          ccaf: 0, // CAJA DE COMPENSACION: 0 YA QUE LA EMPRESA NO ESTA AFILIADA A NINGUNA // OBLIGATORIO
          org_adm_ley: this.obtenerCodMutual(this.nempresa.nombreMutual), // OBLIGATORIO
          num_cargas: trabajador.numero_cargas !== 0 ? trabajador.numero_cargas : '',// Número cargas familiares legales autorizadas(1111)
          num_cargas_maternales: '',// Número de cargas familiares maternales(1112)
          num_cargas_invalidez: '',// Número de cargas familiares invalidez(1113)
          tramo_asigfamiliar: tramoAsigFam,// Tramo asignación familiar(1114)
          rut_orgsindical1: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical2: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical3: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical4: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical5: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical6: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical7: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical8: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical9: '',// Rut organización sindical 1-10(1171-80)
          rut_orgsindical10: '',// Rut organización sindical 1-10(1171-80)
          d_trabajados: row.cells[5].textContent.trim(), // OBLIGATORIO
          num_licencias: diasLicencias > 30 ? 30 : diasLicencias === 0 ? '' : diasLicencias,// Número días de licencia médica en el mes(1116)
          num_vacaciones: row.cells[40].textContent.trim() !== '0' ? row.cells[39].textContent.trim() : '',// Número días de vacaciones en el mes(1117)
          sub_trab_joven: 0, // OBLIGATORIO
          puesto_pesado: '',// Puesto trabajo pesado(1154)
          apvi: trabajador.ahorro_apv??0, // OBLIGATORIO
          apvc: 0, // OBLIGATORIO
          ind_tdo_evento: 0, // OBLIGATORIO
          tasa_indemn_evento: '',// Tasa indemnización a todo evento (Art. 164)(1132)
          s_base: registroLibro.sueldo, // OBLIGATORIO
          sobresueldo: registroLibro.sobresueldo !== 0 ? registroLibro.sobresueldo : '',// Sobresueldo(2102)
          comision: registroLibro.comision !== 0 ? registroLibro.comision : '',// Comisiones(2103)
          bscorrida: registroLibro.bsemanacorrida !== 0 ? registroLibro.bsemanacorrida : '',// Semana corrida mensual(2104)
          participacion: registroLibro.participacion !== 0 ? registroLibro.participacion : '',// Participacion(2105)
          g_legal: row.cells[8].textContent.trim() !== '0' ? row.cells[8].textContent.trim() : '',
          recarg_domingo: registroLibro.recargo_domingo !== 0 ? registroLibro.recargo_domingo : '',// Recargo 30% dia domingo(2107)
          rem_vacaciones: registroLibro.rem_pagada_vacaciones !== 0 ? registroLibro.rem_pagada_vacaciones : '',// Remuneración variable pagada en vacaciones(2108)
          rem_clausura: registroLibro.rem_pagada_clausura !== 0 ? registroLibro.rem_pagada_clausura : '',// Remuneración variable pagada en clausura(2109)
          aguinaldo: registroLibro.aguinaldo !== 0 ? registroLibro.aguinaldo : '',// Aguinaldo(2109)
          bono_otros_fijos: registroLibro.bonos_rem_fijas !== 0 ? registroLibro.bonos_rem_fijas : '',// Bonos u otras remuneraciones fijas mensuales(2111)
          tratos: '',// Tratos(2112)
          bonos_otros_variables: '',// Bonos u otras remuneraciones variables mensuales o superiores a un mes(2113)
          ejer_no_pactada: registroLibro.no_pactado_contrato !== 0 ? registroLibro.no_pactado_contrato : '',// Ejercicio opción no pactada en contrato(2114)
          bconst_rem: registroLibro.benef_remun !== 0 ? registroLibro.benef_remun : '',// Beneficios en especie constitutivos de remuneración(2115)
          rem_bi: '',// Remuneraciones bimestrales(2116)
          rem_tri: '',// Remuneraciones trimestrales(2117)
          rem_cuad: '',// Remuneraciones cuatrimestral(2118)
          rem_sem: '',// Remuneraciones semestrales(2119)
          rem_anu: '',// Remuneraciones anuales(2120)
          part_anual: '',// Participación anual(2121)
          gratif_anual: '',// Gratificación anual(2122)
          otrasrem_superior: '',// Otras remuneraciones superiores a un mes(2123)
          pago_horas: registroLibro.pago_horas_sindical !== 0 ? registroLibro.pagos_horas_sindical : '',// Pago por horas de trabajo sindical(2124)
          sueldo_empresarial: '',// Sueldo empresarial(2161)
          sub_incap_laboral: registroLibro.subs_incap_laboral !== 0 ? registroLibro.subs_incap_laboral : '',// Subsidio por incapacidad laboral por licencia médica(2201)
          beca_estudio: registroLibro.beca_estudio !== 0 ? registroLibro.beca_estudio : '',// Beca de estudio(2202)
          gratif_zona: registroLibro.gratificacion_zona !== 0 ? registroLibro.gratificacion_zona : '',// Gratificaciones de zona(2203)
          otros_ingre_renta: registroLibro.ingresos_no_constitutivos !== 0 ? registroLibro.ingresos_no_constitutivos : '',// Otros ingresos no constitutivos de renta(2204)
          colacion_total: registroLibro.colacion !== 0 ? registroLibro.colacion : '',// Colación total mensual(2301)
          mov_total: registroLibro.movilizacion !== 0 ? registroLibro.movilizacion : '',// Movilización total mensual(2302)
          viaticos_total: registroLibro.viaticos !== 0 ? registroLibro.viaticos : '',// Viáticos total mensual(2303)
          asig_perdida_caja: registroLibro.perdida_caja !== 0 ? registroLibro.perdida_caja : '',// Asignación de pérdida de caja total mensual(2304)
          asig_desg_herramientas: registroLibro.desg_herramientas !== 0 ? registroLibro.desg_herramientas : '',// Asignación de desgaste herramienta total mensual(2305)
          asig_familiar: row.cells[11].textContent.trim() !== '0' ? row.cells[11].textContent.trim() : '',
          gasto_trabajo: registroLibro.gasto_trabajo !== 0 ? registroLibro.gasto_trabajo : '',// Gastos por causa del trabajo(2306)
          gasto_residencia: registroLibro.gasto_residencia !== 0 ? registroLibro.gasto_residencia : '',// Gastos por cambio de residencia(2307)
          sala_cuna: registroLibro.sala_cuna !== 0 ? registroLibro.sala_cuna : '',// Sala cuna(2308)
          asig_teletrabajo: registroLibro.asign_teletrabajo !== 0 ? registroLibro.asign_teletrabajo : '',// Asignación trabajo a distancia o teletrabajo(2309)
          deposito_convenio: '',// Depósito convenido hasta UF 900(2347)
          aloj_trabajo: registroLibro.aloj_trabajo !== 0 ? registroLibro.aloj_trabajo : '',// Alojamiento por razones de trabajo(2310)
          asig_traslacion: registroLibro.asig_traslacion !== 0 ? registroLibro.asig_traslacion : '',// Asignación de traslación(2312)
          idemn_feriado: '',// Indemnización por feriado legal(2313)
          idemn_servicio: '',// Indemnización años de servicio(2314)
          idemn_avisoprevio: '',// Indemnización sustitutiva del aviso previo(2315)
          idemn_fueromaternal: '',// Indemnización fuero maternal(2316)
          pago_idemevento: '',// Pago Indemnización a todo evento(2331)
          idemn_voluntarias: '',// Indemnizaciones voluntarias tributables(2417)
          idemn_contractuales: '',// Indemnizaciones contractuales tributables(2418)
          prevision: row.cells[15].textContent.trim(), // OBLIGATORIO
          salud: row.cells[16].textContent.trim(), // OBLIGATORIO
          cotiz_vol_salud: registroLibro.adicional_isapre > 0 && trabajador.prevision !== 1 ? registroLibro.adicional_isapre : '',// Cotización voluntaria para salud(3144)
          cotiz_afc: row.cells[18].textContent.trim() !== '0' ? row.cells[18].textContent.trim() : '',// Cotización AFC - trabajador(3151)
          cotiz_tec_ext_seg_social: '',// Cotizaciones técnico extranjero para seguridad social fuera de Chile(3146)
          desc_deposito: '',// Descuento depósito convenido hasta UF 900 anual(3147)
          cotiz_ahorroindv_previ_a: '',// Cotización ahorro previsional voluntario individual modalidad A(3155)
          cotiz_ahorroindv_previ_b: '',// Cotización ahorro previsional voluntario individual modalidad B hasta UF 50(3156)
          cotiz_ahorrocol_previ_a: '',// Cotización ahorro previsional voluntario colectivo modalidad A(3157)
          cotiz_ahorrocol_previ_b: '',// Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50(3158)
          imp_ret_remune: row.cells[17].textContent.trim(), // OBLIGATORIO
          imp_retenido_indem: '',// Impuesto retenido por indemnizaciones(3162)
          mayor_retencion: '',// Mayor retención de impuestos solicitada por el trabajador(3163)
          impuest_rem_devengadas_otros_periodos: '',// Impuesto retenido por reliquidación remuneraciones devengadas en otros períodos(3164)
          dif_imp_rem_devengadas_este_periodo: '',// Diferencia de impuesto por reliquidación remuneraciones devengadas en este período(3165)
          ret_prest_clase_media: row.cells[46].textContent.trim() !== '0' ? row.cells[46].textContent.trim() : '',// Retención préstamo clase media 2020(3166)
          reb_zona_extrema: '',// Rebaja zona extrema DL 889(3167)
          cuota_sind1: '',// Cuota sindical 1-10(3171-80)
          cuota_sind2: '',// Cuota sindical 1-10(3171-80)
          cuota_sind3: '',// Cuota sindical 1-10(3171-80)
          cuota_sind4: '',// Cuota sindical 1-10(3171-80)
          cuota_sind5: '',// Cuota sindical 1-10(3171-80)
          cuota_sind6: '',// Cuota sindical 1-10(3171-80)
          cuota_sind7: '',// Cuota sindical 1-10(3171-80)
          cuota_sind8: '',// Cuota sindical 1-10(3171-80)
          cuota_sind9: '',// Cuota sindical 1-10(3171-80)
          cuota_sind10: '',// Cuota sindical 1-10(3171-80)
          cred_social: '',// Crédito social CCAF(3110)
          cuota_vivienda_educacion: '',// Cuota vivienda o educación(3181)
          c_coo_ahorro: '',// Crédito cooperativas de ahorro(3182)
          otros_desc_trabajador: registroLibro.otros_desc_solic_trab !== 0 ? registroLibro.otros_desc_solic_trab : '',// Otros descuentos autorizados y solicitados por el trabajador(3183)
          cotiz_trabajo_pesado: '',// Cotización adicional trabajo pesado - trabajador(3154)
          donaciones_cult_recontr: '',// Donaciones culturales y de reconstrucción(3184)
          otros_descuentos: registroLibro.otros_desc !== 0 ? registroLibro.otros_desc : '',// Otros descuentos(3185)
          pensiones_aliment: '',// Pensiones de alimentos(3186)
          desc_mujer_casada: '',// Descuento mujer casada(3187)
          desc_antic_prest: registroLibro.adelanto > 0 ? registroLibro.adelanto : '',// Descuentos por anticipos y préstamos(3188)
          aporte_afc: registroLibro.totalaportesempleador !== 0 ? registroLibro.totalaportesempleador : '',// Aporte AFC - empleador(4151)
          apo_emp_sanna: totalAporteAccidSanna, // OBLIGATORIO
          aporte_empl_indem_evento: '',// Aporte empleador indemnización a todo evento(4131)
          aporte_trabajo_pesado: '',// Aporte adicional trabajo pesado - empleador(4154)
          apo_emp_seguro_inv: row.cells[34].textContent.trim(), // OBLIGATORIO
          apo_emple_aprevi_colect: '',// Aporte empleador ahorro previsional voluntario colectivo(4157)
          t_haberes: row.cells[14].textContent.trim(), // OBLIGATORIO
          t_haberes_imp_tribu: row.cells[10].textContent.trim(), // OBLIGATORIO
          t_haberes_imp_notribu: 0, // OBLIGATORIO
          t_haberes_noimp_notribu: row.cells[13].textContent.trim(), // OBLIGATORIO
          t_haberes_noimp_tribu: 0, // OBLIGATORIO
          totaldescuento: row.cells[22].textContent.trim(), // OBLIGATORIO
          t_desc_imp_rem: row.cells[17].textContent.trim(), // OBLIGATORIO
          t_desc_imp_indem: '',// Total descuentos impuestos por indemnizaciones(5362)
          t_desc_x_cot_trab: row.cells[20].textContent.trim(), // OBLIGATORIO
          t_ot_desc: row.cells[35].textContent.trim(), // OBLIGATORIO
          t_apor_empl: totalAportesEmpleador, // OBLIGATORIO
          liquido: row.cells[23].textContent.trim(), // OBLIGATORIO
          t_indem: '',// Total indemnizaciones(5502)
          t_indm_trib: 0, // OBLIGATORIO
          total_indem_notribut: '',// Total indemnizaciones no tributables(5565)
        };
        datos.push(dato);
        return row;
      })
    )

    // ORDENAR ARRAY DATOS POR EL RUT DEL TRABAJADOR DE FORMA ASCENDENTE
    // const datosOrdenados = datos.sort((a, b) => {
    //   const RutAString = a.rut.replace('-', '').trim();
    //   const RutA = RutAString.endsWith('K') ? RutAString.slice(0, -1) + '0' : RutAString;
    //   const RutBString = b.rut.replace('-', '').trim();
    //   const RutB = RutBString.endsWith('K') ? RutBString.slice(0, -1) + '0' : RutBString;
    //   return RutA - RutB;
    // })

    // ORDENAR ARRAY DATOS POR EL RUT DEL TRABAJADOR DE FORMA ASCENDENTE
    const datosOrdenados = datos.sort((a, b) => {
      const RutAString = a.rut.replace('-', '').trim();
      const RutA = RutAString.endsWith('K') ? RutAString.slice(0, -1) + '0' : RutAString;
      const RutBString = b.rut.replace('-', '').trim();
      const RutB = RutBString.endsWith('K') ? RutBString.slice(0, -1) + '0' : RutBString;
      return RutA - RutB;
    })
    // Convertir el objeto JSON en una cadena con formato
    const datosJSON = JSON.stringify(datosOrdenados, null, 2);
    const datosObjeto = JSON.parse(datosJSON);

    // const wb = XLSX.utils.book_new(); // Crea un nuevo libro de trabajo
    const ws = XLSX.utils.json_to_sheet([]); // Crea una hoja de trabajo vacía

    XLSX.utils.sheet_add_json(ws, datosObjeto, { skipHeader: true, origin: -1 }); //origin: -1
    const header = [
      'Rut trabajador(1101)',
      'Fecha inicio contrato(1102)',
      'Fecha de término de contrato(1103)',
      'Causal de término del contrato(1104)',
      'Región de prestación de los servicios(1105)',
      'Comuna de prestación de los servicios(1106)',
      'Tipo de impuesto a la renta(1170)',
      'Técnico extranjero exencion de cot. previsionales(1146)',
      'Código tipo de jornada(1107)',
      'Persona con Discapacidad - Pensionado por Invalidez(1108)',
      'Pensionado por vejez(1109)',
      'AFP(1141)',
      'IPS(ExINP)(1142)',
      'FONASA - ISAPRE(1143)',
      'AFC(1151)',
      'CCAF(1110)',
      'Org. administrador ley 16.744(1152)',
      'Nro cargas familiares legales autorizadas(1111)', // BD
      'Nro de cargas familiares maternales(1112)',
      'Nro de cargas familiares invalidez(1113)',
      'Tramo asignación familiar(1114)', // BD
      'Rut organización sindical 1(1171)',
      'Rut organización sindical 2(1172)',
      'Rut organización sindical 3(1173)',
      'Rut organización sindical 4(1174)',
      'Rut organización sindical 5(1175)',
      'Rut organización sindical 6(1176)',
      'Rut organización sindical 7(1177)',
      'Rut organización sindical 8(1178)',
      'Rut organización sindical 9(1179)',
      'Rut organización sindical 10(1180)',
      'Nro días trabajados en el mes(1115)',
      'Nro días de licencia médica en el mes(1116)', // BD
      'Nro días de vacaciones en el mes(1117)', // BD
      'Subsidio trabajador joven(1118)',
      'Puesto trabajo pesado(1154)',
      'APVI(1155)',
      'APVC(1157)',
      'Indemnización a todo evento(1131)',
      'Tasa indemnización a todo evento (Art. 164)(1132)',
      'Sueldo(2101)',
      'Sobresueldo(2102)',
      'Comisiones(2103)', // BD
      'Semana corrida mensual(2104)', // BD
      'Participacion(2105)',
      'Gratificacion(2106)',
      'Recargo 30% dia domingo(2107)',
      'Remuneración variable pagada en vacaciones(2108)',
      'Remuneración variable pagada en clausura(2109)',
      'Aguinaldo(2109)',
      'Bonos u otras remuneraciones fijas mensuales(2111)',
      'Tratos(2112)',
      'Bonos u otras remuneraciones variables mensuales o superiores a un mes(2113)',
      'Ejercicio opción no pactada en contrato(2114)',
      'Beneficios en especie constitutivos de remuneración(2115)',
      'Remuneraciones bimestrales(2116)',
      'Remuneraciones trimestrales(2117)',
      'Remuneraciones cuatrimestral(2118)',
      'Remuneraciones semestrales(2119)',
      'Remuneraciones anuales(2120)',
      'Participación anual(2121)',
      'Gratificación anual(2122)',
      'Otras remuneraciones superiores a un mes(2123)',
      'Pago por horas de trabajo sindical(2124)',
      'Sueldo empresarial(2161)',
      'Subsidio por incapacidad laboral por licencia médica(2201)',
      'Beca de estudio(2202)',
      'Gratificaciones de zona(2203)',
      'Otros ingresos no constitutivos de renta(2204)',
      'Colación total mensual(2301)', // BD
      'Movilización total mensual(2302)', // BD
      'Viáticos total mensual(2303)', // BD
      'Asignación de pérdida de caja total mensual(2304)',
      'Asignación de desgaste herramienta total mensual(2305)',
      'Asignación familiar legal(2311)',
      'Gastos por causa del trabajo(2306)',
      'Gastos por cambio de residencia(2307)',
      'Sala cuna(2308)',
      'Asignación trabajo a distancia o teletrabajo(2309)',
      'Depósito convenido hasta UF 900(2347)',
      'Alojamiento por razones de trabajo(2310)',
      'Asignación de traslación(2312)',
      'Indemnización por feriado legal(2313)',
      'Indemnización años de servicio(2314)',
      'Indemnización sustitutiva del aviso previo(2315)',
      'Indemnización fuero maternal(2316)',
      'Pago Indemnización a todo evento(2331)',
      'Indemnizaciones voluntarias tributables(2417)',
      'Indemnizaciones contractuales tributables(2418)',
      'Cotización obligatoria previsional (AFP o ISP)(3141)', //PREVISION
      'Cotización obligatoria salud 7%(3143)', //SALUD
      'Cotización voluntaria para salud(3144)',
      'Cotización AFC - trabajador(3151)',
      'Cotizaciones técnico extranjero para seguridad social fuera de Chile(3146)',
      'Descuento depósito convenido hasta UF 900 anual(3147)',
      'Cotización ahorro previsional voluntario individual modalidad A(3155)',
      'Cotización ahorro previsional voluntario individual modalidad B hasta UF 50(3156)',
      'Cotización ahorro previsional voluntario colectivo modalidad A(3157)',
      'Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50(3158)',
      'Impuesto retenido por remuneraciones(3161)',
      'Impuesto retenido por indemnizaciones(3162)',
      'Mayor retención de impuestos solicitada por el trabajador(3163)',
      'Impuesto retenido por reliquidación remuneraciones devengadas en otros períodos(3164)',
      'Diferencia de impuesto por reliquidación remuneraciones devengadas en este período(3165)',
      'Retención préstamo clase media 2020(3166)', //BD 3% PRESTAMO
      'Rebaja zona extrema DL 889(3167)',
      'Cuota sindical 1(3171)',
      'Cuota sindical 2(3172)',
      'Cuota sindical 3(3173)',
      'Cuota sindical 4(3174)',
      'Cuota sindical 5(3175)',
      'Cuota sindical 6(3176)',
      'Cuota sindical 7(3177)',
      'Cuota sindical 8(3178)',
      'Cuota sindical 9(3179)',
      'Cuota sindical 10(3180)',
      'Crédito social CCAF(3110)',
      'Cuota vivienda o educación(3181)',
      'Crédito cooperativas de ahorro(3182)',
      'Otros descuentos autorizados y solicitados por el trabajador(3183)',
      'Cotización adicional trabajo pesado - trabajador(3154)',
      'Donaciones culturales y de reconstrucción(3184)',
      'Otros descuentos(3185)',
      'Pensiones de alimentos(3186)',
      'Descuento mujer casada(3187)',
      'Descuentos por anticipos y préstamos(3188)',
      'Aporte AFC - empleador(4151)',
      'Aporte empleador seguro accidentes del trabajo y Ley SANNA(4152)',
      'Aporte empleador indemnización a todo evento(4131)',
      'Aporte adicional trabajo pesado - empleador(4154)',
      'Aporte empleador seguro invalidez y sobrevivencia(4155)',
      'Aporte empleador ahorro previsional voluntario colectivo(4157)',
      'Total haberes(5201)',
      'Total haberes imponibles y tributables(5210)',
      'Total haberes imponibles no tributables(5220)',
      'Total haberes no imponibles y no tributables(5230)',
      'Total haberes no imponibles y tributables(5240)',
      'Total descuentos(5301)',
      'Total descuentos impuestos a la remuneraciones(5361)',
      'Total descuentos impuestos por indemnizaciones(5362)',
      'Total descuentos por cotizaciones del trabajador(5341)',
      'Total otros descuentos(5302)',
      'Total aportes empleador(5410)',
      'Total Liquído(5501)',
      'Total indemnizaciones(5502)',
      'Total indemnizaciones tributables(5564)',
      'Total indemnizaciones no tributables(5565)',
    ];
    XLSX.utils.sheet_add_aoa(ws, [header]);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
    const arrayBufferCSV = this.convertirCSVtoFile(csv);
    const fileName = this.crearFileName();
    // console.log({fileName});
    saveAs(new Blob([arrayBufferCSV], { type: "application/octet-stream" }), fileName+'.csv');
  }
  async obtenerDiasLicencias(rut: number): Promise<number> {
    const periodo: Date = this.datePeriodo.value._d;
    const lastDate = endOfMonth(periodo);
    const res = await this.movimienotPersonalService.obtenerMovimientosPersonalesPorRut(rut);
    if (!res.success) return 0;
    const movimientos: MovimientosPersonal[] = res.data.filter((x: MovimientosPersonal) => {
      const periodoMovimiento = parseISO(x.periodo);
      return (getMonth(periodo) === getMonth(periodoMovimiento) && getYear(periodo) === getYear(periodoMovimiento)) && x.idtipoevento === 10;
    });
    return movimientos.reduce((a:number, e:MovimientosPersonal) => {
      const inicio = parseISO(e.fecha_inicio);
      const final = parseISO(e.fecha_final);
      if (lastDate.getDate() === 31) return (differenceInDays(final, inicio) + 1 > 30 ? 30 : differenceInDays(final, inicio) + 1);
      return differenceInDays(final, inicio);
    }, 0);
  }
  async obtenerCodigoRegion(idregion: number): Promise<number> {
    const res = await this.regionService.TraerRegionesAsync();
    if (res.success) {
      return res.data.find((region) => region.id === idregion)?.codigo_lre??0;
    }else{
      this.alert.error_small(res.msg);
      return 0;
    }
  }
  obtenerTramoAsigFam(tramoAsigFam: number) {
    const tramos = {
      1: 'A',
      2: 'B',
      3: 'C',
      4: 'D'
    }
    return tramos[tramoAsigFam] || 'S';
  }
  // CREAR EL NOMBRE DEL ARCHIVO PARA EL LIBRO DE REMUNERACION ELECTRONICO
  crearFileName() {
    const ctrlValue = this.datePeriodo.value;
    const periodo:Date = ctrlValue.toDate();
    const empresa = this.empresas.find(objeto => objeto.id === this.empresa);
    const rutEmpleador = empresa.run?.replace(/[.-]/g, '');
    
    const month = periodo.getMonth()+1 < 10 ? '0'+Number(periodo.getMonth()+1):periodo.getMonth()+1;
    const year = periodo.getFullYear().toString();
    const textPeriodo = year+month;

    return  rutEmpleador+'_'+textPeriodo;
  }
  convertirCSVtoFile(csv: string): ArrayBuffer {
    const buf = new ArrayBuffer(csv.length);
    const view = new Uint8Array(buf);
    for (var i = 0; i != csv.length; ++i) view[i] = csv.charCodeAt(i) & 0xFF;
    return buf;
  }
  public convetToPDF() {
    const month = this.periodo.min.getMonth() + 1 < 10 ? '0' + Number(this.periodo.min.getMonth() + 1) : this.periodo.min.getMonth() + 1;
    const year = this.periodo.min.getFullYear();
    this.fechaFormatT = month + '/' + year;
    var datos = [];
    datos.push([
      'RUT',
      'NOMBRE',
      'DIAS\nTRABAJADOS',
      // 'FECHA',
      // 'SUCURSAL',
      'SUELDO\nBASE',
      'HORAS\nEXTRAS',
      'GRAT.\nLEGAL',
      'OTROS\nIMPONIBLE',
      'TOTAL\nIMPONIBLE',
      'ASIGN.\nFAMILIAR',
      'OTROS\nNO IMPONIBLE',
      'TOTAL\nNO IMPONIBLE',
      'TOTAL\nHABERES',
      'PREVISION',
      'SALUD',
      'IMPUEST.\nUNICO',
      'SEGURO\nCESANTIA',
      'OTROS\nDESC.\nLEGALES',
      'TOTAL\nDESC.\nLEGALES',
      'DESCUENTOS\nVARIOS',
      'TOTAL\nDESCUENTOS',
      'LIQUIDO',
    ]);
    // Crear un objeto JSON para almacenar los datos de la tabla
    const datas = this.obtenerDatosTabla();
    // Agregar el array de sucursales al objeto JSON
    // Convertir el objeto JSON en una cadena con formato
    const datosJSON = JSON.stringify(datas, null, 2);
    const datosObjeto = JSON.parse(datosJSON);
    let coincidencias = [];
    for (let i = 0; i < this.sucur.length; i++) {
      coincidencias[i] = []; // Crea un array vacío dentro de coincidencias para cada sucursal en sucur
      for (let j = 0; j < datosObjeto.length; j++) {
        if (this.sucur[i] == datosObjeto[j]['sucursal']) {
          coincidencias[i].push(datosObjeto[j]); // Agrega el objeto coincidente al array correspondiente dentro de coincidencias
        }
      }
    }
    let totales = [];

    for (let i = 0; i < coincidencias.length; i++) {
      //TODO: PASAR OBJETO A ARREGLO
      for (let x = 0; x < coincidencias[i].length; x++) {
        const valuesToInsert = Object.values(coincidencias[i][x]);
        valuesToInsert.splice(2, 1); //Elimino sucursal del array
        datos.push(valuesToInsert);
      }

      const totalsRow = this.totalesTabla(coincidencias[i]);
      const totalsRowSlice = totalsRow.slice(0, 19);
      totalsRowSlice.unshift('-', this.sucur[i]);
      datos.push(totalsRowSlice);
      totales.push(totalsRow);
    }

    datos.push([
      '-',
      '-',
      //  'SUCURSAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
      'TOTAL',
    ]);
    const sumArray = totales[0].slice();

    for (let i = 0; i < totales.length; i++) {
      for (let j = 0; j < totales[i].length; j++) {
        // sumArray[j] += totales[i][j];
        // console.log(sumArray[j])
      }
     }
    datos.push([...['-', 'TOTAL GENERAL'], ...sumArray]);
    var documentDefinition = {
      pageOrientation: 'landscape',
      pageSize: 'A4',
      content: [
        {
          style: 'header',
          text: 'Libro de Remuneracion'
        },
        {
          style: 'dia',
          text: ' MES : ' + this.fechaFormatT
        },
        {
          style: 'sucur',
          text:  this.sucur
        },
        {
          style: 'tableExample',
          table: {
            body: datos
          },
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 20, 0, 50],
          fontSize: 5.7,
        },
        header: {
          bold: true,
          fontSize: 30,
          margin: [0, 0, 0, 0],
        },
        dia: {
          bold: true,
          fontSize: 15,
          margin: [0, 0, 0, 0],
        },
        sucur: {
          bold: true,
          fontSize: 15,
          margin: [0, 0, 0, 0] 
        },
        negrita: {
          bold: true,
        },
      },
    };
    pdfMake.createPdf(documentDefinition).open();
  }
  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.trabajadorService.TraerEmpresas().subscribe((res: any) => {
      this.empresas = res.data.map(x => {
        x.cotizBasica = parseFloat(x.cotizBasica);
        x.cotizLeySanna = parseFloat(x.cotizLeySanna);
        return x;
      });
    });
    this.trabajadorService.TraerSucursales().subscribe((res: any) => {
      this.sucursales = res.data.map(x => {
        x.cotizBasica = parseFloat(x.cotizBasica);
        x.cotizLeySanna = parseFloat(x.cotizLeySanna);
        return x;
      });
    });
    for (let i = this.actual; i >= 2000; i--) {
      this.anio.push({ id: this.anio.length, valor: i });
    }
  }
  async obtenerIndicadoresImpuestoUnico(periodo: Date) {
    const month = periodo.getMonth() + 1;
    const monthString = month < 10 ? '0' + month : month;
    const year = periodo.getFullYear();
    try {
      const result = await fetch('https://api.gael.cloud/general/public/impunico/' + monthString + year);
      const indicadores = await result.json();
      this.indicadoresImpuestoUnico = {
        periodo: indicadores.PeriodoMY,
        TR1Desde: indicadores.TR1Desde,
        TR1Hasta: indicadores.TR1Hasta,
        TR1Factor: indicadores.TR1Factor,
        TR1CReb: indicadores.TR1CReb,
        TR2Desde: indicadores.TR2Desde,
        TR2Hasta: indicadores.TR2Hasta,
        TR2Factor: indicadores.TR2Factor,
        TR2CReb: indicadores.TR2CReb,
        TR3Desde: indicadores.TR3Desde,
        TR3Hasta: indicadores.TR3Hasta,
        TR3Factor: indicadores.TR3Factor,
        TR3CReb: indicadores.TR3CReb,
        TR4Desde: indicadores.TR4Desde,
        TR4Hasta: indicadores.TR4Hasta,
        TR4Factor: indicadores.TR4Factor,
        TR4CReb: indicadores.TR4CReb,
        TR5Desde: indicadores.TR5Desde,
        TR5Hasta: indicadores.TR5Hasta,
        TR5Factor: indicadores.TR5Factor,
        TR5CReb: indicadores.TR5CReb,
        TR6Desde: indicadores.TR6Desde,
        TR6Hasta: indicadores.TR6Hasta,
        TR6Factor: indicadores.TR6Factor,
        TR6CReb: indicadores.TR6CReb,
        TR7Desde: indicadores.TR7Desde,
        TR7Hasta: indicadores.TR7Hasta,
        TR7Factor: indicadores.TR7Factor,
        TR7CReb: indicadores.TR7CReb,
        TR8Desde: indicadores.TR8Desde,
        TR8Hasta: indicadores.TR8Hasta,
        TR8Factor: indicadores.TR8Factor,
        TR8CReb: indicadores.TR8CReb,
      };
    } catch (error) {
      console.error(error);
    }
  }
  // METODO QUE CAPTURA EL AÑO DEL DATEPICKER
  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.datePeriodo.value;
    ctrlValue.year(normalizedYear.year());
    ctrlValue.month(normalizedYear.month());
    this.datePeriodo.setValue(ctrlValue);
    datepicker.close();
    const datePeriodoofDate = new Date(this.datePeriodo.value._d);
    this.periodo = {
      min: new Date(datePeriodoofDate.getFullYear(), datePeriodoofDate.getMonth(), 1),
      max: new Date(datePeriodoofDate.getFullYear(), datePeriodoofDate.getMonth() + 1, 0)
    };
    this.filtrarTabla();
    // console.log(this.periodo);
  }

  filtrarTabla() {
    this.listaFiltrada = this.listatra.filter(x =>
      x.sucursal === this.nempresa?.nombre &&
      x.sucur === this.sucursalSelect &&
     new Date(x.f_liquidacion + 'T00:00:00').getTime() >=     this.periodo.min.getTime() &&
   new Date(x.f_liquidacion + 'T00:00:00').getTime() <=     this.periodo.min.getTime() );
    this.dataSource = new MatTableDataSource(this.listaFiltrada);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  async obtenerTrabajador(rut: number) {
    const res = await this.trabajadorService.TraerTrabajadoresRutAsync(rut);
    if (res.success) {
      return res.data[0];
    }else{
      return res.msg;
    }
  }
}
