<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
        <span style="color: white">Agregar Planilla </span>
        <mat-icon style="color: white">add</mat-icon>
    </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Agregar nueva planilla</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <!-- Fila 1 -->
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Trabajador</mat-label>
                        <mat-select  name="planilla.fk_trabajador"  (selectionChange)="ObtenerTrabajador($event)">
                            <mat-option *ngFor="let element of trabajadores_aux" [value]="element.rut">
                              {{element.nombres}} {{element.apellidos}}
                                 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                     </div>
                <!-- Data picker Fecha de inicio -->
             
            </div>

            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Fecha</mat-label>
                        <input matInput [matDatepicker]="picker" (dateChange)="obtener_fecha($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>
                </div>
              
                <!-- Sueldo -->
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sueldo</mat-label>
                        <input type="number" maxlength="6" minlength="3" matInput name="planilla.sueldo" [(ngModel)]="planilla.sueldo" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>

            </div>

            <!-- Fila 2 -->
            <div class="row">

                <!-- Bonos -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Bonos</mat-label>
                        <input type="number" maxlength="7" minlength="0" matInput name="planilla.bonos" [(ngModel)]="planilla.bonos" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>

                <!-- Adelanto -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Adelanto</mat-label>
                        <input type="number" maxlength="6" minlength="3" matInput name="planilla.adelanto" [(ngModel)]="planilla.adelanto" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>
            </div>

            <!-- Fila 3 -->
            <div class="row">

                <!-- Prestamo -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Préstamo</mat-label>
                        <input type="number" maxlength="7" minlength="1" matInput name="planilla.prestamos" [(ngModel)]="planilla.prestamos" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>

                <!-- Inasistencia -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Inasistencia</mat-label>
                        <input type="number" maxlength="3" minlength="1" matInput name="planilla.inasistencia" [(ngModel)]="planilla.inasistencia" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>
            </div>

            <!-- Fila 4 -->
            <div class="row">

                <!-- Asignación Familia -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Asignación Familiar</mat-label>
                        <input type="number" maxlength="10" minlength="1" matInput name="planilla.asignacionFamiliar" [(ngModel)]="planilla.asignacionFamiliar" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>

                <!-- Sueldo líquido-->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sueldo Líquido</mat-label>
                        <input type="number" maxlength="10" minlength="1" matInput name="planilla.sueldoLiquido" [(ngModel)]="planilla.sueldoLiquido" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <!-- Total a pagar -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Total a pagar</mat-label>
                        <input type="number" maxlength="10" minlength="1" matInput name="planilla.totalAPagar" [(ngModel)]="planilla.totalAPagar" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>



                <!-- Transferencia -->
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>transferencia</mat-label>
                        <input type="text" maxlength="10" minlength="1" matInput name="planilla.transferencia" [(ngModel)]="planilla.transferencia" [ngModelOptions]="{standalone: true}" />
                    </mat-form-field>
                </div>

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="agregar_Planilla()">
            Guardar
        </button>
    </div>
</ng-template>