<div class="row">
    <div class="col col-5" *ngIf="empresas.length > 0">
        <!-- SELECT EMPRESAS -->
        <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Empresa</mat-label>
            <mat-select [formControl]="empresa_default_select" required>
                <mat-option *ngFor="let empresa of empresas" [value]="empresa.id" (click)="selectEmpresa(empresa)">
                    {{ empresa.run }} - {{empresa.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col col-2">
        <!-- SELECT PERIODO AÑO -->
        <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Año declaratorio</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event, dp)"
            panelClass="example-month-picker">
            </mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col col-5 mt-3" *ngIf="trabajadores.length > 0">
        <!-- SELECT TRABAJADORES -->
        <p-dropdown [options]="trabajadores_aux" placeholder="Seleccione un trabajador" optionLabel="text"
            emptyFilterMessage="Sin resultados de trabajadores" (onChange)="ObtenerTrabajador($event)"
            [showClear]="true" [filter]="true" filterBy="text" [style]="{ width: '100%' }">
        </p-dropdown>
        
        <!-- <mat-form-field appearance="outline" class="mb-2 w-100">
            <mat-label>Funcionario</mat-label>
            <mat-select [formControl]="trabajador_default_select" required>
                <mat-option *ngFor="let trabajador of trabajadores" [value]="trabajador.rut" (click)="selectTrabajador(trabajador)">
                    {{ trabajador.rut | formatoRut }} - {{trabajador.nombres}} {{trabajador.apellidos}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
    <div class="col col-12 d-flex justify-content-center">
        <!-- BOTON -->
        <button 
        (click)="verificarEstadoDatos()" 
        class="btn btn-primary">
            Generar
        </button>
    </div>
</div>