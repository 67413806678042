import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { ImplementosService } from 'src/app/pages/services/implementos/implementos.service';
import { ImplementosUService } from 'src/app/pages/services/implementosU/implementosU.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

@Component({
  selector: 'app-agregar-uniforme.',
  templateUrl: './agregar-uniforme.component.html',

  providers: [DatePipe]
})
export class AgregarUniformeComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @Input() rut !: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  debouncer: Subject<boolean> = new Subject();

  constructor(

    private modalService: NgbModal,
    private trabajadorService: TrabajadoresService,
    private datePipe: DatePipe,
    private alert: AlertHelper,
    private implementoUService: ImplementosUService,

  ) { }





  /////—————  Variables   ————-\\\\\\



  dataSource: any;
  RutTrabajador: any;
  TrabajadorList: any;
  mostrar: any = 0;
  checked: boolean = false;

  poleron: any;
  polera: any;
  pantalon: any;
  zapatos: any;
  fechaT: Date;
  fechaFormatT: string;
  fecha: Date;
  fechaFormat: string;
  mas: any = 0;
  constt: any = 0;

  listo: any = 0;
  listoU: any = 0;


  /////————— Variable BD AND TABLA   ————-\\\\\\


  displayedColumns: string[] = [
    'rut',
    'nombres',
    'acciones'
  ]



  implementosU: any = {
    id: 0,
    fechaU: 0,
    tallaPolera: "",
    cantidadPolera: "",
    tallaPoleron: "",
    cantidadPoleron: "",
    tallaPantalon: "",
    cantidadPantalon: "",
    tallaZapato: "",
    cantidadZapato: "",
    adicional:"",
    estadoU: "",
    fk_trabajador: 0

  }



  /////—————  Select   MatSelectChange————-\\\\\\


  ObtenerPoleron(event: MatSelectChange) {


    this.poleron = event.value;



  }

  ObtenerPolera(event: MatSelectChange) {


    this.polera = event.value;


  }


  ObtenerZapatos(event: MatSelectChange) {


    this.zapatos = event.value;


  }

  ObtenerPantalon(event: MatSelectChange) {


    this.pantalon = event.value;


  }




  onChangeSlide($event: any) {

    if ($event.checked) {
      this.mostrar = 1
      this.checked = true
    }
    else {
      this.mostrar = 0
      this.checked = false

    }
  }




  /////—————  Select   MatDatepickerInputEvent————-\\\\\\


  obtener_fechaUniforme(event: MatDatepickerInputEvent<Date>) {

    this.fechaT = event.value;
    this.fechaFormatT = this.datePipe.transform(this.fechaT, 'yyyy-MM-dd'); //aqui se transforma usando datepipe al formato año-mes-

  }




  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\



  open(content: any) {


    this.modalService.open(content);

  }
  TraerDatos(event: boolean) {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res) => {
        this.dataSource = [];
        this.TrabajadorList = res.data;


        // this.list = datos.filter((x:any)=>{ return x.Trabajador.Empresa_contratante.nombre === this.empresas[i];});

        // let datosTramitados= res.data ? res.data.filter((x:any)=>{ return x.estado != "SUBIDA"}) :[];


        this.dataSource = new MatTableDataSource(this.TrabajadorList);
        this.dataSource.paginator = this.paginator
      });
  }

  limpiar_Implemento() {


    this.implementosU.fechaU = "";
    this.implementosU.tallaPoleron = "";
    this.implementosU.tallaPolera = "";
    this.implementosU.tallaPantalon = "";
    this.implementosU.tallaZapato = "";


  }

  generarUniforme() {

    this.implementosU.estadoU = "PENDIENTE";

    this.implementosU.fk_trabajador = this.rut;

    this.implementosU.fechaU = this.fechaFormatT;

    this.implementosU.tallaPolera = this.polera;

    this.implementosU.tallaPoleron = this.poleron;

    this.implementosU.tallaZapato = this.zapatos;

    this.implementosU.tallaPantalon = this.pantalon;

    this.implementoUService.CrearUniforme(this.implementosU).subscribe((res: any) => {

      if (res.success == true) {



        this.constt == 1;
        this.alert.success_small(res.msg!)

        this.ngOnInit();
        this.modalService.dismissAll();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }


    })

  }




  /////—————AL INICIAR EL MODULO   ————-\\\\\\



  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });

    this.TraerDatos(true);


    this.constt = 0;

    this.implementoUService.TraerPorRutU(this.rut).subscribe((res: any) => {



      if (res.data == false) {

        //console.log("Este usuario no tiene implementos")


        this.constt = 0;


      } else {
        //console.log("Este usuario si tiene implementos")


        this.constt = 1;

      }

    })


  }

}
