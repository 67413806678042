<!-- HISTORIAL DE COMENTARIOS -->
<ng-container *ngIf="historyDecisionesLiquidacion?.length > 0; else sinHistory">
    <p-card styleClass="mb-2" *ngFor="let aprobacion of historyDecisionesLiquidacion">
        <ng-template pTemplate="title">
            <mat-icon class="{{aprobacion.icon_color}}">{{aprobacion.icon}}</mat-icon>
            {{aprobacion.estado}}
        </ng-template>
        <ng-template pTemplate="subtitle">
            {{aprobacion.fecha | date: 'dd - MMMM - yyyy'}}
        </ng-template>
        <p class="text-wrap">{{aprobacion.comentario}}</p>
        <button mat-button (click)="verLiquidacion(aprobacion)">Ver Liquidación</button>
        <!-- <p><a mat-button href="{{aprobacion.documento}}" target="_blank">Ver liquidación</a></p> -->
    </p-card>
    <!-- PENDIENTE -->
    <p-card>
        <ng-template pTemplate="title">
            <mat-icon class="text-secondary">schedule</mat-icon>
            PENDIENTE
        </ng-template>
        <ng-template pTemplate="subtitle">
            {{documentos[0]?.fechaCronologica | date: 'dd - MMMM - yyyy'}}
        </ng-template>
        <p class="text-wrap">CREACION DE LA LIQUIDACIÓN</p>
        <a mat-button [href]="documentos[0]?.documento" target="_blank">Ver Liquidación</a>
    </p-card>
</ng-container>
<!-- <mat-list *ngIf="historyDecisionesLiquidacion.length > 0; else sinHistory">
    <div mat-subheader></div>
    <mat-list-item *ngFor="let aprobacion of historyDecisionesLiquidacion">
      <mat-icon mat-list-icon *ngIf="aprobacion.estado === 0"><span class="text-warning">edit_square</span></mat-icon>
      <mat-icon mat-list-icon *ngIf="aprobacion.estado === 1"><span class="text-success">thumb_up</span></mat-icon>
      <mat-icon mat-list-icon *ngIf="aprobacion.estado === 2"><span class="text-danger">thumb_down</span></mat-icon>
      <div mat-line class="text-wrap">{{aprobacion.comentario}}</div>
      <div mat-line> Documento: <a mat-button href="{{aprobacion.documento}}" target="_blank">Ver documento</a></div>
      <div mat-line> {{aprobacion.fecha | date: 'dd-MM-yyyy'}} </div>
    </mat-list-item>
</mat-list> -->
<ng-template #sinHistory>
    <mat-list>
        <div mat-subheader>Historial de decisiones</div>
        <mat-list-item>
            <div mat-line>No hay historial de decisiones</div>
        </mat-list-item>
    </mat-list>
</ng-template>