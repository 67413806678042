import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';

import { CompetenciasModule } from './perfiles-ocupacionales/competencias/competencias.module'
import { AntecedentesModule } from './perfiles-ocupacionales/antecedentes/antecedentes.module'
import { AfpModule } from './afp/afp.module'
import { CartasAmonestacionModule } from './documentos/cartas-amonestacion/cartas-amonestacion.module';
import { TrabajadoresModule } from './trabajadores/trabajadores.module';
import { ContratosModule } from './documentos/contratos/contratos.module';
import { HistorialModule } from './historial/historial.module';
import { GestorasistenciaModule } from './gestorasistencia/gestorasistencia.module';
import { HaberesModule } from './haberes/haberes.module';
import { BancoModule } from './banco/banco.module';
import { CiudadesModule } from './ciudades/ciudades.module';
import { SucursalesModule } from './sucursales/sucursales.module';
import { PermisosModule } from './documentos/permisos/permisos.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { CargosModule } from './cargos/cargos.module'
import { ConocimientosModule } from './perfiles-ocupacionales/conocimientos/conocimientos.module';
import { DocTransferencias } from './documentos/transferencias/docTransferencias.module';
import { EmpresasModule } from './empresas/empresas.module';
import { EstadoCivilModule } from './estado-civil/estado-civil.module';
import { FuncionesModule} from './perfiles-ocupacionales/funciones/funciones.module';
import { AdelantosModule } from './gestion-pago/adelantos/adelantos.module';
import { PrestamosModule } from './gestion-pago/prestamos/prestamos.module';
import { horasExtrasModule} from './horas-extras/horasExtras.module';
import { NacionalidadesModule } from './nacionalidades/nacionalidades.module';
import { PrevisionModule } from './prevision/prevision.module';
import { RequisitosModule } from './perfiles-ocupacionales/requisitos/requisitos.module';
import { RolesModule } from './roles/roles.module';
import { FiniquitoModule } from './documentos/finiquitos/finiquito.module';
import { LiquidacionesModule } from './documentos/liquidaciones/liquidaciones.module';
import { PerfilesOcupacionalesModule } from './documentos/perfiles-ocupacionales/perfiles-ocupacionales.module';
import { RazonModule } from './razon/razon.module';
import { LicenciasModule } from './documentos/licencias/licencias.module';
import { GenerarContratoModule } from './generar-contrato/generar-contrato.module';
import { VacacionesModule } from './documentos/vacaciones/vacaciones.module';
import { RegionesModule } from './regiones/regiones.module';
import { TipoprestamoModule } from './tipoprestamo/tipoprestamo.module';
import { MotivosCartaAmonestacionModule } from './motivos-carta-amonestacion/motivos-carta-amonestacion.module';
import { ResumenHaberesModule } from '../contabilidad/resumen-haberes/resumen-haberes.module';

import { PlanillaModule } from './gestion-pago/planilla-pagos/planilla.module';
import { JornadaLaboralModule } from './jornada-laboral/jornada-laboral.module';
import { ImplementosModule } from './implementos/implementos.module';
import { GenerarAnexoModule } from './generar-anexo/generar-anexo.module';
import { LogsModule } from './logs/logs.module';
import { MarcacionModule } from '../trabajador/marcacion/marcacion.module';
import { VentasModule } from '../contabilidad/ventas/ventas.module';
import { PagosModule } from '../contabilidad/pagos/pagos.module';
import { ComprasModule } from '../contabilidad/compras/compras.module';
import { ImpuestoSegundaCatModule } from '../contabilidad/parametros/impuesto-segunda-cat/impuesto-segunda-cat.module';
import { RecaudacionesModule } from '../contabilidad/recaudaciones/recaudaciones.module';
import { CertificadoModule } from '../contabilidad/certificado/certificado.module';
import { PreviredModule } from '../contabilidad/previred/previred.module';
import { MovimientoPersonalModule } from './movimiento-personal/movimiento-personal.module';
import { BuscarFilterModule } from './buscar-filter/buscar-filter.module';
import { SolicitudesModule } from './solicitudes/solicitudes.module';
import { FirmasModule } from './firmas/firmas.module';
import { ExcelPagoModule } from '../contabilidad/excel-pago-liquidacion/excel-pago.module';

@NgModule({
  declarations: [
  
  ],
  imports: [
    AdelantosModule,
    AdminRoutingModule,
    AfpModule,
    AntecedentesModule,
    BancoModule,
    BuscarFilterModule,
    CargosModule,
    CartasAmonestacionModule,
    CertificadoModule,
    CiudadesModule,
    CiudadesModule,
    CommonModule,
    CompetenciasModule,
    ComprasModule,
    ConocimientosModule,
    ContratosModule,
    DocTransferencias,
    EmpresasModule,
    EstadoCivilModule,
    FiniquitoModule,
    FormsModule,
    FuncionesModule,
    GenerarAnexoModule,
    GenerarContratoModule,
    GestorasistenciaModule,
    HaberesModule,
    HistorialModule,
    horasExtrasModule,
    ImplementosModule,
    ImpuestoSegundaCatModule,
    JornadaLaboralModule,
    LicenciasModule,
    LiquidacionesModule,
    LogsModule,
    MarcacionModule,
    MaterialModule,
    MotivosCartaAmonestacionModule,
    MovimientoPersonalModule,
    NacionalidadesModule,
    PagosModule,
    PerfilesOcupacionalesModule,
    PermisosModule,
    PermisosModule,
    PlanillaModule,
    PrestamosModule,
    PreviredModule,
    PrevisionModule,
    RazonModule,
    RecaudacionesModule,
    RegionesModule,
    RequisitosModule,
    ResumenHaberesModule,
    RolesModule,
    SucursalesModule,
    SucursalesModule,
    TipoprestamoModule,
    TrabajadoresModule,
    UsuariosModule,
    VacacionesModule,
    VentasModule,
    SolicitudesModule,
    ExcelPagoModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule { }
