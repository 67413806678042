import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { BancoService } from 'src/app/pages/services/banco/banco.service'; 

import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Banco } from 'src/app/shared/models/banco/banco.interface';


@Component({
  selector: 'app-lista-banco',
  templateUrl: './lista-banco.component.html',
  styleUrls: ['./lista-banco.component.css']
})
export class ListaBancoComponent implements OnInit {

  debouncer: Subject<boolean> = new Subject();

  fileName = 'ciudades.xlsx';
  dataSource: any;
  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);

  }


  banco:any;
  public convetToPDF() {

    var datos=[];
    datos.push(['ID','NOMBRE']);

   
 
  this.banco.map(function (item: any) {

    datos.push([item.id,item.nombre]);
  });
 

  var documentDefinition = {

    content: [

      {text: 'Bancos', style: 'header',fontSize:30},
      {
        table: {
          widths: [50,400],
          body:datos
          
        }
      }
    ]
  };
  
  pdfMake.createPdf(documentDefinition).open();
  }


  
  displayedColumns: string[] = [
    'id',
    'nombre',
    'logo',
    'acciones'
  ]

 

  traerdatos(event:boolean){

    this.bancoService.TraerTodos().subscribe((res) => {
      
      this.dataSource= [];
      this.banco = res.data;

      this.dataSource = new MatTableDataSource(this.banco);
      
  
    })

  }


  eliminar(id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el banco",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.bancoService.eliminar(id).subscribe(res => {
          if (res.success == true) {
            this.traerdatos(true);
            this.alert.success_small(res.msg!)
           
          } else {
            this.alert.error_small(res.msg!)
          }
        });
      }
    })

    this.debouncer.next(true);
  }


  listarbancos:any;

  bancos: Banco = {
    id: 0,
    nombre: '',
    urlLogo: '',

  };


  open(up: any, id: number) {


    this.modalService.open(up);
    this.bancos.id = id
    this.bancoService.TraerPorId(id).subscribe(res => {
     
      this.bancos.nombre = res.data.nombre;
      this.bancos.urlLogo = res.data.urlLogo;
   
    })
  }
  

  actualizar_banco() {


    this.bancoService.actualizar(this.bancos).subscribe(res => {

    
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.modalService.dismissAll();
            this.debouncer.next(true);
            this.ngOnInit();
            this.limpiarFormulario();
          } else {
            this.alert.error_small(res.msg!)
          }

    })
   
  }
  limpiarFormulario() {
    this.bancos.nombre = '';
    this.bancos.urlLogo = '';
  }

  //FITRAL
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
}


 
  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private bancoService: BancoService
  ) { }

  ngOnInit(): void {
    this.traerdatos(true);
  }

}
