import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from 'src/app/material.module';
import { MainVentasComponent } from './main-ventas/main-ventas.component';

import { ListaVentasComponent } from './lista-ventas/lista-ventas.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { HelperModule } from 'src/app/helper.module';
import { MatSortModule } from '@angular/material/sort';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { AgregarVentasComponent } from './agregar-ventas/agregar-ventas.component';

@NgModule({
  declarations: [
    MainVentasComponent,
    AgregarVentasComponent,
    ListaVentasComponent,
  
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    FileUploadModule,
    PrimeNgModule,
    HelperModule,
    MatSortModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
  providers: [
  ],
  exports: [
    MainVentasComponent,
    AgregarVentasComponent,
    ListaVentasComponent
   


  ]
})
export class VentasModule { }
