<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
      <span style="color: white">Agregar Jornada</span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Nueva Jornada Laboral</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Descripción de la Jornada</mat-label>
                    <textarea type="textarea" rows="7" maxlength="1000" name="jornada" required matInput [(ngModel)]="jornada.descripcion"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">Cancelar</span>
      </button>
        <button type="button" class="btn btn-outline-success" (click)="agregar()">
        Guardar
      </button>
    </div>
</ng-template>