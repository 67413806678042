import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { PrevisionService } from 'src/app/pages/services/prevision.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-agregar-prevision',
  templateUrl: './agregar-prevision.component.html',
  styleUrls: ['./agregar-prevision.component.css']
})
export class AgregarPrevisionComponent implements OnInit {
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private previsionService: PrevisionService,
  ) { }
  prevision: any = {
    nombre: "",
    tasa: 0,
    codigo: 0,
  }
  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\
  open(content: any) {
    this.limpiar_Prevision();
    this.modalService.open(content);
  }

  validarPrevision() {
    if (this.prevision.isapre.trim().length == 0) {
      this.alert.errorAlert('Los campos no pueden estar vacios.')
    }
  }

  limpiar_Prevision() {
    this.prevision.nombre = "";
  }

  agregar() {
    this.previsionService.Create_prevision(this.prevision).subscribe((res: any) => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.limpiar_Prevision();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })

  }

  /////—————AL INICIAR EL MODULO   ————-\\\\\\
  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }
}
