
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TransferenciaService {

  constructor(
    private http:HttpClient,
    private router: Router,
    private alert:AlertHelper
  ) { }

  TraerArchivo(id:any): Observable<RequestResponse> {

    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscartransferenciaid/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/transferencia/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  eliminarTransferencia(id:number):Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/transferencia/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError((error) => this.handlerError(error)),
      )
  }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
