import { Component, HostListener, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import saveAs from 'file-saver';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import { HelperService } from 'src/app/pages/services/helper/helper.service';
import { GenerarAnexoService } from 'src/app/pages/services/PDF-GENERADOR/anexos/generar-anexo.service';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { clausulas, valores } from './datos'
import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}
@Component({
  selector: 'app-generar-anexo-contrato',
  templateUrl: './generar-anexo-contrato.component.html',
  styleUrls: ['./generar-anexo-contrato.component.css']
})
export class GenerarAnexoContratoComponent implements OnInit {

  @ViewChild('modal', { static: false }) modal: any;
  // @ViewChild('stepper') private stepper: MatStepper;
  // @HostListener('window:keyup', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.code == 'Enter') {
  //     this.stepper.next();
  //   }
  //   if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
  //     this.stepper.next();
  //   }
  //   if (event.keyCode === KEY_CODE.LEFT_ARROW) {
  //     this.stepper.previous();
  //   }
  // }
  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private generador: GenerarAnexoService,
    private trabajadoresService: TrabajadoresService,
    private ciudadService: CiudadService,
    private sucursalService: SucursalService,
    private helper: HelperService,
  ) {
    this.TraerDatos();
  }
  returnFecha: any;
  src: any;
  render: number = 0;
  lista_trabajadores: any;
  TrabajadorSeleccionado: any;
  lista_ciudades: any;
  CiudadSeleccionada: any = undefined;
  sucursales: any;
  numero_clausula: any;
  clausula_seleccionada: any;
  clausulas = clausulas;
  mostrarfecha: Boolean = false
  texto: any;
  Datos: any = {}
  fecha = Date.now()
  ejemplo: boolean = false;
  MostrarModalPDF: boolean = false
  cargando: boolean = false;
  TresMeses: boolean = false;
  Otro: boolean = false;
  SeisMeses: boolean = false;
  indefinido: boolean = false;
  duracion: String = '';
  idClausula: number;
  Fechas: any = {
		fecha_inicio: '',
		fecha_fin: ''
	}
  fk_trabajador: string = '';
  fecha_ingreso: Date;
  ngOnInit(): void {
  }

  

  //cacular fecha

   addMonths(date, months) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  }

  click3Meses(){
    const fechaI = this.fecha_ingreso.toString();

    const fechaString = fechaI.includes('T') ? fechaI.split('T')[0] : fechaI;
    const fechaDate = parseISO(fechaString);
    const fechaFormateada = format(fechaDate, "yyyy-MMMM-dd", { locale: es } );

    this.SeisMeses = false;
    this.indefinido = false;
    this.Otro = false;
    this.duracion = '3 Meses';
    const date = new Date(fechaFormateada);
    this.returnFecha = this.addMonths(date,3);
    return this.returnFecha;
  }

  clickOtro(){
    this.TresMeses = false;
    this.SeisMeses = false;
    this.indefinido = false;
    this.duracion = 'Otro';
    var temporal = valores[10].valor.split("%");
    this.texto = temporal[0] + " " + this.numero_clausula + " " + temporal[2] + this.helper.FechaTextoContrato(this.fecha_ingreso.toString()) + temporal[4];
  }

  click6Meses(){
    const fechaI = this.fecha_ingreso.toString();

    const fechaString = fechaI.includes('T') ? fechaI.split('T')[0] : fechaI;
    const fechaDate = parseISO(fechaString);
    const fechaFormateada = format(fechaDate, "yyyy-MMMM-dd", { locale: es } );

    this.TresMeses = false;
    this.indefinido = false;
    this.Otro = false;
    const date = new Date(fechaFormateada);
    this.returnFecha = this.addMonths(date,6);
    // console.log(this.returnFecha);
    // console.log(date);
    return this.returnFecha;
  }

  clickIndefinido(){
    // this.mostrarfecha = false;
    this.SeisMeses = false;
    this.TresMeses = false;
    this.Otro = false;
    this.duracion = 'Indefinido';
    var temporal = valores[10].valor.split("%");
    this.texto = temporal[0] + " " + this.numero_clausula + " " + temporal[2] + this.helper.FechaTextoContrato(this.fecha_ingreso.toString()) + temporal[4];
  }

  mostrar(evento: any) {
    //console.log(evento)
  }

  MostrarModal() {
    this.MostrarModalPDF = true
  }
  async GenerarPDF() {
    if (this.texto) {
      this.cargando = true;
      this.Datos.Clausula = this.texto;
      this.Datos.Trabajador = this.TrabajadorSeleccionado;
      this.Datos.NumeroClausula = this.numero_clausula;
      this.Datos.Ciudad = this.CiudadSeleccionada
      this.Datos.clausulaSeleccionada = this.clausula_seleccionada.nombre;
      this.src = await this.generador.generarPDF(this.Datos);

      setTimeout(() => {
        this.cargando = false;
      }, 1000);
      this.MostrarModal();  
    }else{
      this.alert.error_small('El número de la cláusula esta vacia y/o no se ha seleccionado la cláusula.');
    }
  }
  TraerDatos() {

    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        if (res.success) {
          this.lista_trabajadores = res.data.map(x => {
            x.nombreCompleto = x.nombres.toUpperCase() + ' ' + x.apellidos.toUpperCase();
            return x;
          });
        }else{
          this.alert.error_small('Ocurrio un error innesperado.');
          console.error(res.msg);
        }
      }
    );
    this.ciudadService.GetAll_ciudades().subscribe(
      (res) => {
        this.lista_ciudades = res.data;
      }
    );
    this.sucursalService.GetAll_sucursales().subscribe(
      (res) => {
        this.sucursales = res.data;
      }
    );
  }

  ObtenerTrabajador(event: any) {

    this.render = 1;
    this.fk_trabajador = event.value.rut;
    this.trabajadoresService.TraerTrabajadoresRut(this.fk_trabajador).subscribe(response => {
      this.fecha_ingreso = response.data[0].fecha_ingreso;
    })
    console.log(event);
  }
  ObtenerCiudad(event: any) {
    this.render = 1;
  }
  printpdf() {
    saveAs(this.src, 'anexo-contrato.pdf');
  }

  obtener_fecha_clausula(event: any) {
    var temporal = valores[5].valor.split("%");
    //console.log(temporal);
    this.texto = temporal[0] + "" + this.numero_clausula + " " + temporal[2] + this.helper.FechaTextoContrato(this.fecha_ingreso.toString()) + temporal[4] + " " + this.helper.FechaaTexto(event) + " " + temporal[6];
  }

  MostrarEjemplo() {
    this.ejemplo = true;
  }

  ObtenerClausula(event?: any) {
    this.idClausula = event ? event.value.id : this.idClausula;
    switch (this.idClausula) {
      case 1:
        this.mostrarfecha = false;
        var temporal = valores[0].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 2:
        this.mostrarfecha = false;
        var temporal = valores[1].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 3:
        this.mostrarfecha = false;
        var temporal = valores[2].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 4:
        this.mostrarfecha = false;
        var temporal = valores[3].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 5:
        this.mostrarfecha = false;
        var temporal = valores[4].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 6:
        this.mostrarfecha = true
        this.texto = valores[5].valor
        break;
      case 7:
        this.mostrarfecha = false;
        var temporal = valores[6].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 8:
        this.mostrarfecha = false;
        var temporal = valores[7].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 9:
        this.mostrarfecha = false;
        var temporal = valores[8].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2]
        break;
      case 10:
        this.mostrarfecha = false;
        var temporal = valores[9].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2];
        break;
      case 12:
        this.mostrarfecha = false;
        console.log(valores);
        var temporal = valores[11].valor.split("%");
        this.texto = temporal[0] + this.numero_clausula + " " + temporal[2];
        break;
      default:
        this.mostrarfecha = false;
        //console.log("esto no deberia mostrarse , hubo algun error ")
        break;
    }
  }
}
