<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
  Libro de Remuneracion
</h3>
<div class="x_content container-fluid overflow-hiden">
  <div class="row mb-0">
    <div class="col">
      <mat-form-field class="example-full-width " appearance="outline">
        <mat-label>Empresa Contratante</mat-label>
        <mat-select (selectionChange)="ObtenerEmpresaContratante($event)" required>
          <mat-option class="example-full-width" *ngFor="let empresa of empresas" [value]="empresa.id">
            {{empresa.nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
     <!-- Filtro para Sucursal -->
     <div class="col">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Sucursal</mat-label>
        <mat-select (selectionChange)="ObtenerSucursal($event)" required>
          <mat-option class="example-full-width" *ngFor="let sucursal of sucursales" [value]="sucursal.nombre">
            {{sucursal.nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col">
      <!-- SELECT PERIODO AÑO -->
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Periodo</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="datePeriodo" [max]="maxDatePeriodo" readonly>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (monthSelected)="chosenYearHandler($event, dp)"
          panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
      <!-- 
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Buscar Fecha</mat-label>
          <input matInput (keyup)="applyFilter($event)" #input placeholder="2023-07 ejemplo">
        </mat-form-field> 
      -->
    </div>

    <div class="col">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Formato</mat-label>
        <mat-select (selectionChange)="FormatoDescar($event)" required
          [disabled]="empresa === null || empresa === undefined">
          <mat-option class="example-full-width" *ngFor="let f of forma" [value]="f.id">
            {{f.valor}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-0">
    <div class="col col-12 text-center">
      <div *ngIf="renderPDF==1">
        <button style="color: white;background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button>
          Exportar a PDF
        </button>
      </div>
      <div *ngIf="renderCSV==1">
        <button style="color: white; background-color:rgb(9, 165, 232) " (click)="exportCsv()" mat-raised-button>
          Exportar LRE
        </button>
      </div>
      <div *ngIf="renderExcel==1">
        <button style="color: white; background-color:rgb(22, 117, 27) " (click)="obtenerDatosTabla()" mat-raised-button>
          Exportar a Excel
        </button>
      </div>
      <!-- <div *ngIf="renderExcelFinanzas==1">
        <button style="color: white; background-color:rgb(22, 117, 27) " (click)="obtenerDatosTablaFinanzas()" mat-raised-button>
          Exportar a Excel
        </button>
      </div> -->
    </div>
  </div>
</div>
<div class="container-fluid" *ngIf="dataSource">
  <mat-chip-list>
    <mat-chip color="primary" selected>
      Total registros: {{dataSource?.data?.length}}
    </mat-chip>
  </mat-chip-list>
</div>
<div *ngIf="nohaydatos === 0" class="overflow-auto">
  <table mat-table id="tabla" [dataSource]="dataSource" #tabla class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="padding-table" mat-cell *matCellDef="let element">
        <!-- <button mat-button class="button_color_primary" style="background-color:red;" (click)="eliminar(element.id)">
          <mat-icon style="color: white">delete</mat-icon>
        </button> -->
    </ng-container>
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Rut </th>
      <td mat-cell *matCellDef="let element"> {{ element.rut | formatoRut }} </td>
    </ng-container>
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
      <td mat-cell *matCellDef="let element"> {{ element.nombre | uppercase}} </td>
    </ng-container>
    <ng-container matColumnDef="d_trabajados">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dias Trabajados </th>
      <td mat-cell *matCellDef="let element"> {{ element.d_trabajados }} </td>
    </ng-container>
    <ng-container matColumnDef="sucursal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
      <td mat-cell *matCellDef="let element"> {{ element.sucur }} </td>
    </ng-container>
    <!--
      <ng-container matColumnDef="adelanto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Adelanto </th>
        <td mat-cell *matCellDef="let element"> {{ element.adelanto }} </td>
      </ng-container>
    -->
    <ng-container matColumnDef="horasextras">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Horas Extras </th>
      <td mat-cell *matCellDef="let element"> {{ element.horasextras }} </td>
    </ng-container>
    <ng-container matColumnDef="glegal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Gratificación Legal </th>
      <td mat-cell *matCellDef="let element"> {{ element.glegal }} </td>
    </ng-container>
    <ng-container matColumnDef="otroimp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Imponible </th>
      <td mat-cell *matCellDef="let element"> {{ element.otroimp ?? 0}} </td>
    </ng-container>
    <ng-container matColumnDef="totalimp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Imponible </th>
      <td mat-cell *matCellDef="let element"> {{ element.totalimp }} </td>
    </ng-container>
    <ng-container matColumnDef="afamiliar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Asignación Familiar </th>
      <td mat-cell *matCellDef="let element"> {{ element.asig_familiar }} </td>
    </ng-container>
    <ng-container matColumnDef="otronoimp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros no Imponible </th>
      <td mat-cell *matCellDef="let element"> {{ element.otronoimp ?? 0 }} </td>
    </ng-container>

    <ng-container matColumnDef="totalnoimp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total no Imponible </th>
      <td mat-cell *matCellDef="let element"> {{ element.totalnoimp }} </td>
    </ng-container>
    <ng-container matColumnDef="totalhaberes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Haberes </th>
      <td mat-cell *matCellDef="let element"> {{ element.totalhaberes }} </td>
    </ng-container>
    <ng-container matColumnDef="prevision">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Previsión </th>
      <td mat-cell *matCellDef="let element"> {{ element.prevision }} </td>
    </ng-container>
    <ng-container matColumnDef="salud">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Salud </th>
      <td mat-cell *matCellDef="let element"> {{ element.salud }} </td>
    </ng-container>

    <ng-container matColumnDef="scesantia">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Seguro Cesantía </th>
      <td mat-cell *matCellDef="let element"> {{ element.scesantia }} </td>
    </ng-container>
    <ng-container matColumnDef="otrodesclegales">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Descuentos Legales </th>
      <td mat-cell *matCellDef="let element"> {{ element.otrodesclegales }} </td>
    </ng-container>
    <ng-container matColumnDef="totaldesclegales">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Descuentos Legales </th>
      <td mat-cell *matCellDef="let element"> {{ element.totaldesclegales }} </td>
    </ng-container>
    <!--
      <ng-container matColumnDef="totaldescuentos">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> T. Descuentos  </th>
        <td mat-cell *matCellDef="let element"> {{ element.totaldescuentos }} </td>
      </ng-container> 
    -->
    <ng-container matColumnDef="impuestounico">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Impuesto Unico </th>
      <td mat-cell *matCellDef="let element"> {{ element.impuestounico }} </td>
    </ng-container>

    <ng-container matColumnDef="folio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Folio </th>
      <td mat-cell *matCellDef="let element"> {{ element.folio }} </td>
    </ng-container>
    <ng-container matColumnDef="totaldescuento">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Descuento </th>
      <td mat-cell *matCellDef="let element"> {{ element.totaldescuento }} </td>
    </ng-container>
    <ng-container matColumnDef="descvarios">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Descuentos Varios </th>
      <td mat-cell *matCellDef="let element"> {{ element.descvarios }} </td>
    </ng-container>
    <ng-container matColumnDef="liquido">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Liquido </th>
      <td mat-cell *matCellDef="let element"> {{ element.liquido }} </td>
    </ng-container>
    <ng-container matColumnDef="sbase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sueldo Base </th>
      <td mat-cell *matCellDef="let element"> {{ element.sbase }} </td>
    </ng-container>

    <!-- DATOS PARA FOMATO CSV-->
    <ng-container matColumnDef="fecha_ingreso">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Inicio contrato </th>
      <td mat-cell *matCellDef="let element" #fechaInicioContrato> {{ element.fecha_inicio_contratos }} </td>

    </ng-container>

    <ng-container matColumnDef="cod_region_prest_servicios">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Región </th>
      <td mat-cell *matCellDef="let element"> {{ element.cod_region_prest_servicios }} </td>

    </ng-container>
    <ng-container matColumnDef="cod_comuna_prest_servicios">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Comuna </th>
      <td mat-cell *matCellDef="let element"> {{ element.cod_comuna_prest_servicios }} </td>

    </ng-container>
    <ng-container matColumnDef="cod_tipo_jornada">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Jornada </th>
      <td mat-cell *matCellDef="let element"> {{ element.cod_tipo_jornada }} </td>

    </ng-container>
    <ng-container matColumnDef="cod_discapacidad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Discapacidad </th>
      <td mat-cell *matCellDef="let element"> {{ element.cod_discapacidad }} </td>
    </ng-container>

    <ng-container matColumnDef="jubilado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Jubilado </th>
      <td mat-cell *matCellDef="let element"> {{ element.jubilado }} </td>
    </ng-container>

    <ng-container matColumnDef="isapre_fonasa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo Isapre/Fonasa </th>
      <td mat-cell *matCellDef="let element"> {{ element.isapre_fonasa }} </td>
    </ng-container>
    <ng-container matColumnDef="cod_afp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codigo AFP </th>
      <td mat-cell *matCellDef="let element"> {{ element.cod_afp }} </td>
    </ng-container>
    <ng-container matColumnDef="apvi">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> APVI </th>
      <td mat-cell *matCellDef="let element"> {{ element.apvi }} </td>
    </ng-container>
    <ng-container matColumnDef="afc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> AFC </th>
      <td mat-cell *matCellDef="let element"> {{ element.afc }} </td>
    </ng-container>
    <ng-container matColumnDef="seguroInvalidez">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Seguro Invalidez </th>
      <td mat-cell *matCellDef="let element"> {{ element.seguroInvalidez }} </td>
    </ng-container>
    <ng-container matColumnDef="otrosdescuentosCSV">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Otros Descuentos </th>
      <td mat-cell *matCellDef="let element"> {{ element.otrosdescuentosCSV }} </td>
    </ng-container>
    <ng-container matColumnDef="totalaportesempleador">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Aportes Empleador </th>
      <td mat-cell *matCellDef="let element"> {{ element.totalaportesempleador }} </td>
    </ng-container>
    <ng-container matColumnDef="numeroCargas">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cargas </th>
      <td mat-cell *matCellDef="let element"> {{ element.num_cargas }} </td>
    </ng-container>
    <ng-container matColumnDef="tramoAsigFamiliar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Tramo Familiar </th>
      <td mat-cell *matCellDef="let element"> {{ element.tramo_asig_fam }} </td>
    </ng-container>
    <ng-container matColumnDef="numDiasLicencias">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dias Licencias </th>
      <td mat-cell *matCellDef="let element"> {{ element.num_dias_licencias }} </td>
    </ng-container>
    <ng-container matColumnDef="numDiasVacaciones">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dias Vacaciones </th>
      <td mat-cell *matCellDef="let element"> {{ element.num_dias_vacaciones }} </td>
    </ng-container>
    <ng-container matColumnDef="comision">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Comisión </th>
      <td mat-cell *matCellDef="let element"> {{ element.comision }} </td>
    </ng-container>
    <ng-container matColumnDef="bsemanacorrido">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> B. Semana Corrida </th>
      <td mat-cell *matCellDef="let element"> {{ element.bsemanacorrida }} </td>
    </ng-container>
    <ng-container matColumnDef="colacion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Colación </th>
      <td mat-cell *matCellDef="let element"> {{ element.colacion }} </td>
    </ng-container>
    <ng-container matColumnDef="movilizacion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Movilización </th>
      <td mat-cell *matCellDef="let element"> {{ element.movilizacion }} </td>
    </ng-container>
    <ng-container matColumnDef="viaticos">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Viáticos </th>
      <td mat-cell *matCellDef="let element"> {{ element.viaticos }} </td>
    </ng-container>
    <ng-container matColumnDef="retClaseMedia">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ret. Clase Media </th>
      <td mat-cell *matCellDef="let element"> {{ element.ret_clase_media }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="tr_trabajador"></tr>
  </table>
  <!--
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Seleccionar pagina de usuarios"
      class="overflow-hiden">
    </mat-paginator>
  -->
</div>