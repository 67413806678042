import { Component, OnInit } from '@angular/core';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Previsional } from 'src/app/shared/models/previsonal';

@Component({
  selector: 'app-indicadores-previsionales',
  templateUrl: './indicadores-previsionales.component.html',
  styleUrls: ['./indicadores-previsionales.component.css']
})
export class IndicadoresPrevisionalesComponent implements OnInit {
  intentosFetchPrevired: number = 0;
  previsional:Previsional;

  constructor(
    private alert: AlertHelper,
  ) { }

  ngOnInit(): void {
    this.cargarDatosPrevisionales();
  }

  // Obtiene los datos previsionales
  cargarDatosPrevisionales(){
    const hoy = new Date();
    const mes = hoy.getMonth() + 1;
    const anio = hoy.getFullYear();
    this.obtenerFetchPrevisional(mes, anio);
  }

  async obtenerFetchPrevisional(mes:number, year:number){
    this.intentosFetchPrevired++;
    const month =  mes < 10 ? '0'+mes : mes.toString();
    const data = await this.cargarDatosPrevired(month, year);
    if (!data.message) {
      this.previsional = data
      this.previsional.UFValPeriodoAnt = this.previsional.UFValPeriodoAnt.replace(',','.');
      this.previsional.UFValPeriodo = this.previsional.UFValPeriodo.replace(',','.');
    }else{
      if (this.intentosFetchPrevired > 1) {
        this.intentosFetchPrevired = 0;
        this.alert.errorAlert('Al parecer hay algun error de conexion de internet o con los datos.');
      }else{
        // SI EXISTE ERROR, VOLVER A INTENTAR, PERO CON EL MES ANTERIOR
        mes = mes - 1 === 0 ? 12 : mes - 1;
        year = mes -1 === 0 ? year - 1 : year;
        this.obtenerFetchPrevisional(mes, year);
      }
    }
  }

  async cargarDatosPrevired(month: string, year: number): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const result = await fetch('https://api.gael.cloud/general/public/previred/'+month+year);
        const data = await result.json();
        resolve(data);
      }, 2000);
    })
  }

}
