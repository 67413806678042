<div class="row col-12 pl-3">
  <h2>FILTROS</h2>
</div>
<p-toolbar>
  <div class="p-toolbar-group-left">
    <p-dropdown [options]="empresaFilter" [(ngModel)]="empresaSelect" [filter]="true" filterBy="value" (onChange)="FiltrarDatos()"
      [showClear]="true" placeholder="Todas las empresa" optionLabel="text" optionValue="value"styleClass="mr-2">
    </p-dropdown>
    <p-dropdown [options]="trabajadoresFilter" [(ngModel)]="trabajadorSelect" [filter]="true" filterBy="text" (onChange)="FiltrarDatos()"
      [showClear]="true" placeholder="Todos los trabajadores" optionLabel="text" optionValue="value" styleClass="mr-2">
    </p-dropdown>
    <p-dropdown [options]="estadoContratoFilter" [(ngModel)]="estadoContratoSelect" [filter]="true" filterBy="value" (onChange)="FiltrarDatos()"
      [showClear]="true" placeholder="Todos los estado de contrato" optionLabel="text" optionValue="value" styleClass="mr-2">
    </p-dropdown>
    <mat-checkbox class="mr-2" (change)="viewDatosFiniquitados()" 
      [(ngModel)]="viewFiniquitados" color="warn">
      Cargar finiquitados
    </mat-checkbox>
    <button pButton pRipple type="button" icon="pi pi-filter-slash" class="p-button-rounded p-button-danger" pTooltip="Quitar filtro" tooltipPosition="bottom" (click)="RestablecerFiltro()"></button>
  </div>
  <div class="col-md-2 text-md-right ml-auto">
    <mat-form-field appearance="outline">
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="applyFilter($event)" #input>
    </mat-form-field>
  </div>
</p-toolbar>


<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.rut | formatoRut}} </td>
    </ng-container>
    <ng-container matColumnDef="nombres">
        <th mat-header-cell *matHeaderCellDef> NOMBRE COMPLETO </th>
        <td mat-cell *matCellDef="let element"> {{element.nombres | uppercase}} {{element.apellidos | uppercase}} </td>
    </ng-container>

    <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef> EMPRESA</th>
        <td mat-cell *matCellDef="let element">{{ element.Empresa_contratante.nombre | uppercase}}</td>
    </ng-container>


    <ng-container matColumnDef="estado_contrato">
        <th mat-header-cell *matHeaderCellDef> ESTADO CONTRATO</th>
        <td mat-cell *matCellDef="let element"> {{ element.estado_contrato | uppercase}} </td>
    </ng-container>
    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button (click)="Mostrar(mostrar,element.rut)" matBadgeColor="accent" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
              <mat-icon style="color: white">archive</mat-icon>
          </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons>
</mat-paginator>

<!-- <mat-paginator
[pageSizeOptions]="[5, 10, 20]"
showFirstLastButtons
aria-label="Seleccionar pagina de usuarios">
</mat-paginator> -->

<!-- Aca empieza el modal -->
<ng-template style="border: 0px; z-index: 100" #mostrar let-modal2>
    <div class="modal-header" style="text-align: center">
        <mat-card-title>Haberes y Descuentos</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal2.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
        <app-listar-haberes-trabajador [rut]="rut_trabajador"></app-listar-haberes-trabajador>
    </div>
</ng-template>

