import { Injectable } from '@angular/core';
import { isThursday, parseISO } from 'date-fns';
import { formatRut, getLastDigitOfRut } from 'rutlib'
import { Firma } from 'src/app/shared/models/firmas.interface';
import { FirmasService } from '../../firmas.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenerarCartaAmonestacionService {
  pdfMake: any;
  firma: Firma;
  firmaBlanco: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABQAQAAAABtsSv+AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAAB3YoTpAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+gBEBQWLG7Vn/gAAAAUSURBVCjPY/gPAR8YRhmjjKHPAAAr3njv3+BpfAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wMS0xNlQyMDoyMjozNCswMDowMJTfWawAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDEtMTZUMjA6MjI6MzQrMDA6MDDlguEQAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI0LTAxLTE2VDIwOjIyOjQ0KzAwOjAwuFLJ1gAAAABJRU5ErkJggg==';
  base64Firma: string;

  constructor(
    private firmaService: FirmasService,
  ) { 
    this.base64Firma = '';
  }

  // CARGA LA LIBRERIA PDFMAKE
  async loadPDFMake() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  // GENERA LA CARTA DE AMONESTACION
  async generarCartaAmonestacion(objGenerarCarta: any) {
    await this.obtenerFirma(objGenerarCarta);
    await this.loadPDFMake();
    this.convertPDF(objGenerarCarta);
  }
  async obtenerFirma(objGenerarCarta: any) {
    try {
      const res = await this.firmaService.getFirma(objGenerarCarta.trabajador.Empresa_contratante.idfirma);
      if (res.success) {
        this.firma = res.data;
        if (this.firma.rutaImg) this.base64Firma = await this.getBase64ImageFromURL(this.firma.rutaImg);
        else this.base64Firma = this.firmaBlanco;
      }else{
        this.base64Firma = this.firmaBlanco;
      }
    } catch (error) {
      console.error(error);
      this.base64Firma = this.firmaBlanco;
    }
  }
  getBase64ImageFromURL(rutaImg: string): Promise<string> {
    const ruta = `${environment.API}${rutaImg}`;
    let img = new Image();
    return new Promise((resolve, reject) => {
      img.setAttribute("crossOrigin", "anonymous");
      img.src = ruta;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      }
      img.onerror = error => {
        console.log(error);
        reject(error);
      }
    })
  }
  convertPDF(objGenerarCarta: any) {
    const fechaCarta = parseISO(objGenerarCarta.carta.fecha);
    const nombreTrabajador = objGenerarCarta.trabajador.nombres.toUpperCase() + ' ' + objGenerarCarta.trabajador.apellidos.toUpperCase();

    const documento = {
      info: {
        title: 'Carta Amonestacion '+nombreTrabajador+'.pdf',
      },
      pageSize: 'A4',
      content: [
        {
          columns: [
            {
              width: '*',
              stack: [
                {
                  text: fechaCarta.toLocaleDateString('es-CL', { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' }),
                  alignment: 'right',
                }
              ]
            },
          ]
        },
        {
          columns: [
            {
              width: '*',
              margin: [0, 40, 0, 0],
              stack: [
                {
                  text: 'CARTA DE AMONESTACIÓN LABORAL',
                  alignment: 'center',
                  style: 'header'
                }
              ]
            },
          ]
        },
        {
          alignment: 'justify',
          columns: [
            {
              width: '*',
              margin: [0, 40, 0, 0],
              stack: [
                {
                  text: [{text: 'Sr.: '}, {text: nombreTrabajador, bold: true}],
                  alignment: 'left',
                },
                {
                  text: 'CNI N° '+this.formatearRut(objGenerarCarta.trabajador.rut),
                  alignment: 'left',
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'Presente: ',
                  alignment: 'left',
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'Junto con saludarle cordialmente, por medio de la presente, en cumplimiento a la legislación vigente y el reglamento interno de orden, higiene y seguridad, en su capitulo XVI, se le comunica a usted que, con esta fecha, se ha decidido amonestarla por escrito, ello como consecuencia de la falta que se señala a continuación: ',
                },
                {
                  margin: [0, 20, 0, 0],
                  text: [{text: 'Se describen en este documento, '}, {text: objGenerarCarta.descripcion}],
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'Esta conducta no será permitida nuevamente, ya que afectan el correcto desarrollo de su labor y adicional a esto genera un malestar para su empleador. Esperando rectifique su actuar y que situaciones de esta naturaleza no vuelvan a ocurrir.'
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'Debido a lo anterior, se le amonesta de forma escrita ya que esta conducta, no será permitida nuevamente y de ser reiterada, se procederá con el cumplimiento de la normativa legal vigente, el contrato de trabajo suscrito y el reglamento interno de orden, higiene y de seguridad, en proporción a la gravedad de los hechos, pudiendo aplicarse medidas más gravosas e incluso poner término al contrato de trabajo, ya que afectan el correcto desarrollo de su labor y del personal que depende de ello, todo lo anterior con el fin que rectifique su actuar y mantengamos una relación laboral pacífica y sin inconvenientes.'
                },
                {
                  margin: [0, 20, 0, 0],
                  text: 'Sin otro particular, saluda atentamente a Ud.'
                },

              ]
            },
          ]
        },
        {
          columns: [
            {
              alignment: 'center',
              width: '*',
              margin: [0, 100, 0, 0],
              stack: [
                {
                  // IMAGE 64 BLANCA (SOLO PARA OCUPAR ESPACIO EN EL DOCUMENTO)
                  image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABQAQAAAABtsSv+AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAAB3YoTpAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+gBEBQWLG7Vn/gAAAAUSURBVCjPY/gPAR8YRhmjjKHPAAAr3njv3+BpfAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wMS0xNlQyMDoyMjozNCswMDowMJTfWawAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDEtMTZUMjA6MjI6MzQrMDA6MDDlguEQAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI0LTAxLTE2VDIwOjIyOjQ0KzAwOjAwuFLJ1gAAAABJRU5ErkJggg=='
                },
                { 
                  canvas: [
                    { type: 'line', x1: 0, y1: 0, x2: 200, y2: 0 }
                  ] 
                },
                {
                  margin: [0, 15, 0, 0],
                  text: nombreTrabajador,
                  alignment: 'center',
                },
                {
                  text: this.formatearRut(objGenerarCarta.trabajador.rut),
                  alignment: 'center',
                },
                {
                  text: 'TRABAJADOR',
                  alignment: 'center',
                },
              ]
            },
            {
              alignment: 'center',
              width: '*',
              margin: [0, 100, 0, 0],
              stack: [
                {
                  image: this.base64Firma??this.firmaBlanco,
                  height: 80,
                },
                { 
                  canvas: [
                    { type: 'line', x1: 0, y1: 0, x2: 200, y2: 0 }
                  ] 
                },
                {
                  margin: [0, 15, 0, 0],
                  text: objGenerarCarta.trabajador.Empresa_contratante.nombre,
                  alignment: 'center',
                },
                {
                  text: objGenerarCarta.trabajador.Empresa_contratante.run,
                  alignment: 'center',
                },
                {
                  text: 'EMPLEADOR',
                  alignment: 'center',
                },
              ]
            },
          ],
        },
        {
          columns: [
            {
              alignment: 'left',
              width: 'auto',
              margin: [0, 40, 0, 0],
              stack: [
                {
                  text: 'C.C: Inspección del Trabajo',
                  alignment: 'center',
                },
              ]
            },
          ]
        }
      ],
      defaultStyle: {
        lineHeight: 1.5,
      },
      styles: {
        header: {
          fontSize: 12,
          bold: true,
        },
        headerTable: {
          fontSize: 11,
          bold: true,
        },
        textTable: {
          fontSize: 11,
          bold: false
        }
      }
    }
    this.pdfMake.createPdf(documento).open();
  }
  formatearRut(rut: number): string {
    // OBTENER TODOS LOS DIGITOS, MENOS EL DIGITO VERIFICADOR
    const digitos = parseInt(rut.toString().slice(0, -1));
    // OBTENER EL DIGITO VERIFICADOR
    const digitoVerificador = getLastDigitOfRut(digitos);
    // FORMATEAR EL RUT
    const rutString = digitos + digitoVerificador;
    return formatRut(rutString);
  }
}
