<div class="animate__animated animate__fadeInRight">
  <br />

  <div class="col">
    <mat-card class="mat_card_general">
      <h1 class>Modulo de Prestamos</h1>
    </mat-card>
  </div>

  <br />
  <mat-card class="mat-elevation-z5 mat_card_general_content">
    <div class="row d-flex">
      <div class="col-4 mr-auto">
        <app-agregar-prestamos (onDebounce)="actualizarTabla($event)" [list_prestamos]="prestamos">
        </app-agregar-prestamos>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Buscar</mat-label>
          <input matInput [(ngModel)]="textoBuscar" (keyup)="obtenerTextoBuscar($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <app-lista-prestamos (onDebounce)="actualizarTabla($event)"
      [prestamos_list]="prestamos"
      [textoBuscar]="textoBuscar">
    </app-lista-prestamos>
  </mat-card>
  <hr />
</div>