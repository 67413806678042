import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import pdfFonts from 'pdfmake/build/vfs_fonts';
const pdfMake = require('pdfmake/build/pdfmake.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PrestamosService } from 'src/app/pages/services/prestamos.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Prestamos } from 'src/app/shared/models/prestamos.interface';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { TipoPrestamo } from 'src/app/shared/models/tipoprestamo.interface';
import { TiposPrestamoService } from 'src/app/pages/services/tipoprestamo.service';
import { Cuotas } from 'src/app/shared/models/cuotas.interface';
const conversor = require('conversor-numero-a-letras-es-ar');
import Swal from 'sweetalert2';
import { HelperService } from 'src/app/pages/services/helper/helper.service';


@Component({
  selector: 'app-lista-prestamos',
  templateUrl: './lista-prestamos.component.html',
  styleUrls: ['./lista-prestamos.component.css']
})

export class ListaPrestamosComponent implements OnInit {
  @Input() prestamos_list: Prestamos[] = [];
  @Input() textoBuscar: string;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();

  ClaseConversor = conversor.conversorNumerosALetras;
  miConversor = new this.ClaseConversor();

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  addressForm = this.fb.group({
    fechaPrestamo: [null, Validators.required]
  });

  // VARIABLES TOOLTIPS: NUBE FLOTANTE DE INFORMACION
  POSITION:string = 'bottom';
  SHOW_MS:number = 300; // MILISEGUNDOS PARA MOSTRAR EL TOOLTIP 

  numCuotas: number = 1;
  cuotasPagadas: number = 0;
  montoPrestamo: number = 0;
  fechaPrestamo: string = new Date().toISOString();
  //ME SERVIRA PARA SABER SI EL TRABAJADOR ESTA AGREGANDO O EDITANDO
  //0 = AGREGAR : 1 = EDITAR
  accion:number = 0; 

  // VARIABLES PARA ACTUALIZAR PRESTAMOS Y CUOTAS 
  cuotasDelete: Cuotas[] = [];
  cuotasAdd: Cuotas[] = [];
  cuotas:Cuotas[] = [];

  tiposprestamo: TipoPrestamo[] = [];
  update_prestamos:Prestamos;
  debouncer: Subject<boolean> = new Subject();
  displayedColumns: string[] = ['nombreTrabajador', 'observacion', 'autoriza', 'fechaPrestamo', 'cuotas', 'montoTotal', 'estado', 'actions'];
  dataSource = new MatTableDataSource<Prestamos>(this.prestamos_list);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  idPrestamo: any;
  rutTrabajador: any;
  disabledGuardar: boolean = true;
  muestraImprimir: boolean = false;
  muestraDescarga: boolean = false;
  modalReference = null;


  constructor(
    private fb: FormBuilder,
    private alert: AlertHelper,
    private prestamoService: PrestamosService,
    private modalService: NgbModal,
    private TrabajadoresService: TrabajadoresService,
    private auth: AuthService,
    private tiposService: TiposPrestamoService,
    private helper: HelperService,
  ) {
    this.uploader.onCompleteAll = () => {
      //console.log('termino de enviar ');
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.disabledGuardar = false;
      //console.log(file);
      //console.log(this.uploader.queue);
    };

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user = this.auth.ObtenerinfoToken();
      form.append('fk_trabajador', this.rutTrabajador);
      form.append('fk_prestamo', this.idPrestamo);
      form.append('userAt', user.nombre);
    };
  }

  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/prestamo/documento`,
    itemAlias: 'prestamo',
    additionalParameter: {

    }
  });

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  deshabilitaGuardar() {
    this.disabledGuardar = true;
  }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })

    this.tiposService.TraerTiposPrestamo().subscribe(res => {
      if (res.success) {
        this.tiposprestamo = res.data.map(x => ({ id: x.id, nombre: x.nombre, descripcion: x.descripcion }));
      } else {
        this.alert.error_small('Hubo un error innesperado al obtener los tipos de prestamo');
        console.error(res.msg);
      }
    });

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        })

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Ocurrió un error al subir archivo',
        })
      }
    }
  }

  removeFileFromQueue() {
    for (var i = 0; i < this.uploader.queue.length; i++) {
      this.uploader.queue[i].remove();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.prestamos_list != undefined) {
      this.dataSource = new MatTableDataSource<Prestamos>(this.prestamos_list);

      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const filterLower = filter.toLocaleLowerCase().split(' ');
        // console.log({data});
        const nombreTrabajador = data.Trabajador.nombres.toLocaleLowerCase() + ' ' +data.Trabajador.apellidos.toLocaleLowerCase();
        const autoriza = data.autoriza.toLocaleLowerCase();
        const observacion = data.observacion.toLocaleLowerCase();
        const rutTrabajador = data.fk_trabajador.toString();
        const monto = data.montoTotal.toString();
        
        return filterLower.every(
          x => nombreTrabajador.includes(x) || autoriza.includes(x) 
          || observacion.includes(x) || rutTrabajador.includes(x) 
          || monto.includes(x), 
        );
      }

      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
    }
    if (changes.textoBuscar) {
      const filterValue = this.textoBuscar;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  limpiar_prestamos() {
    this.update_prestamos.fechaPrestamo = ""
    this.update_prestamos.autoriza = ""
    this.update_prestamos.observacion = ""
    this.update_prestamos.fechaPrestamo = ""
    this.update_prestamos.cuotas = 0;
    this.update_prestamos.montoTotal = 0;

    this.cuotasDelete = [];
  }

  actualizar_prestamo() {
    const prestamo: any = this.update_prestamos;
    prestamo.cuotasAdd = this.cuotasAdd;
    prestamo.cuotasDelete = this.cuotasDelete;

    this.prestamoService.actualizar_prestamo(this.update_prestamos)
      .subscribe(res => {
        if (res.success == true) {
          this.alert.success_small(res.msg!)
          //this.modalService.dismissAll();
          this.cerrarModal();
          this.limpiar_prestamos();
          this.debouncer.next(true);
        } else {
          this.alert.error_small(res.msg!)
        }
    })
  }

  // Actualizar prestamo y cuotas
  open(content: any, id: number) {
    this.prestamoService.Obtener_prestamo(id).subscribe(res => {
      if (res.success) {
        this.update_prestamos = res.data;
        // console.log({...this.update_prestamos});
        this.montoPrestamo = this.update_prestamos.montoTotal;
        this.numCuotas = this.update_prestamos.cuotas;
        this.fechaPrestamo = this.update_prestamos.fechaPrestamo;
        this.cuotasPagadas = this.update_prestamos.Cuotas.reduce((a:number, e:Cuotas) => {
          return e.estado ? a + 1 : a;
        }, 0);
        this.accion = 1;
        this.modalReference = this.modalService.open(content, { size: 'lg' });
      }
    })
  }

  obtenerNumCuotas() {
    this.verificarDatos();
    if (this.update_prestamos.cuotas) {
      this.accion = 1;
      this.numCuotas = this.update_prestamos.cuotas;
      console.log(this.numCuotas)
    }
  }
  obtenerMonto() {
    if (this.update_prestamos.montoTotal) {
      this.accion = 1;
      this.montoPrestamo = this.update_prestamos.montoTotal;  
    }
    
  }
  obtenerFechaPrestamo() {
    if (this.update_prestamos.fechaPrestamo) {
      this.accion = 1;
      this.fechaPrestamo = this.update_prestamos.fechaPrestamo;
    }
  }
  obtenerCuotasPagadas(event) {
    this.verificarDatos();
    if (event.target.value !== '') {
      this.accion = 1;
      this.cuotasPagadas = parseInt(event.target.value);
      // console.log(this.cuotasPagadas);
    }
  }
  // CUOTAS RECIBIDAS DESDE EL COMPONENTE FORM-CUOTAS
  recibirCuotas(cuotas: Cuotas[]) {
    this.update_prestamos.Cuotas = [...cuotas];
    this.cuotasAdd = this.update_prestamos.Cuotas.filter((x:Cuotas) => !x.id);
    
    this.cuotasPagadas = cuotas.reduce((a:number, e:Cuotas) => e.estado ? a + 1 : a, 0);
  }
  // CUOTAS ELIMINADAS RECIBIDAS DESDE EL COMPONENTE FORM CUOTAS
  recibirCuotasEliminadas(cuotasEliminadas: Cuotas[]){
    this.cuotasDelete.push(...cuotasEliminadas);
    this.cuotasPagadas = this.update_prestamos.Cuotas.reduce((a:number, e:Cuotas) => e.estado ? a + 1 : a, 0);
  }

  verificarDatos(){
    if (this.cuotasPagadas > this.numCuotas) {
      this.cuotasPagadas = this.numCuotas;
    }
    if (this.numCuotas === 0 || !this.numCuotas) {
      this.numCuotas = 1;
    }
  }

  eliminar_prestamo(content: any, id: number, prestamo: Prestamos) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el Préstamo seleccionado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.prestamoService.Eliminar_prestamo(id, prestamo).subscribe(res => {
          if (res.success) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          }else{
            this.alert.error_small('Ocurrio un error al eliminar el prestamo.');
            console.error(res.msg);
          }
        })
      }
    })
  }

  validarPrestamo() {
    if (!confirm('Actualizar prestamo y sus cuotas\n¿Desea continuar con el proceso?')) return;
    let msg = '';
    if (this.update_prestamos.fk_trabajador == null || this.update_prestamos.fk_trabajador == undefined) {
      msg = 'Rut no válido';
      this.alert.error_small(msg);
      return;
    }
    else if (this.update_prestamos.autoriza.trim().length < 3 || this.update_prestamos.autoriza.trim().length > 150) {
      msg = 'Descripción de quien autoriza no válida';
      this.alert.error_small(msg);
      return;
    }
    else if (this.update_prestamos.observacion.trim().length > 1 && this.update_prestamos.observacion.trim().length > 200) {
      msg = 'Observación no válida';
      this.alert.error_small(msg);
      return;
    }
    else if (this.update_prestamos.fechaPrestamo == undefined || this.update_prestamos.fechaPrestamo == '' || this.update_prestamos.fechaPrestamo == null) {
      msg = 'Fecha Préstamo no válida';
      this.alert.error_small(msg);
      return;
    }
    else if (this.update_prestamos.cuotas < 1 || this.update_prestamos.cuotas > 999) {
      msg = 'Número de cuotas no válido';
      this.alert.error_small(msg);
      return;
    }
    else if (this.update_prestamos.montoTotal < 1 || this.update_prestamos.montoTotal > 999999999) {
      msg = 'Monto del crédto no válido';
      this.alert.error_small(msg);
      return;
    }
    else {
      this.update_prestamos.autoriza.toUpperCase();
      this.update_prestamos.observacion.toUpperCase();
      this.actualizar_prestamo();
    }
  }

  ImprimirDocumento(datos: any) {
    console.log({datos});
    this.TrabajadoresService.TraerTrabajadoresRut(datos.Trabajador.rut).subscribe(res => {
      if (res.success) {
        this.generarPdf(datos, res.data[0]);
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Ocurrió un error al generar el archivo'
        })
      }

    });
  }

  generarPdf(datos: any, trabajador: any) {
    const montoEnLetras = this.miConversor.convertToText(datos.montoTotal),
      MESES = [
        "",
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

    var folio = datos.folio.toString(),
      datePipe = new DatePipe('en-CL'),
      fechaFormteada = datePipe.transform(datos.fechaPrestamo, 'dd/MM/yyyy'),
      dia = parseInt(datePipe.transform(datos.fechaPrestamo, 'dd')),
      mes = parseInt(datePipe.transform(datos.fechaPrestamo, 'MM')),
      anio = parseInt(datePipe.transform(datos.fechaPrestamo, 'yyyy'));
    folio = folio.padStart(5, '00000');

    const nombresTrabajador = trabajador.nombres + ' ' + trabajador.apellidos;
    const tipoPrestamo = this.tiposprestamo.find(x => x.id === datos.idtipo)?.nombre??'Sin tipo';
    const cuotas: Cuotas[] = datos.Cuotas;

    var documentDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      content: [
        { text: 'FOLIO: ' + folio, alignment: 'right', bold: true, fontSize: 10 },

        { text: 'COMPROBANTE PRÉSTAMO EMPRESARIAL', style: 'header' },

        { text: '\nSolicitante\n', bold: true, fontSize: 12 },

        {
          style: 'tableExample',
          table: {
            widths: ['auto', '*'],
            body: [

              [{ text: 'Nombre Completo', fontSize: 10 }, { text: nombresTrabajador.toUpperCase(), fontSize: 10 }],
              [{ text: 'RUT', fontSize: 10 }, { text: this.helper.formatoRut(trabajador.rut), fontSize: 10 }],
              [{ text: 'Cargo', fontSize: 10 }, { text: trabajador.Cargo.nombre, fontSize: 10 }]
            ]
          },
        },
        // Se deja constancia que entre al empresa GF SERVICES SpA., Rut N° 76.438.914-K, representada 
        // legalmente por don Miguel Eduardo Vargas Garrido, cédula nacional de identidad N°17.886.328-2, 
        // y al trabajadora doña XIMENA ARACELLY ARAYA GUERRA CNI. N°. 19.377.459-8, se pacta el siguiente préstamo:
        { text: [
          '\n', 
          'Se deja constancia que entre al empresa ',{ text: trabajador.Empresa_contratante.nombre.toUpperCase(), bold: true }, ' RUT N° ', {text: trabajador.Empresa_contratante.run, bold: true}, 
          ', representada legalmente por don ',{text: trabajador.Empresa_contratante.representante.toUpperCase(), bold: true } , ' cédula nacional de identidad ', {text: trabajador.Empresa_contratante.cedula, bold: true},
          ', y al trabajador/a don/a ', {text: nombresTrabajador.toUpperCase() + ' CNI N° '+this.helper.formatoRut(trabajador.rut), bold: true},', se pacta el siguiente  préstamo empresarial.\n\n'],
          fontSize: 10,
          alignment: 'justify' },

        {
          style: 'tableExample',
          table: {
            widths: ['auto', '*'],
            body: [
              [{ text: 'Fecha solicitud del préstamo', fontSize: 10 }, { text: fechaFormteada, fontSize: 10 }],
              [{ text: 'Monto solicitado', fontSize: 10 }, { text: '$' + datos.montoTotal.toLocaleString('es-CL'), fontSize: 10 }],
              [{ text: 'Tipo préstamo', fontSize: 10 }, { text: tipoPrestamo.toUpperCase(), fontSize: 10 }],
              [{ text: 'Cantidad de cuotas', fontSize: 10 }, { text: datos.cuotas, fontSize: 10 }]
            ]
          },
        },
        { text: '\nDetalle de cuotas\n', bold: true, fontSize: 11 },
        {
          style: 'tableExample',
          table: {
            widths: ['auto', 'auto', 100],
            body: this.generadetalleCuotas(cuotas, datos)
          },
        },

        { text: ['\nSe entrega en este acto, el día ', { text: dia + ' de ' + MESES[mes] + ' de ' + anio, bold: true }, ', la cantidad de ', { text: '$' + datos.montoTotal.toLocaleString('es-CL') + ' (' + montoEnLetras + ' pesos)', bold: true }, ' los cuales se descontarán de su sueldo en ', { text: datos.cuotas, bold: true }, ' cuotas', { text: ' a partir de la remuneración del mes de ' + MESES[mes], bold: true }, ' quien lo recibe firmando de conformidad todos los involucrados.'], fontSize: 10, alignment: 'justify' },
        { text:  '\n'},
        { text: 'Para efectos en que el contrato de trabajo termine antes del plazo por cualquier causal, el trabajador autoriza expresamente a su empleador a realizar el descuento del saldo pendiente, del respectivo Finiquito de trabajo', fontSize: 10, alignment: 'justify' },
        { text: '\n\n\n\n\n' },

        {
          style: 'tableExample',
          table: {
            widths: [290, 250],
            headerRows: 1,
            body: [
              [{ text: '', style: 'tableHeader' }, { text: '', style: 'tableHeader' }],
              ['________________________________________', '________________________________________'],
              [{ text: trabajador.nombres.toUpperCase() + ' ' + trabajador.apellidos.toUpperCase(), bold: true, fontSize: 10 }, { text: trabajador.Empresa_contratante.nombre.toUpperCase(), bold: true, fontSize: 10 }],
              [{ text: 'R.U.T N° ' + this.helper.formatoRut(trabajador.rut), bold: true, fontSize: 10 }, { text: 'R.U.T N° ' + trabajador.Empresa_contratante.run, bold: true, fontSize: 10 }],
              [{ text: 'TRABAJADOR', bold: true, fontSize: 10 }, { text: 'EMPLEADOR', bold: true, fontSize: 10 }],
            ]
          },
          layout: 'noBorders'
        },
      ],
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        }
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  }

  transform(value: any) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  generadetalleCuotas(cuotas: Cuotas[], prestamo: Prestamos) {
    const detalle = [],
      datePipe = new DatePipe('en-CL');
    
    detalle.push([{ text: 'N° Cuota', fontSize: 10 }, { text: 'Fecha', fontSize: 10 }, { text: 'Monto', fontSize: 10 }]);

    for (let n = 0; n < cuotas.length; n++) {
      const cuota = cuotas[n];
      const detalleCuota = [{ text: cuota.numcuota, fontSize: 10 }, { text: datePipe.transform(cuota.fechacuota, 'dd/MM/yyyy'), fontSize: 10 }, { text: '$'+this.helper.formatoCLP(cuota.montocuota), 
        fontSize: 10, alignment: 'right' }];
      detalle.push(detalleCuota);
    }

    console.log({detalle});
    return detalle
  }

  SubirDocumento(content: any, rut: any, id: any) {
    this.removeFileFromQueue();
    this.rutTrabajador = rut;
    this.idPrestamo = id;
    this.modalReference = this.modalService.open(content, { size: 'lg' });
  }


  async guardarDocumentoFirmado() {
    this.uploader.uploadAll();
    this.debouncer.next(true);
    //this.modalService.dismissAll();
    this.cerrarModal();
  }

  DescargarDocumento(idPrestamo: any) {
    webPreferences: {
      webSecurity: false
    }
    this.prestamoService.TraerArchivo(idPrestamo).subscribe(
      (res) => {
        window.open(`${environment.API}/prestamos/` + res.data.ruta);
      }
    );
  }

  cerrarModal() {
    this.modalReference.close();
    this.modalReference = null;
    this.cuotasDelete = [];
  }

}
