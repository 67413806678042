import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';

import { Firma } from 'src/app/shared/models/firmas.interface';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-firmas',
  templateUrl: './agregar-firmas.component.html',
  styleUrls: ['./agregar-firmas.component.css']
})
export class AgregarFirmasComponent implements OnInit {
  @Output() actualizarTabla: EventEmitter<boolean> = new EventEmitter();
  // UPLOADER
  uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/firmas/crear`,
    itemAlias: 'firma',
  })
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  fileSrc: any;

  firmaAdd: Firma = {
    nombre: '',
    rutaImg: '',
    eliminado: false,
  }

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
  ) {
    this.uploader.onAfterAddingFile = (file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileSrc = e.target.result;
      };
      reader.readAsDataURL(file._file);
      file.withCredentials = false;
    };
    // VARIABLES PARA EL DROP FILE
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    // SE CREA EL FORMULARIO PARA MANDARLO AL BACKEND
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('nombre', this.firmaAdd.nombre);
      form.append('rutaImg', '');
    };
    // SI LA SUBIDA SALE CORRECTA
    this.uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
      this.alert.success_small('Firma agregada correctamente.');
      this.actualizarTabla.emit(true);
      this.modalService.dismissAll();
    }
    // SI LA SUBIDA SALE MAL
    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.alert.error_small('Error al agregar firma.');
    }
    // SE COMPLETA LA SUBIDA (CON ERRORES O SIN ERRORES)
    this.uploader.onCompleteAll = () => {
      this.limpiarformulario();
      // Swal.close();
    }
  }
  ngOnInit(): void {
  }
  // METODOS PARA EL DROP FILE
  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  open(content: any){
    const ref = this.modalService.open(content, {size: 'lg'});
    ref.result.then(() => {
      this.limpiarformulario();      
    },
    () => {
      this.limpiarformulario();      
    })
  }

  async AgregarFirma(){
    if (this.validarFormulario()) {
      Swal.fire(
        { title: 'Espere', text: 'Subiendo firma...', icon: 'info', allowOutsideClick: false }
      )
      Swal.showLoading();
      this.uploader.uploadAll();
    }else{
      this.alert.error_small('Nombre de la firma esta vacio.');
    }
  }
  validarFormulario(): boolean {
    return this.firmaAdd.nombre.trim() !== '';
  }

  limpiarformulario(){
    // LIMPIAR INPUT FILE
    (document.getElementById('fileFirma') as HTMLInputElement).value = '';
    this.uploader.clearQueue();
    // LIMPIAR FORMULARIO
    this.firmaAdd.nombre = '';
    this.firmaAdd.rutaImg = '';
    this.fileSrc = undefined;
  }
}