import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainGestorasistenciaComponent } from './main-gestorasistencia/main-gestorasistencia.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrimeNgModule } from 'src/app/PrimeNg.module'; 

import { MatSortModule } from '@angular/material/sort';
import { AgregarGestorasistenciaComponent } from './agregar-gestorasistencia/agregar-gestorasistencia.component';
import { ListaGestorasistenciaComponent } from './lista-gestorasistencia/lista-gestorasistencia.component';


import { DragDropModule } from '@angular/cdk/drag-drop';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

///



// // ----------------------------------------------------------------
// //                                                                                            
//                               jjjj                  
//                              j::::j                 
//                               jjjj                  
                                                    
//    ooooooooooo              jjjjjjj   ooooooooooo   
//  oo:::::::::::oo            j:::::j oo:::::::::::oo 
// o:::::::::::::::o            j::::jo:::::::::::::::o
// o:::::ooooo:::::o            j::::jo:::::ooooo:::::o
// o::::o     o::::o            j::::jo::::o     o::::o
// o::::o     o::::o            j::::jo::::o     o::::o
// o::::o     o::::o            j::::jo::::o     o::::o
// o::::o     o::::o            j::::jo::::o     o::::o
// o:::::ooooo:::::o            j::::jo:::::ooooo:::::o
// o:::::::::::::::o            j::::jo:::::::::::::::o
//  oo:::::::::::oo             j::::j oo:::::::::::oo 
//    ooooooooooo               j::::j   ooooooooooo   
//                              j::::j                 
//                    jjjj      j::::j                 
//                   j::::jj   j:::::j                 
//                   j::::::jjj::::::j                 
//                    jj::::::::::::j                  
//                      jjj::::::jjj                   
//                         jjjjjj                      


//si no se importa el material-moment-adapter la fecha cuando la traes te quita un dia
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ScrollingModule } from '@angular/cdk/scrolling';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

@NgModule({
  declarations: [
    MainGestorasistenciaComponent,
    AgregarGestorasistenciaComponent,
    ListaGestorasistenciaComponent,
  
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatDatepickerModule,
    MatSortModule,
    FullCalendarModule,
    MatMomentDateModule,
    ScrollingModule,
    DragDropModule,
    PrimeNgModule,
    NgbModule,
    ReactiveFormsModule,
  ],
  exports:[
    MainGestorasistenciaComponent,
    AgregarGestorasistenciaComponent,
    ListaGestorasistenciaComponent
    
  ]
 
})
export class GestorasistenciaModule { }











