

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, Subject } from 'rxjs';
import { BancoService } from 'src/app/pages/services/banco/banco.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';


@Component({
  selector: 'app-agregar-banco',
  templateUrl: './agregar-banco.component.html',
  styleUrls: ['./agregar-banco.component.css']
})
export class AgregarBancoComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
 
  debouncer: Subject<boolean> = new Subject();

  constructor(
    private modalService: NgbModal,
    private bancoService: BancoService,
    private alert: AlertHelper,
    
    ) { }

  open(content:any) {
    this.modalService.open(content);

  }

  bancos: any = {

    id:0,
  
    nombre:'',

    urlLogo: '',
    
  };


  enviar() {
    

    this.bancoService.crear(this.bancos).subscribe((res) => {

          if (res.success == true) {
  
            this.alert.success_small(res.msg!)    
            this.modalService.dismissAll();
            this.debouncer.next( true );
            this.ngOnInit();
           
          }else{
  
            this.alert.error_small(res.msg!)
  
          }
  
          this.debouncer.next( true );
        
  
  
      })

 


  }


  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });
  }

}
