import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudesvacacionesService {

  constructor(
    private http: HttpClient
  ) { }

    getAllSolicitudes(){
      return this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudvacaciones/`);
    }

    TraerSolicitud(id:any): Observable<RequestResponse> {
      return this.http
      .get<RequestResponse >(`${environment.API}/rrhh/solicitudvacaciones/buscarPorId/${id}`)
      .pipe(
        map((res:RequestResponse) =>{ 
          return res;
        }),
        catchError((err)=> this.handlerError(err))
      );
    }

    updateEstadoSolicitud(id:number, data:any): Observable<RequestResponse>{
      return this.http
      .put<RequestResponse>(`${environment.API}/rrhh/solicitudvacaciones/actualizar/${id}`, data)
      .pipe(
        map((res:RequestResponse) =>{
          return res;
        }),
      );
    }

    async bulkCreateAprobaciones(aprobaciones: any[]): Promise<RequestResponse> {
      console.log({aprobaciones});
      try {
        return await this.http.post<RequestResponse>(`${environment.API}/rrhh/solicitudvacaciones/bulkCreateAprobaciones`, aprobaciones).toPromise();
      } catch (error) {
        console.error(error);
        return {success: false, msg: 'Hubo un error al crear las aprobaciones.'} as RequestResponse;
      }
    }

    generarSolicitud(data: any){
      return this.http
      .post<RequestResponse>(`${environment.API}/rrhh/solicitudvacaciones/solicitarVacaciones/`, data)
      .pipe(
        map((res:RequestResponse) =>{
          return res;
        }),
      );
    }

    private handlerError(err: any):Observable<never>{

      let errorMessage = "";
      if (err) {
        errorMessage = `Error: ${err.error.msg}`;
      }
      // this.alert.error_small(err.error.msg);
      console.error(err);
      return throwError(errorMessage);
    }

}
