<h1 mat-dialog-title class="dialog-title">Subir Documento Permiso</h1>
<div mat-dialog-content class="dialog-content">
  <form class="dialog-form">
    <div class="file-input-container">
      <input type="file" (change)="onFileSelected($event)" class="file-input" />
    </div>
  </form>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button (click)="onNoClick()" class="action-button">Cancelar</button>
  <button mat-button (click)="upload()" class="action-button">Subir</button>
</div>
