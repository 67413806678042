<div class="modal-body">
    
    <table class="example-full-width" cellspacing="0">
      <tr>
          
        <mat-card> <h2>Porfavor, Asegurese de Subir el Documento con el Folio <strong>{{this.trabajador.folio}} </strong> </h2></mat-card>

      </tr>
    
    </table>

    <p>
        <label>Subir Comprobante de Vacaciones  &nbsp;</label>
        <input mat-input  required type="file" ng2FileSelect [uploader]="uploader"  />
    </p>

    <div>
        Estado de Carga
        <div class="progress">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
        </div>
    </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()"> <span aria-hidden="true">Cancelar</span> </button>       
        <button type="button" (click)="guardar()"  [disabled]="!uploader.getNotUploadedItems().length" class="btn btn-outline-success">Guardar</button>           
    </div>