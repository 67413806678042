<h3 style="text-align:center;font-size:1.77rem;font-weight:500">
    Transferencias
</h3>

<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef> RUT </th>
        <td mat-cell *matCellDef="let element"> {{ element.fk_trabajador | formatoRut }} </td>
    </ng-container>

    <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> FECHA</th>
        <td mat-cell *matCellDef="let element">{{ element.fecha | date:'dd/MM/yyyy' }}</td>
    </ng-container>


    <ng-container matColumnDef="motivo">
        <th mat-header-cell *matHeaderCellDef> MOTIVO </th>
        <td mat-cell *matCellDef="let element"> {{ element.motivo | uppercase }} </td>
    </ng-container>

    <ng-container matColumnDef="banco">
        <th mat-header-cell *matHeaderCellDef> BANCO </th>
        <td mat-cell *matCellDef="let element"> {{ element.Banco.nombre | uppercase }} </td>
    </ng-container>

    <ng-container matColumnDef="monto">
        <th mat-header-cell *matHeaderCellDef> MONTO </th>
        <td mat-cell *matCellDef="let element"> {{ element.monto.toLocaleString('es-CL') }} </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button (click)="Descargar(element.id)" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
              <mat-icon style="color: white">search</mat-icon>
          </button>
          <button (click)="Borrar(element.id)" mat-raised-button color="warn">
            <mat-icon style="color: white">delete</mat-icon>
          </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>