import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { debounceTime } from 'rxjs/operators';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { CargoService } from 'src/app/pages/services/cargo.service';
import { JornadaLaboralService } from 'src/app/pages/services/jornada-laboral.service';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-agregar-cargos',
  templateUrl: './agregar-cargos.component.html',
  styleUrls: ['./agregar-cargos.component.css']
})
export class AgregarCargosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  @Input() list_sucursales!:Sucursal[];
  debouncer: Subject<boolean> = new Subject();  

  cargos:Cargo={
    nombre:"",    
    departamento:"",
    lugar:"",
    jornadaLaboral:0,
    dias:"",
    sucursal:0,        
    // id_sucursal:0
  }
  jornadas: any;

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,    
    private cargoService: CargoService,
    private JornadaLaboralService: JornadaLaboralService
  ) { }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      });

      this.JornadaLaboralService.GetAll_jornadas().subscribe((res: any) => {
        this.jornadas = res.data;
      });
  }

  open(content:any){
    this.limpiar();
    this.modalService.open(content, { size: 'lg' });
  }

  validarCargo(){
    if(this.cargos.nombre.trim().length < 3 || this.cargos.nombre.trim().length > 50 ||
    this.cargos.departamento.trim().length < 3 || this.cargos.departamento.trim().length > 50 ||
    this.cargos.lugar.trim().length < 3 || this.cargos.lugar.trim().length > 150 ||
    this.cargos.dias.trim().length < 0 || this.cargos.dias.trim().length > 50 ||
    this.cargos.jornadaLaboral == 0 || this.cargos.sucursal == 0
     ){
      return false;
    }
    else {
      return true;
    }
  }

  limpiar(){
    this.cargos.nombre="";
    this.cargos.departamento="";
    this.cargos.lugar="";
    this.cargos.jornadaLaboral=0;
    this.cargos.dias="";
    this.cargos.sucursal=0;
  }
  
  agregar(){
    if(this.validarCargo()){
      this.cargoService.Create_cargo(this.cargos).subscribe((res:any)=>{
        if (res.success==true) {
          this.alert.success_small(res.msg!)
          this.modalService.dismissAll();
          this.limpiar();
          this.debouncer.next( true );
        }else{
          // this.alert.error_small(res.msg!);
          this.alert.error_small(res);
        }
      })
    }
    else{
      this.alert.errorAlert('Existen campos vacíos o con un formato no válido, favor revisar');
    }
  }

}
