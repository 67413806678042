import { SucursalService } from './../../../services/sucursales.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, HostListener, Renderer2, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NgxSpinnerService } from "ngx-spinner";
import { ContratoEstandarPlazoFijoService } from 'src/app/pages/services/PDF-GENERADOR/contratos/contrato-estandar-plazo-fijo.service';
const pdfMake = require('pdfmake/build/pdfmake.js');
import { MatStepper } from '@angular/material/stepper';
import { ContratoConductor } from 'src/app/pages/services/PDF-GENERADOR/contratos/contrato-estandar-conductor';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Gallery } from 'angular-gallery';
import Swal from 'sweetalert2';
export enum KEY_CODE {
	RIGHT_ARROW = 39,
	LEFT_ARROW = 37
}
@Component({
	selector: 'app-formato-estandar',
	templateUrl: './formato-estandar.component.html',
	styleUrls: ['./formato-estandar.component.css'],
})
export class FormatoEstandarComponent implements OnInit {
	@ViewChild('content3', { static: false }) modal: any;
	@ViewChild('modal2', { static: false }) modal2: any;
	@ViewChild('stepper') private myStepper: MatStepper;
	@Output() EventoRut = new EventEmitter<string>();
  @ViewChild('plazofijo')inputSwitch:ElementRef;
  @ViewChild('obrasyfaenas')inputSwitch2:ElementRef;
	@HostListener('window:keyup', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {

		this.TH = this.TH + event.key
		if (event.code == 'Enter') {
			this.myStepper.next();
		}
		if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
			this.myStepper.next();
		}
		if (event.keyCode === KEY_CODE.LEFT_ARROW) {
			this.myStepper.previous();
		}
		if (this.TH == 'hesoyam') {
			this.showGallery(0)
			this.audio.src = "assets/sfx/efecto4.mp3";
			this.audio.load();
			this.audio.play();
			//this.VerFotos=true;
		}
		this.key = event.key;
	}

	StopSound() {
		this.audio.pause();
	}
	constructor(
		private modalService: NgbModal,
		private alert: AlertHelper,
		private trabajadoresService: TrabajadoresService,
		private ciudadService: CiudadService,
		private pdfgenerador: ContratoEstandarPlazoFijoService,
		private pdfconductor: ContratoConductor,
		private spinner: NgxSpinnerService,
		private sucursalService: SucursalService,
		private gallery: Gallery,
    private renderer: Renderer2
	) { }
	key: any;
	rut: any;
	ComunaConductor: any;
	sucursales: any;
	SucursalesSeleccionadas: any;
	lista_trabajadores: any;
	TrabajadorSeleccionado: any;
	render: number = 0;
	lista_ciudades: any;
	CiudadSeleccionada: any = undefined;
	PazoFijo: boolean = false;
  ObrasFaenas: boolean = false;
	DatosJson: any = {};
	Fechas: any = {
		fecha_inicio: '',
		fecha_fin: ''
	}
	src: any
	sueldo!: number;
	TH: any = '';
	MostrarSucursales: boolean;
	TrabajadoresAux: any;
	VerFotos: boolean = false;
	audio = new Audio();
	ngOnInit(): void {

		this.TraerDatos();
	}

	MostrarModalSucursales() {
		this.MostrarSucursales = true;
	}

	showGallery(index: number = 0) {
		let prop: any = {};
		prop.images = [
			{ path: 'assets/img/1.jpeg' },
			{ path: 'assets/img/4.jpeg' },
			{ path: 'assets/img/5.jpeg' },
			{ path: 'assets/img/6.jpeg' },
			{ path: 'assets/img/7.jpeg' },
			{ path: 'assets/img/8.jpg' },
			{ path: 'assets/img/9.jpg' },
			{ path: 'assets/img/10.jpg' },
			{ path: 'assets/img/14.jpeg' },
			{ path: 'assets/img/19.jpg' },
			{ path: 'assets/img/20.jpg' },
			{ path: 'assets/img/21.jpg' },
			{ path: 'assets/img/23.jpg' },
			{ path: 'assets/img/24.jpg' },
			{ path: 'assets/img/importante.png' }
		];
		prop.index = index;
		this.gallery.load(prop);
	}


  checkPlazoFijo(): void {
this.ObrasFaenas = false;
  }

  checkObrasFaenas(): void {
    this.PazoFijo = false;
      }


	ObtenerTrabajador(event: any) {
		//console.log(event);

		// this.transferencia.banco=this.BancoSeleccionado.id;
		//console.log(this.TrabajadorSeleccionado);
		//console.log(event.value);
		this.rut = event.value.rut;

		//this.EventoRut.emit(event.value.rut);
		this.render = 1;
	}
	ObtenerCiudad(event: any) {

		// this.transferencia.banco=this.BancoSeleccionado.id;
		//console.log(this.CiudadSeleccionada);
		this.render = 1;
	}

	Abrirhaberes() {
		this.modalService.open(this.modal2, { size: 'xl' })
	}
	pulsar(e) {
		this.myStepper.next();
		//console.log("pulso enter")
	}

	TraerDatos() {
		this.SucursalesSeleccionadas = [];
		this.trabajadoresService.TraerTrabajadores().subscribe(
			(res) => {
				this.lista_trabajadores = res.data.map(x => {
					x.nombreCompleto = x.nombres.toUpperCase() + ' ' + x.apellidos.toUpperCase();
					return x;
				});
				this.TrabajadoresAux = this.lista_trabajadores;
				// for (let i = 0; i < this.lista_trabajadores.length; i++) {
				// 	this.TrabajadoresAux[i].nombres = this.lista_trabajadores[i].nombres + ' ' + this.lista_trabajadores.apellidos + ' ( '
				// }
				// console.log(this.lista_trabajadores);

				/**
				* TODO: lo que esta comentado arriba es para en un futuro mostrar el el select el formato de nombre+apellido (cargo)
				* ! si alguien puede puede seguir la idea bienvenida sea
				*/
			}
		);
		this.ciudadService.GetAll_ciudades().subscribe(
			(res) => {
				this.lista_ciudades = res.data;
				// console.log(this.lista_ciudades);
			}
		);
		this.sucursalService.GetAll_sucursales().subscribe(
			(res) => {
				this.sucursales = res.data;
				// console.log(this.lista_ciudades);
			}
		);
	}
	async GenerarPdf() {

		if (this.validar() == true) {
			//console.log(this.SucursalesSeleccionadas)
			this.DatosJson.Ciudad = this.CiudadSeleccionada;
			this.DatosJson.Trabajador = this.TrabajadorSeleccionado;

      if (this.PazoFijo) {
        this.DatosJson.PlazoFijo = this.PazoFijo;
        this.DatosJson.tipoContrato = 'PLAZO FIJO';

      }
      else if (this.ObrasFaenas){
        this.DatosJson.PlazoFijo = this.ObrasFaenas;
        this.DatosJson.tipoContrato = 'OBRASFAENAS';

      }

			this.DatosJson.Fechas = this.Fechas
			this.DatosJson.Sueldo = this.sueldo
			this.DatosJson.SucursalesSeleccionadas = this.SucursalesSeleccionadas
			//console.log(this.DatosJson)
			//console.log(this.pdfgenerador.GenerarPdf(this.DatosJson));
			this.spinner.show();

			this.DatosJson.comuna = this.ComunaConductor;
					//console.log(this.DatosJson);
					await this.pdfgenerador.GenerarPdf(this.DatosJson);
					setTimeout(() => {
						if (this.pdfgenerador.src != undefined) {
							this.src = this.pdfgenerador.src
							//console.log(this.src);
							this.spinner.hide();
							this.modalService.open(this.modal, { size: 'xl', scrollable: true })
						}
						else {
							this.alert.errorAlert('error al generar pdf')
						}
					}, 1500);

		}
		else {
			this.alert.errorAlert('faltan campos que llenar')
		}


	}

	validar(): boolean {
		if (this.sueldo > 0 && this.CiudadSeleccionada != undefined) {
			return true
		}
		else {
			return false
		}
	}



}
