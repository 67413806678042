<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>



<div>
    <button style="background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button>Exportar a PDF</button>
    <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button
        color="green">Exportar a Excel</button>
</div>


<div class="mat-elevation-z8">
    <table id="tabla" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="centro">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>CENTRO DE COSTO</th>
            <td mat-cell *matCellDef="let element">{{element.centroCosto}}</td>
        </ng-container>

        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>NOMBRE</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.nombre | uppercase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="direccion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DIRECCIÓN</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.direccion | uppercase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button (click)="open(content, element.id)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">edit</mat-icon>
                </button> &nbsp;
                <button (click)="Eliminar_Sucursal(content, element.id)" mat-button class="button_color_primary"
                    style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
    </mat-paginator>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Actualizar Sucursal</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Centro Costo</mat-label>
                    <input type="number" maxlength="99" minlength="0" matInput name="sucursales.centroCosto"
                        [(ngModel)]="update_sucursales.centroCosto" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input type="text" maxlength="50" minlength="3" matInput name="sucursales.nombre"
                        [(ngModel)]="update_sucursales.nombre" />
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Direccion</mat-label>
                    <textarea matInput cols="2" [(ngModel)]="update_sucursales.direccion"></textarea>
                </mat-form-field>
            </div>
            <!-- (onChange)="ObtenerCiudad($event)" -->
            <div class="col-12">
                <mat-form-field class="example-full-width " appearance="outline">
                    <mat-label>Ciudad</mat-label>
                    <mat-select [(ngModel)]="ciudadSeleccionada" required>
                        <mat-option class="example-full-width" *ngFor="let ciudad of ciudades_dropdown"
                            [value]="ciudad.id">
                            {{ciudad.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="Actualizar_Sucursales()">
            Actualizar
        </button>
    </div>
</ng-template>