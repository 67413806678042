import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListarLogsComponent } from './listar-logs/listar-logs.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from 'src/app/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';



@NgModule({
  declarations: [
    ListarLogsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FileUploadModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ]
})
export class LogsModule { }
