import { Injectable } from '@angular/core';
import { TrabajadoresService } from '../trabajadores/trabajadores.service';
import { contabilidadService } from '../contabilidad/contabilidad.service';
import { ArchivoPrevired } from 'src/app/shared/models/archivoPrevired.interface';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { LiquidacionService } from '../liquidaciones/liquidacion.service';
import { Previsional } from 'src/app/shared/models/previsonal';
import { MovimientosPersonalService } from '../movimientos-personal.service';
import { MovimientosPersonal } from 'src/app/shared/models/movimientospersonal.interface';
import { cleanRut, getLastDigitOfRut } from 'rutlib/lib';
import { differenceInYears, eachDayOfInterval, endOfMonth, isAfter, isWithinInterval, parseISO, startOfMonth, subYears } from 'date-fns';
import { FuncionesLiquidacionesService } from '../liquidaciones/funciones-liquidaciones.service';
import { Adelantos } from 'src/app/shared/models/adelantos.interface';
import { Prestamos } from 'src/app/shared/models/prestamos.interface';
import { generarLiquidacionService } from '../PDF-GENERADOR/liquidaciones/liquidacion.service';
import { LicenciasService } from '../licencias/licencias.service';

@Injectable({
  providedIn: 'root'
})
export class PreviredService {
  periodo: Date = new Date();
  totalImponibleLicencia: number;
  diasLicencias: number;
  quitarCabecera:boolean = true;
  ufActual: number = 0.0;
  trabajadores: any[] = [];
  trabajadoresListPrevired: any[] = [];
  cods_afps_previred: any[] = [
    { codigo: 0, cod_lre: 100, afp: 'No Cotiza A.P.V.' },
    { codigo: 3, cod_lre: 13, afp: 'Cuprum' },
    { codigo: 5, cod_lre: 14, afp: 'Habitat' },
    { codigo: 8, cod_lre: 6, afp: 'Provida' },
    { codigo: 29, cod_lre: 11, afp: 'Planvital' },
    { codigo: 33, cod_lre: 31, afp: 'Capital' },
    { codigo: 34, cod_lre: 103, afp: 'Modelo' },
    { codigo: 35, cod_lre: 19, afp: 'Uno' },
  ];
  cods_salud_previred: any[] = [
    { codigo: 0, cod_lre: 99, afp: 'Sin Isapre' },
    { codigo: 1, cod_lre: 3, afp: 'Banmédica' },
    { codigo: 2, cod_lre: 9, afp: 'Consalud' },
    { codigo: 3, cod_lre: 12, afp: 'VidaTres' },
    { codigo: 4, cod_lre: 4, afp: 'Colmena' },
    { codigo: 5, cod_lre: 1, afp: 'Isapre Cruz Blanca S.A' },
    { codigo: 7, cod_lre: 102, afp: 'Fonasa' },
    { codigo: 10, cod_lre: 43, afp: 'Nueva Masvida' },
    { codigo: 11, cod_lre: 5, afp: 'Isapre de Codelco Ltda.' },
    { codigo: 12, cod_lre: 40, afp: 'Isapre Bco. Estado' },
    { codigo: 25, cod_lre: 38, afp: 'Cruz del Norte' },
    { codigo: 28, cod_lre: 44, afp: 'Esencial' },
  ]
  trabajador = {
    ubicacion: '',
    nombre: '',
    apellido: '',
    fk_trabajador: 0,
    f_start: '',
    f_end: '',
    dias_semana: 0,
    fecha_ingreso: '',
    habiles: 0,
    inhabiles: 0,
    pendiente: 0.0,
    periodo: new Date(),
    empresa: '',
    empresaRut: 0,
    palabras: '',
    folio: '00000',
    afp: '',
    afc: 1,
    afptasa: 0,
    prevision: '',
    previsiontasa: 0,
    horasExtras: 0,
    carga: 0,
    sbase: 0,
    haberes: [],
    sCesantia: 0,
    estadoContrato: '',
    adelanto: 0,
    prestamo: 0,
    numCuota: 0,
    ahorro_apv: 0,
    prestamos: [],
    jubilado: false,
    tramoAsigFam: 0,
    horasPactadas: 0,
    impuestounicoC: 0,
    previred: {
      periodo: null,
      topes: {
        AfpSalud: 0,
        IPS: 0,
        SeguroCesantia: 0,
      },
      familiar: {
        tramoA: {
          valor: 0,
          max: 0,
        },
        tramoB: {
          valor: 0,
          min: 0,
          max: 0
        },
        tramoC: {
          valor: 0,
          min: 0,
          max: 0,
        },
        tramoD: {
          valor: 0,
          min: 0,
        }
      },
      ufMesActual: 0,
      ufMesAnterior: 0,
      rentaMinima: 0,
      sCesantiaIndefinido: 0,
      seguroInvalidez: 0
    },
  }
  archivoPrevired: ArchivoPrevired[] = [];
  registroArchivoPrevired: ArchivoPrevired = {
    // DATOS TRABAJADOR
    rutSDV: 0,
    dv: '',
    apellidoP: '',
    apellidoM: '',
    nombre: '',
    sexo: '',
    nacional: 0,
    tipoPago: 1,
    periodoDesde: 0,
    periodoHasta: 0,
    regimenPrev: 'AFP',
    tipoTrabajador: 0,
    diasTrabajados: 30,
    tipoLinea: 0,
    codMovPersonal: 0,
    f_desde: undefined,
    f_hasta: undefined,
    tramoAsigFam: '',
    numCargas: 0,
    numCargasMat: 0,
    numCargasInv: 0,
    asigFamiliar: 0,
    asigFamRetro: 0,
    reitegroCargasFam: 0,
    subTrabajadorJoven: 'N',
    // DATOS AFP
    cod_afp: '00',
    rentImponible: 0,
    cotizAFP: 0,
    sis: 0,
    apv: 0,
    rentaImpSustitutivaAFP: 0,
    tasaPactSustitutiva: 0,
    aporteIndemSustitutiva: 0,
    numPeriodos: 0,
    periodoDesdeSust: undefined,
    periodoHastaSust: undefined,
    trabajoPesado: undefined,
    porcTrabajoPesado: 0,
    cotizTrabajoPesado: 0,
    // DATOS APVI
    codInstAPV: 0,
    numContratoAPV: undefined,
    formaPagoAPV: 0,
    cotizacionAPVI: 0,
    cotizDeposConv: 0,
    // DATOS APVC
    codAPVC: 0,
    numContratoAPVC: undefined,
    formaPagoAPVC: 0,
    cotizTrabajadorAPVC: 0,
    cotizEmpleadorAPVC: 0,
    // DATOS AFILIADO VOLUNTARIO
    rutSDV_afiliadoV: 0,
    dv_afiliadoV: undefined,
    apellidoP_afiliadoV: undefined,
    apellidoM_afiliadoV: undefined,
    nombre_afiliadoV: undefined,
    codMovPers_afiliadoV: 0,
    f_desde_afiliadoV: undefined,
    f_hasta_afiliadoV: undefined,
    codAFP_afiliadoV: 0,
    montoCapVolunt: 0,
    montoAhorroVolunt: 0,
    numPeriodoCotiz: 0,
    // DATOS ISL-ISP-FONASA
    codExCajaRegimen: '0000',
    tasaCotizExCajaRegimen: 0,
    rentaImpIPS: 0,
    cotizIPS: 0,
    rentaImpDesahucio: 0,
    codExCajaRegDesahucio: 0,
    tasaCotizDesahucioExCajasPrev: 0,
    cotizDesahucio: 0,
    cotizFonasa: 0,
    cotizAccTrabajo: 0,
    bonificacionLey15385: 0,
    descCargasFamiliaresISL: 0, // ASIGNACION FAMILIAR
    bonosGobierno: 0,
    // DATOS SALUD
    codInstSalud: '07', // 7 = FONASA
    numFUN: 0, // SI TIENE ISAPRE, NUMERO CONTRATO
    rentaImpIsap: 0,
    monedaPactoIsapre: 1,
    cotizPactIsapre: 0,
    cotizObligatoriaIsapre: 0,
    cotizAdicIsapre: 0,
    ges: 0, // EN LA VERSION 70 DEL ARCHIVO PREVIRED SOLO SE AGREGA 0
    // DATOS CCAF
    codCCAF: '00',
    rentaImpCCAF: 0,
    creditosPersCCAF: 0,
    descDentalCCAF: 0,
    descLeasing: 0,
    descSegVida: 0,
    otrosDescCCAF: 0,
    cotizCCAF_NoAfiliadosISAPRES: 0,
    descCargasFamCCAF: 0,
    otrosDescCCAF1: 0,
    otrosDescCCAF2: 0,
    bonosGobiernoCCAF: 0,
    codSucursal: undefined,
    // DATOS MUTUAL
    codMutual: 1, // ASCH
    rentaImpMutual: 0,
    cotizAccTrabajoMutual: 0,
    sucursalPagoMutual: 0,
    // DATOS SEG CESANTIA
    rentaImpSegCesantia: 0,
    segCesantiaTrabajador: 0,
    segCesantiaEmpleador: 0,
    // DATOS PAGADO SUBSIDIO
    rutSDV_subsidio: 0,
    dv_subsidio: undefined,
    // DATOS EMPRESA
    centroCostos_Sucursal: 1,
  }
  uf: { mesActual: number; mesAnterior: number; };
  adelantos: Adelantos[];
  tieneAdelanto: number;
  prestamos: Prestamos[];
  tienePrestamo: number;

  constructor(
    private trabajadorService: TrabajadoresService,
    private contabilidadService: contabilidadService,
    private modLiquidaciones: LiquidacionService,
    private licenciasService: LicenciasService,
    private generarLiquidacionService: generarLiquidacionService,
    private movimientosPersonalesService: MovimientosPersonalService,
    private funcionesLiquidacionesService: FuncionesLiquidacionesService,
    private alert: AlertHelper,
  ) {
    this.totalImponibleLicencia = 0;
    this.diasLicencias = 0;
  }

  async generarArchivoPrevired(opciones_elegidas: any) {
    const res = await this.trabajadorService.TraerTrabajadoresAsync();
    if (res.success) {
      this.trabajadores = res.data;
    }else{
      this.alert.error_small('Hubo un error al obtener los trabajadores.');
      console.error(res.msg);
      return;
    }
    // console.log(this.trabajadores, {opciones_elegidas});
    this.quitarCabecera = opciones_elegidas.cabecera;
    this.periodo = opciones_elegidas.periodo;

    this.funcionesLiquidacionesService.setPeriodo(this.periodo); // SETEO EL PERIODO EN EL SERVICIO DE FUNCIONES LIQUIDACIONES
    this.uf = await this.funcionesLiquidacionesService.obtenerUFs(); // OBTENGO LAS UF DEL MES ACTUAL Y ANTERIOR
    this.trabajador.previred = await this.funcionesLiquidacionesService.obtenerPrevired(); // OBTENGO LOS DATOS DE PREVIRED
    await this.generarLiquidacionService.obtenerIndicadoresImpuestoUnico(this.periodo); // OBTENGO LOS INDICADORES DEL IMPUESTO UNICO
    
    this.trabajadoresListPrevired = this.trabajadores
      .filter(x => x.Sucursal.id === opciones_elegidas.sucursal 
        && x.Empresa_contratante.id === opciones_elegidas.empresa)
      .sort((a, b) => a.apellidos.localeCompare(b.apellidos));
    // console.log(this.trabajadoresListPrevired);
    if (this.trabajadoresListPrevired.length > 0) {
      await this.obtenerPrevired(opciones_elegidas.periodo);
      const result = await this.obtenerInformacion(opciones_elegidas.periodo);
      if (result.length > 0) {
        await this.calcularItems(result);
      } else {
        this.alert.errorAlert('No existe ningun registro.');
      }
    } else {
      this.alert.errorAlert('No existe ningun registro.');
    }
  }

  async obtenerInformacion(periodo: Date) {
    let registros = [];
    try {
      const res = await this.contabilidadService.TraerTodoAsync();
      if (res.success) {
        const data = res.data;
        console.log('DATA: ', data);
        registros = data.filter(x => {
          const fecha = parseISO(x.f_liquidacion);
          // console.log('DATA: ', data);
          return (moment(fecha).month() === moment(periodo).month()) && (moment(fecha).year() === moment(periodo).year());
        })
        .sort((a,b) => a.nombre.localeCompare(b.nombre));
        return registros;
      }else{
        console.error(res.msg);
        return registros;
      }
    } catch (error) {
      console.error(error);
      return registros;
    }
  }

  async calcularItems(registros: any[]) {
    this.archivoPrevired = [];
    for (const registro of registros) {
      // console.log({registro});
      const trabajador = this.trabajadoresListPrevired
        .find(x => x.rut === registro.rut);
      if (trabajador) {
        const movimientos: MovimientosPersonal[] = await this.obtenerMovimientosPersonalTrabajador(registro.rut);
        if (movimientos.length > 0) {
          await this.generarRegistroPreviredMovimientosPersonal(trabajador, registro, movimientos);
        }else{
          this.generarRegistroPrevired(trabajador, registro);
          this.archivoPrevired.push(this.registroArchivoPrevired);
        }
      }
    }
    console.log(this.archivoPrevired);
    // throw Error('ERROR!!');
    if (this.archivoPrevired.length > 0) {
      this.generarTextoCSV(this.archivoPrevired);
    } else {
      this.alert.errorAlert('No existe ningun registro.');
    }
  }
  async calcularLiquidacion(trabajador: any) {
    this.funcionesLiquidacionesService.setRut(trabajador.rut);
    this.funcionesLiquidacionesService.sucursalTrabajador = trabajador.Sucursal;

    this.trabajador.fk_trabajador = trabajador.rut;
    this.trabajador.dias_semana = trabajador.dias_semana;
    this.trabajador.fecha_ingreso = trabajador.ingreso;
    this.trabajador.periodo = this.periodo;
    this.trabajador.afp = trabajador.Prevision.nombre;
    this.trabajador.afptasa = trabajador.pactado_isapre ? trabajador.pactado_isapre : trabajador.afp;
    this.trabajador.prevision = trabajador.Afp.nombre??'';
    this.trabajador.previsiontasa = trabajador.Afp.tasa??0;
    this.trabajador.carga = trabajador.numero_cargas;
    this.trabajador.sbase = trabajador.sueldo_base;
    this.trabajador.estadoContrato = trabajador.estado_contrato;
    this.trabajador.ahorro_apv = trabajador.ahorro_apv;
    this.trabajador.jubilado = trabajador.jubilado === 1;
    this.trabajador.tramoAsigFam = trabajador.tramoAsigFam;
    this.trabajador.horasPactadas = trabajador.horasPactadas;
    this.trabajador.horasExtras = await this.funcionesLiquidacionesService.obtenerHorasExtras();

    this.funcionesLiquidacionesService.CargarTrabajadorLocal(this.trabajador);

    // const numDias = await this.funcionesLiquidacionesService.contarDias();
    // const numInhasistencias = await this.funcionesLiquidacionesService.cambioMes();
    // const pendiente = Math.floor(numInhasistencias * 10) / 10  + numDias;
    // this.trabajador.pendiente = pendiente > 30 ? 30 : pendiente;

    // ==> ADELANTOS
    this.trabajador.adelanto = await this.funcionesLiquidacionesService.ObtenerAdelantos();
    this.adelantos = this.funcionesLiquidacionesService.adelantos;
    this.tieneAdelanto = this.funcionesLiquidacionesService.numAdelanto;
    // ==> PRESTAMOS
    const objetoPrestamo = await this.funcionesLiquidacionesService.CargarPrestamo();
    this.prestamos = objetoPrestamo.prestamos;
    this.trabajador.prestamos = this.prestamos;
    this.tienePrestamo = objetoPrestamo.tienePrestamo;
    this.trabajador.prestamo = objetoPrestamo.prestamo; // MONTO CUOTA
    this.trabajador.numCuota = objetoPrestamo.numCuota;

    // HABERES
    this.trabajador.haberes = await this.funcionesLiquidacionesService.obtenerHaberes();
    // CALCULAMOS LA LIQUIDACION
    this.generarLiquidacionService.calcularLiquidacion(this.trabajador);
    // SE OBTIENE EL TOTAL IMPONIBLE DEL TRABAJADOR
    this.totalImponibleLicencia = this.generarLiquidacionService.total_imponible;
  }
  calcularDiasLicencias(movimiento: MovimientosPersonal): number {
    const fechaInicio = parseISO(movimiento.fecha_inicio);
    const fechaFinal = parseISO(movimiento.fecha_final);
    const diasLicencia = eachDayOfInterval({ start: fechaInicio, end: fechaFinal })
      .filter(x => x.getMonth() === this.periodo.getMonth() && x.getFullYear() === this.periodo.getFullYear());
    return diasLicencia.length > 30 ? 30 : diasLicencia.length;
  }
  async generarRegistroPreviredMovimientosPersonal(trabajador: any, registro: any, movimientos: MovimientosPersonal[]) {
    // ==> VERIFICAR SI ES UNA LICENCIA
    const movLicencia = movimientos.find(x => x.TiposEvento.codigo === 3);
    if (movLicencia) {
      // await this.calcularLiquidacion(trabajador);    
      this.diasLicencias = this.calcularDiasLicencias(movLicencia);
      this.totalImponibleLicencia = movLicencia.montoImponible;
    }
    const rutString:string = trabajador.rut.toString();
    const apellidos = trabajador.apellidos.split(' ');

    const periodoString = (this.periodo.getMonth() + 1) + '' + this.periodo.getFullYear();
    
    const rutSDV = rutString.slice(0, rutString.length - 1);
    const dv = getLastDigitOfRut(parseInt(rutSDV));
    
    const esPlazoFijo = trabajador.estado_contrato === 'Contrato a Plazo Fijo';
    const esFonasa = trabajador.Prevision.cod_prevision === 102;
    const esChileno = trabajador.nacionalidad === 3;
    const mayor65Year = differenceInYears(new Date(), parseISO(trabajador.fecha_nacimiento)) >= 65;
    
    const tasaBasica = parseFloat(trabajador.Empresa_contratante.cotizBasica) / 100;
    const tasaLeySanna = parseFloat(trabajador.Empresa_contratante.cotizLeySanna) / 100;
    const tasaMutual = parseFloat(tasaBasica.toFixed(4)) + parseFloat(tasaLeySanna.toFixed(4));
    const centroCosto = trabajador.Sucursal.centroCosto;
    const codMutual = this.obtenerCodMutual(trabajador.Empresa_contratante.nombreMutual);
    const tramoAsignacionFamiliar = this.obtenerTramoAsignacionFamiiar(trabajador.tramoAsigFam);
    const montoAsignacionFamiliar = this.obtenerMontoAsignacionFamiliar(trabajador.tramoAsigFam, trabajador.numero_cargas);
    
    // const montoPactadoIsapre = Math.round(trabajador.pactado_isapre * this.ufActual);
    const adicionalIsapre = registro.adicional_isapre;
    
    const totalimp = registro.d_trabajados === 0 ? 0 : movLicencia && registro.totalimp === 0 ? this.totalImponibleLicencia : registro.totalimp;
    const totalImpSegCesantia = Math.round((this.totalImponibleLicencia / 30) * (registro.d_trabajados + this.diasLicencias));
    movimientos.forEach((mov:MovimientosPersonal, index: number) => {
      this.registroArchivoPrevired = {
        // DATOS DEL TRABAJADOR
        rutSDV: Number(rutSDV),
        dv: dv,
        apellidoP: apellidos[0].toUpperCase(),
        apellidoM: apellidos.length > 0 ? apellidos[1]?.toUpperCase() : undefined,
        nombre: trabajador.nombres.toUpperCase(),
        sexo: trabajador.sexo,
        nacional: esChileno ? 0 : 1,
        tipoPago: 1,
        periodoDesde: Number(periodoString),
        periodoHasta: 0,
        regimenPrev: trabajador.Afp.cod_afp !== 0 ? 'AFP' : 'SIP' ,
        tipoTrabajador: trabajador.jubilado === 0 && mayor65Year ? 3 
          : trabajador.jubilado === 1 && trabajador.Afp.cod_afp === 0 ? 2 
          : trabajador.jubilado === 1 && trabajador.Afp.cod_afp !== 0 ? 1 
          : 0,
        diasTrabajados: index > 0 ? 0 : registro.d_trabajados,
        tipoLinea: 0,
        codMovPersonal: mov.TiposEvento.codigo,
        f_desde: !mov.fecha_inicio && !mov.fecha_final ? '' : !mov.fecha_inicio ? '0' : mov.fecha_inicio,
        f_hasta: !mov.fecha_final && !mov.fecha_inicio ? '' : !mov.fecha_final ? '0' : mov.fecha_final,
        tramoAsigFam: index > 0 ? '' : tramoAsignacionFamiliar,
        numCargas: index > 0 ? 0 : registro.num_cargas, // this.registroArchivoPrevired.numCargas = registro.num_cargas;
        numCargasMat: 0,
        numCargasInv: 0,
        asigFamiliar: index > 0 ? 0 : montoAsignacionFamiliar,
        asigFamRetro: 0,
        reitegroCargasFam: 0,
        subTrabajadorJoven: 'N',
        // AFP
        cod_afp: trabajador.jubilado === 1 ? '00' : this.cods_afps_previred.find(x => x.cod_lre === registro.cod_afp)?.codigo || '00',
        rentImponible: index > 0 ? 0 : totalimp,
        cotizAFP: index > 0 ? 0 : Math.round(totalimp * (trabajador.Afp.tasa / 100)),
        // (((Imponible del último mes donde trabajó 30 días / 30) * Cantidad días con licencia) * 1,61%) + (Imponible mes con licencia * 1,61%).
        sis: index > 0 || trabajador.jubilado === 1
          ? 0 : movLicencia 
          // ? Math.round(((this.totalImponibleLicencia / 30) * this.diasLicencias) * (this.trabajador.previred.seguroInvalidez / 100) + (registro.totalimp * (this.trabajador.previred.seguroInvalidez / 100))) 
          // ? Math.round(this.totalImponibleLicencia * (this.trabajador.previred.seguroInvalidez / 100))
          ? Math.round(((this.totalImponibleLicencia / 30) * (this.diasLicencias + registro.d_trabajados)) * (this.trabajador.previred.seguroInvalidez / 100)) 
          : Math.round(registro.totalimp * (this.trabajador.previred.seguroInvalidez / 100)),
        apv: index > 0 ? 0 : trabajador.ahorro_apv??0,
        rentaImpSustitutivaAFP: 0,
        tasaPactSustitutiva: 0,
        aporteIndemSustitutiva: 0,
        numPeriodos: 0,
        periodoDesdeSust: '',
        periodoHastaSust: '',
        trabajoPesado: '',
        porcTrabajoPesado: 0,
        cotizTrabajoPesado: 0,
        // APVI
        codInstAPV: '000',
        numContratoAPV: '',
        formaPagoAPV: 0,
        cotizacionAPVI: 0,
        cotizDeposConv: 0,
        // APVC
        codAPVC: '000',
        numContratoAPVC: '',
        formaPagoAPVC: 0,
        cotizTrabajadorAPVC: 0,
        cotizEmpleadorAPVC: 0,
        // AFILIADO VOLUNTARIO
        rutSDV_afiliadoV: 0,
        dv_afiliadoV: '',
        apellidoP_afiliadoV: '',
        apellidoM_afiliadoV: '',
        nombre_afiliadoV: '',
        codMovPers_afiliadoV: 0,
        f_desde_afiliadoV: '',
        f_hasta_afiliadoV: '',
        codAFP_afiliadoV: 0,
        montoCapVolunt: 0,
        montoAhorroVolunt: 0,
        numPeriodoCotiz: 0,
        // IPS ISL FONASA
        codExCajaRegimen: '0000',
        tasaCotizExCajaRegimen: 0,
        rentaImpIPS: totalimp,
        cotizIPS: 0,
        rentaImpDesahucio: 0,
        codExCajaRegDesahucio: 0,
        tasaCotizDesahucioExCajasPrev: 0,
        cotizDesahucio: 0,
        cotizFonasa: index > 0 ? 0 : esFonasa ? Math.round(totalimp * 0.07) : 0,
        cotizAccTrabajo: index > 0 ? 0 : codMutual === 0 ? Math.round(totalimp * 0.0093) : 0,
        bonificacionLey15385: 0,
        descCargasFamiliaresISL: index > 0 ? 0 : montoAsignacionFamiliar,
        bonosGobierno: 0,
        // SALUD
        codInstSalud: this.cods_salud_previred.find(x => x.cod_lre === registro.isapre_fonasa)?.codigo || '00',
        numFUN: 0,
        rentaImpIsap: totalimp,
        monedaPactoIsapre: index > 0 ? 0 : esFonasa ? 1 : 2,
        cotizPactIsapre: index > 0 ? null : esFonasa ? 0 : trabajador.pactado_isapre.toString().replace('.', ','),
        cotizObligatoriaIsapre: index > 0 ? 0 : esFonasa ? 0 : registro.salud,
        cotizAdicIsapre: index > 0 ? 0 : esFonasa ? 0 : adicionalIsapre >= 0 ? adicionalIsapre : 0,
        ges: 0,
        // CCAF
        codCCAF: '00',
        rentaImpCCAF: totalimp,
        creditosPersCCAF: 0,
        descDentalCCAF: 0,
        descLeasing: 0,
        descSegVida: 0,
        otrosDescCCAF: 0,
        cotizCCAF_NoAfiliadosISAPRES: 0,
        descCargasFamCCAF: 0,
        otrosDescCCAF1: 0,
        otrosDescCCAF2: 0,
        bonosGobiernoCCAF: 0,
        codSucursal: '',
        codMutual: codMutual,
        rentaImpMutual: codMutual === 0 
          ? 0 : registro.totalimp === 0 
          ? totalImpSegCesantia : !movLicencia
          ? totalimp : registro.totalimp,
        // MUTUAL
        // (((Imponible del último mes trabajado 30 días / 30) * Cantidad de días con licencia) * Ley Sanna) + (Imponible del mes con licencia* Cotización Mutual).
        cotizAccTrabajoMutual: index > 0 || codMutual === 0 
          ? 0 : movLicencia 
          ? Math.round((((this.totalImponibleLicencia / 30) * this.diasLicencias) * tasaLeySanna) + (totalimp * tasaMutual)) 
          : Math.round(totalimp * tasaMutual), 
        sucursalPagoMutual: 0,
        // SEG CESANTIA
        // PLAZO FIJO: (((Imponible del último mes donde trabajó 30 días / 30) * Cantidad días con licencia) * 3%) + (Imponible mes con licencia * 3%).
        // INDEFINIDO: (((Imponible del último mes donde trabajó 30 días / 30) * Cantidad días con licencia) * 2,4 %) + (Imponible mes con licencia * 2,4%).
        // CUANDO EL TRABAJADOR TIENE LICENCIAS, EL SEGURO DE CESANTIA CON EL TOTAL IMPINIBLE MAS LOS DIAS DE LICENCIA
        rentaImpSegCesantia: registro.totalimp === 0 && !movLicencia
          ? totalimp : movLicencia
          ? totalImpSegCesantia : totalimp,
        segCesantiaTrabajador: index > 0 || esPlazoFijo 
          // ? 0 : movLicencia && registro.totalimp === 0
          // ? 0 : movLicencia && registro.totalimp === 0
          ? 0 : Math.round(totalimp * 0.006),
          // ? Math.round(totalimp * 0.006) : movLicencia 
          // ? Math.round(totalImpSegCesantia * 0.006) : 0,
        segCesantiaEmpleador: index > 0 
          ? 0 : esPlazoFijo && registro.totalimp === 0 && !movLicencia
          ? Math.round(totalimp * 0.024) : !esPlazoFijo && registro.totalimp === 0 && !movLicencia
          ? Math.round(totalimp * 0.03) : esPlazoFijo && movLicencia
          ? Math.round(totalImpSegCesantia * 0.03) : !esPlazoFijo && movLicencia 
          ? Math.round(totalImpSegCesantia * 0.024) : esPlazoFijo 
          ? Math.round(totalimp * 0.03) : Math.round(totalimp * 0.024),
          // Math.round((((this.totalImponibleLicencia / 30) * this.diasLicencias) * 0.024) + (this.totalImponibleLicencia * 0.024))
          // Math.round((((this.totalImponibleLicencia / 30) * this.diasLicencias) * 0.03) + (this.totalImponibleLicencia * 0.03))
        // SUBSIDIOS
        rutSDV_subsidio: mov.rutpagadora ? 
          parseInt(cleanRut(mov.rutpagadora.slice(0, mov.rutpagadora.length - 1))) : 0,
        dv_subsidio: mov.rutpagadora ? 
          cleanRut(mov.rutpagadora.slice(mov.rutpagadora.length - 1)) : '',
        // CENTRO DE COSTOS
        centroCostos_Sucursal: centroCosto,
      }
      this.archivoPrevired.push(this.registroArchivoPrevired);
    });
  }
  obtenerMontoAsignacionFamiliar(tramoAsigFam: number, carga: number): number {
    if (tramoAsigFam === 1) return this.trabajador.previred.familiar.tramoA.valor * carga;
    if (tramoAsigFam === 2) return this.trabajador.previred.familiar.tramoB.valor * carga; 
    if (tramoAsigFam === 3) return this.trabajador.previred.familiar.tramoC.valor * carga;
    if (tramoAsigFam === 4) return this.trabajador.previred.familiar.tramoD.valor * carga;
    return 0;
  }
  obtenerTramoAsignacionFamiiar(tramo_asig_fam: number): string {
    switch (tramo_asig_fam) {
      case 1: return 'A';
      case 2: return 'B'
      case 3: return 'C'
      case 4: return 'D'
      default: return 'S' // SIN INFORMACIÓN
    }
  }
  generarRegistroPrevired(trabajador: any, registro: any) {
    // console.log({trabajador});
    const rutString:string = trabajador.rut.toString();
    const rutSDV = rutString.slice(0, rutString.length - 1);
    const dv = getLastDigitOfRut(parseInt(rutSDV));

    const apellidos = trabajador.apellidos.toUpperCase().split(' ');
    const periodoString = (this.periodo.getMonth() + 1) + '' + this.periodo.getFullYear();
    
    const esFonasa = trabajador.Prevision.cod_prevision === 102;
    const esChileno = trabajador.nacionalidad === 3;
    const mayor65Year = trabajador.fecha_nacimiento ? differenceInYears(new Date(), parseISO(trabajador.fecha_nacimiento)) >= 65 : false;
    const centroCosto = trabajador.Sucursal.centroCosto;
    
    const tramoAsignacionFamiliar = this.obtenerTramoAsignacionFamiiar(trabajador.tramoAsigFam);

    const codMutual = this.obtenerCodMutual(trabajador.Empresa_contratante.nombreMutual);
    const tasaBasica = parseFloat(trabajador.Empresa_contratante.cotizBasica) / 100;
    const tasaLeySanna = parseFloat(trabajador.Empresa_contratante.cotizLeySanna) / 100;
    const tasaMutual = parseFloat(tasaBasica.toFixed(4)) + parseFloat(tasaLeySanna.toFixed(4));

    const adicionalIsapre = Math.round(trabajador.pactado_isapre * this.ufActual) - registro.salud;

    this.registroArchivoPrevired = {
      // DATOS DEL TRABAJADOR
      rutSDV: Number(rutSDV),
      dv: dv,
      apellidoP: apellidos[0],
      apellidoM: apellidos.length > 0 ? apellidos[1] : null,
      nombre: trabajador.nombres.toUpperCase(),
      sexo: trabajador.sexo?.toUpperCase(),
      nacional: esChileno ? 0 : 1,
      tipoPago: 1,
      periodoDesde: Number(periodoString),
      periodoHasta: 0,
      regimenPrev: trabajador.Afp.cod_afp !== 0 ? 'AFP' : 'SIP' ,
      tipoTrabajador: trabajador.jubilado === 0 ? 3 
          : trabajador.jubilado === 1 && trabajador.Afp.cod_afp === 0 ? 2 
          : trabajador.jubilado === 1 && trabajador.Afp.cod_afp !== 0 ? 1 
          : 0,
      diasTrabajados: registro.d_trabajados,
      tipoLinea: 0,
      codMovPersonal: 0,
      f_desde: '',
      f_hasta: '',
      tramoAsigFam: tramoAsignacionFamiliar,
      numCargas: registro.num_cargas,
      numCargasMat: 0,
      numCargasInv: 0,
      asigFamiliar: registro.asig_familiar,
      asigFamRetro: 0,
      reitegroCargasFam: 0,
      subTrabajadorJoven: 'N',
      // AFP
      cod_afp: this.cods_afps_previred.find(x => x.cod_lre === registro.cod_afp)?.codigo || '00',
      rentImponible: registro.totalimp,
      cotizAFP: registro.prevision,
      sis: trabajador.jubilado === 1 || mayor65Year ? 0 : Math.round(registro.totalimp * (this.trabajador.previred.seguroInvalidez / 100)),
      apv: trabajador.ahorro_apv??0,
      rentaImpSustitutivaAFP: 0,
      tasaPactSustitutiva: 0,
      aporteIndemSustitutiva: 0,
      numPeriodos: 0,
      periodoDesdeSust: '',
      periodoHastaSust: '',
      trabajoPesado: '',
      porcTrabajoPesado: 0,
      cotizTrabajoPesado: 0,
      // APVI
      codInstAPV: '000',
      numContratoAPV: '',
      formaPagoAPV: 0,
      cotizacionAPVI: 0,
      cotizDeposConv: 0,
      // APVC
      codAPVC: '000',
      numContratoAPVC: '',
      formaPagoAPVC: 0,
      cotizTrabajadorAPVC: 0,
      cotizEmpleadorAPVC: 0,
      // AFILIADO VOLUNTARIO
      rutSDV_afiliadoV: 0,
      dv_afiliadoV: '',
      apellidoP_afiliadoV: '',
      apellidoM_afiliadoV: '',
      nombre_afiliadoV: '',
      codMovPers_afiliadoV: 0,
      f_desde_afiliadoV: '',
      f_hasta_afiliadoV: '',
      codAFP_afiliadoV: 0,
      montoCapVolunt: 0,
      montoAhorroVolunt: 0,
      numPeriodoCotiz: 0,
      // IPS ISL FONASA
      codExCajaRegimen: '0000',
      tasaCotizExCajaRegimen: 0,
      rentaImpIPS: registro.totalimp,
      cotizIPS: 0,
      rentaImpDesahucio: 0,
      codExCajaRegDesahucio: 0,
      tasaCotizDesahucioExCajasPrev: 0,
      cotizDesahucio: 0,
      cotizFonasa: esFonasa ? registro.salud : 0,
      cotizAccTrabajo: codMutual === 0 ? Math.round(registro.totalimp * 0.0093) : 0,
      bonificacionLey15385: 0,
      descCargasFamiliaresISL: registro.asig_familiar,
      bonosGobierno: 0,
      // SALUD
      codInstSalud: this.cods_salud_previred.find(x => x.cod_lre === registro.isapre_fonasa)?.codigo || '00',
      numFUN: 0,
      rentaImpIsap: registro.totalimp,
      monedaPactoIsapre: esFonasa ? 1 : 2,
      cotizPactIsapre: esFonasa ? 0 : trabajador.pactado_isapre.toString().replace('.', ','),
      cotizObligatoriaIsapre: esFonasa ? 0 : registro.salud,
      cotizAdicIsapre: esFonasa ? 0 : adicionalIsapre >= 0 ? adicionalIsapre : 0,
      ges: 0,
      // CCAF
      codCCAF: '00',
      rentaImpCCAF: registro.totalimp,
      creditosPersCCAF: 0,
      descDentalCCAF: 0,
      descLeasing: 0,
      descSegVida: 0,
      otrosDescCCAF: 0,
      cotizCCAF_NoAfiliadosISAPRES: 0,
      descCargasFamCCAF: 0,
      otrosDescCCAF1: 0,
      otrosDescCCAF2: 0,
      bonosGobiernoCCAF: 0,
      codSucursal: '',
      // MUTUAL
      codMutual: codMutual,
      rentaImpMutual: codMutual === 0 ? 0 : registro.totalimp,
      cotizAccTrabajoMutual: codMutual === 0 ? 0 : Math.round((tasaMutual) * registro.totalimp),
      sucursalPagoMutual: 0,
      // SEG CESANTIA
      rentaImpSegCesantia: registro.totalimp,
      segCesantiaTrabajador: registro.scesantia,
      segCesantiaEmpleador: registro.totalaportesempleador,
      // SUBSIDIOS
      rutSDV_subsidio: 0,
      dv_subsidio: '',
      // CENTRO DE COSTOS
      centroCostos_Sucursal: centroCosto,
    }
  }
  async obtenerMovimientosPersonalTrabajador(rut: number) {
    let movimientos = [];
    const res = await this.movimientosPersonalesService.obtenerMovimientosPersonalesPorRut(rut);
    if (res.success) {
      movimientos = res.data.filter(x => {
        const firstDatePeriodo = startOfMonth(this.periodo);
        const lastDatePeriodo = endOfMonth(this.periodo);
        const fechaInicioMovimiento = x.fecha_inicio ? parseISO(x.fecha_inicio) : firstDatePeriodo;
        const fechaFinalMovimiento = x.fecha_final ? parseISO(x.fecha_final) : endOfMonth(parseISO(x.fecha_inicio));
        return isWithinInterval(fechaInicioMovimiento, { start: firstDatePeriodo, end: lastDatePeriodo }) 
          || isWithinInterval(fechaFinalMovimiento, { start: firstDatePeriodo, end: lastDatePeriodo }); 
      }).sort((a: MovimientosPersonal, b: MovimientosPersonal) => {
        const timeA = new Date(a.createdAt).getTime();
        const timeB = new Date(b.createdAt).getTime();
        return timeB - timeA;
      });
      return movimientos;
    }else{
      console.error(res.msg);
      return movimientos;
    }
  }
  obtenerCodMutual(nombreMutual: string): number {
    console.log({ nombreMutual });
    switch (nombreMutual) {
      case 'Asociación Chilena de Seguridad':
        return 1;
      case 'Mutual de Seguridad CCHC':
        return 2;
      case 'Instituto de Seguridad del Trabajo':
        return 3;
      default:
        return 0;
    }
  }
  obtenerSCesantiaEmpleador(estado_contrato: string): number {
    console.log({ estado_contrato });
    if (estado_contrato === 'Contrato Indefinido') {
      return Math.round(this.registroArchivoPrevired.rentImponible * 0.024);
    }
    return 0;
  }
  generarTextoCSV(registros: ArchivoPrevired[]) {
    registros.sort((a, b) => a.rutSDV - b.rutSDV);
    const timestap = new Date().getTime().toString();
    const jsonString = JSON.stringify(registros);
    const jsonRegistro = JSON.parse(jsonString);
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, jsonRegistro, { skipHeader: this.quitarCabecera });
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ';' });
    const arrayBufferCSV = this.convertirCSVtoFile(csv);
    const fileName = timestap;
    saveAs(new Blob([arrayBufferCSV], { type: "application/octet-stream" }), fileName + '.txt');
  }
  convertirCSVtoFile(csv: string): ArrayBuffer {
    const buf = new ArrayBuffer(csv.length);
    const view = new Uint8Array(buf);
    for (var i = 0; i != csv.length; ++i) view[i] = csv.charCodeAt(i) & 0xFF;
    return buf;
  }
  async obtenerPrevired(periodo: Date) {
    // console.log('Periodo: ', {periodo});
    try {
      const month = periodo.getMonth() + 1;
      const monthString = month < 10 ? '0' + month : month.toString();
      const data = await this.cargarPrevired(monthString, periodo.getFullYear());
      if (!data.message) {
        const previred: Previsional = data;
        previred.UFValPeriodoAnt = previred.UFValPeriodoAnt.replace(',', '.');
        previred.UFValPeriodo = previred.UFValPeriodo.replace(',', '.');
        this.ufActual = parseFloat(previred.UFValPeriodo);
      } else {
        const res = await this.modLiquidaciones.TraerPreviredLiquidacion();
        if (res.success) {
          const previred = res.data;
          this.ufActual = parseFloat(previred.ufMesActual);
        }else{
          console.error(res.msg);
        }
      }
    } catch (error) {
      try {
        const res = await this.modLiquidaciones.TraerPreviredLiquidacion();
        if (res.success) {
          const previred = res.data;
          this.ufActual = parseFloat(previred.ufMesActual);
        }else{
          console.error(res.msg);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  async cargarPrevired(month: string, year: number): Promise<any>{
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const result = await fetch('https://api.gael.cloud/general/public/previred/' + month + year);
        const data = await result.json();
        resolve(data);
      }, 1100)
    })
  }
}
