import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-main-recaudaciones',
  templateUrl: './main-recaudaciones.component.html',
  styleUrls: ['./main-recaudaciones.component.css']
})
export class MainRecaudacionesComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    
  }

}
