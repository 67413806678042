import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ImplementosUService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper)  { }

//   EnviarArchivo(archivo:any){
//     return this.http
//     .post<RequestResponse >(`${environment.API}/rrhh/licencias/documento`,archivo)
//     .pipe(
//       map((res:RequestResponse) =>{
//         return res;
//       }),
//       catchError((err)=> this.handlerError(err))
//     );
//   }

  // CrearImplementos(Implementos:any) {
  //   return this.http
  //   .post<RequestResponse >(`${environment.API}/rrhh/implementos/crear`,Implementos)
  //   .pipe(
  //     map((res:RequestResponse) =>{
  //       return res;
  //     }),
  //     catchError((err)=> this.handlerError(err))
  //   );
  // }

TraerArchivo(id:any): Observable<RequestResponse> {

  return this.http
  .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarImplementosUid/${id}`)
  .pipe(
    map((res:RequestResponse) =>{ 
      return res;
    }),
    catchError((err)=> this.handlerError(err))
  );
}

  TraerPorRutU(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/implementosU/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  
//   TraerArchivo(id:any): Observable<RequestResponse> {
//     return this.http
//     .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarlicenciaid/${id}`)
//     .pipe(
//       map((res:RequestResponse) =>{ 
//         return res;
//       }),
//       catchError((err)=> this.handlerError(err))
//     );
//   }

eliminar(id:any): Observable<RequestResponse> {
  return this.http
  .delete<RequestResponse >(`${environment.API}/rrhh/implementosU/eliminar/${id}`)
  .pipe(
    map((res:RequestResponse) =>{
      return res;
    }),
    catchError((err)=> this.handlerError(err))
  );
}


CrearUniforme(ImplementosU:any) {
  return this.http
  .post<RequestResponse >(`${environment.API}/rrhh/implementosU/crear`,ImplementosU)
  .pipe(
    map((res:RequestResponse) =>{
      return res;
    }),
    catchError((err)=> this.handlerError(err))
  );
}

  TraerTodoU():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/implementosU`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  actualizarEstadoU(implementosU:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/implementosU/actualizarEstadoU/ ${implementosU.id}`,implementosU)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  
  actualizar(implementosU:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/implementosU/actualizar/ ${implementosU.id}`,implementosU)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  

//   TraerLicencia(id:any): Observable<RequestResponse> {
//     return this.http
//     .get<RequestResponse >(`${environment.API}/rrhh/licencias/buscarporid/${id}`)
//     .pipe(
//       map((res:RequestResponse) =>{ 
//         return res;
//       }),
//       catchError((err)=> this.handlerError(err))
//     );
//   }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }






}
