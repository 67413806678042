import { Component, OnInit } from '@angular/core';
import { JornadaLaboralService } from 'src/app/pages/services/jornada-laboral.service';
import { JornadaLaboral } from 'src/app/shared/models/jornada-laboral.interface';

@Component({
  selector: 'app-main-jornada-laboral',
  templateUrl: './main-jornada-laboral.component.html',
  styleUrls: ['./main-jornada-laboral.component.css']
})
export class MainJornadaLaboralComponent implements OnInit {
  jornadas:JornadaLaboral[] = [];

  constructor(
    private JornadaLaboralService: JornadaLaboralService
  ) { }

  ngOnInit(): void {
    this.JornadaLaboralService.GetAll_jornadas().subscribe(
      (res) => {
        this.jornadas = res.data;
      }
    );
  }

  actualizarTabla(event:boolean){
    this.JornadaLaboralService.GetAll_jornadas().subscribe(
      (res) => {
        this.jornadas = res.data;
      }
    );
  }
}
