<div class="animate__animated animate__fadeInRight">
    <div class="col">
        <mat-card class="mat_card_trabajadores">
            <h1 class>Modulo de Contrato</h1>
        </mat-card>
    </div>
    <br/>
    <div class="col">
        <p-card>
            <p-tabView>
                <p-tabPanel header="Formando estándar">
                    <div class="animate__animated animate__fadeInRight">
                        <app-formato-estandar (EventoRut)="RecibirEvento($event)"></app-formato-estandar>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Formato Editable">
                    <div class="animate__animated animate__fadeInRight">
                        <app-formato-editable (EventoRut)="RecibirEvento($event)"></app-formato-editable>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-card>
    </div>
</div>