import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { FormsModule } from '@angular/forms';
import { MainSucursalesComponent } from './main-sucursales/main-sucursales.component';
import { AgregarSucursalesComponent } from './agregar-sucursales/agregar-sucursales.component';
import { ListaSucursalesComponent } from './lista-sucursales/lista-sucursales.component';


@NgModule({
  declarations: [
    MainSucursalesComponent, 
    AgregarSucursalesComponent,
    ListaSucursalesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PrimeNgModule,
  ],
  exports:[
    MainSucursalesComponent, 
    AgregarSucursalesComponent,
    ListaSucursalesComponent
  ]
})
export class SucursalesModule { }
