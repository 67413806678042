<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-hearder>Fecha</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.fecha |date:'d/M/yyyy'}}
            </td>
        </ng-container> 

        <ng-container matColumnDef="fk_trabajador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trabajador</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.fk_trabajador}}
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="nombreTrabajador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Trabajador</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.nombreTrabajador }}
            </td>
        </ng-container> -->

        <ng-container matColumnDef="sueldo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sueldo</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.sueldo}}
            </td>
        </ng-container>

        <ng-container matColumnDef="bonos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bonos</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.bonos}}
            </td>
        </ng-container>

        <ng-container matColumnDef="adelanto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Adelanto</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.adelanto }}
            </td>
        </ng-container>

        <ng-container matColumnDef="prestamos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Préstamo</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.prestamos }}
            </td>
        </ng-container>

        <ng-container matColumnDef="inasistencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inasistencia</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.inasistencia }}
            </td>
        </ng-container>

        <ng-container matColumnDef="asignacionFamiliar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Asignación familiar</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.asignacionFamiliar }}
            </td>
        </ng-container>

        <ng-container matColumnDef="sueldoLiquido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sueldo líquido</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.sueldoLiquido }}
            </td>
        </ng-container>

        <ng-container matColumnDef="totalAPagar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>total a pagar</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.totalAPagar }}
            </td>
        </ng-container>

        <ng-container matColumnDef="transferencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Transferencia</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.transferencia }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button (click)="open(content, element.id)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">edit</mat-icon>
                </button> &nbsp;
                <button (click)="eliminar_planilla(content, element.id)" mat-button class="button_color_primary"
                    style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
    </mat-paginator>
</div>

<!-- Actualizar datos de planilla -->

<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Actualizar Planilla</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="row">

                <div class="col-12">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Nombre del trabajador</mat-label>
                        <mat-select  name="planilla.fk_trabajador"  [(ngModel)]="update_planilla.fk_trabajador" (selectionChange)="ObtenerTrabajador($event)">
                            <mat-option *ngFor="let element of trabajador" [value]="element.rut">
                              {{element.nombres}} {{element.apellidos}}
                                 
                            </mat-option>
                        </mat-select>
                       
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Fecha inicio contrato</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="update_planilla.fecha"
                        [ngModelOptions]="{standalone: true}" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>
                  </div>





                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sueldo</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.sueldo"
                            [(ngModel)]="update_planilla.sueldo" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Bonos</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.bonos"
                            [(ngModel)]="update_planilla.bonos" />
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Adelanto</mat-label>
                        <input type="integer" maxlength="7" minlength="3" matInput name="planilla.adelanto"
                            [(ngModel)]="update_planilla.adelanto" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Préstamo</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.prestamos"
                            [(ngModel)]="update_planilla.prestamos" />
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Inasistencia</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.inasistencia"
                            [(ngModel)]="update_planilla.inasistencia" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row">

                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>asignación familiar</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.inasistencia"
                            [(ngModel)]="update_planilla.inasistencia" />
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Sueldo Líquido</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.sueldoLiquido"
                            [(ngModel)]="update_planilla.sueldoLiquido" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">

                <div class="col-6">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Total a pagar</mat-label>
                        <input type="number" maxlength="7" minlength="3" matInput name="planilla.totalAPagar"
                            [(ngModel)]="update_planilla.totalAPagar" />
                    </mat-form-field>
                </div>
                    <div class="col-6">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Transferencia</mat-label>
                            <input type="number" maxlength="7" minlength="3" matInput name="planilla.transferencia"
                                [(ngModel)]="update_planilla.transferencia" />
                        </mat-form-field>
                    </div>
               

            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="actualizar_planilla()">
            Actualizar
        </button>
    </div>
</ng-template>