import { Component, OnDestroy, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Swal from 'sweetalert2';
import { RegionesService } from 'src/app/pages/services/regiones.service';
import { Region } from 'src/app/shared/models/region.interface';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-lista-ciudades',
  templateUrl: './lista-ciudades.component.html',
  styleUrls: ['./lista-ciudades.component.css']
})
export class ListaCiudadesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  optionsNumeric = {
    digitGroupSeparator: '', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  regiones:Region[] = [];

  fileName = 'ciudades.xlsx';
  exportexcel(): void {
    let element = document.getElementById('tabla');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  public convetToPDF() {

    var datos = [];
    datos.push(['ID', 'NOMBRE']);

    this.ciudades_List.map(function (item) {

      datos.push([item.id, item.nombre]);
    });


    var documentDefinition = {



      content: [

        { text: 'Ciudades', style: 'header', fontSize: 30 },
        {
          table: {
            widths: [50, 400],
            body: datos

          }
        }
      ]
    };
    pdfMake.createPdf(documentDefinition).open();
  }

  @Input() ciudades_List: Ciudad[] = [];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  update_ciudades: Ciudad = {
    id: 0,
    nombre: "",
    codigo_lre: 0,
    idregion: 0,
  };

  displayedColumns: string[] = ['id', 'nombre', 'codigo', 'actions'];
  dataSource = new MatTableDataSource<Ciudad>(this.ciudades_List);

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private ciudadService: CiudadService,
    private regionService: RegionesService,
  ) { }


  ngAfterViewInit(): void {

  }


  ngOnInit(): void {

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.regionService.TraerRegiones().subscribe(res => {
      if (res.success) {
        this.regiones = res.data;
      } else {
        this.modalService.dismissAll();
        this.alert.error_small('Ocurrio un error al obtener las regiones');
        console.error(res.msg);
      }
    })
  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes.ciudades_List != undefined) {
      if (!changes.ciudades_List.firstChange) {
        this.dataSource = new MatTableDataSource<Ciudad>(this.ciudades_List);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }
    }
  }

  open(content: any, id: any) {
    this.update_ciudades.id = id
    this.ciudadService.Obtener_ciudad(id).subscribe(res => {
      this.update_ciudades = res.data;
    })

    this.limpiar_ciudades();
    this.modalService.open(content, {size: 'lg'});

  }

  limpiar_ciudades() {
    this.update_ciudades.nombre = ""
  }

  Actualizar_Ciudades() {
    if (this.update_ciudades.nombre.trim().length == 0) {
      this.alert.error_small('Los campos no pueden estar vacios.')
      return
    }

    this.ciudadService.Actualizar_ciudad(this.update_ciudades).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.limpiar_ciudades();
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }

  Eliminar_Ciudad(content: any, id: any) {

    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara la ciudad",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ciudadService.Eliminar_Ciudad(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {

  }

}
