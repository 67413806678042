<!-- 

<button mat-raised-button (click)="addColumn()"> Add column </button>
<button mat-raised-button (click)="removeColumn()"> Remove column </button>
<button mat-raised-button (click)="shuffle()"> Shuffle </button>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
    <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table> -->


<div class="animate__animated animate__slideInUp  border border-primary">


    <mat-card>
        <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
            Implementos
        </h3>


        <table mat-table id="tabla" matSort [dataSource]="dataSource">

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> Fecha Entrega </th>
                <td mat-cell *matCellDef="let element">{{ element.fechaI | date :'dd/MM/yyyy' }} </td>
            </ng-container>


            <ng-container matColumnDef="equipo">
                <th mat-header-cell *matHeaderCellDef> Equipo</th>
                <td mat-cell *matCellDef="let element"> {{ element.equipo }} </td>
            </ng-container>

            <ng-container matColumnDef="modelo">
                <th mat-header-cell *matHeaderCellDef> Modelo </th>
                <td mat-cell *matCellDef="let element"> {{ element.modelo }} </td>
            </ng-container>
            <ng-container matColumnDef="equipoo">
                <th mat-header-cell *matHeaderCellDef> Equipo</th>
                <td mat-cell *matCellDef="let element"> {{ element.equipoo}} </td>
            </ng-container>

            <ng-container matColumnDef="modeloo">
                <th mat-header-cell *matHeaderCellDef> Modelo</th>
                <td mat-cell *matCellDef="let element"> {{ element.modeloo }} </td>
            </ng-container>
            <ng-container matColumnDef="equipo2">
                <th mat-header-cell *matHeaderCellDef> Equipo</th>
                <td mat-cell *matCellDef="let element"> {{ element.equipo2 }} </td>
            </ng-container>

            <ng-container matColumnDef="modelo2">
                <th mat-header-cell *matHeaderCellDef> Modelo </th>
                <td mat-cell *matCellDef="let element"> {{ element.modelo2 }} </td>
            </ng-container>

            <ng-container matColumnDef="equipo3">
                <th mat-header-cell *matHeaderCellDef> Equipo</th>
                <td mat-cell *matCellDef="let element"> {{ element.equipo3 }} </td>
            </ng-container>

            <ng-container matColumnDef="modelo3">
                <th mat-header-cell *matHeaderCellDef> Modelo </th>
                <td mat-cell *matCellDef="let element"> {{ element.modelo3 }} </td>
            </ng-container>

            <ng-container matColumnDef="equipo4">
                <th mat-header-cell *matHeaderCellDef> Equipo</th>
                <td mat-cell *matCellDef="let element"> {{ element.equipo4 }} </td>
            </ng-container>

            <ng-container matColumnDef="modelo4">
                <th mat-header-cell *matHeaderCellDef> Modelo </th>
                <td mat-cell *matCellDef="let element"> {{ element.modelo4 }} </td>
            </ng-container>

            <ng-container matColumnDef="equipo5">
                <th mat-header-cell *matHeaderCellDef> Equipo</th>
                <td mat-cell *matCellDef="let element"> {{ element.equipo5 }} </td>
            </ng-container>

            <ng-container matColumnDef="modelo5">
                <th mat-header-cell *matHeaderCellDef> Modelo </th>
                <td mat-cell *matCellDef="let element"> {{ element.modelo5 }} </td>
            </ng-container>
         



            <ng-container matColumnDef="observacion">
                <th mat-header-cell *matHeaderCellDef>Observacion </th>
                <td mat-cell *matCellDef="let element"> {{ element.observacion }} </td>
            </ng-container>


            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef>Estado </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.estadoI === 'PENDIENTE'" style="color: rgb(214, 208, 54)">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{{ element.estadoI }}</div>
               
                    </span>
                    <span *ngIf="element.estadoI === 'LISTO'" style="color: rgb(255, 255, 255)">
                      
                        <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{{ element.estadoI }}</div>
                  
                    </span>

                </td>
            </ng-container>


            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>

                <td class="padding-table" mat-cell *matCellDef="let element">

                    <button mat-button (click)="updated(upp,element )" class="button_color_primary"
                        style="background-color:rgb(224, 232, 234)">
                        <mat-icon style="color: rgb(35, 35 ,206)">edit</mat-icon>
                    </button> &nbsp;
                    <span *ngIf="element.estadoI === 'PENDIENTE'" style="color:rgb(24, 209, 49)" >
                        

                        <button mat-button (click)="update(up,element)" class="button_color_primary"matBadge="1"
                        style="background-color:rgb(224, 232, 234)">Subir
                        <mat-icon style="color: rgb(24, 209, 49)">upload</mat-icon>
                    </button> &nbsp;
                    </span>
                    <span *ngIf="element.estadoI === 'LISTO' " style="color: rgb(255, 0, 0)" >
                        
                        <button mat-button (click)="update(up,element)" class="button_color_primary"
                        style="background-color:rgb(224, 232, 234)">Actualizar
                        <mat-icon style="color: rgb(255, 0, 0)">upload</mat-icon>
                    </button> &nbsp;
                    </span>
              
                    <button mat-button (click)="eliminar(contenido,element.id)" class="button_color_primary"
                        style="background-color:rgb(223, 233, 235)">
                        <mat-icon style="color: rgb(236, 18, 18)">delete</mat-icon>
                    </button> &nbsp;
                    <button mat-button (click)="Descargar(element.id)" class="button_color_primary" style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">search</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1]" showFirstLastButtons
            aria-label="Seleccionar pagina de Trabajadores.">
        </mat-paginator>
    </mat-card>

    <ng-template #up let-modal3>
        <div class="modal-header" style="text-align: center">
            <h1>Subir </h1>
            <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    
        <mat-card>
            <p>
                <label>Subir comprobante implementos &nbsp;</label>
                <input mat-input required type="file" ng2FileSelect [uploader]="uploader"   />

            </p>
        </mat-card> 

        <div *ngIf=" proceso == 1">
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">100%</div>
              </div>
        </div>
      <br>


        <button mat-raised-button color="primary" [disabled]="!uploader.getNotUploadedItems().length" (click)="guardar()">

            <mat-icon>backup</mat-icon>
        </button>
    </ng-template>

    <ng-template #upp let-modal4>
        <div class="modal-header" style="text-align: center">
            <h1>Actualizar</h1>
            <button type="button" class="close" aria-label="Close" (click)="modal4.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="container"> 
        <div class="row">
            <div class="col">


                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Fecha </mat-label>
                    <input matInput [matDatepicker]="picker"  name="implementos_update.fechaI" [value]="implementos_update.fechaI" [(ngModel)]="implementos_update.fechaI">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                    <!-- fecha de entrada y fecha de salida  -->
                </mat-form-field>

          
        </div>
    </div>

        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.equipo"
                        [(ngModel)]="implementos_update.equipo" />
                </mat-form-field>
            </div>
                <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.modelo"
                        [(ngModel)]="implementos_update.modelo" />
                </mat-form-field>
            </div>

        </div>
        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.equipoo"
                        [(ngModel)]="implementos_update.equipoo" />
                </mat-form-field>

            </div>
            <div class="col">
                
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.modeloo"
                        [(ngModel)]="implementos_update.modeloo" />
                </mat-form-field>

            </div>

        </div>
        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.equipo2"
                        [(ngModel)]="implementos_update.equipo2" />
                </mat-form-field>

            </div>



            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.modelo2"
                        [(ngModel)]="implementos_update.modelo2" />
                </mat-form-field>

            </div>

        </div>
        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.equipo3"
                        [(ngModel)]="implementos_update.equipo3" />
                </mat-form-field>

            </div>
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.modelo3"
                        [(ngModel)]="implementos_update.modelo3" />
                </mat-form-field>

            </div>
        </div>
        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.equipo4"
                        [(ngModel)]="implementos_update.equipo4" />
                </mat-form-field>

            </div>
            <div class="col">
                
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.modelo4"
                        [(ngModel)]="implementos_update.modelo4" />
                </mat-form-field>

            </div>

        </div>
        <div class="row">
            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Equipo </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.equipo5"
                        [(ngModel)]="implementos_update.equipo5" />
                </mat-form-field>

            </div>
            <div class="col">
                
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Modelo o Nº Serie</mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.modelo5"
                        [(ngModel)]="implementos_update.modelo5" />
                </mat-form-field>

            </div>

            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Observacion</mat-label>
                <input type="text" minlength="0" maxlength="500" matInput [value]="implementos_update.observacion"
                    [(ngModel)]="implementos_update.observacion" />
            </mat-form-field>
        </div>
     

        </div>
        <div class="col " appearance="outline">
        <button mat-button (click)="actualizar( )" class="button_color_primary"
        style="background-color:rgb(224, 232, 234)">
        <mat-icon style="color: rgb(35, 35 ,206)">edit</mat-icon>
    </button> &nbsp;
</div>
    </ng-template>

  
</div>