<br />
<div style="margin-top: 10px; margin-bottom: 10px;">
    <div class="row">
        <div class="col-auto">
            <app-agregar-haberes (onDebounce)="actualizar($event)"></app-agregar-haberes>
        </div>
        <div class="col-auto">
            <button style="background-color: rgb(207, 51, 3);" class="text-light" (click)="convetToPDF()" mat-raised-button>Exportar a PDF</button>
            <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" class="text-light" (click)="exportexcel()" mat-raised-button
                color="green">Exportar a Excel</button> 
        </div>
    </div>
</div>

<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>

<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="codigo">
        <th mat-header-cell *matHeaderCellDef> CÓDIGO </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> NOMBRE </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
    </ng-container>
    <ng-container matColumnDef="descripcion">
        <th mat-header-cell *matHeaderCellDef> DESCRIPCIÓN </th>
        <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
    </ng-container>
    <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef> TIPO </th>
        <td mat-cell *matCellDef="let element"> {{element.tipo ? 'HABER':'DESCUENTO'}} </td>
    </ng-container>
    <ng-container matColumnDef="imp">
        <th mat-header-cell *matHeaderCellDef> IMP </th>
        <td mat-cell *matCellDef="let element"> {{element.imponible ? 'SI':'NO'}} </td>
    </ng-container>
    <ng-container matColumnDef="trib">
        <th mat-header-cell *matHeaderCellDef> TRIB </th>
        <td mat-cell *matCellDef="let element"> {{element.tributable ? 'SI':'NO'}} </td>
    </ng-container>
    <ng-container matColumnDef="grat">
        <th mat-header-cell *matHeaderCellDef> GRAT. </th>
        <td mat-cell *matCellDef="let element"> {{element.gratificacion ? 'SI':'NO'}} </td>
    </ng-container>
    <ng-container matColumnDef="fijo">
        <th mat-header-cell *matHeaderCellDef> FIJO </th>
        <td mat-cell *matCellDef="let element"> {{element.remCaractFijo ? 'SI':'NO'}} </td>
    </ng-container>
    <ng-container matColumnDef="var">
        <th mat-header-cell *matHeaderCellDef> VAR. </th>
        <td mat-cell *matCellDef="let element"> {{element.remCaractVariable ? 'SI':'NO'}} </td>
    </ng-container>
    <ng-container matColumnDef="sem">
        <th mat-header-cell *matHeaderCellDef> SEM. </th>
        <td mat-cell *matCellDef="let element"> {{element.bSemanaCorrida ? 'SI':'NO'}} </td>
    </ng-container>
    <ng-container matColumnDef="prop">
        <th mat-header-cell *matHeaderCellDef> PROP. </th>
        <td mat-cell *matCellDef="let element"> {{element.proporcional ? 'SI':'NO'}} </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button class="button_color_primary" (click)="open(content,element.id)">
                <mat-icon style="color: white">edit</mat-icon>
            </button> &nbsp;
            <button mat-button class="button_color_primary" style="background-color:red" (click)='eliminar(element.id)'>
                <mat-icon style="color: white">delete</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de haberes.">
</mat-paginator>


<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Haberes y Descuentos </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()">
            <div class="col">
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput maxlength="150" minlength="3" placeholder="Nombre"
                                    formControlName="nombre" />
                                <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Descripción</mat-label>
                                <textarea matInput type="textarea" rows="7" maxlength="25000" placeholder="Descripcion"
                                    formControlName="descripcion"></textarea>
                                <mat-error *ngIf="addressForm.controls['descripcion'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </div>


            <div class="col">
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Tipo</mat-label>
                                <mat-select placeholder="Tipo de Haber" formControlName="tipo">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'HABER':'DESCUENTO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['tipo'].hasError('required')">
                                    tipo Haber es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>

                        </td>

                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Proporcinal</mat-label>
                                <mat-select placeholder="Proporcional" formControlName="proporcional">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['proporcional'].hasError('required')">
                                    Proporcional es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col">
                <table class="example-full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Imponible</mat-label>
                                <mat-select placeholder="Imponible?" formControlName="imponible">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['imponible'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Tributable</mat-label>
                                <mat-select placeholder="Tributable?" formControlName="tributable">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['tributable'].hasError('required')">Este campo es
                                    <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Gratificacíon</mat-label>
                                <mat-select placeholder="Gratificacion?" formControlName="gratificacion">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['gratificacion'].hasError('required')">
                                    tipo pago es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>REMUN. DE CARÁCTER FIJA (1)</mat-label>
                                <mat-select placeholder="REMUN. DE CARÁCTER FIJA (1)" formControlName="remCaractFijo">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['remCaractFijo'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>REMUN. DE CARÁCTER VARIABLE (2)</mat-label>
                                <mat-select placeholder="REMUN. DE CARÁCTER VARIABLE (2)"
                                    formControlName="remCaractVariable">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['remCaractVariable'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>BENEFICIO SEMANA CORRIDA (3)</mat-label>
                                <mat-select placeholder="BENEFICIO SEMANA CORRIDA (3)" formControlName="bSemanaCorrida">
                                    <mat-option *ngFor="let opcion of opciones" [value]="opcion">
                                        {{ opcion ? 'SI':'NO' }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['bSemanaCorrida'].hasError('required')">
                                    Este campo es <strong>requerido.</strong>
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <p-dropdown inputId="dropdown" [options]="conceptos_filter" formControlName="idconceptolre"
                                optionLabel="nombre" optionValue="id" [filter]="true" filterBy="nombre"
                                [showClear]="true" styleClass="w-100 mt-2" placeholder="CONCEPTO LRE (4)">
                            </p-dropdown>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-12">
                <br>
                <label>OBS: (1) Remuneración que forma parte del sueldo base, utilizada para el cálculo de H. Extras y
                    Ley 20.281</label>
                <br>
                <label>OBS: (2) Se usa para el ajuste del Sueldo Base Ley 20.281</label>
                <br>
                <label>OBS: (3) Nuevo concepto Ley 20.281.</label>
                <br>
                <label>OBS: (4) Conceptos del Libro de Remuneraciones Electronico.</label>
                <br>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">Cancelar</span>
                </button>
                <button type="button" class="btn btn-outline-success" type="submit">Guardar
                </button>
            </div>


        </form>
    </div>
</ng-template>