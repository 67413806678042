<div div class="row container-fluid">
    <div class="col-12">
        <app-agregar-gestorasistencia (onDebounce)="TraerDatos()"></app-agregar-gestorasistencia>
        <!-- <button style="background-color: rgb(207, 51, 3);"  mat-raised-button>Exportar a PDF</button>    (click)="convetToPDF()" -->
        <!-- <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" mat-raised-button color="green">Exportar a Excel</button>  ((click)="exportexcel()" -->
    </div>
    <div class="col-3">
        <p-dropdown [options]="trabajadoresFilter" [(ngModel)]="trabajadorSelect" [filter]="true" filterBy="value" (onChange)="FiltrarDatos()"
            [showClear]="true" placeholder="Trabajador" optionLabel="value" optionValue="value" [style]="{ width: '100%' }">
        </p-dropdown>
    </div>
    <div class="col-3">
        <p-dropdown [options]="razonesFilter" [(ngModel)]="razonSelect" [filter]="true" filterBy="value" (onChange)="FiltrarDatos()"
            [showClear]="true" placeholder="Razon" optionLabel="value" optionValue="value" [style]="{ width: '100%' }">
        </p-dropdown>
    </div>
    <div class="col-2">
        <div class="p-inputgroup">
            <button type="button" pButton pRipple icon="pi pi-chevron-left" styleClass="p-button-primary" (click)="prevMonth()"></button>   
            <p-calendar [(ngModel)]="periodoSelect" appendTo="body" view="month" dateFormat="mm/yy" styleClass="w-100"
                (onSelect)="FiltrarDatos()" (onClear)="FiltrarDatos()" [readonlyInput]="true" [maxDate]="maxDateValue">
            </p-calendar>
            <button type="button" pButton pRipple icon="pi pi-chevron-right" styleClass="p-button-primary" (click)="afterMonth()"></button>   
        </div>
    </div>
    <div class="col-2">
        <button pButton pRipple type="button" class="p-button-raised p-button-danger" (click)="RestablecerFiltro()"
            pTooltip="Restablecer filtro" tooltipPosition="bottom">
            <mat-icon>
                filter_alt_off
            </mat-icon>
        </button>
    </div>
    <div class="col-md-2">
        <mat-form-field appearance="outline" [style]="{ width: '100%' }">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
    </div>
</div>


<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="trabajador">
        <th mat-header-cell *matHeaderCellDef> TRABAJADOR </th>
        <td mat-cell *matCellDef="let element"> {{element.Trabajador.nombres + ' ' + element.Trabajador.apellidos |
            uppercase}} </td>
    </ng-container>
    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef> FECHA INICIO </th>
        <td mat-cell *matCellDef="let element"> {{element.start| date :'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef> FECHA FIN </th>
        <td mat-cell *matCellDef="let element"> {{element.end| date: 'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="dias_faltados">
        <th mat-header-cell *matHeaderCellDef> DIAS FALTADOS </th>
        <td mat-cell *matCellDef="let element"> {{element.dias_faltados }} </td>
    </ng-container>
    <ng-container matColumnDef="razon">
        <th mat-header-cell *matHeaderCellDef> RAZÓN </th>
        <td mat-cell *matCellDef="let element"> {{element.Razon.razon | uppercase}} </td>
    </ng-container>
    <ng-container matColumnDef="motivo">
        <th mat-header-cell *matHeaderCellDef> MOTIVO</th>
        <td mat-cell *matCellDef="let element"> {{element.motivo | uppercase}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="dsueldo">
        <th mat-header-cell *matHeaderCellDef> DSCTO SUELDO</th>
        <td mat-cell *matCellDef="let element"> {{element.descuento_sueldo}} </td>
    </ng-container>
    <ng-container matColumnDef="dbono">
        <th mat-header-cell *matHeaderCellDef> DSCTO BONO</th>
        <td mat-cell *matCellDef="let element"> {{element.descuento_bono}} </td>
    </ng-container>
    <ng-container matColumnDef="tbono">
        <th mat-header-cell *matHeaderCellDef> % BONO</th>
        <td mat-cell *matCellDef="let element"> {{element.tbono}} </td>
    </ng-container>
    <ng-container matColumnDef="tsueldo">
        <th mat-header-cell *matHeaderCellDef> % SUELDO</th>
        <td mat-cell *matCellDef="let element"> {{element.tsueldo}} </td>
    </ng-container> -->
    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
            <button mat-button class="button_color_primary" *ngIf="(user.rol !== 'ASISTENCIA')" (click)="EditarDatos(content,element.cp_calendario)">
                <mat-icon style="color: white">edit</mat-icon>
            </button> &nbsp;
            <button mat-button class="button_color_primary" *ngIf="(user.rol !== 'ASISTENCIA')" style="background-color:red"
                (click)="Eliminar(element.cp_calendario)">
                <mat-icon style="color: white">delete</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons
    aria-label="Seleccionar pagina de horas extras.">
</mat-paginator>


<!--

███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗         ███████╗██████╗ ██╗████████╗
████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║         ██╔════╝██╔══██╗██║╚══██╔══╝
██╔████╔██║██║   ██║██║  ██║███████║██║         █████╗  ██║  ██║██║   ██║
██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║         ██╔══╝  ██║  ██║██║   ██║
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗    ███████╗██████╔╝██║   ██║
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝    ╚══════╝╚═════╝ ╚═╝   ╚═╝


-->


<ng-template class="animate__zoomIn" style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Editar inasistencia</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-6" *ngIf="trabajadores_aux.length > 0">
                <!-- (onChange)="ObtenerTrabajador($event)" -->
                <label for="dropdown" class="fw-bold">Trabajador</label>
                <p-dropdown id="dropdown" [options]="trabajadores_aux" placeholder="Seleccione trabajador"
                    optionLabel="text" optionValue="value" emptyFilterMessage="Sin resultados de trabajadores"
                    styleClass="example-full-width" [(ngModel)]="asistencia.fk_trabajador"
                    [filter]="true" filterBy="text" [showClear]="true">
                </p-dropdown>
            </div>
            <div class="col-6">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Razón</mat-label>
                    <mat-select [(ngModel)]="asistencia.fk_razon" name="id">
                        <mat-option *ngFor="let element of lista_razones" [value]="element.id">
                            {{element.razon}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="render==0">
            <div class="col-12">
                <!-- [comparisonStart]="campaignTwo.value.start" [comparisonEnd]="campaignTwo.value.end" -->
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Fecha inasistencia (Rango)</mat-label>
                    <mat-date-range-input [formGroup]="fechaDesdeHasta" [rangePicker]="fechaPicker">
                        <input matStartDate placeholder="Ingrese fecha inasistencia desde" formControlName="start" (click)="fechaPicker.open()">
                        <input matEndDate placeholder="Ingrese fecha inasistencia hasta" formControlName="end" (click)="fechaPicker.open()">
                    </mat-date-range-input>
                    <!-- <mat-hint>{{fechaDesdeHasta.value.start | date }} - {{ fechaDesdeHasta.value.end | date }}</mat-hint> -->
                    <mat-datepicker-toggle matSuffix [for]="fechaPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #fechaPicker></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="render==1">
            <div class="col-6">
                <div class="input-group">
                    <span class="input-group-text border-0 w-100 mb-2">Fecha de atraso</span>
                    <input class="form-control example-full-width pt-2 pb-2" placeholder="Ingrese fecha de atraso"
                        name="dp" placement="bottom" [(ngModel)]="modelFecha" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            >
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group">
                    <span class="input-group-text border-0 w-100 mb-2">HH:MM Inicio</span>
                    <ngb-timepicker [(ngModel)]="timeStart"></ngb-timepicker>
                </div>
            </div>
            <div class="col-3">
                <div class="input-group">
                    <span class="input-group-text border-0 w-100 mb-2">HH:MM Llegada</span>
                    <ngb-timepicker [(ngModel)]="timeEnd"></ngb-timepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>MOTIVO</mat-label>
                    <textarea type="textarea" rows="6" maxlength="100" matInput name="motivo"
                        [(ngModel)]="asistencia.motivo"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" (click)="Actualizar()" class="btn btn-outline-success">Actualizar</button>
    </div>
</ng-template>