import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/pages/services/helper/helper.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';

@Component({
  selector: 'app-haberes-trabajador',
  templateUrl: './haberes-trabajador.component.html',
  styleUrls: ['./haberes-trabajador.component.css']
})
export class HaberesTrabajadorComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private helperService: HelperService,
  ) { }

  dataSource = new MatTableDataSource();
  lista_trabajadores:any;
  displayedColumns = [
    'rut',
    'nombres',
    'empresa',
    'estado_contrato',
    'acciones'
  ];
  rut_trabajador:any;
  viewFiniquitados: boolean = false;

  // FILTERS
  trabajadorSelect: string = '';
  empresaSelect: string = '';
  estadoContratoSelect: string = '';
  // DROPDOWS
  trabajadoresFilter: any[] = [];
  empresaFilter: any[] = [];
  estadoContratoFilter: any[] = [];

  ngOnInit(): void {
    this.TraerDatos();
  }


  TraerDatos(finiquitados: boolean = false) {

    this.trabajadoresService.TraerTrabajadores().subscribe(
      (res) => {
        this.lista_trabajadores = res.data;
        if (!finiquitados) {
          this.lista_trabajadores = this.lista_trabajadores.filter(x => x.estado_contrato !== 'Finiquitado');
        }
        this.lista_trabajadores.map(function (dato: any) {
          if (dato.estado_contrato == 1) {
            dato.estado_contrato = "Contrato A Plazo Fijo ";
          }
          if (dato.estado_contrato == 2) {
            dato.estado_contrato = "Contratado ";
          }
          if (dato.estado_contrato == 3) {
            dato.estado_contrato = "Contrato Por Proyecto";
          }
          if (dato.estado_contrato == 4) {
            dato.estado_contrato = "Finiquitado";
          }
          if (dato.estado_contrato == 5) {
            dato.estado_contrato = "freelance";
          }
          if (dato.estado_contrato == 6) {
            dato.estado_contrato = "Honorarios";
          }
          return dato;
        });
        this.FiltrarDatos();
      }
    );
  }
  FiltrarDatos(){
    let listaTrabajadorFilter = this.lista_trabajadores;
    if (this.empresaSelect !== '' && this.empresaSelect) {
      listaTrabajadorFilter = listaTrabajadorFilter.filter(
        x => x.Empresa_contratante.nombre === this.empresaSelect
      );
    }
    if (this.trabajadorSelect !== '' && this.trabajadorSelect) {
      listaTrabajadorFilter = listaTrabajadorFilter.filter(
        x => x.rut.toString() === this.trabajadorSelect
      );
    }
    if (this.estadoContratoSelect !== '' && this.estadoContratoSelect) {
      listaTrabajadorFilter = listaTrabajadorFilter.filter(
        x => x.estado_contrato === this.estadoContratoSelect
      )
    }
    // console.log({listaTrabajadorFilter}, this.trabajadorSelect, this.empresaSelect, this.estadoContratoSelect)
    this.configurarFilter(listaTrabajadorFilter);
    this.inicializarTabla(listaTrabajadorFilter);
  }

  inicializarTabla(lista_trabajadores: any[]) {
    this.dataSource = new MatTableDataSource(lista_trabajadores);
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filterLower = filter.toLocaleLowerCase().split(' ');

      const nombre: string = data.nombres.toLowerCase() 
        + ' ' + data.apellidos.toLowerCase();
      const empresa: string = data.Empresa_contratante.nombre.toLowerCase();
      const estadoContrato: string = data.estado_contrato.toLowerCase();

      return filterLower.every(x => nombre.includes(x) 
        || empresa.includes(x) || estadoContrato.includes(x)) || filterLower === data.rut;
    }
    this.dataSource.paginator = this.paginator;
  }

  configurarFilter(lista_trabajadores: any[]) {
    const trabajadoresFilter = lista_trabajadores.map(
      x => ({
        value: x.rut.toString(),
        text: x.nombres.toUpperCase() + ' ' + x.apellidos.toUpperCase()})
    ).sort(
      (a, b) => a.text.localeCompare(b.text)
    );
    const empresaFilter = lista_trabajadores.map(x => ({
      value: x.Empresa_contratante.nombre, 
      text: x.Empresa_contratante.nombre.toUpperCase()}))
      .sort((a,b) => a.text.localeCompare(b.value));
    const estadoContratoFilter = lista_trabajadores.map(x => ({
      value: x.estado_contrato,
      text: x.estado_contrato.toUpperCase(),
    }));

    this.trabajadoresFilter = this.eliminarDuplicados(trabajadoresFilter);
    this.empresaFilter = this.eliminarDuplicados(empresaFilter);
    this.estadoContratoFilter = this.eliminarDuplicados(estadoContratoFilter);

    this.trabajadorSelect = this.trabajadoresFilter.length === 1 ? this.trabajadoresFilter[0].value.toString() : null;
    this.empresaSelect = this.empresaFilter.length === 1 ? this.empresaFilter[0].value : null;
    this.estadoContratoSelect = this.estadoContratoFilter.length === 1 ? this.estadoContratoFilter[0].value : null;
  }


  RestablecerFiltro(){
    this.trabajadorSelect = null;
    this.empresaSelect = null;
    this.estadoContratoSelect = null;
    this.TraerDatos(this.viewFiniquitados);
  }

  eliminarDuplicados(array: any[]) {
    const conjuntoDeValores = new Set();
    return array.filter(objeto => {
      const valor = objeto['value'];
      if (!conjuntoDeValores.has(valor)) {
          conjuntoDeValores.add(valor);
          return true;
      }
      return false;
    });
  }

  viewDatosFiniquitados(){
    this.TraerDatos(this.viewFiniquitados); 
  }

  Mostrar(content: any ,rut: any) {
    this.modalService.open(content, { fullscreen: true, scrollable: true });
    this.rut_trabajador=rut;
  }

  CerrarModal(){
    this.modalService.dismissAll();
  }

  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}