import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort';
import { ImplementoService } from 'src/app/pages/services/PDF-GENERADOR/implementos/implementos.service';
import { DatePipe } from '@angular/common';
import { UniformeService } from 'src/app/pages/services/PDF-GENERADOR/implementos/uniforme.service';
import { PrimeNGConfig } from 'primeng/api';
import { cleanRut } from 'rutlib/lib';

@Component({
  selector: 'app-lista-implementos',
  templateUrl: './lista-implementos.component.html',
  styleUrls: ['./lista-implementos.component.css'],
  providers: [DatePipe]
})
export class ListaImplementosComponent implements OnInit {

  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @Input() rut!: any;
  constructor(
    private modalService: NgbModal,
    private trabajadorService: TrabajadoresService,
    private imple: ImplementoService,
    private uni: UniformeService,
    private primengConfig: PrimeNGConfig
  ) {
  }


  /////—————  Variables   ————-\\\\\\
  src: any;
  dataSource: any;
  RutTrabajador: any;
  TrabajadorList: any[] = [];
  trabajadorFilter: any[] = [];
  mostrar: any = 1;
  checked: boolean = false;
  renderPdf = false;
  render = true;
  trabajadorListo: any;
  fecha = new Date();
  panelOpenState = false;
  ImplementosList: any;
  constt: any = 0;
  listo: any = 0;
  displayMaximizable: boolean;
  uniformee: boolean;
  implementoo: boolean;
  textSearch: string = '';
  num_trab_finiquitados: number = 0;
  ff: any = 0;

  /////————— Variable BD AND TABLA   ————-\\\\\\


  displayedColumns: string[] = [
    'rut',
    'nombres',
    'agregarI',
    'agregarU',
    'subir',
    'implementos',
    'uniforme'
  ]

  trabajador = {
    ciudad: '',
    nombre: '',
    apellido: '',
    fk_trabajador: 0,
    empresa: '',
    rol: '',
    cargos: ''
  };


  /////—————(👍≖‿‿≖)👍  FUNCIONES   👍(≖‿‿≖👍) ————-\\\\\\


  onChangeSlide($event: any) {

    if ($event.checked) {
      this.mostrar = 1
      this.checked = true
    }
    else {
      this.mostrar = 0
      this.checked = false

    }
  }


  open2(contenido: any, rut: any) {
    this.modalService.open(contenido, { size: 'lg' });
    this.RutTrabajador = rut;


  }

  open3(contenid: any, rut: any) {
    this.modalService.open(contenid, { size: 'lg' });
    this.RutTrabajador = rut;

  }



  open(content3: any, rut: any) {
    this.modalService.open(content3, { size: 'xl' });
    this.RutTrabajador = rut;

  }


  /////


  implemento() {
    this.implementoo = true;

  }


  uniforme() {
    this.uniformee = true;

  }



  TraerDatos(event: boolean) {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res) => {
        if (res.success) {
          this.TrabajadorList = res.data;
          this.trabajadorFilter = this.TrabajadorList.filter((x:any) => x.estado !== 0);
          this.num_trab_finiquitados = this.TrabajadorList.reduce((a,e) => e.estado === 0 ? a + 1 : a, 0);

          this.dataSource = new MatTableDataSource(this.trabajadorFilter);
          this.dataSource = this.configurarTabla(this.dataSource);
        }else{
          console.error(res.msg);
        }
      });
  }
  configurarTabla(dataSource: any): any {
    dataSource.paginator = this.paginator
    // CONFIGURA EL FILTRADO DE DATOS
    dataSource.filterPredicate = (data:any, filter:string) => {
      return this.configurarFilterPredicate(data, filter);
    };
    dataSource.sort = this.sort;
    return dataSource;
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLowerCase().split(' ');

    const rutSinPuntos: string = cleanRut(data.rut.toString());
    const nombre: string = this.normalizeText(data.nombres+ ' ' +data.apellidos).toLowerCase();

    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.rut.toString().includes(x) || nombre.includes(x)
    );
  }


  applyFilter(textSearch: string) {
    //Funcion para filtar cualquier columna por el texto buscado
    this.textSearch = textSearch
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  viewDatosFiniquitados(viewFiniquitados: boolean){
    this.trabajadorFilter = viewFiniquitados 
      ? this.TrabajadorList 
      : this.TrabajadorList.filter(x => x.estado !== 0);
    this.dataSource = new MatTableDataSource(this.trabajadorFilter);
    this.dataSource = this.configurarTabla(this.dataSource);
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }


  async printpdf(rut: any) {


    this.RutTrabajador = rut;

    this.trabajadorService.TraerTrabajadoresRut(this.RutTrabajador).subscribe(
      (res) => {


        //  console.log(res.data[0].Empresa_contratante.run);
        //  return

        this.trabajador.nombre = res.data[0].nombres;
        this.trabajador.apellido = res.data[0].apellidos;
        this.trabajador.ciudad = res.data[0].Ciudad.nombre;
        this.trabajador.fk_trabajador = res.data[0].rut;
        this.trabajador.empresa = res.data[0].Empresa_contratante.nombre;
        this.trabajador.rol = res.data[0].Empresa_contratante.run
        this.trabajador.cargos = res.data[0].cargo

        // console.log(this.trabajador);
        // return
        this.imple.GenerarPdf(this.trabajador);


        setTimeout(() => {
          this.render = true;
          this.renderPdf = true;
          this.src = this.imple.src;
          //console.log(this.vacacionesService.src);
        }, 1000);

      });
    this.render = false;

    //this.src= await this.vacacionesService.convetToPDF(this.trabajador);



  }

  async printpdfU(rut: any) {


    this.RutTrabajador = rut;


    this.trabajadorService.TraerTrabajadoresRut(this.RutTrabajador).subscribe(
      (res) => {


   
        this.trabajador.nombre = res.data[0].nombres;
        this.trabajador.apellido = res.data[0].apellidos;
        this.trabajador.ciudad = res.data[0].Ciudad.nombre;
        this.trabajador.fk_trabajador = res.data[0].rut;
        this.trabajador.empresa = res.data[0].Empresa_contratante.nombre;
        this.trabajador.rol = res.data[0].Empresa_contratante.run
        this.trabajador.cargos = res.data[0].Cargo.nombre

        // console.log(this.trabajador);
        // return
        this.uni.GenerarPdfU(this.trabajador);


        setTimeout(() => {
          this.render = true;
          this.renderPdf = true;
          this.src = this.imple.src;
          //console.log(this.vacacionesService.src);
        }, 1000);



      });
    this.render = false;

    //this.src= await this.vacacionesService.convetToPDF(this.trabajador);



  }

  // Función que elimina los diacríticos de las vocales de una cadena
  normalizeText(texto: string): string {
    return texto?.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }

  /////—————AL INICIAR EL MODULO   ————-\\\\\\



  ngOnInit(): void {

    this.primengConfig.ripple = true;
    this.TraerDatos(true);

  }
}