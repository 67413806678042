<div class="row justify-content-md-center">
    <div class="col-3">
        <label for="empresas" class="block">Empresas</label>
        <p-dropdown inputId="empresas" [options]="empresas!" [(ngModel)]="empresa" optionLabel="nombreView" 
            [filter]="true" filterBy="nombreView" styleClass="w-100" scrollHeight="auto"></p-dropdown>
    </div>
    <div class="col-2">
        <label for="tipos" class="block">Tipo Pago</label>
        <p-dropdown inputId="tipos" [options]="tiposPago!" [(ngModel)]="tipo" optionLabel="tipo" 
            [filter]="true" filterBy="tipo" styleClass="w-100" scrollHeight="auto"></p-dropdown>
    </div>
    <div class="col-2">
        <label class="block">Periodo</label>
        <div class="p-inputgroup">
            <button type="button" pButton pRipple icon="pi pi-arrow-left" styleClass="p-button" (click)="setPeriodo('BEFORE')"></button> 
            <p-calendar [(ngModel)]="periodo" dateFormat="MM yy" view="month" [readonlyInput]="true" styleClass="w-100"></p-calendar>
            <button type="button" pButton pRipple icon="pi pi-arrow-right" styleClass="p-button" (click)="setPeriodo('AFTER')"></button> 
        </div>
    </div>
</div>
<div class="row justify-content-md-center">
    <div class="col-2 d-flex justify-content-center">
        <p-button [label]="loading ? 'Generando...' : 'Generar Excel'" icon="pi pi-download" iconPos="left" [loading]="loading" (onClick)="generarExcelPago()"></p-button>
    </div>
</div>