import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formatRut } from 'rutlib';
import { Subject } from 'rxjs';
import { SolicitudDespidoService } from 'src/app/pages/services/solicitud/solicitud-despido.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-solicitud-despido',
  templateUrl: './listar-solicitud-despido.component.html',
  styleUrls: ['./listar-solicitud-despido.component.css']
})
export class ListarSolicitudDespidoComponent implements OnInit {

  constructor(
    private solicitudService: SolicitudDespidoService,
    private modalService: NgbModal,
    private trabajadorService: TrabajadoresService,
    private alert: AlertHelper,
    ) { }

    selectedValueTrabajador: number;
    selectedValue: number;
    trabajadores: any[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator

  @ViewChild(MatSort) sort!: MatSort;
  @Input() despidos: any[];

  debouncer: Subject<boolean> = new Subject();

  dataSource: any;

  enviado:boolean;

  ngOnInit(): void {
    
  }

  ngOnChanges(change: SimpleChanges){
    console.log(change)
    if (change.despidos) {
      this.dataSource = new MatTableDataSource<any[]>(change.despidos.currentValue);
      this.dataSource.paginator = this.paginator;

    }
  }

  update = {
    id: 0,
    nombre: "",
    razon: "",
    fk_trabajador: ""
  }

  displayedColumns = [
    'id',
    'rut',
    'nombre',
    'razon',
    'acciones',
  ];

  razon: string;
  solicitudes:any;

  onTrabajadorChange(event:any): void{
    this.selectedValueTrabajador = event;

    this.trabajadorService.TraerTrabajadoresRut(this.selectedValueTrabajador).subscribe(
      (res: any) => {
        if (res.success) {
          console.log(res);
        }
      }
    )
  }

  cargarTabla() {
    this.solicitudService.traerInfo().subscribe((res: any) => {
      if (res.success) {
        this.despidos = res.data;
        console.log(this.despidos);
  
        // Iterar sobre los despidos y cambiar enviado a true solo si el estado es 1
        this.despidos.forEach(despido => {
          if (despido.estado === 1) {
            despido.enviado = true;
          }else{
            despido.enviado = false;
          }
        });
  
        this.dataSource = new MatTableDataSource(this.despidos);
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  
  

  open(content: any, id: number) {
    this.update.id = id;
    
    this.trabajadorService.TraerTrabajadores().subscribe(( res: any ) => {
      this.trabajadores = res.data.map(x => ({value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }))
      .sort((a, b) => a.text.localeCompare(b.text));
    })

    this.solicitudService.traerSolicitud(id).subscribe((res:any) => {
      this.update = res.data[0]
    })

    const ref = this.modalService.open(content);
    ref.dismissed.subscribe(() =>{
      this.cargarTabla();
    })
  }

  eliminar(id: any) {
    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminará la solicitud",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.solicitudService.eliminarSolicitudDespido(id).subscribe( (res:any) =>  {
          this.alert.success_small("Solicitud eliminada con éxito");
          this.cargarTabla();
        })

      }
    })

  }


  EditarSolicitudDespido(id:number){

    const data = {
      id: id,
      rut: this.update.fk_trabajador,
      nombre: this.update.nombre,
      razon: this.update.razon
    }

    this.solicitudService.actualizarSolicitudDespido(data).subscribe((res: any) => {
        this.modalService.dismissAll();
        this.alert.success_small("Solicitud editada con éxito");
        this.cargarTabla();
    })

  }

  async enviarCorreo(rut: any, id:number){

    Swal.fire({
      title: '¿Enviar?',
      text: "Se enviará la solicitud por correo electrónico al reponsable, ¿está seguro de continuar?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#74C15C',
      cancelButtonColor: '#6c736e',
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {

        this.trabajadorService.TraerTrabajadoresRut(rut).subscribe(
          async (res1: any) => {
            if (res1.success) {
              this.solicitudService.traerSolicitud(id).subscribe(async (res:any) => {
                const resp = res.data[0]
                const data = res1.data[0];
                console.log(data);
                const rutStr = rut.toString();
    
                // Obtener el último dígito
                const ultimoDigito = rutStr.charAt(rutStr.length - 1);
                
                let nuevoRutStr = rutStr;
                
                if (ultimoDigito === '0') {
                  // Cambiar el último dígito a 'K'
                  nuevoRutStr = rutStr.substring(0, rutStr.length - 1) + 'K';
                }
                
              const datosEmail = {
                  rut: formatRut(nuevoRutStr),
                  nombre: `${resp.nombre.toUpperCase()}`,
                  razon: resp.razon.toUpperCase(),
                  empresa: data.Empresa_contratante.nombre,
                  cargo: data.Cargo.nombre,
                  sucursal: data.Sucursal.nombre,
                  estado: resp.estado,
              }
              const resCorreo = await this.solicitudService.enviarCorreo(datosEmail);
              if (resCorreo.success) {
                const resStatus = await this.solicitudService.ActualizarEstado(datosEmail);
                if (resStatus.success) {
                  this.alert.success_small('Correo enviado.');
                  this.cargarTabla();
                }else{
                  this.alert.errorAlert('Error');
                }
              }else{
                this.alert.errorAlert('Error, no se ha podido enviar el correo')
              }
              // this.solicitudService.enviarCorreo(datosEmail).subscribe()
             
            
              })
                  
            }
          }
        )

      }
    })

   
  }


  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
