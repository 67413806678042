<table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="fechaDesde">
        <th mat-header-cell *matHeaderCellDef> FECHA DESDE</th>
        <td mat-cell *matCellDef="let element" style="width: 15%;">
            <div>
                {{element.fechaDesde | date: 'dd/MM/yyyy'}}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="fechaHasta">
        <th mat-header-cell *matHeaderCellDef> FECHA HASTA</th>
        <td mat-cell *matCellDef="let element" style="width: 15%;">
            <div>
                {{element.fechaHasta | date: 'dd/MM/yyyy'}}
            </div>
        </td>
    </ng-container>


    <ng-container matColumnDef="motivo">
        <th mat-header-cell *matHeaderCellDef> MOTIVO </th>
        <td mat-cell *matCellDef="let element">
            {{ element.motivo }}
        </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element" style="width: 20%;">
            <button mat-raised-button class="button_color_primary mr-2"
                    style="background-color:rgb(37, 199, 228)" [matMenuTriggerFor]="menuView">
                    <mat-icon style="color: white">
                        <span class="material-symbols-outlined">
                            visibility
                        </span>
                    </mat-icon>
                </button>
                <mat-menu #menuView="matMenu">
                    <button *ngIf="element.eliminado !== 1" mat-menu-item (click)="Descargar(element, 1)">
                        <mat-icon>receipt</mat-icon>
                        Visualizar solicitud
                    </button>
                    <button mat-menu-item (click)="Descargar(element, 2)" *ngIf="element.firmada && element.estado === 1">
                        <mat-icon>receipt</mat-icon>
                        Visualizar solicitud firmada
                    </button>
                   
                </mat-menu>
            <button (click)="Borrar(element.id)" mat-raised-button color="warn"
                [disabled]="element.pendienteSolicitud || loading"
                [pTooltip]="element.pendienteSolicitud ? 'Esperando aprobación' : ''" tooltipPosition="bottom">
                <mat-icon style="color: white" *ngIf="!loading">delete</mat-icon>
                <mat-icon style="color: white" *ngIf="!loading">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3">Sin datos</td>
    </tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons aria-label="Select page of periodic elements">
</mat-paginator>

<ng-template style="border: 0px; z-index: 100" #content let-modal3>
    <div class="modal-header" style="text-align: center">
      <mat-card-title>Subir Contrato</mat-card-title>
      <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-subir-permisos [rut]="rut"></app-subir-permisos>
    </div>
  </ng-template>