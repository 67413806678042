import { Injectable } from '@angular/core';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { HttpClient } from '@angular/common/http';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { Observable, throwError } from 'rxjs';
import {  RequestResponseGetAllCiudad } from 'src/app/shared/models/ciudades.interface';
import {  RequestResponseGetAllSucursal } from 'src/app/shared/models/sucursales.interface';
import {  RequestResponseGetAllCargo } from 'src/app/shared/models/cargo.interface';
import {  RequestResponseGetAllEmpresa } from 'src/app/shared/models/empresas.interface';
import {  RequestResponseGetAllAfp } from 'src/app/shared/models/afps.interface';
import {  RequestResponseGetAllPrevision } from 'src/app/shared/models/prevision.interface';
import {  RequestResponseGetAllEstadoCivil } from 'src/app/shared/models/estado-civil.interface';
import { RequestResponseGetAllNacionalidades } from 'src/app/shared/models/nacionalidades.interface';
import { environment } from 'src/environments/environment';

export interface RequestResponseActualizar {
  success: boolean,
  msg: string
}
@Injectable({
  providedIn: 'root'
})


export class TrabajadoresService {

  constructor(
    private http:HttpClient,
    private router: Router,
    private alert:AlertHelper
  ) { }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

  CrearTrabajador(trabajador:any): Observable<RequestResponse> {     
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/trabajador/crear`,trabajador)
    .pipe(
      map((res:RequestResponse) =>{
       
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }
  EliminarTrabajador(rut:number): Observable<RequestResponse> {
    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/trabajador/eliminar/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerTrabajadores(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/trabajador`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerTrabajadoresAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajador`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los trabajadores.'} as RequestResponse)
    }
  }

  TraerTipoMivimiento(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/tiposmovimiento/`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerTrabajadoresRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/trabajador/buscar/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerTrabajadoresRutAsync(rut: any): Promise<RequestResponse> {
    try {
      const response = await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajador/buscar/${rut}`).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      return ({msg: 'Ocurrio un error al obtener el trabajador.', success: false} as RequestResponse)
    }
  }

  async TraerTrabajadoresPorEmpresaAsync(idempresa: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/trabajador/buscar-empresa/${idempresa}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener los trabajadores.'} as RequestResponse)
    }
  }


  TraerCiudades(): Observable<RequestResponseGetAllCiudad> {
    return this.http
    .get<RequestResponseGetAllCiudad >(`${environment.API}/rrhh/ciudad/`)
    .pipe(
      map((res:RequestResponseGetAllCiudad) =>{          
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerSucursales(): Observable<RequestResponseGetAllSucursal> {
    return this.http
    .get<RequestResponseGetAllSucursal >(`${environment.API}/rrhh/sucursal/`)
    .pipe(
      map((res:RequestResponseGetAllSucursal) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerCargos(): Observable<RequestResponseGetAllCargo> {
    return this.http
    .get<RequestResponseGetAllCargo >(`${environment.API}/rrhh/cargo/`)
    .pipe(
      map((res:RequestResponseGetAllCargo) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerEmpresas(): Observable<RequestResponseGetAllEmpresa> {
    return this.http
    .get<RequestResponseGetAllEmpresa >(`${environment.API}/rrhh/empresa/`)
    .pipe(
      map((res:RequestResponseGetAllEmpresa) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerAfp(): Observable<RequestResponseGetAllAfp> {
    return this.http
    .get<RequestResponseGetAllAfp >(`${environment.API}/rrhh/afp/`)
    .pipe(
      map((res:RequestResponseGetAllAfp) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPrevisiones(): Observable<RequestResponseGetAllPrevision> {
    return this.http
    .get<RequestResponseGetAllPrevision >(`${environment.API}/rrhh/prevision/`)
    .pipe(
      map((res:RequestResponseGetAllPrevision) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerEstadosCiviles(): Observable<RequestResponseGetAllEstadoCivil> {
    return this.http
    .get<RequestResponseGetAllEstadoCivil >(`${environment.API}/rrhh/estado/`)
    .pipe(
      map((res:RequestResponseGetAllEstadoCivil) =>{          
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerNacionalidades(): Observable<RequestResponseGetAllNacionalidades> {
    return this.http
    .get<RequestResponseGetAllNacionalidades >(`${environment.API}/rrhh/nacionalidad/`)
    .pipe(
      map((res:RequestResponseGetAllNacionalidades) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  ActualizarTrabajador(trabajador:any): Observable<RequestResponseActualizar>  {
    return this.http
    .put<RequestResponseActualizar >(`${environment.API}/rrhh/trabajador/actualizar/${trabajador.rut}`,trabajador)
    .pipe(
      map((res:RequestResponseActualizar) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async ActualizarTrabajadorAsync(trabajador: any): Promise<RequestResponseActualizar> {
    try {
      return await this.http.put<RequestResponseActualizar>(`${environment.API}/rrhh/trabajador/actualizar/${trabajador.rut}`,trabajador).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al actualizar el trabajador'} as RequestResponseActualizar)
    }
  }

}
