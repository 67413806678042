import { Component, OnInit,EventEmitter,Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { Router } from '@angular/router';
import { ContratoService } from 'src/app/pages/services/contratos/contrato.service';
import { contratosPorVencerTotal } from '../../models/home-contratoPorVencer.interface';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import moment from 'moment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit,OnDestroy {

  isAdmin:boolean|string=false;
  nombreUsuario:string="";
  isLogged:boolean=false;
  contratosPorVencer: contratosPorVencerTotal[] = [];
  countContratos: number = 0;
  loading: boolean = true;
  anexosContratos: contratosPorVencerTotal[] = [];
  trabajadores: [];

  private subscripcion: Subscription = new Subscription;

  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(
    private authService_: AuthService,
    private router :Router,
    private contratoService: ContratoService,
    private trabajadoresService: TrabajadoresService
  ) { }


  ngOnInit(): void {
    this.subscripcion.add(
      this.authService_.isLogged.subscribe(res=>{
        this.isLogged = res;
        if (this.isLogged) {
          this.traerContratosPorVencer();
          this.traerTrabajadores()
          // this.traerContratos();
          // this.traerContratosAnexos();
        }
      })
    );
    this.authService_.isAdmin.subscribe(res=>
      this.isAdmin = res
    );

    this.authService_.userName.subscribe(nombre => {
      this.nombreUsuario = nombre;
    });
    // SI SE CAMBIA EL ESTADO DEL CONTRATO, SE ACTIVA ESTA SUSCRIPCION PARA 
    // TRAER LOS NUMEROS DE LOS CONTRATOS POR VENCER
    this.contratoService.$actualizarContratoVencido.subscribe((verificarContrato) => {
      if (verificarContrato) {
        this.traerContratosPorVencer();
      }
    });

    if (this.nombreUsuario.length === 0) {
      const nombre = this.authService_.userName.getValue();
      if (nombre.length > 0) {
        this.nombreUsuario = nombre;
      }else{
        const nombre = this.authService_.NombreUsuario;
        if (nombre) this.nombreUsuario = nombre;
      }
    }

    ///// verificacion si esta logueado o no
    if (!this.isLogged) {
      this.router.navigate(['/login']);
    }
  }

  traerContratosPorVencer(){
    this.contratoService.verificarContratosPlazoFijo()
      .then((data:contratosPorVencerTotal[]) => {
      this.contratosPorVencer = data.filter(contrato => contrato.tipo_contrato === 1);
      this.anexosContratos = data.filter(contrato => contrato.tipo_contrato === 2 || contrato.tipo_contrato === 3);
      //console.log("CONTRATOS A PUNTO DE VENCER: ",this.contratosPorVencer);
      this.countContratos = this.contratosPorVencer.length + this.anexosContratos.length + this.trabajadores.length;
    }).finally(() => {
      this.loading = false;
    });
  }

  traerTrabajadores() {
    const fechaHoy = moment()
    this.trabajadoresService.TraerTrabajadores().subscribe( res => {
      this.trabajadores = res.data.filter(element => {
        const fechaIngreso = moment(element.fecha_ingreso)
        const diferencia = fechaHoy.diff(fechaIngreso, 'days')
        return diferencia == 4
      })
    })
  }

  // traerContratos() {
  //   this.contratoService.verificarContratos()
  //     .then((data: contratosPorVencerTotal[]) => {
  //       this.anexosContratos = data.filter(contrato => contrato.tipo_contrato === 2);
  
  //       //console.log("CONTRATOS anexos: ", this.anexosContratos);
  //       // this.countContratos = this.anexosContratos.length + this.contratosPorVencer.length;
  //     }).finally(() => {
  //       this.loading = false;
  //     });
  // }

  // traerContratosAnexos() {
  //   this.contratoService.verificarAnexosContratos()
  //   .then((data:contratosPorVencerTotal[]) => {
  //   this.contratosPorVencer = data;
  //   //console.log("CONTRATOS ANEXO LASKJDLKASJDKL: ",this.contratosPorVencer);
  // }).finally(() => {
  //   this.loading = false;
  // });
  // }
  

  ngOnDestroy(): void {
    this.subscripcion.unsubscribe();
  }

  onToggleSidenav(): void{
    this.toggleSidenav.emit();
  }

  onLogout():void{
    this.authService_.logout();
  }

  navegarContratosPorVencer(){
    this.router.navigate([''], {fragment: 'viewContrato', preserveFragment: false});
  }

  navegarAnexosContratosPorVencer(){
    this.router.navigate([''],{fragment: 'viewAnexos', preserveFragment: false});
  }
}
