<div class="animate__animated animate__slideInUp  border border-primary">

    <mat-card>
        <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
            Uniforme
        </h3>

        <!--  -->
        <table mat-table id="tabla" matSort [dataSource]="dataSource" class="dark-table">

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> Fecha Entrega </th>
                <td mat-cell *matCellDef="let element"> {{ element.fechaU | date :'dd/MM/yyyy' }} </td>
            </ng-container>


            <ng-container matColumnDef="tallapolera">
                <th mat-header-cell *matHeaderCellDef> Talla Polera</th>
                <td mat-cell *matCellDef="let element"> {{ element.tallaPolera }} </td>
            </ng-container>
            <ng-container matColumnDef="cantidadpolera">
                <th mat-header-cell *matHeaderCellDef> Cantidad Polera</th>
                <td mat-cell *matCellDef="let element"> {{ element.cantidadPolera }} </td>
            </ng-container>

            <ng-container matColumnDef="tallapoleron">
                <th mat-header-cell *matHeaderCellDef> Talla Poleron </th>
                <td mat-cell *matCellDef="let element"> {{ element.tallaPoleron }} </td>
            </ng-container>
            <ng-container matColumnDef="cantidadpoleron">
                <th mat-header-cell *matHeaderCellDef> Cantidad Poleron </th>
                <td mat-cell *matCellDef="let element"> {{ element.cantidadPoleron }} </td>
            </ng-container>

            <ng-container matColumnDef="tallapantalon">
                <th mat-header-cell *matHeaderCellDef> Talla Pantalon </th>
                <td mat-cell *matCellDef="let element"> {{ element.tallaPantalon }} </td>
            </ng-container>

            <ng-container matColumnDef="cantidadpantalon">
                <th mat-header-cell *matHeaderCellDef> Cantidad Pantalon </th>
                <td mat-cell *matCellDef="let element"> {{ element.cantidadPantalon }} </td>
            </ng-container>

            <ng-container matColumnDef="tallazapato">
                <th mat-header-cell *matHeaderCellDef> Talla Zapatos </th>
                <td mat-cell *matCellDef="let element"> {{ element.tallaZapato }} </td>
            </ng-container>

            <ng-container matColumnDef="cantidadzapato">
                <th mat-header-cell *matHeaderCellDef> Cantidad Zapatos </th>
                <td mat-cell *matCellDef="let element"> {{ element.cantidadZapato }} </td>
            </ng-container>


            <ng-container matColumnDef="adicional">
                <th mat-header-cell *matHeaderCellDef> Observacion </th>
                <td mat-cell *matCellDef="let element"> {{ element.adicional }} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef>Estado</th>

                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.estadoU === 'PENDIENTE'" style="color: rgb(214, 208, 54)">
                        <div class="progress-bar bg-warning" role="progressbar" style="width: 100%;" aria-valuenow="100"
                            aria-valuemin="0" aria-valuemax="100">{{ element.estadoU }}</div>
                    </span>
                    <span *ngIf="element.estadoU === 'LISTO'" style="color: rgb(255, 255, 255)">

                        <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100"
                            aria-valuemin="0" aria-valuemax="100">{{ element.estadoU }}</div>
                    </span>

            </ng-container>

            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>

                <td class="padding-table" mat-cell *matCellDef="let element">


                    <button mat-button (click)="open2(contenidoo,element)" class="button_color_primary"
                        style="background-color:rgb(224, 232, 234)">
                        <mat-icon style="color: rgb(35, 35 ,206)">edit</mat-icon>
                    </button> &nbsp;
                    <span *ngIf="element.estadoU === 'PENDIENTE'" style="color:rgb(24, 209, 49)">
                        <button mat-button (click)="updated(upp,element )" class="button_color_primary" matBadge="1"
                            style="background-color:rgb(224, 232, 234)">Subir
                            <mat-icon style="color: rgb(24, 209, 49)">upload</mat-icon>
                        </button> &nbsp;
                    </span>
                    <span *ngIf="element.estadoU === 'LISTO'" style="color: rgb(255, 0, 0)">
                        <button mat-button (click)="updated(upp,element )" class="button_color_primary" 
                            style="background-color:rgb(224, 232, 234)">Actualizar
                            <mat-icon style="color: rgb(255, 0, 0)">upload</mat-icon>
                        </button> &nbsp;
                    </span>

                    <button mat-button (click)="eliminarU(contenido,element.id)" class="button_color_primary"
                        style="background-color:rgb(223, 233, 235)">
                        <mat-icon style="color: rgb(236, 18, 18)">delete</mat-icon>
                    </button> &nbsp;
                    <button mat-button (click)="Descargar(element.id)" class="button_color_primary"
                        style="background-color:rgb(37, 199, 228)">
                        <mat-icon style="color: white">search</mat-icon>
                    </button>


                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 1]" showFirstLastButtons
            aria-label="Seleccionar pagina de Trabajadores.">
        </mat-paginator>
    </mat-card>
    <ng-template #upp let-modal>
        <div class="modal-header" style="text-align: center">
            <h1>Subir </h1>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="col">

            <!-- <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Estado</mat-label>
                <input type="text" readonly name="razon" matInput [(ngModel)]="implementos_update.estadoI" />
            </mat-form-field> -->

        </div>
        <mat-card>
            <p>
                <label>Subir comprobante uniforme&nbsp;</label>
                <input mat-input required type="file" ng2FileSelect [uploader]="uploader" />

            </p>


        </mat-card>

        <div *ngIf=" proceso == 1">
            <div class="progress">
                <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0"
                    aria-valuemax="100">100%</div>
            </div>
        </div>
        <br>


        <button mat-raised-button color="primary" [disabled]="!uploader.getNotUploadedItems().length"
            (click)="guardarU()">

            <mat-icon>backup</mat-icon>
        </button>
    </ng-template>

    <ng-template #contenidoo let-modal4>
        <div class="modal-header" style="text-align: center">
            <h1>Actualizar</h1>
            <button type="button" class="close" aria-label="Close" (click)="modal4.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">

                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Fecha entrega Uniforme </mat-label>
                        <input matInput [matDatepicker]="picker" name="fechaU" [(ngModel)]="uniforme_update.fechaU" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUI #picker></mat-datepicker>
                    </mat-form-field>

                </div>
            </div>

            <div class="row">
                <div class="col">

                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla de Polera</mat-label>
                        <mat-select matNativeControl required [value]="uniforme_update.tallaPolera"
                            [(ngModel)]="uniforme_update.tallaPolera">

                            <mat-option value="S"> S</mat-option>
                            <mat-option value="M"> M</mat-option>
                            <mat-option value="L"> L</mat-option>
                            <mat-option value="XL"> XL</mat-option>
                            <mat-option value="XXL"> XXL</mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" name="cantidadPolera" matInput [value]="uniforme_update.cantidadPolera"
                            [(ngModel)]="uniforme_update.cantidadPolera" />
                    </mat-form-field>

                </div>
                <div class="col">

                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla de Poleron</mat-label>
                        <mat-select matNativeControl required [value]="uniforme_update.tallaPoleron"
                            [(ngModel)]="uniforme_update.tallaPoleron">


                            <mat-option value="S"> S</mat-option>
                            <mat-option value="M"> M</mat-option>
                            <mat-option value="L"> L</mat-option>
                            <mat-option value="XL"> XL</mat-option>
                            <mat-option value="XXL">XXL </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" name="cantidadPoleron" matInput [value]="uniforme_update.cantidadPoleron"
                            [(ngModel)]="uniforme_update.cantidadPoleron" />
                    </mat-form-field>

                </div>
            </div>

            <div class="row">

                <div class="col">

                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla de Pantalon</mat-label>
                        <mat-select matNativeControl required name="uniforme_update.tallaPantalon"
                            [(ngModel)]="uniforme_update.tallaPantalon" [ngModelOptions]="{standalone: true}">

                            <mat-option value="S"> S</mat-option>
                            <mat-option value="M"> M</mat-option>
                            <mat-option value="L"> L</mat-option>
                            <mat-option value="XL"> XL</mat-option>
                            <mat-option value="XXL">XXL </mat-option>

                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" name="cantidadPantalon" matInput [value]="uniforme_update.cantidadPantalon"
                            [(ngModel)]="uniforme_update.cantidadPantalon" />
                    </mat-form-field>

                </div>
                <div class="col">

                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Talla de Zapatos</mat-label>
                        <mat-select matNativeControl required [value]="uniforme_update.tallaZapato"
                            [(ngModel)]="uniforme_update.tallaZapato">

                            <mat-option value="34"> 34</mat-option>
                            <mat-option value="34.5">34.5</mat-option>
                            <mat-option value="35">35</mat-option>
                            <mat-option value="35.5">35.5</mat-option>
                            <mat-option value="36">36</mat-option>
                            <mat-option value="36.5">36.5 </mat-option>
                            <mat-option value="37">37 </mat-option>
                            <mat-option value="37.5">37.5 </mat-option>
                            <mat-option value="38">38 </mat-option>
                            <mat-option value="38.5">38.5 </mat-option>
                            <mat-option value="39">39 </mat-option>
                            <mat-option value="39.5">39.5 </mat-option>
                            <mat-option value="40">40 </mat-option>
                            <mat-option value="40.5">40.5 </mat-option>
                            <mat-option value="41">41 </mat-option>
                            <mat-option value="41.5">41.5 </mat-option>
                            <mat-option value="42">42 </mat-option>
                            <mat-option value="42.5">42.5 </mat-option>
                            <mat-option value="43">43 </mat-option>
                            <mat-option value="43.5">43.5 </mat-option>
                            <mat-option value="44">44 </mat-option>
                            <mat-option value="44.5">44.5 </mat-option>
                            <mat-option value="45">45 </mat-option>
                            <mat-option value="45.5">45.5 </mat-option>
                            <mat-option value="46">46 </mat-option>

                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Cantidad</mat-label>
                        <input type="number" name="cantidadZapato" matInput [value]="uniforme_update.cantidadZapato"
                            [(ngModel)]="uniforme_update.cantidadZapato" />
                    </mat-form-field>

                </div>
             
            </div>
               
            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Observacion</mat-label>
                    <input type="text" name="adicional" matInput [value]="uniforme_update.adicional"
                        [(ngModel)]="uniforme_update.adicional" />
                </mat-form-field>

            </div>
            <div class="row">
                <div class="col " appearance="outline">
                    <button mat-button (click)="actualizar( )" class="button_color_primary"
                        style="background-color:rgb(224, 232, 234)">
                        <mat-icon style="color: rgb(35, 35 ,206)">edit</mat-icon>
                    </button> &nbsp;
                </div>
            </div>

        

        </div>

        

       
    </ng-template>

   
</div>