

<p-tabView>
    <p-tabPanel header="Tabla">
        <p-toolbar styleClass="bg-light">
            <div class="p-toolbar-group-left overflow-x-auto">

              <p-multiSelect [style]="{'max-width': '800px'}" [(ngModel)]="sucursalSelect" placeholder="Todas las sucursales"  [filter]="true" filterBy="text" [options]="sucursales"  optionLabel="text" optionValue="value"
                optionValue="value" class="mr-2" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()" appendTo="body" [selectedItemsLabel]="'{0} sucursales seleccionadas'"></p-multiSelect>
              <p-dropdown placeholder="Todos los trabajadores" [(ngModel)]="trabajadorSelect" [filter]="true" filterBy="text" [options]="trabajadoresFilter"
                optionLabel="text" optionValue="value" class="mr-2" [showClear]="true" (onChange)="FiltrarDatos()" (onClear)="FiltrarDatos()"
                  appendTo="body"></p-dropdown>
              <div class="mr-2">
                <label for="periodo" class="mr-1">Periodo</label>
                <p-calendar [readonlyInput]="true" inputId="periodo" [(ngModel)]="periodo" view="month" dateFormat="mm/yy" (onSelect)="onMonthChandler($event)"
                 appendTo="body">
                </p-calendar>
              </div>
            
        
            </div>
          </p-toolbar>



<div class="mat-elevation-z8">
    <table id="tabla" mat-table [dataSource]="dataSource" matSort>
         <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>RUT</th>
            <td mat-cell *matCellDef="let element">{{element.rut | formatoRut}}</td>
        </ng-container>

        <ng-container matColumnDef="nombreTrabajador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>NOMBRE</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.nombres | uppercase }} {{ element.apellidos | uppercase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SUCURSAL</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.Sucursal.nombre | uppercase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="fechaNac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>FECHA DE NACIMIENTO</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.fecha_nacimiento | date: 'dd MMMM' }}
            </td>
        </ng-container>

         <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button (click)="open(content, element.id)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">edit</mat-icon>
                </button> &nbsp;
                <button (click)="Eliminar_Sucursal(content, element.id)" mat-button class="button_color_primary"
                    style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container> 

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
    </mat-paginator>
</div>
    </p-tabPanel>
    <p-tabPanel header="Calendario" (click)="cargarCumpleaniosCalendar()">
        <div class="mt-2">
        <full-calendar #fullcalendar [options]="options"></full-calendar>
    </div>
    </p-tabPanel>
</p-tabView>


<ng-template #content let-modal>
    <!-- <div class="modal-header">
      <h4 class="modal-title">Detalles del Trabajador</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
    <div style="padding: 25px;">
    <!-- <h2>Detalles del Trabajador</h2> -->
    <div class="modal-body">
        <div class="text-center">
            <img width="200px" height="200px" src="../../../../assets/img/cumple.png" alt="">
        </div>
        <div class="mt-2">
      <p><strong>Nombre: </strong>{{ trabajadorSeleccionado?.nombres }} {{ trabajadorSeleccionado?.apellidos }}</p>
      <p><strong>Fecha de Nacimiento: </strong>{{ trabajadorSeleccionado?.fecha_nacimiento | date: 'dd-MM-yyyy' }}</p>
      <p><strong>Fecha de ingreso: </strong>{{ trabajadorSeleccionado?.fecha_ingreso | date: 'dd-MM-yyyy' }}</p>
      <p><strong>Sucursal: </strong>{{ trabajadorSeleccionado?.Sucursal.nombre }}</p>
      <p><strong>Cargo: </strong>{{ trabajadorSeleccionado?.Cargo?.nombre }}</p>
    </div>
      <!-- Otros detalles del trabajador -->
    </div>
</div>
    <div class="modal-footer">
        <button class="btn btn-outline-primary" (click)="modal.close()">Cerrar</button>
    </div>

  </ng-template>
  



