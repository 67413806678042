import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Aprobacion } from 'src/app/shared/models/aprobacion.interface';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudLiquidacionService {

  constructor(
    private http: HttpClient,
  ) { }
  async ObtenerTodasAprobacionesLiquidacion(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/aprobaciones/getAprobaciones`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener las aprobaciones.'} as RequestResponse;
    }
  }
  async ObtenerAprobacionesLiquidacion(idliquidacion: number){
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/aprobaciones/getAprobacionesLiquidacion/${idliquidacion}`).toPromise();
    } catch (error) {
      console.log(error);
      return { success: false, msg: 'Hubo un error al obtener las aprobacaciones.' } as RequestResponse;
    }
  }
  async ObtenerAprobacionLiquidacion(id: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/aprobaciones/getAprobacion/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener la aprobación.'} as RequestResponse;
    }
  }
  async CrearLiquidacion(aprobacion:Aprobacion): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/aprobaciones/crearAprobacion`, aprobacion).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al crear la liquidación.'} as RequestResponse;
    }
  }
  async bulkCreateAprobaciones(aprobaciones: Aprobacion[]): Promise<RequestResponse> {
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/aprobaciones/bulkCreateAprobaciones`, aprobaciones).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al crear las aprobaciones.'} as RequestResponse;
    }
  }
}
