import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainFiniquitosComponent } from './main-finiquitos/main-finiquitos.component';
import { ListaFiniquitosComponent } from './lista-finiquitos/lista-finiquitos.component';
import { AgregarFiniquitosComponent } from './agregar-finiquitos/agregar-finiquitos.component';
import { FileUploadModule } from 'ng2-file-upload';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ModalMostrarComponent } from './modal-mostrar/modal-mostrar.component';
import { HelperModule } from 'src/app/helper.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { BuscarFilterModule } from '../../buscar-filter/buscar-filter.module';

@NgModule({
  declarations: [
    MainFiniquitosComponent,
    ListaFiniquitosComponent,
    AgregarFiniquitosComponent,
    ModalMostrarComponent,
  ],
  imports: [
    CommonModule,
    HelperModule,
    MaterialModule,
    FormsModule,
    MatMomentDateModule,
    FileUploadModule,
    PrimeNgModule,
    BuscarFilterModule,
    AutonumericModule.forRoot(),
  ],
  exports:[
    MainFiniquitosComponent,
    ListaFiniquitosComponent,
    AgregarFiniquitosComponent,
  ],
  providers:[
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
                                            // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 
  ]
})
export class FiniquitoModule { }
