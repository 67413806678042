<div class="container-fluid row">
    <div class="mr-2">
        <mat-form-field appearance="outline" style="width: 300px;">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" #input>
        </mat-form-field>
    </div>
    <div class="d-flex align-items-center">
        <mat-checkbox (change)="viewDatosFiniquitados()" [(ngModel)]="viewFiniquitados" color="warn">
            <span [matBadge]="num_trab_finiquitados <= 99 
                ? num_trab_finiquitados 
                : '+99'" 
                matBadgeOverlap="false">Cargar finiquitados</span>
        </mat-checkbox>
    </div>
</div>
