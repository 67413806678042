// subir-documento.component.ts

import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2'; 
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-subir-documento',
  templateUrl: './subir-documento.component.html',
  styleUrls: ['./subir-documento.component.css']
})
export class SubirDocumentoComponent {
  description: string = '';
  file: File | null = null;
  uploader: FileUploader;
  user: any;
  
  @Output() documentUploaded = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<SubirDocumentoComponent>,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { idSolicitud: number, rutTrabajador: string }
  ) {
    this.user = this.auth.ObtenerinfoToken();
    const token = this.auth.getToken();
    this.uploader = new FileUploader({
      url: `${environment.API}/rrhh/permiso/documento`,
      itemAlias: 'permiso',
      additionalParameter: {
        id_solicitud_permiso: this.data.idSolicitud,
        fk_trabajador: this.data.rutTrabajador
      },
      headers: [{name: 'Authorization', value: `Bearer ${token}`}]
    });

    this.uploader.onSuccessItem = (item, response) => {
      this.resetFields();
      this.dialogRef.close();
      this.documentUploaded.emit(); // Emitir evento
      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: 'Documento subido correctamente.',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
    };

    this.uploader.onErrorItem = () => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al subir el documento.',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('IdSolicitud', this.data.idSolicitud);
    };
  }

  onNoClick(): void {
    this.resetFields();
    this.dialogRef.close();
  }

  onFileSelected(event: any): void {
    this.file = event.target.files[0] || null;
    if (this.file) {
      this.uploader.addToQueue([this.file]);
    }
  }

  upload(): void {
    this.uploader.uploadAll();
  }

  private resetFields(): void {
    this.description = '';
    this.file = null;
    this.uploader.clearQueue();
  }
}
