import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImpuestoSegundaCatService {

  constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }



  Todo():Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/impuestoSegundaCat/`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  listarporid(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/impuestoSegundaCat/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  listarporrut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/impuestoSegundaCat/lista/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }



  crear(impuesto:any) {
    return this.http
    .post<RequestResponse >(`${environment.API}/rrhh/impuestoSegundaCat/crear`, impuesto)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  actualizar(impuesto:any): Observable<RequestResponse>  {
    return this.http
    .put<RequestResponse >(`${environment.API}/rrhh/impuestoSegundaCat/actualizar/ ${impuesto.id}`,impuesto)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }



  eliminar(id:any): Observable<RequestResponse> {


    return this.http
    .delete<RequestResponse >(`${environment.API}/rrhh/impuestoSegundaCat/${id}`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }



  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }
}
