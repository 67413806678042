import { Component, OnInit } from '@angular/core';
import { NacionalidadesService } from 'src/app/pages/services/nacionalidades.service';
import { Nacionalidades } from 'src/app/shared/models/nacionalidades.interface';

@Component({
  selector: 'app-main-nacionalidades',
  templateUrl: './main-nacionalidades.component.html',
  styleUrls: ['./main-nacionalidades.component.css']
})
export class MainNacionalidadesComponent implements OnInit {

  nacionalidad:Nacionalidades[] = [];

  constructor(
    private nacionalidadesService: NacionalidadesService
  ) { }

  ngOnInit(): void {
    this.nacionalidadesService.GetAll_nacionalidades().subscribe(
      (res) => {        
        this.nacionalidad = res.data;
      }
    );
  }
  
  actualizarTabla(event:boolean){
    this.nacionalidadesService.GetAll_nacionalidades().subscribe(res=>{
      this.nacionalidad=res.data
    })
  }

}
