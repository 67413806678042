<div class="animate__animated animate__slideInUp">



    <h1>
        Aquii llleguamos
    </h1>


    <!-- (click)="enviar()" -->

</div>