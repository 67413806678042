<div class="animate__animated animate__slideInUp">
    <mat-card>
        <app-lista-contrato> </app-lista-contrato>
    </mat-card>
</div>

<!-- <div class="animate__animated animate__slideInUp">
    <mat-card>
        <app-mostrar-contratos> </app-mostrar-contratos>
    </mat-card>
</div> -->