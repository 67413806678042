import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgregarNacionalidadesComponent } from './agregar-nacionalidades/agregar-nacionalidades.component';
import { ListaNacionalidadesComponent } from './lista-nacionalidades/lista-nacionalidades.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MainNacionalidadesComponent } from './main-nacionalidades/main-nacionalidades.component';


@NgModule({
  declarations: [
    AgregarNacionalidadesComponent,
    ListaNacionalidadesComponent,
    MainNacionalidadesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
  ],
  exports:[
    AgregarNacionalidadesComponent,
    ListaNacionalidadesComponent,
    MainNacionalidadesComponent
  ]
})
export class NacionalidadesModule { }
