import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import Swal from 'sweetalert2';
import { Firma } from 'src/app/shared/models/firmas.interface';
import { FirmasService } from 'src/app/pages/services/firmas.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas.component.html',
  styleUrls: ['./lista-empresas.component.css']
})
export class ListaEmpresasComponent implements OnInit {

  @Input() empresas_List!: Empresa[];
  @Input() list_ciudades!: Ciudad[];
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();

  update_empresas: Empresa = {
    id: 0,
    nombre: "",
    run: "",
    ubicacion: "",
    ciudad: 0,
    correo:"",
    representante: "",
    cedula: "",
    id_ciudad: 0,
    giro: '',
    num_telefono: '',
    cotizBasica: 0.0,
    cotizLeySanna: 0.0,
    nombreMutual: '',
  };

  displayedColumns: string[] = ['id', 'nombre', 'run', 'ubicacion', 'ciudad', 'correo','representante', 'cedula', 'actions'];
  mutuales:any[] = [
    {value: 'Sin Mutual', text: 'Sin mutual' },
    {value: 'Asociación Chilena de Seguridad', text: 'Asociación Chilena de Seguridad (ACHS)'},
    {value: 'Instituto de Seguridad del Trabajo', text: 'Instituto de Seguridad del Trabajo (IST)'},
    {value: 'Mutual de Seguridad CChC', text: 'Mutual de Seguridad CChC'},
  ]
  dataSource = new MatTableDataSource<Empresa>(this.empresas_List);

  firmas: Firma[];
  firmaSrc: string;
  firmaSelected: number;
  listCiudad: Ciudad[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private empresaService: EmpresaService,
    private ciudadService: CiudadService,
    private firmaService: FirmasService,
  ) {
    this.firmas = [];
    this.firmaSrc = '';
    this.firmaSelected = null;
  }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
    this.getCiudades();
  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.empresas_List != undefined) {
      if (!changes.empresas_List.firstChange) {
        this.dataSource = new MatTableDataSource<Empresa>(this.empresas_List);

       
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  getCiudades() {
    this.ciudadService.GetAll_ciudades().subscribe(
      (res) => {
        this.listCiudad = res.data;
      }
    );
  }



  async open(content: any, id: number) {
    await this.obtenerFirmas();
    this.update_empresas.id = id
    this.empresaService.Obtener_empresa(id).subscribe(res => {
      this.update_empresas = res.data;
      // console.log('update-empresa: ', res.data);
    })
   
    this.modalService.open(content, {size: 'lg'});

  }
  async obtenerFirmas() {
    const res = await this.firmaService.getFirmas();
    if (res.success) {
      this.firmas = res.data;
    }else{
      this.firmas = [];
      console.error(res.msg);
    }
  }


  Actualizar_Empresa() {

    
    // if (this.update_empresas.nombre.trim().length == 0) {
    //   this.alert.error_small('Los campos no pueden estar vacios.')
    //   return
    // }

    
    // this.update_empresas.ciudad = this.update_empresas.id_ciudad;
    //console.log(this.update_empresas);
    this.update_empresas.id_ciudad = this.update_empresas.id_ciudad;
    this.update_empresas.idfirma = this.firmaSelected;

   
    this.empresaService.Actualizar_empresa(this.update_empresas).subscribe(res => {


      if (res.success == true) {

      
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
       
        this.debouncer.next(true);
      } else {
        this.alert.error_small(res.msg!)
      }
    })


  }

  Eliminar_Empresa(content: any, id: number) {

    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el empresa",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.empresaService.Eliminar_empresa(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true);
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }

  mostrarFirma(){
    const firma = this.firmas.find(f => f.id === this.firmaSelected);
    if (firma) {
      this.firmaSrc = `${environment.API}${firma.rutaImg}`;
    }
  }

  //FITRAL
  applyFilter(event: Event) {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  

}
