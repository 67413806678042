import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgregarConocimientosComponent } from './agregar-conocimientos/agregar-conocimientos.component';
import { ListaConocimientosComponent } from './lista-conocimientos/lista-conocimientos.component';
import { MainConocimientosComponent } from './main-conocimientos/main-conocimientos.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AgregarConocimientosComponent,
    ListaConocimientosComponent,
    MainConocimientosComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  exports:[
    AgregarConocimientosComponent,
    ListaConocimientosComponent,
    MainConocimientosComponent
  ]
})
export class ConocimientosModule { }
