import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainImpuesto2catComponent } from './main-impuesto2cat/main-impuesto2cat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from 'src/app/material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { KnobModule } from "primeng/knob";
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ListarImpuesto2catComponent } from './listar-impuesto2cat/listar-impuesto2cat.component';
import { AgregarImpuesto2catComponent } from './agregar-impuesto2cat/agregar-impuesto2cat.component';
import { MatSortModule } from '@angular/material/sort';



@NgModule({
  declarations: [
    MainImpuesto2catComponent,
    ListarImpuesto2catComponent,
   AgregarImpuesto2catComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatMomentDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    KnobModule,
    ButtonModule,
    RippleModule,
    MatSortModule,
   
  ],
  exports:[
    MainImpuesto2catComponent,
    ListarImpuesto2catComponent,
    AgregarImpuesto2catComponent
  ]
})
export class ImpuestoSegundaCatModule { }
