import { HttpClient } from '@angular/common/http';
import { ClassField } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError,  from } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { switchMap, tap, mergeMap  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SolicitudPermisoService {

  allTrabajadores: any[] = [];


  constructor(
    private http:HttpClient,
    private alert:AlertHelper
  ) { }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

  crearSolicitudPermiso(data:any) {   
    console.log("data del service: ", data);
    // return this.http
    // .post<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/crear`,data)
    // .pipe(
    //   map((res:RequestResponse) =>{
    //     return res;
    //   }),
    //   catchError((err)=> this.handlerError(err))
    // );

    return this.http.post(`${environment.API}/rrhh/solicitudPermisos/solicitarPermiso/`, data);
  }

  traerInfo(): Observable<RequestResponse>{
    return this.http
    .get<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/`)
    .pipe(
      map((res: RequestResponse)=>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  traerSolicitud(rut: number):Observable<RequestResponse>{
    return this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/buscarPorRut/${rut}`)
    .pipe(
      map((res: RequestResponse) => {
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }
  traerSolicitudPorId(id: number):Observable<RequestResponse>{
    return this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/buscarPorId/${id}`)
    .pipe(
      map((res: RequestResponse) => {
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }

  actualizarSolicitudPermisos(id:number,data:any):Observable<RequestResponse>{
    return this.http.put<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/actualizar/${id}`, data)
    .pipe(
      map(( res:RequestResponse ) => {
        return res;
      }),
      catchError((err) => this.handlerError(err))
    )
  }

  TraerPermiso(id: any): Observable<RequestResponse> {
    return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/documento/buscarPermisosId/${id}`)
      .pipe(
        map(res => res),
        catchError(err => this.handlerError(err))
      );
  }

  // Función para eliminar documento
  eliminarDocumento(id: number, tipo: string): Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/documento/eliminar/${id}/${tipo}`)
      .pipe(
        map(res => res),
        catchError(error => this.handlerError(error))
      );
  }

  Eliminar(id: number): Observable<RequestResponse> {
    return this.TraerPermiso(id).pipe(
      switchMap(res => {
        if (res.success && res.data && res.data.documentos && res.data.documentos.length > 0) {
          console.log("ESTOS SON LOS DATOS DEL DOCUMENTO",res.data.documentos )

          const eliminarDocumentos$ = res.data.documentos.map((doc: { cp_documento: number; tipo: string; }) =>
            this.eliminarDocumento(doc.cp_documento, doc.tipo)
          );
            return from(eliminarDocumentos$).pipe(
            mergeMap(async (obs) => obs),
            switchMap(() => this.http.delete<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/eliminarSolicitud/${id}`)),
            catchError(err => this.handlerError(err))
          );
        } else {
          return this.http.delete<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/eliminarSolicitud/${id}`).pipe(
            catchError(err => this.handlerError(err))
          );
        }
      }),
      catchError(err => this.handlerError(err))
    );
  }

  async bulkCreateAprobaciones(aprobaciones: any[]): Promise<RequestResponse> {
    console.log({aprobaciones});
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/bulkCreateAprobaciones`, aprobaciones).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al crear las aprobaciones.'} as RequestResponse;
    }
  }


  async enviarCorreo(datos: any): Promise<RequestResponse> {
    console.log({datos});
    const correoData = {
      trabajador: datos,
      destinatario: 'nico.casanova10@gmail.com',
      asunto: 'Solicitud de despido',
      cuerpo: `Se ha realizado una solicitud de despido a: `,
    };

    console.log({correoData});
  
    // Envía la solicitud HTTP para enviar el correo
    try {
      return this.http.post<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/enviarCorreo/`, correoData).toPromise();
    } catch (error) {
      return { success: false, msg: 'Error' } as RequestResponse
    }
    // return this.http.post(`${environment.API}/rrhh/solicitudDespido/enviarCorreo/`, correoData)
    //   .subscribe({
    //     next: (response) => {
    //       console.log('Correo enviado con éxito:', response);
          
    //     },
    //     error: (error) => {
    //       console.log("Error al enviar el correo:", datos);
    //       console.error('Error al enviar el correo:', error);
    //     }
    //   });
  }
  
  generarSolicitud(data: any){
    return this.http
    .post<RequestResponse>(`${environment.API}/rrhh/solicitudPermisos/solicitarPermiso/`, data)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
    );
  }


}
