import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MotivosCartaAmonestacionService } from 'src/app/pages/services/motivos-carta-amonestacion.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-listar-motivos',
  templateUrl: './listar-motivos.component.html',
  styleUrls: ['./listar-motivos.component.css']
})
export class ListarMotivosComponent implements OnInit {
  @Input() motivos: any[] = [];
  @Output() actualizarTabla = new EventEmitter<boolean>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['nombre', 'descripcion', 'acciones'];
  
  constructor(
    private motivosService: MotivosCartaAmonestacionService,
    private alert: AlertHelper,
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.motivos && changes.motivos.currentValue) {
      const motivosSort = this.motivos.sort((a, b) => a.nombre.localeCompare(b.nombre));
      this.dataSource = new MatTableDataSource(motivosSort);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  TraerDatos(){
    this.actualizarTabla.emit(true);
  }
  async EliminarMotivo(id: number){
    const resSwal: SweetAlertResult = await this.alert.deleteItem("¿Está seguro de eliminar este motivo?");
    if(resSwal.isConfirmed){
      const res = await this.motivosService.eliminarMotivoAsync(id);
      if (res.success) {
        this.alert.success_small('Motivo eliminado correctamente');
        this.actualizarTabla.emit(true);
      }else{
        this.alert.error_small(res.msg);
      }
    }
  }

}
