<mat-card>
    <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
       Estadisticas Licencias Medicas
    </h3>

    <mat-card  >
     
      <mat-card-header>

        <div class="p-field p-col-12 p-md-4">
        
        <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label" optionValue="value"></p-selectButton>
      
      </div>
        <div class="p-field p-col-12 p-md-4" *ngIf="value1==true">
          <p-calendar [(ngModel)]="year" view="year" dateFormat="yy" inputId="anio"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-4" *ngIf="value1==false">
          <p-calendar [(ngModel)]="mes" view="month" dateFormat="mm/yy" inputId="mes"></p-calendar>
      </div>
      
      <div class="p-field p-col-12 p-md-4">
        <button  (click)="filtrarGrafico()">
                
        <mat-icon>assessment</mat-icon>
        </button> 
      </div>

      </mat-card-header>

      

    <canvas id="myChart" >


    </canvas>

    </mat-card>&nbsp;

 


    <mat-card>

    <h4 style="text-align:center;font-size:1.77rem;font-weight:500">  Todas las licencias</h4>


    <mat-form-field appearance="fill">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="texto a buscar" #input>
    </mat-form-field>
    
    <div class="mat-elevation-z8">    
        <table mat-table [dataSource]="dataSource" matSort >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5px">ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
      
          <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Rut</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.Trabajador.rut | formatoRut }}
           
    
            </td>
          </ng-container>
    
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Trabajador</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.Trabajador.nombres | uppercase }}
              {{ element.Trabajador.apellidos | uppercase }}
    
            </td>
          </ng-container>

          <ng-container matColumnDef="empresa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Empresa</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.Trabajador.Empresa_contratante.nombre  | uppercase }}
            </td>
          </ng-container>            
    
          <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header >Periodo</th>
            <td class="padding-table" mat-cell *matCellDef="let element" >
              {{ element.fechaInicio}}
              {{ element.fechaTermino}}
            </td>
          </ng-container>
    
          <ng-container matColumnDef="motivo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Motivo</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              {{ element.motivo | uppercase }}
            </td>
          </ng-container>    
              
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
              <button 
                mat-button class="button_color_primary">
                <mat-icon style="color: white">description</mat-icon>
              </button>       
            </td>        
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      
        <mat-paginator
          [pageSizeOptions]="[10, 20, 50, 100]"
          showFirstLastButtons
          aria-label="Seleccionar pagina de licencias."
        >
        </mat-paginator>
      </div>

    </mat-card>



</mat-card>
