import { Component, OnInit } from '@angular/core';
import { AfpService } from 'src/app/pages/services/afp.service';
import { MovimientosPersonalService } from 'src/app/pages/services/movimientos-personal.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { formatRut } from 'rutlib';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { TiposEvento } from 'src/app/shared/models/movimientospersonal.interface';

@Component({
  selector: 'app-agregar-movimientos-personal',
  templateUrl: './agregar-movimientos-personal.component.html',
  styleUrls: ['./agregar-movimientos-personal.component.css']
})
export class AgregarMovimientosPersonalComponent implements OnInit {
  constructor(
    private trabajadorService: TrabajadoresService,
    private movimientosPersonal:MovimientosPersonalService,
    private afpService: AfpService,
    private alert: AlertHelper,
    ) { }

  ngOnInit(): void {

    this.getAllTrabajadorInfo();
    this.getAllTiposMovimiento();
    this.getAllTiposEventos();
    this.getAllAfp();
  }

  trabajadores: any = [];
  tiposMovimiento: any = [];
  eventos: any = [];
  eventosFilter: any[];
  afps: any = [];
  selectedValueTrabajador: number;
  selectedValue: number;
  afp: any;
  selectedValueTipo: number = 2;
  prevision: string;
  date: Date = new Date();
  selectedFechaInicio: any;
  selectedFechaTermino: any;
  selectedRut: any;
  montoImponible: number;

  codigosEventosAFP = [1, 2, 3, 4, 5, 7, 8, 11, 12, 13, 14, 15];
  codigosEventosISAPRE = [2, 3, 4, 5, 6, 13, 14, 15];


  getAllTrabajadorInfo(): void {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res: any) => {
        if (res.success) {
          this.trabajadores = res.data.map(x => ({value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }))
            .sort((a, b) => a.text.localeCompare(b.text));

        }
      }
    );
  }

  getAllTiposEventos(): void {
    this.movimientosPersonal.TraerTipoEvento().subscribe(
      (res: any) => {
        if (res.success) {
          this.eventos = res.data.sort((a:TiposEvento, b:TiposEvento) => a.nombre.localeCompare(b.nombre));
          this.eventosFilter = this.eventos.filter(x => this.codigosEventosAFP.includes(x.codigo));
          console.log(this.eventos);
        }
      }
    );
  }

  getAllAfp(): void {
    this.afpService.GetAll_afps().subscribe(
      (res: any) => {
        console.log(res);
        if (res.success) {
          this.afps = res.data;
        }
      }
    );
  }

  onAfpChange(event:any): void{
    

    this.selectedValue = event;
    
  }

  onTipoChange(event:any):void{
    
    this.selectedValueTipo = event;
    if (this.selectedValueTipo === 2) { // AFP
      this.eventosFilter = this.eventos.filter(
        x => this.codigosEventosAFP.includes(x.codigo)
      );
    }else if(this.selectedValueTipo === 3) { // ISAPRE
      this.eventosFilter = this.eventos.filter(
        x => this.codigosEventosISAPRE.includes(x.codigo)
      );
    }
  }

  onTrabajadorChange(event:any): void{
    this.selectedValueTrabajador = event;

    this.trabajadorService.TraerTrabajadoresRut(this.selectedValueTrabajador).subscribe(
      (res: any) => {
        console.log(res);
        if (res.success) {
          this.afp = res.data[0].Afp.nombre;
          this.prevision = res.data[0].Prevision.nombre;
          this.movimientosPersonal.obtenerMovimientosPersonalesPorRut(res.data[0].rut)
          .then((res: any) => {
              console.log(res);
              if (res.success) {
                this.montoImponible = res.data[0].montoImponible;
              }
            }
          )
        }
      }
    )

   
  }

  getAllTiposMovimiento(): void {
    this.trabajadorService.TraerTipoMivimiento().subscribe(
        (res: any) => {
            if (res.success) {
                this.tiposMovimiento = res.data;
            } else {
                console.error("Error en la respuesta: ", res.msg);
            }
        },
        (error) => {
            console.error("Error de la solicitud:", error);
        }
    );
}

onKeyEntidadPagadora(event: any): void {
  this.selectedRut = event.target.value;
  const rut2 = formatRut(this.selectedRut);
  this.selectedRut = rut2;
}

onSubmit(): void {



  const data = {
   fk_trabajador: this?.selectedValueTrabajador,
   idtipomovimiento: this.selectedValueTipo,
   idtipoevento: this.eventosFilter.find(x => x.codigo === this.selectedValue).id,
   fecha_inicio: this.selectedFechaInicio?._d,
   fecha_final: this.selectedFechaTermino?._d,
   rutpagadora: this.selectedRut,
   periodo: this.date,
   montoImponible: this.montoImponible
  };

  this.movimientosPersonal.CrearMovimiento(data).subscribe(
    (res) => {
      if (res.success) {
        this.alert.success_small('Movimiento de personal realizado con exito');
        this.selectedValue = null;
        this.date = null;
        this.selectedValueTipo = null;
        this.selectedValueTrabajador = null;
        this.selectedFechaInicio = null;
        this.selectedFechaTermino = null;
        this.selectedRut = null;
        this.montoImponible = null;

        this.movimientosPersonal.setEventAdd(true);
      }else{
        console.error(res.msg);
      }

    }
  )
  
}

}
