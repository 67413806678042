import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Observable, catchError } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { DeclaracionJuradaDB } from '../../../shared/models/declaracionjurada/declaracionjurada.interface';
import { RectificacionDDJJDB } from '../../../shared/models/declaracionjurada/rectificacionddjj.interface';

@Injectable({
    providedIn: 'root'
})
export class DeclaracionDDJJService {
    constructor(private http:HttpClient,private router: Router,private alert:AlertHelper) { }

    getLastFolio() : Observable<RequestResponse> {
        return this.http
            .get<RequestResponse>(`${environment.API}/rrhh/declaracion/last-folio`)
            .pipe(
                map((res: RequestResponse) => {
                    return res;
                }),
                catchError((error) => this.handlerError(error))
            );
    }
    getCorreccionMonetaria(periodo:string){ 
        return this.http
            .get<RequestResponse>(`${environment.API}/rrhh/declaracion/datos/${periodo}`)
            .pipe(
                map((res: RequestResponse) => {
                    return res;
                }),
                catchError((error) => this.handlerError(error))
            );
    }
    getDeclaracionConFolio(folio:number) {
        return this.http
            .get<RequestResponse>(`${environment.API}/rrhh/declaracion/folio/${folio}`)
            .pipe(
                map((res:RequestResponse) => {
                    return res;
                }),
                catchError((error) => this.handlerError(error))
            )
    }
    getDeclaracionConAnio(anio:number){
        return this.http
            .get<RequestResponse>(`${environment.API}/rrhh/declaracion/anio/${anio}`)
            .pipe(
                map((res:RequestResponse) => {
                    return res;
                }),
                catchError((error) => this.handlerError(error))
            );
    }
    getRectificacionConAnio(anio:number){
        return this.http
            .get<RequestResponse>(`${environment.API}/rrhh/rectificacion/anio/${anio}`)
            .pipe(
                map((res:RequestResponse) => res),
                catchError((error) => this.handlerError(error)),
            )
    }
    crearDeclaracion(declaracionJurada:DeclaracionJuradaDB){
        return this.http
            .post<RequestResponse>(`${environment.API}/rrhh/declaracion/crear`,declaracionJurada)
            .pipe(
                map((res:RequestResponse) => res),
                catchError((error) => this.handlerError(error))
            )
    }
    crearRectificacion(rectificacionDDJJ:RectificacionDDJJDB){
        return this.http
            .post<RequestResponse>(`${environment.API}/rrhh/rectificacion/crear`,rectificacionDDJJ)
            .pipe(
                map((res:RequestResponse) => res),
                catchError((error) => this.handlerError(error)),
            )
    }
    private handlerError(err: any):Observable<never>{
        let errorMessage = "";
        if (err) {
          errorMessage = `Error: ${err.error.msg}`;
        }
        this.alert.error_small(err.error.msg);
        throw errorMessage;
    }
}