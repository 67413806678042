export enum NombreHaberes {
    SueldoBase = 'SUELDO BASE',
    GratificacionLegal = 'GRATIFICACION LEGAL',
    AsignacionFamiliar = 'ASIGNACION FAMILIAR',
    BeneficioSemanaCorrida = 'BENEFICIO SEMANA CORRIDA',
    Prevision = 'PREVISION',
    Salud = 'SALUD',
    HorasExtras = 'HORAS EXTRAS 50%',
    ImpuestoUnico = 'IMPUESTO UNICO',
    SeguroCesantia = 'SEGURO CESANTIA',
    Liquido = 'LIQUIDO',
    SeguroCesantiaEmpleador = 'SEGURO CESANTIA - EMPLEADOR',
    MutualSeguridad = 'MUTUAL DE SEGURIDAD',
    SIS = 'SEGURO DE INVALIDEZ Y SOBREVIVENCIA - EMPLEADOR',
}