<div class="animate__animated animate__fadeInRight">
  <br />
  <div class="col">
    <mat-card class="mat_card_adelantos">
      <h1>Módulo de adelantos</h1>
    </mat-card>
  </div>
  <br />
  <mat-card class="mat-elevation-z5 mat_card_adelantos_content border border-primary">
    <div class="row d-flex container-fluid">
      <div class="col-4 mr-auto">
        <app-agregar-adelantos (onDebounce)="actualizarTabla($event)"></app-agregar-adelantos>
      </div>
      <div class="col-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Buscar</mat-label>
          <input matInput [(ngModel)]="textoBuscar" (keyup)="obtenerTextoBuscar($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <app-lista-adelantos
      (onDebounce)="actualizarTabla($event)"
      [textoBuscar]="textoBuscar"
      [adelantos_list]="adelantos"
    >
    </app-lista-adelantos>
  </mat-card>
  <hr />
</div>
