import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FoliadosService } from 'src/app/pages/services/foliados/foliados.service';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { generarLiquidacionService } from 'src/app/pages/services/PDF-GENERADOR/liquidaciones/liquidacion.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Prestamos } from 'src/app/shared/models/prestamos.interface';
import { PrestamosService } from 'src/app/pages/services/prestamos.service';
import { contabilidadService } from 'src/app/pages/services/contabilidad/contabilidad.service';
import { FuncionesLiquidacionesService } from 'src/app/pages/services/liquidaciones/funciones-liquidaciones.service';

import { default as _rollupMoment, Moment } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import _moment from 'moment';
import Holidays from 'date-holidays';

import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { CiudadRegion } from 'src/app/shared/models/ciudades.interface';
import { Sucursal } from '../../../../../shared/models/sucursales.interface';
import { Cuotas } from 'src/app/shared/models/cuotas.interface';
import { Adelantos } from 'src/app/shared/models/adelantos.interface';
import { endOfMonth, format, parseISO } from 'date-fns';
import { Aprobacion } from 'src/app/shared/models/aprobacion.interface';
import { HelperService } from 'src/app/pages/services/helper/helper.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-test-liquidaciones',
  templateUrl: './test-liquidaciones.component.html',
  styleUrls: ['./test-liquidaciones.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})

export class TestLiquidacionesComponent implements OnInit {
  @Input() rut: number;
  @Input() libroInput: any;
  @Output() nombreTrabajador = new EventEmitter<string>();
  uploader: FileUploader;
  uploaderChangeLiquidacion: FileUploader;
  loading: boolean;
  refreshStatus: boolean = false;

  prestamos: Prestamos[];
  cuota: Cuotas;

  src: any;
  uf: any;
  user: any;
  libro_liquidacion_aprobacion: any;

  datePeriodo: FormControl;
  render: boolean;
  renderPdf: boolean;
  renderbuton: boolean;
  checkDownload: boolean;
  tieneAdelanto: number;
  tienePrestamo: number;
  numVacaciones: number;
  numLicencias: number;
  tributable: number;
  holidays = new Holidays('CL');
  pdfCargado: boolean = false;

  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();
  maxDatePeriodo: Date = new Date(this.currentYear, this.currentMonth + 1, 0);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter()
  displayedColumns: string[] = ['id', 'desde', 'hasta', 'cantidad'];
  dataSource = new MatTableDataSource();
  debouncer: Subject<boolean> = new Subject()
  diasFaltados: any;
  selectDiasTrabajados: string = '5';

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  optionsNumericDias = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 1, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
    maximumValue: '30',
  };
  optionsNumericDecimal = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 4, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };
  optionsNumericUF = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 2, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  estado_contrato = [
    { id: 1, valor: 'Contrato a Plazo Fijo' },
    { id: 2, valor: 'Contrato Indefinido' },
    { id: 3, valor: 'Contrato por Obra o Faena' }
  ];

  jubilados = [
    { id: false, valor: 'No Jubilado' },
    { id: true, valor: 'Jubilado' },
  ]

  idcuota: number[] = [];

  impuestoxd = {

    desde: 0,
    hasta: 0,
    cantidad: 0,

  }

  liquidacion: any = {
    fecha: undefined,
    haberes: 0,
    descuentos: 0,
    alcance_liquido: 0,
    fk_trabajador: 0,
    eliminado: 0,
    pagada_finiquito: 0,
  };

  asistencias: any[] = [];

  trabajador = {
    ubicacion: '',
    nombre: '',
    apellido: '',
    fk_trabajador: 0,
    f_start: '',
    f_end: '',
    dias_semana: 0,
    fecha_ingreso: '',
    habiles: 0,
    inhabiles: 0,
    pendiente: 0.0,
    periodo: this.obtenerPeriodoLocalStorage(),
    empresa: '',
    empresaRut: 0,
    palabras: '',
    folio: '00000',
    afp: '',
    afc: 1,
    afptasa: 0,
    prevision: '',
    previsiontasa: 0,
    horasExtras: 0,
    carga: 0,
    sbase: 0,
    haberes: [],
    sCesantia: 0,
    estadoContrato: '',
    adelanto: 0,
    prestamo: 0,
    numCuota: 0,
    ahorro_apv: 0,
    prestamos: [],
    jubilado: false,
    tramoAsigFam: 0,
    impuestounicoC: 0,
    horasPactadas: 0,
    previred: {
      periodo: null,
      topes: {
        AfpSalud: 0,
        IPS: 0,
        SeguroCesantia: 0,
      },
      familiar: {
        tramoA: {
          valor: 0,
          max: 0,
        },
        tramoB: {
          valor: 0,
          min: 0,
          max: 0
        },
        tramoC: {
          valor: 0,
          min: 0,
          max: 0,
        },
        tramoD: {
          valor: 0,
          min: 0,
        }
      },
      ufMesActual: 0,
      ufMesAnterior: 0,
      rentaMinima: 0,
      sCesantiaIndefinido: 0,
      seguroInvalidez: 0
    },
    // impuestounicoD: 0,
    // impuestounicoH: 0,
  };

  libroremuneracion = {
    idliquidacion: null,
    rut: 0,
    nombre: '',
    f_start: new Date(),
    f_liquidacion: new Date(),
    sucursal: '', //Es la empresa
    sucur: '', //La sucursal, no lo edite porque...
    adelanto: 0,
    horasextras: 0,
    glegal: 0,
    otroimp: 0,
    totalimp: 0,
    otronoimp: 0,
    totalnoimp: 0,
    prevision: 0,
    isapre: 0,
    salud: 0,
    scesantia: 0,
    otrodesclegales: 0,
    totaldesclegales: 0,
    totaldescuentos: 0,
    liquido: 0,
    sbase: 0,
    impuestounico: 0,
    desuentovarios: 0,
    asig_familiar: 0,
    d_trabajados: 0,
    fecha_inicio_contratos: '',
    cod_region_prest_servicios: 0,
    cod_comuna_prest_servicios: 0,
    cod_tipo_jornada: 101,
    cod_discapacidad: 0,
    jubilado: false,
    isapre_fonasa: 102, //Por defecto Fonasa
    cod_afp: 100, //por defecto ninguna
    afc: 1, //por defecto si
    seguroInvalidez: 0,
    comision: 0,
    bsemanacorrida: 0,
    colacion: 0,
    movilizacion: 0,
    viaticos: 0,
    ret_clase_media: 0,
    sueldo: 0,
    sobresueldo: 0,
    participacion: 0,
    recargo_domingo: 0,
    rem_pagada_vacaciones: 0,
    rem_pagada_clausura: 0,
    aguinaldo: 0,
    bonos_rem_fijas: 0,
    no_pactado_contrato: 0,
    benef_remun: 0,
    pago_horas_sindical: 0,
    subs_incap_laboral: 0,
    beca_estudio: 0,
    gratificacion_zona: 0,
    ingresos_no_constitutivos: 0,
    perdida_caja: 0,
    desg_herramientas: 0,
    gasto_trabajo: 0,
    gasto_residencia: 0,
    sala_cuna: 0,
    asign_teletrabajo: 0,
    aloj_trabajo: 0,
    asig_traslacion: 0,
  }
  //CODIGOS COMUNAS LIBRO DE REMUNERACIONES OFICIAL
  ciudadSucursal: CiudadRegion;
  sucursalTrabajador: Sucursal;
  ciudades: CiudadRegion[] = [];

  fecha: Date;
  fechaFormatT: string;
  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });
  adelantos: Adelantos[];

  constructor(
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private folio: FoliadosService,
    private alert: AlertHelper,
    private liquidacionCrear: generarLiquidacionService,
    private _formBuilder: FormBuilder,
    private modLiquidaciones: LiquidacionService,
    private prestamos_services: PrestamosService,
    private libro: contabilidadService,
    private auth: AuthService,
    private helperService: HelperService,
    private funcionesLiquidaciones: FuncionesLiquidacionesService,
  ) {
    // OBTENEMOS EL ULTIMO FOLIO DEL DOCUMENTO
    this.ObtenerFolio();
    this.uploader = new FileUploader({
      url: `${environment.API}/rrhh/liquidacion/documento`,
      itemAlias: 'liquidacion',
    });
    this.uploaderChangeLiquidacion = new FileUploader({
      url: `${environment.API}/rrhh/liquidacion/crearLiquidacionAprobacionDocumento`,
      itemAlias: 'liquidacion',
    });
    this.loading = false;
    this.prestamos = [];
    this.src = {
      urlFile: '',
      blobFile: '',
    };
    this.uf = {
      mesActual: 0.0,
      mesAnterior: 0.0,
    };
    this.libro_liquidacion_aprobacion = {
      liquidacion: null,
      libro: null,
      aprobacion: null,
      userAt: '',
    }
    this.datePeriodo = new FormControl(moment(this.trabajador.periodo));
    this.render = true;
    this.renderPdf = false;
    this.renderbuton = false;
    this.checkDownload = true;
    this.tieneAdelanto = 0;
    this.tienePrestamo = 0;
    this.numVacaciones = 0;
    this.numLicencias = 0;
    this.tributable = 0;
  }
  async ngOnInit() {
    this.user = this.auth.ObtenerinfoToken();
    this.initUploader();

    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor)
      })
  }
  initUploader() {
    // INICIALIZO EL UPLOADER PARA CREAR LIQUIDACIÓN
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('fecha', this.liquidacion.fecha);
      form.append('totalHaberes', this.liquidacion.haberes);
      form.append('totalDescuentos', this.liquidacion.descuentos);
      form.append('alcanceLiquido', this.liquidacion.alcance_liquido);
      form.append('fk_trabajador', this.rut);
      form.append('userAt', this.user.nombre);
    };

    // INICIALIZO EL UPLOADER PARA ACTUALIZAR LIQUIDACIÓN
    this.uploaderChangeLiquidacion.onBuildItemForm = (fileItem: any, form: any) => {
      this.libro_liquidacion_aprobacion.userAt = this.user.nombre;
      form.append('liquidacion', JSON.stringify(this.libro_liquidacion_aprobacion.liquidacion));
      form.append('aprobacion', JSON.stringify(this.libro_liquidacion_aprobacion.aprobacion));
      form.append('remu', JSON.stringify(this.libro_liquidacion_aprobacion.libro));
      form.append('userAt', this.libro_liquidacion_aprobacion.userAt);
      // form.append('libro_liquidacion_aprobacion', JSON.stringify(this.libro_liquidacion_aprobacion));
    };
    // SIRVE PARA EVITAR CONFLICTOS CON LAS CORS
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploaderChangeLiquidacion.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    }
  }

  // Metodo de cliclo de vida para detectar cambios en los @Input
  async ngOnChanges() {
    if (this.libroInput) {
      this.datePeriodo.setValue(moment(this.libroInput.f_liquidacion));
    }
    if (this.rut) {
      // this.obtenerPeriodoLocalStorage();
      await this.InitLiquidaciones();
    }
  }

  async InitLiquidaciones() {
    // console.time();
    // SET LOS VALORES EN EL SERVICE FUNCIONES LIQUIDACIONES
    this.setValueService();
    const trabajador = await this.funcionesLiquidaciones.CargarTrabajador();
    const libroremuneracion = this.funcionesLiquidaciones.libroremuneracion;
    const movimientos = this.funcionesLiquidaciones.movimientos;

    const nombre = trabajador.nombre + ' ' + trabajador.apellido;
    this.nombreTrabajador.emit(nombre.toUpperCase());
    this.trabajador = {
      ubicacion: trabajador.ubicacion,
      nombre: trabajador.nombre,
      apellido: trabajador.apellido,
      fk_trabajador: trabajador.fk_trabajador,
      f_start: trabajador.f_start,
      f_end: trabajador.f_end,
      dias_semana: trabajador.dias_semana,
      fecha_ingreso: trabajador.fecha_ingreso,
      habiles: trabajador.habiles,
      inhabiles: trabajador.inhabiles,
      pendiente: !this.libroInput ? trabajador.pendiente 
        : movimientos.some(x => x.TiposEvento.codigo === 5) ? endOfMonth(parseISO(this.libroInput.f_liquidacion)).getDate() - this.libroInput.d_trabajados : 30 - this.libroInput.d_trabajados,
      periodo: this.libroInput ? parseISO(this.libroInput.f_liquidacion) : trabajador.periodo,
      empresa: trabajador.empresa,
      empresaRut: trabajador.empresaRut,
      palabras: trabajador.palabras,
      folio: this.trabajador.folio,
      afp: trabajador.afp,
      afc: trabajador.afc,
      afptasa: trabajador.afptasa,
      prevision: trabajador.prevision,
      previsiontasa: trabajador.previsiontasa,
      horasExtras: this.trabajador.horasExtras > 0 ? this.trabajador.horasExtras : trabajador.horasExtras,
      carga: trabajador.carga,
      sbase: trabajador.sbase,
      haberes: trabajador.haberes,
      sCesantia: trabajador.sCesantia,
      estadoContrato: trabajador.estadoContrato,
      adelanto: trabajador.adelanto,
      prestamo: trabajador.prestamo,
      numCuota: trabajador.numCuota,
      ahorro_apv: trabajador.ahorro_apv,
      prestamos: trabajador.prestamos,
      jubilado: trabajador.jubilado,
      tramoAsigFam: trabajador.tramoAsigFam,
      horasPactadas: trabajador.horasPactadas,
      impuestounicoC: trabajador.impuestounicoC,
      previred: {
        periodo: null,
        topes: {
          AfpSalud: 0,
          IPS: 0,
          SeguroCesantia: 0,
        },
        familiar: {
          tramoA: {
            valor: 0,
            max: 0,
          },
          tramoB: {
            valor: 0,
            min: 0,
            max: 0
          },
          tramoC: {
            valor: 0,
            min: 0,
            max: 0,
          },
          tramoD: {
            valor: 0,
            min: 0,
          }
        },
        ufMesActual: 0,
        ufMesAnterior: 0,
        rentaMinima: 0,
        sCesantiaIndefinido: 0,
        seguroInvalidez: 0
      },
    }
    if (this.libroInput) {
      this.funcionesLiquidaciones.trabajador.pendiente = this.trabajador.pendiente;
      this.funcionesLiquidaciones.trabajador.sbase = this.trabajador.sbase;
      this.setValueService();
      // this.funcionesLiquidaciones.trabajador.periodo = this.trabajador.periodo;
      // this.datePeriodo.setValue(moment(this.trabajador.periodo));
    }
    this.libroremuneracion.isapre = libroremuneracion.isapre;
    this.libroremuneracion.sucur = libroremuneracion.sucur;
    this.libroremuneracion.asig_familiar = libroremuneracion.asig_familiar;
    this.libroremuneracion.f_start = libroremuneracion.f_start;
    this.libroremuneracion.cod_tipo_jornada = libroremuneracion.cod_tipo_jornada;
    this.libroremuneracion.cod_discapacidad = libroremuneracion.cod_discapacidad;
    this.libroremuneracion.jubilado = libroremuneracion.jubilado;
    this.libroremuneracion.isapre_fonasa = libroremuneracion.isapre_fonasa;
    this.libroremuneracion.cod_afp = libroremuneracion.cod_afp;
    this.libroremuneracion.afc = libroremuneracion.afc;

    this.sucursalTrabajador = this.funcionesLiquidaciones.sucursalTrabajador;
    // this.ciudadSucursal = await this.funcionesLiquidaciones.traerCiudadSucursal(this.sucursalTrabajador.idciudad);
    await this.liquidacionCrear.obtenerIndicadoresImpuestoUnico(this.trabajador.periodo);
    this.uf = this.funcionesLiquidaciones.uf;
    this.trabajador.previred = this.funcionesLiquidaciones.previred;
    this.ciudades = this.funcionesLiquidaciones.ciudades;
    // ==> ADELANTOS
    this.trabajador.adelanto = await this.ObtenerAdelantos(false);
    this.adelantos = this.funcionesLiquidaciones.adelantos;
    this.tieneAdelanto = this.funcionesLiquidaciones.numAdelanto;
    // ==> PRESTAMOS
    const objetoPrestamo = await this.CargarPrestamos(false);
    this.prestamos = objetoPrestamo.prestamos;
    this.trabajador.prestamos = this.prestamos;
    this.tienePrestamo = objetoPrestamo.tienePrestamo;
    this.trabajador.prestamo = objetoPrestamo.prestamo; // MONTO CUOTA
    this.trabajador.numCuota = objetoPrestamo.numCuota;
    this.idcuota = objetoPrestamo.idCuota;

    // await this.obtenerHaberes(true);
    await this.printpdf(true);
    // console.timeEnd();
  }

  async recargarLiquidacion() {
    this.refreshStatus = true;
    setTimeout(() => (this.refreshStatus = false), 1500);
    // SET LOS VALORES EN EL SERVICE FUNCIONES LIQUIDACIONES
    this.setValueService();
    // ==> ADELANTOS
    this.trabajador.adelanto = await this.ObtenerAdelantos(false);
    this.adelantos = this.funcionesLiquidaciones.adelantos;
    this.tieneAdelanto = this.funcionesLiquidaciones.numAdelanto;
    // ==> PRESTAMOS
    const objetoPrestamo = await this.CargarPrestamos(false);
    this.prestamos = objetoPrestamo.prestamos;
    this.trabajador.prestamos = this.prestamos;
    this.tienePrestamo = objetoPrestamo.tienePrestamo;
    this.trabajador.prestamo = objetoPrestamo.prestamo; // MONTO CUOTA
    this.trabajador.numCuota = objetoPrestamo.numCuota;
    this.idcuota = objetoPrestamo.idCuota;

    //Obtenemos los datos del trabajador mediante el rut
    await this.printpdf(true);
  }
  async ObtenerFolio() {
    const res = await this.folio.getLastFolioAsync();
    if (res.success) {
      const folio: string = res.data.toString();
      this.trabajador.folio = folio.padStart(5, '00000');
    } else {
      this.alert.error_small(res.msg);
    }
  }

  cambioDiasTrabajados() {
    const sueldo_diario = this.trabajador.sbase / 30;
    this.libroremuneracion.sbase = Math.round(sueldo_diario * this.trabajador.pendiente);
    this.printpdf(true);
  }

  async printpdf($event) {
    await this.obtenerHaberes(true);

    this.render = false;
    this.renderPdf = true;
    this.renderbuton = true;

    this.fecha = new Date();

    this.fechaFormatT = this.datePipe.transform(this.fecha, 'dd/MM/yyyy'); //aqui se transforma usando datepipe al formato año-mes-

    this.trabajador.f_start = this.fechaFormatT;
    this.trabajador.afptasa = Number(this.trabajador.afptasa);
    this.trabajador.sbase = Number(this.trabajador.sbase);
    this.trabajador.carga = Number(this.trabajador.carga);

    // console.log({...this.trabajador});
    if (this.trabajador.fk_trabajador !== 0) {
      this.src = await this.liquidacionCrear.convetToPDF(this.trabajador);
      this.tributable = this.liquidacionCrear.obtenerTotalTributable();
      this.render = true;
    }
  }

  // DESCARGA LA LIQUIDACION EN PDF
  imprimir() { saveAs(this.src.urlFile, 'Liquidacion_' + this.trabajador.nombre + '.pdf'); }

  // SUBO LA LIQUIDACION Y LA DESCARGO AUTOMATICAMENTE
  async saveLiquidacion() {
    // MOSTRAR UNA ALERTA DE CONFIRMACION PARA GUARDAR LA LIQUIDACION
    if (!confirm('¿Seguro que quiere generar y guardar la liquidación?')) return;
    await this.printpdf(true);

    let remu = this.liquidacionCrear.crear();

    const lastDate = endOfMonth(this.trabajador.periodo);
    this.liquidacion = {
      id: remu.idliquidacion,
      fecha: lastDate,
      haberes: Math.round(remu.totalimp + remu.totalnoimp),
      descuentos: Math.round(remu.totaldescuento),
      alcance_liquido: Math.round((remu.totalimp + remu.totalnoimp) - remu.totaldescuento),
      fk_trabajador: this.rut,
    }
    // SI EXISTE UN LIBRO DE REMUNERACIONES PROVENIENTE DESDE OTRO COMPONENTE, ACTUALIZAR REGISTRO
    if (this.libroInput) {
      await this.ActualizarRegistrosLiquidacion(remu);
    }else{ // SI NO EXISTE UN LIBRO DE REMUNERACIONES PROVENIENTE DESDE OTRO COMPONENTE, CREAR REGISTRO
      await this.CrearRegistrosLiquidacion(remu);
    }
  }
  async ActualizarRegistrosLiquidacion(remu: any) {
    // LIMPIO LA COLA DE CARGA DEL UPLOADER
    this.eliminarColaFile();

    // OBTENER LIQUIDACION
    const resLiquidaicon = await this.modLiquidaciones.TraerPorIdAsync(this.libroInput)
    const getLiquidacion = resLiquidaicon.success ? resLiquidaicon.data : null;
    
    const fileName = String('Liquidacion-' + this.trabajador.nombre + '-' + this.trabajador.apellido + new Date().toISOString() + '.pdf').replace(' ', '-');
    const pdfFile = new File([this.src.blobFile], fileName, { type: 'application/pdf' });

    // ==> NUMERO DE LICENCIAS Y VACACIONES
    const numLicenciasVacaciones = await this.funcionesLiquidaciones.obtenerLicenciasVacaciones();
    this.numLicencias = numLicenciasVacaciones.numLicencias;
    this.numVacaciones = numLicenciasVacaciones.numVacaciones;

    // ACTUALIZAR REGISTRO FOLIO DE LAS LIQUIDACIONES
    await this.folio.LiquidacionAsync();

    const haberes = this.trabajador.haberes;
    const ciudad = this.ciudades.find(x => x.id === this.sucursalTrabajador.idciudad);
    remu = this.construccionLibroRemuneraciones(remu, haberes, ciudad, this.libroInput.idliquidacion);
    remu.id = this.libroInput.id;
    remu.idliquidacion = this.libroInput.idliquidacion;
    this.liquidacion.id = this.libroInput.idliquidacion;

    const aprobacion: Aprobacion = {
      comentario: 'RECTIFICACIÓN DE LA LIQUIDACIÓN',
      estado: 0,
      fecha: format(new Date(), 'yyyy-MM-dd'),
      idaprobador: this.user.id,
      iddocumento: 0,
      idliquidacion: remu.idliquidacion,
    }

    this.liquidacion = {
      ...this.liquidacion,
      eliminado: getLiquidacion ? getLiquidacion.eliminado : 0,
      pagado_finiquito: getLiquidacion ? getLiquidacion.pagada_finiquito : 0,
    }

    this.libro_liquidacion_aprobacion = {
      liquidacion: this.liquidacion,
      libro: remu,
      aprobacion: aprobacion,
      userAt: '',
    }

    this.uploaderChangeLiquidacion.addToQueue([pdfFile]);
    this.uploaderChangeLiquidacion.uploadItem(this.uploaderChangeLiquidacion.queue[0]);

    // DOCUMENTO SUBIDO
    this.uploaderChangeLiquidacion.onSuccessItem = async (item: any, response: any, status: any, headers: any) => {
      const res = JSON.parse(response);
      if (this.trabajador.prestamos.length > 0) {
        this.actualizarCuotaPrestamo();
      } else {
        console.log(res.msg);
        this.alert.success_small(res.msg);
        this.modalService.dismissAll();
      }
      if (this.checkDownload) {
        saveAs(this.src.urlFile, fileName);
      }
      this.libroInput = null;
    }

    // const res = await this.modLiquidaciones.ActualizarLibroLiquidacioAsync(libro_liquidacion_aprobacion);
    // console.log('ActualizarRegistrosLiquidacion: ', {res});
    // if (!res.success) {
    //   console.error(res.msg);
    //   this.alert.errorAlert('Ocurrio un error al actualizar el libro de remuneraciones, liquidacion y/o solicitud.');
    //   return;
    // }

    // SUBIDA INCORRECTA
    this.uploaderChangeLiquidacion.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.alert.error_small('Ocurrio un error al subir el documento.');
    }
  }
  eliminarColaFile() {
    const file = this.uploaderChangeLiquidacion?.queue[0];
    if (file) this.uploaderChangeLiquidacion.removeFromQueue(file);
  }
  async CrearRegistrosLiquidacion(remu: any) {
    const existeLiquidacion = await this.verificarLiquidacionPeriodo();
    // SI EXISTE UNA LIQUIDACION CON EL MISMO PERIODO, MANDAR UNA ALERTA CON CONFIRMACION
    if (existeLiquidacion) if (!confirm('Existe una liquidación con el mismo periodo.\n¿Desea continuar?')) return;
    // LIMPIO LA COLA DE CARGA DEL UPLOADER
    this.eliminarColaFile();
    const fileName = String('Liquidacion-' + this.trabajador.nombre + '-' + this.trabajador.apellido + new Date().toISOString() + '.pdf').replace(' ', '-');
    const pdfFile = new File([this.src.blobFile], fileName, { type: 'application/pdf' });

    // ==> NUMERO DE LICENCIAS Y VACACIONES
    const numLicenciasVacaciones = await this.funcionesLiquidaciones.obtenerLicenciasVacaciones();
    this.numLicencias = numLicenciasVacaciones.numLicencias;
    this.numVacaciones = numLicenciasVacaciones.numVacaciones;

    this.uploader.addToQueue([pdfFile]);
    this.uploader.uploadAll();

    // DOCUMENTO SUBIDO
    this.uploader.onSuccessItem = async (item: any, response: any, status: any, headers: any) => {
      if (this.checkDownload) {
        saveAs(this.src.urlFile, fileName);
      }
      const datos = JSON.parse(response);
      // CREAR REGISTRO FOLIO DE LAS LIQUIDACIONES
      await this.folio.LiquidacionAsync();

      const haberes = this.trabajador.haberes;
      const ciudad = this.ciudades.find(x => x.id === this.sucursalTrabajador.idciudad);
      remu = this.construccionLibroRemuneraciones(remu, haberes, ciudad, datos);

      console.log({ remu });

      const res = await this.libro.crearAsync(remu);
      if (res.success) {
        if (this.trabajador.prestamos.length > 0) {
          this.actualizarCuotaPrestamo();
        } else {
          console.log(res.msg);
          this.alert.success_small(res.msg);
          this.modalService.dismissAll();
        }
      } else {
        console.error(res.msg);
        this.alert.error_small(res.msg);
      }
      this.libroInput = null;
    }
    // SUBIDA INCORRECTA
    this.uploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
      this.alert.error_small('Ocurrio un error al subir el documento.');
    }
  }
  actualizarCuotaPrestamo() {
    this.trabajador.prestamos.map((prestamo: Prestamos) => {
      prestamo.Cuotas = prestamo.Cuotas.map(x => {
        if (this.idcuota.includes(x.id)) x.estado = true;
        return x;
      });
      const pagadas = prestamo.Cuotas.reduce((a: number, e: Cuotas) => e.estado ? a + 1 : a, 0);
      prestamo.estado = pagadas === prestamo.cuotas;
      this.prestamos_services.actualizarPrestamoCuotaLiquidacion(prestamo.id, prestamo).subscribe(res => {
        if (res.success) {
          this.alert.success_small(res.msg);
          this.modalService.dismissAll();
        } else {
          this.alert.error_small('Ocurrio un error al actualizar el prestamo.');
          console.error(res.msg);
        }
      });
      return prestamo;
    });
  }

  async verificarLiquidacionPeriodo() {
    const res = await this.modLiquidaciones.TraerPorRutAsync(this.rut);
    // SI LA PETICION NO ES EXITOSA, MOSTRAR EL ERROR POR CONSOLA
    if (!res.success) {
      this.alert.errorAlert('Al parecer no existe el trabajador o existe problema con el servidor.')
      console.error(res.msg);
      return;
    }
    const liquidaciones = res.data.filter(x => x.eliminado === 0 && x.Aprobaciones?.estado !== 2);
    if (liquidaciones.length === 0) return false;

    const liquidacionesModify = liquidaciones.map(x => {
      const [year, month, day] = x.fecha.split('-').map(Number);
      x.fecha = new Date(year, month - 1, day, 0, 0, 0, 0);
      return x;
    })
    return liquidacionesModify.some(
      x => x.fecha.getMonth() === this.trabajador.periodo.getMonth()
        && x.fecha.getFullYear() === this.trabajador.periodo.getFullYear()
    );
  }

  construccionLibroRemuneraciones(remu: any, haberes: any[], ciudad: CiudadRegion, datos: any): any {
    const movimientos = this.funcionesLiquidaciones.movimientos;
    const movLicencia = movimientos.find(x => x.idtipoevento === 10);
    // const totalImpSinProporcional = this.liquidacionCrear.total_imponible_sin_proporcional;
    const esPlazoFijo = this.trabajador.estadoContrato === 'Contrato a Plazo Fijo';
    remu.idliquidacion = datos.data;
    remu.rut = this.rut;
    remu.cod_region_prest_servicios = ciudad?.CiudadRegion.codigo_lre ?? 0;
    remu.cod_comuna_prest_servicios = ciudad?.codigo_lre ?? 0;
    remu.cod_tipo_jornada = this.libroremuneracion.cod_tipo_jornada;
    remu.cod_discapacidad = this.libroremuneracion.cod_discapacidad;
    remu.isapre_fonasa = this.libroremuneracion.isapre_fonasa;
    remu.cod_afp = this.libroremuneracion.cod_afp;
    remu.afc = this.libroremuneracion.afc;
    const totalImpMovPersonal = movLicencia ? movimientos[movimientos?.length - 1]?.montoImponible??0 : 0;
    remu.seguroInvalidez = movLicencia
      ? Math.round((this.trabajador.previred.seguroInvalidez / 100) * totalImpMovPersonal)
      : Math.round((this.trabajador.previred.seguroInvalidez / 100) * remu.totalimp);
    remu.f_start = new Date(this.trabajador.fecha_ingreso + 'T00:00:00');
    remu.f_liquidacion = this.trabajador.periodo;
    remu.num_cargas = this.trabajador.carga;
    remu.tramo_asig_fam = this.trabajador.tramoAsigFam;
    remu.num_dias_licencias = this.numLicencias;
    remu.num_dias_vacaciones = this.numVacaciones;
    remu.totalaportesempleador = esPlazoFijo && movLicencia ? (3 / 100) * totalImpMovPersonal
      : !esPlazoFijo && movLicencia ? (2.4 / 100) * totalImpMovPersonal
        : esPlazoFijo ? (3 / 100) * remu.totalimp
          : (2.4 / 100) * remu.totalimp;
    remu.jubilado = this.libroremuneracion.jubilado ? 1 : 0;
    // HABERES
    remu.comision = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE?.codigo === 2103 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.bsemanacorrida = Math.round(haberes.reduce((a, e) => e.bSemanaCorrida === 1 ? a + e.montoBSC : a, 0) / 30 * remu.d_trabajados);
    remu.colacion = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2301 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.movilizacion = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2302 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.viaticos = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2303 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.sueldo = Math.round((haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2101 ? a + e.monto : a, 0) + this.trabajador.sbase) / 30 * remu.d_trabajados);
    remu.sobresueldo = remu.horasextras;
    remu.participacion = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2105 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.recargo_domingo = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2107 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.rem_pagada_vacaciones = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2108 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.rem_pagada_clausura = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2109 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.aguinaldo = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2110 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.bonos_rem_fijas = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2111 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.no_pactado_contrato = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2114 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.benef_remun = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2115 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.pago_horas_sindical = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2124 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.subs_incap_laboral = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2201 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.beca_estudio = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2202 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.gratificacion_zona = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2203 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.ingresos_no_constitutivos = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2204 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.perdida_caja = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2304 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.desg_herramientas = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2305 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.gasto_trabajo = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2306 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.gasto_residencia = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2307 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.sala_cuna = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2308 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.asign_teletrabajo = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2309 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.aloj_trabajo = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2310 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    remu.asig_traslacion = Math.round(haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2312 ? a + e.monto : a, 0) / 30 * remu.d_trabajados);
    // DESCUENTOS
    remu.ret_clase_media = haberes.reduce((a, e) => e.Haberes.nombre.includes('RET. PREST. SOLIDARIO 3%')
      || e.Haberes.nombre.includes('RET. PRESTAMO SOLIDARIO 3%' || e.Haberes.nombre.includes('RETENCION PRESTAMO SOLIDARIO 3%')
        || e.Habers.nombre.includes('RETENCIÓN PRESTAMO SOLIDARIO 3%')) ? a + e.monto : a, 0);
    remu.otros_desc_solic_trab = haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 3183 ? a + e.monto : a, 0);
    remu.otros_desc = haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2185 ? a + e.monto : a, 0);
    remu.pension_alimentos = haberes.reduce((a, e) => e.Haberes.ConceptoLRE.codigo === 2185 ? a + e.monto : a, 0);

    return remu;
  }

  obtenerHorasExtras(event: number) {
    this.trabajador.horasExtras = parseFloat(event.toString());
    this.libroremuneracion.horasextras = parseFloat(event.toString());
    if (this.renderPdf) {
      this.printpdf(true);
    }
  }

  async obtenerHaberes(event: boolean) {
    this.trabajador.haberes = await this.funcionesLiquidaciones.obtenerHaberes();
  }

  async CargarPrestamos(event: boolean) {
    this.trabajador.prestamo = 0;
    this.trabajador.prestamos = [];
    return await this.funcionesLiquidaciones.CargarPrestamo();
  }

  async ObtenerAdelantos(event: boolean) {
    return await this.funcionesLiquidaciones.ObtenerAdelantos();
  }
  // METODO QUE CAPTURA EL AÑO DEL DATEPICKER
  async chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.renderPdf = false;
    this.renderbuton = false;
    datepicker.close();

    const ctrlValue = this.datePeriodo.value;
    ctrlValue.year(normalizedYear.year());
    ctrlValue.month(normalizedYear.month());
    this.datePeriodo.setValue(ctrlValue);
    if (this.trabajador.periodo.getMonth() === this.datePeriodo.value._d.getMonth()
      && this.trabajador.periodo.getFullYear() === this.datePeriodo.value._d.getFullYear()) {
      this.printpdf(true);
      return;
    }
    this.trabajador.periodo = this.datePeriodo.value._d;
    // GUARDAR PERIODO EN EL LOCAL STORAGE
    this.guardarPeriodoLocalStorage();  
    this.setValueService();

    const numDias = await this.funcionesLiquidaciones.contarDias();
    const numInhasistencias = await this.funcionesLiquidaciones.cambioMes();
    const pendiente = Math.floor((numInhasistencias + numDias) * 10) / 10;
    // this.trabajador.pendiente = 0.0;
    this.trabajador.pendiente = pendiente > 30 ? 30 : pendiente;

    // ==> ADELANTOS
    this.trabajador.adelanto = await this.ObtenerAdelantos(false);
    this.adelantos = this.funcionesLiquidaciones.adelantos;
    this.tieneAdelanto = this.funcionesLiquidaciones.numAdelanto;
    // ==> PRESTAMOS
    const objetoPrestamo = await this.CargarPrestamos(false);
    this.prestamos = objetoPrestamo.prestamos;
    this.tienePrestamo = objetoPrestamo.tienePrestamo;
    this.trabajador.prestamos = this.prestamos;
    this.trabajador.prestamo = objetoPrestamo.prestamo; // MONTO CUOTA
    this.trabajador.numCuota = objetoPrestamo.numCuota;
    this.idcuota = objetoPrestamo.idCuota;

    this.uf = await this.funcionesLiquidaciones.obtenerUFs();
    await this.funcionesLiquidaciones.cargarMovimientosPersonales();
    await this.liquidacionCrear.obtenerIndicadoresImpuestoUnico(this.trabajador.periodo);
    const periodoString: string = (this.trabajador.periodo.getMonth() + 1) > 9 ? (this.trabajador.periodo.getMonth() + 1).toString() + this.trabajador.periodo.getFullYear().toString()
      : '0' + (this.trabajador.periodo.getMonth() + 1) + this.trabajador.periodo.getFullYear();
    if (periodoString !== this.trabajador.previred.periodo || this.trabajador.previred.periodo === null) {
      this.trabajador.previred = await this.funcionesLiquidaciones.obtenerPrevired();
    }
    this.printpdf(true);
  }
  guardarPeriodoLocalStorage() {
    this.helperService.savePeriodoStorage(this.trabajador.periodo);
  }
  obtenerPeriodoLocalStorage(): Date {
    return this.helperService.getPeriodoStorage();
  }
  setValueService() {
    this.funcionesLiquidaciones.setRut(this.rut);
    this.funcionesLiquidaciones.setPeriodo(this.trabajador.periodo);
  }
}