import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrimeNGConfig } from 'primeng/api';
import { FoliadosService } from 'src/app/pages/services/foliados/foliados.service';
import { LiquidacionService } from 'src/app/pages/services/liquidaciones/liquidacion.service';
import { generarLiquidacionService } from 'src/app/pages/services/PDF-GENERADOR/liquidaciones/liquidacion.service';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { saveAs } from 'file-saver';
import { HorasExtrasService } from 'src/app/pages/services/horasExtras/horasExtras.service';

@Component({
  selector: 'app-generar-liquidacion',
  templateUrl: './generar-liquidacion.component.html',
  styleUrls: ['./generar-liquidacion.component.css']
})
export class GenerarLiquidacionComponent implements OnInit {

  @Input() rut!:any;
  src:any;
  url:any;
  dateObj = new Date();
  month = this.dateObj.getUTCMonth() + 1; //months from 1-12
  day = this.dateObj.getUTCDate();
  year = this.dateObj.getUTCFullYear();
  render=true;
  renderPdf=false;

  trabajador={
    ubicacion:'',
    nombre:'',
    apellido:'',
    fk_trabajador:0,
    f_start:'',
    f_end:'',
    habiles:0,
    inhabiles:0,
    pendiente:0,
    periodo:'',
    empresa:'',
    empresaRut:0,
    palabras:'',
    folio:'0000',
    afp:'',
    afptasa:0,
    prevision:'',
    previsiontasa:0,
    horasExtras:0,
    carga:0,

  };

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private datePipe: DatePipe,
    private primeNGConfig: PrimeNGConfig,
    private folio : FoliadosService,
    private alert:AlertHelper,
    private liquidacionCrear: generarLiquidacionService,
    private horasExtrasService: HorasExtrasService,
  ) { }

  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
    // Obtenemos el Folio
    this.folio.Liquidaciones().subscribe( (res) => {
      this.trabajador.folio = res.data[0].foliado.toString();
      this.trabajador.folio= this.trabajador.folio.padStart(5, '00000');
    });

    //Obtenemos los datos del trabajador mediante el rut
    this.trabajadoresService.TraerTrabajadoresRut(this.rut).subscribe(
      (res) => {
    this.trabajador.ubicacion = res.data[0].Sucursal.nombre;
    this.trabajador.nombre= res.data[0].nombres;
    this.trabajador.apellido=res.data[0].apellidos;
    this.trabajador.fk_trabajador= res.data[0].rut;
    this.trabajador.empresa= res.data[0].Empresa_contratante.nombre;
    this.trabajador.empresaRut= res.data[0].Empresa_contratante.cedula;
    this.trabajador.afp= res.data[0].Afp.nombre;
    this.trabajador.afptasa= res.data[0].Afp.tasa;
    this.trabajador.prevision= res.data[0].Prevision.nombre;
    this.trabajador.previsiontasa= res.data[0].Prevision.tasa;
    this.trabajador.carga= res.data[0].numero_cargas;


      }

    );

    //Obtenemos las horas extras del presente mes del trabajador
    this.horasExtrasService.TraerPorRutMesActual(this.rut).subscribe(
      (res ) => {
        this.trabajador.horasExtras= res.data[0].total_horas
        //console.log(res);
      }
    );


  }

  ngAfterViewInit(): void {
    //console.log(this.rut);
    //console.log(this.trabajador);
  }

  async printpdf() {
    this.render=false;
    // await this.liquidacionCrear.convetToPDF(this.trabajador);



    setTimeout(() => {
      this.render=true;
      this.renderPdf= true;

      //console.log(this.vacacionesService.src);
    }, 1000);
    }


    imprimir(){
      saveAs(this.src, 'Liquidacion_'+this.trabajador.nombre+'.pdf');
      //console.log("AQUI DEBIERA GUARDAR LOS DATOS, Sin la imagen");


    }



}
