<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>

<div>
    <button style="background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button>Exportar a PDF</button>
    <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button
        color="green">Exportar a Excel</button>
</div>

<div class="mat-elevation-z8">
    <table mat-table id="tabla" #tabla [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>NOMBRE</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                {{ element.nombre | uppercase }}
            </td>
        </ng-container>

        <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>CODIGO LRE</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <span *ngIf="element.codigo_lre !== null">{{element.codigo_lre}}</span>
                <span *ngIf="element.codigo_lre === null">S/D</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ACCIONES</th>
            <td class="padding-table" mat-cell *matCellDef="let element">
                <button (click)="open(content,element.id)" mat-button class="button_color_primary">
                    <mat-icon style="color: white">edit</mat-icon>
                </button> &nbsp;
                <button (click)="Eliminar_Ciudad(content, element.id)" mat-button class="button_color_primary"
                    style="background-color:red">
                    <mat-icon style="color: white">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de usuarios.">
    </mat-paginator>
</div>


<ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
        <mat-card-title>Actualizar Ciudad</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input type="text" maxlength="50" matInput [(ngModel)]="update_ciudades.nombre" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Region</mat-label>
                    <!-- (selectionChange)="ObtenerRegion($event)" -->
                    <mat-select [(ngModel)]="update_ciudades.idregion" required>
                        <mat-option class="example-full-width " *ngFor="let region of regiones" [value]="region.id">
                            {{region.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Codigo LRE</mat-label>
                    <input type="text" maxlength="50" matInput [(ngModel)]="update_ciudades.codigo_lre" autonumeric
                        [options]="optionsNumeric" pTooltip="Codigo del Libro de Remuneraciones Electronico"
                        tooltipPosition="bottom" />
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-success" (click)="Actualizar_Ciudades()">
            Actualizar
        </button>
    </div>
</ng-template>