import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HonorarioService {

  constructor(
    private http: HttpClient
  ) { }

  getAllHonorarios(){
    return this.http.get(`${environment.API}/rrhh/honorario/`);
  }

  async TraerArchivoAsync(id: any): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/documento/buscarhonorariosid/${id}`).toPromise();
    } catch (error) {
      console.error(error);
      return ({success: false, msg: 'Hubo un error al obtener el archivo'} as RequestResponse);
    }
  }

  guardarHonorario(formData: FormData): Observable<any> {
    return this.http.post(`${environment.API}/rrhh/honorario/documento`, formData);
  }

  updateEstadoHonorario(id: number, data: any): Observable<any> {
    // const data = {
    //   estado: estado
    // }
    return this.http.put(`${environment.API}/rrhh/honorario/actualizar/${id}`, data);
  }

  async bulkCreateAprobaciones(aprobaciones: any[]): Promise<RequestResponse> {
    console.log({aprobaciones});
    try {
      return await this.http.post<RequestResponse>(`${environment.API}/rrhh/honorario/bulkCreateAprobaciones`, aprobaciones).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al crear las aprobaciones.'} as RequestResponse;
    }
  }
}
