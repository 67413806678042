import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Requisito } from 'src/app/shared/models/requisitos.interface';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { RequisitoService } from 'src/app/pages/services/requisitos.service';
import { CargoService } from 'src/app/pages/services/cargo.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-lista-perfil',
  templateUrl: './lista-perfil.component.html',
  styleUrls: ['./lista-perfil.component.css']
})
export class ListaPerfilComponent implements OnInit {

  @Input() list_requisitos!: any;
  @Input() id_cargo!: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() onDebounce: EventEmitter<boolean> = new EventEmitter();
  debouncer: Subject<boolean> = new Subject();
  update_requisitos: Requisito = {
    id: 0,
    descripcion: "",
    cargo: 0,
    id_cargo: 0,
  };


  ////  items.filter(item => item.name.indexOf(1) !== -1); 
  displayedColumns: string[] = ['id', 'descripcion', 'actions'];
  dataSource = new MatTableDataSource(this.list_requisitos);


  constructor(
    private alert: AlertHelper,
    private modalService: NgbModal,
    private requisitosService: RequisitoService,
    private cargoService: CargoService,
  ) { }

  ngOnInit(): void {  // Necesario para indicar los tiempos de debounce y asi indicarle al app componente padre que se realiza un cambio
    this.debouncer
      .pipe(debounceTime(500))
      .subscribe(valor => {
        this.onDebounce.emit(valor);
      });
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.list_requisitos.currentValue != changes.list_requisitos.previousValue) {

      this.dataSource = new MatTableDataSource(this.list_requisitos);
      this.dataSource.paginator = this.paginator;
    }

  }

  open(content: any, id: number) {
    this.update_requisitos.id = id
    this.requisitosService.Obtener_requisito(id).subscribe(res => {
      this.update_requisitos.descripcion = res.data.descripcion;
      this.update_requisitos.cargo = res.data.cargo;
    })
    this.limpiar_requisitos();
    this.modalService.open(content);

  }

  limpiar_requisitos() {
    this.update_requisitos.descripcion = "";
    this.update_requisitos.cargo = 0;
  }

  Actualizar_Requisito() {
    if (this.update_requisitos.descripcion.trim().length == 0) {
      this.alert.error_small('Los campos no pueden estar vacios.')
      return
    }
    this.update_requisitos.cargo = this.update_requisitos.id_cargo;
    this.requisitosService.Actualizar_requisito(this.update_requisitos).subscribe(res => {
      if (res.success == true) {
        this.alert.success_small(res.msg!)
        this.modalService.dismissAll();
        this.limpiar_requisitos();
        this.debouncer.next(true);  // instruccion que indica al app component padre que hubo un cambio
      } else {
        this.alert.error_small(res.msg!)
      }
    })
  }

  Eliminar_Requisito(content: any, id: number) {

    Swal.fire({
      title: 'Eliminar?',
      text: "Se eliminara el requisito",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.requisitosService.Eliminar_requisito(id).subscribe(res => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.debouncer.next(true); // instruccion que indica al app component padre que hubo un cambio
          } else {
            this.alert.error_small(res.msg!)
          }
        })
      }
    })
  }



}
