import { HttpClient } from '@angular/common/http';
import { ClassField } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudDespidoService {

  constructor(
    private http:HttpClient,
    private alert:AlertHelper
  ) { }

  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

  crearSolicitudDespido(data:any) {   
    console.log("data del service: ", data);
    // return this.http
    // .post<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/crear`,data)
    // .pipe(
    //   map((res:RequestResponse) =>{
    //     return res;
    //   }),
    //   catchError((err)=> this.handlerError(err))
    // );

    return this.http.post(`${environment.API}/rrhh/solicitudDespido/crear`, data);
  }

  traerInfo(): Observable<RequestResponse>{
    return this.http
    .get<RequestResponse>(`${environment.API}/rrhh/solicitudDespido`)
    .pipe(
      map((res: RequestResponse)=>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  traerSolicitud(id: number):Observable<RequestResponse>{
    return this.http.get<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/buscar/${id}`)
    .pipe(
      map((res: RequestResponse) => {
        return res;
      }),
      catchError((err) => this.handlerError(err))
    );
  }

  actualizarSolicitudDespido(data:any):Observable<RequestResponse>{
    return this.http.put<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/actualizar/${data.id}`, data)
    .pipe(
      map(( res:RequestResponse ) => {
        return res;
      }),
      catchError((err) => this.handlerError(err))
    )
  }

  eliminarSolicitudDespido(id: number): Observable<RequestResponse>{
    return this.http.delete<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/eliminar/${id}`)
    .pipe(
      map((res: RequestResponse) => {
        return res;
      }),
      catchError((err) => this.handlerError(err))
    )
  }


  async enviarCorreo(datos: any): Promise<RequestResponse> {
    console.log({datos});
    const correoData = {
      trabajador: datos,
      destinatario: 'nico.casanova10@gmail.com',
      asunto: 'Solicitud de despido',
      cuerpo: `Se ha realizado una solicitud de despido a: `,
    };

    console.log({correoData});
  
    // Envía la solicitud HTTP para enviar el correo
    try {
      return this.http.post<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/enviarCorreo/`, correoData).toPromise();
    } catch (error) {
      return { success: false, msg: 'Error' } as RequestResponse
    }
    // return this.http.post(`${environment.API}/rrhh/solicitudDespido/enviarCorreo/`, correoData)
    //   .subscribe({
    //     next: (response) => {
    //       console.log('Correo enviado con éxito:', response);
          
    //     },
    //     error: (error) => {
    //       console.log("Error al enviar el correo:", datos);
    //       console.error('Error al enviar el correo:', error);
    //     }
    //   });
  }

  async ActualizarEstado(datos: any): Promise<RequestResponse>{
    try {
      return this.http.put<RequestResponse>(`${environment.API}/rrhh/solicitudDespido/actualizarEstado/`, datos).toPromise();
    } catch (error) {
      return { success: false, msg: 'ERROR' } as RequestResponse
    }
  }

}
