import { MaterialModule } from 'src/app/material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainGenerarContratoComponent } from './main-generar-contrato/main-generar-contrato.component';
import { FormatoEstandarComponent } from './formato-estandar/formato-estandar.component';
import { FormsModule } from '@angular/forms';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ModalMostrarPdfComponent } from './modal-mostrar-pdf/modal-mostrar-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSpinnerModule } from "ngx-spinner";
import { TrabajadoresModule } from '../trabajadores/trabajadores.module';
import { SucursalesModule } from '../sucursales/sucursales.module';
import { FormatoEditableComponent } from './formato-editable/formato-editable.component';
import { FuncionesModule } from '../perfiles-ocupacionales/funciones/funciones.module';
import { IvyGalleryModule } from 'angular-gallery';
import { HelperModule } from 'src/app/helper.module';
@NgModule({
  declarations:
    [
      MainGenerarContratoComponent,
      FormatoEstandarComponent,
      ModalMostrarPdfComponent,
      FormatoEditableComponent,

    ],
  imports: [
    CommonModule,
    MaterialModule,
    PrimeNgModule,
    FormsModule,
    PdfViewerModule,
    NgxSpinnerModule,
    TrabajadoresModule,
    SucursalesModule,
    FuncionesModule,
    IvyGalleryModule,
    HelperModule
  ],
  exports: [
    MainGenerarContratoComponent,
    MaterialModule,
    MatMomentDateModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' } //se importa esta linea para que el datepicker se muestre en el formato dd-mm-yyy
    // sin enbargo la fecha que se guarda es de tipo moment , pero no tiene problema al insertarla en la base de datos 

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GenerarContratoModule { }
