import { ContratoService } from './../../../../services/contratos/contrato.service';
import { DatePipe } from '@angular/common';
import { Component,Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { DocumentosService } from 'src/app/pages/services/documentos.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
// import { SolicitudesDocumentosService } from 'src/app/pages/services/solicitudes/solicitudesdocumentos.service';
@Component({
  selector: 'app-mostrar-contratos',
  templateUrl: './mostrar-contratos.component.html',
  styleUrls: ['./mostrar-contratos.component.css'],
  providers: [DatePipe]
})
export class MostrarContratosComponent implements OnInit {
  @Input() rut:any
  constructor(
    private contraroService: ContratoService,
    private documentoService: DocumentosService,
    // private solicitudDocumentoService: SolicitudesDocumentosService,
    private datePipe: DatePipe,
    private alert:AlertHelper,
  ) { }
  displayedColumns = [
    'id',
    'tipo',
    'firma',
    'desde',
    'hasta',
    // 'jornada',
    // 'sueldo',
    'acciones'
  ];
  dataSource:any;
  contratos:any;
  info_documento:any;
  loading: boolean = false;

  ngOnInit(): void {
    this.TraerContratos();

  }

  Descargar(id :any){
    webPreferences: {
      webSecurity: false
    }
    this.contraroService.TraerArchivo(id).subscribe(
      (res)=>{
        console.log(res);
        this.info_documento =res.data[0];
        //console.log(this.info_documento);
        console.log(this.info_documento);
        window.open(`${environment.API}/contratos/`+res.data[0].ruta);
      }

    );
  }
  // async ngOnInit(): Promise<void> {
  //   await this.TraerContratos();

  // }

  // Descargar(id :any){
  //   // webPreferences: {
  //   //   webSecurity: false
  //   // }
  //   this.contraroService.TraerArchivo(id).subscribe(
  //     (res)=>{
  //       console.log(res);
  //       this.info_documento =res.data[0];
  //       console.log(this.info_documento);
  //       window.open(`${environment.API}/contratos/`+res.data[0].ruta);
  //     }

  //   );
  // }

  Borrar(id:number, estado:boolean){
    if (!confirm('¿Seguro que desea eliminar el contrato?')) return;
      if (estado) {
        this.documentoService.eliminarDocumento(id, 'CONTRATO').subscribe((res:RequestResponse) => {
          this.contraroService.eliminarContrato(id).subscribe((res:RequestResponse) => {
            this.alert.success_small('Se elimino el contrato');
            this.TraerContratos();
          }, (error) => {
            this.alert.error_small('Hubo un error al eliminar el contrato.');
            console.error(error);
          });
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar el documento.');
          console.error(error);
        });
      }else{
        this.contraroService.eliminarContrato(id).subscribe((res:RequestResponse) => {
          this.alert.success_small('Se elimino el contrato');
          this.TraerContratos();
        }, (error) => {
          this.alert.error_small('Hubo un error al eliminar el contrato.');
          console.error(error);
        });
      }
    }

  TraerContratos(){

    //console.log(this.rut)
    this.contraroService.TraerPorRut(this.rut).subscribe(
      (res) => {
        //console.table(res);
        this.contratos = res.data;
        this.dataSource = this.contratos;



        for (let i = 0; i <this.contratos.length; i++) {


          this.contratos[i].fechaFirma=this.datePipe.transform(this.contratos[i].fechaFirma,'dd-MM-yyyy');
          this.contratos[i].fechaInicio=this.datePipe.transform(this.contratos[i].fechaInicio,'dd-MM-yyyy');
          this.contratos[i].fechaTermino=this.datePipe.transform(this.contratos[i].fechaTermino,'dd-MM-yyyy');


          switch (this.contratos[i].tipo_contrato) {
            case 1:
              this.contratos[i].tipo_contrato = 'Contrato';
              break;
            case 2:
              this.contratos[i].tipo_contrato = 'Anexo';
              break;
            case 3:
              this.contratos[i].tipo_contrato = 'Anexo de Remuneración';
              break;
            default:
              this.contratos[i].tipo_contrato = 'Sin definir';
              break;
          }
        }
    
      }
    );
    }   
  obtenerTipoContrato(tipo_contrato: number) {
    const tipos = {
      1: 'Contrato',
      2: 'Anexo',
      3: 'Anexo de Remuneración'
    }
    return tipos[tipo_contrato];
  }
}
  
  // -------------------<- COM SOLICITUD PARA BORRAR ->-------------------
  //  (TIENE QUE QUITAR COMENTARIO EN CONTRATO SERVICE)
//   async ngOnInit(): Promise<void> {
//     await this.TraerContratos();

//   }

//   Descargar(id :any){
//     // webPreferences: {
//     //   webSecurity: false
//     // }
//     this.contraroService.TraerArchivo(id).subscribe(
//       (res)=>{
//         console.log(res);
//         this.info_documento =res.data[0];
//         console.log(this.info_documento);
//         window.open(`${environment.API}/contratos/`+res.data[0].ruta);
//       }

//     );
//   }

//   async Borrar(id:number){
//     this.loading = true;
//     if (confirm('¿Seguro que desea eliminar el contrato?')){
//       const res = await this.documentoService.eliminarDocumentoAsync(id, 'CONTRATO');
//       if (res.success) {
//         this.alert.success_small('Se creo la solicitud para eliminar el documento.');
//         await this.TraerContratos();
//       }else{
//         this.alert.error_small(res.msg);
//       }
//     };
//     this.loading = false;
//     // this.documentoService.eliminarDocumento(id, 'CONTRATO').subscribe((res:RequestResponse) => {
//     //   this.contraroService.eliminarContrato(id).subscribe((res:RequestResponse) => {
//     //     this.alert.success_small('Se elimino el contrato');
//     //     this.TraerContratos();
//     //   }, (error) => {
//     //     this.alert.error_small('Hubo un error al eliminar el contrato.');
//     //     console.error(error);
//     //   });
//     // }, (error) => {
//     //   this.alert.error_small('Hubo un error al eliminar el documento.');
//     //   console.error(error);
//     // });
//   }

//   async TraerContratos(){
//     //console.log(this.rut)
//     const res = await this.contraroService.TraerPorRutAsync(this.rut);
//     if (!res.success) {
//       this.alert.error_small(res.msg);
//       return;
//     }
//     this.contratos = res.data;
//     const contratoSource = [];

//     for (const contrato of this.contratos) {
//       contrato.fechaFirma = this.datePipe.transform(contrato.fechaFirma, 'dd-MM-yyyy');
//       contrato.fechaInicio = this.datePipe.transform(contrato.fechaInicio, 'dd-MM-yyyy');
//       contrato.fechaTermino = this.datePipe.transform(contrato.fechaTermino, 'dd-MM-yyyy');

//       const tipo = contrato.tipo_contrato;
//       if (tipo === 1) contrato.tipo_contrato = 'Contrato';
//       else if (tipo === 2) contrato.tipo_contrato = 'Anexo';
//       else if (tipo === 3) contrato.tipo_contrato = 'Anexo de Remuneración';
//       else contrato.tipo_contrato = 'Sin definir';

//       const resDoc = await this.documentoService.getContrato(contrato.id);
//       if (resDoc.success) {
//         const documento = resDoc.data[0];
//         const resSol = await this.solicitudDocumentoService.TraerPorDocumento(documento.cp_documento);
//         if (resSol.success) {
//           const solicitud = resSol.data;
//           contrato.pendienteSolicitud = solicitud;
//         }else{
//           contrato.pendienteSolicitud = false;
//         }
//       }else{
//         contrato.pendienteSolicitud = false;
//       }
//       contratoSource.push(contrato);
//     }
//     this.dataSource = contratoSource;
//   }
//   obtenerTipoContrato(tipo_contrato: number) {
//     const tipos = {
//       1: 'Contrato',
//       2: 'Anexo',
//       3: 'Anexo de Remuneración'
//     }
//     return tipos[tipo_contrato];
//   }
// }
