import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { BancoService } from 'src/app/pages/services/banco/banco.service';
import { AuthService } from 'src/app/pages/auth/auth.service';



interface Transferencia {
  banco: string,
  fecha: string,
  motivo: string
  monto: any,
  tipo_cuenta: string,
  numero_cuenta: number
}

@Component({
  selector: 'app-agregar-doctransferencias',
  templateUrl: './agregar-doctransferencias.component.html',
  styleUrls: ['./agregar-doctransferencias.component.css']
})

export class AgregarDoctransferenciasComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/transferencia/crear`,
    itemAlias: 'transferencia',
    additionalParameter: {
      // contrato:JSON.parse(this.contrato)
    }
  });

  //@Input() rut: any;
  @Input() trabajador: any;
  responses: string[] = [];
  myControl = new FormControl();
  keyword = 'nombre';
  BancoSeleccionado: any;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  
  transferencia: Transferencia = {
    banco: undefined,
    fecha: undefined,
    motivo: undefined,
    monto: undefined,
    tipo_cuenta: undefined,
    numero_cuenta: undefined
  }

  optionsNumeric = {
    digitGroupSeparator: '.', // usar punto como separador de miles
    decimalCharacter: ',', // usar coma como separador decimal
    decimalPlaces: 0, // no mostrar decimales
    minimumValue: '0', // no permitir valores negativos
    negativePositiveSignBehavior: false, // mostrar el signo negativo o positivo después del símbolo de moneda
    modifyValueOnUpDownArrow: false, // no modificar el valor al presionar las teclas de flecha arriba o abajo
    upDownStep: 'progressive', // usar un paso progresivo según el valor del input
    emptyInputBehavior: 'null',
  };

  bancos: any;
  nombre:any;

  motivos = [
    { id: 1, descripcion: 'Adelanto' },
    { id: 2, descripcion: 'Pago Mensual' },
    { id: 3, descripcion: 'Préstamo Empresarial' }
  ]

  constructor(
    private modalService: NgbModal,
    private alert: AlertHelper,
    private bancoservice: BancoService,
    private auth: AuthService
    
  ) {


    this.uploader.onCompleteAll = () => {
     // console.log('se termino de enviar ');
    };
    //estas funciones son propias del uploader , nose para que son necesarias la verdad 

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user= this.auth.ObtenerinfoToken();
      form.append('rut', this.trabajador.rut);
      form.append('banco', this.transferencia.banco);
      form.append('fecha', this.transferencia.fecha);
      form.append('motivo', this.transferencia.motivo);
      form.append('monto', this.transferencia.monto);
      form.append('tipo_cuenta', this.transferencia.tipo_cuenta);
      form.append('numero_cuenta', this.transferencia.numero_cuenta);
      form.append('userAt', user.nombre);
    };
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  removeFileFromQueue(){
    for(var i=0; i<this.uploader.queue.length; i++){
        this.uploader.queue[i].remove();
    }
  }

  ngOnInit(): void {
    this.bancos = this.trabajador.Banco.nombre;
    this.nombre = this.trabajador.nombres+' '+this.trabajador.apellidos;

    this.transferencia.banco = this.trabajador.Banco.id;
    this.transferencia.numero_cuenta = this.trabajador.numero_cuenta;
    this.transferencia.tipo_cuenta = this.trabajador.tipo_cuenta;
    //this.TraerBancos()

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      //console.log("la respuesta ", response);
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
        //console.log("el archivo se guardo con el nombre ", result.nombre);


        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        })
        this.modalService.dismissAll();

      } else {
        //console.log("falso", response)
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',
        })

      }
    }
  }

  // TraerBancos() {
  //   this.bancoservice.TraerTodos().subscribe(
  //     (res) => {
  //       this.bancos = res.data;

  //     }
  //   );
  // }


  onFocusEvent(event: any) {
    this.transferencia.monto = null
  }

  Guardar() {
    if (this.validar()) {
      this.uploader.uploadAll();
    }
    else {
      this.alert.errorAlert("Faltan datos, favor revisar")
    }
  }

  OcultarModal() {
    this.modalService.dismissAll();
  }

  ObtenerMotivo(event: any) {
    if (event.value == 1) {
      this.transferencia.motivo = 'Adelanto';
    }

    if (event.value == 2) {
      this.transferencia.motivo = 'Pago Mensual';
    }

    if (event.value == 3) {
      this.transferencia.motivo = 'Prestamo Empresarial';
    }
  }

  ObtenerBanco(event: any) {
    this.transferencia.banco = event.value;
  }



  validar(): boolean {
    if (this.transferencia.motivo != '' && this.transferencia.banco != '' && this.transferencia.fecha != '' && this.transferencia.monto > 0 && this.transferencia.monto <= 999999999) {
      return true;
    }
    else {
      return false
    }
  }


}
