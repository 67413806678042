import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgregarAntecedentesComponent } from './agregar-antecedentes/agregar-antecedentes.component';
import { ListaAntecedentesComponent } from './lista-antecedentes/lista-antecedentes.component';
import { MainAntecedentesComponent } from './main-antecedentes/main-antecedentes.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AgregarAntecedentesComponent,
    ListaAntecedentesComponent,
    MainAntecedentesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  exports:[
    AgregarAntecedentesComponent,
    ListaAntecedentesComponent,
    MainAntecedentesComponent
  ]
})
export class AntecedentesModule { }
