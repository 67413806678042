<div zclass="animate__animated animate__fadeInRight">
    <mat-card class="mat_card_soli">

        <div class="col-md-2" style="display: flex; justify-content: space-between; max-width: none!important;">
        
                <h3 class="mt-3">SOLICITUDES</h3>
            
            <mat-form-field>
                <mat-label>Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input>
            </mat-form-field>
        </div>


        <div class="row">

          
            <div class="col-12">

             
                <div class="container-fluid">

                    <table mat-table id="tabla" [dataSource]="dataSource" class="mat-elevation-z8">

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element"> {{ element.id}} </td>
                        </ng-container>
                        <ng-container matColumnDef="rut">
                            <th mat-header-cell *matHeaderCellDef> RUT </th>
                            <td mat-cell *matCellDef="let element"> {{ element.fk_trabajador | formatoRut }} </td>
                        </ng-container>
                        <ng-container matColumnDef="nombre">
                            <th mat-header-cell *matHeaderCellDef> NOMBRE </th>
                            <td mat-cell *matCellDef="let element"> {{ element.nombre | uppercase }} </td>
                        </ng-container>
                        <ng-container matColumnDef="razon">
                            <th mat-header-cell *matHeaderCellDef> RAZÓN </th>
                            <td mat-cell *matCellDef="let element"> {{ element.razon | uppercase }} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="acciones">
                            <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button class="button_color_primary" (click)="open(up, element.id)">
                                    <mat-icon style="color: white">edit</mat-icon>
                                </button> &nbsp;
                                <button (click)="eliminar(element.id)" mat-button class="button_color_primary"
                                    style="background-color:red">
                                    <mat-icon style="color: white">delete</mat-icon>
                                </button> &nbsp;
                                <button *ngIf="element.enviado !== true" (click)="enviarCorreo(element.fk_trabajador, element.id)"  mat-button class="button_color_primary"
                                style="background-color: rgb(64, 158, 216)">
                                <mat-icon style="color: white">send</mat-icon>
                                </button>
                                <button *ngIf="element.enviado !== false" disabled mat-button class="button_color_primary"
                                style="background-color: rgb(150, 219, 176)">
                                <mat-icon style="color: white">mail</mat-icon> <mat-icon style="color: white; font-size: 18px;">done</mat-icon> 
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Seleccionar pagina de horas extras.">
                    </mat-paginator>
                </div>

                
            </div>
        </div>

    </mat-card>

    <!-- modal -->
    <ng-template #up let-modal3>
        <div class="modal-header" style="text-align: center">
            <mat-card-title>Editar solicitud de despido</mat-card-title>
            <button type="button" class="close" aria-label="Close" (click)="modal3.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                  <p-dropdown [options]="trabajadores" [(ngModel)]="selectedValueTrabajador" name="update.nombre" optionLabel="text" optionValue="value"
                    placeholder="{{ update.nombre }}" (ngModelChange)="onTrabajadorChange($event)" [showClear]="true" [filter]="true" filterBy="text"></p-dropdown>
                </div>
              
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Razón</mat-label>
                    <textarea matInput minlength="10" rows="5" required name="update.razon" [(ngModel)]="update.razon"> {{ update.razon }}</textarea>
                  </mat-form-field>
                </div>
              </form>
        </div>
        <div class="modal-footer">
            <button mat-raised-button color="primary" (click)="EditarSolicitudDespido(update.id)">
                Editar
            </button>
        </div>
    </ng-template>
</div>