import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FileUploader, FileUploaderOptions,FileSelectDirective } from 'ng2-file-upload';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { MatSelectChange } from '@angular/material/select';
import { DatePipe, formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { GestorAsistenciaService } from 'src/app/pages/services/gestor-asistencia/gestor-asistencia.service';
import { Asistencia } from 'src/app/shared/models/gestor-asistencia/gestor-asistencia.interface'
import { AuthService } from 'src/app/pages/auth/auth.service';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-agregar-licencias',
  templateUrl: './agregar-licencias.component.html',
  styleUrls: ['./agregar-licencias.component.css'],
  providers: [DatePipe]
})
export class AgregarLicenciasComponent implements OnInit {

  @Input() rut:any;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;

  constructor(
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private asistenciaService: GestorAsistenciaService,
    private auth: AuthService
  ) { 
  
    this.uploader.onCompleteAll = () => {

      //console.log('se termino de enviar ');
    };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {

      let user= this.auth.ObtenerinfoToken();
      form.append('fechaInicio', this.licencia.fechaInicio);
      form.append('fechaTermino', this.licencia.fechaTermino);
      form.append('motivo', this.licencia.motivo);
      form.append('fk_trabajador', this.rut);
      form.append('userAt', user.nombre);
    };

  }

  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/licencias/documento`,
    itemAlias: 'licencia',

  });

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }



  licencia: any = {
  
    fechaInicio: undefined,
    fechaTermino: undefined,
    motivo: "",
    fk_trabajador: 0,
    


  }

  calendario : any ={


    motivo:"",
    id: 0,
    start: "",
    end: "",
    razon: 1,
    trabajador: 0,
    dsueldo: "",
    dbono: ""


  }

  cerrarModal(){
    this.modalService.dismissAll(); 
  }

  guardar() {

   
    if (this.validar() == true) {
      this.uploader.uploadAll();
      this.modalService.dismissAll(); 
    }
    else {
 
    }
  }

  ngOnInit(): void {
    //console.log("Trabajador id:"+this.rut);
    this.licencia.fk_trabajador=this.rut;
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
        //console.log("el archivo se guardo con el nombre ", result.nombre);
        //console.log("el archivo se subio")
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: result.msg,
        })

      //   //console.log("Iniciando proceso de inasistencia");
      //   this.calendario.trabajador=this.rut;
      //   this.calendario.motivo=this.licencia.motivo;
      //   this.calendario.razon=1;
      //   this.calendario.dsueldo="SI";
      //   this.calendario.dbono="SI";
      //  // console.log(this.calendario);

      //   this.asistenciaService.crear(this.calendario).subscribe((res: any) => {
      //     if (res.success == true) {
      //     //  console.log("INASISTENCIA CREADA CORRECTAMENTE");
  
      //     } else {
      //       this.alert.error_small(res.msg!)
      //     }
      //   })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',

        })

      }
    }




  }


    // la funcion de aca abajo captura las fechas cuando la fecha final se es indicada y se asigna a los respectivos atributos de la variable contrato
    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
      //se obtiene el valor html de los input del dateranger 
      // se transforman a string
      let fecha_i = dateRangeStart.value;
      let fecha_f = dateRangeEnd.value;
      //esta linea cambia los "/"" por "-""
      this.licencia.fechaInicio = fecha_i.replace(new RegExp("/", "g"), "-");
      this.licencia.fechaTermino = fecha_f.replace(new RegExp("/", "g"), "-");
      //se invirte la fecha del formato "dd-mm-yyyy" al formato "yyyy-mm-dd" 
      let inicio_fecha = this.licencia.fechaInicio.split("-").reverse().join("-");
      let final_fecha = this.licencia.fechaTermino.split("-").reverse().join("-");

      let inicio_arr = inicio_fecha.split("-");
      let final_arr = final_fecha.split("-");
  
      // Ajusta el día para tener dos dígitos
      inicio_arr[2] = inicio_arr[2].padStart(2, '0');
      final_arr[2] = final_arr[2].padStart(2, '0');

      inicio_arr[1] = inicio_arr[1].padStart(2, '0');
      final_arr[1] = final_arr[1].padStart(2, '0');
  
      // Reconstruye la fecha con los días ajustados
      inicio_fecha = inicio_arr.join("-");
      final_fecha = final_arr.join("-");
  
      //se crea una variable de tipo fecha ya con el string de fecha correcto
      let inicio = parseISO(inicio_fecha);
      let final = parseISO(final_fecha);


    console.log({inicio}, {inicio_fecha}, {final}, {final_fecha});
  
      //uso datepipe para transformar por la dudas 
      this.licencia.fechaInicio = this.datePipe.transform(inicio, 'YYYY-MM-dd');
      this.licencia.fechaTermino = this.datePipe.transform(final, 'YYYY-MM-dd');
      this.calendario.start=this.licencia.fechaInicio;
      this.calendario.end=this.licencia.fechaTermino;
     // console.log("fecha inicio: ", this.licencia.fechaInicio, " fecha final: ", this.licencia.fechaTermino);
    }



      validar(): boolean {

        if (
          this.licencia.fechaInicio != undefined &&
          this.licencia.fechaInicio != undefined && this.licencia.motivo.length > 0
        ) {
        return true;
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Faltan datos',
            text: 'Faltan datos , favor revisar',
    
          })
          return false;
        }
      }

}
