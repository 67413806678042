import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MovimientosPersonalService } from 'src/app/pages/services/movimientos-personal.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AfpService } from 'src/app/pages/services/afp.service';
import { formatRut } from 'rutlib';
import { MovimientosPersonal } from 'src/app/shared/models/movimientospersonal.interface';
import { addMonths, getMonth, getYear, parseISO } from 'date-fns';


@Component({
  selector: 'app-lista-movimientos-personal',
  templateUrl: './lista-movimientos-personal.component.html',
  styleUrls: ['./lista-movimientos-personal.component.css']
})
export class ListaMovimientosPersonalComponent implements OnInit {

  displayedColumns: string[] = ['posicion', 'nombres', 'tipomovimiento', 'tipoevento', 'periodo', 'acciones'];
  dataSource = new MatTableDataSource();
  textoBuscar: string = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  trabajadores: any = [];
  tiposMovimiento: any = [];
  eventos: any = [];
  eventosFilter: any[];
  afps: any = [];
  selectedValueTrabajador: number;
  selectedValue: number;
  afp: any;
  selectedValueTipo: number;
  prevision: string;
  date: Date = new Date();
  selectedFechaInicio: any;
  selectedFechaTermino: any;
  selectedRut: any;
  idMovimiento: number;
  periodo: Date = new Date();
  movimientos: MovimientosPersonal[] = [];
  movimientosFilter: MovimientosPersonal[] = [];

  constructor(
    private movimientosPersonal: MovimientosPersonalService,
    private modalService: NgbModal,
    private alert: AlertHelper,
    private trabajadorService: TrabajadoresService,
    private afpService: AfpService,
  ) { }

  closeResult = '';

  FiltrarDatos() {
    const month = getMonth(this.periodo);
    const year = getYear(this.periodo);
    this.movimientosFilter = this.movimientos.filter((x: any) => {
      const periodo = parseISO(x.periodo);
      return getMonth(periodo) === month && getYear(periodo) === year;
    });
    this.configurarTabla();
    this.dataSource.filter = this.textoBuscar;
  }
  CambiarMes(direction: string) {
    switch (direction) {
      case 'LEFT':
        this.periodo = addMonths(this.periodo, -1);
        break;
      case 'RIGHT':
        this.periodo = addMonths(this.periodo, 1);
        break;
    }
    this.FiltrarDatos();
  }

  open(content, rut: number, id: number) {
    this.idMovimiento = id;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.movimientosPersonal.TraerMovimientosPersonal().subscribe(
      (res: any) => {
        if (res.success) {

          const movimientosFiltrados = res.data.find((movimiento) => movimiento.id === id);
          console.log(movimientosFiltrados);

          if (movimientosFiltrados) {
            this.selectedValueTrabajador = movimientosFiltrados.TrabajadorMovimiento.rut;
            const periodo = movimientosFiltrados.periodo;
            this.date = new Date(periodo + 'T00:00:00');

            this.trabajadorService.TraerTrabajadoresRut(rut).subscribe(
              (res: any) => {
                console.log(res);
                if (res.success) {
                  this.afp = res.data[0].Afp.nombre;
                  this.prevision = res.data[0].Prevision.nombre;
                }
              }
            )

            this.selectedValueTipo = movimientosFiltrados.idtipomovimiento;
            this.selectedValue = movimientosFiltrados.TiposEvento.codigo;
            this.selectedFechaInicio = movimientosFiltrados.fecha_inicio;
            this.selectedFechaTermino = movimientosFiltrados.fecha_final;
            this.selectedRut = movimientosFiltrados.rutpagadora;
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  Editar() {
    const data = {
      id: this.idMovimiento,
      fk_trabajador: this?.selectedValueTrabajador,
      idtipomovimiento: this.selectedValueTipo,
      idtipoevento: this.eventosFilter.find(x => x.codigo === this.selectedValue).id,
      fecha_inicio: this.selectedFechaInicio?._d,
      fecha_final: this.selectedFechaTermino?._d,
      rutpagadora: this.selectedRut,
      periodo: this.date
    }

    this.movimientosPersonal.EditarMovimiento(data).subscribe(
      (res) => {
        if (res.success) {
          this.alert.success_small('Movimiento de personal editado con exito');
          this.selectedValue = null;
          this.date = null;
          this.selectedValueTipo = null;
          this.selectedValueTrabajador = null;
          this.selectedFechaInicio = null;
          this.selectedFechaTermino = null;
          this.selectedRut = null;

          this.modalService.dismissAll();
          this.getMovimientosPersonal();
        }
      }
    )

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getMovimientosPersonal();
    this.movimientosPersonal.eventAdd.subscribe(value => {
      if (value) {
        this.getMovimientosPersonal();
      }
    })
    this.getAllAfp();
    this.getAllTiposEventos();
    this.getAllTiposMovimiento();
    this.getAllTrabajadorInfo();
  }

  getMovimientosPersonal() {

    this.movimientosPersonal.TraerMovimientosPersonal().subscribe(
      (res: any) => {
        if (res.success) {
          this.movimientos = res.data;
          this.movimientosFilter = this.movimientos.filter(x => {
            const periodo = parseISO(x.periodo);
            const month = getMonth(this.periodo);
            const year = getYear(this.periodo);
            return getMonth(periodo) === month && getYear(periodo) === year;
          }).sort((a, b) => {
            const fechaA = new Date(a.createdAt);
            const fechaB = new Date(b.createdAt);
            return fechaB.getTime() - fechaA.getTime();
          });


          this.configurarTabla();
          // console.log("este es: ", datos);
        }
      }
    )
  }
  configurarTabla() {
    this.dataSource = new MatTableDataSource(this.movimientosFilter);
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filterLower = filter.toLocaleLowerCase().split(' ');

      const trabajador = data.TrabajadorMovimiento;
      const tiposEventos = data.TiposEvento;
      const tipoMovimiento = data.TiposMovimiento;

      const nombre = trabajador.nombres.toLowerCase() + ' ' + trabajador.apellidos.toLowerCase();
      const nombreEvento = tiposEventos.nombre.toLowerCase();
      const nombreTipo = tipoMovimiento.nombre.toLowerCase();

      return filterLower.every(x => nombre.includes(x)) || data.rut === filterLower
        || filterLower.every(x => nombreEvento.includes(x))
        || filterLower.every(x => nombreTipo.includes(x));
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter() {
    //Funcion para filtar cualquier columna por el texto buscado
    const filterValue = this.textoBuscar;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  Eliminar(id: number) {
    console.log(id);

    Swal.fire({
      title: '¿Eliminar?',
      text: "Se eliminará el movimiento de personal",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#6c736e',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.movimientosPersonal.EliminarMovimiento(id).subscribe((res: any) => {
          if (res.success == true) {
            this.alert.success_small(res.msg!)
            this.getMovimientosPersonal();
          }
        })
      }
    })
  }


  getAllTrabajadorInfo(): void {
    this.trabajadorService.TraerTrabajadores().subscribe(
      (res: any) => {
        if (res.success) {
          this.trabajadores = res.data.map(x => ({ value: x.rut, text: x.nombres?.toUpperCase() + ' ' + x.apellidos?.toUpperCase(), }));

        }
      }
    );
  }

  getAllTiposEventos(): void {
    this.movimientosPersonal.TraerTipoEvento().subscribe(
      (res: any) => {
        if (res.success) {
          this.eventos = res.data;
          this.eventosFilter = this.eventos;
          console.log(this.eventos);
        }
      }
    );
  }

  getAllAfp(): void {
    this.afpService.GetAll_afps().subscribe(
      (res: any) => {
        console.log(res);
        if (res.success) {
          this.afps = res.data;
        }
      }
    );
  }

  onAfpChange(event: any): void {


    this.selectedValue = event;

  }

  onTipoChange(event: any): void {
    const codigosAFP = [1, 2, 3, 4, 5, 7, 8, 11, 12, 13, 14, 15];
    const codigosISAPRE = [2, 3, 4, 5, 6, 13, 14, 15];

    this.selectedValueTipo = event;
    if (this.selectedValueTipo === 2) {
      this.eventosFilter = this.eventos.filter(
        x => codigosAFP.includes(x.codigo)
      );
    } else if (this.selectedValueTipo === 3) {
      this.eventosFilter = this.eventos.filter(
        x => codigosISAPRE.includes(x.codigo)
      );
    }
  }

  onTrabajadorChange(event: any): void {
    this.selectedValueTrabajador = event;

    this.trabajadorService.TraerTrabajadoresRut(this.selectedValueTrabajador).subscribe(
      (res: any) => {
        console.log("este si que si es", res);
        if (res.success) {
          this.afp = res.data[0].Afp.nombre;
          this.prevision = res.data[0].Prevision.nombre;
        }
      }
    )
  }

  getAllTiposMovimiento(): void {
    this.trabajadorService.TraerTipoMivimiento().subscribe(
      (res: any) => {
        if (res.success) {
          this.tiposMovimiento = res.data;
        } else {
          console.error("Error en la respuesta: ", res.msg);
        }
      },
      (error) => {
        console.error("Error de la solicitud:", error);
      }
    );
  }

  onKeyEntidadPagadora(event: any): void {
    this.selectedRut = event.target.value;
    const rut2 = formatRut(this.selectedRut);
    this.selectedRut = rut2;
  }

  onSubmit(): void {

    const data = {
      fk_trabajador: this?.selectedValueTrabajador,
      idtipomovimiento: this.selectedValueTipo,
      idtipoevento: this.selectedValue,
      fecha_inicio: this.selectedFechaInicio?._d,
      fecha_final: this.selectedFechaTermino?._d,
      rutpagadora: this.selectedRut,
      periodo: this.date
    };

    this.movimientosPersonal.CrearMovimiento(data).subscribe(
      (res) => {
        if (res.success) {
          this.alert.success_small('Movimiento de personal realizado con exito');
          this.selectedValue = null;
          this.date = null;
          this.selectedValueTipo = null;
          this.selectedValueTrabajador = null;
          this.selectedFechaInicio = null;
          this.selectedFechaTermino = null;
          this.selectedRut = null;

          this.movimientosPersonal.setEventAdd(true);
        } else {
          console.error(res.msg);
        }

      }
    )

  }


}
