<div class="container mt-5">
    <h3>Agregar Movimiento de Personal</h3>
    
    <div class="row align-items-center">
        <div class="col-12 col-sm-3 text-center mb-2">Trabajador</div>
        <div class="col-12 col-sm-6">
            <p-dropdown [options]="trabajadores" [(ngModel)]="selectedValueTrabajador" optionLabel="text" optionValue="value" 
            placeholder="Seleccione trabajador" (ngModelChange)="onTrabajadorChange($event)" [showClear]="true" [filter]="true" filterBy="text"></p-dropdown>
        </div>
    </div>
        
        <div class="row align-items-center"> 
        <div class="col-12 col-sm-3 text-center mb-2">Periodo </div>
        <div class="col-12 col-sm-2">
            
            <div class="card flex justify-content-center">
                <p-calendar [(ngModel)]="date" view="month" dateFormat="mm/yy" [readonlyInput]="true"></p-calendar>
            </div>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-12 col-sm-3 text-center mb-2">Tipo de movimiento </div>
        <div class="col-12 col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tipo</mat-label>
                <mat-select placeholder="Seleccione un tipo" [(ngModel)]="selectedValueTipo" (ngModelChange)="onTipoChange($event)">
                    <mat-option  [value]="tipo.id" *ngFor="let tipo of tiposMovimiento">
                        {{ tipo.nombre }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row align-items-center" *ngIf="selectedValueTipo==2">
        <div class="col-12 col-sm-3 text-center mb-2">AFP </div>
        <div class="col-12 col-sm-2">
           <strong> <p> {{ afp }} </p>   </strong>
        </div>
    </div>

    <div class="row align-items-center" *ngIf="selectedValueTipo==3">
        <div class="col-12 col-sm-3 text-center mb-2">ISAPRE </div>
        <div class="col-12 col-sm-2">
        <strong> <p> {{ prevision }} </p>   </strong>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-12 col-sm-3 text-center mb-2">Evento </div>
        <div class="col-12 col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Evento</mat-label>
                <mat-select placeholder="Seleccione un nombre" [(ngModel)]="selectedValue" (ngModelChange)="onAfpChange($event)">
                    <mat-option  [value]="evento.codigo" *ngFor="let evento of eventosFilter">
                        {{ evento.nombre }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row align-items-center" *ngIf="selectedValue==3">
        <div class="col-12 col-sm-3 text-center mb-2">Monto Imponible </div>
        <div class="col-12 col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Monto imponible</mat-label>
                <input matInput placeholder="Monto imponible" [(ngModel)]="montoImponible">
            </mat-form-field>
        </div>
    </div>

    <div class="row align-items-center" *ngIf="selectedValue!=2">
        <div class="col-12 col-sm-3 text-center mb-2">Fecha Inicio </div>
        <div class="col-12 col-sm-2">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Fecha Inicio</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="Selecciona una fecha" [(ngModel)]="selectedFechaInicio">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

        <div class="row align-items-center" *ngIf="selectedValue == 2 || selectedValue == 3 || selectedValue == 4 || selectedValue == 7 || selectedValue == 11">
            <div class="col-12 col-sm-3 text-center mb-2">Fecha Termino</div>
            <div class="col-12 col-sm-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Fecha Fin</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Selecciona una fecha" [(ngModel)]="selectedFechaTermino">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row align-items-center" *ngIf="selectedValue==3 ">
            <div class="col-12 col-sm-3 text-center mb-2">Rut Entidad Pagadora</div>
            <div class="col-12 col-sm-2">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Rut</mat-label>
                    <input matInput placeholder="Placeholder" (keyup)="onKeyEntidadPagadora($event)" [(ngModel)]="selectedRut">
                </mat-form-field>
            </div>
        </div>

        <div class="row d-flex justify-content-center">
                 <button class="btn btn-primary" (click)="onSubmit()">Agregar</button>
            
        </div>
        
    
</div>