import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';
import { MainEmpresasComponent } from './main-empresas/main-empresas.component';
import { AgregarEmpresasComponent } from './agregar-empresas/agregar-empresas.component';
import { ListaEmpresasComponent } from './lista-empresas/lista-empresas.component';
import { PrimeNgModule } from 'src/app/PrimeNg.module';


@NgModule({
  declarations: [    
    MainEmpresasComponent,
    AgregarEmpresasComponent,
    ListaEmpresasComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    PrimeNgModule,   
  ],
  exports:[
    MainEmpresasComponent,
    AgregarEmpresasComponent,
    ListaEmpresasComponent,
  ]
})
export class EmpresasModule { }
