<!--END Modal Header-->
<!-- Modal Body-->
<div class="modal-body">
    <div class="row">
    <div class="col-12"> 
        <p-dropdown matInput [options]="tipoDoc" optionLabel="text" optionValue="value" [(ngModel)]="selectedTipoDoc" placeholder="Tipo de documento">
        </p-dropdown>
    </div>
</div>
    <div class="row" *ngIf="selectedTipoDoc === 0">
        
        <div class="col-12">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Fecha </mat-label>
                <input [(ngModel)]="liquidacion.fecha" required matInput (ngModelChange)="verificarPeriodo()"
                    [matDatepicker]="picker" name="picker_firma" (click)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
                <!-- fecha de firma   -->
                <mat-hint class="mt-3" *ngIf="liq_duplicado" align="start">El periodo seleccionado corresponde a una liquidación que ya ha sido subida.</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Haberes</mat-label>
                <input type="text" [(ngModel)]="liquidacion.haberes" (change)="calcularAlcanceLiquido()" required matInput #input 
                    placeholder="$" name="haberes" autonumeric [options]="optionsNumeric">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Descuentos</mat-label>
                <input type="text" [(ngModel)]="liquidacion.descuentos" (change)="calcularAlcanceLiquido()"  required matInput #input 
                    placeholder="$" name="descuentos" autonumeric [options]="optionsNumeric">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Alcance Líquido</mat-label>
                <input type="text" [(ngModel)]="liquidacion.alcance_liquido" required matInput #input 
                    placeholder="$" name="alcanceLiquido" autonumeric [options]="optionsNumeric">
            </mat-form-field>
        </div>
        <div class="col-12">
            <label class="form-label" for="fileLiquidacion">Subir Liquidación</label>
            <input type="file" class="form-control" id="fileLiquidacion" 
                ng2FileSelect [uploader]="uploader" />
        </div>
        <div class="col-12" *ngIf="uploader.progress > 0">
            Estado de Carga
            <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="selectedTipoDoc === 1">
        

            <div class="col-6">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Fecha </mat-label>
                <input required matInput
                    [matDatepicker]="picker" name="picker_firma" [(ngModel)]="fecha">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
            </div>
            <div class="col-6">
            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Alcance líquido</mat-label>
                <input type="text" required matInput #input [(ngModel)]="alcanceLiq">
            </mat-form-field>
        </div>
  
            <div class="col-12">
                <label class="form-label" for="filePagoPorDia">Subir documento de pago por días de trabajo</label>
                <input type="file" class="form-control" id="filePagoPorDia" 
                    ng2FileSelect [uploader]="uploader2" name="honorario" />
            </div>
            <div class="col-12">
                <label class="form-label" for="fileBoleta">Subir boleta</label>
                <input type="file" class="form-control" id="fileBoleta" 
                    ng2FileSelect [uploader]="uploader2" name="honorario" />
            </div>
        
    </div>

</div>
<!--END Modal Body-->
<!-- Modal Footer-->
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()"> <span
            aria-hidden="true">Cancelar</span> </button>
    <button *ngIf="selectedTipoDoc === 0" type="button" (click)="guardar()" [disabled]="!uploader.getNotUploadedItems().length"
        class="btn btn-outline-success">Guardar</button>

        <button *ngIf="selectedTipoDoc === 1" type="button" (click)="guardarHonorario()" [disabled]="!uploader2.getNotUploadedItems().length"
        class="btn btn-outline-success">Guardar</button>

</div>