

<app-agregar-banco (onDebounce)="traerdatos($event)"></app-agregar-banco>
   
<mat-form-field appearance="fill">
  <mat-label>Buscar</mat-label>
  <input matInput (keyup)="applyFilter($event)"  #input>
</mat-form-field>
            
<div>
    <button style="background-color: rgb(207, 51, 3);" (click)="convetToPDF()" mat-raised-button>Exportar a PDF</button>
    <button style="margin-left: 15px; background-color:rgb(22, 117, 27)" (click)="exportexcel()" mat-raised-button color="green">Exportar a Excel</button>
</div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>


    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> BANCO </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef> BANCO </th>
        <td mat-cell *matCellDef="let element">
            <img [src]="element.urlLogo" alt="Image" width="60"/>
        </td>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> ACCIONES </th>
        <td mat-cell *matCellDef="let element">
          
            <button mat-button class="button_color_primary" (click)="open(up, element.id)">
                <mat-icon style="color: white">edit</mat-icon>
            </button>    &nbsp;


            <button mat-button class="button_color_primary" (click)="eliminar(element.id)" style="background-color:red">
                <mat-icon style="color: white">delete</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<ng-template style="border: 0px; z-index: 100" #up let-modal>
    <div class="modal-header">
        <mat-card-title>Actualizar Banco</mat-card-title>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Nombre del banco</mat-label>
              <input
                type="text"
                maxlength="50" 
                minlength="3"
                matInput
                name="nombre"
                [(ngModel)]="bancos.nombre"
               
              />
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Url Logo Banco</mat-label>
                <input type="text" matInput name="bancos.nombre" [(ngModel)]="bancos.urlLogo" [ngModelOptions]="{standalone: true}" />
            </mat-form-field>
        </div>      
        <div class="modal-footer" >
            <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">Cancelar</span>
                </button>
              
            <button type="button" class="btn btn-outline-success" (click)="actualizar_banco()">Guardar
                </button>
        </div>
    </div>
</ng-template>