import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { addMonths } from 'date-fns';
import { GenerarExcelPagoService } from 'src/app/pages/services/generar-excel-pago.service';
import { Unit } from 'src/app/shared/models/Enums/unit.enum';
import { OpcionElegidaExcelPago, TipoPago } from 'src/app/shared/models/excelpago.interface';

type UnitType = 'AFTER'|'BEFORE';

@Component({
  selector: 'app-generar-excel-pago',
  templateUrl: './generar-excel-pago.component.html',
  styleUrls: ['./generar-excel-pago.component.css']
})
export class GenerarExcelPagoComponent implements OnInit {
  @Input() empresasList!: any[];
  empresas: any[];
  empresa: any;
  tiposPago: TipoPago[];
  tipo: TipoPago;
  periodo: Date;
  loading: boolean;
  opciones_elegidas: OpcionElegidaExcelPago;
  
  constructor(
    private generarExcelPagoService: GenerarExcelPagoService,
  ) {
    this.loading = false;
    this.periodo = new Date();
    this.tiposPago = [
      {id: 1, tipo: 'REMUNERACION'},
      {id: 2, tipo: 'ANTICIPO'},
    ]
    this.opciones_elegidas = {
      periodo: new Date,
      tipo: null,
      empresa: null,
    }
  }

  ngOnInit(): void {
  }
  ngOnChanges(change: SimpleChanges){
    if (change.empresasList.currentValue) {
      if (this.empresasList.length > 0) {
        this.empresas = change.empresasList.currentValue;
      }
    }
  }

  setPeriodo(unit: UnitType) {
    if (Unit.AFTER === unit) this.periodo = addMonths(this.periodo, 1);
    else if (Unit.BEFORE === unit) this.periodo = addMonths(this.periodo, -1);
  }

  async generarExcelPago() {
    this.loading = true;
    this.opciones_elegidas.empresa = this.empresa;
    this.opciones_elegidas.tipo = this.tipo;
    this.opciones_elegidas.periodo = this.periodo;
    await this.generarExcelPagoService.generarExcelPago(this.opciones_elegidas);
    this.loading = false;
  }

}
