import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';

import { MainRecaudacionesComponent } from './main-recaudaciones/main-recaudaciones.component';
import { GenerarddjjComponent } from './generarddjj/generarddjj.component';


@NgModule({
  declarations: [
    MainRecaudacionesComponent,
    GenerarddjjComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    MainRecaudacionesComponent,
    GenerarddjjComponent,
  ]
})
export class RecaudacionesModule { }
