import { Component, OnInit } from '@angular/core';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import { Empresa } from 'src/app/shared/models/empresas.interface';

@Component({
  selector: 'app-main-empresas',
  templateUrl: './main-empresas.component.html',
  styleUrls: ['./main-empresas.component.css']
})
export class MainEmpresasComponent implements OnInit {

  empresas: Empresa[] = [];
  list_ciudades_main: Ciudad[] = [];
  ciudades: any[] = [];

  constructor(
    private empresaServide: EmpresaService,
    private ciudadService: CiudadService
  ) { }

  ngOnInit(): void {
    this.empresaServide.GetAll_empresaC().subscribe(
      (res) => {
        this.empresas = res.data;
      });

    this.ciudadService.GetAll_ciudades().subscribe(
      (res) => {
        this.list_ciudades_main = res.data.sort((a, b) => {
          if (a.nombre < b.nombre) { return -1 }
          if (a.nombre > b.nombre) { return 1 }
          return 0;
        }
        );
      }
    );
  }

  actualizarTabla(event: boolean) {
    this.empresaServide.GetAll_empresaC().subscribe(res => {
      this.empresas = res.data
    })
  }

}
