import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { HelperModule } from 'src/app/helper.module';
import { PrimeNgModule } from 'src/app/PrimeNg.module';

import { MainCertificadoComponent } from './main-certificado/main-certificado.component';
import { GenerarCertificadoComponent } from './generar-certificado/generar-certificado.component';

@NgModule({
  declarations: [
    MainCertificadoComponent,
    GenerarCertificadoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HelperModule,
    PrimeNgModule,
  ],
  exports: [
    MainCertificadoComponent,
    GenerarCertificadoComponent,
  ]
})
export class CertificadoModule { }
