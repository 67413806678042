import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatoRutPipe } from './formato-rut.pipe';



@NgModule({
  declarations: [
    FormatoRutPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FormatoRutPipe
  ],
  providers:[
     FormatoRutPipe
  ],
})
export class HelperModule { }
