<div class="row container-fluid">
    <div class="col-4">
        <!-- Empresa -->
        <div class="field">
            <label for="empresasSelect" class="block">
                <h3>Empresas</h3>
            </label>
            <p-dropdown inputId="empresasSelect" [options]="empresas" styleClass="w-100" [(ngModel)]="opciones_elegidas.empresaSelected" placeholder="Seleccione una empresa"
                optionLabel="nombre" optionValue="id" filter="true" filterBy="nombre">
            </p-dropdown>
        </div>
    </div>
    <div class="col-2">
        <!-- Periodo -->
        <div class="field">
            <label for="periodoPicker" class="block">
                <h3>Periodo</h3>
            </label>
            <div class="p-inputgroup">
                <button type="button" pButton pRipple icon="pi pi-chevron-left" styleClass="p-button-success" (click)="changeMonth('PREV')"></button>    
                <p-calendar styleClass="w-100" [(ngModel)]="opciones_elegidas.periodo" view="month" dateFormat="mm/yy" [readonlyInput]="true" inputId="periodoPicker"></p-calendar>
                <button type="button" pButton pRipple icon="pi pi-chevron-right" styleClass="p-button-danger" (click)="changeMonth('LATER')"></button>      
            </div>
        </div>
    </div>
    <div class="col-2 d-flex align-items-center">
        <!-- Boton generar -->
        <p-button pRipple label="Generar resumen" styleClass="p-button-lg p-button-raised p-button-success w-100" [loading]="isLoading" (onClick)="generarResumen()"></p-button>
    </div>
</div>