<div *ngIf="!loading; else cargando" class="row">
  <div class="col-5">
    <div *ngIf="renderbuton" class="row d-flex align-items-center container-fluid">
      <button class="mr-2" style="background-color: rgb(5, 125, 245); color: white;" (click)="recargarLiquidacion()"
        mat-raised-button pTooltip="Recargar liquidación" tooltipPosition="bottom" [disabled]="refreshStatus">
        <mat-icon>
          refresh
        </mat-icon>
      </button>
      <button *ngIf="renderPdf" class="mr-2" style="background-color: rgb(207, 51, 3); color: white;" [disabled]="!pdfCargado"
        (click)="imprimir()" mat-raised-button pTooltip="Descargar liquidación" tooltipPosition="bottom">
        <mat-icon>picture_as_pdf</mat-icon>
      </button>
      <button class="mr-2" style="background-color: green; color: white;" (click)="saveLiquidacion($event)"
        mat-raised-button>
        Guardar liquidación
        <mat-icon>save</mat-icon>
      </button>
      <mat-checkbox [(ngModel)]="checkDownload" color="warn">
        Descargar al guardar liquidación
      </mat-checkbox>
    </div>

    <mat-progress-bar *ngIf="!render" mode="indeterminate"></mat-progress-bar>
    <div *ngIf="renderPdf; else cargando">
      <pdf-viewer [src]="src.urlFile" [rotation]="0" [original-size]="false" [show-all]="true" (on-progress)="pdfCargado = false"
        [fit-to-page]="false" [zoom-scale]="'page-width'" (after-load-complete)="pdfCargado = true"
        [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true"
        style="max-width: 100vw; height: 100vh;">
        <!-- [stick-to-page]="true" [zoom]="1" -->
      </pdf-viewer>
    </div>
  </div>
  <div class="col-7">
    <mat-stepper [linear]="false" #stepper>
      <mat-step [stepControl]="firstFormGroup" label="Datos">
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width" (click)="dp.open()">
              <mat-label>Periodo</mat-label>
              <input matInput [matDatepicker]="dp" [formControl]="datePeriodo" 
                [max]="maxDatePeriodo" readonly>
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp startView="year" (monthSelected)="chosenYearHandler($event, dp)"
                panelClass="example-month-picker">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Dias Faltados</mat-label>
              <input type="text" required [(ngModel)]="trabajador.pendiente" matInput #input
                (blur)="cambioDiasTrabajados()" autonumeric [options]="optionsNumericDias">
              <button mat-icon-button matSuffix (click)="cambioMes()">
                <mat-icon>
                  <span class="material-icons-outlined">
                    restart_alt
                  </span>
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Sueldo Base</mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input type="text" required [(ngModel)]="trabajador.sbase" matInput #input name="Sueldo Base" autonumeric
                [options]="optionsNumeric" (blur)="printpdf($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="example-full-width">
              <span matPrefix>% &nbsp;</span>
              <mat-label>% Prevision</mat-label>
              <input type="text" required [(ngModel)]="trabajador.previsiontasa" matInput #input name="% de Prevision"
                autonumeric [options]="optionsNumericDecimal" (blur)="printpdf($event)">
            </mat-form-field>
          </div>
          <div *ngIf="ifFonasa==0" class="col">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>UF Isapre</mat-label>
              <span matPrefix>UF &nbsp;</span>
              <input type="text" required [(ngModel)]="trabajador.afptasa" matInput #input autonumeric
                [options]="optionsNumericDecimal" (blur)="printpdf($event)">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>% S. Cesantia</mat-label>
              <span matPrefix>% &nbsp;</span>
              <input type="number" required (blur)="printpdf($event)"
                [(ngModel)]="trabajador.previred.sCesantiaIndefinido" matInput #input name="% de S. Cesantia">
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" class="example-full-width">
              <span matPrefix> &nbsp;</span>
              <mat-label>Cargas</mat-label>
              <input type="text" required [(ngModel)]="trabajador.carga" matInput #input name="N° de Cargas" autonumeric
                [options]="optionsNumeric" (blur)="printpdf($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Contrato</mat-label>
              <mat-select [(value)]="trabajador.estadoContrato" required (selectionChange)="printpdf($event)">
                <mat-option *ngFor="let contrato of estado_contrato " [value]="contrato.valor">
                  {{ contrato.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Jubilación</mat-label>
              <mat-select [(value)]="trabajador.jubilado" required (selectionChange)="printpdf($event)">
                <mat-option *ngFor="let jubilado of jubilados " [value]="jubilado.id">
                  {{ jubilado.valor }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
          <!-- <div *ngIf="ifFonasa==1" class="col">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>% Fonasa</mat-label>
              <span matPrefix>% &nbsp;</span>
              <input type="text" required [(ngModel)]="trabajador.afptasa" matInput #input (blur)="printpdf($event)"
                autonumeric [options]="optionsNumeric">
            </mat-form-field>
          </div> -->
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>UF al Mes Actual</mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input type="text" required [(ngModel)]="uf.mesActual" (blur)="printpdf($event)" autonumeric
                [options]="optionsNumericUF" matInput #input name="UF Actual">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>UF al Mes Anterior</mat-label>
              <span matPrefix>$ &nbsp;</span>
              <input type="text" required [(ngModel)]="uf.mesAnterior" autonumeric [options]="optionsNumericUF"
                (blur)="printpdf($event)" matInput #input name="UF Anterior">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Sueldo Min. Imponible</mat-label>
              <span matPrefix> &nbsp;</span>
              <input type="text" required [ngModel]="trabajador.previred.rentaMinima" autonumeric readonly
                [options]="optionsNumeric" matInput #input name="Renta Minima Imponible">
            </mat-form-field>
          </div>
        </div>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" label="Haberes">
        <app-listar-haberes-trabajador (onDebounce)="printpdf($event)" [rut]="rut" [periodoLiquidacion]="trabajador.periodo"
          [tributable]="tributable"></app-listar-haberes-trabajador>

        <button mat-button matStepperPrevious>
          <mat-icon>navigate_before</mat-icon>Anterior
        </button>
        <button style="float: right;" mat-button matStepperNext>
          <mat-icon>navigate_next</mat-icon>Siguiente
        </button>

      </mat-step>
      <mat-step label="Horas Extras" state="hextras" [stepControl]="firstFormGroup">
        <app-lista-horas-extras (horasExtrasValue)="obtenerHorasExtras($event)" [periodo]="trabajador.periodo" [rut]="rut"> </app-lista-horas-extras>
        <div>
          <button mat-button matStepperPrevious>
            <mat-icon>navigate_before</mat-icon>Anterior
          </button>
          <button style="float: right;" mat-button matStepperNext>
            <mat-icon>navigate_next</mat-icon>Siguiente
          </button>
        </div>
      </mat-step>
      <mat-step state="adelantos" [stepControl]="firstFormGroup">
        <ng-template matStepLabel>Adelantos</ng-template>

        <app-agregar-adelantos (onDebounce)="recargarLiquidacion()">
        </app-agregar-adelantos>
        <br />
        <app-lista-adelantos (onDebounce)="recargarLiquidacion()" [adelantos_list]="adelantos">
        </app-lista-adelantos>

        <div>
          <button mat-button matStepperPrevious>
            <mat-icon>navigate_before</mat-icon>Anterior
          </button>
          <button style="float: right;" mat-button matStepperNext>
            <mat-icon>navigate_next</mat-icon>Siguiente
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="firstFormGroup" state="prestamos">
        <ng-template matStepLabel>Prestamos</ng-template>
        <app-agregar-prestamos (onDebounce)="recargarLiquidacion()">
        </app-agregar-prestamos>
        <br />
        <app-lista-prestamos (onDebounce)="recargarLiquidacion()" [prestamos_list]="prestamos">
        </app-lista-prestamos>
      </mat-step>
      <ng-template matStepperIcon="hextras">
        <span matBadgePosition="before" matBadgeSize="small" [matBadge]="trabajador.horasExtras"
          matBadgeColor="warn">3</span>
      </ng-template>
      <ng-template matStepperIcon="adelantos">
        4
        <mat-icon *ngIf="tieneAdelanto" matBadgePosition="after" matBadgeSize="small" [matBadge]="tieneAdelanto"
          matBadgeColor="warn">launch</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="prestamos">
        5
        <mat-icon *ngIf="trabajador.prestamo>0" matBadgePosition="before" matBadgeSize="small"
          [matBadge]="tienePrestamo" matBadgeColor="warn">store_mall_directory</mat-icon>
      </ng-template>
      <!-- <ng-template matStepperIcon="impuestoSegundaCat">
        6
        <mat-icon *ngIf="trabajador.impuestoSegundaCat>0" matBadgePosition="before" matBadgeSize="small"
          [matBadge]="tieneImpuesto" matBadgeColor="warn">store_mall_directory</mat-icon>
      </ng-template> -->
    </mat-stepper>
  </div>
</div>
<ng-template #cargando class="justify-content-center">
  <div class="d-flex align-items-center">
    <strong>Cargando liquidación...</strong>
    <div class="spinner-border text-danger ml-auto" role="status" aria-hidden="true"></div>
  </div>
</ng-template>
