<div class="container-fluid">
    <div class="row d-flex justify-content-center">
        <div class="col-4">
            <p-dropdown [options]="empresas" placeholder="Seleccione una empresa" optionLabel="nombre"
                emptyFilterMessage="Sin resultados de empresas" (onChange)="selectEmpresa($event)"
                [filter]="true" filterBy="nombre" [style]="{ width: '100%' }"></p-dropdown>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-4">
            <p-dropdown [options]="sucursales" placeholder="Seleccione una sucursal" optionLabel="nombre"
                emptyFilterMessage="Sin resultados de sucursales" (onChange)="selectSucursal($event)"
                [filter]="true" filterBy="nombre" [style]="{ width: '100%' }"></p-dropdown>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-4">
            <!-- SELECT VIEW CABECERA -->
            <mat-form-field appearance="outline" class="formulario" class="w-100">
                <mat-label>Cabecera</mat-label>
                <mat-select [(value)]="opciones_elegidas.cabecera">
                    <mat-option [value]="true">QUITAR CABECERA</mat-option>
                    <mat-option [value]="false">NO QUITAR CABECERA</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-4">
            <!-- SELECT PERIODO AÑO -->
            <mat-form-field appearance="outline" class="formulario" class="w-100">
                <mat-label>Periodo</mat-label>
                <input matInput [matDatepicker]="dp" [formControl]="date" [max]="maxDate" (blur)="chosenDate($event)">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp startView="multi-year" (monthSelected)="chosenYearHandler($event, dp)"
                panelClass="example-month-picker">
                </mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <!-- BOTON -->
            <p-button 
                [disabled]="opciones_elegidas.sucursal === 0"
                (click)="generarArchivo()" 
                [loading]="isGenerated" 
                icon="pi pi-file" 
                label="Generar archivo">
            </p-button>
        </div>
    </div>
</div>