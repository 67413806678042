<div>
    <button mat-button class="button_color_primary" (click)="open(content)">
      <span style="color: white">Agregar Ciudad </span>
      <mat-icon style="color: white">add</mat-icon>
    </button>
  </div>
  
  <ng-template style="border: 0px; z-index: 100" #content let-modal>
    <div class="modal-header">
      <mat-card-title>Agregar nueva ciudad</mat-card-title>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Nombre de la ciudad</mat-label>
            <input
              type="text"
              maxlength="50"
              minlength="3"
              matInput
              name="ciudades.nombre"
              [(ngModel)]="ciudades.nombre"
            />
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Region</mat-label>
            <mat-select (selectionChange)="ObtenerRegion($event)" required>
              <mat-option class="example-full-width " *ngFor="let region of regiones"
                [value]="region.id">
                {{region.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Codigo LRE</mat-label>
            <input matInput type="text" [(ngModel)]="ciudades.codigo_lre" pTooltip="Codigo del Libro de Remuneraciones Electronico" tooltipPosition="bottom" 
              autonumeric [options]="optionsNumeric">
        </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">Cancelar</span>
      </button>
      <button type="button" class="btn btn-outline-success" (click)="agregar()">
        Guardar
      </button>
    </div>
  </ng-template>
  