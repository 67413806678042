import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { RequestResponse } from 'src/app/shared/models/request-response.interface';
import { environment } from 'src/environments/environment';
import { catchError,map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FiniquitoService {

  constructor(
    private http:HttpClient,
    private router: Router,
    private alert:AlertHelper
  ) { }

  TraerArchivo(id:any): Observable<RequestResponse> {

    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/documento/buscarfiniquitosid/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRut(rut:any): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/finiquito/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerPorRutAsync(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/finiquito/rut/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return { success: false, msg: 'Hubo un error al obtener los finiquitos.' } as RequestResponse;
    }
  }

  eliminarContrato(id:number): Observable<RequestResponse> {
    return this.http
      .delete<RequestResponse>(`${environment.API}/rrhh/finiquito/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) => res),
        catchError(error => this.handlerError(error)),
      )
  }
  private handlerError(err: any):Observable<never>{

    let errorMessage = "";
    if (err) {
      errorMessage = `Error: ${err.error.msg}`;
    }
    this.alert.error_small(err.error.msg);
    return throwError(errorMessage);
  }

}
