//Modulos
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminModule } from './pages/admin/admin.module';
import { UsuariosModule } from './pages/admin/usuarios/usuarios.module';
import { MaterialModule } from './material.module';
import { SidebarModule } from './shared/components/sidebar/sidebar.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DenunciasModule } from './pages/admin/denuncias/denuncias.module';



//Componentes

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { BannerComponent } from './shared/components/banner/banner.component';

//Servicios

import { AlertHelper } from './shared/components/helpers/alert.helpers';
import { AuthService } from './pages/auth/auth.service';
import { InterceptorsTokenService } from './shared/interceptors/interceptors-token.service';
import { UsuariosService } from './pages/services/usuarios.service';
import { RolesService } from './pages/services/roles.service';
import { RolesModule } from './pages/admin/roles/roles.module';
import { PermisosModule } from './pages/admin/documentos/permisos/permisos.module';
import { PrimeNgModule } from './PrimeNg.module';

// AlertasModules
import { AlertaLicencias } from './shared/components/helpers/alertas/alerta-licencias/alerta-licencias.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { FormatoRutPipe } from './formato-rut.pipe';
 



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    BannerComponent,
 //   FormatoRutPipe, 
  ],
  imports: [
    BrowserModule,
    AdminModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    PrimeNgModule,
    SidebarModule,
    ReactiveFormsModule,
    HttpClientModule,
    UsuariosModule,
    FlexLayoutModule,
    NgbModule,
    RolesModule,
    PermisosModule,
    DenunciasModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorsTokenService, multi: true },
      HttpClientModule,
      AuthService,
      AlertHelper,
      UsuariosService,
      RolesService,
      AlertaLicencias,
   //   FormatoRutPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent] 
})
export class AppModule { }
