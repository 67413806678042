import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListaMovimientosPersonalComponent } from './lista-movimientos-personal/lista-movimientos-personal.component';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { FormsModule } from '@angular/forms';
import { MainMovimientosPersonalComponent } from './main-movimientos-personal/main-movimientos-personal.component';
import { MaterialModule } from 'src/app/material.module';
import { AgregarMovimientosPersonalComponent } from './agregar-movimientos-personal/agregar-movimientos-personal.component';
import { HelperModule } from 'src/app/helper.module';

@NgModule({
  declarations: [
    MainMovimientosPersonalComponent,
    ListaMovimientosPersonalComponent,
    AgregarMovimientosPersonalComponent
  ],
  imports: [
    CommonModule,
    HelperModule,
    PrimeNgModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    MainMovimientosPersonalComponent,
    ListaMovimientosPersonalComponent
  ]
})
export class MovimientoPersonalModule { }
