import { Component, OnInit } from '@angular/core';
import { TiposPrestamoService } from 'src/app/pages/services/tipoprestamo.service';

@Component({
  selector: 'app-main-tipoprestamo',
  templateUrl: './main-tipoprestamo.component.html',
  styleUrls: ['./main-tipoprestamo.component.css']
})
export class MainTipoprestamoComponent implements OnInit {

  constructor(private tprestamoService: TiposPrestamoService) { }
  tprestamo: any[] = [];

  ngOnInit(): void {
    this.tprestamoService.TraerTiposPrestamo().subscribe(
      (res) => {
        this.tprestamo = res.data.sort((a: any, b: any) => a.nombre.localeCompare(b.nombre));
      }
    );
  }


  actualizarTabla(event:boolean){
    this.tprestamoService.TraerTiposPrestamo().subscribe(res=>{
      this.tprestamo=res.data
    })
  }

}
