import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { PagenotfoundComponent } from 'src/app/shared/components/pagenotfound/pagenotfound.component';
import { HomeComponent } from '../home/home.component';
import { ListarLogsComponent } from './logs/listar-logs/listar-logs.component';
import { MainAdelantosComponent } from './gestion-pago/adelantos/main-adelantos/main-adelantos.component';
import { MainAfpComponent } from './afp/main-afp/main-afp.component';
import { MainAntecedentesComponent } from './perfiles-ocupacionales/antecedentes/main-antecedentes/main-antecedentes.component';
import { MainAsignarHaberesComponent } from './trabajadores/main-haberes/main-haberes.component';
import { MainBancoComponent } from './banco/main-banco/main-banco.component';
import { MainCargosComponent } from './cargos/main-cargos/main-cargos.component';
import { MainCartasComponent } from './documentos/cartas-amonestacion/main-cartas/main-cartas.component';
import { MainCertificadoComponent } from '../contabilidad/certificado/main-certificado/main-certificado.component';
import { MainCiudadesComponent } from './ciudades/main-ciudades/main-ciudades.component';
import { MainCompetenciasComponent } from './perfiles-ocupacionales/competencias/main-competencias/main-competencias.component';
import { MainComponent } from './trabajadores/main/main.component';
import { MainComprasComponent } from '../contabilidad/compras/main-compras/main-compras.component';
import { MainConocimientosComponent } from './perfiles-ocupacionales/conocimientos/main-conocimientos/main-conocimientos.component';
import { MainContratosComponent } from './documentos/contratos/main-contratos/main-contratos.component';
import { MainDoctransferenciasComponent } from './documentos/transferencias/main-doctransferencias/main-doctransferencias.component';
import { MainEmpresasComponent } from './empresas/main-empresas/main-empresas.component';
import { MainEstadoCivilComponent } from './estado-civil/main-estado-civil/main-estado-civil.component';
import { MainFiniquitosComponent } from './documentos/finiquitos/main-finiquitos/main-finiquitos.component';
import { FirmasMainComponent } from './firmas/firmas-main/firmas-main.component';
import { MainFuncionesComponent } from './perfiles-ocupacionales/funciones/main-funciones/main-funciones.component';
import { MainGenerarAnexoComponent } from './generar-anexo/main-generar-anexo/main-generar-anexo.component';
import { MainGenerarContratoComponent } from './generar-contrato/main-generar-contrato/main-generar-contrato.component';
import { MainGestorasistenciaComponent } from './gestorasistencia/main-gestorasistencia/main-gestorasistencia.component';
import { MainHaberesComponent } from './haberes/main-haberes/main-haberes.component';
import { MainHistorialComponent } from './historial/main-historial/main-historial.component';
import { MainHorasExtrasComponent } from './horas-extras/main-horas-extras/main-horas-extras.component';
import { MainImplementosComponent } from './implementos/main-implementos/main-implementos/main-implementos.component';
import { MainImpuesto2catComponent } from '../contabilidad/parametros/impuesto-segunda-cat/main-impuesto2cat/main-impuesto2cat.component';
import { MainJornadaLaboralComponent } from './jornada-laboral/main-jornada-laboral/main-jornada-laboral.component';
import { MainLicenciasComponent } from './documentos/licencias/main-licencias/main-licencias.component';
import { MainLiquidacionesComponent } from './documentos/liquidaciones/main-liquidaciones/main-liquidaciones.component';
import { MainMarcacionComponent } from '../trabajador/marcacion/main-marcacion/main-marcacion.component';
import { MainMotivosComponent } from './motivos-carta-amonestacion/main-motivos/main-motivos.component';
import { MainMovimientosPersonalComponent } from './movimiento-personal/main-movimientos-personal/main-movimientos-personal.component';
import { MainNacionalidadesComponent } from './nacionalidades/main-nacionalidades/main-nacionalidades.component';
import { MainPagosComponent } from '../contabilidad/pagos/main-pagos/main-pagos.component';
import { MainPerfilComponent } from './perfiles-ocupacionales/requisitos/main-perfil/main-perfil.component';
import { MainPerfilesOcupacionalesComponent } from './documentos/perfiles-ocupacionales/main-perfiles-ocupacionales/main-perfiles-ocupacionales.component';
import { MainPermisosComponent } from './documentos/permisos/main-permisos/main-permisos.component';
import { MainPlanillaPagosComponent } from './gestion-pago/planilla-pagos/main-planilla-pagos/main-planilla-pagos.component';
import { MainPrestamosComponent } from './gestion-pago/prestamos/main-prestamos/main-prestamos.component';
import { MainPreviredComponent } from '../contabilidad/previred/main-previred/main-previred.component';
import { MainPrevisionComponent } from './prevision/main-prevision/main-prevision.component';
import { MainRazonComponent } from './razon/main-razon/main-razon/main-razon.component';
import { MainRecaudacionesComponent } from '../contabilidad/recaudaciones/main-recaudaciones/main-recaudaciones.component';
import { MainRegionesComponent } from './regiones/main-regiones/main-regiones.component';
import { MainRolesComponent } from './roles/main-roles/main-roles.component';
import { MainUsuariosComponent } from './usuarios/main-usuarios/main-usuarios.component';
import { MainSucursalesComponent } from './sucursales/main-sucursales/main-sucursales.component';
// import { PagenotfoundComponent } from 'src/app/shared/components/pagenotfound/pagenotfound.component';
import { MainVacacionesComponent } from './documentos/vacaciones/main-vacaciones/main-vacaciones.component';
import { MainVentasComponent } from '../contabilidad/ventas/main-ventas/main-ventas.component';
import { SolicitudesLiquidacionComponent } from './solicitudes/solicitudes-liquidacion/solicitudes-liquidacion.component';
import { MainResumenHaberesComponent } from '../contabilidad/resumen-haberes/main-resumen-haberes/main-resumen-haberes.component';
import { SolicitudesDocumentosComponent } from './solicitudes/solicitudes-documentos/solicitudes-documentos.component';
import { ElegirDesicionCorreoComponent } from './solicitudes/solicitudes-documentos/elegir-desicion-correo/elegir-desicion-correo.component';
import { MainTipoprestamoComponent } from './tipoprestamo/main-tipoprestamo/main-tipoprestamo.component';
import { MainSolicitudDespidoComponent } from './solicitudes/solicitud-despido/main-solicitud-despido/main-solicitud-despido.component';
import { MainSolicitudVacacionesComponent } from './solicitudes/solicitudes-vacaciones/main-solicitud-vacaciones/main-solicitud-vacaciones.component';
import { MainExcelPagoComponent } from '../contabilidad/excel-pago-liquidacion/main-excel-pago/main-excel-pago.component';
import { MainSolicitudPermisosComponent } from './solicitudes/solicitud-permisos/main-solicitud-permisos/main-solicitud-permisos.component';
import { MainDenunciasComponent } from './denuncias/main-denuncias/main-denuncias.component';

const routes: Routes =
  [
    { path: 'main-trabajadores', component: MainComponent },
    { path: 'main-movimientos-personal', component: MainMovimientosPersonalComponent },
    { path: 'main-asignar-haberes', component: MainAsignarHaberesComponent },
    { path: 'main-usuarios', component: MainUsuariosComponent },
    { path: 'main-roles', component: MainRolesComponent },
    { path: 'main-permisos', component: MainPermisosComponent },
    { path: 'main-sucursales', component: MainSucursalesComponent },
    { path: 'main-ciudades', component: MainCiudadesComponent },
    { path: 'main-empresas', component: MainEmpresasComponent },
    { path: 'main-adelantos', component: MainAdelantosComponent },
    { path: 'main-prestamos', component: MainPrestamosComponent },
    { path: 'main-planilla-pagos', component: MainPlanillaPagosComponent },
    { path: 'main-afp', component: MainAfpComponent },
    { path: 'main-banco', component: MainBancoComponent },
    { path: 'main-haberes', component: MainHaberesComponent },
    { path: 'main-gestorasistencia', component: MainGestorasistenciaComponent },
    { path: 'main-prevision', component: MainPrevisionComponent },
    { path: 'main-estado-civil', component: MainEstadoCivilComponent },
    { path: 'main-nacionalidades', component: MainNacionalidadesComponent },
    { path: 'main-perfil', component: MainPerfilComponent },
    { path: 'main-cargos', component: MainCargosComponent },
    { path: 'main-funciones', component: MainFuncionesComponent },
    { path: 'main-competencias', component: MainCompetenciasComponent },
    { path: 'main-conocimientos', component: MainConocimientosComponent },
    { path: 'main-contratos', component: MainContratosComponent },
    { path: 'solicitud-desicion/:code/:option', component: ElegirDesicionCorreoComponent },
    { path: 'main-finiquitos', component: MainFiniquitosComponent },
    { path: 'main-firmas', component: FirmasMainComponent },
    { path: 'main-generar-anexo', component: MainGenerarAnexoComponent},
    { path: 'main-generar-contrato', component: MainGenerarContratoComponent},
    { path: 'main-historial', component: MainHistorialComponent },
    { path: 'main-horas-extras', component: MainHorasExtrasComponent },
    { path: 'main-implementos', component: MainImplementosComponent},
    { path: 'main-jornada-laboral', component: MainJornadaLaboralComponent},
    { path: 'main-licencias', component: MainLicenciasComponent},
    { path: 'main-liquidaciones', component: MainLiquidacionesComponent },
    { path: 'main-marcacion', component: MainMarcacionComponent},
    { path: 'main-motivos', component: MainMotivosComponent },
    { path: 'main-otros', component: MainAntecedentesComponent },
    { path: 'main-perfiles-ocupacionales', component: MainPerfilesOcupacionalesComponent},
    { path: 'main-razon', component: MainRazonComponent},
    { path: 'main-resumen-haberes', component: MainResumenHaberesComponent },
    { path: 'main-solicitudes-liquidacion', component: SolicitudesLiquidacionComponent },
    { path: 'main-solicitudes-documentos', component: SolicitudesDocumentosComponent },
    { path: 'main-tipoprestamo', component: MainTipoprestamoComponent },
    { path: 'main-transferencias', component: MainDoctransferenciasComponent },
    { path: 'main-cartas', component: MainCartasComponent },
    { path: 'main-vacaciones', component: MainVacacionesComponent},
    { path: 'listar-logs', component:ListarLogsComponent},
    //Router Contabilidada
    { path: 'main-ventas', component: MainVentasComponent},
    { path: 'main-pagos', component: MainPagosComponent},
    { path: 'main-compras', component: MainComprasComponent},
    { path: 'main-recaudaciones', component: MainRecaudacionesComponent },
    { path: 'main-certificado', component: MainCertificadoComponent },
    { path: 'main-impuesto-segunda-cat', component: MainImpuesto2catComponent},
    { path: 'main-previred', component: MainPreviredComponent },
    { path: 'main-regiones', component: MainRegionesComponent },
    { path: 'main-solicitud-despido', component: MainSolicitudDespidoComponent },
    { path: 'main-solicitud-vacaciones', component: MainSolicitudVacacionesComponent  },
    { path: 'main-solicitud-permisos', component: MainSolicitudPermisosComponent  },
    { path: 'main-excel-pago', component: MainExcelPagoComponent },
    { path: 'main-denuncias', component: MainDenunciasComponent },


    //////////////////////
    { path: '', component: HomeComponent },

  ];
@NgModule({
  imports: [

    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }
