<div class="animate__animated animate__slideInUp">


 <mat-card>
        <h3 style="text-align:center;font-size:1.77rem;font-weight:500">
            Modulo Compras
        </h3>
 
        <div>Fecha {{fecha_Actual | date :'dd/MM/yyyy'}}</div> 


        <!--  (onChange)="ObtenerRegion($event)"
        [(ngModel)]="trabajadoresregion" [ngModelOptions]="{standalone: true}"-->



        <div class="row">
            <div class="col">
                <p-dropdown [options]="clientes" (onChange)="Trabajadores($event)" [(ngModel)]="trabajadores"
                    [ngModelOptions]="{standalone: true}" placeholder="Cliente" optionLabel="nombre" [filter]="true"
                    filterBy="nombre" [showClear]="true" [style]="{ width: '100%' }">
                </p-dropdown>

            </div>
            <div class="col">

                <p-dropdown [options]="documento" (onChange)="Documentos($event)" [(ngModel)]="documentos"
                    [ngModelOptions]="{standalone: true}" placeholder="Tipo de Documentos" optionLabel="nombre"
                    [filter]="true" filterBy="nombre" [showClear]="true" [style]="{ width: '100%' }">
                </p-dropdown>

            </div>

        </div>

        <div class="row">

            <div class="col">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Fecha de Emision </mat-label>
                    <input matInput [matDatepicker]="picker" (dateChange)="Emision($event)" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUI #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>N° Folio </mat-label>
                    <input type="number" minlength="0" maxlength="500" matInput />
                </mat-form-field>

            </div>


            <div class="col">

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Fecha de Vencimiento </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput value="{{fechaMostrar}}" disabled />
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Descripcion </mat-label>
                    <input type="text" minlength="0" maxlength="500" matInput />
                </mat-form-field>

            </div>
        </div> 
    
    </mat-card>
<br>
      <mat-card>
       <div class="row">

            <div class="col"> 
                
                    <table class="table table-striped">

                        <tr>
                            <th>
                                Cuenta
                            </th>
                            <th>
                                Monto
                            </th>
                            <th>

                            </th>

                        </tr>
                        <tr>
                            <td>
                                Cuenta ejemplo
                            </td>
                            <td>
                                $ 199.812
                            </td>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="efeceto" checked>
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Efecto
                                    </label>
                                </div>
                            </td>


                        </tr>


                    </table>


                    <br>
                    <br>
                    <!-- <table style=" width: 100%; ">

                        <tr>
                            <th>
                                Inpuesto/Retencion
                            </th>
                            <th>
                                Monto
                            </th>
                            <th>
                                IVA
                            </th>


                        </tr>
                        <tr>
                            <td>
                                Cuenta ejemplo
                            </td>
                            <td>
                                $ 237.895
                            </td>
                            <td>
                                $ 37.983
                            </td>


                        </tr>

                    </table> -->
                    <!-- <div class="col">
                        <mat-form-field  appearance="outline">
                            <mat-label>Cuenta Auxiliar </mat-label>
                            <input type="text" minlength="0" maxlength="500" matInput  />
                        </mat-form-field>

                    </div> -->

                </div>
            </div> 
    
    </mat-card>


</div>