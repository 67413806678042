import { Component, OnInit } from '@angular/core';
import { CargoService } from 'src/app/pages/services/cargo.service';
import { CiudadService } from 'src/app/pages/services/ciudades.service';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { SucursalService } from 'src/app/pages/services/sucursales.service';
import { Cargo } from 'src/app/shared/models/cargo.interface';
import { Ciudad } from 'src/app/shared/models/ciudades.interface';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { Sucursal } from 'src/app/shared/models/sucursales.interface';

@Component({
  selector: 'app-main-cargos',
  templateUrl: './main-cargos.component.html',
  styleUrls: ['./main-cargos.component.css']
})
export class MainCargosComponent implements OnInit {

  cargos: Cargo[] = [];
  list_sucursales_main: Sucursal[] = [];
  sucursales : any[] = [];

  constructor(    
    private cargoService: CargoService,
    private sucursalService: SucursalService
  ) { }

  ngOnInit(): void {
    this.cargoService.GetAll_cargoS().subscribe(
      (res) => {                
        this.cargos = res.data
      });
              
      this.sucursalService.GetAll_sucursales().subscribe(
        (res) => {                            
          this.list_sucursales_main = res.data;                    
        }
      );
    }

  actualizarTabla(event:boolean){
    this.cargoService.GetAll_cargoS().subscribe(res=>{          
      this.cargos=res.data
    })
  }

}
