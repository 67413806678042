import { MatSortModule } from '@angular/material/sort';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { MaterialModule } from 'src/app/material.module';
import { AgregarTrabajadorComponent } from './agregar-trabajador/agregar-trabajador.component';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ListaTrabajadoresComponent } from './lista-trabajadores/lista-trabajadores.component';
import { HaberesTrabajadorComponent } from './haberes-trabajador/haberes-trabajador.component';
import { ListarHaberesTrabajadorComponent } from './listar-haberes-trabajador/listar-haberes-trabajador.component';
import { ModalEditarComponent } from './modal-editar/modal-editar.component';
import { HelperModule } from 'src/app/helper.module';
import { CalendarModule } from 'primeng/calendar';
import { PrimeNgModule } from 'src/app/PrimeNg.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { MainAsignarHaberesComponent } from './main-haberes/main-haberes.component';
import { MostrarDocumentoTrabajadorComponent } from './mostrar-documento-trabajador/mostrar-documento-trabajador.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';

@NgModule({
  declarations: [
    ListaTrabajadoresComponent,
    MainComponent,
    AgregarTrabajadorComponent,
    HaberesTrabajadorComponent,
    ListarHaberesTrabajadorComponent,
    ModalEditarComponent,
    MainAsignarHaberesComponent,
    MostrarDocumentoTrabajadorComponent,
    UploadDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    MatDatepickerModule,
    MatSortModule,
    HelperModule,
    CalendarModule,
    PrimeNgModule,
    AutonumericModule
  ],
  exports:[
    ListaTrabajadoresComponent,
    MainComponent,
    AgregarTrabajadorComponent,
    HaberesTrabajadorComponent,
    ListarHaberesTrabajadorComponent
  ]
})
export class TrabajadoresModule { }
