import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrabajadoresService } from 'src/app/pages/services/trabajadores/trabajadores.service';
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers';
import { FileUploader } from 'ng2-file-upload';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { MatSelectChange } from '@angular/material/select';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { EmpresaService } from 'src/app/pages/services/empresas.service';
import { Empresa } from 'src/app/shared/models/empresas.interface';
import { ContratoService } from 'src/app/pages/services/contratos/contrato.service';
import { cleanRut } from 'rutlib/lib';

export interface DialogData {
  url: any;

}
@Component({
  selector: 'app-lista-contrato',
  templateUrl: './lista-contrato.component.html',
  styleUrls: ['./lista-contrato.component.css'],
  providers: [DatePipe],
})
export class ListaContratoComponent implements OnInit {
  estado_contrato_old:number;
  num_trab_finiquitados:number = 0;
  selectEstadoContrato:string = "";
  empresas:Empresa[] = [];
  selectEmpresa: string = '';
  empresa_old:number = undefined;
  textSearch: string = '';
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  dataSource: any;
  lista_trabajadores: any[] = [];
  trabajadoresFilter: any[] = [];
  RutTrabajador: any;
  // responses: string[] = [];
  // cambiarEmpresaTrabajador:boolean = true;
  // cambiarEstadoContrato:boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns = [
    'rut',
    'nombres',
    'empresa',
    'estado_contrato',
    'acciones'
  ];
  contrato: any = {
    fechaFirma: null,
    fechaInicio: null,
    fechaTermino: null,
    estado: "",
    tipo_contrato: "",
    // jornada: "",
    // tipoSueldo: "",
    // sueldo: "",
  }


  public uploader: FileUploader = new FileUploader({
    url: `${environment.API}/rrhh/contrato/documento`,
    itemAlias: 'contrato',

  });
  //metodo principal que se llama apenas se invoque esta pagina
  async ngOnInit() {
    await this.TraerDatos();
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      let result = JSON.parse(response);
      //response viene directo del backend
      if (result.response == true) {
        this.limpiar();
        this.alert.success_small('Contrato subido correctamente');
        this.modalService.dismissAll();
        this.contratoService.setVerificarContratosPlazoFijo(true);
        this.TraerDatos();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error al subir archivo',
          text: 'se genero un error al subir archivo',
        })
      }
    }
  };

  constructor(
    private modalService: NgbModal,
    private trabajadoresService: TrabajadoresService,
    private empresaService: EmpresaService,
    private alert: AlertHelper,
    private auth: AuthService,
    private _liveAnnouncer: LiveAnnouncer,
    private contratoService:ContratoService,
    private datePipe: DatePipe,
  ) {
    // Estas funciones son propias del uploader , nose para que son necesarias la verdad
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      let user= this.auth.ObtenerinfoToken();

      form.append('fechaFirma', this.contrato.fechaFirma);
      form.append('fechaInicio', this.contrato.fechaInicio);
      form.append('fechaTermino', this.contrato.fechaTermino);
      form.append('estado', this.contrato.estado);
      form.append('tipo_contrato', this.contrato.tipo_contrato);
      form.append('rut', this.RutTrabajador);
      form.append('userAt', user.nombre);
      // form.append('tipoSueldo', this.contrato.tipoSueldo);
      // form.append('sueldo', this.contrato.sueldo);
      // form.append('jornada', this.contrato.jornada);
    };
    this.uploader.onErrorItem = (item, response, status) => {
      this.limpiar();
      this.uploader.clearQueue();
    }
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  // SE GUARDA EL CONTRATO EN EL SERVIDOR Y LOS DATOS EN LA BD
  async guardar() {
    if (this.validar()) {
      // console.log(this.estado_contrato_old !== Number(this.selectEstadoContrato) || this.empresa_old !== Number(this.selectEmpresa));
      this.contrato.fechaFirma = this.contrato.fechaFirma ? this.contrato.fechaFirma?._d : this.datePipe.transform(new Date(0), 'yyyy-MM-dd');
      this.contrato.fechaInicio = this.datePipe.transform(this.contrato.fechaInicio._d, 'yyyy-MM-dd');
      this.contrato.fechaTermino = this.datePipe.transform(this.contrato.fechaTermino?._d, 'yyyy-MM-dd');
      console.log({...this.contrato});
      if (this.estado_contrato_old !== Number(this.selectEstadoContrato) || this.empresa_old !== Number(this.selectEmpresa)) {
        this.actualizarTrabajador()
      }
      this.subirContrato();
    }
    else {
      this.alert.error_small('Existen datos vacios.');
     // console.log("fallo el validar y entro aca por alguna razón ")
    }
  }
  // SE ACTUALIZAN ALGUNOS DATOS QUE SE CAMBIAN AL SUBIR UN CONTRATO
  actualizarTrabajador(){
    this.trabajadoresService.TraerTrabajadoresRut(this.RutTrabajador)
      .subscribe(({data}) =>{
        console.log({data});
        const trabajador = data[0]; 
        this.eliminarEntidades(trabajador);
        if (this.estado_contrato_old !== Number(this.selectEstadoContrato)) {
          switch (this.selectEstadoContrato) {
            case '1':
              trabajador.estado_contrato = 'Contrato a Plazo Fijo';
              break;
            case '2':
              trabajador.estado_contrato = 'Contrato Indefinido';
              break;
            case '3':
              trabajador.estado_contrato = 'Contrato por Obra o Faena';
              break;
            case '4':
              trabajador.estado_contrato = 'Finiquitado';
              break;
          }
        }
        if (this.empresa_old !== Number(this.selectEmpresa)) {
          trabajador.empresa_contratante = this.selectEmpresa;
        }
        this.trabajadoresService.ActualizarTrabajador(trabajador).subscribe(res => {
          if (res.success) {
            this.subirContrato();
          } else {
            this.alert.error_small(res.msg);
          }
        })
      }
    );
  }
  // SE SUBE EL CONTRATO
  subirContrato(){
    // Se llama el metodo para subir archivo cuando esta todo validado
    this.uploader.uploadAll();
    this.uploader.onSuccessItem = () => {
      this.alert.success_small('Contrato subido correctamente');
      this.modalService.dismissAll();
      this.contratoService.setVerificarContratosPlazoFijo(true);
      this.TraerDatos();
  
    }
  }
  // ELIMINA LAS ENTIDADES DEL TRABAJADOR ANTES DE ENVIAR PARA ACTUALIZAR SUS DATOS
  eliminarEntidades(trabajador:any){
    delete trabajador.Ciudad;
    delete trabajador.Afp;
    delete trabajador.Cargo;
    delete trabajador.Empresa_contratante;
    delete trabajador.Estado_civil;
    delete trabajador.Nacionalidad;
    delete trabajador.Prevision;
    delete trabajador.Sucursal;
    delete trabajador.Banco;
  }
  validar(): boolean {
    console.log(this.contrato.estado, this.contrato.tipo_contrato)
    if (
      // this.contrato.sueldo != undefined &&
      // this.contrato.fechaFirma != undefined &&
      this.contrato.estado != undefined &&
      this.contrato.tipo_contrato != undefined
      // this.contrato.jornada != undefined &&
      // this.contrato.tipoSueldo != undefined &&
      // this.contrato.fechaInicio.length > 0 &&
      // this.contrato.fechaTermino.length > 0
    ) {
      //console.log("esta todo validado")
      //console.log(true);
      return true;
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Faltan datos , favor revisar',

      });
      return false;
    }
  }

  limpiar(): void {
    this.contrato.fechaFirma = undefined;
    this.contrato.fechaInicio = undefined;
    this.contrato.fechaTermino = undefined;
    // this.contrato.jornada = undefined;
    // this.contrato.tipoSueldo = undefined;
    // this.contrato.sueldo = undefined;
    this.contrato.estado = undefined;
    this.contrato.tipo_contrato = undefined;
    //window.location.reload();
  }

  open(content: any ,rut:any) {
    this.RutTrabajador=rut;
    const trabajador = this.lista_trabajadores.find(x => x.rut === rut);
    if (trabajador === undefined) {
      this.alert.errorAlert('Ocurrio un error al conseguir los datos del trabajador.\nAl parecer no existe el trabajador registrado.');
      return;
    }
    this.empresa_old = trabajador.empresa_contratante;
    this.empresaService.GetAll_empresas().subscribe(({data}) => {
      this.empresas = data;
      this.selectEmpresa = trabajador.empresa_contratante;
    })
    let estado_contrato:number = undefined;
    switch (trabajador.estado_contrato) {
      case 'Contrato a Plazo Fijo':
        estado_contrato = 1;
        this.estado_contrato_old = estado_contrato;
        break;
      case 'Contrato Indefinido':
        estado_contrato = 2;
        this.estado_contrato_old = estado_contrato;
        break;
      case 'Contrato por Obra o Faena':
        estado_contrato = 3;
        this.estado_contrato_old = estado_contrato;
        break;
      case 'Finiquitado':
        estado_contrato = 4;
        this.estado_contrato_old = estado_contrato;
        break;
    }
    this.modalService.open(content, { size: 'lg' });
    this.contrato.estado = estado_contrato;
    this.selectEstadoContrato = estado_contrato.toString();
  }

  open2(content2: any ,rut:any) {
    this.modalService.open(content2, { size: 'xl' });
    this.RutTrabajador=rut;
  }

  // la funcion de aca abajo captura las fechas cuando la fecha final se es indicada y se asigna a los respectivos atributos de la variable contrato
  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    //se obtiene el valor html de los input del dateranger
    // se transforman a string
    let fecha_i = dateRangeStart.value;
    let fecha_f = dateRangeEnd.value;
    //esta linea cambia los "/"" por "-""
    // this.contrato.fechaInicio = fecha_i.replace(new RegExp("/", "g"), "-");
    // this.contrato.fechaTermino = fecha_f.replace(new RegExp("/", "g"), "-");
    // //se invirte la fecha del formato "dd-mm-yyyy" al formato "yyyy-mm-dd"
    // let inicio_fecha = this.contrato.fechaInicio.split("-").reverse().join("-");
    // let final_fecha = this.contrato.fechaTermino.split("-").reverse().join("-");
    //se crea una variable de tipo fecha ya con el string de fecha correcto
    // let inicio = new Date(inicio_fecha);
    // let final = new Date(final_fecha);

    //uso datepipe para transformar por la dudas
    // this.contrato.fechaInicio = this.datePipe.transform(inicio, 'YYYY-MM-dd');
    // this.contrato.fechaTermino = this.datePipe.transform(final, 'YYYY-MM-dd');
    //console.log("fecha inicio: ", this.contrato.fechaInicio, " fecha final: ", this.contrato.fechaTermino);
  }
  // ORDENA LOS DATOS
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async TraerDatos() {
    const res = await this.trabajadoresService.TraerTrabajadoresAsync();
    if (res.success) {
      this.lista_trabajadores = res.data;
      this.trabajadoresFilter = this.lista_trabajadores.filter(x => x.estado !== 0);
      // CONTAR LOS TRABAJADORES FINIQUITADOS
      this.num_trab_finiquitados = this.lista_trabajadores.reduce((a,e) => e.estado === 0 ? a + 1 : a, 0);
      this.dataSource = new MatTableDataSource<any>(this.trabajadoresFilter);
      this.dataSource = this.configurarTabla(this.dataSource);
    }else{
      this.alert.error_small(res.msg);
    }
  }

  configurarTabla(dataSource){
    dataSource.paginator = this.paginator;
    // CONFIGURA EL FILTRADO DE DATOS
    dataSource.filterPredicate = (data:any, filter:string) => {
      return this.configurarFilterPredicate(data, filter);
    };
    // CONFIGURA EL ORDENAMIENTO DE LOS DATOS DE LA EMPRESA
    dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'empresa': return item.Empresa_contratante.nombre;
        default: return item[property];
      }
    }
    dataSource.sort = this.sort;
    return dataSource;
  }
  configurarFilterPredicate(data: any, filter: string) {
    const filterLower = filter.toLowerCase().split(' ');

    const rutSinPuntos: string = cleanRut(data.rut.toString());
    const nombre: string = this.normalizeText(data.nombres+ ' ' +data.apellidos).toLowerCase();
    const empresa: string = this.normalizeText(data.Empresa_contratante.nombre).toLowerCase();
    const estado_contrato: string = data.estado_contrato.toLowerCase();

    return filterLower.every(
      x => rutSinPuntos.includes(x) || data.rut.toString().includes(x) 
        || nombre.includes(x) || empresa.includes(x) || estado_contrato.includes(x)
    );
  }
  // METODO QUE FILTRA EL CONTENIDO DE LA TABLA
  applyFilter(textSearch: string){
    this.textSearch = textSearch;
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }

  ObtenerEstadoContrato(event:MatSelectChange) {
    this.contrato.estado = event.value;
  }
  cambiarFechaInicio(){
    console.log(this.contrato.fechaInicio)
    if (!this.contrato.fechaInicio) {
      this.contrato.fechaInicio = this.contrato.fechaFirma;
    }else if(this.contrato.fechaFirma.isAfter(this.contrato.fechaInicio)){
      this.contrato.fechaInicio = this.contrato.fechaFirma;
    };
  }
  viewDatosFiniquitados(viewFiniquitados: boolean){
    this.trabajadoresFilter = viewFiniquitados ? 
      this.lista_trabajadores : this.lista_trabajadores.filter(x => x.estado !== 0);

    this.dataSource = new MatTableDataSource<any>(this.trabajadoresFilter);
    this.dataSource = this.configurarTabla(this.dataSource);
    this.dataSource.filter = this.textSearch.trim().toLowerCase();
  }
  // Función que elimina los diacríticos de las vocales de una cadena
  normalizeText(texto: string): string {
    return texto?.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2").normalize();
  }
}