<div>
  <button mat-button class="button_color_primary" (click)="open(content)">
    <span style="color: white">Agregar Prestamo </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar nuevo prestamo</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-stepper orientation="vertical" [linear]="isLinear" #stepper>
      <mat-step label="Prestamo" state="payments">
        <!-- Trabajador (Tiene que venir de la lista de trabajadores) -->
        <div class="row mb-0">
          <div class="col-8">
            <label for="selectTrabajador">Trabajador</label>
            <p-dropdown id="selectTrabajador" [options]="trabajadores_aux" placeholder="Seleccione un trabajador"
              optionLabel="nombre" emptyFilterMessage="Sin resultados de trabajadores"
              (onChange)="ObtenerRutTrabajador($event)" [showClear]="true" [filter]="true" filterBy="nombre"
              [style]="{ width: '100%' }"></p-dropdown>
          </div>
          <div class="col-4">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Rut trabajador</mat-label>
              <input *ngIf="prestamos.fk_trabajador != '' " type="text" disabled matInput name="prestamos.fk_trabajador"
                [ngModel]="prestamos.fk_trabajador | formatoRut " class="rounded" />
              <input *ngIf="prestamos.fk_trabajador == '' " type="text" disabled matInput class="rounded" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <!-- Autoriza -->
          <div class="col-6">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Autoriza</mat-label>
              <input type="text" maxlength="100" minlength="3" matInput name="prestamos.autoriza" required
                [(ngModel)]="prestamos.autoriza" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Tipo prestamo</mat-label>
              <mat-select [(value)]="prestamos.idtipo" required>
                <mat-option [value]="tipo.id" *ngFor="let tipo of tiposprestamo">{{ tipo.nombre }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <!-- Tipo Prestamo [(value)]="selectedFood" -->
        <div class="row">
          <!-- Observación -->
          <div class="col-12">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Observación</mat-label>
              <textarea type="textarea" rows="3" maxlength="200" name="prestamos.observacion" matInput
                [(ngModel)]="prestamos.observacion"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Fecha de solicitud</mat-label>
              <input matInput (click)="picker.open()" (focus)="picker.open()" [matDatepicker]="picker"
                [(ngModel)]="prestamos.fechaPrestamo" (dateChange)="obtenerFechaPrestamo()" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker touchUI #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- Cuotas -->
          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Cuotas</mat-label>
              <input type="number" min="1" max="999" matInput name="prestamos.cuotas" required (blur)="obtenerNumCuotas()"
                [(ngModel)]="prestamos.cuotas" />
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Pagadas</mat-label>
              <input type="number" min="0" max="999" matInput [value]="cuotasPagadas" (blur)="obtenerCuotasPagadas($event)" />
            </mat-form-field>
          </div>

          <!-- Monto Total -->
          <div class="col-4">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Monto total</mat-label>
              <input type="text" matInput name="prestamos.montoTotal" required [(ngModel)]="prestamos.montoTotal" (blur)="obtenerMonto()"
                autonumeric [options]="optionsNumeric" />
            </mat-form-field>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button mat-raised-button matStepperNext color="primary">Ir a las cuotas</button>
          </div>
        </div>
      </mat-step>
      <mat-step label="Cuotas del prestamo" state="request_quote">
        <app-form-cuotas 
          [numCuotas]="numCuotas" 
          [montoPrestamo]="montoPrestamo" 
          [fechaPrestamo]="fechaPrestamo" 
          [cuotasPagadas]="cuotasPagadas"
          [accion]="accion"
          ($cuotas)="recibirCuotas($event)"
          ></app-form-cuotas>
        <div class="row">
          <div class="col-12">
            <p-divider></p-divider>
          </div>
          <div class="col-12 d-flex justify-content-end">
            <button mat-raised-button matStepperPrevious color="primary">Ir al prestamo</button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="cerrarModal()">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="validarPrestamo()">
      Guardar
    </button>
  </div>
</ng-template>