<div>
  <button mat-button class="button_color_primary" (click)="open(content)">
    <span style="color: white">Agregar Sucursal </span>
    <mat-icon style="color: white">add</mat-icon>
  </button>
</div>

<ng-template style="border: 0px; z-index: 100" #content let-modal>
  <div class="modal-header">
    <mat-card-title>Agregar nueva sucursal</mat-card-title>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Centro de Costo</mat-label>
          <input matInput type="number" min="0" max="99" name="sucursales.centroCosto" [(ngModel)]="sucursales.centroCosto" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input type="text" maxlength="50" minlength="3" matInput name="sucursales.nombre"
            [(ngModel)]="sucursales.nombre" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <p-dropdown [options]="ciudades_dropdown" placeholder="Seleccione la ciudad" optionLabel="text"
          styleClass="pt-2 pb-2" emptyFilterMessage="Sin resultados de ciudades" (onChange)="ObtenerCiudad($event)"
          [showClear]="true" [filter]="true" filterBy="text" [style]="{ width: '100%' }">
        </p-dropdown>
      </div>
      <div class="col-12">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Direccion</mat-label>
          <textarea matInput cols="2" [(ngModel)]="sucursales.direccion"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">Cancelar</span>
    </button>
    <button type="button" class="btn btn-outline-success" (click)="agregar()">
      Guardar
    </button>
  </div>
</ng-template>