import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AlertHelper } from 'src/app/shared/components/helpers/alert.helpers'
import { catchError, map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { RequestResponse } from 'src/app/shared/models/request-response.interface'
import { Observable, throwError } from 'rxjs'


@Injectable({
    providedIn: 'root'
})

export class HorasExtrasService {

    constructor(
        private http: HttpClient,
        private alert: AlertHelper,
        private router: Router
    ) { }



    TraerTrabajadores(): Observable<RequestResponse> {
      return this.http
      .get<RequestResponse>(`${environment.API}/rrhh/trabajador/`)
      .pipe(
        map((res: RequestResponse) =>{
          return res;
        }),
        catchError((err)=> this.handlerError(err))
      );
    }

    Eliminar(id:number): Observable<RequestResponse> {
      return this.http
      .delete<RequestResponse >(`${environment.API}/rrhh/horasExtras/eliminar/${id}`)
      .pipe(
        map((res:RequestResponse) =>{
          return res;
        }),
        catchError((err)=> this.handlerError(err))
      );
    }
    crear(horasExtras:any): Observable<RequestResponse> {        

      
      return this.http
      .post<RequestResponse >(`${environment.API}/rrhh/horasExtras/crear`,horasExtras)
      .pipe(
        map((res:RequestResponse) =>{
          return res;
        }),
        catchError((err)=> this.handlerError(err))
      );
    }

 

  ObtenerTodo(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/horasExtras`)
    .pipe(
      map((res:RequestResponse) =>{
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async ObtenerTodoAsync(): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/horasExtras`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener las horas extras'} as RequestResponse
    }
  }

    TraerPorId(id:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/horasExtras/${id}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRutMesActual(rut:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/horasExtras/rut/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  TraerPorRut(rut:number): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/horasExtras/all/${rut}`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
  }

  async TraerPorRutAsync(rut: number): Promise<RequestResponse> {
    try {
      return await this.http.get<RequestResponse>(`${environment.API}/rrhh/horasExtras/all/${rut}`).toPromise();
    } catch (error) {
      console.error(error);
      return {success: false, msg: 'Hubo un error al obtener las horas extras.'} as RequestResponse
    }
  }

  lista_horas(): Observable<RequestResponse> {
    return this.http
    .get<RequestResponse >(`${environment.API}/rrhh/horasExtras/listar`)
    .pipe(
      map((res:RequestResponse) =>{ 
        return res;
      }),
      catchError((err)=> this.handlerError(err))
    );
    }

    Update_horaExtra(horasExtras: any): Observable<RequestResponse> {
      return this.http
          .put<RequestResponse>(`${environment.API}/rrhh/horasExtras/actualizar/ ${horasExtras.id}`, horasExtras)
          .pipe(
              map((res: RequestResponse) => {
                  return res;
              }),
              catchError((err) => this.handlerError(err))
          )
  }
    

    private handlerError(err: any): Observable<never> {
        let errorMessage = ""
        if (err) {
            errorMessage = `Error: ${err.error.msg}`
        }
        this.alert.error_small(err.error.msg)
        return throwError(errorMessage)
    }
}